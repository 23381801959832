<div class="row linha-botao-concluir">
    <div class="row col-12 justify-content-center align-items-center">
        <button type="button" 
                (click)="acaoBotaoConcluir()"
                class="btn btn-success btn-concluir">
            <svg class="iconeGrid">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'check' }}"
                ></use>
            </svg>
            Concluir seleção
        </button>
    </div>
</div>
<mat-form-field>
    <mat-label>
        <svg class="iconeGridFiltro">
            <use
                attr.xlink:href="assets/sprite.svg#{{ 'search' }}"
            ></use>
        </svg>
        Pesquise aqui o usuário para delegar.
    </mat-label>
    <input matInput (keyup)="aplicarFiltro($event)" placeholder="Digite a descrição ou o código de sua atividade para filtrar no quadro abaixo.">
</mat-form-field>
  
<div class="mat-elevation-z8" >
    <div class="table-container-atividades"  >
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="listagem">
        <ng-container matColumnDef="selecao" >
            <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-selecao"
                mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"></th>
            <td mat-cell *matCellDef="let row"
                style="text-align: center; padding-left: 5px; height: 10px;">
                <mat-radio-button
                    #atividadeInput
                    (click)="$event.stopPropagation()"
                    (change)="selecionarUsuarios(row)"
                    [checked]="linhaSelecionada.isSelected(row)"
                    [aria-label]="checkboxLabel(row)"
                    id="Atividade_{{ row.codigo }}"
                    name="cbo">
                </mat-radio-button>
            </td>
        </ng-container>
        <ng-container matColumnDef="login">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-login"
                mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Login </th>
            <td mat-cell *matCellDef="let row"
                style="text-align: center; padding-left: 5px; padding-right: 5px;">
                <p>
                    {{ row.login }}
                </p>
            </td>
        </ng-container>

        <ng-container matColumnDef="nome">
            <th style="width: 85%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-nome"
                mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Nome </th>
            <td mat-cell *matCellDef="let row"
                style="text-align: left; padding-right: 5px;"> 
                {{ row.nome }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row 
            class="ajusteDeLinha"
            (click)="selecionarUsuarios(row)"
            *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </div>
    <mat-paginator pageSize="15" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
<div>&nbsp;</div>
