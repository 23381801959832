import { Component, OnInit, Renderer2, Input, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Licenciamento } from 'src/app/model/licenciamento';
import { Pendencia } from 'src/app/model/pendencia';
import { Empresa } from '../../../../model/empresa';
import { AtendimentoObservacaoComponent } from '../atendimento-observacao/atendimento-observacao.component';
import { Observacao } from '../../../../model/observacao';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormQuestionarioComponent } from '../../form-questionario/form-questionario.component';
import { Constantes } from '../../../../model/constantes';
import { LicenciamentoService } from '../../../../services/licenciamento/licenciamento.service';
import { take } from 'rxjs/operators';
import { LicenciamentoOrientacao } from 'src/app/model/licenciamentoOrientacao';
import { LicenciamentoFluxo } from '../../../../model/licenciamentoFluxo';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from "@angular/router";
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { Documento } from '../../../../model/documento';
import { ViewportScroller } from '@angular/common';
import { EmpresaService } from '../../../../services/empresa/empresa.service';
import { AtendimentoAvaliadorComponent } from '../atendimento-protocolo/atendimento-avaliador.component';
import { Protocolo } from 'src/app/model/protocolo';
import { LicenciamentoAtividades } from '../../../../model/licenciamentoAtividades';

@Component({
  selector: 'app-atendimento-licenciamento',
  templateUrl: './atendimento-licenciamento.component.html',
  styleUrls: ['./atendimento-licenciamento.component.scss']
})
export class AtendimentoLicenciamentoComponent implements OnInit {

  @Input() empresa: Empresa;
  @Input() licenciamento: Licenciamento;
  
  formularioConfiguracaoDocumento: FormGroup;
  formularioAtividades: FormGroup;
  pendencia: Pendencia;
  tipoDePendenciaLicenciamento = Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO;
  tipoDePendenciaLicenciamentoDescricao = Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO_DESCRICAO;
  tipoDocumento = Constantes.TIPO_DE_DOCUMENTO;

  licenciamentoAuxiliar: Licenciamento;
  licenciamentoAtendimento: Licenciamento;
  listaPerguntasPendentes: Set<string>;

  menssagemProrrogarLicenca: string;
  licencaVencida: boolean = false;
  listaDePaineisAbertos: Set<string>;
  listaDeOrientacoes: Set<string>;
  listaDeAtividades: Set<string>;

  
  @ViewChild(AtendimentoAvaliadorComponent)
  atendimentoAvaliador: AtendimentoAvaliadorComponent;

  @ViewChildren(FormQuestionarioComponent)
  formQuestionario: QueryList<FormQuestionarioComponent>;

  @ViewChild("observacaoDocumentos")
  atendimentoObservacaoDocumentos: AtendimentoObservacaoComponent;

  @ViewChild("observacaoFluxo")
  atendimentoObservacaoFluxo: AtendimentoObservacaoComponent;

  constructor(
    private document: Document,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private empresaService: EmpresaService,
    private licenciamentoService: LicenciamentoService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private arquivoService: ArquivoService,
    private viewportScroller: ViewportScroller){ 

    this.pendencia = new Pendencia();
    this.licenciamento = new Licenciamento();

    this.formularioConfiguracaoDocumento = this.formBuilder.group({
      prazoEntrega: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(50)]],
      validadeLicenca: [""],
      atividadeConstaCopam: ["1"],
    });

    this.formularioAtividades = this.formBuilder.group({
      atividadesExercidas: [""],
      atividadesNaoExercidas: [""],
    })

    this.viewportScroller.setOffset([0,330]);

    this.listaDePaineisAbertos = new Set();
    this.listaDeOrientacoes = new Set();
    this.listaDeAtividades = new Set();
    
  }

  ngOnInit(): void {
    this.empresaService
      .buscarPorInscricaoParaAtendimentoDeLicenciamento(
        localStorage.getItem("pId"), 
        this.activatedRoute.snapshot.params["inscricao"],
        this.activatedRoute.snapshot.params["codigoOrgao"], 
        this.activatedRoute.snapshot.params["codigoRequisito"],
        this.activatedRoute.snapshot.params["codigoMenu"]
      )
      .pipe(take(1))
      .subscribe( retorno => {
        this.empresa = new Empresa();
        Object.assign(this.empresa, retorno[0]);
        Object.assign(this.licenciamento, retorno[1]);
        this.preencher();
      });
    
  }

  abrirFecharPainel(painel) {
    if (this.listaDePaineisAbertos.has(painel)) {
      this.listaDePaineisAbertos.delete(painel);
    }
    else  {
      this.listaDePaineisAbertos.add(painel);
    }
  }
  
  verificarPainel(painel) {
    return this.listaDePaineisAbertos.has(painel);
  }

  preencher() {

    this.atendimentoAvaliador.protocolo = new Protocolo();
    this.atendimentoAvaliador.protocolo.inscricao = this.empresa.inscricao;
    this.atendimentoAvaliador.protocolo.protocolo = this.empresa.protocolo.toString();

    var protocoloAuxiliar = this.empresa.protocolos.filter( item => { return item.protocolo == this.empresa.protocolo.toString() });
    if (protocoloAuxiliar.length) {

      this.atendimentoAvaliador.protocolo.data = protocoloAuxiliar[0].data;
    }

    this.atendimentoAvaliador.protocolo.processo = this.empresa.protocolos[0].processo;
    this.atendimentoAvaliador.protocolo.viabilidade = this.empresa.viabilidade;
      
    this.atendimentoObservacaoDocumentos.permitirAtendimento = true;
    this.atendimentoObservacaoDocumentos.observacao.enable();
    this.atendimentoObservacaoDocumentos.formulario.updateValueAndValidity();
    this.atendimentoObservacaoDocumentos.tituloObservacao = "Observações do Documento"

    let observacoesDocumento = new Array<Observacao>();

    this.licenciamento.documentos.forEach( documento => {
      let observacao = new Observacao();
      observacao.data = documento.documentoEndereco.data;
      observacao.avaliacao = documento.subTipo;
      observacao.processo = "Licenciamento Eletrônico - Gerenciamento de Documentos";
      observacao.login.nome = documento.nomeUsuario;
      observacao.observacao = documento.documentoEndereco.observacao;
      observacoesDocumento.push(observacao);
    });

    this.atendimentoObservacaoDocumentos.preencher(observacoesDocumento);

    let observacoesFluxo = new Array<Observacao>();

    this.licenciamento.licenciamentoFluxos.forEach( fluxo => {
      let observacao = new Observacao();
      observacao.data = fluxo.data;
      observacao.avaliacao = fluxo.status;
      observacao.processo = "DE: " + fluxo.orgaoOrigem.nome + " PARA: " + fluxo.orgaoDestino.nome;
      observacao.login.nome = fluxo.login.nome;
      observacao.observacao = fluxo.observacao;
      observacoesFluxo.push(observacao);
    });

    this.atendimentoObservacaoFluxo.preencher(observacoesFluxo);

    if (parseInt(this.licenciamento.qtdProrrogacaoLicenca) == 3) {
      this.menssagemProrrogarLicenca = 'Existe <b>1</b> prorrogação disponível.';
    }
    else if (parseInt(this.licenciamento.qtdProrrogacaoLicenca) < 3) {
      this.menssagemProrrogarLicenca = `Existem <b>  ${ (4 - parseInt(this.licenciamento.qtdProrrogacaoLicenca)) } </b> prorrogações disponíveis.`;
    }
    else {
      this.menssagemProrrogarLicenca = "Não existem prorrogações disponíveis."
    }

    this.validadeLicenca.setValue(this.licenciamento.dataVenciamentoLicenca);
    this.licencaVencida = new Date(new Date().toLocaleDateString("pt-br")) > new Date(this.licenciamento.dataVenciamentoLicenca.split("/").reverse().toString())

  }

  goToAnchor(ancora) {
    this.viewportScroller.scrollToAnchor(ancora);
    this.document.querySelectorAll(".nav-link").forEach( item => this.renderer.removeClass(item, "active"));
    this.renderer.addClass(this.document.querySelector("." + ancora), "active");    
  }

  acaoCriticarTodoQuestionario(checkbox: MatCheckbox) {
    this.formQuestionario.toArray().forEach( formQuestionarioSelecionado => {
      formQuestionarioSelecionado.selecionarTodos = checkbox.checked;
      formQuestionarioSelecionado.perguntas.forEach( pergunta => {
        formQuestionarioSelecionado.acaoCriticar(checkbox, pergunta.codigo);
      })
    })
  }

  acaoSelecionarOrientacoes(orientacao: MatCheckbox, codigo) {
    if (orientacao.checked) {
      this.listaDeOrientacoes.add(codigo);
    }
    else {
      this.listaDeOrientacoes.delete(codigo);
    }
  }

  acaoSelecionarAtividades(atividade: MatCheckbox, codigo) {
    if (atividade.checked) {
      this.listaDeAtividades.add(codigo);
    }
    else {
      this.listaDeAtividades.delete(codigo);
    }
    console.log(this.listaDeAtividades);
  }

  documentoLicencaBloqueadaParaLiberar() {
    let documento = this.licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == Constantes.TIPO_DE_DOCUMENTO.Lic.toString());
    
    if (documento.length) {
      return false;
    }

    return true;
  }

  enviarQuestionario() {

  }

  gerarDocumento(){

    if(this.licenciamento.documentoGerar == 'Gerar FOB' && this.prazoEntrega.value == ''){
      Swal.fire("", "Favor informar o prazo de entrega de documentação", "error");
      return false;
    }

    this.licenciamentoAuxiliar = new Licenciamento();
    this.licenciamentoAuxiliar.cliente                        = this.licenciamento.cliente;
    this.licenciamentoAuxiliar.inscricao                      = this.licenciamento.inscricao;
    this.licenciamentoAuxiliar.requisito                      = this.licenciamento.requisito;
    this.licenciamentoAuxiliar.mensagem                       = this.licenciamento.mensagem;
    this.licenciamentoAuxiliar.fatorDeRisco                   = this.licenciamento.fatorDeRisco
    this.licenciamentoAuxiliar.fatorDeRiscoLista              = this.licenciamento.fatorDeRiscoLista
    this.licenciamentoAuxiliar.fatorDeRiscoEnquadramento      = this.licenciamento.fatorDeRiscoEnquadramento
    this.licenciamentoAuxiliar.fatorDeRiscoPergunta           = this.licenciamento.fatorDeRiscoPergunta
    this.licenciamentoAuxiliar.fatorDeRiscoPerguntaDescricao  = this.licenciamento.fatorDeRiscoPerguntaDescricao
    this.licenciamentoAuxiliar.fatorDeRiscoRespostaDescricao  = this.licenciamento.fatorDeRiscoRespostaDescricao
    this.licenciamentoAuxiliar.fatorDeRiscoDocumento          = this.licenciamento.fatorDeRiscoDocumento
    this.licenciamentoAuxiliar.possuiPrazoEntregaDocumentacao = this.licenciamento.possuiPrazoEntregaDocumentacao;
    this.licenciamentoAuxiliar.prazoEntregaDocumentacao       = this.prazoEntrega.value;
    this.licenciamentoAuxiliar.atividadesConstaCopam          = this.atividadeConstaCopam.value;

    let ultimoFluxo = this.licenciamento.licenciamentoFluxos.length - 1;
    this.licenciamentoAuxiliar.licenciamentoFluxos = [this.licenciamento.licenciamentoFluxos[ultimoFluxo]];
    this.licenciamentoAuxiliar.licenciamentoFluxos[0].observacao = this.atendimentoObservacaoDocumentos.observacao.value;
    
    this.listaDeOrientacoes.forEach( orientacao => {
      let licenciamentoOrientacao = new LicenciamentoOrientacao();
      licenciamentoOrientacao.codigo = orientacao;
      this.licenciamentoAuxiliar.licenciamentoOrientacoes.push(licenciamentoOrientacao);
    });
    
    this.licenciamentoService
      .gerarDocumento(this.licenciamentoAuxiliar)
      .pipe(take(1))
        .subscribe( retorno => {
          if (retorno) {
          if (retorno.length > 2) {
              this.licenciamento.situacaoFob = retorno[2];
              this.licenciamento.situacaoCertidaoDeDispensa = retorno[3];
              this.licenciamento.situacaoLicenca = retorno[4];
              this.licenciamento.documentoLiberar = retorno[5];
              this.licenciamento.documentoBloquear = retorno[6];
              this.licenciamento.documentoVisualizar = retorno[7];
              this.licenciamento.documentoTipo = retorno[8];
              this.licenciamento.documentos = retorno[11];
              this.preencher();
            }
            Swal.fire({
              title: "Licenciamento Eletrônico",
              html: retorno[0],
              icon: retorno[1],
              willClose: () => {
                retorno[10].forEach(documento => {
                  this.baixarArquivo(documento.codigo, this.licenciamento.documentoTipo, documento.nome, documento.extensao)
                });
              }
            })
          }
          else {
            Swal.fire("", "Erro ao gerar o documento", "error");
          }
        });
  }

  baixarDocumento() {
    let documento = this.licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == this.licenciamento.documentoTipo);
    if (documento.length) {
      this.baixarArquivo(documento[0].documentoEndereco.codigo, this.licenciamento.documentoTipo, documento[0].documentoEndereco.nome, documento[0].documentoEndereco.extensao)
    }
  }

  baixarArquivo(codigo, tipo, nome, extensao) {
    this.arquivoService.downloadArquivoLicenciamentoPorCodigo(codigo, tipo)
    .subscribe((res: any) => {
      this.arquivoService.handleFile(res, nome + "." + extensao);
    });
  }

  liberarBloquearDocumento(){
    this.licenciamentoService
      .liberarBloquearDocumento(this.licenciamento.cliente, this.licenciamento.inscricao, this.licenciamento.requisito.orgao.codigo, this.licenciamento.requisito.codigo, this.licenciamento.documentoTipo)
      .pipe(take(1))
        .subscribe( retorno => {
          if (retorno) {
            if (retorno.length > 2) {
              this.licenciamento.situacaoFob = retorno[2];
              this.licenciamento.situacaoCertidaoDeDispensa = retorno[3];
              this.licenciamento.situacaoLicenca = retorno[4];
              this.licenciamento.documentoLiberar = retorno[5];
              this.licenciamento.documentoBloquear = retorno[6];
              this.licenciamento.documentoVisualizar = retorno[7];
              this.licenciamento.documentos = retorno[8];
              this.preencher();
            }
            Swal.fire({
              title: "Licenciamento Eletrônico",
              html: retorno[0],
              icon: retorno[1],
              willClose: () => {
              }
            })
          }
          else {
            Swal.fire("", "Erro ao tentar liberar ou bloquear o documento.", "error");
          }
        });
  }

  prorrogarLicenca() {

  }

  validarAtendimento(acao){
    this.listaPerguntasPendentes = new Set<string>();

    if(acao == Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO.Deferido || acao == Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO.Indeferido){
      this.formQuestionario.forEach( questionario => questionario.perguntasCriticadas.forEach( codigo => this.listaPerguntasPendentes.add(codigo) ) );

      if(acao == Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO.Deferido && this.listaPerguntasPendentes.size > 0){
        Swal.fire("", "O Questionário não pode ser deferido com crítica(s). Favor retirar qualquer crítica do questionário para o deferimento.O Questionário não pode ser deferido com crítica(s). Favor retirar qualquer crítica do questionário para o deferimento.", "error");
        return false;
      }
      if(acao == Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO.Indeferido && this.listaPerguntasPendentes.size == 0){
        Swal.fire("", "Para o status Pendente Usuário é necessário ao menos uma crítica ao questionário.", "error");
        return false;
      }
    }

    return true;
  }

  atender(acao){ 

    let caminhoRetorno = "../";

    if(this.validarAtendimento(acao)){

      let ultimoFluxo = this.licenciamento.licenciamentoFluxos.length - 1;

      this.licenciamentoAtendimento = new Licenciamento();
      this.licenciamentoAtendimento.cliente = this.licenciamento.cliente;
      this.licenciamentoAtendimento.inscricao = this.licenciamento.inscricao;
      this.licenciamentoAtendimento.requisito = this.licenciamento.requisito;
      this.licenciamentoAtendimento.atividades = this.licenciamento.atividades;
      this.licenciamentoAtendimento.protocolo = this.licenciamento.licenciamentoFluxos[ultimoFluxo].protocolo;
      this.licenciamentoAtendimento.licenciamentoFluxos = [this.licenciamento.licenciamentoFluxos[ultimoFluxo]];
      this.licenciamentoAtendimento.licenciamentoFluxos[0].status = acao;
      this.licenciamentoAtendimento.licenciamentoFluxos[0].observacao = this.atendimentoObservacaoFluxo.observacao.value;
      this.listaPerguntasPendentes.forEach( codigo => {
        this.licenciamentoAtendimento.licenciamentoFluxos[0].listaPerguntasPendentes = this.licenciamentoAtendimento.licenciamentoFluxos[0].listaPerguntasPendentes.concat(codigo + ",")
      });

      this.listaDeAtividades.forEach( codigoAtividade => {
        var indexAtividadeExercida = this.empresa.viabilidade.atividadesExercidas.findIndex( atividade => { return atividade.codigo == codigoAtividade });
        var indexAtividadeNaoExercida = this.empresa.viabilidade.atividadesNaoExercidas.findIndex( atividade => { return atividade.codigo == codigoAtividade });

        if (indexAtividadeExercida > -1) {
          var licencimentoAtividadeAuxiliar = new LicenciamentoAtividades();

          licencimentoAtividadeAuxiliar.cliente = this.empresa.viabilidade.atividadesExercidas[indexAtividadeExercida].cliente;
          licencimentoAtividadeAuxiliar.protocolo = this.licenciamento.licenciamentoFluxos[ultimoFluxo].protocolo;
          licencimentoAtividadeAuxiliar.inscricao = this.licenciamento.inscricao;
          licencimentoAtividadeAuxiliar.codigoAtividade = this.empresa.viabilidade.atividadesExercidas[indexAtividadeExercida].codigo;
          licencimentoAtividadeAuxiliar.descricaoAtividade = this.empresa.viabilidade.atividadesExercidas[indexAtividadeExercida].nome;

          this.licenciamentoAtendimento.licenciamentoAtividades.push(licencimentoAtividadeAuxiliar);
        }
        else if (indexAtividadeNaoExercida > -1) {
          var licencimentoAtividadeAuxiliar = new LicenciamentoAtividades();

          licencimentoAtividadeAuxiliar.cliente = this.empresa.viabilidade.atividadesNaoExercidas[indexAtividadeNaoExercida].cliente;
          licencimentoAtividadeAuxiliar.protocolo = this.licenciamento.licenciamentoFluxos[ultimoFluxo].protocolo;
          licencimentoAtividadeAuxiliar.inscricao = this.licenciamento.inscricao;
          licencimentoAtividadeAuxiliar.codigoAtividade = this.empresa.viabilidade.atividadesNaoExercidas[indexAtividadeNaoExercida].codigo;
          licencimentoAtividadeAuxiliar.descricaoAtividade = this.empresa.viabilidade.atividadesNaoExercidas[indexAtividadeNaoExercida].nome;
          
          this.licenciamentoAtendimento.licenciamentoAtividades.push(licencimentoAtividadeAuxiliar);
        }
      })


      Swal.fire({
        text: 'Confirmar a análise?',
        icon: 'question',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then((result) => {
        if (!result.dismiss) {
          this.licenciamentoService
          .atender(this.licenciamentoAtendimento)
          .pipe(take(1))
            .subscribe( retorno => {
              if (retorno) {
                if (retorno.length > 2) {
                  this.licenciamento.atendimentoDeferir = retorno[2];
                  this.licenciamento.atendimentoIndeferir = retorno[3];
                  this.licenciamento.atendimentoBloquear = retorno[4];
                  this.licenciamento.atendimentoLiberar = retorno[5];
                  this.licenciamento.atendimentoBloquear = retorno[6];
                  this.licenciamento.atendimentoReavaliar = retorno[7];
                  this.licenciamento.atendimentoReativar = retorno[8];
                }
                Swal.fire({
                  title: "Licenciamento Eletrônico",
                  html: `${ retorno[0] }`,
                  icon: "success",
                  willClose: () => {
                    //this.licenciamentoAtendido.emit({ pendencia: retorno });
                    document.location.reload();
                  }
                })
              }
              else {
                Swal.fire("", "Não foi possível efetuar a solicitação, tente novamente mais tarde.", "error")
              }
            });
            }
      });

    }
 
    
  }
    
  get prazoEntrega() {
    return this.formularioConfiguracaoDocumento.get("prazoEntrega");
  }
  set prazoEntrega(prazoEntrega) {
    this.formularioConfiguracaoDocumento.get("prazoEntrega").setValue(prazoEntrega);
  }

  get validadeLicenca() {
    return this.formularioConfiguracaoDocumento.get("validadeLicenca");
  }
  set validadeLicenca(validadeLicenca) {
    this.formularioConfiguracaoDocumento.get("validadeLicenca").setValue(validadeLicenca);
  }
    
  get atividadeConstaCopam() {
    return this.formularioConfiguracaoDocumento.get("atividadeConstaCopam");
  }
  set atividadeConstaCopam(atividadeConstaCopam) {
    this.formularioConfiguracaoDocumento.get("atividadeConstaCopam").setValue(atividadeConstaCopam);
  }

  get atividadesExercidas() {
    return this.formularioAtividades.get("atividadesExercidas");
  }
  set atividadesExercidas(atividadesExercidas) {
    this.formularioAtividades.get("atividadesExercidas").setValue(atividadesExercidas);
  }

  get atividadesNaoExercidas() {
    return this.formularioAtividades.get("atividadesNaoExercidas");
  }
  set atividadesNaoExercidas(atividadesNaoExercidas) {
    this.formularioAtividades.get("atividadesNaoExercidas").setValue(atividadesNaoExercidas);
  }

}
