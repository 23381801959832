import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipeModule } from '../../../../pipes/pipes.module';
import { AlertModule } from '../../../../components/alert/alert.module';
import { AtendimentoObservacaoComponent } from './atendimento-observacao.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AtendimentoPareceresModule } from '../atendimento-pareceres/atendimento-pareceres.module';


@NgModule({
  declarations: [
    AtendimentoObservacaoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    PipeModule,
    AlertModule,
    PerfectScrollbarModule,
  ],
  exports: [
    AtendimentoObservacaoComponent
  ],
})
export class AtendimentoObservacaoModule {}
