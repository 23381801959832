<div  id="navigation" class="sidebar-wrapper sidebar-theme">
  <div id="menuLateral">
    <nav id="sidebar">
      <div class="shadow-bottom"></div>
      <ul class="list-unstyled menu-categories ps pb-5" id="accordionExample" [perfectScrollbar]="config">
        <li *ngFor="let item of menuItems | filtro: {campo: 'sidebar', valor: 'true'}" class="menu">
          <a data-toggle="collapse"
            aria-expanded="false"
            [routerLink]="item.rotaSistema"
            routerLinkActive="texto-active"
            class="dropdown-toggle cursor-pointer"
          >
            <div class="row col-12 justify-content-start align-items-center">
              <div class="col-2">
                <svg>
                  <use
                    attr.xlink:href="#{{ item.icone }}"
                  ></use>
                </svg>
              </div>
              <div class="col-10">
                <span>{{ item.posturas == "true" ? item.posturasDescricao : item.descricao }}</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
