import { OnInit, Component, Input } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Documento } from "src/app/model/documento";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-form-observacao-evento",
    templateUrl: "./form-observacao-evento.component.html",
    styleUrls: ["./form-observacao-evento.component.scss"]
})
export class FormObservacaoEventoComponent implements OnInit {
    
    @Input() documentos: Array<Documento>;
    
    tipoEmpresa: string;
    formulario: FormGroup;
    
    emitirCritica: boolean;
    mensagemCritica: string;
    emitirErro: boolean;
    mensagemErro: string;
    
    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute) {
        
        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];
    
        this.documentos = new Array<Documento>();

        this.formulario = this.formBuilder.group({
            objetoSocial: ["", [Validators.maxLength(7000)]],
        });

        this.emitirCritica = false;
        this.mensagemCritica = "";
        this.emitirErro = false;
        this.mensagemErro = "";

    }

    ngOnInit(): void {
        this.inicializacaoFormulario();
    }

    inicializacaoFormulario() {

        this.objetoSocial.clearValidators();
        this.objetoSocial.updateValueAndValidity();
    }

    limpar(){
        this.objetoSocial.reset();
        this.objetoSocial.setValue("");
    }
    
    get objetoSocial() {
        return this.formulario.get("objetoSocial");
    }
    set objetoSocial(objetoSocial) {
        this.formulario.get("objetoSocial").setValue(objetoSocial);
    }
    
}