<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Ciclo de Vida</strong>
        </div>
    </div>
    <div *ngIf="protocolosLinhaDoTempo == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolosLinhaDoTempo != null" class="card-body" [class.p-0]="semCardHeader">
        <div id="navSection" data-spy="affix" class="nav sidenav-2">
            <div class="sidenav-2-content">
                <a *ngFor="let protocoloLinhaDoTempo of protocolosLinhaDoTempo" 
                    href="javascript:void(0);" (click)="exibirOcultar(protocoloLinhaDoTempo.protocolo)" class="nav-link-2 {{ 'tabCicloDeVida_' + protocoloLinhaDoTempo.protocolo }}">
                    <p class="card-text text-center">
                        {{ protocoloLinhaDoTempo.protocolo }}
                    </p>
                </a>
            </div>
        </div>
        <div class="form-row row-linha justify-content-start align-items-start" *ngIf="semProtocoloParaExibir">
            <div class="col-12">
                <p class="card-text text-center">
                    Selecione um protocolo para visualizar o detalhamento
                </p>
            </div>
        </div>
        <div *ngFor="let protocoloLinhaDoTempo of protocolosLinhaDoTempo"
            class="form-row row-linha justify-content-start align-items-start">
            <div class="col-12 quadro-linha-do-tempo {{ 'tabCicloDeVida_' + protocoloLinhaDoTempo.protocolo + 'Detalhes' }}" 
                [class.hidden]="(controleDeVisualizacaoDosProtocolos | filtro: {campo: 'protocolo', valor: protocoloLinhaDoTempo.protocolo})[0].ocultar">
                <app-linha-do-tempo [protocoloLinhaDoTempo]="protocoloLinhaDoTempo"></app-linha-do-tempo>
            </div>
        </div>
    </div>
</div>