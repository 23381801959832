import { Component, OnInit } from '@angular/core';
import { Protocolo } from "src/app/model/protocolo";

@Component({
  selector: 'app-informacao-requisito',
  templateUrl: './informacao-requisito.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoRequisitoComponent implements OnInit {

  protocolo: Protocolo;
  
  constructor() {
    this.protocolo = new Protocolo();
  }

  ngOnInit(): void {
  }

}
