import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Uso } from '../../model/uso';

@Injectable({ providedIn: 'root' })
export class RedeSimService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarPorProtocolo(cliente, protocolo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/redeSim/buscarPorProtocolo/${ cliente }/${ protocolo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }
         
}