import { Injectable } from '@angular/core';
import {
  Router,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class OperacaoGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      return true;
    }

    if (state.url.indexOf("servicoParaCidadao") != -1) {
      return true;
    }
    
    if (state.url.replace("/","") != "") {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }
    else {
      this.router.navigate(['/portal-home'], { queryParams: { returnUrl: state.url } });
    }

    return false;
  }
}
