import { Injectable } from '@angular/core';
import { defer, NEVER, Subject } from 'rxjs';
import { finalize, share } from 'rxjs/operators';
@Injectable()
export class LoaderService {
  isLoading = new Subject<boolean>();
  
  public readonly isLoading$ = defer(() => { 
    this.show();
    return NEVER.pipe(
      finalize(() => {
        this.hide();
      })
    );
  }).pipe(share());
  
  show() {
    this.isLoading.next(true);
  }
   hide() {
     this.isLoading.next(false); 
   }
}
