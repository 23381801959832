import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from '../../../components/alert/alert.module';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from '../../../pipes/pipes.module';
import { ContaCorrenteComponent } from './conta-corrente/conta-corrente.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { AtendimentoGridConsultaModule } from '../atendimento/atendimento-grid-consulta/atendimento-grid-consulta.module';
import { GerenciamentoTaxaComponent } from './gerenciamento-taxa.component';
import { EstatisticasTaxaComponent } from './estatisticas-taxa/estatisticas-taxa.component';
import { ProcessamentoLoteTaxaComponent } from './processamento-lote/processamento-lote-taxa.component';
import { OperacaoTaxaComponent } from './operacao-taxa/operacao-taxa.component';
import { GridParcelasModule } from './grid-parcelas/grid-parcelas.module';
import { LancamentoManualComponent } from './lancamento-manual/lancamento-manual.component';
import { AcordoComponent } from './acordo/acordo.component';
import { BaixaManualComponent } from './baixa-manual/baixa-manual.component';
import { MatRadioModule } from '@angular/material/radio';
import { RevisaoComponent } from './revisao/revisao.component';
import { CancelamentoComponent } from './cancelamento/cancelamento.component';
import { MemoriaDeCalculoModule } from './memoria-de-calculo/memoria-de-calculo.module';
import { MemoriaDeCalculoComponent } from './memoria-de-calculo/memoria-de-calculo.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableExporterModule } from 'mat-table-exporter';
import { OperacaoLoteComponent } from './operacao-lote/operacao-lote.component';
import { CalendarioFiscalLoteComponent } from './calendario-fiscal-lote/calendario-fiscal-lote.component';


@NgModule({
  declarations: [
    ContaCorrenteComponent,
    GerenciamentoTaxaComponent,
    EstatisticasTaxaComponent,
    ProcessamentoLoteTaxaComponent,
    OperacaoLoteComponent,
    OperacaoTaxaComponent,
    LancamentoManualComponent,
    AcordoComponent,
    BaixaManualComponent,
    RevisaoComponent,
    CancelamentoComponent,
    CalendarioFiscalLoteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    NgxMaskModule.forRoot(),
    PerfectScrollbarModule,
    PipeModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTabsModule,
    MatIconModule,
    AtendimentoGridConsultaModule,
    GridParcelasModule,
    MemoriaDeCalculoModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    MatMenuModule,
    MatTableExporterModule,
    MatIconModule,
  ],
  exports: [
    ContaCorrenteComponent,
    MemoriaDeCalculoComponent,
  ],
})
export class TaxasModule {}
