<div class="card" *ngIf="tipoDeMemoria == 'Geral' || tipoDeMemoria == 'Previa'">
    <div class="card-body p-0 mb-2">
        <div class="row justify-content-start align-items-center">
            <div class="col-12 card-header-linha">
                <p class="card-text pt-2 pb-2">
                    <b>DADOS DO LANÇAMENTO</b>
                </p>                    
            </div>
        </div>
    </div>
    <div class="card-body p-0 mb-2">
        <div class="row">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Código</strong>
                </p>
            </div>
            <div class="form-group col-10">
                <p class="card-text">
                    {{ taxaLancamento.codigo }}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Identificação</strong>
                </p>
            </div>
            <div class="form-group col-10">
                <p class="card-text">
                    {{ taxaLancamento.codigoTaxa != '' ? taxaLancamento.codigoTaxa + ' - ' : '' }}{{ taxaLancamento.identificacao }}
                </p>
            </div>
        </div>
        <div class="row" *ngIf="taxaLancamento.taxaLancamentoAgregados.length">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Lançamentos Agregados</strong>
                </p>
            </div>
            <div class="form-group col-10">
                <div class="row" *ngFor="let taxaLancamentoAgregado of taxaLancamento.taxaLancamentoAgregados">
                    <div class="form-group col-12 p-0">
                        <p class="card-text">
                            {{ taxaLancamentoAgregado.codigo }} - {{ taxaLancamentoAgregado.identificacao }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="taxaLancamento.requisitoDescricao != ''">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Módulo</strong>
                </p>
            </div>
            <div class="form-group col-10">
                <p class="card-text">
                    {{ taxaLancamento.orgao }} - {{ taxaLancamento.orgaoDescricao }}
                </p>
            </div>
        </div>
        <div class="row" *ngIf="taxaLancamento.requisitoDescricao != ''">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Requisito</strong>
                </p>
            </div>
            <div class="form-group col-10">
                <p class="card-text">
                    {{ taxaLancamento.requisito }} - {{ taxaLancamento.requisitoDescricao }}
                </p>
            </div>
        </div>
        <div class="row" *ngIf="taxaLancamento.faseDescricao != ''">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Módulo</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text">
                    {{ taxaLancamento.orgao }} - {{ taxaLancamento.orgaoDescricao }}
                </p>
            </div>
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Fase</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text">
                    {{ taxaLancamento.faseDescricao }}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Avaliação</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text" class="{{ acaoAtendimentoClass[taxaLancamento.avaliacao] }}">
                    {{ acaoAtendimentoDescricao[taxaLancamento.avaliacao] }}
                </p>
            </div>
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Situação</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text"
                    [class.texto-danger]="taxaLancamento.situacao == parcelaSituacao.Vencida"
                    [class.texto-success]="taxaLancamento.situacao == parcelaSituacao.Paga">
                    {{ parcelaSituacaoDescricao[taxaLancamento.situacao] }}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Quantidade de Parcelas</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text">
                    {{ taxaLancamento.qtdParcelas }}
                </p>
            </div>
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Valor Total</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text">
                    R$ {{ formatToBRL(taxaLancamento.total) }}
                </p>
            </div>
        </div>
        <div class="row" *ngIf="taxaLancamento.taxaLancamentoMemorias.length">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Parâmetros</strong>
                </p>
            </div>
            <div class="form-group col-10">
                <div class="row" *ngFor="let taxaLancamentoMemoria of taxaLancamento.taxaLancamentoMemorias | filtro: {campo: 'descricaoRegraParametro', valor: '', tipoValor: '!='};">
                    <div class="form-group col-12 p-0">
                        <p class="card-text">
                            {{ taxaLancamentoMemoria.descricaoRegraParametro }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="row row-linha mb-5">
            <div class="row justify-content-start align-items-center">
                <div class="col-12 card-header-linha">
                    <p class="card-text">
                        <b>MEMÓRIA DE CÁLCULO</b>
                    </p>                    
                </div>
            </div>
            <app-grid-memoria-de-calculo class="w-100"></app-grid-memoria-de-calculo>
        </div>
    </div>
</div>
    

<div class="card" *ngIf="tipoDeMemoria == 'Parcela'">
    <div class="card-body p-0 mb-2">
        <div class="row justify-content-start align-items-center">
            <div class="col-12 card-header-linha">
                <p class="card-text pt-2 pb-2">
                    <b>DADOS DA PARCELA</b>
                </p>                    
            </div>
        </div>
    </div>
    <div class="card-body p-0 mb-2">
        <div class="row">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Lançamento / Parcela</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text">
                    {{ taxaLancamentoParcela.codigoLancamento }} / {{ taxaLancamentoParcela.codigo }} - {{ taxaLancamentoParcela.numero }}
                </p>
            </div>
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Vencimento</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text">
                    {{ taxaLancamentoParcela.vencimento }} 
                    <strong [class.texto-danger]="taxaLancamentoParcela.situacao == parcelaSituacao.Vencida"
                        [class.texto-success]="taxaLancamentoParcela.situacao == parcelaSituacao.Paga">
                        ({{ parcelaSituacaoDescricao[taxaLancamentoParcela.situacao] }})
                    </strong>
                </p>
            </div>
        </div>            
        <div class="row">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Desconto</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text">
                    R$ {{ formatToBRL(taxaLancamentoParcela.subTotalDesconto) }}
                </p>
            </div>
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Mora</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text">
                    R$ {{ formatToBRL(taxaLancamentoParcela.subTotalMora) }}
                </p>
            </div>
        </div>            
        <div class="row">
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Juros</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text">
                    R$ {{ formatToBRL(taxaLancamentoParcela.subTotalJuros) }}
                </p>
            </div>
            <div class="form-group col-2">
                <p class="card-text">
                    <strong>Total</strong>
                </p>
            </div>
            <div class="form-group col-4">
                <p class="card-text">
                    R$ {{ formatToBRL(taxaLancamentoParcela.total) }}
                </p>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="row row-linha mb-5">
            <div class="row justify-content-start align-items-center">
                <div class="col-12 card-header-linha">
                    <p class="card-text">
                        <b>MEMÓRIA DE CÁLCULO DA CORREÇÃO MONETÁRIA</b>
                    </p>                    
                </div>
            </div>
            <app-grid-memoria-de-calculo-parcela class="w-100"></app-grid-memoria-de-calculo-parcela>
        </div>
    </div>
</div>