
<div *ngIf="exibirLocalizacao" class="card">
    <!-- <div class="card-header">
        <div class="row">
            <div class="row col-12 justify-content-start align-items-center" >
                <strong>Local Em Pesquisa</strong>
            </div>
        </div>
    </div> -->
    <div class="card-body p-0">
        <div class="row col-12" *ngIf="endereco == null">
            <p class="card-text">
                Nenhum endereço selecionado.
            </p>
        </div>
        <div class="row col-12 p-0" *ngIf="endereco != null">
            <div class="row col-12 justify-content-center align-items-center header-atividades p-0">
                <div class="row col-1 justify-content-center align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>CEP</strong>
                    </p>
                </div>
                <div class="row col-4 justify-content-start align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>Logradouro</strong>
                    </p>
                </div>
                <div class="row col-3 justify-content-center align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>Bairro</strong>
                    </p>
                </div>
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>Cidade</strong>
                    </p>
                </div>
                <div class="row col-1 justify-content-center align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>Estado</strong>
                    </p>
                </div>
                <div class="row col-1 justify-content-center align-items-center"></div>
            </div>
            <div class="row col-12 p-0 pt-3 pb-3">
                <div class="row col-1 justify-content-center align-items-center">
                    <p class="card-text text-center">
                        {{ ("00000000" + endereco.cep).slice(-8) | mask: '00000-000'  }}
                    </p>
                </div>
                <div class="row col-4 justify-content-start align-items-center">
                    <p class="card-text">
                        {{ endereco.tipoLogradouro }} 
                        {{ endereco.logradouro }}{{ endereco.numero == '' ? '' : ', ' }}
                        {{ endereco.numero }}{{ endereco.complemento == '' ? '' : ' - ' }}
                        {{ endereco.complemento }}
                        <!-- {{ endereco.pontoReferencia == '' ? '' : ' - ' }}{{ endereco.pontoReferencia }} -->
                    </p>
                </div>
                <div class="row col-3 justify-content-center align-items-center">
                    <p class="card-text">
                        {{ endereco.bairro }}
                    </p>
                </div>
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text text-center">
                        {{ endereco.cidade }}
                    </p>
                </div>
                <div class="row col-1 justify-content-center align-items-center">
                    <p class="card-text text-center">
                        {{ endereco.estado }}
                    </p>
                </div>
                <div class="row col-1 justify-content-center align-items-center">
                    <ng-template class #popTitle>
                        <div class="row col-12 row-linha justify-content-center align-items-center">
                            <b>Informações complementares</b>
                        </div>
                    </ng-template>
                    <ng-template #popContent>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Bairro:</b>
                            </div>
                            <div class="row col-5">
                                {{ consultaViabilidadeEndereco.bairro }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Tipos de Negócios:</b>
                            </div>
                            <div class="row col-5">
                                {{ consultaViabilidadeEndereco.tipoNegocio }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de Vida dos Negócios:</b>
                            </div>
                            <div class="row col-5">
                                {{ consultaViabilidadeEndereco.vidaNegocio }}
                            </div>
                        </div>                      
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de área utilizada:</b>
                            </div>
                            <div class="row col-5">
                                {{ consultaViabilidadeEndereco.areaUtilizada }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de funcionários:</b>
                            </div>
                            <div class="row col-5">
                                {{ consultaViabilidadeEndereco.qtdeFuncionarios }}
                            </div>
                        </div>                        
                    </ng-template>
                    <button type="button" 
                            id="btn-info-localizacao"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                            popoverClass="popover-google-maps"
                            placement="left"
                            container="body"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade texto-success">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'info' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!exibirLocalizacao && exibirAtividades" class="card">
    <div class="row col-12 p-0" >
        <div class="row col-12 justify-content-center align-items-center header-atividades p-0">
            <div class="row col-1 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>CEP</strong>
                </p>
            </div>
            <div class="row col-4 justify-content-start align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Logradouro</strong>
                </p>
            </div>
            <div class="row col-3 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Bairro</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Cidade</strong>
                </p>
            </div>
            <div class="row col-1 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Estado</strong>
                </p>
            </div>
            <div class="row col-1 justify-content-center align-items-center"></div>
        </div>
        <div class="row col-12" *ngIf="localizacaoSelecionada == null" >
            <p class="card-text text-center w-100 m-4" >
                Nenhuma endereço selecionado.
            </p>
        </div>
        <div class="row col-12 p-0 pt-3 pb-3" *ngIf="localizacaoSelecionada != null" >
            <div class="row col-1 justify-content-center align-items-center">
                <p class="card-text text-center">
                    {{ ("00000000" + localizacaoSelecionada.cep).slice(-8) | mask: '00000-000'  }}
                </p>
            </div>
            <div class="row col-4 justify-content-start align-items-center">
                <p class="card-text">
                    {{ localizacaoSelecionada.tipoLogradouro }} 
                    {{ localizacaoSelecionada.logradouro }}{{ localizacaoSelecionada.numero == '' ? '' : ', ' }}
                    {{ localizacaoSelecionada.numero }}{{ localizacaoSelecionada.complemento == '' ? '' : ' - ' }}
                    {{ localizacaoSelecionada.complemento }}
                    <!-- {{ localizacaoSelecionada.pontoReferencia == '' ? '' : ' - ' }}{{ localizacaoSelecionada.pontoReferencia }} -->
                </p>
            </div>
            <div class="row col-3 justify-content-center align-items-center">
                <p class="card-text">
                    {{ localizacaoSelecionada.bairro }}
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text text-center">
                    {{ localizacaoSelecionada.cidade }}
                </p>
            </div>
            <div class="row col-1 justify-content-center align-items-center">
                <p class="card-text text-center">
                    {{ localizacaoSelecionada.estado }}
                </p>
            </div>
            <div class="row col-1 justify-content-center align-items-center">
                <div class="row col-6 justify-content-center align-items-center">
                    <ng-template class #popTitle>
                        <div class="row col-12 row-linha justify-content-center align-items-center">
                            <b>Informações complementares</b>
                        </div>
                    </ng-template>
                    <ng-template #popContent>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Bairro:</b>
                            </div>
                            <div class="row col-5">
                                {{ consultaViabilidadeEndereco.bairro }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Tipos de Negócios:</b>
                            </div>
                            <div class="row col-5">
                                {{ consultaViabilidadeEndereco.tipoNegocio }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de Vida dos Negócios:</b>
                            </div>
                            <div class="row col-5">
                                {{ consultaViabilidadeEndereco.vidaNegocio }}
                            </div>
                        </div>                      
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de área utilizada:</b>
                            </div>
                            <div class="row col-5">
                                {{ consultaViabilidadeEndereco.areaUtilizada }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de funcionários:</b>
                            </div>
                            <div class="row col-5">
                                {{ consultaViabilidadeEndereco.qtdeFuncionarios }}
                            </div>
                        </div>                        
                    </ng-template>
                    <button type="button" 
                            id="btn-info-localizacao"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                            popoverClass="popover-google-maps"
                            placement="left"
                            container="body"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade texto-success">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'info' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
                <div class="row col-6 justify-content-center align-items-center">
                    <button type="button" 
                            matTooltip="Excluir"
                            matTooltipPosition="right"
                            class="btn"
                            (click)="acaoExcluirLocalizacaoEscolhida()">
                        <svg class="iconeListaAtividade">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="exibirAtividades" class="card">
    <!-- <div class="card-header">
        <div class="row">
            <div class="row col-12 justify-content-start align-items-center" >
                <strong>Atividades Em Pesquisa</strong>
            </div>
        </div>
    </div> -->
    <div class="card-body p-0">
        <div class="row col-12" *ngIf="atividades == null" >
            <p class="card-text" >
                Nenhuma atividades selecionada.
            </p>
        </div>
        <div class="row col-12 p-0" *ngIf="atividades != null" >
            <div class="row col-12 header-atividades">
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>Classificações</strong>
                    </p>
                </div>
                <div class="row col-10 justify-content-center align-items-center ">
                    <p class="card-text titulo-atividades">
                        <strong>Atividades</strong>
                    </p>
                </div>
            </div>
            <div class="row col-12 linha-grid-atividades-selecionadas" 
                *ngFor="let atividade of atividades; let i = index">
                <div class="row col-2 justify-content-center align-items-center">
                    <button *ngIf="atividade.uso.status == tiposDeUsos.Permitido"
                            type="button" 
                            matTooltip="Possível"
                            matTooltipPosition="right"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade texto-success">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'thumbs-up' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="atividade.uso.status == tiposDeUsos.Nao_Permitido"
                            type="button" 
                            matTooltip="Não Possível"
                            matTooltipPosition="right"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade texto-danger">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'thumbs-down' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="atividade.uso.status == tiposDeUsos.Permissivel"
                            type="button" 
                            matTooltip="Permissível"
                            matTooltipPosition="right"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade texto-warning">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    <ng-template class #popTitle>
                        <div class="row col-12 row-linha justify-content-center align-items-center">
                            <b>Informações complementares</b>
                        </div>
                    </ng-template>
                    <ng-template #popContent>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Município:</b>
                            </div>
                            <div class="row col-5">
                                {{ (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].endereco.cidade }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Tipos de Negócios:</b>
                            </div>
                            <div class="row col-5">
                                {{ (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].tipoNegocio }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de Vida dos Negócios:</b>
                            </div>
                            <div class="row col-5">
                                {{ (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].vidaNegocio }}
                            </div>
                        </div>                      
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de área utilizada:</b>
                            </div>
                            <div class="row col-5">
                                {{ (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].areaUtilizada }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de funcionários:</b>
                            </div>
                            <div class="row col-5">
                                {{ (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].qtdeFuncionarios }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Porte da Atividade:</b>
                            </div>
                            <div class="row col-5">
                                Médio
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-12">
                                <b>Requisitos:</b>
                            </div>
                        </div>   
                        <div class="row col-12 row-linha">
                            <div class="row col-12" *ngFor="let requisito of (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].requisitos">
                                <div class="row col-1"></div>
                                <div class="row col-2">
                                    <p class="card-text">
                                        {{ requisito.orgao.nome }}
                                    </p>
                                </div>
                                <div class="row col-9">
                                    <p class="card-text">
                                        {{ requisito.nome }}
                                    </p>
                                </div>
                            </div>
                        </div> 
                    </ng-template>
                    <button type="button" 
                            id="btn-info-localizacao"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                            popoverClass="popover-google-maps"
                            placement="right"
                            container="body"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade texto-success">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'info' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
                <div class="row col-10 justify-content-start align-items-center"
                    [class.mt-2]="!exibirLocalizacao || !exibirAtividades"
                    [class.mb-2]="!exibirLocalizacao || !exibirAtividades">
                    <p class="card-text" *ngIf="tipoDeNegocio == tiposDeEmpresa.Autonomo">
                        {{  atividade.codigo | mascara: 12 }} - {{ formatarNomeAtividade(atividade.nome) }}
                    </p>
                    <p class="card-text" *ngIf="tipoDeNegocio != tiposDeEmpresa.Autonomo">
                        {{  atividade.codigo | mascara: 10 }} - {{ formatarNomeAtividade(atividade.nome) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!exibirAtividades && exibirLocalizacao" class="card">
    <div class="card-body p-0">
        <div class="row col-12 p-0" >
            <div class="row col-12 header-atividades">
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>Classificações</strong>
                    </p>
                </div>
                <div class="row col-10 justify-content-center align-items-center ">
                    <p class="card-text titulo-atividades">
                        <strong>Atividades</strong>
                    </p>
                </div>
            </div>
            <div class="row col-12" *ngIf="!atividadesSelecionadas.length" >
                <p class="card-text text-center w-100 m-4" >
                    Nenhuma atividades selecionada.
                </p>
            </div>
            <div class="row col-12 linha-grid-atividades-selecionadas" 
                *ngFor="let atividade of atividadesSelecionadas; let i = index">
                <div class="row col-2 justify-content-center align-items-center">
                    <button *ngIf="atividade.uso.status == tiposDeUsos.Permitido"
                            type="button" 
                            matTooltip="Possível"
                            matTooltipPosition="right"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade texto-success">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'thumbs-up' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="atividade.uso.status == tiposDeUsos.Nao_Permitido"
                            type="button" 
                            matTooltip="Não Possível"
                            matTooltipPosition="right"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade texto-danger">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'thumbs-down' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="atividade.uso.status == tiposDeUsos.Permissivel"
                            type="button" 
                            matTooltip="Permissível"
                            matTooltipPosition="right"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade texto-warning">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    <ng-template class #popTitle>
                        <div class="row col-12 row-linha justify-content-center align-items-center">
                            <b>Informações complementares</b>
                        </div>
                    </ng-template>
                    <ng-template #popContent>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Município:</b>
                            </div>
                            <div class="row col-5">
                                {{ (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].endereco.cidade }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Tipos de Negócios:</b>
                            </div>
                            <div class="row col-5">
                                {{ (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].tipoNegocio }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de Vida dos Negócios:</b>
                            </div>
                            <div class="row col-5">
                                {{ (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].vidaNegocio }}
                            </div>
                        </div>                      
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de área utilizada:</b>
                            </div>
                            <div class="row col-5">
                                {{ (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].areaUtilizada }}
                            </div>
                        </div>
                        <div class="row col-12 row-linha">
                            <div class="row col-7">
                                <b>Média de funcionários:</b>
                            </div>
                            <div class="row col-5">
                                {{ (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].qtdeFuncionarios }}
                            </div>
                        </div> 
                        <div class="row col-12 row-linha">
                            <div class="row col-12">
                                <b>Requisitos:</b>
                            </div>
                        </div>   
                        <div class="row col-12 row-linha">
                            <div class="row col-12" *ngFor="let requisito of (consultaViabilidadeAtividades | filtro: {campo: 'codigo', valor: atividade.codigo })[0].requisitos">
                                <div class="row col-1"></div>
                                <div class="row col-2">
                                    <p class="card-text">
                                        {{ requisito.orgao.nome }}
                                    </p>
                                </div>
                                <div class="row col-9">
                                    <p class="card-text">
                                        {{ requisito.nome }}
                                    </p>
                                </div>
                            </div>
                        </div>                        
                    </ng-template>
                    <button type="button" 
                            id="btn-info-localizacao"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                            popoverClass="popover-google-maps"
                            placement="right"
                            container="body"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade texto-success">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'info' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
                <div class="row col-9 justify-content-start align-items-center"
                    [class.mt-2]="!exibirLocalizacao || !exibirAtividades"
                    [class.mb-2]="!exibirLocalizacao || !exibirAtividades">
                    <p class="card-text" *ngIf="tipoDeNegocio == tiposDeEmpresa.Autonomo">
                        {{  atividade.codigo | mascara: 12 }} - {{ formatarNomeAtividade(atividade.nome) }}
                    </p>
                    <p class="card-text" *ngIf="tipoDeNegocio != tiposDeEmpresa.Autonomo">
                        {{  atividade.codigo | mascara: 10 }} - {{ formatarNomeAtividade(atividade.nome) }}
                    </p>
                </div>
                <div class="row col-1">
                    <button type="button" 
                            matTooltip="Excluir"
                            matTooltipPosition="right"
                            class="btn"
                            (click)="acaoExcluirAtividadeEscolhida(i)">
                        <svg class="iconeListaAtividade">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="apiLoaded | async">
    <div class="row col-12">
        <div class="row col-12">
            <google-map class="w-100" [options]="options"></google-map>
        </div>
    </div>
</div>
<div class="card"></div>
<div class="card" *ngIf="exibirLocalizacao || exibirAtividades">
    <div class="card-body p-0">
        <div class="row col-12 p-0" >
            <div class="row col-12 header-atividades">
                <div class="row col-12 justify-content-center align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>Conclusão da pesquisa prévia de viabilidade para o seu empreendimento no município</strong>
                    </p>
                </div>
            </div>
            <div class="row col-12 justify-content-center align-items-center" *ngIf="situacaoEmpresa == tiposDeUsos.Permitido">
                <p class="texto-success">
                    <svg class="icone-pesquisa-viabilidade texto-success">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'thumbs-up' }}"
                        ></use>
                    </svg>
                    PERMITIDO
                </p>
            </div>
            <div class="row col-12 justify-content-center align-items-center" *ngIf="situacaoEmpresa == tiposDeUsos.Nao_Permitido">
                <p class="texto-danger">
                    <svg class="icone-pesquisa-viabilidade texto-danger">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'thumbs-down' }}"
                        ></use>
                    </svg>
                    NÃO PERMITIDO
                </p>
            </div>
            <div class="row col-12 justify-content-center align-items-center" *ngIf="situacaoEmpresa == tiposDeUsos.Permissivel">
                <p class="texto-warning">
                    <svg class="icone-pesquisa-viabilidade texto-warning">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                        ></use>
                    </svg>
                    PERMISSÍVEL
                </p>
            </div>
            <div class="row col-12 row-linha">
                <div class="row col-12">
                    <b>Requisitos necessárias para emissão de seu alvará:</b>
                </div>
            </div>   
            <div class="row col-12 row-linha">
                <div class="row col-12" *ngFor="let requisito of requisitosEmpresa">
                    <div class="row col-1"></div>
                    <div class="row col-2">
                        <p class="card-text">
                            {{ requisito.orgao.nome }}
                        </p>
                    </div>
                    <div class="row col-9">
                        <p class="card-text">
                            {{ requisito.nome }}
                        </p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>
<div class="card"></div>