<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card" *ngIf="orgao != '888' && !eDespacho">
        <div class="card-header" *ngIf="!semCardHeader">
            <div class="col-12 justify-content-start align-items-center">
                <strong>Atendimento</strong>
            </div>
        </div>
        <div *ngIf="requisitos == null" class="card-body" [class.p-0]="semCardHeader">
            <div class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text text-center">
                        Carregando...
                    </p>
                </div>
            </div>      
        </div>
        <div *ngIf="requisitos != null" >
            <div class="row p-0" *ngFor="let requisito of requisitos | filtro: {campo: 'orgao.codigo', valor: orgao}">
                <div class="row">
                    <div class="row col-2 justify-content-center align-items-center header-risco text-uppercase"
                        [class.btn-success]="requisito.statusAvaliacao == 'S'"
                        [class.btn-secondary]="requisito.statusAvaliacao == ''"
                        [class.btn-dark]="requisito.statusAvaliacao == 'R'"
                        [class.btn-danger]="requisito.statusAvaliacao == 'N' || requisito.statusAvaliacao == 'C'"
                        [class.btn-warning]="requisito.statusAvaliacao == 'P'">
                        {{ 
                            requisito.statusAvaliacao == 'S' ? 'DEFERIDO' : 
                            requisito.statusAvaliacao == 'N' ? 'INDEFERIDO' :
                            requisito.statusAvaliacao == 'P' ? 'PENDENTE' :
                            requisito.statusAvaliacao == 'R' ? 'REAVALIAÇÃO' :
                            requisito.statusAvaliacao == 'C' ? 'CANCELADO' : 'NÃO AVALIADO'
                        }}
                    </div>
                    <div class="row col-8 justify-content-center align-items-center header-atendimento-requisito text-uppercase">
                        <p class="card-text titulo-atividades text-uppercase"
                            [class.erro]="requisito.obrigatorio == true" >
                            {{ requisito.codigo }} - {{ requisito.nome }}
                        </p>
                    </div>
                    <div class="row col-2 justify-content-center align-items-center header-risco text-uppercase"
                        [class.btn-info]="requisito.requisitoRisco.codigo == '' || requisito.requisitoRisco.codigo == '1'"
                        [class.btn-success]="requisito.requisitoRisco.codigo == '2'"
                        [class.btn-warning]="requisito.requisitoRisco.codigo == '3'"
                        [class.btn-danger]="requisito.requisitoRisco.codigo >= '4'">
                        Risco: {{ requisito.requisitoRisco.descricao }}
                    </div>
                </div>
                
                <div class="row col-12" *ngIf="requisito.bloqueiaDocumentoLista.length">
                    <div class="row col-12" >&nbsp;</div>
                    <app-alert class="w-100" type="danger" *ngIf="requisito.bloqueiaDocumentoLista.length">
                        <div>
                            * Este requisito bloqueará o(s) seguinte(s) documento(s): <b>{{ retornarListaDeDocumentos(requisito.bloqueiaDocumentoLista) }}</b>
                        </div>
                    </app-alert>
                </div>

                <div class="row col-12" *ngIf="licenciamentos != null && 
                                                licenciamentos.length &&
                                                (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo }).length">
                    <div class="row col-12" *ngIf="!requisito.bloqueiaDocumentoLista.length">&nbsp;</div>
                    <app-alert class="w-100" type="warning" *ngIf="(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].perguntasLicenciamento.length && 
                                                                    !(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].licenciamentoFluxos.length">
                        <div>
                            Existe um questionário de licenciamento para esse requisito aguardando as respostas do contribuinte.
                        </div>
                    </app-alert>
                    <app-alert class="w-100" type="warning" *ngIf="(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo }).length && 
                                                                    (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].licenciamentoFluxos.length">
                        <div>
                            Existe um questionário de licenciamento para esse requisito, acesse o botão de atendimento para visualizar o mesmo.
                        </div>
                    </app-alert>
                    <app-alert class="w-100" type="info" *ngIf="(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo }).length && 
                                                                (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].situacaoLicenca &&
                                                                (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].statusDoLicenciamento == tipoDePendenciaLicenciamento.Deferido">
                        <div>
                            {{ tipoDocumentoDescricao[tipoDocumento.Lic] }} está disponível para impressão, para imprimir acesse o botão de atendimento.
                        </div>
                    </app-alert>
                    <app-alert class="w-100" type="info" *ngIf="(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo }).length && 
                                                                (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].questionarioBloqueado &&
                                                                (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].documentoTipo != ''">
                        <div>
                            {{ tipoDocumentoDescricao[(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].documentoTipo] }} está disponível para impressão, para imprimir acesse o botão de atendimento.
                        </div>
                    </app-alert>
                </div>
                <div class="row col-12 row-linha" >
                    <p class="card-text">
                        Caro Auditor, para o atendimento dessa Requisito favor se atentar a validade apresentada, essa validade tem influência em todo o comportamento da mesma.<br>
                        Se for necessário o ajuste desta informação, clicar no icone <svg class="icone-editar"><use attr.xlink:href="assets/sprite.svg#{{ 'edit' }}" ></use></svg> e informar a validade.<br>
                        Após as devidas análises utilizar o botão de atendimento com a opção desejada.
                    </p>
                </div>
                <div class="row col-12 row-linha" >&nbsp;</div>
                <div class="row col-12" >
                    <div class="row col-2 justify-content-start align-items-center" ></div>
                    <div class="row col-8 justify-content-center align-items-center" >
                        <p class="card-text text-uppercase">
                            <strong *ngIf="requisito.vencimento == ''"> 
                                VALIDADE: INDETERMINADA
                            </strong>
                            <strong *ngIf="requisito.vencimento != ''">
                                VALIDADE: {{ requisito.vencimento }}
                            </strong>
                            <button  *ngIf="requisito.statusAvaliacao != 'C'"
                                type="button" 
                                matTooltip="Editar Validade"
                                matTooltipPosition="above"
                                class="btn"
                                (click)="inserirValidade(requisito)">
                                <svg class="icone-editar">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                                    ></use>
                                </svg>
                            </button>
                        </p>
                    </div>
                    <div class="row col-2 justify-content-end align-items-center" >
                        <app-atendimento-requisito-acao 
                            *ngIf="requisito.statusAvaliacao != 'C'"
                            [empresa]="empresa"
                            [pendencias]="pendencias"
                            [despachos]="despachos"
                            [licenciamento]="(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0]"
                            [codigoRequisito]="requisito.codigo"
                            (acaoDeAtendimento)="atender($event, requisito)" 
                        ></app-atendimento-requisito-acao>
                    </div>
                </div>
                <div class="row col-12 row-linha" >&nbsp;</div>
                <div class="row col-12 quadro-divisor-titulo-requisito" *ngIf="orgao == '640' && requisito.codigo == '12598'">
                    <div class="row col-10">
                        <p class="card-text text-uppercase">
                            <b>Automação TFE</b>
                        </p>
                    </div>
                    <div class="row col-2 justify-content-end align-items-center">
                        <button
                            type="button" 
                            matTooltip="Parcelas"
                            matTooltipPosition="above"
                            class="btn p-0"
                            (click)="visualizarParcelas(requisito)">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'file-text' }}"
                                ></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div *ngIf="!exibirDadosTfe" class="row col-12 quadro-divisor-historico-requisito">
                    <div class="row col-12 justify-content-center align-items-center">
                        <p class="card-text text-center">
                            <br>
                                Não existem informações disponibilizadas para esse negócio.
                            <br>&nbsp;
                        </p>
                    </div>
                </div>
                <div class="row col-12 quadro-divisor-historico-requisito" *ngIf="orgao == '640' && requisito.codigo == '12598' && exibirDadosTfe">
                    <p class="card-text col-12">
                        <b>Caro auditor, o requisito no dia {{ dtSeriaModificado }} estava nessa situação:</b> <br>
                        <b>Status: </b> 
                        <span class="p-1" [class.btn-warning]="situacaoAtual == 'PENDENTE' || situacaoAtual == 'PENDENTE USUÁRIO' || situacaoAtual == 'PENDENTE ORGÃO' || situacaoAtual == 'PENDENTE AVALIAÇÃO'"
                              [class.btn-danger]="situacaoAtual == 'INDEFERIDO' || situacaoAtual == 'CANCELADO'"
                              [class.btn-success]="situacaoAtual == 'DEFERIDO'"
                              [class.btn-secondary]="situacaoAtual == 'NÃO AVALIADO'"
                              [class.btn-dark]="situacaoAtual == 'REAVALIAÇÃO'">
                                {{ situacaoAtual }}
                        </span>
                        <br>
                        <b>Vencimento:</b> {{ dtVencimentoAtual }} <br>
                        <b>* Lembrete: Utilizar o histórico do requisito para verificar essa informação:</b> 
                    </p>
                    <p class="card-text col-12 mt-4">
                        <b>Caro auditor, o requisito no dia {{ dtSeriaModificado }} seria passado para a seguinte situação:</b> <br>
                        <b>Status: </b> 
                        <span class="p-1" [class.btn-warning]="situacaoTfe == 'PENDENTE' || situacaoTfe == 'PENDENTE USUÁRIO' || situacaoTfe == 'PENDENTE ORGÃO' || situacaoTfe == 'PENDENTE AVALIAÇÃO'"
                              [class.btn-danger]="situacaoTfe == 'INDEFERIDO' || situacaoTfe == 'CANCELADO'"
                              [class.btn-success]="situacaoTfe == 'DEFERIDO'"
                              [class.btn-secondary]="situacaoTfe == 'NÃO AVALIADO'"
                              [class.btn-dark]="situacaoTfe == 'REAVALIAÇÃO'">
                                {{ situacaoTfe }}
                        </span>
                        <br>
                        <b>Vencimento:</b> {{ dtVencimentoTfe }} <br>
                        <b>Observação:</b> <span [innerHtml]="descObservacaoTfe"></span>
                    </p>
                </div>
                <div class="row col-12 row-linha" >&nbsp;</div>
                <div class="row col-12 quadro-divisor-titulo-requisito" >
                    <div class="row col-12">
                        <p class="card-text text-uppercase">
                            <b>Histórico</b>
                        </p>
                    </div>
                </div>
                <div class="row col-12 quadro-divisor-historico-requisito">
                    <app-historico-requisito class="w-100" [dados]="[requisito,'atendimento']"></app-historico-requisito>
                </div>
                <div class="mb-5">
                    <div class="col-12 justify-content-start align-items-center">
                        <strong>&nbsp;</strong>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!(requisitos | filtro: {campo: 'orgao.codigo', valor: orgao}).length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                        Não existem requisitos vinculados.
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="orgao == '888' && !eDespacho">
        <div class="card-header" *ngIf="!semCardHeader">
            <div class="col-12 justify-content-start align-items-center">
                <strong>Atendimento</strong>
            </div>
        </div>
        <div *ngIf="requisitos == null" class="card-body" [class.p-0]="semCardHeader">
            <div class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text text-center">
                        Carregando...
                    </p>
                </div>
            </div>      
        </div>
        <div *ngIf="requisitos != null" >
            <div class="row p-0" *ngFor="let requisito of requisitos | filtro: {campo: 'orgao.codigo', valor: orgao}">
                <div class="row">
                    <div class="row col-2 justify-content-center align-items-center header-risco text-uppercase"
                        [class.btn-success]="requisito.statusAvaliacao == 'S'"
                        [class.btn-secondary]="requisito.statusAvaliacao == ''"
                        [class.btn-dark]="requisito.statusAvaliacao == 'R'"
                        [class.btn-danger]="requisito.statusAvaliacao == 'N' || requisito.statusAvaliacao == 'C'"
                        [class.btn-warning]="requisito.statusAvaliacao == 'P'">
                        {{ 
                            requisito.statusAvaliacao == 'S' ? 'DEFERIDO' : 
                            requisito.statusAvaliacao == 'N' ? 'INDEFERIDO' :
                            requisito.statusAvaliacao == 'P' ? 'PENDENTE' :
                            requisito.statusAvaliacao == 'R' ? 'REAVALIAÇÃO' :
                            requisito.statusAvaliacao == 'C' ? 'CANCELADO' : 'NÃO AVALIADO'
                        }}
                    </div>
                    <div class="row col-8 justify-content-center align-items-center header-atividades text-uppercase">
                        <p class="card-text titulo-atividades text-uppercase text-center"
                            [class.erro]="requisito.obrigatorio == true" >
                            {{ orgao == '888' ? requisito.orgao.codigo + ' - ' + requisito.orgao.nome : '' }} <br *ngIf="orgao == '888'"> {{ requisito.codigo }} - {{ requisito.nome }}
                        </p>
                    </div>
                    <div class="row col-2 justify-content-center align-items-center header-risco text-uppercase"
                        [class.btn-info]="requisito.requisitoRisco.codigo == '' || requisito.requisitoRisco.codigo == '1'"
                        [class.btn-success]="requisito.requisitoRisco.codigo == '2'"
                        [class.btn-warning]="requisito.requisitoRisco.codigo == '3'"
                        [class.btn-danger]="requisito.requisitoRisco.codigo >= '4'">
                        Risco: {{ requisito.requisitoRisco.descricao }}
                    </div>
                </div>
                <div class="row col-12" *ngIf="licenciamentos != null && licenciamentos.length">
                    <div class="row col-12" >&nbsp;</div>
                    <app-alert class="w-100" type="warning" *ngIf="(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo }).length && 
                                                                    (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].licenciamentoFluxos.length">
                        <div>
                            Existe um questionário de licenciamento para esse Requisito, acesse o botão de atendimento para visualizar o mesmo.
                        </div>
                    </app-alert>
                    <app-alert class="w-100" type="info" *ngIf="(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo }).length && 
                                                                (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].situacaoLicenca &&
                                                                (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].statusDoLicenciamento == tipoDePendenciaLicenciamento.Deferido">
                        <div>
                            {{ tipoDocumentoDescricao[tipoDocumento.Lic] }} está disponível para impressão, para imprimir acesse o botão de atendimento.
                        </div>
                    </app-alert>
                    <app-alert class="w-100" type="info" *ngIf="(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo }).length && 
                                                                (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].questionarioBloqueado &&
                                                                (licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].documentoTipo != ''">
                        <div>
                            {{ tipoDocumentoDescricao[(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0].documentoTipo] }} está disponível para impressão, para imprimir acesse o botão de atendimento.
                        </div>
                    </app-alert>
                </div>
                <div class="row col-12 row-linha" >
                    <p class="card-text">
                        Caro Auditor, para o atendimento dessa Requisito favor se atentar a validade apresentada, essa validade tem influência em todo o comportamento da mesma.<br>
                        Se for necessário o ajuste desta informação, clicar no icone <svg class="icone-editar"><use attr.xlink:href="assets/sprite.svg#{{ 'edit' }}" ></use></svg> e informar a validade.<br>
                        Após as devidas análises utilizar o botão de atendimento com a opção desejada.
                    </p>
                </div>
                <div class="row col-12 row-linha" >&nbsp;</div>
                <div class="row col-12" >
                    <div class="row col-2 justify-content-start align-items-center" ></div>
                    <div class="row col-8 justify-content-center align-items-center" >
                        <p class="card-text text-uppercase">
                            <strong *ngIf="requisito.vencimento == ''"> 
                                VALIDADE: INDETERMINADA
                            </strong>
                            <strong *ngIf="requisito.vencimento != ''">
                                VALIDADE: {{ requisito.vencimento }}
                            </strong>
                            <button  *ngIf="requisito.statusAvaliacao != 'C'"
                                type="button" 
                                matTooltip="Editar Validade"
                                matTooltipPosition="above"
                                class="btn"
                                (click)="inserirValidade(requisito)">
                                <svg class="icone-editar">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                                    ></use>
                                </svg>
                            </button>
                        </p>
                    </div>
                    <div class="row col-2 justify-content-end align-items-center" >
                        <app-atendimento-requisito-acao 
                            *ngIf="requisito.statusAvaliacao != 'C'"
                            [empresa]="empresa"
                            [pendencias]="pendencias"
                            [despachos]="despachos"
                            [licenciamento]="(licenciamentos | filtro: {campo: 'requisito.codigo', valor: requisito.codigo })[0]"
                            [codigoRequisito]="requisito.codigo"
                            (acaoDeAtendimento)="atender($event, requisito)" 
                        ></app-atendimento-requisito-acao>
                    </div>
                </div>
                <div class="row col-12 quadro-divisor-titulo-requisito" *ngIf="requisito.codigo == '12598'">
                    <div class="row col-12">
                        <p class="card-text text-uppercase">
                            <b>Automação TFE</b>
                        </p>
                    </div>
                </div>
                <div class="row col-12 quadro-divisor-historico-requisito" *ngIf="requisito.codigo == '12598'">
                    <p class="card-text">
                        <b>Status:</b> 
                        <span [class.btn-warning]="situacaoTfe == 'PENDENTE' || situacaoTfe == 'PENDENTE USUÁRIO' || situacaoTfe == 'PENDENTE ORGÃO' || situacaoTfe == 'PENDENTE AVALIAÇÃO'"
                              [class.btn-danger]="situacaoTfe == 'INDEFERIDO' || situacaoTfe == 'CANCELADO'"
                              [class.btn-success]="situacaoTfe == 'DEFERIDO'"
                              [class.btn-secondary]="situacaoTfe == 'NÃO AVALIADO'"
                              [class.btn-dark]="situacaoTfe == 'REAVALIAÇÃO'">
                                {{ situacaoTfe }}
                        </span>
                        <br>
                        <b>Data de Vencimento:</b> {{ dtVencimentoTfe }} <br>
                        <b>Observação:</b> {{ descObservacaoTfe }}<br>
                        <b>Data de Automação:</b> {{ dtSeriaModificado }}
                    </p>
                </div>
                <div class="row col-12 row-linha" >&nbsp;</div>
                <div class="row col-12 quadro-divisor-titulo-requisito" >
                    <div class="row col-12">
                        <p class="card-text text-uppercase">
                            <b>Histórico</b>
                        </p>
                    </div>
                </div>
                <div class="row col-12 quadro-divisor-historico-requisito">
                    <app-historico-requisito class="w-100" [dados]="[requisito,'atendimento']"></app-historico-requisito>
                </div>
                <div class="mb-5">
                    <div class="col-12 justify-content-start align-items-center">
                        <strong>&nbsp;</strong>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!(requisitos | filtro: {campo: 'orgao.codigo', valor: orgao}).length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                        Não existem requisitos vinculados.
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="eDespacho">
        <div class="card-header" *ngIf="!semCardHeader">
            <div class="col-12 justify-content-start align-items-center">
                <strong>Atendimento</strong>
            </div>
        </div>
        <app-atendimento-observacao #observacoesDespacho></app-atendimento-observacao>
    </div>    
    <app-atendimento-acao *ngIf="eDespacho" (acaoDeAtendimento)="atender($event, null)"></app-atendimento-acao>
</form>