import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from '@angular/router';
import { Critica } from '../../../../model/critica';
import { Pendencia } from '../../../../model/pendencia';
import { Constantes } from '../../../../model/constantes';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { Licenciamento } from '../../../../model/licenciamento';
import { Empresa } from '../../../../model/empresa';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { LicenciamentoService } from '../../../../services/licenciamento/licenciamento.service';
import Swal from 'sweetalert2';
import { Despacho } from 'src/app/model/despacho';

@Component({
  selector: 'app-atendimento-requisito-acao',
  templateUrl: './atendimento-requisito-acao.component.html',
  styleUrls: ['./atendimento-empresa.component.scss']
})
export class AtendimentoRequisitoAcaoComponent implements OnInit {

  @Output() acaoDeAtendimento = new EventEmitter();
  @Input() empresa: Empresa;
  @Input() criticas: Array<Critica>;
  @Input() licenciamento: Licenciamento;
  @Input() pendencias: Array<Pendencia>;
  @Input() despachos: Array<Despacho>;
  @Input() codigoRequisito: string;
  pendenciasSelecionadas: Array<Pendencia>;

  tipoDocumento = Constantes.TIPO_DE_DOCUMENTO;
  tipoDePendenciaLicenciamento = Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO;
  tipoDocumentoDescricao = Constantes.TIPO_DE_DOCUMENTO_DESCRICAO;
  

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private licenciamentoService: LicenciamentoService,
    private activatedRoute: ActivatedRoute,
    private arquivoService: ArquivoService) {
  }

  ngOnInit(): void {}

  atender(acaoAtendimento) {
    this.acaoDeAtendimento.emit({ acao: acaoAtendimento, pendenciasSelecionadas: this.pendenciasSelecionadas, despachos: this.despachos, licenciamento: this.licenciamento});
  }

  deferir() {
    this.atender(Constantes.ACAO_ATENDIMENTO.Deferir);
  }

  indeferir() {
    this.atender(Constantes.ACAO_ATENDIMENTO.Indeferir);    
  }

  cancelar() {
    this.atender(Constantes.ACAO_ATENDIMENTO.Cancelar);    
  }

  questionario() {
    this.router.navigate([this.router.url, "elicenciamento", this.codigoRequisito]);
  }

  documentoQuestionarioBloqueadoParaLiberar() {
    let documento = this.licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == this.licenciamento.documentoTipo);
    if (documento.length) {
      return false;
    }

    return true;
  }

  documentoLicencaBloqueadaParaLiberar() {
    let documento = this.licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == Constantes.TIPO_DE_DOCUMENTO.Lic.toString());
    
    if (documento.length) {
      return false;
    }

    return true;
  }
  
  liberarBloquearDocumento(documentoTipo){
    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '80%',
      height: '95%',
      maxWidth: 'none',
      maxHeight: 'none',
      closeOnNavigation: true,
      data: {
          titulo: "Pareceres",
          componente: "atendimento-pareceres",
          dados: [
            "requisito",
            localStorage.getItem("pId"), 
            this.activatedRoute.snapshot.params["codigoOrgao"], 
            this.licenciamento.requisito.codigo,
            Constantes.ACAO_ATENDIMENTO.Liberar_Bloquear,
            ""
          ]
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result.parecerFinal != null) {
        var observacao = result.parecerFinal;
        this.confirmarliberarBloquearDocumento(documentoTipo, observacao);
      }
    });
    
  }

  confirmarliberarBloquearDocumento(documentoTipo, observacao) {
    var licenciamentoAuxiliar = new Licenciamento();
    licenciamentoAuxiliar.cliente = this.licenciamento.cliente;
    licenciamentoAuxiliar.inscricao = this.licenciamento.inscricao;
    licenciamentoAuxiliar.requisito.orgao.codigo = this.licenciamento.requisito.orgao.codigo;
    licenciamentoAuxiliar.requisito.codigo = this.licenciamento.requisito.codigo;
    licenciamentoAuxiliar.documentoTipo = documentoTipo;
    licenciamentoAuxiliar.mensagem = observacao;

    this.licenciamentoService
      .liberarBloquearDocumentoComObservacao(licenciamentoAuxiliar)
      .pipe(take(1))
        .subscribe( retorno => {
          if (retorno) {
            if (retorno.length > 2) {
              this.licenciamento.situacaoFob = retorno[2];
              this.licenciamento.situacaoCertidaoDeDispensa = retorno[3];
              this.licenciamento.situacaoLicenca = retorno[4];
              this.licenciamento.documentoLiberar = retorno[5];
              this.licenciamento.documentoBloquear = retorno[6];
              this.licenciamento.documentoVisualizar = retorno[7];
              this.licenciamento.documentos = retorno[8];
            }
            Swal.fire({
              title: "Licenciamento Eletrônico",
              html: retorno[0],
              icon: retorno[1],
              willClose: () => {
              }
            })
          }
          else {
            Swal.fire("", "Erro ao tentar liberar ou bloquear o documento.", "error");
          }
        });
  }
    
  regerarDocumento(documentoTipo){
    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '80%',
      height: '95%',
      maxWidth: 'none',
      maxHeight: 'none',
      closeOnNavigation: true,
      data: {
          titulo: "Pareceres",
          componente: "atendimento-pareceres",
          dados: [
            "requisito",
            localStorage.getItem("pId"), 
            this.activatedRoute.snapshot.params["codigoOrgao"], 
            this.licenciamento.requisito.codigo,
            Constantes.ACAO_ATENDIMENTO.Liberar_Bloquear,
            ""
          ]
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result.parecerFinal != null) {
        var observacao = result.parecerFinal;
        this.confirmarregerarDocumento(documentoTipo, observacao);
      }
    });
    
  }

  confirmarregerarDocumento(documentoTipo, observacao) {
    var licenciamentoAuxiliar = new Licenciamento();
    licenciamentoAuxiliar.cliente = this.licenciamento.cliente;
    licenciamentoAuxiliar.inscricao = this.licenciamento.inscricao;
    licenciamentoAuxiliar.requisito.orgao.codigo = this.licenciamento.requisito.orgao.codigo;
    licenciamentoAuxiliar.requisito.codigo = this.licenciamento.requisito.codigo;
    licenciamentoAuxiliar.documentoTipo = documentoTipo;
    licenciamentoAuxiliar.mensagem = observacao;

    this.licenciamentoService
      .regerarDocumento(licenciamentoAuxiliar)
      .pipe(take(1))
        .subscribe( retorno => {
          if (retorno) {
            if (retorno.length > 2) {
              this.licenciamento.situacaoFob = retorno[2];
              this.licenciamento.situacaoCertidaoDeDispensa = retorno[3];
              this.licenciamento.situacaoLicenca = retorno[4];
              this.licenciamento.documentoLiberar = retorno[5];
              this.licenciamento.documentoBloquear = retorno[6];
              this.licenciamento.documentoVisualizar = retorno[7];
              this.licenciamento.documentos = retorno[8];
            }
            Swal.fire({
              title: "Licenciamento Eletrônico",
              html: retorno[0],
              icon: retorno[1],
              willClose: () => {
              }
            })
          }
          else {
            Swal.fire("", "Erro ao tentar liberar ou bloquear o documento.", "error");
          }
        });
  }

  liberarBloquearQuestionario(){
    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '80%',
      height: '95%',
      maxWidth: 'none',
      maxHeight: 'none',
      closeOnNavigation: true,
      data: {
          titulo: "Pareceres",
          componente: "atendimento-pareceres",
          dados: [
            "requisito",
            localStorage.getItem("pId"), 
            this.activatedRoute.snapshot.params["codigoOrgao"], 
            this.licenciamento.requisito.codigo,
            Constantes.ACAO_ATENDIMENTO.Liberar_Bloquear,
            ""
          ]
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result.parecerFinal != null) {
        var observacao = result.parecerFinal;
        this.confirmarliberarBloquearQuestionario(observacao);
      }
    });
    
  }

  confirmarliberarBloquearQuestionario(observacao) {
    var licenciamentoAuxiliar = new Licenciamento();
    licenciamentoAuxiliar.cliente = this.licenciamento.cliente;
    licenciamentoAuxiliar.inscricao = this.licenciamento.inscricao;
    licenciamentoAuxiliar.requisito.orgao.codigo = this.licenciamento.requisito.orgao.codigo;
    licenciamentoAuxiliar.requisito.codigo = this.licenciamento.requisito.codigo;
    licenciamentoAuxiliar.mensagem = observacao;

    this.licenciamentoService
      .liberarBloquearQuestionarioComObservacao(licenciamentoAuxiliar)
      .pipe(take(1))
        .subscribe( retorno => {
          if (retorno) {
            if (retorno.length > 2) {
              this.licenciamento = retorno[2];
            }
            Swal.fire({
              title: "Licenciamento Eletrônico",
              html: retorno[0],
              icon: retorno[1],
              willClose: () => {
                this.atender(Constantes.ACAO_ATENDIMENTO.Atualizar_Licenciamento);
              }
            })
          }
          else {
            Swal.fire("", "Erro ao tentar liberar ou bloquear o documento.", "error");
          }
        });
  }

  imprimirLicenca() {
    let documento = this.licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == Constantes.TIPO_DE_DOCUMENTO.Lic.toString());
    if (documento.length) {
      this.baixarArquivo(documento[0].documentoEndereco.codigo, documento[0].tipo, documento[0].documentoEndereco.nome, documento[0].documentoEndereco.extensao)
    }
  }

  imprimirDocumentoQuestionarioBloqueado() {
    let documento = this.licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == this.licenciamento.documentoTipo);
    
    if (documento.length) {
      this.baixarArquivo(documento[0].documentoEndereco.codigo, documento[0].tipo, documento[0].documentoEndereco.nome, documento[0].documentoEndereco.extensao)
    }
  }

  regerarDocumentoQuestionarioBloqueado() {
    let documento = this.licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == this.licenciamento.documentoTipo);
    
    if (documento.length) {
      this.baixarArquivo(documento[0].documentoEndereco.codigo, documento[0].tipo, documento[0].documentoEndereco.nome, documento[0].documentoEndereco.extensao)
    }
  }

  baixarArquivo(codigo, tipo, nome, extensao) {
    this.arquivoService.downloadArquivoLicenciamentoPorCodigo(codigo, tipo)
    .subscribe((res: any) => {
      this.arquivoService.handleFile(res, nome + "." + extensao);
    });
  } 

  pendenciarOrgao(){
    this.atender(Constantes.ACAO_ATENDIMENTO.Pendenciar_Orgao);
  }

  pendenciar() {
    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '60%',
      closeOnNavigation: true,
      data: {
        titulo: "Pendência",
        componente: "atendimento-pendenciar",
        dados: [this.pendencias, this.activatedRoute.snapshot.params["codigoOrgao"], this.criticas, this.codigoRequisito]
      }
      });
      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === "" || result === null)
          return false;

      this.pendenciasSelecionadas = result.pendencias;
      this.atender(Constantes.ACAO_ATENDIMENTO.Pendenciar);
    });
  }

  despachar() {
    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '80%',
      height: '90%',
      maxWidth: 'none',
      maxHeight: 'none',
      closeOnNavigation: true,
      data: {
        titulo: "Despacho",
        componente: "atendimento-despachar",
        dados: [
          this.despachos, 
          this.activatedRoute.snapshot.params["codigoOrgao"], 
          this.activatedRoute.snapshot.params["protocolo"], 
          this.activatedRoute.snapshot.params["inscricao"],
          this.codigoRequisito
        ]
      }
      });
      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === "" || result === null)
          return false;

      this.pendenciasSelecionadas = result.pendencias;

      this.atender(Constantes.ACAO_ATENDIMENTO.Despachar);
    });
  }

  responderDespacho() {
    this.atender(Constantes.ACAO_ATENDIMENTO.Responder_Despacho);
  }

}
