import { DespachoFluxo } from "./despachoFluxo";
import { Processo } from "./processo";

export class Despacho {
    cliente: string;
    protocolo: string;
    inscricao: string;
    codigo: string;
    codigoTipo: string;
    descricaoTipo: string;
    codigoStatus: string;
    descricaoStatus: string;
    avaliacao: string;
    data: string;
    fluxos: Array<DespachoFluxo>;
    situacaoInterna: string;
    situacao: number;
    fase: string;
    processo: Processo;
    icone: string;
    classe: string;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.inscricao = "";
        this.codigo = "";
        this.codigoTipo = "";
        this.descricaoTipo = "";
        this.codigoStatus = "";
        this.descricaoStatus = "";
        this.avaliacao = "";
        this.data = "";
        this.fluxos = new Array<DespachoFluxo>();
        this.situacaoInterna = "";
        this.situacao = 1;
        this.fase = "";
        this.processo = new Processo();
        this.icone = "";
        this.classe = "";
    }
}