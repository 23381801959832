<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Documentos</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="empresaSuspensa" class="card-body p-0">
        <div class="row col-12 justify-content-center align-items-center">
            <p class="card-text text-center">
                <br>
                Empresa suspensa, por gentileza verificar no módulo de suspensão.
                <br>&nbsp;
            </p>
        </div>
    </div>
    <div *ngIf="protocolo != null && !empresaSuspensa" class="card-body p-0">
        <div *ngIf="protocolo.documentos.length" class="row justify-content-center align-items-center header-atividades">
            <div class="row col-4 justify-content-start align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Descrição</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Data de Emissão</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Data de Vencimento</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Status</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-end align-items-center"></div>
        </div>
        <div *ngFor="let documento of protocolo.documentos"
            class="row row-linha-documentos linha-grid-divisao-top">
            <div class="row col-4 justify-content-start align-items-center">
                <p class="card-text titulo">
                    <strong>{{ documento.nome }}</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo">
                    <strong>{{ documento.dataEmissao }}</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo">
                    <strong>{{ documento.dataVencimento }}</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo">
                    <strong>{{ documento.status == 'N' ? 'Bloqueado' : 'Liberado' }}</strong>
                </p>
            </div>
            <div class="row col-1 justify-content-center align-items-center texto-secondary"
                (click)="visualizarArquivo(documento.codigo, documento.numero)"
                matTooltip="Visualizar"
                matTooltipPosition="right">
                <svg class="iconeGridDownload">
                    <use attr.xlink:href="assets/sprite.svg#{{ 'search' }}"></use>
                </svg>
            </div>
            <div class="row col-1 justify-content-center align-items-center"
                [class.texto-success]="documento.status != 'N'"
                [class.texto-danger]="documento.status == 'N'"
                (click)="baixarArquivo(documento.codigo, documento.numero)"
                [matTooltip]="documento.status != 'N' ? 'Download' : 'Bloqueado para Download'"
                matTooltipPosition="right">
                <svg class="iconeGridDownload">
                    <use attr.xlink:href="assets/sprite.svg#{{ documento.status != 'N' ? 'download' : 'x-circle'}}"></use>
                </svg>
            </div>
        </div>
        <div *ngIf="!protocolo.documentos.length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                    Não existem documento(s) liberado(s).
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>
