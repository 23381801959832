import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeModule } from '../../../pipes/pipes.module';
import { AlertModule } from '../../../components/alert/alert.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatIconModule } from '@angular/material/icon';
import { OutrosModule } from '../outros.module';
import { AberturaOficioComponent } from './abertura/abertura-oficio.component';
import { BaixaOficioComponent } from './baixa/baixa-oficio.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { AtendimentoModule } from '../atendimento/atendimento.module';
import { AtualizacaoOficioComponent } from './atualizacao/atualizacao-oficio/atualizacao-oficio.component';
import { AtendimentoGridModule } from '../atendimento/atendimento-grid/atendimento-grid.module';
import { GridModule } from 'src/app/components/grid/grid.module';
import { AtendimentoAcaoModule } from '../atendimento/atendimento-acao/atendimento-acao.module';
import { AlteracaoOficioComponent } from './alteracao/alteracao-oficio.component';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  declarations: [
    AberturaOficioComponent,
    BaixaOficioComponent,
    AtualizacaoOficioComponent,
    AlteracaoOficioComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatDividerModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    MatMenuModule,
    MatTableExporterModule,
    MatIconModule,
    MatTooltipModule,
    PipeModule,
    AlertModule,
    OutrosModule,
    AtendimentoModule,
    GridModule,
    AtendimentoAcaoModule,
    MatCheckboxModule,
  ],
  exports: [    
    AberturaOficioComponent,
    BaixaOficioComponent,
    AtualizacaoOficioComponent,
    AlteracaoOficioComponent
  ],
})
export class OficioModule {}
