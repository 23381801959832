<div class="justify-content-md-end">
  <form name="formulario" 
    [formGroup]="formulario" 
    class="justify-content-center align-items-center"
    >
    <app-alert type="danger"
        *ngIf="emitirErro" >
        <div [innerHtml]="mensagemErro"></div>
    </app-alert>
    <div class="card mb-0">
      <div class="card-header">
        <div class="col-12">
            <strong>Cancelamento</strong>
        </div>
      </div>
    </div>
  </form>
  <div class="card" *ngFor="let taxaLancamento of taxaLancamentos">
    <div class="card-body p-0 mb-2">
        <div class="row justify-content-start align-items-center">
            <div class="col-12 card-header-linha">
                <p class="card-text pt-2 pb-2">
                    <b>LANÇAMENTO: {{ taxaLancamento.codigoTaxa != '' ? taxaLancamento.codigoTaxa + ' - ' : '' }}{{ taxaLancamento.identificacao }}</b>
                </p>                    
            </div>
        </div>
    </div>
    <div class="card-body p-0 mb-2">
        <div class="row">
          <div class="form-group col-2">
              <p class="card-text">
                  <strong>Avaliação</strong>
              </p>
          </div>
          <div class="form-group col-2">
              <p class="card-text" class="{{ acaoAtendimentoClass[taxaLancamento.avaliacao] }}">
                  {{ acaoAtendimentoDescricao[taxaLancamento.avaliacao] }}
              </p>
          </div>
          <div class="form-group col-2">
              <p class="card-text">
                  <strong>Quantidade de Parcelas</strong>
              </p>
          </div>
          <div class="form-group col-1">
              <p class="card-text">
                  {{ taxaLancamento.qtdParcelas }}
              </p>
          </div>
          <div class="form-group col-2">
              <p class="card-text">
                  <strong>Valor Total</strong>
              </p>
          </div>
          <div class="form-group col-2">
              <p class="card-text text-right">
                  R$ {{ formatToBRL(taxaLancamento.total) }}
              </p>
          </div>
          <div class="form-group col-1 text-right">
            <button type="button" 
              id="btn-cancelar-lancamento"
              matTooltip="Cancelamento"
              matTooltipPosition="above"
              class="btn btn-sm p-0 m-0"
              (click)="acaoBotaoCancelar(taxaLancamento.codigo)">
              <svg class="icone-excluir">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                  ></use>
              </svg>
            </button>
          </div>
        </div>
    </div>
  </div>
  <div class="card" *ngIf="taxaLancamentos == null || !taxaLancamentos.length">
    <div class="card-body p-0 mb-2">
      <div class="row">
        <div class="form-group col-12 mt-4">
            <p class="card-text text-center">
                <strong>Não existe(m) lançamentos para ser(em) cancelado(s)</strong>
            </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
      <div class="row col-12">
        <div class="row col-6 justify-content-start align-items-center">
            <button type="button" class="btn btn-danger" (click)="acaoBotaoVoltar()">Voltar</button>
        </div>
      </div>
  </div>
</div>