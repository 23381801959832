<mat-form-field>
    <mat-label>
        <svg class="iconeGridFiltro">
            <use
                attr.xlink:href="assets/sprite.svg#{{ 'search' }}"
            ></use>
        </svg>
        Pesquise aqui .
    </mat-label>
    <input matInput (keyup)="aplicarFiltro($event)" placeholder="Digite para filtrar no quadro abaixo.">
</mat-form-field>
  
<div class="mat-elevation-z8" >
    <div class="table-container-atividades"  >
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="listagem">
        <ng-container matColumnDef="selecao" >
            <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-selecao"
                mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"></th>
            <td mat-cell *matCellDef="let row"
                style="text-align: center; padding-left: 5px; height: 10px;">
            </td>
        </ng-container>
        <ng-container matColumnDef="classificacao">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-classificacao"
                mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Classificações</th>
            <td mat-cell *matCellDef="let row"
                style="text-align: center; padding-left: 5px; padding-right: 5px;">
                <p>
                    <button *ngIf="row.uso.status == tiposDeUsos.Permitido"
                            type="button" 
                            matTooltip="Possível"
                            matTooltipPosition="right"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'thumbs-up' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="row.uso.status == tiposDeUsos.Nao_Permitido"
                            type="button" 
                            matTooltip="Não Possível"
                            matTooltipPosition="right"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'thumbs-down' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="row.uso.status == tiposDeUsos.Permissivel"
                            type="button" 
                            matTooltip="Permissível"
                            matTooltipPosition="right"
                            class="btn">
                        <svg class="icone-pesquisa-viabilidade">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                </p>
            </td>
        </ng-container>
        
        <ng-container matColumnDef="codigo">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-codigo"
                mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Código </th>
            <td mat-cell *matCellDef="let row"
                style="text-align: center; padding-right: 5px;"> 
                {{ row.codigo | mascara: (tipoDeNegocio == 'E' ? 10 : 12) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="nome">
            <th style="width: 80%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-nome"
                mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Descrição </th>
            <td mat-cell *matCellDef="let row"
                style="text-align: left; padding-right: 5px;"> 
                {{ row.nome }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row 
            class="ajusteDeLinha"
            *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="row mt-4" *ngIf="dataSource.filteredData.length == 0">
        <div class="form-row col-12 justify-content-center align-items-center">
            Não foram encontradas atividades para esse permissionamento.
        </div>
      </div>
    </div>
    <mat-paginator pageSize="15" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
<div>&nbsp;</div>
