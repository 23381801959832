<div class="card" *ngIf="codigoOrgao == '200' && !meusProcessos">
    <app-alert type="danger"
        *ngIf="emitirErro" >
        <div [innerHtml]="mensagemErro"></div>
    </app-alert>
    <div class="card">
        <div class="card-header">
            <div class="row justify-content-center align-items-center">
                <div class="row col-12" >
                    <strong>Qual o embasamento legal para o deferimento?</strong>
                </div>
            </div>
        </div>
        <div *ngIf="atendimentoConfiguracao == null" class="card-body">
            <div class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text text-center">
                        Carregando...
                    </p>
                </div>
            </div>      
        </div>
        <div *ngIf="atendimentoConfiguracao != null" class="card-body p-0" >
            <div class="row p-0" *ngFor="let embasamento of atendimentoConfiguracao.viabilidade.embasamentos;">
                <div class="row col-12 p-0 pl-1 pt-3 pb-3 justify-content-left align-items-center text-uppercase">
                    <mat-checkbox *ngIf="permitirAtendimento"
                        #embasamentoCheckBox
                        class="pr-2"
                        (change)="acaoCheckBoxEmbasamento(embasamento.codigo, embasamentoCheckBox)"
                        [checked]="acaoCheckedEmbasamento(embasamento)"
                        id="embasamentoLegal_{{ embasamento.codigo}}"
                        name="embasamentoLegal_{{ embasamento.codigo}}">
                    </mat-checkbox>
                    <svg *ngIf="!permitirAtendimento"
                        [class.icone-check-square]="retornarNomeIcone(embasamento.codigo) == 'check-square'"
                        [class.icone-square]="retornarNomeIcone(embasamento.codigo) == 'square'">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ retornarNomeIcone(embasamento.codigo) }}"
                        ></use>
                    </svg>
                    <p class="card-text pl-2">
                        {{ embasamento.nome }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card" *ngIf="codigoOrgao == '100' || (codigoOrgao == '200' && meusProcessos)">
    <div class="card">
        <div class="card-header">
            <div class="row justify-content-center align-items-center">
                <div class="row col-12" >
                    <strong>Embasamento Legal</strong>
                </div>
            </div>
        </div>
        <div *ngIf="embasamentosSelecionados == null" class="card-body">
            <div class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text text-center">
                        Carregando...
                    </p>
                </div>
            </div>      
        </div>
        <div *ngIf="embasamentosSelecionados != null" class="card-body p-0" >
            <div class="row p-0" *ngFor="let embasamento of embasamentosSelecionados">
                <div class="row col-12 p-0 pl-1 pt-3 pb-3 justify-content-left align-items-center text-uppercase">
                    <p class="card-text pl-2">
                        {{ embasamento.nome }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>