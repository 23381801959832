import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { TaxaLancamento } from '../../../../model/taxaLancamento';
import { TaxaLancamentoParcela } from '../../../../model/taxaLancamentoParcela';
import { Constantes } from '../../../../model/constantes';
import { MascaraPipe } from '../../../../pipes/mascara.pipe';
import { MatDialog } from '@angular/material/dialog';
import { TaxaService } from 'src/app/services/taxa/taxa.service';
import { take } from 'rxjs/operators';
import { AtendimentoService } from 'src/app/services/operacao/atendimento.service';
import { Requisito } from 'src/app/model/requisito';
import { Grid } from 'src/app/model/grid';
import { GridFiltro } from 'src/app/model/gridFiltro';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';


@Component({
    selector: "app-grid-parcelas-tfe",
    templateUrl: "./grid-parcelas-tfe.component.html",
    styleUrls: ["./grid-parcelas-tfe.component.scss"]
})
export class GridParcelasTfeComponent implements OnInit {

    @Output() acaoDeAtendimento = new EventEmitter();

    grid: Grid;
    gridGiex: Grid;
    gridParcelas: Grid;
  
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumns: string[] = ["Inscricao","Dt_Vencimento","Desc_Observacao","Status","Id","Referencia","Timestamp"];
  
    dataSourceGiex: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumnsGiex: string[] = ["Inscricao","Icin_Tipo_Certidao", "Dt_Inclusao"];
  
    dataSourceParcelas: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumnsParcelas: string[] = ["Ano", "Desconto", "Dt_Vencimento", "Isencao", "Parcela", "Status", "Total", "Valor_a_Pagar"];
    
    @ViewChild("matPaginator", {static: true}) matPaginator: MatPaginator;    
    @ViewChild("matPaginatorGiex", {static: true}) matPaginatorGiex: MatPaginator;
    @ViewChild("matPaginatorParcelas", {static: true}) matPaginatorParcelas: MatPaginator;
    
    @ViewChildren(MatSort, {}) sort: QueryList<MatSort>;

    @Input() requisito: Requisito;

    mensagemDeBusca: string = "Não existem informações desse sistema.";

    constructor(
        private mascara: MascaraPipe,
        private atendimentoService: AtendimentoService,
        public dialog: MatDialog) {
            this.grid = new Grid();
            this.grid.filtro.push(new GridFiltro());
            
            this.gridGiex = new Grid();
            this.gridGiex.filtro.push(new GridFiltro());
            
            this.gridParcelas = new Grid();
            this.gridParcelas.filtro.push(new GridFiltro());

            this.requisito = new Requisito();
            
    }

    ngOnInit(): void {
        this.atendimentoService
            .buscarDadosIntegradosTfe(this.requisito.inscricao, this.requisito.codigo)
            .pipe(take(1))
            .subscribe( (retorno) => {
                this.grid.dados = retorno[0];
                this.gridGiex.dados = retorno[1];
                this.gridParcelas.dados = retorno[2];
                this.atualizarTabela();
        });
    }

    atualizarTabela() {
        this.dataSource = new MatTableDataSource(this.grid.dados);
        this.dataSource.paginator = this.matPaginator;
        this.dataSource.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
        this.sort.toArray()[0].active = "Timestamp";
        this.sort.toArray()[0].direction = <SortDirection>"ASC".toLowerCase();
    
        this.dataSource.sort = this.sort.toArray()[0];

        
        this.dataSourceGiex = new MatTableDataSource(this.gridGiex.dados);
        this.dataSourceGiex.paginator = this.matPaginatorGiex;
        this.dataSourceGiex.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
        this.sort.toArray()[1].active = "Dt_Inclusao";
        this.sort.toArray()[1].direction = <SortDirection>"ASC".toLowerCase();
    
        this.dataSourceGiex.sort = this.sort.toArray()[1];

        
        this.dataSourceParcelas = new MatTableDataSource(this.gridParcelas.dados);
        this.dataSourceParcelas.paginator = this.matPaginatorParcelas;
        this.dataSourceParcelas.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
        this.sort.toArray()[2].active = "Dt_Inclusao";
        this.sort.toArray()[2].direction = <SortDirection>"ASC".toLowerCase();
    
        this.dataSourceParcelas.sort = this.sort.toArray()[2];
    }

    ordenacaoGrid(data, sort) {
        if (sort.active == null)
            return data;
  
        let campo = sort.active.replace("servico", "servicoDescricao");
  
        if (campo == "Timestamp" || campo == "Dt_Inclusao") {
            data.sort( (item_1, item_2) => (new Date(item_1[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) > new Date(item_2[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (campo == "dataSituacao") {
            data.sort( (item_1, item_2) => (new Date(item_1[campo].split("/").reverse().toString()) > new Date(item_2[campo].split("/").reverse().toString()) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (campo == "inscricao") {
            data.sort( (item_1, item_2) => (item_1[campo] > item_2[campo] ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (typeof(data[0][campo]) == typeof("0")) {
            data.sort( (item_1, item_2) => (item_1[campo]).localeCompare(item_2[campo])  * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (typeof(data[0][campo]) == typeof(0)) {
            data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo])) ? 0 : item_1[campo] ) - ( isNaN(parseInt(item_2[campo])) ? 0 : item_2[campo] ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        
        return data;
    }
    
    formatToBRL(valor) {
        return this.mascara.transform(valor, 11);
    }


}
