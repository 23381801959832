import { pipe, Subscription } from 'rxjs';
import { EmbasamentoService } from './../../../../services/embasamento/embasamento.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AtendimentoConfiguracao } from "src/app/model/atendimentoConfiguracao";
import Swal from 'sweetalert2';
import { Embasamento } from '../../../../model/embasamento';
import { MatCheckbox } from '@angular/material/checkbox';
import { Constantes } from '../../../../model/constantes';
import { Viabilidade } from 'src/app/model/viabilidade';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-atendimento-embasamento',
  templateUrl: './atendimento-embasamento.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoEmbasamentoComponent implements OnInit {

  numeroProtocolo: string;
  atendimentoConfiguracao: AtendimentoConfiguracao;
  embasamentosSelecionados: Array<Embasamento>;
  tipoEmpresa: string;
  permitirAtendimento: boolean;
  emitirErro: boolean;
  mensagemErro: string;
  codigoOrgao: string;
  meusProcessos: boolean;
  matcheckboxEmbasamento: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private embasamentoService: EmbasamentoService) {
    this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];    
    let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
    this.meusProcessos = this.activatedRoute.snapshot.data["menu"] == Constantes.MENU_ATENDIMENTO.Processos;
    this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (!meusNegocios && !this.meusProcessos));
    this.codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"];
    this.embasamentosSelecionados = new Array<Embasamento>();
   }

  ngOnInit(): void {

  }

  acaoCheckBoxEmbasamento(valor, campo: MatCheckbox) {
    if (campo.checked) {
        this.embasamentosSelecionados.push(this.atendimentoConfiguracao.viabilidade.embasamentos.filter(item => item.codigo == valor)[0]);
    }
    else {
        let index = this.embasamentosSelecionados.findIndex(item => item.codigo == valor);
        if (index >= 0) {
            this.embasamentosSelecionados.splice(index, 1);
        }
    }
  }

  acaoCheckedEmbasamento(embasamento: Embasamento) {
    let retorno = false;

    this.embasamentosSelecionados.forEach( embasamentosSelecionado => {
        if (embasamento.codigo == embasamentosSelecionado.codigo) {
            retorno = true;
        }
    })

    return retorno;
  }
  
  validarFormulario() {
    this.emitirErro = false;
    let mensagemErro = "";

    if (!this.embasamentosSelecionados.length) {
      mensagemErro = "Favor informar qual o embasamento legal.<br>";
    }

    if (mensagemErro == "") {
      return true;
    }
    
    this.mensagemErro = mensagemErro;
    this.emitirErro = true;
    Swal.fire("Embasamento Incorreto", "Corrigir os erros descritos no informativo da página.", "error");
    
    return false;
    
  }

  retornarNomeIcone(codigo) {
    return this.embasamentosSelecionados.find(embasamento => embasamento.codigo == codigo) ? 'check-square' : 'square';
  }

  buscarConfiguracaoEmbasamento() {
    this.embasamentoService
      .buscarConfiguracaoEmbasamento(localStorage.getItem("pId"))
      .pipe(take(1))
      .subscribe( retorno => {
          this.embasamentosSelecionados = retorno;
          this.atendimentoConfiguracao.viabilidade.embasamentos.forEach(embasamento => {
            this.embasamentosSelecionados.forEach(embasamentoSelecionado => {
              if(embasamento.codigo == embasamentoSelecionado.codigo){
                localStorage.getItem("pId") == '4867' ? retorno = true : false;
              }
            });
          });
        });
  }
}