<div *ngIf="apiLoaded | async">
    <div class="row col-12">
        <div class="row col-12">
            <google-map class="w-100" [options]="options"></google-map>
        </div>
    </div>
</div>
<div class="card m-4 mb-5" *ngIf="userId == '34817176016'">
    <div class="card-body p-0">
        <div class="row col-12 p-0" >
            <div class="row col-12 header-atividades">
                <div class="row col-12 justify-content-center align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>Análise de Entorno</strong>
                    </p>
                </div>
            </div>
            <div class="row col-12 row-linha">
                <div class="row col-12">
                    <p class="card-text">
                        <b>1200m</b> POSTOS DE SAÚDE
                    </p>
                </div>
            </div>
            <div class="row col-12 row-linha">
                <div class="row col-12">
                    <p class="card-text">
                        <b>50m</b> HOSPITAIS PARTICULARES
                    </p>
                </div>
            </div>
            <div class="row col-12 row-linha">
                <div class="row col-12">
                    <p class="card-text">
                        <b>200m</b> ESCOLAS PARTICULARES
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>