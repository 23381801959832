export class LicenciamentoAtividades {
    cliente: string;
    protocolo: string;
    inscricao: string;
    codigoAtividade: string;
    descricaoAtividade: string;
    status: boolean;
    data: string;
    login: string;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.inscricao = "";
        this.codigoAtividade = "";
        this.descricaoAtividade = "";
        this.status = false;
        this.data = "";
        this.login = "";
    }
}