import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CoreComponent } from './components/core/core.component';
import { LoginComponent } from './pages/outros/autenticacao/login/login.component';
import { AuthGuard } from './services/authentication/auth.guard';
import { NaoEncontradoComponent } from './pages/outros/erros/nao-encontrado/nao-encontrado.component';
import { AcessibilidadeComponent } from "./pages/outros/acessibilidade/acessibilidade.component";
import { MapaDoSiteComponent } from "./pages/outros/mapa-do-site/mapa-do-site.component";
import { PortalHomeComponent } from "./pages/portal/home/portal-home.component";
import { OperacaoGuard } from './services/authentication/opercao.guard';
import { TermoComponent } from './pages/outros/termo/termo.component';

const routes: Routes = [
  { path: 'portal-home', 
    component: PortalHomeComponent,
    children: [
      { 
        path: 'acessibilidade', 
        component: AcessibilidadeComponent,
        data: {
          breadcrumb: ' ',
          icone: 'home',
          exibirMenu: false,
        }
      },
      { 
        path: 'mapa-do-site', 
        component: MapaDoSiteComponent,
        data: {
          breadcrumb: ' ',
          icone: 'home',
          exibirMenu: false,
        }
      },
      { 
        path: 'termo', 
        component: TermoComponent,
        data: {
          breadcrumb: ' ',
          icone: 'home',
          exibirMenu: false,
        }
      },
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'selecionar-estrutura', component: LoginComponent },
  { path: 'alterar-senha', component: LoginComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    component: CoreComponent,
    children: [
      { path: 'acessibilidade', component: AcessibilidadeComponent },
      { path: 'mapa-do-site', component: MapaDoSiteComponent },
      {
        path: 'integracao',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: ' ',
          icone: 'home',
        },
        loadChildren: () =>
          import('./pages/integracao/integracao.module').then(
            (m) => m.IntegracaoModule
          ),
      },
      {
        path: 'configuracao',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: ' ',
          icone: 'home',
        },
        loadChildren: () =>
          import('./pages/configuracao/configuracao.module').then(
            (m) => m.ConfiguracaoModule
          ),
      },
      {
        path: 'gestao',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: ' ',
          icone: 'home',
        },
        loadChildren: () =>
          import('./pages/gestao/gestao.module').then((m) => m.GestaoModule),
      },
      {
        path: 'operacao',
        canActivate: [AuthGuard],
        canActivateChild: [OperacaoGuard],
        data: {
          breadcrumb: ' ',
          icone: 'home',
        },
        loadChildren: () =>
          import('./pages/operacao/operacao.module').then((m) => m.OperacaoModule),
      },
    ],
  },
  {
    path: '**',
    component: NaoEncontradoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled', onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top', })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
