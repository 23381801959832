import { OnInit, Component } from "@angular/core";

@Component({
    selector: "app-acessibilidade",
    templateUrl: "./acessibilidade.component.html"
})
export class AcessibilidadeComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}

}