<div class="form-row row-linha justify-content-center align-items-center linha-botao">
    <button  *ngIf="modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento"
        type="button" 
        class="btn btn-success"
        title="imprimirFic"
        (click)="imprimirFic()"
    >
        Imprimir Fic
    </button>
    <button  *ngIf="modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento"
        type="button" 
        class="btn btn-warning"
        title="regerarFic"
        (click)="regerarFic()"
    >
        Gerar Fic
    </button>
    <button  *ngIf="modulo == menuAtendimento.Recadastro"
        type="button" 
        class="btn btn-warning"
        title="regerarFic"
        (click)="regerarFic()"
    >
        Gerar Recadastro
    </button>    
    <button  *ngIf="modulo == menuAtendimento.Dados_Cadastrais && permitirRegerarAlvaraProvisorio"
        type="button" 
        class="btn btn-warning"
        title="regerarAlvaraProvisorio"
        (click)="regerarAlvaraProvisorio()"
    >
        Gerar Alvará Provisório
    </button>
    <button  *ngIf="modulo == menuAtendimento.Dados_Cadastrais && permitirRegerarDispensaDeAlvara"
        type="button" 
        class="btn btn-warning"
        title="regerarDispensa"
        (click)="regerarDispensa()"
    >
        Gerar Dispensa
    </button>
    <button  *ngIf="modulo == menuAtendimento.Baixa"
        type="button" 
        class="btn btn-warning"
        title="regerarBaixa"
        (click)="regerarBaixa()"
    >
        Gerar Comprovante De Baixa
    </button>
</div>
