import { Component, OnInit } from '@angular/core';
import { CompleterService, CompleterData } from 'ng2-completer';
import { MenuService } from '../../.././../services/menu/menu.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pesquisa-menu',
  templateUrl: './pesquisa-menu.component.html',
  styleUrls: ['./pesquisa-menu.component.scss'],
})
export class PesquisaMenuComponent implements OnInit {
  dataService: CompleterData;
  inputBusca: string;
  formBusca: FormGroup;
  pesquisaFocus = false;
  menus = [];

  constructor(
    private completerService: CompleterService,
    private menuService: MenuService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  get busca() {
    return this.formBusca.controls.inputBusca;
  }

  ngOnInit(): void {
    this.formBusca = this.formBuilder.group({
      inputBusca: null,
    });
    this.authenticationService.currentAxis.subscribe((axis) => {
      if (axis != "" && axis != null) {
        this.criarMenus(axis);
      }            
    });
  }
  
  obterMenusPorTexto() {
    this.dataService = this.completerService.local(
      this.menus,
      'descricao',
      'descricao'
    );
  }

  criarMenus(menus) {
    menus.item.forEach((item) => {
      // item.rotaSistema = '/' + item.rota.split('.').splice(1, Number.MAX_VALUE).join('/');

      if (!item.item.length) {
        this.menus.push({
          icone: item.icone, 
          descricao: item.descricao, 
          rota: item.rotaSistema,
        });
      }

      item.item.forEach((funcionalidade) => {
        funcionalidade.rotaSistema =
        '/' + funcionalidade.rota.split('.').splice(1, Number.MAX_VALUE).join('/')
        + '/' + item.codigo
        + '/' + funcionalidade.orgao
        + '/' + funcionalidade.codigo;
        
        this.menus.push({
          icone: funcionalidade.icone, 
          descricao: item.descricao + ' - ' + funcionalidade.descricao, 
          rota: funcionalidade.rotaSistema,
        });
      });
    });
  }

  selecionaMenu() {
    this.menus.forEach((menu) => {
      const eixoMenu = menu.rota.split('/')[1];
      
      if (menu.descricao === this.busca.value) {
        const eixo = this.authenticationService.currentUserValue.eixos.findIndex(item => item.rota.split('.')[1] === menu.rota.split('/')[1]);
        this.authenticationService.setCurrentAxis(eixo);
        this.busca.setValue("");
        this.router.navigate([menu.rota]);
      }
    });
  }
}
