import { Component, OnInit } from '@angular/core';
import { Pendencia } from '../../../../model/pendencia';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { Protocolo } from '../../../../model/protocolo';
import { Empresa } from '../../../../model/empresa';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { Licenciamento } from '../../../../model/licenciamento';
import { Despacho } from 'src/app/model/despacho';
import { Constantes } from '../../../../model/constantes';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AtendimentoService } from '../../../../services/operacao/atendimento.service';
import { Login } from '../../../../model/login';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidator } from 'src/app/components/validators/form-validator';
import { Suspensao } from '../../../../model/suspensao';
import { ProtocoloService } from '../../../../services/operacao/protocolo.service';
import { Processo } from '../../../../model/processo';
import { Configuracao } from '../../../../model/configuracao';
import { Atendimento } from 'src/app/model/atendimento';
import { EmpresaService } from '../../../../services/empresa/empresa.service';

@Component({
    selector: "app-atendimento-reativacao",
    templateUrl: "./atendimento-reativacao.component.html",
    styleUrls: ['./atendimento-reativacao.component.scss']
})
export class AtendimentoReativacaoComponent implements OnInit {

    numeroInscricao: string;
    numeroProtocolo: string;
    configuracao: Configuracao;
    processo: Processo;
    cnpj: string;
    permitirAtendimento: boolean;
    permitirDelegar: boolean = false;
    usuarios: Array<Login>;

    despachos: Array<Despacho>;

    emitirErro: boolean;
    mensagemErro: string;

    formulario: FormGroup;

    codigoMenu: number;
    protocolo: Protocolo;

    dataSuspensao: string;

    empresa: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private atendimentoService: AtendimentoService,
        private protocoloService: ProtocoloService,
        private empresaService: EmpresaService,
        public dialog: MatDialog,
        private router: Router,
        private formBuilder: FormBuilder) {
    
        this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "";
        this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];
        this.cnpj = this.activatedRoute.snapshot.params["cnpj"];

        this.permitirAtendimento = true;

        this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];

        atendimentoService
            .permissoesParaAtendimento()
            .pipe(take(1))
            .subscribe( retorno => {
                this.permitirDelegar = retorno.delegar;
        })

        this.emitirErro = false;

        this.formulario = this.formBuilder.group({
            observacao: ["", [Validators.required, Validators.maxLength(20000)]],
        });
    }

    ngOnInit(): void {
    }

    preencherParaAtendimento(){

        this.empresaService
            .buscarParaAlteracao(
                localStorage.getItem("pId"), 
                this.cnpj, 
                this.numeroInscricao)
            .pipe(take(1))
            .subscribe( (retorno: Empresa) => {
                this.empresa = retorno.status;

                console.log(this.empresa);

                if (this.empresa == Constantes.STATUS_EMPRESA.Ativa || this.empresa == Constantes.STATUS_EMPRESA.Alteracao_Em_Andamento || this.empresa == Constantes.STATUS_EMPRESA.Suspensa) {
                    this.bloquearAtendimento(this.empresa);
                }
                /*else{
                    this.protocoloService
                    .buscarSuspensaoAtiva(this.numeroInscricao)
                    .pipe(take(1))
                    .subscribe( retorno => {
                        this.processoAdministrativo = retorno.processoAdministrativo;
                        this.dataInicio = retorno.dataInicio;
                        this.observacao = retorno.observacao;
                        this.bloquearAtendimento("1");

                        this.dataSuspensao = retorno.dataInicio;
                    });
                }*/
            })
        
    }

    bloquearAtendimento(statusEmpresa){

        this.observacao.disable();
        
        //let mensagemErro = "";

        //mensagemErro += "Empresa já está ativa.";
        
        //if (mensagemErro === "") {
            //this.emitirErro = false;
            //this.observacao.enable();
        //}
        //else{

            if(statusEmpresa == Constantes.STATUS_EMPRESA.Suspensa){
                Swal.fire("", "Empresa suspensa. Cancele a suspensão da empresa para reativa-la.", "error");
                this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
            }else if (statusEmpresa == Constantes.STATUS_EMPRESA.Ativa || statusEmpresa == Constantes.STATUS_EMPRESA.Alteracao_Em_Andamento){
                Swal.fire("", "Esse Negócio encontra-se ativo na base cadastral.", "error");                
                this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
            }
            
            
            this.emitirErro = true;
            //this.mensagemErro = mensagemErro;
        //}

        return false;
    }

    acaoBotaoReativar(){
        if(this.validarFormulario()){
            
                Swal.fire({
                    text: 'Tem certeza que deseja reativar esse negócio?',
                    icon: 'question',
                    customClass: {
                      confirmButton: 'btn btn-success',
                      cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: true,
                    showCancelButton: true,
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !Swal.isLoading()
                  })
                  .then((result) => {
                    if (!result.dismiss) {
                        this.executarComPareceres();
                    }
                  });
                
            

        }

    }
    
    executarComPareceres() {
        
        const dialogRef = this.dialog.open(IcadModalComponent, {
          width: '80%',
          height: '95%',
          maxWidth: 'none',
          maxHeight: 'none',
          closeOnNavigation: true,
          data: {
              titulo: "Pareceres",
              componente: "atendimento-pareceres",
              dados: [
                "fase",
                localStorage.getItem("pId"), 
                this.activatedRoute.snapshot.params["codigoOrgao"], 
                this.processo, 
                null,
                this.observacao.value
              ]
          }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
          if (result.parecerFinal != null) {
            this.observacao.setValue(result.parecerFinal);
            this.preencher();
                this.protocoloService
                    .gerarReativacao(this.protocolo)
                    .pipe(take(1))
                    .subscribe( retorno => {
                        if(retorno){
                            Swal.fire("", "Negócio reativado com sucesso! <br> Protocolo nº " + retorno.protocolo + ". Caro auditor, você efetuou a reativação desse negócio.", "success");
                        }
                        else{
                            Swal.fire("", "Erro ao tentar reativar.", "error");
                        }
                        this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
                    });
            }
        });
      }

    mensagemAcaoBotaoSuspender(retorno) {
        Swal.fire({
            title: retorno.titulo,
            html: retorno.mensagem,
            icon: retorno.status,
            willClose: () => {
                this.router.navigate([retorno.redirecionamento], {relativeTo: this.activatedRoute});
            }
        })
    }

    validarFormulario() {
  
        if ((this.dataSuspensao != '' && !this.permitirAtendimento)) {
            return false;
        }

        if (this.formulario.invalid) {
            let mensagemErro = "";

           if(this.observacao.invalid){
                mensagemErro += "Favor informar a observação.<br>"
            }

            if (mensagemErro === "") {
                this.emitirErro = false;
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErro = true;
            this.mensagemErro = mensagemErro;

            return false;
        }

        this.emitirErro = false;
        this.mensagemErro = "";
        
        return true;
    }

    preencher() {
        this.processo = new Processo();
        
        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        this.configuracao.processos
            .forEach(processo => {
                var tipo = this.protocolo.registro.tipoPessoa == "" ? "J" : this.protocolo.registro.tipoPessoa;

                if (processo.codigo === "V" && processo.tipo === "V") {
                    this.processo.cliente = processo.cliente;
                    this.processo.codigo = processo.codigo;
                    this.processo.descricao = processo.descricao;
                    this.processo.protocolo = processo.protocolo;
                    this.processo.tipo = processo.tipo;
                    this.processo.servicos = processo.servicos;
                    this.processo.servicos[0].codigo = 164;
                } 
            }
        );

        this.protocolo.processo = this.processo;
        this.protocolo.suspensao.observacao = this.observacao.value;
        
        //if(this.permitirAtendimento){
            var atendimentoAuxiliar = new Atendimento();
            atendimentoAuxiliar.parecer = Constantes.ACAO_ATENDIMENTO.Deferir;

            this.protocolo.viabilidade.atendimentos.push(atendimentoAuxiliar);
        //}

    }

    get observacao() {
        return this.formulario.get("observacao");
    }  
    set observacao(observacao) {
        this.formulario.get("observacao").setValue(observacao);
    }

}