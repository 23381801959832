import { OnInit, Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { FormValidator } from "src/app/components/validators/form-validator";
import { formatToCPFOrCNPJ, formatToPhone, formatToDate, isCNPJ, isCPF } from 'brazilian-values';
import { LoginService } from "src/app/services/login/login.service";
import { Login } from "src/app/model/login";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import { Constantes } from "src/app/model/constantes";
import { Documento } from "src/app/model/documento";
import { Telefone } from "src/app/model/telefone";
import { MaskPipe } from "ngx-mask";
import { Critica } from '../../../model/critica';
import { Protocolo } from '../../../model/protocolo';

@Component({
    selector: "app-form-responsavel-dados",
    templateUrl: "./form-responsavel-dados.component.html",
    styleUrls: ["./form-responsavel.component.scss"]
})
export class FormResponsavelDadosComponent implements OnInit {
    
    formulario: FormGroup;

    login: Login;

    estados = Constantes.ESTADOS;

    emitirCritica: boolean;
    mensagemCritica: string;

    protocolo: Protocolo;
    
    constructor(
        private formBuilder: FormBuilder,
        private loginService: LoginService,
        private mask: MaskPipe) {

        this.formulario = this.formBuilder.group({
            cpfCnpj: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(18), FormValidator.cpfCnpjValido()]],
            nome: ['', [Validators.required]],
            sexo: ['', [Validators.required]],
            nascimento: ['', [Validators.minLength(8), FormValidator.dataValida()]],
            email: ['', [Validators.required, Validators.email]],
            telefoneFixo: [''],
            telefoneComercial: [''],
            telefoneCelular: ['', [Validators.required, Validators.minLength(15)]],
            tipoDocumento: ['', [Validators.required]],
            numeroDocumento: ['', [Validators.required]],
            estadoDocumento: ['', [Validators.required]],
            emissaoDocumento: ['', [Validators.minLength(8), FormValidator.dataValida()]],

        });

        this.login = new Login();
        this.protocolo = new Protocolo();
        
        this.emitirCritica = false;
        this.mensagemCritica = "";
    }

    ngOnInit(): void {
    };

    dadosEmpresa(){
        this.sexo.clearValidators();
        this.nascimento.clearValidators();
        this.tipoDocumento.clearValidators();
        this.numeroDocumento.clearValidators();
        this.estadoDocumento.clearValidators();
        this.emissaoDocumento.clearValidators();
     }

     dadosAutonomo(){
        this.sexo.setValidators([Validators.required]);
        this.nascimento.setValidators([Validators.required, FormValidator.dataValida()]);
        this.tipoDocumento.setValidators([Validators.required]);
        this.numeroDocumento.setValidators([Validators.required]);
        this.estadoDocumento.setValidators([Validators.required]);
        this.emissaoDocumento.setValidators([Validators.required, FormValidator.dataValida()]);
     }

    verificaUsuario() {
        if (this.cpfCnpj.invalid)
            return false;
  
        let login = new Login();
        login.login = this.cpfCnpj.value.replace(/\D/g,"");

        this.loginService
            .buscarPorLogin(login)
            .pipe(take(1))
            .subscribe( (usuario: Login) => { 
                if (!usuario.login) {
                    return false;
                }
                Swal.fire("", "O CPF/CNPJ preenchido é igual ao CPF/CNPJ do Solicitante, os dados do sócio serão preenchidos automaticamente", "info");

                this.preencherCampos(usuario);

                return usuario;
            },
            (err) => {
                console.log('err', err);
                Swal.fire('', 'Não foi possível verificar o CPF / CNPJ.', 'error');
            }
        );
    }

    formatToCPFOrCNPJ(valor) {
        this.cpfCnpj.setValue(formatToCPFOrCNPJ(valor));
    }

    isCpf(valor) {
        return !isCNPJ(valor.replace(/\D/g,""));
    }
    
    formatToPhone(objeto) {
        objeto.value = formatToPhone(objeto.value);
    }

    preencherCampos(dados: Login) {
        this.limparCampos();

        this.cpfCnpj.setValue(formatToCPFOrCNPJ(dados.login.toString().replace(/\D/g,"")));
        this.nome.setValue(dados.nome);
        this.email.setValue(dados.emails[0]);
        dados.telefones.forEach( telefone => {
            let ddd = telefone.ddd.toString().replace(/\D/g,"") == "0" ? "" : telefone.ddd.toString().replace(/\D/g,"");
            let telefoneNumero = formatToPhone(ddd.concat(telefone.numero.toString().replace(/\D/g,"")));
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Residencial) {
                this.telefoneFixo.setValue(telefoneNumero);
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Comercial) {
                this.telefoneComercial.setValue(telefoneNumero);
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Celular) { 
                this.telefoneCelular.setValue(telefoneNumero);
            }
        });
        this.sexo.setValue(dados.sexo);
        this.nascimento.setValue(this.mask.transform(dados.nascimento.replace(/\D/g,""), "00/00/0000"));
        dados.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Rg || documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Rm) {
                this.tipoDocumento.setValue(documento.tipo);
                this.numeroDocumento.setValue(documento.numero);
                this.estadoDocumento.setValue(documento.estadoEmissor);
                this.emissaoDocumento.setValue(this.mask.transform(documento.dataEmissao.replace(/\D/g,""), "00/00/0000"));
            }
        });
        
    }

    preencher() {

        let documentoCPF = new Documento();
        documentoCPF.tipo = isCNPJ(this.cpfCnpj.value) ? Constantes.TIPO_DE_DOCUMENTO.Cnpj : Constantes.TIPO_DE_DOCUMENTO.Cpf;
        documentoCPF.nome = isCNPJ(this.cpfCnpj.value) ? Constantes.TIPO_DE_DOCUMENTO_DESCRICAO[Constantes.TIPO_DE_DOCUMENTO.Cnpj] : Constantes.TIPO_DE_DOCUMENTO_DESCRICAO[Constantes.TIPO_DE_DOCUMENTO.Cpf];
        documentoCPF.numero = this.cpfCnpj.value;

        let documentoRG = new Documento();
        documentoRG.tipo = this.tipoDocumento.value == Constantes.TIPO_DE_DOCUMENTO.Rg ? Constantes.TIPO_DE_DOCUMENTO.Rg : Constantes.TIPO_DE_DOCUMENTO.Rm;
        documentoRG.nome = this.tipoDocumento.value == Constantes.TIPO_DE_DOCUMENTO.Rg ? "RG" : "RM";
        documentoRG.numero = this.numeroDocumento.value;
        documentoRG.estadoEmissor = this.estadoDocumento.value;
        documentoRG.dataEmissao = this.mask.transform(this.emissaoDocumento.value.replace(/\D/g,""), "00/00/0000");
        
        let telefones = new Array<Telefone>();
        
        let telefone = new Telefone();
        telefone.tipo = Constantes.TIPO_DE_TELEFONE.Residencial;
        telefone.ddd = this.telefoneFixo.value.replace(/\D/g,"").slice(0,2);
        telefone.numero = this.telefoneFixo.value.replace(/\D/g,"").slice(-8);
        telefones.push(telefone);

        telefone = new Telefone();
        telefone.tipo = Constantes.TIPO_DE_TELEFONE.Comercial;
        telefone.ddd = this.telefoneComercial.value.replace(/\D/g,"").slice(0,2);
        telefone.numero = this.telefoneComercial.value.replace(/\D/g,"").slice(-8);
        telefones.push(telefone);

        telefone = new Telefone();
        telefone.tipo = Constantes.TIPO_DE_TELEFONE.Celular;
        telefone.ddd = this.telefoneCelular.value.replace(/\D/g,"").slice(0,2);
        telefone.numero = this.telefoneCelular.value.replace(/\D/g,"").slice(-9);
        telefones.push(telefone);

        this.login.documentos = [documentoCPF, documentoRG];
        this.login.login = this.cpfCnpj.value.replace(/\D/g,"");
        this.login.nome = this.nome.value;
        this.login.emails = [this.email.value];
        this.login.telefones = telefones;
        this.login.sexo = this.sexo.value;
        this.login.nascimento = this.mask.transform(this.nascimento.value.replace(/\D/g,""), "00/00/0000");
        if (isCPF(this.cpfCnpj.value.replace(/\D/g,""))) {
            this.login.tipo = Constantes.TIPO_PESSOA.Fisica;
        } else {
            this.login.tipo = Constantes.TIPO_PESSOA.Juridica;
        }
    }

    liberaBloqueiaCamposPorCritica(protocolo: Protocolo) {
        this.sexo.enable();
        this.nascimento.enable();
        this.email.enable();
        this.telefoneFixo.enable();
        this.telefoneComercial.enable();
        this.telefoneCelular.enable();
        this.tipoDocumento.enable();
        this.numeroDocumento.enable();
        this.estadoDocumento.enable();
        this.emissaoDocumento.enable();

        this.emitirCritica = false;
        this.mensagemCritica = "";

        if (protocolo.criticas.length) {
            this.mensagemCritica = Protocolo.exibirCriticasFormularioSemBloqueio(this.formulario, protocolo.criticas, "responsavel");
            
            if (this.mensagemCritica != "") {
                this.emitirCritica = true;
            }
        }
        
    }

    limparCampos() {
        this.cpfCnpj.setValue("");
        this.nome.setValue("");
        this.email.setValue("");
        this.telefoneFixo.setValue("");
        this.telefoneComercial.setValue("");
        this.telefoneCelular.setValue("");
        this.sexo.setValue("");
        this.nascimento.setValue("");
        this.tipoDocumento.setValue("");
        this.numeroDocumento.setValue("");
        this.estadoDocumento.setValue("");
        this.emissaoDocumento.setValue("");
        this.formulario.markAsUntouched();
    }

    get cpfCnpj() {
        return this.formulario.get("cpfCnpj");
    }  
    set cpfCnpj(cpfCnpj) {
        this.formulario.get("cpfCnpj").setValue(cpfCnpj);
    }

    get nome() {
        return this.formulario.get("nome");
    }  
    set nome(nome) {
        this.formulario.get("nome").setValue(nome);
    }

    get email() {
        return this.formulario.get("email");
    }  
    set email(email) {
        this.formulario.get("email").setValue(email);
    }

    get sexo() {
        return this.formulario.get("sexo");
    }  
    set sexo(sexo) {
        this.formulario.get("sexo").setValue(sexo);
    }

    get nascimento() {
        return this.formulario.get("nascimento");
    }  
    set nascimento(nascimento) {
        this.formulario.get("nascimento").setValue(nascimento);
    }

    get telefoneFixo() {
        return this.formulario.get("telefoneFixo");
    }  
    set telefoneFixo(telefoneFixo) {
        this.formulario.get("telefoneFixo").setValue(telefoneFixo);
    }

    get telefoneComercial() {
        return this.formulario.get("telefoneComercial");
    }  
    set telefoneComercial(telefoneComercial) {
        this.formulario.get("telefoneComercial").setValue(telefoneComercial);
    }

    get telefoneCelular() {
        return this.formulario.get("telefoneCelular");
    }  
    set telefoneCelular(telefoneCelular) {
        this.formulario.get("telefoneCelular").setValue(telefoneCelular);
    }

    get tipoDocumento() {
        return this.formulario.get("tipoDocumento");
    }  
    set tipoDocumento(tipoDocumento) {
        this.formulario.get("tipoDocumento").setValue(tipoDocumento);
    }

    get numeroDocumento() {
        return this.formulario.get("numeroDocumento");
    }  
    set numeroDocumento(numeroDocumento) {
        this.formulario.get("numeroDocumento").setValue(numeroDocumento);
    }

    get estadoDocumento() {
        return this.formulario.get("estadoDocumento");
    }  
    set estadoDocumento(estadoDocumento) {
        this.formulario.get("estadoDocumento").setValue(estadoDocumento);
    }

    get emissaoDocumento() {
        return this.formulario.get("emissaoDocumento");
    }  
    set emissaoDocumento(emissaoDocumento) {
        this.formulario.get("emissaoDocumento").setValue(emissaoDocumento);
    }
}