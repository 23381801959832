import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from "@angular/material/icon";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDividerModule } from "@angular/material/divider";

import { OperacaoRoutingModule } from './operacao-routing.module';
import { ProcessoComponent } from "./processo/processo.component";
import { GridModule } from "src/app/components/grid/grid.module";
import { AberturaEmpresaMeiComponent } from "./abertura/empresa-mei/abertura-empresa-mei.component";
import { AlteracaoEmpresaMeiComponent } from "./alteracao/empresa-mei/alteracao-empresa-mei.component";
import { OutrosModule } from "../outros/outros.module";
import { TodoModule } from "src/app/components/todo/todo.module";
import { PendenciaComponent } from "./pendencia/pendencia.component";
import { FicComponent } from "./fic/fic.component";
import { DetalhamentoComponent } from "./detalhamento/detalhamento.component";
import { AberturaAutonomoComponent } from './abertura/autonomo/abertura-autonomo.component';
import { AlertModule } from "src/app/components/alert/alert.module";
import { AlteracaoAutonomoComponent } from './alteracao/autonomo/alteracao-autonomo.component';
import { BaixaEmpresaMeiAutonomoComponent } from './baixa/empresa-mei-autonomo/baixa-empresa-mei-autonomo.component';
import { FaqComponent } from './faq/faq.component';
import { AutenticidadeAlvaraComponent } from './autenticidade-alvara/autenticidade-alvara.component';
import { InformacoesCadastroModule } from '../outros/informacoes-cadastro/informacoes-cadastro.module';
import { RecadastroEmpresaMeiComponent } from './recadastro/empresa-mei/recadastro-empresa-mei.component';
import { RecadastroAutonomoComponent } from "./recadastro/autonomo/recadastro-autonomo.component";
import { EmpresasComponent } from './empresas/empresas.component';
import { TimeLineModule } from '../outros/time-line/time-line.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InformacaoRedeSimModule } from '../outros/informacoes-cadastro/informacao-rede-sim/informacao-rede-sim.module';
import { AberturaEventoComponent } from './abertura/evento/abertura-evento.component';
import { InformacaoRedeSimPerguntasComponent } from '../outros/informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.component';
import { InformacaoRedeSimPerguntasModule } from '../outros/informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.module';
import { DebitoComponent } from './debito/debito.component';
import { MeusDamComponent } from './meus-dam/meus-dam.component';


@NgModule({
  declarations: [
    ProcessoComponent,
    PendenciaComponent,
    EmpresasComponent,
    AberturaEmpresaMeiComponent,
    AberturaEventoComponent,
    AlteracaoEmpresaMeiComponent,
    BaixaEmpresaMeiAutonomoComponent,
    FicComponent,
    DetalhamentoComponent,
    AberturaAutonomoComponent,
    AlteracaoAutonomoComponent,
    FaqComponent,
    AutenticidadeAlvaraComponent,
    RecadastroEmpresaMeiComponent,
    RecadastroAutonomoComponent,
    DebitoComponent,
    MeusDamComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatIconModule,
    MatStepperModule,
    MatDividerModule,
    MatCheckboxModule,
    OperacaoRoutingModule,
    GridModule,
    OutrosModule,
    TodoModule,
    AlertModule,
    InformacoesCadastroModule,
    InformacaoRedeSimModule,
    InformacaoRedeSimPerguntasModule,
  ],
  exports: [
    ProcessoComponent,
    FaqComponent,
    AutenticidadeAlvaraComponent,
    DebitoComponent,
    MeusDamComponent,
  ]
})
export class OperacaoModule { }
