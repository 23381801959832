<div class="layout-px-spacing-atendimento-alvara">
    <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="mail-box-container-historico-requisito">
                <div class="mail-overlay"></div>
                <div id="todo-inbox" class="accordion todo-inbox">
                    <div class="todo-box">                        
                        <div id="ct" 
                            class="todo-box-scroll-atendimento-alvara" 
                            [perfectScrollbar]="config" >
                            <div *ngFor="let historico of dados[0]; let i = index"
                                class="todo-item all-list">
                                <div class="todo-item-inner">
                                    <div class="todo-content-atendimento-alvara">
                                        <p class="meta-text mb-0" 
                                            [attr.data-todoHtml]="'<p>' + historico.data + ' - ' + historico.atendenteNome + ' - ' + historico.justificativa + '</p>'" >
                                            <strong>Data:</strong> {{ historico.timestamp }} - {{ historico.atendenteNome }}<br>
                                            <strong>Situação:</strong> {{situacaoAlvaraEmpresaDescricao[historico.situacaoEmpresa]}}<br>
                                            <strong>Ação:</strong> {{ historico.tipoDocumento == 'Alvará Definitivo' && historico.acaoAtendimentoDescricao == 'RENOVAR' ? 'GERAÇÃO' : historico.acaoAtendimentoDescricao | uppercase }} - {{ historico.tipoDocumento }} - {{ historico.validadeDocumento == '' ? 'INDETERMINADA' : historico.validadeDocumento }} - {{ historico.justificativa }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                                    
            </div>            
        </div>
    </div>
</div>