export class RequisitoRisco {
    cliente: string;
    codigo: string;
    descricao: string;
    grauDeRisco: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.descricao = "";
        this.grauDeRisco = "";
    }
}