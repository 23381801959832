import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { Constantes } from '../../../../model/constantes';

@Component({
  selector: 'app-historico-atendimento-alvara',
  templateUrl: './historico-atendimento-alvara.component.html',
  styleUrls: ['../atendimento-protocolo/atendimento-protocolo.component.scss']
})
export class HistoricoAtendimentoAlvaraComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};
  
  numeroInscricao: string;
  tipoEmpresa: string;
  situacaoAlvaraEmpresaDescricao = Constantes.SITUACAO_ALVARA_EMPRESA_DESCRICAO;

  @Output() selecao = new EventEmitter();
  @Input() dados: Array<any>;

  constructor(
    private activatedRoute: ActivatedRoute) {
    this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];
   }

  ngOnInit(): void {
  }

  acaoDoFiltro(valor) {
    var rex = new RegExp(valor, 'i');
    $('.todo-box .todo-item').hide();
    $('.todo-box .todo-item').filter(function() {
        return rex.test($(this).text());
    }).show();
}

}
