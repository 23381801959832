import { Component, OnInit, Input, ViewChild, Renderer2, EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { Protocolo } from "src/app/model/protocolo";
import { EmpresaService } from "src/app/services/empresa/empresa.service";
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


@Component({
  selector: 'app-atendimento-baixa',
  templateUrl: './atendimento-baixa.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoBaixaComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};

  protocolo: Protocolo;
  permitirAtendimento: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private empresaService: EmpresaService) {
    
      let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
      this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !meusNegocios);


   }

  ngOnInit(): void {}
  
}
