import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FooterComponent } from "./core/footer/footer.component";
import { SidebarComponent } from "./core/sidebar/sidebar.component";
import { SubHeaderComponent } from "./core/sub-header/sub-header.component";
import { BreadcrumbComponent } from "./core/sub-header/breadcrumb/breadcrumb.component";
import { SidebarEixoComponent } from "./core/sub-header/sidebar-eixo/sidebar-eixo.component";
import { HeaderComponent } from "./core/header/header.component";
import { EixosComponent } from "./core/header/eixos/eixos.component";
import { AjudaComponent } from "./core/header/ajuda/ajuda.component";
import { EmpresasEiconComponent } from "./core/header/empresas-eicon/empresas-eicon.component";
import { NotificacoesComponent } from "./core/header/notificacoes/notificacoes.component";
import { UsuarioComponent } from "./core/header/usuario/usuario.component";
import { PesquisaMenuComponent } from "./core/header/pesquisa-menu/pesquisa-menu.component";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { SpinnerComponent } from "./spinner/spinner.component";
import { AlertModule } from "./alert/alert.module";
import { CardModule } from "./card/card.module";
import { ModalModule } from "./modal/modal.module";
import { LoaderComponent } from "./loader/loader.component";
import { LoaderService } from "../services/loader/loader.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderInterceptor } from "../services/loader/loader.interceptor";
import { Ng2CompleterModule } from "ng2-completer";
import { GridModule } from "./grid/grid.module";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { JwtInterceptor } from "../services/authentication/jwt.interceptor";
import { TodoModule } from "./todo/todo.module";
import { PipeModule } from "../pipes/pipes.module";
import { HeaderPortalComponent } from "./core/header/header-portal.component";
import { NgbDropdownModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from "ngx-ui-loader";
import { TimeLineModule } from '../pages/outros/time-line/time-line.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#00A75D",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "rectangle-bounce",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#083D33",
  "fgsPosition": "bottom-right",
  "fgsSize": 60,
  "fgsType": "three-strings",
  "gap": 10,
  "logoPosition": "center-center",
  "logoSize": 300,
  "logoUrl": "assets/img/logos/_logo_iCad_Completo.svg",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  // "overlayColor": "rgba(162,198,23,0.35)",
  "overlayColor": "rgba(255,255,255,0.8)",
  "pbColor": "#006242",
  "pbDirection": "ltr",
  "pbThickness": 4,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};

const components = [
  FooterComponent,
  SidebarComponent,
  SubHeaderComponent,
  BreadcrumbComponent,
  SidebarEixoComponent,
  HeaderComponent,
  HeaderPortalComponent,
  EixosComponent,
  AjudaComponent,
  EmpresasEiconComponent,
  NotificacoesComponent,
  UsuarioComponent,
  BreadcrumbComponent,
  SpinnerComponent,
  LoaderComponent,
  PesquisaMenuComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    AlertModule,
    CardModule,
    ModalModule,
    GridModule,
    Ng2CompleterModule,
    TodoModule,
    PipeModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    TimeLineModule,
  ],
  exports: components,
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
  ],
})
export class ComponentsModule {}
