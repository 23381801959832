import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { MatCheckbox } from "@angular/material/checkbox";
import Swal from "sweetalert2";
import { DespachoFluxo } from "src/app/model/despachoFluxo";
import { Orgao } from "src/app/model/orgao";
import { filter, take } from 'rxjs/operators';
import { Constantes } from '../../../../model/constantes';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Despacho } from '../../../../model/despacho';
import { AtendimentoService } from 'src/app/services/operacao/atendimento.service';
import { pipe } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-atendimento-despachar',
  templateUrl: './atendimento-despachar.component.html',
  styleUrls: ['./atendimento-despachar.component.scss']
})
export class AtendimentoDespacharComponent implements OnInit {
  
  public config: PerfectScrollbarConfigInterface = {};

  @Output() selecao = new EventEmitter();
  
  tipoDespacho = Constantes.TIPO_DE_PENDENCIA;
  
  formulario: FormGroup;

  public despacho: Despacho;

  @Input() dados: Array<any>;

  eConfiguracaoFluxo: boolean = false;
  
  constructor(private formBuilder: FormBuilder,
    private atendimentoService: AtendimentoService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog) {
    this.despacho = new Despacho();

    this.formulario = formBuilder.group({});
  }

  ngOnInit(): void {
    this.dados[0].forEach((despacho: Despacho) => {
      despacho.fluxos.forEach((despachoFluxo: DespachoFluxo) => {
        despachoFluxo.orgaoOrigem.codigo = this.dados[1];
        this.formulario.addControl(`despacho_${ despachoFluxo.orgaoDestino.codigo }`, new FormControl(""));
      });
    });

    if (this.dados[4] != null) {
      this.eConfiguracaoFluxo = this.dados[4] == "CONFIGURAR-PROCESSOS"
    }
  }

  exigeJustificativa(checkbox: MatCheckbox, index, tipo){
    if (this.eConfiguracaoFluxo) {
      this.justificativaSimples(checkbox, index, tipo);
      return false;
    }

    if (checkbox.checked) {
      const dialogRef = this.dialog.open(IcadModalComponent, {
        width: '80%',
        height: '95%',
        maxWidth: 'none',
        maxHeight: 'none',
        closeOnNavigation: true,
        data: {
            titulo: "Pareceres",
            componente: "atendimento-pareceres",
            dados: [
              "requisito",
              localStorage.getItem("pId"), 
              this.dados[1], 
              this.dados[4],
              "P",
              tipo == "ALTERAR" ? this.dados[0][0].fluxos[index].observacao : ""
            ]
        }
      });
  
      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result.parecerFinal != null) {
          this.adicionarDespacho(result.parecerFinal, index);
        }
        else {
          if (tipo != "ALTERAR") {
            checkbox.checked = false;
            this.adicionarDespacho("", index);
          }
        }
      });
    }
    else {
      this.adicionarDespacho("", index);
    }
  }
  
  justificativaSimples(checkbox: MatCheckbox, index, tipo) {
    if (checkbox.checked) {
      Swal.fire({
        text: 'Qual o motivo para a inclusão desse uso?',
        icon: 'question',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then((result) => {
        if (result.value != null) {
          this.adicionarDespacho(result.value, index);
        }
        else {
          if (tipo != "ALTERAR") {
            checkbox.checked = false;
            this.adicionarDespacho("", index);
          }
        }
      })
    }
    else {
      this.adicionarDespacho("", index);
    }
  }

  despachar() {
    if (this.eConfiguracaoFluxo) {
      this.selecao.emit({ despachos: true});

      return false
    }

    var despachos = this.dados[0][0].fluxos.filter( despacho => despacho.observacao != "");

    if (!despachos.length) {
      Swal.fire("", "Favor informar o(s) despacho(s) que deseja.", "error");
      return false;
    }

    this.despacho.protocolo = this.dados[2] != null ? this.dados[2] : "";
    this.despacho.inscricao = this.dados[3] != null ? this.dados[3] : "";
    this.despacho.fluxos = despachos;

    this.atendimentoService
      .despachar(this.despacho)
      .pipe(take(1))
      .subscribe( retorno => {
        Swal.fire({
          title: retorno.titulo,
          html: retorno.mensagem,
          icon: retorno.status,
          width: 700,
          willClose: () => {
            location.reload();
          }
        });
      })

  }

  adicionarDespacho(parecerFinal, index) {
    this.dados[0][0].fluxos[index].observacao = parecerFinal;
  }

}
