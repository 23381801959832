<div class="row d-flex justify-content-center mb-70">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-body" *ngFor="let processo of listaDeProcessos; let i = index">
                <h5 [id]="processo" class="card-title">{{ processo }}</h5>
                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <div class="vertical-timeline-item vertical-timeline-element" *ngFor="let observacao of observacoesPorProcessos[i]">
                        <div [id]="observacao.observacao"> <span class="vertical-timeline-element-icon bounce-in"> <i class="badge badge-dot badge-dot-xl {{ observacao.avaliacaoClasse }}">&nbsp;</i> </span>
                            <div class="vertical-timeline-element-content bounce-in">
                                <h4 class="timeline-title"><b>{{ observacao.observacao }}</b></h4>
                                <p>{{ observacao.processo }} <br> {{ observacao.login.nome }}</p> <span class="vertical-timeline-element-date">{{ observacao.data }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>