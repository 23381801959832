export class Parecer {
    cliente: string;
    protocolo: string;
    codigo: string;
    codigoOrgao: string;
    codigoRequisito: string;
    fase: string;
    avaliacao: string;
    ordem: string;
    status: string;
    parecer: string;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.codigo = "";
        this.codigoOrgao = "";
        this.codigoRequisito = "";
        this.fase = "";
        this.avaliacao = "";
        this.ordem = "";
        this.status = "";
        this.parecer = "";
    }
}