<div class="row">
    <div class="card col-6 pl-0"> 
        <div class="card-header">
            <div class="row col-12">
                <div class="col-8">
                    <strong *ngIf="tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV'">Endereço do meu evento</strong>
                    <strong *ngIf="tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">Endereço do meu negócio</strong>
                </div>
                <div class="col-4 text-right">
                    <button
                        type="button" id="btn-copiar-endereco-empresarial"
                        matTooltip="Copiar"
                        matTooltipPosition="above"
                        (click)="enviarParaAreaDeTranferenciaEmpresarial()"
                        class="btn btn-sm">
                        <svg class="icone-adicionar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'copy' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="protocolo == null" class="card-body">
            <div class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text text-center">
                        Carregando...
                    </p>
                </div>
            </div>      
        </div>
        <div *ngIf="protocolo != null && protocolo.viabilidade.enderecos[0] != null" class="card-body">
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Cep</strong> 
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-cep-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].cep == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].cep')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].cep'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-cep-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].cep', protocolo.viabilidade.enderecos[0].cep, 'Cep')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>             
                        {{ protocolo.viabilidade.enderecos[0].cep == '' ? 'Não Cadastrado' : ( protocolo.viabilidade.enderecos[0].cep  | mask: '00000-000' ) }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Cadastro Imobiliário</strong> 
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-inscricao-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].inscricao == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].inscricao')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].inscricao'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-inscricao-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].inscricao', protocolo.viabilidade.enderecos[0].inscricao, 'Cadastro Imobiliário')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        {{ protocolo.viabilidade.enderecos[0].inscricao == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[0].inscricao }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Inscrição Imobiliária</strong> 
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-inscricao-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].inscricaoFormatada == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].inscricaoFormatada')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].inscricaoFormatada'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-inscricaoFormatada-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].inscricaoFormatada', protocolo.viabilidade.enderecos[0].inscricaoFormatada, 'Inscrição Imobiliária')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        {{ protocolo.viabilidade.enderecos[0].inscricaoFormatada == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[0].inscricaoFormatada }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Tipo</strong> 
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-tipo-logradouro-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].logradouro == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].tipoLogradouro')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].tipoLogradouro'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-tipoLogradouro-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].tipoLogradouro', protocolo.viabilidade.enderecos[0].tipoLogradouro, 'Tipo de Logradouro')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        {{ protocolo.viabilidade.enderecos[0].tipoLogradouro }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Logradouro</strong> 
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-logradouro-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].logradouro == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].logradouro')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].logradouro'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-logradouro-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].logradouro', protocolo.viabilidade.enderecos[0].logradouro, 'Logradouro')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        {{ protocolo.viabilidade.enderecos[0].logradouro }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Número</strong> 
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-numero-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].logradouro == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].numero')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].numero'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-numero-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].numero', protocolo.viabilidade.enderecos[0].numero, 'Número')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        {{ protocolo.viabilidade.enderecos[0].numero }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Compl.</strong>
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-complemento-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].complemento == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].complemento')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].complemento'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-complemento-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].complemento', protocolo.viabilidade.enderecos[0].complemento, 'Complemento')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        {{ protocolo.viabilidade.enderecos[0].complemento == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[0].complemento }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Ref.</strong>
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-ponto-referencia-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].pontoReferencia == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].pontoReferencia')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].pontoReferencia'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-pontoReferencia-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].pontoReferencia', protocolo.viabilidade.enderecos[0].pontoReferencia, 'Ponto de Referência')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        {{ protocolo.viabilidade.enderecos[0].pontoReferencia == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[0].pontoReferencia }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Bairro</strong>
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-bairro-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].bairro == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].bairro')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].bairro'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-bairro-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].bairro', protocolo.viabilidade.enderecos[0].bairro, 'Bairro')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        {{ protocolo.viabilidade.enderecos[0].bairro == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[0].bairro }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Cidade</strong>
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-cidade-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].cidade == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].cidade')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].cidade'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-cidade-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].cidade', protocolo.viabilidade.enderecos[0].cidade, 'Cidade')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        {{ protocolo.viabilidade.enderecos[0].cidade == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[0].cidade }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Estado</strong>
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-estado-empresarial"
                    [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[0].estado == ''"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[0].estado')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[0].estado'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-estado-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[0].estado', protocolo.viabilidade.enderecos[0].estado, 'Estado')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        {{ protocolo.viabilidade.enderecos[0].estado == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[0].estado }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha justify-content-start align-items-center">
                <div class="col-12">
                    <p class="card-text font-italic">                
                        {{ 
                            protocolo.viabilidade.enderecos[0].tipoBusca == 'C' ? '* O endereço foi declarado como Urbano e foi localizado por Cep' : 
                            protocolo.viabilidade.enderecos[0].tipoBusca == 'I' ? '* O endereço foi declarado como Urbano e foi localizado por Inscrição Imobiliária' : 
                            protocolo.viabilidade.enderecos[0].tipoBusca == 'L' ? '* O endereço foi declarado como Urbano e foi localizado por Logradouro' : 
                            protocolo.viabilidade.enderecos[0].tipoBusca == 'R' ? '* O endereço foi declarado como Rural' : 
                            protocolo.viabilidade.enderecos[0].tipoBusca == 'S' ? '* O endereço foi declarado como Sem Regularização' : '* O endereço foi declarado como Urbano'
                        }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    
    <div class="card col-6 pr-0"> 
        <div class="card-header">
            <div class="row col-12">
                <div class="col-8">
                    <strong *ngIf="tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV'">Endereço do requerente</strong>
                    <strong *ngIf="tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">Endereço para minha correspondência</strong>
                </div>
                <div class="col-4 text-right">
                    <button
                        type="button" id="btn-copiar-endereco-correspondencia"
                        matTooltip="Copiar"
                        matTooltipPosition="above"
                        (click)="enviarParaAreaDeTranferenciaCorrespondencia()"
                        class="btn btn-sm">
                        <svg class="icone-adicionar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'copy' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="protocolo == null" class="card-body">
            <div class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text text-center">
                        Carregando...
                    </p>
                </div>
            </div>      
        </div>
        <div *ngIf="protocolo != null && protocolo.viabilidade.enderecos[1] != null" class="card-body">
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Cep</strong> 
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-cep-empresarial"
                        [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[1].cep == ''"
                        [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.viabilidade.enderecos[1].cep')"
                        [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[1].cep')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].cep'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-cep-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[1].cep', protocolo.viabilidade.enderecos[1].cep, 'Cep')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>  
                        <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].cep'}).length && permiteCriticar
                                && (protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].cep'})[0].codOrgao == processo"
                            type="button" id="btn-criticar-cep-empresarial"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar('protocolo.viabilidade.enderecos[1].cep', protocolo.viabilidade.enderecos[1].cep, 'Cep')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>
                        {{ protocolo.viabilidade.enderecos[1].cep == '' ? 'Não Cadastrado' : ( protocolo.viabilidade.enderecos[1].cep  | mask: '00000-000' ) }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Tipo</strong> 
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-tipo-logradouro-empresarial"
                        [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[1].logradouro == ''"
                        [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.viabilidade.enderecos[1].tipoLogradouro')"
                        [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[1].inscricaoFormatada')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].inscricaoFormatada'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-inscricaoFormatada-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[1].inscricaoFormatada', protocolo.viabilidade.enderecos[1].inscricaoFormatada, 'Inscrição Imobiliária')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>  
                        <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].tipoLogradouro'}).length && permiteCriticar
                                && (protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].tipoLogradouro'})[0].codOrgao == processo"
                            type="button" id="btn-criticar-tipo-logradouro-empresarial"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar('protocolo.viabilidade.enderecos[1].tipoLogradouro', protocolo.viabilidade.enderecos[1].tipoLogradouro, 'Tipo de Logradouro')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>
                        {{ protocolo.viabilidade.enderecos[1].tipoLogradouro }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Logradouro</strong> 
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-logradouro-empresarial"
                        [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[1].logradouro == ''"
                        [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.viabilidade.enderecos[1].logradouro')"
                        [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[1].logradouro')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].logradouro'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-logradouro-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[1].logradouro', protocolo.viabilidade.enderecos[1].logradouro, 'Logradouro')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>
                        <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].logradouro'}).length && permiteCriticar
                                && (protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].logradouro'})[0].codOrgao == processo"
                            type="button" id="btn-criticar-logradouro-empresarial"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar('protocolo.viabilidade.enderecos[1].logradouro', protocolo.viabilidade.enderecos[1].logradouro, 'Logradouro')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>
                        <strong></strong>{{ protocolo.viabilidade.enderecos[1].logradouro }},
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Número</strong> 
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-numero-empresarial"
                        [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[1].logradouro == ''"
                        [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.viabilidade.enderecos[1].numero')"
                        [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[1].numero')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].numero'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-numero-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[1].numero', protocolo.viabilidade.enderecos[1].numero, 'Número')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].numero'}).length && permiteCriticar
                                && (protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].numero'})[0].codOrgao == processo"
                            type="button" id="btn-criticar-numero-empresarial"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar('protocolo.viabilidade.enderecos[1].numero', protocolo.viabilidade.enderecos[1].numero, 'Numero')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>
                        <strong></strong>{{ protocolo.viabilidade.enderecos[1].numero }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Compl.</strong>
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-complemento-empresarial"
                        [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[1].complemento == ''"
                        [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.viabilidade.enderecos[1].complemento')"
                        [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[1].complemento')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].complemento'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-complemento-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[1].complemento', protocolo.viabilidade.enderecos[1].complemento, 'Complemento')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>   
                        <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].complemento'}).length && permiteCriticar
                                && (protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].complemento'})[0].codOrgao == processo"
                            type="button" id="btn-criticar-complemento-empresarial"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar('protocolo.viabilidade.enderecos[1].complemento', protocolo.viabilidade.enderecos[1].complemento, 'Complemento')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>
                        {{ protocolo.viabilidade.enderecos[1].complemento == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[1].complemento }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Bairro</strong>
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-bairro-empresarial"
                        [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[1].bairro == ''"
                        [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.viabilidade.enderecos[1].bairro')"
                        [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[1].bairro')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].bairro'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-bairro-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[1].bairro', protocolo.viabilidade.enderecos[1].bairro, 'Bairro')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>
                        <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].bairro'}).length && permiteCriticar
                                && (protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].bairro'})[0].codOrgao == processo"
                            type="button" id="btn-criticar-bairro-empresarial"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar('protocolo.viabilidade.enderecos[1].bairro', protocolo.viabilidade.enderecos[1].bairro, 'Bairro')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>
                        {{ protocolo.viabilidade.enderecos[1].bairro == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[1].bairro }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Cidade</strong>
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-cidade-empresarial"
                        [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[1].cidade == ''"
                        [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.viabilidade.enderecos[1].cidade')"
                        [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[1].cidade')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].cidade'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-cidade-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[1].cidade', protocolo.viabilidade.enderecos[1].cidade, 'Cidade')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>
                        <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].cidade'}).length && permiteCriticar
                                && (protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].cidade'})[0].codOrgao == processo"
                            type="button" id="btn-criticar-cidade-empresarial"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar('protocolo.viabilidade.enderecos[1].cidade', protocolo.viabilidade.enderecos[1].cidade, 'Cidade')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>
                        {{ protocolo.viabilidade.enderecos[1].cidade == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[1].cidade }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-4">
                    <p class="card-text">
                        <strong>Estado</strong>
                    </p>
                </div>
                <div class="col-8">
                    <p class="card-text" id="lbl-estado-empresarial"
                        [class.valor-nao-cadastrado]="protocolo.viabilidade.enderecos[1].estado == ''"
                        [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.viabilidade.enderecos[1].estado')"
                        [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.viabilidade.enderecos[1].estado')">   
                        <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].estado'}).length && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-estado-empresarial"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia('protocolo.viabilidade.enderecos[1].estado', protocolo.viabilidade.enderecos[1].estado, 'Estado')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>
                        <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].estado'}).length && permiteCriticar
                                && (protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.viabilidade.enderecos[1].estado'})[0].codOrgao == processo"
                            type="button" id="btn-criticar-estado-empresarial"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar('protocolo.viabilidade.enderecos[1].estado', protocolo.viabilidade.enderecos[1].estado, 'Estado')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>
                        {{ protocolo.viabilidade.enderecos[1].estado == '' ? 'Não Cadastrado' : protocolo.viabilidade.enderecos[1].estado }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="card col-12 pl-0"> 
        <div class="form-row row-linha justify-content-start align-items-center">
            <div class="col-8">
                <p class="card-text" id="lbl-analise-entorno">
                    <button 
                        type="button" id="btn-analise-entorno"
                        (click)="analiseDeEntorno()"
                        class="btn btn-primary col-12">
                        <svg class="icone">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'map' }}"
                            ></use>
                        </svg>
                        Analise de Entorno - Clique aqui para abrir o mapa e analisar o entorno do negócio
                    </button>
                </p>
            </div>
            <div class="col-4">
                <p class="card-text" id="lbl-analise-entorno">
                    <button 
                        type="button" id="btn-analise-entorno"
                        (click)="visualizarMapa()"
                        class="btn btn-success col-12">
                        <svg class="icone">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'map' }}"
                            ></use>
                        </svg>
                        Visualizar Local
                    </button>
                </p>
            </div>
        </div>
        </div>
</div>
