import { MatSort, SortDirection } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginatorIntl, MatPaginator } from "@angular/material/paginator";
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';

import { Grid } from "src/app/model/grid";
import { GridProviderPaginacao } from "src/app/components/grid/grid-provider-paginacao";

import { MatDialog } from "@angular/material/dialog";
import { GridFiltro } from "src/app/model/gridFiltro";
import { Constantes } from '../../../../model/constantes';
import { formatToCPFOrCNPJ } from 'brazilian-values';
import { MascaraPipe } from '../../../../pipes/mascara.pipe';
import { SelectionModel } from '@angular/cdk/collections';
import { TaxaLancamentoParcela } from '../../../../model/taxaLancamentoParcela';
import Swal from "sweetalert2";
import { AuthenticationService } from '../../../../services/authentication/authentication.service';

@Component({
  selector: 'app-grid-parcelas',
  templateUrl: './grid-parcelas.component.html',
  styleUrls: ['./grid-parcelas.component.scss'],
  providers: [ 
      {
          provide: MatPaginatorIntl,
          useClass: GridProviderPaginacao
      }
  ]
})
export class GridParcelasComponent implements OnInit {
  
  grid: Grid;
  gridParcelaUnica: Grid;
  
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = ["numero","vencimento","subTotal","subTotalDesconto","subTotalMora","subTotalJuros","subTotalCorrecaoMonetaria","total","situacao","dataPagamento","totalBaixa","menu"];
  linhaSelecionada: SelectionModel<TaxaLancamentoParcela>;

  dataSourceParcelaUnica: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumnsParcelaUnica: string[] = ["numero","vencimento","subTotal","subTotalDesconto","subTotalMora","subTotalJuros","subTotalCorrecaoMonetaria","total","situacao","dataPagamento","totalBaixa","menu"];
  linhaSelecionadaParcelaUnica: SelectionModel<TaxaLancamentoParcela>;

  parcelaSituacao = Constantes.PARCELA_SITUACAO;
  parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input() exercicio: string;
  @Input() codigoLancamento: string;
  @Output() acaoMenuAtendimento = new EventEmitter();

  acaoAtendimento = Constantes.ACAO_ATENDIMENTO;

  mensagemDeBuscaParcelaUnica: string = "Não existem parcela única disponível.";
  mensagemDeBusca: string = "Não existem parcela(s) ativa(s).";

  currentUser: string = "";

  constructor(
    private mascara: MascaraPipe,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService) { 
      this.gridParcelaUnica = new Grid();
      this.gridParcelaUnica.filtro.push(new GridFiltro());
      
      this.grid = new Grid();
      this.grid.filtro.push(new GridFiltro());

      this.linhaSelecionadaParcelaUnica = new SelectionModel<TaxaLancamentoParcela>(true);
      this.linhaSelecionada = new SelectionModel<TaxaLancamentoParcela>(true);

      this.currentUser = this.authenticationService.currentUserValue.perfil;

      
    }

  ngOnInit(): void {

  }

  atualizarTabela() {
    this.dataSourceParcelaUnica = new MatTableDataSource(this.gridParcelaUnica.dados);
    this.linhaSelecionadaParcelaUnica = new SelectionModel<TaxaLancamentoParcela>(true);

    this.dataSource = new MatTableDataSource(this.grid.dados);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
    this.linhaSelecionada = new SelectionModel<TaxaLancamentoParcela>(true);
    this.sort.active = "vencimento";
    this.sort.direction = <SortDirection>"ASC".toLowerCase();

    this.dataSource.sort = this.sort;
  }

  formatToCPFOrCNPJ(valor) {
    return formatToCPFOrCNPJ(valor.toString());
  }

  formatToBRL(valor) {
      return this.mascara.transform(valor, 11);
  }

  aplicarFiltro(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  ordenacaoGrid(data, sort) {
    if (sort.active == null)
        return data;

    let campo = sort.active;
    
    if (campo == "adicionado") {
      data.sort( (item_1, item_2) => (new Date(item_1[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) > new Date(item_2[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (campo == "vencimento") {
        data.sort( (item_1, item_2) => (new Date(item_1[campo].split("/").reverse().toString()) > new Date(item_2[campo].split("/").reverse().toString()) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (campo == "inscricao") {
        data.sort( (item_1, item_2) => (item_1[campo] > item_2[campo] ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (typeof(data[0][campo]) == typeof("0")) {
        data.sort( (item_1, item_2) => (item_1[campo]).localeCompare(item_2[campo])  * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (typeof(data[0][campo]) == typeof(0)) {
        data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo])) ? 0 : item_1[campo] ) - ( isNaN(parseInt(item_2[campo])) ? 0 : item_2[campo] ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    
    return data;
  }

  imprimirBoleto(boletoUnico) {
    if (this.linhaSelecionadaParcelaUnica.isEmpty() && this.linhaSelecionada.isEmpty()) {
      Swal.fire("", "Para gerar uma guia de pagamento, deve ser selecionado uma ou mais parcelas.", "error");
      return false;
    }

    if (!this.linhaSelecionadaParcelaUnica.isEmpty()) {
      this.acaoMenuAtendimento.emit({ boletoUnico: boletoUnico, parcelas: this.linhaSelecionadaParcelaUnica.selected });
    }
    else if (!this.linhaSelecionada.isEmpty()) {
      this.acaoMenuAtendimento.emit({ boletoUnico: boletoUnico, parcelas: this.linhaSelecionada.selected });
    }
  }

  acaoImprimirComprovante(row) {
    this.acaoMenuAtendimento.emit({ comprovante: row });
  }

  acaoInformacaoBaixa(row) {
    this.acaoMenuAtendimento.emit({ informacoesBaixa: row, comprovante: row });
  }

  acaoMemoriaDeCalculoParcela(row) {
    this.acaoMenuAtendimento.emit({ memoriaDeCalculoParcela: row });
  }

  selecionarParcelaUnica(row) {
    this.linhaSelecionadaParcelaUnica.toggle(row);
  }

  selecionarParcela(row) {
    this.linhaSelecionada.toggle(row);
  }

}
