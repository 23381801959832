<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Zoneamento</strong>
        </div>
    </div>
    <div *ngIf="atendimentoConfiguracao == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="atendimentoConfiguracao" class="card-body p-0">
        <div class="row">
            <div [class.hidden]="!permitirAtendimento" class="row col-11 p-0 justify-content-start align-items-center">
                <select 
                        #zonaSelect
                        class="form-control form-control-sm"
                        name="slcZona" 
                        id="slcZona"                
                    >
                        <option value="">Selecione aqui o zoneamento</option>
                        <option *ngFor="let zona of atendimentoConfiguracao.viabilidade.zonaUsos" 
                            value="{{ zona.codigo }}"
                        >
                            {{ zona.nomeResumido }} - {{ zona.nome }}
                        </option>
                </select>
            </div>
            <div *ngIf="permitirAtendimento" class="row col-1 p-0 justify-content-end align-items-center">
                <button 
                    class="btn btn-outline-success btn-adiconar-card-header mr-0"
                    type="button" 
                    id="btnAdicionarZona"
                    (click)="acaoAdicionarZonaEscolhida(zonaSelect)">
                        <svg class="icone-adicionar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'plus' }}"
                            ></use>
                        </svg>
                </button>
            </div>
            <div class="col-12 p-0">
                <div>
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSourceZona" matSort>
                            <ng-container matColumnDef="codigo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-codigo"> Código </th>
                                <td mat-cell *matCellDef="let row" class="text-left"> {{ row.codigo }} </td>
                            </ng-container>
                        
                            <ng-container matColumnDef="nome">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-descricao"> Descrição </th>
                                <td mat-cell *matCellDef="let row"> {{ row.nomeResumido }} - {{ row.nome }} </td>
                            </ng-container>
                        
                            <ng-container matColumnDef="tipoDescricao">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-tipo"> Tipo </th>
                                <td mat-cell *matCellDef="let row"> {{ row.tipoDescricao }} </td>
                            </ng-container>
                        
                            <ng-container matColumnDef="acao">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-cidade"></th>
                                <td mat-cell *matCellDef="let row" class="text-right">
                                    <button *ngIf="permitirAtendimento" type="button" 
                                        matTooltip="Excluir"
                                        matTooltipPosition="above"
                                        [id]="'btnExcluirZona_' + row.codigo"
                                        class="btn btn-sm"
                                        (click)="acaoExcluirZonaEscolhida(row.codigo)">
                                        <svg class="icone-excluir">
                                            <use
                                                attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                                            ></use>
                                        </svg>
                                    </button>
                                </td>
                            </ng-container>        
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsZona"></tr>
                            <tr mat-row class="ajusteDeLinha" *matRowDef="let row; columns: displayedColumnsZona;"></tr>
                        </table>
                    </div>
                    <div *ngIf="!zonasAdicionadas.length"
                        class="card-sem-informacao">
                        <p class="card-text text-center">
                            Nenhum zoneamento adicionado.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Usos</strong>
        </div>
    </div>
    <div *ngIf="atendimentoConfiguracao == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="atendimentoConfiguracao" class="card-body p-0 mt-1">
        <div class="row align-items-start">
            <div class="row col-6 p-0 pr-1">
                <div [class.hidden]="!permitirAtendimento" class="row col-10 p-0 justify-content-end align-items-center">
                    <select 
                        #usoPermitidosSelect
                        class="form-control form-control-sm"
                        name="slcUsosPermitidos" 
                        id="slcUsosPermitidos"                
                    >
                        <option value="">Selecione aqui os Usos Permitidos</option>
                        <option *ngFor="let uso of atendimentoConfiguracao.viabilidade.usos" 
                            value="{{ uso.codigo }}"
                        >
                            {{ uso.nome | uppercase }}
                        </option>
                    </select>
                </div>  
                <div *ngIf="permitirAtendimento" class="row col-2 pr-0 justify-content-end align-items-center">
                    <button type="button" 
                        (click)="acaoBotaoAdicionarUsoPermitido(usoPermitidosSelect)"
                        id="btnAdicionarUsosPermitidos"
                        class="btn btn-outline-success btn-adiconar-card-header mr-0">
                        <svg class="icone-adicionar">
                            <use
                            attr.xlink:href="assets/sprite.svg#{{ 'plus' }}"
                            ></use>
                        </svg>
                    </button> 
                </div>
                <div class="col-12 p-0">
                    <div>&nbsp;</div>
                    <div>
                        <div class="table-container">
                            <table mat-table [dataSource]="dataSourceUsoPermitido" matSort>
                                <ng-container matColumnDef="nome">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow text-left" id="row-descricao"> Uso(s) permitidos adicionado(s) </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.nome }} </td>
                                </ng-container>
                            
                                <ng-container matColumnDef="acao">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-cidade"></th>
                                    <td mat-cell *matCellDef="let row" class="text-right">
                                        <button *ngIf="permitirAtendimento" type="button" 
                                            matTooltip="Excluir"
                                            matTooltipPosition="above"
                                            class="btn btn-sm"
                                            [id]="'btnExcluirUsosPermitidos_' + row.codigo"
                                            (click)="acaoBotaoExcluirUsoPermitido(row.codigo)">
                                            <svg class="icone-excluir">
                                                <use
                                                    attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                                                ></use>
                                            </svg>
                                        </button>
                                    </td>
                                </ng-container>        
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsUsoPermitido"></tr>
                                <tr mat-row class="ajusteDeLinha" *matRowDef="let row; columns: displayedColumnsUsoPermitido;"></tr>
                            </table>
                            <div *ngIf="!usosPermitidosAdicionados.length"
                                class="card-sem-informacao">
                                <p class="card-text text-center">
                                    Nenhum uso permitido adicionado.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div class="row col-6 p-0 pl-1">
                <div [class.hidden]="!permitirAtendimento" class="row col-10 p-0 justify-content-end align-items-center">
                    <select 
                        #usoPermissiveisSelect
                        class="form-control form-control-sm"
                        name="slcUsosPermissiveis" 
                        id="slcUsosPermissiveis"                
                    >
                        <option value="">Selecione aqui os Usos Permissíveis</option>
                        <option *ngFor="let uso of atendimentoConfiguracao.viabilidade.usos" 
                            value="{{ uso.codigo }}"
                        >
                            {{ uso.nome | uppercase }}
                        </option>
                    </select>
                </div>  
                <div *ngIf="permitirAtendimento" class="row col-2 pr-0 justify-content-end align-items-center">
                    <button type="button" 
                        (click)="acaoBotaoAdicionarUsoPermissivel(usoPermissiveisSelect)"
                        id="btnAdicionarUsosPermissiveis"
                        class="btn btn-outline-success btn-adiconar-card-header mr-0">
                        <svg class="icone-adicionar">
                            <use
                            attr.xlink:href="assets/sprite.svg#{{ 'plus' }}"
                            ></use>
                        </svg>
                    </button> 
                </div>
                <div class="col-12 p-0" >
                    <div>&nbsp;</div>
                    <div>
                        <div class="table-container">
                            <table mat-table [dataSource]="dataSourceUsoPermissivel" matSort>
                                <ng-container matColumnDef="nome">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow text-left" id="row-descricao"> Uso(s) permissíveis adicionado(s) </th>
                                    <td mat-cell *matCellDef="let row"> {{ row.nome }} </td>
                                </ng-container>
                            
                                <ng-container matColumnDef="acao">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-cidade"></th>
                                    <td mat-cell *matCellDef="let row" class="text-right">
                                        <button *ngIf="permitirAtendimento" type="button" 
                                            matTooltip="Excluir"
                                            matTooltipPosition="above"
                                            class="btn btn-sm"
                                            [id]="'btnExcluirUsosPermissiveis_' + row.codigo"
                                            (click)="acaoBotaoExcluirUsoPermissiveis(row.codigo)">
                                            <svg class="icone-excluir">
                                                <use
                                                    attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                                                ></use>
                                            </svg>
                                        </button>
                                    </td>
                                </ng-container>        
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsUsoPermissivel"></tr>
                                <tr mat-row class="ajusteDeLinha" *matRowDef="let row; columns: displayedColumnsUsoPermissivel;"></tr>
                            </table>
                            <div *ngIf="!usosPermissiveisAdicionados.length"
                                class="card-sem-informacao">
                                <p class="card-text text-center">
                                    Nenhum uso permissível adicionado.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>