import { Atendimento } from "./atendimento";

export class Fase {
    cliente: string;
    codigo: string;
    protocolo: string;
    atendimentos: Array<Atendimento>;
    servico: string;
    fase: string;
    situacao: string;
    situacaoDescricao: string;
    situacaoIcone: string;
    situacaoClasse: string;
    situacaoClasseTimeLine: string;
    dataInicio: string;
    dataFim: string;
    sequencia: string;
    codigoOrgao: string;
    codigoOrgaoDescricao: string;
    codigoOrgaoRetorno: string;
    codigoOrgaoRetornoDescricao: string;
    nomeProcesso: string;
    codigoMensagemPendencia: string;
    urlProcesso: string;
    codigoStatusPrevia: string;
    statusAvaliacao: string;
    codigoTipoFluxo: string;
    codigoTipoProcesso: string;
    codigoTipoAnalise: string;
    descricaoTipoAnalise: string;
    descricaoTipoAnaliseV2: string;
    situacaoAtual: string;
    dataSituacaoAtual: string;
    statusRecebeInscricao: string;
    statusAcompanhamentoPostura: string;
    tipoRetornoAcao: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.protocolo = "";
        this.atendimentos = new Array<Atendimento>();
        this.servico = "";
        this.fase = "";
        this.situacao = "";
        this.situacaoDescricao = "";
        this.situacaoIcone = "";
        this.situacaoClasse = "";
        this.situacaoClasseTimeLine = "";
        this.dataInicio = "";
        this.dataFim = "";
        this.sequencia = "";
        this.codigoOrgao = "";
        this.codigoOrgaoDescricao = "";
        this.codigoOrgaoRetorno = "";
        this.codigoOrgaoRetornoDescricao = "";
        this.nomeProcesso = "";
        this.codigoMensagemPendencia = "";
        this.urlProcesso = "";
        this.codigoStatusPrevia = "";
        this.statusAvaliacao = "";
        this.codigoTipoFluxo = "";
        this.codigoTipoProcesso = "";
        this.codigoTipoAnalise = "";
        this.descricaoTipoAnalise = "";
        this.descricaoTipoAnaliseV2 = "";
        this.situacaoAtual = "";
        this.dataSituacaoAtual = "";
        this.statusRecebeInscricao = "";
        this.statusAcompanhamentoPostura = "";
        this.tipoRetornoAcao = "";
    }
}