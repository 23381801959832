<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Requerente</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolo != null" class="card-body" [class.p-0]="semCardHeader">
        <div *ngIf="!dadosAuditor">
            <div *ngFor="let documento of protocolo.requerente.documentos | filtro: {campo: 'numero', valor: '', tipoValor: '!='}"
                class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>
                            {{ tipoDocumentoDescricao[documento.tipo] }}
                        </strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text" id="lbl-documento-requerente">
                        {{ 
                            documento.tipo == tipoDocumento.Cpf.toString() ? (documento.numero | mask: '000.000.000-00') :
                            documento.tipo == tipoDocumento.Cnpj.toString() ? (documento.numero | mask: '00.000.000/0000-00') : documento.numero
                        }}
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="dadosAuditor">
            <div
                class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>
                            CPF
                        </strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text" id="lbl-cpf-auditor-requerente">
                        {{ documentoUsuario }}
                    </p>
                </div>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong *ngIf="tipoEmpresa != 'EEV'">Nome</strong>
                    <strong *ngIf="tipoEmpresa == 'EEV'">Razão Social</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor" id="lbl-nome-auditor-requerente"
                    [class.valor-nao-cadastrado]="protocolo.requerente.nome == ''">
                    {{ protocolo.requerente.nome == '' ? 'Não Cadastrado' : protocolo.requerente.nome }}
                </p>
                <p class="card-text" *ngIf="dadosAuditor" id="lbl-nome-requerente">
                    {{ nomeUsuario }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>E-mail</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor" id="lbl-email-auditor-requerente"
                    [class.valor-nao-cadastrado]="protocolo.requerente.emails.length == 0 || protocolo.requerente.emails[0] == ''">
                    {{ 
                        protocolo.requerente.emails.length == 0 ? 'Não Cadastrado' : 
                        protocolo.requerente.emails[0] == '' ? 'Não Cadastrado' : protocolo.requerente.emails[0]
                    }}
                </p>
                <p class="card-text" *ngIf="dadosAuditor" id="lbl-email-requerente">
                    {{ emailUsuario }}
                </p>
            </div>
        </div>
        <div *ngFor="let telefone of protocolo.requerente.telefones"
            class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.hidden]="tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AO'">
            <div class="col-2">
                <p class="card-text">
                    <strong>
                        {{ 
                            telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                            telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 
                            telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 'Telefone Recado'
                        }}
                    </strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" [id]="'lbl-telefone-' + telefone.tipo +'-requerente'"
                    [class.valor-nao-cadastrado]="telefone.numero == ''">
                    {{ 
                        telefone.numero == '' ? 'Não Cadastrado' : 
                        (telefone.ddd + telefone.numero).length == 10 ? (telefone.ddd + telefone.numero | mask: '(00) 0000-0000') : 
                        (telefone.ddd + telefone.numero).length > 10 ? (telefone.ddd + telefone.numero | mask: '(00) 00000-0000') : telefone.ddd + telefone.numero
                    }}
                </p>
            </div>
        </div>
        <div *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'BA' && tipoEmpresa != 'EEV'" class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Órgão de Registro</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-orgao-requerente"
                    [class.valor-nao-cadastrado]="protocolo.registro.orgaoNome == ''">
                    {{ protocolo.registro.orgaoNome == '' ? 'Não Cadastrado' : protocolo.registro.orgaoNome }}
                </p>
            </div>
        </div>
        <div *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'BA' && tipoEmpresa != 'EEV'" class="form-row row-linha justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Natureza Jurídica</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-natureza-requerente"
                    [class.valor-nao-cadastrado]="protocolo.registro.naturezaJuridica.nome == ''">
                    {{ protocolo.registro.naturezaJuridica.codigo == '' ? '' : protocolo.registro.naturezaJuridica.codigo + ' - ' }}
                    {{ protocolo.registro.naturezaJuridica.nome == '' ? 'Não Cadastrado' : protocolo.registro.naturezaJuridica.nome }}
                </p>
            </div>
        </div>
    </div>
</div>