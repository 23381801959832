import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class SocioService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarPorCpfCnpj(cliente, CpfCnpj) {
        return this.http.get<any>(
        `${ environment.icadUrl }/socio/buscarPorCpfCnpj/${ cliente }/${ CpfCnpj }`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorProtocolo(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/socio/buscarPorProtocolo/${ cliente }/${ protocolo }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorInscricao(cliente, inscricao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/socio/buscarPorInscricao/${ cliente }/${ inscricao }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
}