export class Escolaridade {
    cliente: string;
    codigo: string;
    nome: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.nome = "";
    }
}