import { Component, Input, OnInit } from '@angular/core';
import { Protocolo } from "src/app/model/protocolo";
import { Constantes } from "src/app/model/constantes";
import { AcompanhamentoService } from '../../../../services/operacao/acompanhamento.service';
import { take } from 'rxjs/operators';
import { ProtocoloAcompanhamento } from '../../../../model/protocoloAcompanhamento';

@Component({
  selector: 'app-informacao-situacao',
  templateUrl: './informacao-situacao.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoSituacaoComponent implements OnInit {

  @Input() protocoloAcompanhamento: ProtocoloAcompanhamento;
  @Input() tipoEmpresa: string = "";

  nomeUsuario: string;
  documentoUsuario: string;
  dataAtual: Date;
  emailUsuario: string;
  dadosAuditor : boolean;
  tipoDeEmpresa: string;

  constructor(private acompanhamentoService: AcompanhamentoService) {}

  ngOnInit(): void {
  }

  preencher(protocolo) {
    this.acompanhamentoService
        .acompanharPorProtocoloParaAtendimento(protocolo)
        .pipe(take(1))
        .subscribe( retorno => {
          retorno.regularizacoes.forEach(dados => {
            this.protocoloAcompanhamento = dados;
          });
          retorno.cancelados.forEach(dados => {
              this.protocoloAcompanhamento = dados;

          });
          retorno.concluidos.forEach(dados => {
              this.protocoloAcompanhamento = dados;

          });
        });
            
  }

}
