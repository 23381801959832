<div *ngIf="dados[0].observacoes != null" class="card-body p-0" >
    <div class="row col-12 ps div-observacoes p-0" [perfectScrollbar]="config">
        <div *ngFor="let observacao of dados[0].observacoes; let i = index" class="row col-12 p-0 pt-2 pb-2 linha-grid-divisao">
            <div class="row col-12 p-0 justify-content-start align-items-start">
                <div class="row col-12 row-linha-observacao">
                    <div class="col-2 p-0">
                        <p class="meta-text mb-0 w-100" >
                            <b>Situação</b>
                        </p>
                    </div>
                    <div class="col-10 p-0">
                        <p class="meta-text mb-0 w-100">
                            <button type="button" 
                                class="btn btn-sm btn-informacao-atividade pt-0 pb-0 mb-0"
                                [class.btn-success]="observacao.avaliacao == 'S' || observacao.avaliacao == tipoDePendenciaLicenciamento.Deferido || observacao.avaliacao == tipoDePendenciaLicenciamento.Documento_Liberado || observacao.avaliacao == tipoDePendenciaLicenciamento.Documento_Gerado"
                                [class.btn-secondary]="observacao.avaliacao == '' || observacao.avaliacao == 'NA' || observacao.avaliacao == 'PA' || observacao.avaliacao == tipoDePendenciaLicenciamento.Bloqueado"
                                [class.btn-danger]="observacao.avaliacao == 'N' || observacao.avaliacao == 'C' || observacao.avaliacao == tipoDePendenciaLicenciamento.Indeferido || observacao.avaliacao == tipoDePendenciaLicenciamento.Documento_Bloqueado"
                                [class.btn-dark]="observacao.avaliacao == 'R' || observacao.avaliacao == tipoDePendenciaLicenciamento.Pendente_Orgao"
                                [class.btn-warning]="observacao.avaliacao == 'P' || observacao.avaliacao == 'U' || observacao.avaliacao == tipoDePendenciaLicenciamento.Pendente_Usuario || observacao.avaliacao == tipoDePendenciaLicenciamento.Licenca_Prorrogada">
                                {{ 
                                    observacao.avaliacao == 'S' ? 'DEFERIDO' : 
                                    observacao.avaliacao == 'N' ? 'INDEFERIDO' :
                                    observacao.avaliacao == 'P' || observacao.avaliacao == 'U' ? 'PENDENTE' :
                                    observacao.avaliacao == 'C' ? 'CANCELADO' : 
                                    observacao.avaliacao == 'R' ? 'REAVALIAÇÃO' : 
                                    observacao.avaliacao == 'PA' ? 'RESPOSTA' : 
                                    observacao.avaliacao == 'NA' ? 'NÃO AVALIADO' : 
                                    tipoDePendenciaLicenciamentoDescricao[observacao.avaliacao] != null ? (tipoDePendenciaLicenciamentoDescricao[observacao.avaliacao] | uppercase) : 'NÃO AVALIADO'
                                }}
                                {{
                                    observacao.tipo != '' ? ' - ' + (tipoDePendenciaDescricao[observacao.tipo] | uppercase) : ''
                                }}
                            </button>{{ observacao.data }} - {{ observacao.login.nome == '' ? 'NÃO INFORMADO' : observacao.login.nome }}
                        </p>
                    </div>
                </div>
                <div class="row col-12 row-linha-observacao">
                    <div class="col-2 p-0">
                        <p class="meta-text mb-0 w-100" >
                            <b>Observação</b>
                        </p>
                    </div>
                    <div class="col-10 p-0">
                        <p class="meta-text mb-0 w-100" [innerHtml]="observacao.observacao.replaceAll('\r\n','<br>').replaceAll('\n','<br>').replaceAll('\r','<br>')">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 pt-4 pb-4" *ngIf="!dados[0].observacoes.length">
        <p class="card-text text-center">
            Não existe histórico para esse requisito.
        </p>
    </div>
</div>