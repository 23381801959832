import { OnInit, Component, Output, EventEmitter, ViewChild, Input, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormValidator } from "src/app/components/validators/form-validator";
import { formatToCPFOrCNPJ, isCPF, isCNPJ } from "brazilian-values";
import { Socio } from "src/app/model/socio";
import { Login } from "src/app/model/login";
import { LoginService } from "src/app/services/login/login.service";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import { SocioService } from "src/app/services/socio/socio.service";
import { SocioRegra } from "src/app/model/socioRegra";
import { MascaraPipe } from "src/app/pipes/mascara.pipe";
import { MatRadioButton } from "@angular/material/radio";
import { abort } from "process";
import { FormEnderecoComponent } from "../form-endereco/form-endereco.component";
import { FormResponsavelDadosComponent } from "./form-responsavel-dados.component";
import { Constantes } from "src/app/model/constantes";
import { ActivatedRoute } from "@angular/router";
import { Protocolo } from '../../../model/protocolo';
import { Critica } from '../../../model/critica';

@Component({
    selector: "app-form-responsavel",
    templateUrl: "./form-responsavel.component.html",
    styleUrls: ["./form-responsavel.component.scss"]
})
export class FormResponsavelComponent implements OnInit{

    @Output() renomearBotao = new EventEmitter();
    
    @ViewChild(FormResponsavelDadosComponent)
    formResponsavelDados: FormResponsavelDadosComponent;

    @ViewChild(FormEnderecoComponent)
    formEndereco: FormEnderecoComponent;
    
    formulario: FormGroup;

    loginEncontrado: Socio;
    capitalSocialMatriz: string;
    socioRegras: Array<SocioRegra>;
    socios: Array<Socio>;
    protocolo: Protocolo;
    
    emitirCritica: boolean;
    mensagemCritica: string = "";
    emitirErro: boolean;
    mensagemErro: string;
    editarResponsavel: boolean;
    obrigatorioContador: boolean;
    termoResponsavelContabil: boolean;
    possuiContador: boolean;

    tipoEmpresa: string;
    
    constructor(
        private formBuilder: FormBuilder,
        private loginService: LoginService,
        private socioService: SocioService,
        private activatedRoute: ActivatedRoute,
        private mascara: MascaraPipe) {

        this.tipoEmpresa = this.tipoEmpresa == null || this.tipoEmpresa == "" ? this.activatedRoute.snapshot.data["tipoEmpresa"] : this.tipoEmpresa;
        
        this.formulario = formBuilder.group({
            controleValidade: ["", [Validators.required]],
            cpfCnpjResponsavelBusca: ['', [Validators.minLength(14), Validators.maxLength(18), FormValidator.cpfCnpjValido()]],
        });

        this.capitalSocialMatriz = "0,00";
        this.socios = new Array<Socio>();
        this.protocolo = new Protocolo();

        this.emitirErro = false;
        this.editarResponsavel = false;
        this.obrigatorioContador = false;
        this.termoResponsavelContabil = false;
        this.possuiContador = false;
    }

    ngOnInit(): void {
    }
    
    formatToCPFOrCNPJ(valor) {
      this.cpfCnpjResponsavelBusca.setValue(formatToCPFOrCNPJ(valor));
    }

    formatToCPF(valor) {
        this.cpfCnpjResponsavelBusca.setValue(formatToCPFOrCNPJ(valor));
    }

    isCpf(valor) {
        return !isCNPJ(valor.replace(/\D/g,""));
    }

    htmlFormatToCPFOrCNPJ(valor) {
        return formatToCPFOrCNPJ(valor.replace(/\D/g,""));
    }

    buscar() {
        this.socios.forEach( socio => {
            if (socio.login.login == this.cpfCnpjResponsavelBusca.value.replace(/\D/g,"")) {
                Swal.fire("", "O CPF/CNPJ já se encontra adicionado na lista de responsáveis.", "info");
                this.cpfCnpjResponsavelBusca.setValue("");
            }
        });
        
        if (this.cpfCnpjResponsavelBusca.invalid  || this.cpfCnpjResponsavelBusca.value == "")
            return false;

        let login = this.cpfCnpjResponsavelBusca.value.replace(/\D/g,"");

        this.loginEncontrado = new Socio();

        this.loginService
            .buscarPorLoginQuadroSocietario(login)
            .pipe(take(1))
            .subscribe( usuario => {
                if (usuario.possuiCadastro) {
                    this.loginEncontrado.login = usuario;
                }
                else {
                    this.buscarCadastroSocio(localStorage.getItem("pId"), login);
                }

                // mudar mensagem para indicar obrigatoriedade de contador
                if(!this.loginEncontrado.login.contador && (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo)){
                    Swal.fire('', 'Necessário adicionar um contador', 'error');
                    return false;
                }
                

                this.limparFormularios();
                this.formResponsavelDados.login = this.loginEncontrado.login;
                this.formResponsavelDados.cpfCnpj.setValue(formatToCPFOrCNPJ(login.toString()));
                this.formResponsavelDados.cpfCnpj.disable();
                this.formResponsavelDados.nome.setValue(this.loginEncontrado.login.nome);
                this.formResponsavelDados.email.setValue(this.loginEncontrado.login.emails[0]);
                if(!isCPF(this.formResponsavelDados.cpfCnpj.value)){
                    this.formResponsavelDados.dadosEmpresa();
                }
                else{
                    this.formResponsavelDados.dadosAutonomo();
                }
                this.verificaUsuario(login);
                this.atualizarCriticas();
                this.toggle();
                
            },
            (err) => {
                console.log('err', err);
                Swal.fire('', 'Não foi possível verificar o CPF / CNPJ.', 'error');
            }
        );
    }

    buscarCadastroSocio(cliente, cpfCnpj) {
        this.socioService
            .buscarPorCpfCnpj(cliente, cpfCnpj)
            .pipe(take(1))
            .subscribe( usuario => {
                if (!usuario.login) {
                    this.loginEncontrado.login = usuario;
                }
            })
    }

    verificaUsuario(login) {
      
        if (this.cpfCnpjResponsavelBusca.invalid)
            return false;

        this.loginService
            .buscarPorLogin(login)
            .pipe(take(1))
            .subscribe( (usuario: Login) => {
                if (!usuario.login) {
                    return false;
                }
                Swal.fire("", "O CPF/CNPJ preenchido é igual ao CPF/CNPJ do Solicitante, os dados do sócio serão preenchidos automaticamente", "info");

                this.formResponsavelDados.preencherCampos(usuario);

                usuario.enderecos[0].cliente = localStorage.getItem("pId");
                usuario.enderecos[0].tipoBusca = "C";
                usuario.enderecos[0].tipoEndereco = Constantes.TIPO_DE_ENDERECO.Cadastro;
                this.formEndereco.preencherCampos(usuario.enderecos[0]);

                return usuario;
            },
            (err) => {
                console.log('err', err);
                Swal.fire('', 'Não foi possível verificar o CPF / CNPJ.', 'error');
            }
        );
    }

    calcularPercentualTotal() {
        let percentualTotal = "0";
        let capitalSocialTotal = 0;

        this.socios.forEach( socio => {
            capitalSocialTotal += parseFloat(socio.participacao.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2"));
        })

        if (capitalSocialTotal != 0 && this.capitalSocialMatriz != "0,00")
            percentualTotal = ((capitalSocialTotal * 100) / parseFloat(this.capitalSocialMatriz.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2"))).toFixed(10);
        
        return percentualTotal;
    }

    calcularPercentual(index) {
        let percentual = "0";
        let capitalSocial = parseFloat(this.socios[index].participacao.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2"));
        
        if (capitalSocial != 0 && this.capitalSocialMatriz != "0,00")
            percentual = ((capitalSocial * 100) / parseFloat(this.capitalSocialMatriz.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2"))).toFixed(10);
        
        return percentual;
    }

    ajusteDePercentual(percentual) {
        return parseFloat(percentual).toFixed(0)
    }

    acaoSalvarSocio() {
        this.formResponsavelDados.preencher();
        this.formEndereco.preencherEndereco();

        let index = -1;
        let socio = new Socio();
        Object.assign(socio.login, this.formResponsavelDados.login);
        Object.assign(socio.login.enderecos, [this.formEndereco.endereco]);
        
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo){
            socio.contador = true;
            this.socios = [socio];
        }

        this.socios.forEach( (socioMap, i) => {
            if (socioMap.login.login == socio.login.login) {
                index = i
            }
            if (socio.contador) {
                this.possuiContador = true;
            }
        })

        if (index == -1) {
            this.socios.push(socio);
        }
        else {
            this.socios[index].login = socio.login;
        }
    }

    acaoExcluirSocioEscolhido(index) {
        if (this.socios[index].contador) {
            this.possuiContador = false;
        }
        this.socios.splice(index, 1);
    }

    acaoEditarSocioEscolhido(index) {
        this.limparFormularios();
        this.formResponsavelDados.preencherCampos(this.socios[index].login);
        this.formEndereco.preencherCampos(this.socios[index].login.enderecos[0]);
        this.formResponsavelDados.cpfCnpj.disable();
        this.atualizarCriticas();
        this.toggle();
    }

    acaoRadioResponsavelLegal(index) {
        this.socios.forEach( (socio, i) => {
            socio.responsavelLegal = false;
            if (index == i)
                socio.responsavelLegal = true;
        })
    }

    acaoRadioContador(index) {
        if(!this.socios[index].login.contador){
            return false;
        }
        this.socios.forEach( (socio, i) => {
            socio.contador = false;
            if (index == i) {
                socio.contador = true;
                this.possuiContador = true;
                Swal.fire("", `Caro contribuinte, atenção quanto a qualificação do contador, caso o contador não seja um sócio, desconsiderar o campo qualificação.`, "warning");            

            }
        })
    }

    acaoContadorDesmarcar(contadorRadio, index) {
        contadorRadio.checked = false;
        this.socios[index].contador = false;
        this.possuiContador = false;
    }

    acaoQualificacaoSelect(qualificacao, index, responsavelLegal: MatRadioButton, inputCapitalSocial) {
        this.socios[index].qualificacao = qualificacao;

        if (qualificacao == "") {
            responsavelLegal.checked = false;
        }

        this.socioRegras.forEach( regra => {
            if (regra.qualificacao == qualificacao) {
                if ((this.tipoEmpresa != Constantes.TIPO_EMPRESA.Mei && !regra.permiteCnpj && this.socios[index].login.documentos[0].tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj) ||
                    (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei && !regra.permiteCnpjMei && this.socios[index].login.documentos[0].tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj)) 
                {
                    this.socios[index].qualificacao = "";
                    this.socios[index].responsavelLegal = false;
                    this.socios[index].participacao = "0,00";
                    inputCapitalSocial.value = "0,00";
                    inputCapitalSocial.disabled = true;
                    responsavelLegal.checked = false;

                    (<HTMLSelectElement>document.getElementById(`qualificacao_${ index }`)).value = "";
                    Swal.fire("", `Caro contribuinte, a qualificação ${ regra.naturezaPessoaNome } não é permitida para CNPJ.`, "error");
                    return false;
                }

                this.socios[index].qualificacaoNome = regra.naturezaPessoaNome;
                responsavelLegal.disabled = false;
                inputCapitalSocial.disabled = false;
                if (!regra.responsavelLegal) {
                    this.socios[index].responsavelLegal = false;
                    responsavelLegal.checked = false;
                    responsavelLegal.disabled = true;
                }
                if (!regra.temParticipacao) {
                    this.socios[index].participacao = "0,00";
                    inputCapitalSocial.value = "0,00";
                    inputCapitalSocial.disabled = true;
                }
            }
        });
    }

    acaoCapitalSocialInput(campo, index) {
        let percentualAtual = this.mascara.transform(parseFloat(this.socios[index].participacao.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2")).toFixed(2), 11);
        campo.value = this.mascara.transform(campo.value, 11);
        this.socios[index].participacao = parseFloat(campo.value.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2")).toFixed(2);

        if (parseFloat(this.calcularPercentualTotal()) > 100) {
            Swal.fire("", "A soma de participação de todos os sócios chegou ao total do capital social.", "info");
            campo.value = percentualAtual;
        }

        campo.value = this.mascara.transform(campo.value, 11);
        this.socios[index].participacao = parseFloat(campo.value.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2")).toFixed(2);
    }

    acaoTermoResponsavelContabil(termoResponsavelContabil) {
        this.termoResponsavelContabil = termoResponsavelContabil.checked;
    }

    toggle() {
        if (this.formResponsavelDados.nome.value != "") {
            this.formResponsavelDados.nome.disable();
        }
        this.editarResponsavel = !this.editarResponsavel;
        
        if (this.mensagemCritica != "") {
            this.emitirCritica = !this.editarResponsavel;
        }
        
        this.renomearBotao.emit({ renomear: true});
    }

    limparFormularios() {
        this.cpfCnpjResponsavelBusca.setValue("");
        this.formResponsavelDados.limparCampos();
        this.formResponsavelDados.nome.enable();
        this.formEndereco.limparCampos();
        this.formEndereco.liberaBloqueiaCampos();
    }

    validarFormulario() {
        this.controleValidade.setValue("");

        if (this.editarResponsavel) {
            this.formResponsavelDados.formulario.markAllAsTouched();
            this.formEndereco.formulario.markAllAsTouched();
            
            if (this.formResponsavelDados.formulario.valid && this.formEndereco.formulario.valid) {
                this.emitirErro = false;
                this.mensagemErro = "";
                this.acaoSalvarSocio();
                this.toggle();
            }
            else {
                
                let mensagemErro = "";
            
                if (this.formResponsavelDados.formulario.invalid) {
                    mensagemErro += "Favor corrigir os campos nos dados do responsável.<br>"
                }
                
                if (this.formEndereco.formulario.invalid) {
                    mensagemErro += "Favor corrigir os campos de endereço do responsável.<br>"
                }

                Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

                this.emitirErro = true;
                this.mensagemErro = mensagemErro;
                
                Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");
                return false;
            }
            return false;
        }
        else {

            if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo){
                if(this.socios.length){
                    this.controleValidade.setValue(true);
                    return true; 
                }
                else{
                    if (!this.obrigatorioContador || this.termoResponsavelContabil){
                        this.controleValidade.setValue(true);
                        return true;
                    }
                    Swal.fire("", "Para continuar o processo, é necessário informar o contador.", "error");
                }
                return false;
            }

            let possuiResponsavelInvalido = false; 
            let mensagemResponsavelInvalido = "";

            let possuiResponsavelLegal = false;
            let existeQualificacaoRegra = false;
            let socioSemQualificacao = false;
            let possuiContador = false;
            let participacaoTotal = 0;

            this.socios.forEach( (socio, index) => {
                if (!isCPF(socio.login.documentos[0].numero) && !isCNPJ(socio.login.documentos[0].numero)) {
                    mensagemResponsavelInvalido = `Para continuar o processo, é necessário remover o responsável que está com o documento inválido:<br><br><b>${ socio.login.nome }</b><br><br>Sendo necessário esse responsável, adicione ele novamente após a remoção.`;
                    possuiResponsavelInvalido = true;
                }

                existeQualificacaoRegra = false;

                socio.participacao = parseFloat(socio.participacao.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2")).toFixed(2);

                this.socioRegras.forEach( regra => {
                    if (socio.qualificacao == regra.qualificacao) {
                        existeQualificacaoRegra = true;
                    }

                    if (regra.qualificacao == socio.qualificacao) {
                        if ((this.tipoEmpresa != Constantes.TIPO_EMPRESA.Mei && !regra.permiteCnpj && socio.login.documentos[0].tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj) ||
                            (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei && !regra.permiteCnpjMei && socio.login.documentos[0].tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj)) 
                        {
                            socio.qualificacao = "";
                            socio.participacao = "0,00";
                            socio.responsavelLegal = false;
                            (<HTMLSelectElement>document.getElementById(`qualificacao_${ index }`)).value = "";
                        }
                    }
                });
    
                if (!existeQualificacaoRegra) {
                    socio.qualificacao = "";
                }

                if (socio.responsavelLegal) {
                    possuiResponsavelLegal = true;
                }
                if (socio.contador) {
                    possuiContador = true;
                }
                if (socio.qualificacao != "" && socio.qualificacao != "0") {
                    socioSemQualificacao = true;
                }

                participacaoTotal += parseFloat(socio.participacao);
            })
            
            if (possuiResponsavelInvalido) {
                Swal.fire({
                    html: mensagemResponsavelInvalido,
                    icon: "error"
                });
    
                return false;
            }

            if (!possuiResponsavelLegal) {
                Swal.fire("", "Para continuar o processo, é necessário adicionar o Responsável Legal.", "error");
                return false;
            }

            if (!socioSemQualificacao) {
                Swal.fire("", "Para continuar o processo, é necessário informar a qualificação do(s) sócio(s).", "error");
                return false;
            }

            if ( (this.obrigatorioContador && !possuiContador) || (!this.obrigatorioContador && !possuiContador && !this.termoResponsavelContabil) ) {
                Swal.fire("", "Para continuar o processo, é necessário informar o contador.", "error");
                return false;
            }

            if ((parseFloat(this.calcularPercentualTotal()) < 100 || parseFloat(this.calcularPercentualTotal()) > 100) && parseFloat(this.capitalSocialMatriz.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2")) != 0.00) {

                Swal.fire({
                    html: `Quantidade Participativa dos Sócios não corresponde ao Capital Social.<br><br>Capital Social: R$ ${ this.capitalSocialMatriz }<br><br>Participação: R$ ${ this.mascara.transform(participacaoTotal.toFixed(2), 11) }`,
                    icon: "error"
                })
                return false;
            }
            
            this.controleValidade.setValue(true);
            
        }

        return true;
    }

    campoInvalido(campo) {
        if (this.formulario.controls[campo] == null)
            return false;

        return this.formulario.controls[campo].invalid;
    } 

    campoTocado(campo) {
        if (this.formulario.controls[campo] == null)
            return false;

        return this.formulario.controls[campo].touched;
    }

    campoErro(campo, erro) {
        if (this.formulario.controls[campo] == null)
            return false;

        return this.formulario.controls[campo].hasError(erro);
    }

    criarFormControls() {
        this.socios.forEach( (socio, index) => {
            if (this.formulario.get(`protocolo_fic_${ socio.login.documentos[0].numero }_login_documentos[0]_numero`) == null) {
                this.formulario.addControl(`protocolo_fic_${ socio.login.documentos[0].numero }_login_documentos[0]_numero`, new FormControl(""));
            }
            if (this.formulario.get(`protocolo_fic_${ socio.login.documentos[0].numero }_responsavelLegal`) == null) {
                this.formulario.addControl(`protocolo_fic_${ socio.login.documentos[0].numero }_responsavelLegal`, new FormControl(""));
            }
            if (this.formulario.get(`protocolo_fic_${ socio.login.documentos[0].numero }_contador`) == null) {
                this.formulario.addControl(`protocolo_fic_${ socio.login.documentos[0].numero }_contador`, new FormControl(""));
            }
            if (this.formulario.get(`protocolo_fic_${ socio.login.documentos[0].numero }_qualificacao`) == null) {
                this.formulario.addControl(`protocolo_fic_${ socio.login.documentos[0].numero }_qualificacao`, new FormControl(""));
            }
            if (this.formulario.get(`protocolo_fic_${ socio.login.documentos[0].numero }_participacao`) == null) {
                this.formulario.addControl(`protocolo_fic_${ socio.login.documentos[0].numero }_participacao`, new FormControl(""));
            }

            this.formulario.get(`protocolo_fic_${ socio.login.documentos[0].numero }_qualificacao`).setValue(socio.qualificacao);
            if (socio.responsavelLegal) {
                this.acaoRadioResponsavelLegal(index);
            }
        });
    }

    preencherCampos(protocolo: Protocolo) {

        this.socios = protocolo.fic.socios;
        this.socios.forEach( socio => {
            if (socio.contador) {
                this.possuiContador = true;
            }
        });

        this.protocolo = protocolo;
        this.criarFormControls();
        this.atualizarCriticas();
    }

    atualizarCriticas() {
        var protocoloAuxiliar = new Protocolo();
        Object.assign(protocoloAuxiliar, this.protocolo);

        protocoloAuxiliar.criticas = protocoloAuxiliar.criticas.filter(item => { return item.nmColuna.search(this.formResponsavelDados.cpfCnpj.value.replace(/\D/g,"")) != -1 });
        
        this.formResponsavelDados.liberaBloqueiaCamposPorCritica(protocoloAuxiliar);
        this.formEndereco.liberaBloqueiaCamposPorCriticaSemBloqueio(protocoloAuxiliar);
        
        if (this.protocolo.criticas.length) {
            this.mensagemCritica = Protocolo.exibirCriticasFormularioSemBloqueio(this.formulario, this.protocolo.criticas, "responsavel");
            
            this.protocolo.criticas.filter(item => { return item.passo == "responsavel" && item.nmColuna.search("login") != -1 })
                .forEach( critica => {
                    this.mensagemCritica += critica.nomeCampo + " - " + critica.justificativa + "<br>";
                });

            if (this.mensagemCritica != "") {
                this.emitirCritica = true;
            }

            this.cpfCnpjResponsavelBusca.enable();
        }
    }

    get controleValidade() {
        return this.formulario.get("controleValidade");
    }
    set controleValidade(controleValidade) {
        this.formulario.get("controleValidade").setValue(controleValidade);
    }
    
    get cpfCnpjResponsavelBusca() {
        return this.formulario.get("cpfCnpjResponsavelBusca");
    }  
    set cpfCnpjResponsavelBusca(cpfCnpjResponsavelBusca) {
        this.formulario.get("cpfCnpjResponsavelBusca").setValue(cpfCnpjResponsavelBusca);
    }
}