<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">    
                <strong>Identificação do Negócio</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="im">Inscrição Municipal</label>
                    <input
                        #inputIm
                        autofocus
                        (input)="formatToNumber(inputIm.value)"
                        [class.is-invalid]="im.invalid && im.touched"
                        class="form-control form-control-sm "
                        formControlName="im"
                        id="im"
                        name="im"
                        type="text"
                        maxlength="20"
                    />
                    
                    <div class="mb-1" *ngIf="im.invalid && im.touched">
                        <span 
                            class="erro" 
                            *ngIf="im.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="im.errors.maxlength"
                        >A inscrição municipal deve conter até 20 caracteres.</span>
                    </div>
                    
                </div>
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="cpfCnpjEmpresa" *ngIf="tipoEmpresa == 'A' || tipoEmpresa == 'BA'">CPF</label>
                    <label class="col-sm-12 col-form-label" for="cpfCnpjEmpresa" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'BA'">CPF/CNPJ</label>
                    <input
                        #inputCpfCnpjEmpresa
                        (input)="formatToCPFOrCNPJ(inputCpfCnpjEmpresa.value)"
                        [class.is-invalid]="cpfCnpjEmpresa.invalid && cpfCnpjEmpresa.touched"
                        [attr.maxlength]="tipoEmpresa == 'A' || tipoEmpresa == 'BA' ? '14' : '18'"
                        autofocus
                        class="form-control form-control-sm "
                        formControlName="cpfCnpjEmpresa"
                        id="cpfCnpjEmpresa"
                        name="cpfCnpjEmpresa"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="cpfCnpjEmpresa.invalid && cpfCnpjEmpresa.touched">
                        <span 
                            class="erro" 
                            *ngIf="cpfCnpjEmpresa.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="cpfCnpjEmpresa.errors.minlength"
                        >O CPF / CNPJ deve conter ao menos 14 caracteres.</span>
                        <span 
                            class="erro" 
                            *ngIf="cpfCnpjEmpresa.errors.maxlength"
                        >O CPF / CNPJ deve conter até 18 caracteres.</span>
                        <span 
                            class="erro" 
                            *ngIf="cpfCnpjEmpresa.hasError('mensagem')"
                        >{{ cpfCnpjEmpresa.errors.mensagem}}</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
    
</form>