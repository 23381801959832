import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { TaxaService } from 'src/app/services/taxa/taxa.service';
import { TaxaLancamento } from 'src/app/model/taxaLancamento';
import { TaxaLancamentoParcela } from 'src/app/model/taxaLancamentoParcela';
import { Constantes } from 'src/app/model/constantes';
import { MascaraPipe } from 'src/app/pipes/mascara.pipe';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormValidator } from '../../../../components/validators/form-validator';
import Swal from 'sweetalert2';
import { Indice } from 'src/app/model/indice';
import { CalendarioFiscal } from 'src/app/model/calendarioFiscal';


@Component({
    selector: "app-lancamento-manual",
    templateUrl: "./lancamento-manual.component.html",
    styleUrls: ["./../gerenciamento-taxa.component.scss"]
})
export class LancamentoManualComponent implements OnInit {
    
    @Output() acaoMenuAtendimento = new EventEmitter();

    formulario: FormGroup;

    taxaLancamento: TaxaLancamento;
    indicesCorrecao: Array<Indice>;
    calendariosFiscaisAtivos: Array<CalendarioFiscal>;

    formaCobrancaSelect = Constantes.FORMA_COBRANCA_SELECT;
    formaCobrancaDescricao = Constantes.FORMA_COBRANCA_DESCRICAO;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;
    
    emitirErro: boolean;
    mensagemErro: string;

    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private mascara: MascaraPipe,
        private taxaService: TaxaService) {

        this.formulario = this.formBuilder.group({
            cliente: [""],
            codigo: [""],
            protocolo: [""],
            inscricao: [""],
            identificacao: ["", [Validators.required, Validators.maxLength(200)]],
            exercicio: ["", [Validators.required]],
            valorLancamento: ["00,000", [Validators.required, Validators.maxLength(12)]],
            imTipoVencimento: ["1", [Validators.required]],
            imReferencia: ["", [Validators.required, Validators.minLength(8), FormValidator.dataValida()]],
            imPrimeiroVencimento: ["", [Validators.required, Validators.maxLength(3)]],
            imDemaisVencimentos: ["", [Validators.required, Validators.maxLength(3)]],
            imNumeroParcelas: ["", [Validators.required, Validators.maxLength(3)]],
            imCalendarioFiscal: [""],
            imParcelaMinima: ["00,000", [Validators.required, Validators.maxLength(10)]],
            tipoMora: ["$"],
            valorMora: ["00,000", [Validators.required, Validators.maxLength(10)]],
            formaCobrancaMora: ["U"],
            tipoJuros: ["$"],
            valorJuros: ["00,000", [Validators.required, Validators.maxLength(10)]],
            formaCobrancaJuros: ["U"],
            tipoCorrecaoMonetaria: ["%"],
            valorCorrecaoMonetaria: [""],
            formaCobrancaCorrecaoMonetaria: ["M"],
            tipoDesconto: ["$"],
            valorDesconto: ["00,000", [Validators.required, Validators.maxLength(10)]],
            formaCobrancaDesconto: ["U"],
            qtdDiasRecebimento: ["", [Validators.required, Validators.maxLength(3)]],
            qtdDiasDesconto: ["", [Validators.required, Validators.maxLength(3)]],
        });
            
    }

    ngOnInit(): void {
        this.tipoCorrecaoMonetaria.disable();
        this.formaCobrancaCorrecaoMonetaria.disable();

        this.carregarIndicesCorrecao();
    }

    carregarIndicesCorrecao() {
        this.taxaService
            .buscarTaxaIndiceCorrecaoAtivos()
            .pipe(take(1))
            .subscribe( retorno => {
                this.indicesCorrecao = retorno;
                this.carregarCalendariosFiscais();
            })
    }
     
    carregarCalendariosFiscais() {
      this.taxaService
      .buscarTaxaCalendariosFiscaisAtivos()
      .pipe(take(1))
      .subscribe( retorno => {
          this.calendariosFiscaisAtivos = retorno;
      })
    }

    acaoBotaoVoltar() {
        this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Cancelar });
    }

    acaoImTipoVencimento() {
        this.imReferencia.clearValidators();
        this.imReferencia.setValidators([Validators.required, Validators.minLength(8), FormValidator.dataValida()]);
        this.imReferencia.updateValueAndValidity();
        this.imReferencia.enable();
        
        this.imPrimeiroVencimento.clearValidators();
        this.imPrimeiroVencimento.setValidators([Validators.required, Validators.maxLength(3)]);
        this.imPrimeiroVencimento.updateValueAndValidity();
        this.imPrimeiroVencimento.enable();
        
        this.imDemaisVencimentos.clearValidators();
        this.imDemaisVencimentos.setValidators([Validators.required, Validators.maxLength(3)]);
        this.imDemaisVencimentos.updateValueAndValidity();
        this.imDemaisVencimentos.enable();
        
        this.imNumeroParcelas.clearValidators();
        this.imNumeroParcelas.setValidators([Validators.required, Validators.maxLength(3)]);
        this.imNumeroParcelas.updateValueAndValidity();
        this.imNumeroParcelas.enable();
        
        if (this.imTipoVencimento.value != "1") {
            this.imReferencia.setValue("");
            this.imReferencia.clearValidators();
            this.imReferencia.updateValueAndValidity();
            this.imReferencia.disable();
        }
        if (this.imTipoVencimento.value == "3") {
            this.imPrimeiroVencimento.setValue("");
            this.imPrimeiroVencimento.clearValidators();
            this.imPrimeiroVencimento.updateValueAndValidity();
            this.imPrimeiroVencimento.disable();
            
            this.imDemaisVencimentos.setValue("");
            this.imDemaisVencimentos.clearValidators();
            this.imDemaisVencimentos.updateValueAndValidity();
            this.imDemaisVencimentos.disable();
            
            this.imNumeroParcelas.setValue("");
            this.imNumeroParcelas.clearValidators();
            this.imNumeroParcelas.updateValueAndValidity();
            this.imNumeroParcelas.disable();
        }
    }

    formatToBRL(valor, formControlName) {
        this.formulario.controls[formControlName].setValue(this.mascara.transform(valor, 11));
    }

    // -------------------------------------------------------------------------
    // Ações para salvar os dados - Início
    // -------------------------------------------------------------------------
    acaoBotaoSalvar() {
        if (this.validarFormulario()) {
        
          this.preencherTaxa();

        this.taxaService
            .salvarLancamentoManual(this.taxaLancamento)
            .pipe(take(1))
            .subscribe(retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    willClose: () => {
                        if (retorno.redirecionamento != null && retorno.redirecionamento == "VOLTAR") {
                            this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Atualizar_Lancamentos_Taxa, taxaLancamentos: retorno.taxaLancamentos });
                        }
                    }
                })
            });
        }

    }

    validarFormulario() {
        this.formulario.markAllAsTouched();
        
        if (this.formulario.invalid) {
          let mensagemErro = "";

          Swal.fire("", "Corrigir os erros nos campos marcados na página.", "error");

          this.emitirErro = false;
          this.mensagemErro = mensagemErro;

          return false;
        }

        this.emitirErro = false;
        this.mensagemErro = "";

        return true;
    }

    preencherTaxa() {
        this.taxaLancamento = new TaxaLancamento();

        this.taxaLancamento.cliente = this.cliente.value;
        this.taxaLancamento.codigo = this.codigo.value;
        this.taxaLancamento.protocolo = this.protocolo.value;
        this.taxaLancamento.inscricao = this.inscricao.value;
        this.taxaLancamento.identificacao = this.identificacao.value;
        this.taxaLancamento.exercicio = this.exercicio.value;
        this.taxaLancamento.total = this.valorLancamento.value;
        this.taxaLancamento.imReferencia = this.imReferencia.value;
        this.taxaLancamento.imFatoGerador = this.imTipoVencimento.value == "2" ? "S" : "N";
        this.taxaLancamento.imPrimeiroVencimento = this.imPrimeiroVencimento.value;
        this.taxaLancamento.imDemaisVencimentos = this.imDemaisVencimentos.value;
        this.taxaLancamento.imNumeroParcelas = this.imNumeroParcelas.value;
        this.taxaLancamento.tipoMora = this.tipoMora.value;
        this.taxaLancamento.valorMora = this.valorMora.value;
        this.taxaLancamento.formaCobrancaMora = this.formaCobrancaMora.value;
        this.taxaLancamento.tipoJuros = this.tipoJuros.value;
        this.taxaLancamento.valorJuros = this.valorJuros.value;
        this.taxaLancamento.formaCobrancaJuros = this.formaCobrancaJuros.value;
        this.taxaLancamento.tipoCorrecaoMonetaria = this.tipoCorrecaoMonetaria.value;
        this.taxaLancamento.valorCorrecaoMonetaria = this.valorCorrecaoMonetaria.value;
        this.taxaLancamento.formaCobrancaCorrecaoMonetaria = this.formaCobrancaCorrecaoMonetaria.value;
        this.taxaLancamento.tipoDesconto = this.tipoDesconto.value;
        this.taxaLancamento.valorDesconto = this.valorDesconto.value;
        this.taxaLancamento.formaCobrancaDesconto = this.formaCobrancaDesconto.value;
        this.taxaLancamento.qtdDiasDesconto = this.qtdDiasDesconto.value;
        this.taxaLancamento.qtdDiasRecebimento = this.qtdDiasRecebimento.value;
        this.taxaLancamento.imCalendarioFiscal = this.imCalendarioFiscal.value;
        this.taxaLancamento.imParcelaMinima = this.imParcelaMinima.value;
        this.taxaLancamento.loginProcessamento = "";
    }

    limpar() {
        this.emitirErro = false;
        this.mensagemErro = "";

        this.cliente.reset();
        this.codigo.reset();
        this.protocolo.reset();
        this.inscricao.reset();
        this.identificacao.reset();
        this.exercicio.reset();
        this.valorLancamento.reset();
        this.imTipoVencimento.reset();
        this.imReferencia.reset();
        this.imPrimeiroVencimento.reset();
        this.imDemaisVencimentos.reset();
        this.imNumeroParcelas.reset();
        this.imCalendarioFiscal.reset();
        this.imParcelaMinima.reset();
        this.tipoMora.reset();
        this.valorMora.reset();
        this.formaCobrancaMora.reset();
        this.tipoJuros.reset();
        this.valorJuros.reset();
        this.formaCobrancaJuros.reset();
        this.tipoCorrecaoMonetaria.reset();
        this.valorCorrecaoMonetaria.reset();
        this.formaCobrancaCorrecaoMonetaria.reset();
        this.tipoDesconto.reset();
        this.valorDesconto.reset();
        this.formaCobrancaDesconto.reset();
        this.qtdDiasRecebimento.reset();
        this.qtdDiasDesconto.reset();

        this.cliente.setValue("");
        this.codigo.setValue("");
        this.protocolo.setValue("");
        this.inscricao.setValue("");
        this.identificacao.setValue("");
        this.exercicio.setValue("");
        this.valorLancamento.setValue("00,00");
        this.imTipoVencimento.setValue("1");
        this.imReferencia.setValue("");
        this.imPrimeiroVencimento.setValue("");
        this.imDemaisVencimentos.setValue("");
        this.imNumeroParcelas.setValue("");
        this.imCalendarioFiscal.setValue("");
        this.imParcelaMinima.setValue("00,00");
        this.tipoMora.setValue("$");
        this.valorMora.setValue("00,00");
        this.formaCobrancaMora.setValue("U");
        this.tipoJuros.setValue("$");
        this.valorJuros.setValue("00,00");
        this.formaCobrancaJuros.setValue("U");
        this.tipoCorrecaoMonetaria.setValue("%");
        this.valorCorrecaoMonetaria.setValue("");
        this.formaCobrancaCorrecaoMonetaria.setValue("M");
        this.tipoDesconto.setValue("$");
        this.valorDesconto.setValue("00,00");
        this.formaCobrancaDesconto.setValue("U");
        this.qtdDiasRecebimento.setValue("");
        this.qtdDiasDesconto.setValue("");
    }

    get cliente() {
        return this.formulario.get("cliente");
    }
    set cliente(cliente) {
        this.formulario.get("cliente").setValue(cliente);
    }
    
    get codigo() {
        return this.formulario.get("codigo");
    }
    set codigo(codigo) {
        this.formulario.get("codigo").setValue(codigo);
    }
    
    get protocolo() {
        return this.formulario.get("protocolo");
    }
    set protocolo(protocolo) {
        this.formulario.get("protocolo").setValue(protocolo);
    }
    
    get inscricao() {
        return this.formulario.get("inscricao");
    }
    set inscricao(inscricao) {
        this.formulario.get("inscricao").setValue(inscricao);
    }
    
    get identificacao() {
        return this.formulario.get("identificacao");
    }
    set identificacao(identificacao) {
        this.formulario.get("identificacao").setValue(identificacao);
    }

    get exercicio() {
      return this.formulario.get("exercicio");
    }
    set exercicio(exercicio) {
      this.formulario.get("exercicio").setValue(exercicio);
    }

    get valorLancamento() {
      return this.formulario.get("valorLancamento");
    }
    set valorLancamento(valorLancamento) {
      this.formulario.get("valorLancamento").setValue(valorLancamento);
    }
    
    get imTipoVencimento() {
        return this.formulario.get("imTipoVencimento");
    }
    set imTipoVencimento(imTipoVencimento) {
        this.formulario.get("imTipoVencimento").setValue(imTipoVencimento);
    }
    
    get imReferencia() {
        return this.formulario.get("imReferencia");
    }
    set imReferencia(imReferencia) {
        this.formulario.get("imReferencia").setValue(imReferencia);
    }
    
    get imPrimeiroVencimento() {
        return this.formulario.get("imPrimeiroVencimento");
    }
    set imPrimeiroVencimento(imPrimeiroVencimento) {
        this.formulario.get("imPrimeiroVencimento").setValue(imPrimeiroVencimento);
    }
    
    get imDemaisVencimentos() {
        return this.formulario.get("imDemaisVencimentos");
    }
    set imDemaisVencimentos(imDemaisVencimentos) {
        this.formulario.get("imDemaisVencimentos").setValue(imDemaisVencimentos);
    }
    
    get imNumeroParcelas() {
        return this.formulario.get("imNumeroParcelas");
    }
    set imNumeroParcelas(imNumeroParcelas) {
        this.formulario.get("imNumeroParcelas").setValue(imNumeroParcelas);
    }
    
    get imParcelaMinima() {
        return this.formulario.get("imParcelaMinima");
    }
    set imParcelaMinima(imParcelaMinima) {
        this.formulario.get("imParcelaMinima").setValue(imParcelaMinima);
    }
    
    get imCalendarioFiscal() {
        return this.formulario.get("imCalendarioFiscal");
    }
    set imCalendarioFiscal(imCalendarioFiscal) {
        this.formulario.get("imCalendarioFiscal").setValue(imCalendarioFiscal);
    }
    
    get tipoJuros() {
        return this.formulario.get("tipoJuros");
    }
    set tipoJuros(tipoJuros) {
        this.formulario.get("tipoJuros").setValue(tipoJuros);
    }
    
    get valorJuros() {
        return this.formulario.get("valorJuros");
    }
    set valorJuros(valorJuros) {
        this.formulario.get("valorJuros").setValue(valorJuros);
    }
    
    get formaCobrancaJuros() {
        return this.formulario.get("formaCobrancaJuros");
    }
    set formaCobrancaJuros(formaCobrancaJuros) {
        this.formulario.get("formaCobrancaJuros").setValue(formaCobrancaJuros);
    }
  
    get tipoCorrecaoMonetaria() {
      return this.formulario.get("tipoCorrecaoMonetaria");
    }
    set tipoCorrecaoMonetaria(tipoCorrecaoMonetaria) {
      this.formulario.get("tipoCorrecaoMonetaria").setValue(tipoCorrecaoMonetaria);
    }
  
    get valorCorrecaoMonetaria() {
      return this.formulario.get("valorCorrecaoMonetaria");
    }
    set valorCorrecaoMonetaria(valorCorrecaoMonetaria) {
      this.formulario.get("valorCorrecaoMonetaria").setValue(valorCorrecaoMonetaria);
    }
  
    get formaCobrancaCorrecaoMonetaria() {
      return this.formulario.get("formaCobrancaCorrecaoMonetaria");
    }
    set formaCobrancaCorrecaoMonetaria(formaCobrancaCorrecaoMonetaria) {
      this.formulario.get("formaCobrancaCorrecaoMonetaria").setValue(formaCobrancaCorrecaoMonetaria);
    }
    
    get tipoMora() {
        return this.formulario.get("tipoMora");
    }
    set tipoMora(tipoMora) {
        this.formulario.get("tipoMora").setValue(tipoMora);
    }
    
    get valorMora() {
        return this.formulario.get("valorMora");
    }
    set valorMora(valorMora) {
        this.formulario.get("valorMora").setValue(valorMora);
    }
    
    get formaCobrancaMora() {
        return this.formulario.get("formaCobrancaMora");
    }
    set formaCobrancaMora(formaCobrancaMora) {
        this.formulario.get("formaCobrancaMora").setValue(formaCobrancaMora);
    }
    
    get qtdDiasRecebimento() {
        return this.formulario.get("qtdDiasRecebimento");
    }
    set qtdDiasRecebimento(qtdDiasRecebimento) {
        this.formulario.get("qtdDiasRecebimento").setValue(qtdDiasRecebimento);
    }  
    
    get tipoDesconto() {
        return this.formulario.get("tipoDesconto");
    }
    set tipoDesconto(tipoDesconto) {
        this.formulario.get("tipoDesconto").setValue(tipoDesconto);
    }
    
    get valorDesconto() {
        return this.formulario.get("valorDesconto");
    }
    set valorDesconto(valorDesconto) {
        this.formulario.get("valorDesconto").setValue(valorDesconto);
    }
    
    get formaCobrancaDesconto() {
        return this.formulario.get("formaCobrancaDesconto");
    }
    set formaCobrancaDesconto(formaCobrancaDesconto) {
        this.formulario.get("formaCobrancaDesconto").setValue(formaCobrancaDesconto);
    }
    
    get qtdDiasDesconto() {
        return this.formulario.get("qtdDiasDesconto");
    }
    set qtdDiasDesconto(qtdDiasDesconto) {
        this.formulario.get("qtdDiasDesconto").setValue(qtdDiasDesconto);
    }

}
