import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-empresas-eicon",
  templateUrl: "./empresas-eicon.component.html",
  styleUrls: ["./empresas-eicon.component.scss"],
})
export class EmpresasEiconComponent {
  @Input() showCompaniesDropdown: boolean;
  @Output() clickCompaniesDropDown = new EventEmitter();
  @Input() user: boolean;

  constructor() {}
}
