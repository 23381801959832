<div>
    <app-alert type="danger" >
        Caro Auditor, as informações abaixo são de responsabilidade dos sistemas que disponibilizaram essas informações via integração para o sistema ICAD, qualquer divergência devem ser tratadas com os sistemas responsáveis pela geração destes dados.
    </app-alert>
    <app-alert type="warning" >
        Caro Auditor, as informações abaixo foram disponibilizadas pelo sistema Mercúrio.
    </app-alert>
    <div class="card-body p-0">
        <div class="row row-linha mb-5">
            <div class="mat-elevation-z9 w-100">
                <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">                
                    <ng-container matColumnDef="Inscricao">
                        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Inscricao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Inscrição </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha" > {{ row.Inscricao }} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="Dt_Vencimento">
                        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Dt_Vencimento" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Vencimento </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" > {{ row.Dt_Vencimento }} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="Desc_Observacao">
                        <th style="width: 40%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Desc_Observacao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Observações </th>
                        <td style="text-align: left; padding-left: 10px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha" > {{ row.Desc_Observacao }} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="Status">
                        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Status" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Status </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" > {{ row.Status }} </td>
                    </ng-container> 
                
                    <ng-container matColumnDef="Id">
                        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Id" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Id </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha" > {{ row.Id }} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="Referencia">
                        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Referencia" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Referência </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" > {{ row.Referencia }} </td>
                    </ng-container> 
                
                    <ng-container matColumnDef="Timestamp">
                        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Timestamp" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Data de Inclusão </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha" > {{ row.Timestamp }} </td>
                    </ng-container> 
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="row" *ngIf="dataSource.filteredData.length == 0">
                <div class="form-row col-12 pt-3 justify-content-center align-items-center">
                    <p class="card-text text-center">
                    <br>{{ mensagemDeBusca }}<br>&nbsp;
                    </p>
                </div>
                </div>
                <mat-paginator #matPaginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator> 
                <div class="row col-12 justify-content-center align-items-center">
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Excel', sheet: 'sheet_name', Props: {Author: 'Talha'}})">EXCEL</button>
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('json')">JSON</button>
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('txt')">TXT</button>
                </div>
            </div>
            <div>&nbsp;</div>
        </div>
    </div>
    <app-alert type="warning" >
        Caro Auditor, as informações abaixo foram disponibilizadas pelo sistema GIEX.
    </app-alert>
    
    <div class="card-body p-0">
        <div class="row row-linha mb-5">
            <div class="mat-elevation-z9 w-100">
                <table mat-table [dataSource]="dataSourceGiex" matSort matTableExporter #exporter="matTableExporter">
                    <ng-container matColumnDef="Inscricao">
                        <th style="width: 20%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Inscricao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Inscrição </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha" > {{ row.Inscricao }} </td>
                    </ng-container>

                    <ng-container matColumnDef="Icin_Tipo_Certidao">
                        <th style="width: 60%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Icin_Tipo_Certidao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Certidão </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" > {{ row.Icin_Tipo_Certidao }} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="Dt_Inclusao">
                        <th style="width: 20%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Dt_Inclusao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Data Inclusão </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha" > {{ row.Dt_Inclusao }} </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsGiex"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsGiex;"></tr>
                </table>
                <div class="row" *ngIf="dataSourceGiex.filteredData.length == 0">
                <div class="form-row col-12 pt-3 justify-content-center align-items-center">
                    <p class="card-text text-center">
                    <br>{{ mensagemDeBusca }}<br>&nbsp;
                    </p>
                </div>
                </div>
                <mat-paginator #matPaginatorGiex [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator> 
                <div class="row col-12 justify-content-center align-items-center">
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Excel', sheet: 'sheet_name', Props: {Author: 'Talha'}})">EXCEL</button>
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('json')">JSON</button>
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('txt')">TXT</button>
                </div>
            </div>
            <div>&nbsp;</div>
        </div>
    </div>
    <app-alert type="warning" >
        Caro Auditor, as informações disponibilizadas pelo Mercúrio e Giex foram interpretadas da seguinte forma pelo sistema IcadOnline.
    </app-alert>
    <div class="card-body p-0">
        <div class="row row-linha mb-5">
            <div class="mat-elevation-z9 w-100">
                <table mat-table [dataSource]="dataSourceParcelas" matSort matTableExporter #exporter="matTableExporter">
                    <ng-container matColumnDef="Ano">
                        <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Ano" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Ano </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" > {{ row.Ano }} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="Desconto">
                        <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Desconto" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Desconto </th>
                        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha" > {{ row.Desconto }} % </td>
                    </ng-container>
                
                    <ng-container matColumnDef="Dt_Vencimento">
                        <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Dt_Vencimento" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Vencimento </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" > {{ row.Dt_Vencimento }} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="Isencao">
                        <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Isencao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Isenção </th>
                        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha" > R$ {{ formatToBRL(row.Isencao) }} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="Parcela">
                        <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Parcela" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Parcela </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" > {{ row.Parcela }} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="Status">
                        <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Status" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Status </th>
                        <td style="text-align: center; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha" > {{ row.Status }} </td>
                    </ng-container> 
                
                    <ng-container matColumnDef="Total">
                        <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Total" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Total </th>
                        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" > R$ {{ formatToBRL(row.Total) }} </td>
                    </ng-container> 
                
                    <ng-container matColumnDef="Valor_a_Pagar">
                        <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-Valor_a_Pagar" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Valor a Pagar </th>
                        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha" > R$ {{ formatToBRL(row.Valor_a_Pagar) }} </td>
                    </ng-container> 
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsParcelas"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsParcelas;"></tr>
                </table>
                <div class="row" *ngIf="dataSourceParcelas.filteredData.length == 0">
                <div class="form-row col-12 pt-3 justify-content-center align-items-center">
                    <p class="card-text text-center">
                    <br>{{ mensagemDeBusca }}<br>&nbsp;
                    </p>
                </div>
                </div>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator> 
                <div class="row col-12 justify-content-center align-items-center">
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Excel', sheet: 'sheet_name', Props: {Author: 'Talha'}})">EXCEL</button>
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('json')">JSON</button>
                <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('txt')">TXT</button>
                </div>
            </div>
            <div>&nbsp;</div>
        </div>
    </div>
</div>