<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Requerente</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>NOME</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text">
                        {{ nomeUsuario }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>CPF / CNPJ</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text">
                        {{ formatToCPFOrCNPJ(documentoUsuario) }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>E-MAIL</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text">
                        {{ emailUsuario }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>DATA DA SOLICITAÇÃO</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text">
                        {{ dataAtual }}
                    </p>
                </div>
            </div>
        </div>
    </div>

</form>