<div class="widget-content widget-content-area rounded-pills-icon">
    <ul class="nav nav-pills mb-4 mt-3  justify-content-center" id="rounded-pills-icon-tab" role="tablist">
        <li class="nav-item ml-2 mr-2"
            (click)="interacaoAbas($event)">
            <a class="nav-link mb-2 text-center" 
                id="informacoes-negocio-tab" 
                href="javascript:void(0);" 
                role="tab" 
                aria-controls="informacoes-negocio" 
                aria-selected="true">
                <svg id="informacoes-negocio-tab" class="iconeGrid">
                    <use attr.xlink:href="assets/sprite.svg#{{ 'edit-3' }}"></use>
                </svg> 
                Cadastro
            </a>
        </li>
        <li class="nav-item ml-2 mr-2"
            (click)="interacaoAbas($event)">
            <a class="nav-link mb-2 text-center" 
                id="informacoes-localizacao-tab" 
                href="javascript:void(0);" 
                role="tab" 
                aria-controls="informacoes-localizacao" 
                aria-selected="false">
                <svg class="iconeGrid" id="informacoes-localizacao-tab" >
                    <use attr.xlink:href="assets/sprite.svg#{{ 'map-pin' }}"></use>
                </svg> 
                Localização
            </a>
        </li>
        <li class="nav-item ml-2 mr-2"
            (click)="interacaoAbas($event)">
            <a class="nav-link mb-2 text-center" 
                id="informacoes-atividade-tab" 
                href="javascript:void(0);" 
                role="tab" 
                aria-controls="informacoes-atividade" 
                aria-selected="false">
                <svg class="iconeGrid" id="informacoes-atividade-tab" >
                    <use attr.xlink:href="assets/sprite.svg#{{ 'clock' }}"></use>
                </svg> 
                Atividades
            </a>
        </li>
        <li class="nav-item ml-2 mr-2"
            (click)="interacaoAbas($event)">
            <a class="nav-link mb-2 text-center" 
                id="informacoes-responsavel-tab" 
                href="javascript:void(0);" 
                role="tab" 
                aria-controls="informacoes-responsavel" 
                aria-selected="false">
                <svg class="iconeGrid" id="informacoes-responsavel-tab" >
                    <use attr.xlink:href="assets/sprite.svg#{{ 'pen-tool' }}"></use>
                </svg> 
                Responsáveis
            </a>
        </li>
        <li class="nav-item ml-2 mr-2"
            (click)="interacaoAbas($event)">
            <a class="nav-link mb-2 text-center" 
                id="informacoes-questionario-tab" 
                href="javascript:void(0);" 
                role="tab" 
                aria-controls="informacoes-questionario" 
                aria-selected="false">
                <svg class="iconeGrid" id="informacoes-questionario-tab" >
                    <use attr.xlink:href="assets/sprite.svg#{{ 'help-circle' }}"></use>
                </svg> 
                Questionário
            </a>
        </li>
        <li class="nav-item ml-2 mr-2"
            (click)="interacaoAbas($event)">
            <a class="nav-link mb-2 text-center" 
                id="informacoes-licenciamento-tab" 
                href="javascript:void(0);" 
                role="tab" 
                aria-controls="informacoes-licenciamento" 
                aria-selected="false">
                <svg class="iconeGrid" id="informacoes-licenciamento-tab" >
                    <use attr.xlink:href="assets/sprite.svg#{{ 'shield' }}"></use>
                </svg> 
                Licenciamento
            </a>
        </li>
        <li class="nav-item ml-2 mr-2"
            (click)="interacaoAbas($event)">
            <a class="nav-link mb-2 text-center" 
                id="informacoes-documentacoes-tab" 
                href="javascript:void(0);" 
                role="tab" 
                aria-controls="informacoes-documentacoes" 
                aria-selected="false">
                <svg class="iconeGrid" id="informacoes-documentacoes-tab" >
                    <use attr.xlink:href="assets/sprite.svg#{{ 'award' }}"></use>
                </svg> 
                Documentações
            </a>
        </li>
    </ul>
    <div class="tab-content" id="rounded-pills-icon-tabContent">
        <div class="tab-pane fade" id="informacoes-negocio" role="tabpanel" aria-labelledby="informacoes-negocio-tab">
            <app-informacao-identificacao [tipoEmpresa]="tipoEmpresa"></app-informacao-identificacao>
        </div>
        
        <div class="tab-pane fade" id="informacoes-localizacao" role="tabpanel" aria-labelledby="informacoes-localizacao-tab">
            <app-informacao-localizacao></app-informacao-localizacao>
        </div>
         
        <div class="tab-pane fade" id="informacoes-atividade" role="tabpanel" aria-labelledby="informacoes-atividade-tab">
            <app-informacao-atividade></app-informacao-atividade>
        </div>
        
        <div class="tab-pane fade" id="informacoes-responsavel" role="tabpanel" aria-labelledby="informacoes-responsavel-tab">
            <app-informacao-responsavel></app-informacao-responsavel>
        </div>
        
        <div class="tab-pane fade" id="informacoes-questionario" role="tabpanel" aria-labelledby="informacoes-questionario-tab">
            <app-informacao-questionario #informacaoQuestionarioViabilidade titulo="Consulta Prévia"></app-informacao-questionario>

            <app-informacao-questionario #informacaoQuestionarioFic titulo="Dados Cadastrais"></app-informacao-questionario> 
        </div>
        
        <div class="tab-pane fade" id="informacoes-licenciamento" role="tabpanel" aria-labelledby="informacoes-licenciamento-tab">
              
        </div>
        
        <div class="tab-pane fade" id="informacoes-documentacoes" role="tabpanel" aria-labelledby="informacoes-documentacoes-tab">
              <app-informacao-documento></app-informacao-documento>
        </div>
    </div>
</div>