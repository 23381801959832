export class Notificacao {
    cliente: string;
    tipo: number;
    typeMime: string;
    origem: string;
    destino: string;
    assunto: string;
    corpo: string;
    data: string;
    status: string;

    constructor() {
        this.cliente = "";
        this.tipo = 0;
        this.typeMime = "text/plain";
        this.origem = "";
        this.destino = "";
        this.assunto = "";
        this.corpo = "";
        this.data = "";
        this.status = "";
    }
}