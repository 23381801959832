import { OnInit, Component, ViewChild } from "@angular/core";
import { take } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Grid } from "src/app/model/grid";
import { GridOpcao } from "src/app/model/gridOpcao";
import { GridFiltro } from "src/app/model/gridFiltro";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { formatToCPFOrCNPJ } from "brazilian-values";
import { FormValidator } from "src/app/components/validators/form-validator";
import { TaxaService } from "src/app/services/taxa/taxa.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { GridDamComponent } from "src/app/components/grid/grid-dam.component";

@Component({
    selector: 'app-meus-dam',
    templateUrl: './meus-dam.component.html'
})

export class MeusDamComponent implements OnInit {

    numeroInscricao: string;
    numeroProtocolo: string;
    orgao: string;
    formulario: FormGroup;

    grid: Grid;
    filtroOpcoes: Array<GridOpcao>;

    emitirErro: boolean;
    mensagemErro: string;
    codigoMenu: string;
    
    @ViewChild("emAberto")
    gridEmAberto: GridDamComponent;

    @ViewChild("pagas")
    gridPagas: GridDamComponent;

    @ViewChild("finalizado")
    gridFinalizado: GridDamComponent;

    emAberto: Array<any> = new Array<any>();
    pagas: Array<any> = new Array<any>();
    finalizado: Array<any> = new Array<any>();
    
    constructor(
        private taxaService: TaxaService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,) 
    {

        this.grid = new Grid();
        this.grid.filtro.push(new GridFiltro());
        
        this.mensagemErro = "";
        this.emitirErro = false;
        
        this.formulario = this.formBuilder.group({
          tipoBusca: [''],
          tipoBuscaValorInput: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(18), FormValidator.cpfCnpjValido()]],
          tipoBuscaValorSelect: [''],
        });   
        
        this.orgao = this.activatedRoute.snapshot.params["codigoOrgao"];
        this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
        this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "";
        this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];
    }
    
    ngOnInit(): void {

      this.taxaService
        .buscarDebitos(this.authenticationService.currentUserValue.codigo_usuario)
        .pipe(take(1))
        .subscribe(retorno => {
          this.gridEmAberto.dados = retorno.emAberto;
          this.gridEmAberto.atualizarTabela();
          this.gridEmAberto.aplicarGridExterno();

          this.gridPagas.dados = retorno.pagas;
          this.gridPagas.atualizarTabela();
          this.gridPagas.aplicarGridExterno();

          this.gridFinalizado.dados = retorno.finalizado;
          this.gridFinalizado.atualizarTabela();
          this.gridFinalizado.aplicarGridExterno();
        });

    }

    acaoSelectTipoBusca() {
      this.tipoBuscaValorInput.setValue("");
      this.tipoBuscaValorSelect.setValue("");

    }

    formatToCPFOrCNPJ(valor) {
      this.tipoBuscaValorInput.setValue(formatToCPFOrCNPJ(valor));
    }

    buscarGridPorFiltro() {
        let valor = this.tipoBuscaValorInput.value;

        if (valor == "") {
            Swal.fire("", "Favor informar um valor para busca.", "error")
            return false;
        }

         let valorFormatado = valor.replace(/\D/g,"");

         this.taxaService
           .buscarDebitos(valorFormatado)
           .pipe(take(1))
           .subscribe(
            retorno => {
              retorno.pagas.map(dados => {
                  this.pagas.push({
                      "codigoLancamento": dados.codigoLancamento,
                      "codigoParcela": dados.codigoParcela,
                      "requisitoDescricao": dados.requisitoDescricao,
                      "fase": dados.fase,
                      "totalParcela": dados.totalParcela,
                      "vencimentoParcela": dados.vencimentoParcela,
                      "situacaoParcelaDescricao": dados.situacaoParcelaDescricao
                   })
              });
              retorno.emAberto.map(dados => {
                this.emAberto.push({
                    "codigoLancamento": dados.codigoLancamento,
                    "codigoParcela": dados.codigoParcela,
                    "requisitoDescricao": dados.requisitoDescricao,
                    "fase": dados.fase,
                    "totalParcela": dados.totalParcela,
                    "vencimentoParcela": dados.vencimentoParcela,
                    "situacaoParcelaDescricao": dados.situacaoParcelaDescricao
                 })
            });
            retorno.finalizado.map(dados => {
              this.finalizado.push({
                  "codigoLancamento": dados.codigoLancamento,
                  "codigoParcela": dados.codigoParcela,
                  "requisitoDescricao": dados.requisitoDescricao,
                  "fase": dados.fase,
                  "totalParcela": dados.totalParcela,
                  "vencimentoParcela": dados.vencimentoParcela,
                  "situacaoParcelaDescricao": dados.situacaoParcelaDescricao
               })
            });

              this.gridPagas.dados = this.pagas;
              this.gridPagas.atualizarTabela();
              this.gridEmAberto.dados = this.emAberto;
              this.gridEmAberto.atualizarTabela();
              this.gridFinalizado.dados = this.finalizado;
              this.gridFinalizado.atualizarTabela();
      
          }
           )
    }
  
    get tipoBusca() {
      return this.formulario.get("tipoBusca");
    }  
    set tipoBusca(tipoBusca) {
      this.formulario.get("tipoBusca").setValue(tipoBusca);
    }
  
    get tipoBuscaValorInput() {
      return this.formulario.get("tipoBuscaValorInput");
    }  
    set tipoBuscaValorInput(tipoBuscaValorInput) {
      this.formulario.get("tipoBuscaValorInput").setValue(tipoBuscaValorInput);
    }
  
    get tipoBuscaValorSelect() {
      return this.formulario.get("tipoBuscaValorSelect");
    }  
    set tipoBuscaValorSelect(tipoBuscaValorSelect) {
      this.formulario.get("tipoBuscaValorSelect").setValue(tipoBuscaValorSelect);
    }

}