<div *ngIf="tipoEmpresa == 'E' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM'"
    class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Identificação</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolo != null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.nomeEmpresa')"
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.fic.nomeEmpresa')">
            <div class="col-2">
                <p class="card-text">
                    <strong>Nome Empresarial</strong>
                </p>
            </div>
            <div class="col-10" *ngIf="!tipoAutenticidade">
                <p class="card-text" id="lbl-nome-empresarial-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.fic.nomeEmpresa == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.nomeEmpresa'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-nome-empresarial-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.fic.nomeEmpresa', protocolo.fic.nomeEmpresa, 'Nome Empresarial')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.nomeEmpresa'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-nome-empresarial-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.fic.nomeEmpresa', protocolo.fic.nomeEmpresa, 'Nome Empresarial')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.fic.nomeEmpresa == '' ? 'Não Cadastrado' : protocolo.fic.nomeEmpresa }}
                </p>
            </div>
            <div class="col-10" *ngIf="tipoAutenticidade">
                <p class="card-text" id="lbl-nome-empresarial-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.nome == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.nome'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-nome-empresarial-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.nome', protocolo.registro.nome, 'Nome Empresarial')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.nome == '' ? 'Não Cadastrado' : protocolo.registro.nome }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.nomeFantasia')"
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.fic.nomeFantasia')" *ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Nome Fantasia</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-nome-fantasia-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.fic.nomeFantasia == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.nomeFantasia'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-nome-fantasia-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.fic.nomeFantasia', protocolo.fic.nomeFantasia, 'Nome Fantasia')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.nomeFantasia'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-nome-empresarial-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.fic.nomeFantasia', protocolo.fic.nomeFantasia, 'Nome Fantasia')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.fic.nomeFantasia == '' ? 'Não Cadastrado' : protocolo.fic.nomeFantasia }}
                </p>
            </div>
        </div>
        <div *ngIf="!tipoAutenticidade">
            <div *ngFor="let telefone of protocolo.fic.telefones"
                class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
                [class.campo-criticado]="protocolo.existeCriticaPorCampo(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                        telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                        telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero')"
                
                [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                            telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                            telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero')">
                <div class="col-2">
                    <p class="card-text">
                        <strong>
                            {{ 
                                telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                                telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 
                                telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 'Telefone Celular'
                            }}
                        </strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text" [id]="'lbl-' + telefone.tipo + '-identificacao'"
                        [class.valor-nao-cadastrado]="telefone.numero == ''">
                        <button *ngIf="((protocolo.criticas | filtro: {campo: 'nmColuna', valor: telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                        telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                        telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero'}).length)  && permitirAtendimento && permiteCriticar"
                            type="button"  [id]="'btn-criticar-' + telefone.tipo + '-identificacao'"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                    telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero',
                                    telefone.ddd + telefone.numero, 
                                    telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                                    telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 'Telefone Celular')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>                        
                        <button *ngIf="((protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                        telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                        telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero'}).length) && permitirAtendimento && permiteInconsistencias"
                            type="button" id="btn-inconsistencia-nome-empresarial-identificacao"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                    telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero',
                                    telefone.ddd + telefone.numero, 
                                    telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                                    telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 'Telefone Celular')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>
                        {{ 
                            telefone.ddd.toString().length == 2 ? '(' + telefone.ddd + ')' : ''
                        }}
                        {{ 
                            telefone.numero == '' ? 'Não Cadastrado' : 
                            telefone.numero.toString().length == 8 ? (telefone.numero | mask: '0000-0000') :
                            telefone.numero.toString().length > 8 ? (telefone.numero | mask: '00000-0000') : telefone.numero
                        }}
                    </p>
                </div>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.sites')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.fic.sites')" *ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Site</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-site-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.fic.sites.length == 0 || protocolo.fic.sites[0] == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.sites'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-site-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.fic.sites', protocolo.fic.sites[0], 'Site')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>                                        
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.sites'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-nome-empresarial-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.fic.sites', protocolo.fic.sites[0], 'Site')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ 
                        protocolo.fic.sites.length == 0 ? 'Não Cadastrado' : 
                        protocolo.fic.sites[0] == '' ? 'Não Cadastrado' : protocolo.fic.sites[0]
                    }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.capitalSocial')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.capitalSocial')"*ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Capital Social</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-capital-social-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.capitalSocial == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.capitalSocial'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-capital-social-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.capitalSocial', protocolo.registro.capitalSocial, 'Capital Social')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.capitalSocial'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-capital-social-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.capitalSocial', protocolo.registro.capitalSocial, 'Capital Social')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.capitalSocial == '' ? 'Não Cadastrado' : 'R$ ' + formatToBRL(protocolo.registro.capitalSocial) }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.ultimaAlteracao')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.ultimaAlteracao')"*ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Data Ult. Alteração</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-ultima-alteracao-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.ultimaAlteracao == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.ultimaAlteracao'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-ultima-alteracao-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.ultimaAlteracao', protocolo.registro.ultimaAlteracao, 'Data Ult. Alteração')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.ultimaAlteracao'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-ultima-alteracao-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.ultimaAlteracao', protocolo.registro.ultimaAlteracao, 'Data Ult. Alteração')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.ultimaAlteracao == '' ? 'Não Cadastrado' : protocolo.registro.ultimaAlteracao }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.numero')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.numero')"*ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>NIRE</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-nire-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.numero == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.numero'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-nire-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.numero', protocolo.registro.numero, 'NIRE')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.numero'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-nire-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.numero', protocolo.registro.numero, 'NIRE')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.numero == '' ? 'Não Cadastrado' : protocolo.registro.numero }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.data')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.data')"*ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Data NIRE</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-data-nire-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.data == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.data'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-data-nire-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.data', protocolo.registro.data, 'Data NIRE')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.data'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-data-nire-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.data', protocolo.registro.data, 'Data NIRE')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.data == '' ? 'Não Cadastrado' : protocolo.registro.data }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.data')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.data')"*ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Porte</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-porte-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.porte == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.porte'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-porte-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.porte', protocolo.registro.porte, 'Porte da Empresa')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.porte'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-porte-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.porte', protocolo.registro.porte, 'Porte da Empresa')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.porte == '' ? 'Não Cadastrado' : protocolo.registro.porte }}
                </p>
            </div>
        </div>
        <div *ngFor="let documento of protocolo.registro.documentos"
            class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>{{ tipoDocumentoDescricao[documento.tipo] }}</strong>
                </p>
            </div>
            <div class="col-4">
                <p class="card-text" [id]="'lbl-' + documento.tipo + '-numero-identificacao'"
                    [class.valor-nao-cadastrado]="documento.numero == ''"
                    [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero')"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero')">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero'}).length && permitirAtendimento && permiteCriticar"
                        type="button" [id]="'btn-criticar-' + documento.tipo + '-numero-identificacao'"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero', documento.numero, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" [id]="'btn-inconsistencia-' + documento.tipo + '-numero-identificacao'"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero', documento.numero, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{
                        documento.numero == '' ? 'Não Cadastrado' : 
                        documento.tipo == tipoDocumento.Cpf.toString() ? (documento.numero | mask: '000.000.000-00') :
                        documento.tipo == tipoDocumento.Cnpj.toString() ? (documento.numero | mask: '00.000.000/0000-00') : documento.numero
                    }}
                </p>
            </div>
            <div class="col-6">
                <p class="card-text" [id]="'lbl-' + documento.tipo + '-emissao-identificacao'"
                    [class.valor-nao-cadastrado]="documento.dataEmissao == ''"
                    [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao')"
                    [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao')">
                    - Emissão:
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao'}).length && permitirAtendimento && permiteCriticar"
                        type="button" [id]="'btn-criticar-' + documento.tipo + '-emissao-identificacao'"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao', documento.dataEmissao, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" [id]="'btn-inconsistencia-' + documento.tipo + '-emissao-identificacao'"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao', documento.dataEmissao, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button> {{ documento.dataEmissao == '' ? 'Não Cadastrado' : documento.dataEmissao }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.data')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.data')"*ngIf="tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Data Abertura</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-data-abertura-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.data == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.data'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-data-abertura-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.data', protocolo.registro.data, 'Data Abertura')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.data'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-data-abertura-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.data', protocolo.registro.data, 'Data Abertura')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.data == '' ? 'Não Cadastrado' : protocolo.registro.data }}
                </p>
            </div>
        </div>
    </div>
</div>

<div [class.card]="!permiteCriticar"
    [class.card-critica]="permiteCriticar" *ngIf="tipoEmpresa == 'A' || tipoEmpresa == 'AA'">
    <div [class.card-header]="!permiteCriticar"
        [class.card-header-critica]="permiteCriticar">
        <div class="col-12">
            <strong>Dados do Autônomo</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolo != null" class="card-body" [class.p-0]="semCardHeader">    
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.nome')"
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.nome')">
            <div class="col-2">
                <p class="card-text">
                    <strong>Nome</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-nome-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.nome == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.nome'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-nome-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.nome', protocolo.registro.nome, 'Nome')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.nome'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-nome-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.nome', protocolo.registro.nome, 'Nome')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.nome == '' ? 'Não Cadastrado' : protocolo.registro.nome }}
                </p>
            </div>
        </div>   
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.nascimento')"
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.nascimento')" *ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Data de Nascimento</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-nascimento-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.nascimento == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.nascimento'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-nascimento-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.nascimento', protocolo.registro.nascimento, 'Data de Nascimento')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.nascimento'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-nascimento-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.nascimento', protocolo.registro.nascimento, 'Data de Nascimento')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.nascimento == '' ? 'Não Cadastrado' : protocolo.registro.nascimento }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.sexo')"
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.sexo')" *ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Sexo</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-sexo-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.sexo == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.sexo'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-sexo-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.sexo', protocolo.registro.sexo, 'Sexo')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.sexo'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-sexo-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.sexo', protocolo.registro.sexo, 'Sexo')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.sexo == '1' ? 'Masculino' : 'Feminino' }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.escolaridade.nome')"
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.escolaridade.nome')" *ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Escolaridade</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-escolaridade-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.escolaridade.nome == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.escolaridade.nome'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-escolaridade-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.escolaridade.nome', protocolo.registro.escolaridade.nome, 'Escolaridade')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.escolaridade.nome'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-escolaridade-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.escolaridade.nome', protocolo.registro.escolaridade.nome, 'Escolaridade')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.escolaridade.nome == '' ? 'Não Cadastrado' : protocolo.registro.escolaridade.nome }}
                </p>
            </div>
        </div>
        <div *ngFor="let documento of protocolo.registro.documentos" 
            class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>{{ tipoDocumentoDescricao[documento.tipo] }} </strong>
                </p>
            </div>
            <div class="col-2"
                [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero')"
                [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero')">
                <p class="card-text" [id]="'lbl-' + documento.tipo + '-numero-identificacao'"
                    [class.valor-nao-cadastrado]="documento.numero == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero'}).length && permitirAtendimento && permiteCriticar"
                        type="button" [id]="'btn-criticar-' + documento.tipo + '-numero-identificacao'"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero', documento.numero, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" [id]="'btn-inconsistencia-' + documento.tipo + '-numero-identificacao'"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.numero', documento.numero, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ 
                        documento.numero == '' ? 'Não Cadastrado' : 
                        documento.tipo == tipoDocumento.Cpf.toString() ? (documento.numero | mask: '000.000.000-00') :
                        documento.tipo == tipoDocumento.Cnpj.toString() ? (documento.numero | mask: '00.000.000/0000-00') : documento.numero
                    }}
                </p>
            </div>
            <div class="col-2" 
                [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao')"
                [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao')" *ngIf="documento.nome.toLowerCase() != 'CPF'.toLowerCase()">
                <p class="card-text" [id]="'lbl-' + documento.tipo + '-emissao-identificacao'"
                    [class.valor-nao-cadastrado]="documento.dataEmissao == ''">
                   <strong>Emissão</strong> 
                   <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao'}).length && permitirAtendimento && permiteCriticar"
                       type="button" [id]="'btn-criticar-' + documento.tipo + '-emissao-identificacao'"
                       matTooltip="Criticar"
                       matTooltipPosition="above"
                       (click)="protocolo.criticar('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao', documento.dataEmissao, tipoDocumentoDescricao[documento.tipo])"
                       class="btn btn-sm">
                       <svg class="icone-excluir">
                           <use
                               attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                           ></use>
                       </svg>
                   </button>
                   <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao'}).length && permitirAtendimento && permiteInconsistencias"
                       type="button" [id]="'btn-inconsistencia-' + documento.tipo + '-emissao-identificacao'"
                       matTooltip="Inconsistência"
                       matTooltipPosition="above"
                       (click)="protocolo.exibirInconsistencia('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.dataEmissao', documento.dataEmissao, tipoDocumentoDescricao[documento.tipo])"
                       class="btn btn-sm">
                       <svg class="icone-editar">
                           <use
                               attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                           ></use>
                       </svg>
                   </button> {{ documento.dataEmissao == '' ? 'Não Cadastrado' : documento.dataEmissao }}
                </p>
            </div>
            <div class="col-1"
                [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.estadoEmissor')"
                [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.estadoEmissor')" *ngIf="documento.nome.toLowerCase() == 'RG'.toLowerCase() || documento.nome.toLowerCase() == 'ORGAO_CLASSE'.toLowerCase()">
                <p class="card-text" [id]="'lbl-' + documento.tipo + '-estado-identificacao'"
                    [class.valor-nao-cadastrado]="documento.estadoEmissor == ''">
                    <strong>UF</strong> 
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.estadoEmissor'}).length && permitirAtendimento && permiteCriticar"
                        type="button" [id]="'btn-criticar-' + documento.tipo + '-estado-identificacao'"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.estadoEmissor', documento.estadoEmissor, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.estadoEmissor'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" [id]="'btn-inconsistencia-' + documento.tipo + '-estado-identificacao'"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.estadoEmissor', documento.estadoEmissor, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button> {{ documento.estadoEmissor == '' ? '--' : documento.estadoEmissor }}
                </p>
            </div>
            <div class="col-5"
                [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoEmissor')"
                [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoEmissor')" *ngIf="documento.nome.toLowerCase() == 'RG'.toLowerCase()">
                <p class="card-text" [id]="'lbl-' + documento.tipo + '-emissor-identificacao'"
                    [class.valor-nao-cadastrado]="documento.orgaoEmissor == ''">
                    <strong>Órgão</strong> 
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoEmissor'}).length && permitirAtendimento && permiteCriticar"
                        type="button" [id]="'btn-criticar-' + documento.tipo + '-emissor-identificacao'"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoEmissor', documento.orgaoEmissor, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoEmissor'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" [id]="'btn-inconsistencia-' + documento.tipo + '-emissor-identificacao'"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoEmissor', documento.orgaoEmissor, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button> {{ documento.orgaoEmissor == '' ? 'Não Cadastrado' : documento.orgaoEmissor }}
                </p>
            </div>
            <div class="col-5"
                [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoDeClasse.nome')"
                [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoDeClasse.nome')" *ngIf="documento.nome.toLowerCase() == 'ORGAO_CLASSE'.toLowerCase()">
                <p class="card-text" [id]="'lbl-' + documento.tipo + '-classe-identificacao'"
                    [class.valor-nao-cadastrado]="documento.orgaoDeClasse.nome == ''">
                    <strong>Classe</strong> 
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoDeClasse.nome'}).length && permitirAtendimento && permiteCriticar"
                        type="button" [id]="'btn-criticar-' + documento.tipo + '-classe-identificacao'"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoDeClasse.nome', documento.orgaoDeClasse.nome, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoDeClasse.nome'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" [id]="'btn-inconsistencia-' + documento.tipo + '-classe-identificacao'"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.documento.' + documento.nome.toLowerCase() + '.orgaoDeClasse.nome', documento.orgaoDeClasse.nome, tipoDocumentoDescricao[documento.tipo])"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button> {{ documento.orgaoDeClasse.nome == '' ? 'Não Cadastrado' : documento.orgaoDeClasse.nome }}
                </p>
            </div>         
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.registro.data')"
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.data')" *ngIf="tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Data Abertura</strong>
                </p>
            </div>
            <div class="col-4">
                <p class="card-text" id="lbl-data-abertura-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.data == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.data'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-data-abertura-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.data', protocolo.registro.data, 'Data Abertura')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.data'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-data-abertura-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.data', protocolo.registro.data, 'Data Abertura')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.data == '' ? 'Não Cadastrado' : protocolo.registro.data }}
                </p>
            </div>
        </div>
        <div *ngIf="!tipoAutenticidade">
            <div *ngFor="let telefone of protocolo.fic.telefones"
                class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
                [class.campo-criticado]="protocolo.existeCriticaPorCampo(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                            telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                            telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero')"
                [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                                telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                                telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero')">
                <div class="col-2">
                    <p class="card-text">
                        <strong>
                            {{ 
                                telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                                telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 
                                telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 'Telefone Celular'
                            }}
                        </strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text" [id]="'lbl-' + telefone.tipo + '-identificacao'"
                        [class.valor-nao-cadastrado]="telefone.numero == ''">
                        <button *ngIf="((protocolo.criticas | filtro: {campo: 'nmColuna', valor: telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                        telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                        telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero'}).length) && permitirAtendimento && permiteCriticar"
                            type="button" [id]="'btn-criticar-' + telefone.tipo + '-identificacao'"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                    telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero',
                                    telefone.numero, 
                                    telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                                    telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 'Telefone Celular')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>
                        <button *ngIf="((protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                        telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                        telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero'}).length) && permitirAtendimento && permiteInconsistencias"
                            type="button" [id]="'btn-inconsistencia-' + telefone.tipo + '-identificacao'"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                    telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero',
                                    telefone.numero, 
                                    telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                                    telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 'Telefone Celular')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>
                        {{ 
                            telefone.ddd.toString().length == 2 ? '(' + telefone.ddd + ')' : ''
                        }}
                        {{ 
                            telefone.numero == '' ? 'Não Cadastrado' : 
                            telefone.numero.toString().length == 8 ? (telefone.numero | mask: '0000-0000') :
                            telefone.numero.toString().length > 8 ? (telefone.numero | mask: '00000-0000') : telefone.numero
                        }}
                    </p>
                </div>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.emails')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.fic.emails')"*ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>E-mail</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-email-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.fic.emails[0] == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.emails'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-email-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.fic.emails', protocolo.fic.emails[0], 'E-mail')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.emails'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-email-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.fic.emails', protocolo.fic.emails[0], 'E-mail')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.fic.emails[0] == '' ? 'Não Cadastrado' : protocolo.fic.emails[0] }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.sites')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.fic.sites')" *ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Site</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-site-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.fic.sites[0] == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.sites'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-site-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.fic.sites', protocolo.fic.sites[0], 'Site')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.sites'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-site-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.fic.sites', protocolo.fic.sites[0], 'Site')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.fic.sites[0] == '' ? 'Não Cadastrado' : protocolo.fic.sites[0] }}
                </p>
            </div>
        </div> 
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.registro.estabelecido')"*ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Tipo de Autônomo</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-estabelecido-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.estabelecido == ''">
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.estabelecido'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-site-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.estabelecido', protocolo.registro.estabelecido, 'Tipo de Autônomo')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ 
                        protocolo.registro.estabelecido == 'S' ? 'Estabelecido' : 
                        protocolo.registro.estabelecido == 'N' ? 'Não Estabelecido' : 'Não Cadastrado'
                    }}
                </p>
            </div>
        </div> 
    </div>
</div>

<div *ngIf="tipoEmpresa == 'EEV' || tipoEmpresa == 'AEV'"
    class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Identificação do Evento</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolo != null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.nomeEmpresa')"
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.fic.nomeEmpresa')">
            <div class="col-2">
                <p class="card-text">
                    <strong>Nome do Evento</strong>
                </p>
            </div>
            <div class="col-10" *ngIf="!tipoAutenticidade">
                <p class="card-text" id="lbl-nome-empresarial-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.fic.nomeEmpresa == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.nomeEmpresa'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-nome-empresarial-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.fic.nomeEmpresa', protocolo.fic.nomeEmpresa, 'Nome Empresarial')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.nomeEmpresa'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-nome-empresarial-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.fic.nomeEmpresa', protocolo.fic.nomeEmpresa, 'Nome Empresarial')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.fic.nomeEmpresa == '' ? 'Não Cadastrado' : protocolo.fic.nomeEmpresa }}
                </p>
            </div>
            <div class="col-10" *ngIf="tipoAutenticidade">
                <p class="card-text" id="lbl-nome-empresarial-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.registro.nome == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.nome'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-nome-empresarial-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.registro.nome', protocolo.registro.nome, 'Nome Empresarial')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.registro.nome'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-nome-empresarial-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.registro.nome', protocolo.registro.nome, 'Nome Empresarial')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ protocolo.registro.nome == '' ? 'Não Cadastrado' : protocolo.registro.nome }}
                </p>
            </div>
        </div>
        <div *ngIf="!tipoAutenticidade">
            <div *ngFor="let telefone of protocolo.fic.telefones"
                class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
                [class.campo-criticado]="protocolo.existeCriticaPorCampo(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                        telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                        telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero')"
                [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                            telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                            telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero')">
                <div class="col-2">
                    <p class="card-text">
                        <strong>
                            {{ 
                                telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                                telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 
                                telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 'Telefone Celular'
                            }}
                        </strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text" [id]="'lbl-' + telefone.tipo + '-identificacao'"
                        [class.valor-nao-cadastrado]="telefone.numero == ''">
                        <button *ngIf="((protocolo.criticas | filtro: {campo: 'nmColuna', valor: telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                        telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                        telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero'}).length)  && permitirAtendimento && permiteCriticar"
                            type="button"  [id]="'btn-criticar-' + telefone.tipo + '-identificacao'"
                            matTooltip="Criticar"
                            matTooltipPosition="above"
                            (click)="protocolo.criticar(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                    telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero',
                                    telefone.numero, 
                                    telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                                    telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 'Telefone Celular')"
                            class="btn btn-sm">
                            <svg class="icone-excluir">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                ></use>
                            </svg>
                        </button>
                        <button *ngIf="((protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                        telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                        telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero'}).length)  && permitirAtendimento && permiteInconsistencias"
                            type="button"  [id]="'btn-inconsistencia-' + telefone.tipo + '-identificacao'"
                            matTooltip="Inconsistência"
                            matTooltipPosition="above"
                            (click)="protocolo.exibirInconsistencia(telefone.tipo === tipoTelefone.Residencial ? 'protocolo.fic.telefone.fixo.numero' : 
                                    telefone.tipo === tipoTelefone.Comercial ? 'protocolo.fic.telefone.comercial.numero' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'protocolo.fic.telefone.celular.numero' : 'protocolo.fic.telefone.celular.numero',
                                    telefone.numero, 
                                    telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                                    telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 
                                    telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 'Telefone Celular')"
                            class="btn btn-sm">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                                ></use>
                            </svg>
                        </button>
                        {{ 
                            telefone.ddd.toString().length == 2 ? '(' + telefone.ddd + ')' : ''
                        }}
                        {{ 
                            telefone.numero == '' ? 'Não Cadastrado' : 
                            telefone.numero.toString().length == 8 ? (telefone.numero | mask: '0000-0000') :
                            telefone.numero.toString().length > 8 ? (telefone.numero | mask: '00000-0000') : telefone.numero
                        }}
                    </p>
                </div>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.emails')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.fic.emails')"*ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>E-mail</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-site-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.fic.emails.length == 0 || protocolo.fic.emails[0] == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.emails'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-site-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.fic.emails', protocolo.fic.emails[0], 'Site')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.emails'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-site-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.fic.emails', protocolo.fic.emails[0], 'Site')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ 
                        protocolo.fic.emails.length == 0 ? 'Não Cadastrado' : 
                        protocolo.fic.emails[0] == '' ? 'Não Cadastrado' : protocolo.fic.emails[0]
                    }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.sites')" 
            [class.campo-inconsistencia]="protocolo.existeInconsistenciaPorCampo('protocolo.fic.sites')"*ngIf="!tipoAutenticidade">
            <div class="col-2">
                <p class="card-text">
                    <strong>Site</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" id="lbl-site-identificacao"
                    [class.valor-nao-cadastrado]="protocolo.fic.sites.length == 0 || protocolo.fic.sites[0] == ''">
                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.sites'}).length && permitirAtendimento && permiteCriticar"
                        type="button" id="btn-criticar-site-identificacao"
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.fic.sites', protocolo.fic.sites[0], 'Site')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="(protocolo.inconsistencias | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.sites'}).length && permitirAtendimento && permiteInconsistencias"
                        type="button" id="btn-inconsistencia-site-identificacao"
                        matTooltip="Inconsistência"
                        matTooltipPosition="above"
                        (click)="protocolo.exibirInconsistencia('protocolo.fic.sites', protocolo.fic.sites[0], 'Site')"
                        class="btn btn-sm">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-triangle' }}"
                            ></use>
                        </svg>
                    </button>
                    {{ 
                        protocolo.fic.sites.length == 0 ? 'Não Cadastrado' : 
                        protocolo.fic.sites[0] == '' ? 'Não Cadastrado' : protocolo.fic.sites[0]
                    }}
                </p>
            </div>
        </div>
        
    </div>
</div>