import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { formatToCapitalized, formatToCNPJ } from "brazilian-values";

import { Atividade } from "src/app/model/atividade";
import { AtividadeService } from "src/app/services/atividade/atividade.service";
import { FormaAtuacao } from "src/app/model/formaAtuacao";
import { TipoUnidade } from "src/app/model/tipoUnidade";
import { IcadModalComponent } from "src/app/components/modal/icad-modal/icad-modal.component";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatRadioButton } from "@angular/material/radio";
import Swal from "sweetalert2";
import { FormValidator } from "src/app/components/validators/form-validator";
import { take } from "rxjs/operators";
import { FiltroPipe } from "src/app/pipes/filtro.pipe";
import { ActivatedRoute } from "@angular/router";
import { Constantes } from "src/app/model/constantes";
import { ThrowStmt } from "@angular/compiler";
import { Protocolo } from '../../../model/protocolo';
import { AuthenticationService } from "src/app/services/authentication/authentication.service";

@Component({
    selector: "app-form-atividade",
    templateUrl: "./form-atividade.component.html",
    styleUrls: ["./form-atividade.component.scss"]
})
export class FormAtividadeComponent implements OnInit {

    tipoEmpresa: string;
    tipoEmpresaBusca: string;

    formulario: FormGroup;

    tipoUnidadeAuxiliares: Array<TipoUnidade>;
    observacaoTipoUnidadeAuxiliar: string;
    formasAtuacaoConfiguracao: Array<FormaAtuacao>;

    atividades: Array<Atividade>;
    atividadesSelecionadas: Array<Atividade>;

    quantidadePrincipal: number = 0;
    quantidadeExercida: number = 0;
    quantidadeNaoxercida: number = 0;
    atividadePrincipal;

    limiteSelecao: number = 100;
    
    // Configurações
    atividadesExercidas: Array<Atividade>;
    atividadesNaoExercidas: Array<Atividade>;
    atividadesOriginaisExercidas: Array<Atividade>;
    formasAtuacao: Array<FormaAtuacao>;
    formasAtuacaoSelecionadas: Array<string>;

    emitirErro: boolean;
    mensagemErro: string;
    abrirPainel: boolean;
    bloqueadoPorCriticas: boolean = false;

    emitirCritica: boolean = false;
    mensagemCritica: string = "";

    codigoCliente: string;
    cliente: string;

    constructor(
        private formBuilder: FormBuilder,
        private atividadeService: AtividadeService,
        private activatedRoute: ActivatedRoute,
        private filtro: FiltroPipe,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService) {

        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];
        
        this.observacaoTipoUnidadeAuxiliar = "";
        this.tipoUnidadeAuxiliares = JSON.parse(localStorage.getItem('user')).configuracao.tipoUnidades;
        this.formasAtuacaoConfiguracao = JSON.parse(localStorage.getItem('user')).configuracao.formasAtuacao;

        this.atividades = new Array<Atividade>();
        this.atividadesSelecionadas = new Array<Atividade>();
        
        this.atividadesExercidas = new Array<Atividade>();
        this.atividadesNaoExercidas = new Array<Atividade>();
        this.atividadesOriginaisExercidas = new Array<Atividade>();
        this.formasAtuacao = new Array<FormaAtuacao>();
        this.formasAtuacaoSelecionadas = new Array<string>();

        this.emitirErro = false;
        this.abrirPainel = true;
            
        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
            tipoUnidade: ["1"],
            tipoUnidadeAuxiliar: [""],
            formaAtuacaoCheckBox: [""],
            descricaoAtividadeEconomica: ["", [Validators.required, Validators.maxLength(20000)]],
        });

        this.cliente = localStorage.getItem("pId");

    }

    ngOnInit(): void {
        this.buscarAtividades(false);
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Empresa || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Mei || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Oficio ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Empresa ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Mei){
            this.descricaoAtividadeEconomica.clearValidators();

            
        }

        this.codigoCliente = this.authenticationService.currentUserValue.cliente;
    }

    limpar(){
        this.tipoUnidade.reset();
        this.tipoUnidade.setValue("1");
        this.tipoUnidadeAuxiliar.reset();
        this.tipoUnidadeAuxiliar.setValue("");
        this.descricaoAtividadeEconomica.reset();
        this.descricaoAtividadeEconomica.setValue(""); 

        this.formasAtuacao = new Array<FormaAtuacao>();
        this.formasAtuacaoSelecionadas = new Array<string>();
        this.atividades = new Array<Atividade>();
    }

    buscarAtividades(abrirPouUp) {
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo){
            this.atividadeService
                .buscarCbo(localStorage.getItem("pId"))
                .pipe(take(1))
                .subscribe( retorno => {
                    this.atividades = retorno;
                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento) {  
                        this.atividades = this.filtro.transform(retorno, {campo: "evento", valor: true} );
                    }
                    
                    if (abrirPouUp) {
                        this.abrirPopUp();
                    }
                });
        }
        else{
            this.atividadeService
                .buscarCnae(localStorage.getItem("pId"))
                .pipe(take(1))
                .subscribe( retorno => {
                    this.atividades = retorno;
                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei ||
                        this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Mei) {  
                        this.atividades = this.filtro.transform(retorno, {campo: "mei", valor: true} );
                    }
                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento) {  
                        this.atividades = this.filtro.transform(retorno, {campo: "evento", valor: true} );
                    }
                    
                    if (abrirPouUp) {
                        this.abrirPopUp();
                    }
                });
        }
    }
    
    acaoBotaoAdicionarAtividade() {

        if (!this.atividades.length || this.tipoEmpresa != this.tipoEmpresaBusca) {
            this.tipoEmpresaBusca = this.tipoEmpresa;
            this.buscarAtividades(true);
            return true;
        }

        this.abrirPopUp();
    }

    abrirPopUp() {
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo){
            this.openDialogCbo([this.atividades, this.atividadesSelecionadas, Constantes.TIPO_EMPRESA.Autonomo]);
        }
        else{
            this.openDialog([this.atividades, this.atividadesSelecionadas, Constantes.TIPO_EMPRESA.Empresa]);
        }    
        
    }

    acaoRadioAtividadePrincipal(index) {
        this.atividadesSelecionadas.forEach( atividade => {
            atividade.principal = false;
        });
        
        this.atividadesSelecionadas[index].principal = true;

        this.quantidadeDeAtividade(this.atividadesSelecionadas);
        this.quantidadeDeAtividadeAutonomo(this.atividadesSelecionadas); 
    }

    acaoCheckBoxAtividadeExercida(index, campo: MatCheckbox, campoAtividadePrincipal: MatRadioButton) {
        /*if (!campo.checked) {
            campoAtividadePrincipal.checked = false;
        }*/
        this.atividadesSelecionadas[index].exercida = campo.checked;

        this.atividadePrincipal = campoAtividadePrincipal;
        this.montarQuantidadeAtividade();
        this.montarQuantidadeAtividadeAutonomo(); 

    }

    acaoExcluirAtividadeEscolhida(index) {
        this.atividadesSelecionadas.splice(index, 1);
        this.montarQuantidadeAtividade();
        this.montarQuantidadeAtividadeAutonomo(); 

    }

    acaoValidarAtividadeEscolhida(index) {
        var atividadesValidas = [];
        var atividadeInvalidaSelecionada = [this.atividadesSelecionadas[index]];

        this.atividadeService
            .buscarCnaeDescritores(atividadeInvalidaSelecionada[0].classe.replace(/\D/g,""))
            .pipe(take(1))
            .subscribe( retorno => {
                atividadesValidas = retorno;
                if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei ||
                    this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Mei) {  
                    atividadesValidas = this.filtro.transform(retorno, {campo: "mei", valor: true} );
                }
                if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento) {  
                    atividadesValidas = this.filtro.transform(retorno, {campo: "evento", valor: true} );
                }

                this.openDialogCnaeDescritores([atividadesValidas, atividadeInvalidaSelecionada, Constantes.TIPO_EMPRESA.Empresa, 1], index)
            });

    }

    acaoTipoUnidadeAuxiliarSelect(selecao) {
        this.tipoUnidadeAuxiliares.forEach( tipoUnidadeAuxiliar => {
            if (tipoUnidadeAuxiliar.codigo === selecao.value) {
                this.observacaoTipoUnidadeAuxiliar = `<strong>* Informação Adicional:</strong> ${ tipoUnidadeAuxiliar.observacao }`;
            }
        })

    }

    acaoRadioTipoUnidade() {
        if (this.tipoUnidade.value === "1") {
            this.tipoUnidadeAuxiliar.setValue("");
        }
    }

    acaoCheckBoxFormaAtuacao(valor, campo: MatCheckbox) {
        if (campo.checked) {
            this.formasAtuacaoSelecionadas.push(valor);
        }
        else {
            let index = this.formasAtuacaoSelecionadas.indexOf(valor);
            if (index >= 0) {
                this.formasAtuacaoSelecionadas.splice(index, 1);
            }
        }
    }

    acaoCheckedFormaAtuacao(formaAtuacao: FormaAtuacao) {
        let retorno = false;

        this.formasAtuacaoSelecionadas.forEach( formaAtuacaoSelecionada => {
            if (formaAtuacao.codigo == formaAtuacaoSelecionada) {
                retorno = true;
            }
        })

        return retorno;
    }

    formatarNomeAtividade(nome) {
        return formatToCapitalized(nome);
    }

    openDialog(dados) {
        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                titulo: "Lista de Atividades",
                componente: "grid-atividade",
                dados: dados
            }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result === "" || result === null)
                return false;

            this.atividadesSelecionadas = result.atividades.selected;

            if (this.atividadesSelecionadas.length > this.limiteSelecao) {
                Swal.fire("", `Não foi possível adicionar porque o limite máximo é de até ${ this.limiteSelecao } atividades.`, "error");
                this.atividadesSelecionadas = []
            }
            
            this.montarQuantidadeAtividade();
        });

    }

    openDialogCnaeDescritores(dados, index) {
        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                titulo: "Lista de Atividades",
                componente: "grid-atividade",
                dados: dados
            }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result === "" || result === null)
                return false;
            
            result.atividades.selected[0].principal = this.atividadesSelecionadas[index].principal;
            result.atividades.selected[0].exercida = this.atividadesSelecionadas[index].exercida;
            this.atividadesSelecionadas.splice(index, 1);
            this.atividadesSelecionadas.push(result.atividades.selected[0]);

            if (this.atividadesSelecionadas.length > this.limiteSelecao) {
                Swal.fire("", `Não foi possível adicionar porque o limite máximo é de até ${ this.limiteSelecao } atividades.`, "error");
                this.atividadesSelecionadas = []
            }
            
            this.montarQuantidadeAtividade();
        });

    }

    openDialogCbo(dados) {
        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                titulo: "Lista de Atividades",
                componente: "grid-atividade",
                dados: dados
            }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result === "" || result === null)
                return false;

            this.atividadesSelecionadas = result.atividades.selected;
            
            this.montarQuantidadeAtividadeAutonomo(); 
        });

    }

    validarFormulario() {
        this.controleValidade.setValue("");
        
        if (this.formulario.invalid) {
            let mensagemErro = "";
            
            if (!this.bloqueadoPorCriticas) {
                if (!this.atividadesSelecionadas.length) {
                    mensagemErro += "Favor adicionar suas atividades.<br>"
                }
                else {
                    let existeExercida = false;
                    let existePrincipal = false;
                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo ||
                        this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo ||
                        this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo ||
                        this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ||
                        this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo){
                        existeExercida = true;
                        existePrincipal = true;
                    }
                    this.atividadesSelecionadas.forEach( atividade => {
                        if (atividade.exercida) {
                            existeExercida = true;
                        }
                        if (atividade.principal) {
                            existePrincipal = true;
                        }
                    });
                    // if (!existeExercida) {
                    //     mensagemErro += "Favor informar a(s) atividade(s) exercida(s).<br>"
                    // }
                    if (!existePrincipal) {
                        mensagemErro += "Favor informar sua atividade principal.<br>"
                    }
                }
            }

            // if (this.tipoEstabelecimento.invalid) {
            //     mensagemErro += "Favor informar o tipo de estabelecimento.<br>"
            // }
            // if (this.tipoEstabelecimento.value == "F") {
            //     if (this.cnpjFilial.invalid) {
            //         mensagemErro += "Informar o CNPJ da Matriz.<br>"
            //     }
            // }
            if (this.tipoUnidade.invalid) {
                mensagemErro += "Favor informar o tipo de unidade.<br>"
            }
            if (this.tipoUnidade.value == 1) {
                if (!this.formasAtuacaoSelecionadas.length && 
                    (this.tipoEmpresa != Constantes.TIPO_EMPRESA.Baixa_Mei && 
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Baixa_Empresa && 
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Baixa_Autonomo &&
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio &&
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo &&
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa &&
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei &&
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Empresa_Evento &&
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Autonomo_Evento &&
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Alteracao_Oficio_Empresa &&
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Alteracao_Oficio_Mei &&
                    this.tipoEmpresa != Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo)) {
                    mensagemErro += "Informar ao menos uma forma de atuação.<br>"
                }
            }
            if (this.descricaoAtividadeEconomica.value == "" && 
                (this.tipoEmpresa != Constantes.TIPO_EMPRESA.Autonomo &&
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Baixa_Mei && 
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Baixa_Empresa && 
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Baixa_Autonomo &&
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio &&
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo &&
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa &&
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei &&
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Empresa_Evento &&
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Autonomo_Evento &&
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo &&
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Alteracao_Oficio_Empresa &&
                this.tipoEmpresa != Constantes.TIPO_EMPRESA.Alteracao_Oficio_Mei)) {
                mensagemErro += "Descreva suas atividades e como será o funcionamento do seu negócio.<br>"
            }

            if (mensagemErro === "") {
                this.controleValidade.setValue(true);
                this.emitirErro = false;
                return true;
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErro = true;
            this.mensagemErro = mensagemErro;

            return false;
        }

        this.emitirErro = false;
        this.mensagemErro = "";
        
        return true;
    }

    preencher() {
        this.atividadesExercidas = new Array<Atividade>();
        this.atividadesNaoExercidas = new Array<Atividade>();

        this.atividadesSelecionadas.forEach( atividade => {
            if (atividade.exercida) {
                this.atividadesExercidas.push(atividade);
            }
            else {
                this.atividadesNaoExercidas.push(atividade);
            }
        });

        if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento){
            this.atividadesExercidas = [this.atividadesSelecionadas[0]];
        }

        this.formasAtuacao = new Array<FormaAtuacao>();

        this.formasAtuacaoSelecionadas.forEach( formaAtuacaoSelecionada => {
            this.formasAtuacaoConfiguracao.forEach( formaAtuacao => {
                if (formaAtuacaoSelecionada == formaAtuacao.codigo) {
                    this.formasAtuacao.push(formaAtuacao)
                }
            });
        });
        
    }

    preencherCamposCriticas(protocolo: Protocolo) {
        this.bloqueadoPorCriticas = true;

        this.atividadesSelecionadas = protocolo.viabilidade.atividadesExercidas.concat(protocolo.viabilidade.atividadesNaoExercidas);
        this.atividadesExercidas = protocolo.viabilidade.atividadesExercidas;
        this.atividadesNaoExercidas = protocolo.viabilidade.atividadesNaoExercidas;
        if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento){
            this.atividadesExercidas = protocolo.viabilidade.atividadesExercidas;
        }
        
        this.tipoUnidade.setValue(protocolo.viabilidade.tipoUnidade.toString());
        this.tipoUnidadeAuxiliar.setValue(protocolo.viabilidade.tipoUnidadeAuxiliar.toString());

        this.formasAtuacao = protocolo.viabilidade.formasAtuacao;
        this.descricaoAtividadeEconomica.setValue(protocolo.viabilidade.descricaoAtividadeEconomica);

        if (protocolo.criticas.length && protocolo.criticas.filter(item => { return item.campo == "atividadesExercidas" || item.campo == "atividadesNaoExercidas" }).length) {
            this.emitirCritica = true;
            
            protocolo.criticas.forEach(item => { 
                if (item.campo == "atividadesExercidas" || item.campo == "atividadesNaoExercidas" ) {
                    this.mensagemCritica += item.nomeCampo + "<br>";
                }
            });
        }

        this.mensagemCritica += Protocolo.exibirCriticasFormulario(this.formulario, protocolo.criticas);
        if (this.mensagemCritica != "") {
            this.emitirCritica = true;
        }

        this.montarQuantidadeAtividade();
        this.montarQuantidadeAtividadeAutonomo(); 
        
    }

    montarQuantidadeAtividade(){
        this.quantidadeDeAtividade(this.atividadesSelecionadas);
    }

    quantidadeDeAtividade(atividadesSelecionadas) {
        this.quantidadeExercida = atividadesSelecionadas.filter(atividade => atividade.exercida == true).length;
        this.quantidadeNaoxercida = atividadesSelecionadas.filter(atividade => atividade.exercida == false).length;
        this.quantidadePrincipal = atividadesSelecionadas.filter(atividade => atividade.principal == true).length;
    }
    
    montarQuantidadeAtividadeAutonomo(){
        this.quantidadeDeAtividadeAutonomo(this.atividadesSelecionadas);
    }

    quantidadeDeAtividadeAutonomo(atividadesSelecionadas){
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo){

                this.quantidadeExercida = atividadesSelecionadas.filter(atividade => atividade.exercida == true).length;
                this.quantidadePrincipal = atividadesSelecionadas.filter(atividade => atividade.principal == true).length;
        }
        
    }

    visualizarListaServicos(){
        const url = `https://wwwx.gissonline.com.br/Rel/rol_atividades.cfm?pid=${this.codigoCliente}`;
        window.open(url, '_blank');        
    }

    get controleValidade() {
        return this.formulario.get("controleValidade");
    }
    set controleValidade(controleValidade) {
        this.formulario.get("controleValidade").setValue(controleValidade);
    }

    get tipoUnidade() {
        return this.formulario.get("tipoUnidade");
    }
    set tipoUnidade(tipoUnidade) {
        this.formulario.get("tipoUnidade").setValue(tipoUnidade);
    }

    get tipoUnidadeAuxiliar() {
        return this.formulario.get("tipoUnidadeAuxiliar");
    }
    set tipoUnidadeAuxiliar(tipoUnidadeAuxiliar) {
        this.formulario.get("tipoUnidadeAuxiliar").setValue(tipoUnidadeAuxiliar);
    }

    get formaAtuacaoCheckBox() {
        return this.formulario.get("formaAtuacaoCheckBox");
    }
    set formaAtuacaoCheckBox(formaAtuacaoCheckBox) {
        this.formulario.get("formaAtuacaoCheckBox").setValue(formaAtuacaoCheckBox);
    }

    get descricaoAtividadeEconomica() {
        return this.formulario.get("descricaoAtividadeEconomica");
    }
    set descricaoAtividadeEconomica(descricaoAtividadeEconomica) {
        this.formulario.get("descricaoAtividadeEconomica").setValue(descricaoAtividadeEconomica);
    }

}