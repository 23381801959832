import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { RequisitoService } from '../../../services/requisito/requisito.service';
import { take } from 'rxjs/operators';
import { CadastrarRequisitoComponent } from './cadastrar-requisito/cadastrar-requisito.component';
import { Constantes } from '../../../model/constantes';
import Swal from 'sweetalert2';
import { ConfiguracaoService } from '../../../services/configuracao/configuracao.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-gerenciamento-de-requisitos',
  templateUrl: './gerenciamento-de-requisitos.component.html',
  styleUrls: ['./gerenciamento-de-requisitos.component.scss'],
})
export class GerenciamentoDeRequisitosComponent implements OnInit {

  exibirListagem = true;
  exibirFormularioDeCadastro = false;
  rota = null;

  @ViewChild(CadastrarRequisitoComponent)
  cadastrarRequisito: CadastrarRequisitoComponent;
  
  constructor(private configuracaoService: ConfiguracaoService,
    private requisitoService: RequisitoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,) {
      this.rota = this.router.url;
    }

  ngOnInit(): void {}
  
  acaoBotaoVoltar(event) {
    this.router.navigate(["/configuracao/viabilidade"]);
  }
  
  acaoNovoRequisito(){
    this.router.navigate([ this.router.url, "cadastroRequisito" ]);
  }

  acaoAlterarRequisito(codigoRequisito) {
    this.router.navigate([ this.router.url, "editarRequisito", codigoRequisito ]);    
  }
  
  acaoAtendimento(event) {
    if(event.acao == Constantes.ACAO_ATENDIMENTO.Alterar) {
      this.acaoAlterarRequisito(event.dados.COD_REQUISITO);
    }
    else if(event.acao == Constantes.ACAO_ATENDIMENTO.Excluir) {
      this.acaoExcluirRequisito(event.dados.COD_REQUISITO, event.dados.DESC_REQUISITO);
    }
  }

  acaoExcluirRequisito(codigoRequisito, nomeRequisito) {
    let html = "";

    html += `
      <div class="text-left">
        Deseja realmente excluir o requisito <strong>${ codigoRequisito} - ${ nomeRequisito }</strong>.<br><br>
      </div>
    `;

    Swal.fire({
      html: html,
      icon: 'question',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    })
    .then((result) => {
      if (!result.dismiss) {
        Swal.fire("", "O requisito vai ser tornar inativo", "success");
        this.requisitoService
        .excluirRequisito(codigoRequisito)
        .pipe(take(1))  
          .subscribe(retorno => {
            if (retorno) {
              Swal.fire("", "Requisito inativado com sucesso! ", "success");
              location.reload();

            }
            else {
              Swal.fire("", "Não foi possível excluir o Requisito!", "error");
            }
        })
      }      
    })
  }


}