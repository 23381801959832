import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PesquisaViabilidadeComponent } from './pesquisa-viabilidade.component';
import { OutrosModule } from '../outros/outros.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { NgxMaskModule } from 'ngx-mask';
import { PipeModule } from '../../pipes/pipes.module';
import { GoogleMapsComponentModule } from '../outros/google-maps/google-maps-component.module';
import { PesquisaViabilidadeIdentificacaoComponent } from './pesquisa-viabilidade-identificacao/pesquisa-viabilidade-identificacao.component';
import { AlertModule } from '../../components/alert/alert.module';


@NgModule({
  declarations: [
    PesquisaViabilidadeComponent,
    PesquisaViabilidadeIdentificacaoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatDividerModule,
    NgxMaskModule.forRoot(),
    OutrosModule,
    PipeModule,
    AlertModule,
    GoogleMapsComponentModule,
  ],
  exports: [
    PesquisaViabilidadeComponent
  ],
})
export class PesquisaViabilidadeModule {}
