import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Requisito } from '../../../../model/requisito';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import Swal from 'sweetalert2';
import { Constantes } from '../../../../model/constantes';
import { Observacao } from '../../../../model/observacao';
import { RequisitoService } from '../../../../services/requisito/requisito.service';
import { take } from 'rxjs/operators';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { Pendencia } from '../../../../model/pendencia';
import { Atendimento } from '../../../../model/atendimento';
import { MatDialog } from '@angular/material/dialog';
import { AtendimentoRequisitoAcaoComponent } from './atendimento-requisito-acao.component';
import { Licenciamento } from '../../../../model/licenciamento';
import { Empresa } from '../../../../model/empresa';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormValidator } from '../../../../components/validators/form-validator';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { Documento } from '../../../../model/documento';
import { isDate, formatToDate } from 'brazilian-values';
import { Despacho } from 'src/app/model/despacho';
import { AtendimentoObservacaoComponent } from '../atendimento-observacao/atendimento-observacao.component';
import { DespachoFluxo } from 'src/app/model/despachoFluxo';
import { AtendimentoService } from 'src/app/services/operacao/atendimento.service';
import { Cliente } from '../../../../model/cliente';

@Component({
  selector: 'app-atendimento-empresa-requisitos',
  templateUrl: './atendimento-empresa-requisitos.component.html',
  styleUrls: ['./atendimento-empresa.component.scss']
})
export class AtendimentoEmpresaRequisitosComponent implements OnInit {

  @Output() acaoDeAtendimento = new EventEmitter();
  @Input() semCardHeader: boolean = false;

  formulario: FormGroup;
  numeroProtocolo: string;
  empresa: Empresa;
  requisitos: Array<Requisito>;
  licenciamentos: Array<Licenciamento>;
  orgao: string;
  nomeOrgao: string;
  tipoDeBloqueioRequisito = Constantes.TIPO_DE_BLOQUEIO_REQUISITO;
  tipoDeBloqueioRequisitoDescricao = Constantes.TIPO_DE_BLOQUEIO_REQUISITO_DESCRICAO;
  tipoDePendenciaLicenciamento = Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO;
  tipoDocumento = Constantes.TIPO_DE_DOCUMENTO;
  tipoDocumentoDescricao = Constantes.TIPO_DE_DOCUMENTO_DESCRICAO;
  eDespacho: boolean = false;
  atendimentoDespacho: Despacho;

  tipoEmpresa: string;
  permitirAtendimento: boolean;
  emitirErro: boolean;
  mensagemErro: string;
  
  htmlValidade: string;

  pendencias: Array<Pendencia>;
  pendenciasSelecionadas: Array<Pendencia>;

  despachos: Array<Despacho>;

  exibirDadosTfe: boolean = false;
  cliente: string;
  codRequisito: string;
  descObservacaoAtual: string;
  descObservacaoTfe: string = "";
  dtIndeterminadaAtual: string;
  dtIndeterminadaTfe: string;
  dtSeriaModificado: string;
  dtVencimentoAtual: string;
  dtVencimentoTfe: string;
  emprInscMobiliaria: string;
  requisito: string;
  situacaoAtual: string;
  situacaoTfe: string;
  
  @ViewChild(AtendimentoRequisitoAcaoComponent)
  atendimentoRequisitoAcao: AtendimentoRequisitoAcaoComponent;

  @ViewChild("observacoesDespacho")
  atendimentoObservacaoDespacho: AtendimentoObservacaoComponent;
  
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private requisitoService: RequisitoService,
    private arquivoService: ArquivoService,
    private atendimentoService: AtendimentoService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,) {

    this.orgao = this.activatedRoute.snapshot.params["codigoOrgao"];
    this.nomeOrgao = this.activatedRoute.snapshot.params["orgao"];
    this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];
    
    let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
    this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !meusNegocios);
    
    this.eDespacho = this.activatedRoute.snapshot.data["subMenu"] == "despacho";

    if (this.eDespacho) {
      this.atendimentoDespacho = new Despacho();
      this.atendimentoDespacho.codigo = this.activatedRoute.snapshot.params["codigoDespacho"];
      this.atendimentoDespacho.protocolo = this.numeroProtocolo;
      this.atendimentoDespacho.inscricao = this.activatedRoute.snapshot.params["inscricao"];
    }

    this.pendencias = new Array<Pendencia>();
    this.pendenciasSelecionadas = new Array<Pendencia>();

    this.htmlValidade = "";

    this.formulario = this.formBuilder.group({
      dataValidade: ['', [Validators.required, FormValidator.dataValida()]]
    });
  }

  ngOnInit(): void {    
    this.authenticationService.currentAxis.subscribe((axis) => {
      if (axis != "" && axis != null) {
        axis.item.forEach(item => {
          if(item.orgao.toString() == this.orgao){
            this.nomeOrgao = item.titulo.toUpperCase();
          }
        });
      }            
    });

    if (this.eDespacho) {
      setTimeout(() => {
        this.atendimentoObservacaoDespacho.tituloHistorico = "Histórico de Despachos";
        this.atendimentoObservacaoDespacho.permitirAtendimento = false;
        this.preencherAtendimentoDespacho()
      }, 100);
    }

    if(this.activatedRoute.snapshot.params["codigoOrgao"] == 640){
      this.buscarBaixaTfe();
    }
    
  }

  async preencherAtendimentoDespacho() {
    let observacoesDespacho = new Array<Observacao>();

    await this.atendimentoService
      .buscarDespachosParaAtendimento(this.activatedRoute.snapshot.params["codigoDespacho"])
      .pipe(take(1))
      .subscribe( (retorno: Despacho) => {             
        retorno.fluxos.forEach( fluxo => {
          let observacao = new Observacao();
          observacao.data = fluxo.data;
          observacao.avaliacao = fluxo.status;
          observacao.processo = `${ fluxo.orgaoOrigem.codigo } - ${ fluxo.orgaoOrigem.nome }`;
          observacao.login.nome = fluxo.login.nome;
          observacao.observacao = fluxo.observacao;
          observacoesDespacho.push(observacao);
        });
    
        this.atendimentoObservacaoDespacho.preencher(observacoesDespacho);
    });

    
  }

  async buscarBaixaTfe() {
      
    await this.atendimentoService
      .buscarBaixaTfe(this.activatedRoute.snapshot.params["inscricao"])
      .pipe(take(1))
      .subscribe( (retorno) => {

        this.exibirDadosTfe = retorno.length > 0;

        retorno.forEach( linha => {
          this.cliente = linha.Cliente;
          this.codRequisito = linha.Cod_Requisito;
          this.descObservacaoAtual = linha.Desc_Observacao_Atual;
          this.descObservacaoTfe = linha.Desc_Observacao_Tfe.replaceAll('\r\n','<br>').replaceAll('\n','<br>').replaceAll('\r','<br>');
          this.dtIndeterminadaAtual = linha.Dt_Indeterminada_Atual;
          this.dtIndeterminadaTfe = linha.Dt_Indeterminada_Tfe;
          this.dtSeriaModificado = linha.Dt_Seria_Modificado;
          this.dtVencimentoAtual = linha.Dt_Vencimento_Atual;
          this.dtVencimentoTfe = linha.Dt_Vencimento_Tfe;
          this.emprInscMobiliaria = linha.Empr_Insc_Mobiliaria;
          this.requisito = linha.Requisito;
          this.situacaoAtual = linha.Situacao_Atual;
          this.situacaoTfe = linha.Situacao_Tfe;    
        });

    });
  }  

  visualizarParcelas(requisito) {
      
    this.openDialog("grid-parcelas-tfe", [requisito]);
  }

  openDialog(componente, dados) {

    const dialogRef = this.dialog.open(IcadModalComponent, {
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        maxHeight: 'none',
        closeOnNavigation: true,
        data: {
            componente: componente,
            dados: dados
        }
    });

    // dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
  }

  atender(acaoAtendimento, requisito) {
    if (acaoAtendimento.acao === Constantes.ACAO_ATENDIMENTO.Atualizar_Licenciamento) {
      this.atualizarLicenciamento(acaoAtendimento.licenciamento);
      return false;
    }
    if (acaoAtendimento.acao === Constantes.ACAO_ATENDIMENTO.Pendenciar) {
      this.confirmarAtendimento(acaoAtendimento, requisito, "");
      return false;
    }

    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '80%',
      height: '95%',
      maxWidth: 'none',
      maxHeight: 'none',
      closeOnNavigation: true,
      data: {
          titulo: "Pareceres",
          componente: "atendimento-pareceres",
          dados: [
            "requisito",
            localStorage.getItem("pId"), 
            this.activatedRoute.snapshot.params["codigoOrgao"], 
            requisito == null ? "0" : requisito.codigo,
            acaoAtendimento.acao,
            ""
          ]
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result.parecerFinal != null) {
        var observacao = result.parecerFinal;
        this.confirmarAtendimento(acaoAtendimento, requisito, observacao)
      }
    });

  }

  confirmarAtendimento(acaoAtendimento, requisito, observacaoPreenchida) {

    let mensagemHtml = "";
    if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Deferir) {
      mensagemHtml = `Confirma o deferimento do licenciamento?<br><br> <b>Parecer: </b><br> ${ observacaoPreenchida }`;
    }
    else if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Indeferir) {
      mensagemHtml = `Confirma o indeferimnento do licenciamento?<br><br> <b>Parecer: </b><br> ${ observacaoPreenchida }`;
    }
    else if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Cancelar) {
      mensagemHtml = `Confirma o cancelamento do licenciamento?<br><br> <b>Parecer: </b><br> ${ observacaoPreenchida }`;
    }
    else if(acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Pendenciar){
      mensagemHtml = `Confirma a pendência do licenciamento?`;

      Swal.fire({
        title: "", 
        html: mensagemHtml,
        icon: "question",
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        showCancelButton: true
      })
      .then((result) => {
        if (result.value) {
          requisito.statusAvaliacao = acaoAtendimento.acao;
          let observacao = new Observacao();
          observacao.observacao = result.value;
          requisito.observacoes = [observacao];
          if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Pendenciar) {
            let atendimento = new Atendimento();
            atendimento.pendencias = acaoAtendimento.pendenciasSelecionadas;
            requisito.atendimentos = [atendimento];
          }
          
          this.executarAtendimento(requisito);
        }
        });

      return false;
    }
    else if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Atendimento_Licenciamento) {
      //this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
      document.location.reload();
      return false;
    }
    else if(acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Responder_Despacho){
      mensagemHtml = `Confirma o despacho?<br><br> <b>Parecer: </b><br> ${ observacaoPreenchida }`;
    }

    if (this.validar(acaoAtendimento)) {
      Swal.fire({
        title: "", 
        html: mensagemHtml,
        icon: "question",
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        showCancelButton: true,
        buttonsStyling: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then((result) => {
        if (result.value) {
          if (this.eDespacho) {
            this.executarAtendimentoDespacho(observacaoPreenchida);
          }
          else {
            requisito.statusAvaliacao = acaoAtendimento.acao;
            let observacao = new Observacao();
            observacao.observacao = observacaoPreenchida;
            requisito.observacoes = [observacao];
            if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Pendenciar) {
              let atendimento = new Atendimento();
              atendimento.pendencias = acaoAtendimento.pendenciasSelecionadas;
              requisito.atendimentos = [atendimento];
            }

            this.executarAtendimento(requisito);
          }         
        }
      });
    }
    
  }

  executarAtendimento(requisito) {
    this.requisitoService
      .atendeRequisito(requisito)
      .pipe(take(1))
      .subscribe( retorno => {
        if (retorno.documento != null && retorno.documento != "") {
          Swal.fire({
            title: retorno.titulo,
            html: retorno.mensagem,
            icon: retorno.status,
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            willClose: () => {
            }
          })
          .then((result) => {
            if (!result.dismiss) {
              this.arquivoService.downloadArquivoAtendimento(retorno.documento.toString())
                .subscribe((res: any) => {
                  this.arquivoService.handleFile(res, retorno.documento.toString() + '.pdf');
                });
            }
          });
        }
        else {
          Swal.fire({
            title: retorno.titulo,
            html: retorno.mensagem,
            icon: retorno.status,
            width: 700,
            willClose: () => {
              this.acaoDeAtendimento.emit({ acao: "acaoAtendimento", empresa: retorno.dados});
            }
          });
        }
      });
  }

  executarAtendimentoDespacho(observacao) {
    let caminhoRetorno = "../../../";

    var despachoFluxo = new DespachoFluxo();
    despachoFluxo.orgaoOrigem.codigo = this.orgao;
    despachoFluxo.observacao = observacao;

    this.atendimentoDespacho.fluxos = [despachoFluxo];

    this.atendimentoService
      .responderDespacho(this.atendimentoDespacho)
      .pipe(take(1))
      .subscribe(retornoAtendimento => {
        Swal.fire({
          title: retornoAtendimento.titulo,
          html: retornoAtendimento.mensagem,
          icon: retornoAtendimento.status,
          willClose: () => {
            this.router.navigate([caminhoRetorno], {relativeTo: this.activatedRoute}); 
          }
        });
    })
  }

  inserirValidade(requisito){
    if (requisito.statusAvaliacao == "C")
      return false;

    this.criarHtmlValidade(requisito);

    Swal.fire({
      icon: 'question',
      html: this.htmlValidade,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      didOpen: () => {
        this.semValidade();
      },
      preConfirm: () => {
        if ($('input[id=vencimento]').val() != "INDETERMINADO" && new Date($('input[id=vencimento]').val().toString().split("/").reverse().toString()) < new Date(new Date().toLocaleDateString().split("/").reverse().toString())) {
          Swal.showValidationMessage("A data de vencimento tem que ser maior que a data atual.")
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
    .then((result) => {
      if (!result.dismiss) {
        requisito.vencimento = $('input[id=vencimento]').val();     
      }
    });
  }
 
  criarHtmlValidade(requisito: Requisito){
    this.htmlValidade = `
      <div class="row col-12 justify-content-center align-items-center">
        <p class="w-100">
          <b>${ requisito.codigo } - ${ requisito.nome }</b>
        </p>
      </div>
      <br><br>
      <div class="row col-12 justify-content-center align-items-center">
        <p class="w-100">
          Qual o vencimento para esse Requisito ?
        </p>
      </div>
      <div class="row col-12">
        <div class="row col-8 justify-content-center align-items-center">
          <p class="w-100">
            <input type="text" id="vencimento" name="vencimento" value="${ requisito.vencimento }" class="swal2-input text-center" mask="00/00/0000">
          </p>
        </div>
        <div class="row col-4 justify-content-center align-items-center">
          <p class="w-100">
            <input type="checkbox" id="semValidade" name="semValidade" value="" > Sem validade
          </p>
        </div>
      </div>
    `;
  }

  retornarListaDeDocumentos(lista: Array<Documento>) {
    var listaRetorno = "";

    lista.forEach(documento => { listaRetorno = listaRetorno.concat(documento.nome, " - ") });
    listaRetorno = listaRetorno.substring(0, listaRetorno.length - 3);
    
    return listaRetorno;
  }
  
  semValidade() {
    $("input[id=vencimento]").mask("00/00/0000");
    $("input[id=semValidade]").on("click", function(){
      if ($("input[id=semValidade]:checked").length) {
        $("input[id=vencimento]").val("INDETERMINADO");
        $("input[id=vencimento]").prop("disabled", true);
      } else if (!$("input[id=semValidade]:checked").length) {
        $("input[id=vencimento]").val(null);
        $("input[id=vencimento]").prop("disabled", false);
      }
    });
  }

  atualizarLicenciamento(licenciamento: Licenciamento) {
    var licenciamentoEncontrado = this.licenciamentos.findIndex( licenciamentoAuxiliar => { 
      return licenciamentoAuxiliar.requisito.orgao.codigo == licenciamento.requisito.orgao.codigo && licenciamentoAuxiliar.requisito.codigo == licenciamento.requisito.codigo
    });

    if (licenciamentoEncontrado > -1) {
      Object.assign(this.licenciamentos[licenciamentoEncontrado], licenciamento);
    }

  }

  // ---------------------------------------------------------
  // Validações do pedido de atendimento
  // ---------------------------------------------------------
  validar(acaoAtendimento) {
    if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Pendenciar) {
      return true;
    }

    return true;
    
  }

  get dataValidade() {
      return this.formulario.get("dataValidade");
  }  
  set dataValidade(dataValidade) {
      this.formulario.get("dataValidade").setValue(dataValidade);
  }


}
