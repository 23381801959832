<div
  class="d-none"
  aria-label="sprite icons"
  [inlineSVG]="'/assets/sprite.svg'"
></div>
<app-header-portal 
    (acaoHeader)="acaoClickHeader($event)"></app-header-portal>
<div
  class="main-container sidebar-closed"
  id="container"
>
    <div id="content" class="main-content">
        <div class="pcoded-wrapper">
            <div class="pcoded-content">
                <div class="pcoded-inner-content">
                    <div class="main-body">
                        <div class="row col-12 justify-content-center align-items-center"
                            [class.hidden]="exibirMenu">
                            <button class="col-3 btn btn-block btn-success"
                                [routerLink]="['/portal-home']"
                                title="Voltar"
                                id="btnVoltar"
                            >
                              Voltar para o portal
                            </button>
                        </div>
                        <div [class.hidden]="!exibirMenu"
                            class="titulo-portal">
                            Portal do Empreendedor <br>
                            <span class="titulo-linha"></span>
                        </div>
                        <div [class.hidden]="!exibirMenu"
                            id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                            </ol>
                            <div class="carousel-inner">
                                <div class="carousel-item active cursor-pointer"
                                    (click)="acaoClickHeader({acao: 'PESQUISA-VIABILIDADE'})">
                                    <img src="../../../../assets/img/placeholder/carrosel-1.png" class="d-block w-100" alt="Abra sua empresa">
                                    <div class="carousel-caption d-none d-md-block">
                                        <h5>Abra já sua empresa!</h5>
                                        <p>
                                            Clique aqui e faça a pesquisa de viabilidade do seu negócio.<br>
                                            Com a iCadOnline você pode abrir, alterar ou encerrar sua empresa de forma on-line.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                        <div class="row justify-content-center align-items-center"
                            [class.hidden]="!exibirMenu">
                            <div class="col-12 titulo-portal-dados text-center">
                                Veja o desempenho da cidade
                            </div>
                            <div class="arrow-down">
                                <div class="arrow-down-interna">
                                    <div class="arrow-down-interna-branca"></div>
                                </div>
                            </div>
                            <div class="col-12 portal-dados text-center">
                                <div class="simple--counter-container">
                                    <div class="counter-container">
                                        <div class="counter-content quadro-dados">
                                            <h1 class="s-counter2 s-counter contador-dados">{{ dados.processosAgilizados }}</h1>
                                        </div>
                                        <p class="s-counter-text titulo-quadro-dados">PROCESSOS AGILIZADOS</p>
                                    </div>
                                    <div class="counter-container">
                                        <div class="counter-content quadro-dados">
                                            <h1 class="s-counter3 s-counter contador-dados">{{ dados.negociosAbertos }}</h1>
                                        </div>
                                        <p class="s-counter-text titulo-quadro-dados">NEGÓCIOS ABERTOS</p>
                                    </div>
                                    <div class="counter-container">
                                        <div class="counter-content quadro-dados">
                                            <h1 class="s-counter4 s-counter contador-dados">{{ dados.atendimentosRealizados }}</h1>
                                        </div>
                                        <p class="s-counter-text titulo-quadro-dados">ATENDIMENTOS REALIZADOS</p>
                                    </div>
                                    <div class="counter-container">
                                        <div class="counter-content quadro-dados">
                                            <h1 class="s-counter4 s-counter contador-dados">{{ dados.alvarasGerados }}</h1>
                                        </div>
                                        <p class="s-counter-text titulo-quadro-dados">ALVARÁS GERADOS</p>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <app-home id="navigation" [class.hidden]="!exibirMenu"></app-home>
                        <app-pesquisa-viabilidade [class.hidden]="!exibirPesquisaViabilidade"></app-pesquisa-viabilidade>
                        <app-autenticidade-alvara [class.hidden]="!exibirVerifiqueAutenticidadeDoAlvara"></app-autenticidade-alvara>
                        <app-faq [class.hidden]="!exibirFaq"></app-faq>
                        <app-cadastrar-usuario [class.hidden]="!exibirCadastroDeUsuario" [exibirBotaoFechar]="false"></app-cadastrar-usuario>
                        <app-acessibilidade [class.hidden]="!exibirAcessibilidade"></app-acessibilidade>
                        <app-termo [class.hidden]="!exibirTermo"></app-termo>
                        <app-mapa-do-site [class.hidden]="!exibirMapaDoSite"></app-mapa-do-site>
                        <div class="page-wrapper conteudo-container">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer id="footer"></app-footer>
