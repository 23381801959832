import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class CreatePdf {
    
    public documento;

    private documentoPadrao = {
        footer: function(currentPage, pageCount) { return currentPage.toString() + ' de ' + pageCount; },
        header: function(currentPage, pageCount, pageSize) {
            return [
                { text: 'simple text', alignment: (currentPage % 2) ? 'left' : 'right' },
                { canvas: [ { type: 'rect', x: 170, y: 32, w: pageSize.width - 170, h: 40 } ] }
            ]
        },
        content: [],
        styles: {}
    }

    constructor() {
        this.documento = this.documentoPadrao;
    }

    abrirPDF() {
        pdfMake.createPdf(this.documento).open();
    }
}