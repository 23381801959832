import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class FaqService {

    constructor(private http: HttpClient, private router: Router) {}

    avaliacaoPositivaFaq(faq) {
        return this.http.post<any>(
        `${ environment.icadUrl }/faq/avaliacaoPositivaFaq/`, JSON.stringify(faq),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    avaliacaoNegativaFaq(faq) {
        return this.http.post<any>(
        `${ environment.icadUrl }/faq/avaliacaoNegativaFaq/`, JSON.stringify(faq),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarFaqSuporte(cliente) {
        return this.http.get<any>(
        `${ environment.icadUrl }/faq/buscarFaqSuporte/${ cliente }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    salvarConfiguracaoSuporte(faqSuporte) {
        return this.http.post<any>(
        `${ environment.icadUrl }/faq/salvarConfiguracaoSuporte/`, JSON.stringify(faqSuporte),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarModuloFaq(cliente, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/faq/buscarModuloFaq/${ cliente }/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarModulosFaq(cliente) {
        return this.http.get<any>(
        `${ environment.icadUrl }/faq/buscarModulosFaq/${ cliente }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    salvarModuloFaq(faqModulo) {
        return this.http.post<any>(
        `${ environment.icadUrl }/faq/salvarModuloFaq/`, JSON.stringify(faqModulo),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarSubModuloFaq(cliente, codigoModulo, codigoSubModulo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/faq/buscarSubModuloFaq/${ cliente }/${ codigoModulo }/${ codigoSubModulo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    salvarSubModuloFaq(faqModulo) {
        return this.http.post<any>(
        `${ environment.icadUrl }/faq/salvarSubModuloFaq/`, JSON.stringify(faqModulo),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPerguntaFaq(cliente, codigoModulo, codigoSubModulo, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/faq/buscarPerguntaFaq/${ cliente }/${ codigoModulo }/${ codigoSubModulo }/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    salvarPerguntaFaq(formData) {
        return this.http.post<any>(
        `${ environment.icadUrl }/faq/salvarPerguntaFaq`, formData,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }
    
}