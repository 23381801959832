import { ResumoCadastroComponent } from './../resumo-cadastro/resumo-cadastro.component';
import { take } from 'rxjs/operators';

import { LoginService } from './../../../../../services/login/login.service';
import { CadastrarUsuarioComponent } from './../cadastrar-usuario/cadastrar-usuario.component';
import { AuthenticationService } from './../../../../../services/authentication/authentication.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Login } from 'src/app/model/login';
import { Constantes } from 'src/app/model/constantes';
import { formatToCPFOrCNPJ, formatToPhone } from 'brazilian-values';
import { Documento } from 'src/app/model/documento';


@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  cadastrarUsuarioEvent: boolean;
  documento: Documento;
  
  @ViewChild(CadastrarUsuarioComponent)
  cadastrarUsuario: CadastrarUsuarioComponent;

  @ViewChild(ResumoCadastroComponent)
  resumoCadastro: ResumoCadastroComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private loginService: LoginService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.buscarPorLogin();
      this.cadastrarUsuario.formUsuario.cpfCnpj.disable();
    }, 1);
  }

  buscarPorLogin(){
           
    let login = new Login();
    login.login = parseInt(localStorage.getItem("userId").replace(/\D/g,""));
    this.loginService
        .buscarPorLogin(login)
        .pipe(take(1))
        .subscribe( (dados: Login) => { 
            if (!dados.login) {
                return false;
            }
            this.preencherLogin(dados);
        },
        (err) => {
            console.log('err', err);
        }
    );
  }
  
  preencherLogin(dados: Login){
    this.preencherUsuario(dados);
    this.preencherEnderecos(dados);
    this.preencherTelefones(dados);
    this.preencherDocumentos(dados);
    this.preencherResumo(dados);
  }

  preencherUsuario(dados) {
    this.cadastrarUsuario.formUsuario.cpfCnpj.setValue(formatToCPFOrCNPJ(dados.login.toString().replace(/\D/g,"")));
    this.cadastrarUsuario.formUsuario.nome.setValue(dados.nome);
    this.cadastrarUsuario.formUsuario.email.setValue(dados.emails[0]);
  }

  preencherEnderecos(dados) { 
    this.cadastrarUsuario.formEndereco.cep.setValue(dados.enderecos[0].cep);
    this.cadastrarUsuario.formEndereco.bairro.setValue(dados.enderecos[0].bairro);
    this.cadastrarUsuario.formEndereco.cidade.setValue(dados.enderecos[0].cidade);
    this.cadastrarUsuario.formEndereco.estado.setValue(dados.enderecos[0].estado);
    this.cadastrarUsuario.formEndereco.logradouro.setValue(dados.enderecos[0].logradouro);
    this.cadastrarUsuario.formEndereco.tipoLogradouro.setValue(dados.enderecos[0].tipoLogradouro);
    this.cadastrarUsuario.formEndereco.numero.setValue(dados.enderecos[0].numero);
    this.cadastrarUsuario.formEndereco.complemento.setValue(dados.enderecos[0].complemento);
    this.cadastrarUsuario.formEndereco.pontoReferencia.setValue(dados.enderecos[0].pontoReferencia);

  }

  preencherTelefones(dados) {
    dados.telefones.forEach( telefone => {
      let ddd = telefone.ddd.toString().replace(/\D/g,"") == "0" ? "" : telefone.ddd.toString().replace(/\D/g,"");
      let telefoneNumero = formatToPhone(ddd.concat(telefone.numero.toString().replace(/\D/g,"")));
      if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Residencial) {
        this.cadastrarUsuario.formUsuario.telefoneFixo.setValue(telefoneNumero);
      }
      if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Comercial) {
        this.cadastrarUsuario.formUsuario.telefoneComercial.setValue(telefoneNumero);
      }
      if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Celular) { 
        this.cadastrarUsuario.formUsuario.telefoneCelular.setValue(telefoneNumero);
      }
    })
  }

  preencherDocumentos(dados) {

    dados.documentos.forEach(documento => {
      if(documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Crc) {
        this.cadastrarUsuario.formUsuario.possuiCrc.setValue("S");
        this.cadastrarUsuario.formUsuario.numeroCrc.setValue(documento.numero);
        this.cadastrarUsuario.formUsuario.estadoCrc.setValue(documento.estadoEmissor);
        this.cadastrarUsuario.formUsuario.classificacaoCrc.setValue(documento.classificacao);
        this.cadastrarUsuario.formUsuario.tipoCrc.setValue(documento.subTipo);
      }
      else if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Crea) {
        this.cadastrarUsuario.formUsuario.possuiCrea.setValue("S");
        this.cadastrarUsuario.formUsuario.numeroCrea.setValue(documento.numero);
        this.cadastrarUsuario.formUsuario.estadoCrea.setValue(documento.estadoEmissor);

      }
      else if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cau) {
        this.cadastrarUsuario.formUsuario.possuiCau.setValue("S");
        this.cadastrarUsuario.formUsuario.numeroCau.setValue(documento.numero);
        this.cadastrarUsuario.formUsuario.estadoCau.setValue(documento.estadoEmissor);
        this.cadastrarUsuario.formUsuario.classificacaoCau.setValue(documento.classificacao);
        this.cadastrarUsuario.formUsuario.emissaoCau.setValue(documento.dataEmissao);
      }
    });
  }

  preencherResumo(dados){
    this.cadastrarUsuario.resumoCadastro.login = dados;
  }

  onCancelarCadastro(event) {
    this.cadastrarUsuarioEvent = event.cadastrarUsuario;
  }
}
