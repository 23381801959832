import { Telefone } from './telefone';
import { Endereco } from './endereco';
import { ConsultaViabilidadeAtividade } from './consultaViabilidadeAtividade';
import { ConsultaViabilidadeZona } from './ConsultaViabilidadeAtividadeZona';
import { ConsultaViabilidadeEndereco } from './consultaViabilidadeEndereco';



export class ConsultaViabilidade {
    cliente: string;
    inscricao: string;
    razaoSocial: string;
    atividades: string;
    tipoNegocio: string;
    entidade: string;
    tipoServico: string;
    telefones: Array<Telefone>;
    enderecos: Array<ConsultaViabilidadeEndereco>;
    tipo: string;
    tipoMapa: string;
    latitude: string;
    longitude: string;
    ldts: string;
    imobiliario: string;
    zonas: Array<ConsultaViabilidadeZona>;
    listaAtividades: Array<ConsultaViabilidadeAtividade>

    constructor() {
        this.cliente = "";
        this.inscricao = "";
        this.razaoSocial = "";
        this.atividades = "";
        this.tipoNegocio = "";
        this.entidade = "";
        this.tipoServico = "";
        this.telefones = new Array<Telefone>();
        this.enderecos = new Array<ConsultaViabilidadeEndereco>();
        this.tipo = "";
        this.tipoMapa = "";
        this.latitude = "";
        this.longitude = "";
        this.ldts = "";
        this.imobiliario = "";
        this.zonas = new Array<ConsultaViabilidadeZona>();
        this.listaAtividades = new Array<ConsultaViabilidadeAtividade>();
    }
}