import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Protocolo } from "src/app/model/protocolo";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class ProtocoloService {

    constructor(private http: HttpClient, private router: Router) {}

    gerarViabilidade(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/viabilidade`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    atenderViabilidade(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/atenderViabilidade`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    gerarFic(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/fic`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    atenderFic(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/atenderFic`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    atenderRecadastramento(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/atenderRecadastramento`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    enviarRecadastroParaViabilidade(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/enviarRecadastroParaViabilidade`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    gerarEncerramento(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/encerramento`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    atenderEncerramento(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/atenderEncerramento`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    gerarAlteracao(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/alteracao`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    gerarAlteracaoPorOficio(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/alteracaoPorOficio`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    gerarRecadastro(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/recadastro`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    gerarSuspensao(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/suspensao`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    removerSuspensao(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/removerSuspensao`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    atenderSuspensao(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/atenderSuspensao`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    cancelar(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/cancelar/${ cliente }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    cancelarPorOficio(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/cancelarPorOficio`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    detalhar(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/detalhar/${ cliente }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    detalharConsultaPrevia(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/detalharConsultaPrevia/${ cliente }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    detalharDadosCadastrais(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/detalharDadosCadastrais/${ cliente }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    detalharBaixa(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/detalharBaixa/${ cliente }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    detalharParaAtendimento(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/detalharParaAtendimento/${ cliente }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    detalharPorInscricao(cliente, inscricao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/detalharPorInscricao/${ cliente }/${ inscricao }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    detalharApenasPerguntas(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/detalharApenasPerguntas/${ cliente }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarProtocolosPorImobiliario(cliente, protocolo, Inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/protocolo/buscarProtocolosPorImobiliario/${ cliente }/${ protocolo }/${ Inscricao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    gerarEncerramentoPorOficio(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/encerramentoPorOficio`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    gerarAberturaPorOficio(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/aberturaPorOficio`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    linhaDoTempo(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/linhaDoTempo/${ cliente }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    vincularInscricao(protocolo, inscricao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/vincularInscricao/${ protocolo }/${ inscricao }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    acompanharPorInscricaoParaAtendimento(protocolo, inscricao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/acompanharPorInscricaoParaAtendimento/${ protocolo }/${ inscricao }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarSolicitacoesDeDespachoPorProtocolo(cliente, protocolo, codigoOrgao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/despacho/buscarSolicitacoesDeDespachoPorProtocolo/${ cliente }/${ protocolo }/${ codigoOrgao }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    menusAdicionaisAcompanhamento(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/menusAdicionaisAcompanhamento/${ cliente }/${ protocolo }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    listarSuspensao(protocolo, inscricao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/listarSuspensao/${ protocolo }/${ inscricao }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarSuspensaoAtiva(inscricao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/protocolo/buscarSuspensaoAtiva/${ inscricao }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    gerarReativacao(protocolo: Protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/protocolo/gerarReativacao`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    

}