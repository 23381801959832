<div id="navSection" data-spy="affix" class="nav sidenav">
    <div class="sidenav-content">
        <a href="javascript:void(0);" (click)="goToAnchor('tabsRequerente')" class="active nav-link tabsRequerente">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                ></use>
            </svg>  
            Requerente
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsRedeSim')" class="nav-link tabsRedeSim" *ngIf="empresa.redeSim.protocoloRedeSim != null && empresa.redeSim.protocoloRedeSim != ''">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                ></use>
            </svg> Redesim
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsIdentificacao')" class="nav-link tabsIdentificacao">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'briefcase' }}"
                ></use>
            </svg>
            Identificação
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsResponsavel')" class="nav-link tabsResponsavel">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'users' }}"
                ></use>
            </svg>
            Responsáveis
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsLocalizacao')" class="nav-link tabsLocalizacao">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'map' }}"
                ></use>
            </svg>
            Localização
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsZoneamento')" class="nav-link tabsZoneamento">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'codepen' }}"
                ></use>
            </svg> Zoneamento
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsAtividades')" class="nav-link tabsAtividades">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'activity' }}"
                ></use>
            </svg>
            Atividades
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsRequisitos')" class="nav-link tabsRequisitos">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'link' }}"
                ></use>
            </svg>
            Requisitos
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsDocumentos')" class="nav-link tabsDocumentos">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="#{{ 'segunda_via_documentos' }}"
                ></use>
            </svg>
            Documentos
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsProtocolos')" class="nav-link tabsProtocolos">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="#{{ 'processos_02' }}"
                ></use>
            </svg>
            Protocolos
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsQuestionario')" class="nav-link tabsQuestionario" *ngIf="!meusNegocios && !consultaProcesso">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'book-open' }}"
                ></use>
            </svg>
            Questionário
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsAnexo')" class="nav-link tabsAnexo">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'upload-cloud' }}"
                ></use>
            </svg> Anexo(s)
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsTaxas')" class="nav-link tabsTaxas" *ngIf="moduloTaxaLiberado || meusNegocios">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'credit-card' }}"
                ></use>
            </svg> DAM
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsCicloDeVida')" class="nav-link tabsCicloDeVida">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'activity' }}"
                ></use>
            </svg> Ciclo de Vida
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsAtendimento')" class="nav-link tabsAtendimento" *ngIf="!meusNegocios && !consultaProcesso">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'trending-up' }}"
                ></use>
            </svg>
            Atendimento
        </a>
    </div>
</div>  

    
<div class="row col-10 layout-top-spacing p-3">
    <div class="row col-12">
        <div class="col-lg-12 col-12 layout-spacing">
            <div class="card mb-0">
                <div class="card-body">
                    <div class="row">
                        <div class="row col-9 justify-content-center align-items-center">
                            <app-atendimento-avaliador class="w-100"></app-atendimento-avaliador>
                        </div>
                        <div class="row col-3 justify-content-center align-items-center" *ngIf="codigoMenu != '179' && codigoMenu != '180' && codigoMenu != '205'">
                            <app-atendimento-delegar class="w-100"></app-atendimento-delegar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row col-12">
        <div id="tabsRequerente" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionRequerente">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Requerente</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionRequerente', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-informacao-requerente [tipoEmpresa]="tipoEmpresa" [semCardHeader]="true"></app-informacao-requerente>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12" *ngIf="empresa.redeSim.protocoloRedeSim != null && empresa.redeSim.protocoloRedeSim != ''">
        <div id="tabsRedeSim" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionRedeSim">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>RedeSim</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionRedeSim', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-informacao-rede-sim [semCardHeader]="true"></app-informacao-rede-sim>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsIdentificacao" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionIdentificacao">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Identificação</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionIdentificacao', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-informacao-identificacao [tipoEmpresa]="tipoEmpresa" [semCardHeader]="true"></app-informacao-identificacao>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsResponsavel" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionResponsavel">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Responsáveis</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionResponsavel', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-informacao-responsavel permiteCriticar="true" [semCardHeader]="true"></app-informacao-responsavel>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>   

    <div class="row col-12">
        <div id="tabsLocalizacao" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionLocalizacao">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Localização</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionLocalizacao', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-informacao-localizacao [semCardHeader]="true"></app-informacao-localizacao>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsZoneamento" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionZoneamento">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Zoneamento</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionZoneamento', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-atendimento-zoneamento [semCardHeader]="true"></app-atendimento-zoneamento>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
    
    <div class="row col-12">
        <div id="tabsAtividades" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionAtividades">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Atividades</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionAtividades', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-atendimento-atividade [semCardHeader]="true"></app-atendimento-atividade>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsRequisitos" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionRequisitos">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Requisitos</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionRequisitos', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-atendimento-requisito (acaoDeAtendimento)="atualizacaoRequisitoAdicionadoRemovido($event)" [semCardHeader]="true"></app-atendimento-requisito>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsDocumentos" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionDocumentos">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Documentos</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionDocumentos', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-informacao-documento [semCardHeader]="true"></app-informacao-documento>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsProtocolos" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionProtocolos">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Protocolos</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionProtocolos', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-informacao-protocolos [semCardHeader]="true"></app-informacao-protocolos>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12" *ngIf="!meusNegocios && !consultaProcesso">
        <div id="tabsQuestionario" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionQuestionario">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Questionário</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionQuestionario', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-atendimento-questionario #atendimentoQuestionario titulo="Auditor" [semCardHeader]="true"></app-atendimento-questionario>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12" >
        <div id="tabsAnexo" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionAnexo">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Anexo(s)</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionAnexo', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-anexar [dados]="['', numeroInscricao, orgao, 'ATENDIMENTO']" [semCardHeader]="true"></app-anexar>      
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12" *ngIf="moduloTaxaLiberado || meusNegocios">
        <div id="tabsTaxas" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionTaxas">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>DAM</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionTaxas', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-conta-corrente [semCardHeader]="true"></app-conta-corrente>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
    
    <div class="row col-12" >
        <div id="tabsCicloDeVida" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="">
                <ngb-panel id="accordionCicloDeVida">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Ciclo de Vida</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionCicloDeVida', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-ciclo-de-vida [semCardHeader]="true"></app-ciclo-de-vida>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12 mb-5" *ngIf="modulo != 'moduloAlvara' && modulo != 'suspensao' && !meusNegocios && !consultaProcesso && modulo != 'reativacao'">
        <div id="tabsAtendimento" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="accordionAtendimento">
                <ngb-panel id="accordionAtendimento">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Atendimento</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionAtendimento', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-atendimento-empresa-requisitos [semCardHeader]="true" (acaoDeAtendimento)="atualizacaoRequisitoAtendido($event)"></app-atendimento-empresa-requisitos>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12 mb-5" *ngIf="modulo == 'moduloAlvara'">
        <div id="tabsAtendimento" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="accordionAtendimento">
                <ngb-panel id="accordionAtendimento">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Atendimento</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionAtendimento', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-atendimento-alvara [semCardHeader]="true" (acaoDeAtendimentoAlvara)="atualizacaoAtendimentoAlvara($event)"></app-atendimento-alvara>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12 mb-5" *ngIf="modulo == 'suspensao'">
        <div id="tabsAtendimento" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="accordionAtendimento">
                <ngb-panel id="accordionAtendimento">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Atendimento</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionAtendimento', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                         <app-atendimento-suspensao (acaoDeAtendimentoAlvara)="atualizacaoAtendimentoAlvara($event)"></app-atendimento-suspensao>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row col-12 mb-5" *ngIf="modulo == 'reativacao'">
        <div id="tabsAtendimento" class="col-lg-12 col-12 layout-spacing">
            <ngb-accordion #acc="ngbAccordion" activeIds="accordionAtendimento">
                <ngb-panel id="accordionAtendimento">
                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                        <div class="row col-12">
                            <div class="row col-10 justify-content-start align-items-center" >
                                <strong>Atendimento</strong>
                            </div>
                            <div class="row col-2 justify-content-end align-items-center">
                                <button type="button" 
                                    ngbPanelToggle 
                                    (click)="clickAccordion('accordionAtendimento', opened)"
                                    class="btn btn-success btn-painel">
                                    <svg [class.hidden]="opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                        ></use>
                                    </svg>
                                    <svg [class.hidden]="!opened" 
                                        class="icone-painel">
                                        <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                         <app-atendimento-reativacao (acaoDeAtendimentoAlvara)="atualizacaoAtendimentoAlvara($event)"></app-atendimento-reativacao>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

</div>
