export class AtendimentoAlvara {

    cliente: string;
    protocolo: string;
    inscricao: string;
    atendenteCodigo: string;
    atendenteNome: string;
    situacaoEmpresa: string;
    validadeDocumento: string;
    acaoAtendimentoCodigo: string;
    acaoAtendimentoDescricao: string;
    codigoDocumento: string;
    nomeDocumento: string;
    tipoDocumento: string;
    justificativa: string;
    quantidadeRenovacoes: string;
    emitirAvisoRenovacoes: string;
    mensagensDeAtendimento: Array<any>;
    timestamp: string;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.inscricao = "";
        this.atendenteCodigo = "";
        this.atendenteNome = "";
        this.situacaoEmpresa = "";
        this.validadeDocumento = "";
        this.acaoAtendimentoCodigo = "";
        this.acaoAtendimentoDescricao = "";
        this.codigoDocumento = "";
        this.nomeDocumento = "";
        this.tipoDocumento = "";
        this.justificativa = "";        
        this.quantidadeRenovacoes = "";
        this.emitirAvisoRenovacoes = "";
        this.mensagensDeAtendimento = Array<any>();
        this.timestamp = "";
    }
}