import { Zona } from './zona';

export class Endereco {
    cliente: string;
    codigo: string;
    protocolo: string;
    inscricao: string;
    inscricaoFormatada: string;
    cep: string;
    tipoLogradouroCodigo: string;
    tipoLogradouro: string;
    tituloLogradouro: string;
    codigoLogradouro: string;
    logradouro: string;
    numero: string;
    complemento: string;
    codigoBairro: string;
    bairro: string;
    cidade: string;
    estado: string;
    pais: string;
    pontoReferencia: string;
    latitude: string;
    longitude: string;
    area: string;
    tipoEndereco: number;
    tipoBusca: string;
    baseUtilizada: string;
    zonas: Array<Zona>;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.protocolo = "";
        this.inscricao = "";
        this.inscricaoFormatada = "";
        this.cep = "";
        this.tipoLogradouroCodigo = "";
        this.tipoLogradouro = "";
        this.tituloLogradouro = "";
        this.codigoLogradouro = "";
        this.logradouro = "";
        this.numero = "";
        this.complemento = "";
        this.codigoBairro = "";
        this.bairro = "";
        this.cidade = "";
        this.estado = "";
        this.pais = "";
        this.pontoReferencia = "";
        this.latitude = "";
        this.longitude = "";
        this.area = "";
        this.tipoEndereco = 0;
        this.tipoBusca = "";
        this.baseUtilizada = "";
        this.zonas = new Array<Zona>();
    }
}