import { Pergunta } from "./pergunta";

export class PerguntaResposta {
    cliente: string;
    protocolo: string;
    inscricao: string;
    processo: string;
    perfil: string;
    perguntas: Array<Pergunta>;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.inscricao = "";
        this.processo = "";
        this.perfil = "";
        this.perguntas = new Array<Pergunta>();
    }
}