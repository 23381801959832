<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card" >
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Observação do Evento</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="col-sm-12 col-form-label" for="objetoSocial"></label>
                    <textarea #objetoSocialTextArea
                        class="form-control form-control-sm"
                        [class.is-invalid]="objetoSocial.invalid && objetoSocial.touched"
                        formControlName="objetoSocial"
                        id="objetoSocial"
                        name="objetoSocial"
                        maxlength="7000"
                        cols="30" 
                        rows="5"
                    ></textarea>
                    <div class="mb-1">
                        <span 
                            class="erro" 
                            *ngIf="objetoSocial.invalid && objetoSocial.touched && objetoSocial.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="objetoSocial.invalid && objetoSocial.touched && objetoSocial.errors.maxlength"
                        >A descrição deve conter até 7000 caracteres.</span>
                        <span class="quantidade-caracter">
                            Quantidade de Caracteres: {{ objetoSocial.value.length }} / 7000
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>