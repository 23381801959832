<div class="container-principal">
    <div class="row col-lg-12 col-12 pl-0 pr-0">
        <div class="row card-menus">
            <div class="col-md-2 boxInicial2"
                [class.box-laranja]="exibirProcessamentos"
                [class.box-cinza]="!exibirProcessamentos">
                <div class="row m-4">
                    <div class="form-row col-md-12 justify-content-center animaHover">
                        <div (click)="processamentoLote()"
                            class="animated fadeInUp">
                            <span>
                                <svg class="icone">
                                    <use
                                      attr.xlink:href="#{{ 'pie-chart' }}"
                                    ></use>
                                </svg>
                            </span>
                            <p class="texto-icone">Processamento</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 boxInicial2"
                [class.box-azul]="exibirCalendarioFiscal"
                [class.box-cinza]="!exibirCalendarioFiscal">
                <div class="row m-4">
                    <div class="form-row col-md-12 justify-content-center animaHover">
                        <div (click)="alterarCalendarioFiscal()"
                            class="animated fadeInUp">
                            <span>
                                <svg class="icone">
                                    <use
                                      attr.xlink:href="#{{ 'pie-chart' }}"
                                    ></use>
                                </svg>
                            </span>
                            <p class="texto-icone">Calendário Fiscal</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div id="divDetalhes" class="container-tamanho">
        <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0" [class.hidden]="!exibirProcessamentos">
            <div class="col-12 pl-0 pr-0">
                <app-processamento-lote-taxa></app-processamento-lote-taxa>
            </div>
        </div>
        
        <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0" [class.hidden]="!exibirCalendarioFiscal">
            <div class="col-12 pl-0 pr-0">
                <app-calendario-fiscal-lote></app-calendario-fiscal-lote>
            </div>
        </div>
    </div>
</div>
