import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Empresa } from '../../../model/empresa';
import { Constantes } from '../../../model/constantes';
import { AtendimentoGridConsultaComponent } from '../../outros/atendimento/atendimento-grid-consulta/atendimento-grid-consulta.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Grid } from '../../../model/grid';
import { GridOpcao } from '../../../model/gridOpcao';
import { GridFiltro } from 'src/app/model/gridFiltro';
import { AtendimentoService } from '../../../services/operacao/atendimento.service';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ConsultaGeralService } from '../../../services/consultaGeral/consultaGeral.service';
import { ConsultaGeral } from '../../../model/consultaGeral';
import { AtendimentoRequisitoComponent } from '../../outros/atendimento/atendimento-protocolo/atendimento-requisito.component';
import { LinhaDoTempoComponent } from '../../outros/linha-do-tempo/linha-do-tempo.component';
import { InformacaoRedeSimComponent } from '../../outros/informacoes-cadastro/informacao-rede-sim/informacao-rede-sim.component';
import { InformacaoRequerenteComponent } from '../../outros/informacoes-cadastro/informacao-requerente/informacao-requerente.component';
import { InformacaoIdentificacaoComponent } from '../../outros/informacoes-cadastro/informacao-identificacao/informacao-identificacao.component';
import { Protocolo } from '../../../model/protocolo';
import { InformacaoSituacaoComponent } from '../../outros/informacoes-cadastro/informacao-situacao/informacao-situacao.component';
import { InformacaoLocalizacaoComponent } from '../../outros/informacoes-cadastro/informacao-localizacao/informacao-localizacao.component';
import { InformacaoAtividadeComponent } from '../../outros/informacoes-cadastro/informacao-atividade/informacao-atividade.component';
import { InformacaoResponsavelComponent } from '../../outros/informacoes-cadastro/informacao-responsavel/informacao-responsavel.component';
import { InformacaoDocumentoComponent } from '../../outros/informacoes-cadastro/informacao-documento/informacao-documento.component';
import { AtendimentoAtividadeComponent } from '../../outros/atendimento/atendimento-protocolo/atendimento-atividade.component';
import { AtendimentoConfiguracao } from 'src/app/model/atendimentoConfiguracao';
import { InformacaoProtocolosComponent } from '../../outros/informacoes-cadastro/informacao-protocolos/informacao-protocolos.component';
import { AnexarComponent } from '../../outros/anexar/anexar.component';
import { InformacaoSituacaoEmpresaComponent } from '../../outros/informacoes-cadastro/informacao-situacao-empresa/informacao-situacao-empresa.component';
import { ContaCorrenteComponent } from '../../outros/taxas/conta-corrente/conta-corrente.component';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { EmpresaService } from 'src/app/services/empresa/empresa.service';
import { InformacaoRedeSimPerguntasComponent } from '../../outros/informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.component';

@Component({
    selector: "app-consultar-protocolo",
    templateUrl: "./consultar-protocolo.component.html",
    styleUrls: ["./consultar-protocolo.component.scss"]
})
export class ConsultarProtocoloComponent implements OnInit {

    tipoEmpresa: string = "E";

    numeroInscricao: string;
    numeroProtocolo: string;
    orgao: string;
    formulario: FormGroup;

    consultaGeral: ConsultaGeral = new ConsultaGeral();

    grid: Grid;
    filtroOpcoes: Array<GridOpcao>;

    emitirErro: boolean;
    mensagemErro: string;
    codigoMenu: string;
  
    @ViewChild(InformacaoSituacaoComponent)
    informacaoSituacao: InformacaoSituacaoComponent;
  
    @ViewChild(InformacaoSituacaoEmpresaComponent)
    informacaoSituacaoEmpresa: InformacaoSituacaoEmpresaComponent;
  
    @ViewChild(InformacaoRequerenteComponent)
    informacaoRequerente: InformacaoRequerenteComponent;
    
    @ViewChild(InformacaoRedeSimComponent)
    informacaoRedeSim: InformacaoRedeSimComponent;

    @ViewChild(InformacaoRedeSimPerguntasComponent)
    informacaoRedeSimPerguntas: InformacaoRedeSimPerguntasComponent;
  
    @ViewChild(InformacaoLocalizacaoComponent)
    informacaoLocalizacao: InformacaoLocalizacaoComponent;
  
    @ViewChild(InformacaoAtividadeComponent)
    informacaoAtividade: InformacaoAtividadeComponent;
  
    @ViewChild("informacaoIdentificacaoComponentProtocolo")
    informacaoIdentificacaoProtocolo: InformacaoIdentificacaoComponent;
  
    @ViewChild("informacaoIdentificacaoComponentEmpresa")
    informacaoIdentificacaoEmpresa: InformacaoIdentificacaoComponent;

    @ViewChild(LinhaDoTempoComponent)
    linhaDoTempo: LinhaDoTempoComponent;

    @ViewChild(InformacaoResponsavelComponent)
    informacaoResponsavel: InformacaoResponsavelComponent;

    @ViewChild("atendimentoRequisitoComponentProtocolo")
    atendimentoRequisitoProtocolo: AtendimentoRequisitoComponent;

    @ViewChild("atendimentoRequisitoComponentEmpresa")
    atendimentoRequisitoEmpresa: AtendimentoRequisitoComponent;

    @ViewChild(InformacaoDocumentoComponent)
    informacaoDocumento: InformacaoDocumentoComponent;

    @ViewChild(InformacaoProtocolosComponent)
    informacaoProtocolos: InformacaoProtocolosComponent;
    
    @ViewChild(AtendimentoGridConsultaComponent)
    atendimentoGridConsulta: AtendimentoGridConsultaComponent;
    
    @ViewChild("anexarProtocolo")
    anexarProtocolo: AnexarComponent;
    
    @ViewChild("anexarEmpresa")
    anexarEmpresa: AnexarComponent;
    
    @ViewChild("contaCorrenteProtocolo")
    contaCorrenteProtocolo: ContaCorrenteComponent;
    
    @ViewChild("contaCorrenteEmpresa")
    contaCorrenteEmpresa: ContaCorrenteComponent;

    exibirLista: boolean = false;
    exibirProtocolo: boolean = false;
    exibirEmpresa: boolean = false;
  
    moduloTaxaLiberado: boolean = false;
    
    empresaSuspensa: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private atendimentoService: AtendimentoService,
        private consultaGeralService: ConsultaGeralService,
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private empresaService: EmpresaService,) {

        this.grid = new Grid();
        this.grid.filtro.push(new GridFiltro());
        
        this.mensagemErro = "";
        this.emitirErro = false;
        
        this.formulario = this.formBuilder.group({
          tipoBusca: [''],
          tipoBuscaValorInput: [''],
          tipoBuscaValorSelect: [''],
        });   
        
        this.orgao = this.activatedRoute.snapshot.params["codigoOrgao"];
        this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
        this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "";
        this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];
        
        authenticationService.currentAxisValue.item.forEach(item => {
          if (item.nome == "moduloTaxa") {
            this.moduloTaxaLiberado = true;
          }
        });
    }

    ngOnInit(): void {
      this.buscarGridConfiguracaoFiltro();
    }

    acaoSelectTipoBusca() {
      this.tipoBuscaValorInput.setValue("");
      this.tipoBuscaValorSelect.setValue("");

      if (this.grid.filtro[this.tipoBusca.value].tipoCampo == "SELECT") {
          of(this.grid.filtro[this.tipoBusca.value].opcoes).subscribe(opcoes => {
              this.filtroOpcoes = opcoes;
          });
      }
    }

    buscarGridConfiguracaoFiltro() {
        this.atendimentoService
            .buscarGridConfiguracaoFiltro(this.activatedRoute.snapshot.params["codigoOrgao"], this.activatedRoute.snapshot.params["codigoMenu"])
            .pipe(take(1))
            .subscribe(retorno => {
                this.grid = retorno;
            })
    }

    buscarGridPorFiltro() {
        let valor = this.grid.filtro[this.tipoBusca.value].tipoCampo == "INPUT" ? this.tipoBuscaValorInput.value : this.tipoBuscaValorSelect.value
        
        if (this.grid.filtro[this.tipoBusca.value].tipoCampo == "") {
            valor = "TODOS";
        }

        if (valor == "") {
            Swal.fire("", "Favor informar um valor para busca.", "error")
            return false;
        }

        var valorFormatado = valor.replace(/[/]/g,"");

        if (this.tipoBusca.value == 9 || this.tipoBusca.value == 12 || this.tipoBusca.value == 1 || this.tipoBusca.value == 11) {
          valorFormatado = valor.replace(/\D/g,"");
        }
        
        this.consultaGeralService
          .buscar(this.grid.filtro[this.tipoBusca.value].codigo, valorFormatado)
          .pipe(take(1))
          .subscribe(retorno => {
            Object.assign(this.consultaGeral, retorno);
            this.preencherDados();
          })
    }

    preencherDados() {
      this.exibirLista = false;
      this.exibirProtocolo = false;
      this.exibirEmpresa = false;
      this.emitirErro = false;

      if (this.consultaGeral.protocolos.length) {
        if (this.consultaGeral.protocolos.length == 1) {
          this.exibirProtocolo = true;
          this.preencherProtocolo();
        }
      }
      else if (this.consultaGeral.empresas.length) {
        if (this.consultaGeral.empresas.length == 1) {
          this.exibirEmpresa = true;
          this.preencherEmpresa();
        }
      }
      else if (this.consultaGeral.empresasAcompanhamento.length) {
        if (this.consultaGeral.empresasAcompanhamento.length > 1) {
          this.exibirLista = true;
          this.preencherGridEmpresas();
        }
      }
      else {
        this.exibirLista = false;
        this.exibirProtocolo = false;
        this.exibirEmpresa = false;
        this.emitirErro = true;

        if (this.consultaGeral.qtdTotal == "0") {
          this.mensagemErro = "Não conseguimos localizar dados em nossa base!"
        }
        else {
          this.mensagemErro = "<b>ATENÇÃO</b> - " + this.consultaGeral.mensagem;
        }
      }
    }

    preencherProtocolo() {
      var protocoloAuxiliar = new Protocolo();
      Object.assign(protocoloAuxiliar, this.consultaGeral.protocolos[0]);

      this.tipoEmpresa = protocoloAuxiliar.processo.tipoPessoa == Constantes.TIPO_PESSOA.Fisica ? "A" : "E";

      this.informacaoSituacao.preencher(protocoloAuxiliar.protocolo);

      this.informacaoRequerente.tipoEmpresa = this.tipoEmpresa;
      this.informacaoRequerente.protocolo = protocoloAuxiliar;
      
      this.informacaoIdentificacaoProtocolo.tipoEmpresa = this.tipoEmpresa;
      this.informacaoIdentificacaoProtocolo.protocolo = protocoloAuxiliar;
      this.informacaoIdentificacaoProtocolo.permitirAtendimento = false;
      
      if(protocoloAuxiliar.redeSim.protocoloRedeSim  != "") {
        setTimeout(() => {
          this.informacaoRedeSim.protocolo = protocoloAuxiliar;
          this.informacaoRedeSimPerguntas.protocolo = protocoloAuxiliar;
        }, 1);
      }
      
      this.linhaDoTempo.protocoloLinhaDoTempo = protocoloAuxiliar.linhaDoTempo;
      
      this.atendimentoRequisitoProtocolo.permitirAtendimento = false;
      this.atendimentoRequisitoProtocolo.numeroProtocolo = protocoloAuxiliar.protocolo;
      this.atendimentoRequisitoProtocolo.preencher(protocoloAuxiliar.viabilidade.requisitos, protocoloAuxiliar);

      this.anexarProtocolo.dados = [protocoloAuxiliar.protocolo, "", this.orgao, 'ATENDIMENTO'];
      this.anexarProtocolo.execultarBusca();

      if (this.contaCorrenteProtocolo != null) {
        this.contaCorrenteProtocolo.numeroProtocolo = protocoloAuxiliar.protocolo;
        this.contaCorrenteProtocolo.buscarTaxaLancamentoParcelas();
      }
    }

    preencherEmpresa() {
      var empresa = new Empresa();
      Object.assign(empresa, this.consultaGeral.empresas[0]);

      this.tipoEmpresa = empresa.registro.tipoPessoa == Constantes.TIPO_PESSOA.Fisica ? "A" : "E";
      
      this.informacaoSituacaoEmpresa.tipoEmpresa = this.tipoEmpresa;
      this.informacaoSituacaoEmpresa.empresa = empresa;

      this.informacaoIdentificacaoEmpresa.tipoEmpresa = this.tipoEmpresa;
      this.informacaoIdentificacaoEmpresa.permitirAtendimento = false;
      this.informacaoIdentificacaoEmpresa.protocolo.fic = empresa.fic;
      this.informacaoIdentificacaoEmpresa.protocolo.registro = empresa.registro;
      
      if(empresa.redeSim.protocoloRedeSim  != "") {
        setTimeout(() => {
          this.informacaoRedeSim.protocolo.redeSim = empresa.redeSim;
          this.informacaoRedeSimPerguntas.protocolo.redeSim = empresa.redeSim;
        }, 1);
      }

      this.informacaoLocalizacao.protocolo = new Protocolo();
      this.informacaoLocalizacao.protocolo.viabilidade = empresa.viabilidade;

      this.informacaoAtividade.protocolo = new Protocolo();
      this.informacaoAtividade.protocolo.viabilidade = empresa.viabilidade;
    
      this.informacaoAtividade.protocolo = new Protocolo();
      this.informacaoAtividade.protocolo.fic = empresa.fic;
      this.informacaoAtividade.protocolo.viabilidade = empresa.viabilidade;

      this.informacaoResponsavel.protocolo = new Protocolo();
      this.informacaoResponsavel.protocolo.fic = empresa.fic;

      this.atendimentoRequisitoEmpresa.permitirAtendimento = false;
      this.atendimentoRequisitoEmpresa.numeroInscricao = empresa.inscricao;
      this.atendimentoRequisitoEmpresa.preencher(empresa.viabilidade.requisitos, empresa);

      this.informacaoDocumento.protocolo = new Protocolo();
      this.informacaoDocumento.protocolo.documentos = empresa.documentos;

      this.informacaoProtocolos.protocolos = empresa.protocolosAcompanhamento;

      this.anexarEmpresa.dados = ["", empresa.inscricao, this.orgao, 'ATENDIMENTO'];
      this.anexarEmpresa.execultarBusca();

      if (this.contaCorrenteEmpresa != null) {
        this.contaCorrenteEmpresa.numeroInscricao = empresa.inscricao;
        this.contaCorrenteEmpresa.buscarTaxaLancamentoParcelas();
      }

      if (empresa.suspensao.dataInicio != "" && empresa.suspensao.dataFim == "") {
        this.empresaSuspensa = true;
        this.informacaoDocumento.empresaSuspensa = true;
      }
      
    }

    preencherGridEmpresas() {
      this.atendimentoGridConsulta.grid.dados = this.consultaGeral.empresasAcompanhamento;
      this.atendimentoGridConsulta.atualizarTabela();
    }

    visualizarProtocolo() {
      this.router.navigate([this.router.url, "visualizar-protocolo", this.consultaGeral.protocolos[0].protocolo], { queryParams: {}})
    }
  
    visualizarEmpresa() {
      this.router.navigate([this.router.url, "visualizar-empresa", this.consultaGeral.empresas[0].inscricao, this.consultaGeral.empresas[0].protocolo], { queryParams: {}})
    }
  
    get tipoBusca() {
      return this.formulario.get("tipoBusca");
    }  
    set tipoBusca(tipoBusca) {
      this.formulario.get("tipoBusca").setValue(tipoBusca);
    }
  
    get tipoBuscaValorInput() {
      return this.formulario.get("tipoBuscaValorInput");
    }  
    set tipoBuscaValorInput(tipoBuscaValorInput) {
      this.formulario.get("tipoBuscaValorInput").setValue(tipoBuscaValorInput);
    }
  
    get tipoBuscaValorSelect() {
      return this.formulario.get("tipoBuscaValorSelect");
    }  
    set tipoBuscaValorSelect(tipoBuscaValorSelect) {
      this.formulario.get("tipoBuscaValorSelect").setValue(tipoBuscaValorSelect);
    }

}