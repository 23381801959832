import { Usuario } from "./usuario";
import { PerfilNivel } from './perfilNivel';
import { Perfil } from "./perfil";

export class Login extends Usuario {
    login: number;
    senha: string;
    status: boolean;
    statusAuditor: boolean;
    possuiCadastro: boolean;
    fraseSeguranca: string;
    codigoValidacao: string;
    codigoValidacaoDataValidade: string;
    perfilNivel: PerfilNivel;
    perfis: Array<Perfil>;
    clientes: Array<any>;

    constructor() {
        super();
        this.login = 0;
        this.senha = "";
        this.status = false;
        this.statusAuditor = false;
        this.possuiCadastro = false;
        this.fraseSeguranca = "";
        this.codigoValidacao = "";
        this.codigoValidacaoDataValidade = "";
        this.perfilNivel = new PerfilNivel();
        this.perfis = new Array<Perfil>();
        this.clientes = [];
    }

}