export class EnderecoRegra {
    cliente: string;
    buscarBaseCorreios: string;
    buscarBasePrefeitura: string;
    buscarPorCep: string;
    buscarPorInscricaoImobiliaria: string;
    buscarPorLogradouro: string;
    liberarEnderecoUrbano: string;
    liberarEnderecoRural: string;
    liberarEnderecoInternacional: string;
    municipioCepUnico: string;
    urbanoNomeCampo: string;
    urbanoValidarCampo: string;
    urbanoFraseAjuda: string;
    urbanoExibirFormatada: string;
    ruralNomeCampo: string;
    ruralValidarCampo: string;
    ruralFraseAjuda: string;
    inscricaoValida: string;
    tipoEndereco: string;
    estado: string;

    constructor() {
        this.cliente = "";
        this.buscarBaseCorreios = "";
        this.buscarBasePrefeitura = "";
        this.buscarPorCep = "";
        this.buscarPorInscricaoImobiliaria = "";
        this.buscarPorLogradouro = "";
        this.liberarEnderecoUrbano = "";
        this.liberarEnderecoRural = "";
        this.liberarEnderecoInternacional = "";
        this.municipioCepUnico = "";
        this.urbanoNomeCampo = "";
        this.urbanoValidarCampo = "";
        this.urbanoFraseAjuda = "";
        this.urbanoExibirFormatada = "";
        this.ruralNomeCampo = "";
        this.ruralValidarCampo = "";
        this.ruralFraseAjuda = "";
        this.inscricaoValida = "";
        this.tipoEndereco = "";
        this.estado = "";
    }
}