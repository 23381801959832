import { Usuario } from "./usuario";
import { GridMetodo } from './gridMetodo';
import { Documento } from './documento';

export class ProtocoloAcompanhamento {
    cliente: string;
    login: string;
    protocolo: string;
    redeSim: string;
    ultimoProtocolo: string;
    inscricao: string;
    requerente: Usuario;
    servico: string;
    servicoDescricao: string;
    servicoIcone: string;
    servicoClasse: string;
    fase: string;
    faseDescricao: string;
    faseIcone: string;
    faseClasse: string;
    situacao: number;
    situacaoDescricao: string;
    situacaoIcone: string;
    situacaoClasse: string;
    dataSituacao: string;
    gridMetodos: Array<GridMetodo>;
    documentos: Array<Documento>;

    constructor() {
        this.cliente = "";
        this.login = "";
        this.protocolo = "";
        this.redeSim = "";
        this.ultimoProtocolo = "";
        this.inscricao = "";
        this.requerente = new Usuario();
        this.servico = "";
        this.servicoDescricao = "";
        this.servicoIcone = "";
        this.servicoClasse = "";
        this.fase = "";
        this.faseDescricao = "";
        this.faseIcone = "";
        this.faseClasse = "";
        this.situacao = 1;
        this.situacaoDescricao = "";
        this.situacaoIcone = "";
        this.situacaoClasse = "";
        this.dataSituacao = "";
        this.gridMetodos = new Array<GridMetodo>();
        this.documentos = new Array<Documento>();
    }
}