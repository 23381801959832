<div class="row linha-botao-concluir">
    <div class="row col-12 justify-content-center align-items-center">
        <button type="button" 
                (click)="acaoBotaoConcluir()"
                class="btn btn-success btn-concluir">
            <svg class="iconeGrid">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'check' }}"
                ></use>
            </svg>
            Concluir seleção
        </button>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="row col-12 justify-content-start align-items-center" >
                <strong>{{tipoEmpresa == 'A' ? 'Atividades Autônomo' : tipoEmpresa == 'E' ? 'Atividades Empresa' : 'Atividades Mei'}}</strong>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="row col-12 mt-3">
            <div class="row col-5 justify-content-start align-items-center">
                <mat-form-field class="w-100">
                    <mat-label>
                        <svg class="iconeGridFiltro">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'search' }}"
                            ></use>
                        </svg>
                        Pesquise aqui atividades {{ tipoEmpresa == 'E' ? "(Cnaes's)" : "(Cbo)" }} para seu negócio.
                    </mat-label>
                    <input matInput (keyup)="acaoDoFiltro($event, 'todo-box-item-disponivel-' + tipoEmpresa)" placeholder="Digite a descrição ou o código de sua atividade para filtrar no quadro abaixo.">
                </mat-form-field>
            </div>
            <div class="row col-2"></div>
            <div class="row col-5 justify-content-start align-items-center">
                <mat-form-field class="w-100">
                    <mat-label>
                        <svg class="iconeGridFiltro">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'search' }}"
                            ></use>
                        </svg>
                        Pesquise aqui atividades {{ tipoEmpresa == 'E' ? "(Cnaes's)" : "(Cbo)" }} para seu negócio.
                    </mat-label>
                    <input matInput (keyup)="acaoDoFiltro($event, 'todo-box-item-selecionado-' + tipoEmpresa)" placeholder="Digite a descrição ou o código de sua atividade para filtrar no quadro abaixo.">
                </mat-form-field>
            </div>
        </div>
        <div class="row col-12 mt-3">
            <div class="row col-5 header-atividades justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Atividades Não Vinculadas</strong>
                </p>
            </div>
            <div class="row col-2"></div>
            <div class="row col-5 header-atividades justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Atividades Vinculadas</strong>
                </p>
            </div>
        </div>       
        <div class="row col-12">
            <div class="row col-5 todo-box-scroll">
                <mat-selection-list #listaDeAtividadesDisponiveis [multiple]="true" class="w-100">
                    <mat-list-option  class="{{ 'todo-box-item-disponivel-' + tipoEmpresa }}" *ngFor="let atividade of atividadesDisponiveis" [value]="atividade">
                        <p class="card-text" *ngIf="tipoEmpresa == tiposDeEmpresa.Autonomo">
                            {{  atividade.codigo | mascara: 12 }} - {{ formatarNomeAtividade(atividade.nome) }}
                        </p>
                        <p class="card-text" *ngIf="tipoEmpresa != tiposDeEmpresa.Autonomo">
                            {{  atividade.codigo | mascara: 10 }} - {{ formatarNomeAtividade(atividade.nome) }}
                        </p>
                    </mat-list-option>
                </mat-selection-list>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <div class="row col-12 justify-content-center align-items-center pl-3 pr-3">
                    <button type="button" 
                        (click) ="acaoSelecionadosParaDireita(listaDeAtividadesDisponiveis)"
                        class="btn btn-secondary w-100">
                        <svg class="icone-manipular-list">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'chevron-right' }}"
                            ></use>
                        </svg><br> Adicionar
                    </button>
                </div>
                <div class="row col-12 justify-content-center align-items-center pl-3 pr-3">
                    <button type="button" 
                        (click) ="acaoSelecionadosParaEsquerda(listaDeAtividadesSelecionadas)"
                        class="btn btn-secondary w-100">
                        <svg class="icone-manipular-list">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'chevron-left' }}"
                            ></use>
                        </svg><br> Remover
                    </button>
                </div>
                <div class="row col-12 justify-content-center align-items-center pl-3 pr-3">
                    <button type="button" 
                        (click) ="acaoTodosParaDireita()"
                        class="btn btn-secondary w-100">
                        <svg class="icone-manipular-list">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'chevrons-right' }}"
                            ></use>
                        </svg><br> Adicionar Todos
                    </button>
                </div>
                <div class="row col-12 justify-content-center align-items-center pl-3 pr-3">
                    <button type="button" 
                        (click) ="acaoTodosParaEsquerda()"
                        class="btn btn-secondary w-100">
                        <svg class="icone-manipular-list">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'chevrons-left' }}"
                            ></use>
                        </svg><br> Remover Todos
                    </button>
                </div>
            </div>
            <div class="row col-5 todo-box-scroll">
                <mat-selection-list #listaDeAtividadesSelecionadas [multiple]="true" class="w-100">
                    <mat-list-option class="{{ 'todo-box-item-selecionado-' + tipoEmpresa }}" *ngFor="let atividade of atividadesSelecionadas" [value]="atividade">
                        <p class="card-text" *ngIf="tipoEmpresa == tiposDeEmpresa.Autonomo">
                            {{  atividade.codigo | mascara: 12 }} - {{ formatarNomeAtividade(atividade.nome) }}
                        </p>
                        <p class="card-text" *ngIf="tipoEmpresa != tiposDeEmpresa.Autonomo">
                            {{  atividade.codigo | mascara: 10 }} - {{ formatarNomeAtividade(atividade.nome) }}
                        </p>
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
    </div>
</div>
