<div class="row col-12 p-0 pl-2 justify-content-center align-items-center" *ngIf="permitirDelegar">
    <div class="row col-12 p-0 pl-2 justify-content-end align-items-start ng-star-inserted">
        <button 
            *ngIf="permitirAtendimento"
            type="button" 
            class="btn btn-outline-success btn-titulo"
            title="analisar"
            (click)="delegarAtendimento()"
        >
            <svg class="iconeFecharPopUp">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                ></use>
            </svg>
            Delegar
        </button>
    </div>
</div>