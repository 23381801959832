<div class="justify-content-md-end">
    <div class="row col-12" class="w-100" >
      <div class="container-principal-atendimento mt-3 mb-5">
        <div class="card">
          <div class="card-header">
            <div class="col-12">
              <strong>{{ titulo }}</strong>
            </div>
          </div>
          <div class="card-body p-0 mb-2">
            <div class="row mt-3">
              <div class="form-group col-12">
                <input class="form-control" type="file" id="formFile" (change)="upload($event)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-12" class="w-100" >
      <!-- PARCELA ÚNICA -->
<div>&nbsp;</div>
<div>&nbsp;</div>

<!-- LISTA DE PARCELAS -->
<div class="form-row row-linha justify-content-start align-items-center">
  <div class="row col-12 justify-content-center align-items-center">
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="downloadVinculosAtivos()">Baixar vínculos ativos atualmente</button>
  </div>
  <div class="col-12">
      <div class="col-12 card-header-linha">
          <p class="card-text">
              <b>ARQUIVOS ENVIADOS</b>
          </p>                    
      </div>
  </div>
</div>
<div class="mat-elevation-z9">
  <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">
    <ng-container matColumnDef="codigo">
        <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-codigo" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Código </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.codigo }} </td>
    </ng-container>
    <ng-container matColumnDef="nome">
      <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-nome" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Nome do Arquivo </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.nome }} </td>
  </ng-container>
  <ng-container matColumnDef="nomeLogin">
      <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-nomeLogin" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Nome Usuário </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.nomeLogin }} </td>
  </ng-container>
  <ng-container matColumnDef="dataInicio">
      <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-dataInicio" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Data Ínicio Processamento </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.dataInicio }} </td>
  </ng-container>
  <ng-container matColumnDef="dataFim">
      <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-dataFim" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Data Fim Processamento </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.dataFim }} </td>
  </ng-container>
  <ng-container matColumnDef="observacao">
      <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-observacao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Status </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.observacao }} </td>
  </ng-container>
  <ng-container matColumnDef="qtdeDeLinhas">
      <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-qtdeDeLinhas" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Quantidade de Linhas </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.qtdDeLinhas }} </td>
  </ng-container>
  <ng-container matColumnDef="qtdeDeLinhasVerificadas">
      <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-qtdeDeLinhasVerificadas" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Quantidade de Linhas Verificadas </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.qtdDeLinhasVerificadas }} </td>
  </ng-container>
  <ng-container matColumnDef="html">
      <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-html" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"></th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> 
        <svg class="iconeGridDownload" id="informacoes-documentacoes-tab" title="Download" (click)="downloadPorCodigo(row.codigo,row.nome)">
          <use attr.xlink:href="assets/sprite.svg#{{ 'download' }}"></use>
        </svg> 
      </td>
  </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="row" *ngIf="dataSource.filteredData.length == 0">
    <div class="form-row col-12 pt-3 justify-content-center align-items-center">
      <p class="card-text text-center">
        <br>{{ mensagemDeBusca }}<br>&nbsp;
      </p>
    </div>
  </div>
  <mat-paginator [pageSize]="12" [pageSizeOptions]="[6, 12, 24, 48, 60, 120]"></mat-paginator> 
  <div class="row col-12 justify-content-center align-items-center">
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Excel', sheet: 'sheet_name', Props: {Author: 'Talha'}})">EXCEL</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('json')">JSON</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('txt')">TXT</button>
  </div>
</div>
<div>&nbsp;</div>

    </div>
  </div>