<div class="container-principal">
    <article>
        <div class="container-content row  mb-2">
            <div class="col-12 text-center">
                <h3>
                    <b>POLÍTICA DE PRIVACIDADE E TERMOS DE USO A SER UTILIZADA NOS SÍTIOS DE INTERNET, SISTEMAS E APLICATIVOS GERIDOS PELA PREFEITURA MUNICIPAL</b>
                </h3>
            </div>
        </div>
        <hr>
        <div class="col-12 mt-2 mb-5">
            <h4>
                <b>1. DA POLÍTICA DE PRIVACIDADE</b>
            </h4>
            <h4 class="mt-4 mb-2"><b>1.1. Tipos de dados coletados</b></h4>
            <p>
                1.1.1. Os dados serão coletados diretamente do usuário em seu cadastramento e uso do sistema, nos cadastros acessados pelo sistema (ainda que obtidos por integrações com outros sistemas municipais e convênios) ou automaticamente (pela utilização de cookies ou tecnologia similar).<br><br>
                1.1.2. A falta de preenchimento de todos os dados solicitados como obrigatórios por este Aplicativo poderá impossibilitar este Aplicativo de fornecer os seus serviços.<br><br>
                1.1.3. Os Usuários ficam responsáveis por quaisquer Dados Pessoais de terceiros que forem obtidos, publicados ou compartilhados através deste serviço (este Aplicativo) e confirmam que possuem a autorização dos terceiros para fornecerem os Dados para o Município.<br><br>
                1.2. Finalidade dos dados obtidos<br><br>
                1.2.1. Desenvolver, manter e aperfeiçoar os recursos e funcionalidades do Sítio e/ou Aplicativo;<br><br>
                1.2. 2. Possibilitar o acesso e o uso dos recursos e funcionalidades do Sítio e/ou Aplicativo pelos Usuários;<br><br>
                1.2.3. Analisar o desempenho do Sítio e/ou Aplicativo, medir a audiência do Sítio e/ou Aplicativo, verificar os hábitos de navegação dos Usuários no Sítio e/ou Aplicativo, a forma pela qual chegaram à página do Sítio e/ou Aplicativo (por exemplo, através de links de outros Sítios, buscadores ou diretamente pelo endereço), avaliar estatísticas relacionadas ao número de acessos e uso do Sítio e/ou Aplicativo, seus recursos e funcionalidades;<br><br>
                1.2.4. Analisar a segurança do Sítio e/ou Aplicativo, aperfeiçoamento e desenvolvimento de ferramentas antifraude;<br><br>
                1.2.5. Melhorar a experiência de navegação dos Usuários no Sítio e/ou Aplicativo;<br><br>
                1.2.6. Permitir o fornecimento de serviços mais personalizados e adequados às necessidades dos Usuários, tais como páginas de perfil, atualizações e conteúdos relevantes;<br><br>
                1.2.7. Permitir a comunicação entre os Usuários e a Prefeitura Municipal, inclusive mediante o envio e recebimento de e-mails;<br><br>
                1.2.8. Identificar os perfis, hábitos e necessidades para auxílio ao Município na melhor execução de suas competências;<br><br>
                1.2.9. Ser utilizado para estudos, análises, e como material instrutório em assuntos tributários e administrativos, respeitadas as garantias constitucionais e legais, em especial às aplicáveis ao uso da internet;<br><br>
                1.2.10. Serem utilizados para fins jurídicos pela Municipalidade em juízo ou nas etapas conducentes à possível ação jurídica decorrente de uso indevido deste serviço (este Aplicativo) ou dos serviços relacionados.<br><br>
            </p>
            <h4 class="mt-4 mb-2"><b>1.3. Dados cadastrais</b></h4>
            <p>
                1.3.1. A utilização dos Sítios e/ou Aplicativo pode estar condicionada à utilização de certificação digital no formato e-CPF ou e-CNPJ, e/ou a cadastramento de usuário mediante utilização do número de CPF e/ou CNPJ como campo chave.<br><br>
                1.3.2. Durante o cadastramento, o usuário pode ser demandado a atualizar informações cadastrais obtidas nos registros municipais, ou ainda estaduais ou federais.<br><br>
                1.3.3. O Usuário se responsabiliza pela precisão e veracidade dos dados informados e reconhece que a inconsistência destes poderá implicar a impossibilidade de acessar o Sítio e/ou Aplicativo, além das sanções administrativas, civis, e penais previstas na legislação brasileira, em especial na tributária e administrativa.<br><br>
                1.3.4. O Usuário assume inteira responsabilidade pela guarda, sigilo e boa utilização do Login e Chave de Acessos cadastrados.<br><br>
                1.3.5. O Login e Chave de Acesso, em qualquer modalidade de autenticação, só poderão ser utilizados pelo Usuário cadastrado, sendo expressamente proibido o compartilhamento de Login e/ou Chave de Acesso com quaisquer terceiros.<br><br>
                1.3.6. Mesmo que Usuário exclua ou cancele seu cadastro no Sítio e/ou Aplicativo, fica ressalvada a guarda pela Prefeitura Municipal e pelos Entes Conveniados das informações e/ou dados cuja manutenção seja a eles imposta em razão de obrigações normativas ou, ainda, cuja a manutenção seja necessária para cumprimento de ordem judicial, no âmbito de processos judiciais e/ou administrativos e questionamento de terceiros decorrentes das atividades desempenhadas pelo Usuário no Sítio e/ou Aplicativo.<br><br>
            </p>
            <h4 class="mt-4 mb-2"><b>1.4. Informações coletadas automaticamente</b></h4>
            <p>
                1.4.1. Poderão ser capturadas e armazenadas informações fornecidas automaticamente pelo dispositivo eletrônico utilizado pelo usuário, seu navegador ou por meio da utilização de cookies e outras ferramentas:<br><br>
                1.4.1.1. Modelo de hardware do dispositivo eletrônico utilizado pelo usuário;<br><br>
                1.4.1.2. Sistema operacional e versão utilizados;<br><br>
                1.4.1.3. Tipo de navegador e versão utilizada;<br><br>
                1.4.1.4.  Identificadores de dispositivos exclusivos;<br><br>
                1.4.1.5. Informações sobre a rede utilizada e o número de telefone;<br><br>
                1.4.1.6. Idioma utilizado no dispositivo ou navegador;<br><br>
                1.4.1.7. Resolução de tela;<br><br>
                1.4.1.8. Identificador de publicidade;<br><br>
                1.4.1.9. Identificadores exclusivos do aplicativo;<br><br>
                1.4.1.10. Endereço de Protocolo de Internet (Endereço IP);<br><br>
                1.4.1.11. Localização geográfica (geolocalização);<br><br>
                1.4.1.12. Duração do acesso;<br><br>
                1.4.1.13. Informações de identificador de SMS;<br><br>
                1.4.1.14. Páginas visualizadas dentro do Sítio;<br><br>
                1.4.1.15. Forma de utilização do aplicativo;<br><br>
                1.4.1.16. Informações que são coletadas por cookies e outras ferramentas.<br><br>
                1.4.2. O Usuário poderá desabilitar os cookies por meio das opções de configuração do seu respectivo navegador. Contudo, ao decidir pela proibição dos cookies, o Usuário está ciente e reconhece que é possível que o Sítio e/ou Aplicativo não desempenhe todas as suas funcionalidades.<br><br>
                1.4.3. Poderão ser coletadas automaticamente informações recebidas de terceiros que usuários fornecem acerca uns dos outros quando utilizam o aplicativo, sistema ou redes sociais, bem como informações compartilhadas publicamente pelo usuário na internet, mídias sociais, ou informações obtidas de outras empresas afiliadas e/ou parceiras, ou ainda de terceiros contratados com o objetivo de coleta e análise de informações.<br><br>
                1.4.4. Poderão ainda, serem coletadas informações constantes em outras bases governamentais.<br><br>
            </p>
            <h4 class="mt-4 mb-2"><b>1.5. Tratamento das informações</b></h4>
            <p>
                1.5.1. O fornecimento das Informações, supramencionadas, implicará a expressa autorização para que estas sejam utilizadas para o fornecimento dos serviços públicos ou desenvolvimento das atividades estatais, com o propósito definido na lei, ou em negócio jurídico, bem como para que tais informações sejam arquivadas.<br><br>
                1.5.2. Uma vez provido das Informações Pessoais a respeito do usuário, a Prefeitura Municipal poderá utilizar os dados do usuário para o fim de enviar comunicados, direcionada por e-mail ou por quaisquer outros meios de comunicação, contendo informações sobre o Município ou sobre sua atividade estatal.<br><br>
                1.5.3. Entretanto, não havendo obrigatoriedade legal ou regulamentar, fica reservado ao usuário o direito de, a qualquer momento, inclusive no ato da disponibilização das Informações, informar, por meio dos canais de comunicação disponíveis para o cadastramento de tais informações, do não interesse em receber comunicados, hipótese em que o Município interromperá tais serviços no menor tempo possível.<br><br>
            </p>
            <h4 class="mt-4 mb-2"><b>1.6. Declaração de privacidade</b></h4>
            <p>
                1.6.1. O acesso às Informações Pessoais coletadas e armazenadas pela Prefeitura Municipal é restrito aos profissionais autorizados ao uso dessas informações, e necessário à prestação de suas atribuições, sendo limitado o uso para outras tarefas.<br><br>
                1.6.2. Os profissionais, inclusive terceiros, na qualidade de agentes públicos, se submetem a legislação administrativa vigente, devendo respeitar os princípios do Direito Administrativo, o código de conduta municipal instituído pela Controladoria Geral do Município - CGM, submetendo-se às sanções previstas na Lei Municipal, que instituiu o Estatuto do Servidor Público Municipal, e na Lei Federal nº 8.429 de 2 de junho de 1.992 que trata da Improbidade Administrativa - LIA<br><br>
                1.6.3. A Prefeitura Municipal, nos termos da legislação vigente, poderá revelar as Informações que tenha recebido, concordando, desde já, o usuário com tal revelação, desde que não conflitem com as leis que tratam de sigilo, nas seguintes hipóteses:<br><br>
                1.6.3.1. Sempre que estiver obrigado a revelá-las, seja em virtude de dispositivo legal, ato de autoridade competente, ordem ou mandado judicial;<br><br>
                1.6.3.2. Aos seus parceiros comerciais e/ou prestadores de serviço, a fim de atender à solicitação de serviços efetuada pelos usuários;<br><br>
                1.6.3.3. As empresas por ele controladas;<br><br>
                1.6.3.4. Aos Entes Conveniados, nos termos do convênio.<br><br>
            </p>
            <h4 class="mt-4 mb-2"><b>1.7. Método de processamento</b></h4>
            <p>
                1.7.1. A Prefeitura Municipal processa os dados de Usuários de forma adequada, em conformidade com as certificações que lhe são emitidas, e com as políticas municipais de governança de Tecnologia da Informação, ainda, tomam as medidas de segurança adequadas para impedir o acesso não autorizado, divulgação, alteração ou destruição não autorizada dos Dados.<br><br>
                1.7.2. O processamento de dados é realizado utilizando computadores e /ou ferramentas de TI habilitadas pela Prefeitura Municipal, e/ou em base de dados mantidas na nuvem pelos fornecedores de serviços contratados pela Prefeitura Municipal, os quais estão devidamente de acordo com a legislação de dados vigente e em conformidade com as diretrizes da Secretaria Municipal de Tecnologia da Informação, seguindo procedimentos organizacionais e meios estritamente relacionados com os fins indicados.<br><br>
            </p>
        </div>
        <div class="col-12 mt-2 mb-5">
            <h4>
                <b>2. EXTENSÃO DOS EFEITOS</b>
            </h4>
<b>            <h4 class="mt-4 mb-2"></h4></b>            <p>
                2.1. Os termos da Política de Privacidade aqui expostos aplicar-se-ão exclusivamente às Informações acima definidas, que venham a ser disponibilizadas à Prefeitura Municipal, pelo usuário para a utilização de seus produtos e serviços em sítios e/ou aplicativos.<br><br>
                2.2. A Política de Privacidade aqui exposta poderá não ser aplicável a outro serviço, mesmo que municipal, que não sejam os disponibilizados pela própria Prefeitura Municipal.<br><br>
            </p>
        </div>
        <div class="col-12 mt-2 mb-5">
            <h4>
                <b>3. DOS TERMOS DE USO</b>
            </h4>
            <h4 class="mt-4 mb-2"><b>3.1. Ao Usuário é vedado o(a)</b></h4>
            <p>
                3.1.1. Prática de quaisquer atos ilícitos e/ou violação da legislação vigente, inclusive das disposições da Lei 9.613/98 e da Lei 12.846/13;<br><br>
                3.1.2. Atos contrários à moral e aos bons costumes;<br><br>
                3.1.3. Carregamento, envio e/ou transmissão de qualquer conteúdo de cunho erótico, pornográfico, obsceno, difamatório ou calunioso ou que façam apologia ao crime, uso de drogas, consumo de bebidas alcoólicas ou de produtos fumígenos, violência física ou moral;<br><br>
                3.1.4. Carregamento, envio e/ou transmissão de qualquer conteúdo que promova ou incite o preconceito (inclusive de origem, raça, sexo, cor, orientação sexual e idade) ou qualquer forma de discriminação, bem como o ódio ou atividades ilegais;<br><br>
                3.1.5. Ameaça, coação, constrangimento físico ou moral aos demais Usuários;<br><br>
                3.1.6. Violação de direitos de terceiros;<br><br>
                3.1.7. Violação dos direitos de sigilo e privacidade alheios;<br><br>
                3.1.8. Atos que causem ou propiciem a contaminação ou prejudiquem quaisquer equipamentos da municipalidade, inclusive por meio de vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou por quaisquer outros dispositivos que venham a ser criados;<br><br>
                3.1.9. Praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam causar prejuízo a municipalidade, a qualquer Usuário e/ou a quaisquer terceiros;<br><br>
                3.1.10. Usar qualquer nome empresarial, marca, nome de domínio, slogan ou expressão de propaganda ou qualquer sinal distintivo ou bem de propriedade intelectual de titularidade da municipalidade e/ou de suas Afiliadas.<br><br>
            </p>
            <h4 class="mt-4 mb-2"><b>3.2. Responsabilidade do Usuário</b></h4>
            <p>
                3.2.1. Incide sobre todos e quaisquer atos ou omissões por ele realizados a partir de seu acesso à Internet, ao Sítio e/ou Aplicativo;<br><br>
                3.2.2. Sobre todo e qualquer conteúdo por ele carregado, enviado e/ou transmitido ao Sítio e/ou Aplicativo;<br><br>
                3.2.3. Obriga-se a proceder à reparação de todos e quaisquer danos, diretos ou indiretos (inclusive decorrentes de violação de quaisquer direitos de outros usuários, de terceiros, inclusive direitos de propriedade intelectual, de sigilo e de personalidade), que sejam causados a municipalidade, a qualquer outro Usuário, ou, ainda, a qualquer terceiro, inclusive em virtude do descumprimento do disposto nestes Termos de Uso e Política de Privacidade ou de qualquer ato praticado a partir de seu acesso à Internet, ao Sítio e/ou Aplicativo.<br><br>
            </p>
            <h4 class="mt-4 mb-2"><b>3.3. A Municipalidade se isenta de responsabilidade</b></h4>
            <p>
                3.3.1. Por qualquer ato ou omissão realizado e/ou dano causado pelo Usuário decorrente do acesso ao Sítio e/ou Aplicativo;<br><br>
                3.3.2. Pelo uso indevido do Sítio e/ou Aplicativo por qualquer Usuário ou terceiros e/ou pelos conteúdos carregados, enviados e/ou transmitido ao Sítio e/ou Aplicativos pelos Usuários;<br><br>
                3.3.3. Por falhas, impossibilidades técnicas ou indisponibilidades do sistema;<br><br>
                3.3.4. Pela instalação no equipamento do Usuário ou de terceiros, de vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de quaisquer outros dispositivos que venham a ser criados, em decorrência da navegação na Internet pelo Usuário.<br><br>
            </p>
        </div>
        <div class="col-12 mt-2 mb-5">
            <h4>
                <b>4. ALTERAÇÕES DO ACESSO E DOS TERMOS DE USO E POLÍTICAS DE PRIVACIDADE</b>
            </h4>
<b>            <h4 class="mt-4 mb-2"></h4></b>            <p>
                4.1. A Prefeitura Municipal, a qualquer tempo, a seu exclusivo critério e sem necessidade de qualquer aviso prévio ou posterior a qualquer Usuário ou terceiros, poderá:<br><br>
                4.1.1. Suspender, cancelar ou interromper o acesso ao Sítio e/ou Aplicativo e<br><br>
                4.1.2. Remover, alterar e/ou atualizar no todo ou em parte o Sítio e/ou Aplicativo bem como seus respectivos conteúdos e/ou Termos de Uso e Política de Privacidade. Qualquer alteração e/ou atualização destes Termos de Uso e Política de Privacidade passará a vigorar a partir da data de sua publicação no Sítio e/ou Aplicativo e deverá ser integralmente observada pelos Usuários.<br><br>
            </p>
        </div>
        <div class="col-12 mt-2 mb-5">
            <h4>
                <b>5. FORO</b>
            </h4>
<b>            <h4 class="mt-4 mb-2"></h4></b>            <p>
                5.1. Estes Termos de Uso e Política de Privacidade são regidos de acordo com a legislação brasileira. Quaisquer disputas ou controvérsias oriundas de quaisquer atos praticados no âmbito da utilização dos Sítios e/ou Aplicativos pelos Usuários, inclusive com relação ao descumprimento dos Termos de Uso e Política de Privacidade ou pela violação dos direitos da municipalidade, de outros Usuários e/ou de terceiros, inclusive direitos de propriedade intelectual, de sigilo e de personalidade, serão processadas na JUSPRO  Justiça Sem Processo Mediação e Conciliação S/S Ltda. como Câmara de Mediação apta a dirimir as questões envolvendo os Usuários titulares dos dados, nos termos do artigo 52, §7º da Lei Geral de Proteção de Dados.
            </p>
        </div>
    </article>
</div>
