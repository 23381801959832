import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, filter } from 'rxjs/operators';

import { TaxaService } from 'src/app/services/taxa/taxa.service';
import { TaxaLancamento } from 'src/app/model/taxaLancamento';
import { TaxaLancamentoParcela } from 'src/app/model/taxaLancamentoParcela';
import { Constantes } from 'src/app/model/constantes';
import { MascaraPipe } from 'src/app/pipes/mascara.pipe';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormValidator } from '../../../../components/validators/form-validator';
import Swal from 'sweetalert2';
import { isDate } from 'brazilian-values';
import { TaxaPagamento } from 'src/app/model/taxaPagamento';


@Component({
    selector: "app-baixa-manual",
    templateUrl: "./baixa-manual.component.html",
    styleUrls: ["./../gerenciamento-taxa.component.scss"]
})
export class BaixaManualComponent implements OnInit {
    
    @Output() acaoMenuAtendimento = new EventEmitter();

    formulario: FormGroup;

    emitirErro: boolean;
    mensagemErro: string;

    taxaLancamentos: Array<TaxaLancamento>;
    taxaLancamentoParcelas: Array<TaxaLancamentoParcela>;
    taxaLancamentoParaAtendimento: TaxaLancamento;
    taxaPagamentos: Array<TaxaPagamento>;
    parcelaUnica: boolean = false;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private mascara: MascaraPipe,
        private taxaService: TaxaService) {

        this.formulario = this.formBuilder.group({
            cliente: [""],
            codigo: [""],            
            protocolo: [""],
            inscricao: [""],
        });
            
    }

    ngOnInit(): void {
    }

    formatToBRL(valor, formControlName, lancamento, parcela) {
        this.formulario.controls[formControlName].setValue(this.mascara.transform(valor, 11));

        this.atualizarValores(lancamento, parcela);
    }

    formatToBRLSemAtualizarValores(valor, formControlName) {
        this.formulario.controls[formControlName].setValue(this.mascara.transform(valor, 11));
    }

    atualizarValores(lancamento, parcela) {
        var calculo = 
            parseFloat(this.taxaLancamentoParcelas.filter(taxaLancamentoParcela => { return taxaLancamentoParcela.codigoLancamento == lancamento && taxaLancamentoParcela.codigo == parcela })[0].subTotal.toString().replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2")) -
            parseFloat(this.formulario.controls[`subTotalDesconto_${ lancamento }_${ parcela }`].value.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2")) +
            parseFloat(this.formulario.controls[`subTotalMora_${ lancamento }_${ parcela }`].value.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2")) +
            parseFloat(this.formulario.controls[`subTotalJuros_${ lancamento }_${ parcela }`].value.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2")) +
            parseFloat(this.formulario.controls[`subTotalCorrecaoMonetaria_${ lancamento }_${ parcela }`].value.replace(/\D/g,"").replace(/(\d)(\d{2})$/,"$1.$2"));
        
        this.formulario.controls[`total_${ lancamento }_${ parcela }`].setValue(calculo.toFixed(2).replace(".", ","));
    }

    formatToBRLValor(valor) {
        return this.mascara.transform(valor, 11);
    }

    acaoBotaoVoltar() {
        this.limpar();
        this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Cancelar });
    }

    acaoConfirmarBaixa() {
        this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Atualizar_Lancamentos_Pos_Baixa });
    }

    atualizarParcela(taxaLancamentoParcela: TaxaLancamentoParcela) {
        this.preencherBaixa();
        
        if (this.formulario.controls[`dataPagamento_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].valid) {
            this.taxaService
                .atualizarParcelaRetroativo(taxaLancamentoParcela)
                .pipe(take(1))
                .subscribe(retorno => {
                    if (retorno.taxaLancamentoParcelas.length) {
                        taxaLancamentoParcela.codigo = retorno.taxaLancamentoParcelas[0].codigo;
                        taxaLancamentoParcela.codigoLancamento = retorno.taxaLancamentoParcelas[0].codigoLancamento;
                        taxaLancamentoParcela.codigoAcordo = retorno.taxaLancamentoParcelas[0].codigoAcordo;
                        taxaLancamentoParcela.numero = retorno.taxaLancamentoParcelas[0].numero;
                        taxaLancamentoParcela.situacao = retorno.taxaLancamentoParcelas[0].situacao;
                        taxaLancamentoParcela.dataSituacao = retorno.taxaLancamentoParcelas[0].dataSituacao;
                        taxaLancamentoParcela.vencimento = retorno.taxaLancamentoParcelas[0].vencimento;
                        taxaLancamentoParcela.subTotalMora = retorno.taxaLancamentoParcelas[0].subTotalMora;
                        taxaLancamentoParcela.subTotalJuros = retorno.taxaLancamentoParcelas[0].subTotalJuros;
                        taxaLancamentoParcela.subTotalCorrecaoMonetaria = retorno.taxaLancamentoParcelas[0].subTotalCorrecaoMonetaria;
                        taxaLancamentoParcela.subTotalDesconto = retorno.taxaLancamentoParcelas[0].subTotalDesconto;
                        taxaLancamentoParcela.subTotal = retorno.taxaLancamentoParcelas[0].subTotal;
                        taxaLancamentoParcela.total = retorno.taxaLancamentoParcelas[0].total;
                        taxaLancamentoParcela.loginProcessamento = retorno.taxaLancamentoParcelas[0].loginProcessamento;
                        taxaLancamentoParcela.processamento = retorno.taxaLancamentoParcelas[0].processamento;
                        taxaLancamentoParcela.loginCadastro = retorno.taxaLancamentoParcelas[0].loginCadastro;
                        taxaLancamentoParcela.adicionado = retorno.taxaLancamentoParcelas[0].adicionado;

                        this.formulario.controls[`subTotalDesconto_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].setValue(taxaLancamentoParcela.subTotalDesconto.toString());
                        this.formulario.controls[`subTotalMora_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].setValue(taxaLancamentoParcela.subTotalMora.toString());
                        this.formulario.controls[`subTotalJuros_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].setValue(taxaLancamentoParcela.subTotalJuros.toString());
                        this.formulario.controls[`subTotalCorrecaoMonetaria_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].setValue(taxaLancamentoParcela.subTotalCorrecaoMonetaria.toString());
                        this.formulario.controls[`total_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].setValue(taxaLancamentoParcela.total.toString());
                    }
                    Swal.fire({
                        title: retorno.titulo,
                        html: retorno.mensagem,
                        icon: retorno.status,
                        willClose: () => {
                            // this.acaoBotaoVoltar();
                        }
                    })
                });
        }
    }

    // -------------------------------------------------------------------------
    // Ações para salvar os dados - Início
    // -------------------------------------------------------------------------
    acaoBotaoSalvar() {
        if (this.validarFormulario()) {
        
          this.preencherBaixa();

          Swal.fire({
            text: 'Caro Auditor, será realizado uma pré baixa com os calculos dessa(s) parcela(s), a(s) baixa(s) só será(ão) concretizado a partir da segunda confirmação, deseja continuar?',
            icon: 'question',
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
          })
          .then((result) => {
            if (!result.dismiss) {
                this.taxaService
                    .previaBaixa(this.taxaPagamentos)
                    .pipe(take(1))
                    .subscribe(retorno => {
                        Swal.fire({
                            title: retorno.titulo,
                            html: retorno.mensagem,
                            icon: retorno.status,
                            customClass: {
                              confirmButton: 'btn btn-success',
                              cancelButton: 'btn btn-danger'
                            },
                            buttonsStyling: true,
                            showCancelButton: retorno.status != "error",
                            confirmButtonText: retorno.status != "error" ? 'Confirmar' : "OK",
                            cancelButtonText: 'Cancelar',
                            showLoaderOnConfirm: true,
                        })
                        .then((result) => {
                            if (!result.dismiss && retorno.status != "error") {
                                var taxaPagamentos = this.preencherBaixaPrevia(retorno.codigoPagamentosPre);
                                this.taxaService
                                    .baixarPorPrevia(taxaPagamentos)
                                    .pipe(take(1))
                                    .subscribe(retorno => {
                                        Swal.fire({
                                            title: retorno.titulo,
                                            html: retorno.mensagem,
                                            icon: retorno.status,
                                            willClose: () => {
                                                this.acaoConfirmarBaixa();
                                            }
                                        })
                                    });
                            }       
                        });
                    });
            }       
          });
        }

    }

    validarFormulario() {
        this.formulario.markAllAsTouched();
        
        if (this.formulario.invalid) {
          Swal.fire("", "Favor informar a data de pagamento da(s) parcela(s).", "error");

          return false;
        }

        this.emitirErro = false;
        this.mensagemErro = "";

        return true;
    }

    preencherBaixa() {
        this.taxaPagamentos = new Array<TaxaPagamento>();

        this.taxaLancamentoParcelas.forEach( taxaLancamentoParcela => {
            taxaLancamentoParcela.taxaBaixa.subTotalDesconto = this.formulario.controls[`subTotalDesconto_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaLancamentoParcela.taxaBaixa.subTotalMora = this.formulario.controls[`subTotalMora_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaLancamentoParcela.taxaBaixa.subTotalJuros = this.formulario.controls[`subTotalJuros_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaLancamentoParcela.taxaBaixa.subTotalCorrecaoMonetaria = this.formulario.controls[`subTotalCorrecaoMonetaria_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaLancamentoParcela.taxaBaixa.total = this.formulario.controls[`total_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaLancamentoParcela.taxaBaixa.dataPagamento = this.formulario.controls[`dataPagamento_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaLancamentoParcela.taxaBaixa.dataCredito = this.formulario.controls[`dataPagamento_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;

            var taxaPagamento = new TaxaPagamento();
            taxaPagamento.protocolo = this.taxaLancamentos[0].protocolo;
            taxaPagamento.inscricao = this.taxaLancamentos[0].inscricao;
            
            taxaPagamento.taxaBaixa.tipoBaixa = Constantes.TIPO_BAIXA.Manual;
            taxaPagamento.taxaBaixa.subTotalDesconto = this.formulario.controls[`subTotalDesconto_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaPagamento.taxaBaixa.subTotalMora = this.formulario.controls[`subTotalMora_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaPagamento.taxaBaixa.subTotalJuros = this.formulario.controls[`subTotalJuros_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaPagamento.taxaBaixa.subTotalCorrecaoMonetaria = this.formulario.controls[`subTotalCorrecaoMonetaria_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaPagamento.taxaBaixa.total = this.formulario.controls[`total_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaPagamento.taxaBaixa.dataPagamento = this.formulario.controls[`dataPagamento_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            taxaPagamento.taxaBaixa.dataCredito = this.formulario.controls[`dataPagamento_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].value;
            
            taxaPagamento.taxaLancamentoParcelas.push(taxaLancamentoParcela);
            
            this.taxaPagamentos.push(taxaPagamento)
        });

    }

    preencherBaixaPrevia(codigoPagamentosPre) {
        var taxaPagamentos = new Array<TaxaPagamento>();

        codigoPagamentosPre.split(",").forEach(codigo => {
            var taxaPagamento = new TaxaPagamento();
            taxaPagamento.codigo = codigo;

            taxaPagamentos.push(taxaPagamento);
        });

        return taxaPagamentos;
    }

    limpar() {
        this.cliente.reset();
        this.codigo.reset();
        this.protocolo.reset();
        this.inscricao.reset();

        this.cliente.setValue("");
        this.codigo.setValue("");
        this.protocolo.setValue("");
        this.inscricao.setValue("");
        
        this.taxaLancamentoParcelas = new Array<TaxaLancamentoParcela>();

        this.formulario = this.formBuilder.group({
            cliente: [""],
            codigo: [""],            
            protocolo: [""],
            inscricao: [""],
        });
    }

    preencherParaAtender(taxaLancamentos, taxaLancamentoParcelasSelecionadas, parcelaUnica) {
        this.parcelaUnica = parcelaUnica;
        this.taxaLancamentos = taxaLancamentos;
        
        taxaLancamentoParcelasSelecionadas.forEach(taxaLancamentoParcela => {
            var taxaLancamentoParcelaAuxiliar = new TaxaLancamentoParcela();

            taxaLancamentoParcelaAuxiliar.codigo = taxaLancamentoParcela.codigo;
            taxaLancamentoParcelaAuxiliar.codigoLancamento = taxaLancamentoParcela.codigoLancamento;
            taxaLancamentoParcelaAuxiliar.codigoAcordo = taxaLancamentoParcela.codigoAcordo;
            taxaLancamentoParcelaAuxiliar.numero = taxaLancamentoParcela.numero;
            taxaLancamentoParcelaAuxiliar.situacao = taxaLancamentoParcela.situacao;
            taxaLancamentoParcelaAuxiliar.dataSituacao = taxaLancamentoParcela.dataSituacao;
            taxaLancamentoParcelaAuxiliar.vencimento = taxaLancamentoParcela.vencimento;
            taxaLancamentoParcelaAuxiliar.subTotalMora = taxaLancamentoParcela.subTotalMora;
            taxaLancamentoParcelaAuxiliar.subTotalJuros = taxaLancamentoParcela.subTotalJuros;
            taxaLancamentoParcelaAuxiliar.subTotalCorrecaoMonetaria = taxaLancamentoParcela.subTotalCorrecaoMonetaria;
            taxaLancamentoParcelaAuxiliar.subTotalDesconto = taxaLancamentoParcela.subTotalDesconto;
            taxaLancamentoParcelaAuxiliar.subTotal = taxaLancamentoParcela.subTotal;
            taxaLancamentoParcelaAuxiliar.total = taxaLancamentoParcela.total;
            taxaLancamentoParcelaAuxiliar.loginProcessamento = taxaLancamentoParcela.loginProcessamento;
            taxaLancamentoParcelaAuxiliar.processamento = taxaLancamentoParcela.processamento;
            taxaLancamentoParcelaAuxiliar.loginCadastro = taxaLancamentoParcela.loginCadastro;
            taxaLancamentoParcelaAuxiliar.adicionado = taxaLancamentoParcela.adicionado;

            this.taxaLancamentoParcelas.push(taxaLancamentoParcelaAuxiliar);

            this.formulario.addControl(`subTotalDesconto_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`, new FormControl("00,000", Validators.required));
            this.formulario.addControl(`subTotalMora_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`, new FormControl("00,000", Validators.required));
            this.formulario.addControl(`subTotalJuros_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`, new FormControl("00,000", Validators.required));
            this.formulario.addControl(`subTotalCorrecaoMonetaria_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`, new FormControl("00,000", Validators.required));
            this.formulario.addControl(`total_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`, new FormControl("00,000"));
            this.formulario.addControl(`dataPagamento_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`, new FormControl("", [Validators.required, FormValidator.dataValida()]));
        })

        setTimeout(() => {
            this.taxaLancamentoParcelas.forEach(taxaLancamentoParcela => {
                this.formulario.controls[`subTotalDesconto_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].setValue(taxaLancamentoParcela.subTotalDesconto.toString());
                this.formulario.controls[`subTotalMora_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].setValue(taxaLancamentoParcela.subTotalMora.toString());
                this.formulario.controls[`subTotalJuros_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].setValue(taxaLancamentoParcela.subTotalJuros.toString());
                this.formulario.controls[`subTotalCorrecaoMonetaria_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].setValue(taxaLancamentoParcela.subTotalCorrecaoMonetaria.toString());
                this.formulario.controls[`total_${ taxaLancamentoParcela.codigoLancamento }_${ taxaLancamentoParcela.codigo }`].setValue(taxaLancamentoParcela.total.toString());
            })
        }, 100);
    }

    informacoesECalculos(taxaLancamentoParcela: TaxaLancamentoParcela) {
        var retorno = `* Lançamento <b>${ this.taxaLancamentos.filter(taxaLancamento => { return taxaLancamento.codigo == taxaLancamentoParcela.codigoLancamento })[0].identificacao }</b>`;
        retorno += `, parcela número <b>${ taxaLancamentoParcela.numero }</b>`;
        retorno += `, valor base <b>R$ ${ taxaLancamentoParcela.subTotal }</b>`;

        if (taxaLancamentoParcela.situacao == Constantes.PARCELA_SITUACAO.Vencida) {
            retorno += ` e atualmente ela encontra-se <b class="texto-danger">${ Constantes.PARCELA_SITUACAO_DESCRICAO[taxaLancamentoParcela.situacao] }</b>`;
        }
        else if (taxaLancamentoParcela.situacao == Constantes.PARCELA_SITUACAO.Paga) {
            retorno += ` e atualmente ela encontra-se <b class="texto-success">${ Constantes.PARCELA_SITUACAO_DESCRICAO[taxaLancamentoParcela.situacao] }</b>`;
        }

        return retorno;
    }

    validadorValores(valor1, valor2) {
        return valor1.toString().replace(/\D/g, "") != valor2.toString().replace(/\D/g, "");
    }

    get cliente() {
        return this.formulario.get("cliente");
    }
    set cliente(cliente) {
        this.formulario.get("cliente").setValue(cliente);
    }
    
    get codigo() {
        return this.formulario.get("codigo");
    }
    set codigo(codigo) {
        this.formulario.get("codigo").setValue(codigo);
    }
    
    get protocolo() {
        return this.formulario.get("protocolo");
    }
    set protocolo(protocolo) {
        this.formulario.get("protocolo").setValue(protocolo);
    }
    
    get inscricao() {
        return this.formulario.get("inscricao");
    }
    set inscricao(inscricao) {
        this.formulario.get("inscricao").setValue(inscricao);
    }


}
