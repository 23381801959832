import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { take } from 'rxjs/operators';
import * as pbi from 'powerbi-client';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { environment } from '../../../../../environments/environment.dev';


@Component({
    selector: "app-estatisticas-taxa",
    templateUrl: "./estatisticas-taxa.component.html",
    styleUrls: ["./../gerenciamento-taxa.component.scss"]
})
export class EstatisticasTaxaComponent implements OnInit {
    
    report: pbi.Embed;
    @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;

    constructor(private authenticationService: AuthenticationService) {
        
    }

    ngOnInit(): void {
        this.authenticationService
            .tokenPowerBI()
            .pipe(take(1))
            .subscribe(retorno => {
                this.showReport(retorno);
            })
    }

    showReport(Token) {
        // Embed URL    
        let embedUrl = environment.powerBI.reportBaseURL;
        let groupID = environment.powerBI.groupID;
        let embedReportId = '034ad498-a6a7-4354-86eb-71343369d1b6';
        let settings: pbi.IEmbedSettings = {
            // background: pbi.models.BackgroundType.Transparent,
            filterPaneEnabled: false,
            navContentPaneEnabled: true,
            persistentFiltersEnabled: false
        };
        let basicFilter: pbi.models.IBasicFilter = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "prefeitura",
              column: "id_prefeitura"
            },
            operator: "In",
            values: [parseInt(localStorage.getItem("pId"))],
            filterType: pbi.models.FilterType.Basic, // pbi.models.FilterType.BasicFilter
        };

        let config: pbi.IEmbedConfiguration = {
            type: 'report',
            tokenType: pbi.models.TokenType.Aad,
            accessToken: Token.access_token,
            embedUrl: embedUrl,
            groupId: groupID,
            id: embedReportId,
            filters: [
                basicFilter
            ],
            settings: settings
        };
        let reportContainer = this.reportContainer.nativeElement;
        let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        this.report = powerbi.embed(reportContainer, config);
        this.report.off("loaded");
        this.report.on("loaded", () => {
            console.log("Loaded");
            // this.setTokenExpirationListener(Token.expiration, 2);
        });
        this.report.on("error", () => {
            console.log("Error");
        });
    }


}
