<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<form class="justify-content-center align-items-center" name="formulario" [formGroup]="formulario">
    
    <div class="card" *ngIf="tipoEmpresaAuxiliar == 'UJ' || tipoEmpresaAuxiliar == 'UM' || tipoEmpresaAuxiliar == 'UF'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Requerente</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>NOME</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text">
                        {{ nomeUsuario }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>CPF / CNPJ</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text">
                        {{ formatToCPFOrCNPJReturn(documentoUsuario) }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>E-MAIL</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text">
                        {{ emailUsuario }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>DATA DA SOLICITAÇÃO</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text">
                        {{ dataAtual }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <app-form-tipo-negocio *ngIf="tipoEmpresaAuxiliar == 'UJ' || tipoEmpresaAuxiliar == 'UM' || tipoEmpresaAuxiliar == 'UF'" (acaoDeAtendimento)="atualizarTipoEmpresa($event)"></app-form-tipo-negocio>

    <div class="card" *ngIf="tipoEmpresaAuxiliar != 'UJ' && tipoEmpresaAuxiliar != 'UM' && tipoEmpresaAuxiliar != 'UF'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong *ngIf="tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA'">Dados do Contribuinte</strong>
                <strong *ngIf="tipoEmpresa != 'AE' && tipoEmpresa != 'AM' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">Dados do Requerente</strong>
                <strong *ngIf="tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV'">Identificação do Requerente</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="col-sm-12 col-form-label" for="cpfCnpj" *ngIf="tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">CPF / CNPJ</label>
                    <label class="col-sm-12 col-form-label" for="cpfCnpj" *ngIf="tipoEmpresa == 'EEV'">CNPJ do Requerente</label>
                    <label class="col-sm-12 col-form-label" for="cpfCnpj" *ngIf="tipoEmpresa == 'AEV'">CPF do Requerente</label>
                    <input #inputCpfCnpj 
                        (input)="formatToCPFOrCNPJ(inputCpfCnpj.value)" 
                        (blur)="verificaUsuario()" 
                        [class.is-invalid]="cpfCnpj.invalid && cpfCnpj.touched" 
                        autofocus 
                        class="form-control form-control-sm col-4" 
                        formControlName="cpfCnpj" 
                        id="cpfCnpj" 
                        name="cpfCnpj"
                        type="text" 
                        maxlength="18" 
                        *ngIf="tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'"/>
                    <input #inputCpfCnpj 
                        (input)="formatToCPF(inputCpfCnpj.value)" 
                        (blur)="verificaUsuario()" 
                        [class.is-invalid]="cpfCnpj.invalid && cpfCnpj.touched" 
                        autofocus 
                        class="form-control form-control-sm col-4" 
                        formControlName="cpfCnpj" 
                        id="cpfCnpj" 
                        name="cpfCnpj"
                        type="text" 
                        maxlength="14" 
                        *ngIf="tipoEmpresa == 'AEV'"/>  
                    <input #inputCpfCnpj 
                        (input)="formatToCNPJ(inputCpfCnpj.value)" 
                        (blur)="verificaUsuario()" 
                        [class.is-invalid]="cpfCnpj.invalid && cpfCnpj.touched" 
                        autofocus 
                        class="form-control form-control-sm col-4" 
                        formControlName="cpfCnpj" 
                        id="cpfCnpj" 
                        name="cpfCnpj"
                        type="text" 
                        maxlength="18" 
                        *ngIf="tipoEmpresa == 'EEV'"/>        
                    <div class="mb-1" *ngIf="cpfCnpj.invalid && cpfCnpj.touched">
                        <span class="erro" *ngIf="cpfCnpj.errors.required">Campo obrigatório</span>
                        <span class="erro" *ngIf="cpfCnpj.errors.minlength">O CPF / CNPJ deve conter ao menos 14 caracteres.</span>
                        <span class="erro" *ngIf="cpfCnpj.errors.maxlength">O CPF / CNPJ deve conter até 18 caracteres.</span>
                        <span class="erro" *ngIf="cpfCnpj.hasError('mensagem')">{{cpfCnpj.errors.mensagem}}</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="nome" *ngIf="tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">Nome</label>
                    <label class="col-sm-12 col-form-label" for="nome" *ngIf="tipoEmpresa == 'AEV'">Nome do Requerente</label>
                    <label class="col-sm-12 col-form-label" for="nome" *ngIf="tipoEmpresa == 'EEV'">Razão Social do Requerente</label>
                    <input [class.is-invalid]="nome.invalid && nome.touched" class="form-control form-control-sm" formControlName="nome" id="nome" name="nome" type="text" />
                    <div class="mb-1" *ngIf="nome.invalid && nome.touched">
                        <span class="erro" *ngIf="nome.errors.required">Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="email">E-mail</label>
                    <input [class.is-invalid]="email.invalid && email.touched" class="form-control form-control-sm" formControlName="email" id="email" name="email" type="text" />
                    <div class="mb-1" *ngIf="email.invalid && email.touched">
                        <span class="erro" *ngIf="email.errors.required">Campo obrigatório</span>
                        <span class="erro" *ngIf="email.errors.email">O valor digitado não é um e-mail válido.</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="telefoneFixo" *ngIf="tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">Telefone Particular</label>
                    <label class="col-sm-12 col-form-label" for="telefoneFixo" *ngIf="tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV'">Telefone Fixo</label>
                    <input #telefoneFixoInput (input)="formatToPhone(telefoneFixoInput)" class="form-control form-control-sm" formControlName="telefoneFixo" id="telefoneFixo" name="telefoneFixo" type="text" />
                </div>
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="telefoneComercial">Telefone Comercial</label>
                    <input #telefoneComercialInput (input)="formatToPhone(telefoneComercialInput)" class="form-control form-control-sm" formControlName="telefoneComercial" id="telefoneComercial" name="telefoneComercial" type="text" />
                </div>
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="celular">Celular</label>
                    <input #telefoneCelularInput (input)="formatToPhone(telefoneCelularInput)"  (change)="formatToPhone(telefoneCelularInput)" [class.is-invalid]="telefoneCelular.invalid && telefoneCelular.touched" class="form-control form-control-sm" formControlName="telefoneCelular" id="telefoneCelular" name="telefoneCelular"
                        type="text" maxlength="16" />
                    <div class="mb-1" *ngIf="telefoneCelular.invalid && telefoneCelular.touched">
                        <span class="erro" *ngIf="telefoneCelular.errors.required">Campo obrigatório</span>
                        <span class="erro" *ngIf="telefoneCelular.errors.maxlength">Telefone celular digitado é inválido.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'BE' && tipoEmpresa != 'BM' && tipoEmpresa != 'BA' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV' && tipoEmpresa != 'UF'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Local de Registro do Negócio</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="orgaoDeRegistro">Órgão de Registro</label>
                    <select #orgaoDeRegistroSelect [class.is-invalid]="orgaoDeRegistro.invalid && orgaoDeRegistro.touched" (change)="acaoOrgaoDeRegistroSelect(orgaoDeRegistroSelect.value)" formControlName="orgaoDeRegistro" class="form-control form-control-sm" name="orgaoDeRegistro"
                        id="orgaoDeRegistro">
                        <option value="" *ngIf="tipoEmpresa != 'M'"></option>
                        <option *ngFor="let servico of servicos" 
                            [selected]="tipoEmpresa == 'M' && servico.codigoAuxiliar == 1"
                            value="{{ servico.codigoAuxiliar }}"
                        >
                            {{ servico.descricao }}
                        </option>
                    </select>
                    <div class="mb-1" *ngIf="orgaoDeRegistro.invalid && orgaoDeRegistro.touched">
                        <span class="erro" *ngIf="orgaoDeRegistro.errors.required">Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-8">
                    <label class="col-sm-12 col-form-label" for="naturezaJuridica">Natureza Jurídica</label>
                    <select #naturezaJuridicaSelect [class.is-invalid]="naturezaJuridica.invalid && naturezaJuridica.touched" formControlName="naturezaJuridica" class="form-control form-control-sm" name="naturezaJuridica" id="naturezaJuridica">
                        <option value="" *ngIf="tipoEmpresa != 'M'"></option>
                        <option *ngFor="let naturezaJuridica of listaDeNaturezas" 
                            [selected]="(tipoEmpresa == 'M' || tipoEmpresa == 'AM') && naturezaJuridica.codigo == '213-5'"
                            value="{{ naturezaJuridica.codigo }}"
                        >
                            {{ naturezaJuridica.codigo }} - {{ naturezaJuridica.nome }}
                        </option>
                    </select>
                    <div class="mb-1" *ngIf="naturezaJuridica.invalid && naturezaJuridica.touched">
                        <span class="erro" *ngIf="naturezaJuridica.errors.required">Campo obrigatório</span>
                    </div>
                </div>
            </div>
        </div>
    </div>



</form>