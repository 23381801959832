import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { Protocolo } from "src/app/model/protocolo";
import { Constantes } from "src/app/model/constantes";
import { PerguntaResposta } from "src/app/model/perguntaResposta";
import { InformacaoLocalizacaoComponent } from "../informacoes-cadastro/informacao-localizacao/informacao-localizacao.component";
import { InformacaoRequerenteComponent } from "../informacoes-cadastro/informacao-requerente/informacao-requerente.component";
import { InformacaoIdentificacaoComponent } from "../informacoes-cadastro/informacao-identificacao/informacao-identificacao.component";
import { InformacaoAtividadeComponent } from "../informacoes-cadastro/informacao-atividade/informacao-atividade.component";
import { InformacaoResponsavelComponent } from "../informacoes-cadastro/informacao-responsavel/informacao-responsavel.component";
import { InformacaoQuestionarioComponent } from "../informacoes-cadastro/informacao-questionario/informacao-questionario.component";
import { Empresa } from "src/app/model/empresa";
import { InformacaoEncerramentoComponent } from '../informacoes-cadastro/informacao-encerramento/informacao-encerramento.component';
import { AnexarComponent } from "../anexar/anexar.component";
import { ActivatedRoute } from "@angular/router";
import { AtendimentoAcaoComponent } from "../atendimento/atendimento-acao/atendimento-acao.component";

@Component({
    selector: "app-form-resumo",
    templateUrl: "./form-resumo.component.html",
    styleUrls: ["./form-resumo.component.scss"]
})
export class FormResumoComponent implements OnInit {

    formulario: FormGroup;

    numeroProtocolo: string = "";
    protocolo: Protocolo;
    empresa: Empresa;

    @Input() tipoEmpresa = "";
    @Input() processo = "";
    tipoProcesso = Constantes.PROCESSO;

    apenasCorrespondencia: boolean;

    nomeUsuario: string;
    documentoUsuario: string;
    dataAtual: Date;
    emailUsuario: string;
    dadosAuditor: boolean = false;
    cliente: string;

    @ViewChild(InformacaoRequerenteComponent)
    informacaoRequerente: InformacaoRequerenteComponent;
    
    @ViewChild(InformacaoIdentificacaoComponent)
    informacaoIdentificacao: InformacaoIdentificacaoComponent;
    
    @ViewChild(InformacaoLocalizacaoComponent)
    informacaoLocalizacao: InformacaoLocalizacaoComponent;
    
    @ViewChild(InformacaoEncerramentoComponent)
    informacaoEncerramento: InformacaoEncerramentoComponent;
    
    @ViewChild(InformacaoAtividadeComponent)
    informacaoAtividade: InformacaoAtividadeComponent;
    
    @ViewChild(InformacaoResponsavelComponent)
    informacaoResponsavel: InformacaoResponsavelComponent;

    @ViewChild("informacaoQuestionarioViabilidade")
    informacaoQuestionarioViabilidade: InformacaoQuestionarioComponent;

    @ViewChild("informacaoQuestionarioFic")
    informacaoQuestionarioFic: InformacaoQuestionarioComponent;

    @ViewChild("informacaoQuestionarioViabilidadeEvento")
    informacaoQuestionarioViabilidadeEvento: InformacaoQuestionarioComponent;

    @ViewChild("informacaoQuestionarioFicEvento")
    informacaoQuestionarioFicEvento: InformacaoQuestionarioComponent;

    @ViewChild("informacaoQuestionarioEncerramento")
    informacaoQuestionarioEncerramento: InformacaoQuestionarioComponent;

    @ViewChild("informacaoQuestionarioRecadastro")
    informacaoQuestionarioRecadastro: InformacaoQuestionarioComponent;

    @ViewChild(AtendimentoAcaoComponent)
    atendimentoAcao: AtendimentoAcaoComponent;
    
    @ViewChild(AnexarComponent)
    anexar: AnexarComponent;

    constructor(
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder) {

        this.formulario = this.formBuilder.group({
            termoDeAceite: ["", Validators.required]
        });

        this.protocolo = new Protocolo();
        this.protocolo.viabilidade.perguntasUsuario = new PerguntaResposta();

        this.empresa = new Empresa();

        this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "";

        this.cliente = localStorage.getItem("pId");
    }

    ngOnInit() {}

    preencher() {
        this.informacaoRequerente.protocolo = this.protocolo;
        this.informacaoIdentificacao.protocolo = this.protocolo;
        this.informacaoLocalizacao.protocolo = this.protocolo;
        this.informacaoLocalizacao.apenasCorrespondencia = this.apenasCorrespondencia;
        this.informacaoEncerramento.protocolo = this.protocolo;
        this.informacaoAtividade.protocolo = this.protocolo;
        this.informacaoAtividade.empresa = this.empresa;
        this.informacaoResponsavel.protocolo = this.protocolo;
        this.informacaoQuestionarioViabilidade.perguntasUsuario = this.protocolo.viabilidade.perguntasUsuario;
        this.informacaoQuestionarioFic.perguntasUsuario = this.protocolo.fic.perguntasUsuario;
        this.informacaoQuestionarioViabilidadeEvento.perguntasUsuario = this.protocolo.viabilidade.perguntasUsuario;
        this.informacaoQuestionarioFicEvento.perguntasUsuario = this.protocolo.fic.perguntasUsuario;
        this.informacaoQuestionarioEncerramento.perguntasUsuario = this.protocolo.encerramento.perguntasUsuario;
        this.informacaoQuestionarioRecadastro.perguntasUsuario = this.protocolo.fic.perguntasUsuario;


        if(this.dadosAuditor == true){
            this.informacaoRequerente.nomeUsuario = this.nomeUsuario;
            this.informacaoRequerente.documentoUsuario = this.documentoUsuario;
            this.informacaoRequerente.dataAtual = this.dataAtual;
            this.informacaoRequerente.emailUsuario = this.emailUsuario;
            this.informacaoRequerente.dadosAuditor = this.dadosAuditor;
        }
    }

    preencherAnexos() {
        this.protocolo.anexos = this.anexar.anexos;
    }
    
    get termoDeAceite() {
        return this.formulario.get("termoDeAceite");
    }
    set termoDeAceite(termoDeAceite) {
        this.formulario.get("termoDeAceite").setValue(termoDeAceite);
    }

}