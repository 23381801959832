export class DocumentoEndereco {
    codigo: string;
    endereco: string;
    enderecoTemporario: string;
    nome: string;
    extensao: string;
    observacao: string;
    data: string;

    constructor() {
        this.codigo = "";
        this.endereco = "";
        this.enderecoTemporario = "";
        this.nome = "";
        this.extensao = "";
        this.observacao = "";
        this.data = "";
    }
}