import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";

import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { Constantes } from "src/app/model/constantes";
import { FormRequerenteComponent } from "src/app/pages/outros/form-requerente/form-requerente.component";
import { FormIdentificacaoComponent } from "src/app/pages/outros/form-identificacao/form-identificacao.component";
import { FormIdentificacaoNegocioComponent } from "src/app/pages/outros/form-identificacao-negocio/form-identificacao-negocio.component";
import { FormLocalizacaoComponent } from "src/app/pages/outros/form-localizacao/form-localizacao.component";
import { FormAtividadeComponent } from "src/app/pages/outros/form-atividade/form-atividade.component";
import { FormResponsavelComponent } from "src/app/pages/outros/form-responsavel/form-responsavel.component";
import { FormQuestionarioComponent } from "src/app/pages/outros/form-questionario/form-questionario.component";
import { FormResumoComponent } from "src/app/pages/outros/form-resumo/form-resumo.component";
import { Configuracao } from "src/app/model/configuracao";
import { Servico } from "src/app/model/servico";
import { MatStepper } from "@angular/material/stepper";
import { Pergunta } from "src/app/model/pergunta";
import { Protocolo } from "src/app/model/protocolo";
import { Processo } from "src/app/model/processo";
import { Documento } from "src/app/model/documento";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import { PerguntaResposta } from "src/app/model/perguntaResposta";
import { EmpresaService } from "src/app/services/empresa/empresa.service";
import { Empresa } from "src/app/model/empresa";
import { ComprovanteRecadastro } from "src/app/components/pdf/comprovanteRecadastro";
import { Atividade } from "src/app/model/atividade";
import { ThrowStmt } from "@angular/compiler";

@Component({
    selector: "app-recadastro-empresa-mei",
    templateUrl: "./recadastro-empresa-mei.component.html"
})
export class RecadastroEmpresaMeiComponent implements OnInit {
    
    tipoEmpresa: string;
    informativo: string;
    stepper: MatStepper;
    passoAnterior: number;
    passoAtual: number;
    configuracao: Configuracao;
    processo: Processo;
    tipoProcesso = Constantes.PROCESSO;
    servicos: Array<Servico>;
    empresa: Empresa;
    protocolo: Protocolo;
    protocoloTemporario: Protocolo;
    documentos: Array<Documento>;

    // Formulários
    formRecadastro: FormGroup;
    formularioRequerente: FormGroup;
    formularioIdentificacao: FormGroup;
    formularioIdentificacaoNegocio: FormGroup;
    formularioLocalizacao: FormGroup;
    formularioAtividade: FormGroup;
    formularioResponsavel: FormGroup;
    formularioQuestionarioRecadastro: FormGroup;
    formularioResumoAlteracao: FormGroup;

    @ViewChild(FormRequerenteComponent)
    formRequerente: FormRequerenteComponent;

    @ViewChild(FormIdentificacaoComponent)
    formIdentificacao: FormIdentificacaoComponent;

    @ViewChild(FormIdentificacaoNegocioComponent)
    formIdentificacaoNegocio: FormIdentificacaoNegocioComponent;

    @ViewChild(FormLocalizacaoComponent)
    formLocalizacao: FormLocalizacaoComponent;

    @ViewChild(FormAtividadeComponent)
    formAtividade: FormAtividadeComponent;

    @ViewChild("formQuestionarioRecadastro")
    formQuestionarioRecadastro: FormQuestionarioComponent;

    @ViewChild(FormResponsavelComponent)
    formResponsavel: FormResponsavelComponent;

    @ViewChild(FormResumoComponent)
    formResumo: FormResumoComponent;

    // Botões
    btnAnteriorLocalizacao: string = "Anterior";
    btnAvancarLocalizacao: string = "Avançar";

    // Botões
    btnAnteriorResponsavel: string = "Anterior";
    btnAvancarResponsavel: string = "Avançar";

    emitirErroIdentificacao: boolean;
    mensagemErro: string;

    statusRecadastro: string;

    constructor(
        private router: Router,
        private protocoloService: ProtocoloService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private empresaService: EmpresaService) {

        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];    

        this.empresa = new Empresa();
        this.empresa.registro.documentos = [new Documento()];

        this.protocolo = new Protocolo();
        this.protocoloTemporario = new Protocolo();
        this.protocoloTemporario.cliente = localStorage.getItem("pId");
        this.protocoloTemporario.fic.perguntasUsuario = new PerguntaResposta();
        
        this.formRecadastro = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
        });

        this.informativo = Constantes.INFORMATIVO_RECADASTRO_EMPRESA_MEI[0];

        this.documentos = new Array<Documento>();

        this.processo = new Processo();

        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        
        this.configuracao.processos
            .forEach(processo => { 
                if (processo.codigo === "A" && processo.tipo === "S" && processo.tipoPessoa == Constantes.TIPO_PESSOA.Juridica) {
                    this.processo.cliente = processo.cliente;
                    this.processo.codigo = 'R';
                    this.processo.descricao = processo.descricao;
                    this.processo.protocolo = processo.protocolo;
                    this.processo.tipo = 'R';
                    this.protocoloTemporario.processo = this.processo;
                    this.servicos = processo.servicos;

                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {

                        processo.servicos.forEach(servico => {
                            if (servico.codigo == 1) {
                                this.servicos = [servico];
                            }
                        });
                    }    
                } 
            }
            
        );

        this.emitirErroIdentificacao = false;
        this.protocolo = Object.assign(this.protocolo, this.protocoloTemporario);
    }

    ngOnInit(): void {
        this.passoAnterior = 0;
        this.passoAtual = 0;

        this.empresaService
            .verificaPeriodoRecadastro(localStorage.getItem("pId"))
            .pipe(take(1))
            .subscribe( retorno => {
                this.statusRecadastro = retorno;

                if (this.statusRecadastro == "S") 
                    this.montagemFormulario()
             });

    }

    montagemFormulario() {
        setTimeout(() => {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioIdentificacao = this.formIdentificacao.formulario;
            this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionarioRecadastro = this.formQuestionarioRecadastro.formulario;
            this.formularioResumoAlteracao = this.formResumo.formulario;
        }, 0);
    }

    verificaValidacaoFormulario(formGroup: FormGroup) {
        
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });
    }

    buscarEmpresa() {
        let cnpj = "";
    
        this.empresa.registro.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj)
                cnpj = documento.numero;
        });

        /*if (cnpj.replace(/\D/g,"") == this.formIdentificacao.cpfCnpjEmpresa.value.replace(/\D/g,"")
            && this.empresa.inscricao.replace(/\D/g,"") == this.formIdentificacao.im.value.replace(/\D/g,"")) {
                this.stepper.next();
                return false;
        }*/

        this.empresaService
            .buscarParaRecadastro(
                this.protocoloTemporario.cliente, 
                this.formIdentificacao.cpfCnpjEmpresa.value.replace(/\D/g,""), 
                this.formIdentificacao.im.value.replace(/\D/g,""))
            .pipe(take(1))
            .subscribe( (retorno: Empresa) => {
                this.empresa = retorno;
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Fora_Do_Periodo) {
                    Swal.fire("", "Recadastro não permitido. Favor se informar sobre o período de Recadastramento.", "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Nao_Existe) {
                    Swal.fire("", "Inscrição Municipal não localizada na base de dados Municipal, verifique a informação tente novamente.", "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Alteracao_Em_Andamento) {
                    Swal.fire("", "Empresa já possui um processo em andamento.", "info");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Sem_Permissao) {
                    Swal.fire("", "Solicitante não possui vínculo com a empresa.", "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Empresa_Encerrada) {                    
                    Swal.fire({
                        title: "",
                        html: `Inscrição Municipal foi baixada em ${ this.empresa.encerramento.data }, não é possível solicitar um recadastro.`,
                        icon: "warning"
                    })
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA.Ativa) {
                    Swal.fire("", "Inscrição encontrada, pode seguir com os passos de recadastramento!", "success");
                    this.preencherCamposIdentificacaoNegocio();
                    //this.preencherCamposEndereco();
                    //this.preencherCamposAtividade();
                    //this.preencherCamposResponsavel();
                    //this.preencherCamposQuestionario();
                    this.stepper.next();
                }
            })
    }

    carregarIdentificacao() {
        this.formIdentificacaoNegocio.capitalSocial.enable();
        this.configuracao.processos.forEach( processo => {
            if (processo.codigo == "A" && processo.tipo == "S") {
                processo.servicos.forEach( servico => {
                    if (servico.codigo == this.formRequerente.orgaoDeRegistro.value) {
                        servico.naturezaJuridicas.forEach( natureza => {
                            if (natureza.codigo == this.formRequerente.naturezaJuridica.value) {
                                this.documentos = natureza.documentos;
                                this.formResponsavel.socioRegras = natureza.socioRegras;
                                this.formResponsavel.obrigatorioContador = natureza.obrigatorioContador;
                                if (!natureza.possuiCapitalSocial) {
                                    this.formIdentificacaoNegocio.capitalSocial.disable();
                                }
                                if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
                                    this.formResponsavel.obrigatorioContador = false;
                                }      
                            }
                        })
                    }
                })
            }
        });
    }

    preencherCamposIdentificacaoNegocio() {
        this.formIdentificacaoNegocio.preencherCamposRecadastro(this.empresa);

    }

    preencherCamposEndereco() {
        this.formLocalizacao.exibeFormularioEndereco = !this.empresa.viabilidade.enderecos.length;
        this.empresa.viabilidade.enderecos.forEach( endereco => {
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Empresarial) {
                this.formLocalizacao.preenchimentoInicial= false;
                this.formLocalizacao.preencherEnderecoEmpresarial(endereco);
            }
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Correspondencia) {
                this.formLocalizacao.preencherEnderecoCorrespondencia(endereco);
            }
        })
    }

    preencherCamposAtividade() {
        
        this.formAtividade.atividadesSelecionadas = new Array<Atividade>();
        this.formAtividade.atividadesSelecionadas = this.empresa.viabilidade.atividadesExercidas.concat(this.empresa.viabilidade.atividadesNaoExercidas);

        this.formAtividade.tipoUnidade.setValue(this.empresa.viabilidade.tipoUnidade);
        this.formAtividade.tipoUnidadeAuxiliar.setValue(this.empresa.viabilidade.tipoUnidadeAuxiliar);
        
        this.empresa.viabilidade.formasAtuacao.forEach( formaAtuacao => {
            this.formAtividade.formasAtuacaoSelecionadas.push(formaAtuacao.codigo);
        })
        this.formAtividade.descricaoAtividadeEconomica.setValue(this.empresa.viabilidade.descricaoAtividadeEconomica);
    }

    preencherCamposResponsavel() {
        this.formResponsavel.socios = this.empresa.fic.socios;
    }

    preencherCamposQuestionario() {
        this.empresa.fic.perguntasUsuario.perguntas.forEach( pergunta => {
            this.formQuestionarioRecadastro.perguntasRespondidasOutrosProcessos.push(pergunta);
        })
        
    }

    avancarPassoIdentificacao(passos) {

        if (this.stepper == null) {
            this.stepper = passos;
        } 

        
        this.formIdentificacao.controleValidade.setValue(true);
        this.formularioRequerente = this.formRequerente.formulario;
        this.formularioIdentificacao = this.formIdentificacao.formulario;
        this.verificaValidacaoFormulario(this.formularioRequerente);
        this.verificaValidacaoFormulario(this.formularioIdentificacao);
        if (this.formularioRequerente.valid && this.formularioIdentificacao.valid) {
            this.emitirErroIdentificacao = false;
            this.carregarIdentificacao();
            this.buscarEmpresa();
        }
        else {
            this.formIdentificacao.controleValidade.setValue("");
            let mensagemErro = "";
            
            if (this.formularioRequerente.invalid) {
                mensagemErro += "Favor corrigir os campos nos dados do requerente e/ou local de registro da empresa.<br>"
            }
            
            if (this.formularioIdentificacao.invalid) {
                mensagemErro += "Favor corrigir os campos de identificação da empresa.<br>"
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErroIdentificacao = true;
            this.mensagemErro = mensagemErro;
        }
        
    }

    avancarPassoIdentificacaoNegocio() {
        this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
        this.verificaValidacaoFormulario(this.formIdentificacaoNegocio.formulario);
        if (this.formularioIdentificacaoNegocio.valid) {
            this.formIdentificacaoNegocio.emitirErro = false;
            this.formIdentificacaoNegocio.mensagemErro = "";
            this.stepper.next();
        }
        else {
            let mensagemErro = "";
            
            if (this.formularioIdentificacaoNegocio.invalid) {
                mensagemErro += "Favor corrigir os campos obrigatórios.<br>"
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.formIdentificacaoNegocio.emitirErro = true;
            this.formIdentificacaoNegocio.mensagemErro = mensagemErro;
        }
    }

    renomearBotaoLocalizacao() {
        this.btnAnteriorLocalizacao = "Anterior";
        this.btnAvancarLocalizacao = "Avançar";

        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (!this.formLocalizacao.preenchimentoInicial) {
                this.btnAnteriorLocalizacao = "Cancelar";
                this.btnAvancarLocalizacao = "Confirmar";
            }
        }
    }

    voltarPassoLocalizacao() {
        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (this.formLocalizacao.preenchimentoInicial) {
                this.stepper.previous();
            }
            else {
                this.formLocalizacao.toggle();
            }
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoLocalizacao() {
        this.formularioLocalizacao = this.formLocalizacao.formulario;
        this.verificaValidacaoFormulario(this.formLocalizacao.formulario);
        if (this.formLocalizacao.validarFormulario())
            this.stepper.next();
        
    }

    avancarPassoAtividade() {
        this.formularioAtividade = this.formAtividade.formulario;
        if (this.formAtividade.validarFormulario())
            this.stepper.next();
    }

    renomearBotaoResponsavel() {
        this.btnAnteriorResponsavel = "Anterior";
        this.btnAvancarResponsavel = "Avançar";

        if (this.formResponsavel.editarResponsavel) {
            this.btnAnteriorResponsavel = "Cancelar";
            this.btnAvancarResponsavel = "Confirmar";
        }
    }

    voltarPassoResponsavel() {
        if (this.formResponsavel.editarResponsavel) {
            this.formResponsavel.toggle();
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoResponsavel() {
        this.formularioResponsavel = this.formResponsavel.formulario;
        if (this.formResponsavel.validarFormulario())
            this.stepper.next();
        
    }

    avancarPassoQuestionario() {
        this.verificaValidacaoFormulario(this.formQuestionarioRecadastro.formulario);
        if (this.formQuestionarioRecadastro.validarFormulario()) {
            this.stepper.next();
        }
    }

    compararAlteracoes() {

    }

    mudancaPasso(event, passos) {
        
        if (this.stepper == null) {
            this.stepper = passos;
        } 

        if (this.passoAtual == 0) {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioIdentificacao = this.formIdentificacao.formulario;
            this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionarioRecadastro = this.formQuestionarioRecadastro.formulario;
            this.formularioResumoAlteracao = this.formResumo.formulario;
        }
        // Problema de quando entrava na pagina de atividade já dava erro direto
        // if ( this.passoAtual == 2 ) {
        //     this.formAtividade.validarFormulario();
        // }

        if (event.previouslySelectedIndex == 1) {
            this.formResponsavel.capitalSocialMatriz = this.formIdentificacaoNegocio.capitalSocial.value;
        }
        
        if (event.selectedIndex == 0) {
            this.formIdentificacao.controleValidade.setValue("");
        }

        if (event.selectedIndex == 1) {
            this.formIdentificacaoNegocio.cnpj.disable();
            this.formIdentificacaoNegocio.dataCnpj.disable();

            if (this.formIdentificacaoNegocio.dataCnpj.value.trim() == "")
                this.formIdentificacaoNegocio.dataCnpj.enable();
        }
        
        if (event.selectedIndex == 2) {
            this.formLocalizacao.controleValidade.setValue("");
        }

        if ( event.selectedIndex == 3) {
            this.formAtividade.controleValidade.setValue("");
        }

        if ( event.selectedIndex == 4) {
            this.formResponsavel.controleValidade.setValue("");
        }
        
        if (event.selectedIndex == 5) {
            // Questionário de Recadastro
            this.formQuestionarioRecadastro.controleValidade.setValue("");
            this.formQuestionarioRecadastro.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
    
            let perguntaRecadastro = new Pergunta();
            perguntaRecadastro.cliente = localStorage.getItem("pId");
            perguntaRecadastro.tipo = Constantes.TIPO_DE_PERGUNTA.Recadastro;
            perguntaRecadastro.atividades = this.formAtividade.atividadesSelecionadas;
            
            this.formQuestionarioRecadastro.carregarPerguntas(perguntaRecadastro);
            this.formularioQuestionarioRecadastro = this.formQuestionarioRecadastro.formulario;
        }
        
        this.informativo = Constantes.INFORMATIVO_RECADASTRO_EMPRESA_MEI[event.selectedIndex];
        this.passoAtual = event.selectedIndex;
        this.passoAnterior = event.previouslySelectedIndex;

        this.montarRecadastro(event);
    }

    montarRecadastro(event) {
        if ( event.previouslySelectedIndex == 0) {

            let documento = new Documento();
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documento.nome = "CPF";
            documento.numero = localStorage.getItem("userId");

            this.protocoloTemporario.solicitante.documentos = [documento];
            
            this.formRequerente.preencher();
            this.protocoloTemporario.requerente = this.formRequerente.requerente;
            this.protocoloTemporario.registro = this.formRequerente.registro;
            this.processo.servicos = [this.formRequerente.servicoEscolhido];

            // Ajuste de fluxo - os fluxos são fixos no antigo Icad - ver forma de deixar dinâmico
            // Código de serviço tem que ser 71 para Empresa e 74 para Mei
            if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa) {
                this.processo.servicos[0].codigo = 71;
            }
            else if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
                this.processo.servicos[0].codigo = 74;
            }
            
            this.protocoloTemporario.data = new Date().toLocaleDateString("pt-br");
            this.formIdentificacaoNegocio.protocolo = this.protocoloTemporario;
            
        }
        else if ( event.previouslySelectedIndex == 1) {
            this.formIdentificacaoNegocio.preencher();
            this.protocoloTemporario = this.formIdentificacaoNegocio.protocolo;
        }
        else if (event.previouslySelectedIndex == 2) {
            this.protocoloTemporario.viabilidade.enderecos = [
                this.formLocalizacao.enderecoEmpresarial,
                this.formLocalizacao.enderecoCorrespondencia,
            ];
            this.formResumo.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
        }
        else if (event.previouslySelectedIndex == 3) {
            this.formAtividade.preencher();

            this.protocoloTemporario.viabilidade.atividadesExercidas = this.formAtividade.atividadesExercidas;
            this.protocoloTemporario.viabilidade.atividadesNaoExercidas = this.formAtividade.atividadesNaoExercidas;

            this.protocoloTemporario.viabilidade.tipoUnidade = this.formAtividade.tipoUnidade.value;
            this.protocoloTemporario.viabilidade.tipoUnidadeAuxiliar = this.formAtividade.tipoUnidadeAuxiliar.value;
            
            this.protocoloTemporario.viabilidade.formasAtuacao = this.formAtividade.formasAtuacao;
            this.protocoloTemporario.viabilidade.descricaoAtividadeEconomica = this.formAtividade.descricaoAtividadeEconomica.value;
        }
        else if (event.previouslySelectedIndex == 4) {
            this.protocoloTemporario.fic.socios = this.formResponsavel.socios;
        }
        else if (event.previouslySelectedIndex == 5) {
            this.formQuestionarioRecadastro.preencher();
            this.protocoloTemporario.fic.perguntasUsuario = this.formQuestionarioRecadastro.perguntasRespostas;
        }
        
        this.formResumo.empresa = this.empresa;
        this.formResumo.protocolo = this.protocoloTemporario;
        this.formResumo.preencher();
    }

    gerarRecadastro() {
        this.formResumo.preencherAnexos();
        
        if (!this.formResumo.termoDeAceite.value) {
            Swal.fire("", "Para que a solicitação seja finalizada ,você devera estar de acordo com o termo de responsabilidade.", "info")
            return false;
        }

        this.protocolo = Object.assign(this.protocolo, this.protocoloTemporario);
        
        
        this.protocoloService
            .gerarRecadastro(this.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    willClose: () => {
                        this.router.navigate(["/operacao/acompanhamento"]);
                    }
                })
            });
    }

}