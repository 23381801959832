export class GridMetodo {
    codigo: string;
    nome: string;
    descricao: string;
    icone: string;
    criacao: string;

    constructor() {
        this.codigo = "";
        this.nome = "";
        this.descricao = "";
        this.icone = "";
        this.criacao = "";
    }
}