<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="row col-12 justify-content-center align-items-center header-sub-titulo mb-0">
        <div class="row col-12" >
            <p class="w-100 card-text titulo-sub-titulo text-center">
                <strong>DESPACHAR</strong>
            </p>
        </div>
    </div>
    <div class="row col-12 quadro-divisor ps tamanho-quadro mt-0 pt-0" [perfectScrollbar]="config">
        <div class="w-100 linha-grid-divisao-top row-linha" *ngFor="let despachoFluxo of dados[0][0].fluxos; let i = index" >
            <div class="w-100" >
                <div class="row">
                    <div class="row col-12 mt-2 mb-2 justify-content-left align-items-center text-uppercase">
                        <mat-checkbox 
                            #despachoFluxoCheckbox
                            formControlName="despacho_{{ despachoFluxo.orgaoDestino.codigo }}"
                            id="despacho_{{ despachoFluxo.orgaoDestino.codigo }}"
                            name="despacho_{{ despachoFluxo.orgaoDestino.codigo }}"
                            [checked]="despachoFluxo.observacao != ''"
                            (change)="exigeJustificativa(despachoFluxoCheckbox, i, 'ADICIONAR')">
                        </mat-checkbox>
                        <b class="ml-2">{{ despachoFluxo.orgaoDestino.nome }}</b>
                    </div>
                </div>
                <div class="row justificativa-despacho mb-0" *ngIf="despachoFluxo.observacao != ''">
                    <div class="row col-11 justify-content-start align-items-center">
                        <p class="card-text w-100">
                            <strong>Observações: </strong>{{ despachoFluxo.observacao }}
                        </p>
                    </div>
                    <div class="row col-1 justify-content-end align-items-center">
                        <button type="button" 
                                matTooltip="Editar"
                                matTooltipPosition="above"
                                class="btn btn-sm"
                                (click)="exigeJustificativa(despachoFluxoCheckbox, i, 'ALTERAR')">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                                ></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row col-12 justify-content-center align-items-center linha-botao">
        <button 
            type="button" 
            class="btn btn-success"
            title="despachar"
            (click)="despachar()">
            Confirmar e Despachar
        </button>
    </div>
</form>