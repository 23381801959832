export class MeioPagamento {
    cliente: string;
    codigo: string;
    descricao: string;
    tipo: string;
    convenioCodigo: string;
    status: string;
    loginCadastro: string;
    adicionado: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.descricao = "";
        this.tipo = "";
        this.convenioCodigo = "";
        this.status = "";
        this.loginCadastro = "";
        this.adicionado = "";
    }
}