<mat-form-field>
    <mat-label>Pesquisar</mat-label>
    <input matInput (keyup)="aplicarFiltro($event)" placeholder="Digite qualquer valor para filtrar por todas as opções">
</mat-form-field>
  
<div class="mat-elevation-z9">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="protocolo">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-protocolo" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Protocolo </th>
            <td style="text-align: center; padding-left: 5px;" mat-cell *matCellDef="let row"> {{row.protocolo}} </td>
        </ng-container>

        <ng-container matColumnDef="redeSim">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-redeSim" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Redesim / IDF </th>
            <td style="text-align: center; padding-left: 5px;" mat-cell *matCellDef="let row"> {{row.redeSim ? row.redeSim : '----'}} </td>
        </ng-container>

        <ng-container matColumnDef="inscricao">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-inscricao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Inscrição </th>
            <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{row.inscricao ? row.inscricao : '----'}} </td>
        </ng-container>

        <ng-container matColumnDef="requerente">
            <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-requerente" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Requerente </th>
            <td style="text-align: left; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{row.requerente}} </td>
        </ng-container>

        <ng-container matColumnDef="servico">
            <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-servico" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Serviço </th>
            
            <ng-container *matCellDef="let row">
                <td 
                    matTooltip="{{row.servicoDescricao}}"
                    matTooltipPosition="below"
                    style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell > 
                    <svg 
                        class="iconeGrid">
                        <use
                          attr.xlink:href="#{{ row.servicoIcone }}"
                        ></use>
                    </svg>   
                    <span style="float: left;width: 100%;">{{ row.servicoDescricao }} </span>
                </td>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="faseDescricao">
            <th style="width: 20%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-fase" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Fase </th>
            <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{row.faseDescricao}} </td>
        </ng-container>

        <ng-container matColumnDef="situacao">
            <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-situacao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Situação </th>
            <ng-container *matCellDef="let row">
                <td matTooltip="{{row.situacaoDescricao}}"
                    matTooltipPosition="below"
                    class="{{ row.situacaoClasse }}"
                    style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell 
                    > 
                    <svg class="iconeGrid">
                        <use
                          attr.xlink:href="assets/sprite.svg#{{ row.situacaoIcone }}"
                        ></use>
                    </svg>    
                </td>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="dataSituacao">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-data" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Data Situação </th>
            <td style="text-align: center; padding-right: 5px;" mat-cell *matCellDef="let row"> {{row.dataSituacao}} </td>
        </ng-container>

        <ng-container matColumnDef="menu">
            <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-menu" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"></th>
            <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row">

                <button class="btn btn-menu" mat-button [matMenuTriggerFor]="beforeMenu" (click)="buscarMetodosAdicionais(row)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <button class="btn" mat-menu-item *ngFor="let gridMetodo of row.gridMetodos"
                        (click)="acaoMenu(gridMetodo.nome)"
                    >
                    <svg class="icone-grid-menu m-2">
                        <use
                          attr.xlink:href="assets/sprite.svg#{{ gridMetodo.icone }}"
                        ></use>
                    </svg>  
                    {{ gridMetodo.descricao }}</button>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator pageSize="25" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<div>&nbsp;</div>