import { OnInit, Component, Input, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Fase } from '../../../../model/fase';
import { Constantes } from '../../../../model/constantes';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ViewportScroller } from '@angular/common';
import { Processo } from '../../../../model/processo';

@Component({
    selector: "app-time-line-horizontal",
    templateUrl: "./time-line-horizontal.component.html",
    styleUrls: ['./time-line-horizontal.component.scss']
})
export class TimeLineHorizontalComponent implements OnInit {

    public config: PerfectScrollbarConfigInterface = {};

    @Input() dados: Array<any>;
    @Input() porFase: boolean = true;
    @Input() porProcesso: boolean = false;

    titulo: string;
    numeroProtocolo: string;
    fases: Array<Fase>;
    situacaoAcompanhamento = Constantes.SITUACAO_DE_ACOMPANHAMENTO;

    numeroInscricao: string;
    processos: Array<Processo>;

    constructor(
        private document: Document,
        private viewportScroller: ViewportScroller,
        private activatedRoute: ActivatedRoute) {
            if (this.porFase)
                this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];
            if (this.porProcesso)
                this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];
    }

    ngOnInit(): void {
        if (this.dados != null) {
            this.numeroProtocolo = this.dados[0];
            this.fases = this.dados[1]
        }

    }

    goToAnchor(ancora) {
        this.document.getElementById(ancora).scrollIntoView();
        this.viewportScroller.scrollToAnchor(ancora);
        let posicaoAtual = this.viewportScroller.getScrollPosition();
        this.viewportScroller.scrollToPosition([posicaoAtual[0], posicaoAtual[1] - 146])
    }

}