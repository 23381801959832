import { Requisito } from "./requisito";
import { Orgao } from './orgao';
import { Login } from './login';
import { Zona } from "./zona";
import { Uso } from "./uso";
import { Atividade } from "./atividade";
import { PerguntaResposta } from "./perguntaResposta";
import { Pendencia } from "./pendencia";
import { Critica } from './critica';

export class Atendimento {
    cliente: string;
    protocolo: string;
    inscricao: string;
    processo: string;
    tipoEmpresa: string;
    atendente: string;
    requisito: Requisito;
    requisitos: Array<Requisito>;
    perguntasAuditor: PerguntaResposta;
    zonas: Array<Zona>;
    usos: Array<Uso>;
    atividadesExercidas: Array<Atividade>;
    atividadesNaoExercidas: Array<Atividade>;
    inicio: string;
    fim: string;
    parecer: string;
    observacao: string;
    orgao: Orgao;
    login: Login;
    pendencias: Array<Pendencia>;
    criticas: Array<Critica>;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.inscricao = "";
        this.processo = "";
        this.tipoEmpresa = "";
        this.atendente = "";
        this.requisito = new Requisito();
        this.requisitos = new Array<Requisito>();
        this.perguntasAuditor = new PerguntaResposta();
        this.zonas = new Array<Zona>();
        this.usos = new Array<Uso>();
        this.atividadesExercidas = new Array<Atividade>();
        this.atividadesNaoExercidas = new Array<Atividade>();
        this.inicio = "";
        this.fim = "";
        this.parecer = "";
        this.observacao = "";
        this.orgao = new Orgao();
        this.login = new Login();
        this.pendencias = new Array<Pendencia>();
        this.criticas = new Array<Critica>();
    }
}