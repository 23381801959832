import { TaxaLancamentoParcela } from './taxaLancamentoParcela';
import { MeioPagamento } from './meioPagamento';
import { TaxaBaixa } from './taxaBaixa';
import { Convenio } from './convenio';
import { Endereco } from './endereco';
import { Telefone } from './telefone';

export class TaxaPagamento {
    cliente: string;
    codigo: string;
    protocolo: string;
    inscricao: string;
    emprInsEstadual: string;
    emprNomEmpresa: string;            
    endereco: Endereco;
    telefones: Array<Telefone>;
    locaEmail: string;
    meioPagamento: MeioPagamento;
    convenio: Convenio;
    taxaLancamentoParcelas: Array<TaxaLancamentoParcela>;
    nossoNumero: string;
    nossoNumeroDv: string;
    linhaDigitavel: string;
    codigoBarras: string;
    codigoBarrasImagem: string;
    vencimento: string;
    subTotalMora: string;
    subTotalJuros: string;
    subTotalCorrecaoMonetaria: string;
    subTotalDesconto: string;
    subTotal: string;
    total: string;
    loginProcessamento: string;
    processamento: string;
    loginCadastro: string;
    adicionado: string;
    pagamentoUnico: boolean;
    fatorVencimento: string;
    taxaBaixa: TaxaBaixa;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.protocolo = "";
        this.inscricao = "";
        this.emprInsEstadual = "";
        this.emprNomEmpresa = "";            
        this.endereco = new Endereco();
        this.telefones = new Array<Telefone>();
        this.locaEmail = "";
        this.meioPagamento = new MeioPagamento();
        this.convenio = new Convenio();
        this.taxaLancamentoParcelas = new Array<TaxaLancamentoParcela>();
        this.nossoNumero = "";
        this.nossoNumeroDv = "";
        this.linhaDigitavel = "";
        this.codigoBarras = "";
        this.codigoBarrasImagem = "";
        this.vencimento = "";
        this.subTotalMora = "";
        this.subTotalJuros = "";
        this.subTotalCorrecaoMonetaria = "";
        this.subTotalDesconto = "";
        this.subTotal = "";
        this.total = "";
        this.loginProcessamento = "";
        this.processamento = "";
        this.loginCadastro = "";
        this.adicionado = "";
        this.pagamentoUnico = false;
        this.fatorVencimento = "";
        this.taxaBaixa = new TaxaBaixa();
    }
}