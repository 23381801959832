import { Banco } from './banco';
import { ConvenioRegra } from './convenioRegra';

export class Convenio {
    cliente: string;
    codigo: string;
    codigoConvenio: string;
    descricao: string;
    banco: Banco;
    agencia: string;
    agenciaDigito: string;
    conta: string;
    contaDigito: string;
    codigoBeneficiario: string;
    descricaoBeneficiario: string;
    codigoCarteira: string;
    descricaoCarteira: string;
    codigoCarteiraRegistrada: string;
    descricaoCarteiraRegistrada: string;
    nossoNumeroInicio: string;
    nossoNumeroAtual: string;
    nossoNumeroDvModulo: string;
    nossoNumeroDvBase: string;
    nossoNumeroDvSomaDigito: string;
    nossoNumeroDvSubtrair: string;
    nossoNumeroDvResto: string;
    nossoNumeroDvConsiderar: string;
    nossoNumeroRegras: Array<ConvenioRegra>;
    codigoBarrasDvModulo: string;
    codigoBarrasDvBase: string;
    codigoBarrasDvSomaDigito: string;
    codigoBarrasDvSubtrair: string;
    codigoBarrasDvResto: string;
    codigoBarrasDvConsiderar: string;
    codigoBarrasRegras: Array<ConvenioRegra>;
    linhaDigitavelDvModulo: string;
    linhaDigitavelDvBase: string;
    linhaDigitavelDvSomaDigito: string;
    linhaDigitavelDvSubtrair: string;
    linhaDigitavelDvResto: string;
    linhaDigitavelDvConsiderar: string;
    linhaDigitavelRegras: Array<ConvenioRegra>;
    status: string;
    loginCadastro: string;
    adicionado: string;

    constructor() {
        
        this.cliente = "";
        this.codigo = "";
        this.codigoConvenio = "";
        this.descricao = "";
        this.banco = new Banco();
        this.agencia = "";
        this.agenciaDigito = "";
        this.conta = "";
        this.contaDigito = "";
        this.codigoBeneficiario = "";
        this.descricaoBeneficiario = "";
        this.codigoCarteira = "";
        this.descricaoCarteira = "";
        this.codigoCarteiraRegistrada = "";
        this.descricaoCarteiraRegistrada = "";
        this.nossoNumeroInicio = "";
        this.nossoNumeroAtual = "";
        this.nossoNumeroDvModulo = "";
        this.nossoNumeroDvBase = "";
        this.nossoNumeroDvSomaDigito = "";
        this.nossoNumeroDvSubtrair = "";
        this.nossoNumeroDvResto = "";
        this.nossoNumeroDvConsiderar = "";
        this.nossoNumeroRegras = new Array<ConvenioRegra>();
        this.codigoBarrasDvModulo = "";
        this.codigoBarrasDvBase = "";
        this.codigoBarrasDvSomaDigito = "";
        this.codigoBarrasDvSubtrair = "";
        this.codigoBarrasDvResto = "";
        this.codigoBarrasDvConsiderar = "";
        this.codigoBarrasRegras = new Array<ConvenioRegra>();
        this.linhaDigitavelDvModulo = "";
        this.linhaDigitavelDvBase = "";
        this.linhaDigitavelDvSomaDigito = "";
        this.linhaDigitavelDvSubtrair = "";
        this.linhaDigitavelDvResto = "";
        this.linhaDigitavelDvConsiderar = "";
        this.linhaDigitavelRegras = new Array<ConvenioRegra>();
        this.status = "";
        this.loginCadastro = "";
        this.adicionado = "";
    }
}