import { Component, OnInit } from "@angular/core";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { Pendencia } from "src/app/model/pendencia";
import { AcompanhamentoService } from "src/app/services/operacao/acompanhamento.service";
import { take } from "rxjs/operators";
import { Constantes } from "src/app/model/constantes";
import { ActivatedRoute, Router } from "@angular/router";
import { IcadModalComponent } from "../modal/icad-modal/icad-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { EmpresaService } from "src/app/services/empresa/empresa.service";
import { Empresa } from "src/app/model/empresa";
import PerfectScrollbar from 'perfect-scrollbar';
import { ProtocoloService } from '../../services/operacao/protocolo.service';
import { Licenciamento } from "src/app/model/licenciamento";
import { ArquivoService } from '../../services/arquivo/arquivo.service';
import Swal from "sweetalert2";
import { Protocolo } from '../../model/protocolo';
import { TaxaService } from "src/app/services/taxa/taxa.service";
import { TaxaPagamento } from "src/app/model/taxaPagamento";
import { TaxaLancamentoParcela } from "src/app/model/taxaLancamentoParcela";

@Component({
    selector: "app-todo-pendencia",
    templateUrl: "./todo-pendencia.component.html",
    styleUrls: ["./todo.component.scss"]
})
export class TodoPendenciaComponent implements OnInit {
    
    public config: PerfectScrollbarConfigInterface = {};

    codigoMenuPai: string;
    codigoOrgao: string;
    codigoMenu: string;
    
    pendencias: Array<Pendencia>;
    pendenciaTipo = Constantes.TIPO_DE_PENDENCIA
    pendenciaFase = Constantes.FASE_DE_ACOMPANHAMENTO_DESCRICAO;
    pendenciaDescricao = Constantes.TIPO_DE_PENDENCIA_DESCRICAO;
    
    constructor(
        private router: Router,
        private empresaService: EmpresaService,
        private taxaService: TaxaService,
        private acompanhamentoService: AcompanhamentoService,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private arquivoService: ArquivoService,) {
        this.pendencias = new Array<Pendencia>();
        this.codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];
        this.codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"];
        this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
    }

    ngOnInit(): void {
        this.acompanhamentoService.pendencia()
            .pipe(take(1))
            .subscribe( pendencias => {
                this.pendencias = pendencias;

                setTimeout(() => {
                    if (this.activatedRoute.snapshot.params["inscricao"] != null) {
                        (<HTMLSelectElement>document.getElementById("txtFiltro")).value = this.activatedRoute.snapshot.params["inscricao"];
                        this.acaoDoFiltro(this.activatedRoute.snapshot.params["inscricao"]);
                        const alerta = Swal.mixin({
                            toast: true,
                            position: "bottom",
                            showConfirmButton: false,
                            timer: 10000,
                            timerProgressBar: true,
                            width: 1000,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        });
                        alerta.fire({
                            icon: 'warning',
                            title: 'Caro contribuinte, suas pendências estão filtradas, remova o filtro para visualizar todas as pendências.'
                        });
                    }
                    else if (this.activatedRoute.snapshot.params["protocolo"] != null) {
                        (<HTMLSelectElement>document.getElementById("txtFiltro")).value = this.activatedRoute.snapshot.params["protocolo"];
                        this.acaoDoFiltro(this.activatedRoute.snapshot.params["protocolo"]);
                        const alerta = Swal.mixin({
                            toast: true,
                            position: "bottom",
                            showConfirmButton: false,
                            timer: 10000,
                            timerProgressBar: true,
                            width: 1000,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        });
                        alerta.fire({
                            icon: 'warning',
                            title: 'Caro contribuinte, suas pendências estão filtradas, remova o filtro para visualizar todas as pendências.'
                        });
                    }
                    else {
                        this.atualizarScrollBar();
                    }
                }, 1);
            });
    }

    acaoDoFiltro(valor) {
        var rex = new RegExp(valor, 'i');
        $('.todo-box .todo-item').hide();
        $('.todo-box .todo-item').filter(function() {
            return rex.test($(this).text());
        }).show();
        this.atualizarScrollBar();
    }

    seguirProcesso(pendencia: Pendencia) {
        if (parseInt(pendencia.codigoTipo) == Constantes.TIPO_DE_PENDENCIA.Providenciar_Anexo) {
            this.abrirPopUp(pendencia);
        }
        else if (parseInt(pendencia.codigoTipo) == Constantes.TIPO_DE_PENDENCIA.Preenchimento_De_Dados_Cadastrais) {
            this.router.navigate(["/operacao/fic", pendencia.protocolo]);
        }
        else if (parseInt(pendencia.codigoTipo) == Constantes.TIPO_DE_PENDENCIA.Pendente_Usuario_Cadastro) {
            this.abrirPopUp(pendencia);
        }
        else if (parseInt(pendencia.codigoTipo) == Constantes.TIPO_DE_PENDENCIA.Pendente_Usuario_Cadastro ||
                parseInt(pendencia.codigoTipo) == Constantes.TIPO_DE_PENDENCIA.Critica_De_Dados_Cadastrais) 
        {
            if (pendencia.processo.tipo == "A" || pendencia.processo.tipo == "M" || pendencia.processo.codigo == "2") {
                this.seguirProcessoParaAbertura(pendencia);
            }
            else if (pendencia.processo.tipo == "U" || pendencia.processo.tipo == "I") {
                this.seguirProcessoParaAlteracao(pendencia);
            }
        }
        else if (parseInt(pendencia.codigoTipo) == Constantes.TIPO_DE_PENDENCIA.Esclarecimento_Ao_Auditor) {
            this.abrirPopUp(pendencia);
        }
        else if (parseInt(pendencia.codigoTipo) == Constantes.TIPO_DE_PENDENCIA.Questionario_De_Licenciamento) {
            this.abrirPopUp(pendencia);
        }
        else if (parseInt(pendencia.codigoTipo) == Constantes.TIPO_DE_PENDENCIA.Pendente_Usuario_Requisito) {
            this.abrirPopUp(pendencia);
        }
        else if (parseInt(pendencia.codigoTipo) == Constantes.TIPO_DE_PENDENCIA.Renovacao_Do_Requisito) {
            this.abrirPopUp(pendencia);
        }
        
            
    }

    seguirProcessoParaAbertura(pendencia: Pendencia) {
        // Verifica a Fase
        if (parseInt(pendencia.fase) == Constantes.FASE_DE_ACOMPANHAMENTO.Consulta_Previa) {
            // Verifica o tipo de empresa
            if (pendencia.processo.tipo == Constantes.TIPO_EMPRESA.Mei) {
                this.router.navigate(["/operacao/mei/abertura", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu], {queryParams: pendencia});
            }
            else if (pendencia.processo.tipo == Constantes.TIPO_EMPRESA.Autonomo) {
                this.router.navigate(["/operacao/autonomo/abertura", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu], {queryParams: pendencia});
            }
            else if (pendencia.processo.tipo == Constantes.TIPO_EMPRESA.Autonomo_Evento) {
                this.router.navigate(["/operacao/evento/aberturaAutonomo", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu], {queryParams: pendencia});
            }
            else if (pendencia.processo.tipo == Constantes.TIPO_EMPRESA.Empresa_Evento) {
                this.router.navigate(["/operacao/evento/aberturaEmpresa", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu], {queryParams: pendencia});
            }
            else {
                this.router.navigate(["/operacao/empresa/abertura", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu], {queryParams: pendencia});
            }
        }
        else {
            this.router.navigate(["/operacao/fic", pendencia.protocolo]);
        }
    }

    seguirProcessoParaAlteracao(pendencia: Pendencia) {
        // Verifica a Fase
        if (parseInt(pendencia.fase) == Constantes.FASE_DE_ACOMPANHAMENTO.Consulta_Previa) {
            // Verifica o tipo de empresa
            if (pendencia.processo.tipo == Constantes.TIPO_EMPRESA.Mei) {
                this.router.navigate(["/operacao/mei/alteracao", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu], {queryParams: pendencia});
            }
            else if (pendencia.processo.tipo == Constantes.TIPO_EMPRESA.Autonomo) {
                this.router.navigate(["/operacao/autonomo/alteracao", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu], {queryParams: pendencia});
            }
            else if (pendencia.processo.tipo == Constantes.TIPO_EMPRESA.Autonomo_Evento) {
                this.router.navigate(["/operacao/evento/alteracaoAutonomo", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu], {queryParams: pendencia});
            }
            else if (pendencia.processo.tipo == Constantes.TIPO_EMPRESA.Empresa_Evento) {
                this.router.navigate(["/operacao/evento/alteracaoEmpresa", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu], {queryParams: pendencia});
            }
            else {
                this.router.navigate(["/operacao/empresa/alteracao", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu], {queryParams: pendencia});
            }
        }
        else {
            this.router.navigate(["/operacao/fic", pendencia.protocolo]);
        }
    }

    abrirPopUp(pendencia: Pendencia) {
        if (parseInt(pendencia.codigoTipo) == Constantes.TIPO_DE_PENDENCIA.Questionario_De_Licenciamento) {
            this.openDialogEmpresaParaPendenciaDeLicenciamentoPorOrgaoERequisito(pendencia, "licenciamento");
        }
        else if (pendencia.inscricao != "") {
            this.openDialogPorInscricao(pendencia, "atendimento-pendencia");
        }
        else if (pendencia.protocolo != "") {
            this.openDialogPorProtocolo(pendencia);
        }
    }

    openDialogPorProtocolo(dados) {
        var protocolo = new Protocolo()
        protocolo.protocolo = dados.protocolo.toString().replace(/\D/g,"")
        const dialogRef = this.dialog.open(IcadModalComponent, {
            panelClass: "teste",
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                titulo: "Lista de Atividades",
                componente: "atendimento-pendencia",
                dados: [dados, protocolo, this.codigoOrgao]
            }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
    }

    openDialogPorInscricao(dados, componente) {        
        this.empresaService
            .buscarPorInscricaoDadosBasicos(
                dados.cliente,  
                dados.inscricao.toString().replace(/\D/g,""))
            .pipe(take(1))
            .subscribe( (retorno: Empresa) => {
                const dialogRef = this.dialog.open(IcadModalComponent, {
                    panelClass: "teste",
                    width: '100%',
                    height: '100%',
                    maxWidth: 'none',
                    maxHeight: 'none',
                    closeOnNavigation: true,
                    data: {
                        titulo: "Lista de Atividades",
                        componente: componente,
                        dados: [dados, retorno, this.codigoOrgao]
                    }
                });
            
                dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
            })
    }

    openDialogEmpresaParaPendenciaDeLicenciamentoPorOrgaoERequisito(dados, componente) {
        
        this.empresaService
            .buscarPorInscricaoParaPendenciaDeLicenciamentoPorOrgaoERequisito(
                dados.cliente,  
                dados.inscricao.toString().replace(/\D/g,""),
                dados.fluxos[0].requisito.orgao.codigo,
                dados.fluxos[0].requisito.codigo)
            .pipe(take(1))
            .subscribe( (retorno: Empresa) => {
                if (!retorno.licenciamentos[0].questionarioBloqueado && retorno.licenciamentos[0].statusDoLicenciamento != Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO.Deferido) {
                    this.router.navigate([
                        "/operacao/licenciamento", 
                        this.codigoMenuPai, 
                        this.codigoOrgao, 
                        this.codigoMenu, 
                        dados.inscricao.toString().replace(/\D/g,""),
                        dados.fluxos[0].requisito.orgao.codigo,
                        dados.fluxos[0].requisito.codigo
                    ]);
                }
                else {
                    this.imprimirDocumentoQuestionarioBloqueado(retorno.licenciamentos[0]);
                }
            })
    }

    imprimirDocumentoQuestionarioBloqueado(licenciamento: Licenciamento) {
        let documento = licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == licenciamento.documentoTipo);
        if (licenciamento.situacaoLicenca) {
            documento = licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == Constantes.TIPO_DE_DOCUMENTO.Lic.toString());
        }
        
        if (documento.length) {
            this.baixarArquivo(documento[0].documentoEndereco.codigo, documento[0].tipo, documento[0].documentoEndereco.nome, documento[0].documentoEndereco.extensao)
        }
    }

    baixarArquivo(codigo, tipo, nome, extensao) {
        this.arquivoService.downloadArquivoLicenciamentoPorCodigo(codigo, tipo)
            .subscribe((res: any) => {
                this.arquivoService.handleFile(res, nome + "." + extensao);
            });
    }

    atualizarScrollBar() {
        const container = document.querySelector('.todo-box-scroll');
        const ps = new PerfectScrollbar(container, {
            wheelSpeed: 0.1,
            wheelPropagation: true,
            minScrollbarLength: 40
        });
        container.scrollTop = 0;
        ps.update();
    }

    acaoImprimirBoleto(pendencia) {
        Swal.fire({
            title: "Gerar Dam",
            html: "Sr. Contribuinte, Selecione Gerar DAM, o documento de arrecadação municipal será disponibilizado para download. A partir desse momento ficará como pendência até a baixa do débito.  Para obter uma segunda via ou acompanhar a baixa da DAM, acesse o menu Acompanhamento e selecione Meus DAM.",
            icon: "success",
            customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: 'Gerar DAM',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: () => !Swal.isLoading(),
            willClose: () => {
            }
        })
        .then((result) => {
            if (!result.dismiss) {
                this.gerarPagamento(pendencia)    
            }
        });
        
    }

    gerarPagamento(pendencia) {
        var taxaPagamento = new TaxaPagamento();
        taxaPagamento.protocolo = pendencia.protocolo;
        taxaPagamento.inscricao = pendencia.inscricao;
        taxaPagamento.meioPagamento.tipo = Constantes.MEIO_PAGAMENTO_TIPO.Boleto_Registrado

        var taxaLancamentoParcela = new TaxaLancamentoParcela();
        taxaLancamentoParcela.codigo = pendencia.fluxos[0].codigo;

        taxaPagamento.taxaLancamentoParcelas = [taxaLancamentoParcela];

        this.taxaService
            .gerarPagamento(taxaPagamento)
            .pipe(take(1))
            .subscribe( retornoAtendimento => {
                Swal.fire({
                    title: retornoAtendimento.titulo,
                    html: retornoAtendimento.mensagem,
                    icon: retornoAtendimento.status,
                    customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: true,
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    allowOutsideClick: () => !Swal.isLoading(),
                    willClose: () => {
                    }
                })
                .then((result) => {
                    if (!result.dismiss) {
                        if (retornoAtendimento.arquivos.length) {
                            retornoAtendimento.arquivos.forEach( nome => {
                                this.arquivoService.downloadArquivoAtendimento(nome)
                                    .subscribe((res: any) => {
                                        this.arquivoService.handleFile(res, nome + '.pdf');
                                    });
                                })
                        }
                        else {
                            Swal.fire("", "Não foi possível localizar o arquivo.", "error");
                        }
                    }
                });
            });
    }

}