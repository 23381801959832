import { Menu } from './menu';

export class Orgao {
    cliente: string;
    codigo: string;
    nome: string;
    nomeSimples: string;
    menus: Array<Menu>;
    ativo: boolean;
    bloqueado: boolean;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.nome = "";
        this.nomeSimples = "";
        this.menus = new Array<Menu>();
        this.ativo = true;
        this.bloqueado = true;
    }
}