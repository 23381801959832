import { Usuario } from "./usuario";
import { GridMetodo } from './gridMetodo';
import { Endereco } from './endereco';
import { Requisito } from './requisito';

export class EmpresaAcompanhamento {
    cliente: string;
    inscricao: string;
    ultimoProtocolo: string;
    cpfCnpj: string;
    razaoSocial: string;
    dataInscricaoMunicipal: string;
    dataAbertura: string;
    dataEncerramento: string;
    tipoEmpresa: string;
    tipoEmpresaDescricao: string;
    tipoEmpresaClasse: string;
    tipoEmpresaIcone: string;
    situacaoEmpresa: string;
    situacaoEmpresaDescricao: string;
    qtdDePendencias: string;
    endereco: Endereco;
    requisitos: Array<Requisito>;
    socio: boolean;
    responsavelLegal: boolean;
    contador: boolean;
    dataSuspensao: string;
    qtdeDam: string;

    constructor() {
        this.cliente = "";
        this.inscricao = "";
        this.ultimoProtocolo = "";
        this.cpfCnpj = "";
        this.razaoSocial = "";
        this.dataInscricaoMunicipal = "";
        this.dataAbertura = "";
        this.dataEncerramento = "";
        this.tipoEmpresa = "";
        this.tipoEmpresaDescricao = "";
        this.tipoEmpresaClasse = "";
        this.tipoEmpresaIcone = "";
        this.situacaoEmpresa = "";
        this.situacaoEmpresaDescricao = "";
        this.qtdDePendencias = "";
        this.endereco = new Endereco();
        this.requisitos = Array<Requisito>();
        this.socio = false;
        this.responsavelLegal = false;
        this.contador = false;
        this.dataSuspensao = "";
        this.qtdeDam = "";
    }
}