<form name="formulario" 
class="justify-content-center align-items-center"
>
<div class="container-principal-atendimento mt-3 mb-5">
    <div class="card">
      <div class="card-header">
        <div class="col-12">
          <strong>Vínculos por Arquivo</strong>
        </div>
      </div>
      <div class="card-body pt-3">
        <div class="row col-12 row-linha pb-4" >
          <p class="card-text">
            Caro Auditor, nos botões abaixo você poderá configurar para quais atividades esse requisito se tonará obrigatório.<br>
            Esse vínculo fará com que o requisito venha selecionado automaticamente nos processos de abertura ou alteração do negócio.<br>
          </p>
        </div>
        <div class="row col-12">
          <div class="row col-2 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialog('tipoDeVinculo.Empresa')">Empresa</button>
          </div>
          <div class="row col-2 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialog('tipoDeVinculo.Mei')">Mei</button>
          </div>
          <div class="row col-2 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialog('tipoDeVinculo.Autonomo')">Autônomo</button>
          </div>
          <div class="row col-3 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialog('tipoDeVinculo.Autonomo_Estabelecido')">Autônomo Estabelecido</button>
          </div>
          <div class="row col-3 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialog('tipoDeVinculo.Autonomo_Nao_Estabelecido')">Autônomo Não Estabelecido</button>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</form>

