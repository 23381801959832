import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { TaxaService } from '../../../../services/taxa/taxa.service';
import { Constantes } from '../../../../model/constantes';
import { MascaraPipe } from '../../../../pipes/mascara.pipe';
import { Taxa } from 'src/app/model/taxa';
import { FormBuilder, FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TaxaLote } from '../../../../model/taxaLote';
import { MatTableDataSource } from '@angular/material/table';
import { Grid } from 'src/app/model/grid';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { GridFiltro } from 'src/app/model/gridFiltro';
import { ArquivoService } from 'src/app/services/arquivo/arquivo.service';
import { CentroCusto } from 'src/app/model/centroCusto';
import { TaxaLoteOrgaoRequisito } from 'src/app/model/taxaLoteOrgaoRequisito';
import { TaxaLoteDetalhe } from 'src/app/model/taxaLoteDetalhe';
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TaxaLancamento } from 'src/app/model/taxaLancamento';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';


@Component({
    selector: "app-processamento-lote-taxa",
    templateUrl: "./processamento-lote-taxa.component.html",
    styleUrls: ["./../gerenciamento-taxa.component.scss"]
})
export class ProcessamentoLoteTaxaComponent implements OnInit {
    
    formulario: FormGroup;

    emitirErro: boolean;
    mensagemErro: string;

    centrosDeCusto: Array<CentroCusto>;
    taxasAgregadas: Array<Taxa>;
    taxaLotes: Array<TaxaLote>;
    taxaLoteDetalhes: Array<any>;
    taxaLoteDetalheEmpresas: Array<TaxaLoteDetalhe>;
    taxaLoteOrgaoRequisitos: Array<TaxaLoteOrgaoRequisito>;
    taxaLoteListaEmpresasReprocessamento: Array<TaxaLoteDetalhe>;

    taxaLancamentos: Array<TaxaLancamento>;

    processamentoLoteStatus = Constantes.PROCESSAMENTO_LOTE_STATUS;
    processamentoLoteStatusDescricao = Constantes.PROCESSAMENTO_LOTE_STATUS_DESCRICAO

    permitirGerarPrevia: boolean = false;
    exibirLotes: boolean = false;
    exibirLotesSessoes: boolean = false;
    exibirLoteDetalheEmpresas: boolean = false;
    exibirListaEmpresasReprocessamento: boolean = false;
    posicaoInicialSolicitada: string = "";
    posicaoFinalSolicitada: string = "";
    processamentoLoteLiberado: boolean = false;

    grid: Grid;
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumns: string[] = ["codigo", "adicionado","status","qtdEmpresas","qtdEmpresasProcessadas","total","menu"];
    @ViewChild("matPaginator", {static: true}) paginator: MatPaginator;

    gridDetalhe: Grid;
    dataSourceDetalhe: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumnsDetalhe: string[] = ["de", "ate", "total", "menu"];
    @ViewChild("matPaginatorDetalhe", {static: true}) paginatorDetalhe: MatPaginator;

    gridDetalheEmpresas: Grid;
    dataSourceDetalheEmpresas: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumnsDetalheEmpresas: string[] = ["inscricao", "emprCpfCgc", "emprNomEmpresa", "processamento", "total", "menu"];    
    @ViewChild("matPaginatorDetalheEmpresas", {static: true}) paginatorDetalheEmpresas: MatPaginator;

    gridListaEmpresasReprocessamento: Grid;
    dataSourceListaEmpresasReprocessamento: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumnsListaEmpresasReprocessamento: string[] = ["inscricao", "emprCpfCgc", "emprNomEmpresa"];    
    @ViewChild("matPaginatorListaEmpresasReprocessamento", {static: true}) paginatorListaEmpresasReprocessamento: MatPaginator;

    @ViewChildren(MatSort, {}) sort: QueryList<MatSort>;

    constructor(
        private formBuilder: FormBuilder,
        private arquivoService: ArquivoService,
        private authenticationService: AuthenticationService,
        private mascara: MascaraPipe,
        public dialog: MatDialog,
        private taxaService: TaxaService) {

        this.centrosDeCusto = new Array<CentroCusto>();
        this.taxasAgregadas = new Array<Taxa>();
        this.taxaLotes = new Array<TaxaLote>();
        this.taxaLoteDetalhes = new Array<any>();
        this.taxaLoteDetalheEmpresas = new Array<TaxaLoteDetalhe>();
        this.taxaLoteOrgaoRequisitos = new Array<TaxaLoteOrgaoRequisito>();
        this.taxaLancamentos = new Array<TaxaLancamento>();
        this.taxaLoteListaEmpresasReprocessamento = new Array<TaxaLoteDetalhe>();

        this.grid = new Grid();
        this.grid.filtro.push(new GridFiltro());
        
        this.gridDetalhe = new Grid();
        this.gridDetalhe.filtro.push(new GridFiltro());
        
        this.gridDetalheEmpresas = new Grid();
        this.gridDetalheEmpresas.filtro.push(new GridFiltro());
        
        this.gridListaEmpresasReprocessamento = new Grid();
        this.gridListaEmpresasReprocessamento.filtro.push(new GridFiltro());

        this.formulario = this.formBuilder.group({
            codigoCentroDeCusto: [''],
        });

        authenticationService.currentAxisValue.item.forEach(menu => {
          if (menu.nome == "moduloTaxa") {
            menu.item.forEach(subMenu => {
              if (subMenu.nome == "operacaoLote") {
                subMenu.item.forEach(funcionalidade => {
                  if (funcionalidade.nome == "processarLote") {
                    this.processamentoLoteLiberado = true;
                  }
                });
              }
            });
          }
        });
            
    }

    ngOnInit(): void {
        this.carregarCentrosDeCusto()
    }
     
    carregarCentrosDeCusto() {
        this.taxaService
        .buscarTaxaCentroDeCustoAtivos()
        .pipe(take(1))
        .subscribe( retorno => {
            this.centrosDeCusto = retorno;
        })
    }
     
    formatToBRL(valor) {
        return this.mascara.transform(valor, 11);
    }

    acaoSelectTipoBusca() {
      this.limpar();
    }

    buscarLotes() {
        if (!this.processamentoLoteLiberado) {
          Swal.fire("", "Caro Auditor, está funcionalidade não está liberada para o seu usuário.", "error");
          return false;
        }

        this.limpar();

        if (this.codigoCentroDeCusto.value == "") {
            Swal.fire("", "Para localizar as prévias e lotes processados, selecionar um centro de custo disponível.", "error");
            return false;
        }

        this.exibirLotes = true;

        this.taxaService
            .buscarTaxaLotes(this.codigoCentroDeCusto.value)
            .pipe(take(1))
            .subscribe( retorno => {
                this.taxaLotes = retorno;
                this.grid.dados = retorno;

                if (this.taxaLotes.length) {
                    var existePreviaAtiva = this.taxaLotes.findIndex( taxaLote => taxaLote.status != Constantes.PROCESSAMENTO_LOTE_STATUS.Previa_Descartada );

                    if (existePreviaAtiva != -1) {
                        this.taxaLoteOrgaoRequisitos = this.taxaLotes[existePreviaAtiva].taxaLoteOrgaoRequisitos
                    }
                }

                this.atualizarTabela();
            })
    }

    buscarTaxaLoteDetalheSessoes() {
        this.limparSessoes();
        this.limparDetalhes();

        this.exibirLotesSessoes = true;

        this.taxaService
            .buscarTaxaLoteSessoes(this.codigoCentroDeCusto.value)
            .pipe(take(1))
            .subscribe( retorno => {
                this.taxaLoteDetalhes = retorno;
                this.gridDetalhe.dados = retorno;
                this.atualizarTabelaDetalhe();
            })
    }

    novaPrevia() {
        Swal.fire({
            html: "Deseja realmente solicitar uma nova prévia para a taxa?",
            icon: 'question',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
        })
        .then((result) => {
            if (!result.dismiss) {
                this.taxaService
                    .novaPreviaLote(this.codigoCentroDeCusto.value)
                    .pipe(take(1))
                    .subscribe( retorno => {
                        Swal.fire({
                            title: retorno.titulo,
                            html: retorno.mensagem,
                            icon: retorno.status,
                            willClose: () => {
                            }
                        })
                    })
            }
        })
    }

    processarLote() {
        this.taxaService
            .verificarProcessamentoDeLote(this.codigoCentroDeCusto.value)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    showCancelButton: true,
                    showConfirmButton: retorno.status != "error",
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (!result.dismiss && retorno.status != "error") {
                        this.taxaService
                            .gerarLotePorTaxa(this.codigoCentroDeCusto.value)
                            .pipe(take(1))
                            .subscribe( retorno => {
                                Swal.fire({
                                    title: retorno.titulo,
                                    html: retorno.mensagem,
                                    icon: retorno.status,
                                    willClose: () => {
                                    }
                                })
                            })                        
                    }            
                })
            })

    }

    acaoBaixarDetalhamento(row) {
        this.taxaService
            .verificarBaixaDeArquivoDeLote(row.codigo, 1, 0)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    showCancelButton: true,
                    showConfirmButton: retorno.status != "error",
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (!result.dismiss && retorno.status != "error") {
                        this.arquivoService.downloadArquivoTaxaLoteDetalhamento(row.codigo)
                        .subscribe((res: any) => {
                            this.arquivoService.handleFile(res, 'detalhamento_lote_' + row.codigo + '.csv');
                        });
                    }            
                })
            })

    }

    acaoBaixarDetalhamentoComMemoria(row) {
        this.taxaService
            .verificarBaixaDeArquivoDeLote(row.codigo, 0, 1)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    showCancelButton: true,
                    showConfirmButton: retorno.status != "error",
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (!result.dismiss && retorno.status != "error") {
                        this.arquivoService.downloadArquivoTaxaLoteMemoria(row.codigo)
                        .subscribe((res: any) => {
                            this.arquivoService.handleFile(res, 'memoria_lote_' + row.codigo + '.csv');
                        });
                    }            
                })
            })
    }

    acaoListarEmpresas(row) {
        var lote = this.taxaLotes.filter( taxaLote => taxaLote.status != Constantes.PROCESSAMENTO_LOTE_STATUS.Previa_Descartada );

        if (!lote.length) {
            Swal.fire("", "Não existe(m) empresa(s) para exibir.", "error");
            return false;
        }

        this.exibirLoteDetalheEmpresas = true;
        this.exibirListaEmpresasReprocessamento = true;
        this.posicaoInicialSolicitada = row.posicaoInicial;
        this.posicaoFinalSolicitada = row.posicaoFinal;

        this.taxaService
            .buscarTaxaLoteEmpresasPorSessao(row.posicaoInicial, row.posicaoFinal, lote[0].codigo)
            .pipe(take(1))
            .subscribe( retorno => {
                this.taxaLoteDetalheEmpresas = retorno[0];
                this.gridDetalheEmpresas.dados = retorno[0];
                this.taxaLoteListaEmpresasReprocessamento = retorno[1];
                this.gridListaEmpresasReprocessamento.dados = retorno[1];
                this.atualizarTabelaDetalheEmpresas();
                this.atualizarTabelaListaEmpresasReprocessamento();
            })
    }

    buscarMemoriasDeCalculoPreviaPorInscricao(row) {      
        var lote = this.taxaLotes.filter( taxaLote => taxaLote.status != Constantes.PROCESSAMENTO_LOTE_STATUS.Previa_Descartada );

        this.taxaLancamentos = new Array<TaxaLancamento>();

        this.taxaService
            .buscarTaxaLancamentoPorPreviaEInscricao(lote[0].codigo, row.inscricao)
            .pipe(take(1))
            .subscribe( retorno => {
                this.taxaLancamentos = retorno;
            })
    }

    acaoMemoriaDeCalculo(codigoLancamento) {
        if (codigoLancamento != "") {
            let taxaLancamentosAuxiliar = this.taxaLancamentos.filter( taxaLancamento => { return taxaLancamento.codigo == codigoLancamento });
            if (taxaLancamentosAuxiliar != null) {
                this.openDialog("memoria-de-calculo", [taxaLancamentosAuxiliar[0], null, "Previa"]);
            }
        }
        else {
            Swal.fire("", "Não existe pré lançamento para essa empresa para exibir.", "error");
            return false;
        }
    }

    acaoReprocessar(row) {        
        this.exibirListaEmpresasReprocessamento = true;

        Swal.fire({
            html: "Qual o motivo do reprocessamento?",
            icon: "question",
            input: "select",
            inputOptions: Constantes.MOTIVO_REPROCESSAMENTO_EMPRESA_SELECT,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.taxaService
                    .reprocessarEmpresaPorPrevia(row.codigoLote, row.codigoAcesso, row.inscricao, result.value)
                    .pipe(take(1))
                    .subscribe( retorno => {
                        Swal.fire({
                            title: retorno.titulo,
                            html: retorno.mensagem,
                            icon: retorno.status,
                        })

                        if (retorno.status != "error") {
                            this.taxaLoteListaEmpresasReprocessamento = retorno.listaDeEmpresas;
                            this.gridListaEmpresasReprocessamento.dados = retorno.listaDeEmpresas;
                            this.atualizarTabelaListaEmpresasReprocessamento();
                        }
                    })
            }            
        })
    }

    limpar() {
        this.exibirLotes = false;

        this.dataSource = new MatTableDataSource();

        this.grid = new Grid();
        this.grid.filtro.push(new GridFiltro());        

        this.taxasAgregadas = new Array<Taxa>();
        this.taxaLotes = new Array<TaxaLote>();
        this.taxaLoteOrgaoRequisitos = new Array<TaxaLoteOrgaoRequisito>();

        this.limparSessoes();
        this.limparDetalhes();
        this.limparEmpresasReprocessamento();
    }

    limparSessoes() {
        this.exibirLotesSessoes = false;

        this.dataSourceDetalhe = new MatTableDataSource();
        
        this.gridDetalhe = new Grid();
        this.gridDetalhe.filtro.push(new GridFiltro());
        
        this.taxaLoteDetalhes = new Array<any>();
    }

    limparDetalhes() {
        this.exibirLoteDetalheEmpresas = false;
        this.posicaoInicialSolicitada = "";
        this.posicaoFinalSolicitada = "";

        this.dataSourceDetalheEmpresas = new MatTableDataSource();
        
        this.gridDetalheEmpresas = new Grid();
        this.gridDetalheEmpresas.filtro.push(new GridFiltro());

        this.taxaLancamentos = new Array<TaxaLancamento>();
    }

    limparEmpresasReprocessamento() {
        this.exibirListaEmpresasReprocessamento = false;

        this.dataSourceListaEmpresasReprocessamento = new MatTableDataSource();
        
        this.gridListaEmpresasReprocessamento = new Grid();
        this.gridListaEmpresasReprocessamento.filtro.push(new GridFiltro());
        
        this.taxaLoteListaEmpresasReprocessamento = new Array<TaxaLoteDetalhe>();
    }

    atualizarTabela() {
        this.dataSource = new MatTableDataSource(this.grid.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
        this.sort.toArray()[0].active = "adicionado";
        this.sort.toArray()[0].direction = <SortDirection>"DESC".toLowerCase();
        this.dataSource.sort = this.sort.toArray()[0];
    }

    atualizarTabelaDetalhe() {
        this.dataSourceDetalhe = new MatTableDataSource(this.gridDetalhe.dados);
        this.dataSourceDetalhe.paginator = this.paginatorDetalhe;
        this.dataSourceDetalhe.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
        this.sort.toArray()[1].active = "de";
        this.sort.toArray()[1].direction = <SortDirection>"ASC".toLowerCase();
        this.dataSourceDetalhe.sort = this.sort.toArray()[1];
    }

    atualizarTabelaDetalheEmpresas() {
        this.dataSourceDetalheEmpresas = new MatTableDataSource(this.gridDetalheEmpresas.dados);
        this.dataSourceDetalheEmpresas.paginator = this.paginatorDetalheEmpresas;
        this.dataSourceDetalheEmpresas.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
        this.sort.toArray()[2].active = "inscricao";
        this.sort.toArray()[2].direction = <SortDirection>"ASC".toLowerCase();
        this.dataSourceDetalheEmpresas.sort = this.sort.toArray()[2];
    }

    atualizarTabelaListaEmpresasReprocessamento() {
        this.dataSourceListaEmpresasReprocessamento = new MatTableDataSource(this.gridListaEmpresasReprocessamento.dados);
        this.dataSourceListaEmpresasReprocessamento.paginator = this.paginatorListaEmpresasReprocessamento;
        this.dataSourceListaEmpresasReprocessamento.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
        this.sort.toArray()[3].active = "inscricao";
        this.sort.toArray()[3].direction = <SortDirection>"ASC".toLowerCase();
        this.dataSourceListaEmpresasReprocessamento.sort = this.sort.toArray()[3];
    }

    ordenacaoGrid(data, sort) {
      if (sort.active == null)
          return data;
  
      let campo = sort.active;
      
      if (campo == "adicionado") {
          data.sort( (item_1, item_2) => (new Date(item_1[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) > new Date(item_2[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (campo == "inscricao") {
          data.sort( (item_1, item_2) => (item_1[campo] > item_2[campo] ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (data[0][campo].search("$") != -1) {
          data.sort( (item_1, item_2) => ( ( isNaN(parseFloat(item_1[campo].replace("R$", "").replace(/\./g, "").replace(/\,/g, "."))) ? 0 : item_1[campo].replace("R$", "").replace(/\./g, "").replace(/\,/g, ".") ) - ( isNaN(parseFloat(item_2[campo].replace("R$", "").replace(/\./g, "").replace(/\,/g, "."))) ? 0 : item_2[campo].replace("R$", "").replace(/\./g, "").replace(/\,/g, ".") ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (typeof(data[0][campo]) == typeof("0")) {
          data.sort( (item_1, item_2) => (item_1[campo]).localeCompare(item_2[campo])  * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (typeof(data[0][campo]) == typeof(0)) {
          data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo])) ? 0 : item_1[campo] ) - ( isNaN(parseInt(item_2[campo])) ? 0 : item_2[campo] ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      
      return data;
    }
  
    aplicarFiltro(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
      }
    }
  
    aplicarFiltroDetalhe(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSourceDetalhe.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSourceDetalhe.paginator) {
          this.dataSourceDetalhe.paginator.firstPage();
      }
    }
  
    aplicarFiltroDetalheEmpresas(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSourceDetalheEmpresas.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSourceDetalheEmpresas.paginator) {
          this.dataSourceDetalheEmpresas.paginator.firstPage();
      }
    }
  
    aplicarFiltroListaEmpresasReprocessamento(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSourceListaEmpresasReprocessamento.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSourceListaEmpresasReprocessamento.paginator) {
          this.dataSourceListaEmpresasReprocessamento.paginator.firstPage();
      }
    }

    openDialog(componente, dados) {

        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                componente: componente,
                dados: dados
            }
        });
    
        // dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
    }

    acaoConfirmarReprocessamento() {
        if (!this.taxaLoteListaEmpresasReprocessamento.length) {
            Swal.fire("", "Não existe(m) empresa(s) para reprocessar.", "error");
            return false;
        }

        this.taxaService
            .confirmarReprocessamentoDeEmpresas(this.taxaLoteListaEmpresasReprocessamento[0].codigoLote)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                })

                if (retorno.status != "error") {
                    this.taxaLoteListaEmpresasReprocessamento = retorno.listaDeEmpresas;
                    this.gridListaEmpresasReprocessamento.dados = retorno.listaDeEmpresas;
                    this.atualizarTabelaListaEmpresasReprocessamento();
                }
            })
    }
  
    get codigoCentroDeCusto() {
      return this.formulario.get("codigoCentroDeCusto");
    }  
    set codigoCentroDeCusto(codigoCentroDeCusto) {
      this.formulario.get("codigoCentroDeCusto").setValue(codigoCentroDeCusto);
    }


}
