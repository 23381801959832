import { Component, OnInit } from "@angular/core";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { take } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { IcadModalComponent } from "../modal/icad-modal/icad-modal.component";
import { MatDialog } from "@angular/material/dialog";
import PerfectScrollbar from 'perfect-scrollbar';
import { ProtocoloService } from '../../services/operacao/protocolo.service';
import { ArquivoService } from '../../services/arquivo/arquivo.service';
import { Empresa } from '../../model/empresa';
import { EmpresaService } from '../../services/empresa/empresa.service';
import { AcompanhamentoService } from '../../services/operacao/acompanhamento.service';
import { Licenciamento } from '../../model/licenciamento';
import { Constantes } from '../../model/constantes';
import { formatToCPFOrCNPJ } from "brazilian-values";
import { EmpresaAcompanhamento } from '../../model/empresaAcompanhamento';
import { TaxaService } from "src/app/services/taxa/taxa.service";

@Component({
    selector: "app-todo-empresas",
    templateUrl: "./todo-empresas.component.html",
    styleUrls: ["./todo.component.scss"]
})
export class TodoEmpresasComponent implements OnInit {
    
    public config: PerfectScrollbarConfigInterface = {};

    codigoMenuPai: string;
    codigoOrgao: string;
    codigoMenu: string;
    tipoDeBloqueioRequisito = Constantes.TIPO_DE_BLOQUEIO_REQUISITO;
    tipoDeBloqueioRequisitoDescricao = Constantes.TIPO_DE_BLOQUEIO_REQUISITO_DESCRICAO;
    situacaoAlvaraEmpresa = Constantes.SITUACAO_ALVARA_EMPRESA;
    
    empresas: Array<Empresa>;
    
    constructor(
        private router: Router,
        private acompanhamentoService: AcompanhamentoService,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private arquivoService: ArquivoService,) {
        this.empresas = new Array<Empresa>();
        this.codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];
        this.codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"];
        this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
    }

    ngOnInit(): void {
        this.acompanhamentoService.empresas()
            .pipe(take(1))
            .subscribe( empresas => {
                this.empresas = empresas;
                setTimeout(() => {
                    this.atualizarScrollBar();
                }, 1);    
            });
    }

    acaoDoFiltro(valor) {
        var rex = new RegExp(valor, 'i');
        $('.todo-box .todo-item').hide();
        $('.todo-box .todo-item').filter(function() {
            return rex.test($(this).text());
        }).show();
        this.atualizarScrollBar();
    }

    formatToCPFOrCNPJ(valor) {
        return formatToCPFOrCNPJ(valor.toString());
    }

    visualizar(empresa: EmpresaAcompanhamento) {    
        this.router.navigate([this.router.url, empresa.inscricao, empresa.ultimoProtocolo], { queryParams: {}})
    }

    cicloDeVida(empresa: EmpresaAcompanhamento) {    
        let dados = [empresa.inscricao, "", this.activatedRoute.snapshot.params["codigoOrgao"] ];
        this.openDialog("ciclo-de-vida", dados);   
    }

    openDialog(componente, dados) {

        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                titulo: "Time Line",
                componente: componente,
                dados: dados
            }
        });
    
        // dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
    }

    imprimirDocumentoQuestionarioBloqueado(licenciamento: Licenciamento) {
        let documento = licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == licenciamento.documentoTipo);
        if (licenciamento.situacaoLicenca) {
            documento = licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == Constantes.TIPO_DE_DOCUMENTO.Lic.toString());
        }
        
        if (documento.length) {
            this.baixarArquivo(documento[0].documentoEndereco.codigo, documento[0].tipo, documento[0].documentoEndereco.nome, documento[0].documentoEndereco.extensao)
        }
    }

    baixarArquivo(codigo, tipo, nome, extensao) {
        this.arquivoService.downloadArquivoLicenciamentoPorCodigo(codigo, tipo)
            .subscribe((res: any) => {
                this.arquivoService.handleFile(res, nome + "." + extensao);
            });
    }

    atualizarScrollBar() {
        const container = document.querySelector('.todo-box-scroll');
        const ps = new PerfectScrollbar(container, {
            wheelSpeed: 0.1,
            wheelPropagation: true,
            minScrollbarLength: 40
        });
        container.scrollTop = 0;
        ps.update();
    }

    irParaPendencias(empresa: EmpresaAcompanhamento) {
        this.router.navigate([this.router.url, "acompanhamento", "pendencia", empresa.inscricao, empresa.ultimoProtocolo]);
    }

    irParaMeusDam(empresa: EmpresaAcompanhamento){
        this.router.navigate([this.router.url, "acompanhamento", "meusDam", empresa.inscricao, empresa.ultimoProtocolo]);        
    }

}