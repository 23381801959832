<div class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Dados Básicos</strong>
        </div>
    </div>
    <div *ngIf="login == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="login != null" class="card-body">
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>CPF / CNPJ</strong>
                </p>
            </div>
            <div class="col-4">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="login.login.toString() == ''">
                    {{ 
                        login.login.toString().length < 12 ? (login.login.toString() | mask: '000.000.000-00') :
                        login.login.toString().length > 12 ? (login.login.toString() | mask: '00.000.000/0000-00') : login.login.toString()
                    }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Nome</strong>
                </p>
            </div>
            <div class="col-4">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="login.nome == ''">
                    {{ login.nome == '' ? 'Não Cadastrado' : login.nome }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>E-mail</strong>
                </p>
            </div>
            <div class="col-4">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="login.emails.length == 0 || login.emails[0] == ''">
                    {{ 
                        login.emails.length == 0 ? 'Não Cadastrado' : 
                        login.emails[0] == '' ? 'Não Cadastrado' : login.emails[0]
                    }}
                </p>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Telefones</strong>
        </div>
    </div>
    <div *ngIf="login == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="login != null" class="card-body">
        <div *ngFor="let telefone of login.telefones" class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>
                        {{ 
                            telefone.tipo === tipoTelefone.Residencial ? 'Telefone Fixo' :
                            telefone.tipo === tipoTelefone.Comercial ? 'Telefone Comercial' : 
                            telefone.tipo === tipoTelefone.Celular ? 'Telefone Celular' : 'Telefone Recado' 
                        }}
                    </strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="telefone.numero == ''">
                    {{ 
                        telefone.numero == '' ? 'Não Cadastrado' : 
                        (telefone.ddd.toString() + telefone.numero.toString()).length == 10 ? (telefone.ddd.toString() + telefone.numero | mask: '(00) 0000-0000') : 
                        (telefone.ddd.toString() + telefone.numero.toString()).length > 10 ? (telefone.ddd.toString() + telefone.numero | mask: '(00) 00000-0000') : telefone.ddd.toString() + telefone.numero.toString()
                    }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Documentos</strong>
        </div>
    </div>
    <div *ngIf="login == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="login != null" class="card-body">
        <div *ngFor="let documento of login.documentos | filtro: {campo: 'nome', valor: 'CRC'}" 
            class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.numero == ''">
                    <strong>Número CRC</strong> 
                </p>
            </div>
            <div class="col-2">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.numero == ''">
                    {{ documento.numero == '' ? 'Não Possui' : documento.numero }}
                </p>
            </div>
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.estadoEmissor == ''">
                    <strong>Estado CRC</strong> 
                </p>
            </div>
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.estadoEmissor == ''">
                    {{ documento.estadoEmissor == '' ? 'Não Possui' : documento.estadoEmissor }}
                </p>
            </div>
            <div class="col-2">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.classificacao == ''">
                    <strong>Classificação CRC</strong> 
                </p>
            </div>
            <div class="col-2">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.classificacao == ''">
                    {{ documento.classificacao == '' ? 'Não Possui' : documento.classificacao }}
                </p>
            </div>
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.subTipo == ''">
                    <strong>Tipo CRC</strong> 
                </p>
            </div>
            <div class="col-2">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.subTipo == ''">
                    {{ documento.subTipo == '' ? 'Não Possui' : documento.subTipo }}
                </p>
            </div>
        </div>
        <div *ngFor="let documento of login.documentos | filtro: {campo: 'nome', valor: 'CREA'}" 
            class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.numero == ''">
                    <strong>Número CREA</strong> 
                </p>
            </div>
            <div class="col-2">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.numero == ''">
                    {{ documento.numero == '' ? 'Não Possui' : documento.numero }}
                </p>
            </div>
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.estadoEmissor == ''">
                    <strong>Estado CREA</strong> 
                </p>
            </div>
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.estadoEmissor == ''">
                    {{ documento.estadoEmissor == '' ? 'Não Possui' : documento.estadoEmissor }}
                </p>
            </div>
        </div>
        <div *ngFor="let documento of login.documentos | filtro: {campo: 'nome', valor: 'CAU'}" 
            class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.numero == ''">
                    <strong>Número CAU</strong> 
                </p>
            </div>
            <div class="col-2">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.numero == ''">
                    {{ documento.numero == '' ? 'Não Possui' : documento.numero }}
                </p>
            </div>
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.estadoEmissor == ''">
                    <strong>Estado CAU</strong> 
                </p>
            </div>
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.estadoEmissor == ''">
                    {{ documento.estadoEmissor == '' ? 'Não Possui' : documento.estadoEmissor }}
                </p>
            </div>
            <div class="col-2">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.classificacao == ''">
                    <strong>Classificação CAU</strong> 
                </p>
            </div>
            <div class="col-2">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.classificacao == ''">
                    {{ documento.classificacao == '' ? 'Não Possui' : documento.classificacao }}
                </p>
            </div>
            <div class="col-1">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.dataEmissao == ''">
                    <strong>Emissão CAU</strong> 
                </p>
            </div>
            <div class="col-2">
                <p class="card-text"
                    [class.valor-nao-cadastrado]="documento.dataEmissao == ''">
                    {{ documento.dataEmissao == '' ? 'Não Possui' : documento.dataEmissao }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Endereço</strong>
        </div>
    </div>
    <div *ngIf="login == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="login != null" class="card-body">
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Cep</strong> 
                </p>
            </div>
            <div class="col-10">
                <p class="card-text">
                    {{ login.enderecos[0].cep == '' ? 'Não Cadastrado' : ( login.enderecos[0].cep  | mask: '00000-000' ) }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Endereço</strong> 
                </p>
            </div>
            <div class="col-10">
                <p class="card-text">
                    {{ login.enderecos[0].tipoLogradouro }} {{ login.enderecos[0].logradouro }}, {{ login.enderecos[0].numero }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Compl.</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text">
                    {{ login.enderecos[0].complemento }}
                </p>
            </div>
        </div>
        <!-- <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Ref.</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text">
                    {{ login.enderecos[0].pontoReferencia }}
                </p>
            </div>
        </div> -->
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Bairro</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text">
                    {{ login.enderecos[0].bairro }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Cidade</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text">
                    {{ login.enderecos[0].cidade }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Estado</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text">
                    {{ login.enderecos[0].estado }}
                </p>
            </div>
        </div>
    </div>
</div>

