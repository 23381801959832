<form
    class="containerLogin_formulario row no-gutters justify-content-center align-items-center"
    name="formCadastrarUsuario"
    [formGroup]="formCadastrarUsuario"
    (ngSubmit)="salvar()"
>
    <div class="mb-5"
        [class.col-md-8]="!estaAutenticado()" 
        [class.col-sm-6]="!estaAutenticado()" 
        [class.col-md-12]="estaAutenticado()" 
        [class.col-sm-10]="estaAutenticado()">
    <div class="card m-3 px-3">
        <div [class.hidden]="!exibirBotaoFechar"
            class="col-12 d-flex justify-content-end">
            <button
                class="btn btn-danger"
                (click)="cancelarCadastrarUsuario()"
                type="button"
                title="Voltar"
                style="margin-right: -31px;"
            >
            <svg class="iconeFecharPopUp">
                <use
                  attr.xlink:href="assets/sprite.svg#{{ 'x' }}"
                ></use>
            </svg> 
            </button>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="col-12 d-flex justify-content-center mt-3">
                    <img
                        class="logo"
                        src="../../../../../assets/img/logos/_logo_iCad.svg"
                    />
                </div>
                <div class="col-12 d-flex justify-content-center mt-4" *ngIf="!estaAutenticado()">
                    <h1 class="containerLogin_formulario_titulo">
                        Informe seus dados e defina sua senha, para ter acesso aos serviços Icad Online
                    </h1>
                </div>
                <div class="col-12 d-flex justify-content-center mt-4" *ngIf="estaAutenticado()">
                    <h1 class="containerLogin_formulario_titulo">
                       Consulta e alteração de cadastro de contribinte
                    </h1>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12 mt-4">
                    <mat-horizontal-stepper 
                        labelPosition="bottom" 
                        linear 
                        #passos
                        (selectionChange)="mudancaPasso($event, passos)">
                        <mat-step label="Dados Básicos" 
                            [stepControl]="formularioUsuario">
                            <app-form-usuario></app-form-usuario>
                            <div class="form-row">
                                <div class="col-12 text-right">
                                    <mat-divider></mat-divider>
                                    <br>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12 text-right">
                                    <button 
                                        (click)="verificaValidacaoFormulario(formularioUsuario)"
                                        type="button" 
                                        class="btn btn-success" 
                                        mat-button 
                                        matStepperNext>
                                        Avançar
                                    </button>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step 
                            label="Endereço" 
                            [stepControl]="formularioEndereco">
                            <app-form-endereco tipoEnderecoConfiguracao="3"></app-form-endereco>
                            <div class="form-row">
                                <div class="col-12 text-right">
                                    <mat-divider></mat-divider>
                                    <br>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-6 text-left">
                                    <button 
                                        type="button" 
                                        class="btn btn-danger" 
                                        mat-button
                                        matStepperPrevious>
                                        Anterior
                                    </button>
                                </div>
                                <div class="col-6 text-right">
                                    <button 
                                        (click)="verificaValidacaoFormulario(formularioEndereco)"
                                        type="button"
                                        class="btn btn-success" 
                                        mat-button 
                                        matStepperNext>
                                        Avançar
                                    </button>
                                </div>
                            </div>
                        </mat-step>
                       
                        <mat-step label="Finalização">
                            <div class="form-row">
                                <div class="col-12 text-center" *ngIf="!estaAutenticado()">
                                    Confirmação de cadastro.<br><br>
                                    Um e-mail de validação será enviado informado o código de validação.<br><br>
                                    Após o recebimento retornar ao portal, efetuar o acesso e validar o cadastro.<br><br>
                                    Para confirmar o cadastro, clique em salvar.
                                </div>
                                <div class="col-12" *ngIf="estaAutenticado()">
                                   <app-resumo-cadastro></app-resumo-cadastro>
                                </div>
                            </div>
                            <br>
                            <div class="form-row">
                                <div class="col-12 text-right">
                                    <mat-divider></mat-divider>
                                    <br>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-6 text-left">
                                    <button 
                                        type="button" 
                                        class="btn btn-danger" 
                                        mat-button 
                                        matStepperPrevious>
                                        Anterior
                                    </button>
                                </div>
                                <div class="col-6 text-right">
                                    <button 
                                        type="submit" 
                                        class="btn btn-success"
                                        title="salvar"
                                    >
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </mat-step>
                        
                    </mat-horizontal-stepper>
                </div>
            </div>
        </div>
    </div>
    </div>
</form>

