import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeModule } from '../../../pipes/pipes.module';
import { FormQuestionarioComponent } from './form-questionario.component';
import { AlertModule } from '../../../components/alert/alert.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    FormQuestionarioComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatCheckboxModule,
    PipeModule,
    AlertModule,
    NgbAccordionModule,
  ],
  exports: [
    FormQuestionarioComponent
  ],
})
export class FormQuestionarioModule {}
