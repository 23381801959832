import { take } from "rxjs/operators";
import { MatSort, SortDirection } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginatorIntl, MatPaginator } from "@angular/material/paginator";
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';

import { Grid } from "src/app/model/grid";
import { GridProviderPaginacao } from "src/app/components/grid/grid-provider-paginacao";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { AtendimentoService } from "src/app/services/operacao/atendimento.service";

import { MatDialog } from "@angular/material/dialog";
import { IcadModalComponent } from "src/app/components/modal/icad-modal/icad-modal.component";
import { FormGroup, FormBuilder } from "@angular/forms";
import { GridFiltro } from "src/app/model/gridFiltro";
import { GridOpcao } from "src/app/model/gridOpcao";
import { of } from "rxjs";
import Swal from "sweetalert2";
import { Constantes } from '../../../../model/constantes';
import { param } from "jquery";
import { Login } from '../../../../model/login';
import { LicenciamentoService } from "src/app/services/licenciamento/licenciamento.service";
import { LoginService } from "src/app/services/login/login.service";
import { Console } from "console";

@Component({
  selector: 'app-atendimento-grid',
  templateUrl: './atendimento-grid.component.html',
  styleUrls: ['./atendimento-grid.component.scss'],
  providers: [ 
      {
          provide: MatPaginatorIntl,
          useClass: GridProviderPaginacao
      }
  ]
})
export class AtendimentoGridComponent implements OnInit {

  @Input() menusAdicionais = [];
  @Output() acaoMenuAtendimento = new EventEmitter();
  
  grid: Grid;
  filtroOpcoes: Array<GridOpcao>;
  dados: Array<any>;
  abas: Array<any>;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = [];
  formulario: FormGroup;
  codigoMenuPai: number;
  permitirAtendimento: boolean;
  codigoMenu: number;
  eixoConfiguracao: boolean;
  eixoIntegracao: boolean;
  mensagemDeBusca: string = "Não foram encontrados registros.";
  
  emitirErro: boolean = false;
  mensagemErro: string;

  numeroInscricao: string;
  numeroProtocolo: string;
  permitirDelegar: boolean = false;
  usuarios: Array<Login>;

  acessoSuspensao: string = "1";

  abaInicial: number = 0;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  acaoAtendimento = Constantes.ACAO_ATENDIMENTO;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private atendimentoService: AtendimentoService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private licenciamentoService: LicenciamentoService,
    private loginService: LoginService) { 
      this.grid = new Grid();
      this.grid.filtro.push(new GridFiltro());

      this.codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];
      this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
      this.formulario = this.formBuilder.group({
        tipoBusca: [''],
        tipoBuscaValorInput: [''],
        tipoBuscaValorSelect: [''],
      });    
      let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
      this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !meusNegocios);
      this.eixoConfiguracao = this.router.url.split("/")[1] == "configuracao";
      this.eixoIntegracao = this.router.url.split("/")[1] == "integracao";

      this.licenciamentoService
      .buscarConfiguracaoSuspensao()
      .pipe(take(1))
      .subscribe( retorno => {
        this.acessoSuspensao = retorno;
      });

  }

  ngOnInit(): void {
    this.authenticationService.currentAxis.subscribe( menus => this.retornaMenus(menus.item) );
    this.buscarGrid(this.activatedRoute.snapshot.params["codigoMenu"]);
    
    if (this.permitirAtendimento) {
      this.atendimentoService
            .permissoesParaAtendimento()
            .pipe(take(1))
            .subscribe( retorno => {
                this.loginService
                    .permissaoParaDelegar()
                    .pipe(take(1))
                    .subscribe( retornoPermissao => {
                        if(retornoPermissao){
                            this.permitirDelegar = retorno.delegar;
                        }
                    })
            })
    }


  }

  retornarNomeMenuAtendimento() {
    var menus = [];

    if (this.permitirAtendimento) {
      menus.push(['edit', 'btnAtender', 'Atender']);
      menus.push(['edit', 'btnAtenderNovaAba', 'Atender Nova Aba']);
      if (this.permitirDelegar) {
        menus.push(['edit', 'btnDelegar', 'Delegar']);
      }
    }
    else {
      menus.push(['search', 'btnVisualizar', 'Visualizar']);
      menus.push(['search', 'btnVisualizarNovaAba', 'Visualizar Nova Aba']);
    }
    
    return menus;
  }

  atualizarTabela() {

    if(this.codigoMenu == 179 && this.acessoSuspensao == '0'){
      Swal.fire("", "Município não possuí acesso ao módulo de suspensão. Por gentileza solicitar ao responsável que libere o módulo.", "error");
      this.router.navigate(["/operacao/oficio"]);
    }

    if(this.codigoMenu == 180 && this.acessoSuspensao == '0'){
      Swal.fire("", "Município não possuí acesso ao módulo de suspensão. Por gentileza solicitar ao responsável que libere o módulo.", "error");
      this.router.navigate(["/operacao/oficio"]);
    }
    
    this.dataSource = new MatTableDataSource(this.grid.dados);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
    this.grid.campos.forEach(item => {
      if (item.ativoOrdenacao == 'S') {
        this.sort.active = item.nmCampoTabela;
        this.sort.direction = <SortDirection>item.ordemAscDesc.toLowerCase();
        item.ativoOrdenacao = 'N';
      }
    });

    this.dataSource.sort = this.sort;

    if (!this.grid.dados.length) {
      let filtroAplicado = this.grid.filtro.filter( item => item.inicial == "S");
      if (filtroAplicado.length && filtroAplicado[0].mensagem != "") {
        if(this.codigoMenu != 179 && this.codigoMenu != 205){
          this.mensagemDeBusca = filtroAplicado[0].mensagem;
          this.mensagemErro = "<b>ATENÇÃO</b> - Devido a quantidade de registro não foi possível exibir no grid, utilize os filtros abaixo.";
          this.emitirErro = true;
        }
      }
      else {
        this.mensagemDeBusca = "Não foram encontrados registros.";
        this.mensagemErro = "";
        this.emitirErro = false;
      }
    }

    this.limparFiltro();
    this.atualizaFiltro();
  }

  atualizaFiltro() {
    setTimeout(() => {      
      this.grid.filtro.forEach((filtro, indice) => {
        if (filtro.inicial == "S") {
          this.tipoBusca.setValue(indice);
          this.acaoSelectTipoBusca();
          if (filtro.tipoCampo == "SELECT") {
            setTimeout(() => {              
              this.tipoBuscaValorSelect.setValue(filtro.valorPadrao);
            }, 1);
          }
          else {
            this.tipoBuscaValorInput.setValue(filtro.valorPadrao);
          }
        }
      });
    }, 1);
  }

  limparFiltro() {
    this.tipoBusca.setValue("");
    this.tipoBuscaValorInput.setValue("");
    this.tipoBuscaValorSelect.setValue("");
  }

  aplicarFiltro(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  abrirAtendimento(row, novaAba) {
    if (row.COD_PREVIA_ID != "") {
      this.atendimentoService.movimentarProtocolo(row.COD_PREVIA_ID).pipe(take(1)).subscribe();
    }
    if (row.EMPR_NUM_INSCRICAO != "") {
      this.atendimentoService.movimentarEmpresa(row.EMPR_NUM_INSCRICAO).pipe(take(1)).subscribe();
    }
    console.log(row)
    if(row.COD_ORGAO == 200){
      if ((this.codigoMenuPai == 34 || this.codigoMenuPai == 76) || (this.codigoMenuPai == 38 && this.codigoMenu == 180)) {
        if (novaAba) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree([row.EMPR_NUM_INSCRICAO, row.COD_PREVIA_ID == "" ? "0" : row.COD_PREVIA_ID], {relativeTo: this.activatedRoute})
          );
          window.open("#" + url, '_blank')
        }
        else {
          this.router.navigate([this.router.url, row.EMPR_NUM_INSCRICAO, row.COD_PREVIA_ID == "" ? "0" : row.COD_PREVIA_ID])
        }
      }
      else if ( (this.codigoMenuPai == 89 && this.codigoMenu == 99) || (this.codigoMenuPai == 38 && this.codigoMenu == 179) || (this.codigoMenuPai == 38 && this.codigoMenu == 205)) {
        if (novaAba) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree([row.EMPR_NUM_INSCRICAO], {relativeTo: this.activatedRoute})
          );
          window.open("#" + url, '_blank')
        }
        else {
          this.router.navigate([this.router.url, row.EMPR_NUM_INSCRICAO])
        }
      }
      else {
        if (novaAba) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree([row.PARAMETRO_ATENDIMENTO], {relativeTo: this.activatedRoute})
          );
          window.open("#" + url, '_blank')
        }
        else {
          this.router.navigate([this.router.url, row.PARAMETRO_ATENDIMENTO])
        }
      }
    }
    else{
      var params = [];

      if(this.codigoMenuPai == 38 && this.codigoMenu == 179){
        params.push(row.PARAMETRO_ATENDIMENTO,row.EMPR_CPF_CGC);
      }
      else if(this.codigoMenuPai == 38 && this.codigoMenu == 205){
        params.push(row.PARAMETRO_ATENDIMENTO,row.EMPR_CPF_CGC);
      }
      else if(this.codigoMenuPai == 38 && this.codigoMenu == 180){
        params.push(row.PARAMETRO_ATENDIMENTO,row.EMPR_CPF_CGC);
      }
      else{
        params.push(row.PARAMETRO_ATENDIMENTO);
      }
      

      if (row.COD_PREVIA_ID != null && row.COD_PREVIA_ID != "") {
        params.push(row.COD_PREVIA_ID);
      }
      if (this.activatedRoute.snapshot.params["subMenu"] != null && this.activatedRoute.snapshot.params["subMenu"] == "despacho") {
        if (row.EMPR_NUM_INSCRICAO != "") {
          params.push(row.EMPR_NUM_INSCRICAO);
        }
      }
      if (novaAba) {
        const url = this.router.serializeUrl(this.router.createUrlTree(params, {relativeTo: this.activatedRoute}));
        window.open("#" + url, '_blank')
      }
      else {
        params = params.reverse();
        params.push(this.router.url);
        params= params.reverse();
        this.router.navigate(params);
      }
    }
  }

  delegarAtendimento(row){
    this.numeroProtocolo = row.COD_PREVIA_ID;
    this.numeroInscricao = row.EMPR_NUM_INSCRICAO;

    const dialogRef = this.dialog.open(IcadModalComponent, {
      panelClass: "Usuários",
      width: '100%',
      height: '100%',
      maxWidth: 'none',
      maxHeight: 'none',
      closeOnNavigation: true,
      data: {
        titulo: "Atendimento de pendência",
        componente: "grid-usuarios",
        dados: [this.activatedRoute.snapshot.params["codigoOrgao"], this.numeroProtocolo, this.numeroInscricao]
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result === "" || result === null)
        return false;

      this.usuarios = result.usuarios.selected;

      this.vincularAtendimento();
    });
  }

  vincularAtendimento(){
   if (this.usuarios.length > 0) {
      Swal.fire({
        title: "",
        html: `Deseja vincular o atendimendo ao usuário <b>${ this.usuarios[0].nome }</b>?`,
        icon: 'question',
        showCancelButton: true})
        .then((result) => {
          if (result.value) {
            if(this.activatedRoute.snapshot.params["codigoOrgao"] != '200'){
              this.vincularAtendimentoPorInscricao()
            }
            else {
             
              this.vincularAtendimentoPorProtocolo()
            }
          }
        });
    }
      
  }

  vincularAtendimentoPorInscricao() {
   this.atendimentoService
      .vincularAtendimentoPorInscricao(
        localStorage.getItem('pId'), 
        this.activatedRoute.snapshot.params["codigoOrgao"],
        this.usuarios[0].codigo,
        this.numeroInscricao,
      )
      .pipe(take(1))
      .subscribe( retorno => {
        if (retorno.length) {
          Swal.fire("", `Atendimento da empresa vinculado ao usuário <b>${ this.usuarios[0].nome }</b>.`, "info");
        }
      });
  }

  vincularAtendimentoPorProtocolo() {
    this.atendimentoService
      .vincularAtendimentoPorProtocolo(
        localStorage.getItem('pId'), 
        this.activatedRoute.snapshot.params["codigoOrgao"],
        this.usuarios[0].codigo,
        this.numeroProtocolo
      )
      .pipe(take(1))
      .subscribe( retorno => {
        if (retorno.length) {
          Swal.fire("", `Atendimento do protocolo vinculado ao usuário <b>${ this.usuarios[0].nome }</b>.`, "info");
        }
      });
  }

  openDialog(dados) {
    const dialogRef = this.dialog.open(IcadModalComponent, {
        panelClass: "teste",
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        maxHeight: 'none',
        closeOnNavigation: true,
        data: {
            titulo: "Lista de Atividades",
            componente: "form-requisito-list"
            //dados: [dados, retorno]
        }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === "" || result === null)
            document.location.reload();
    });
   
  }

  selecaoAba(indiceMenu) {
    this.router.navigate([
      this.abas[indiceMenu].rota.split('.').splice(1, Number.MAX_VALUE).join('/'),
      this.activatedRoute.snapshot.params["codigoMenuPai"],
      this.activatedRoute.snapshot.params["codigoOrgao"],
      this.abas[indiceMenu].codigo
    ]);
    
    this.buscarGrid(this.abas[indiceMenu].codigo);
  }

  retornaMenus(itens) {
    let codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"]
    let codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];
    if (itens != "" && itens != null) {
      itens.forEach(item => {
        if (codigoMenuPai == item.codigo && codigoOrgao == item.orgao) {
          if (item.item.length) {
            this.abas = item.item;
            this.abas.forEach( (aba: any, indice) => {
              if (aba.codigo == this.activatedRoute.snapshot.params["codigoMenu"]) {
                this.abaInicial = indice;
              }
            });
          }
        }
      });
    }   
  }

  buscarGrid(codigoMenu) {
    this.emitirErro = false;
    this.mensagemErro = "";
    this.mensagemDeBusca = "Não foram encontrados registros.";

    this.displayedColumns = [];

    this.atendimentoService
      .buscarGrid(this.activatedRoute.snapshot.params["codigoOrgao"], codigoMenu)
      .pipe(take(1))
      .subscribe(retorno => {
        if (retorno.status != null && retorno.status == "error") {
          Swal.fire({
            title: retorno.titulo,
            html: retorno.mensagem,
            icon: retorno.status,
            willClose: () => {
              return false;
            }
          });

          return false;
        }

        this.grid = retorno;

        var dadosAuxiliar = [];
        
        this.grid.dados.forEach( dado => {
          var camposAuxiliares = {};
          this.grid.campos.forEach( campo => {
            camposAuxiliares[campo.nmCampoTabela] = dado[campo.codigo];
          })
          dadosAuxiliar.push(camposAuxiliares);
        });
        
        this.grid.dados = dadosAuxiliar;

        this.grid.campos.forEach(campo => {
          if (campo.campoVisualizado == "S") {
            this.displayedColumns.push(campo.nmCampoTabela);
          }
        });

        this.displayedColumns.push("menu");
        this.atualizarTabela();    
        let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
        this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !meusNegocios);
      })
  }

  buscarGridPorFiltro() {
    this.emitirErro = false;
    this.mensagemErro = "";
    this.mensagemDeBusca = "Não foram encontrados registros.";

    let valor = this.grid.filtro[this.tipoBusca.value].tipoCampo == "SELECT" ? this.tipoBuscaValorSelect.value : this.tipoBuscaValorInput.value
    
    if (this.grid.filtro[this.tipoBusca.value].tipoCampo == "") {
      valor = "TODOS";
    }

    if (valor == "") {
      Swal.fire("", "Favor informar um valor para busca.", "error")
      return false;
    }

    this.displayedColumns = [];
    this.atendimentoService
      .buscarGridPorFiltro(
        this.grid.orgao, 
        this.grid.menu, 
        this.grid.filtro[this.tipoBusca.value].codigo, 
        valor)
      .pipe(take(1))
      .subscribe(retorno => {
        if (retorno.status != null && retorno.status == "error") {
          Swal.fire({
            title: retorno.titulo,
            html: retorno.mensagem,
            icon: retorno.status,
            willClose: () => {
              return false;
            }
          });

          return false;
        }
        
        this.grid = retorno;

        var dadosAuxiliar = [];
        
        this.grid.dados.forEach( dado => {
          var camposAuxiliares = {};
          this.grid.campos.forEach( campo => {
            camposAuxiliares[campo.nmCampoTabela] = dado[campo.codigo];
          })
          dadosAuxiliar.push(camposAuxiliares);
        });
        
        this.grid.dados = dadosAuxiliar;
        
        this.grid.campos.forEach(campo => {
          if (campo.campoVisualizado == "S") {
            this.displayedColumns.push(campo.nmCampoTabela);
          }
        });
        this.displayedColumns.push("menu");
        this.atualizarTabela();
      })
  }

  acaoSelectTipoBusca() {
    this.tipoBuscaValorInput.setValue("");
    this.tipoBuscaValorSelect.setValue("");

    if (this.grid.filtro[this.tipoBusca.value].tipoCampo == "SELECT") {
        of(this.grid.filtro[this.tipoBusca.value].opcoes).subscribe(opcoes => {
            this.filtroOpcoes = opcoes;
        });
    }
  }

  eventoAtendimento(acaoAtendimento, row) {
    this.acaoMenuAtendimento.emit({ acao: acaoAtendimento, dados: row});
  }
  
  get tipoBusca() {
    return this.formulario.get("tipoBusca");
  }  
  set tipoBusca(tipoBusca) {
    this.formulario.get("tipoBusca").setValue(tipoBusca);
  }

  get tipoBuscaValorInput() {
    return this.formulario.get("tipoBuscaValorInput");
  }  
  set tipoBuscaValorInput(tipoBuscaValorInput) {
    this.formulario.get("tipoBuscaValorInput").setValue(tipoBuscaValorInput);
  }

  get tipoBuscaValorSelect() {
    return this.formulario.get("tipoBuscaValorSelect");
  }  
  set tipoBuscaValorSelect(tipoBuscaValorSelect) {
    this.formulario.get("tipoBuscaValorSelect").setValue(tipoBuscaValorSelect);
  }

  ordenacaoGrid(data, sort) {
    if (sort.active == null)
      return data;

    let campo = sort.active.replace('ICONE', 'DESCRICAO');

    try {
      if (this.grid.campos.find(item => item.nmCampoTabela.toUpperCase() == campo.toUpperCase()).tipoDados == "NUMBER") {
        data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo])) ? 0 : item_1[campo] ) - ( isNaN(parseInt(item_2[campo])) ? 0 : item_2[campo] ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (this.grid.campos.find(item => item.nmCampoTabela.toUpperCase() == campo.toUpperCase()).tipoDados == "PARSE_NUMBER") {
        data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo].replace(/\D/g,""))) ? 0 : item_1[campo].replace(/\D/g,"") ) - ( isNaN(parseInt(item_2[campo].replace(/\D/g,""))) ? 0 : item_2[campo].replace(/\D/g,"") ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (this.grid.campos.find(item => item.nmCampoTabela.toUpperCase() == campo.toUpperCase()).tipoDados == "STRING") {
        data.sort( (item_1, item_2) => (item_1[campo] > item_2[campo] ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (this.grid.campos.find(item => item.nmCampoTabela.toUpperCase() == campo.toUpperCase()).tipoDados == "DATE") {
        data.sort( (item_1, item_2) => (new Date(item_1[campo].split("/").reverse().toString()) > new Date(item_2[campo].split("/").reverse().toString()) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (this.grid.campos.find(item => item.nmCampoTabela.toUpperCase() == campo.toUpperCase()).tipoDados == "DATE_TIME") {
        data.sort( (item_1, item_2) => (new Date(item_1[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) > new Date(item_2[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
       
      
    } catch (error) {
      console.log(this.grid.campos, campo)
    }
    
    return data;
  }
}
