import { Escolaridade } from "./escolaridade";
import { NaturezaJuridica } from "./naturezaJuridica";
import { Cartorio } from "./cartorio";
import { Documento } from "./documento";

export class Registro {
    cliente: string;
    inscricao: string;
    protocolo: string;
    numero: string;
    data: string;
    tipo: string;
    tipoPessoa: string;
    nome: string;
    nascimento: string;
    escolaridade: Escolaridade;
    estabelecido: string;
    naturezaJuridica: NaturezaJuridica;
    cartorio: Cartorio;
    orgao: string;
    orgaoNome: string;
    ultimaAlteracao: string;
    capitalSocial: string;
    porte: string;
    documentos: Array<Documento>;
    sexo: string;

    constructor() {
        this.cliente = "";
        this.inscricao = "";
        this.protocolo = "";
        this.numero = "";
        this.data = "";
        this.tipo = "";
        this.tipoPessoa = "";
        this.nome = "";
        this.nascimento = "";
        this.escolaridade = new Escolaridade();
        this.estabelecido = "";
        this.naturezaJuridica = new NaturezaJuridica();
        this.cartorio = new Cartorio();
        this.orgao = "";
        this.orgaoNome = "";
        this.ultimaAlteracao = "";
        this.capitalSocial = "";
        this.porte = "";
        this.documentos = new Array<Documento>();
        this.sexo = "";
    }
}