import { OnInit, Component, ViewChild } from "@angular/core";
import { FormEnderecoComponent } from '../outros/form-endereco/form-endereco.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Endereco } from '../../model/endereco';
import { FormAtividadePesquisaComponent } from '../outros/form-atividade-pesquisa/form-atividade-pesquisa.component';
import { Atividade } from '../../model/atividade';
import { PesquisaViabilidadeIdentificacaoComponent } from './pesquisa-viabilidade-identificacao/pesquisa-viabilidade-identificacao.component';
import { STEP_STATE } from "@angular/cdk/stepper";
import { Constantes } from '../../model/constantes';
import { FormLocalizacaoPesquisaComponent } from '../outros/form-localizacao-pesquisa/form-localizacao-pesquisa.component';
import { GoogleMapsComponent } from '../outros/google-maps/google-maps.component';
import { ThrowStmt } from "@angular/compiler";
import { ArquivoService } from '../../services/arquivo/arquivo.service';

@Component({
    selector: "app-pesquisa-viabilidade",
    templateUrl: "./pesquisa-viabilidade.component.html",
    styleUrls: ["./pesquisa-viabilidade.component.scss"]
})
export class PesquisaViabilidadeComponent implements OnInit {

    stepper: MatStepper;
    passoAnterior: number;
    passoAtual: number;
    endereco: Endereco;
    atividades: Array<Atividade>;

    formulario: FormGroup;

    @ViewChild(PesquisaViabilidadeIdentificacaoComponent)
    pesquisaViabilidadeIdentificacao: PesquisaViabilidadeIdentificacaoComponent;

    @ViewChild(FormLocalizacaoPesquisaComponent)
    formLocalizacaoPesquisa: FormLocalizacaoPesquisaComponent;

    @ViewChild(FormAtividadePesquisaComponent)
    formAtividade: FormAtividadePesquisaComponent;

    @ViewChild(GoogleMapsComponent)
    googleMaps: GoogleMapsComponent;

    exibirLocalizacao = false;
    exibirAtividades = false;

    tituloPasso = 'ESTA FERRAMENTA DE PESQUISA FOI DISPONIBILIZADA PARA QUE VOCÊ TENHA A OPORTUNIDADE DE VISUALIZAR E ANALISAR TODOS OS CENÁRIOS POSSÍVEIS PARA A ABERTURA OU ALTERAÇÃO DE UM EMPREENDIMENTO NO MUNICÍPIO';

    // Botões
    btnAnteriorLocalizacao: string = "Anterior";
    btnAvancarLocalizacao: string = "Avançar";

    constructor(private formBuilder: FormBuilder,
                private arquivoService: ArquivoService,) {
        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]]
        });
    }

    ngOnInit(): void {}

    verificaValidacaoFormulario(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });
    }

    avancarPassoTipoPesquisa(passos) {
        if (this.stepper == null) {
            this.stepper = passos;
        }
        
        if (this.pesquisaViabilidadeIdentificacao.validarFormulario()) {
            if (this.pesquisaViabilidadeIdentificacao.tipoDeBusca == Constantes.TIPO_DE_PESQUISA.Localizacao_Atividade) {
                this.exibirLocalizacao = true;
                this.exibirAtividades = true;
            }
            else if (this.pesquisaViabilidadeIdentificacao.tipoDeBusca == Constantes.TIPO_DE_PESQUISA.Localizacao) {
                this.exibirLocalizacao = true;
            }
            else if (this.pesquisaViabilidadeIdentificacao.tipoDeBusca == Constantes.TIPO_DE_PESQUISA.Atividade) {
                this.exibirAtividades = true;
            }
            setTimeout(() => {
                if (this.formAtividade != null) {
                    this.formAtividade.tipoEmpresa = this.pesquisaViabilidadeIdentificacao.tipoDeNegocio;
                }
                this.googleMaps.tipoDeNegocio = this.pesquisaViabilidadeIdentificacao.tipoDeNegocio;
                this.stepper.next();
            }, 1);
        }
    }

    renomearBotaoLocalizacao() {
        this.btnAnteriorLocalizacao = "Anterior";
        this.btnAvancarLocalizacao = "Avançar";

        if (this.formLocalizacaoPesquisa.exibeFormularioEndereco) {
            if (!this.formLocalizacaoPesquisa.preenchimentoInicial) {
                this.btnAnteriorLocalizacao = "Cancelar";
                this.btnAvancarLocalizacao = "Confirmar";
            }
        }
        
        this.defineTituloPasso(this.passoAtual);
    }

    voltarPassoLocalizacao() {
        if (this.formLocalizacaoPesquisa.exibeFormularioEndereco) {
            if (this.formLocalizacaoPesquisa.preenchimentoInicial) {
                this.stepper.previous();
            }
            else {
                this.formLocalizacaoPesquisa.toggle();
            }
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoLocalizacao() {
        this.verificaValidacaoFormulario(this.formLocalizacaoPesquisa.formulario);
        if (this.formLocalizacaoPesquisa.validarFormulario())
            this.stepper.next();
    }

    avancarPassoAtividade() {
        if (this.formAtividade.validarFormulario())
            this.stepper.next();
    }

    preencher() {

        if (this.formLocalizacaoPesquisa != null) {
            if (!this.formLocalizacaoPesquisa.enderecoSelecionado.isEmpty()) {        
                this.endereco = this.formLocalizacaoPesquisa.enderecoSelecionado.selected[0];
            }
        }

        if (this.formAtividade != null && this.formAtividade.atividadesSelecionadas.length) {
            this.atividades = this.formAtividade.atividadesSelecionadas;
        }

        this.googleMaps.endereco = this.endereco;
        this.googleMaps.atividades = this.atividades;
        this.googleMaps.exibirLocalizacao = this.formLocalizacaoPesquisa != null;
        this.googleMaps.exibirAtividades = this.formAtividade != null;
        
        this.googleMaps.adicionarLocalizacaoNoMapa();        
        this.googleMaps.carregarPossivelPermissivelNaoPossivelThread();
        this.googleMaps.preencher();
    
    }

    mudancaPasso(event, passos) {
        if (this.stepper == null) {
            this.stepper = passos;
        }

        if (event.selectedIndex == 0 ) {
            this.exibirLocalizacao = false;
            this.exibirAtividades = false;
            this.pesquisaViabilidadeIdentificacao.limpar();
            if (this.formLocalizacaoPesquisa != null) {
                this.formLocalizacaoPesquisa.enderecoSelecionado.clear();
            }
            this.endereco = null;
            this.atividades = null;
            this.googleMaps.limpar();
        }

        if (event.previouslySelectedIndex == 1 && this.exibirLocalizacao) {
            this.googleMaps.limparLocalizacao();
            this.googleMaps.limparPerimetro();
            this.googleMaps.limparPossiveisPermissiveisNaoPossiveis();
            if (this.formLocalizacaoPesquisa.exibeFormularioEndereco && !this.formLocalizacaoPesquisa.preenchimentoInicial) {
                this.formLocalizacaoPesquisa.toggle();
            }
        }
        else if (event.previouslySelectedIndex == 1 && this.exibirAtividades) {
            this.googleMaps.limparConcorrentes();
            this.googleMaps.limparPossiveisPermissiveisNaoPossiveis();
        }
        else if (event.selectedIndex == 3) {
            this.googleMaps.limparLocalizacao();
            this.googleMaps.limparAtividades();
            this.googleMaps.limparConcorrentes();
            this.googleMaps.limparPossiveisPermissiveisNaoPossiveis();
            this.googleMaps.limparPerimetro();
            if (this.formLocalizacaoPesquisa.exibeFormularioEndereco && !this.formLocalizacaoPesquisa.preenchimentoInicial) {
                this.formLocalizacaoPesquisa.toggle();
            }
        }
        
        this.passoAtual = event.selectedIndex;
        this.passoAnterior = event.previouslySelectedIndex;        
        this.preencher();
        this.defineTituloPasso(event.selectedIndex)

        if (event.previouslySelectedIndex == 0 ) {
            this.googleMaps.inicializarMapa();
        }

        if (this.formLocalizacaoPesquisa != null && this.formAtividade != null) {
            if (event.selectedIndex == 3 ) {
                this.googleMaps.verificarPermissionamentoAtividades();
            }
        }
    }

    defineTituloPasso(passo){
        
        this.tituloPasso = 'ESTA FERRAMENTA DE PESQUISA FOI DISPONIBILIZADA PARA QUE VOCÊ TENHA A OPORTUNIDADE DE VISUALIZAR E ANALISAR TODOS OS CENÁRIOS POSSÍVEIS PARA A ABERTURA OU ALTERAÇÃO DE UM EMPREENDIMENTO NO MUNICÍPIO';

        if (passo == 1) {
            
            // this.tituloPasso = 'PESQUISE O LOCAL PRETENDIDO PARA O SEU EMPREENDIMENTO';
            
            this.tituloPasso = `
            Pesquise o local pretendido para o seu empreendimento, algumas dicas:
            <ul>
                <li>
                    Você poderá localizar seu endereço através das opções de busca no quadro abaixo.
                </li>
                <li>
                    Se o seu endereço não foi localizado por alguma opção escolhida, preencha os campos com o máximo de informação que puder.
                </li>
                <li>
                    Ao avançar será exibido uma listagem com os endereços encontrados de acordo com as informações passadas.
                </li>
            </ul>
            `;
            
            if (passo == 1 && !this.exibirLocalizacao) {
                this.tituloPasso = `
                    Indique as atividades que pretende exercer no seu empreendimento, algumas dicas:
                    <ul>
                        <li>
                            Clique em adicionar atividades para selecionar as atividades pretendidas no seu empreendimento (você poderá selecionar até 6 atividades simultaneamente para a pesquisa).
                        </li>
                        <li>
                            As atividades que você selecionou serão apresentadas no quadro abaixo após você concluir a seleção.
                        </li>
                        <li>
                            Você poderá remover ou adicionar atividades a qualquer instante.
                        </li>
                        <li>
                            Após conferência das informações, clique em AVANÇAR.
                        </li>
                    </ul>
                `;
            }

            if (this.formLocalizacaoPesquisa != null && !this.formLocalizacaoPesquisa.exibeFormularioEndereco) {
                this.tituloPasso = 'EXISTE DIVERGÊNCIA ENTRA A SUA INFORMAÇÃO E A DISPONIBILIZADA PELO MUNICÍPIO. SELECIONE O ENDEREÇO COM MAIOR PROXIMIDADE DA SUA BUSCA.';
            }

        }
        else if (passo == 2) {
            if (!this.exibirLocalizacao || !this.exibirAtividades) {
                this.tituloPasso = `
                    RESULTADO DA PESQUISA
                    <ul>
                        <li>
                            Para os detalhes básicos de utilização do local e atividades que você definiu, utilize o ícone de informação visualizado nos quadros.
                        </li>
                        <li>
                            Utilize os ícones à esquerda do mapa para alterar os pontos e referências visualizadas, combinando-os de modo a poder efetuar uma avaliação das possibilidades que o local/atividade(s) possam lhe oferecer.
                        </li>
                        <li>
                            Salve ou compartilhe o resultado para uma futura análise e/ou comparação com novas pesquisas.
                        </li>
                    </ul>
                `;
            }
            else {
                this.tituloPasso = `
                    Indique as atividades que pretende exercer no seu empreendimento, algumas dicas:
                    <ul>
                        <li>
                            Clique em adicionar atividades para selecionar as atividades pretendidas no seu empreendimento (você poderá selecionar até 6 atividades simultaneamente para a pesquisa).
                        </li>
                        <li>
                            As atividades que você selecionou serão apresentadas no quadro abaixo após você concluir a seleção.
                        </li>
                        <li>
                            Você poderá remover ou adicionar atividades a qualquer instante.
                        </li>
                        <li>
                            Após conferência das informações, clique em AVANÇAR.
                        </li>
                    </ul>
                `;
            }
        }
        else if (passo == 3) {
            this.tituloPasso = `
                RESULTADO DA PESQUISA
                <ul>
                    <li>
                         Para os detalhes básicos de utilização do local e atividades que você definiu, utilize o ícone de informação visualizado nos quadros.
                    </li>
                    <li>
                        Utilize os ícones à esquerda do mapa para alterar os pontos e referências visualizadas, combinando-os de modo a poder efetuar uma avaliação das possibilidades que o local/atividade(s) possam lhe oferecer.
                    </li>
                    <li>
                        Salve ou compartilhe o resultado para uma futura análise e/ou comparação com novas pesquisas.
                    </li>
                </ul>
            `;
        }
    }

    baixarArquivo(codigo){
        this.arquivoService.downloadArquivoBinarioFaq(codigo)
        .subscribe((res: any) => {
            this.arquivoService.handleFile(res, 'manual_webmap.docx');
        });
    }

    salvar() {
    }
}