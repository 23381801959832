import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Zona } from '../../model/zona';

@Injectable({ providedIn: 'root' })
export class DocumentoService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarPorProtocolo(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/documento/buscarPorProtocolo/${ cliente }/${ protocolo }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorInscricao(cliente, inscricao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/documento/buscarPorInscricao/${ cliente }/${ inscricao }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
          
}