import { Component, Input, OnInit } from '@angular/core';
import { Protocolo } from "src/app/model/protocolo";
import { Constantes } from "src/app/model/constantes";
import { RedeSimService } from '../../../../services/redeSim/redeSim.service';
import { RedeSim } from '../../../../model/redeSim';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-informacao-rede-sim-perguntas',
  templateUrl: './informacao-rede-sim-perguntas.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoRedeSimPerguntasComponent implements OnInit {

  @Input() protocolo: Protocolo;
  @Input() tipoEmpresa: string = "";
  @Input() semCardHeader: boolean = false;
  @Input() executarBuscaDadosRedeSim: boolean = false;
  
  tipoTelefone = Constantes.TIPO_DE_TELEFONE;
  tipoDocumento = Constantes.TIPO_DE_DOCUMENTO;
  tipoDocumentoDescricao = Constantes.TIPO_DE_DOCUMENTO_DESCRICAO;

  nomeUsuario: string;
  documentoUsuario: string;
  dataAtual: Date;
  emailUsuario: string;
  dadosAuditor : boolean;
  tipoDeEmpresa: string;

  constructor(private redeSimService: RedeSimService,
              private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    
    if(this.executarBuscaDadosRedeSim){
      this.buscarDadosRedeSim();
    }

  }

  buscarDadosRedeSim(){
    this.redeSimService
      .buscarPorProtocolo(localStorage.getItem("pId"),this.protocolo.protocolo)
      .pipe(take(1))
      .subscribe(retorno => {
        this.protocolo.redeSim = retorno;
      })
      
  }

}
