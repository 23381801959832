import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";

import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { Constantes } from "src/app/model/constantes";
import { FormRequerenteComponent } from "src/app/pages/outros/form-requerente/form-requerente.component";
import { FormLocalizacaoComponent } from "src/app/pages/outros/form-localizacao/form-localizacao.component";
import { FormAtividadeComponent } from "src/app/pages/outros/form-atividade/form-atividade.component";
import { FormResponsavelComponent } from 'src/app/pages/outros/form-responsavel/form-responsavel.component';
import { FormQuestionarioComponent } from "src/app/pages/outros/form-questionario/form-questionario.component";
import { Configuracao } from "src/app/model/configuracao";
import { Servico } from "src/app/model/servico";
import { MatStepper } from "@angular/material/stepper";
import { Pergunta } from "src/app/model/pergunta";
import { Protocolo } from "src/app/model/protocolo";
import { Processo } from "src/app/model/processo";
import { Documento } from "src/app/model/documento";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import { PerguntaResposta } from "src/app/model/perguntaResposta";
import { FormIdentificacaoNegocioComponent } from "src/app/pages/outros/form-identificacao-negocio/form-identificacao-negocio.component";
import { FormResumoComponent } from "src/app/pages/outros/form-resumo/form-resumo.component";


@Component({
    selector: "app-abertura-autonomo",
    templateUrl: "./abertura-autonomo.component.html"
})
export class AberturaAutonomoComponent implements OnInit {

    tipoEmpresa: string;
    informativo: string;
    stepper: MatStepper;
    passoAnterior: number;
    passoAtual: number;
    configuracao: Configuracao;
    processo: Processo;
    tipoProcesso = Constantes.PROCESSO;
    servicos: Array<Servico>;
    protocolo: Protocolo;

    // Formulários
    formAberturaAutonomo: FormGroup;
    formularioRequerente: FormGroup;
    formularioIdentificacao: FormGroup;
    formularioLocalizacao: FormGroup;
    formularioAtividade: FormGroup;
    formularioResponsavel: FormGroup;
    formularioQuestionarioViabilidade: FormGroup;
    formularioQuestionarioFic: FormGroup;
    formularioResumo: FormGroup;

    
    
    @ViewChild(FormRequerenteComponent)
    formRequerente: FormRequerenteComponent;

    @ViewChild(FormIdentificacaoNegocioComponent)
    formIdentificacaoNegocio: FormIdentificacaoNegocioComponent;

    @ViewChild(FormLocalizacaoComponent)
    formLocalizacao: FormLocalizacaoComponent;

    @ViewChild(FormAtividadeComponent)
    formAtividade: FormAtividadeComponent;

    @ViewChild(FormResponsavelComponent)
    formResponsavel: FormResponsavelComponent;

    @ViewChild("formQuestionarioViabilidade")
    formQuestionarioViabilidade: FormQuestionarioComponent;

    @ViewChild("formQuestionarioFic")
    formQuestionarioFic: FormQuestionarioComponent;

    @ViewChild(FormResumoComponent)
    formResumo: FormResumoComponent;

    // Botões
    btnAnteriorLocalizacao: string = "Anterior";
    btnAvancarLocalizacao: string = "Avançar";

    // Botões
    btnAnteriorResponsavel: string = "Anterior";
    btnAvancarResponsavel: string = "Avançar";

    constructor(
        private router: Router,
        private protocoloService: ProtocoloService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute) {

        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];

        this.protocolo = new Protocolo();
        this.protocolo.cliente = localStorage.getItem("pId");
        this.protocolo.viabilidade.perguntasUsuario = new PerguntaResposta();

        this.formAberturaAutonomo = this.formBuilder.group({
            controleValidade: ["", [Validators.required]]
        });

        this.informativo = Constantes.INFORMATIVO_ABERTURA_AUTONOMO[0];

        this.processo = new Processo();

        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        
        this.configuracao.processos
            .forEach(processo => {
                var tipoProcesso = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ? "P" : "A";

                if (processo.codigo === "A" && processo.tipo === tipoProcesso && processo.tipoPessoa == Constantes.TIPO_PESSOA.Fisica) {
                    this.processo.cliente = processo.cliente;
                    this.processo.codigo = processo.codigo;
                    this.processo.descricao = processo.descricao;
                    this.processo.protocolo = processo.protocolo;
                    this.processo.tipo = processo.tipo;
                    this.protocolo.processo = this.processo;
                    this.servicos = processo.servicos;
                } 
            }
        );
        

    }

    ngOnInit(): void {
        this.passoAnterior = 0;
        this.passoAtual = 0;

        setTimeout(() => {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioIdentificacao = this.formIdentificacaoNegocio.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioResponsavel = this.formResponsavel.formulario;
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
            this.formularioResumo = this.formResumo.formulario;
        }, 0);
    }
    
    verificaValidacaoFormulario(formGroup: FormGroup) {
        
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });
    }

    renomearBotaoLocalizacao() {
        this.btnAnteriorLocalizacao = "Anterior";
        this.btnAvancarLocalizacao = "Avançar";

        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (!this.formLocalizacao.preenchimentoInicial) {
                this.btnAnteriorLocalizacao = "Cancelar";
                this.btnAvancarLocalizacao = "Confirmar";
            }
        }
    }

    voltarPassoLocalizacao() {
        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (this.formLocalizacao.preenchimentoInicial) {
                this.stepper.previous();
            }
            else {
                this.formLocalizacao.toggle();
            }
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoLocalizacao() {
        this.formularioLocalizacao = this.formLocalizacao.formulario;
        this.verificaValidacaoFormulario(this.formLocalizacao.formulario);
        if (this.formLocalizacao.validarFormulario())
            this.stepper.next();
        
    }

    avancarPassoAtividade() {
        if (this.formAtividade.validarFormulario())
            this.stepper.next();
    }

    renomearBotaoResponsavel() {
        this.btnAnteriorResponsavel = "Anterior";
        this.btnAvancarResponsavel = "Avançar";

        if (this.formResponsavel.editarResponsavel) {
            this.btnAnteriorResponsavel = "Cancelar";
            this.btnAvancarResponsavel = "Confirmar";
        }
    }

    voltarPassoResponsavel() {
        if (this.formResponsavel.editarResponsavel) {
            this.formResponsavel.toggle();
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoResponsavel() {
        this.formularioResponsavel = this.formResponsavel.formulario;
        if (this.formResponsavel.validarFormulario())
            this.stepper.next();
        
    }

    avancarPassoQuestionario() {
        this.verificaValidacaoFormulario(this.formQuestionarioViabilidade.formulario);
        this.verificaValidacaoFormulario(this.formQuestionarioFic.formulario);
        if (this.formQuestionarioViabilidade.validarFormulario() && this.formQuestionarioFic.validarFormulario()) {
            this.stepper.next();
        }
    }

    mudancaPasso(event, passos) {
        
        if (this.stepper == null) {
            this.stepper = passos;
        } 

        if (this.passoAtual == 0) {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioIdentificacao = this.formIdentificacaoNegocio.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioResponsavel = this.formResponsavel.formulario;
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
            this.formularioResumo = this.formResumo.formulario;
        }

        if ( this.passoAtual == 3 ) {
            this.formAtividade.validarFormulario();
        }
        
        if (event.selectedIndex == 2) {
            this.formLocalizacao.formulario.get("controleValidade").setValue("");
        }
        if (event.selectedIndex == 3) {
            this.formAtividade.formulario.get("controleValidade").setValue("");
        }
        if (event.selectedIndex == 4) {
            this.formResponsavel.obrigatorioContador = false;
            this.formResponsavel.controleValidade.setValue("");
        }
        if ( event.selectedIndex == 5) {
            // Questionário de Viabilidade
            this.formQuestionarioViabilidade.controleValidade.setValue("");
            this.formQuestionarioViabilidade.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
    
            let perguntaViabilidade = new Pergunta();
            perguntaViabilidade.cliente = localStorage.getItem("pId");
            perguntaViabilidade.tipo = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ? Constantes.TIPO_DE_PERGUNTA.Viabilidade_Evento : 
                                        this.formLocalizacao.apenasCorrespondencia.value ? Constantes.TIPO_DE_PERGUNTA.Viabilidade_Autonomo_Nao_Estabelecido : Constantes.TIPO_DE_PERGUNTA.Viabilidade_Autonomo;
            perguntaViabilidade.atividades = this.formAtividade.atividadesSelecionadas;
            
            this.formQuestionarioViabilidade.carregarPerguntas(perguntaViabilidade);
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;

            // Questionário de Fic
            this.formQuestionarioFic.controleValidade.setValue("");
            this.formQuestionarioFic.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
    
            let perguntaFic = new Pergunta();
            perguntaFic.cliente = localStorage.getItem("pId");
            perguntaFic.tipo = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ? Constantes.TIPO_DE_PERGUNTA.Fic_Evento : 
                                this.formLocalizacao.apenasCorrespondencia.value ? Constantes.TIPO_DE_PERGUNTA.Fic_Autonomo_Nao_Estabelecido : Constantes.TIPO_DE_PERGUNTA.Fic_Autonomo;
            perguntaFic.atividades = this.formAtividade.atividadesSelecionadas;
            
            this.formQuestionarioFic.carregarPerguntas(perguntaFic);
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
        }
        
        this.informativo = Constantes.INFORMATIVO_ABERTURA_AUTONOMO[event.selectedIndex];
        this.passoAtual = event.selectedIndex;
        this.passoAnterior = event.previouslySelectedIndex;

        this.montarAberturaAutonomo(event);
    }

    montarAberturaAutonomo(event) {
        if ( event.previouslySelectedIndex == 0) {

            let documento = new Documento();
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documento.nome = "CPF";
            documento.numero = localStorage.getItem("userId");

            this.protocolo.solicitante.documentos = [documento];
            
            this.formRequerente.preencher();
            this.protocolo.requerente = this.formRequerente.requerente;
            this.processo.servicos = [this.formRequerente.servicoEscolhido];

            this.protocolo.data = new Date().toLocaleDateString();
            this.formIdentificacaoNegocio.protocolo = this.protocolo;
            
        }
        else if ( event.previouslySelectedIndex == 1) {
            this.formIdentificacaoNegocio.preencher();
            this.protocolo.fic = this.formIdentificacaoNegocio.protocolo.fic;
            this.protocolo.registro = this.formIdentificacaoNegocio.protocolo.registro;
        }
        else if (event.previouslySelectedIndex == 2) {
            this.protocolo.viabilidade.enderecos = [
                this.formLocalizacao.enderecoEmpresarial,
                this.formLocalizacao.enderecoCorrespondencia,
            ];
            this.formResumo.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
            //SETANDO FLUXO DE AUTONOMO ESTABELECIDO NA MÃO
            this.processo.servicos[0].codigo = 60;
            this.protocolo.registro.estabelecido = "S";

            //SETANDO FLUXO DE AUTONOMO NÃO ESTABELECIDO NA MÃOs
            if(this.formLocalizacao.apenasCorrespondencia.value){
                this.processo.servicos[0].codigo = 61;
                this.protocolo.registro.estabelecido = "N";
            }

            if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento) {
                this.processo.servicos[0].codigo = 160;
            }
        }
        else if (event.previouslySelectedIndex == 3) {
            this.formAtividade.preencher();

            this.protocolo.viabilidade.atividadesExercidas = this.formAtividade.atividadesExercidas;

            this.protocolo.viabilidade.tipoUnidade = this.formAtividade.tipoUnidade.value;
            this.protocolo.viabilidade.tipoUnidadeAuxiliar = this.formAtividade.tipoUnidadeAuxiliar.value;
            
            this.protocolo.viabilidade.formasAtuacao = this.formAtividade.formasAtuacao;
            this.protocolo.viabilidade.descricaoAtividadeEconomica = this.formAtividade.descricaoAtividadeEconomica.value;
        }
        else if (event.previouslySelectedIndex == 4){
            this.protocolo.fic.socios = this.formResponsavel.socios;
        }
        else if (event.previouslySelectedIndex == 5) {
            this.formQuestionarioViabilidade.preencher();
            this.protocolo.viabilidade.perguntasUsuario = this.formQuestionarioViabilidade.perguntasRespostas;
            
            this.formQuestionarioFic.preencher();
            this.protocolo.fic.perguntasUsuario = this.formQuestionarioFic.perguntasRespostas;
        }
        this.formResumo.protocolo = this.protocolo;
        this.formResumo.preencher();
    }

    gerarAberturaAutonomo() {
        this.formResumo.preencherAnexos();
        
        if (!this.formResumo.termoDeAceite.value) {
            Swal.fire("", "Para que a solicitação seja finalizada ,você devera estar de acordo com o termo de responsabilidade.", "info")
            return false;
        }

        this.protocoloService
            .gerarFic(this.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    willClose: () => {
                        if (retorno.redirecionamento != null) {
                            this.router.navigate([retorno.redirecionamento]);
                        }
                    }
                })
            });
    }

}