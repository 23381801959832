export class Suspensao {

    cliente: string;
    protocolo: string;
    inscricao: string;
    atendenteCodigo: string;
    atendenteNome: string;
    processoAdministrativo: string;
    dataInicio: string;
    dataFim: string;
    observacao: string;
    timestamp: string;
    cnpj: string;
    dataEnvioInicio: string;
    dataEnvioFim: string;
   
    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.inscricao = "";
        this.atendenteCodigo = "";
        this.atendenteNome = "";
        this.processoAdministrativo = "";
        this.dataInicio = "";
        this.dataFim = "";
        this.observacao = "";
        this.timestamp = "";
        this.cnpj = "";
        this.dataEnvioInicio = "";
        this.dataEnvioFim = "";
      }
}