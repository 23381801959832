import { OnInit, Component, ViewChild } from "@angular/core";
import { AcompanhamentoService } from "src/app/services/operacao/acompanhamento.service";
import { GridAcompanhamentoComponent } from "src/app/components/grid/grid-acompanhamento.component";
import { take } from "rxjs/operators";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Grid } from "src/app/model/grid";
import { GridOpcao } from "src/app/model/gridOpcao";
import { GridFiltro } from "src/app/model/gridFiltro";
import { ActivatedRoute, Router } from "@angular/router";
import { of } from "rxjs";
import Swal from "sweetalert2";

@Component({
    selector: 'app-processo',
    templateUrl: './processo.component.html'
})

export class ProcessoComponent implements OnInit {

    // tipoEmpresa: string = "E";

    numeroInscricao: string;
    numeroProtocolo: string;
    orgao: string;
    formulario: FormGroup;

    // consultaGeral: ConsultaGeral = new ConsultaGeral();

    grid: Grid;
    filtroOpcoes: Array<GridOpcao>;

    emitirErro: boolean;
    mensagemErro: string;
    codigoMenu: string;
    
    @ViewChild("regularizacoes")
    gridRegularizacoes: GridAcompanhamentoComponent;

    @ViewChild("concluidos")
    gridConcluidos: GridAcompanhamentoComponent;

    @ViewChild("cancelados")
    gridCancelados: GridAcompanhamentoComponent;
    
    regularizacoes: Array<any> = new Array<any>();
    concluidos: Array<any> = new Array<any>();
    cancelados: Array<any> = new Array<any>();

    constructor(
        private acompanhamentoService: AcompanhamentoService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,) 
    {

        this.grid = new Grid();
        this.grid.filtro.push(new GridFiltro());
        
        this.mensagemErro = "";
        this.emitirErro = false;
        
        this.formulario = this.formBuilder.group({
          tipoBusca: [''],
          tipoBuscaValorInput: [''],
          tipoBuscaValorSelect: [''],
        });   
        
        this.orgao = this.activatedRoute.snapshot.params["codigoOrgao"];
        this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
        this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "";
        this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];
    }
    
    ngOnInit(): void {
        this.acompanhamentoService
            .acompanhar()
            .pipe(take(1))
            .subscribe(
                retorno => {
                    retorno.regularizacoes.map(dados => {
                        this.regularizacoes.push({
                            "protocolo": dados.protocolo,
                            "redeSim": dados.redeSim,
                            "inscricao": dados.inscricao,
                            "requerente": dados.requerente.nome,
                            "servico": dados.servico,
                            "servicoDescricao": dados.servicoDescricao,
                            "servicoClasse": dados.servicoClasse,
                            "servicoIcone": dados.servicoIcone,
                            "fase": dados.fase,
                            "faseDescricao": dados.faseDescricao,
                            "faseClasse": dados.faseClasse,
                            "faseIcone": dados.faseIcone,
                            "situacao": dados.situacao,
                            "situacaoDescricao": dados.situacaoDescricao,
                            "situacaoClasse": dados.situacaoClasse,
                            "situacaoIcone": dados.situacaoIcone,
                            "dataSituacao": dados.dataSituacao,
                            "gridMetodos": dados.gridMetodos
                        })
                    });

                    retorno.concluidos.map(dados => {
                        this.concluidos.push({
                            "protocolo": dados.protocolo,
                            "redeSim": dados.redeSim,
                            "inscricao": dados.inscricao,
                            "requerente": dados.requerente.nome,
                            "servico": dados.servico,
                            "servicoDescricao": dados.servicoDescricao,
                            "servicoClasse": dados.servicoClasse,
                            "servicoIcone": dados.servicoIcone,
                            "fase": dados.fase,
                            "faseDescricao": dados.faseDescricao,
                            "faseClasse": dados.faseClasse,
                            "faseIcone": dados.faseIcone,
                            "situacao": dados.situacao,
                            "situacaoDescricao": dados.situacaoDescricao,
                            "situacaoClasse": dados.situacaoClasse,
                            "situacaoIcone": dados.situacaoIcone,
                            "dataSituacao": dados.dataSituacao,
                            "gridMetodos": dados.gridMetodos
                        })
                    });

                    retorno.cancelados.map(dados => {
                        this.cancelados.push({
                            "protocolo": dados.protocolo,
                            "redeSim": dados.redeSim,
                            "inscricao": dados.inscricao,
                            "requerente": dados.requerente.nome,
                            "servico": dados.servico,
                            "servicoDescricao": dados.servicoDescricao,
                            "servicoClasse": dados.servicoClasse,
                            "servicoIcone": dados.servicoIcone,
                            "fase": dados.fase,
                            "faseDescricao": dados.faseDescricao,
                            "faseClasse": dados.faseClasse,
                            "faseIcone": dados.faseIcone,
                            "situacao": dados.situacao,
                            "situacaoDescricao": dados.situacaoDescricao,
                            "situacaoClasse": dados.situacaoClasse,
                            "situacaoIcone": dados.situacaoIcone,
                            "dataSituacao": dados.dataSituacao,
                            "gridMetodos": dados.gridMetodos
                        })
                    });

                    this.gridRegularizacoes.dados = this.regularizacoes;
                    this.gridRegularizacoes.atualizarTabela();

                    this.gridConcluidos.dados = this.concluidos;
                    this.gridConcluidos.atualizarTabela();

                    this.gridCancelados.dados = this.cancelados;
                    this.gridCancelados.atualizarTabela();
                }
            )
    }

    acaoSelectTipoBusca() {
      this.tipoBuscaValorInput.setValue("");
      this.tipoBuscaValorSelect.setValue("");

      if (this.grid.filtro[this.tipoBusca.value].tipoCampo == "SELECT") {
          of(this.grid.filtro[this.tipoBusca.value].opcoes).subscribe(opcoes => {
              this.filtroOpcoes = opcoes;
          });
      }
    }

    buscarGridPorFiltro() {
        let valor = this.grid.filtro[this.tipoBusca.value].tipoCampo == "INPUT" ? this.tipoBuscaValorInput.value : this.tipoBuscaValorSelect.value
        
        if (this.grid.filtro[this.tipoBusca.value].tipoCampo == "") {
            valor = "TODOS";
        }

        if (valor == "") {
            Swal.fire("", "Favor informar um valor para busca.", "error")
            return false;
        }

        var valorFormatado = valor.replace(/[/]/g,"");

        if (this.tipoBusca.value == 9 || this.tipoBusca.value == 12 || this.tipoBusca.value == 1 || this.tipoBusca.value == 11) {
          valorFormatado = valor.replace(/\D/g,"");
        }
        
        // this.consultaGeralService
        //   .buscar(this.grid.filtro[this.tipoBusca.value].codigo, valorFormatado)
        //   .pipe(take(1))
        //   .subscribe(retorno => {
        //     Object.assign(this.consultaGeral, retorno);
        //     this.preencherDados();
        //   })
    }
  
    get tipoBusca() {
      return this.formulario.get("tipoBusca");
    }  
    set tipoBusca(tipoBusca) {
      this.formulario.get("tipoBusca").setValue(tipoBusca);
    }
  
    get tipoBuscaValorInput() {
      return this.formulario.get("tipoBuscaValorInput");
    }  
    set tipoBuscaValorInput(tipoBuscaValorInput) {
      this.formulario.get("tipoBuscaValorInput").setValue(tipoBuscaValorInput);
    }
  
    get tipoBuscaValorSelect() {
      return this.formulario.get("tipoBuscaValorSelect");
    }  
    set tipoBuscaValorSelect(tipoBuscaValorSelect) {
      this.formulario.get("tipoBuscaValorSelect").setValue(tipoBuscaValorSelect);
    }

}