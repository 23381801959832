<form class="justify-content-center align-items-center" name="formulario" [formGroup]="formulario">
    <div class="card">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Selecione o Tipo do Seu Negócio</strong>
            </div>
        </div>

        <div class="card-body">
            <mat-radio-group #tipoNegocioRadio formControlName="tipoNegocio" aria-label="Selecione uma opção" (change)="acaoTipoEmpresa()">
                <div class="row">
                    <div class="form-group col-12">
                        <p class="card-text titulo">
                            <mat-radio-button value="{{ tipoEmpresa == tipoEmpresaConstante.Baixa_Oficio ? tipoEmpresaConstante.Baixa_Empresa : tipoEmpresaConstante.Abertura_Oficio_Empresa}}">
                                Empresa
                            </mat-radio-button>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <p class="card-text titulo">
                            <mat-radio-button value="{{ tipoEmpresa == tipoEmpresaConstante.Baixa_Oficio ? tipoEmpresaConstante.Baixa_Mei : tipoEmpresaConstante.Abertura_Oficio_Mei}}">
                                Mei
                            </mat-radio-button>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <p class="card-text titulo">
                            <mat-radio-button value="{{ tipoEmpresa == tipoEmpresaConstante.Baixa_Oficio ? tipoEmpresaConstante.Baixa_Autonomo : tipoEmpresaConstante.Abertura_Oficio_Autonomo}}">
                                Autônomo
                            </mat-radio-button>
                        </p>
                    </div>
                </div>
            </mat-radio-group>
        </div>
    </div>

</form>