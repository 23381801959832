<div class="card" *ngIf="geraInscricao == 'M' && tipoProcesso != 'U' && tipoProcesso != 'I' && (modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Oficio)">
    <form name="formulario" [formGroup]="formulario" >
        <div class="card-header">
            <div class="row justify-content-center align-items-center">
                <div class="row col-12" >
                    <strong>Gerador Manual de Inscrição Municipal</strong>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="row col-12 pt-4 justify-content-left align-items-center">
                <div class="row col-9 pl-1">
                    <p class="card-text-auditor" *ngIf="tipoEmpresa != 'M'">
                        Informe a inscrição municipal manualmente.
                    </p>
                    <p class="card-text-auditor" *ngIf="tipoEmpresa == 'M'">
                        Informe a inscrição municipal manualmente ou escolha gerar uma nova inscrição automática.
                    </p>
                </div>
                <div class="row col-3">
                    <input
                        autofocus
                        (blur)="iniciarParametros()"
                        class="form-control form-control-sm text-center"
                        #inscricaoDigitada
                        id="inscricaoDigitada"
                        name="inscricaoDigitada"
                        formControlName="inscricaoDigitada" 
                        type="text"
                        maxlength="10"
                    />
                  </div>
            </div>
            <div class="row col-12 pt-4 justify-content-left align-items-center" *ngIf="tipoEmpresa == 'M'">
                <div class="row col-12 pl-1">
                    <mat-checkbox 
                        class="pr-2"
                        #inscricaoAutomatica
                        name="inscricaoAutomatica"
                        id="inscricaoAutomatica"
                        formControlName="inscricaoAutomatica" 
                        (change)="marcarAutomatica(inscricaoAutomatica)"
                        value="true"
                    >
                    </mat-checkbox>
                    <p class="card-text-auditor">
                        Não estou informando uma inscrição manual e desejo que seja gerada uma nova inscrição municipal automáticamente.
                    </p>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="form-row row-linha justify-content-center align-items-center linha-botao" *ngIf="modulo != menuAtendimento.Oficio">
    <button *ngIf="modulo != menuAtendimento.Cancelamento && !eDespacho"
        type="button" 
        class="btn btn-success"
        title="deferir"
        (click)="deferir()"
    >
        Deferido
    </button>
    <button *ngIf="modulo != menuAtendimento.Cancelamento && !eDespacho"
        type="button" 
        class="btn btn-danger"
        title="indeferir"
        (click)="indeferir()"
    >
        Indeferido
    </button>
    <button *ngIf="modulo != menuAtendimento.Cancelamento && !eDespacho"
        type="button" 
        class="btn btn-warning"
        title="pendenciar"
        (click)="pendenciar()"
    >
        Pendência
    </button>
    <button *ngIf="modulo != menuAtendimento.Cancelamento && !eDespacho"
        type="button" 
        class="btn btn-warning"
        title="despachar"
        (click)="despachar()"
    >
        Despacho
    </button>
    <button *ngIf="eDespacho"
        type="button" 
        class="btn btn-warning"
        title="despachar"
        (click)="responderDespacho()"
    >
        Responder Despacho
    </button>
    <button *ngIf="modulo != menuAtendimento.Cancelamento && modulo != menuAtendimento.Recadastro && !eDespacho"
        type="button" 
        class="btn btn-success"
        title="pendenciar"
        (click)="enviarParaViabilidade()"
        [class.hidden]="!mostrarBotaoViabilidade"
    >
        Enviar Para Viabilidade
    </button>
    <button *ngIf="modulo != menuAtendimento.Cancelamento && !eDespacho"
        type="button" 
        class="btn btn-warning"
        title="pendenciarOrgao"
        (click)="pendenciarOrgao()"
        [class.hidden]="!mostrarBotaoPendenteOrgao"
    >
        Aguardar No Órgão
    </button>
    <button *ngIf="modulo == menuAtendimento.Cancelamento"
        type="button" 
        class="btn btn-danger"
        title="cancelarProtocolo"
        (click)="cancelarProtocolo()"
    >
        Cancelar
    </button>
</div>
