export class OrgaoDeClasse {
    cliente: string;
    codigo: string;
    nome: string;
    abreviacao: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.nome = "";
        this.abreviacao = "";
    }
}