<div class="container-fluid" *ngIf="porFase">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-5 text-center">Linha do tempo do protocolo {{ numeroProtocolo }}</h4>
                    <div class="hori-timeline" dir="ltr">
                        <ul class="list-inline events text-center">
                            <li *ngFor="let fase of fases" class="list-inline-item event-list col-4">
                                <div class="px-2" >
                                    <div class="event-date {{ fase.situacaoClasseTimeLine }}">
                                        <b>{{ fase.dataInicio == '' ? 'Em Espera' : (fase.dataInicio | uppercase ) }}</b>
                                    </div>
                                    <h5 class="titulo-horizontal">{{ fase.descricaoTipoAnaliseV2 }}</h5>
                                    <p class="text-muted">
                                        {{ fase.nomeProcesso }}<br>
                                        {{ fase.codigoOrgaoDescricao }}<br>
                                        {{ fase.situacaoDescricao }}
                                    </p>
                                    <a href="javascript:void(0);" class="btn btn-primary btn-sm" (click)="goToAnchor(fase.descricaoTipoAnaliseV2)">Detalhar</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- end card -->
        </div>
    </div>
</div>
<div class="container-fluid" *ngIf="porProcesso">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-5 text-center">Linha do tempo da inscrição {{ numeroInscricao }}</h4>
                    <div class="hori-timeline" dir="ltr">
                        <ul class="list-inline events text-center">
                            <li *ngFor="let processo of processos" class="list-inline-item event-list col-4">
                                <div class="px-2" >
                                    <div class="event-date {{ processo.servicos[0].fases[0].situacaoClasseTimeLine }}">
                                        <b>{{ processo.servicos[0].fases[0].dataFim == '' ? (processo.servicos[0].fases[0].dataInicio | uppercase ) : (processo.servicos[0].fases[0].dataFim | uppercase ) }}</b>
                                    </div>
                                    <h5 class="titulo-horizontal">{{ processo.protocolo }}</h5>
                                    <p class="text-muted">
                                        {{ processo.descricao }}<br>
                                        {{ processo.servicos[0].fases[0].nomeProcesso }}<br>
                                        {{ processo.servicos[0].fases[0].situacaoDescricao }}
                                    </p>
                                    <a href="javascript:void(0);" class="btn btn-primary btn-sm" (click)="goToAnchor(processo.protocolo + '_' + processo.descricao + '_' + processo.statusDescricao)">Detalhar</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- end card -->
        </div>
    </div>
</div>