import { Component, OnInit, ViewChild } from '@angular/core';
import { Constantes } from '../../../model/constantes';
import { FormGroup } from '@angular/forms';
import { FormIdentificacaoComponent } from '../../outros/form-identificacao/form-identificacao.component';
import { Empresa } from '../../../model/empresa';
import { EmpresaService } from "src/app/services/empresa/empresa.service";
import { Documento } from '../../../model/documento';
import Swal from 'sweetalert2';
import { take } from 'rxjs/operators';
import { InformacaoDocumentoComponent } from '../../outros/informacoes-cadastro/informacao-documento/informacao-documento.component';
import { Protocolo } from 'src/app/model/protocolo';
import { InformacaoIdentificacaoComponent } from '../../outros/informacoes-cadastro/informacao-identificacao/informacao-identificacao.component';
import { InformacaoRequisitoComponent } from '../../outros/informacoes-cadastro/informacao-requisito/informacao-requisito.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-autenticidade-alvara',
  templateUrl: './autenticidade-alvara.component.html'
})
export class AutenticidadeAlvaraComponent implements OnInit {
  informativo: string;
  empresa: Empresa;
  tipoEmpresa: Empresa;
  empresaLocalizada: boolean;
  dados : any;
  protocolo = new Protocolo();
  formulario: FormGroup;
  // Formulários
  formularioIdentificacao: FormGroup;

  @ViewChild(FormIdentificacaoComponent)
  formIdentificacao: FormIdentificacaoComponent;

  @ViewChild(InformacaoIdentificacaoComponent)
  informacaoIdentificacao: InformacaoIdentificacaoComponent;

  @ViewChild(InformacaoDocumentoComponent)
  informacaoDocumento: InformacaoDocumentoComponent;

  @ViewChild(InformacaoRequisitoComponent)
  informacaoRequisito: InformacaoRequisitoComponent;


  constructor(
    private empresaService: EmpresaService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.informativo = Constantes.INFORMATIVO_AUTENTICIDADE_ALVARA[0];

    this.empresa = new Empresa();
    this.empresa.registro.documentos = [new Documento()];

    setTimeout(() => {
      this.formularioIdentificacao = this.formIdentificacao.formulario;  
      this.informacaoIdentificacao.tipoAutenticidade = true;    
    }, 10);
   }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.activatedRoute.snapshot.queryParams["vParam2"] != null) {
        this.formIdentificacao.im.setValue(this.activatedRoute.snapshot.queryParams["vParam2"]);
      }
      if (this.activatedRoute.snapshot.queryParams["vParam3"] != null) {
        this.formIdentificacao.cpfCnpjEmpresa.setValue(this.activatedRoute.snapshot.queryParams["vParam3"]);
      }
      
      this.verificaAutenticidadeAlvara();

    }, 10);
  }

  verificaAutenticidadeAlvara(){
    this.empresaLocalizada = false;

    if (this.formIdentificacao.im.value.replace(/\D/g,"") == "" || this.formIdentificacao.cpfCnpjEmpresa.value.replace(/\D/g,"") == "") {
        return false;
    }

    this.empresaService
        .buscarDadosBasicos(
            localStorage.getItem("pId"),
            this.formIdentificacao.im.value.replace(/\D/g,""),
            this.formIdentificacao.cpfCnpjEmpresa.value.replace(/\D/g,""))
        .pipe(take(1))
        .subscribe( (retorno: Empresa) => {
            this.empresa = retorno;
            if (this.empresa.status == Constantes.STATUS_EMPRESA.Nao_Existe) {
                Swal.fire("", "Contribuinte não localizamos as informações em nossa base de dados. Verifique os dados e tente novamente.", "error");
                return false;
            }
            if (this.empresa.status == Constantes.STATUS_EMPRESA.Empresa_Encerrada) {
                Swal.fire({
                    title: "",
                    html: `Inscrição Municipal foi baixada em ${ this.empresa.encerramento.data }, não é possível verificar a autenticidade.`,
                    icon: "warning"
                })
                return false;
            }
            if (this.empresa.status == Constantes.STATUS_EMPRESA.Ativa) {
                Swal.fire("", "Inscrição encontrada, verifique abaixo as informações.", "success");
                this.preencher();
            }
        })
  }

  preencher(){
      this.empresaLocalizada = true;
      this.protocolo.registro = this.empresa.registro;
      this.protocolo.viabilidade = this.empresa.viabilidade;
      this.protocolo.fic = this.empresa.fic;
      this.protocolo.documentos = this.empresa.documentos;
      this.informacaoDocumento.protocolo = this.protocolo;
      this.informacaoIdentificacao.tipoEmpresa = this.protocolo.registro.tipoPessoa == "F" ?  Constantes.TIPO_EMPRESA.Autonomo : Constantes.TIPO_EMPRESA.Empresa;
      this.informacaoIdentificacao.protocolo = this.protocolo;  
    }


}
