import { Component, OnInit } from '@angular/core';
import { Pendencia } from '../../../../model/pendencia';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { Protocolo } from '../../../../model/protocolo';
import { Empresa } from '../../../../model/empresa';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { Licenciamento } from '../../../../model/licenciamento';
import { Despacho } from 'src/app/model/despacho';
import { Constantes } from '../../../../model/constantes';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AtendimentoService } from '../../../../services/operacao/atendimento.service';
import { Login } from '../../../../model/login';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
    selector: "app-atendimento-delegar",
    templateUrl: "./atendimento-delegar.component.html",
    styleUrls: ['./atendimento-delegar.component.scss']
})
export class AtendimentoDelegarComponent implements OnInit {

    numeroInscricao: string;
    numeroProtocolo: string;
    permitirAtendimento: boolean;
    permitirDelegar: boolean = false;
    usuarios: Array<Login>;

    despachos: Array<Despacho>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private atendimentoService: AtendimentoService,
        public dialog: MatDialog,
        private loginService: LoginService,) {
    
        this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "";
        this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];

        let meusProcessos = this.activatedRoute.snapshot.data["menu"] == Constantes.MENU_ATENDIMENTO.Processos;
        let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
        this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (!meusNegocios && !meusProcessos));

        atendimentoService
            .permissoesParaAtendimento()
            .pipe(take(1))
            .subscribe( retorno => {
                loginService
                    .permissaoParaDelegar()
                    .pipe(take(1))
                    .subscribe( retornoPermissao => {
                        if(retornoPermissao){
                            this.permitirDelegar = retorno.delegar;
                        }
                    })
            })
    }

    ngOnInit(): void {
    }

    delegarAtendimento(){
      const dialogRef = this.dialog.open(IcadModalComponent, {
          panelClass: "Usuários",
          width: '100%',
          height: '100%',
          maxWidth: 'none',
          maxHeight: 'none',
          closeOnNavigation: true,
          data: {
              titulo: "Atendimento de pendência",
              componente: "grid-usuarios",
              dados: [this.activatedRoute.snapshot.params["codigoOrgao"], this.numeroInscricao, this.numeroProtocolo]
          }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result === "" || result === null)
                return false;

            this.usuarios = result.usuarios.selected;

            this.vincularAtendimento();
        });
    }

    vincularAtendimento(){
        if (this.usuarios.length > 0) {
            Swal.fire({
                title: "",
                html: `Deseja vincular o atendimendo ao usuário <b>${ this.usuarios[0].nome }</b>?`,
                icon: 'question',
                showCancelButton: true})
                .then((result) => {
                    if (result.value) {
                        //if (this.numeroInscricao != null && this.numeroInscricao != "") {
                        if(this.activatedRoute.snapshot.params["codigoOrgao"] != 200){
                            this.vincularAtendimentoPorInscricao()
                        }
                        else {
                            this.vincularAtendimentoPorProtocolo()
                        }
                    }
                });
        }
        
    }

    vincularAtendimentoPorInscricao() {
        this.atendimentoService
            .vincularAtendimentoPorInscricao(
                localStorage.getItem('pId'), 
                this.activatedRoute.snapshot.params["codigoOrgao"],
                this.usuarios[0].codigo,
                this.numeroInscricao,
            )
            .pipe(take(1))
            .subscribe( retorno => {
                if (retorno.length) {
                    Swal.fire("", `Atendimento da empresa vinculado ao usuário <b>${ this.usuarios[0].nome }</b>.`, "info");
                }
            });
    }

    vincularAtendimentoPorProtocolo() {
        this.atendimentoService
            .vincularAtendimentoPorProtocolo(
                localStorage.getItem('pId'), 
                this.activatedRoute.snapshot.params["codigoOrgao"],
                this.usuarios[0].codigo,
                this.numeroProtocolo
            )
            .pipe(take(1))
            .subscribe( retorno => {
                if (retorno.length) {
                    Swal.fire("", `Atendimento do protocolo vinculado ao usuário <b>${ this.usuarios[0].nome }</b>.`, "info");
                }
            });
    }

}