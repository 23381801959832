import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FormValidator } from "src/app/components/validators/form-validator";
import { formatToCPFOrCNPJ, formatToCPF, formatToCNPJ, formatToPhone } from "brazilian-values";
import { Servico } from "src/app/model/servico";
import { NaturezaJuridica } from "src/app/model/naturezaJuridica";
import { of } from "rxjs";
import { LoginService } from "src/app/services/login/login.service";
import { Login } from "src/app/model/login";
import Swal from "sweetalert2";
import { Constantes } from "src/app/model/constantes";
import { Documento } from "src/app/model/documento";
import { Telefone } from "src/app/model/telefone";
import { Registro } from "src/app/model/registro";
import { take } from "rxjs/operators";
import { MaskPipe } from 'ngx-mask';
import { ThrowStmt } from "@angular/compiler";
import { Protocolo } from '../../../model/protocolo';
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import * as moment from 'moment';
import { FormTipoNegocioComponent } from "../form-tipo-negocio/form-tipo-negocio.component";

@Component({
    selector: "app-form-requerente",
    templateUrl: "./form-requerente.component.html",
    styleUrls: ["./form-requerente.component.scss"]
})
export class FormRequerenteComponent implements OnInit {

    tipoEmpresa: string;
    tipoEmpresaAuxiliar: string;
    formulario: FormGroup;
    requerente: Login;
    registro: Registro;
    servicoEscolhido: Servico;
    emitirCritica: boolean = false;
    mensagemCritica: string = "";

    nomeUsuario: string;
    documentoUsuario: string;
    dataAtual: Date;
    emailUsuario: string;
    
    @Output() acaoDeAtendimento = new EventEmitter();
    
    @Input() servicos: Array<Servico>;
    listaDeNaturezas: Array<NaturezaJuridica>;

    @ViewChild(FormTipoNegocioComponent)
    formTipoNegocio: FormTipoNegocioComponent;


    constructor(
        
        private formBuilder: FormBuilder,
        private loginService: LoginService,
        private activatedRoute: ActivatedRoute,
        private mask: MaskPipe,
        private authenticationService: AuthenticationService) {
        
        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];
        this.tipoEmpresaAuxiliar = this.activatedRoute.snapshot.data["tipoEmpresa"];

        
        if(this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa && 
           this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei && 
           this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo && 
           this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio){
            this.formulario = this.formBuilder.group({
                cpfCnpj: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(18), FormValidator.cpfCnpjValido()]],
                nome: ['', [Validators.required]],
                email: ['', [Validators.required, Validators.email]],
                telefoneFixo: [''],
                telefoneComercial: [''],
                telefoneCelular: ['', [Validators.required, Validators.minLength(16)]],
                orgaoDeRegistro: ['', [Validators.required,]],
                naturezaJuridica: ['', [Validators.required]],
            });
        }
        else{
            this.formulario = this.formBuilder.group({
                cpfCnpj: ['', [Validators.minLength(14), Validators.maxLength(18), FormValidator.cpfCnpjValido()]],
                nome: [''],
                email: ['', [ Validators.email]],
                telefoneFixo: [''],
                telefoneComercial: [''],
                telefoneCelular: ['', Validators.minLength(16)],
                orgaoDeRegistro: ['', [Validators.required,]],
                naturezaJuridica: ['', [Validators.required]],
            });
        }

        this.requerente = new Login();
        this.registro = new Registro();
        this.servicoEscolhido = new Servico();

    }

    ngOnInit(): void {
       this.montarConfiguracao();    
       this.inicializacaoFormulario(); 
    }

    atualizarTipoEmpresa(acaoAtendimento) {
         this.tipoEmpresa = acaoAtendimento.tipoEmpresaFormulario;
         this.montarConfiguracao();
         this.inicializacaoFormulario(); 
         
         this.acaoDeAtendimento.emit({ tipoEmpresaFormulario: acaoAtendimento.tipoEmpresaFormulario });
    }
        

    inicializacaoFormulario() {

        this.nomeUsuario = this.authenticationService.currentUserValue.nomeFantasia;
        this.documentoUsuario = this.authenticationService.currentUserValue.codigo_usuario;
        this.emailUsuario = this.authenticationService.currentUserValue.email;
        let dataAtual  = new Date();
        this.dataAtual = moment(dataAtual).format("DD/MM/YYYY HH:mm");
    }

    montarConfiguracao() {
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei ||
            this.tipoEmpresaAuxiliar == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Mei
        ) {
            this.servicos.forEach(servico => {
                if (servico.codigoAuxiliar == 1) {
                    of(servico.naturezaJuridicas).subscribe(listaDeNatureza => {
                        listaDeNatureza.forEach(natureza => {
                            if (natureza.codigo == "213-5") {
                                this.listaDeNaturezas = [natureza];
                                this.naturezaJuridica.setValue(natureza.codigo);
                            }
                        })
                    });
                    this.orgaoDeRegistro.setValue(servico.codigoAuxiliar);
                }
            });
            this.orgaoDeRegistro.disable();
            this.naturezaJuridica.disable();
            if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei ||
                this.tipoEmpresaAuxiliar == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Mei
            ){
                this.cpfCnpj.clearValidators();
                this.email.clearValidators();
                this.nome.clearValidators();
                this.telefoneCelular.clearValidators();

                this.cpfCnpj.updateValueAndValidity();
                this.email.updateValueAndValidity();
                this.nome.updateValueAndValidity();
                this.telefoneCelular.updateValueAndValidity();
            }
        }
        else if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Empresa || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Mei || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo ||
            this.tipoEmpresaAuxiliar == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo){
            this.orgaoDeRegistro.clearValidators();
            this.naturezaJuridica.clearValidators();
            this.cpfCnpj.clearValidators();
            this.cpfCnpj.setValidators(FormValidator.cpfCnpjValido());
            this.email.clearValidators();
            this.email.setValidators(Validators.email);
            this.nome.clearValidators();
            this.telefoneCelular.clearValidators();
            this.telefoneCelular.setValidators(Validators.minLength(11));

            this.orgaoDeRegistro.updateValueAndValidity();
            this.naturezaJuridica.updateValueAndValidity();
            this.cpfCnpj.updateValueAndValidity();
            this.email.updateValueAndValidity();
            this.nome.updateValueAndValidity();
            this.telefoneCelular.updateValueAndValidity();
        }
        else if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento){
            
            this.orgaoDeRegistro.clearValidators();
            this.naturezaJuridica.clearValidators();

            this.orgaoDeRegistro.updateValueAndValidity();
            this.naturezaJuridica.updateValueAndValidity();
            
        }
        else if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa ||
                this.tipoEmpresaAuxiliar == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Empresa
        ){
            this.cpfCnpj.clearValidators();
            this.cpfCnpj.setValidators(FormValidator.cpfCnpjValido());
            this.email.clearValidators();
            this.email.setValidators(Validators.email);
            this.nome.clearValidators();
            this.telefoneCelular.clearValidators();
            this.telefoneCelular.setValidators(Validators.minLength(16));

            this.cpfCnpj.updateValueAndValidity();
            this.email.updateValueAndValidity();
            this.nome.updateValueAndValidity();
            this.telefoneCelular.updateValueAndValidity();
      
            this.orgaoDeRegistro.enable();
            this.naturezaJuridica.enable();
        }

    }
    
    formatToCPFOrCNPJ(valor) {
        this.cpfCnpj.setValue(formatToCPFOrCNPJ(valor));
    }

    formatToCPFOrCNPJReturn(valor) {
        return formatToCPFOrCNPJ(valor.toString());
    }

    formatToCPF(valor) {
        this.cpfCnpj.setValue(formatToCPF(valor));
    }

    formatToCNPJ(valor) {
        this.cpfCnpj.setValue(formatToCNPJ(valor));
    }
  
    formatToPhone(objeto) {
      objeto.value = formatToPhone(objeto.value);
      this.atualizaValoresTelefones();
    }

    atualizaValoresTelefones() {
        if (this.telefoneFixo.value != null) {
            this.telefoneFixo.setValue(formatToPhone(this.telefoneFixo.value));
        }
        if (this.telefoneComercial.value != null) {
            this.telefoneComercial.setValue(formatToPhone(this.telefoneComercial.value));
        }
        if (this.telefoneCelular.value != null) {
            this.telefoneCelular.setValue(formatToPhone(this.telefoneCelular.value));
        }
    }

    verificaUsuario() {
      
        if (this.cpfCnpj.invalid)
            return false;
  
        let login = new Login();
        login.login = this.cpfCnpj.value.replace(/\D/g,"");

        if(this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo && this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa && this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei && this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio){
            this.loginService
            .buscarPorLogin(login)
            .pipe(take(1))
            .subscribe( (usuario: Login) => { 
                if (!usuario.login) {
                    return false;
                }
                Swal.fire("", "O CPF/CNPJ preenchido é igual ao CPF/CNPJ do Solicitante, os dados do requerente serão preenchidos automaticamente", "info");

                this.preencherCampos(usuario);
            },
            (err) => {
                console.log('err', err);
                Swal.fire('', 'Não foi possível verificar o CPF / CNPJ.', 'error');
            }
        );
        }    
        
    }

    acaoOrgaoDeRegistroSelect(value: number) {
        this.servicos.forEach(servico => {
            if (servico.codigoAuxiliar == value) {
                of(servico.naturezaJuridicas).subscribe(natureza => {
                    this.listaDeNaturezas = natureza;
                });
            }
        });
    }

    preencherCampos(dados: Login) {
        this.limparCampos();

        this.cpfCnpj.setValue(formatToCPFOrCNPJ(dados.login.toString()));
        this.nome.setValue(dados.nome);
        this.email.setValue(dados.emails[0]);
        dados.telefones.forEach( telefone => {

            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Residencial) {
                this.telefoneFixo.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Comercial) {
                this.telefoneComercial.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Celular) { 
                this.telefoneCelular.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
        });
    }

    preencherCamposCriticas(protocolo: Protocolo) {
        this.limparCampos();

        this.cpfCnpj.setValue(formatToCPFOrCNPJ(protocolo.requerente.login.toString()));
        this.nome.setValue(protocolo.requerente.nome);
        this.email.setValue(protocolo.requerente.emails[0]);
        protocolo.requerente.telefones.forEach( telefone => {
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Residencial) {
                this.telefoneFixo.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Comercial) {
                this.telefoneComercial.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Celular) { 
                this.telefoneCelular.setValue(formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString())));
            }
        });
        
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
            of(protocolo.registro.naturezaJuridica).subscribe(natureza => {
                this.listaDeNaturezas = [natureza];
                setTimeout(() => {
                    this.naturezaJuridica.setValue(natureza.codigo);
                }, 1);
            });
            this.orgaoDeRegistro.setValue(protocolo.registro.orgao);
            this.orgaoDeRegistro.disable();
            this.naturezaJuridica.disable();
        }

        this.mensagemCritica += Protocolo.exibirCriticasFormulario(this.formulario, protocolo.criticas);
        if (this.mensagemCritica != "") {
            this.emitirCritica = true;
        }
        
    }
   
    limparCampos() {
        
        this.cpfCnpj.reset();
        this.nome.reset();
        this.email.reset();
        this.telefoneFixo.reset();
        this.telefoneComercial.reset();
        this.telefoneCelular.reset();

        if (this.tipoEmpresa != Constantes.TIPO_EMPRESA.Mei && 
            this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa && 
            this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei) {
            this.orgaoDeRegistro.reset();
            this.naturezaJuridica.reset();
        }
    }

    preencher() {

        let documento = new Documento();
        documento.numero = this.cpfCnpj.value;

        if ((this.cpfCnpj.value).length == 14) {
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documento.nome = "CPF";
        }else if ((this.cpfCnpj.value).length == 18){
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cnpj;
            documento.nome = "CNPJ";
        }
        this.requerente.documentos = [documento];
        this.requerente.nome = this.nome.value;
        this.requerente.emails = [this.email.value];
        
        let telefones = new Array<Telefone>();
        
        let telefoneFixo = new Telefone();
        telefoneFixo.tipo = Constantes.TIPO_DE_TELEFONE.Residencial;
        telefoneFixo.ddd = this.telefoneFixo.value != null ? this.telefoneFixo.value.replace(/\D/g,"").substring(0, 2) : "";
        telefoneFixo.numero = this.telefoneFixo.value != null ? this.telefoneFixo.value.replace(/\D/g,"").substring(2, 11) : "";
        telefones.push(telefoneFixo);
        
        let telefoneComercial = new Telefone();
        telefoneComercial.tipo = Constantes.TIPO_DE_TELEFONE.Comercial;
        telefoneComercial.ddd = this.telefoneComercial.value != null ? this.telefoneComercial.value.replace(/\D/g,"").substring(0, 2) : "";
        telefoneComercial.numero = this.telefoneComercial.value != null ? this.telefoneComercial.value.replace(/\D/g,"").substring(2, 11) : "";
        telefones.push(telefoneComercial);
        
        let telefoneCelular = new Telefone();
        telefoneCelular.tipo = Constantes.TIPO_DE_TELEFONE.Celular;
        telefoneCelular.ddd = this.telefoneCelular.value != null ? this.telefoneCelular.value.replace(/\D/g,"").substring(0, 2) : "";
        telefoneCelular.numero = this.telefoneCelular.value != null ? this.telefoneCelular.value.replace(/\D/g,"").substring(2, 11) : "";
        telefones.push(telefoneCelular);

        this.requerente.nome = this.nome.value;
        this.requerente.telefones = telefones;

        if(this.requerente.nome == ''){
            this.requerente.nome = this.authenticationService.currentUserValue.nomeFantasia;
            
            let documento = new Documento();
            documento.numero = this.authenticationService.currentUserValue.codigo_usuario;
            
            if ((this.authenticationService.currentUserValue.codigo_usuario).length == 11) {
                documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
                documento.nome = "CPF";
            }else if ((this.authenticationService.currentUserValue.codigo_usuario).length == 14){
                documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cnpj;
                documento.nome = "CNPJ";
            }
            this.requerente.documentos = [documento];

        }
        
        
        if (this.tipoEmpresa != Constantes.TIPO_EMPRESA.Autonomo &&
            this.tipoEmpresa != Constantes.TIPO_EMPRESA.Baixa_Empresa &&
            this.tipoEmpresa != Constantes.TIPO_EMPRESA.Baixa_Mei &&
            this.tipoEmpresa != Constantes.TIPO_EMPRESA.Baixa_Autonomo &&
            this.tipoEmpresa != Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo &&
            this.tipoEmpresa != Constantes.TIPO_EMPRESA.Autonomo_Evento &&
            this.tipoEmpresa != Constantes.TIPO_EMPRESA.Empresa_Evento) {
                this.registro.orgao = this.orgaoDeRegistro.value == null ? "" : this.orgaoDeRegistro.value;
                this.servicos.forEach( servicoEncontrado => {
                    if (servicoEncontrado.codigoAuxiliar.toString() == this.orgaoDeRegistro.value.toString()) {
                        Object.assign(this.servicoEscolhido, servicoEncontrado);
                        this.registro.orgaoNome = servicoEncontrado.descricao
        
                        servicoEncontrado.naturezaJuridicas.forEach( natureza => {
                            if (natureza.codigo == this.naturezaJuridica.value) {
                                this.servicoEscolhido.naturezaJuridicas = [natureza];
                                this.registro.naturezaJuridica = natureza
                            }
                        })
                    }
                })
        }
        else if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento) { 
            Object.assign(this.servicoEscolhido, this.servicos[0]);
        }
        
    }

    get cpfCnpj() {
        return this.formulario.get("cpfCnpj");
    }  
    set cpfCnpj(cpfCnpj) {
        this.formulario.get("cpfCnpj").setValue(cpfCnpj);
    }

    get nome() {
        return this.formulario.get("nome");
    }  
    set nome(nome) {
        this.formulario.get("nome").setValue(nome);
    }

    get email() {
        return this.formulario.get("email");
    }  
    set email(email) {
        this.formulario.get("email").setValue(email);
    }

    get telefoneFixo() {
        return this.formulario.get("telefoneFixo");
    }  
    set telefoneFixo(telefoneFixo) {
        this.formulario.get("telefoneFixo").setValue(telefoneFixo);
    }

    get telefoneComercial() {
        return this.formulario.get("telefoneComercial");
    }  
    set telefoneComercial(telefoneComercial) {
        this.formulario.get("telefoneComercial").setValue(telefoneComercial);
    }

    get telefoneCelular() {
        return this.formulario.get("telefoneCelular");
    }  
    set telefoneCelular(telefoneCelular) {
        this.formulario.get("telefoneCelular").setValue(telefoneCelular);
    }

    get orgaoDeRegistro() {
        return this.formulario.get("orgaoDeRegistro");
    }  
    set orgaoDeRegistro(orgaoDeRegistro) {
        this.formulario.get("orgaoDeRegistro").setValue(orgaoDeRegistro);
    }

    get naturezaJuridica() {
        return this.formulario.get("naturezaJuridica");
    }  
    set naturezaJuridica(naturezaJuridica) {
        this.formulario.get("naturezaJuridica").setValue(naturezaJuridica);
    }

}