import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class ArquivoService {

    constructor(private http: HttpClient) {}

    buscarPorProtocolo(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/anexo/buscarPorProtocolo/${ cliente }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorInscricao(cliente, inscricao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/anexo/buscarPorInscricao/${ cliente }/${ inscricao }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorCodigo(cliente, codigo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/anexo/buscarPorCodigo/${ cliente }/${ codigo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    atenderAnexo(anexo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/anexo/atenderAnexo/`, JSON.stringify(anexo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoAtendimento(arquivo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoAtendimento/${ arquivo }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoAtendimentoPorThread(protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoAtendimentoPorThread/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoPorCodigo(codigo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoPorCodigo/${ codigo }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoDeVinculosPorCodigo(codigo,tipo,parametro1) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoDeVinculosPorCodigo/${ codigo }/${ tipo }/${ parametro1 }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoDeVinculosAtivos(tipo,parametro1) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoDeVinculosAtivos/${ tipo }/${ parametro1 }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoAtendimentoParaVisualizar(arquivo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoAtendimentoParaVisualizar/${ arquivo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoPorCodigoParaVisualizar(codigo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoPorCodigoParaVisualizar/${ codigo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoBinarioFaq(codigo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoBinarioFaq/${ localStorage.getItem("pId") }/${ codigo }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoBinarioFotoPerfil(codigo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoBinarioFotoPerfil/${ localStorage.getItem("pId") }/${ codigo }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoLicenciamentoPorCodigo(codigo, tipo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoLicenciamentoPorCodigo/${ codigo }/${ tipo }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    upload(formData) {
        return this.http.post<any>(
            `${ environment.icadUrlV2 }/arquivo/upload`, formData,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    uploadAnexo(formData) {
        return this.http.post<any>(
            `${ environment.icadUrlV2 }/anexo/uploadAnexo`, formData,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    uploadFotoPerfil(formData) {
        return this.http.post<any>(
            `${ environment.icadUrlV2 }/anexo/uploadFotoPerfil`, formData,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    uploadArquivoRetorno150(formData) {
        return this.http.post<any>(
            `${ environment.icadUrlV2 }/arquivo/uploadArquivoRetorno150`, formData,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    uploadArquivoDeVinculos(formData) {
        return this.http.post<any>(
            `${ environment.icadUrlV2 }/arquivo/uploadArquivoDeVinculos`, formData,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    downloadComprovanteTaxaPorCodigo(codigo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadComprovanteTaxaPorCodigo/${ codigo }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoTaxaLoteDetalhamento(codigo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoTaxaLoteDetalhamento/${ codigo }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    downloadArquivoTaxaLoteMemoria(codigo) {
        return this.http.get<any>(
            `${ environment.icadUrlV2 }/arquivo/downloadArquivoTaxaLoteMemoria/${ codigo }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    handleFile(res: any, fileName: string) {
        const file = new Blob([res], {
          type: res.type
        });
    
        // IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file);
          return;
        }
    
        const blob = window.URL.createObjectURL(file);
    
        const link = document.createElement('a');
        link.href = blob;
        link.download = fileName;
    
        link.dispatchEvent(new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        }));
    
        setTimeout(() => { // firefox
          window.URL.revokeObjectURL(blob);
          link.remove();
        }, 100);
    }

    deletarArquivo(codigo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/anexo/deletarAnexo/${ codigo }`,
            {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    uploadProcessamentoLote(formData) {
        return this.http.post<any>(
            `${ environment.icadUrl }/arquivo/uploadProcessamentoLote`, formData,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',

                }),
            }
        );
    }

    buscarInformacoesProcessamentoLote() {
        return this.http.get<any>(
            `${ environment.icadUrl }/arquivo/buscarInformacoesProcessamentoLote`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
}
