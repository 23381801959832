import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';
import { retry, catchError, tap } from "rxjs/operators";
import { IcadModalComponent } from "src/app/components/modal/icad-modal/icad-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LoaderService } from "../loader/loader.service";
import Swal from "sweetalert2";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  eixos: any;

  constructor(
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private document: Document,
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any> | 
    HttpHeaderResponse | 
    HttpProgressEvent | 
    HttpResponse<any> | 
    HttpUserEvent<any>>  {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.authenticationToken) {
      request = request.clone({
        headers: request.headers.append(
          'Authorization',
          `Token ${currentUser.authenticationToken}`
        ),
      });
    }
    
    if (currentUser && currentUser.access_token) {
      request = request.clone({
        headers: request.headers.append(
          'Authorization',
          localStorage.getItem('tknEstrutura') ? localStorage.getItem('tknEstrutura') : currentUser.access_token
        ),
      });
    }

    return next
      .handle(request)
      .pipe(tap(event => {
        if(event instanceof HttpResponse) { 
          if (event.headers.get('refresh_token') != "" && event.headers.get('refresh_token') != null) {
            localStorage.setItem(
              'tknEstrutura',
              JSON.stringify('Bearer ' + event.headers.get('refresh_token'))
            );
          }
        }
      }))
      .pipe(catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          Swal.fire("", "Sua sessão expirou, efetue novamente o acesso.", "info");
          this.authenticationService.logout();
          // const dialogRef = this.dialog.open(IcadModalComponent, {
          //     width: '410px',
          //     height: 'auto',
          //     // maxWidth: "60vh",
          //     // maxHeight: "none",

          //     disableClose: true,
          //     data: {
          //         titulo: "",
          //         componente: "login"
          //     }
          // });
      
          // dialogRef.afterClosed().subscribe(result => {
          //   this.loaderService.show();
          //   setTimeout(() => {
          //     if (result.autenticado && this.authenticationService.reload) {
          //       document.location.reload();
          //     }
          //   }, 0);
          // });
            
        }
        return throwError(error);
      })
    );

    
  }
}
