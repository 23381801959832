import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

// import { Users } from 'src/models/users';
import { Endereco } from '../../model/endereco';
import { Imobiliario } from '../../model/imobiliario';

@Injectable({ providedIn: 'root' })
export class EnderecoService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarRegra(cliente, tipoEndereco) {
        return this.http.get<any>(
        `${ environment.icadUrl }/endereco/buscarRegra/${ cliente }/${ tipoEndereco }`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorCep(cliente, tipoEndereco, cep) {
        return this.http.get<any>(
        `${ environment.icadUrl }/endereco/buscarPorCep/${ cliente }/${ tipoEndereco }/${ cep }`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorInscricao(cliente, tipoEndereco, inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/endereco/buscarPorInscricao/${ cliente }/${ tipoEndereco }/${ inscricao }`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }

    validarInscricao(cliente, inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/endereco/validarInscricao/${ cliente }/${ inscricao }`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorLogradouro(cliente, tipoEndereco, estado, logradouro) {
        return this.http.get<any>(
        `${ environment.icadUrl }/endereco/buscarPorLogradouro/${ cliente }/${ tipoEndereco }/${ estado }/${ logradouro }`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarImobiliarioPorCodigo(cliente, inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/endereco/buscarImobiliarioPorCodigo/${ cliente }/${ inscricao }`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarImobiliarioPorProtocolo(cliente, protocolo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/endereco/buscarImobiliarioPorProtocolo/${ cliente }/${ protocolo }`,
        {
            reportProgress: true,
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarEnderecosAlternativos(endereco: Endereco) {
        return this.http.post<any>(
            `${ environment.icadUrl }/endereco/buscarEnderecosAlternativos`, JSON.stringify(endereco),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarNomesDeLogradouros() {
        return this.http.get<any>(
        `${ environment.icadUrl }/endereco/buscarNomesDeLogradouros`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarNomesDeBairros() {
        return this.http.get<any>(
        `${ environment.icadUrl }/endereco/buscarNomesDeBairros`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }
    
    incluirImobiliario(imobiliario: Imobiliario) {
        return this.http.post<any>(
            `${ environment.icadUrl }/endereco/incluirImobiliario`, JSON.stringify(imobiliario),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    alterarImobiliario(imobiliario: Imobiliario) {
        return this.http.post<any>(
            `${ environment.icadUrl }/endereco/alterarImobiliario`, JSON.stringify(imobiliario),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    validarCepPorCliente(cep) {
        return this.http.get<any>(
        `${ environment.icadUrl }/endereco/validarCepPorCliente/${ cep }`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
        );
    }

}
