import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { Licenciamento } from '../../model/licenciamento';

@Injectable({
  providedIn: 'root'
})
export class LicenciamentoService {

  constructor(private http: HttpClient, private router: Router) {}

  gerarLicenciamentoEletronico(licenciamento: Licenciamento) {
    return this.http.post<any>(
        `${ environment.icadUrl }/licenciamento/gerarLicenciamentoEletronico`, JSON.stringify(licenciamento),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
    );
  }

  buscarPorProtocolo(cliente, protocolo) {
    return this.http.get<any>(
    `${ environment.icadUrl }/licenciamento/buscarPorProtocolo/${ cliente }/${ protocolo }`,
    {
        headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
        }),
    }
    );
  }

  gerarDocumento(licenciamento: Licenciamento) {
    return this.http.post<any>(
        `${ environment.icadUrl }/licenciamento/gerarDocumento`, JSON.stringify(licenciamento),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
    );
  }

  atender(licenciamento: Licenciamento) {
    return this.http.post<any>(
        `${ environment.icadUrl }/licenciamento/atender`, JSON.stringify(licenciamento),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
    );
  }

  liberarBloquearDocumento(cliente, inscricao, orgao, requisito, tipo){
    return this.http.get<any>(
      `${ environment.icadUrl }/licenciamento/liberarBloquearDocumento/${ cliente }/${ inscricao }/${ orgao }/${ requisito }/${ tipo }`,
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }

  liberarBloquearDocumentoComObservacao(licenciamento: Licenciamento){
    return this.http.post<any>(
      `${ environment.icadUrl }/licenciamento/liberarBloquearDocumentoComObservacao`, JSON.stringify(licenciamento),
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }

  regerarDocumento(licenciamento: Licenciamento){
    return this.http.post<any>(
      `${ environment.icadUrl }/licenciamento/regerarDocumento`, JSON.stringify(licenciamento),
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }

  liberarBloquearQuestionarioComObservacao(licenciamento: Licenciamento){
    return this.http.post<any>(
      `${ environment.icadUrl }/licenciamento/liberarBloquearQuestionarioComObservacao`, JSON.stringify(licenciamento),
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }

  buscarParaAtendimentoPorInscricaoEOrgao(cliente, inscricao, orgao, requisito, atividades){
    return this.http.get<any>(
      `${ environment.icadUrl }/licenciamento/buscarParaAtendimentoPorInscricaoEOrgao/${ cliente }/${ inscricao }/${ orgao }/${ requisito }/${ atividades }`,
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }

  buscarConfiguracaoAtendimento(){
    return this.http.get<any>(
      `${ environment.icadUrl }/licenciamento/buscarConfiguracaoAtendimento`,
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }
  
  configurarAtendimento(licenciamento: Licenciamento){
    return this.http.post<any>(
      `${ environment.icadUrl }/licenciamento/configurarAtendimento`, JSON.stringify(licenciamento),
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }

  configurarSuspensao(ativo: string){
    return this.http.post<any>(
      `${ environment.icadUrl }/licenciamento/configurarSuspensao`, JSON.stringify(ativo),
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }

  buscarConfiguracaoSuspensao(){
    return this.http.get<any>(
      `${ environment.icadUrl }/licenciamento/buscarConfiguracaoSuspensao`,
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }

  buscarCadastroPorCodigo(codigo: string){
    return this.http.get<any>(
      `${ environment.icadUrl }/licenciamento/buscarCadastroPorCodigo/${ codigo }`,
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }
  
  salvarConfiguracoesLicenciamento(licenciamento: Licenciamento){
    return this.http.post<any>(
      `${ environment.icadUrl }/licenciamento/salvarConfiguracoesLicenciamento`, JSON.stringify(licenciamento),
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }
 
}






