import { Uso } from "./uso";

export class Atividade {
    cliente: string;
    inscricao: string;
    protocolo: string;
    codigo: string;
    nome: string;
    principal: boolean;
    tipo: string;
    ano: string;
    classe: string;
    servico: string;
    mei: boolean;
    evento: boolean;
    familia: string;
    codigoInterno: string;
    exercida: boolean;
    uso: Uso;
    usos: Array<Uso>;
    grauDeRisco: string;

    constructor() {
        this.cliente = "";
        this.inscricao = "";
        this.protocolo = "";
        this.codigo = "";
        this.nome = "";
        this.principal = false;
        this.tipo = "";
        this.ano = "";
        this.classe = "";
        this.servico = "";
        this.mei = false;
        this.evento = false;
        this.familia = "";
        this.codigoInterno = "";
        this.exercida = false;
        this.uso = new Uso();
        this.usos = new Array<Uso>();
        this.grauDeRisco = "";
    }
}