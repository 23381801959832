import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InformacaoRequerenteComponent } from "./informacao-requerente/informacao-requerente.component";
import { InformacaoLocalizacaoComponent } from "./informacao-localizacao/informacao-localizacao.component";
import { InformacaoAtividadeComponent } from "./informacao-atividade/informacao-atividade.component";
import { InformacaoIdentificacaoComponent } from "./informacao-identificacao/informacao-identificacao.component";
import { InformacaoQuestionarioComponent } from "./informacao-questionario/informacao-questionario.component";
import { InformacaoResponsavelComponent } from "./informacao-responsavel/informacao-responsavel.component";
import { InformacaoRequisitoComponent } from './informacao-requisito/informacao-requisito.component';
import { InformacaoFluxoComponent } from './informacao-fluxo/informacao-fluxo.component';
import { InformacaoDocumentoComponent } from './informacao-documento/informacao-documento.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxMaskModule } from "ngx-mask";
import { PipeModule } from "src/app/pipes/pipes.module";
import { InformacaoCadastroComponent } from "./informacao-cadastro.component";
import { InformacaoEncerramentoComponent } from './informacao-encerramento/informacao-encerramento.component';
import { InformacaoProtocolosComponent } from './informacao-protocolos/informacao-protocolos.component';
import { InformacaoRedeSimComponent } from './informacao-rede-sim/informacao-rede-sim.component';
import { InformacaoSituacaoComponent } from './informacao-situacao/informacao-situacao.component';
import { InformacaoSituacaoEmpresaComponent } from './informacao-situacao-empresa/informacao-situacao-empresa.component';
import { MatMenuModule } from "@angular/material/menu";
import { InformacaoRedeSimModule } from './informacao-rede-sim/informacao-rede-sim.module';

const components = [
    InformacaoRequerenteComponent,
    InformacaoLocalizacaoComponent,
    InformacaoAtividadeComponent,
    InformacaoIdentificacaoComponent,
    InformacaoQuestionarioComponent,
    InformacaoResponsavelComponent,
    InformacaoRequisitoComponent,
    InformacaoFluxoComponent,
    InformacaoDocumentoComponent,
    InformacaoCadastroComponent,
    InformacaoEncerramentoComponent,
    InformacaoProtocolosComponent,
    InformacaoSituacaoComponent,
    InformacaoSituacaoEmpresaComponent,
];

@NgModule({
    declarations: [
        components
    ],
    imports: [
        CommonModule,
        MatTooltipModule,
        MatMenuModule,
        NgxMaskModule.forRoot(),
        PipeModule
    ],
    exports: [
        components
    ],
    providers: [ 
        {
            provide: Document,
            useValue: document
        },
        {
            provide: Window,
            useValue: window
        }
    ]
})
export class InformacoesCadastroModule {}