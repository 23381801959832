import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemoriaDeCalculoComponent } from './memoria-de-calculo.component';
import { PipeModule } from 'src/app/pipes/pipes.module';
import { GridMemoriaDeCalculoModule } from '../grid-memoria-de-calculo/grid-memoria-de-calculo.module';
import { GridMemoriaDeCalculoParcelaModule } from '../grid-memoria-de-calculo-parcela/grid-memoria-de-calculo-parcela.module';


@NgModule({
  declarations: [
    MemoriaDeCalculoComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    GridMemoriaDeCalculoModule,
    GridMemoriaDeCalculoParcelaModule,
  ],
  exports: [
    MemoriaDeCalculoComponent
  ],
})
export class MemoriaDeCalculoModule {}
