import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, take } from 'rxjs/operators';
import { environment } from "../../../../../src/environments/environment"
import { GoogleMap, MapGeocoder } from '@angular/google-maps';
import { Endereco } from '../../../model/endereco';
import { Atividade } from '../../../model/atividade';
import { formatToCapitalized } from 'brazilian-values';
import { ConsultaViabilidadeService } from '../../../services/consultaViabilidade/consultaViabilidade.service';
import { ConsultaViabilidade } from '../../../model/consultaViabilidade';
import { Constantes } from '../../../model/constantes';
import * as moment from 'moment';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import "bootstrap";
import { IcadModalComponent } from '../../../components/modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Uso } from '../../../model/uso';
import Swal from 'sweetalert2';
import { MascaraPipe } from '../../../pipes/mascara.pipe';
import { ConsultaViabilidadeEndereco } from '../../../model/consultaViabilidadeEndereco';
import { ConsultaViabilidadeAtividade } from '../../../model/consultaViabilidadeAtividade';
import { ConsultaViabilidadeRequisito } from 'src/app/model/consultaViabilidadeRequisito';
import { Orgao } from '../../../model/orgao';
import { constants } from 'buffer';
import { Requisito } from 'src/app/model/requisito';
import { RequisitoService } from '../../../services/requisito/requisito.service';

@Component({
    selector: "app-google-maps",
    templateUrl: "./google-maps.component.html",
    styleUrls: ["./google-maps.component.scss"]
})
export class GoogleMapsComponent implements OnInit {

    exibirLocalizacao = false;
    exibirAtividades = false;

    jaInicializado: boolean = false;
    tipoDeNegocio: string = "";

    consultaViabilidade: ConsultaViabilidade;
    endereco: Endereco;
    consultaViabilidadeEndereco: ConsultaViabilidadeEndereco;
    enderecoLatLng: google.maps.LatLng;
    atividades: Array<Atividade>;
    consultaViabilidadeAtividades: Array<ConsultaViabilidadeAtividade>;
    
    apiLoaded: Observable<boolean>;

    tiposDeUsos = Constantes.TIPO_DE_USO;
    tiposDeEmpresa = Constantes.TIPO_EMPRESA;

    situacaoEmpresa: string;
    requisitosEmpresa: Array<ConsultaViabilidadeRequisito>;

    pontosInteresseGoogle: boolean = false;

    markerLocalizacao: Array<google.maps.Marker>;
    markerConcorrentes: Array<google.maps.Marker>;
    markerClusterConcorrentes: MarkerClusterer;
    markerServicosPublicos: Array<google.maps.Marker>;
    markerClusterServicosPublicos: MarkerClusterer;
    markerPossiveis: Array<google.maps.Marker>;
    markerClusterPossiveis: MarkerClusterer;
    markerPermissiveis: Array<google.maps.Marker>;
    markerClusterPermissiveis: MarkerClusterer;
    markerNaoPossiveis: Array<google.maps.Marker>;
    markerClusterNaoPossiveis: MarkerClusterer;

    circlePerimetro: google.maps.Circle;

    concorrentes: Array<ConsultaViabilidade>;
    servicosPublicos: Array<ConsultaViabilidade>;
    atividadesPossiveisPermissiveisNaoPossiveis: Array<ConsultaViabilidadeAtividade>;
    enderecosPossiveisPermissiveisNaoPossiveis: Array<ConsultaViabilidadeEndereco>;

    infoWindows: Array<google.maps.InfoWindow>;

    localizacaoSelecionada: ConsultaViabilidadeEndereco;
    atividadesSelecionadas: Array<ConsultaViabilidadeAtividade>;

    options: google.maps.MapOptions = {
        center: {lat: -12.1346651, lng: -63.4053246},
        zoom: 4,
        streetViewControl: false,
        styles: [
            {
                featureType: "poi",
                stylers: [
                    {
                        visibility: this.pontosInteresseGoogle ? "on" : "off"
                    }
                ]
            }
        ]
    };

    @ViewChild(GoogleMap)
    mapa: GoogleMap;

    constructor(private render: Renderer2,
        private httpClient: HttpClient,
        private geocoder: MapGeocoder,
        private consultaViabilidadeService: ConsultaViabilidadeService,
        private requisitoService: RequisitoService,
        public dialog: MatDialog,
        private mascara: MascaraPipe) {
        this.apiLoaded = this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${ environment.keyGoogleMaps }`, 'callback')
            .pipe(
            map(() => true),
                catchError(() => of(false)),
            );

        this.consultaViabilidade = new ConsultaViabilidade();
        this.markerLocalizacao = new Array<google.maps.Marker>();
        this.markerConcorrentes = new Array<google.maps.Marker>();
        this.markerServicosPublicos = new Array<google.maps.Marker>();

        this.atividadesSelecionadas = new Array<ConsultaViabilidadeAtividade>();
    }
        
    ngOnInit(): void { }

    formatarNomeAtividade(nome) {
        return formatToCapitalized(nome);
    }

    verificarPermissionamentoAtividades() {
        if (this.endereco == null || this.atividades == null) {
            return false;
        }

        let listaDeAtividades = "";
        this.atividades.forEach( item => {
            listaDeAtividades = listaDeAtividades.concat(item.codigo, ",");
        })

        this.consultaViabilidadeService
            .buscarPermissaoPorImobiliarioEAtividade(localStorage.getItem("pId"), this.endereco.inscricao, listaDeAtividades, this.tipoDeNegocio)
            .pipe(take(1))
            .subscribe( (dados: ConsultaViabilidade) => {
                dados.listaAtividades.forEach( atividadeEncontrada => {
                    let indice = this.atividades.findIndex( atividade => atividade.codigo == atividadeEncontrada.codigo);
                    if (indice != -1) {
                        this.atividades[indice].uso.status = atividadeEncontrada.uso.status;
                    }
                })
            },
            (err) => {
                console.log('err', err);
            }
        );
    }

    inicializarMapa() {
        if (!this.jaInicializado) {
            var cliente = JSON.parse(localStorage.getItem("cliente")).endereco;

            this.geocoder.geocode({
                address: `${ cliente.numero } ${ cliente.tipoLogradouro } ${ cliente.logradouro }, ${ cliente.cidade }, ${ cliente.estado }`
            }).subscribe(({results}) => {
                const latLng = results[0].geometry.location;
                this.mapa.googleMap.panTo(latLng);
                this.mapa.googleMap.setZoom(12);
            });
    
            this.criarBotoesInteracao();

            this.jaInicializado = true;
        }
        
        this.consultaViabilidadeEndereco = new ConsultaViabilidadeEndereco();
        this.consultaViabilidadeAtividades = Array<ConsultaViabilidadeAtividade>();
        this.infoWindows = new Array<google.maps.InfoWindow>();
        this.gerenciarBotoes();
    }

    acaoExibirLocalizacao() {
        if (!this.exibirLocalizacao && this.exibirAtividades) {
            return false;
        }

        if (this.endereco != null) {
            this.markerLocalizacao.forEach( markerLocalizacao => {
                markerLocalizacao.setVisible( !markerLocalizacao.getVisible() );
                if (this.circlePerimetro != null) {
                    if(!markerLocalizacao.getVisible()) {
                        (<HTMLSelectElement>document.getElementById("selecaoPerimetro")).value = "0";
                    }
                    this.acaoExibirPerimetro();
                }
                this.render.setProperty(document.getElementById("selecaoPerimetro"), "disabled", !markerLocalizacao.getVisible());
            }) 
            return false;
        }
        
        this.adicionarLocalizacaoNoMapa();
    }

    adicionarLocalizacaoNoMapa() {
        if (this.endereco != null) {
            this.geocoder.geocode({
                address: `${ this.endereco.numero } ${ this.endereco.tipoLogradouro } ${ this.endereco.logradouro }, ${ this.endereco.cidade }, ${ this.endereco.estado }`
            }).subscribe(({results}) => {
                this.enderecoLatLng = results[0].geometry.location;
                const marker = new google.maps.Marker({
                    position: this.enderecoLatLng,
                    map: this.mapa.googleMap,
                });
                this.mapa.googleMap.panTo(this.enderecoLatLng);
                this.mapa.googleMap.setZoom(12);
                
                const infos = this.janelaDeInformacoesLocalizacao(this.endereco);

                this.infoWindows.push(infos);

                // Adicionando informações ao marcador
                marker.addListener("click", () => {
                    this.infoWindows.forEach( infoWindow => {
                        infoWindow.close();
                    })
                    infos.open( this.mapa.googleMap, marker );
                    this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                });
                
                this.markerLocalizacao.push(marker);

                if (document.getElementById("selecaoPerimetro") != null) {
                    this.render.setProperty(document.getElementById("selecaoPerimetro"), "disabled", false);
                }

                this.preencher();
            });
        }

    }

    acaoExibirPerimetro(){
    
        if (this.endereco != null || this.localizacaoSelecionada != null) {

            let valorKm = (<HTMLSelectElement>document.getElementById("selecaoPerimetro")).value;
            
            if (parseInt(valorKm) != 0 && this.circlePerimetro == null) {
                this.adicionarPerimetroNoMapa(parseInt(valorKm));
            }

            if (this.circlePerimetro != null) {
                if (parseInt(valorKm) == 0) {
                    this.circlePerimetro.setVisible(false);
                }
                else {
                    this.circlePerimetro.setVisible(true);
                    this.circlePerimetro.setRadius(1000 * parseInt(valorKm));
                }
            }

            return false;
        }

    }

    adicionarPerimetroNoMapa(valorKm){
        
        if (this.endereco != null || this.localizacaoSelecionada != null) {
            
            this.circlePerimetro = new google.maps.Circle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                map: this.mapa.googleMap,
                center: this.enderecoLatLng,
                radius: 1000 * parseFloat(valorKm),
            });

        }
    }

    acaoExibirConcorrentes() {
        if (this.exibirLocalizacao && !this.exibirAtividades && !this.atividadesSelecionadas.length) {
            return false;
        }

        if (this.concorrentes != null) {
            if (!this.markerConcorrentes.length) {
                Swal.fire("Concorrentes", "Não foram encontrados locais para essa consulta.", "info");
                return false;
            }
            this.markerConcorrentes.forEach( markerConcorrente => {
                markerConcorrente.setVisible( !markerConcorrente.getVisible() );
            }) 

            // if (this.markerClusterConcorrentes == null) {
            //     this.markerClusterConcorrentes = new MarkerClusterer(this.mapa.googleMap, this.markerConcorrentes, {
            //         imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            //         ignoreHidden: true,
            //     });
            // }

            // this.markerClusterConcorrentes.getMarkers().forEach( markerClusterConcorrente => {
            //     markerClusterConcorrente.setVisible( !markerClusterConcorrente.getVisible() )
            // });

            // this.markerClusterConcorrentes.repaint();

            return false;
        }
        
        this.adicionarConcorrentesNoMapa();

    }

    adicionarConcorrentesNoMapa() {
        let listaDeAtividades = "";

        if (this.exibirAtividades) {
            this.atividades.forEach( item => {
                listaDeAtividades = listaDeAtividades.concat(item.codigo, ",");
            });
        }
        else {
            this.atividadesSelecionadas.forEach( item => {
                listaDeAtividades = listaDeAtividades.concat(item.codigo, ",");
            });
        }

        this.consultaViabilidadeService
            .buscarConcorrentesPorAtividade(localStorage.getItem("pId"), listaDeAtividades, this.tipoDeNegocio)
            .pipe(take(1))
            .subscribe( (dados: Array<ConsultaViabilidade>) => {
                this.concorrentes = dados;
                this.concorrentes.forEach( (concorrente, indice) => {
                    if (concorrente.latitude != "" && concorrente.longitude != "") {
                        const latLng = new google.maps.LatLng(parseFloat(concorrente.latitude), parseFloat(concorrente.longitude));

                        const image = "assets/img/google-pin/azul.png";
                        
                        // Criando o marcador
                        const marker = new google.maps.Marker({
                            position: latLng,
                            map: this.mapa.googleMap,
                            icon: image,
                            visible: true,
                            zIndex: 1
                        });

                        // this.mapa.googleMap.panTo(latLng);
                        // this.mapa.googleMap.setZoom(12);

                        const infos = this.janelaDeInformacoesParaConcorrentes(concorrente);
                        
                        this.infoWindows.push(infos);

                        // Adicionando informações ao marcador
                        marker.addListener("click", () => {
                            this.infoWindows.forEach( infoWindow => {
                                infoWindow.close();
                            })
                            infos.open( this.mapa.googleMap, marker );
                            this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                        });
                        this.markerConcorrentes.push(marker);
                    }
                    else {
                        // setTimeout(() => {
                        //     this.geocoder.geocode({
                        //         address: `${ concorrente.enderecos[0].numero } ${ concorrente.enderecos[0].tipoLogradouro } ${ concorrente.enderecos[0].logradouro }, ${ concorrente.enderecos[0].cidade }, ${ concorrente.enderecos[0].estado }`
                        //     })
                        //     .pipe(take(1))
                        //     .subscribe(({results, status}) => {
                        //         if (status == google.maps.GeocoderStatus.OK) {
                        //             if (results != null) {
                        //                 const latLng = results[0].geometry.location;
                        //                 const image = "assets/img/google-pin/concorrentes.png";
                                        
                        //                 // Criando o marcador
                        //                 const marker = new google.maps.Marker({
                        //                     position: latLng,
                        //                     map: this.mapa.googleMap,
                        //                     icon: image,
                        //                 });
            
                        //                 this.mapa.googleMap.panTo(latLng);
                        //                 this.mapa.googleMap.setZoom(12);
            
                        //                 const infos = this.janelaDeInformacoesParaEmpresa(concorrente);

                        //                 this.infoWindows.push(infos);
            
                        //                 // Adicionando informações ao marcador
                        //                 marker.addListener("click", () => {
                                                // this.infoWindows.forEach( infoWindow => {
                                                //     infoWindow.close();
                                                // })
                                                // infos.open( this.mapa.googleMap, marker );
                                                // this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                        //                 });
                        //                 this.markerConcorrentes.push(marker);
                        //             }
        
                        //         }
                        //         //console.log(`${ concorrente.enderecos[0].numero } ${ concorrente.enderecos[0].tipoLogradouro } ${ concorrente.enderecos[0].logradouro }, ${ concorrente.enderecos[0].cidade }, ${ concorrente.enderecos[0].estado }`, results, status)
                                
                        //     });
                        // }, 600 * indice);
                    }
                    
                });

                if (!this.concorrentes.length) {
                    this.concorrentes = null
                }
                
                // this.acaoExibirConcorrentes();
            },
            (err) => {
                console.log('err', err);
            }
        );
    }
    
    acaoExibirServicosPublicos() {
        if (this.servicosPublicos != null) {
            if (!this.markerServicosPublicos.length) {
                Swal.fire("Serviços Públicos", "Não foram encontrados locais para essa consulta.", "info");
                return false;
            }
            this.markerServicosPublicos.forEach( markerServicoPublico => {
                markerServicoPublico.setVisible( !markerServicoPublico.getVisible() );
            }) 

            // if (this.markerClusterServicosPublicos == null) {
            //     this.markerClusterServicosPublicos = new MarkerClusterer(this.mapa.googleMap, this.markerServicosPublicos, {
            //         imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            //         ignoreHidden: true,
            //     });
            // }

            // this.markerClusterServicosPublicos.getMarkers().forEach( markerClusterServicoPublico => {
            //     markerClusterServicoPublico.setVisible( !markerClusterServicoPublico.getVisible() )
            // });

            // this.markerClusterServicosPublicos.repaint();

            return false;
        }

        this.adicionarServicosPublicosNoMapa();
    }

    adicionarServicosPublicosNoMapa() {    
        this.consultaViabilidadeService
            .buscarServicosPublicos(localStorage.getItem("pId"))
            .pipe(take(1))
            .subscribe( (dados: Array<ConsultaViabilidade>) => {
                this.servicosPublicos = dados;
                dados.forEach( (servicoPublico, indice) => {
                    if (servicoPublico.latitude != "" && servicoPublico.longitude != "") {
                        const latLng = new google.maps.LatLng(parseFloat(servicoPublico.latitude), parseFloat(servicoPublico.longitude));
                        var image = "";
                        if(servicoPublico.entidade == 'ESCOLAS PARTICULARES'){
                            image = "assets/img/google-pin/escola.png";
                        }
                        else if(servicoPublico.entidade == 'HOSPITAIS PARTICULARES'){
                            image = "assets/img/google-pin/hospital.png";
                        }
                        else if(servicoPublico.entidade == 'POSTOS DE SAÚDE'){
                            image = "assets/img/google-pin/posto-saude.png";
                        }
                        else {
                            image = "assets/img/google-pin/servico-publico.png";
                        }

                        const marker = new google.maps.Marker({
                            position: latLng,
                            icon: image,
                            map: this.mapa.googleMap,
                            title: servicoPublico.razaoSocial,
                            visible: true,
                            zIndex: 1
                        });

                        const infos = this.janelaDeInformacoesParaServicosPublicos(servicoPublico);

                        this.infoWindows.push(infos);

                        // Adicionando informações ao marcador
                        marker.addListener("click", () => {
                            this.infoWindows.forEach( infoWindow => {
                                infoWindow.close();
                            })
                            infos.open( this.mapa.googleMap, marker );
                            this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                        });

                        this.markerServicosPublicos.push(marker);
                    }
                    else {
                        setTimeout(() => {                        
                            this.geocoder.geocode({
                                address: `${ servicoPublico.enderecos[0].numero } ${ servicoPublico.enderecos[0].tipoLogradouro } ${ servicoPublico.enderecos[0].logradouro }, ${ servicoPublico.enderecos[0].cidade }, ${ servicoPublico.enderecos[0].estado }`
                            }).subscribe(({results}) => {
                                if (results != null) {
                                    const latLng = results[0].geometry.location;
                                    
                                    var image = "";
                                    if(servicoPublico.entidade == 'ESCOLAS PARTICULARES'){
                                        image = "assets/img/google-pin/escola.png";
                                    }
                                    else if(servicoPublico.entidade == 'HOSPITAIS PARTICULARES'){
                                        image = "assets/img/google-pin/hospital.png";
                                    }
                                    else if(servicoPublico.entidade == 'POSTOS DE SAÚDE'){
                                        image = "assets/img/google-pin/posto-saude.png";
                                    }
                                    else {
                                        image = "assets/img/google-pin/servico-publico.png";
                                    }
        
                                    const marker = new google.maps.Marker({
                                        position: latLng,
                                        icon: image,
                                        title: servicoPublico.razaoSocial,
                                        visible: false,
                                    });
                                    // this.mapa.googleMap.panTo(latLng);
                                    // this.mapa.googleMap.setZoom(12);
        
                                    const infos = this.janelaDeInformacoesParaServicosPublicos(servicoPublico);
                                    
                                    this.infoWindows.push(infos);
                                    
                                    // Adicionando informações ao marcador
                                    marker.addListener("click", () => {
                                        this.infoWindows.forEach( infoWindow => {
                                            infoWindow.close();
                                        })
                                        infos.open( this.mapa.googleMap, marker );
                                        this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                                    });
        
                                    this.markerServicosPublicos.push(marker);
        
                                    if ((this.servicosPublicos.length - 1) == indice) {
                                        new MarkerClusterer(this.mapa.googleMap, this.markerServicosPublicos, {
                                            imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                                        });
                                    }
                                }
                            });
                        }, 600 * indice);
                    }
                    
                });

                if (!this.servicosPublicos.length) {
                    this.servicosPublicos = null
                }
            },
            (err) => {
                console.log('err', err);
            }
        );
    }

    carregarPossivelPermissivelNaoPossivelThread() {
        if (this.exibirLocalizacao && !this.exibirAtividades && this.endereco != null) {
            this.consultaViabilidadeService
                .buscarPermissaoPorImobiliarioThread(localStorage.getItem("pId"), this.endereco.inscricao, this.tipoDeNegocio)
                .pipe(take(1))
                .subscribe( (retorno) => {
                    if (retorno.statusThread == null) {
                        this.atividadesPossiveisPermissiveisNaoPossiveis = retorno.listaAtividades;
                    }
                },
                (err) => {
                    console.log('err', err);
                }
            );
        }
        else if (!this.exibirLocalizacao && this.exibirAtividades && this.atividades != null) {
            let listaDeAtividades = "";
            this.atividades.forEach( item => {
                listaDeAtividades = listaDeAtividades.concat(item.codigo, ",");
            })
            
            this.consultaViabilidadeService
                .buscarPermissaoPorAtividadesThread(localStorage.getItem("pId"), listaDeAtividades, this.tipoDeNegocio)
                .pipe(take(1))
                .subscribe( (retorno) => {
                    if (retorno.statusThread == null) {
                        this.enderecosPossiveisPermissiveisNaoPossiveis = retorno.enderecos;
                    }
                },
                (err) => {
                    console.log('err', err);
                }
            );
        }
    }

    carregarPossivelPermissivelNaoPossivel(tipoDeUso) {
        if (this.endereco != null) {
            if (this.exibirLocalizacao && !this.exibirAtividades && this.atividadesPossiveisPermissiveisNaoPossiveis == null) {
                this.consultaViabilidadeService
                    .buscarPermissaoPorImobiliario(localStorage.getItem("pId"), this.endereco.inscricao, this.tipoDeNegocio)
                    .pipe(take(1))
                    .subscribe( (retorno) => {
                        if (retorno.statusThread == null) {
                            this.atividadesPossiveisPermissiveisNaoPossiveis = retorno.listaAtividades;
                            this.abrirModalPossivelPermissivelNaoPossivel(tipoDeUso);
                        }
                    },
                    (err) => {
                        console.log('err', err);
                    }
                );
            }
            else if (!this.exibirLocalizacao && this.exibirAtividades) {

            }
        }

        if (this.atividadesPossiveisPermissiveisNaoPossiveis == null) {
            return false;
        }

        return true;
    }

    acaoExibirNaoPossivel() {
        if (this.exibirLocalizacao && !this.exibirAtividades) {
            if (this.carregarPossivelPermissivelNaoPossivel(Constantes.TIPO_DE_USO.Nao_Permitido)) {
                this.abrirModalPossivelPermissivelNaoPossivel(Constantes.TIPO_DE_USO.Nao_Permitido);
            }
        }  
        else if(!this.exibirLocalizacao && this.exibirAtividades){
            if (this.enderecosPossiveisPermissiveisNaoPossiveis != null && this.markerNaoPossiveis != null) {
                if (!this.markerNaoPossiveis.length) {
                    Swal.fire("Locais Não possiveis", "Não foram encontrados locais para essa consulta.", "info");
                    return false;
                }
                this.markerNaoPossiveis.forEach( markerNaoPossivel => {
                    markerNaoPossivel.setVisible( !markerNaoPossivel.getVisible() );
                }) 
                
                // if (this.markerClusterNaoPossiveis == null) {
                //     this.markerClusterNaoPossiveis = new MarkerClusterer(this.mapa.googleMap, this.markerNaoPossiveis, {
                //         imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                //         ignoreHidden: true,
                //     });
                // }
    
                // this.markerClusterNaoPossiveis.getMarkers().forEach( markerClusterConcorrente => {
                //     markerClusterConcorrente.setVisible( !markerClusterConcorrente.getVisible() )
                // });
    
                // this.markerClusterNaoPossiveis.repaint();
    
                return false;
            }

            this.adicionarPossivelPermissivelNaoPossivelNoMapa(Constantes.TIPO_DE_USO.Nao_Permitido);
        }
    }

    acaoExibirPermissivel() {
        if (this.exibirLocalizacao && !this.exibirAtividades) {
            if (this.carregarPossivelPermissivelNaoPossivel(Constantes.TIPO_DE_USO.Permissivel)) {
                this.abrirModalPossivelPermissivelNaoPossivel(Constantes.TIPO_DE_USO.Permissivel);
            }
        }  
        else if (!this.exibirLocalizacao && this.exibirAtividades){
            if (this.enderecosPossiveisPermissiveisNaoPossiveis != null && this.markerNaoPossiveis != null) {
                if (!this.markerPermissiveis.length) {
                    Swal.fire("Locais Permissiveis", "Não foram encontrados locais para essa consulta.", "info");
                    return false;
                }

                this.markerPermissiveis.forEach( markerPermissivel => {
                    markerPermissivel.setVisible( !markerPermissivel.getVisible() );
                }) 

                // if (this.markerClusterPermissiveis == null) {
                //     this.markerClusterPermissiveis = new MarkerClusterer(this.mapa.googleMap, this.markerPermissiveis, {
                //         imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                //         ignoreHidden: true,
                //     });
                // }
    
                // this.markerClusterPermissiveis.getMarkers().forEach( markerClusterConcorrente => {
                //     markerClusterConcorrente.setVisible( !markerClusterConcorrente.getVisible() )
                // });
    
                // this.markerClusterPermissiveis.repaint();
    
                return false;
            }
            
            this.adicionarPossivelPermissivelNaoPossivelNoMapa(Constantes.TIPO_DE_USO.Permissivel);
        }
    }

    acaoExibirPossivel() {
        if (this.exibirLocalizacao && !this.exibirAtividades) {
            if (this.carregarPossivelPermissivelNaoPossivel(Constantes.TIPO_DE_USO.Permitido)) {
                this.abrirModalPossivelPermissivelNaoPossivel(Constantes.TIPO_DE_USO.Permitido);
            }
        }  
        else if (!this.exibirLocalizacao && this.exibirAtividades){
            if (this.enderecosPossiveisPermissiveisNaoPossiveis != null && this.markerNaoPossiveis != null) {
                if (!this.markerPossiveis.length) {
                    Swal.fire("Locais Possiveis", "Não foram encontrados locais para essa consulta.", "info");
                    return false;
                }
                
                this.markerPossiveis.forEach( markerPossivel => {
                    markerPossivel.setVisible( !markerPossivel.getVisible() );
                }) 

                // if (this.markerClusterPossiveis == null) {
                //     this.markerClusterPossiveis = new MarkerClusterer(this.mapa.googleMap, this.markerPossiveis, {
                //         imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                //         ignoreHidden: true,
                //     });
                // }
    
                // this.markerClusterPossiveis.getMarkers().forEach( markerClusterConcorrente => {
                //     markerClusterConcorrente.setVisible( !markerClusterConcorrente.getVisible() )
                // });
    
                // this.markerClusterPossiveis.repaint();
    
                return false;
            }
            
            this.adicionarPossivelPermissivelNaoPossivelNoMapa(Constantes.TIPO_DE_USO.Permitido);
        }

    }    

    adicionarPossivelPermissivelNaoPossivelNoMapa(tipoDeUso) {
        this.markerPossiveis = new Array<google.maps.Marker>();
        this.markerPermissiveis = new Array<google.maps.Marker>();
        this.markerNaoPossiveis = new Array<google.maps.Marker>();

        let listaDeAtividades = "";
        this.atividades.forEach( item => {
            listaDeAtividades = listaDeAtividades.concat(item.codigo, ",");
        })
        
        this.consultaViabilidadeService
            .buscarPermissaoPorAtividades(localStorage.getItem("pId"), listaDeAtividades, this.tipoDeNegocio)
            .pipe(take(1))
            .subscribe( (retorno) => {
                if (retorno.statusThread == null) {
                    this.enderecosPossiveisPermissiveisNaoPossiveis = retorno.enderecos;
                    this.enderecosPossiveisPermissiveisNaoPossiveis.forEach( (endereco, indice) => {                        
                        if (endereco.latitude != "" && endereco.longitude != "") {
                            const latLng = new google.maps.LatLng(parseFloat(endereco.latitude), parseFloat(endereco.longitude));

                            if (endereco.uso.status == Constantes.TIPO_DE_USO.Nao_Permitido) {
                                var image = "assets/img/google-pin/vermelho.png";
                                var zIndex = 2;
                            }
                            else if (endereco.uso.status == Constantes.TIPO_DE_USO.Permissivel) {
                                var image = "assets/img/google-pin/amarelo.png";
                                var zIndex = 3;
                            }
                            else if (endereco.uso.status == Constantes.TIPO_DE_USO.Permitido) {
                                var image = "assets/img/google-pin/verde.png";
                                var zIndex = 4;
                            }
    
                            const marker = new google.maps.Marker({
                                position: latLng,
                                map: this.mapa.googleMap,
                                icon: image,
                                title: endereco.logradouro,
                                visible: false,
                                zIndex: zIndex
                            });
    
                            const infos = this.janelaDeInformacoesParaPossivelPermissivelNaoPossivel(endereco, indice);
                            
                            this.infoWindows.push(infos);
                            
                            // Adicionando informações ao marcador
                            marker.addListener("click", () => {
                                this.infoWindows.forEach( infoWindow => {
                                    infoWindow.close();
                                })
                                infos.open( this.mapa.googleMap, marker );
                                this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                            });
    
                            if (endereco.uso.status == Constantes.TIPO_DE_USO.Nao_Permitido) {
                                this.markerNaoPossiveis.push(marker);
                            }
                            else if (endereco.uso.status == Constantes.TIPO_DE_USO.Permissivel) {
                                this.markerPermissiveis.push(marker);
                            }
                            else if (endereco.uso.status == Constantes.TIPO_DE_USO.Permitido) {
                                this.markerPossiveis.push(marker);
                            }
                        }
                        // else {
                        //     setTimeout(() => {                        
                        //         this.geocoder.geocode({
                        //             address: `${ endereco.numero } ${ endereco.tipoLogradouro } ${ endereco.logradouro }, ${ endereco.cidade }, ${ endereco.estado }`
                        //         }).subscribe(({results}) => {
                        //             if (results != null) {
                        //                 const latLng = results[0].geometry.location;
                                        
                        //                 if (endereco.uso.status == Constantes.TIPO_DE_USO.Nao_Permitido) {
                        //                     // var image = "assets/img/google-pin/servico-publico.png";
                        //                 }
                        //                 else if (endereco.uso.status == Constantes.TIPO_DE_USO.Permissivel) {
                        //                     // var image = "assets/img/google-pin/servico-publico.png";
                        //                 }
                        //                 else if (endereco.uso.status == Constantes.TIPO_DE_USO.Permitido) {
                        //                     // var image = "assets/img/google-pin/servico-publico.png";
                        //                 }
                
                        //                 const marker = new google.maps.Marker({
                        //                     position: latLng,
                        //                     map: this.mapa.googleMap,
                        //                     // icon: image,
                        //                     title: endereco.logradouro,
                        //                     visible: endereco.uso.status == tipoDeUso,
                        //                 });
                
                        //                 const infos = this.janelaDeInformacoesParaPossivelPermissivelNaoPossivel(endereco);

                        //                 this.infoWindows.push(infos);
                        
                        //                 // Adicionando informações ao marcador
                        //                 marker.addListener("click", () => {
                                                // this.infoWindows.forEach( infoWindow => {
                                                //     infoWindow.close();
                                                // })
                                                // infos.open( this.mapa.googleMap, marker );
                                                // this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                        //                 });
                
                        //                 if (endereco.uso.status == Constantes.TIPO_DE_USO.Nao_Permitido) {
                        //                     this.markerNaoPossiveis.push(marker);
                        //                 }
                        //                 else if (endereco.uso.status == Constantes.TIPO_DE_USO.Permissivel) {
                        //                     this.markerPermissiveis.push(marker);
                        //                 }
                        //                 else if (endereco.uso.status == Constantes.TIPO_DE_USO.Permitido) {
                        //                     this.markerPossiveis.push(marker);
                        //                 }
                                        
                        //             }
                        //         });
                        //     }, 1);
                        // }
                        
                    });

                }

                if (tipoDeUso == Constantes.TIPO_DE_USO.Nao_Permitido) {
                    this.acaoExibirNaoPossivel();
                }
                if (tipoDeUso == Constantes.TIPO_DE_USO.Permissivel) {
                    this.acaoExibirPermissivel();
                }
                if (tipoDeUso == Constantes.TIPO_DE_USO.Permitido) {
                    this.acaoExibirPossivel();
                }
            },
            (err) => {
                console.log('err', err);
            }
        );

    }

    abrirModalPossivelPermissivelNaoPossivel(tipoDeUso) {
        var dados = [];
        var tipoDeNegocioEscolhido;

        if (tipoDeUso == Constantes.TIPO_DE_USO.Nao_Permitido) {
            dados.push(
                this.atividadesPossiveisPermissiveisNaoPossiveis.filter(atividade => {
                    return atividade.uso.status == Constantes.TIPO_DE_USO.Nao_Permitido
                })
            );

            tipoDeNegocioEscolhido = this.tipoDeNegocio;
        }
        else if (tipoDeUso == Constantes.TIPO_DE_USO.Permissivel) {
            dados.push(
                this.atividadesPossiveisPermissiveisNaoPossiveis.filter(atividade => {
                    return atividade.uso.status == Constantes.TIPO_DE_USO.Permissivel
                })
            );

            tipoDeNegocioEscolhido = this.tipoDeNegocio;
        }
        else if (tipoDeUso == Constantes.TIPO_DE_USO.Permitido) {
            dados.push(
                this.atividadesPossiveisPermissiveisNaoPossiveis.filter(atividade => {
                    return atividade.uso.status == Constantes.TIPO_DE_USO.Permitido
                }),
            );
            tipoDeNegocioEscolhido = this.tipoDeNegocio;
        }
        
        dados.push(this.atividadesSelecionadas);
        dados.push(tipoDeNegocioEscolhido);
        dados.push(
            this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo || 
            this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Baixa_Autonomo || 
            this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo_Evento ? 1 : 6 
        );

        this.openDialog(dados);
    }

    acaoExibirPontosInteresseGoogle() {

        this.pontosInteresseGoogle = !this.pontosInteresseGoogle

        this.mapa.googleMap.setOptions({
            styles: [
                {
                    featureType: "poi",
                    stylers: [
                        {
                            visibility: this.pontosInteresseGoogle ? "on" : "off"
                        }
                    ]
                }
            ]
        });
    }

    acaoExibirCompartilhar() {
        this.printAnyMaps();
        // this.htmlParaImagem();
    }

    openDialog(dados) {
        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                titulo: "Lista de Permissões para o Imóvel",
                componente: "grid-atividade",
                dados: dados
            }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result === "" || result === null)
                return false;

            
            this.atividadesSelecionadas = result.atividades.selected;
            this.preencher();
            this.gerenciarBotoes();
            this.limparConcorrentes();
        });
    }

    acaoEscolherLocalizacao(endereco: ConsultaViabilidadeEndereco) {
        
        Swal.fire("Localização escolhida", `${ endereco.tipoLogradouro } ${ endereco.logradouro } , ${ endereco.numero } -  ${ endereco.bairro } - ${ endereco.cidade } - ${ endereco.estado } - CEP ${ endereco.cep }`, "success")
        .then( result => {
            if (result) {
                this.limparLocalizacao();
                this.limparPerimetro();
        
                this.localizacaoSelecionada =  endereco;
        
                this.atividades.forEach( atividade => {
                    var atividadeAuxiliar = endereco.atividades.filter( item => { return item.codigo == atividade.codigo });
        
                    var uso = new Uso();
                    uso.codigo = atividadeAuxiliar[0].uso.codigo;
                    uso.status = atividadeAuxiliar[0].uso.status;
        
                    atividade.uso = uso;
                });
                this.enderecoLatLng = new google.maps.LatLng(parseFloat(endereco.latitude), parseFloat(endereco.longitude));
                this.mapa.googleMap.setCenter(this.enderecoLatLng);
                this.mapa.googleMap.setZoom(15);
                
                this.render.setProperty(document.getElementById("selecaoPerimetro"), "disabled", false);
                (<HTMLSelectElement>document.getElementById("selecaoPerimetro")).value = "1";
                this.adicionarPerimetroNoMapa(1);

                this.preencher();

            }
        });
    }

    acaoExcluirLocalizacaoEscolhida() {
        this.atividades.forEach( atividade => {
            atividade.uso = new Uso();
        });
        
        this.limparLocalizacao();
        this.limparPerimetro();
    }

    acaoExcluirAtividadeEscolhida(index) {
        this.atividadesSelecionadas.splice(index, 1);
        this.gerenciarBotoes();
        this.limparConcorrentes();
    }

    janelaDeInformacoesLocalizacao(endereco){
        // Criando as informações para exibir no marcador
        return new google.maps.InfoWindow({
            content: `
            <html>
                <head></head>
                <body>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <strong>Endereço:&nbsp;</strong> ${ endereco.tipoLogradouro } ${ endereco.logradouro } ${ endereco.numero }, ${ endereco.cidade } - ${ endereco.estado }
                    </div>
                </body>
            </html>`
        });
    }
    
    janelaDeInformacoesParaConcorrentes(consultaViabilidade: ConsultaViabilidade) {
        var listaDeAtividades = '';

        if (this.atividades != null) {
            this.atividades.forEach( atividade => {
                if (consultaViabilidade.atividades.search(atividade.codigo) != -1) {
                    listaDeAtividades = listaDeAtividades + "<li>" + 
                        (this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo || this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo_Evento ? this.mascara.transform(atividade.codigo.toString(), 12) : this.mascara.transform(atividade.codigo.toString(), 10)) + 
                        " - " + (atividade.nome.length > 60 ? this.formatarNomeAtividade(atividade.nome.substring(0, 60) + "...") : this.formatarNomeAtividade(atividade.nome)) + "</li>"
                }
            });
        }

        if (this.atividadesSelecionadas != null) {
            this.atividadesSelecionadas.forEach( atividade => {
                if (consultaViabilidade.atividades.search(atividade.codigo) != -1) {
                    listaDeAtividades = listaDeAtividades + "<li>" + 
                        (this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo || this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo_Evento ? this.mascara.transform(atividade.codigo.toString(), 12) : this.mascara.transform(atividade.codigo.toString(), 10)) + 
                        " - " + (atividade.nome.length > 60 ? this.formatarNomeAtividade(atividade.nome.substring(0, 60) + "...") : this.formatarNomeAtividade(atividade.nome)) + "</li>"
                }
            });
        }

        var inicio = moment(new Date(consultaViabilidade.ldts));
        var agora = moment(new Date());
        var diferenca = moment.duration({
            years: agora.year() - inicio.year(),
            months: agora.month() - inicio.month(),
            days: agora.date() - inicio.date()
        });

        // Criando as informações para exibir no marcador
        return new google.maps.InfoWindow({
            content: `
            <html>
                <head></head>
                <body>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Razão Social: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.razaoSocial }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Endereço: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.enderecos[0].tipoLogradouro } ${ consultaViabilidade.enderecos[0].logradouro } , ${ consultaViabilidade.enderecos[0].numero } -  ${ consultaViabilidade.enderecos[0].bairro } - ${ consultaViabilidade.enderecos[0].cidade } - ${ consultaViabilidade.enderecos[0].estado } - CEP ${ consultaViabilidade.enderecos[0].cep }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">&nbsp;</div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-12">
                            <strong>Tempo de Vida do Negócio no Local:&nbsp;</strong> ${ diferenca.years() } anos, ${ diferenca.months() } meses e ${ diferenca.days() } dias
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-12">
                            <strong>Atividades: </strong>
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-12">
                            <ul>
                                ${ listaDeAtividades }
                            </ul>
                        </div>
                    </div>
                </body>
            </html>`
        });
    }

    janelaDeInformacoesParaServicosPublicos(consultaViabilidade: ConsultaViabilidade) {
        // Criando as informações para exibir no marcador
        return new google.maps.InfoWindow({
            content: `
            <html>
                <head></head>
                <body>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Grupo: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.entidade }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Tipo: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.tipoServico }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Razão Social: </strong>
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.razaoSocial }
                        </div>
                    </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Endereço: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.enderecos[0].tipoLogradouro } ${ consultaViabilidade.enderecos[0].logradouro } ${ consultaViabilidade.enderecos[0].numero } , ${ consultaViabilidade.enderecos[0].bairro } - ${ consultaViabilidade.enderecos[0].cidade } - ${ consultaViabilidade.enderecos[0].estado } - CEP ${ consultaViabilidade.enderecos[0].cep }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Telefone: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.telefones[0].numero }
                        </div>
                    </div>
                </body>
            </html>`
        });
    }

    janelaDeInformacoesParaPossivelPermissivelNaoPossivel(endereco, index){
        // Criando as informações para exibir no marcador
        var listaDeAtividades = '';
        endereco.atividades.forEach( atividade => {
            listaDeAtividades += `
                <div class="row col-12 justify-content-start align-items-center">
                    <div class="row col-1">
                        <svg class="icone-pesquisa-viabilidade ${ atividade.uso.status == Constantes.TIPO_DE_USO.Permitido ? "texto-success" : atividade.uso.status == Constantes.TIPO_DE_USO.Permissivel ? "texto-warning" : "texto-danger" }">
                            <use
                                xlink:href="#${ atividade.uso.status == Constantes.TIPO_DE_USO.Permitido ? "thumbs-up" : atividade.uso.status == Constantes.TIPO_DE_USO.Permissivel ? "alert-triangle" : "thumbs-down" }"
                            ></use>
                        </svg>
                    </div>
                    <div class="row col-11">
                        ${ (this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo || this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo_Evento ? this.mascara.transform(atividade.codigo.toString(), 12) : this.mascara.transform(atividade.codigo.toString(), 10)) }
                        - ${ (atividade.nome.length > 55 ? this.formatarNomeAtividade(atividade.nome.substring(0, 55) + "...") : this.formatarNomeAtividade(atividade.nome)) }
                    </div>
                </div>
                `
        });

        var htmlJanela = new google.maps.InfoWindow({
            content: `
            <html>
                <head></head>
                <body>
                
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-2">
                            <strong>Endereço: </strong> 
                        </div>
                        <div class="col-10">
                            ${ endereco.tipoLogradouro } ${ endereco.logradouro } , ${ endereco.numero } -  ${ endereco.bairro } - ${ endereco.cidade } - ${ endereco.estado } - CEP ${ endereco.cep }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">&nbsp;</div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-12">
                            <strong>Atividades: </strong>
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps row-linha">
                        ${ listaDeAtividades }
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">&nbsp;</div>
                    <div class="row col-12 row-linha-janela-titulo-maps text-center">
                        <button
                            class="btn btn-block btn-success"
                            title="Escolher Localização"
                            type="button"
                            id="btnEscolherEndereco_${ index }"
                        >
                            Escolher Localização
                        </button>
                    </div>
                </body>
            </html>`
        });

        // google.maps.event.addListener(htmlJanela, 'domready', function() {
        //     document.getElementById("btnEscolherEndereco_" + index).addEventListener("click", () => {
        //         // htmlJanela.
        //         this.acaoEscolherLocalizacao(endereco);
        //         htmlJanela.close();
        //     });
        // });
        
        htmlJanela.addListener('domready', () => {
            document.getElementById("btnEscolherEndereco_" + index).addEventListener("click", () => {
                this.acaoEscolherLocalizacao(endereco);
                htmlJanela.close();
            });
        });

        return htmlJanela;
    }

    janelaDeInformacoesComplementaresParaLocalizacao() {
        if ( this.endereco != null ||  this.localizacaoSelecionada != null) {
            this.consultaViabilidadeEndereco = new ConsultaViabilidadeEndereco();
            this.consultaViabilidadeEndereco.cliente            = this.endereco != null ? this.endereco.cliente : this.localizacaoSelecionada.cliente;
            this.consultaViabilidadeEndereco.codigo             = this.endereco != null ? this.endereco.codigo : this.localizacaoSelecionada.codigo;
            this.consultaViabilidadeEndereco.protocolo          = this.endereco != null ? this.endereco.protocolo : this.localizacaoSelecionada.protocolo;
            this.consultaViabilidadeEndereco.inscricao          = this.endereco != null ? this.endereco.inscricao : this.localizacaoSelecionada.inscricao;
            this.consultaViabilidadeEndereco.inscricaoFormatada = this.endereco != null ? this.endereco.inscricaoFormatada : this.localizacaoSelecionada.inscricaoFormatada;
            this.consultaViabilidadeEndereco.cep                = this.endereco != null ? this.endereco.cep : this.localizacaoSelecionada.cep;
            this.consultaViabilidadeEndereco.tipoLogradouro     = this.endereco != null ? this.endereco.tipoLogradouro : this.localizacaoSelecionada.tipoLogradouro;
            this.consultaViabilidadeEndereco.tituloLogradouro   = this.endereco != null ? this.endereco.tituloLogradouro : this.localizacaoSelecionada.tituloLogradouro;
            this.consultaViabilidadeEndereco.codigoLogradouro   = this.endereco != null ? this.endereco.codigoLogradouro : this.localizacaoSelecionada.codigoLogradouro;
            this.consultaViabilidadeEndereco.logradouro         = this.endereco != null ? this.endereco.logradouro : this.localizacaoSelecionada.logradouro;
            this.consultaViabilidadeEndereco.numero             = this.endereco != null ? this.endereco.numero : this.localizacaoSelecionada.numero;
            this.consultaViabilidadeEndereco.complemento        = this.endereco != null ? this.endereco.complemento : this.localizacaoSelecionada.complemento;
            this.consultaViabilidadeEndereco.codigoBairro       = this.endereco != null ? this.endereco.codigoBairro : this.localizacaoSelecionada.codigoBairro;
            this.consultaViabilidadeEndereco.bairro             = this.endereco != null ? this.endereco.bairro : this.localizacaoSelecionada.bairro;
            this.consultaViabilidadeEndereco.cidade             = this.endereco != null ? this.endereco.cidade : this.localizacaoSelecionada.cidade;
            this.consultaViabilidadeEndereco.estado             = this.endereco != null ? this.endereco.estado : this.localizacaoSelecionada.estado;
            this.consultaViabilidadeEndereco.pais               = this.endereco != null ? this.endereco.pais : this.localizacaoSelecionada.pais;
            this.consultaViabilidadeEndereco.pontoReferencia    = this.endereco != null ? this.endereco.pontoReferencia : this.localizacaoSelecionada.pontoReferencia;
            this.consultaViabilidadeEndereco.latitude           = this.endereco != null ? this.endereco.latitude : this.localizacaoSelecionada.latitude;
            this.consultaViabilidadeEndereco.longitude          = this.endereco != null ? this.endereco.longitude : this.localizacaoSelecionada.longitude;
            this.consultaViabilidadeEndereco.area               = this.endereco != null ? this.endereco.area : this.localizacaoSelecionada.area;
            this.consultaViabilidadeEndereco.tipoEndereco       = this.endereco != null ? this.endereco.tipoEndereco : this.localizacaoSelecionada.tipoEndereco;
            this.consultaViabilidadeEndereco.tipoBusca          = this.endereco != null ? this.endereco.tipoBusca : this.localizacaoSelecionada.tipoBusca;
            this.consultaViabilidadeEndereco.baseUtilizada      = this.endereco != null ? this.endereco.baseUtilizada : this.localizacaoSelecionada.baseUtilizada;
            this.consultaViabilidadeEndereco.tipoNegocio        = "Médio Porte"
            this.consultaViabilidadeEndereco.vidaNegocio        = "25 anos"
            this.consultaViabilidadeEndereco.areaUtilizada      = "69,07 m²"
            this.consultaViabilidadeEndereco.qtdeFuncionarios   = "32"
        }
    }

    janelaDeInformacoesComplementaresParaAtividades() {
        this.consultaViabilidadeAtividades = new Array<ConsultaViabilidadeAtividade>();
        
        if (this.atividades != null) {            
            this.atividades.forEach( atividade => {
                
                var consultaViabilidadeAtividade = new ConsultaViabilidadeAtividade();
                consultaViabilidadeAtividade.codigo             = atividade.codigo;
                consultaViabilidadeAtividade.usos               = atividade.usos;
                consultaViabilidadeAtividade.endereco.cidade    = "BETIM - " + atividade.codigo;
                consultaViabilidadeAtividade.tipoNegocio        = "Médio Porte";
                consultaViabilidadeAtividade.vidaNegocio        = "25 anos";
                consultaViabilidadeAtividade.areaUtilizada      = "69,07 m²";
                consultaViabilidadeAtividade.qtdeFuncionarios   = "32";

                this.consultaViabilidadeAtividades.push(consultaViabilidadeAtividade);
                
            })
        }
        else if (this.atividadesSelecionadas != null) {
            this.atividadesSelecionadas.forEach( atividade => {
                var consultaViabilidadeAtividade = new ConsultaViabilidadeAtividade();
                consultaViabilidadeAtividade.codigo             = atividade.codigo;
                consultaViabilidadeAtividade.usos               = atividade.usos;
                consultaViabilidadeAtividade.endereco.cidade    = "BETIM - " + atividade.codigo;
                consultaViabilidadeAtividade.tipoNegocio        = "Médio Porte";
                consultaViabilidadeAtividade.vidaNegocio        = "25 anos";
                consultaViabilidadeAtividade.areaUtilizada      = "69,07 m²";
                consultaViabilidadeAtividade.qtdeFuncionarios   = "32";

                this.consultaViabilidadeAtividades.push(consultaViabilidadeAtividade);
            })
        }
    }

    preencher() {
        this.janelaDeInformacoesComplementaresParaLocalizacao();
        this.janelaDeInformacoesComplementaresParaAtividades();

        this.consultaViabilidade = new ConsultaViabilidade();
        this.consultaViabilidade.cliente = localStorage.getItem("pId");
        this.consultaViabilidade.enderecos.push(this.consultaViabilidadeEndereco);
        this.consultaViabilidade.listaAtividades = this.consultaViabilidadeAtividades;
        this.consultaViabilidade.tipoNegocio = this.tipoDeNegocio;

        if ( (this.endereco != null || this.localizacaoSelecionada != null) && (this.atividades != null || this.atividadesSelecionadas != null) ) {
            this.consultaViabilidadeService
                .verificaRegraParaEmpreendimento(this.consultaViabilidade)
                .pipe(take(1))
                .subscribe( retorno => {
                    var dados: ConsultaViabilidade = retorno[0];
                    var dadosGerais: ConsultaViabilidade = retorno[1];
                    if(dados.listaAtividades[0].uso.status == Constantes.TIPO_DE_USO.Nao_Permitido){
                        this.situacaoEmpresa = Constantes.TIPO_DE_USO.Nao_Permitido;
                    }
                    else if(dados.listaAtividades[0].uso.status == Constantes.TIPO_DE_USO.Permissivel){
                        this.situacaoEmpresa = Constantes.TIPO_DE_USO.Permissivel;
                    }
                    else if(dados.listaAtividades[0].uso.status == Constantes.TIPO_DE_USO.Permitido){
                        this.situacaoEmpresa = Constantes.TIPO_DE_USO.Permitido;
                    }
                    
                    this.consultaViabilidadeAtividades = dados.listaAtividades;

                    this.requisitosEmpresa = dadosGerais.listaAtividades[0].requisitos;
                },
                (err) => {
                    console.log('err', err);
                }
            );
        }
    }

    limpar() {
        this.consultaViabilidade = new ConsultaViabilidade();

        this.limparLocalizacao();
        this.limparAtividades();
        this.limparConcorrentes();
        this.limparServicosPublicos();
        this.limparPossiveisPermissiveisNaoPossiveis();
        this.limparPerimetro();
        this.inicializarMapa();
    }

    limparLocalizacao() {
        if (this.markerLocalizacao != null) {
            this.markerLocalizacao.forEach( markerConcorrente => {
                markerConcorrente.setMap(null);
            });
        }
        
        this.endereco = null;
        this.consultaViabilidadeEndereco = new ConsultaViabilidadeEndereco();;
        this.localizacaoSelecionada = null;
        this.markerLocalizacao = new Array<google.maps.Marker>();
    }

    limparAtividades() {
        this.atividades = null;
        this.consultaViabilidadeAtividades = Array<ConsultaViabilidadeAtividade>();
        this.atividadesSelecionadas = new Array<ConsultaViabilidadeAtividade>();
    }

    limparConcorrentes() {
        if (this.markerClusterConcorrentes != null) {
            this.markerClusterConcorrentes.setMap(null);
            this.markerClusterConcorrentes.repaint();
        }

        if (this.markerConcorrentes != null) {
            this.markerConcorrentes.forEach( markerConcorrente => {
                markerConcorrente.setMap(null);
            });
        }
        this.markerClusterConcorrentes = null;
        this.markerConcorrentes = new Array<google.maps.Marker>();

        this.concorrentes = null;
    }

    limparServicosPublicos() {
        if (this.markerClusterServicosPublicos != null) {
            this.markerClusterServicosPublicos.setMap(null);
            this.markerClusterServicosPublicos.repaint();
        }

        if (this.markerServicosPublicos != null) {
            this.markerServicosPublicos.forEach( markerServicoPublico => {
                markerServicoPublico.setMap(null);
            });
        }
        this.markerClusterServicosPublicos = null;
        this.markerServicosPublicos = new Array<google.maps.Marker>();

        this.servicosPublicos = null;
    }

    limparPossiveisPermissiveisNaoPossiveis() {
        if (this.markerClusterPossiveis != null) {
            this.markerClusterPossiveis.setMap(null);
            this.markerClusterPossiveis.repaint();
        }
        if (this.markerClusterPermissiveis != null) {
            this.markerClusterPermissiveis.setMap(null);
            this.markerClusterPermissiveis.repaint();
        }
        if (this.markerClusterNaoPossiveis != null) {
            this.markerClusterNaoPossiveis.setMap(null);
            this.markerClusterNaoPossiveis.repaint();
        }

        if (this.markerPossiveis != null) {   
            this.markerPossiveis.forEach( markerPossivel => {
                markerPossivel.setMap(null);
            });
        }
        if (this.markerPermissiveis != null) {   
            this.markerPermissiveis.forEach( markerPermissivel => {
                markerPermissivel.setMap(null);
            });
        }
        if (this.markerNaoPossiveis != null) {   
            this.markerNaoPossiveis.forEach( markerNaoPossivel => {
                markerNaoPossivel.setMap(null);  
            });
        }

        this.markerPossiveis = null;
        this.markerPermissiveis = null;
        this.markerNaoPossiveis = null;

        this.markerClusterPossiveis = null;
        this.markerClusterPermissiveis = null;
        this.markerClusterNaoPossiveis = null;

        this.atividadesPossiveisPermissiveisNaoPossiveis = null;
        this.enderecosPossiveisPermissiveisNaoPossiveis = null;
    }

    limparPerimetro() {
        this.enderecoLatLng = null;
        if (this.circlePerimetro != null) {
            this.circlePerimetro.setMap(null);
        }
        this.circlePerimetro = null;
        if (document.getElementById("selecaoPerimetro") != null) {
            (<HTMLSelectElement>document.getElementById("selecaoPerimetro")).value = "0";
            this.render.setProperty(document.getElementById("selecaoPerimetro"), "disabled", true);
        }
    }

    gerenciarBotoes() {
        if (!this.exibirLocalizacao && this.exibirAtividades) {
            if (document.getElementById("localizacao") != null) {
                this.render.setStyle(document.getElementById("localizacao"), "color", "#aaa");
            }
            if (document.getElementById("concorrentes") != null) {
                this.render.removeStyle(document.getElementById("concorrentes"), "color");
            }
        }
        else if (this.exibirLocalizacao && !this.exibirAtividades) {
            if (document.getElementById("localizacao") != null) {
                this.render.removeStyle(document.getElementById("localizacao"), "color");
            }
            if (document.getElementById("concorrentes") != null) {
                this.render.setStyle(document.getElementById("concorrentes"), "color", "#aaa");
            }
            if (document.getElementById("possivel")) {
                this.render.removeStyle(document.getElementById("possivel"), "color");
            }
            if (document.getElementById("nao-possivel") != null) {
                this.render.removeStyle(document.getElementById("nao-possivel"), "color");
            }
            if (document.getElementById("permissivel") != null) {
                this.render.removeStyle(document.getElementById("permissivel"), "color");
            }
        }
        else if (this.exibirLocalizacao && this.exibirAtividades) {
            if (document.getElementById("localizacao") != null) {
                this.render.removeStyle(document.getElementById("localizacao"), "color");
            }
            if (document.getElementById("concorrentes") != null) {
                this.render.removeStyle(document.getElementById("concorrentes"), "color");
            }
            if (document.getElementById("possivel")) {
                this.render.setStyle(document.getElementById("possivel"), "color", "#aaa");
            }
            if (document.getElementById("nao-possivel") != null) {
                this.render.setStyle(document.getElementById("nao-possivel"), "color", "#aaa");
            }
            if (document.getElementById("permissivel") != null) {
                this.render.setStyle(document.getElementById("permissivel"), "color", "#aaa");
            }
        }

        if (this.atividadesSelecionadas.length) {
            if (document.getElementById("concorrentes") != null) {
                this.render.removeStyle(document.getElementById("concorrentes"), "color");
            }
        }
        
    }

    criarBotoesInteracao() {
        const divPrincipal = document.createElement("div");
        divPrincipal.setAttribute("class", "acoes-mapa-principal");
        divPrincipal.style.backgroundColor = "#fff";
        divPrincipal.style.border = "2px solid #fff";
        divPrincipal.style.borderRadius = "3px";
        divPrincipal.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        divPrincipal.style.cursor = "pointer";
        divPrincipal.style.marginTop = "8px";
        divPrincipal.style.marginLeft = "10px";
        divPrincipal.style.marginBottom = "22px";
        divPrincipal.style.textAlign = "center";

        this.criarBotaoLocalizacao(divPrincipal);
        this.criarBotaoConcorrentes(divPrincipal);
        this.criarBotaoServicosPublicos(divPrincipal);
        this.criarBotaoPossivel(divPrincipal);
        this.criarBotaoNaoPossivel(divPrincipal);
        this.criarBotaoPermissivel(divPrincipal);
        this.criarBotaoPontosInteresseGoogle(divPrincipal);
        this.criarBotaoCompartilhar(divPrincipal);

        const divPerimetro = document.createElement("div");
        divPerimetro.setAttribute("class", "acoes-mapa-perimetro");
        divPerimetro.style.backgroundColor = "#fff";
        divPerimetro.style.border = "2px solid #fff";
        divPerimetro.style.borderRadius = "3px";
        divPerimetro.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        divPerimetro.style.cursor = "pointer";
        divPerimetro.style.marginBottom = "8px";
        divPerimetro.style.width = "500px";
        this.criarBotaoPerimetro(divPerimetro);

        const divLegendas = document.createElement("div");
        divLegendas.setAttribute("class", "acoes-mapa-perimetro");
        divLegendas.style.backgroundColor = "#fff";
        divLegendas.style.border = "2px solid #fff";
        divLegendas.style.borderRadius = "3px";
        divLegendas.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        divLegendas.style.cursor = "pointer";
        divLegendas.style.marginTop = "8px";
        divLegendas.style.width = "125px";
        divLegendas.style.height = "20px";
        this.criarBotaoLegendas(divLegendas);

        this.mapa.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(divPrincipal);
        this.mapa.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(divPerimetro);
        this.mapa.controls[google.maps.ControlPosition.TOP_CENTER].push(divLegendas);

        if (document.getElementById("selecaoPerimetro") != null) {
            this.render.setProperty(document.getElementById("selecaoPerimetro"), "disabled", true);
        }

    }

    criarBotaoLocalizacao(divPrincipal) {
        // Localização.
        const divBotao = this.criarDivBotao("map-pin", "Localização", "localizacao", "");
        divBotao.addEventListener("click", () => {
            this.acaoExibirLocalizacao();
        });

        divPrincipal.appendChild(divBotao);
    }

    criarBotaoConcorrentes(divPrincipal) {
        // Concorrentes.
        const divBotao = this.criarDivBotao("eye", "Concorrentes", "concorrentes", "");
        divBotao.style.borderTop = "1px solid #eeeeee";

        divBotao.addEventListener("click", () => {
            this.acaoExibirConcorrentes();
        });

        divPrincipal.appendChild(divBotao);
    }

    criarBotaoServicosPublicos(divPrincipal) {
        // Serviços Públicos.
        const divBotao = this.criarDivBotao("globe", "Serviços Públicos", "servicos-publicos", "");
        divBotao.style.borderTop = "1px solid #eeeeee";

        divBotao.addEventListener("click", () => {
            this.acaoExibirServicosPublicos();
        });
        divPrincipal.appendChild(divBotao);
    }

    criarBotaoPossivel(divPrincipal) {
        // Possível.
        const divBotao = this.criarDivBotao("thumbs-up", "Possível", "possivel", "texto-success");
        divBotao.style.borderTop = "1px solid #eeeeee";

        divBotao.addEventListener("click", () => {
            this.acaoExibirPossivel();
        });
        divPrincipal.appendChild(divBotao);
    }

    criarBotaoNaoPossivel(divPrincipal) {
        // Não Possível.
        const divBotao = this.criarDivBotao("thumbs-down", "Não Possível", "nao-possivel", "texto-danger");
        divBotao.style.borderTop = "1px solid #eeeeee";

        divBotao.addEventListener("click", () => {
            this.acaoExibirNaoPossivel();
        });
        divPrincipal.appendChild(divBotao);
    }

    criarBotaoPermissivel(divPrincipal) {
        // Permissível.
        const divBotao = this.criarDivBotao("alert-triangle", "Permissível", "permissivel", "texto-warning");
        divBotao.style.borderTop = "1px solid #eeeeee";

        divBotao.addEventListener("click", () => {
            this.acaoExibirPermissivel();
        });
        divPrincipal.appendChild(divBotao);
    }

    criarBotaoPontosInteresseGoogle(divPrincipal) {
        // Pontos de Interesse do Google.
        const divBotao = this.criarDivBotao("map", "Pontos de Interesse do Google", "pontos-interesse-google", "");
        divBotao.style.borderTop = "1px solid #eeeeee";

        divBotao.addEventListener("click", () => {
            this.acaoExibirPontosInteresseGoogle();
        });
        divPrincipal.appendChild(divBotao);
    }

    criarBotaoCompartilhar(divPrincipal) {
        // Compartilhar.
        const divBotao = this.criarDivBotao("printer", "Compartilhar", "compartilhar", "");
        divBotao.style.borderTop = "1px solid #eeeeee";

        divBotao.addEventListener("click", () => {
            this.acaoExibirCompartilhar();
        });
        divPrincipal.appendChild(divBotao);
    }

    criarBotaoPerimetro(divPerimetro) {
        // Perímetro.
        const selectPerimetro = document.createElement("select");
        selectPerimetro.id = "selecaoPerimetro";
        selectPerimetro.setAttribute("class", "form-control form-control-sm");
        selectPerimetro.style.color = "rgb(25,25,25)";
        selectPerimetro.style.fontFamily = "Roboto,Arial,sans-serif";
        selectPerimetro.style.fontSize = "14px";
        selectPerimetro.style.lineHeight = "38px";
        selectPerimetro.style.padding = "8px 15px";

        selectPerimetro.addEventListener("change", () => {
            this.acaoExibirPerimetro();
        });

        const optionPerimetro0 = document.createElement("option");
        optionPerimetro0.value = "0";
        optionPerimetro0.text = "Nenhum";
        selectPerimetro.appendChild(optionPerimetro0);
        
        const optionPerimetro1 = document.createElement("option");
        optionPerimetro1.value = "1";
        optionPerimetro1.text = "1 KM";
        selectPerimetro.appendChild(optionPerimetro1);

        const optionPerimetro2 = document.createElement("option");
        optionPerimetro2.value = "2";
        optionPerimetro2.text = "2 KM";
        selectPerimetro.appendChild(optionPerimetro2);

        const optionPerimetro3 = document.createElement("option");
        optionPerimetro3.value = "3";
        optionPerimetro3.text = "3 KM";
        selectPerimetro.appendChild(optionPerimetro3);

        const optionPerimetro4 = document.createElement("option");
        optionPerimetro4.value = "4";
        optionPerimetro4.text = "4 KM";
        selectPerimetro.appendChild(optionPerimetro4);

        const optionPerimetro5 = document.createElement("option");
        optionPerimetro5.value = "5";
        optionPerimetro5.text = "5 KM";
        selectPerimetro.appendChild(optionPerimetro5);

        const optionPerimetro6 = document.createElement("option");
        optionPerimetro6.value = "10";
        optionPerimetro6.text = "10 KM";
        selectPerimetro.appendChild(optionPerimetro6);

        const optionPerimetro7 = document.createElement("option");
        optionPerimetro7.value = "15";
        optionPerimetro7.text = "15 KM";
        selectPerimetro.appendChild(optionPerimetro7);

        const optionPerimetro8 = document.createElement("option");
        optionPerimetro8.value = "25";
        optionPerimetro8.text = "25 KM";
        selectPerimetro.appendChild(optionPerimetro8);
        
        const divPrincipal = document.createElement("div");
        divPrincipal.setAttribute("class", "row col-12 justify-content-start align-items-center");
        divPrincipal.style.paddingRight = "0px"

        const divTitulo = document.createElement("div");
        divTitulo.setAttribute("class", "row col-8");
        divTitulo.style.paddingRight = "0px"
        divTitulo.innerHTML = `Definir um raio a partir da localização `;
        
        const divSelect = document.createElement("div");
        divSelect.setAttribute("class", "row col-4");
        divSelect.style.paddingRight = "0px"

        divSelect.appendChild(selectPerimetro);

        divPrincipal.appendChild(divTitulo);
        divPrincipal.appendChild(divSelect);

        divPerimetro.appendChild(divPrincipal);
    }

    criarBotaoLegendas(divLegendas) {
        // Perímetro.
        const selectPerimetro = document.createElement("div");
        selectPerimetro.id = "divLegendas";
        selectPerimetro.setAttribute("class", "form-control form-control-sm");
        selectPerimetro.style.color = "rgb(25,25,25)";
        selectPerimetro.style.fontFamily = "Roboto,Arial,sans-serif";
        selectPerimetro.style.fontSize = "30px";
        selectPerimetro.style.lineHeight = "38px";
        selectPerimetro.style.padding = "8px 15px";
  
        const divPrincipal = document.createElement("div");
        divPrincipal.setAttribute("class", "row col-12 justify-content-start align-items-center");
        divPrincipal.style.paddingRight = "0px"

        const divTitulo = document.createElement("div");
        divTitulo.setAttribute("class", "row col-8");
        divTitulo.style.paddingRight = "0px"
        divTitulo.style.fontSize = "14px";
        divTitulo.innerHTML = `Legendas`;

        divPrincipal.appendChild(divTitulo);

        divLegendas.appendChild(divPrincipal);

        divLegendas.addEventListener("click", () => {
            var ele = $('#legendas');
            $('html, body').animate({
                scrollTop: ele.offset().top
            }, 700);
        });
    }

    criarDivBotao(nomeIcone, textoToolTip, id, classe) {
        const divBotao = document.createElement("div");
        divBotao.style.color = "rgb(25,25,25)";
        divBotao.style.fontFamily = "Roboto,Arial,sans-serif";
        divBotao.style.fontSize = "14px";
        divBotao.style.lineHeight = "38px";
        divBotao.style.padding = "5px 10px";
        divBotao.style.minHeight = "55px";
        divBotao.title = textoToolTip;
        
        divBotao.innerHTML = `
            <svg class="icone-pesquisa-viabilidade ${ classe }" id="${ id }" style="float: left; position: relative; margin: 10px;">
                <use
                    xlink:href="#${ nomeIcone }"
                ></use>
            </svg>
        `;
        
        const popper = document.createElement("div");
        popper.id = "tooltip";
        popper.setAttribute("class", "tooltip-google-maps");
        popper.setAttribute("data-popper-placement-google-maps", "right");
        popper.innerHTML = textoToolTip;
        
        divBotao.addEventListener("mouseenter", () => {
            //this.exibirToolTip(popper);
        });
        
        divBotao.addEventListener("mouseleave", () => {
            //this.exibirToolTip(popper);
        });
        
        const arrow = document.createElement("div");
        arrow.id = "arrow";
        arrow.setAttribute("data-popper-arrow", "");
        popper.appendChild(arrow);
        
        divBotao.appendChild(popper);

        return divBotao;
    }

    exibirToolTip(objeto: HTMLDivElement) {
        if (objeto.getAttribute("data-show") == null) {
            objeto.setAttribute("data-show", "");
        }
        else {
            objeto.removeAttribute("data-show");
        }
    }

    htmlParaImagem() {
        let marcarLoalizacao = "";
        
        this.markerLocalizacao.forEach( localizacao => {
            marcarLoalizacao += `&markers=anchor:${ localizacao.getPosition().toUrlValue() }`;
        });

        let marcarConcorrentes = "";
        
        this.markerConcorrentes.forEach( concorrente => {
            marcarConcorrentes += `&markers=icon:https://portal.icadonline.com.br/biblioteca/imagem/icones/azul.png|${ concorrente.getPosition().toUrlValue() }`;
        });
        
        this.httpClient.get<any>(
            `http://maps.googleapis.com/maps/api/staticmap?size=800x1200&center=${ this.mapa.getCenter().toUrlValue() }&zoom=${ this.mapa.getZoom() }${ marcarLoalizacao }${ marcarConcorrentes }&style=feature:poi|visibility:${ this.pontosInteresseGoogle ? "on" : "off" }&key=${ environment.keyGoogleMaps }`,
            {
                headers: new HttpHeaders({
                    // app_id: environment.appId,
                    // param_user: 'ORG',
                    // 'Content-Type': 'application/json',
                }),
            }
        )
        .subscribe( retorno => {
            // console.log(retorno.toDataURL("image/png"));
        });
        // this.httpClient.jsonp(`http://maps.googleapis.com/maps/api/staticmap?&size=600x400&key=${ environment.keyGoogleMaps }`, 'callback')
        //     .pipe(
        //     map(() => true),
        //         catchError(() => of(false)),
        //     )
        //     .subscribe( retorno => {
        //         console.log(retorno);
        //     });
        
        // var Pagelink = "about:blank";
        // var pwa = window.open(Pagelink, "_new");
        // pwa.document.open();
        // pwa.document.write(this.mapa.googleMap.getDiv().innerHTML);
        // pwa.document.close();
        
        // html2canvas(document.querySelector(".div-google-maps")).then(canvas => {
        //     document.body.appendChild(canvas)
            // console.log(canvas);
            // var Pagelink = "about:blank";
            // var pwa = window.open(Pagelink, "_new");
            // pwa.document.open();
            // pwa.document.write(canvas.innerHTML);
            // pwa.document.close();
            // var imgData = canvas.toDataURL("image/png");
            // self.AddImagesResource(imgData);
            // console.log(imgData);
            // document.body.appendChild(canvas);
        // });

        

    }

    printAnyMaps() {
        const $body = $('body');
        const $mapContainer = $('.div-google-maps');
        const $mapContainerParent = $mapContainer.parent();
        const $printContainer = $('<div style="position:relative;">');

        $printContainer
        .height($mapContainer.height())
        .append($mapContainer)
        .prependTo($body);
        
        const $content = $body
        .children()
        .not($printContainer)
        .not('script')
        .detach();
        
        const $patchedStyle = $('<style media="print">')
        .text(`
            img { max-width: none !important; }
            a[href]:after { content: ""; }
            .acoes-mapa-principal { visibility: hidden;}
            .acoes-mapa-perimetro { visibility: hidden;}
        `)
        .appendTo('head');
    
        window.print();
    
        $body.prepend($content);
        $mapContainerParent.prepend($mapContainer);
    
        $printContainer.remove();
        $patchedStyle.remove();
    }

}