import { Component, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";

import { GridProviderPaginacao } from "./grid-provider-paginacao";
import { Atividade } from "src/app/model/atividade";
import { SelectionModel } from "@angular/cdk/collections";
import Swal from 'sweetalert2';
import { Constantes } from '../../model/constantes';

@Component({
    selector: "app-grid-atividade",
    templateUrl: "./grid-atividade.component.html",
    styleUrls: ["./grid.component.scss"],
    providers: [ 
        {
            provide: MatPaginatorIntl,
            useClass: GridProviderPaginacao
        }
    ]
})
export class GridAtividadeComponent {
    @Output() selecao = new EventEmitter();
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ["selecao","codigo","nome"];
    linhaSelecionada: SelectionModel<Atividade>;
    
    @Input() dados: Array<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    
    atividades: Array<Atividade>;
    tipoEmpresa: string;
    limitarSelecao: number = 0;


    constructor() { }
    
    ngOnInit() {
        this.tipoEmpresa = this.dados[2];
        this.dataSource = new MatTableDataSource(this.dados[0]);
        this.limitarSelecao = this.dados.length > 3 ? this.dados[3] : 0;
        this.linhaSelecionada = new SelectionModel<Atividade>(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa || (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo && this.limitarSelecao > 0));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dados[1].forEach(atividadeSelecionada => {
            var row = this.dataSource.data.filter( linha => atividadeSelecionada.codigo == linha.codigo );
            if (row.length) {
                row[0].exercida = atividadeSelecionada.exercida;
                row[0].principal = atividadeSelecionada.principal;
                this.linhaSelecionada.select(row[0]);
            }
        });
    }

    aplicarFiltro(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    acaoBotaoConcluir() {
        this.selecao.emit({ atividades: this.linhaSelecionada });
    }

    isAllSelected() {
        const numSelected = this.linhaSelecionada.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    selecionarAtividade(row, atividadeInput) {
        if (this.limitarSelecao > 0) {
            this.linhaSelecionada.toggle(row);
            if (this.linhaSelecionada.selected.length > this.limitarSelecao) {
                this.linhaSelecionada.deselect(row);
                if (atividadeInput != null) atividadeInput.checked = false;
                Swal.fire("", `Não foi possível adicionar porque o limite máximo é de até ${ this.limitarSelecao } atividades.`, "error");
            }
        }
        else {
            this.linhaSelecionada.toggle(row);
        }
    }

    teste(event) {
        console.log(event)
    }

    masterToggle() {
        this.isAllSelected() ?
            this.linhaSelecionada.clear() :
            this.dataSource.data.forEach(row => this.linhaSelecionada.select(row));
    }

    checkboxLabel(row?: Atividade): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        
        return `${this.linhaSelecionada.isSelected(row) ? 'deselect' : 'select'} row ${row.codigo + 1}`;
    }
}