import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";

import { PerguntaService } from "src/app/services/pergunta/pergunta.service";
import { Pergunta } from "src/app/model/pergunta";
import { MascaraPipe } from "src/app/pipes/mascara.pipe";
import { Resposta } from "src/app/model/resposta";
import Swal from "sweetalert2";
import { take } from "rxjs/operators";
import { PerguntaResposta } from "src/app/model/perguntaResposta";
import { Constantes } from "src/app/model/constantes";
import { ActivatedRoute } from "@angular/router";
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: "app-form-questionario",
    templateUrl: "./form-questionario.component.html",
    styleUrls: ["./form-questionario.component.scss"]
})
export class FormQuestionarioComponent implements OnInit {

    @Output() perguntasCarregadas = new EventEmitter();
    @Input() perguntasCarregadasDeInicio: Array<Pergunta>;
    @Input() nome: string;
    @Input() titulo: string;
    @Input() tipo: string;
    @Input() permitirAtendimento: any;
    @Input() apenasAcompanhamento: boolean = false;   
    @Input() semCardHeader: boolean = false; 

    @Input() licenciamentoCriticado: boolean = false;

    permitirSalvarRespostasAuditor: boolean = false;
    
    codigoOrgao: string;

    tipoQuestionario = Constantes.TIPO_DE_PERGUNTA;
    
    formulario: FormGroup;
    perguntas: Array<Pergunta>;
    perguntasRespondidas: Array<Pergunta>;
    perguntasRespondidasOutrosProcessos: Array<Pergunta>;
    perguntasRespostas: PerguntaResposta;
    
    emitirErro: boolean;
    emitirCritica: boolean;
    mensagemCritica: string;
    apenasCorrespondencia: boolean;
    mensagemErro: string;
    selecionarTodos: boolean = false;
    perguntasCriticadas: Set<string>;

    bloqueadoPorCriticas: boolean = false;

    liberarPreenchimento: boolean = false;

    tipoEmpresa: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private perguntaService: PerguntaService,
        private mascara: MascaraPipe) {

        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];

        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
        });
        
        if (this.tipo != Constantes.TIPO_DE_PERGUNTA.Auditor) {
            this.perguntas = new Array<Pergunta>();
        }
        
        this.perguntasRespondidas = new Array<Pergunta>();
        this.perguntasRespondidasOutrosProcessos = new Array<Pergunta>();
        this.perguntasRespostas = new PerguntaResposta();
        
        this.emitirCritica = false;
        this.mensagemCritica = "";
        this.emitirErro = false;
        this.apenasCorrespondencia = false;

        this.codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"];

    }
    
    ngOnInit(): void {
        this.perguntasCriticadas = new Set<string>();
        
        if (this.tipo != Constantes.TIPO_DE_PERGUNTA.Licenciamento) {
            if (this.permitirAtendimento != null && !this.permitirAtendimento) {    
                let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
                this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !meusNegocios);
            }
        }
        else {
            this.permitirAtendimento = this.permitirAtendimento == 'true'
        }
        
        if (this.perguntasCarregadasDeInicio != null) {
            Object.assign(this.perguntas, this.perguntasCarregadasDeInicio);
            this.montagemDePerguntas();
        }
    }

    async carregarPerguntas(param) {
        // Renova o formulário
        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
        });

        await this.perguntaService
            .listarPerguntas(param)
            .pipe(take(1))
            .subscribe( retorno => {
                if (retorno.length) {
                    this.perguntas = retorno;
                    this.montagemDePerguntas();
                }
            });
    }

    montagemDePerguntas() {
        let respostas = new Array<any>();

        this.perguntas.forEach( pergunta => {
            // Adiciona o campo no formulário
            this.formulario.addControl(`resposta_${pergunta.codigo}`, new FormControl("", Validators.required));
            if (pergunta.obrigatorio == "N" || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Empresa) {
                this.formulario.controls[`resposta_${pergunta.codigo}`].clearValidators();
            }

            if (pergunta.codigoAutomacao == "EC") {
                if (this.apenasCorrespondencia) {
                    this.formulario.controls[`resposta_${pergunta.codigo}`].disable();
                    this.atualizaResposta(pergunta.codigo, "SIM");
                }
            }

            if (this.tipo == Constantes.TIPO_DE_PERGUNTA.Licenciamento) {
                if(this.licenciamentoCriticado){
                    pergunta.respostas.forEach( resposta =>{
                        if(resposta.ativa){
                            this.atualizaResposta(pergunta.codigo, resposta.nome.toUpperCase());
                        }
                    })
                    if(!pergunta.criticada){
                        this.formulario.controls[`resposta_${pergunta.codigo}`].disable();
                    }
                }
                else{
                    this.atualizaResposta(pergunta.codigo, "NÃO");
                }
            }

            this.perguntasRespondidas.forEach( perguntaRespondida => {
                if (pergunta.codigo == perguntaRespondida.codigo) {
                    this.formulario.controls[`resposta_${pergunta.codigo}`].setValue(perguntaRespondida.respostas[0].nome);
                    respostas.push(perguntaRespondida);
                }
            })
            
            this.perguntasRespondidasOutrosProcessos.forEach( perguntaRespondidaOutroProcesso => {
                let perguntaFoiRespondida = false;
                this.perguntasRespondidas.forEach( perguntaRespondida => {
                    if (pergunta.codigo == perguntaRespondida.codigo) {
                        perguntaFoiRespondida = true;
                    }
                });
                if (pergunta.codigo == perguntaRespondidaOutroProcesso.codigo) {
                    if (!perguntaFoiRespondida) {
                        this.formulario.controls[`resposta_${pergunta.codigo}`].setValue(perguntaRespondidaOutroProcesso.respostas[0].nome);
                        respostas.push(perguntaRespondidaOutroProcesso);
                    }
                    if(!this.liberarPreenchimento && (this.tipo == Constantes.TIPO_DE_PERGUNTA.Viabilidade || this.tipo == Constantes.TIPO_DE_PERGUNTA.Viabilidade_Evento)){
                        this.formulario.controls[`resposta_${pergunta.codigo}`].disable();
                    }
                    if (this.bloqueadoPorCriticas) {
                        this.formulario.controls[`resposta_${pergunta.codigo}`].disable();
                    }
                    
                }
            });

            if (pergunta.criticada) {
                this.perguntasCriticadas.add(pergunta.processo);
            }
            

        })

        if (this.tipo == Constantes.TIPO_DE_PERGUNTA.Licenciamento && this.codigoOrgao != "100") {
            let mensagemAuxiliar = "";

            this.perguntas.forEach( pergunta => {
                if (pergunta.respostas[0].nome.toUpperCase() != "NÃO") {
                    mensagemAuxiliar += "<b>" + pergunta.processo + " - " + pergunta.nome + "</b><br>";
                    mensagemAuxiliar += "<strong>Resp.: " + pergunta.respostas[0].nome + "</strong><br><br>";
                }
            });

            if (mensagemAuxiliar != "") {
                this.mensagemCritica = "Existe(m) pergunta(s) modificada(s): <br>" + mensagemAuxiliar;
            }
        }

        if (this.perguntasCriticadas.size > 0) { 
            if (this.mensagemCritica != "") {
                this.mensagemCritica += "<br><br>"
            }

            let mensagemAuxiliar = "";

            this.perguntasCriticadas.forEach( codigo => {
                this.perguntas.filter(pergunta => pergunta.processo == codigo).forEach( perguntaEncontrada => {
                    mensagemAuxiliar += "<b>" + perguntaEncontrada.processo + " - " + perguntaEncontrada.nome + "</b> ";

                    var resposta = "";
                    perguntaEncontrada.respostas.filter(respostaEncontrada => respostaEncontrada.ativa).forEach( respostaAtiva => resposta = respostaAtiva.nome);
                    mensagemAuxiliar += "<strong>Resp.: " + resposta + "</strong><br>";
                });
            });

            this.mensagemCritica += "Existe(m) pergunta(s) criticada(s): <br>" + mensagemAuxiliar;
        }

        if (this.mensagemCritica != "") {

            this.emitirCritica = true;
        }

        this.perguntasRespondidas = respostas;
        this.perguntasCarregadas.emit({ carregado: true});
    }

    acaoRespostaSelect(campo) {
        let codigoPergunta = campo.id.replace("resposta_", "");
        let valorResposta = campo.value;
        this.atualizaResposta(codigoPergunta, valorResposta);

    }

    acaoRespostaInput(campo, tipo) {
        campo.value = this.mascara.transform(campo.value, tipo);
        
        let codigoPergunta = campo.id.replace("resposta_", "");
        let valorResposta = campo.value;
        this.atualizaResposta(codigoPergunta, valorResposta);
    }

    acaoCriticar(checkbox: MatCheckbox, codigo) {
        if (checkbox.checked) {
            this.perguntasCriticadas.add(codigo);
        }
        else {
            this.perguntasCriticadas.delete(codigo);
        }
    }

    limpar(){
        this.perguntasRespondidas.forEach( existePerguntaRespondida => {
            existePerguntaRespondida.respostas[0].nome = null;
        })
    }

    atualizaResposta(codigoPergunta, valorResposta) {

        let existe = false;

        this.perguntasRespondidas.forEach( existePerguntaRespondida => {
            if (codigoPergunta == existePerguntaRespondida.codigo) {
                existePerguntaRespondida.respostas[0].nome = valorResposta
                existe = true;
            }
        })

        this.perguntas.forEach(obrigatorias => {
            if(this.formulario.controls[`resposta_${codigoPergunta}`].touched){
                if(obrigatorias.obrigatorio == "S" && obrigatorias.codigo == codigoPergunta) {
                    this.validarFormulario();
                }
            }
        });
        



        let perguntaRespondida = new Pergunta();
        let resposta = new Resposta();

        if (!existe) {
            this.perguntas.forEach( pergunta => {
                if (codigoPergunta == pergunta.codigo) {
                    perguntaRespondida.cliente = pergunta.cliente;
                    perguntaRespondida.codigo = pergunta.codigo;
                    perguntaRespondida.nome = pergunta.nome;
                    perguntaRespondida.atividades = pergunta.atividades;
                    perguntaRespondida.tipo = pergunta.tipo;
                    perguntaRespondida.obrigatorio = pergunta.obrigatorio;
                    perguntaRespondida.processo = pergunta.processo;
                    
                    resposta.nome = valorResposta;
                    perguntaRespondida.respostas.push(resposta);
                }
            });
            this.perguntasRespondidas.push(perguntaRespondida);
        }
    }

    inserirRespostas(perguntas) {
        if (perguntas == null)
            return false;

        perguntas.forEach(pergunta => {
            this.formulario.controls[`resposta_${pergunta.codigo}`].setValue(pergunta.respostas[0].nome);
            this.atualizaResposta(pergunta.codigo, pergunta.respostas[0].nome);
        });
    }

    validarFormulario() {
        this.formulario.markAllAsTouched();
        this.emitirErro = false;
        if (this.formulario.invalid) {
            this.controleValidade.setValue("");
            let mensagemErro = "";

            this.perguntas.forEach(pergunta => {
                let campo = `resposta_${pergunta.codigo}`;
    
                if (pergunta.obrigatorio == "S" && this.formulario.controls[campo].status == 'INVALID') {
                    mensagemErro += "Favor responder as perguntas obrigatórias.<br>"
                }
            });

            if (mensagemErro == "") {
                this.controleValidade.setValue(true);
                this.emitirErro = false;
                return true;
            }

            Swal.fire("Questionário Incorreto", "Corrigir os erros descritos no informativo da página.", "error");

            this.emitirErro = true;
            this.mensagemErro = mensagemErro;

            return false;
        }

        this.emitirErro = false;
        this.mensagemErro = "";

        return true;
    }    

    campoInvalido(campo) {
        if (this.formulario.controls[campo] == null)
            return false;

        return this.formulario.controls[campo].invalid;
    } 

    campoTocado(campo) {
        if (this.formulario.controls[campo] == null)
            return false;

        return this.formulario.controls[campo].touched;
    }

    campoErro(campo, erro) {
        if (this.formulario.controls[campo] == null)
            return false;

        return this.formulario.controls[campo].hasError(erro);
    }

    preencher() {
        this.perguntasRespostas = new PerguntaResposta();
        this.perguntasRespostas.perguntas = this.perguntasRespondidas;
    }

    liberarPerguntas() {
        Object.keys(this.formulario.controls).forEach(controle => {
            this.formulario.controls[controle].disable();
            if (this.liberarPreenchimento) {
                this.formulario.controls[controle].enable();
            }
        });
    }

    salvarRespostasAuditor() {    
        var perguntasRespostaAuxiliar = new PerguntaResposta();
        perguntasRespostaAuxiliar.inscricao = this.activatedRoute.snapshot.params["inscricao"];
        perguntasRespostaAuxiliar.perguntas = this.perguntasRespondidas;
        if(this.validarFormulario()){
            this.perguntaService
            .salvarRespostasAuditor(perguntasRespostaAuxiliar)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    width: 700,
                    willClose: () => {}
                });            
            })
        }else {
            Swal.fire("Questionário Incorreto", "Corrigir os erros descritos no informativo da página.", "error");
        } 
    }

    get controleValidade() {
        return this.formulario.get("controleValidade");
    }
    set controleValidade(controleValidade) {
        this.formulario.get("controleValidade").setValue(controleValidade);
    }

}