import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Notificacao } from "src/app/model/notificacao";

@Injectable({ providedIn: 'root' })
export class NotificacaoService {

    constructor(private http: HttpClient, private router: Router) {}

    enviarComAutenticacao(notificacao: Notificacao) {
        return this.http.post<any>(
            `${ environment.icadUrl }/notificacao/enviarComAutenticacao`, JSON.stringify(notificacao),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    buscarMensagensNaoLidas(cliente, Logins, Perfil) {
        return this.http.get<any>(
        `${ environment.icadUrl }/mensageria/buscarMensagensNaoLidas/${ cliente }/${ Logins }/${ Perfil }`,
        {
            reportProgress: true,
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }
    
    marcarComoLida(cliente, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/mensageria/marcarComoLida/${ cliente }/${ codigo }`,
        {
            reportProgress: true,
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }
}