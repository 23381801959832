import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Encerramento } from '../../../model/encerramento';
import { FormValidator } from '../../../components/validators/form-validator';
import { MotivoEncerramento } from '../../../model/motivoEncerramento';
import Swal from "sweetalert2";
import { MascaraPipe } from '../../../pipes/mascara.pipe';
import { MaskPipe } from 'ngx-mask';

@Component({
    selector: "app-form-encerramento",
    templateUrl: "./form-encerramento.component.html"
})
export class FormEncerramentoComponent implements OnInit {

    tipoEmpresa: string;
    formulario: FormGroup;
    motivosEncerramento: Array<MotivoEncerramento>;
    encerramento: Encerramento;
    
    emitirErro: boolean;
    mensagemErro: string;

    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private mask: MaskPipe) {
        
        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];
        this.motivosEncerramento = JSON.parse(localStorage.getItem('user')).configuracao.motivosEncerramento;
        this.encerramento = new Encerramento();

        this.emitirErro = false;

        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
            motivoBaixa: ['', [Validators.required]],
            dataPretendida: ['', [Validators.required, Validators.minLength(8), FormValidator.dataValida(), FormValidator.dataMaiorHoje()]],
            observacao: ["", [Validators.required, Validators.maxLength(20000)]],
        });
    }

    ngOnInit(): void {


    }
    
    limparCampos() {
        this.motivoBaixa.reset();
        this.dataPretendida.reset();
        this.observacao.reset();
        this.observacao.setValue("");
    }

    validarFormulario() {
        this.controleValidade.setValue("");
        
        if (this.formulario.invalid) {
            let mensagemErro = "";

            if (this.motivoBaixa.invalid) {
                mensagemErro += "Favor informar o motivo da baixa.<br>"
            }
            if (this.dataPretendida.invalid) {
                mensagemErro += "Favor informar a data pretendida para a baixa.<br>"
            }
            if (this.observacao.value == "" ) {
                mensagemErro += "Adicione observações pertinentes a solicitação.<br>"
            }

            if (mensagemErro === "") {
                this.controleValidade.setValue(true);
                this.emitirErro = false;
                return true;
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErro = true;
            this.mensagemErro = mensagemErro;

            return false;
        }

        this.emitirErro = false;
        this.mensagemErro = "";
        
        return true;
    }

    preencher() {
        let motivoEncerramento = this.motivosEncerramento.filter(item => item.codigo == this.motivoBaixa.value)[0];

        this.encerramento.motivosEncerramento.push(motivoEncerramento);
        this.encerramento.data = this.mask.transform(this.dataPretendida.value, "00/00/0000");
        this.encerramento.descricao = this.observacao.value;
    }

    get controleValidade() {
        return this.formulario.get("controleValidade");
    }
    set controleValidade(controleValidade) {
        this.formulario.get("controleValidade").setValue(controleValidade);
    }
    
    get motivoBaixa() {
        return this.formulario.get("motivoBaixa");
    }  
    set motivoBaixa(motivoBaixa) {
        this.formulario.get("motivoBaixa").setValue(motivoBaixa);
    }

    get dataPretendida() {
        return this.formulario.get("dataPretendida");
    }  
    set dataPretendida(dataPretendida) {
        this.formulario.get("dataPretendida").setValue(dataPretendida);
    }

    get observacao() {
        return this.formulario.get("observacao");
    }  
    set observacao(observacao) {
        this.formulario.get("observacao").setValue(observacao);
    }

}