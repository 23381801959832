import { Documento } from "./documento";
import { Endereco } from "./endereco";
import { Telefone } from "./telefone";

export class Usuario {
    cliente: string;
    codigo: string;
    protocolo: string;
    nome: string;
    nomeDoPai: string;
    nomeDaMae: string;
    nascimento: string;
    tipo: string;
    sexo: string;
    contador: boolean;
    documentos: Array<Documento>;
    enderecos: Array<Endereco>;
    telefones: Array<Telefone>;
    emails: Array<string>;
    sites: Array<string>;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.protocolo = "";
        this.nome = "";
        this.nomeDoPai = "";
        this.nomeDaMae = "";
        this.nascimento = "";
        this.tipo = "";
        this.sexo = "";
        this.contador = false;
        this.documentos = new Array<Documento>();
        this.enderecos = new Array<Endereco>();
        this.telefones = new Array<Telefone>();
        this.emails = new Array<string>();
        this.sites = new Array<string>();
    }
}