import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatRadioButton } from '@angular/material/radio';

@Component({
    selector: "app-form-autonomo-requerente",
    templateUrl: "./form-autonomo-requerente.component.html"
})

export class FormAutonomoRequerenteComponent implements OnInit{

    @Input() tipoAutonomo: string;

    formulario: FormGroup;

    constructor(
        private formBuilder: FormBuilder){

        this.formulario = this.formBuilder.group({
            autonomoRequerente: ["1", [Validators.required]]
        });
    }

    ngOnInit(): void{
        
    }

    acaoRadioAutonomoRequerente(campoAutonomoRequerente: MatRadioButton){
        

    }
    
    validarFormulario(){

    }
    
}

