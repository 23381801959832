import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";


@Injectable({ providedIn: 'root' })
export class AcompanhamentoService {

    constructor(private http: HttpClient, private router: Router) {}

    acompanhar() {
        return this.http.get<any>(
        `${ environment.icadUrl }/protocolo/acompanhar/${ localStorage.getItem('pId') }/${ localStorage.getItem('userId') }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    acompanharPorProtocolo(protocolo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/protocolo/acompanharPorProtocolo/${ localStorage.getItem('pId') }/${ localStorage.getItem('userId') }/${ protocolo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    acompanharPorProtocoloParaAtendimento(protocolo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/protocolo/acompanharPorProtocoloParaAtendimento/${ localStorage.getItem('pId') }/${ localStorage.getItem('userId') }/${ protocolo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    pendencia() {
        return this.http.get<any>(
        `${ environment.icadUrl }/pendencia/listar/${ localStorage.getItem('pId') }/${ localStorage.getItem('userId') }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    empresas() {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/acompanhar/${ localStorage.getItem('pId') }/${ localStorage.getItem('userId') }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    responderPendencia(pendencia) {
        return this.http.post<any>(
        `${ environment.icadUrl }/pendencia/responder`, JSON.stringify(pendencia),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
    );
    }
}