<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Redesim</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolo != null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Requerente</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor"
                [class.valor-nao-cadastrado]="protocolo.redeSim.requerenteNome == ''">
                    {{ protocolo.redeSim.requerenteNome == '' ? 'Não Cadastrado' : protocolo.redeSim.requerenteNome }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>CPF</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor"
                [class.valor-nao-cadastrado]="protocolo.redeSim.requerenteCpf == ''">
                    {{ protocolo.redeSim.requerenteCpf == '' ? 'Não Cadastrado' : protocolo.redeSim.requerenteCpf | mask: '000.000.000-00' }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>E-mail</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor"
                [class.valor-nao-cadastrado]="protocolo.redeSim.email == ''">
                    {{ protocolo.redeSim.email == '' ? 'Não Cadastrado' : protocolo.redeSim.email }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Protocolo</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor"
                [class.valor-nao-cadastrado]="protocolo.redeSim.protocoloRedeSim == ''">
                    {{ protocolo.redeSim.protocoloRedeSim == '' ? 'Não Cadastrado' : protocolo.redeSim.protocoloRedeSim }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>CNPJ do Negócio</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor"
                [class.valor-nao-cadastrado]="protocolo.redeSim.cnpjDoNegocio == ''">
                    {{ protocolo.redeSim.cnpjDoNegocio == '' ? 'Não Cadastrado' : protocolo.redeSim.cnpjDoNegocio | mask: '00.000.000/0000-00' }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Evento(s)</strong>
                </p>
            </div>
            <div class="col-10">
                <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center" *ngFor="let redeSimEvento of protocolo.redeSim.redeSimEventos">
                    <div class="col-12">
                        <p class="card-text" *ngIf="!dadosAuditor"
                        [class.valor-nao-cadastrado]="redeSimEvento.codigoEvento == ''">
                        {{ redeSimEvento.codigoEvento == '' ? 'Não Cadastrado' : redeSimEvento.codigoEvento }} - {{ redeSimEvento.descricaoEvento == '' ? 'Não Cadastrado' : redeSimEvento.descricaoEvento }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Simples Nacional</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor">
                    {{ protocolo.redeSim.simplesNacional ? 'Sim' : 'Não' }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Data Simples Nacional</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor"
                [class.valor-nao-cadastrado]="protocolo.redeSim.dataInclusaoSimplesNacional == ''">
                    {{ protocolo.redeSim.dataInclusaoSimplesNacional == '' ? 'Não Cadastrado' : protocolo.redeSim.dataInclusaoSimplesNacional }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>SIMEI</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor">
                    {{ protocolo.redeSim.simei ? 'Sim' : 'Não' }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Data SIMEI</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor"
                [class.valor-nao-cadastrado]="protocolo.redeSim.dataInclusaoSimei == ''">
                    {{ protocolo.redeSim.dataInclusaoSimei == '' ? 'Não Cadastrado' : protocolo.redeSim.dataInclusaoSimei }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Possui Estabelecimento</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor">
                    {{ protocolo.redeSim.possuiEstabelecimento ? 'Sim' : 'Não' }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Cód Situação Cadastral RFB</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor"
                [class.valor-nao-cadastrado]="protocolo.redeSim.codigoSituacaoCadastral == ''">
                    {{ protocolo.redeSim.codigoSituacaoCadastral == '' ? 'Não Cadastrado' : protocolo.redeSim.codigoSituacaoCadastral }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Desc Situação Cadastral RFB</strong>
                </p>
            </div>
            <div class="col-10">
                <p class="card-text" *ngIf="!dadosAuditor"
                [class.valor-nao-cadastrado]="protocolo.redeSim.descricaoSituacaoCadastral == ''">
                    {{ protocolo.redeSim.descricaoSituacaoCadastral == '' ? 'Não Cadastrado' : protocolo.redeSim.descricaoSituacaoCadastral }}
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="card col-12 pl-0"> 
            <div class="form-row row-linha justify-content-start align-items-center">
                <div class="col-12">
                    <p class="card-text" id="lbl-analise-entorno">
                        <button 
                            type="button" id="btn-analise-entorno"
                            (click)="questionarioLicenciamento()"
                            class="btn btn-primary col-12">
                            <svg class="icone">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'map' }}"
                                ></use>
                            </svg>
                            Redesim - Questionário de Licenciamento
                        </button>
                    </p>
                </div>
            </div>
            </div>
    </div>
</div>