import { Servico } from "./servico";

export class Processo {
    cliente: string;
    codigo: string;
    descricao: string;
    inscricao: string;
    protocolo: string;
    tipo: string;
    tipoPessoa: string;
    servicos: Array<Servico>;
    statusCodigo: string;
    statusDescricao: string;
    data: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.descricao = "";
        this.inscricao = "";
        this.protocolo = "";
        this.tipo = "";
        this.tipoPessoa = "";
        this.servicos = new Array<Servico>();
        this.statusCodigo = "";
        this.statusDescricao = "";
        this.data = "";
    }
}