import { Orgao } from './orgao';

export class ConsultaViabilidadeRequisito{
    cliente: string;
    codigo: string;
    orgao: Orgao;
    nome: string;
    preRequisito: boolean;
    processo: string;
    obrigatorio: boolean;
    bloqueiaDocumento: string;
    status: string;

    constructor(){
        this.cliente = "";
        this.codigo = "";
        this.orgao = new Orgao();
        this.nome = "";
        this.preRequisito = false;
        this.processo = "";
        this.obrigatorio = false;
        this.bloqueiaDocumento = "";
        this.status = "";
    }
}