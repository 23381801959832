import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatMenuModule } from "@angular/material/menu";

import { GridEnderecoComponent } from "./grid-endereco.component";
import { GridAcompanhamentoComponent } from "./grid-acompanhamento.component";
import { GridAtividadeComponent } from "./grid-atividade.component";
import { PipeModule } from "src/app/pipes/pipes.module";
import { GridPermissoesComponent } from './grid-permissoes.component';
import { GridLogradouroBairroComponent } from './grid-logradouro-bairro.component';
import { GridUsuariosComponent } from './grid-usuarios.component';
import { GridAtualizacaoOficioComponent } from './grid-atualizacao-oficio.component';
import { GridDamComponent } from './grid-dam.component';

@NgModule({
    declarations: [ 
        GridEnderecoComponent,
        GridAcompanhamentoComponent,
        GridAtividadeComponent,
        GridPermissoesComponent,
        GridLogradouroBairroComponent,
        GridUsuariosComponent,
        GridAtualizacaoOficioComponent,
        GridDamComponent
    ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTooltipModule,
        MatMenuModule,
        PipeModule
    ],
    exports: [ 
        GridEnderecoComponent,
        GridAcompanhamentoComponent,
        GridAtividadeComponent,
        GridPermissoesComponent,
        GridLogradouroBairroComponent,
        GridUsuariosComponent,
        GridAtualizacaoOficioComponent,
        GridDamComponent,
    ]
})
export class GridModule {}