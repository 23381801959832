<div ngbDropdown container="body" class="btn-group">
    <button type="button" class="btn btn-success dropdown-toggle" ngbDropdownToggle>Atendimento</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <a class="dropdown-item" href="javascript:void(0)" (click)="deferir()">Deferir</a>
        <a class="dropdown-item" href="javascript:void(0)" (click)="indeferir()">Indeferir</a>
        <a class="dropdown-item" href="javascript:void(0)" (click)="pendenciar()">Pendenciar</a>
        <a class="dropdown-item" href="javascript:void(0)" (click)="pendenciarOrgao()">Pendenciar Orgão</a>
        <a class="dropdown-item" href="javascript:void(0)" (click)="despachar()">Despachar</a>
        <a class="dropdown-item" href="javascript:void(0)" (click)="cancelar()">Cancelar</a>
        <a *ngIf="licenciamento != null && licenciamento.licenciamentoFluxos.length"
            class="dropdown-item" 
            href="javascript:void(0)" 
            (click)="questionario()">
            E-Licenciamento
        </a>
        <a *ngIf="licenciamento != null && licenciamento.situacaoLicenca && licenciamento.statusDoLicenciamento == tipoDePendenciaLicenciamento.Deferido && !documentoLicencaBloqueadaParaLiberar()"
            class="dropdown-item" 
            href="javascript:void(0)" 
            (click)="imprimirLicenca()">
            Imprimir Licença
        </a>
        <a *ngIf="licenciamento != null && licenciamento.situacaoLicenca && licenciamento.statusDoLicenciamento == tipoDePendenciaLicenciamento.Deferido && documentoLicencaBloqueadaParaLiberar()"
            class="dropdown-item" 
            href="javascript:void(0)" 
            (click)="liberarBloquearDocumento(tipoDocumento.Lic)">
            Liberar Licença
        </a>
        <a *ngIf="licenciamento != null && licenciamento.situacaoLicenca && licenciamento.statusDoLicenciamento == tipoDePendenciaLicenciamento.Deferido && !documentoLicencaBloqueadaParaLiberar()"
            class="dropdown-item" 
            href="javascript:void(0)" 
            (click)="liberarBloquearDocumento(tipoDocumento.Lic)">
            Bloquear Licença
        </a>
        <a *ngIf="licenciamento != null && licenciamento.possuiRegraBloqueioQuestionario && licenciamento.questionarioBloqueado"
            class="dropdown-item" 
            href="javascript:void(0)" 
            (click)="liberarBloquearQuestionario()">
            Liberar Questionário
        </a>
        <a *ngIf="licenciamento != null && licenciamento.possuiRegraBloqueioQuestionario && !licenciamento.questionarioBloqueado"
            class="dropdown-item" 
            href="javascript:void(0)" 
            (click)="liberarBloquearQuestionario()">
            Bloquear Questionário
        </a>
        <a *ngIf="licenciamento != null && licenciamento.questionarioBloqueado && licenciamento.documentoTipo != '' && licenciamento.documentoTipo != tipoDocumento.Cdp_Transp.toString() && documentoQuestionarioBloqueadoParaLiberar()"
            class="dropdown-item" 
            href="javascript:void(0)" 
            (click)="liberarBloquearDocumento(licenciamento.documentoTipo)">
            Liberar {{ tipoDocumentoDescricao[licenciamento.documentoTipo] }}
        </a>
        <a *ngIf="licenciamento != null && licenciamento.questionarioBloqueado && licenciamento.documentoTipo != '' && licenciamento.documentoTipo != tipoDocumento.Cdp_Transp.toString() && !documentoQuestionarioBloqueadoParaLiberar()"
            class="dropdown-item" 
            href="javascript:void(0)" 
            (click)="liberarBloquearDocumento(licenciamento.documentoTipo)">
            Bloquear {{ tipoDocumentoDescricao[licenciamento.documentoTipo] }}
        </a>
        <a *ngIf="licenciamento != null && licenciamento.questionarioBloqueado && licenciamento.documentoTipo != '' && !documentoQuestionarioBloqueadoParaLiberar()"
            class="dropdown-item" 
            href="javascript:void(0)" 
            (click)="regerarDocumento(licenciamento.documentoTipo)">
            Regerar {{ tipoDocumentoDescricao[licenciamento.documentoTipo] }}
        </a>
        <a *ngIf="licenciamento != null && licenciamento.questionarioBloqueado && licenciamento.documentoTipo != '' && !documentoQuestionarioBloqueadoParaLiberar()"
            class="dropdown-item" 
            href="javascript:void(0)" 
            (click)="imprimirDocumentoQuestionarioBloqueado()">
            Imprimir {{ tipoDocumentoDescricao[licenciamento.documentoTipo] }}
        </a>
    </div>
</div>