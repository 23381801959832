import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filtro"
})
export class FiltroPipe implements PipeTransform {
    
    transform(itens: any[], filtro: any) {
        if (itens == null)
            return [];

        if ( filtro.campo.split(".").length == 1) {
            if (filtro.tipoValor == "==") {
                return itens.filter( item => item[filtro.campo].toString() == filtro.valor.toString());
            }
            else if (filtro.tipoValor == "!=") {
                return itens.filter( item => item[filtro.campo].toString() != filtro.valor.toString());
            }
            else if (filtro.tipoValor == "length") {
                return itens.filter( item => item[filtro.campo].length);
            }
            else {
                return itens.filter( item => item[filtro.campo].toString() == filtro.valor.toString());
            }
        }
        else {
            if (filtro.tipoValor == "==") {
                return itens.filter( item => item[filtro.campo.split(".")[0]][filtro.campo.split(".")[1]].toString() == filtro.valor.toString());
            }
            else if (filtro.tipoValor == "!=") {
                return itens.filter( item => item[filtro.campo.split(".")[0]][filtro.campo.split(".")[1]].toString() != filtro.valor.toString());
            }
            else {
                return itens.filter( item => item[filtro.campo.split(".")[0]][filtro.campo.split(".")[1]].toString() == filtro.valor.toString());
            }
        }
    }

}