<app-alert type="warning" >
    Caro contribuinte, confira todas as informações.
</app-alert>
<app-alert *ngIf="apenasCorrespondencia"
            type="warning" >
    Caro contribuinte, você selecionou o tipo de endereço “Endereço somente para Correspondência”.
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <app-informacao-requerente
        [class.hidden]="processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais && processo != tipoProcesso.Consulta_Previa && processo != tipoProcesso.Baixa && processo != tipoProcesso.Consulta_Previa && processo != tipoProcesso.Recadastramento && processo != tipoProcesso.Oficio && processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais_Evento"
        [tipoEmpresa]="tipoEmpresa">
    </app-informacao-requerente>

    <!-- Fic / Alteração -->
    <app-informacao-identificacao 
        [class.hidden]="processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais && processo != tipoProcesso.Dados_Cadastrais && processo != tipoProcesso.Baixa && processo != tipoProcesso.Recadastramento && processo != tipoProcesso.Oficio && processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais_Evento" 
        [tipoEmpresa]="tipoEmpresa">
    </app-informacao-identificacao>

    <app-informacao-localizacao [tipoEmpresa]="tipoEmpresa"></app-informacao-localizacao>

    <app-informacao-encerramento
        [class.hidden]="processo != tipoProcesso.Baixa"
    ></app-informacao-encerramento>

    <app-informacao-atividade 
        [class.hidden]="processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais && processo != tipoProcesso.Consulta_Previa && processo != tipoProcesso.Baixa && processo != tipoProcesso.Consulta_Previa && processo != tipoProcesso.Recadastramento && processo != tipoProcesso.Oficio && processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais_Evento"
        [tipoEmpresa]="tipoEmpresa">
    </app-informacao-atividade> 

    <!-- Fic / Alteração -->
    <app-informacao-responsavel 
        [class.hidden]="processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais && processo != tipoProcesso.Dados_Cadastrais && processo != tipoProcesso.Recadastramento">
    </app-informacao-responsavel>

    <app-informacao-questionario 
        #informacaoQuestionarioViabilidade
        [class.hidden]="processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais && processo != tipoProcesso.Consulta_Previa"
        titulo="Consulta Prévia" id="div-questionario-consulta-previa">
    </app-informacao-questionario>

    <!-- Fic / Alteração -->
    <app-informacao-questionario 
        #informacaoQuestionarioFic
        [class.hidden]="processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais && processo != tipoProcesso.Dados_Cadastrais" 
        titulo="Dados Cadastrais" id="div-questionario-dados-cadastrais" >
    </app-informacao-questionario>

    <app-informacao-questionario 
        #informacaoQuestionarioViabilidadeEvento
        [class.hidden]="processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais_Evento"
        titulo="Consulta Prévia Evento" id="div-questionario-consulta-previa">
    </app-informacao-questionario>

    <!-- Fic / Alteração -->
    <app-informacao-questionario 
        #informacaoQuestionarioFicEvento
        [class.hidden]="processo != tipoProcesso.Consulta_Previa_E_Dados_Cadastrais_Evento" 
        titulo="Dados Cadastrais Evento" id="div-questionario-dados-cadastrais" >
    </app-informacao-questionario>

    <!-- Baixa / Encerramento -->
    <app-informacao-questionario 
        #informacaoQuestionarioEncerramento
        [class.hidden]="processo != tipoProcesso.Baixa" 
        titulo="Baixa Empresa" id="div-questionario-baixa" >
    </app-informacao-questionario>

    <!--  Recadastramento -->
    <app-informacao-questionario
        #informacaoQuestionarioRecadastro
        [class.hidden]="processo != tipoProcesso.Recadastramento"
        titulo="Recadastramento" id="div-questionario-recadastramento">
    </app-informacao-questionario>
    
    <!-- Anexos -->
    <app-anexar 
        class="m-3" 
        [dados]="[numeroProtocolo, '', '100', 'RESUMO']" >
    </app-anexar>

    <app-atendimento-acao *ngIf="processo == tipoProcesso.Oficio"></app-atendimento-acao>

    <div class="card" *ngIf="cliente == '9999' && (tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV')">
        <div class="card-header">
            <div class="col-12">
                <strong>Documentação Necessária</strong>
            </div>
        </div>
        <div class="card-body"> 
            <div    class="form-row row-linha">
                <div class="col-11">
                    <p class="card-text">
                        ATENÇÃO: NECESSÁRIO ANEXAR OS DOCUMENTOS DESCRITOS ABAIXO
                        <BR>
                            - Cópia do contrato social/declaração de firma individual/estatuto, com o devido registro no órgão competente;<br />
                            - Cópia do CNPJ;<br />
                            - Cópia da declaração de ME, EPP ou MEI, caso seja optante do Simples Nacional cópia da declaração;<br />
                            - Cópia do RG e CPF;
                        <BR>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header">
            <div class="col-12">
                <strong>Termo de Responsabilidade</strong>
            </div>
        </div>
        <div class="card-body"> 
            <div    class="form-row row-linha">
                <div class="row col-1 justify-content-center align-items-center">
                    <mat-checkbox 
                        formControlName="termoDeAceite"
                        id="termoDeAceite"
                        name="termoDeAceite">
                    </mat-checkbox>
                </div>
                <div class="col-11">
                    <p class="card-text" *ngIf="tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">
                        "Declaro sob pena de Lei, que todas as informações aqui prestadas são verdadeiras e que estou ciente das penalidades cabíveis em caso de omissão ou informação que não represente a verdade."
                    </p>
                    <p class="card-text" *ngIf="tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV'">
                        ATENÇÃO: É OBRIGATÓRIO O PRAZO MÍNIMO DE 30 DIAS DE ANTECEDÊNCIA DO EVENTO PARA ABERTURA DO PROCESSO ADMINISTRATIVO PARA OBTENÇÃO DE ALVARÁ PARA EVENTO TEMPORÁRIO.
                        <BR>
                        DECLARO PARA FINS DE CONCESSÃO DE ALVARÁ PARA EVENTO TEMPORÁRIO, ESTAR CIENTE DAS OBRIGAÇÕES E RESPONSABILIDADE QUE LHES CABEM, EM ESPECIAL, AS SEGUINTES:<br>
                        - CUMPRIR O CONTROLE DE EMISSÃO DE RUÍDOS ACÚSTICOS, OBRIGANDO-SE A RESPEITAR OS LIMITES DE EMISSÃO SONORAS ESTABELECIDOS PELA LEI MUNICIPAL Nº 5.421/2012, CONFORME PARECER TÉCNICO EMITIDO PELA SECRETÁRIA MUNICIPAL DE MEIO AMBIENTE;<br>
                        - É OBRIGATÓRIO A LIMPEZA DE LOCAIS PÚBLICOS APÓS A REALIZAÇÃO DE EVENTOS, SUJEITO A PENALIDADE CONFORME PREVISTO NO 4º, DO ARTIGO 162 DA LEI ORGÂNICA DO MUNICÍPIO, CONFORME PARECER TÉCNICO EMITIDO PELA SECRETARIA DE MEIO AMBIENTE;<br>
                        - CUMPRIR LEGISLAÇÃO VIGENTE, NO QUE SE REFERE À PREVENÇÃO CONTRA INCÊNDIO E PÂNICO EM EVENTOS TEMPORÁRIOS DE ACORDO COM A LEI ESTATUAL Nº 10.130/2001, JUNTO AO CORPO DE BOMBEIROS MILITAR;<br>
                        - É NECESSÁRIO A AUTORIZAÇÃO JUDICIAL PARA ENTRADA E PERMANÊNCIA DE CRIANÇA E/OU ADOLESCENTE DESACOMPANHADO DOS PAIS OU RESPONSÁVEL, DE ACORDO COM A LEI FEDERAL Nº 8.069/1990, JUNTO AO JUIZADO DA INFÂNCIA E DA JUVENTUDE, SE NECESSÁRIO;<br>
                        - ESTOU CIENTE QUE DECLARAR FATO QUE SABER SER INVERÍDICO, PODE VIR A CONSTITUIR CRIME APURÁVEL PELO MINISTÉRIO PÚBLICO NA FORMA DA LEI.
                        <BR>
                    </p>
                </div>
            </div>
        </div>
    </div>


</form>