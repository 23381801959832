<div class="justify-content-md-end">
    <div class="card mb-0">
        <div class="card-header">
            <div class="col-12">
                <strong>Calendário Fiscal</strong>
            </div>
        </div>
    </div>
    <div class="row col-lg-12 col-12 pl-0 pr-0">
        <div class="col-12 pl-0 pr-0">
            <div class="card">
                <div class="card-body p-0 mb-2">
                    <div class="row justify-content-start align-items-center">
                        <div class="col-12 card-header-linha">
                            <p class="card-text pt-2 pb-2">
                                <b>AUDITOR, UTILIZE UMA DAS OPÇÕES PARA LOCALIZAR OS PROCESSAMENTOS REALIZADOS PELO CENTRO DE CUSTO SELECIONADO.</b>
                            </p>                    
                        </div>
                    </div>
                </div>
                <div class="card-body card-body-localizacao">
                <form
                    name="formulario"
                    [formGroup]="formulario"
                >
                    <div class="row justify-content-center align-items-center"> 
                        <div class="col-5">
                            <select 
                                #codigoCentroDeCustoSelect
                                class="form-control form-control-sm"
                                formControlName="codigoCentroDeCusto"
                                name="codigoCentroDeCusto" 
                                id="codigoCentroDeCusto"                
                            >
                                <option disabled value="">--- Selecione ---</option>
                                <option *ngFor="let centroDeCusto of centrosDeCusto; let i = index"
                                    [value]="centroDeCusto.codigoCentroDeCusto" 
                                    >{{ centroDeCusto.codigoCentroDeCusto }} - {{ centroDeCusto.identificacao }}
                                </option>
                            </select>
                        </div>
                        <div class="col-5">
                            <select 
                                #codigoCalendarioFiscalSelect
                                class="form-control form-control-sm"
                                formControlName="codigoCalendarioFiscal"
                                (change)="acaoSelectCalendarioFiscal()"
                                name="codigoCalendarioFiscal" 
                                id="codigoCalendarioFiscal"                
                            >
                                <option disabled value="">--- Selecione ---</option>
                                <option *ngFor="let calendarioFiscal of calendariosFiscaisAtivos; let i = index"
                                    [value]="calendarioFiscal.codigo" 
                                    >{{ calendarioFiscal.codigo }} - {{ calendarioFiscal.identificacao }}
                                </option>
                            </select>
                        </div>
                        <div class="col-2 text-center">
                            <button 
                                type="button" 
                                class="btn btn-secondary"
                                title="Buscar"
                                (click)="buscarCalendarioFiscalPorCentroDeCusto()"
                            >
                            Buscar Calendário
                            </button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row col-12 row-linha pt-2 pb-2">
        <p class="card-text">
            Caro Auditor, logo abaixo estão as informações do calendário atual do processamento e o novo calendário que será aplicado caso a conclusão seja realizada.<br><br>
            <b>1ª ATENÇÃO</b>: Caso os lançamentos possuam parcelá única, a mesma será atualizada com o primeiro vencimento.<br>
            <b>2ª ATENÇÃO</b>: Parcelas já pagas ou finalizadas não terão impacto.<br>
        </p>
    </div> 

    <div class="row col-12 mt-2">
        <div class="col-1"></div>
        <div class="card col-4 p-0 justify-content-start align-items-center">
            <div class="card-body col-12 p-0 mb-2">
                <div class="row justify-content-start align-items-center">
                    <div class="col-12 texto-danger-invertido">
                        <p class="card-text pt-2 pb-2 texto-danger-invertido text-center">
                            <b>CALENDÁRIO ATUAL</b>
                        </p>                    
                    </div>
                </div>
                <div *ngFor="let calendarioFiscalData of calendarioFiscalAtual.calendarioFiscalDatas; let i = index">
                    <div class="row"
                        [class.row-cinza]="i % 2 != 0">
                        <div class="form-group col-6 mt-3">
                            <p class="card-text text-center">
                                <strong>{{ calendarioFiscalData.numero }}ª Parcela</strong>
                            </p>
                        </div>
                        <div class="form-group col-6 mt-3">
                            <p class="card-text text-center">
                                {{ calendarioFiscalData.data }}
                            </p>
                        </div>
                    </div>
                </div>
                <div *ngIf="calendarioFiscalAtual.calendarioFiscalDatas == null || !calendarioFiscalAtual.calendarioFiscalDatas.length">
                    <div class="row">
                        <div class="form-group col-12 mt-4">
                            <p class="card-text text-center">
                                <strong>Não existe(m) data(s) ativa(s)</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-2"></div>
        <div class="card col-4 p-0 justify-content-start align-items-center">
            <div class="card-body col-12 p-0 mb-2">
                <div class="row justify-content-start align-items-center">
                    <div class="col-12 texto-success-invertido">
                        <p class="card-text pt-2 pb-2 texto-success-invertido text-center">
                            <b>CALENDÁRIO REVISADO</b>
                        </p>                    
                    </div>
                </div>
                <div *ngFor="let calendarioFiscalDataRevisado of calendarioFiscalRevisado.calendarioFiscalDatas; let i = index">                
                    <div class="row"
                        [class.row-cinza]="i % 2 != 0">
                        <div class="form-group col-6 mt-3">
                            <p class="card-text text-center">
                                <strong>{{ calendarioFiscalDataRevisado.numero }}ª Parcela</strong>
                            </p>
                        </div>
                        <div class="form-group col-6 mt-3">
                            <p class="card-text text-center">
                                {{ calendarioFiscalDataRevisado.data }}
                            </p>
                        </div>
                    </div>
                </div>
                <div *ngIf="calendarioFiscalRevisado.calendarioFiscalDatas == null || !calendarioFiscalRevisado.calendarioFiscalDatas.length">
                    <div class="row">
                        <div class="form-group col-12 mt-4">
                            <p class="card-text text-center">
                                <strong>Não existe(m) data(s) para ser revisada(s)</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="row col-12">
            <div class="row col-6 justify-content-start align-items-center">
                <button type="button" class="btn btn-danger" (click)="acaoBotaoVoltar()">Voltar</button>
            </div>
            <div class="row col-6 justify-content-end align-items-center divisao-esquerda">
            <button type="button" class="btn btn-success" (click)="acaoBotaoSalvar()">Concluir</button>
            </div>
        </div>
    </div>
</div>