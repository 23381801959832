import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EmpresaService {

    constructor(private http: HttpClient) {}

    buscarPorInscricao(cliente, Inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarPorInscricao/${ cliente }/${ Inscricao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorInscricaoDadosBasicos(cliente, Inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarPorInscricaoDadosBasicos/${ cliente }/${ Inscricao }`,
        {
            reportProgress: true,
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorInscricaoSemLicenciamentos(cliente, Inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarPorInscricaoSemLicenciamentos/${ cliente }/${ Inscricao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        } 
        );
    }

    buscarPorInscricaoSemLicenciamentosParaAtendimento(cliente, Inscricao, orgao, menu) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarPorInscricaoSemLicenciamentosParaAtendimento/${ cliente }/${ Inscricao }/${ orgao }/${ menu }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorInscricaoSemLicenciamentosSomenteAtendimento(cliente, Inscricao, orgao, menu) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarPorInscricaoSemLicenciamentosSomenteAtendimento/${ cliente }/${ Inscricao }/${ orgao }/${ menu }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorInscricaoParaAtendimentoDeLicenciamento(cliente, Inscricao, orgao, requisito, menu) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarPorInscricaoParaAtendimentoDeLicenciamento/${ cliente }/${ Inscricao }/${ orgao }/${ requisito }/${ menu }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorInscricaoParaPendenciaDeLicenciamento(cliente, Inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarPorInscricaoParaPendenciaDeLicenciamento/${ cliente }/${ Inscricao }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorInscricaoParaPendenciaDeLicenciamentoPorOrgaoERequisito(cliente, Inscricao, Orgao, Requisito) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarPorInscricaoParaPendenciaDeLicenciamentoPorOrgaoERequisito/${ cliente }/${ Inscricao }/${ Orgao }/${ Requisito }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    

    buscarPorInscricaoParaBaixa(cliente, Inscricao, cnpjCpf) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarPorInscricaoParaBaixa/${ cliente }/${ Inscricao }/${ cnpjCpf }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    detalhar(cliente, Inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/detalhar/${ cliente }/${ Inscricao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarParaAlteracao(cliente, CpfCnpj, Inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarParaAlteracao/${ cliente }/${ CpfCnpj }/${ Inscricao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarParaRecadastro(cliente, CpfCnpj, Inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/empresa/buscarParaRecadastro/${ cliente }/${ CpfCnpj }/${ Inscricao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarEmpresasPorDocumento(cliente, protocolo, documento){
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/buscarEmpresasPorDocumento/${ cliente }/${ protocolo }/${ documento }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
            );
    }

    buscarDadosBasicos(cliente, inscricao, cpfCnpj){
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/buscarDadosBasicos/${ cliente }/${ inscricao }/${ cpfCnpj }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarDadosBasicosComAutenticacao(cliente, inscricao){
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/buscarDadosBasicosComAutenticacao/${ cliente }/${ inscricao }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarDadosBasicosParaAtendimentoDeBaixa(cliente, inscricao){
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/buscarDadosBasicosParaAtendimentoDeBaixa/${ cliente }/${ inscricao }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    verificaPeriodoRecadastro(cliente){
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/verificaPeriodoRecadastro/${ cliente }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    verificaPermissaoEmpresa(cliente, inscricao, cpfCnpj){
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/verificaPermissaoEmpresa/${ cliente }/${ inscricao }/${ cpfCnpj }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    cicloDeVida(cliente, inscricao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/cicloDeVida/${ cliente }/${ inscricao }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    validarInscricaoMunicipal(cliente, inscricao, cpfCnpj){
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/validarInscricaoMunicipal/${ cliente }/${ inscricao }/${ cpfCnpj }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    permiteAtendimento(cliente, inscricao, protocolo){
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/permiteAtendimento/${ cliente }/${ inscricao }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    existeSuspensao(cliente, inscricao, cpfCnpj){
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/existeSuspensao/${ cliente }/${ inscricao }/${ cpfCnpj }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscaSuspensaoPorInscricao(cliente, inscricao){
        return this.http.get<any>(
            `${ environment.icadUrl }/empresa/buscaSuspensaoPorInscricao/${ cliente }/${ inscricao }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

}
