// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  icadUrl: "http://10.0.34.37:8888/rest",
  icadUrlV2: "http://10.0.34.37:8888/rest",
  ciphersUrl: "http://10.0.34.37:8888/rest",
  keyGoogleMaps: "AIzaSyDJ-6QsF_1F48UdBKTZrY69g0LReNq5BPE",
  // icadUrl: "https://api.eiconbrasil.com.br/icad-servicos/api-v1",
  // ciphersUrl: "https://api.eiconbrasil.com.br/icad-servicos/api-v1",
  // icadUrl: "/rest",
  // ciphersUrl: "/rest",
  appId: "C2C5E736-DB91-46FB-9539-138BD18A359D",

  // production: false,
  // prucUrl: "http://back-dev-pruc.apps.intranet.local/api/v1",
  // srvBaseUrl: "http://srv-0015.eicon.com.br/base",
  // ciphersUrl: "http://login-dev-pruc.apps.intranet.local/api/v1",
  // appId: "C2C5E736-DB91-46FB-9539-138BD18A359D",
  
  powerBI: {
    reportBaseURL: 'https://app.powerbi.com/reportEmbed',
    qnaBaseURL: 'https://app.powerbi.com/qnaEmbed',
    tileBaseURL: 'https://app.powerbi.com/embed',
    groupID: 'f2899fe3-d94c-4a81-8ef3-9b77992ba51d',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
