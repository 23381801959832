import { MatSort, SortDirection } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginatorIntl, MatPaginator } from "@angular/material/paginator";
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';

import { Grid } from "src/app/model/grid";
import { GridProviderPaginacao } from "src/app/components/grid/grid-provider-paginacao";

import { MatDialog } from "@angular/material/dialog";
import { GridFiltro } from "src/app/model/gridFiltro";
import { Constantes } from '../../../../model/constantes';
import { formatToCPFOrCNPJ } from 'brazilian-values';
import { MascaraPipe } from '../../../../pipes/mascara.pipe';
import { SelectionModel } from '@angular/cdk/collections';
import { TaxaLancamentoParcela } from '../../../../model/taxaLancamentoParcela';
import Swal from "sweetalert2";

@Component({
  selector: 'app-grid-memoria-de-calculo',
  templateUrl: './grid-memoria-de-calculo.component.html',
  styleUrls: ['./grid-memoria-de-calculo.component.scss'],
  providers: [ 
      {
          provide: MatPaginatorIntl,
          useClass: GridProviderPaginacao
      }
  ]
})
export class GridMemoriaDeCalculoComponent implements OnInit {
  
  grid: Grid;
  
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = ["codigoLancamento","descricaoRegra","regraPositiva","acao","tipo","valor","subTotal","total"];
  linhaSelecionada: SelectionModel<TaxaLancamentoParcela>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Output() acaoMenuAtendimento = new EventEmitter();

  mensagemDeBusca: string = "Não existem memória de cálculo(s) ativa(s).";

  tipoTaxaRegraDescricao = Constantes.TIPO_TAXA_REGRA_DESCRICAO;
  acaoTaxaRegraDescricao = Constantes.ACAO_TAXA_REGRA_DESCRICAO;


  constructor(
    private mascara: MascaraPipe,
    public dialog: MatDialog,) { 
      this.grid = new Grid();
      this.grid.filtro.push(new GridFiltro());
    }

  ngOnInit(): void {
  }

  atualizarTabela() {

    this.dataSource = new MatTableDataSource(this.grid.dados);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
    this.linhaSelecionada = new SelectionModel<TaxaLancamentoParcela>(true);
    this.sort.active = "vencimento";
    this.sort.direction = <SortDirection>"ASC".toLowerCase();

    this.dataSource.sort = this.sort;
  }

  formatToCPFOrCNPJ(valor) {
    return formatToCPFOrCNPJ(valor.toString());
  }

  formatToBRL(valor) {
      return this.mascara.transform(valor, 11);
  }

  aplicarFiltro(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  ordenacaoGrid(data, sort) {
      if (sort.active == null)
          return data;

      let campo = sort.active.replace("servico", "servicoDescricao");

      if (campo == "adicionado") {
        data.sort( (item_1, item_2) => (new Date(item_1[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) > new Date(item_2[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (campo == "dataSituacao") {
          data.sort( (item_1, item_2) => (new Date(item_1[campo].split("/").reverse().toString()) > new Date(item_2[campo].split("/").reverse().toString()) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (campo == "inscricao") {
          data.sort( (item_1, item_2) => (item_1[campo] > item_2[campo] ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (typeof(data[0][campo]) == typeof("0")) {
          data.sort( (item_1, item_2) => (item_1[campo]).localeCompare(item_2[campo])  * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (typeof(data[0][campo]) == typeof(0)) {
          data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo])) ? 0 : item_1[campo] ) - ( isNaN(parseInt(item_2[campo])) ? 0 : item_2[campo] ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      
      return data;
  }

}
