import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AtendimentoService } from './services/operacao/atendimento.service';
import { take } from 'rxjs/operators';
import { AuthenticationService } from './services/authentication/authentication.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { IcadModalComponent } from './components/modal/icad-modal/icad-modal.component';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "datta-able";

  constructor(private router: Router,
    public dialog: MatDialog,
    public authenticationService: AuthenticationService,
    private atendimentoService: AtendimentoService) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

  }
  
  abrirChat() {
    const currentUser = this.authenticationService.currentUserValue;

    this.atendimentoService
        .urlChat(currentUser.perfil)
        .pipe(take(1))
        .subscribe( retorno => {
          Swal.fire({
              title: "", 
              html: retorno[1],
              icon: "question",
              showConfirmButton: retorno[2].find(item => item == "chat") ? true : false,
              showDenyButton: retorno[2].find(item => item == "email") ? true : false,
              showCancelButton: true,
              confirmButtonText: retorno[3] ? "Suporte" : "Chat",
              denyButtonText: "E-mail",})
              .then((result) => {
                if (result.isConfirmed) {
                  window.open(retorno[0]);
                } 
                else if (result.isDenied) {
                  this.popUpEmail()
                }
              });
        });
  }

  popUpEmail() {
    const dialogRef = this.dialog.open(IcadModalComponent, {
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        maxHeight: 'none',
        closeOnNavigation: true,
        data: {
            titulo: "Enviar E-mail",
            componente: "enviar-email-suporte",
            dados: []
        }
    });

    // dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
  }
}
