import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { formatToCapitalized } from "brazilian-values";

import Swal from "sweetalert2";
import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Atividade } from '../../../model/atividade';
import { AtividadeService } from '../../../services/atividade/atividade.service';
import { FiltroPipe } from '../../../pipes/filtro.pipe';
import { Constantes } from '../../../model/constantes';
import { IcadModalComponent } from '../../../components/modal/icad-modal/icad-modal.component';

@Component({
    selector: "app-form-atividade-pesquisa",
    templateUrl: "./form-atividade-pesquisa.component.html",
    styleUrls: ["./form-atividade-pesquisa.component.scss"]
})
export class FormAtividadePesquisaComponent implements OnInit {

    @Input() titulo = "Atividades";
    @Input() limitarQuantidadeAtividades = true;

    tipoEmpresa: string;
    tipoEmpresaBusca: string;

    formulario: FormGroup;

    atividades: Array<Atividade>;
    atividadesSelecionadas: Array<Atividade>;
    
    emitirErro: boolean;
    mensagemErro: string;
    abrirPainel: boolean;

    tipoEmpresaConstantes = Constantes.TIPO_EMPRESA;

    constructor(
        private formBuilder: FormBuilder,
        private atividadeService: AtividadeService,
        private activatedRoute: ActivatedRoute,
        private filtro: FiltroPipe,
        public dialog: MatDialog) {

        this.tipoEmpresa = "";

        this.atividades = new Array<Atividade>();
        this.atividadesSelecionadas = new Array<Atividade>();
        
        this.emitirErro = false;
        this.abrirPainel = true;
            
        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]]
        });
    }

    ngOnInit(): void {}

    buscarAtividades(abrirPouUp) {
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento){
            this.atividadeService
                .buscarCbo(localStorage.getItem("pId"))
                .pipe(take(1))
                .subscribe( retorno => {
                    this.atividades = retorno;
                    
                    if (abrirPouUp) {
                        this.abrirPopUp();
                    }
                });
        }
        else{
            this.atividadeService
                .buscarCnae(localStorage.getItem("pId"))
                .pipe(take(1))
                .subscribe( retorno => {
                    this.atividades = retorno;
                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {  
                        this.atividades = this.filtro.transform(retorno, {campo: "mei", valor: true} );
                    }
                    
                    if (abrirPouUp) {
                        this.abrirPopUp();
                    }
                });
        }
    }
    
    acaoBotaoAdicionarAtividade() {
        if (!this.validarBusca()) {
            return false;
        }

        if (!this.atividades.length || this.tipoEmpresa != this.tipoEmpresaBusca) {
            this.tipoEmpresaBusca = this.tipoEmpresa;
            this.buscarAtividades(true);
            return true;
        }

        this.abrirPopUp();
    }

    abrirPopUp() {
        let limite = this.limitarQuantidadeAtividades ? 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo || 
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ? 1 : 6 : 0;
        this.openDialog([this.atividades, this.atividadesSelecionadas, Constantes.TIPO_EMPRESA.Empresa, limite]);        
    }

    acaoExcluirAtividadeEscolhida(index) {
        this.atividadesSelecionadas.splice(index, 1);
    }

    formatarNomeAtividade(nome) {
        return formatToCapitalized(nome);
    }

    openDialog(dados) {
        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                titulo: "Lista de Atividades",
                componente: "grid-atividade",
                dados: dados
            }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result === "" || result === null)
                return false;

            this.atividadesSelecionadas = result.atividades.selected;
        });
    }

    openDialogCbo(dados) {
        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                titulo: "Lista de Atividades",
                componente: "grid-atividade",
                dados: dados
            }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result === "" || result === null)
                return false;

            this.atividadesSelecionadas = result.atividades.selected;
        });
    }

    validarFormulario() {
        this.controleValidade.setValue("");
        
        if (this.formulario.invalid) {
            let mensagemErro = "";
            
            if (!this.atividadesSelecionadas.length) {
                mensagemErro += "Favor adicionar suas atividades.<br>"
            }

            if (mensagemErro === "") {
                this.controleValidade.setValue(true);
                this.emitirErro = false;
                return true;
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErro = true;
            this.mensagemErro = mensagemErro;

            return false;
        }

        this.emitirErro = false;
        this.mensagemErro = "";
        
        return true;
    }

    validarBusca() {
        if (this.tipoEmpresa == "") {
            let mensagemErro = "Favor escolher o tipo do seu negócio para buscar as atividades relacionadas a ele.";
            
            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErro = true;
            this.mensagemErro = mensagemErro;

            return false;
        }

        this.emitirErro = false;
        this.mensagemErro = "";
    
        return true;
    }

    get controleValidade() {
        return this.formulario.get("controleValidade");
    }
    set controleValidade(controleValidade) {
        this.formulario.get("controleValidade").setValue(controleValidade);
    }

}