<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="row justify-content-center align-items-center header-sub-titulo">
        <div class="row col-12" >
            <p class="w-100 card-text titulo-sub-titulo text-center">
                <strong>SOLICITAÇÕES AO CONTRIBUINTE</strong>
            </p>
        </div>
    </div>
    <div class="row quadro-divisor" [perfectScrollbar]="config">
        <div class="w-100" *ngFor="let pendencia of dados[0]; let i = index;">
            <div class="w-100" *ngIf="(pendencia.fase != 'P' && pendencia.descricaoTipo != 'Crítica De Dados Cadastrais') || ((dados[2] | filtro: {campo: 'justificativa', valor: '', tipoValor: '!='}).length && pendencia.descricaoTipo == 'Crítica De Dados Cadastrais')">
                <div class="row">
                    <div class="row col-1 py-2 justify-content-left align-items-center">
                        <mat-checkbox 
                            #pendenciaCheckbox
                            formControlName="pendencia_{{ pendencia.codigo }}_{{ pendencia.fase }}"
                            id="pendencia_{{ pendencia.codigo }}"
                            name="pendencia_{{ pendencia.codigo }}"
                            (change)="exigeJustificativa(pendenciaCheckbox, i, 'ADICIONAR')">
                        </mat-checkbox>
                    </div>
                    <div class="row col-11 justify-content-left align-items-center text-uppercase">
                        <p class="card-text">
                            {{ pendencia.descricaoTipo }}
                        </p>
                   </div>
                </div>
                <div *ngFor="let pendenciaAdicionada of pendencias">
                    <div class="row justificativa-pendencia" *ngIf="pendencia.codigo == pendenciaAdicionada.codigo">
                        <div class="row col-11 justify-content-start align-items-center">
                            <p class="card-text">
                                <strong>Observações: </strong>{{ pendenciaAdicionada.fluxos[0].observacao }}
                            </p>
                        </div>
                        <div class="row col-1 justify-content-end align-items-center">
                            <button type="button" 
                                    matTooltip="Editar"
                                    matTooltipPosition="above"
                                    class="btn btn-sm"
                                    (click)="exigeJustificativa(pendenciaCheckbox, i, 'ALTERAR')">
                                <svg class="icone-editar">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                                    ></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="mt-5 form-row row-linha justify-content-center align-items-center linha-botao">
        <button 
            type="button" 
            class="btn btn-success"
            title="pendenciar"
            (click)="pendenciar()">
            Confirmar e Pendênciar
        </button>
    </div>
</form>