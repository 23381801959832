<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card">
         <div class="card-body">
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="processoAdministrativo">Processo Administrativo</label>
                    <input
                        #processoAdministrativoInput
                        class="form-control form-control-sm text-center"
                        id="processoAdministrativo"
                        name="processoAdministrativo"
                        formControlName="processoAdministrativo"
                        type="text"
                        maxlength="30"
                    />
                    <div class="mb-1" *ngIf="processoAdministrativo.invalid && processoAdministrativo.touched">
                        <span 
                            class="erro" 
                            *ngIf="processoAdministrativo.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="dataInicio">Data Início da Suspensão</label>
                    <input
                        #dataInicioInput
                        mask="00/00/0000"
                        class="form-control form-control-sm text-center"
                        id="dataInicio"
                        name="dataInicio"
                        type="text"
                        formControlName="dataInicio"
                        maxlength="10"
                    />
                    <div class="mb-1" *ngIf="dataInicio.invalid && dataInicio.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataInicio.errors.minlength"
                        >Data inválida</span>
                        <span 
                            class="erro" 
                            *ngIf="dataInicio.hasError('mensagem')"
                        >{{dataInicio.errors.mensagem}}</span>
                    </div>
                </div>
                <div class="form-group col-3" *ngIf="codigoMenu != 179">
                    <label class="col-sm-12 col-form-label" for="dataFim">Data Fim da Suspensão</label>
                    <input
                        #dataFimInput
                        mask="00/00/0000"
                        class="form-control form-control-sm text-center"
                        id="dataFim"
                        name="dataFim"
                        type="text"
                        formControlName="dataFim"
                        maxlength="10"
                    />
                    <div class="mb-1" *ngIf="dataFim.invalid && dataFim.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataFim.errors.minlength"
                        >Data inválida</span>
                        <span 
                            class="erro" 
                            *ngIf="dataFim.hasError('mensagem')"
                        >{{dataFim.errors.mensagem}}</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12" *ngIf="codigoMenu != 179"> 
                    <label class="col-sm-12 col-form-label" for="observacao">Observações Gerais</label>
                    <textarea #observacaoTextArea
                        class="form-control form-control-sm"
                        id="observacao"
                        name="observacao"
                        formControlName="observacao"
                        maxlength="500"
                        cols="30" 
                        rows="5"
                    ></textarea>
                    <div class="mb-1">
                        <span 
                            class="erro" 
                            *ngIf="observacao.invalid && observacao.touched && observacao.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="observacao.invalid && observacao.touched && observacao.errors.maxlength"
                        >A descrição deve conter até 500 caracteres.</span>
                        <span class="quantidade-caracter">
                            Quantidade de Caracteres: {{ observacao.value.length }} / 500
                        </span>
                    </div>
                </div>
            </div>
                <div class="row col-12 top-atendimento mt-2">
                  <div class="row col-12  pt-3 pb-3">
                    <div class="row col-12 justify-content-end align-items-center divisao-esquerda">
                      <button type="button" class="btn btn-success" (click)="acaoBotaoSuspender()" *ngIf="codigoMenu == 179">Suspender</button>
                      <button type="button" class="btn btn-success" (click)="acaoBotaoSuspender()" *ngIf="codigoMenu != 179">Cancelar Suspensão</button>
                    </div>
                  </div>
                </div>
        </div> 
    </div>
    
</form>