<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Protocolos</strong>
        </div>
    </div>
    <div *ngIf="protocolos == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolos != null" class="card-body p-0">
        <div *ngIf="protocolos.length" class="row justify-content-center align-items-center header-atividades">
            <div class="row col-1 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Protocolo</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Requerente</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Serviço</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Fase</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Situação</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Início</strong>
                </p>
            </div>
            <div class="row col-1"></div>
        </div>
        <div *ngFor="let protocolo of protocolos"
            class="row row-linha-documentos linha-grid-divisao-top">
            <div class="row col-1 justify-content-center align-items-center">
                <p class="card-text titulo fs-10">
                    <strong>{{ protocolo.protocolo }}</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-start align-items-center">
                <p class="card-text titulo fs-10"
                    matTooltip="{{ protocolo.requerente.nome }}"
                    matTooltipPosition="below">
                    <strong>{{ protocolo.requerente.nome.length > 35 ? protocolo.requerente.nome.substring(0, 35) + '...' : protocolo.requerente.nome }}</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo text-center fs-10"
                    matTooltip="{{ protocolo.servicoDescricao }}"
                    matTooltipPosition="below">
                    <svg 
                        class="iconeGrid">
                        <use
                          attr.xlink:href="#{{ protocolo.servicoIcone }}"
                        ></use>
                    </svg><br>
                    <strong>{{ protocolo.servicoDescricao }} </strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo">
                    <strong>{{ protocolo.faseDescricao }}</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo text-center fs-10"
                    matTooltip="{{protocolo.situacaoDescricao}}"
                    matTooltipPosition="below"
                    class="{{ protocolo.situacaoClasse }}">
                    <svg class="iconeGrid">
                        <use
                          attr.xlink:href="assets/sprite.svg#{{ protocolo.situacaoIcone }}"
                        ></use>
                    </svg><br>
                    <strong>{{ protocolo.situacaoDescricao }}</strong>
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo fs-10">
                    <strong>{{ protocolo.dataSituacao }}</strong>
                </p>
            </div>
            <div class="row col-1 justify-content-end align-items-center">
                <button class="btn btn-menu p-0 m-0" mat-button [matMenuTriggerFor]="beforeMenu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <button class="btn" mat-menu-item 
                        (click)="abrirQuestionario(protocolo.protocolo)"
                    >
                        <svg class="icone-grid-menu m-2"><use attr.xlink:href="assets/sprite.svg#{{ 'book-open' }}"></use></svg> Questionário(s)
                    </button>
                    <button class="btn" mat-menu-item 
                        (click)="abrirRedeSim(protocolo.protocolo)"
                    >
                        <svg class="icone-grid-menu m-2"><use attr.xlink:href="assets/sprite.svg#{{ 'book-open' }}"></use></svg> Dados REDESIM
                    </button>
                </mat-menu>
            </div>
        </div>
        <div *ngIf="!protocolos.length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                    Não existem protocolos(s) ativo(s).
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>
