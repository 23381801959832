import { Component, OnInit, Input, Output, HostListener, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

import { AuthenticationService } from "../../../../services/authentication/authentication.service";
import Swal from 'sweetalert2';

@Component({
  selector: "app-usuario",
  templateUrl: "./usuario.component.html",
  styleUrls: ["./usuario.component.scss"],
})
export class UsuarioComponent implements OnInit {
  @Input() showUserDropdown: boolean;
  @Output() clickUserDropDown = new EventEmitter();
  @Input() user: boolean;
  @Input() webUser: boolean;

  fotoCliente: string;
  usuarioInfos: any;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fotoCliente = this.authenticationService.currentFotoValue;
    this.usuarioInfos = this.authenticationService.currentUserValue.infos;

    this.authenticationService.fotoAlterada.subscribe( evento => {
      if (evento.acao) {
        this.fotoCliente = this.authenticationService.currentFotoValue;
      }
    });
  }

  redirecionaSelecaoPerfil() {
    this.router.navigate(["/selecionar-estrutura"]);
  }

  redirecionaAlterarSenha() {
    this.router.navigate(["/alterar-senha"]);
  }

  redirecionarPerfil(){
    this.router.navigate(["/operacao/perfil"]);
  }

  logout() {
    this.authenticationService.logout();
  }
}
