import { Component, Input, OnInit, Output, Pipe, EventEmitter } from '@angular/core';

import { Atividade } from '../../../../../model/atividade';
import { formatToCapitalized } from 'brazilian-values';
import { AtividadeService } from '../../../../../services/atividade/atividade.service';
import { Constantes } from '../../../../../model/constantes';
import { take, filter } from 'rxjs/operators';
import { FiltroPipe } from '../../../../../pipes/filtro.pipe';
import { MatSelectionList } from '@angular/material/list';

@Component({
    selector: 'app-form-requisito-list',
    templateUrl: './form-requisito-list.component.html',
    styleUrls: ['../../gerenciamento-de-requisitos.component.scss'],
  })
  export class FormRequisitoListComponent implements OnInit {
    
    @Input() dados: Array<any>;
    @Output() selecao: EventEmitter<any> = new EventEmitter<any>()
    @Input() tipoEmpresa: string;
    tiposDeEmpresa = Constantes.TIPO_EMPRESA;

    atividadesDisponiveis: Array<Atividade>
    atividadesSelecionadas: Array<Atividade>
    
    constructor(private atividadeService: AtividadeService) {}
    
    ngOnInit(): void {
        this.atividadesSelecionadas = this.dados[0];
        this.tipoEmpresa = this.dados[1];
        this.buscarAtividades();
    }

    formatarNomeAtividade(nome) {
        return formatToCapitalized(nome);
    }

    buscarAtividades() {
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Estabelecido ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Nao_Estabelecido
        ) {
                this.atividadeService
                .buscarCbo(localStorage.getItem("pId"))
                .pipe(take(1))
                .subscribe( retorno => {
                    if (this.dados[2] != null) {
                        var listaDeAtividadesNaoListadas = "";
                        this.dados[2].forEach( atividadeNaoListadaEncontrada => {
                            listaDeAtividadesNaoListadas = listaDeAtividadesNaoListadas.concat(atividadeNaoListadaEncontrada.codigo).concat(",")
                        });
                        this.atividadesDisponiveis = retorno.filter( atividade => listaDeAtividadesNaoListadas.search(atividade.codigo) == -1)
                    }
                    else {
                        this.atividadesDisponiveis = retorno;
                    }

                    this.inicializar();
                });
        }
        else{
            this.atividadeService
                .buscarCnae(localStorage.getItem("pId"))
                .pipe(take(1))
                .subscribe( retorno => {
                    this.atividadesDisponiveis = retorno;
                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {  
                        this.atividadesDisponiveis = retorno.filter( item => item.mei);
                    }
                    this.inicializar();
                });
        }
    }

    inicializar() {
        if (this.atividadesSelecionadas != null) {
            this.atividadesSelecionadas.forEach( item => {
                var index = this.atividadesDisponiveis.findIndex( atividade => { return atividade.codigo == item.codigo});
                if (index != -1) {
                    this.atividadesDisponiveis.splice(index, 1 );
                }
            });
        }
    }

    acaoSelecionadosParaDireita(listaDeAtividadesDisponiveis: MatSelectionList) {
        listaDeAtividadesDisponiveis.selectedOptions.selected.forEach( item => {
            this.atividadesDisponiveis.splice( this.atividadesDisponiveis.findIndex( atividade => { return atividade.codigo == (<Atividade>item.value).codigo}), 1 );
            this.atividadesSelecionadas.push((<Atividade>item.value));
        });
    }
    
    acaoSelecionadosParaEsquerda(listaDeAtividadesSelecionadas: MatSelectionList) {
        listaDeAtividadesSelecionadas.selectedOptions.selected.forEach( item => {
            this.atividadesSelecionadas.splice( this.atividadesSelecionadas.findIndex( atividade => { return atividade.codigo == (<Atividade>item.value).codigo}), 1 );
            this.atividadesDisponiveis.push((<Atividade>item.value));
        });
    }

    acaoTodosParaDireita() {
        this.atividadesSelecionadas = this.atividadesDisponiveis.concat(this.atividadesSelecionadas);
        this.atividadesDisponiveis = new Array<Atividade>();
    }
    
    acaoTodosParaEsquerda() {
        this.atividadesDisponiveis = this.atividadesDisponiveis.concat(this.atividadesSelecionadas);
        this.atividadesSelecionadas = new Array<Atividade>();
    }

    limpar() {
        this.atividadesSelecionadas = new Array<Atividade>();
        this.atividadesDisponiveis = new Array<Atividade>();
    }

    acaoBotaoConcluir() {
        this.selecao.emit({ atividades: this.atividadesSelecionadas });
    }

    acaoDoFiltro(event: Event, nomeClasse) {
        const valor = (event.target as HTMLInputElement).value;

        var rex = new RegExp(valor, 'i');
        $('.todo-box-scroll .' + nomeClasse).hide();
        $('.todo-box-scroll .' + nomeClasse).filter(function() {
            return rex.test($(this).text());
        }).show();
    }

  }