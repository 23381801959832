<div class="container-principal">
    <mat-tab-group mat-align-tabs="start" selectedIndex="2" (selectedIndexChange)="selecaoAba($event)">
        <mat-tab label="Estatísticas">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
                    <img src="../../../../../assets/img/icons/dashboard.svg" style="width: 15px;"/>
                </mat-icon> Estatísticas
            </ng-template>
            <app-estatisticas-taxa></app-estatisticas-taxa>
        </mat-tab>
        <mat-tab label="Operação por Lote">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
                    <img src="../../../../../assets/img/icons/pie-chart.svg" style="width: 15px;"/>
                </mat-icon> Operação por Lote
            </ng-template>
            <app-operacao-lote></app-operacao-lote>
        </mat-tab>
        <mat-tab label="Operação Individual">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
                    <img src="../../../../../assets/img/icons/credit-card.svg" style="width: 15px;"/>
                </mat-icon> Operação Individual
            </ng-template>
            <app-operacao-taxa></app-operacao-taxa>
        </mat-tab>
    </mat-tab-group>
</div>