<div class="justify-content-md-end">
    <form name="formulario" 
        [formGroup]="formulario" 
        class="justify-content-center align-items-center"
        >
        <app-alert type="danger"
            *ngIf="emitirErro" >
            <div [innerHtml]="mensagemErro"></div>
        </app-alert>
        <div class="card">
            <div class="card-header">
                <div class="col-12">
                    <strong>Dados da Taxa</strong>
                </div>
            </div>
            <div class="card-body p-0 mb-2">
                <div class="row mt-3">
                    <div class="form-group col-8">
                        <label>Identificação</label>
                        <input
                            #inputIdentificacao
                            [class.is-invalid]="identificacao.invalid && identificacao.touched"
                            class="form-control form-control-sm"
                            formControlName="identificacao"
                            id="identificacao"
                            name="identificacao"
                            type="text"
                            maxlength="200"
                            id="identificacao" 
                            name="identificacao"
                            placeholder="Informe o nome da taxa"
                        />
                        <div class="mb-1" *ngIf="identificacao.invalid && identificacao.touched">
                            <span class="erro" *ngIf="identificacao.errors.required">Campo obrigatório</span>
                            <span class="erro" 
                                *ngIf="identificacao.hasError('mensagem')"
                            >{{identificacao.errors.mensagem}}</span>
                        </div>
                    </div>
                    <div class="form-group col-2">
                      <label>Exercício</label>
                      <input
                          #inputExercicio
                          [class.is-invalid]="exercicio.invalid && exercicio.touched"
                          class="form-control form-control-sm text-center"
                          formControlName="exercicio"
                          id="exercicio"
                          name="exercicio"
                          type="text"
                          mask="0000"
                          [showMaskTyped]="true"
                          id="exercicio" 
                          name="exercicio"
                          placeholder="Informe o ano da taxa"
                      />
                      <div class="mb-1" *ngIf="exercicio.invalid && exercicio.touched">
                        <span class="erro" *ngIf="exercicio.errors.required">Campo obrigatório</span>
                        <span class="erro" 
                              *ngIf="exercicio.hasError('mensagem')"
                          >{{exercicio.errors.mensagem}}</span>
                      </div>
                    </div>
                    <div class="form-group col-2">
                        <label>Valor</label>
                        <input
                            #inputValorLancamento
                            [class.is-invalid]="valorLancamento.invalid && valorLancamento.touched"
                            (input)="formatToBRL(inputValorLancamento.value, 'valorLancamento')"
                            class="form-control form-control-sm text-center"
                            formControlName="valorLancamento"
                            id="valorLancamento"
                            name="valorLancamento"
                            type="text"
                            maxlength="12"
                            prefix="R$ "
                            mask="separator"
                            thousandSeparator="."
                            placeHolderCharacter=""
                            [showMaskTyped]="true"
                            id="valorLancamento" 
                            name="valorLancamento"
                            placeholder="Valor mínimo"
                        />
                        <div class="mb-1" *ngIf="valorLancamento.invalid && valorLancamento.touched">
                            <span class="erro" *ngIf="valorLancamento.errors.required">Campo obrigatório</span>
                            <span class="erro" 
                                *ngIf="valorLancamento.hasError('mensagem')"
                            >{{valorLancamento.errors.mensagem}}</span>
                        </div>
                    </div>
                </div>
            <div class="row mt-3">
            </div>
                <div class="row col-12 row-linha pb-4">
                    <p class="card-text">
                    * Se atentar a configuração de exercício, essa configuração determina se o lançamento ocorrerá de acordo com a data da ação.<br>
                    </p>
                </div>
            </div>  
        </div>
        <div class="card">
            <div class="card-header">
                <div class="col-12">
                    <strong>Vencimento da Taxa</strong>
                </div>
            </div>
            <div class="card-body p-0 mb-2">
                <div class="row col-12 row-linha pb-2">
                    <p class="card-text">
                    Caro Auditor, logo abaixo você informará como será o comportamento dos lançamentos e também quais as condições de desconto e/ou juros no momento do pagamento de acordo com o vencimento.
                    </p>
                </div>
            </div>
            <div class="card-body p-0 mb-2 mt-4">
                <div class="row mt-3">
                    <div class="row col-12 justify-content-start align-items-center">
                    Lançamento Automático
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="row col-2 justify-content-start align-items-center">
                        <mat-radio-group 
                            formControlName="imTipoVencimento" 
                            (change)="acaoImTipoVencimento()"
                            aria-label="Status">
                            <mat-radio-button value="1">
                                <div class="row mt-3 justify-content-start align-items-center">
                                    <div class="form-group col-3 pl-0">
                                    <strong>A partir de:</strong>
                                    </div>
                                </div>
                            </mat-radio-button><br>
                            <mat-radio-button value="2">
                              <div class="row mt-3 justify-content-start align-items-center">
                                <div class="form-group col-3 pl-0">
                                  <strong>Fato Gerador</strong>
                                </div>
                              </div>
                            </mat-radio-button><br>
                            <mat-radio-button value="3">
                              <div class="row mt-3 justify-content-start align-items-center">
                                <div class="form-group col-3 pl-0">
                                  <strong>Calendário Fiscal</strong>
                                </div>
                              </div>
                            </mat-radio-button>
                        </mat-radio-group> 
                    </div>
                    <div class="form-group col-8" *ngIf="imTipoVencimento.value == '3'">
                      <label>Calendário Fiscal</label>
                      <select name="imCalendarioFiscal" 
                        formControlName="imCalendarioFiscal" 
                        id="imCalendarioFiscal" 
                        class="form-control"
                        >
                        <option *ngFor="let calendarioFiscal of calendariosFiscaisAtivos" 
                          value="{{ calendarioFiscal.codigo }}">
                          {{ calendarioFiscal.codigo }} - {{ calendarioFiscal.identificacao }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-2" *ngIf="imTipoVencimento.value != '3'">
                        <label>Referência</label>
                        <input
                            #inputImReferencia
                            [class.is-invalid]="imReferencia.invalid && imReferencia.touched"
                            class="form-control form-control-sm text-center"
                            formControlName="imReferencia"
                            id="imReferencia"
                            name="imReferencia"
                            type="text"
                            mask="00/00/0000"
                            [showMaskTyped]="true"
                            id="imReferencia" 
                            name="imReferencia"
                            placeholder=""
                        />
                        <div class="mb-1" *ngIf="imReferencia.invalid && imReferencia.touched">
                            <span class="erro" *ngIf="imReferencia.errors.required">Campo obrigatório</span>
                            <span class="erro" 
                                *ngIf="imReferencia.hasError('mensagem')"
                            >{{imReferencia.errors.mensagem}}</span>
                        </div>
                    </div>
                    <div class="form-group col-2" *ngIf="imTipoVencimento.value != '3'">
                        <label>Primeiro Vencimento</label>
                        <input
                            #inputImPrimeiroVencimento
                            [class.is-invalid]="imPrimeiroVencimento.invalid && imPrimeiroVencimento.touched"
                            class="form-control form-control-sm text-center"
                            formControlName="imPrimeiroVencimento"
                            id="imPrimeiroVencimento"
                            name="imPrimeiroVencimento"
                            type="text"
                            mask="990"
                            suffix=" dia(s)."
                            placeHolderCharacter=""
                            [showMaskTyped]="true"
                            id="imPrimeiroVencimento" 
                            name="imPrimeiroVencimento"
                            placeholder="Quantidade de dias"
                        />
                        <div class="mb-1" *ngIf="imPrimeiroVencimento.invalid && imPrimeiroVencimento.touched">
                            <span class="erro" *ngIf="imPrimeiroVencimento.errors.required">Campo obrigatório</span>
                            <span class="erro" 
                                *ngIf="imPrimeiroVencimento.hasError('mensagem')"
                            >{{imPrimeiroVencimento.errors.mensagem}}</span>
                        </div>
                    </div>
                    <div class="form-group col-2" *ngIf="imTipoVencimento.value != '3'">
                        <label>Demais Vencimentos</label>
                        <input
                            #inputImDemaisVencimentos
                            [class.is-invalid]="imDemaisVencimentos.invalid && imDemaisVencimentos.touched"
                            class="form-control form-control-sm text-center"
                            formControlName="imDemaisVencimentos"
                            id="imDemaisVencimentos"
                            name="imDemaisVencimentos"
                            type="text"
                            mask="990"
                            suffix=" dia(s)."
                            placeHolderCharacter=""
                            [showMaskTyped]="true"
                            id="imDemaisVencimentos" 
                            name="imDemaisVencimentos"
                            placeholder="Quantidade de dias"
                        />
                        <div class="mb-1" *ngIf="imDemaisVencimentos.invalid && imDemaisVencimentos.touched">
                            <span class="erro" *ngIf="imDemaisVencimentos.errors.required">Campo obrigatório</span>
                            <span class="erro" 
                                *ngIf="imDemaisVencimentos.hasError('mensagem')"
                            >{{imDemaisVencimentos.errors.mensagem}}</span>
                        </div>
                    </div>
                    <div class="form-group col-2" *ngIf="imTipoVencimento.value != '3'">
                        <label>N.º de Parcelas</label>
                        <input
                            #inputImNumeroParcelas
                            [class.is-invalid]="imNumeroParcelas.invalid && imNumeroParcelas.touched"
                            class="form-control form-control-sm text-center"
                            formControlName="imNumeroParcelas"
                            id="imNumeroParcelas"
                            name="imNumeroParcelas"
                            type="text"
                            mask="990"
                            suffix=" parcela(s)."
                            placeHolderCharacter=""
                            [showMaskTyped]="true"
                            id="imNumeroParcelas" 
                            name="imNumeroParcelas"
                            placeholder="Quantidade de parcelas"
                        />
                        <div class="mb-1" *ngIf="imNumeroParcelas.invalid && imNumeroParcelas.touched">
                            <span class="erro" *ngIf="imNumeroParcelas.errors.required">Campo obrigatório</span>
                            <span class="erro" 
                                *ngIf="imNumeroParcelas.hasError('mensagem')"
                            >{{imNumeroParcelas.errors.mensagem}}</span>
                        </div>
                    </div>
                    <div class="form-group col-2">
                        <label>Parcela Mínima</label>
                        <input
                            #inputImParcelaMinima
                            [class.is-invalid]="imParcelaMinima.invalid && imParcelaMinima.touched"
                            (input)="formatToBRL(inputImParcelaMinima.value, 'imParcelaMinima')"
                            class="form-control form-control-sm text-center"
                            formControlName="imParcelaMinima"
                            id="imParcelaMinima"
                            name="imParcelaMinima"
                            type="text"
                            maxlength="10"
                            prefix="R$ "
                            mask="separator"
                            thousandSeparator="."
                            placeHolderCharacter=""
                            [showMaskTyped]="true"
                            id="imParcelaMinima" 
                            name="imParcelaMinima"
                            placeholder="Valor mínimo"
                        />
                        <div class="mb-1" *ngIf="imParcelaMinima.invalid && imParcelaMinima.touched">
                            <span class="erro" *ngIf="imParcelaMinima.errors.required">Campo obrigatório</span>
                            <span class="erro" 
                                *ngIf="imParcelaMinima.hasError('mensagem')"
                            >{{imParcelaMinima.errors.mensagem}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="card-body p-0 mb-2 mt-2">
                <div class="row mt-3">
                    <div class="form-group col-2">
                        <label>Tipo de Desconto</label>
                        <select name="tipoDesconto" 
                            formControlName="tipoDesconto" 
                            (change)="tipoDesconto.value == '$' ? valorDesconto.setValue('00,000') : valorDesconto.setValue('0,00')"
                            id="tipoDesconto" 
                            class="form-control"
                            >
                            <option value="$">Moeda</option>
                            <option value="%">Porcentagem</option>
                        </select>
                    </div>
                    <div class="form-group col-2" *ngIf="tipoDesconto.value == '$'">
                        <label>Valor Desconto</label>
                        <input
                            #inputValorDesconto
                            [class.is-invalid]="valorDesconto.invalid && valorDesconto.touched"
                            (input)="formatToBRL(inputValorDesconto.value, 'valorDesconto')"
                            class="form-control form-control-sm text-center"
                            formControlName="valorDesconto"
                            id="valorDesconto"
                            name="valorDesconto"
                            type="text"
                            maxlength="13"
                            prefix="R$ "
                            mask="separator"
                            thousandSeparator="."
                            placeHolderCharacter=""
                            [showMaskTyped]="true"
                            placeholder="Valor mínimo"
                        />
                    </div>
                    <div class="form-group col-2" *ngIf="tipoDesconto.value == '%'">
                        <label>Valor Desconto</label>
                        <input
                            #inputValorDesconto
                            [class.is-invalid]="valorDesconto.invalid && valorDesconto.touched"
                            (input)="formatToBRL(inputValorDesconto.value, 'valorDesconto')"
                            class="form-control form-control-sm text-center"
                            formControlName="valorDesconto"
                            id="valorDesconto"
                            name="valorDesconto"
                            type="text"
                            mask="separator"
                            thousandSeparator="."
                            maxlength="6"
                        />
                    </div>
                    <div class="form-group col-2">
                        <label>Cobrança Desconto</label>
                        <select name="formaCobrancaDesconto" 
                            formControlName="formaCobrancaDesconto" 
                            id="formaCobrancaDesconto" 
                            class="form-control"
                            >
                            <option value="PU">Parcela Única</option>
                            <option *ngFor="let formaCobranca of formaCobrancaSelect; let i = index"
                            value="{{formaCobranca.value}}">
                            {{ formaCobranca.label }}
                            </option> 
                        </select>
                    </div>
                    <div class="form-group col-2">
                        <label>Desconto aplicado até</label>
                        <input
                            #inputQtdDiasDesconto
                            [class.is-invalid]="qtdDiasDesconto.invalid && qtdDiasDesconto.touched"
                            class="form-control form-control-sm text-center"
                            formControlName="qtdDiasDesconto"
                            id="qtdDiasDesconto"
                            name="qtdDiasDesconto"
                            type="text"
                            mask="990"
                            suffix=" dia(s)."
                            placeHolderCharacter=""
                            [showMaskTyped]="true"
                            id="qtdDiasDesconto" 
                            name="qtdDiasDesconto"
                            placeholder="Quantidade de dias"
                        />
                        <div class="mb-1" *ngIf="qtdDiasDesconto.invalid && qtdDiasDesconto.touched">
                            <span class="erro" *ngIf="qtdDiasDesconto.errors.required">Campo obrigatório</span>
                            <span class="erro" 
                                *ngIf="qtdDiasDesconto.hasError('mensagem')"
                            >{{qtdDiasDesconto.errors.mensagem}}</span>
                        </div>
                    </div>
                    <div class="form-group col-2">
                        <label>Receber em até</label>
                        <input
                            #inputqtdDiasRecebimento
                            [class.is-invalid]="qtdDiasRecebimento.invalid && qtdDiasRecebimento.touched"
                            class="form-control form-control-sm text-center"
                            formControlName="qtdDiasRecebimento"
                            id="qtdDiasRecebimento"
                            name="qtdDiasRecebimento"
                            type="text"
                            mask="990"
                            suffix=" dia(s)."
                            placeHolderCharacter=""
                            [showMaskTyped]="true"
                            id="qtdDiasRecebimento" 
                            name="qtdDiasRecebimento"
                            placeholder="Quantidade de dias"
                        />
                        <div class="mb-1" *ngIf="qtdDiasRecebimento.invalid && qtdDiasRecebimento.touched">
                            <span class="erro" *ngIf="qtdDiasRecebimento.errors.required">Campo obrigatório</span>
                            <span class="erro" 
                                *ngIf="qtdDiasRecebimento.hasError('mensagem')"
                            >{{qtdDiasRecebimento.errors.mensagem}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="card-body p-0 mb-2 mt-2">
                <div class="row mt-3">
                    <div class="form-group col-2">
                        <label>Tipo de Mora</label>
                        <select name="tipoMora" 
                            formControlName="tipoMora" 
                            (change)="tipoMora.value == '$' ? valorMora.setValue('00,000') : valorMora.setValue('0,00')"
                            id="tipoMora" 
                            class="form-control"
                            >
                            <option value="$">Moeda</option>
                            <option value="%">Porcentagem</option>
                        </select>
                    </div>
                    <div class="form-group col-2" *ngIf="tipoMora.value == '$'">
                        <label>Valor Mora</label>
                        <input
                            #inputValorMora
                            [class.is-invalid]="valorMora.invalid && valorMora.touched"
                            (input)="formatToBRL(inputValorMora.value, 'valorMora')"
                            class="form-control form-control-sm text-center"
                            formControlName="valorMora"
                            id="valorMora"
                            name="valorMora"
                            type="text"
                            maxlength="13"
                            prefix="R$ "
                            mask="separator"
                            thousandSeparator="."
                            placeHolderCharacter=""
                            [showMaskTyped]="true"
                            placeholder="Valor mínimo"
                        />
                    </div>
                    <div class="form-group col-2" *ngIf="tipoMora.value == '%'">
                        <label>Valor Mora</label>
                        <input
                            #inputValorMora
                            [class.is-invalid]="valorMora.invalid && valorMora.touched"
                            (input)="formatToBRL(inputValorMora.value, 'valorMora')"
                            class="form-control form-control-sm text-center"
                            formControlName="valorMora"
                            id="valorMora"
                            name="valorMora"
                            type="text"
                            mask="separator"
                            thousandSeparator="."
                            maxlength="6"
                        />
                    </div>
                    <div class="form-group col-2">
                        <label>Cobrança Mora</label>
                        <select name="formaCobrancaMora" 
                            formControlName="formaCobrancaMora" 
                            id="formaCobrancaMora" 
                            class="form-control"
                            >
                            <option *ngFor="let formaCobranca of formaCobrancaSelect; let i = index"
                            value="{{formaCobranca.value}}">
                            {{ formaCobranca.label }}
                            </option> 
                        </select>
                    </div>
                </div>
            </div>
            <hr>
            <div class="card-body p-0 mb-2 mt-2">
                <div class="row mt-3">
                    <div class="form-group col-2">
                        <label>Tipo de Juros</label>
                        <select name="tipoJuros" 
                            formControlName="tipoJuros" 
                            (change)="tipoJuros.value == '$' ? valorJuros.setValue('00,000') : valorJuros.setValue('0,00')"
                            id="tipoJuros" 
                            class="form-control"
                            >
                            <option value="$">Moeda</option>
                            <option value="%">Porcentagem</option>
                        </select>
                    </div>
                    <div class="form-group col-2" *ngIf="tipoJuros.value == '$'">
                        <label>Valor Juros</label>
                        <input
                            #inputValorJuros
                            [class.is-invalid]="valorJuros.invalid && valorJuros.touched"
                            (input)="formatToBRL(inputValorJuros.value, 'valorJuros')"
                            class="form-control form-control-sm text-center"
                            formControlName="valorJuros"
                            id="valorJuros"
                            name="valorJuros"
                            type="text"
                            maxlength="13"
                            prefix="R$ "
                            mask="separator"
                            thousandSeparator="."
                            placeHolderCharacter=""
                            [showMaskTyped]="true"
                            placeholder="Valor mínimo"
                        />
                    </div>
                    <div class="form-group col-2" *ngIf="tipoJuros.value == '%'">
                        <label>Valor Juros</label>
                        <input
                            #inputValorJuros
                            [class.is-invalid]="valorJuros.invalid && valorJuros.touched"
                            (input)="formatToBRL(inputValorJuros.value, 'valorJuros')"
                            class="form-control form-control-sm text-center"
                            formControlName="valorJuros"
                            id="valorJuros"
                            name="valorJuros"
                            type="text"
                            mask="separator"
                            thousandSeparator="."
                            maxlength="6"
                        />
                    </div>
                    <div class="form-group col-2">
                        <label>Cobrança Juros</label>
                        <select name="formaCobrancaJuros" 
                            formControlName="formaCobrancaJuros" 
                            id="formaCobrancaJuros" 
                            class="form-control"
                            >
                            <option *ngFor="let formaCobranca of formaCobrancaSelect; let i = index"
                            value="{{formaCobranca.value}}">
                            {{ formaCobranca.label }}
                            </option> 
                        </select>
                    </div>
                </div>
            </div>
            <hr>
            <div class="card-body p-0 mb-2 mt-2">
                <div class="row mt-3">
                    <div class="form-group col-2">
                        <label>Tipo de Correção Monetária</label>
                        <select name="tipoCorrecaoMonetaria" 
                            formControlName="tipoCorrecaoMonetaria" 
                            id="tipoCorrecaoMonetaria" 
                            class="form-control"
                        >
                            <option value="$">Moeda</option>
                            <option value="%">Porcentagem</option>
                        </select>
                    </div>
                    <div class="form-group col-2">
                        <label>Valor Correção Monetária</label>
                        <select name="valorCorrecaoMonetaria" 
                            formControlName="valorCorrecaoMonetaria" 
                            id="valorCorrecaoMonetaria" 
                            class="form-control"
                        >
                            <option value="">Nenhum</option>
                            <option *ngFor="let indice of indicesCorrecao" 
                                value="{{ indice.codigo }}">
                                {{ indice.identificacao }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-2">
                        <label>Cobrança Correção Monetária</label>
                        <select name="formaCobrancaCorrecaoMonetaria" 
                            formControlName="formaCobrancaCorrecaoMonetaria" 
                            id="formaCobrancaCorrecaoMonetaria" 
                            class="form-control"
                        >
                            <option *ngFor="let formaCobranca of formaCobrancaSelect; let i = index"
                                value="{{formaCobranca.value}}">
                                {{ formaCobranca.label }}
                            </option> 
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="row col-12">
          <div class="row col-6 justify-content-start align-items-center">
              <button type="button" class="btn btn-danger" (click)="acaoBotaoVoltar()">Voltar</button>
          </div>
          <div class="row col-6 justify-content-end align-items-center divisao-esquerda">
            <button type="button" class="btn btn-success" (click)="acaoBotaoSalvar()">Concluir</button>
          </div>
        </div>
    </div>
</div>