<div id="navSection" data-spy="affix" class="nav  sidenav">
    <div class="sidenav-content">
        <a href="javascript:void(0);" (click)="goToAnchor('tabsRequerente')" class="active nav-link tabsRequerente">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                ></use>
            </svg> Requerente
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsRedeSim')" class="nav-link tabsRedeSim" *ngIf="protocolo.redeSim.protocoloRedeSim != null && protocolo.redeSim.protocoloRedeSim != ''">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                ></use>
            </svg> Redesim 
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsIdentificacao')" class="nav-link tabsIdentificacao" *ngIf="modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento || modulo == menuAtendimento.Baixa || modulo == menuAtendimento.Recadastro || modulo == menuAtendimento.Processos || eDespacho">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'briefcase' }}"
                ></use>
            </svg> Identificação
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsLocalizacao')" class="nav-link tabsLocalizacao">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'map' }}"
                ></use>
            </svg> Localização
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsResponsavel')" class="nav-link tabsResponsavel" *ngIf="modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento || modulo == menuAtendimento.Recadastro || modulo == menuAtendimento.Processos || eDespacho">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'users' }}"
                ></use>
            </svg> Responsáveis
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsImobiliario')" class="nav-link tabsImobiliario" *ngIf="modulo == menuAtendimento.Consulta_Previa || modulo == menuAtendimento.Consulta_Previa_Evento || eDespacho">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'map-pin' }}"
                ></use>
            </svg> Imobiliário
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsZoneamento')" class="nav-link tabsZoneamento" *ngIf="modulo == menuAtendimento.Consulta_Previa || modulo == menuAtendimento.Consulta_Previa_Evento || eDespacho">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'codepen' }}"
                ></use>
            </svg> Zoneamento
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsAtividades')" class="nav-link tabsAtividades">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'activity' }}"
                ></use>
            </svg> Atividades
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsRequisitos')" class="nav-link tabsRequisitos" *ngIf="modulo != menuAtendimento.Baixa || eDespacho">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'link' }}"
                ></use>
            </svg> Requisitos
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsDocumentos')" class="nav-link tabsDocumentos">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="#{{ 'segunda_via_documentos' }}"
                ></use>
            </svg>
            Documentos
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsQuestionario')" class="nav-link tabsQuestionario">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'book-open' }}"
                ></use>
            </svg> Questionário
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsAnexo')" class="nav-link tabsAnexo">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'upload-cloud' }}"
                ></use>
            </svg> Anexo(s)
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsTaxas')" class="nav-link tabsTaxas" *ngIf="moduloTaxaLiberado">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'credit-card' }}"
                ></use>
            </svg> DAM
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsLinhaDoTempo')" class="nav-link tabsLinhaDoTempo">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'activity' }}"
                ></use>
            </svg> Linha do Tempo
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsSeletorIm')" class="nav-link tabsSeletorIm" *ngIf="seletorIm">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'settings' }}"
                ></use>
            </svg> Seletor de Im
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsAcoes')" class="nav-link tabsAcoes">
            <svg class="icone-historico">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'trending-up' }}"
                ></use>
            </svg> Finalização
        </a>
    </div>
</div>


<div class="row col-10 layout-top-spacing p-3">
    <div class="row col-12 top-atendimento">
        <div class="col-lg-12 col-12 layout-spacing">
            <div class="row container-principal-atendimento container-tamanho pt-3 pb-3">
                <div class="row col-9 justify-content-center align-items-center">
                    <app-atendimento-avaliador class="w-100"></app-atendimento-avaliador>
                </div>
                <div class="row col-3 justify-content-center align-items-center">
                    <app-atendimento-delegar class="w-100"></app-atendimento-delegar>
                </div>
            </div>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsRequerente" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-atendimento-empresa-cnpj *ngIf="modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento || eDespacho"></app-atendimento-empresa-cnpj>
                <br>
                <app-informacao-requerente [tipoEmpresa]="tipoEmpresa"></app-informacao-requerente>
            </div>
        </div>
    </div>
    
    <div class="row col-12" *ngIf="protocolo.redeSim.protocoloRedeSim != null && protocolo.redeSim.protocoloRedeSim != ''">
        <div id="tabsRedeSim" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-informacao-rede-sim></app-informacao-rede-sim>
            </div>
        </div>
    </div>

    <div class="row col-12" *ngIf="modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento || modulo == menuAtendimento.Baixa || modulo == menuAtendimento.Recadastro || modulo == menuAtendimento.Processos || eDespacho">
        <div id="tabsIdentificacao" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-informacao-identificacao [tipoEmpresa]="tipoEmpresa" [permiteCriticar]="modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento" [permiteInconsistencias]="modulo == menuAtendimento.Recadastro"></app-informacao-identificacao>
            </div>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsLocalizacao" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-informacao-localizacao [tipoEmpresa]="tipoEmpresa" [permiteCriticar]="modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento" [permiteInconsistencias]="modulo == menuAtendimento.Recadastro"></app-informacao-localizacao>
            </div>
        </div>
    </div>

    <div class="row col-12" *ngIf="modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Recadastro || modulo == menuAtendimento.Processos || eDespacho">
        <div id="tabsResponsavel" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-informacao-responsavel [permiteCriticar]="modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento" [permiteInconsistencias]="modulo == menuAtendimento.Recadastro"></app-informacao-responsavel>
            </div>
        </div>
    </div>

    <div class="row col-12" *ngIf="modulo == menuAtendimento.Consulta_Previa || modulo == menuAtendimento.Consulta_Previa_Evento || eDespacho">
        <div id="tabsImobiliario" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-atendimento-imobiliario (imobiliarioCarregado)="acaoImobiliarioCarregado($event)"></app-atendimento-imobiliario>
            </div>
        </div>
    </div>

    <div class="row col-12" *ngIf="modulo == menuAtendimento.Consulta_Previa || modulo == menuAtendimento.Consulta_Previa_Evento || eDespacho">
        <div id="tabsZoneamento" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-atendimento-zoneamento (usoModificado)="acaoModificacaoUso($event)" *ngIf="modulo == menuAtendimento.Consulta_Previa || modulo == menuAtendimento.Consulta_Previa_Evento || eDespacho"></app-atendimento-zoneamento>
            </div>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsAtividades" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-atendimento-atividade [tipoEmpresa]="tipoEmpresa"></app-atendimento-atividade>
            </div>
        </div>
    </div>

    <div class="row col-12" *ngIf="modulo != menuAtendimento.Baixa || eDespacho">
        <div id="tabsRequisitos" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-atendimento-requisito></app-atendimento-requisito>
            </div>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsDocumentos" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-informacao-documento></app-informacao-documento>
            </div>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsQuestionario" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-informacao-questionario #informacaoQuestionarioConsultaPrevia titulo="de Consulta Prévia" *ngIf="modulo == menuAtendimento.Consulta_Previa || modulo == menuAtendimento.Dados_Cadastrais || eDespacho || (modulo == menuAtendimento.Processos && protocolo.processo.codigo != '27' && protocolo.processo.codigo != '28' && protocolo.processo.tipo != 'E' && protocolo.processo.tipo != 'R' && protocolo.processo.tipo != 'T')"></app-informacao-questionario>
                <app-informacao-questionario #informacaoQuestionarioConsultaPrevia titulo="de Consulta Prévia Evento" *ngIf="modulo == menuAtendimento.Consulta_Previa_Evento || modulo == menuAtendimento.Dados_Cadastrais_Evento || (modulo == menuAtendimento.Processos && protocolo.processo.codigo == '27' || protocolo.processo.codigo == '28')"></app-informacao-questionario>
                <app-informacao-questionario #informacaoQuestionarioDadosCadastrais titulo="de Dados Cadastrais" *ngIf="modulo == menuAtendimento.Dados_Cadastrais || eDespacho || (modulo == menuAtendimento.Processos && protocolo.processo.codigo != '27' && protocolo.processo.codigo != '28' && protocolo.processo.tipo != 'E' && protocolo.processo.tipo != 'R' && protocolo.processo.tipo != 'T')"></app-informacao-questionario>
                <app-informacao-questionario #informacaoQuestionarioDadosCadastrais titulo="de Dados Cadastrais Evento" *ngIf="modulo == menuAtendimento.Dados_Cadastrais_Evento || (modulo == menuAtendimento.Processos && protocolo.processo.codigo == '27')"></app-informacao-questionario>
                <app-informacao-questionario #informacaoQuestionarioRecadastro titulo="de Recadastramento" *ngIf="modulo == menuAtendimento.Recadastro || eDespacho || (modulo == menuAtendimento.Processos && protocolo.processo.tipo == 'R')"></app-informacao-questionario>
                <app-informacao-questionario #informacaoQuestionarioEncerramento titulo="de Baixa" *ngIf="modulo == menuAtendimento.Baixa || eDespacho || (modulo == menuAtendimento.Processos && protocolo.processo.tipo == 'E' || protocolo.processo.tipo == 'T')"></app-informacao-questionario>
                <app-atendimento-questionario #atendimentoQuestionario titulo="Auditor" *ngIf="modulo != menuAtendimento.Processos"></app-atendimento-questionario>
            </div>
        </div>
    </div>
    
    <div class="row col-12" >
        <div id="tabsAnexo" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-anexar [dados]="[numeroProtocolo, numeroInscricao, orgao, 'ATENDIMENTO']" ></app-anexar>      
            </div>
        </div>
    </div>
    
    <div class="row col-12" *ngIf="moduloTaxaLiberado">
        <div id="tabsTaxas" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-conta-corrente></app-conta-corrente>
            </div>
        </div>
    </div>
    
    <div class="row col-12" >
        <div id="tabsLinhaDoTempo" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-linha-do-tempo></app-linha-do-tempo>
            </div>
        </div>
    </div>

    <div class="row col-12" *ngIf="seletorIm">
        <div id="tabsSeletorIm" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-atendimento-seletor-im ></app-atendimento-seletor-im>
            </div>
        </div>
    </div>

    <div class="row col-12">
        <div id="tabsAcoes" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-atendimento-embasamento *ngIf="modulo == menuAtendimento.Consulta_Previa || modulo == menuAtendimento.Consulta_Previa_Evento || modulo == menuAtendimento.Processos || eDespacho"></app-atendimento-embasamento>
                <app-atendimento-critica *ngIf="(modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento) && permitirAtendimento"></app-atendimento-critica>
                <app-atendimento-baixa *ngIf="modulo == menuAtendimento.Baixa"></app-atendimento-baixa>
                <app-atendimento-observacao #observacoesDespacho></app-atendimento-observacao>
                <app-atendimento-observacao #observacoesGerais></app-atendimento-observacao>
                <app-atendimento-data-abertura *ngIf="(((modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento) && processoDeAbertura) || modulo == menuAtendimento.Baixa) && permitirAtendimento"></app-atendimento-data-abertura>
                <br>
                <app-atendimento-acao *ngIf="permitirAtendimento || eDespacho" (acaoDeAtendimento)="atender($event)" [criticas]="protocolo.criticas"></app-atendimento-acao>
                <app-atendimento-regerar *ngIf="(modulo == menuAtendimento.Dados_Cadastrais || modulo == menuAtendimento.Dados_Cadastrais_Evento || modulo == menuAtendimento.Baixa || modulo == menuAtendimento.Recadastro) && !permitirAtendimento && botaoRegerarDocumento"></app-atendimento-regerar>
            </div>
        </div> 
    </div>
</div> 