import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Zona } from '../../model/zona';

@Injectable({ providedIn: 'root' })
export class ZonaService {

    constructor(private http: HttpClient, private router: Router) {}

    incluirZona(zona: Zona) {
        return this.http.post<any>( `${ environment.icadUrl }/zona/incluirZona`, JSON.stringify(zona),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarZona(cliente, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/zona/buscarZona/${ cliente }/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    alterarZona(codigo) {
        return this.http.post<any>( `${ environment.icadUrl }/zona/alterarZona`, JSON.stringify(codigo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarZonasParaAtendimento() {
        return this.http.get<any>( `${ environment.icadUrl }/zona/buscarZonasParaAtendimento/${ localStorage.getItem("pId") }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarUsosParaAtendimento() {
        return this.http.get<any>( `${ environment.icadUrl }/zona/buscarUsosParaAtendimento/${ localStorage.getItem("pId") }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarUsosParaAtendimentoComTipos() {
        return this.http.get<any>( `${ environment.icadUrl }/zona/buscarUsosParaAtendimentoComTipos/${ localStorage.getItem("pId") }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarZonasPorProtocolo(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/zona/buscarZonasPorProtocolo/${ cliente }/${ protocolo }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarUsosPorProtocolo(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/zona/buscarUsosPorProtocolo/${ cliente }/${ protocolo }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
          
}