import { Telefone } from './telefone';
import { Endereco } from './endereco';
import { Zona } from './zona';
import { Atividade } from './atividade';
import { ConsultaViabilidadeUso } from './consultaViabilidadeUso';
import { ConsultaViabilidadeRequisito } from './consultaViabilidadeRequisito';
import { ConsultaViabilidadeEndereco } from './consultaViabilidadeEndereco';



export class ConsultaViabilidadeAtividade {
    codigo: string;
    nome: string;
    uso: ConsultaViabilidadeUso;
    requisitos: Array<ConsultaViabilidadeRequisito>;
    usos: Array<ConsultaViabilidadeUso>;
    endereco: ConsultaViabilidadeEndereco;
    tipoNegocio: string;
    vidaNegocio: string;
    areaUtilizada: string;
    qtdeFuncionarios: string;
    vidaAtividade: string;
   
    constructor() {
        this.codigo = "";
        this.nome = "";
        this.uso = new ConsultaViabilidadeUso();
        this.requisitos = new Array<ConsultaViabilidadeRequisito>();
        this.usos = new Array<ConsultaViabilidadeUso>();
        this.endereco = new ConsultaViabilidadeEndereco();
        this.tipoNegocio = "";
        this.vidaNegocio = "";
        this.areaUtilizada = "";
        this.qtdeFuncionarios = "";
        this.vidaAtividade = "";
    }
}