import { Orgao } from "./orgao";
import { Observacao } from "./observacao";
import { Atendimento } from './atendimento';
import { RequisitoRisco } from './requisitoRisco';
import { Documento } from './documento';

export class Requisito {
    cliente: string;
    codigo: string;
    protocolo: string;
    inscricao: string;
    atendimentos: Array<Atendimento>;
    orgao: Orgao;
    nome: string;
    preRequisito: string;
    processo: string;
    obrigatorio: boolean;
    justificativa: string;
    observacoes: Array<Observacao>;
    vencimento: string;
    status: string;
    statusAvaliacao: string;
    bloqueiaDocumento: string;
    bloqueiaDocumentoLista: Array<Documento>;
    tipoVisualizacao: string;
    requisitoRisco: RequisitoRisco;
    encerramento: string;
    dataVinculo: string;
    possuiLicenciamento: boolean;
    avaliacaoInicial: string;
    observacaoInicial : string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.protocolo = "";
        this.inscricao = "";
        this.atendimentos = new Array<Atendimento>();
        this.orgao = new Orgao();
        this.nome = "";
        this.preRequisito = "";
        this.processo = "";
        this.obrigatorio = false;
        this.justificativa = "";
        this.observacoes = new Array<Observacao>();
        this.vencimento = "";
        this.status = "" ;
        this.statusAvaliacao = "";
        this.bloqueiaDocumento = "";
        this.bloqueiaDocumentoLista = new Array<Documento>();
        this.tipoVisualizacao = "";
        this.requisitoRisco = new RequisitoRisco();
        this.encerramento = "";
        this.dataVinculo = "";
        this.possuiLicenciamento = false;
        this.avaliacaoInicial = "";
        this.observacaoInicial = "";
    }
}