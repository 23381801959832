<div class="justify-content-md-end">
  <div class="row col-12 top-atendimento mt-2">
    <div class="row col-12 container-principal-atendimento container-tamanho pt-3 pb-3">
      <div class="row col-6 justify-content-start align-items-center">
          <button type="button" class="btn btn-danger" (click)="acaoBotaoVoltar()">Voltar</button>
      </div>
      <div class="row col-6 justify-content-end align-items-center divisao-esquerda">
        <button type="button" class="btn btn-success" (click)="acaoBotaoSalvar()">Concluir</button>
      </div>
    </div>
  </div>
</div>
<form name="formulario" 
[formGroup]="formulario" 
class="justify-content-center align-items-center"
>
<div class="container-principal-atendimento mt-3 mb-5">
  <app-alert type="danger"
      *ngIf="emitirErro" >
      <div [innerHtml]="mensagemErro"></div>
  </app-alert>
  <app-alert type="danger" 
    *ngIf="tipoBloqueio.value != ''">
    <div class="w-100 text-center mr-5 pr-5" *ngIf="codigoMenu == 99"><b>REQUISITO BLOQUEARÁ DOCUMENTOS</b></div>
    <div class="w-100 text-center mr-5 pr-5" *ngIf="codigoMenu == 100"><b>PRÉ-REQUISITO BLOQUEARÁ DOCUMENTOS</b></div>
  </app-alert>
  <app-alert 
    [type]="
      classificacaoRequisito.value == '' ? 'info':
      classificacaoRequisito.value <= '2' ? 'success' :
      classificacaoRequisito.value == '3' ? 'warning' :
      classificacaoRequisito.value >= '4' ? 'danger' : ''
    ">
    <div class="w-100 text-center mr-5 pr-5" *ngIf="codigoMenu == 99">
      <b>
        ESTE REQUISITO ESTÁ CLASSIFICADO COMO 
        {{ 
          requisitoRiscos != null ? 
            (requisitoRiscos | filtro: {campo: 'codigo', valor: classificacaoRequisito.value}).length && 
            (requisitoRiscos | filtro: {campo: 'codigo', valor: classificacaoRequisito.value})[0].codigo != '' ? 
              ((requisitoRiscos | filtro: {campo: 'codigo', valor: classificacaoRequisito.value})[0].descricao | uppercase) 
            : 'SEM' 
          : 'SEM' 
        }} RISCO
      </b>
    </div>
    <div class="w-100 text-center mr-5 pr-5" *ngIf="codigoMenu == 100">
      <b>
        ESTE PRÉ-REQUISITO ESTÁ CLASSIFICADO COMO 
        {{ 
          requisitoRiscos != null ? 
            (requisitoRiscos | filtro: {campo: 'codigo', valor: classificacaoRequisito.value}).length && 
            (requisitoRiscos | filtro: {campo: 'codigo', valor: classificacaoRequisito.value})[0].codigo != '' ? 
              ((requisitoRiscos | filtro: {campo: 'codigo', valor: classificacaoRequisito.value})[0].descricao | uppercase) 
            : 'SEM' 
          : 'SEM' 
        }} RISCO
      </b>
    </div>
  </app-alert>
    <div class="card">
      <div class="card-header">
        <div class="col-12" *ngIf="codigoMenu == 99">
          <strong>{{ acaoAtendimento == acaoAtendimentoAuxiliar.Alterar ? 'Editar Requisito' : 'Cadastrar Requisito' }}</strong>
        </div>
        <div class="col-12" *ngIf="codigoMenu == 100">
          <strong>{{ acaoAtendimento == acaoAtendimentoAuxiliar.Alterar ? 'Editar Pré-requisito' : 'Cadastrar Pré-requisito' }}</strong>
        </div>
      </div>
      <div class="card-body p-0 mb-2">
        <div class="row mt-3">
          <div class="form-group col-8" *ngIf="codigoMenu == 99">
            <label>Nome</label>
            <input class="form-control form-control-sm" formControlName="nomeRequisito"
              placeholder="Nome do requisito a ser criado" id="nomeRequisito" name="nomeRequisito"
              type="text" />
            <div class="mb-1" *ngIf="nomeRequisito.invalid && nomeRequisito.touched">
              <span class="erro" *ngIf="nomeRequisito.errors.required">Campo obrigatório</span>
            </div>
          </div>
          <div class="form-group col-8" *ngIf="codigoMenu == 100">
            <label>Nome</label>
            <input class="form-control form-control-sm" formControlName="nomeRequisito"
              placeholder="Nome do pré-requisito a ser criado" id="nomeRequisito" name="nomeRequisito"
              type="text" />
            <div class="mb-1" *ngIf="nomeRequisito.invalid && nomeRequisito.touched">
              <span class="erro" *ngIf="nomeRequisito.errors.required">Campo obrigatório</span>
            </div>
          </div>
          
          <div class="form-group col-3">
            <label>Classificação</label>
            <select name="classificacaoRequisito" formControlName="classificacaoRequisito" id="classificacaoRequisito" class="form-control">
              <option value=""></option>
              <option *ngFor="let requisitoRisco of requisitoRiscos" 
                value="{{requisitoRisco.codigo}}">
                {{ requisitoRisco.descricao }}
              </option>
            </select>
          </div>
          <div class="row col-1 justify-content-center align-items-center">
            <mat-radio-group 
              formControlName="statusRequisito" 
              aria-label="Status">
                <mat-radio-button value="1">
                    <strong>Ativo</strong>
                </mat-radio-button><br>
                <mat-radio-button value="0">
                  <strong>Inativo</strong>
              </mat-radio-button>
            </mat-radio-group> 
          </div>
        </div>
        <div class="row">
          <div class="form-group col-8" *ngIf="orgaos == null">
            <p class="text-center mb-0">
              Carregando
            </p>
          </div>
          <div class="form-group col-8" *ngIf="orgaos != null">
            <label>Orgão</label>
            <select name="codigoOrgao" 
              formControlName="codigoOrgao" 
              id="codigoOrgao" 
              class="form-control">
              <option value=""></option>
              <option *ngFor="let orgao of orgaos" 
                value="{{orgao.codigo}}">
                {{ orgao.nome }}
              </option>
            </select>
          </div>
          <div class="form-group col-4">
            <label>Fase</label>
            <select name="tipoRequisito" formControlName="tipoRequisito" id="tipoRequisito"
              class="form-control">
              <option value=""></option>
              <option value="F">Dados Cadastrais</option>
              <option value="V">Consulta Prévia</option>
              <option value="P">Dados Cadastrais/Consulta Prévia</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-header">
        <div class="col-12">
          <strong>Avaliação Automática</strong>
        </div>
      </div>
      <div class="card-body pt-3 pb-2">
        <div class="row">
          <div class="row col-12 row-linha pb-4" *ngIf="codigoMenu == 99">
            <p class="card-text">
                Caro Auditor, para requisitos com avaliação automática, preencher o quadro abaixo.<br>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-4" *ngIf="orgaos != null">
            <label>Avaliação Automática</label>
            <select name="avaliacaoInicial" formControlName="avaliacaoInicial" id="avaliacaoInicial"
              class="form-control">
              <option value=""></option>
              <option value="S">Deferir</option>
              <option value="N">Indeferir</option>
            </select>
          </div>

          <div class="form-group col-8" *ngIf="orgaos != null">
            <label>Observação de Avaliação</label>
            <input class="form-control form-control-sm" formControlName="observacaoInicial"
              placeholder="Observação de avaliação automática do requisito" id="observacaoInicial" name="observacaoInicial"
              type="text" />
          </div>
        </div>
      </div>
      <div class="card-header">
        <div class="col-12">
          <strong>Tipos de Documentos</strong>
        </div>
      </div>
      <div class="card-body pt-3 pb-2">
        <div class="row col-12 row-linha pb-4" *ngIf="codigoMenu == 99">
          <p class="card-text">
              Caro Auditor, logo abaixo estão os tipos de de documentos que o requisito bloqueará caso o negócio possua o requisito vinculado.<br>
              Quando o requisito possuir em sua configuração qualquer desses documentos abaixo selecionado, essa configuração somente funcionará quando os documentos abaixo forem emitidos pelo sistema.<br>
          </p>
        </div>
        <div class="row col-12 row-linha pb-4" *ngIf="codigoMenu == 100">
          <p class="card-text">
              Caro Auditor, logo abaixo estão os tipos de de documentos que o pré-requisito bloqueará caso o negócio possua o pré-requisito vinculado.<br>
              Quando o pré-requisito possuir em sua configuração qualquer desses documentos abaixo selecionado, essa configuração somente funcionará quando os documentos abaixo forem emitidos pelo sistema.<br>
          </p>
        </div>
        <div class="row col-12 justify-content-center align-items-center">
          <p class="text-center">
            <mat-checkbox *ngFor="let documento of documentos; let i = index"
              [class.ml-5]="i > 0"
              #tipoDocumento
              (change)="acaoSelecionarTipoDocumento(tipoDocumento, documento.codigo)"
              [checked]="tipoBloqueio.value.search(documento.codigo) != -1"
              id="tipoDocumento_{{ documento.codigo }}"
              name="tipoDocumento">
              {{ documento.nome }}
            </mat-checkbox>
          </p>
        </div>        
        <div class="row col-12 row-linha pt-4" >
          <p class="card-text">
              <b>* NÃO TERÁ EFEITO PARA DOCUMENTOS EMITIDOS MANUALMENTE.</b>
          </p>
        </div>
      </div>
      <div class="card-header">
        <div class="col-12">
          <strong>Vínculos</strong>
        </div>
      </div>
      <div class="card-body pt-3">
        <div class="row col-12 justify-content-start align-items-center" *ngIf="codigoMenu == 99">
          <p class="card-text">
            <mat-checkbox
              [checked]='obrigatorio'
              (change)='requisitoObrigatorio()'> Geral - Esse requisito será selecionado automáticamente nas fases que ele pertence, isso não impede que o auditor em sua análise remova essa seleção.
            </mat-checkbox>
          </p>
        </div>
        <div class="row col-12 justify-content-start align-items-center" *ngIf="codigoMenu == 100">
          <p class="card-text">
            <mat-checkbox
              [checked]='obrigatorio'
              (change)='requisitoObrigatorio()'> Geral - Esse pré-requisito será selecionado automáticamente nas fases que ele pertence, isso não impede que o auditor em sua análise remova essa seleção.
            </mat-checkbox>
          </p>
        </div>
      </div>
      <div class="card-body pt-3" *ngIf="!obrigatorio">
        <div class="row col-12 row-linha pb-4" >
          <p class="card-text">
            Caro Auditor, nos botões abaixo você poderá configurar para quais atividades esse requisito se tonará obrigatório.<br>
            Esse vínculo fará com que o requisito venha selecionado automaticamente nos processos de abertura ou alteração do negócio.<br>
          </p>
        </div>
        <div class="row col-12">
          <div class="row col-2 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialog(tipoEmpresa.Empresa)">Empresa</button>
          </div>
          <div class="row col-2 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialog(tipoEmpresa.Mei)">Mei</button>
          </div>
          <div class="row col-2 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialog(tipoEmpresa.Autonomo)">Autônomo</button>
          </div>
          <div class="row col-3 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialog(tipoEmpresa.Autonomo_Estabelecido)">Autônomo Estabelecido</button>
          </div>
          <div class="row col-3 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialog(tipoEmpresa.Autonomo_Nao_Estabelecido)">Autônomo Não Estabelecido</button>
          </div>
        </div>
        <div class="row col-12 mt-4" *ngIf="codigoMenu == 99">
          <app-alert class="w-100" type="warning">
            <div>
              ATENÇÃO - Se esse requisito for necessário em todos os processos, independente da atividade, selecionar o combo "Geral" que se encontra no começo do cadastro.
            </div>
          </app-alert>
        </div>
        <div class="row col-12 mt-4" *ngIf="codigoMenu == 100">
          <app-alert class="w-100" type="warning">
            <div>
              ATENÇÃO - Se esse pré-requisito for necessário em todos os processos, independente da atividade, selecionar o combo "Geral" que se encontra no começo do cadastro.
            </div>
          </app-alert>
        </div>
      </div>
    </div>
  </div>
  <div class="container-principal-atendimento mt-3 mb-5" *ngIf="acaoAtendimento == acaoAtendimentoAuxiliar.Alterar">
    <div class="card">
      <div class="card-header">
        <div class="col-12">
          <strong>Vínculos por Arquivo</strong>
        </div>
      </div>
      <div class="card-body pt-3">
        <div class="row col-12 row-linha pb-4" >
          <p class="card-text">
            Caro Auditor, nos botões abaixo você poderá configurar para quais atividades esse requisito se tonará obrigatório.<br>
            Esse vínculo fará com que o requisito venha selecionado automaticamente nos processos de abertura ou alteração do negócio.<br>
          </p>
        </div>
        <div class="row col-12">
          <div class="row col-2 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialogVinculo(tipoDeVinculo.Requisito_Cnae_Empresa)">Empresa</button>
          </div>
          <div class="row col-2 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialogVinculo(tipoDeVinculo.Requisito_Cnae_Mei)">Mei</button>
          </div>
          <div class="row col-2 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialogVinculo(tipoDeVinculo.Requisito_Cnae_Autonomo)">Autônomo</button>
          </div>
          <div class="row col-3 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialogVinculo(tipoDeVinculo.Requisito_Cnae_Autonomo_Estabelecido)">Autônomo Estabelecido</button>
          </div>
          <div class="row col-3 justify-content-center align-items-center">
            <button type="button" class="btn btn-success col-12" (click)="openDialogVinculo(tipoDeVinculo.Requisito_Cnae_Autonomo_Nao_Estabelecido)">Autônomo Não Estabelecido</button>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</form>



