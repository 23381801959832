<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card">
        <div class="card-header">
            <div class="row justify-content-center align-items-center">
                <div class="row col-12" >
                    <strong>Informações adicionais</strong>
                </div>
            </div>
        </div>
        <div class="card-body p-0 pt-4" >
            <div class="row col-12 pl-1 justify-content-start align-items-center">
                <div class="form-group col-9">
                    <p class="card-text-auditor">
                        {{ mensagemDeAcordoComModulo }}
                    </p>
                </div>
                <div class="form-group col-3">
                    <input
                        #inputDataAbertura
                        [class.is-invalid]="dataAbertura.invalid && dataAbertura.touched"
                        autofocus
                        class="form-control form-control-sm text-center"
                        formControlName="dataAbertura"
                        id="dataAbertura"
                        name="dataAbertura"
                        type="text"
                        value="${ new Date().toLocaleDateString() }" 
                        mask="00/00/0000"
                        maxlength="10"
                    />
                    <div class="mb-1" *ngIf="dataAbertura.invalid && dataAbertura.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataAbertura.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="dataAbertura.errors.minlength"
                        >Data inválida</span>
                        <span 
                            class="erro" 
                            *ngIf="!dataAbertura.errors.minlength && dataAbertura.hasError('mensagem')"
                        >{{dataAbertura.errors.mensagem}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>