<div class="card">
    <div class="card-header">
        <div class="row justify-content-center align-items-center">
            <div class="row col-12" >
                <strong>Informações adicionais</strong>
            </div>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolo != null" class="card-body p-0 pt-4" >
        <div class="row col-12 pl-1 justify-content-start align-items-center">
            <div class="form-group col-3">
                <p class="card-text-auditor">
                    <strong>Motivo</strong>
                </p>
            </div>
            <div class="form-group col-9">
                <span *ngFor="let motivoEncerramento of protocolo.encerramento.motivosEncerramento"
                    class="card-text">
                    <p class="card-text-auditor">
                        {{ motivoEncerramento.descricao }}
                    </p>
                </span>
            </div>
        </div>
        <div class="row col-12 pl-1 justify-content-start align-items-center">
            <div class="form-group col-3">
                <p class="card-text-auditor">
                    <strong>Data Pretendida:</strong>
                </p>
            </div>
            <div class="form-group col-9">
                <p class="card-text-auditor">
                    {{ protocolo.encerramento.data }}
                </p>
            </div>
        </div>
        <div class="row col-12 pl-1 justify-content-start align-items-center">
            <div class="form-group col-3">
                <p class="card-text-auditor">
                    <strong>Observação do contribuinte</strong>
                </p>
            </div>
            <div class="form-group col-9">
                <p class="card-text-auditor">
                    {{ protocolo.encerramento.descricao }}
                </p>
            </div>
        </div>
    </div>
</div>