<div *ngIf="protocolo == null" class="row col-12">
    <div class="form-row col-12 row-linha">
        <div class="col-12">
            <p class="card-text text-center">
                Carregando...
            </p>
        </div>
    </div>      
</div>
<div class="row col-12" *ngIf="protocolo != null">
    <div class="row col-3 justify-content-start align-items-center">
        <button
            mat-button
            mat-dialog-close
            (click)="acaoBotaoVoltar()"
            class="btn btn-outline-danger btn-titulo"
            type="button"
            title="Fechar"
        >
        <svg class="iconeFecharPopUp">
            <use
                attr.xlink:href="assets/sprite.svg#{{ 'arrow-left' }}"
            ></use>
        </svg> 
        Voltar
        </button>
        <button 
            *ngIf="permitirAtendimento"
            type="button" 
            class="btn btn-outline-success btn-titulo"
            title="analisar"
            (click)="vincularAtendimento()"
        >
            <svg class="iconeFecharPopUp">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                ></use>
            </svg>
            Analisar
        </button>
    </div>
    <div class="row col-9 justify-content-center align-items-center" *ngIf="!protocoloAtendido">
        <div class="col-12" *ngIf="menu == 'viabilidade'">
            <p class="text-center m-0 p-titulo">
                <b>{{ protocolo.processo.descricao.toUpperCase() }}</b><br>
                {{ 
                    protocolo.inscricao.toString().toUpperCase() != '' ? 'Inscrição' : ''
                }} 
                <b>{{ protocolo.inscricao.toString().toUpperCase() != '' ? protocolo.inscricao.toString().toUpperCase(): '' }}</b> 
                {{ protocolo.inscricao.toString().toUpperCase() != '' ? ' / ' : '' }} 
                {{ 
                    protocolo.protocolo.toUpperCase() != '' ? 'Protocolo' : ''
                }} 
                <b>{{ protocolo.protocolo.toUpperCase() != '' ? protocolo.protocolo.toUpperCase() : '' }}</b>
                {{ protocolo.data.toUpperCase() != '' ? ' - ' : '' }} 
                {{ protocolo.data.toUpperCase() != '' ? protocolo.data.toUpperCase() : '' }}
            </p>
            <p class="text-center p-titulo" *ngIf="protocolo.viabilidade.atendimentos.length > 0">
                Em atendimento por:  <b>{{ protocolo.viabilidade.atendimentos[protocolo.viabilidade.atendimentos.length - 1].atendente.toUpperCase() }}</b> - {{ protocolo.viabilidade.atendimentos[protocolo.viabilidade.atendimentos.length - 1].inicio.toUpperCase() }}
            </p>
        </div>
        <div class="col-12" *ngIf="menu == 'fic'">
            <p class="text-center m-0 p-titulo">
                <b>{{ protocolo.processo.descricao.toUpperCase() }}</b><br>
                {{ 
                    protocolo.inscricao.toString().toUpperCase() != '' ? 'Inscrição' : ''
                }} 
                <b>{{ protocolo.inscricao.toString().toUpperCase() != '' ? protocolo.inscricao.toString().toUpperCase(): '' }}</b> 
                {{ protocolo.inscricao.toString().toUpperCase() != '' ? ' / ' : '' }} 
                {{ 
                    protocolo.protocolo.toUpperCase() != '' ? 'Protocolo' : ''
                }} 
                <b>{{ protocolo.protocolo.toUpperCase() != '' ? protocolo.protocolo.toUpperCase() : '' }}</b>
                {{ protocolo.data.toUpperCase() != '' ? ' - ' : '' }} 
                {{ protocolo.data.toUpperCase() != '' ? protocolo.data.toUpperCase() : '' }}
            </p>
            <p class="text-center p-titulo" *ngIf="protocolo.fic.atendimentos.length > 0">
                Em atendimento por:  <b>{{ protocolo.fic.atendimentos[protocolo.fic.atendimentos.length - 1].atendente.toUpperCase() }}</b> - {{ protocolo.fic.atendimentos[protocolo.fic.atendimentos.length - 1].inicio.toUpperCase() }}
            </p>
        </div>
        <div class="col-12" *ngIf="menu != 'viabilidade' && menu != 'fic'">
            <p class="text-center m-0 p-titulo">
                <b>{{ protocolo.processo.descricao.toUpperCase() }}</b><br>
                {{ 
                    protocolo.inscricao.toString().toUpperCase() != '' ? 'Inscrição' : ''
                }} 
                <b>{{ protocolo.inscricao.toString().toUpperCase() != '' ? protocolo.inscricao.toString().toUpperCase(): '' }}</b> 
                {{ protocolo.inscricao.toString().toUpperCase() != '' ? ' / ' : '' }} 
                {{ 
                    protocolo.protocolo.toUpperCase() != '' ? 'Protocolo' : ''
                }} 
                <b>{{ protocolo.protocolo.toUpperCase() != '' ? protocolo.protocolo.toUpperCase() : '' }}</b>
                {{ protocolo.data.toUpperCase() != '' ? ' - ' : '' }} 
                {{ protocolo.data.toUpperCase() != '' ? protocolo.data.toUpperCase() : '' }}
            </p>
            <p class="text-center p-titulo" *ngIf="protocolo.viabilidade.atendimentos.length > 0">
                Em atendimento por:  <b>{{ protocolo.viabilidade.atendimentos[protocolo.viabilidade.atendimentos.length - 1].atendente.toUpperCase() }}</b> - {{ protocolo.viabilidade.atendimentos[protocolo.viabilidade.atendimentos.length - 1].inicio.toUpperCase() }}
            </p>
        </div>
    </div>
    <div class="row col-9 justify-content-center align-items-center" *ngIf="protocoloAtendido">
        <div class="col-12" *ngIf="menu == 'viabilidade'">
            <div class="col-12" *ngFor="let atendimento of protocolo.viabilidade.atendimentos">
                <p class="text-center p-titulo" *ngIf="atendimento.parecer != ''">
                    <button type="button" 
                        class="btn btn-sm"
                        [class.btn-success]="atendimento.parecer == 'S'"
                        [class.btn-danger]="atendimento.parecer == 'N'">
                        {{ 
                            atendimento.parecer == 'S' ? 'DEFERIDO' : 
                            atendimento.parecer == 'N' ? 'INDEFERIDO' : 'NÃO INFORMADO'
                        }}
                    </button>                
                </p>
                <p class="text-center m-0 p-titulo" *ngIf="atendimento.parecer != ''">
                    <b>{{ protocolo.processo.descricao.toUpperCase() }}</b><br>
                    {{ 
                        protocolo.inscricao.toString().toUpperCase() != '' ? 'Inscrição' : ''
                    }} 
                    <b>{{ protocolo.inscricao.toString().toUpperCase() != '' ? protocolo.inscricao.toString().toUpperCase(): '' }}</b> 
                    {{ protocolo.inscricao.toString().toUpperCase() != '' ? ' / ' : '' }} 
                    {{ 
                        protocolo.protocolo.toUpperCase() != '' ? 'Protocolo' : ''
                    }} 
                    <b>{{ protocolo.protocolo.toUpperCase() != '' ? protocolo.protocolo.toUpperCase() : '' }}</b>
                    {{ protocolo.data.toUpperCase() != '' ? ' - ' : '' }} 
                    {{ protocolo.data.toUpperCase() != '' ? protocolo.data.toUpperCase() : '' }}
                </p>
                <p class="text-center p-titulo" *ngIf="atendimento.parecer != ''">
                    Atendimento realizado por:  <b>{{ atendimento.atendente.toUpperCase() }}</b> - {{ atendimento.fim.toUpperCase() }}
                </p>
            </div>
        </div>
        <div class="col-12" *ngIf="menu == 'fic'">
            <div class="col-12" *ngFor="let atendimento of protocolo.fic.atendimentos">
                <p class="text-center p-titulo" *ngIf="atendimento.parecer != ''">
                    <button type="button" 
                        class="btn btn-sm"
                        [class.btn-success]="atendimento.parecer == 'S'"
                        [class.btn-danger]="atendimento.parecer == 'N'">
                        {{ 
                            atendimento.parecer == 'S' ? 'DEFERIDO' : 
                            atendimento.parecer == 'N' ? 'INDEFERIDO' : 'NÃO INFORMADO'
                        }}
                    </button>                
                </p>
                <p class="text-center m-0 p-titulo" *ngIf="atendimento.parecer != ''">
                    <b>{{ protocolo.processo.descricao.toUpperCase() }}</b><br>
                    {{ 
                        protocolo.inscricao.toString().toUpperCase() != '' ? 'Inscrição' : ''
                    }} 
                    <b>{{ protocolo.inscricao.toString().toUpperCase() != '' ? protocolo.inscricao.toString().toUpperCase(): '' }}</b> 
                    {{ protocolo.inscricao.toString().toUpperCase() != '' ? ' / ' : '' }} 
                    {{ 
                        protocolo.protocolo.toUpperCase() != '' ? 'Protocolo' : ''
                    }} 
                    <b>{{ protocolo.protocolo.toUpperCase() != '' ? protocolo.protocolo.toUpperCase() : '' }}</b>
                    {{ protocolo.data.toUpperCase() != '' ? ' - ' : '' }} 
                    {{ protocolo.data.toUpperCase() != '' ? protocolo.data.toUpperCase() : '' }}
                </p>
                <p class="text-center p-titulo" *ngIf="atendimento.parecer != ''">
                    Atendimento realizado por:  <b>{{ atendimento.atendente.toUpperCase() }}</b> - {{ atendimento.fim.toUpperCase() }}
                </p>
            </div>
        </div>
        <div class="col-12" *ngIf="menu != 'viabilidade' && menu != 'fic'">
            <div class="col-12">
                <p class="text-center m-0 p-titulo">
                    <b>{{ protocolo.processo.descricao.toUpperCase() }}</b><br>
                    {{ 
                        protocolo.inscricao.toString().toUpperCase() != '' ? 'Inscrição' : ''
                    }} 
                    <b>{{ protocolo.inscricao.toString().toUpperCase() != '' ? protocolo.inscricao.toString().toUpperCase(): '' }}</b> 
                    {{ protocolo.inscricao.toString().toUpperCase() != '' ? ' / ' : '' }} 
                    {{ 
                        protocolo.protocolo.toUpperCase() != '' ? 'Protocolo' : ''
                    }} 
                    <b>{{ protocolo.protocolo.toUpperCase() != '' ? protocolo.protocolo.toUpperCase() : '' }}</b>
                    {{ protocolo.data.toUpperCase() != '' ? ' - ' : '' }} 
                    {{ protocolo.data.toUpperCase() != '' ? protocolo.data.toUpperCase() : '' }}
                </p>
            </div>
        </div>
    </div>
</div>
