<div class="container-principal">
    <article>
        <div class="container-content row  mb-2">
            <div class="col-12 text-center">
                <h2>Acessibilidade</h2>
            </div>
        </div>
        <div class="col-12 mt-2 mb-5">
            O termo acessibilidade significa incluir a pessoa com deficiência na participação de atividades como o uso de produtos, serviços e informações. Alguns exemplos são os prédios com rampas de acesso para cadeira de rodas e banheiros adaptados para deficientes.
            <br><br>
            Na internet, acessibilidade refere-se principalmente às recomendações do WCAG (World Content Accessibility Guide) do W3C e, no caso do Governo Brasileiro, ao eMAG (Modelo de Acessibilidade em Governo Eletrônico).
            <br><br>
            O eMAG está alinhado às recomendações internacionais, mas estabelece padrões de comportamento acessível para sites governamentais.
            <br><br>
            Na parte superior do portal existe uma barra de acessibilidade onde se encontra atalhos de navegação padronizados e as opções para alterar o contraste e tamanho de fonte. Essas ferramentas estão disponíveis em todas as páginas do portal.
            <br><br>
            Os atalhos padrões do Portal da Saúde são:
            <br><br>
            Teclando-se Alt + 1 em qualquer página do portal, chega-se diretamente ao começo do conteúdo principal da página.
            <br><br>
            Teclando-se Alt + 2 em qualquer página do portal, chega-se diretamente ao início do menu principal.
            <br><br>
            Teclando-se Alt + 3 em qualquer página do portal, chega-se diretamente em sua busca interna.
            <br><br>
            Teclando-se Alt + 4 em qualquer página do portal, chega-se diretamente ao menu do rodapé da página.
            <br><br>
            Teclando-se Alt + 5 em qualquer página do portal, chega-se diretamente à página de acessibilidade.
            <br><br>
            Teclando-se Alt + 6 em qualquer página do portal, chega-se diretamente à funcionalidade do alto-contraste.
            <br><br>
            Teclando-se Alt + 7 em qualquer página do portal, aumenta o tamanho das letras do portal.
            <br><br>
            Teclando-se Alt + 8 em qualquer página do portal, diminui o tamanho das letras do portal.
            <br><br>
            Teclando-se Alt + 9 em qualquer página do portal, chega-se diretamente à página de mapa do site.
            <br><br>
            No caso do Firefox, em vez de Alt + número, tecle simultaneamente Alt + Shift + número.
            <br><br>
            Sendo Firefox no Mac OS, em vez de Alt + Shift + número, tecle simultaneamente Ctrl + Alt + número.
            <br><br>
            No Opera, as teclas são Shift + Escape + número. Ao teclar apenas Shift + Escape, o usuário encontrará uma janela com todas as alternativas de ACCESSKEY da página.
            <br><br>
            Ao final desse texto, você poderá baixar alguns arquivos que explicam melhor o termo acessibilidade e como deve ser implementado nos sites da Internet.
            <br><br>
            <span class="text-primary font-20"><strong>Leis e decretos sobre acessibilidade:</strong></span><br>
            <a href="http://www.planalto.gov.br/ccivil_03/_Ato2004-2006/2004/Decreto/D5296.htm" target="_blank" rel="noopener noreferrer">Decreto nº 5.296 de 02 de dezembro de 2004</a><br>
            <a href="http://www.planalto.gov.br/ccivil_03/_ato2007-2010/2009/decreto/d6949.htm" target="_blank" rel="noopener noreferrer">Decreto nº 6.949, de 25 de agosto de 2009</a> - Promulga a Convenção Internacional sobre os Direitos das Pessoas com Deficiência e seu Protocolo Facultativo, assinados em Nova York, em 30 de março de 2007.<br>
            <a href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2012/Decreto/D7724.htm" target="_blank" rel="noopener noreferrer">Decreto nº 7.724, de 16 de Maio de 2012</a> - Regulamenta a Lei No 12.527, que dispõe sobre o acesso a informações.<br>
            <a href="https://www.governodigital.gov.br/documentos-e-arquivos/e-MAG%20V3.pdf" target="_blank" rel="noopener noreferrer">Modelo de Acessibilidade de Governo Eletrônico</a> (link externo) <br>
            <a href="https://www.governodigital.gov.br/documentos-e-arquivos/legislacao/portaria3_eMAG.pdf" target="_blank" rel="noopener noreferrer">Portaria nº 03, de 07 de Maio de 2007 - formato .pdf (35,5Kb)</a> - Institucionaliza o Modelo de Acessibilidade em Governo Eletrônico – eMAG<br><br>
            <strong>Dúvidas, sugestões e críticas:</strong><br>
            No caso de problemas com a acessibilidade do portal, favor entrar em <a href="mailto:giespp@giespp.com.br">contato</a>.
        </div>
    </article>
</div>
