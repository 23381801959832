import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, Output, ElementRef, Renderer2, RendererStyleFlags2 } from "@angular/core";
import { DattaConfig } from "../../../app-config";

import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Constantes } from "src/app/model/constantes";
import { ViewportScroller } from '@angular/common';

@Component({
  selector: "app-header-portal",
  templateUrl: "./header-portal.component.html",
  styleUrls: ["./header-portal.component.scss"],
})
export class HeaderPortalComponent {

  @Output() acaoHeader = new EventEmitter();
  
  public showUserDropdown = false;
  public showEixosDropdown = false;
  public showNotificationDropdown = false;
  public showCompaniesDropdown = false;
  public codigoCliente: string = "null";
  public nomeCliente: string;
  public departamentoUsuario: string;
  public dataAtual: string;
  public ip: string;

  constructor(private element: ElementRef,
              private renderer: Renderer2,
              private document: Document,
              private window: Window,
              private http: HttpClient,
              private authenticationService: AuthenticationService,
              private viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
    this.dataAtual = this.formatDataAtual();
    this.getIpCliente();
  }

  goToAnchor(ancora) {
    this.viewportScroller.scrollToAnchor(ancora);
    let posicaoAtual = this.viewportScroller.getScrollPosition();
    this.viewportScroller.scrollToPosition([posicaoAtual[0], posicaoAtual[1] - 146])
  }

  getIpCliente() {
    this.http.get(
      "https://api.ipify.org/?format=json",
      {
        reportProgress: true,
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
      }
    ).subscribe( (res:any) => this.ip = res.ip );
  }

  formatDataAtual() {
    let data = new Date();
    let diaSemana = Constantes.DIAS_DA_SEMANA[data.getDay() % 7];
    let mes = Constantes.MESES_DO_ANO[data.getMonth()];
    return (diaSemana + ", " + data.getDate() + " de " + mes + " de " + data.getFullYear()).toString();
  }

  mudarTamanhoFonte(tamanho) {
    if (this.element.nativeElement.closest("body").style.fontSize == "") {
      this.element.nativeElement.closest("body").style.fontSize = "14px";
    }
    if (tamanho == Constantes.TAMANHO_DA_FONTE.Aumentar) {
      this.renderer.setStyle(this.document.querySelector("body"), "font-size", (parseInt(this.element.nativeElement.closest("body").style.fontSize.replace(/\D/g,"")) + 2).toString() + "px", RendererStyleFlags2.DashCase);
    }
    else if (tamanho == Constantes.TAMANHO_DA_FONTE.Diminuir) {
      this.renderer.setStyle(this.document.querySelector("body"), "font-size", (parseInt(this.element.nativeElement.closest("body").style.fontSize.replace(/\D/g,"")) - 2).toString() + "px", RendererStyleFlags2.DashCase);
    }
    
  }

  mudarContraste() {
    if (this.document.querySelector("body").className == "contrast") {
      this.renderer.removeClass(this.document.querySelector("body"), "contrast");
    }
    else {
      this.renderer.addClass(this.document.querySelector("body"), "contrast");
    }
  }

  acaoClickHeader(acao) {
    this.acaoHeader.emit({ acao: acao});
  }
}
