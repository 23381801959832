import { NgModule } from "@angular/core";
import { MascaraPipe } from "./mascara.pipe";
import { FiltroPipe } from "./filtro.pipe";

@NgModule({
    declarations: [
        MascaraPipe,
        FiltroPipe,
    ],
    imports: [
    ],
    exports: [
        MascaraPipe,
        FiltroPipe,
    ]
})
export class PipeModule {}