export class PerfilNivel {
    codigo: string;
    nivel: string;
    icone: string;
    descricao: string;
    codigoPai: string;
    subNivel: string;
    perfilInterno: string;
    gerenciarCadastro: boolean;
    perfilNiveis: Array<PerfilNivel>;

    constructor() {
        this.codigo = "";
        this.nivel = "";
        this.icone = "";
        this.descricao = "";
        this.codigoPai = "";
        this.subNivel = "";
        this.perfilInterno = "";
        this.gerenciarCadastro = false;
        this.perfilNiveis = new Array<PerfilNivel>();
    }
}