import { OrgaoDeClasse } from "./orgaoDeClasse";
import { DocumentoEndereco } from './documentoEndereco';

export class Documento {
    cliente: string;
    codigo: string;
    nome: string;
    codigoUsuario: string;
    nomeUsuario: string;
    numero: string;
    tipo: number;
    subTipo: string;
    dataEmissao: string;
    dataVencimento: string;
    orgaoEmissor: string;
    estadoEmissor: string;
    classificacao: string;
    orgaoDeClasse: OrgaoDeClasse;
    documentoEndereco: DocumentoEndereco;
    ativo: string;
    status: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.nome = "";
        this.codigoUsuario = "";
        this.nomeUsuario = "";
        this.numero = "";
        this.tipo = 0;
        this.subTipo = "";
        this.dataEmissao = "";
        this.dataVencimento = "";
        this.orgaoEmissor = "";
        this.estadoEmissor = "";
        this.classificacao = "";
        this.orgaoDeClasse = new OrgaoDeClasse();
        this.documentoEndereco = new DocumentoEndereco();
        this.ativo = "";
        this.status = "";
    }
}