
<div class="mat-elevation-z9">
  <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">
    <ng-container matColumnDef="codigoLancamento">
        <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-codigoLancamento" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Lançamento </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" > {{ row.codigoLancamento }} </td>
    </ng-container>

    <ng-container matColumnDef="identificacaoIndice">
      <th style="width: 25%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-identificacaoIndice" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Índice </th>
      <td style="text-align: left; padding-left: 10px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha" > {{ row.identificacaoIndice }} </td>
    </ng-container>

    <ng-container matColumnDef="mesAnoIndice">
      <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-mesAnoIndice" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Data Alíquota </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" > {{ row.mesIndice + '/' + row.anoIndice }} </td>
    </ng-container>

    <ng-container matColumnDef="valorIndice">
      <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-valorIndice" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Alíquota </th>
      <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha" > {{ formatToBRL(row.valorIndice) }} </td>
    </ng-container>

    <ng-container matColumnDef="mesAnoReferencia">
      <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-mesReferencia" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Data Referência </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" > {{ row.mesReferencia + '/' + row.anoReferencia }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotalAcumulado">
    <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotalAcumulado" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Subtotal </th>
      <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha" > R$ {{ formatToBRL(row.subTotalAcumulado) }} </td>
    </ng-container> 

    <ng-container matColumnDef="subTotalJurosAcumulado">
    <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotalJurosAcumulado" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Correção </th>
      <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" > R$ {{ formatToBRL(row.subTotalJurosAcumulado) }} </td>
    </ng-container> 

    <ng-container matColumnDef="totalAcumulado">
    <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-totalAcumulado" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Valor Atualizado </th>
      <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha" > R$ {{ formatToBRL(row.totalAcumulado) }} </td>
    </ng-container> 

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="row" *ngIf="dataSource.filteredData.length == 0">
    <div class="form-row col-12 pt-3 justify-content-center align-items-center">
      <p class="card-text text-center">
        <br>{{ mensagemDeBusca }}<br>&nbsp;
      </p>
    </div>
  </div>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator> 
  <div class="row col-12 justify-content-center align-items-center">
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Excel', sheet: 'sheet_name', Props: {Author: 'Talha'}})">EXCEL</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('json')">JSON</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('txt')">TXT</button>
  </div>
</div>
<div>&nbsp;</div>
