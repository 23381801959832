import { Component, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";

import { GridProviderPaginacao } from "./grid-provider-paginacao";
import { SelectionModel } from "@angular/cdk/collections";
import Swal from 'sweetalert2';
import { Constantes } from '../../model/constantes';

@Component({
    selector: "app-grid-logradouro-bairro",
    templateUrl: "./grid-logradouro-bairro.component.html",
    styleUrls: ["./grid.component.scss"],
    providers: [ 
        {
            provide: MatPaginatorIntl,
            useClass: GridProviderPaginacao
        }
    ]
})
export class GridLogradouroBairroComponent {
    @Output() selecao = new EventEmitter();
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ["selecao","codigo","nome"];
    linhaSelecionada: SelectionModel<any>;
    
    @Input() dados: Array<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    
    tipoListagem: string;
    limitarSelecao: number = 0;


    constructor() { }
    
    ngOnInit() {
        this.tipoListagem = this.dados[2];
        this.dataSource = new MatTableDataSource(this.dados[0]);
        this.limitarSelecao = 1;
        this.linhaSelecionada = new SelectionModel<any>(false, this.dados[1]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    aplicarFiltro(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    acaoBotaoConcluir() {
        this.selecao.emit({ logradouroBairro: this.linhaSelecionada });
    }

    isAllSelected() {
        const numSelected = this.linhaSelecionada.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    selecionarLinha(row) {
        this.linhaSelecionada.toggle(row);
    }

    masterToggle() {
        this.isAllSelected() ?
            this.linhaSelecionada.clear() :
            this.dataSource.data.forEach(row => this.linhaSelecionada.select(row));
    }

    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        
        return `${this.linhaSelecionada.isSelected(row) ? 'deselect' : 'select'} row ${row.codigo + 1}`;
    }
}