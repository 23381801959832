
<li class="nav-item dropdown eicon-profile-dropdown"
  [class.show]="showCompaniesDropdown"
  [class.user-atach]="true"
>
  <div ngbDropdown class="d-inline-block">
    <a href="javascript:void(0);"
       class="dropdown-toggle"
       id="eiconProfileDropdown"
       title="Soluções Eicon"
       ngbDropdownToggle
       >
       <svg class="icone-header">
        <use
          class=""
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#eicon-logo"
        ></use>
      </svg>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <p>Soluções Eicon</p>
      <div class="logosEicon">
        <div>
    
          <a href="http://www.acto.net.br/" target="_blank" rel="noopener noreferrer"title="Acto">
            <svg class="icone-solucoes">
              <use
                class=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#_logo_Acto"
              ></use>
            </svg>
          </a>
        </div>
        <div>
          <a href="http://www.siss.com.br/" target="_blank" rel="noopener noreferrer"title="Siss">
            <svg class="icone-solucoes">
              <use
                class=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#_logo_Siss"
              ></use>
            </svg>
          </a>
        </div>
        <div>
          <a href="http://www.gdeonline.com.br/" target="_blank" rel="noopener noreferrer"title="Gde">
            <svg class="icone-solucoes">
              <use
                class=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#_logo_Gde"
              ></use>
            </svg>
          </a>
        </div>
        <div>
          <a href="http://www.gier.com.br/" target="_blank" rel="noopener noreferrer"title="Gier">
            <svg class="icone-solucoes">
              <use
                class=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#_logo_Gier"
              ></use>
            </svg>
          </a>
    
        </div>
      </div>
      <div class="logosEicon">
        <div>
    
          <a href="http://www.giexonline.com.br/" target="_blank" rel="noopener noreferrer"title="Giex">
            <svg class="icone-solucoes">
              <use
                class=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#_logo_Giex"
              ></use>
            </svg>
          </a>
        </div>
        <div>
          <a href="http://www.giss.com.br/" target="_blank" rel="noopener noreferrer"title="GissOnLine">
            <svg class="icone-solucoes">
              <use
                class=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#_logo_GissOnline"
              ></use>
            </svg>
          </a>
        </div>
        <div>
          <a href="http://www.icadonline.com.br/" target="_blank" rel="noopener noreferrer"title="iCad">
            <svg class="icone-solucoes">
              <use
                class=""
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#_logo_iCad"
              ></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</li>

