import { ProtocoloLinhaDoTempoDetalhe } from "./protocoloLinhaDoTempoDetalhe";
import { RedeSim } from './redeSim';
import { Documento } from './documento';

export class ProtocoloLinhaDoTempo {
    cliente: string;
    protocolo: string;
    inscricao: string;
    redeSim: RedeSim;
    codigoServico: string;
    descricaoServico: string;
    codigoFase: string;
    descricaoFase: string;
    dataFase: string;
    codigoSituacao: string;
    descricaoSituacao: string;
    dataSituacao: string;
    dataAbertura: string;
    documentos: Array<Documento>;
    linhaDoTempoDetalhes: Array<ProtocoloLinhaDoTempoDetalhe>;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.inscricao = "";
        this.redeSim = new RedeSim();
        this.codigoServico = "";
        this.descricaoServico = "";
        this.codigoFase = "";
        this.descricaoFase = "";
        this.dataFase = "";
        this.codigoSituacao = "";
        this.descricaoSituacao = "";
        this.dataSituacao = "";
        this.dataAbertura = "";
        this.documentos = new Array<Documento>();
        this.linhaDoTempoDetalhes = new Array<ProtocoloLinhaDoTempoDetalhe>();
    }

    
}