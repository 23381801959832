<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<form
    name="formulario"
    [formGroup]="formulario"
>
    <div [class.hidden]="!exibeFormularioEndereco">
        <div class="row">
            <div class="col-12">
                <app-form-endereco tipoEnderecoConfiguracao="2"></app-form-endereco>
            </div>
        </div>
        <div [class.hidden]="!enderecoEmpresarialPreenchimento || tipoEmpresa == 'AEV'"
            class="row">
            <div class="col-12">
                <p>
                    <mat-checkbox #chkApenasCorrespondencia
                        (change)="acaoApenasCorrespondencia()"
                        formControlName="apenasCorrespondencia"
                        id="apenasCorrespondencia"
                        name="apenasCorrespondencia">
                        {{ textoEnderecoApenasCorrespondencia }}
                    </mat-checkbox>
                </p>
            </div>
        </div>
    </div>
    <div [class.hidden]="exibeFormularioEndereco">
        <app-alert [class.hidden]="chkApenasCorrespondencia.checked"
            type="warning">
            Se deseja utilizar o mesmo endereço "ENDEREÇO DO MEU NEGÓCIO" para endereço de correspondência, clique na seta que aponta para a direita que está localizada entre os quadros.
        </app-alert>
        <app-alert [class.hidden]="textoTipoEstabelecimento == ''"
            type="danger">
            {{ textoTipoEstabelecimento }}
        </app-alert>
        <app-alert [class.hidden]="!chkApenasCorrespondencia.checked"
            type="warning">
            {{ textoEnderecoApenasCorrespondencia }}
        </app-alert>
        <app-alert *ngIf="emitirErro"
            type="danger">
            Favor informar todos os endereços.
        </app-alert>
        <div class="row">
            <div class="row col-5 justify-content-center align-items-center div-endereco">
                <p *ngIf="!enderecoEmpresarialPreenchido"
                    id="btn-adicionar-endereco-empresarial"
                    (click) ="acaoAdicionarEnderecoEmpresarial()"
                    class="pIcone existeAcao">
                    <svg class="iconeAdicionar">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'plus-circle' }}"
                        ></use>
                    </svg>
                    <br><br>
                    Adicionar um endereço para minha empresa.
                </p>
                <p *ngIf="enderecoEmpresarialPreenchido"
                    class="titulo-endereco-preenchido">
                    ENDEREÇO DO MEU NEGÓCIO
                </p>
                <p *ngIf="enderecoEmpresarialPreenchido"
                    class="endereco-preenchido"
                    [innerHTML]="enderecoEmpresarialEscolhido">
                </p>
                <div *ngIf="enderecoEmpresarialPreenchido" class="divIcone">
                    <button *ngIf="!enderecoEmpresarialBloqueadoEditar" 
                        type="button" 
                        id="btn-editar-endereco-empresarial"
                        matTooltip="Editar"
                        matTooltipPosition="above"
                        class="btn btn-warning"
                        (click)="editarEnderecoEmpresarial()">
                        <svg class="iconeEndereco"
                            [class.preenchido]="enderecoEmpresarialPreenchido"
                            [class.naoPreenchido]="!enderecoEmpresarialPreenchido">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'edit-3' }}"
                            ></use>
                        </svg>
                    </button>
                    <button *ngIf="enderecoEmpresarialPreenchido && !enderecoEmpresarialBloqueado" 
                        type="button" 
                        id="btn-excluir-endereco-empresarial"
                        matTooltip="Excluir"
                        matTooltipPosition="above"
                        class="btn btn-danger"
                        (click)="limparEnderecoEmpresarial()">
                        <svg class="iconeEndereco"
                            [class.preenchido]="enderecoEmpresarialPreenchido"
                            [class.naoPreenchido]="!enderecoEmpresarialPreenchido">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <svg class="iconeAdicionar"
                    [class.existeAcao]="!chkApenasCorrespondencia.checked"
                    [matTooltip]="chkApenasCorrespondencia.checked ? 'Apenas para correspondência' : 'Copiar'"
                    matTooltipPosition="above"
                    (click) ="acaoUtilizarParaCorrespondencia()">
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ 
                            chkApenasCorrespondencia.checked ? 'mail' : 'arrow-right-circle'
                        }}"
                    ></use>
                </svg>
            </div>
            <div class="row col-5 justify-content-center align-items-center div-endereco">
                <p *ngIf="!enderecoCorrespondenciaPreenchido"
                    id="btn-adicionar-endereco-correspondencia"
                    (click) ="acaoAdicionarEnderecoCorrespondencia()"
                    class="pIcone existeAcao">
                    <svg class="iconeAdicionar">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'plus-circle' }}"
                        ></use>
                    </svg>
                    <br><br>
                    Adicionar um endereço diferente para correspondência.
                </p>
                <p *ngIf="enderecoCorrespondenciaPreenchido"
                    class="titulo-endereco-preenchido">
                    ENDEREÇO DE CORRESPONDÊNCIA
                </p>
                <p *ngIf="enderecoCorrespondenciaPreenchido"
                    id="pEmpresa" 
                    [innerHTML]="enderecoCorrespondenciaEscolhido"
                    class="endereco-preenchido">
                </p>
                <div *ngIf="enderecoCorrespondenciaPreenchido && !chkApenasCorrespondencia.checked && !enderecoCorrespondenciaBloqueado" class="divIcone">
                    <button type="button" 
                        id="btn-editar-endereco-correspondencia"
                        matTooltip="Editar"
                        matTooltipPosition="above"
                        class="btn btn-warning"
                        (click)="editarEnderecoCorrespondencia()">
                        <svg class="iconeEndereco">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'edit-3' }}"
                            ></use>
                        </svg>
                    </button>
                    <button type="button" 
                        id="btn-excluir-endereco-correspondencia"
                        matTooltip="Excluir"
                        matTooltipPosition="above"
                        class="btn btn-danger"
                        (click)="limparEnderecoCorrespondencia()">
                        <svg class="iconeEndereco">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>