import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeModule } from '../../../pipes/pipes.module';
import { ConsultarProtocoloComponent } from './consultar-protocolo.component';
import { AtendimentoGridConsultaModule } from '../../outros/atendimento/atendimento-grid-consulta/atendimento-grid-consulta.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LinhaDoTempoModule } from '../../outros/linha-do-tempo/linha-do-tempo.module';
import { AtendimentoModule } from '../../outros/atendimento/atendimento.module';
import { InformacoesCadastroModule } from '../../outros/informacoes-cadastro/informacoes-cadastro.module';
import { AlertModule } from '../../../components/alert/alert.module';
import { AnexarModule } from '../../outros/anexar/anexar.module';
import { InformacaoRedeSimModule } from '../../outros/informacoes-cadastro/informacao-rede-sim/informacao-rede-sim.module';
import { TaxasModule } from '../../outros/taxas/taxas.module';
import { InformacaoRedeSimPerguntasModule } from '../../outros/informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.module';


@NgModule({
  declarations: [
    ConsultarProtocoloComponent
  ],
  imports: [
    CommonModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    MatMenuModule,
    MatTableExporterModule,
    MatIconModule,
    MatTooltipModule,
    AlertModule,
    PipeModule,
    AtendimentoGridConsultaModule,
    AtendimentoModule,
    InformacoesCadastroModule,
    LinhaDoTempoModule,
    AnexarModule,
    TaxasModule,
    InformacaoRedeSimModule,
    TaxasModule,
    InformacaoRedeSimPerguntasModule,
  ],
  exports: [
    ConsultarProtocoloComponent
  ],
})
export class ConsultarProtocoloModule {}
