import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";

import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { Constantes } from "src/app/model/constantes";
import { FormRequerenteComponent } from "src/app/pages/outros/form-requerente/form-requerente.component";
import { FormIdentificacaoComponent } from "src/app/pages/outros/form-identificacao/form-identificacao.component";
import { FormLocalizacaoComponent } from "src/app/pages/outros/form-localizacao/form-localizacao.component";
import { FormAtividadeComponent } from "src/app/pages/outros/form-atividade/form-atividade.component";
import { FormResponsavelComponent } from "src/app/pages/outros/form-responsavel/form-responsavel.component";
import { FormQuestionarioComponent } from "src/app/pages/outros/form-questionario/form-questionario.component";
import { FormResumoComponent } from "src/app/pages/outros/form-resumo/form-resumo.component";
import { Configuracao } from "src/app/model/configuracao";
import { Servico } from "src/app/model/servico";
import { MatStepper } from "@angular/material/stepper";
import { Pergunta } from "src/app/model/pergunta";
import { Protocolo } from "src/app/model/protocolo";
import { Processo } from "src/app/model/processo";
import { Documento } from "src/app/model/documento";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import { PerguntaResposta } from "src/app/model/perguntaResposta";
import { EmpresaService } from "src/app/services/empresa/empresa.service";
import { Empresa } from "src/app/model/empresa";
import { Atividade } from "src/app/model/atividade";
import { ComprovanteAlteracao } from "src/app/components/pdf/comprovanteAlteracao";
import { FormIdentificacaoNegocioComponent } from "src/app/pages/outros/form-identificacao-negocio/form-identificacao-negocio.component";
import { FormValidator } from 'src/app/components/validators/form-validator';

@Component({
    selector: "app-alteracao-autonomo",
    templateUrl: "./alteracao-autonomo.component.html"
})
export class AlteracaoAutonomoComponent implements OnInit {
    
    tipoEmpresa: string;
    informativo: string;
    stepper: MatStepper;
    passoAnterior: number;
    passoAtual: number;
    configuracao: Configuracao;
    processo: Processo;
    tipoProcesso = Constantes.PROCESSO;
    servicos: Array<Servico>;
    empresa: Empresa;
    protocolo: Protocolo;
    protocoloTemporario: Protocolo;
    documentos: Array<Documento>;

    // Formulários
    formAlteracao: FormGroup;
    formularioRequerente: FormGroup;
    formularioIdentificacao: FormGroup;
    formularioIdentificacaoNegocio: FormGroup;
    formularioLocalizacao: FormGroup;
    formularioAtividade: FormGroup;
    formularioResponsavel: FormGroup;
    formularioQuestionarioViabilidade: FormGroup;
    formularioQuestionarioFic: FormGroup;
    formularioResumoAlteracao: FormGroup;

    @ViewChild(FormRequerenteComponent)
    formRequerente: FormRequerenteComponent;

    @ViewChild(FormIdentificacaoComponent)
    formIdentificacao: FormIdentificacaoComponent;

    @ViewChild(FormIdentificacaoNegocioComponent)
    formIdentificacaoNegocio: FormIdentificacaoNegocioComponent;

    @ViewChild(FormLocalizacaoComponent)
    formLocalizacao: FormLocalizacaoComponent;

    @ViewChild(FormAtividadeComponent)
    formAtividade: FormAtividadeComponent;

    @ViewChild("formQuestionarioViabilidade")
    formQuestionarioViabilidade: FormQuestionarioComponent;

    @ViewChild("formQuestionarioFic")
    formQuestionarioFic: FormQuestionarioComponent;

    @ViewChild(FormResponsavelComponent)
    formResponsavel: FormResponsavelComponent;

    @ViewChild(FormResumoComponent)
    formResumo: FormResumoComponent;

    // Botões
    btnAnteriorLocalizacao: string = "Anterior";
    btnAvancarLocalizacao: string = "Avançar";

    // Botões
    btnAnteriorResponsavel: string = "Anterior";
    btnAvancarResponsavel: string = "Avançar";

    emitirErroIdentificacao: boolean;
    mensagemErro: string;

    constructor(
        private router: Router,
        private protocoloService: ProtocoloService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private empresaService: EmpresaService) {

        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];    

        this.empresa = new Empresa();
        this.empresa.registro.documentos = [new Documento()];

        this.protocolo = new Protocolo();
        this.protocoloTemporario = new Protocolo();
        this.protocoloTemporario.cliente = localStorage.getItem("pId");
        this.protocoloTemporario.viabilidade.perguntasUsuario = new PerguntaResposta();
        this.protocoloTemporario.fic.perguntasUsuario = new PerguntaResposta();

        this.formAlteracao = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
        });

        this.informativo = Constantes.INFORMATIVO_ALTERACAO_EMPRESA_MEI[0];

        this.documentos = new Array<Documento>();

        setTimeout(() => {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioIdentificacao = this.formIdentificacao.formulario;
            this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
            this.formularioResumoAlteracao = this.formResumo.formulario;
        }, 0);

        this.processo = new Processo();
        
        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        this.configuracao.processos
            .forEach(processo => { 
                if (processo.codigo === "A" && processo.tipo === "S" && processo.tipoPessoa == Constantes.TIPO_PESSOA.Fisica) {
                    this.processo.cliente = processo.cliente;
                    this.processo.codigo = 'U';
                    this.processo.descricao = processo.descricao;
                    this.processo.protocolo = processo.protocolo;
                    this.processo.tipo = 'U';
                    this.protocoloTemporario.processo = this.processo;
                    this.servicos = processo.servicos;

                } 
            }
        );

        this.emitirErroIdentificacao = false;
        this.protocolo = Object.assign(this.protocolo, this.protocoloTemporario);
    }

    ngOnInit(): void {
        this.passoAnterior = 0;
        this.passoAtual = 0;

    }

    verificaValidacaoFormulario(formGroup: FormGroup) {
        
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });
    }

    buscarEmpresa() {
        let cnpj = "";
        
        this.empresa.registro.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj)
                cnpj = documento.numero;
        });

        if (cnpj.toString().replace(/\D/g,"") == this.formIdentificacao.cpfCnpjEmpresa.value.toString().replace(/\D/g,"")
            && this.empresa.inscricao.toString().replace(/\D/g,"") == this.formIdentificacao.im.value.toString().replace(/\D/g,"")) {
                this.formIdentificacao.controleValidade.setValue(true);
                this.stepper.next();
                return false;
        }

        this.empresaService
            .buscarParaAlteracao(
                this.protocoloTemporario.cliente, 
                this.formIdentificacao.cpfCnpjEmpresa.value.replace(/\D/g,""), 
                this.formIdentificacao.im.value.replace(/\D/g,""))
            .pipe(take(1))
            .subscribe( retorno => {
                this.empresa = retorno;
                if (retorno.codigoErro != null) {
                    Swal.fire({
                        title: retorno.titulo,
                        html: retorno.mensagem,
                        icon: retorno.status,                       
                    })
                    return false;                   
                }     
                else if (this.empresa.status == Constantes.STATUS_EMPRESA.Nao_Existe) {
                    Swal.fire("", "Inscrição Municipal não localizada na base de dados Municipal, verifique a informação tente novamente.", "error");
                    return false;
                }
                else if (this.empresa.status == Constantes.STATUS_EMPRESA.Alteracao_Em_Andamento) {
                    Swal.fire("", "Empresa já possui um processo de alteração em andamento.", "info");
                    return false;
                }
                else if (this.empresa.status == Constantes.STATUS_EMPRESA.Suspensa) {
                    Swal.fire({
                        title: "",
                        html: `Inscrição Municipal suspensa, não é possível solicitar uma alteração.`,
                        icon: "warning"
                    })
                    return false;
                }
                else if (this.empresa.status == Constantes.STATUS_EMPRESA.Empresa_Encerrada) {
                    Swal.fire({
                        title: "",
                        html: `Inscrição Municipal foi baixada em ${ retorno.encerramento.data }, não é possível solicitar uma alteração.`,
                        icon: "warning"
                    })
                    return false;
                }
                else if (this.empresa.status == Constantes.STATUS_EMPRESA.Ativa) {
                    this.formIdentificacao.controleValidade.setValue(true);
                    Swal.fire("", "Inscrição encontrada, pode seguir com os passos de alteração!", "success");
                    this.empresa = retorno;
                    this.preencherCamposIdentificacaoNegocio();
                    this.preencherCamposEndereco();
                    this.preencherCamposAtividade();
                    this.preencherCamposResponsavel();
                    this.preencherCamposQuestionario();
                    this.stepper.next();
                }
                
            })
    }

    carregarIdentificacao() {
        this.configuracao.processos.forEach( processo => {
            if (processo.codigo == "A" && processo.tipo == "S") {
                processo.servicos.forEach( servico => {
                    if (servico.codigo == this.formRequerente.orgaoDeRegistro.value) {
                        servico.naturezaJuridicas.forEach( natureza => {
                            if (natureza.codigo == this.formRequerente.naturezaJuridica.value) {
                                this.documentos = natureza.documentos;
                                this.formResponsavel.socioRegras = natureza.socioRegras;
                                this.formResponsavel.obrigatorioContador = natureza.obrigatorioContador;
                            }
                        })
                    }
                })
            }
        });
    }


    preencherCamposIdentificacaoNegocio() {
        this.formIdentificacaoNegocio.preencherCampos(this.empresa);

        if(this.protocolo.processo.codigo == 'U' && Constantes.TIPO_EMPRESA.Autonomo){
            this.formIdentificacaoNegocio.cpfAutonomo.disable();
        }

    }

    preencherCamposEndereco() {
        this.formLocalizacao.exibeFormularioEndereco = !this.empresa.viabilidade.enderecos.length;
        this.empresa.viabilidade.enderecos.forEach( endereco => {
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Empresarial) {
                this.formLocalizacao.preenchimentoInicial= false;
                this.formLocalizacao.preencherEnderecoEmpresarial(endereco);
            }
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Correspondencia) {
                this.formLocalizacao.preencherEnderecoCorrespondencia(endereco);
            }
        })
    }

    preencherCamposAtividade() {

        this.formAtividade.atividadesSelecionadas = new Array<Atividade>();
        this.formAtividade.atividadesSelecionadas = this.empresa.viabilidade.atividadesExercidas;

        this.formAtividade.tipoUnidade.setValue(this.empresa.viabilidade.tipoUnidade);
        this.formAtividade.tipoUnidadeAuxiliar.setValue(this.empresa.viabilidade.tipoUnidadeAuxiliar);
        
        this.empresa.viabilidade.formasAtuacao.forEach( formaAtuacao => {
            this.formAtividade.formasAtuacaoSelecionadas.push(formaAtuacao.codigo);
        })
        this.formAtividade.descricaoAtividadeEconomica.setValue(this.empresa.viabilidade.descricaoAtividadeEconomica);
    }

    preencherCamposResponsavel() {
        this.formResponsavel.socios = this.empresa.fic.socios;
    }

    preencherCamposQuestionario() {
        this.empresa.viabilidade.perguntasUsuario.perguntas.forEach( pergunta => {
            this.formQuestionarioViabilidade.perguntasRespondidasOutrosProcessos.push(pergunta);
        })
        this.empresa.fic.perguntasUsuario.perguntas.forEach( pergunta => {
            this.formQuestionarioFic.perguntasRespondidasOutrosProcessos.push(pergunta);
        })
    }

    avancarPassoIdentificacao(passos) {

        if (this.stepper == null) {
            this.stepper = passos;
        } 
        
        this.formularioRequerente = this.formRequerente.formulario;
        this.formularioIdentificacao = this.formIdentificacao.formulario;
        this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
        this.verificaValidacaoFormulario(this.formularioRequerente);
        this.verificaValidacaoFormulario(this.formularioIdentificacao);
        if (this.formularioRequerente.valid && this.formIdentificacao.cpfCnpjEmpresa.valid && this.formIdentificacao.im.valid) {
            this.emitirErroIdentificacao = false;
            this.carregarIdentificacao();
            this.buscarEmpresa();
        }
        else {
            this.formIdentificacao.controleValidade.setValue("");
            let mensagemErro = "";
            
            if (this.formularioRequerente.invalid) {
                mensagemErro += "Favor corrigir os campos nos dados do requerente e/ou local de registro da empresa.<br>"
            }
            
            if (this.formularioIdentificacao.invalid) {
                mensagemErro += "Favor corrigir os campos de identificação da empresa.<br>"
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErroIdentificacao = true;
            this.mensagemErro = mensagemErro;
        }
        
    }

    avancarPassoIdentificacaoNegocio() {
        this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
        this.verificaValidacaoFormulario(this.formIdentificacaoNegocio.formulario);
        if (this.formularioIdentificacaoNegocio.valid) {
            this.formIdentificacaoNegocio.emitirErro = false;
            this.formIdentificacaoNegocio.mensagemErro = "";
            this.stepper.next();
        }
        else {
            let mensagemErro = "";
            
            if (this.formularioIdentificacaoNegocio.invalid) {
                mensagemErro += "Favor corrigir os campos obrigatórios.<br>"
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.formIdentificacaoNegocio.emitirErro = true;
            this.formIdentificacaoNegocio.mensagemErro = mensagemErro;
        }
    }

    renomearBotaoLocalizacao() {
        this.btnAnteriorLocalizacao = "Anterior";
        this.btnAvancarLocalizacao = "Avançar";

        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (!this.formLocalizacao.preenchimentoInicial) {
                this.btnAnteriorLocalizacao = "Cancelar";
                this.btnAvancarLocalizacao = "Confirmar";
            }
        }
    }

    voltarPassoLocalizacao() {
        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (this.formLocalizacao.preenchimentoInicial) {
                this.stepper.previous();
            }
            else {
                this.formLocalizacao.toggle();
            }
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoLocalizacao() {
        this.formularioLocalizacao = this.formLocalizacao.formulario;
        this.verificaValidacaoFormulario(this.formLocalizacao.formulario);
        if (this.formLocalizacao.validarFormulario()) {
            var mensagemHtml = "";

            if (this.formLocalizacao.apenasCorrespondencia.value) {
                mensagemHtml = 'Caro contribuinte, ao avançar com a alteração será classificado como <b>Autônomo Não Estabelecido</b>. <br><br>Caso seja um Autônomo Estabelecido, selecione Cancelar, em seguida selecione a opção Editar no Endereço do Meu Negócio e desmarque a opção: <br><br><b>Não possuo estabelecimento fixo, autônomo não estabelecido.</b>'}
            else {
                mensagemHtml = 'Caro contribuinte, ao avançar com a alteração será classificado como <b>Autônomo Estabelecido</b>. <br><br>Caso seja um Autônomo Não Estabelecido, selecione Cancelar, em seguida selecione a opção Editar no Endereço do Meu Negócio e selecione a opção: <br><br><b>Não possuo estabelecimento fixo, autônomo não estabelecido.</b>'
            }

            Swal.fire({
            title: "", 
            html: mensagemHtml,
            icon: 'question',
            showCancelButton: true})
            .then((result) => {
                if (result.value) {
                    this.stepper.next();
                }
            });
        }
        
    }

    avancarPassoAtividade() {
        if (this.formAtividade.validarFormulario())
        this.stepper.next();
        
    }

    renomearBotaoResponsavel() {
        this.btnAnteriorResponsavel = "Anterior";
        this.btnAvancarResponsavel = "Avançar";

        if (this.formResponsavel.editarResponsavel) {
            this.btnAnteriorResponsavel = "Cancelar";
            this.btnAvancarResponsavel = "Confirmar";
        }
    }

    voltarPassoResponsavel() {
        if (this.formResponsavel.editarResponsavel) {
            this.formResponsavel.toggle();
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoResponsavel() {
        this.formularioResponsavel = this.formResponsavel.formulario;
        if (this.formResponsavel.validarFormulario())
            this.stepper.next();
        
    }

    avancarPassoQuestionario() {
        this.verificaValidacaoFormulario(this.formQuestionarioViabilidade.formulario);
        this.verificaValidacaoFormulario(this.formQuestionarioFic.formulario);
        if (this.formQuestionarioViabilidade.validarFormulario() && this.formQuestionarioFic.validarFormulario()) {
            this.stepper.next();
        }
    }

    compararAlteracoes() {

    }

    mudancaPasso(event, passos) {
        if (this.stepper == null) {
            this.stepper = passos;
        } 

        if (this.passoAtual == 0) {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioIdentificacao = this.formIdentificacao.formulario;
            this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioResponsavel = this.formResponsavel.formulario;
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
            this.formularioResumoAlteracao = this.formResumo.formulario;
        }
        
        if ( this.passoAtual == 2 ) {
            this.formAtividade.validarFormulario();
        }

         if (event.selectedIndex == 0) {
            this.formIdentificacao.controleValidade.setValue("");
        }

         if (event.selectedIndex == 1) {
      
        }
        
        if (event.selectedIndex == 2) {
            this.formLocalizacao.controleValidade.setValue("");
        }

        if ( event.selectedIndex == 3) {
            this.formAtividade.controleValidade.setValue("");
        }

        if ( event.selectedIndex == 4) {
            this.formResponsavel.controleValidade.setValue("");
        }
        
        if (event.selectedIndex == 5) {
            // Questionário de Viabilidade
            this.formQuestionarioViabilidade.controleValidade.setValue("");
            this.formQuestionarioViabilidade.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
    
            let perguntaViabilidade = new Pergunta();
            perguntaViabilidade.cliente = localStorage.getItem("pId");
            perguntaViabilidade.tipo = this.formLocalizacao.apenasCorrespondencia.value ? Constantes.TIPO_DE_PERGUNTA.Viabilidade_Autonomo_Nao_Estabelecido : Constantes.TIPO_DE_PERGUNTA.Viabilidade_Autonomo;
            perguntaViabilidade.atividades = this.formAtividade.atividadesSelecionadas;
            
            this.formQuestionarioViabilidade.carregarPerguntas(perguntaViabilidade);
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;

            // Questionário de Fic
            this.formQuestionarioFic.controleValidade.setValue("");
            this.formQuestionarioFic.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
    
            let perguntaFic = new Pergunta();
            perguntaFic.cliente = localStorage.getItem("pId");
            perguntaFic.tipo = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ? Constantes.TIPO_DE_PERGUNTA.Fic_Evento : 
                                this.formLocalizacao.apenasCorrespondencia.value ? Constantes.TIPO_DE_PERGUNTA.Fic_Autonomo_Nao_Estabelecido : Constantes.TIPO_DE_PERGUNTA.Fic_Autonomo;
            perguntaFic.atividades = this.formAtividade.atividadesSelecionadas;
            
            this.formQuestionarioFic.carregarPerguntas(perguntaFic);
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
        }
        
        this.informativo = Constantes.INFORMATIVO_ALTERACAO_EMPRESA_MEI[event.selectedIndex];
        this.passoAtual = event.selectedIndex;
        this.passoAnterior = event.previouslySelectedIndex;

        this.montarAlteracao(event);
    }

    montarAlteracao(event) {
        if ( event.previouslySelectedIndex == 0) {

            let documento = new Documento();
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documento.nome = "CPF";
            documento.numero = localStorage.getItem("userId");

            this.protocolo.solicitante.documentos = [documento];
            
            this.formRequerente.preencher();
            this.protocolo.requerente = this.formRequerente.requerente;
            this.processo.servicos = [this.formRequerente.servicoEscolhido];

            this.protocolo.data = new Date().toLocaleDateString();
            this.formIdentificacaoNegocio.protocolo = this.protocolo;
            this.formIdentificacaoNegocio.cpfAutonomo = this.formIdentificacao.cpfCnpjEmpresa.value;

            // Ajuste de fluxo - os fluxos são fixos no antigo Icad - ver forma de deixar dinâmico
            // Código de serviço tem que ser 20 para Empresa e 24 para Mei
            //this.processo.servicos[0].codigo = 22;

            this.protocoloTemporario.requerente = this.formRequerente.requerente;
            this.protocoloTemporario.data = new Date().toLocaleDateString("pt-br");
            
        }
        else if ( event.previouslySelectedIndex == 1) {
            this.formIdentificacaoNegocio.preencher();
            this.protocoloTemporario.registro = this.formIdentificacaoNegocio.protocolo.registro;
            this.protocoloTemporario.fic = this.formIdentificacaoNegocio.protocolo.fic;
        }
        else if (event.previouslySelectedIndex == 2) {
            this.protocoloTemporario.viabilidade.enderecos = [
                this.formLocalizacao.enderecoEmpresarial,
                this.formLocalizacao.enderecoCorrespondencia,
            ];
            this.formResumo.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
            //SETANDO FLUXO DE AUTONOMO ESTABELECIDO NA MÃO
            this.processo.servicos[0].codigo = 22;
            this.protocolo.registro.estabelecido = "S";

            //SETANDO FLUXO DE AUTONOMO NÃO ESTABELECIDO NA MÃOs
            if(this.formLocalizacao.apenasCorrespondencia.value){
                this.processo.servicos[0].codigo = 23;
                this.protocolo.registro.estabelecido = "N";
            }
            
        }
        else if (event.previouslySelectedIndex == 3) {
            this.formAtividade.preencher();

            this.protocoloTemporario.viabilidade.atividadesExercidas = this.formAtividade.atividadesExercidas;

            this.protocoloTemporario.viabilidade.tipoUnidade = this.formAtividade.tipoUnidade.value;
            this.protocoloTemporario.viabilidade.tipoUnidadeAuxiliar = this.formAtividade.tipoUnidadeAuxiliar.value;
            
            this.protocoloTemporario.viabilidade.formasAtuacao = this.formAtividade.formasAtuacao;
            this.protocoloTemporario.viabilidade.descricaoAtividadeEconomica = this.formAtividade.descricaoAtividadeEconomica.value;
        }
        else if (event.previouslySelectedIndex == 4) {
            this.protocoloTemporario.fic.socios = this.formResponsavel.socios;
        }
        else if (event.previouslySelectedIndex == 5) {
            this.formQuestionarioViabilidade.preencher();
            this.protocoloTemporario.viabilidade.perguntasUsuario = this.formQuestionarioViabilidade.perguntasRespostas;
            
            this.formQuestionarioFic.preencher();
            this.protocoloTemporario.fic.perguntasUsuario = this.formQuestionarioFic.perguntasRespostas;
        }
        
        this.formResumo.empresa = this.empresa;
        this.formResumo.protocolo = this.protocoloTemporario;
        this.formResumo.preencher();
        
    }

    gerarAlteracao() {
        this.formResumo.preencherAnexos();

        if (!this.formResumo.termoDeAceite.value) {
            Swal.fire("", "Para que a solicitação seja finalizada ,você devera estar de acordo com o termo de responsabilidade.", "info")
            return false;
        }

        this.protocolo = Object.assign(this.protocolo, this.protocoloTemporario);

        this.protocoloService
            .gerarAlteracao(this.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    willClose: () => {
                        if (retorno.redirecionamento != null) {
                            this.router.navigate([retorno.redirecionamento]);
                        }
                    }
                })
            });
    }

}