import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  
  public menus: Array<any>;
  navigationSubscription; 
  tipoMenu: string;

  constructor(private authenticationService: AuthenticationService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {    
    this.menus = new Array<any>();
    this.tipoMenu = "";
    if (this.activatedRoute.snapshot.data["menu"] == "orgao") {
      this.tipoMenu = this.activatedRoute.snapshot.params["orgao"];

      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.initialiseInvites();
        }
      });
    } 
    else if (this.activatedRoute.snapshot.data["menu"] != null) {
      this.tipoMenu = this.activatedRoute.snapshot.data["menu"];
    }

   }

  ngOnInit(): void {
    this.authenticationService.currentAxis.subscribe((axis) => {
      if (axis != "" && axis != null) {
        this.criarMenus(axis);
      }            
    });
  }

  ngOnDestroy() {
     if (this.navigationSubscription) {  
        this.navigationSubscription.unsubscribe();
     }
  }

  initialiseInvites() {
    this.tipoMenu = this.activatedRoute.snapshot.params["orgao"];
  }

  criarMenus(menus) {
    menus.item.forEach((item) => {
      // item.rotaSistema = '/' + item.rota.split('.').splice(1, Number.MAX_VALUE).join('/');

      if (item.item.length) {
        this.menus.push({icone: item.icone, 
          descricao: item.descricao, 
          rota: item.rotaSistema, 
          class: item.class, 
          menu: item.rota.split('.')[item.rota.split('.').length - 1], 
          titulo: item.titulo,
          subTitulo: item.subTitulo, 
          itens: new Array<any>()}); 
      }

      item.item.forEach((funcionalidade) => {
        funcionalidade.rotaSistema =
        '/' + funcionalidade.rota.split('.').splice(1, Number.MAX_VALUE).join('/')
        + '/' + item.codigo
        + '/' + funcionalidade.orgao
        + '/' + funcionalidade.codigo;
        
        this.menus[this.menus.length - 1].itens.push({
          icone: funcionalidade.icone, 
          descricao: funcionalidade.descricao, 
          rota: funcionalidade.rotaSistema,
          nome: item.nome + '_' + funcionalidade.nome
        });
      });
    });
  }

}
