<div class="layout-px-spacing">

    <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-md-12">

            <div class="mail-box-container">
                <div class="mail-overlay"></div>

                <div id="todo-inbox" class="accordion todo-inbox">
                    <div class="search">
                        <input #filtro
                            type="text"
                            (input)="acaoDoFiltro(filtro.value)"
                            class="form-control input-search" placeholder="Localizar por aqui...">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu mail-menu d-lg-none"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                    </div>
            
                    <div class="todo-box">
                        
                        <div id="ct" class="todo-box-scroll" [perfectScrollbar]="config" >
                            <div *ngFor="let empresa of empresas; let i = index"
                                class="todo-item all-list">
                                <div class="todo-item-inner">
                                    <div class="row n-chk justify-content-center align-items-center">
                                        <p class="meta-date text-center">
                                            <svg 
                                                class="iconeGrid {{ empresa.tipoEmpresaClasse }}">
                                                <use
                                                    attr.xlink:href="#{{ empresa.tipoEmpresaIcone }}"
                                                ></use>
                                            </svg><br>
                                            {{ empresa.tipoEmpresaDescricao }}
                                        </p>
                                    </div>

                                    <div class="todo-content row-linha">
                                        <h3 class="todo-heading" [attr.data-todoHeading]="empresa.inscricao + ' - ' + empresa.cpfCnpj + ' - ' + empresa.razaoSocial">
                                            {{ empresa.razaoSocial }}
                                        </h3>
                                        <p class="meta-date" *ngIf="empresa.qtdDePendencias.toString() != '0'">
                                            <button type="button" (click)="irParaPendencias(empresa)" class="btn btn-sm btn-informacao-atividade btn-warning">
                                                VISUALIZAR PENDÊNCIAS
                                            </button>
                                            <b>ATENÇÃO - Existe(m) {{ empresa.qtdDePendencias }} pendência(s) para este negócio, acesse o menu de pendências para visualiza-lás.</b>
                                        </p>
                                        <p class="meta-date">
                                            <b>Inscrição Municipal:</b> {{ empresa.inscricao }} - <b>CPF / CNPJ:</b> {{ formatToCPFOrCNPJ(empresa.cpfCnpj) }}
                                        </p>
                                        <p class="meta-date">
                                            <b>Data de Abertura:</b> {{ empresa.dataAbertura }} {{ empresa.dataEncerramento != '' ? ' - ' : '' }}<b class="texto-warning">{{ empresa.dataEncerramento != '' ? 'EMPRESA ENCERRADA EM ' + empresa.dataEncerramento : empresa.dataSuspensao != '' ? 'NEGÓCIO SUSPENSO DE OFÍCIO EM ' + empresa.dataSuspensao : '' }}</b>
                                        </p>
                                        <p class="meta-date">
                                            <b>Licenciamento:</b>
                                            <span 
                                                [class.texto-success]="empresa.situacaoEmpresa == situacaoAlvaraEmpresa.Regular.toString() || empresa.situacaoEmpresa == situacaoAlvaraEmpresa.Regular_Cancelado.toString()"
                                                [class.texto-secondary]="empresa.situacaoEmpresa == ''"
                                                [class.texto-danger]="empresa.situacaoEmpresa == situacaoAlvaraEmpresa.Irregular.toString()"
                                                [class.texto-info]="empresa.situacaoEmpresa == situacaoAlvaraEmpresa.Indefinida.toString()"
                                                [class.texto-warning]="empresa.situacaoEmpresa == situacaoAlvaraEmpresa.Em_Regularizacao.toString()">
                                                <b>
                                                    {{ empresa.situacaoEmpresaDescricao | uppercase }}
                                                </b>
                                            </span> <br>
                                        </p>
                                        <p class="meta-date" *ngIf="empresa.qtdeDam != ''">
                                            <button type="button" (click)="irParaMeusDam(empresa)" class="btn btn-sm btn-informacao-atividade btn-warning">
                                                MEUS DAM
                                            </button>
                                            <b>Quantidade de DAM em aberto:</b> {{ empresa.qtdeDam }}
                                        </p>
                                        <p class="meta-date">
                                            <img
                                                class="icone-quadro-societario"
                                                matTooltip="Responsável Legal"
                                                matTooltipPosition="below"
                                                src="../../../assets/img/icons/vinculo_{{ empresa.responsavelLegal ? 'L' : 'N' }}.png"
                                            />
                                            <img
                                                class="icone-quadro-societario"
                                                matTooltip="Sócio"
                                                matTooltipPosition="below"
                                                src="../../../assets/img/icons/vinculo_{{ empresa.socio ? 'S' : 'N' }}.png"
                                            />
                                            <img
                                                class="icone-quadro-societario"
                                                matTooltip="Responsável Contábil"
                                                matTooltipPosition="below"
                                                src="../../../assets/img/icons/vinculo_{{ empresa.contador ? 'C' : 'N' }}.png"
                                            />
                                        </p>
                                    </div>
                                    <div class="action-dropdown custom-dropdown-icon">
                                        <div ngbDropdown class="d-inline-block">
                                            <a class="dropdown-toggle" href="javascript:void(0);" role="button" id="dropdownMenuLink-2" ngbDropdownToggle>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                            </a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <a class="dropdown-item" href="javascript:void(0);"
                                                    (click)="visualizar(empresa)"
                                                >Visualizar</a>
                                                <a class="dropdown-item" href="javascript:void(0);"
                                                    (click)="cicloDeVida(empresa)"
                                                >Ciclo de Vida</a>
                                                <a class="dropdown-item" href="javascript:void(0);" *ngIf="empresa.qtdeDam != ''"
                                                    (click)="irParaMeusDam(empresa)"
                                                >Meus DAM</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>                                    
            </div>
            
        </div>
    </div>

</div>