import { Component, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";

import { GridProviderPaginacao } from "./grid-provider-paginacao";
import { SelectionModel } from "@angular/cdk/collections";
import Swal from 'sweetalert2';
import { Constantes } from '../../model/constantes';
import { Usuario } from '../../model/usuario';
import { AtendimentoService } from '../../services/operacao/atendimento.service';
import { take } from 'rxjs/operators';

@Component({
    selector: "app-grid-usuarios",
    templateUrl: "./grid-usuarios.component.html",
    styleUrls: ["./grid.component.scss"],
    providers: [ 
        {
            provide: MatPaginatorIntl,
            useClass: GridProviderPaginacao
        }
    ]
})
export class GridUsuariosComponent {
    @Output() selecao = new EventEmitter();
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ["selecao","login","nome"];
    linhaSelecionada: SelectionModel<Usuario>;
    
    @Input() dados: Array<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    
    usuarios: Array<Usuario>;
    tipoEmpresa: string;
    

    constructor(private atendimentoService: AtendimentoService) { }
    
    ngOnInit() {

        this.atendimentoService
            .buscarAtendentes(this.dados[0])
            .pipe(take(1))
            .subscribe( retorno => {
                this.tipoEmpresa = this.dados[2];
                this.dataSource = new MatTableDataSource(retorno);
                this.linhaSelecionada = new SelectionModel<Usuario>(false);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            });

    }

    aplicarFiltro(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    acaoBotaoConcluir() {
        this.selecao.emit({ usuarios: this.linhaSelecionada });
    }

    isAllSelected() {
        const numSelected = this.linhaSelecionada.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    selecionarUsuarios(row) {
        this.linhaSelecionada.toggle(row);
    }

    masterToggle() {
        this.isAllSelected() ?
            this.linhaSelecionada.clear() :
            this.dataSource.data.forEach(row => this.linhaSelecionada.select(row));
    }

    checkboxLabel(row?: Usuario): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        
        return `${this.linhaSelecionada.isSelected(row) ? 'deselect' : 'select'} row ${row.codigo + 1}`;
    }
}