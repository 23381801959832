import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

import { Requisito } from '../../../../model/requisito';
import { Orgao } from '../../../../model/orgao';
import { Constantes } from '../../../../model/constantes';
import { RequisitoService } from '../../../../services/requisito/requisito.service';
import { Atendimento } from '../../../../model/atendimento';
import { MatDialog } from '@angular/material/dialog';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { Atividade } from '../../../../model/atividade';
import { Documento } from '../../../../model/documento';
import { MatCheckbox } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfiguracaoService } from '../../../../services/configuracao/configuracao.service';
import { RequisitoRisco } from '../../../../model/requisitoRisco';



@Component({
  selector: 'app-cadastrar-requisito',
  templateUrl: './cadastrar-requisito.component.html',
  styleUrls: ['../gerenciamento-de-requisitos.component.scss'],
})
export class CadastrarRequisitoComponent implements OnInit {

  formulario: FormGroup;

  requisito: Requisito;
  orgaos: Array<Orgao>;
  documentos: Array<Documento>
  requisitoRiscos: Array<RequisitoRisco>

  emitirErro: boolean;
  mensagemErro: string;

  obrigatorio = false;

  atendimento: Array<any> = new Array<any>();
  tipoEmpresa = Constantes.TIPO_EMPRESA;
  tipoDeVinculo = Constantes.TIPO_DE_VINCULO;

  acaoAtendimento: string;
  acaoAtendimentoAuxiliar = Constantes.ACAO_ATENDIMENTO;

  atividadesSelecionadasEmpresa: Array<Atividade> = new Array<Atividade>();
  atividadesSelecionadasMei: Array<Atividade> = new Array<Atividade>();
  atividadesSelecionadasAutonomo: Array<Atividade> = new Array<Atividade>();
  atividadesSelecionadasAutonomoEstabelecido: Array<Atividade> = new Array<Atividade>();
  atividadesSelecionadasAutonomoNaoEstabelecido: Array<Atividade> = new Array<Atividade>();

  listaDeDocumentos: Set<string>;

  codigoMenu = null;

  constructor(
    private formBuilder: FormBuilder,
    private requisitoService: RequisitoService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private configuracaoService: ConfiguracaoService,) {

    this.listaDeDocumentos = new Set<string>();
    
    this.formulario = this.formBuilder.group({
      nomeRequisito: ['', [Validators.required]],
      codigoOrgao: ['', [Validators.required]],
      tipoRequisito: ['', [Validators.required]],
      classificacaoRequisito: ['', [Validators.required]],
      tipoBloqueio: [''],
      statusRequisito: ['', [Validators.required]],
      avaliacaoInicial : [''],
      observacaoInicial: [''],
    });

    this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
  }

  ngOnInit(): void {
    this.buscarOrgaos()
  }

  buscarOrgaos() {
    this.configuracaoService
      .buscarConfiguracaoRequisito()
      .pipe(take(1))
      .subscribe( retorno => {
        this.orgaos = retorno[0];
        this.documentos = retorno[1];
        this.requisitoRiscos = retorno[2];

        if (this.activatedRoute.snapshot.params["codigoRequisito"] != null) {
          this.preencherParaAtendimento(this.activatedRoute.snapshot.params["codigoRequisito"]);
        }
      })
  }

  preencherParaAtendimento(codigoRequisito) {
    this.acaoAtendimento = Constantes.ACAO_ATENDIMENTO.Alterar;
    this.requisitoService
      .buscarPorCodigoParaAtendimento(localStorage.getItem("pId"), codigoRequisito)
      .pipe(take(1))
      .subscribe((retorno: Requisito) => {
        this.requisito = retorno;

        var existeAtividadesSelecionadasEmpresa = this.requisito.atendimentos.filter(atendimento => atendimento.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa)[0].atividadesExercidas;
        var existeAtividadesSelecionadasMei = this.requisito.atendimentos.filter(atendimento => atendimento.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei)[0].atividadesExercidas;
        var existeAtividadesSelecionadasAutonomo = this.requisito.atendimentos.filter(atendimento => atendimento.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo)[0].atividadesExercidas;
        var existeAtividadesSelecionadasAutonomoEstabelecido = this.requisito.atendimentos.filter(atendimento => atendimento.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Estabelecido)[0].atividadesExercidas;
        var existeAtividadesSelecionadasAutonomoNaoEstabelecido = this.requisito.atendimentos.filter(atendimento => atendimento.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Nao_Estabelecido)[0].atividadesExercidas;

        if (existeAtividadesSelecionadasEmpresa != null) {
          this.atividadesSelecionadasEmpresa = existeAtividadesSelecionadasEmpresa;
        }
        if (existeAtividadesSelecionadasMei != null) {
          this.atividadesSelecionadasMei = existeAtividadesSelecionadasMei;
        }
        if (existeAtividadesSelecionadasAutonomo != null) {
          this.atividadesSelecionadasAutonomo = existeAtividadesSelecionadasAutonomo;
        }
        if (existeAtividadesSelecionadasAutonomoEstabelecido != null) {
          this.atividadesSelecionadasAutonomoEstabelecido = existeAtividadesSelecionadasAutonomoEstabelecido;
        }
        if (existeAtividadesSelecionadasAutonomoNaoEstabelecido != null) {
          this.atividadesSelecionadasAutonomoNaoEstabelecido = existeAtividadesSelecionadasAutonomoNaoEstabelecido;
        }
        
        this.obrigatorio = this.requisito.obrigatorio;
        this.nomeRequisito.setValue(this.requisito.nome);
        this.classificacaoRequisito.setValue(this.requisito.requisitoRisco.codigo);
        this.statusRequisito.setValue(this.requisito.status);
        this.tipoRequisito.setValue(this.requisito.processo);
        this.requisito.bloqueiaDocumento.split(",").forEach(item => this.listaDeDocumentos.add(item));
        this.tipoBloqueio.setValue(this.requisito.bloqueiaDocumento);
        this.avaliacaoInicial.setValue(this.requisito.avaliacaoInicial);
        this.observacaoInicial.setValue(this.requisito.observacaoInicial);
        this.codigoOrgao.setValue(this.requisito.orgao.codigo);
        this.codigoOrgao.disable();
      })
  }

  acaoSelecionarTipoDocumento(checkbox: MatCheckbox, codigo) {
    if (checkbox.checked) {
      this.listaDeDocumentos.add(codigo);
    }
    else {
        this.listaDeDocumentos.delete(codigo);
    }
  }

  requisitoObrigatorio() {
      this.obrigatorio = !this.obrigatorio;
  }

  openDialogVinculo(tipoVinculo){

    let codigoRequisito = this.requisito.codigo;
    let tituloVinculo = "";
    
    if(tipoVinculo == Constantes.TIPO_DE_VINCULO.Requisito_Cnae_Empresa){
      tituloVinculo = this.requisito.codigo + " - " + this.requisito.nome + " - Vínculos para Empresas."
    }
    else if(tipoVinculo == Constantes.TIPO_DE_VINCULO.Requisito_Cnae_Mei){
      tituloVinculo = this.requisito.codigo + " - " + this.requisito.nome + " - Vínculos para Meis."
    }
    else if(tipoVinculo == Constantes.TIPO_DE_VINCULO.Requisito_Cnae_Autonomo){
      tituloVinculo = this.requisito.codigo + " - " + this.requisito.nome + " - Vínculos para Autônomos."
    }
    else if(tipoVinculo == Constantes.TIPO_DE_VINCULO.Requisito_Cnae_Autonomo_Estabelecido){
      tituloVinculo = this.requisito.codigo + " - " + this.requisito.nome + " - Vínculos para Autônomos Estabelecidos."
    }
    else if(tipoVinculo == Constantes.TIPO_DE_VINCULO.Requisito_Cnae_Autonomo_Nao_Estabelecido){
      tituloVinculo = this.requisito.codigo + " - " + this.requisito.nome + " - Vínculos para Autônomos Não Estabelecidos."
    }

    console.log(tituloVinculo);

    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '100%',
      height: '100%',
      maxWidth: 'none',
      maxHeight: 'none',
      closeOnNavigation: true,
      data: {
          titulo: tituloVinculo,
          componente: "grid-arquivos-vinculos",
          dados: [tituloVinculo,tipoVinculo,codigoRequisito]
      }
  });

  dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result === "" || result === null)
          return false;

    //this.montarAtividadesSelecionadas(result.atividades, tipoEmpresa);
  });

  }

  openDialog(tipoEmpresa) {
    let atividadesSelecionadas;
    let atividadesNaoListadas;

    if (tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa) {
      atividadesSelecionadas = this.atividadesSelecionadasEmpresa;
    }
    else if (tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
      atividadesSelecionadas = this.atividadesSelecionadasMei;
    }
    else if (tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo) {
      atividadesSelecionadas = this.atividadesSelecionadasAutonomo;
      atividadesNaoListadas = this.atividadesSelecionadasAutonomoEstabelecido.concat(this.atividadesSelecionadasAutonomoNaoEstabelecido);
    } 
    else if (tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Estabelecido) {
      atividadesSelecionadas = this.atividadesSelecionadasAutonomoEstabelecido;
      atividadesNaoListadas = this.atividadesSelecionadasAutonomo.concat(this.atividadesSelecionadasAutonomoNaoEstabelecido);
    } 
    else if (tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Nao_Estabelecido) {
      atividadesSelecionadas = this.atividadesSelecionadasAutonomoNaoEstabelecido;
      atividadesNaoListadas = this.atividadesSelecionadasAutonomo.concat(this.atividadesSelecionadasAutonomoEstabelecido);
    } 

    const dialogRef = this.dialog.open(IcadModalComponent, {
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        maxHeight: 'none',
        closeOnNavigation: true,
        data: {
            titulo: "Atividades Para Vincular",
            componente: "form-requisito-list",
            dados: [atividadesSelecionadas, tipoEmpresa, atividadesNaoListadas]
        }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === "" || result === null)
            return false;

      this.montarAtividadesSelecionadas(result.atividades, tipoEmpresa);
    });

  }

  montarAtividadesSelecionadas(atividadesSelecionadas, tipoEmpresa) {

    if (tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo) {
      this.atividadesSelecionadasAutonomo = atividadesSelecionadas;
    }
    else if (tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Estabelecido) {
      this.atividadesSelecionadasAutonomoEstabelecido = atividadesSelecionadas;
    }
    else if (tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Nao_Estabelecido) {
      this.atividadesSelecionadasAutonomoNaoEstabelecido = atividadesSelecionadas;
    }
    else if (tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
      this.atividadesSelecionadasMei = atividadesSelecionadas;
    }
    else if (tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa) {
      this.atividadesSelecionadasEmpresa = atividadesSelecionadas;
    }
  }

  acaoBotaoVoltar(){
    if(this.codigoMenu == 99){
      this.router.navigate([
        'configuracao/viabilidade/requisitos', 
        this.activatedRoute.snapshot.params["codigoMenuPai"],
        this.activatedRoute.snapshot.params["codigoOrgao"],
        this.activatedRoute.snapshot.params["codigoMenu"],
      ]);
    }
    else{
      this.router.navigate([
        'configuracao/viabilidade/preRequisitoBaixa', 
        this.activatedRoute.snapshot.params["codigoMenuPai"],
        this.activatedRoute.snapshot.params["codigoOrgao"],
        this.activatedRoute.snapshot.params["codigoMenu"],
      ]);
    }
    
  }

  validarFormulario() {
    if (this.formulario.invalid) {
      let mensagemErro = "";

      if (this.nomeRequisito.invalid) {
        mensagemErro += "Favor corrigir nomeRequisito.<br>"
      }

      if (this.codigoOrgao.invalid) {
        mensagemErro += "Favor corrigir codigoOrgao.<br>"
      }

      if (this.tipoRequisito.invalid) {
        mensagemErro += "Favor corrigir tipoRequisito.<br>"
      }

      if (this.statusRequisito.invalid) {
        mensagemErro += "Favor corrigir statusRequisito.<br>"
      }

      if (mensagemErro === "") {
        this.emitirErro = false;
        return true;
      }

      Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

      this.emitirErro = true;
      this.mensagemErro = mensagemErro;

      return false;
    }

    this.emitirErro = false;
    this.mensagemErro = "";

    return true;
  }

  acaoBotaoSalvar() {
    if (this.validarFormulario()) {
      this.preencher();
      if (this.acaoAtendimento == Constantes.ACAO_ATENDIMENTO.Alterar) {
        this.requisitoService
          .alterarRequisito(this.requisito)
          .pipe(take(1))
          .subscribe(retorno => {
            this.mensagemAcaoBotaoSalvar(retorno);
          });
      }
      else {
        this.requisitoService
          .incluirRequisito(this.requisito)
          .pipe(take(1))
          .subscribe(retorno => {
            this.mensagemAcaoBotaoSalvar(retorno);
          });
      }
    }

  }

  mensagemAcaoBotaoSalvar(retorno) {
    Swal.fire({
      title: retorno.titulo,
      html: retorno.mensagem,
      icon: retorno.status,
      willClose: () => {
        this.router.navigate([
          "configuracao",
          "viabilidade",
          "requisitos",
          this.activatedRoute.snapshot.params["codigoMenuPai"],
          this.activatedRoute.snapshot.params["codigoOrgao"],
          this.activatedRoute.snapshot.params["codigoMenu"]
        ]);
      }
    })
  }

  preencher() {
    if (this.acaoAtendimento != Constantes.ACAO_ATENDIMENTO.Alterar) {
      this.requisito = new Requisito();
    }
    this.requisito.cliente = localStorage.getItem("pId");
    this.requisito.nome = this.nomeRequisito.value;
    this.requisito.status = this.statusRequisito.value;
    this.requisito.orgao.codigo = this.codigoOrgao.value;
    this.requisito.processo = this.tipoRequisito.value;
    
    this.requisito.bloqueiaDocumento = "";
    this.listaDeDocumentos.forEach( item => this.requisito.bloqueiaDocumento = this.requisito.bloqueiaDocumento.concat(item, ","));

    this.requisito.obrigatorio = this.obrigatorio;
    this.requisito.requisitoRisco = this.classificacaoRequisito.value != "" ? this.requisitoRiscos.filter( risco => risco.codigo == this.classificacaoRequisito.value)[0] : new RequisitoRisco();

    this.requisito.avaliacaoInicial = this.avaliacaoInicial.value;
    this.requisito.observacaoInicial = this.observacaoInicial.value;
   
    if(this.codigoMenu == 99){
      this.requisito.preRequisito = "N";
      this.requisito.encerramento = "N";
    }
    else{
      this.requisito.preRequisito = "S";
      this.requisito.encerramento = "S";
    }
    

    var atendimentoAtividadesEmpresa = new Atendimento();
    var atendimentoAtividadesMei = new Atendimento();
    var atendimentoAtividadesAutonomo = new Atendimento();
    var atendimentoAtividadesAutonomoEstabelecido = new Atendimento();
    var atendimentoAtividadesAutonomoNaoEstabelecido = new Atendimento();

    atendimentoAtividadesEmpresa.tipoEmpresa = Constantes.TIPO_EMPRESA.Empresa;
    atendimentoAtividadesMei.tipoEmpresa = Constantes.TIPO_EMPRESA.Mei;
    atendimentoAtividadesAutonomo.tipoEmpresa = Constantes.TIPO_EMPRESA.Autonomo;
    atendimentoAtividadesAutonomoEstabelecido.tipoEmpresa = Constantes.TIPO_EMPRESA.Autonomo_Estabelecido;
    atendimentoAtividadesAutonomoNaoEstabelecido.tipoEmpresa = Constantes.TIPO_EMPRESA.Autonomo_Nao_Estabelecido;

    if(this.obrigatorio) {
      this.requisito.atendimentos = [];
    }
    else {
      atendimentoAtividadesEmpresa.atividadesExercidas = this.atividadesSelecionadasEmpresa;
      atendimentoAtividadesMei.atividadesExercidas = this.atividadesSelecionadasMei;
      atendimentoAtividadesAutonomo.atividadesExercidas = this.atividadesSelecionadasAutonomo;
      atendimentoAtividadesAutonomoEstabelecido.atividadesExercidas = this.atividadesSelecionadasAutonomoEstabelecido;
      atendimentoAtividadesAutonomoNaoEstabelecido.atividadesExercidas = this.atividadesSelecionadasAutonomoNaoEstabelecido;

      this.requisito.atendimentos = [
        atendimentoAtividadesEmpresa, 
        atendimentoAtividadesMei, 
        atendimentoAtividadesAutonomo, 
        atendimentoAtividadesAutonomoEstabelecido, 
        atendimentoAtividadesAutonomoNaoEstabelecido
      ];
    }
  }

  get nomeRequisito() {
    return this.formulario.get("nomeRequisito");
  }
  set nomeRequisito(nomeRequisito) {
    this.formulario.get("nomeRequisito").setValue(nomeRequisito);
  }

  get codigoOrgao() {
    return this.formulario.get("codigoOrgao");
  }
  set codigoOrgao(codigoOrgao) {
    this.formulario.get("codigoOrgao").setValue(codigoOrgao);
  }

  get tipoRequisito() {
    return this.formulario.get("tipoRequisito");
  }
  set tipoRequisito(tipoRequisito) {
    this.formulario.get("tipoRequisito").setValue(tipoRequisito);
  }

  get classificacaoRequisito() {
    return this.formulario.get("classificacaoRequisito");
  }
  set classificacaoRequisito(classificacaoRequisito) {
    this.formulario.get("classificacaoRequisito").setValue(classificacaoRequisito);
  }

  get tipoBloqueio() {
    return this.formulario.get("tipoBloqueio");
  }
  set tipoBloqueio(tipoBloqueio) {
    this.formulario.get("tipoBloqueio").setValue(tipoBloqueio);
  }

  get statusRequisito() {
    return this.formulario.get("statusRequisito");
  }
  set statusRequisito(statusRequisito) {
    this.formulario.get("statusRequisito").setValue(statusRequisito);
  }

  get avaliacaoInicial() {
    return this.formulario.get("avaliacaoInicial");
  }
  set avaliacaoInicial(avaliacaoInicial) {
    this.formulario.get("avaliacaoInicial").setValue(avaliacaoInicial);
  }

  get observacaoInicial() {
    return this.formulario.get("observacaoInicial");
  }
  set observacaoInicial(observacaoInicial) {
    this.formulario.get("observacaoInicial").setValue(observacaoInicial);
  }
  
}