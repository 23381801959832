<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div id="toggleAccordion">
        <div class="card">
            <div class="card-header" id="div-atividades">
                <section class="mb-0 mt-0">
                    <div class="row">
                        <div class="row col-6 justify-content-start align-items-center">
                            <strong>{{ titulo }}</strong>
                        </div>
                        <div class="form-group col-6 justify-content-end align-items-center text-right mb-0">
                            <button type="button" 
                                (click)="acaoBotaoAdicionarAtividade()"
                                class="btn btn-success">
                                <svg class="iconeAtividade">
                                    <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'plus' }}"
                                    ></use>
                                </svg>
                                Adicionar Atividade
                            </button>
                        </div>
                    </div>
                </section>
            </div>
            <div id="expansao-atividades" class="collapse show" aria-labelledby="div-atividades" data-parent="#toggleAccordion" >
                <div *ngIf="atividadesSelecionadas.length"
                    class="card-body card-body-titulo">
                    <div class="div-lista-atividades">
                        <div class="row justify-content-center align-items-center header-atividades">
                            <div class="row col-11">
                                <p class="card-text titulo-atividades">
                                    <strong>Atividade</strong>
                                </p>
                            </div>
                            <div class="row col-1"></div>
                        </div>
                        <div class="row linha-grid-atividades-selecionadas" 
                            *ngFor="let atividade of atividadesSelecionadas; let i = index">
                            <div class="row col-12 justify-content-center align-items-center">
                            </div>
                            <div class="row col-11 justify-content-start align-items-center">
                                <p class="card-text">
                                    {{ atividade.codigo | mascara: 10 }} - {{ formatarNomeAtividade(atividade.nome) }}
                                </p>
                            </div>
                            <div class="row col-1">
                                <button type="button" 
                                        matTooltip="Excluir"
                                        matTooltipPosition="right"
                                        class="btn"
                                        (click)="acaoExcluirAtividadeEscolhida(i)">
                                    <svg class="iconeListaAtividade">
                                        <use
                                            attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!atividadesSelecionadas.length"
                    class="card-body">
                    <p class="card-text">
                        Suas atividades apareceram aqui.
                    </p>
                </div>
            </div>
        </div>
    </div>
</form>