import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class ConfiguracaoService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarConfiguracaoRequisito() {
        return this.http.get<any>(
            `${ environment.icadUrl }/configuracao/buscarConfiguracaoRequisito`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarConfiguracaoPorServicoEFase(servico, fase) {
        return this.http.get<any>(
            `${ environment.icadUrl }/configuracao/buscarConfiguracaoPorServicoEFase/${ servico }/${ fase }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
}