<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card mb-3">
        <div class="card-body">
            <div class="row justify-content-center align-items-center">
                <div class="row col-12 row-linha" >
                    <p class="card-text" [innerHtml]="textoInformativo"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <div class="row justify-content-center align-items-center">
                <div class="row col-12" >
                    <strong>Pareceres disponíveis</strong>
                </div>
            </div>
        </div>
        <div *ngIf="pareceres != null" class="card-body p-0" >
            <div class="row col-12 ps div-pareceres p-0" [perfectScrollbar]="config" *ngIf="pareceres.length">
                <div *ngFor="let parecer of pareceres; let i = index" class="row col-12 p-0 pt-2 pb-2 linha-grid-divisao">
                    <div class="row col-12 p-0 justify-content-start align-items-start">
                        <div class="row col-12 row-linha-observacao align-items-center">
                            <div class="col-1 p-0 text-center">
                                <button
                                    type="button" 
                                    id="btn-adiconar-parecer"
                                    matTooltip="Adicionar"
                                    matTooltipPosition="above"
                                    (click)="adicionarParecer(parecer.parecer)"
                                    class="btn btn-sm btn-success btn-concluir m-0">
                                    <svg class="icone-adicionar">
                                        <use
                                            attr.xlink:href="assets/sprite.svg#{{ 'plus' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                            <div class="col-11 p-0">
                                <p class="meta-text mb-0 w-100">
                                    {{ parecer.parecer }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row col-12  div-pareceres p-0 justify-content-center align-items-center" *ngIf="!pareceres.length">
                <p class="card-text text-center">
                    Não existe pareceres configurado para esse processo.
                </p>
            </div>
        </div>
    </div>
    <div class="card mb-1">
        <div class="card-header">
            <div class="row justify-content-center align-items-center">
                <div class="row col-12" >
                    <strong>Parecer Final</strong>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="form-group col-12 p-0 mt-1" >
                <textarea #parecerFinalTextArea
                    class="form-control form-control-sm"
                    [class.is-invalid]="parecerFinal.invalid && parecerFinal.touched"
                    formControlName="parecerFinal"
                    id="parecerFinal"
                    name="parecerFinal"
                    maxlength="2000"
                    cols="30" 
                    rows="5"
                ></textarea>
                <div class="mb-1">
                    <span 
                        class="erro" 
                        *ngIf="parecerFinal.invalid && parecerFinal.touched && parecerFinal.errors.required"
                    >Campo obrigatório</span>
                    <span 
                        class="erro" 
                        *ngIf="parecerFinal.invalid && parecerFinal.touched && parecerFinal.errors.maxlength"
                    >A descrição deve conter até 2000 caracteres.</span>
                    <span class="quantidade-caracter">
                        Quantidade de Caracteres: {{ parecerFinal.value.length }} / 2000
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row linha-botao-concluir">
        <div class="row col-12 justify-content-center align-items-center">
            <button type="button" 
                    (click)="acaoBotaoConcluir()"
                    class="btn btn-success btn-concluir">
                <svg class="iconeGrid">
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ 'check' }}"
                    ></use>
                </svg>
                Concluir Parecer
            </button>
        </div>
    </div>
</form>