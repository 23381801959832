import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { PipeModule } from '../../../pipes/pipes.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AnaliseEntornoComponent } from './analise-entorno.component';

@NgModule({
    declarations: [
        AnaliseEntornoComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NgxMaskModule.forRoot(),
        PipeModule,
        NgbPopoverModule
    ],
    exports: [
        AnaliseEntornoComponent
    ],
    providers: [ 
        {
            provide: Document,
            useValue: document 
        },
        {
            provide: Window,
            useValue: window
        }
    ]
})
export class AnaliseEntornoModule {}