import { Component, OnInit, Renderer2, RendererStyleFlags2, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HeaderPortalComponent } from "src/app/components/core/header/header-portal.component";
import { MenuService } from "src/app/services/menu/menu.service";
import { take } from "rxjs/operators";
import { HomeComponent } from "../../home/home.component";
import { isNgTemplate } from "@angular/compiler";
import { PesquisaViabilidadeComponent } from '../../pesquisa-viabilidade/pesquisa-viabilidade.component';
import { Cliente } from '../../../model/cliente';
import { FaqComponent } from '../../operacao/faq/faq.component';
import { CadastrarUsuarioComponent } from '../../outros/autenticacao/login/cadastrar-usuario/cadastrar-usuario.component';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { IcadModalComponent } from '../../../components/modal/icad-modal/icad-modal.component';
import { PopUp } from '../../../model/popUp';

@Component({
    selector: "app-portal-home",
    templateUrl: "./portal-home.component.html",
    styleUrls: ["././portal-home.component.scss"]
})
export class PortalHomeComponent implements OnInit, OnDestroy {
    
    @ViewChild(HeaderPortalComponent) headerPortalComponent: HeaderPortalComponent;
    @ViewChild(HomeComponent) homeComponent: HomeComponent;
    @ViewChild(CadastrarUsuarioComponent) cadastrarUsuarioComponent: CadastrarUsuarioComponent;
    @ViewChild(FaqComponent) faqComponent: FaqComponent;
    @ViewChild(PesquisaViabilidadeComponent) pesquisaViabilidade: PesquisaViabilidadeComponent;

    navigationSubscription; 
    exibirMenu: boolean;
    exibirPesquisaViabilidade: boolean;
    exibirCadastroDeUsuario: boolean;
    exibirAcessibilidade: boolean;
    exibirTermo: boolean;
    exibirMapaDoSite: boolean;
    exibirVerifiqueAutenticidadeDoAlvara: boolean;
    exibirFaq: boolean;
    cliente: string;
    dados: any;
    popUps: Array<PopUp>;

    constructor(private router: Router, 
                private authenticationService: AuthenticationService,
                public dialog: MatDialog,
                private menuService: MenuService,
                private activatedRoute: ActivatedRoute) {

        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.initialiseInvites();
            }
        });

        this.dados = {
            processosAgilizados: "",
            negociosAbertos: "",
            atendimentosRealizados: "",
            alvarasGerados: "",
        };

        this.exibirMenu = true;
        this.exibirPesquisaViabilidade = false;
        this.exibirCadastroDeUsuario = false;
        this.exibirAcessibilidade = false;
        this.exibirTermo = false;
        this.exibirMapaDoSite = false;
        this.exibirVerifiqueAutenticidadeDoAlvara = false;
        this.exibirFaq = false;
        this.cliente = window.location.hostname.split(".")[0];
        this.popUps = new Array<PopUp>();
        
        // this.renderer.setStyle(this.document.querySelector("body"), "background", "#fff", RendererStyleFlags2.DashCase);

        this.initialiseInvites();
    }

    ngOnInit(): void {
        this.menuService.buscarDadosClienteUrl(this.cliente.toLocaleLowerCase())
            .pipe(take(1))
            .subscribe((retorno: Cliente) => {
                localStorage.setItem("pId", retorno.codigo);
                this.authenticationService.setCurrentCliente(JSON.stringify(retorno));

                this.cadastrarUsuarioComponent.formEndereco.buscarRegra();

                this.menuService.buscarDados(retorno.codigo)
                    .pipe(take(1))
                    .subscribe((retorno) => {
                        this.dados = retorno;
                    });
                    
                this.buscarMenuPorCliente();
            });
    }
    
    buscarMenuPorCliente() {
        this.menuService.buscarMenuPorCliente('menu_cliente')
            .pipe(take(1))
            .subscribe((retorno) => {
                this.headerPortalComponent.codigoCliente = retorno.param_user[0].id;
                this.headerPortalComponent.nomeCliente = retorno.param_user[0].valor;
                this.headerPortalComponent.departamentoUsuario = retorno.departamento;
                this.homeComponent.criarMenus(retorno.eixos[0]);
                localStorage.setItem('configuracao', JSON.stringify(retorno.configuracao));
                this.faqComponent.gerarFaqsAvaliadas();
                this.popUps = retorno.configuracao.popUps;
                this.abrirPopUp();
            });
    }

    ngOnDestroy() {
        if (this.navigationSubscription) {  
           this.navigationSubscription.unsubscribe();
        }
    }
    
    initialiseInvites() {
        if (this.router.routerState.snapshot.url.split("/").length > 2 && this.activatedRoute.snapshot.routeConfig.children.filter( item => item.path == this.router.routerState.snapshot.url.split("/")[2]).length) {
            this.exibirMenu = this.activatedRoute.snapshot.routeConfig.children.filter( item => item.path == this.router.routerState.snapshot.url.split("/")[2])[0].data["exibirMenu"];
        }
        else if (this.activatedRoute.snapshot.queryParams["exibir"] != null) {
            this.atualizarPagina(this.activatedRoute.snapshot.queryParams["exibir"])
        }
        else {
            this.acaoBotaoVoltar();
        }
    }
    
    onCancelarCadastro(event) {
        
    }
    
    acaoBotaoVoltar() {
        if (this.exibirPesquisaViabilidade && this.pesquisaViabilidade.googleMaps != null && this.pesquisaViabilidade.googleMaps.jaInicializado) {
            window.location.reload();
            return false;
        }
        
        this.exibirMenu = true;
        this.exibirPesquisaViabilidade = false;
        this.exibirVerifiqueAutenticidadeDoAlvara = false;
        this.exibirFaq = false;
        this.exibirCadastroDeUsuario = false;
        this.exibirAcessibilidade = false;
        this.exibirTermo = false;
        this.exibirMapaDoSite = false;
    }

    acaoClickHeader(event) {
        if (event.acao == "ACESSAR") {
            // this.exibirLogin = true
            this.router.navigate(["/login"]);   
        }
        else if (event.acao == "PORTAL") {
            this.router.navigate(["/portal-home"]);
        }
        else if (event.acao == "PESQUISA-VIABILIDADE") {
            if(localStorage.getItem("pId") == '9999'){
                this.router.navigate(["/portal-home"], {queryParams: {exibir: "PESQUISA-VIABILIDADE"}});
            }
        }
        else if (event.acao == "CADASTRO-DE-USUARIO") {
            this.router.navigate(["/portal-home"], {queryParams: {exibir: "CADASTRO-DE-USUARIO"}});
        }
        else if (event.acao == "ACESSIBILIDADE") {
            this.router.navigate(["/portal-home"], {queryParams: {exibir: "ACESSIBILIDADE"}});
        }
        else if (event.acao == "TERMO") {
            this.router.navigate(["/portal-home"], {queryParams: {exibir: "TERMO"}});
        }
        else if (event.acao == "MAPA-DO-SITE") {
            this.router.navigate(["/portal-home"], {queryParams: {exibir: "MAPA-DO-SITE"}});
        }
    }

    atualizarPagina(acao) {
        if (acao == "PESQUISA-VIABILIDADE") {
            this.exibirMenu = false;
            this.exibirPesquisaViabilidade = true;
            this.exibirVerifiqueAutenticidadeDoAlvara = false;
            this.exibirFaq = false;
            this.exibirCadastroDeUsuario = false;
            this.exibirAcessibilidade = false;
            this.exibirTermo = false;
            this.exibirMapaDoSite = false;
        }
        else if (acao == "VERIFIQUE-AUTENTICIDADE-DO-ALVARA") {
            this.exibirMenu = false;
            this.exibirPesquisaViabilidade = false;
            this.exibirVerifiqueAutenticidadeDoAlvara = true;
            this.exibirFaq = false;
            this.exibirCadastroDeUsuario = false;
            this.exibirAcessibilidade = false;
            this.exibirTermo = false;
            this.exibirMapaDoSite = false;
        }
        else if (acao == "FAQ") {
            this.exibirMenu = false;
            this.exibirPesquisaViabilidade = false;
            this.exibirVerifiqueAutenticidadeDoAlvara = false;
            this.exibirFaq = true;
            this.exibirCadastroDeUsuario = false;
            this.exibirAcessibilidade = false;
            this.exibirTermo = false;
            this.exibirMapaDoSite = false;
        }
        else if (acao == "CADASTRO-DE-USUARIO") {
            this.exibirMenu = false;
            this.exibirPesquisaViabilidade = false;
            this.exibirVerifiqueAutenticidadeDoAlvara = false;
            this.exibirFaq = false;
            this.exibirCadastroDeUsuario = true;
            this.exibirAcessibilidade = false;
            this.exibirTermo = false;
            this.exibirMapaDoSite = false;
        }
        else if (acao == "ACESSIBILIDADE") {
            this.exibirMenu = false;
            this.exibirPesquisaViabilidade = false;
            this.exibirVerifiqueAutenticidadeDoAlvara = false;
            this.exibirFaq = false;
            this.exibirCadastroDeUsuario = false;
            this.exibirAcessibilidade = true;
            this.exibirTermo = false;
            this.exibirMapaDoSite = false; 
        }
        else if (acao == "TERMO") {
            this.exibirMenu = false;
            this.exibirPesquisaViabilidade = false;
            this.exibirVerifiqueAutenticidadeDoAlvara = false;
            this.exibirFaq = false;
            this.exibirCadastroDeUsuario = false;
            this.exibirAcessibilidade = false;
            this.exibirTermo = true;
            this.exibirMapaDoSite = false; 
        }
        else if (acao == "MAPA-DO-SITE") {
            this.exibirMenu = false;
            this.exibirPesquisaViabilidade = false;
            this.exibirVerifiqueAutenticidadeDoAlvara = false;
            this.exibirFaq = false;
            this.exibirCadastroDeUsuario = false;
            this.exibirAcessibilidade = false;
            this.exibirTermo = false;
            this.exibirMapaDoSite = true;
        }

    }

    abrirPopUp() {
        if (!this.popUps.length) {
            return false;
        }

        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '60%',
            height: '80%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                titulo: "Informativo",
                componente: "pop-up",
                dados: [this.popUps[0].textoPopup, this.popUps[0].tituloPopup, this.popUps[0].descricao]
            }
        });
    }

    

}