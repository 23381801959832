<div class="row col-12 justify-content-center align-items-center linha-botao" *ngIf="modulo == 'fic'">
    <button *ngIf="documentoEmpresasProtocolos.length != 0"
        type="button" 
        class="btn btn-outline-success"
        title="deferir"
        (click)="exibeEmpresasCnpj(documentoEmpresasProtocolos)"
        class="btn btn-danger"
    >
        Negócios com o mesmo CNPJ / CPF ( {{ documentoEmpresasProtocolos.length }} )
    </button>
    <button *ngIf="documentoEmpresasProtocolos.length == 0"
        type="button" 
        class="btn btn-outline-success"
        title="deferir"
        class="btn btn-info"
    >
        Não existem negócios com o mesmo CNPJ / CPF ( {{ documentoEmpresasProtocolos.length }} )
    </button>
</div>    