import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridParcelasTfeComponent } from './grid-parcelas-tfe.component';
import { PipeModule } from 'src/app/pipes/pipes.module';
import { GridMemoriaDeCalculoModule } from '../grid-memoria-de-calculo/grid-memoria-de-calculo.module';
import { GridMemoriaDeCalculoParcelaModule } from '../grid-memoria-de-calculo-parcela/grid-memoria-de-calculo-parcela.module';
import { AlertModule } from 'src/app/components/alert/alert.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';


@NgModule({
  declarations: [
    GridParcelasTfeComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTableExporterModule,
    GridMemoriaDeCalculoModule,
    GridMemoriaDeCalculoParcelaModule,
    AlertModule,
  ],
  exports: [
    GridParcelasTfeComponent
  ],
})
export class GridParcelasTfeModule {}
