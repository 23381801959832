<app-alert type="danger" class="fs-12"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<app-alert *ngIf="apenasCorrespondencia"
            type="warning" >
    Caro contribuinte, você selecionou o tipo de endereço “Endereço somente para Correspondência”.
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card" *ngIf="tipo != tipoQuestionario.Auditor && tipo != tipoQuestionario.Licenciamento">
        <div class="card-header" *ngIf="!semCardHeader">
            <div class="col-12 justify-content-start align-items-center">
                <strong>Questionário {{ titulo }}</strong>
            </div>
        </div>
        <div class="card-body" [class.p-0]="semCardHeader">
            <div *ngFor="let pergunta of perguntas; let i = index">

                <div class="form-row linha-grid"
                    [class.linha-grid-divisao]="i !== 0">
                    <div class="form-group col-9 justify-content-start align-items-center">
                        <p class="card-text">
                            <strong>{{ i + 1 }}</strong> {{ pergunta.nome }}
                        </p>
                    </div>
                    <div *ngIf="pergunta.tipo == '3'" class="form-group col-3 justify-content-end align-items-center">
                        <select 
                            #respostaSelect
                            [class.is-invalid]="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)"
                            formControlName="resposta_{{ pergunta.codigo }}" 
                            (change)="acaoRespostaSelect(respostaSelect)"
                            class="form-control form-control-sm"
                            name="resposta_{{ pergunta.codigo }}" 
                            id="resposta_{{ pergunta.codigo }}"                
                        >
                            <option value=""></option>
                            <option *ngFor="let resposta of pergunta.respostas" 
                                value="{{ resposta.nome | uppercase}}"
                            >
                                {{ resposta.nome | uppercase}}
                            </option>
                        </select>
                        <div class="mb-1 linha-erro" *ngIf="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)">
                            <span 
                                class="erro" 
                                *ngIf="campoErro('resposta_' + pergunta.codigo, 'required')"
                            >Campo obrigatório</span>
                        </div>
                    </div>
                    <div *ngIf="pergunta.tipo != '3'" class="form-group col-3 justify-content-end align-items-center">
                        <input
                            #respostaInput
                            [class.is-invalid]="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)"
                            formControlName="resposta_{{ pergunta.codigo }}" 
                            (input)="acaoRespostaInput(respostaInput, pergunta.tipo)"
                            matTooltip="{{ 
                                pergunta.tipo == '1' ? 'Area' :
                                pergunta.tipo == '2' ? 'Texto' :
                                pergunta.tipo == '4' ? 'Hora' :
                                pergunta.tipo == '5' ? 'Período' :
                                pergunta.tipo == '6' ? 'Data' :
                                pergunta.tipo == '7' ? 'Período' :
                                pergunta.tipo == '8' ? 'Telefone' :
                                pergunta.tipo == '9' ? 'Numero' : ''
                            }}"
                            matTooltipPosition="left"
                            class="form-control text-right form-control-sm {{ 
                                pergunta.tipo == '1' ? 'notNull' :
                                pergunta.tipo == '2' ? 'notNull' :
                                pergunta.tipo == '4' ? 'horaSimples' :
                                pergunta.tipo == '5' ? 'horaComposta' :
                                pergunta.tipo == '6' ? 'dataSimples' :
                                pergunta.tipo == '7' ? 'dataComposta' :
                                pergunta.tipo == '8' ? 'telefoneComposto' :
                                pergunta.tipo == '9' ? 'notNull' : ''
                            }}"
                            [attr.maxlength]=" 
                                pergunta.tipo == '1' ? '10' :
                                pergunta.tipo == '2' ? '50' :
                                pergunta.tipo == '4' ? '5' :
                                pergunta.tipo == '5' ? '11' :
                                pergunta.tipo == '6' ? '10' :
                                pergunta.tipo == '7' ? '21' :
                                pergunta.tipo == '8' ? '12' :
                                pergunta.tipo == '9' ? '10' : '0'
                            "
                            id="resposta_{{ pergunta.codigo }}"
                            name="resposta_{{ pergunta.codigo }}"
                            type="text"
                        />
                        <div class="mb-1 linha-erro" *ngIf="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)">
                            <span 
                                class="erro" 
                                *ngIf="campoErro('resposta_' + pergunta.codigo, 'required')"
                            >Campo obrigatório</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-auditor" *ngIf="tipo == tipoQuestionario.Licenciamento">
        <ngb-accordion #acc="ngbAccordion" activeIds="">
            <ngb-panel>
                <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                    <div class="row col-12">
                        <div class="row col-10 justify-content-start align-items-center" >
                            <strong>{{ titulo }}</strong>
                        </div>
                        <div class="row col-2 justify-content-end align-items-center">
                            <button type="button" 
                                ngbPanelToggle 
                                class="btn btn-success btn-painel">
                                <svg [class.hidden]="opened" 
                                    class="icone-painel">
                                    <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                    ></use>
                                </svg>
                                <svg [class.hidden]="!opened" 
                                    class="icone-painel">
                                    <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                    ></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template class="card-body p-0 pl-1 pr-1 pb-1" ngbPanelContent>
                    <div *ngFor="let pergunta of perguntas; let i = index">
                        <div class="form-row linha-grid">
                            <div 
                            [class.pergunta-licenciamento]="!pergunta.criticada"  
                            [class.pergunta-licenciamento-critica]="pergunta.criticada"  
                            class="form-group col-12 justify-content-start align-items-center pl-3 pr-3">
                                <mat-checkbox *ngIf="permitirAtendimento"
                                    class="mr-2"
                                    #criticarResposta
                                    (change)="acaoCriticar(criticarResposta, pergunta.codigo)"
                                    [checked]="selecionarTodos"
                                    id="criticarResposta_{{ pergunta.codigo }}"
                                    name="criticarResposta">
                                </mat-checkbox>
                                <button type="button" 
                                    class="btn btn-sm btn-informacao-atividade btn-outline-secondary">
                                    {{ pergunta.codigoAutomacao }}
                                </button>
                                <button type="button" 
                                    class="btn btn-sm btn-informacao-atividade btn-secondary">
                                    {{ pergunta.processo }}
                                </button> <b>{{ pergunta.nome }}</b>
                            </div>
                            <div class="form-group" 
                                [class.col-1]="!permitirAtendimento && !apenasAcompanhamento"
                                [class.col.12]="permitirAtendimento || apenasAcompanhamento">
                                <p class="card-text" [class.textoVermelho]="pergunta.respostas[0].nome != 'NÃO' && permitirAtendimento">
                                    <strong>Resp.:</strong> {{ permitirAtendimento || apenasAcompanhamento ? pergunta.respostas[0].nome : '' }} <span *ngIf="permitirAtendimento">[FR:{{ pergunta.respostas[0].fatorDeRisco }}]</span>
                                </p>
                            </div>
                            <div *ngIf="!permitirAtendimento && !apenasAcompanhamento"
                                class="form-group col-11 justify-content-end align-items-center">
                                <div *ngIf="pergunta.tipo == '3'">
                                    <select 
                                        #respostaSelect
                                        [class.is-invalid]="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)"
                                        formControlName="resposta_{{ pergunta.codigo }}" 
                                        (change)="acaoRespostaSelect(respostaSelect)"
                                        class="form-control form-control-sm"
                                        name="resposta_{{ pergunta.codigo }}" 
                                        id="resposta_{{ pergunta.codigo }}"                
                                    >
                                        <option *ngFor="let resposta of pergunta.respostas;" 
                                            [selected]="resposta.ativa"
                                            value="{{ resposta.nome | uppercase}}"
                                        >
                                            {{ resposta.nome | uppercase}}
                                        </option>
                                    </select>
                                    <div class="mb-1 linha-erro" *ngIf="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)">
                                        <span 
                                            class="erro" 
                                            *ngIf="campoErro('resposta_' + pergunta.codigo, 'required')"
                                        >Campo obrigatório</span>
                                    </div>
                                </div>
                                <div *ngIf="pergunta.tipo != '3'">
                                    <input
                                        #respostaInput
                                        [class.is-invalid]="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)"
                                        formControlName="resposta_{{ pergunta.codigo }}" 
                                        (input)="acaoRespostaInput(respostaInput, pergunta.tipo)"
                                        matTooltip="{{ 
                                            pergunta.tipo == '1' ? 'Area' :
                                            pergunta.tipo == '2' ? 'Texto' :
                                            pergunta.tipo == '4' ? 'Hora' :
                                            pergunta.tipo == '5' ? 'Período' :
                                            pergunta.tipo == '6' ? 'Data' :
                                            pergunta.tipo == '7' ? 'Período' :
                                            pergunta.tipo == '8' ? 'Telefone' :
                                            pergunta.tipo == '9' ? 'Numero' : ''
                                        }}"
                                        matTooltipPosition="left"
                                        class="form-control text-right form-control-sm {{ 
                                            pergunta.tipo == '1' ? 'notNull' :
                                            pergunta.tipo == '2' ? 'notNull' :
                                            pergunta.tipo == '4' ? 'horaSimples' :
                                            pergunta.tipo == '5' ? 'horaComposta' :
                                            pergunta.tipo == '6' ? 'dataSimples' :
                                            pergunta.tipo == '7' ? 'dataComposta' :
                                            pergunta.tipo == '8' ? 'telefoneComposto' :
                                            pergunta.tipo == '9' ? 'notNull' : ''
                                        }}"
                                        [attr.maxlength]=" 
                                            pergunta.tipo == '1' ? '10' :
                                            pergunta.tipo == '2' ? '50' :
                                            pergunta.tipo == '4' ? '5' :
                                            pergunta.tipo == '5' ? '11' :
                                            pergunta.tipo == '6' ? '10' :
                                            pergunta.tipo == '7' ? '21' :
                                            pergunta.tipo == '8' ? '12' :
                                            pergunta.tipo == '9' ? '10' : '0'
                                        "
                                        id="resposta_{{ pergunta.codigo }}"
                                        name="resposta_{{ pergunta.codigo }}"
                                        type="text"
                                    />
                                    <div class="mb-1 linha-erro" *ngIf="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)">
                                        <span 
                                            class="erro" 
                                            *ngIf="campoErro('resposta_' + pergunta.codigo, 'required')"
                                        >Campo obrigatório</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
        
    <div class="card card-auditor" *ngIf="tipo == tipoQuestionario.Auditor">
        <div class="card-header" *ngIf="!semCardHeader">
            <div class="col-12">
                <strong>Questionário {{ titulo }}</strong>
            </div>
        </div>
        <div *ngIf="perguntas == null" class="card-body card-body-auditor pt-2">
            <div class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text-auditor text-center">
                        Carregando...
                    </p>
                </div>
            </div>      
        </div>
        <div *ngIf="perguntas != null" class="card-body card-body-auditotr2p-0">
            <div *ngFor="let pergunta of perguntas; let i = index">
                <div class="row linha-grid"
                    [class.linha-grid-divisao]="i !== 0">
                    <div class="row col-9 pl-1 justify-content-start align-items-center">
                        <p class="card-text-auditor">
                            <strong>{{ i + 1 }}</strong> {{ pergunta.nome }}
                        </p>
                    </div>
                    <div *ngIf="!permitirAtendimento">
                        <p class="card-text-auditor" *ngFor="let perguntaRespondida of perguntasRespondidas | filtro: {campo: 'codigo', valor: pergunta.codigo}">
                            <strong>R: {{ perguntaRespondida.respostas[0].nome }}</strong>
                        </p>
                        <p class="card-text-auditor" *ngIf="!(perguntasRespondidas | filtro: {campo: 'codigo', valor: pergunta.codigo}).length">
                            <strong>R: Sem Resposta</strong>
                        </p>
                    </div>
                    <div *ngIf="pergunta.tipo == '3' && permitirAtendimento" class="form-group col-3 pr-0 justify-content-end align-items-center">
                        <select 
                            #respostaSelect
                            [class.is-invalid]="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)"
                            formControlName="resposta_{{ pergunta.codigo }}" 
                            (change)="acaoRespostaSelect(respostaSelect)"
                            class="form-control form-control-sm"
                            name="resposta_{{ pergunta.codigo }}" 
                            id="resposta_{{ pergunta.codigo }}"                
                        >
                            <option value=""></option>
                            <option *ngFor="let resposta of pergunta.respostas" 
                                value="{{ resposta.nome | uppercase}}"
                            >
                                {{ resposta.nome | uppercase}}
                            </option>
                        </select>
                        <div class="mb-1 linha-erro" *ngIf="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)">
                            <span 
                                class="erro" 
                                *ngIf="campoErro('resposta_' + pergunta.codigo, 'required')"
                            >Campo obrigatório</span>
                        </div>
                    </div>
                    <div *ngIf="pergunta.tipo != '3' && permitirAtendimento" class="form-group col-3 pr-0 justify-content-end align-items-center">
                        <input
                            #respostaInput
                            [class.is-invalid]="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)"
                            formControlName="resposta_{{ pergunta.codigo }}" 
                            (input)="acaoRespostaInput(respostaInput, pergunta.tipo)"
                            matTooltip="{{ 
                                pergunta.tipo == '1' ? 'Area' :
                                pergunta.tipo == '2' ? 'Texto' :
                                pergunta.tipo == '4' ? 'Hora' :
                                pergunta.tipo == '5' ? 'Período' :
                                pergunta.tipo == '6' ? 'Data' :
                                pergunta.tipo == '7' ? 'Período' :
                                pergunta.tipo == '8' ? 'Telefone' :
                                pergunta.tipo == '9' ? 'Numero' : ''
                            }}"
                            matTooltipPosition="left"
                            class="form-control text-right form-control-sm {{ 
                                pergunta.tipo == '1' ? 'notNull' :
                                pergunta.tipo == '2' ? 'notNull' :
                                pergunta.tipo == '4' ? 'horaSimples' :
                                pergunta.tipo == '5' ? 'horaComposta' :
                                pergunta.tipo == '6' ? 'dataSimples' :
                                pergunta.tipo == '7' ? 'dataComposta' :
                                pergunta.tipo == '8' ? 'telefoneComposto' :
                                pergunta.tipo == '9' ? 'notNull' : ''
                            }}"
                            [attr.maxlength]=" 
                                pergunta.tipo == '1' ? '10' :
                                pergunta.tipo == '2' ? '500' :
                                pergunta.tipo == '4' ? '5' :
                                pergunta.tipo == '5' ? '11' :
                                pergunta.tipo == '6' ? '10' :
                                pergunta.tipo == '7' ? '21' :
                                pergunta.tipo == '8' ? '12' :
                                pergunta.tipo == '9' ? '10' : '0'
                            "
                            id="resposta_{{ pergunta.codigo }}"
                            name="resposta_{{ pergunta.codigo }}"
                            type="text"
                        />
                        <div class="mb-1 linha-erro" *ngIf="campoInvalido('resposta_' + pergunta.codigo) && campoTocado('resposta_' + pergunta.codigo)">
                            <span 
                                class="erro" 
                                *ngIf="campoErro('resposta_' + pergunta.codigo, 'required')"
                            >Campo obrigatório</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row col-12 justify-content-center align-items-center linha-botao mt-5" *ngIf="permitirSalvarRespostasAuditor">
                <button 
                    type="button" 
                    class="btn btn-success"
                    title="Salvar"
                    (click)="salvarRespostasAuditor()">
                    Salvar Respostas
                </button>
            </div>
            <div class="col-12 pt-4" *ngIf="!perguntas.length">
                <p class="card-text-auditor text-center">
                    Não existe nenhum questionário.
                </p>
            </div>
        </div>
    </div>
</form>