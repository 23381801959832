<div id="navSection" data-spy="affix" class="nav sidenav">
    <div class="sidenav-content">
        <a href="javascript:void(0);" (click)="goToAnchor('tabsDadosLicenciamento')" class="nav-link tabsDadosLicenciamento">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                ></use>
            </svg>  
            Dados Licenciamento
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsAtividades')" class="nav-link tabsAtividades" *ngIf="licenciamento.cliente == '9999' && licenciamento.atendimentoDeferir != ''">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                ></use>
            </svg>  
            Atividades
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsConfiguracaoDocumento')" class="nav-link tabsConfiguracaoDocumento">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                ></use>
            </svg>  
            Configuração
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsObservacoes')" class="nav-link tabsObservacoes">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                ></use>
            </svg>  
            Observações
        </a>
        <a *ngFor="let perguntaLicenciamento of licenciamento.perguntasLicenciamento"
            href="javascript:void(0);" 
            matTooltip="{{ perguntaLicenciamento.perfil == '' ? 'Pergunta(s) Fixa(s)' : perguntaLicenciamento.processo }}"
            matTooltipPosition="left"
            (click)="goToAnchor('tabsPerguntas' + perguntaLicenciamento.perfil)" 
            class="nav-link tabsPerguntas{{ perguntaLicenciamento.perfil }}">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                ></use>
            </svg>  
            {{ perguntaLicenciamento.perfil == '' ? 'Grupo Fixa(s)' : 'Grupo ' + perguntaLicenciamento.perfil }}
        </a>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsFinalizacao')" class="nav-link tabsFinalizacao">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                ></use>
            </svg>  
            Atendimento
        </a>
    </div>
</div>
<div class="row col-10 layout-top-spacing p-3">
    <div class="row col-12 top-atendimento">
        <div class="col-lg-12 col-12 layout-spacing">
            <div class="row container-principal-atendimento container-tamanho pt-3 pb-3">
                <div class="row col-9 justify-content-center align-items-center">
                    <app-atendimento-avaliador titulo="QUESTIONÁRIO PARA OBTENÇÃO DE LICENÇA" class="w-100"></app-atendimento-avaliador>
                </div>
                <div class="row col-3 justify-content-center align-items-center divisao-esquerda">
                    <app-atendimento-pendencia-modal class="w-100"></app-atendimento-pendencia-modal>
                </div>
            </div>
        </div>
    </div>

    <!-- Dados do Licenciamento -->
    <div class="row col-12">
        <div id="tabsDadosLicenciamento" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <div class="card">
                    <div class="card-header">
                        <div class="col-12">
                            <strong>Dados do Licenciamento</strong>
                        </div>
                    </div>
                    <div *ngIf="empresa == null" class="card-body">
                        <div class="form-row row-linha">
                            <div class="col-12">
                                <p class="card-text text-center">
                                    Carregando...
                                </p>
                            </div>
                        </div>      
                    </div>
                    <div *ngIf="empresa != null" class="card-body">
                        <div class="form-row row-linha linha-grid-divisao justify-content-center align-items-center">
                            <div class="col-2">
                                <p class="card-text">
                                    <strong>Nome:</strong>
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text">
                                    <strong>{{ empresa.registro.nome }}</strong>
                                </p>
                            </div>
                        </div>
        
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text">
                                    <strong>CPF / CNPJ:</strong>
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text">
                                    <strong>{{ empresa.registro.documentos[1].numero }}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text">
                                    <strong>Insc. Mobiliária:</strong>
                                </p>
                            </div>
                            <div class="col-4">
                                <p class="card-text">
                                    <strong>{{ empresa.inscricao }} </strong>
                                </p>
                            </div>
                            <div class="col-2">
                                <p class="card-text">
                                    <strong>Data de Abertura: </strong>
                                </p>
                            </div>
                            <div class="col-4">
                                <p class="card-text">
                                    <strong> {{ empresa.registro.documentos[1].dataEmissao }} </strong>
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text">
                                    <strong>Insc. Estadual: </strong>
                                </p>
                            </div>
                            <div class="col-4">
                                <p class="card-text">
                                    <strong>{{ empresa.inscricao }}</strong>
                                </p>
                            </div>
                            <div class="col-2">
                                <p class="card-text">
                                    <strong>Data de Insc. Estadual:</strong>
                                </p>
                            </div>
                            <div class="col-4">
                                <p class="card-text">
                                    <strong>{{ empresa.registro.documentos[1].dataEmissao }}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text">
                                    <strong>Órgão:</strong>
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text">
                                    <strong>{{ empresa.registro.orgaoNome}}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text">
                                    <strong>Requisitos:</strong>
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text">
                                    <strong>{{ licenciamento.requisito.codigo }} - {{ licenciamento.requisito.nome }}</strong>
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text">
                                    <strong>Status:</strong>
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text">
                                    <strong>{{ tipoDePendenciaLicenciamentoDescricao[licenciamento.statusDoLicenciamento] }}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Configuração do E-Licenciamento -->
    <div class="row col-12" *ngIf="licenciamento.cliente == '9999' && licenciamento.atendimentoDeferir != ''">
        <div id="tabsAtividades" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <div class="card">
                    <div class="card-header">
                        <div class="col-12">
                            <strong>Atividades</strong>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-row row-linha">
                            <div class="col-12">
                                <p class="card-text text-left">
                                    *Selecione as atividades que serão visualizadas no documento.
                                </p>
                            </div>
                        </div>      
                    </div>
                    <div *ngIf="empresa == null" class="card-body">
                        <div class="form-row row-linha">
                            <div class="col-12">
                                <p class="card-text text-center">
                                    Carregando...
                                </p>
                            </div>
                        </div>      
                    </div>
                    <div *ngIf="empresa != null" class="card-body">
                        <form
                            class="col-lg-12 col-12 p-0 justify-content-center align-items-center"
                            name="formularioAtividades"
                            [formGroup]="formularioAtividades"
                        >
                        <div class="card-body">
                            <div class="form-row row-linha">
                                <div class="col-12">
                                    <p class="card-text text-left">
                                        Atividades exercidas no local
                                    </p>
                                </div>
                            </div>      
                        </div>
                        <div *ngFor="let listaExercida of empresa.viabilidade.atividadesExercidas; let i = index">
                            <div class="row col-12 pt-1 pb-1 pl-0 pr-0">
                                <div class="row col-1 justify-content-left align-items-center">
                                    <p class="card-text">
                                        <mat-checkbox
                                            #checkboxAtividade
                                            (change)="acaoSelecionarAtividades(checkboxAtividade, listaExercida.codigo)"
                                            formControlName="atividadesExercidas"
                                            id="Atividade_{{ listaExercida.codigo }}"
                                            name="Atividade_{{ listaExercida.codigo }}">
                                        </mat-checkbox>
                                    </p>
                                </div>
                                <div class="row col-2 justify-content-left align-items-center">
                                    <p class="card-text">
                                        <button type="button" 
                                            class="btn btn-sm btn-informacao-atividade"
                                            [class.btn-secondary]="listaExercida.principal"
                                            [class.btn-primary]="!listaExercida.principal">
                                            {{ listaExercida.principal ? 'PRINCIPAL' : 'SECUNDÁRIA' }}
                                        </button>
                                    </p>
                                </div>
                                <div class="row justify-content-start align-items-center col-9" >
                                    <p class="card-text">
                                        <b>{{ listaExercida.codigo | mascara: 10 }}</b> - {{ listaExercida.nome }}
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div class="card-body">
                            <div class="form-row row-linha">
                                <div class="col-12">
                                    <p class="card-text text-left">
                                        Atividades não exercidas no local
                                    </p>
                                </div>
                            </div>      
                        </div>

                        <div *ngFor="let listaExercida of empresa.viabilidade.atividadesNaoExercidas; let i = index">
                            <div class="row col-12 pt-1 pb-1 pl-0 pr-0">
                                <div class="row col-1 justify-content-left align-items-center">
                                    <p class="card-text">
                                        <mat-checkbox
                                            #checkboxAtividade
                                            (change)="acaoSelecionarAtividades(checkboxAtividade, listaExercida.codigo)"
                                            formControlName="atividadesNaoExercidas"
                                            id="Atividade_{{ listaExercida.codigo }}"
                                            name="Atividade_{{ listaExercida.codigo }}">
                                        </mat-checkbox>
                                    </p>
                                </div>
                                <div class="row col-2 justify-content-left align-items-center">
                                    <p class="card-text">
                                        <button type="button" 
                                            class="btn btn-sm btn-informacao-atividade"
                                            [class.btn-secondary]="listaExercida.principal"
                                            [class.btn-primary]="!listaExercida.principal">
                                            {{ listaExercida.principal ? 'PRINCIPAL' : 'SECUNDÁRIA' }}
                                        </button>
                                    </p>
                                </div>
                                <div class="row justify-content-start align-items-center col-9" >
                                    <p class="card-text">
                                        <b>{{ listaExercida.codigo | mascara: 10 }}</b> - {{ listaExercida.nome }}
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                        </form>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Configuração do E-Licenciamento -->
    <div class="row col-12">
        <div id="tabsConfiguracaoDocumento" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <div class="card">
                    <div class="card-header">
                        <div class="col-12">
                            <strong>Configuração</strong>
                        </div>
                    </div>
                    <div *ngIf="licenciamento == null" class="card-body">
                        <div class="form-row row-linha">
                            <div class="col-12">
                                <p class="card-text text-center">
                                    Carregando...
                                </p>
                            </div>
                        </div>      
                    </div>
                    <div *ngIf="licenciamento != null" class="card-body">
                        <form
                            class="col-lg-12 col-12 p-0 justify-content-center align-items-center"
                            name="formularioConfiguracaoDocumento"
                            [formGroup]="formularioConfiguracaoDocumento"
                        >
                            <div *ngIf="licenciamento.possuiCertidaoDeDispensa || licenciamento.situacaoLicenca"
                                class="form-row row-linha linha-grid-divisao justify-content-center align-items-center">
                                <div class="col-12 pb-4">
                                    <p class="card-text text-center">
                                        <strong>
                                            {{ !licenciamento.situacaoCertidaoDeDispensa && licenciamento.situacaoLicenca ? 'Licenciamento' : 'Certidão de Dispensa' }}
                                            da empresa está atualmente com o status de:
                                        </strong>
                                        <b [class.texto-success]="licenciamento.situacaoCertidaoDeDispensa || (licenciamento.situacaoLicenca && licenciamento.statusDoLicenciamento == tipoDePendenciaLicenciamento.Deferido && !documentoLicencaBloqueadaParaLiberar())"
                                            [class.texto-danger]="(!licenciamento.situacaoCertidaoDeDispensa && !licenciamento.situacaoLicenca) || (licenciamento.situacaoLicenca && licenciamento.statusDoLicenciamento == tipoDePendenciaLicenciamento.Bloqueado || documentoLicencaBloqueadaParaLiberar())">
                                            {{ 
                                                licenciamento.situacaoCertidaoDeDispensa || (licenciamento.situacaoLicenca && licenciamento.statusDoLicenciamento == tipoDePendenciaLicenciamento.Deferido && !documentoLicencaBloqueadaParaLiberar()) ? 'DOCUMENTO LIBERADO' : 
                                                (!licenciamento.situacaoCertidaoDeDispensa && !licenciamento.situacaoLicenca) || (licenciamento.situacaoLicenca && (licenciamento.statusDoLicenciamento == tipoDePendenciaLicenciamento.Bloqueado || documentoLicencaBloqueadaParaLiberar())) ? 'DOCUMENTO BLOQUEADO' : 'NÃO GERADO'
                                            }}
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="licenciamento.situacaoLicenca && licenciamento.statusDoLicenciamento == tipoDePendenciaLicenciamento.Deferido"
                                class="form-row row-linha linha-grid-divisao justify-content-center align-items-center">
                                <div class="col-3">
                                    <p class="card-text">
                                        <strong>Validade da Licença:</strong>
                                    </p>
                                </div>
                                <div class="col-9">
                                    <p class="card-text">
                                        <button type="button" class="btn btn-sm" 
                                            [class.btn-outline-success]="!licencaVencida"
                                            [class.btn-outline-danger]="licencaVencida">
                                            {{ this.licenciamento.dataVenciamentoLicenca }}
                                        </button>
                                        <button type="button" class="btn btn-sm btn-secondary" 
                                            (click)="atender(tipoDePendenciaLicenciamento.Prorrogar_Licenca)">
                                            Prorrogar Licença
                                        </button>
                                        <span [innerHtml]="menssagemProrrogarLicenca"></span>
                                    </p>
                                </div>
                            </div>
                            <div class="form-row row-linha linha-grid-divisao justify-content-center align-items-center">
                                <div class="col-3">
                                    <p class="card-text">
                                        <strong>Licença Pretendida:</strong>
                                    </p>
                                </div>
                                <div class="col-9">
                                    <p class="card-text">
                                        {{ licenciamento.fatorDeRiscoDocumento != '' ? licenciamento.fatorDeRiscoDocumento : 'NADA CONSTA' }}
                                    </p>
                                </div>
                            </div>
                            <div class="form-row row-linha linha-grid-divisao justify-content-center align-items-center">
                                <div class="col-3">
                                    <p class="card-text">
                                        <strong>Classificação:</strong>
                                    </p>
                                </div>
                                <div class="col-9">
                                    <p class="card-text">
                                        {{ licenciamento.fatorDeRisco != '' ? licenciamento.fatorDeRisco : 'NADA CONSTA' }}
                                    </p>
                                </div>
                            </div>
                            <div class="form-row row-linha linha-grid-divisao justify-content-center align-items-center">
                                <div class="col-3">
                                    <p class="card-text">
                                        <strong>Enquadramento:</strong>
                                    </p>
                                </div>
                                <div class="col-9">
                                    <p class="card-text">
                                        {{ licenciamento.fatorDeRiscoEnquadramento != '' ? licenciamento.fatorDeRiscoEnquadramento : 'NADA CONSTA' }}
                                    </p>
                                </div>
                            </div>
                            <div class="form-row row-linha linha-grid-divisao justify-content-center align-items-center">
                                <div class="col-3">
                                    <p class="card-text">
                                        <strong>Atividade:</strong>
                                    </p>
                                </div>
                                <div class="col-9">
                                    <p class="card-text">
                                        {{ licenciamento.fatorDeRiscoPergunta != '' ? licenciamento.fatorDeRiscoPergunta : 'NADA CONSTA' }}
                                    </p>
                                </div>
                            </div>
                            <div class="form-row row-linha linha-grid-divisao justify-content-center align-items-center">
                                <div class="col-3">
                                    <p class="card-text">
                                        <strong>Processo Administrativo:</strong>
                                    </p>
                                </div>
                                <div class="col-9">
                                    <p class="card-text">
                                        {{ licenciamento.numeroProtocolo != '' ? licenciamento.numeroProtocolo : 'NADA CONSTA' }}
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="licenciamento.possuiPrazoEntregaDocumentacao" class="form-row row-linha linha-grid-divisao justify-content-center align-items-center">
                                <div class="col-3">
                                    <p class="card-text">
                                        <strong>Prazo de Entrega de Documentação:</strong>
                                    </p>
                                </div>
                                <div class="col-9">
                                    <input
                                        formControlName="prazoEntrega"
                                        id="prazo_entrega"
                                        name="prazo_entrega"
                                        class="form-control form-control-sm col-3"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div *ngIf="licenciamento.possuiClassificacaoCopam" class="form-row row-linha mt-4">
                                <div class="row col-12 justify-content-center align-items-center">
                                    <p class="card-text titulo">
                                        <mat-radio-group 
                                            formControlName="atividadeConstaCopam" 
                                            aria-label="Selecione uma opção">
                                            <mat-radio-button value="1"
                                                >Atividade <b>NÃO CONSTA</b> no COPAM
                                            </mat-radio-button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <mat-radio-button value="2"
                                                >Atividade <b>CONSTA</b> no COPAM
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="(licenciamento.documentoTipo.toString() == tipoDocumento.Cdp.toString() && licenciamento.possuiCertidaoDeDispensa) ||
                            licenciamento.documentoTipo.toString() == tipoDocumento.Fob.toString()||
                            licenciamento.documentoTipo.toString() == tipoDocumento.Documento_Anexo_eLic.toString()" 
                                class="row col-12 justify-content-center align-items-center mt-5">
                                    <button *ngIf="licenciamento.documentoGerar != ''" type="button" class="btn btn-success" (click)="gerarDocumento();">
                                    {{ licenciamento.documentoGerar }}
                                </button>
                                <button *ngIf="licenciamento.documentoVisualizar != '' && licenciamento.documentoGerar != 'Gerar Documento(s)/Anexos'" type="button" class="btn btn-success" (click)="baixarDocumento();">
                                    {{ licenciamento.documentoVisualizar }}
                                </button>
                                <button *ngIf="licenciamento.documentoLiberar != '' || licenciamento.documentoBloquear != '' && licenciamento.documentoGerar != 'Gerar Documento(s)/Anexos'"
                                    type="button" 
                                    class="btn" 
                                    [class.btn-success]="licenciamento.documentoLiberar != ''"
                                    [class.btn-danger]="licenciamento.documentoLiberar == ''"
                                    (click)="liberarBloquearDocumento()">
                                    {{ licenciamento.documentoLiberar != '' ? licenciamento.documentoLiberar : licenciamento.documentoBloquear }}
                                </button>
                            </div>
                        </form>
                        <div class="form-row col-12 p-0 accordion-border mt-4" *ngIf="licenciamento.tipoDocumento == 'FOB'">
                            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="w-100">
                                <ngb-panel>
                                    <ng-template class="card-header" ngbPanelHeader let-opened="opened">
                                        <div class="row col-12">
                                            <div class="row col-10 justify-content-start align-items-center" >
                                                <strong>Documentos FOB</strong>
                                            </div>
                                            <div class="row col-2 justify-content-end align-items-center">
                                                <button type="button" 
                                                    ngbPanelToggle 
                                                    class="btn btn-success btn-painel">
                                                    <svg [class.hidden]="opened" 
                                                        class="icone-painel">
                                                        <use
                                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                                        ></use>
                                                    </svg>
                                                    <svg [class.hidden]="!opened" 
                                                        class="icone-painel">
                                                        <use
                                                        attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                                        ></use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template class="card-body" ngbPanelContent>
                                        <div *ngFor="let orientacao of licenciamento.licenciamentoOrientacoes ; let i = index"
                                            class="row col-12 justify-content-start align-items-center" 
                                            [class.mt-3]="i > 0">
                                            <p class="card-text">
                                                <mat-checkbox
                                                    #checkboxOrientacao
                                                    (change)="acaoSelecionarOrientacoes(checkboxOrientacao, orientacao.codigo)"
                                                    class="mr-2"
                                                    id="orientacao_{{ orientacao.codigo }}"
                                                    name="orientacao">
                                                </mat-checkbox>
                                                {{ orientacao.orientacao }}
                                            </p>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                        <div class="form-row col-12 p-0 mt-4">
                            <app-atendimento-observacao class="w-100" #observacaoDocumentos></app-atendimento-observacao>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Observações -->
    <div class="row col-12">
        <div id="tabsObservacoes" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-atendimento-observacao #observacaoFluxo></app-atendimento-observacao>
            </div>
        </div>
    </div>
    
    <!-- CheckBox para criticar todo o questionário -->
    <div class="row col-12 pt-5 pb-2">
        <div class="col-lg-12 col-12 layout-top-spacing">
            <mat-checkbox 
                #criticarTodoQuestionario
                (change)="acaoCriticarTodoQuestionario(criticarTodoQuestionario)"
                id="criticarTodoQuestionario"
                name="criticarTodoQuestionario">
            </mat-checkbox>
            Criticar todo o questionário
        </div>
    </div>

    <!-- Questionário -->
    <div class="row col-12">
        <div class="col-lg-12 col-12 layout-spacing" *ngFor="let perguntaLicenciamento of licenciamento.perguntasLicenciamento">
            <div [id]="'tabsPerguntas' + perguntaLicenciamento.perfil" class="row col-12 p-0" >
                <div class="container-principal-atendimento w-100">
                    <app-form-questionario 
                        class="w-100"
                        [nome]="perguntaLicenciamento.perfil"
                        tipo="L"
                        permitirAtendimento="true"
                        [titulo]="perguntaLicenciamento.perfil == '' ? 'Grupo Pergunta(s) Fixa(s)' : 'Grupo ' + perguntaLicenciamento.perfil + ' - ' + perguntaLicenciamento.processo"
                        [perguntasCarregadasDeInicio]="perguntaLicenciamento.perguntas"></app-form-questionario>
                </div>
            </div>
        </div>
    </div>

    <!-- Atendimento -->
    <div class="row col-12">
        <div id="tabsFinalizacao" class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <div class="card">
                    <div class="card-header">
                        <div class="col-12">
                            <strong>Atendimento</strong>
                        </div>
                    </div>
                    <div *ngIf="licenciamento == null" class="card-body">
                        <div class="form-row row-linha">
                            <div class="col-12">
                                <p class="card-text text-center">
                                    Carregando...
                                </p>
                            </div>
                        </div>      
                    </div>
                    <div *ngIf="licenciamento != null" class="card-body">
                        <div class="row col-12 justify-content-center align-items-center mt-2">
                            <button *ngIf="licenciamento.atendimentoDeferir != ''" type="button" class="btn btn-success" (click)="atender(tipoDePendenciaLicenciamento.Deferido)">
                                {{ licenciamento.atendimentoDeferir }}
                            </button>
                            <button *ngIf="licenciamento.atendimentoIndeferir != ''" type="button" class="btn btn-warning" (click)="atender(tipoDePendenciaLicenciamento.Indeferido)">
                                {{ licenciamento.atendimentoIndeferir }}
                            </button>
                            <button *ngIf="licenciamento.atendimentoBloquear != '' || licenciamento.atendimentoLiberar != ''" type="button" class="btn btn-danger" (click)="atender(licenciamento.atendimentoBloquear != '' ? tipoDePendenciaLicenciamento.Bloqueado : tipoDePendenciaLicenciamento.Liberado)">
                                {{ licenciamento.atendimentoBloquear != '' ? licenciamento.atendimentoBloquear : licenciamento.atendimentoLiberar }}
                            </button>
                            <button *ngIf="licenciamento.atendimentoReavaliar != ''" type="button" class="btn btn-success" (click)="atender(tipoDePendenciaLicenciamento.Pendente_Orgao)">
                                {{ licenciamento.atendimentoReavaliar }}
                            </button>
                            <button *ngIf="licenciamento.atendimentoReativar != ''" type="button" class="btn btn-danger" (click)="atender(tipoDePendenciaLicenciamento.Pendente_Usuario)">
                                {{ licenciamento.atendimentoReativar }}
                            </button>
                        </div>
                        <div *ngIf="licenciamento.atendimentoInformativo.length" class="row col-12 justify-content-center align-items-center mt-5">
                            <p *ngFor="let informativo of licenciamento.atendimentoInformativo; let i = index" class="card-text">
                                * <b>{{ informativo.titulo }}</b> {{ informativo.descricao }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
