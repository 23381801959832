import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { AtendimentoConfiguracao } from "src/app/model/atendimentoConfiguracao";
import { EnderecoService } from 'src/app/services/endereco/endereco.service';
import { Imobiliario } from "src/app/model/imobiliario";
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ProtocoloService } from 'src/app/services/operacao/protocolo.service';
import { Constantes } from 'src/app/model/constantes';
import { ImobiliarioEmpresaProtocolo } from "src/app/model/imobiliarioEmpresaProtocolo";

@Component({
  selector: 'app-atendimento-imobiliario',
  templateUrl: './atendimento-imobiliario.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoImobiliarioComponent implements OnInit {

  @Output() imobiliarioCarregado = new EventEmitter();

  numeroProtocolo: string;
  atendimentoConfiguracao: AtendimentoConfiguracao;
  imobiliario: Imobiliario;
  tipoEmpresa: string;
  protocolosIndeferidos: Array<ImobiliarioEmpresaProtocolo>;

  constructor(
    private enderecoService: EnderecoService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog) {
    this.atendimentoConfiguracao = new AtendimentoConfiguracao();
    this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];
    this.tipoEmpresa = this.atendimentoConfiguracao.processo.tipo;
    this.protocolosIndeferidos = new Array<ImobiliarioEmpresaProtocolo>();
   }

  ngOnInit(): void {
    
  }

  preencher(atendimentoConfiguracao){
    this.atendimentoConfiguracao = atendimentoConfiguracao;
    this.imobiliarioPorProtocolo(atendimentoConfiguracao);
  }

  async imobiliarioPorProtocolo(atendimentoConfiguracao){
    await this.enderecoService
      .buscarImobiliarioPorProtocolo(atendimentoConfiguracao.cliente,atendimentoConfiguracao.protocolo)
      .pipe(take(1))
      .subscribe(retorno => {
        this.imobiliario = retorno;
        this.imobiliario.protocolos.forEach(protocolo =>{
          if(protocolo.status == Constantes.SITUACAO_DE_ACOMPANHAMENTO.Concluido_Indeferido.toString()){
            this.protocolosIndeferidos.push(protocolo);
          }
        })
        this.acaoImobiliarioCarregado();
      })
  }
  
  exibeEmpresas(empresas){
    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '70%',
      closeOnNavigation: true,
      data: {
          titulo: "Empresas ativas no mesmo imóvel",
          componente: "lista-empresa",
          dados : [empresas]
      }
      });

      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === "" || result === null)
          return false;

      //this.atividadesSelecionadas = result.atividades.selected;
    });
  }

  exibeProtocolos(protocolos){
    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '70%',
      closeOnNavigation: true,
      data: {
          titulo: "Protocolos solicitados no mesmo imóvel",
          componente: "lista-protocolo",
          dados : [protocolos]
      }
      });

      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === "" || result === null)
          return false;
    });
  }

  acaoImobiliarioCarregado() {
    this.imobiliarioCarregado.emit({ acao: this.imobiliario.zonas});
  }

}
