import { Component, OnInit, Input, Renderer2 } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormControl } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';

import Swal from "sweetalert2";
import { formatToCEP } from 'brazilian-values';
import { Endereco } from "src/app/model/endereco";
import { EnderecoRegra } from "src/app/model/enderecoRegra";
import { EnderecoService } from "src/app/services/endereco/endereco.service";
import { IcadModalComponent } from "src/app/components/modal/icad-modal/icad-modal.component";
import { Constantes } from "src/app/model/constantes";
import { FormValidator } from "src/app/components/validators/form-validator";
import { take } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { EnumService } from '../../../services/enumeradores/enum.service';
import { Protocolo } from '../../../model/protocolo';
import { ActivatedRoute, Router } from '@angular/router';
import { MatCheckbox } from "@angular/material/checkbox";

@Component({
    selector: "app-form-endereco",
    templateUrl: "./form-endereco.component.html",
    styleUrls: ["./form-endereco.component.scss"]

})
export class FormEnderecoComponent implements OnInit {
        
    @Input() tipoEnderecoConfiguracao: string;

    formulario: FormGroup;
    
    endereco: Endereco;
    enderecoRegra = new EnderecoRegra;

    emitirErro: boolean;
    inscricaoFormatadaValida: boolean;
    
    estados = Constantes.ESTADOS;
    tiposLogradouros: Array<any>;

    liberarCampos: boolean;
    liberaBusca: boolean = true;
    layoutConfiguracao: boolean = false;

    bloqueadoPorCriticas: boolean = false;
    
    emitirCritica: boolean;
    mensagemCritica: string = "";

    codigoMenu = null;

    maxLengthBuscaValor: number;

    enderecoSemRegularizacao: boolean = false;

    constructor(
        private render: Renderer2,
        private formBuilder: FormBuilder,
        private enderecoService: EnderecoService,
        private router: Router,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private enumService: EnumService,
        private activatedRoute: ActivatedRoute,) {

        this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];    
            
        this.formulario = this.formBuilder.group({
            cliente: ["", [Validators.required]],
            codigo: [""],
            protocolo: [""],
            inscricao: [""],
            inscricaoFormatada: [""],
            cep: ["", [Validators.required, Validators.minLength(8)]],
            tipoLogradouroCodigo: ["", [Validators.required]],
            tipoLogradouro: [""],
            tituloLogradouro: [""],
            codigoLogradouro: [""],
            logradouro: ["", [Validators.required]],
            numero: ["", [FormValidator.orOther('complemento')]],
            complemento: [""],
            codigoBairro: [""],
            bairro: ["", [Validators.required]],
            cidade: ["", [Validators.required]],
            estado: ["", [Validators.required, Validators.maxLength(2)]],
            pais: [""],
            pontoReferencia: [""],
            latitude: [""],
            longitude: [""],
            area: [""],
            tipoEndereco: ["", [Validators.required]],
            tipoUrbanoRural: ["1"],
            tipoBusca: ["", [Validators.required]],
            tipoBuscaValor: [""],
            estadoBusca: [""],
            baseUtilizada: [""],
            semRegularizacao: [""],
        });

        this.formulario.controls['tipoBuscaValor'].disable();
        this.formulario.get("cliente").setValue(localStorage.getItem('pId'));

        this.liberarCampos = true;

        if(this.estaAutenticado()){
            this.cep.clearValidators();
            this.tipoBusca.clearValidators();
            if(this.codigoMenu == 115){
                this.tipoLogradouroCodigo.clearValidators();
                this.logradouro.clearValidators();
                this.numero.clearValidators();
                this.bairro.clearValidators();
                this.cidade.clearValidators();
                this.estado.clearValidators();
            }
        }

        this.enumService
            .tipoDeLogradouro()
            .pipe(take(1))
            .subscribe( retorno => {
                this.tiposLogradouros = retorno
            })
    }

    ngOnInit(): void {
        this.cliente.setValue(localStorage.getItem('pId'));
        this.tipoEndereco.setValue(this.tipoEnderecoConfiguracao);

        this.enderecoRegra.tipoEndereco = this.tipoEndereco.value;
        this.liberaBloqueiaCampos();
        this.buscarRegra();

        if ( (this.enderecoRegra.urbanoValidarCampo == "S" && this.tipoUrbanoRural.value == "1") ||
           (this.enderecoRegra.ruralValidarCampo == "S" && this.tipoUrbanoRural.value == "2") ) {
            this.formulario.controls["inscricaoFormatada"].setValidators([Validators.required]);
        }
    }

    estaAutenticado() {
        return this.authenticationService.estaAutenticado();
    }

    verificaValidacaoFormulario(formGroup: FormGroup) {
        
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });
    }
    
    openDialog(dados) {
        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '1100px',
            maxHeight: "none",
            data: {
                titulo: "Lista de Endereços",
                componente: "grid-endereco",
                dados: dados
            }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.preencherCampos(result.row);
            }
        });
    }
      
    acaoTipoUrbanoRuralInput() {
        this.limparCampos();
        this.liberaBloqueiaCampos(); 
        if (this.tipoUrbanoRural.value != 1) {
            this.tipoBusca.clearValidators();
            if (this.tipoUrbanoRural.value == 2) {
                this.tipoBusca.setValue("R");
                if(localStorage.getItem('pId') == '545'){
                    this.cep.setValue(Constantes.CEP_UNICO.Betim);
                    this.formulario.controls['cep'].disable();
                }
            }
            else if (this.tipoUrbanoRural.value == 3) {
                this.inscricaoFormatada.clearValidators();
                this.inscricaoFormatada.updateValueAndValidity();
                this.tipoBusca.setValue("S");
            }
        }
        else {
            this.cliente.setValue(localStorage.getItem('pId')) 
            this.tipoBusca.setValidators([Validators.required]);
        }
        this.tipoBusca.updateValueAndValidity();
    }

    acaoSemRegularizacao(checkbox: MatCheckbox) {
        if (this.tipoBusca.value === "I") {
            Swal.fire({
                title: "",
                html: `Busca realizada por ${ this.tipoUrbanoRural.value != '2' ? this.enderecoRegra.urbanoNomeCampo : this.enderecoRegra.ruralNomeCampo } não é possível declarar como sem regularização.`,
                icon: "info"
            })
            checkbox.checked = false;
            this.enderecoSemRegularizacao = false;
            return false;
        }

        this.inscricaoFormatada.setValue("");
        this.inscricaoFormatada.clearValidators();
        this.inscricaoFormatada.disable();

        if (!checkbox.checked) {
            this.inscricaoFormatada.enable();
            if ( (this.enderecoRegra.urbanoValidarCampo == "S" && this.tipoUrbanoRural.value == "1") ||
                (this.enderecoRegra.ruralValidarCampo == "S" && this.tipoUrbanoRural.value == "2") ) {
                this.formulario.controls["inscricaoFormatada"].setValidators([Validators.required]);
            }
        }
        
        this.inscricaoFormatada.updateValueAndValidity();
    }

    acaoTipoBuscaSelect(valor) {
        // this.limparCampos();
        this.liberaBloqueiaCampos();
        this.acaoInputBuscaValor(valor);
    }

    acaoInputBuscaValor(valor)  {
        if(valor === 'C') {
            this.maxLengthBuscaValor = 9;
        }else{
            this.maxLengthBuscaValor = 20;
        }
    }
        
    formatToCep(valor) {
        valor.value = formatToCEP(valor.value);
    }
  
    buscarRegra() {
        if (localStorage.getItem('pId') != null) {
            this.enderecoService
            .buscarRegra(localStorage.getItem('pId'), this.enderecoRegra.tipoEndereco)
            .pipe(take(1))
            .subscribe( (dados) => {
                this.enderecoRegra = dados;
                },
                (err) => {
                    console.log('err', err);
                }
            );
        }
    }

    buscarEndereco() {

        this.formulario.controls['inscricaoFormatada'].enable();
        if (this.tipoBusca.value === "C") {
            this.buscarPorCep();
        }
        else if (this.tipoBusca.value === "I") {
            this.formulario.controls['inscricaoFormatada'].disable();
            this.buscarPorInscricao();
        }
        else if (this.tipoBusca.value === "L") {
            this.buscarPorLogradouro();
        }
    }
  
    buscarPorCep() {
        if (this.enderecoRegra.tipoEndereco == '2') {
            
            if ( (this.enderecoRegra.urbanoValidarCampo == "S" && this.tipoUrbanoRural.value == "1") ||
                (this.enderecoRegra.ruralValidarCampo == "S" && this.tipoUrbanoRural.value == "2") ) {
                this.formulario.controls["inscricaoFormatada"].setValidators([Validators.required]);
            }
            else{
                this.formulario.controls["inscricaoFormatada"].clearValidators();
            }
            
        }else{
            this.formulario.controls["inscricaoFormatada"].clearValidators();
        }
        

        this.enderecoService
            .buscarPorCep(localStorage.getItem('pId'), this.enderecoRegra.tipoEndereco, this.tipoBuscaValor.value.replace(/\D/g,""))
            .pipe(take(1))
            .subscribe( (dados) => {
                if (!dados.length) {
                    Swal.fire('', 'Cep não localizado.', 'warning');
                    return false;
                }
                if (dados.length > 1) {
                    this.openDialog(dados);
                    return false;
                }
                this.preencherCampos(dados[0]);
                },
                (err) => {
                    console.log('err', err);
                }
        );
    }
  
    buscarCep(cep) {

        if (this.enderecoRegra.tipoEndereco != "4")
            return false;
            
        this.enderecoService
            .buscarPorCep(localStorage.getItem('pId'), this.enderecoRegra.tipoEndereco, cep.replace(/\D/g,""))
            .pipe(take(1))
            .subscribe( (dados) => {
                if (!dados.length) {
                    Swal.fire({
                        title: "",
                        html: `Cep não localizado.<br><br>Caro contribuinte, o campos serão liberados para o preenchimento manual.`,
                        icon: "info"
                    })
                    this.liberarCampos = true;
                    this.limparCampos();
                    this.liberaBloqueiaCampos();
                    this.cep.setValue(cep);
                    return false;
                }
                if (dados.length > 1) {
                    this.openDialog(dados);
                    return false;
                }
                this.liberarCampos = false;
                this.limparCampos();
                this.preencherCampos(dados[0]);
                this.liberaBloqueiaCampos();
                this.cep.enable();
                },
                (err) => {
                    console.log('err', err);
                }
        );
    }
  
    buscarPorLogradouro() {
        
        if (this.enderecoRegra.tipoEndereco == '2') {
            
            if ( (this.enderecoRegra.urbanoValidarCampo == "S" && this.tipoUrbanoRural.value == "1") ||
                (this.enderecoRegra.ruralValidarCampo == "S" && this.tipoUrbanoRural.value == "2") ) {
                this.formulario.controls["inscricaoFormatada"].setValidators([Validators.required]);
            }
            else{
                this.formulario.controls["inscricaoFormatada"].clearValidators();
            }
            
        }else{
            this.formulario.controls["inscricaoFormatada"].clearValidators();
        }
        
        if (this.enderecoRegra.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Empresarial.toString()) {
            this.estadoBusca.setValue(this.enderecoRegra.estado);
            this.estadoBusca.disable();
        }
        
        this.enderecoService
            .buscarPorLogradouro(localStorage.getItem('pId'), this.enderecoRegra.tipoEndereco, this.estadoBusca.value, this.tipoBuscaValor.value)
            .pipe(take(1))
            .subscribe( (dados) => {
                if (!dados.length) {
                    Swal.fire('', 'Logradouro não localizado.', 'warning');
                    return false;
                }
                if (dados.length > 1) {
                    this.openDialog(dados);
                    return false;
                }
                this.preencherCampos(dados[0]);
                },
                (err) => {
                    console.log('err', err);
                }
        );
    }
  
    buscarPorInscricao() {

        this.enderecoService
            .buscarPorInscricao(localStorage.getItem('pId'), this.enderecoRegra.tipoEndereco, this.tipoBuscaValor.value)
            .pipe(take(1))
            .subscribe( (dados) => {
                if (!dados.length) {
                    Swal.fire('', 'Inscrição não localizado.', 'warning');
                    return false;
                }
                if (dados.length > 1) {
                    this.openDialog(dados);
                    return false;
                }
                this.preencherCampos(dados[0]);
                },
                (err) => {
                    console.log('err', err);
                }
        );
    }

    validarInscricao() {
        if ( ( (this.enderecoRegra.urbanoValidarCampo == "S" && this.tipoUrbanoRural.value == "1") ||
           (this.enderecoRegra.ruralValidarCampo == "S" && this.tipoUrbanoRural.value == "2") 
           && this.tipoUrbanoRural.value != "3")) {

            this.enderecoService
                .validarInscricao(localStorage.getItem('pId'), this.inscricaoFormatada.value)
                .pipe(take(1))
                .subscribe( (dados) => {
                    if (!dados) {
                        Swal.fire('', 'Inscrição Imobiliária inválida.', 'warning');
                        this.inscricaoFormatada.reset();
                        return false;
                    }
                    this.inscricaoFormatadaValida = true;
                    },
                    (err) => {
                        console.log('err', err);
                    }
            );
        }
    }

    preencherCampos(dados: Endereco) {
        this.endereco = dados;

        this.limparCampos();
        
        this.formulario.patchValue({
            cliente: dados.cliente,
            codigo: dados.codigo,
            protocolo: dados.protocolo,
            inscricao: dados.inscricao,
            inscricaoFormatada: dados.inscricaoFormatada,
            cep: formatToCEP( ("00000000" + dados.cep).slice(-8).toString()),
            tipoLogradouroCodigo: dados.tipoLogradouroCodigo,
            tipoLogradouro: dados.tipoLogradouro,
            tituloLogradouro: dados.tituloLogradouro.toUpperCase(),
            codigoLogradouro: dados.codigoLogradouro,
            logradouro: dados.logradouro,
            numero: dados.numero,
            complemento: dados.complemento,
            codigoBairro: dados.codigoBairro,
            bairro: dados.bairro,
            cidade: dados.cidade,
            estado: dados.estado,
            pais: dados.pais,
            pontoReferencia: dados.pontoReferencia,
            latitude: dados.latitude,
            longitude: dados.longitude,
            area: dados.area,
            tipoEndereco: dados.tipoEndereco,
            tipoBusca: dados.tipoBusca,
            baseUtilizada: dados.baseUtilizada,
        });
        
        if (this.tipoEndereco.value != Constantes.TIPO_DE_ENDERECO.Empresarial && this.tipoLogradouroCodigo.value == "") {
            this.tipoLogradouroCodigo.enable();
        }

        Object.keys(this.formulario.controls).forEach( controle => {
            if (this.formulario.get(controle).disabled && this.formulario.get(controle).invalid) {
                this.emitirErro = true;
            }
        });
    }   

    preencherEndereco() { 
        this.endereco.cliente = this.cliente.value;
        this.endereco.codigo = this.codigo.value;
        this.endereco.protocolo = this.protocolo.value;
        this.endereco.inscricao = this.inscricao.value;
        this.endereco.inscricaoFormatada = this.inscricaoFormatada.value;
        this.endereco.cep = this.cep.value.replace(/\D/g,"");
        this.endereco.tipoLogradouroCodigo = this.tipoLogradouroCodigo.value;
        this.endereco.tipoLogradouro = this.tiposLogradouros.filter( logradouro => logradouro.id == this.tipoLogradouroCodigo.value)[0].valor.toUpperCase();
        this.endereco.tituloLogradouro = this.tituloLogradouro.value;
        this.endereco.codigoLogradouro = this.codigoLogradouro.value;
        this.endereco.logradouro = this.logradouro.value;
        this.endereco.numero = this.numero.value;
        this.endereco.complemento = this.complemento.value;
        this.endereco.codigoBairro = this.codigoBairro.value; 
        this.endereco.bairro = this.bairro.value;
        this.endereco.cidade = this.cidade.value;
        this.endereco.estado = this.estado.value;
        this.endereco.pais = this.pais.value;
        this.endereco.pontoReferencia = this.pontoReferencia.value;
        this.endereco.latitude = this.latitude.value;
        this.endereco.longitude = this.longitude.value;
        this.endereco.area = this.area.value;
        this.endereco.tipoEndereco = this.tipoEndereco.value;
        this.endereco.tipoBusca = this.tipoBusca.value;
        this.endereco.baseUtilizada = this.baseUtilizada.value;
    }

    liberaBloqueiaCampos() {
        if (this.bloqueadoPorCriticas) {
            return false;
        }

        this.formulario.controls['cep'].disable();
        this.formulario.controls['tipoLogradouroCodigo'].disable();
        this.formulario.controls['logradouro'].disable();
        this.formulario.controls['numero'].disable();
        this.formulario.controls['complemento'].enable();
        this.formulario.controls['pontoReferencia'].enable();
        this.formulario.controls['bairro'].disable();
        this.formulario.controls['cidade'].disable();
        this.formulario.controls['estado'].disable();
        this.enderecoSemRegularizacao = false;
        
        if ((this.tipoUrbanoRural.value != "1" || this.enderecoRegra.tipoEndereco != Constantes.TIPO_DE_ENDERECO.Empresarial.toString() || this.enderecoRegra.municipioCepUnico === "S") && this.liberarCampos ) {
            this.formulario.controls['tipoBusca'].enable();
            this.formulario.controls['cep'].enable();
            this.formulario.controls['inscricaoFormatada'].enable();
            this.formulario.controls['tipoLogradouroCodigo'].enable();
            this.formulario.controls['logradouro'].enable();
            this.formulario.controls['numero'].enable();
            this.formulario.controls['complemento'].enable();
            this.formulario.controls['pontoReferencia'].enable();
            this.formulario.controls['bairro'].enable();
        }

        if (this.enderecoRegra.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Empresarial.toString()) {
            this.estadoBusca.setValue(this.enderecoRegra.estado);
            this.estadoBusca.disable();
            this.cidade.setValue(this.authenticationService.currentClienteValue.endereco.cidade);
            this.estado.setValue(this.authenticationService.currentClienteValue.endereco.estado);
        }
        else {
            if (this.tipoLogradouroCodigo.value == "" && this.logradouro.value != "") {
                this.tipoLogradouroCodigo.enable();
            }
            this.formulario.controls['cidade'].enable();
            this.formulario.controls['estado'].enable();
            this.estadoBusca.enable();
        }

        if (this.tipoBusca.value !== "L" && this.tipoBusca.value !== "" ||
            (this.tipoBusca.value === "L" && this.estadoBusca.value != "")) {
            this.formulario.controls['tipoBuscaValor'].enable();
        }

        if (this.tipoBusca.value !== "I") {
            this.formulario.controls['numero'].enable();
        }

        this.liberarCampos = true;
    }

    bloqueiaCampos() {
        this.liberaBusca = false;
        this.formulario.controls['cep'].disable();
        this.formulario.controls['tipoLogradouroCodigo'].disable();
        this.formulario.controls['logradouro'].disable();
        this.formulario.controls['numero'].disable();
        this.formulario.controls['complemento'].disable();
        this.formulario.controls['pontoReferencia'].disable();
        this.formulario.controls['bairro'].disable();
        this.formulario.controls['cidade'].disable();
        this.formulario.controls['estado'].disable();
    }

    liberaBloqueiaCamposPorCritica(protocolo: Protocolo) {
        this.liberaBloqueiaCampos();

        this.emitirCritica = false;
        this.mensagemCritica = "";

        if (protocolo.criticas.filter(item => { return item.passo == "endereco" }).length) {
            if (this.enderecoRegra.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Correspondencia.toString()) {
                this.mensagemCritica = Protocolo.exibirCriticasFormularioSemBloqueio(this.formulario, protocolo.criticas, "endereco");
            }

            if (this.mensagemCritica != "") {
                this.emitirCritica = true;
            }
        }
        
        if (this.tipoLogradouroCodigo.value == "" && this.logradouro.value != "") {
            this.tipoLogradouroCodigo.enable();
        }
    }

    liberaBloqueiaCamposPorCriticaSemBloqueio(protocolo: Protocolo) {
        this.liberaBloqueiaCampos();

        this.emitirCritica = false;
        this.mensagemCritica = "";

        if (protocolo.criticas.length) {
            this.mensagemCritica = Protocolo.exibirCriticasFormularioSemBloqueio(this.formulario, protocolo.criticas, "endereco");

            if (this.mensagemCritica != "") {
                this.emitirCritica = true;
            }
        }
        
        if (this.tipoLogradouroCodigo.value == "" && this.logradouro.value != "") {
            this.tipoLogradouroCodigo.enable();
        }
    }

    limparCampos() {
        this.endereco = new Endereco();
        this.codigo.setValue("");
        this.protocolo.setValue("");
        this.inscricao.setValue("");
        this.inscricaoFormatadaValida = false;
        this.inscricaoFormatada.setValue("");
        this.cep.setValue("");
        this.tipoLogradouroCodigo.setValue("");
        this.tituloLogradouro.setValue("");
        this.codigoLogradouro.setValue("");
        this.logradouro.setValue("");
        this.numero.setValue("");
        this.complemento.setValue("");
        this.codigoBairro.setValue("");
        this.bairro.setValue("");
        this.cidade.setValue("");
        this.estado.setValue("");
        this.pais.setValue("");
        this.pontoReferencia.setValue("");
        this.latitude.setValue("");
        this.longitude.setValue("");
        this.area.setValue("");
        this.tipoBuscaValor.setValue("");
        this.baseUtilizada.setValue("");
        this.enderecoSemRegularizacao = false;
        this.formulario.markAsUntouched();
    }

    visualizarGoogleMaps() {
        const url = `http://www.google.com/maps?f=q&source=s_q&hl=pt-BR&geocode=&q=${ this.tiposLogradouros.filter( logradouro => logradouro.id == this.tipoLogradouroCodigo.value)[0].valor.toUpperCase() }+${ this.logradouro.value },+${ this.numero.value }+-+${ this.bairro.value }+-+${ this.cidade.value }+-+${ this.estado.value },+${ this.cep.value.replace(/\D/g,"") }+Brasil&z=16&iwloc=A"`
        window.open(url, '_blank');        
    }
    
    get cliente() {
        return this.formulario.get("cliente");
    }
    set cliente(cliente) {
        this.formulario.get("cliente").setValue(cliente);
    }

    get codigo() {
        return this.formulario.get("codigo");
    }
    set codigo(codigo) {
        this.formulario.get("codigo").setValue(codigo);
    }

    get protocolo() {
        return this.formulario.get("protocolo");
    }
    set protocolo(protocolo) {
        this.formulario.get("protocolo").setValue(protocolo);
    }

    get inscricao() {
        return this.formulario.get("inscricao");
    }
    set inscricao(inscricao) {
        this.formulario.get("inscricao").setValue(inscricao);
    }

    get inscricaoFormatada() {
        return this.formulario.get("inscricaoFormatada");
    }
    set inscricaoFormatada(inscricaoFormatada) {
        this.formulario.get("inscricaoFormatada").setValue(inscricaoFormatada);
    }

    get cep() {
        return this.formulario.get("cep");
    }
    set cep(cep) {
        this.formulario.get("cep").setValue(cep);
    }

    get tipoLogradouroCodigo() {
        return this.formulario.get("tipoLogradouroCodigo");
    }
    set tipoLogradouroCodigo(tipoLogradouroCodigo) {
        this.formulario.get("tipoLogradouroCodigo").setValue(tipoLogradouroCodigo);
    }

    get tipoLogradouro() {
        return this.formulario.get("tipoLogradouro");
    }
    set tipoLogradouro(tipoLogradouro) {
        this.formulario.get("tipoLogradouro").setValue(tipoLogradouro);
    }

    get tituloLogradouro() {
        return this.formulario.get("tituloLogradouro");
    }
    set tituloLogradouro(tituloLogradouro) {
        this.formulario.get("tituloLogradouro").setValue(tituloLogradouro);
    }

    get codigoLogradouro() {
        return this.formulario.get("codigoLogradouro");
    }
    set codigoLogradouro(codigoLogradouro) {
        this.formulario.get("codigoLogradouro").setValue(codigoLogradouro);
    }

    get logradouro() {
        return this.formulario.get("logradouro");
    }
    set logradouro(logradouro) {
        this.formulario.get("logradouro").setValue(logradouro);
    }

    get numero() {
        return this.formulario.get("numero");
    }
    set numero(numero) {
        this.formulario.get("numero").setValue(numero);
    }

    get complemento() {
        return this.formulario.get("complemento");
    }
    set complemento(complemento) {
        this.formulario.get("complemento").setValue(complemento);
    }

    get codigoBairro() {
        return this.formulario.get("codigoBairro");
    }
    set codigoBairro(codigoBairro) {
        this.formulario.get("codigoBairro").setValue(codigoBairro);
    }

    get bairro() {
        return this.formulario.get("bairro");
    }
    set bairro(bairro) {
        this.formulario.get("bairro").setValue(bairro);
    }

    get cidade() {
        return this.formulario.get("cidade");
    }
    set cidade(cidade) {
        this.formulario.get("cidade").setValue(cidade);
    }

    get estado() {
        return this.formulario.get("estado");
    }
    set estado(estado) {
        this.formulario.get("estado").setValue(estado);
    }

    get pais() {
        return this.formulario.get("pais");
    }
    set pais(pais) {
        this.formulario.get("pais").setValue(pais);
    }

    get pontoReferencia() {
        return this.formulario.get("pontoReferencia");
    }
    set pontoReferencia(pontoReferencia) {
        this.formulario.get("pontoReferencia").setValue(pontoReferencia);
    }

    get latitude() {
        return this.formulario.get("latitude");
    }
    set latitude(latitude) {
        this.formulario.get("latitude").setValue(latitude);
    }

    get longitude() {
        return this.formulario.get("longitude");
    }
    set longitude(longitude) {
        this.formulario.get("longitude").setValue(longitude);
    }

    get area() {
        return this.formulario.get("area");
    }
    set area(area) {
        this.formulario.get("area").setValue(area);
    }

    get tipoEndereco() {
        return this.formulario.get("tipoEndereco");
    }
    set tipoEndereco(tipoEndereco) {
        this.formulario.get("tipoEndereco").setValue(tipoEndereco);
    }

    get tipoUrbanoRural() {
        return this.formulario.get("tipoUrbanoRural");
    }
    set tipoUrbanoRural(tipoUrbanoRural) {
        this.formulario.get("tipoUrbanoRural").setValue(tipoUrbanoRural);
    }

    get tipoBusca() {
        return this.formulario.get("tipoBusca");
    }
    set tipoBusca(tipoBusca) {
        this.formulario.get("tipoBusca").setValue(tipoBusca);
    }

    get estadoBusca() {
        return this.formulario.get("estadoBusca");
    }
    set estadoBusca(estadoBusca) {
        this.formulario.get("estadoBusca").setValue(estadoBusca);
    }

    get tipoBuscaValor() {
        return this.formulario.get("tipoBuscaValor");
    }
    set tipoBuscaValor(tipoBuscaValor) {
        this.formulario.get("tipoBuscaValor").setValue(tipoBuscaValor);
    }

    get baseUtilizada() {
        return this.formulario.get("baseUtilizada");
    }
    set baseUtilizada(baseUtilizada) {
        this.formulario.get("baseUtilizada").setValue(baseUtilizada);
    }

    get semRegularizacao() {
        return this.formulario.get("semRegularizacao");
    }
    set semRegularizacao(semRegularizacao) {
        this.formulario.get("semRegularizacao").setValue(semRegularizacao);
    }

}