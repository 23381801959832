import { CalendarioFiscalData } from './calendarioFiscalData';
export class CalendarioFiscal {
    cliente: string;
    codigo: string;
    exercicio: string;
    identificacao: string;
    calendarioFiscalDatas: Array<CalendarioFiscalData>;
    loginCadastro: string;
    adicionado: string;
    status: string;
    observacao: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.exercicio = "";
        this.identificacao = "";
        this.calendarioFiscalDatas = new Array<CalendarioFiscalData>();
        this.loginCadastro = "";
        this.adicionado = "";
        this.status = "";
        this.observacao = "";
    }
}