<div class="container-principal">
    <div *ngFor="let menu of menus; let i = index"
        class="col-md-12 boxInicial2 {{ menu.class }}"
        [class.hidden]="tipoMenu != menu.menu && tipoMenu != ''">
        <div class="row" style="padding-top:25px;">
            <div class="form-row col-md-12">
                <p class="divider">
                    {{ menu.titulo }}
                </p>
            </div>
            <div class="form-row col-md-12">
                <p class="divider divider-observacao">
                    {{ menu.subTitulo }}
                </p>
            </div>
        </div>
        <div class="row" style="padding-top:25px;">
            <div class="form-row col-md-12 justify-content-center layout-spacing animaHover">
                <div *ngFor="let item of menu.itens; let i = index"
                    [routerLink]="'/' + item.rota"
                    [id]="item.nome"
                    class="animated fadeInUp col-md-3 p-4">
                    <span>
                        <svg class="icone">
                            <use
                              attr.xlink:href="#{{ item.icone }}"
                            ></use>
                        </svg>
                    </span>
                    <p class="texto-icone">{{ item.descricao }}</p>
                </div>
            </div>
        </div>
    </div>
</div>   
