<div class="card">
    <div *ngIf="protocoloAcompanhamento == null" class="card-body">
        <div class="row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocoloAcompanhamento != null" class="card-body p-0">
        <div class="row justify-content-center align-items-center header-atividades mb-3">
            <div class="row col-4 justify-content-center align-items-center" >
                <p class="card-text titulo-atividades">
                    <strong>Serviço</strong>
                </p>
             </div>
            <div class="row col-4 justify-content-center align-items-center" >
                <p class="card-text titulo-atividades">
                    <strong>Fase</strong>
                </p>
            </div>
            <div class="row col-4 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Situação</strong>
                </p>
            </div>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="row col-4 justify-content-center align-items-center">
                <p class="text-center">
                    <svg 
                        class="iconeGrid">
                        <use
                        attr.xlink:href="#{{ protocoloAcompanhamento.servicoIcone }}"
                        ></use>
                    </svg><br>
                    {{ protocoloAcompanhamento.servicoDescricao }} 
                </p>
            </div>
            <div class="row col-4 justify-content-center align-items-center">
                <p class="text-center">
                    {{ protocoloAcompanhamento.faseDescricao }}
                </p>
            </div>
            <div class="row col-4 justify-content-center align-items-center">
                <p class="text-center">
                    <svg class="iconeGrid {{ protocoloAcompanhamento.situacaoClasse }}">
                        <use
                          attr.xlink:href="assets/sprite.svg#{{ protocoloAcompanhamento.situacaoIcone }}"
                        ></use>
                    </svg><br>
                    {{ protocoloAcompanhamento.situacaoDescricao }}
                </p>
            </div>
        </div>
    </div>
</div>