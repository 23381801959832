import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { Router, ActivatedRoute } from "@angular/router";
import { Constantes } from "src/app/model/constantes";
import { Servico } from "src/app/model/servico";
import { FormIdentificacaoComponent } from "src/app/pages/outros/form-identificacao/form-identificacao.component";
import { FormRequerenteComponent } from "src/app/pages/outros/form-requerente/form-requerente.component";
import { FormIdentificacaoNegocioComponent } from "src/app/pages/outros/form-identificacao-negocio/form-identificacao-negocio.component";
import { FormQuestionarioComponent } from "src/app/pages/outros/form-questionario/form-questionario.component";
import { FormResumoComponent } from "src/app/pages/outros/form-resumo/form-resumo.component";
import { Processo } from "src/app/model/processo";
import { Configuracao } from "src/app/model/configuracao";
import { FormAtividadeComponent } from "src/app/pages/outros/form-atividade/form-atividade.component";
import { EmpresaService } from "src/app/services/empresa/empresa.service";
import { Empresa } from "src/app/model/empresa";
import Swal from "sweetalert2";
import { take, throwIfEmpty } from "rxjs/operators";
import { Protocolo } from "src/app/model/protocolo";
import { Atividade } from "src/app/model/atividade";
import { Documento } from "src/app/model/documento";
import { Pergunta } from "src/app/model/pergunta";
import { FormLocalizacaoComponent } from "src/app/pages/outros/form-localizacao/form-localizacao.component";
import { FormEncerramentoComponent } from '../../../outros/form-encerramento/form-encerramento.component';
import { ProtocoloService } from '../../../../services/operacao/protocolo.service';
import { ComprovanteEncerramento } from '../../../../components/pdf/comprovanteEncerramento';
import { FormTipoNegocioComponent } from '../../../outros/form-tipo-negocio/form-tipo-negocio.component';
import { ConfiguracaoService } from '../../../../services/configuracao/configuracao.service';
import { FormIdentificacaoRequerenteComponent } from '../../../outros/form-identificacao-requerente/form-identificacao-requerente.component';
import { ThrowStmt } from "@angular/compiler";
import * as moment from 'moment';

@Component({
    selector: "app-baixa-oficio",
    templateUrl: "./baixa-oficio.component.html"
})
export class BaixaOficioComponent implements OnInit {
    
    empresa: Empresa;
    tipoDescricao: string;
    informativo: string;
    stepper: MatStepper;
    passoAnterior: number;
    passoAtual: number;
    configuracao: Configuracao;
    servicos: Array<Servico>;
    tipoProcesso = Constantes.PROCESSO;
    processo: Processo;
    protocolo: Protocolo;

    emitirErroIdentificacao: boolean;

    // Formularios
    formBaixa: FormGroup;
    formularioIdentificacaoRequerente: FormGroup;
    formularioTipoNegocio: FormGroup;
    formularioIdentificacao: FormGroup;
    formularioEncerramento: FormGroup;
    formularioAtividade: FormGroup;
    formularioQuestionarioEncerramento: FormGroup;

    @ViewChild(FormIdentificacaoRequerenteComponent)
    formIdentificacaoRequerente: FormIdentificacaoRequerenteComponent;

    @ViewChild(FormTipoNegocioComponent)
    formTipoNegocio: FormTipoNegocioComponent;

    @ViewChild(FormIdentificacaoComponent)
    formIdentificacao: FormIdentificacaoComponent;

    @ViewChild(FormEncerramentoComponent)
    formEncerramento: FormEncerramentoComponent;

    @ViewChild(FormAtividadeComponent)
    formAtividade: FormAtividadeComponent;
    
    @ViewChild(FormLocalizacaoComponent)
    formLocalizacao: FormLocalizacaoComponent;

    @ViewChild("formQuestionarioEncerramento")
    formQuestionarioEncerramento: FormQuestionarioComponent;

    @ViewChild(FormResumoComponent)
    formResumo: FormResumoComponent;

    // Botões
    btnAnteriorLocalizacao: string = "Anterior";
    btnAvancarLocalizacao: string = "Avançar";

    mensagemErro: string;
    
    constructor(
        private router: Router, 
        private activatedRoute: ActivatedRoute,
        private protocoloService: ProtocoloService,
        private empresaService: EmpresaService,
        private configuracaoService: ConfiguracaoService,
        private formBuilder: FormBuilder) {
        
        this.informativo = Constantes.INFORMATIVO_BAIXA_OFICIO[0];
        this.empresa = new Empresa();

        this.formBaixa = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
        });

        this.processo = new Processo();

        this.protocolo = new Protocolo();
        this.protocolo.cliente = localStorage.getItem("pId");
        
        setTimeout(() => {
            this.formularioIdentificacaoRequerente = this.formIdentificacaoRequerente.formulario;
            this.formularioTipoNegocio = this.formTipoNegocio.formulario;
            this.formularioIdentificacao = this.formIdentificacao.formulario;
            this.formularioEncerramento = this.formEncerramento.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionarioEncerramento = this.formQuestionarioEncerramento.formulario;
        }, 0);
        
        this.protocolo.processo = this.processo;
    }

    ngOnInit(): void {
        this.passoAnterior = 0;
        this.passoAtual = 0;
        this.tipoDescricao = this.activatedRoute.snapshot.data["tipoDescricao"];
    }

    verificaValidacaoFormulario(formGroup: FormGroup) {        
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });
    }

    buscarEmpresa() {
        let cnpj = "";
        
        this.protocolo.registro.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj)
                cnpj = documento.numero;
        });

        if (cnpj.replace(/\D/g,"") == this.formIdentificacao.cpfCnpjEmpresa.value.replace(/\D/g,"")
            && this.protocolo.inscricao.replace(/\D/g,"") == this.formIdentificacao.im.value.replace(/\D/g,"")) {
                this.stepper.next();
                return false;
        }

        this.empresaService
             .buscarPorInscricaoParaBaixa(
                 localStorage.getItem("pId"),
                 this.formIdentificacao.im.value.replace(/\D/g,""),
                 this.formIdentificacao.cpfCnpjEmpresa.value.replace(/\D/g,""))
             .pipe(take(1))
             .subscribe( (retorno: Empresa) => {
                 this.empresa = retorno;
                 if (this.empresa.status == Constantes.STATUS_EMPRESA_BAIXA.Empresa_Encerrada) {
                    Swal.fire("", `Inscrição Municipal está baixada desde ${this.empresa.encerramento.data}.`, "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA_BAIXA.Cadastro_Bloqueado) {
                    Swal.fire("", "CADASTRO BLOQUEADO devido a não realização do RECADASTRAMENTO MOBILIÁRIO OBRIGATÓRIO, de acordo com Decreto n°363 de 12/01/2010.\n\nPara regularizar a situação cadastral, o interessado deverá se recadastrar clicando no ícone  RECADASTRAMENTO GERAL, preenchendo o formulário eletrônico.", "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA_BAIXA.Possui_Alteracao_Ou_encerramento) {
                    Swal.fire("", "Inscrição Municipal está em regularização, aguarde a conclusão ou cancele o processo.", "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA_BAIXA.Nao_Permitido) {
                    Swal.fire("", "Não é possível encerrar a Empresa / Autônomo.", "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA_BAIXA.Inscricao_Nao_Encontrada) {
                    Swal.fire("", "Inscrição Municipal não localizada na base de dados Municipal, verifique a informação e tente novamente.", "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA_BAIXA.Cnpj_Cpf_Nao_Conferem || this.empresa.status == Constantes.STATUS_EMPRESA_BAIXA.Permitido_Porem_Cnpj_Cpf_Nao_Conferem) {
                    Swal.fire("", "CNPJ/CPF informado não confere com o CNPJ/CPF do negócio.", "error");
                    return false;
                }
                if (this.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Empresa  && this.empresa.registro.tipoPessoa != Constantes.TIPO_PESSOA.Juridica) {
                    Swal.fire("", "Não é possível encerrar a inscrição porque não é do tipo EMPRESA.", "error");
                    return false;
                }
                if (this.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Mei && this.empresa.registro.tipoPessoa != Constantes.TIPO_EMPRESA.Mei) {
                    Swal.fire("", "Não é possível encerrar a inscrição porque não é do tipo MEI.", "error");
                    return false;
                }
                if (this.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Autonomo && this.empresa.registro.tipoPessoa != Constantes.TIPO_PESSOA.Fisica) {
                    Swal.fire("", "Não é possível encerrar a inscrição porque não é do tipo AUTÔNOMO.", "error");
                    return false;
                }
                if (this.empresa.status == Constantes.STATUS_EMPRESA_BAIXA.Permitido) { 
                    this.stepper.next();
                    Swal.fire("", "Inscrição Municipal localizada na base de dados Municipal.", "success");
                    this.preencherCamposAtividade();
                }
            })
    }

    preencherCamposAtividade() {
        this.formAtividade.atividadesSelecionadas = new Array<Atividade>();
        this.formAtividade.atividadesSelecionadas = this.empresa.viabilidade.atividadesExercidas.concat(this.empresa.viabilidade.atividadesNaoExercidas);
        this.formAtividade.atividadesOriginaisExercidas = this.empresa.viabilidade.atividadesExercidas;
    }

    avancarPassoIdentificacao(passos) {

        if (this.stepper == null) {
            this.stepper = passos;
        } 
        
        this.formTipoNegocio.controleValidade.setValue(true);
        this.formularioTipoNegocio = this.formTipoNegocio.formulario;
        this.formAtividade.tipoEmpresa = this.formTipoNegocio.tipoNegocio.value;

        this.formResumo.nomeUsuario = this.formIdentificacaoRequerente.nomeUsuario;
        this.formResumo.documentoUsuario = this.formIdentificacaoRequerente.documentoUsuario;
        this.formResumo.emailUsuario = this.formIdentificacaoRequerente.emailUsuario;
        this.formResumo.dataAtual = this.formIdentificacaoRequerente.dataAtual;
        this.formResumo.dadosAuditor = true;

        this.formIdentificacao.controleValidade.setValue(true);
        this.formularioIdentificacao = this.formIdentificacao.formulario;
        this.verificaValidacaoFormulario(this.formularioIdentificacao);
         if (this.formularioIdentificacao.valid) {
             this.emitirErroIdentificacao = false;
             if(this.formTipoNegocio.tipoNegocio.value == ''){
                Swal.fire("", "Selecione o tipo do negócio.", "error");
                return false;    
             }
             this.buscarEmpresa();
        }
         else {
             this.formTipoNegocio.controleValidade.setValue("");
             this.formIdentificacao.controleValidade.setValue("");
             let mensagemErro = "";
            
             /*if (this.formularioRequerente.invalid) {
                 mensagemErro += "Favor corrigir os campos nos dados do requerente e/ou local de registro da empresa.<br>"
             }*/    
             
             if (this.formularioTipoNegocio.invalid) {
                mensagemErro += "Favor corrigir os campos de identificação da empresa.<br>"
             }
             if (this.formularioIdentificacao.invalid) {
                 mensagemErro += "Favor corrigir os campos de identificação da empresa.<br>"
             }

             Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

             this.emitirErroIdentificacao = true;
             this.mensagemErro = mensagemErro;

         }
         
    }

    avancarPassoAtividade() {
        if (this.formEncerramento.validarFormulario() && this.formAtividade.validarFormulario())
            this.stepper.next();

    }

    avancarPassoQuestionario() {
        this.verificaValidacaoFormulario(this.formQuestionarioEncerramento.formulario);
        
        if (this.formQuestionarioEncerramento.validarFormulario()) {
            this.stepper.next();
        }
    }

    renomearBotaoLocalizacao() {
        this.btnAnteriorLocalizacao = "Anterior";
        this.btnAvancarLocalizacao = "Avançar";

        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (!this.formLocalizacao.preenchimentoInicial) {
                this.btnAnteriorLocalizacao = "Cancelar";
                this.btnAvancarLocalizacao = "Confirmar";
            }
        }
    }

    mudancaPasso(event, passos) {
        
        if (this.stepper == null) {
            this.stepper = passos;
        } 

        if (this.passoAtual == 0) {
            let dataAtual  = new Date();
            this.formIdentificacaoRequerente.dataAtual = moment(dataAtual).format("DD/MM/YYYY HH:mm");
            this.formularioTipoNegocio = this.formTipoNegocio.formulario;
            this.formularioIdentificacao = this.formIdentificacao.formulario;
            this.formularioEncerramento = this.formEncerramento.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionarioEncerramento = this.formQuestionarioEncerramento.formulario;
        }
        
        if ( this.passoAtual == 1 ) {
             this.formAtividade.validarFormulario();
        }
        
        if (event.selectedIndex == 0) {
    
             this.formIdentificacao.controleValidade.setValue("");
             let dataAtual  = new Date();
             this.formIdentificacaoRequerente.dataAtual = moment(dataAtual).format("DD/MM/YYYY HH:mm");

             this.formIdentificacao.im.setValue("");
             this.formIdentificacao.cpfCnpjEmpresa.setValue("");
             this.formEncerramento.limparCampos();
             this.formAtividade.limpar();
             this.formQuestionarioEncerramento.limpar();
            
        }
        
        if ( event.selectedIndex == 1) {
            this.formAtividade.controleValidade.setValue("");
        }
        
        if (event.selectedIndex == 2) {
             // Questionário de Encerramento
            this.formQuestionarioEncerramento.controleValidade.setValue("");
    
            let perguntaEncerramento = new Pergunta();
            perguntaEncerramento.cliente = localStorage.getItem("pId");
            perguntaEncerramento.tipo = Constantes.TIPO_DE_PERGUNTA.Encerramento;
            perguntaEncerramento.atividades = this.formAtividade.atividadesSelecionadas;
            
            this.formQuestionarioEncerramento.carregarPerguntas(perguntaEncerramento);
            this.formularioQuestionarioEncerramento = this.formQuestionarioEncerramento.formulario;
        }
        
        this.informativo = Constantes.INFORMATIVO_BAIXA_OFICIO[event.selectedIndex];
        this.passoAtual = event.selectedIndex;
        this.passoAnterior = event.previouslySelectedIndex;
        
        this.montarBaixa(event);
    }

    montarConfiguracao() {
        if (this.formTipoNegocio.tipoNegocio != null) {
            if (this.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Baixa_Autonomo) {
                this.configuracaoService
                    .buscarConfiguracaoPorServicoEFase(
                        Constantes.SERVICO_DE_ACOMPANHAMENTO.Baixa_De_Autonomo_Por_Oficio,
                        Constantes.FASE_DE_ACOMPANHAMENTO.Baixa
                    )
                    .pipe(take(1))
                    .subscribe( retorno => {
                        this.configuracao = retorno;
                        this.montarObjetoProcesso(this.configuracao.processos[0]);
                    })
            }
            else if (this.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Baixa_Empresa || this.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Baixa_Mei) {
                this.configuracaoService
                    .buscarConfiguracaoPorServicoEFase(
                        Constantes.SERVICO_DE_ACOMPANHAMENTO.Baixa_De_Empresa_Mei_Por_Oficio,
                        Constantes.FASE_DE_ACOMPANHAMENTO.Baixa
                    )
                    .pipe(take(1))
                    .subscribe( retorno => {
                        this.configuracao = retorno;
                        this.montarObjetoProcesso(this.configuracao.processos[0]);
                    })
            }
        }
    }

    montarObjetoProcesso(processo) {
        this.processo.cliente = processo.cliente;
        this.processo.codigo = processo.codigo;
        this.processo.descricao = processo.descricao;
        this.processo.protocolo = processo.protocolo;
        this.processo.tipo = processo.tipo;
        this.processo.servicos = processo.servicos;
        this.formEncerramento.motivosEncerramento = this.configuracao.motivosEncerramento;
    }

    montarBaixa(event) {
        if ( event.previouslySelectedIndex == 0) {
            this.montarConfiguracao();
            this.protocolo.data = new Date().toLocaleDateString();
            this.formIdentificacao.preencher();
            this.protocolo.registro = this.formIdentificacao.registro;        
        }
        else if (event.previouslySelectedIndex == 1) {
            this.formEncerramento.preencher();
            this.protocolo.encerramento = this.formEncerramento.encerramento;

            this.formAtividade.preencher();
            this.protocolo.viabilidade.atividadesExercidas = this.formAtividade.atividadesExercidas;
            this.protocolo.viabilidade.atividadesNaoExercidas = this.formAtividade.atividadesNaoExercidas;

            // ADICIONANDO ENDEREÇO PARA VISUALIZAÇÃO NO RESUMO
            this.empresa.viabilidade.enderecos.forEach( endereco => {
                if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Empresarial) {
                    this.formLocalizacao.preenchimentoInicial= false;
                    this.formLocalizacao.preencherEnderecoEmpresarial(endereco);
                }
                if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Correspondencia) {
                    this.formLocalizacao.preencherEnderecoCorrespondencia(endereco);
                }
            })

            this.protocolo.viabilidade.enderecos = [
                this.formLocalizacao.enderecoEmpresarial,
                this.formLocalizacao.enderecoCorrespondencia,
            ];
            this.formResumo.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;   
        }
    
        else if (event.previouslySelectedIndex == 2) {
            this.formQuestionarioEncerramento.preencher();                
            this.protocolo.encerramento.perguntasUsuario = this.formQuestionarioEncerramento.perguntasRespostas;
        }
        
        this.formResumo.tipoEmpresa = this.protocolo.registro.tipoPessoa == "J" ? Constantes.TIPO_EMPRESA.Baixa_Empresa : Constantes.TIPO_EMPRESA.Baixa_Autonomo ;
        this.formResumo.empresa = this.empresa;
        this.formResumo.protocolo = this.protocolo;
        this.formResumo.preencher();
        
    }
    
    gerarBaixa() {
        this.formResumo.preencherAnexos();
        
        if (!this.formResumo.termoDeAceite.value) {
            Swal.fire("", "Para que a solicitação seja finalizada ,você devera estar de acordo com o termo de responsabilidade.", "info")
            return false;
        }

        this.protocoloService
            .gerarEncerramentoPorOficio(this.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    willClose: () => {
                        this.router.navigate(["/operacao"], {relativeTo: this.activatedRoute});
                    }
                })
            });
    }

}