import { Documento } from "./documento";
import { SocioRegra } from "./socioRegra";

export class NaturezaJuridica {
    cliente: string;
    codigoServico: string;
    codigo: string;
    nome: string;
    documentos: Array<Documento>;
    possuiCapitalSocial: boolean;
    obrigatorioContador: boolean;
    socioRegras: Array<SocioRegra>;

    constructor() {
        this.cliente = "";
        this.codigoServico = "";
        this.codigo = "";
        this.nome = "";
        this.documentos = new Array<Documento>();
        this.possuiCapitalSocial = false;
        this.obrigatorioContador = false;
        this.socioRegras = new Array<SocioRegra>();
    }
}