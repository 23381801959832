import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { TaxaService } from 'src/app/services/taxa/taxa.service';
import { TaxaLancamento } from 'src/app/model/taxaLancamento';
import { TaxaLancamentoParcela } from 'src/app/model/taxaLancamentoParcela';
import { Constantes } from 'src/app/model/constantes';
import { MascaraPipe } from 'src/app/pipes/mascara.pipe';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormValidator } from '../../../../components/validators/form-validator';


@Component({
    selector: "app-acordo",
    templateUrl: "./acordo.component.html",
    styleUrls: ["./../gerenciamento-taxa.component.scss"]
})
export class AcordoComponent implements OnInit {
    
    @Output() acaoMenuAtendimento = new EventEmitter();

    formulario: FormGroup;

    emitirErro: boolean;
    mensagemErro: string;

    taxaLancamentos: Array<TaxaLancamento>;
    taxaLancamentoParcelas: Array<TaxaLancamentoParcela>;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private mascara: MascaraPipe,
        private taxaService: TaxaService) {

        this.formulario = this.formBuilder.group({
            cliente: [""],
            codigo: [""],
            protocolo: ["", [Validators.required]],
            inscricao: ["", [Validators.required]],
            identificacao: ["", [Validators.required, Validators.maxLength(200)]],
            status: ["1", [Validators.required]],
            exercicio: ["", [Validators.required]],
            codigoOrgao: ['', [Validators.required]],
            codigoRequisitoFase: ['', [Validators.required]],
            tipoAcao: ['', [Validators.required]],
            indiceCodigo: ["", [Validators.required]],
            indiceEmbasamento: ["", { disabled: true }, [Validators.required]],
            indiceValor: ["00,000", { disabled: true }, [Validators.required]],
            indiceData: ["", { disabled: true }, [Validators.required, Validators.minLength(8), FormValidator.dataValida()]],
            loteTipoVencimento: ["1", [Validators.required]],
            loteReferencia: ["", [Validators.required, Validators.minLength(8), FormValidator.dataValida()]],
            lotePrimeiroVencimento: ["", [Validators.required, Validators.maxLength(3)]],
            loteDemaisVencimentos: ["", [Validators.required, Validators.maxLength(3)]],
            loteNumeroParcelas: ["", [Validators.required, Validators.maxLength(3)]],
            loteParcelaMinima: ["00,000", [Validators.required, Validators.maxLength(10)]],
            imTipoVencimento: ["1", [Validators.required]],
            imReferencia: ["", [Validators.required, Validators.minLength(8), FormValidator.dataValida()]],
            imPrimeiroVencimento: ["", [Validators.required, Validators.maxLength(3)]],
            imDemaisVencimentos: ["", [Validators.required, Validators.maxLength(3)]],
            imNumeroParcelas: ["", [Validators.required, Validators.maxLength(3)]],
            imParcelaMinima: ["00,000", [Validators.required, Validators.maxLength(10)]],
            tipoMora: ["$"],
            valorMora: ["00,000", [Validators.required, Validators.maxLength(10)]],
            formaCobrancaMora: ["U"],
            tipoJuros: ["$"],
            valorJuros: ["00,000", [Validators.required, Validators.maxLength(10)]],
            formaCobrancaJuros: ["U"],
            tipoDesconto: ["$"],
            valorDesconto: ["00,000", [Validators.required, Validators.maxLength(10)]],
            formaCobrancaDesconto: ["U"],
            qtdDiasRecebimento: ["", [Validators.required, Validators.maxLength(3)]],
            qtdDiasDesconto: ["", [Validators.required, Validators.maxLength(3)]],
    
    
            indexRegra: [""],
            vincular: ["Porte"],
            porte: ["J"],
            pergunta: [""],
            resposta1: ["", [Validators.maxLength(200)]],
            resposta2: ["", [Validators.maxLength(200)]],
            condicao: [""],
            acao: ["+"],
            tipo: ["$"],
            valorMoeda: ["00,000"],
            valorPorcentagem: [""],
            valorQuantidade: [""],
        });
            
    }

    ngOnInit(): void {
    }

    acaoBotaoVoltar(){
        this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Cancelar });
    }

    // -------------------------------------------------------------------------
    // Ações para salvar os dados - Início
    // -------------------------------------------------------------------------
    acaoBotaoSalvar() {
        // if (this.validarFormulario()) {
        
        //   this.preencherTaxa();

        //   this.taxaService
        //     .salvar(this.taxa)
        //     .pipe(take(1))
        //     .subscribe(retorno => {
        //       Swal.fire({
        //         title: retorno.titulo,
        //         html: retorno.mensagem,
        //         icon: retorno.status,
        //         willClose: () => {
        //           if (this.acaoAtendimento = Constantes.ACAO_ATENDIMENTO.Replicar) {
        //             this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
        //           }
        //           if (retorno.redirecionamento != null && retorno.redirecionamento != "") {
        //             this.router.navigate([retorno.redirecionamento], {relativeTo: this.activatedRoute});
        //           }
        //         }
        //       })
        //     });
        // }

    }

    validarFormulario() {
        // this.formulario.markAllAsTouched();
        
        // if (this.formulario.invalid) {
        //   let mensagemErro = "";

        //   Swal.fire("", "Corrigir os erros nos campos marcados na página.", "error");

        //   this.emitirErro = true;
        //   this.mensagemErro = mensagemErro;

        //   return false;
        // }

        // this.emitirErro = false;
        // this.mensagemErro = "";

        // return true;
    }

    limpar() {
        this.cliente.reset();
        this.codigo.reset();
        this.protocolo.reset();
        this.inscricao.reset();

        this.cliente.setValue("");
        this.codigo.setValue("");
        this.protocolo.setValue("");
        this.inscricao.setValue("");
        
    }

    get cliente() {
        return this.formulario.get("cliente");
    }
    set cliente(cliente) {
        this.formulario.get("cliente").setValue(cliente);
    }
    
    get codigo() {
        return this.formulario.get("codigo");
    }
    set codigo(codigo) {
        this.formulario.get("codigo").setValue(codigo);
    }
    
    get protocolo() {
        return this.formulario.get("protocolo");
    }
    set protocolo(protocolo) {
        this.formulario.get("protocolo").setValue(protocolo);
    }
    
    get inscricao() {
        return this.formulario.get("inscricao");
    }
    set inscricao(inscricao) {
        this.formulario.get("inscricao").setValue(inscricao);
    }


}
