import { Component, OnInit, Input } from '@angular/core';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { ProtocoloAcompanhamento } from 'src/app/model/protocoloAcompanhamento';
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ProtocoloService } from 'src/app/services/operacao/protocolo.service';
import { take } from 'rxjs/operators';
import { RedeSim } from '../../../../model/redeSim';
import { Protocolo } from '../../../../model/protocolo';

@Component({
  selector: 'app-informacao-protocolos',
  templateUrl: './informacao-protocolos.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoProtocolosComponent implements OnInit {

  @Input() semCardHeader: boolean = false;
  protocolos: Array<ProtocoloAcompanhamento>;
  redesim: RedeSim;
  
  constructor(
    private arquivoService: ArquivoService,
    private protocoloService: ProtocoloService,
    public dialog: MatDialog) {

    this.protocolos = new Array<ProtocoloAcompanhamento>();
    this.redesim = new RedeSim;
  }

  ngOnInit(): void {
  }

  abrirQuestionario(protocolo) {
    let dados = [];
    this.protocoloService
      .detalharApenasPerguntas(localStorage.getItem("pId"), protocolo)
      .pipe(take(1))
      .subscribe( retorno => {
        dados.push("");
        dados.push(true);
        dados.push(retorno);
        this.openDialog("questionario", dados);
      });
  }

  abrirRedeSim(protocolo){

    var protocoloAuxiliar  = new Protocolo();
    protocoloAuxiliar.redeSim = this.redesim;
    protocoloAuxiliar.protocolo = protocolo;
    let dados = [];
    dados.push(protocoloAuxiliar);
    dados.push("");
    dados.push(false);
    dados.push(true);
    this.openDialog("redeSim", dados);
   
  }

  openDialog(componente, dados) {

    const dialogRef = this.dialog.open(IcadModalComponent, {
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        maxHeight: 'none',
        closeOnNavigation: true,
        data: {
            componente: componente,
            dados: dados
        }
    });

    // dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
  }
}
