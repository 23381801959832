import { Atividade } from "./atividade";
import { Resposta } from "./resposta";
import { Orgao } from './orgao';
import { Atendimento } from "./atendimento";

export class Pergunta {
    cliente: string;
    codigo: string;
    codigoAutomacao: string;
    criticada: string;
    nome: string;
    atividades: Array<Atividade>;
    tipo: string;
    fixa: string;
    grupo: string;
    grupoDescricao: string;
    obrigatorio: string;
    processo: string;
    respostas: Array<Resposta>;
    status: string;
    ordem: number;
    staPreencheObrig: string;
    codPerguntaInter: string;
    staImprimeAlvara: string;
    dataFim: string;
    dataInicio: string;
    tpProcesso: string;
    orgao: Orgao;
    perguntaDeAuditor: boolean;
    atendimentos: Array<Atendimento>;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.codigoAutomacao = "";
        this.criticada = "";
        this.nome = "";
        this.atividades = new Array<Atividade>();
        this.tipo = "";
        this.fixa = "";
        this.grupo = "";
        this.grupoDescricao = "";
        this.obrigatorio = "";
        this.processo = "";
        this.respostas = new Array<Resposta>();
        this.status = "";
        this.ordem = 0;
        this.staPreencheObrig = "";
        this.codPerguntaInter = "";
        this.staImprimeAlvara = "";
        this.dataFim = "";
        this.dataInicio = "";
        this.tpProcesso = "";
        this.orgao = new Orgao();
        this.perguntaDeAuditor = false;
        this.atendimentos = new Array<Atendimento>();
    }
}