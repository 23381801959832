<div class="container-principal">
    <div class="row">
        <div class="col-12 d-flex justify-content-center mt-4">
            <h4>
                {{ informativo }}
            </h4>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-12 mt-4">
            <ng-template>Autenticidade de Alvará</ng-template>
            <app-form-identificacao></app-form-identificacao>
        </div>
    </div>
    <div class="row">
        <div class="row col-12 justify-content-center mb-5">
            <button 
                type="button"
                (click)="verificaAutenticidadeAlvara()"
                class="btn btn-success"
            >
                Verificar Autenticidade
            </button>
        </div>
    </div>
    <div class="row" [hidden]="!empresaLocalizada">
        <div class="col-12">
            <app-informacao-identificacao [tipoEmpresa]="tipoEmpresa"></app-informacao-identificacao>
        </div>
    </div>
    <div class="row" [hidden]="!empresaLocalizada">
        <div class="col-12">
            <app-informacao-documento></app-informacao-documento>
        </div>
    </div>
</div>
