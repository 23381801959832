import { Atendimento } from './atendimento';
import { PerguntaResposta } from './perguntaResposta';
import { MotivoEncerramento } from './motivoEncerramento';
import { Observacao } from './observacao';

export class Encerramento {
    cliente: string;
    protocolo: string;
    tipoEmpresa: string;
    inscricao: string;
    codigo: string;
    motivosEncerramento: Array<MotivoEncerramento>;
    descricao: string;
    data: string;
    atendimentos: Array<Atendimento>;
    perguntasUsuario: PerguntaResposta;
    perguntasAuditor: PerguntaResposta;
    observacoes: Array<Observacao>;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.tipoEmpresa = "";
        this.inscricao = "";
        this.codigo = "";
        this.motivosEncerramento = new Array<MotivoEncerramento>();
        this.descricao = "";
        this.data = "";
        this.atendimentos = new Array<Atendimento>();
        this.perguntasUsuario = new PerguntaResposta();
        this.perguntasAuditor = new PerguntaResposta();
        this.observacoes = new Array<Observacao>();
    }
}