import { RelatorioComponent } from './../../gestao/indicadores/home/relatorio.component';
import { ArquivoService } from './../../../services/arquivo/arquivo.service';
import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { take, takeUntil } from "rxjs/operators";

import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { formatToCPFOrCNPJ, formatToPhone, isCPF, isCNPJ } from 'brazilian-values';
import { Constantes } from "src/app/model/constantes";
import { FormValidator } from "src/app/components/validators/form-validator";

import { LoginService } from 'src/app/services/login/login.service';
import { Subject } from 'rxjs';
import { Login } from 'src/app/model/login';
import { toBase64String } from '@angular/compiler/src/output/source_map';


@Component({
    selector: "app-form-usuario",
    templateUrl: "./form-usuario.component.html"

})

export class FormUsuarioComponent implements OnInit {
    
    private unsubscribe = new Subject<void>();
    @Input() dados: Array<any>;
    fotoPerfil: Set<File>;
    formData: FormData;
    listaDeProtocolos: Set<string>;
    loginService: LoginService;
    formulario: FormGroup;
    estados = Constantes.ESTADOS;
    verificaCnpjCfp = false;
    fotoPerfilBinario: string = "assets/img/placeholder/sem-foto.jpg";
    @ViewChild("inputCpfCnpj") inputCpfCnpj: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private arquivoService: ArquivoService
        ) {
        
        this.formulario = this.formBuilder.group({
            cpfCnpj: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(18), FormValidator.cpfCnpjValido()]],
            nome: ['', [Validators.required]],
            senha: ['', [Validators.required, Validators.minLength(6)]],
            confirmarSenha: ['', [Validators.required, FormValidator.equalsTo('senha')]],
            email: ['', [Validators.required, Validators.email]],
            telefoneFixo: [''],
            telefoneComercial: [''],
            telefoneCelular: ['', [Validators.required, Validators.minLength(15)]],
            possuiCrc: ['N'],
            numeroCrc: [''],
            estadoCrc: [''],
            classificacaoCrc: [''],
            tipoCrc: [''],
            possuiCrea: ['N'],
            numeroCrea: [''],
            estadoCrea: [''],
            possuiCau: ['N'],
            numeroCau: [''],
            estadoCau: [''],
            classificacaoCau: [''],
            emissaoCau: ['', [Validators.minLength(8), FormValidator.dataValida()]],
        });

          if(this.estaAutenticado()){
            this.senha.clearValidators();
            this.confirmarSenha.clearValidators();
            this.cpfCnpj.clearValidators();
          }
    }
    
    ngOnInit(): void {
      this.fotoPerfilBinario = this.authenticationService.currentFotoValue != "" && this.authenticationService.currentFotoValue != null ? this.authenticationService.currentFotoValue : this.fotoPerfilBinario;
    }

    ngOnDestroy(): void {
      this.unsubscribe.next();
      this.unsubscribe.complete();
    }

    estaAutenticado() {
      return this.authenticationService.estaAutenticado();
    }
    
    formatToCPFOrCNPJ(valor) {
      this.cpfCnpj.setValue(formatToCPFOrCNPJ(valor));
    }
  
    formatToPhone(objeto) {
      objeto.value = formatToPhone(objeto.value);
    }

    acaoPossuiCrc() {
      if (this.possuiCrc.value == "N") {
        this.numeroCrc.setValue("");
        this.estadoCrc.setValue("");
        this.classificacaoCrc.setValue("");
        this.tipoCrc.setValue("");
      }
    }

    acaoPossuiCrea() {
      if (this.possuiCrea.value == "N") {
        this.numeroCrea.setValue("");
        this.estadoCrea.setValue("");
      }
    }

    acaoPossuiCau() {
      if (this.possuiCau.value == "N") {
        this.numeroCau.setValue("");
        this.estadoCau.setValue("");
        this.classificacaoCau.setValue("");
        this.emissaoCau.setValue("");
      }
    }

    verificaUsuario() {
      if (this.cpfCnpj.invalid)
        return false;

        if(!this.estaAutenticado()){
          this.authenticationService
          .obterUsuario(this.cpfCnpj.value.replace(/\D/g,""))
          .pipe(take(1))
          .subscribe( (existeUsuario) => {
              if (existeUsuario) {
                this.cpfCnpj.reset();
                this.cpfCnpj.markAsTouched();
                this.inputCpfCnpj.nativeElement.focus()
                Swal.fire('', 'Usuário já existe.', 'warning');
              }
            },
            (err) => {
              console.log('err', err);
              Swal.fire('', 'Não foi possível verificar o CPF / CNPJ.', 'error');
            }
          );
        }
    }

    buscarPorLogin(){
           
      let login = new Login();
      login.login = parseInt(localStorage.getItem("userId").replace(/\D/g,""));
      this.loginService
          .buscarPorLogin(login)
          .pipe(take(1))
          .subscribe( (dados: Login) => { 
              if (!dados.login) {
                  return false;
              } 
          },
          (err) => {
              console.log('err', err);
          }
      );
    }

    upload(event) {
      if (this.arquivos(event)) {
        this.arquivoService
          .uploadFotoPerfil(this.formData)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe (retorno => {
              if (retorno.html != "") {
                this.authenticationService.setCurrentFoto(retorno.html);
                this.fotoPerfilBinario = this.authenticationService.currentFotoValue;
  
                Swal.fire({
                    title: "Anexado",
                    html: `Caro contribuinte, você anexou o arquivo: <b>${ this.formData.get("fileName") }</b>, com sucesso.`,
                    icon: "success",
                })
              }
              else {
                Swal.fire({
                    title: "Não Anexado",
                    html: `Caro contribuinte, não foi possível anexar o arquivo: <b>${ this.formData.get("fileName") }</b>.`,
                    icon: "error",
                })
              }
          });
      }
      
      (event.target as HTMLInputElement).value = "";
    }

    arquivos(event) {
      this.formData = new FormData();
      const arquivosSelecionados = <FileList>event.srcElement.files;
      this.fotoPerfil = new Set();

      
      for (let i = 0; i < arquivosSelecionados.length; i++) {
        if (arquivosSelecionados[i].size > 2097152) {
          Swal.fire({
            title: "",
            html: `Contribuinte <b>${ this.nome.value }</b>, não foi possível adicionar essa imagem porque ela passou do limite de tamanho permitido, favor diminuir ou escolher uma outra imagem até <b>2 mb</b>.`,
            icon: "error",
          });
  
          return false;
        }

        this.fotoPerfil.add(arquivosSelecionados[i]);
        this.formData.append('file', arquivosSelecionados[i], arquivosSelecionados[i].name);
        this.formData.append('fileName', arquivosSelecionados[i].name);
      }

      return true;
    }

    get cpfCnpj() {
      return this.formulario.get("cpfCnpj");
    }  
    set cpfCnpj(cpfCnpj) {
      this.formulario.get("cpfCnpj").setValue(cpfCnpj);
    }

    get nome() {
      return this.formulario.get("nome");
    }  
    set nome(nome) {
      this.formulario.get("nome").setValue(nome);
    }

    get senha() {
      return this.formulario.get("senha");
    }  
    set senha(senha) {
      this.formulario.get("senha").setValue(senha);
    }

    get confirmarSenha() {
      return this.formulario.get("confirmarSenha");
    }  
    set confirmarSenha(confirmarSenha) {
      this.formulario.get("confirmarSenha").setValue(confirmarSenha);
    }

    get email() {
      return this.formulario.get("email");
    }  
    set email(email) {
      this.formulario.get("email").setValue(email);
    }

    get telefoneFixo() {
      return this.formulario.get("telefoneFixo");
    }  
    set telefoneFixo(telefoneFixo) {
      this.formulario.get("telefoneFixo").setValue(telefoneFixo);
    }

    get telefoneComercial() {
      return this.formulario.get("telefoneComercial");
    }  
    set telefoneComercial(telefoneComercial) {
      this.formulario.get("telefoneComercial").setValue(telefoneComercial);
    }

    get telefoneCelular() {
      return this.formulario.get("telefoneCelular");
    }  
    set telefoneCelular(telefoneCelular) {
      this.formulario.get("telefoneCelular").setValue(telefoneCelular);
    }
    
    get possuiCrc() {
      return this.formulario.get("possuiCrc");
    }
    set possuiCrc(possuiCrc) {
      this.formulario.get("possuiCrc").setValue(possuiCrc);
    }

    get numeroCrc() {
      return this.formulario.get("numeroCrc");
    }
    set numeroCrc(numeroCrc) {
      this.formulario.get("numeroCrc").setValue(numeroCrc);
    }

    get estadoCrc() {
      return this.formulario.get("estadoCrc");
    }
    set estadoCrc(estadoCrc) {
      this.formulario.get("estadoCrc").setValue(estadoCrc);
    }

    get classificacaoCrc() {
      return this.formulario.get("classificacaoCrc");
    }
    set classificacaoCrc(classificacaoCrc) {
      this.formulario.get("classificacaoCrc").setValue(classificacaoCrc);
    }

    get tipoCrc() {
      return this.formulario.get("tipoCrc");
    }
    set tipoCrc(tipoCrc) {
      this.formulario.get("tipoCrc").setValue(tipoCrc);
    }

    get possuiCrea() {
      return this.formulario.get("possuiCrea");
    }
    set possuiCrea(possuiCrea) {
      this.formulario.get("possuiCrea").setValue(possuiCrea);
    }

    get numeroCrea() {
      return this.formulario.get("numeroCrea");
    }
    set numeroCrea(numeroCrea) {
      this.formulario.get("numeroCrea").setValue(numeroCrea);
    }

    get estadoCrea() {
      return this.formulario.get("estadoCrea");
    }
    set estadoCrea(estadoCrea) {
      this.formulario.get("estadoCrea").setValue(estadoCrea);
    }

    get possuiCau() {
      return this.formulario.get("possuiCau");
    }
    set possuiCau(possuiCau) {
      this.formulario.get("possuiCau").setValue(possuiCau);
    }

    get numeroCau() {
      return this.formulario.get("numeroCau");
    }
    set numeroCau(numeroCau) {
      this.formulario.get("numeroCau").setValue(numeroCau);
    }

    get estadoCau() {
      return this.formulario.get("estadoCau");
    }
    set estadoCau(estadoCau) {
      this.formulario.get("estadoCau").setValue(estadoCau);
    }

    get classificacaoCau() {
      return this.formulario.get("classificacaoCau");
    }
    set classificacaoCau(classificacaoCau) {
      this.formulario.get("classificacaoCau").setValue(classificacaoCau);
    }

    get emissaoCau() {
      return this.formulario.get("emissaoCau");
    }
    set emissaoCau(emissaoCau) {
      this.formulario.get("emissaoCau").setValue(emissaoCau);
    }
    
}