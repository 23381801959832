export class Cartorio {
    cnpj: string;
    livro: string;
    folha: string;

    constructor() {
        this.cnpj = "";
        this.livro = "";
        this.folha = "";
    }
}