import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged, take } from 'rxjs/operators';
import { OrgaoService } from '../../../../services/orgao/orgao.service';
import { Orgao } from '../../../../model/orgao';
import { Constantes } from '../../../../model/constantes';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbItems: any;
  currentUrl: string;
  orgaos: Array<Orgao> = new Array<Orgao>();

  public currentAxis: string;
  
  constructor(private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private orgaoService: OrgaoService) {
      
  }
    
  ngOnInit() {
    this.buscarOrgaosParaConfiguracao()
  }

  buscarOrgaosParaConfiguracao() {
    this.orgaoService
      .buscarOrgaosParaConfiguracao()
      .pipe(take(1))
      .subscribe( retorno => {
        this.orgaos = retorno;
        
        this.router.events
          .pipe(
            filter((event) => event instanceof NavigationEnd),
            distinctUntilChanged()
          )
          .subscribe(() => {
            this.breadcrumbItems = this.buildBreadCrumb(this.activatedRoute.root);
          });
        this.breadcrumbItems = this.buildBreadCrumb(this.activatedRoute.root);
      })
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs = []) {
    this.currentUrl = this.router.url;

    let label = "";
    
    if (route.snapshot.params["codigoOrgao"] == 9992) {
      label =
        route.routeConfig && route.routeConfig.data
          ? route.routeConfig.data.breadcrumb == "Orgão"
            ? "Módulo Alvará"
            : route.routeConfig.data.breadcrumb
          : '';
    }
    else {
      label =
        route.routeConfig && route.routeConfig.data
          ? route.routeConfig.data.breadcrumb == "Orgão"
            ? this.orgaos.filter( orgao => orgao.nomeSimples == route.snapshot.params["orgao"])[0].nome
            : route.routeConfig.data.breadcrumb
          : '';
    }
    let icone =
      route.routeConfig && route.routeConfig.data
        ? route.routeConfig.data.icone
        : '';
    const isAxis =
      route.routeConfig && route.routeConfig.data
        ? route.routeConfig.data.isAxis
        : false;
    let path =
      route.routeConfig && route.routeConfig.data && route.routeConfig.path
        ? route.routeConfig.path.split("/").map(rota => rota[0] == ":" && rota.search(":orgao") == -1 ? "" : rota.search(":orgao") > -1 ? route.snapshot.params[rota.substring(1)] : rota ).toString().replace(/,/g,"/")
        : '';
        
    const nextUrl = `${url}/${path}`;

    const breadcrumb = {
      label,
      icone,
      url: nextUrl,
      isAxis,
    };
    
    var newBreadcrumbs = breadcrumb.label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];

    if (route.snapshot.params["subMenu"] != null) {
      let label = Constantes.SUB_MENUS[route.snapshot.params["subMenu"]];
      let icone = Constantes.SUB_MENUS_ICONES[route.snapshot.params["subMenu"]];
      let rotaFormatada = route.routeConfig.path.split("/").map(rota => rota[0] == ":" ? route.snapshot.params[rota.substring(1)] : rota ).toString().replace(/,/g,"/");

      const isAxis = false;
      let path =
        route.routeConfig && route.routeConfig.data && route.routeConfig.path
          ? route.snapshot.params["codigoMenu"] != null ? 
              rotaFormatada.substring(0, rotaFormatada.search(route.snapshot.params["codigoMenu"])) + route.snapshot.params["codigoMenu"] :
              rotaFormatada
          : '';
  
      const nextUrlSubMenu = path ? `${url}/${path}` : url;
  
      const breadcrumbSubMenu = {
        label,
        icone,
        url: nextUrlSubMenu,
        isAxis,
      };
    
      newBreadcrumbs = breadcrumbSubMenu.label
        ? [...newBreadcrumbs, breadcrumbSubMenu]
        : [...newBreadcrumbs];
    }
    
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

}
