<ul class="nav nav-pills mb-3 mt-3 nav-fill" id="justify-pills-tab" role="tablist">
    <li class="nav-item" *ngFor="let faqModulo of faqModulos; let i = index">
        <a (click)="interacaoAbas($event.target.id)" [attr.class]="faqModulo.codigo == 1 ? 'nav-link active' : 'nav-link'" [attr.id]="'justify-pills-' + faqModulo.codigo + '-tab'" data-toggle="pill" [attr.href]="'#justify-pills-' + faqModulo.codigo" role="tab" [attr.aria-controls]="'justify-pills-' + faqModulo.codigo" aria-selected="true">{{ faqModulo.nome }}</a>
    </li>
</ul>

<div class="tab-content" id="justify-pills-tabContent">
    <div *ngFor="let faqModulo of faqModulos; let i = index">
        <div class="tab-pane fade d-none" [attr.id]="'justify-pills-' + faqModulo.codigo" role="tabpanel" [attr.aria-labelledby]="'justify-pills-'+ faqModulo.codigo + '-tab'">
            <div class="row mb-4 mt-3">
                <div  class="col-sm-3 col-12" *ngIf="faqModulo.faqSubModulos.length > 1">
                    <div  class="nav flex-column nav-pills mb-sm-0 mb-3" id="v-pills-sub-tab" role="tablist" aria-orientation="vertical">
                      <a *ngFor="let faqSubModulo of faqModulo.faqSubModulos; let i = index" 
                        (click)="interacaoAbasVertical($event.target.id)"
                        class="nav-link mb-2" 
                        [class.active]="faqModulo.codigoSubModulo == 1"
                        [attr.id]="'v-pills-sub-' + faqSubModulo.codigoSubModulo + '-tab'" 
                        data-toggle="pill" 
                        [attr.href]="'#v-pills-sub-' + faqSubModulo.codigoSubModulo" 
                        [attr.aria-controls]="'v-pills-sub-' + faqSubModulo.codigoSubModulo"
                        role="tab" 
                        aria-selected="true">{{ faqSubModulo.nome }}</a>
                    </div>
                </div>
            
                <div class="col-12"
                    [class.col-sm-6]="faqModulo.faqSubModulos.length > 1"
                    [class.col-sm-12]="faqModulo.faqSubModulos.length == 1"
                >
                    <div class="search" style="text-align: center;">
                        Não Encontrou o que Procurava? <a target="_blank" (click)="abrirChat()" style="cursor: pointer;">Clique aqui.</a>
                        <!---<button
                            class="btn btn-block btn-danger"
                            title="Não encontrou o que procurava?"
                            type="button"
                            id="btnChat"
                            (click)="abrirChat()"
                        >
                        </button>--->
                    </div>
                    <div class="search">
                        <input #filtro
                            type="text"
                            (input)="acaoDoFiltro(filtro.value, faqModulo.codigo)"
                            class="form-control input-search" placeholder="Pesquise Aqui...">
                    </div>
                    <div class="todo-box-{{ faqModulo.codigo }}">
                        <div class="tab-content p-0" id="v-pills-sub-tabContent">
                            <div *ngFor="let faqSubModulo of faqModulo.faqSubModulos; let i = index"  
                                class="tab-pane fade" 
                                [class.d-none]="faqModulo.faqSubModulos.length > 1"  
                                [class.show]="faqModulo.faqSubModulos.length == 1"  
                                [class.active]="faqModulo.faqSubModulos.length == 1"                                
                                role="tabpanel" 
                                [attr.id]="'v-pills-sub-' + faqSubModulo.codigoSubModulo" 
                                [attr.aria-labelledby]="'v-pills-sub-' + faqSubModulo.codigoSubModulo + '-tab'">
                                <div class="card component-card_4 todo-item-{{ faqModulo.codigo }} all-list" *ngFor="let faq of faqSubModulo.faqs">
                                    <div class="card-body">
                                        <div class="user-info" *ngIf="faq.pergunta != ''">
                                            <h5 class="card-user_name">
                                                <b>
                                                    {{ faq.pergunta }}
                                                    <img src="../../../../assets/img/icons/download_green.svg" style="width: 20px;" class="cursor-pointer" title="Baixar Arquivo" (click)="baixarArquivo(faq.faqArquivo);" *ngIf="faq.codigoModulo != 1"/>
                                                </b>
                                            </h5>
                                            <p class="card-text" *ngIf="faq.codigoModulo == 1">R: {{ faq.resposta }}</p>
                                        </div>
                                        <div style="text-align:left;" *ngIf="faq.pergunta != '' && faq.codigoModulo == 1">
                                            <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;" (click)="avaliacaoPositiva(faq);">
                                                <img src="../../../../assets/img/icons/thumbs-up-green.svg" style="width: 15px;" class="cursor-pointer" title="Avaliar Positivamente" />
                                            </mat-icon>
                                            <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;" (click)="avaliacaoNegativa(faq);">
                                                <img src="../../../../assets/img/icons/thumbs-down-red.svg" style="width: 15px;" class="cursor-pointer" title="Avaliar Negativamente"/>
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="user-info" *ngIf="faqSubModulo.faqs.length == 0">
                                    <h5 class="card-user_name" style="text-align: center;">Não configurado.</h5>
                                </div>
                            </div>
                            <div class="todo-box-nao-encontrado-{{ faqModulo.codigo }}" style="display: none;">
                                <h6 class="card-user_name" style="text-align: center;"><b>Não foi localizado nenhum item, faça uma nova pesquisa utilizando outra informação.</b></h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="faqModulo.codigo == 1" class="col-sm-3 col-12">
                    <div>
                        <h4 style="color:#00A75D; text-align: center;">
                            Mais bem avaliadas
                            <img src="../../../../assets/img/icons/thumbs-up-green.svg" style="width: 20px; " />
                        </h4>
                    </div>
                    <div class="card component-card_4" *ngFor="let faqMaisAvaliada of faqMaisAvaliadas; let i = index"> 
                        <div class="card-body">
                            <div class="user-info">
                                <h5 class="card-user_name" style="font-size: 12px;">
                                    <b>
                                        {{faqMaisAvaliada.pergunta}}
                                    </b>
                                </h5>
                                <p class="card-user_occupation" style="font-size: 10px;">
                                    * Módulo: {{faqMaisAvaliada.nomeSubModulo}}
                                </p>
                                <p class="card-text" style="font-size: 12px;">R: {{faqMaisAvaliada.resposta}}</p>
                            </div>
                            <div style="text-align:left;">
                                <img src="../../../../assets/img/icons/thumbs-up-green.svg" style="width: 15px; cursor: pointer;" (click)="avaliacaoPositiva(faqMaisAvaliada);" title="Avaliar Positivamente" />
                                <span class="badge badge-success" style="margin:0 1em 0 0.2em;">{{faqMaisAvaliada.qtdeAvaliadas}}</span>    
                                <img src="../../../../assets/img/icons/thumbs-down-red.svg" style="width: 15px; cursor: pointer;" (click)="avaliacaoNegativa(faqMaisAvaliada);" title="Avaliar Negativamente"/>
                                <span class="badge badge-danger" style="margin:0 1em 0 0.2em;">{{faqMaisAvaliada.qtdeNegadas}}</span>
                            </div>
                        </div>
                    </div>
                    <!---<div>
                        <h4 style="color:#1b55e2; text-align: center;">
                            Mais Buscadas
                            <img src="../../../../assets/img/icons/search-info.svg" style="width: 20px;"/>
                        </h4>
                    </div>
                    <div class="card component-card_4" *ngFor="let faqMaisBuscada of faqMaisBuscadas; let i = index"> 
                        <div class="card-body">
                            <div class="user-info">
                                <h5 class="card-user_name">
                                    <b>
                                        {{faqMaisBuscada.pergunta}}
                                    </b>
                                </h5>
                                <p class="card-user_occupation">Manager</p>
                                <div class="card-star_rating" style="text-align: left;">
                                    <img src="../../../../assets/img/icons/thumbs-up-green.svg" style="width: 15px; "/>
                                    <span class="badge badge-success" style="margin:0 1em 0 0.2em;">{{faqMaisBuscada.qtdeAvaliadas}}</span>    
                                    <img src="../../../../assets/img/icons/thumbs-down-red.svg" style="width: 15px;"/>
                                    <span class="badge badge-danger" style="margin:0 1em 0 0.2em;">{{faqMaisBuscada.qtdeNegadas}}</span>
                                    <img src="../../../../assets/img/icons/search-info.svg" style="width: 15px;"/>
                                    <span class="badge badge-primary">{{faqMaisBuscada.qtdeBuscadas}}</span>
                                </div>
                                <p class="card-text">R: {{faqMaisBuscada.resposta}}</p>
                            </div>
                            <div style="text-align:left;">
                                <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
                                    <img src="../../../../assets/img/icons/thumbs-up-green.svg" style="width: 15px; cursor: pointer;"/>
                                </mat-icon>
                                <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
                                    <img src="../../../../assets/img/icons/thumbs-down-red.svg" style="width: 15px; cursor: pointer;"/>
                                </mat-icon>
                            </div>
                        </div>
                    </div>--->
                              
            </div>
        </div>    
    </div>
</div>