import { Component, Input, OnInit } from '@angular/core';
import { Protocolo } from "src/app/model/protocolo";
import { Atividade } from '../../../../model/atividade';
import { Empresa } from '../../../../model/empresa';

@Component({
  selector: 'app-informacao-atividade',
  templateUrl: './informacao-atividade.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoAtividadeComponent implements OnInit {
  
  @Input() tipoEmpresa = "";
  @Input() permiteCriticar = false;
  @Input() permiteInconsistencias = false;  
  protocolo: Protocolo;
  empresa: Empresa;
  
  formasAtuacaoConfiguracao = JSON.parse(localStorage.getItem('user')).configuracao.formasAtuacao;
  tipoUnidadeAuxiliares = JSON.parse(localStorage.getItem('user')).configuracao.tipoUnidades;
  atividadesOriginaisExercidas: Array<Atividade>;

  constructor() {
    this.protocolo = new Protocolo();
    this.empresa = new Empresa();
  }

  ngOnInit(): void {
  }

}
