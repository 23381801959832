<div class="card mb-0">
    <div class="card-header">
        <div class="col-12">
            <strong>Processamento em Lote</strong>
        </div>
    </div>
</div>
<div class="row col-lg-12 col-12 pl-0 pr-0">
    <div class="col-12 pl-0 pr-0">
        <div class="card">
            <div class="card-body p-0 mb-2">
                <div class="row justify-content-start align-items-center">
                    <div class="col-12 card-header-linha">
                        <p class="card-text pt-2 pb-2">
                            <b>AUDITOR, UTILIZE UMA DAS OPÇÕES PARA LOCALIZAR OS PROCESSAMENTOS REALIZADOS PELO CENTRO DE CUSTO SELECIONADO.</b>
                        </p>                    
                    </div>
                </div>
            </div>
            <div class="card-body card-body-localizacao">
            <form
                name="formulario"
                [formGroup]="formulario"
            >
                <div class="row justify-content-center align-items-center"> 
                    <div class="col-10">
                        <select 
                            #codigoCentroDeCustoSelect
                            class="form-control form-control-sm"
                            (change)="acaoSelectTipoBusca()"
                            formControlName="codigoCentroDeCusto"
                            name="codigoCentroDeCusto" 
                            id="codigoCentroDeCusto"                
                        >
                            <option disabled value="">--- Selecione ---</option>
                            <option *ngFor="let centroDeCusto of centrosDeCusto; let i = index"
                                [value]="centroDeCusto.codigoCentroDeCusto" 
                                >{{ centroDeCusto.identificacao }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <button 
                            type="button" 
                            class="btn btn-secondary"
                            title="Buscar"
                            (click)="buscarLotes()"
                        >
                        Buscar Lote
                        </button>
                    </div>
                </div>
            </form>
            </div>
        </div>
    </div>
</div>

<div class="row col-12 pl-0 pr-0" *ngIf="emitirErro">
    <div class="col-12 pl-0 pr-0">
        <app-alert type="danger">
            <div [innerHtml]="mensagemErro"></div>
        </app-alert>
    </div>
</div>

<div class="card col-lg-12 col-12 pl-0 pr-0" *ngIf="exibirLotes">
    <div class="card-body p-0 mb-2">
        <div class="row justify-content-start align-items-center">
            <div class="col-12 card-header-linha">
                <p class="card-text pt-2 pb-2">
                    <b>DADOS DO CENTRO DE CUSTO</b>
                </p>
            </div>
        </div>
    </div>

    <div class="row col-12 row-linha pt-2 pb-2">
        <p class="card-text">
            Caro Auditor, logo abaixo estão as informações do centro de custo com a sua identificação e todas as taxas que estão vinculadas a prévia ativa.<br>
        </p>
    </div> 

    <hr>

    <div class="card-body p-0 mb-2">
        <div class="row">
            <div class="col-10">
                <div class="row">
                    <div class="form-group col-2">
                        <p class="card-text">
                            <strong>Identificação</strong>
                        </p>
                    </div>
                    <div class="form-group col-10">
                        <p class="card-text">
                            {{ (centrosDeCusto | filtro: {campo: 'codigoCentroDeCusto', valor: codigoCentroDeCusto.value})[0].identificacao }}
                            <b>({{ (centrosDeCusto | filtro: {campo: 'codigoCentroDeCusto', valor: codigoCentroDeCusto.value})[0].codigoCentroDeCusto }})</b>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-2">
                        <p class="card-text">
                            <strong>Taxa(s)</strong>
                        </p>
                    </div>
                    <div class="form-group col-10">
                        <p class="card-text" *ngFor="let taxaLoteOrgaoRequisito of taxaLoteOrgaoRequisitos">
                            {{ taxaLoteOrgaoRequisito.identificacao }} <b>({{ taxaLoteOrgaoRequisito.codigoTaxa }})</b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="divIcone">
                    <button type="button" 
                        id="btn-gerar-previa"
                        matTooltip="Nova Prévia"
                        matTooltipPosition="above"
                        class="btn btn-warning col-12"
                        (click)="novaPrevia()">
                        <svg class="iconeGrid">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'file' }}"
                            ></use>
                        </svg> Gerar Prévia
                    </button>
                </div>
                <div class="divIcone">
                    <button type="button" 
                        id="btn-gerar-lote"
                        matTooltip="Processar Lote"
                        matTooltipPosition="above"
                        class="btn btn-success col-12"
                        (click)="processarLote()">
                        <svg class="iconeGrid">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'file-text' }}"
                            ></use>
                        </svg> Gerar Lote
                    </button>
                </div>                
            </div>
        </div>
    </div>
</div>

<div class="card p-0 mb-4" [class.hidden]="!exibirLotes">
    <div class="col-12 card-header-linha">
        <p class="card-text pt-2 pb-2">
            <b>PRÉVIAS SOLICITADAS</b>
        </p>
    </div>

    <div class="row col-12 row-linha pt-2 pb-2">
        <p class="card-text">
            Caro Auditor, logo abaixo você encontra todas as prévias já solicitadas.<br>
        </p>
    </div> 

    <div class="card-body card-body-localizacao pl-1 pr-1">
        <div class="row">
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="aplicarFiltro($event)" placeholder="Digite qualquer valor para filtrar por todas as opções">
            </mat-form-field>
        </div>
    </div>

    <div class="mat-elevation-z9">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="codigo">
                <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-codigo" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Lote </th>
                <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.codigo }} </td>
            </ng-container>

            <ng-container matColumnDef="adicionado">
                <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-adicionado" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Data Solicitação </th>
                <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.adicionado }} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th style="width: 50%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-status" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Status </th>
                <td style="text-align: left; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ processamentoLoteStatusDescricao[row.status] }} </td>
            </ng-container>

            <ng-container matColumnDef="qtdEmpresas">
                <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-qtdEmpresas" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Empresas </th>
                <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.qtdEmpresas }} </td>
            </ng-container>

            <ng-container matColumnDef="qtdEmpresasProcessadas">
                <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-qtdEmpresasProcessadas" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Processadas </th>
                <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.qtdEmpresasProcessadas }} </td>
            </ng-container>

            <ng-container matColumnDef="total">
                <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-total" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Total </th>
                <td style="text-align: right; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.total }} </td>
            </ng-container>

            <ng-container matColumnDef="menu">
                <th style="width: 1%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-menu" mat-header-cell *matHeaderCellDef class="hide-arrow"></th>
                <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row">        
                    <button class="btn btn-menu" mat-button [matMenuTriggerFor]="beforeMenu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button *ngIf="row.status != processamentoLoteStatus.Previa_Descartada"
                            class="btn" mat-menu-item
                            (click)="buscarTaxaLoteDetalheSessoes()"
                            >
                            <svg class="icone-grid-menu m-2">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                            ></use>
                            </svg>
                            Listar Empresas por sessão
                        </button>
                        <button 
                            class="btn" mat-menu-item
                            (click)="acaoBaixarDetalhamento(row)"
                            >
                            <svg class="icone-grid-menu m-2">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                            ></use>
                            </svg>
                            Baixar Detalhamento
                        </button>
                        <button 
                            class="btn" mat-menu-item
                            (click)="acaoBaixarDetalhamentoComMemoria(row)"
                            >
                            <svg class="icone-grid-menu m-2">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                            ></use>
                            </svg>
                            Baixar Detalhamento Com Memória
                        </button>
                    </mat-menu>
                </td>
            </ng-container>        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="row" *ngIf="dataSource.filteredData.length == 0">
            <div class="form-row col-12 pt-3 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>Não existem valor(es) cadastrado(s).<br>&nbsp;
                </p>
            </div>
        </div>
        <mat-paginator #matPaginator [pageSize]="5" [pageSizeOptions]="[5, 10, 15, 20]"></mat-paginator> 
    </div>
    <div>&nbsp;</div>
</div>

<div class="card p-0 mb-4" [class.hidden]="!exibirLotesSessoes">    
    <div class="col-12 card-header-linha">
        <p class="card-text pt-2 pb-2">
            <b>DETALHAMENTO DE PRÉVIA - LOTE 
                {{ 
                    (taxaLotes | filtro: {campo: 'status', valor: processamentoLoteStatus.Previa_Descartada, tipoValor: '!='}).length ? 
                    (taxaLotes | filtro: {campo: 'status', valor: processamentoLoteStatus.Previa_Descartada, tipoValor: '!='})[0].codigo : ''
                }}
            </b>
        </p>
    </div>

    <div class="row col-12 row-linha pt-2 pb-2">
        <p class="card-text">
            Caro Auditor, logo abaixo tem sessões de empresas que estão no lote ativo.<br>
        </p>
    </div> 

    <div class="card-body card-body-localizacao pl-1 pr-1">
        <div class="row">
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="aplicarFiltroDetalhe($event)" placeholder="Digite qualquer valor para filtrar por todas as opções">
            </mat-form-field>
        </div>
    </div>

    <div class="mat-elevation-z9">
        <table mat-table [dataSource]="dataSourceDetalhe" matSort>
            <ng-container matColumnDef="de">
                <th style="width: 40%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-de" mat-header-cell *matHeaderCellDef class="hide-arrow"> De </th>
                <td style="text-align: left; padding-left: 20px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.de }} </td>
            </ng-container>

            <ng-container matColumnDef="ate">
                <th style="width: 40%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-ate" mat-header-cell *matHeaderCellDef class="hide-arrow"> Até </th>
                <td style="text-align: left; padding-left: 20px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.ate }} </td>
            </ng-container>

            <ng-container matColumnDef="total">
                <th style="width: 20%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-total" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Total da Sessão </th>
                <td style="text-align: right; padding-left: 5px; padding-right: 20px;" mat-cell *matCellDef="let row"> {{ row.total }} </td>
            </ng-container>

            <ng-container matColumnDef="menu">
                <th style="width: 1%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-menu" mat-header-cell *matHeaderCellDef class="hide-arrow"></th>
                <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row">        
                    <button class="btn btn-menu" mat-button [matMenuTriggerFor]="beforeMenu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <button 
                            class="btn" mat-menu-item
                            (click)="acaoListarEmpresas(row)"
                            >
                            <svg class="icone-grid-menu m-2">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                            ></use>
                            </svg>
                            Listar Empresas
                        </button>
                    </mat-menu>
                </td>
            </ng-container>        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsDetalhe"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDetalhe;"></tr>
        </table>
        <div class="row" *ngIf="dataSourceDetalhe.filteredData.length == 0">
            <div class="form-row col-12 pt-3 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>Não existem detalhe(s) a serem exibido(s).<br>&nbsp;
                </p>
            </div>
        </div>
        <mat-paginator #matPaginatorDetalhe [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 40]"></mat-paginator> 
    </div>
    <div>&nbsp;</div>
</div>

<div class="card p-0 mb-4" [class.hidden]="!exibirLoteDetalheEmpresas">    
    <div class="col-12 card-header-linha">
        <p class="card-text pt-2 pb-2">
            <b>LISTAGEM DE EMPRESAS - LOTE 
                {{ 
                    (taxaLotes | filtro: {campo: 'status', valor: processamentoLoteStatus.Previa_Descartada, tipoValor: '!='}).length ? 
                    (taxaLotes | filtro: {campo: 'status', valor: processamentoLoteStatus.Previa_Descartada, tipoValor: '!='})[0].codigo : ''
                }}
            </b>
        </p>
    </div>

    <div class="row col-12 row-linha pt-2 pb-2">
        <p class="card-text">
            Caro Auditor, logo abaixo tem sessões de empresas que estão no lote ativo.<br>
        </p>
    </div> 

    <div class="card-body card-body-localizacao pl-1 pr-1">
        <div class="row">
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="aplicarFiltroDetalheEmpresas($event)" placeholder="Digite qualquer valor para filtrar por todas as opções">
            </mat-form-field>
        </div>
    </div>

    <div class="mat-elevation-z9">
        <table mat-table [dataSource]="dataSourceDetalheEmpresas" matSort>
            <ng-container matColumnDef="inscricao">
                <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-inscricao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Inscrição </th>
                <td style="text-align: right; padding-left: 5px; padding-right: 20px;" mat-cell *matCellDef="let row"> {{ row.inscricao }} </td>
            </ng-container>
            
            <ng-container matColumnDef="emprCpfCgc">
                <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-emprCpfCgc" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> CNPJ / CPF </th>
                <td style="text-align: right; padding-left: 5px; padding-right: 20px;" mat-cell *matCellDef="let row"> {{ row.emprCpfCgc }} </td>
            </ng-container>
            
            <ng-container matColumnDef="emprNomEmpresa">
                <th style="width: 50%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-emprNomEmpresa" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Razão Social / Nome </th>
                <td style="text-align: left; padding-left: 20px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.emprNomEmpresa }} </td>
            </ng-container>
            
            <ng-container matColumnDef="processamento">
                <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-processamento" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Processamento </th>
                <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.processamento == '' ? 'Não Processado' : row.processamento }} </td>
            </ng-container>
            
            <ng-container matColumnDef="total">
                <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-total" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Total </th>
                <td style="text-align: right; padding-left: 5px; padding-right: 20px;" mat-cell *matCellDef="let row"> {{ row.total }} </td>
            </ng-container>

            <ng-container matColumnDef="menu">
                <th style="width: 1%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-menu" mat-header-cell *matHeaderCellDef class="hide-arrow"></th>
                <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row">        
                    <button class="btn btn-menu" mat-button [matMenuTriggerFor]="beforeMenu" (click)="buscarMemoriasDeCalculoPreviaPorInscricao(row)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">                        
                        <button class="btn" mat-menu-item *ngFor="let taxaLancamento of taxaLancamentos"
                            (click)="acaoMemoriaDeCalculo(taxaLancamento.codigo)"
                        >
                            <svg class="icone-grid-menu m-2">
                                <use
                                attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                                ></use>
                            </svg>  
                            {{ taxaLancamento.identificacao }} <b>({{ taxaLancamento.codigoTaxa }})</b> - <b>R$ {{ formatToBRL(taxaLancamento.total) }}</b>
                        </button>
                        <button 
                            class="btn" mat-menu-item
                            (click)="acaoReprocessar(row)"
                            >
                            <svg class="icone-grid-menu m-2">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                            ></use>
                            </svg>
                            ADICIONAR PARA REPROCESSAMENTO
                        </button>
                    </mat-menu>
                </td>
            </ng-container>        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsDetalheEmpresas"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDetalheEmpresas;"></tr>
        </table>
        <div class="row" *ngIf="dataSourceDetalheEmpresas.filteredData.length == 0">
            <div class="form-row col-12 pt-3 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>Não existem detalhe(s) a serem exibido(s).<br>&nbsp;
                </p>
            </div>
        </div>
        <mat-paginator #matPaginatorDetalheEmpresas [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator> 
    </div>
    <div>&nbsp;</div>
</div>

<div class="card p-0 mb-4" [class.hidden]="!exibirListaEmpresasReprocessamento">    
    <div class="col-12 card-header-linha">
        <p class="card-text pt-2 pb-2">
            <b>LISTA DE EMPRESAS AGUARDANDO CONFIRMAÇÃO PARA REPROCESSAMENTO</b>
        </p>
    </div>

    <div class="row col-12 row-linha pt-2 pb-2">
        <p class="card-text">
            Caro Auditor, logo abaixo tem a lista de empresas adicionadas para reprocessamento e estão aguardando a confirmação.<br>
        </p>
    </div> 

    <div class="row col-12 row-linha pt-5 pb-5">
        <div class="col-4"></div>
        <div class="row col-4 justify-content-center align-items-center">
            <button class="btn btn-success text-center" (click)="acaoConfirmarReprocessamento()">CONFIRMAR REPROCESSAMENTO</button>
        </div>
        <div class="col-4"></div>
    </div> 

    <div class="card-body card-body-localizacao pl-1 pr-1">
        <div class="row">
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="aplicarFiltroListaEmpresasReprocessamento($event)" placeholder="Digite qualquer valor para filtrar por todas as opções">
            </mat-form-field>
        </div>
    </div>

    <div class="mat-elevation-z9">
        <table mat-table [dataSource]="dataSourceListaEmpresasReprocessamento" matSort>
            <ng-container matColumnDef="inscricao">
                <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-inscricao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Inscrição </th>
                <td style="text-align: right; padding-left: 5px; padding-right: 20px;" mat-cell *matCellDef="let row"> {{ row.inscricao }} </td>
            </ng-container>
            
            <ng-container matColumnDef="emprCpfCgc">
                <th style="width: 15%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-emprCpfCgc" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> CNPJ / CPF </th>
                <td style="text-align: right; padding-left: 5px; padding-right: 20px;" mat-cell *matCellDef="let row"> {{ row.emprCpfCgc }} </td>
            </ng-container>
            
            <ng-container matColumnDef="emprNomEmpresa">
                <th style="width: 75%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-emprNomEmpresa" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Razão Social / Nome </th>
                <td style="text-align: left; padding-left: 20px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.emprNomEmpresa }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsListaEmpresasReprocessamento"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsListaEmpresasReprocessamento;"></tr>
        </table>
        <div class="row" *ngIf="dataSourceListaEmpresasReprocessamento.filteredData.length == 0">
            <div class="form-row col-12 pt-3 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>Não existem detalhe(s) a serem exibido(s).<br>&nbsp;
                </p>
            </div>
        </div>
        <mat-paginator #matPaginatorListaEmpresasReprocessamento [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator> 
    </div>
    <div>&nbsp;</div>
</div>