import { Component, EventEmitter, Output, ElementRef, Renderer2, RendererStyleFlags2 } from "@angular/core";
import { DattaConfig } from "../../../app-config";

import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Constantes } from "src/app/model/constantes";
import { ViewportScroller } from '@angular/common';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  public showUserDropdown = false;
  public showEixosDropdown = false;
  public showNotificationDropdown = false;
  public showCompaniesDropdown = false;
  public codigoCliente: string = "null";
  public nomeUsuario: string;
  public nomeCliente: string;
  public cargoUsuario: string;
  public departamentoUsuario: string;

  constructor(private element: ElementRef,
              private renderer: Renderer2,
              private document: Document,
              private authenticationService: AuthenticationService,
              private viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
    this.codigoCliente = this.authenticationService.currentUserValue.cliente;
    this.nomeCliente = this.authenticationService.currentUserValue.municipio;
    this.departamentoUsuario = this.authenticationService.currentUserValue.departamentoUsuario;
    this.nomeUsuario = this.authenticationService.currentUserValue.nomeFantasia;
    this.cargoUsuario = this.authenticationService.currentUserValue.cargoUsuario;
  }

  goToAnchor(ancora) {
    this.viewportScroller.scrollToAnchor(ancora);
    let posicaoAtual = this.viewportScroller.getScrollPosition();
    this.viewportScroller.scrollToPosition([posicaoAtual[0], posicaoAtual[1] - 146])
  }

  mudarTamanhoFonte(tamanho) {
    if (this.element.nativeElement.closest("body").style.fontSize == "") {
      this.element.nativeElement.closest("body").style.fontSize = "14px";
    }
    if (tamanho == Constantes.TAMANHO_DA_FONTE.Aumentar) {
      this.renderer.setStyle(this.document.querySelector("body"), "font-size", (parseInt(this.element.nativeElement.closest("body").style.fontSize.replace(/\D/g,"")) + 2).toString() + "px", RendererStyleFlags2.DashCase);
    }
    else if (tamanho == Constantes.TAMANHO_DA_FONTE.Diminuir) {
      this.renderer.setStyle(this.document.querySelector("body"), "font-size", (parseInt(this.element.nativeElement.closest("body").style.fontSize.replace(/\D/g,"")) - 2).toString() + "px", RendererStyleFlags2.DashCase);
    }
    
  }

  mudarContraste() {
    if (this.document.querySelector("body").className == "contrast") {
      this.renderer.removeClass(this.document.querySelector("body"), "contrast");
    }
    else {
      this.renderer.addClass(this.document.querySelector("body"), "contrast");
    }
  }
}
