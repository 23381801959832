import { ArquivoService } from './../../services/arquivo/arquivo.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { GridProviderPaginacao } from './grid-provider-paginacao';

@Component({
  selector: 'app-grid-atualizacao-oficio',
  templateUrl: './grid-atualizacao-oficio.component.html',styleUrls: ["./grid.component.scss"],
  providers: [ 
      {
          provide: MatPaginatorIntl,
          useClass: GridProviderPaginacao
      }
  ]
})
export class GridAtualizacaoOficioComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ["codigo","arquivo","status"];
  
  @Input() linhasArquivo: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  limitarSelecao: number = 0;

  constructor(private arquivoService: ArquivoService) { }

  ngOnInit(): void {
    this.buscarinformacoesdeArquivosCarregados();
  }

  buscarinformacoesdeArquivosCarregados() {

    this.linhasArquivo = this.arquivoService
      .buscarInformacoesProcessamentoLote()
      .pipe(take(1))
      .subscribe(retorno => {
        this.dataSource = new MatTableDataSource(retorno);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
        this.dataSource.sort = this.sort;
        this.linhasArquivo = retorno
      }
      )
  }

  ordenacaoGrid(data, sort) {
      if (sort.active == null)
          return data;

      let campo = sort.active.replace("servico", "servicoDescricao");

      if (campo == "adicionado") {
        data.sort( (item_1, item_2) => (new Date(item_1[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) > new Date(item_2[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (campo == "dataSituacao") {
          data.sort( (item_1, item_2) => (new Date(item_1[campo].split("/").reverse().toString()) > new Date(item_2[campo].split("/").reverse().toString()) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (campo == "inscricao") {
          data.sort( (item_1, item_2) => (item_1[campo] > item_2[campo] ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (typeof(data[0][campo]) == typeof("0")) {
          data.sort( (item_1, item_2) => (item_1[campo]).localeCompare(item_2[campo])  * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      else if (typeof(data[0][campo]) == typeof(0)) {
          data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo])) ? 0 : item_1[campo] ) - ( isNaN(parseInt(item_2[campo])) ? 0 : item_2[campo] ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
      }
      
      return data;
  }
}
