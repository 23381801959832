import { Processo } from "./processo";
import { Viabilidade } from "./viabilidade";
import { Fic } from "./fic";
import { Licenciamento } from "./licenciamento";
import { Critica } from './critica';
import { Encerramento } from './encerramento';

export class AtendimentoConfiguracao{
    
    cliente: string;
    inscricao: string;
    protocolo: string;
    codigo: string;
    menu: string;
    orgao: string;
    processo: Processo;
    viabilidade: Viabilidade;
    fic: Fic;
    criticas: Array<Critica>;
    licenciamentos: Array<Licenciamento>;
    encerramento: Encerramento;

    constructor() {
        this.cliente = "";
        this.inscricao = "";
        this.protocolo = "";
        this.codigo = "";
        this.menu = "";
        this.orgao = "";
        this.processo = new Processo();
        this.viabilidade = new Viabilidade();
        this.fic = new Fic();
        this.licenciamentos = new Array<Licenciamento>();
        this.criticas = new Array<Critica>();
        this.encerramento = new Encerramento();
    }

}