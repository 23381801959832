import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Requisito } from '../../model/requisito';

@Injectable({ providedIn: 'root' })
export class RequisitoService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarPorInscricao(cliente, Inscricao, PreRequisito) {
        return this.http.get<any>(
        `${ environment.icadUrl }/requisito/buscarPorInscricao/${ cliente }/${ Inscricao }/${ PreRequisito }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorCodigo(cliente, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/requisito/buscarPorCodigo/${ cliente }/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorCodigoParaAtendimento(cliente, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/requisito/buscarPorCodigoParaAtendimento/${ cliente }/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    atendeRequisito(requisito: Requisito){
        return this.http.post<any>(
            `${ environment.icadUrl }/requisito/atendeRequisito/`, JSON.stringify(requisito),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarRequisitosParaConfiguracao(cliente) {
        return this.http.get<any>(
            `${ environment.icadUrl }/requisito/buscarRequisitosParaConfiguracao/${ cliente }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarRequisitosParaConfiguracaoComPrefeitura(cliente) {
        return this.http.get<any>(
            `${ environment.icadUrl }/requisito/buscarRequisitosParaConfiguracaoComPrefeitura/${ cliente }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarRequisitosParaConfiguracaoSomentePrefeitura(cliente) {
        return this.http.get<any>(
            `${ environment.icadUrl }/requisito/buscarRequisitosParaConfiguracaoSomentePrefeitura/${ cliente }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPosturas(cliente) {
        return this.http.get<any>(
            `${ environment.icadUrl }/requisito/buscarPosturas/${ cliente }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarOrgaosParaConfiguracao(cliente) {
        return this.http.get<any>(
            `${ environment.icadUrl }/orgao/buscarOrgaosParaConfiguracao/${ cliente }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    incluirRequisito(requisito: Requisito) {
        return this.http.post<any>( `${ environment.icadUrl }/requisito/incluirRequisito`, JSON.stringify(requisito),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    excluirRequisito(codigo) {
        return this.http.post<any>( `${ environment.icadUrl }/requisito/excluirRequisito`, JSON.stringify(codigo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    alterarRequisito(codigo) {
        return this.http.post<any>( `${ environment.icadUrl }/requisito/alterarRequisito`, JSON.stringify(codigo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    adicionarRequisitoProtocolo(requisito: Requisito) {
        return this.http.post<any>(
        `${ environment.icadUrl }/requisito/adicionarRequisitoProtocolo`, JSON.stringify(requisito),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    removerRequisitoProtocolo(requisito: Requisito) {
        return this.http.post<any>(
        `${ environment.icadUrl }/requisito/removerRequisitoProtocolo`, JSON.stringify(requisito),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    adicionarRequisitoEmpresa(requisito: Requisito) {
        return this.http.post<any>(
        `${ environment.icadUrl }/requisito/adicionarRequisitoEmpresa`, JSON.stringify(requisito),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    removerRequisitoEmpresa(requisito: Requisito) {
        return this.http.post<any>(
        `${ environment.icadUrl }/requisito/removerRequisitoEmpresa`, JSON.stringify(requisito),
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarParaAtendimentoOficio(ServicoCodigo, TipoPessoa, atividades) {
        return this.http.get<any>(
        `${ environment.icadUrl }/requisito/buscarParaAtendimentoOficio/${ ServicoCodigo }/${ TipoPessoa }/${ atividades }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarSolicitacoesDeDespachoPorInscricao(cliente, inscricao, codigoOrgao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/despacho/buscarSolicitacoesDeDespachoPorInscricao/${ cliente }/${ inscricao }/${ codigoOrgao }`,
            {
                reportProgress: false,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarVinculosPorAtividades(tipoEmpresa,atividades) {
        return this.http.get<any>(
            `${ environment.icadUrl }/requisito/buscarVinculosPorAtividades/${ localStorage.getItem("pId")}/${ tipoEmpresa }/${ atividades }`,
            {
                reportProgress: false,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarVinculosPorArquivo(tipoVinculo,codigoRequisito) {
        return this.http.get<any>(
            `${ environment.icadUrl }/requisito/buscarVinculosPorArquivo/${ localStorage.getItem("pId")}/${ tipoVinculo }/${ codigoRequisito }`,
            {
                reportProgress: false,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

       
}