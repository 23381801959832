import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import { Component, Input, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginatorIntl, MatPaginator } from "@angular/material/paginator";

import { GridProviderPaginacao } from "./grid-provider-paginacao";
import { MatDialog } from '@angular/material/dialog';
import Swal from "sweetalert2";
import { Constantes } from '../../model/constantes';
import { ArquivoService } from '../../services/arquivo/arquivo.service';
import { TaxaLancamentoParcela } from "src/app/model/taxaLancamentoParcela";
import { TaxaPagamento } from "src/app/model/taxaPagamento";
import { TaxaService } from "src/app/services/taxa/taxa.service";
import { MascaraPipe } from "src/app/pipes/mascara.pipe";

@Component({
    selector: "app-grid-dam",
    templateUrl: "./grid-dam.component.html",
    styleUrls: ["./grid.component.scss"],
    providers: [ 
        {
            provide: MatPaginatorIntl,
            useClass: GridProviderPaginacao
        }
    ]
})
export class GridDamComponent {
    
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ["protocoloInscricao","nomeEmpresaNomeRequerente","lancamentoTotal","vencimentoParcela","numeroParcela","situacaoParcela","totalParcela","totalBaixa","menu"];
    teste: boolean = true;

    codigoMenuPai: number;
    codigoMenu: number;
    codigoOrgao: number;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

    @Input() dados: Array<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(
        private mascara: MascaraPipe,
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private arquivoService: ArquivoService,
        private taxaService: TaxaService) {
        
        this.dados = new Array<any>();
        this.dataSource = new MatTableDataSource(this.dados);

        this.codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];
        this.codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"];
        this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
        }
    
    ngOnInit() {}

    formatToBRL(valor) {
        return this.mascara.transform(valor, 11);
    }

    acaoImprimirBoleto(row) {
        this.gerarPagamento(row)
    }

    gerarPagamento(row) {
        var taxaPagamento = new TaxaPagamento();
        taxaPagamento.protocolo = row.protocolo;
        taxaPagamento.inscricao = row.inscricao;
        taxaPagamento.meioPagamento.tipo = Constantes.MEIO_PAGAMENTO_TIPO.Boleto_Registrado

        var taxaLancamentoParcela = new TaxaLancamentoParcela();
        taxaLancamentoParcela.codigo = row.codigoParcela;
        taxaLancamentoParcela.codigoLancamento = row.codigoLancamento;

        taxaPagamento.taxaLancamentoParcelas = [taxaLancamentoParcela];

        this.taxaService
            .gerarPagamento(taxaPagamento)
            .pipe(take(1))
            .subscribe( retornoAtendimento => {
                Swal.fire({
                    title: retornoAtendimento.titulo,
                    html: retornoAtendimento.mensagem,
                    icon: retornoAtendimento.status,
                    customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: true,
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    allowOutsideClick: () => !Swal.isLoading(),
                    willClose: () => {
                    }
                })
                .then((result) => {
                    if (!result.dismiss) {
                        if (retornoAtendimento.arquivos.length) {
                            retornoAtendimento.arquivos.forEach( nome => {
                                this.arquivoService.downloadArquivoAtendimento(nome)
                                    .subscribe((res: any) => {
                                        this.arquivoService.handleFile(res, nome + '.pdf');
                                    });
                                })
                        }
                        else {
                            Swal.fire("", "Não foi possível localizar o arquivo.", "error");
                        }
                    }
                });
            });
    }

    atualizarTabela() {
        this.dataSource = new MatTableDataSource(this.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
        this.dataSource.sort = this.sort;
    }

    aplicarFiltro(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    aplicarFiltroPorValor(valor) {
        const filterValue = valor;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    acaoMenu(acao) {
        eval(acao);
    }

    aplicarGridExterno(){
        setTimeout(() => {
            if (this.activatedRoute.snapshot.params["inscricao"] != null) {
                (<HTMLSelectElement>document.getElementById("txtFiltro")).value = this.activatedRoute.snapshot.params["inscricao"];
                this.aplicarFiltroPorValor(this.activatedRoute.snapshot.params["inscricao"]);
                const alerta = Swal.mixin({
                    toast: true,
                    position: "bottom",
                    showConfirmButton: false,
                    timer: 10000,
                    timerProgressBar: true,
                    width: 1000,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });
                alerta.fire({
                    icon: 'warning',
                    title: 'Caro contribuinte, suas dams estão filtradas, remova o filtro para visualizar todas as dams.'
                });
            }
            else if (this.activatedRoute.snapshot.params["protocolo"] != null) {
                (<HTMLSelectElement>document.getElementById("txtFiltro")).value = this.activatedRoute.snapshot.params["protocolo"];
                this.aplicarFiltroPorValor(this.activatedRoute.snapshot.params["protocolo"]);
                const alerta = Swal.mixin({
                    toast: true,
                    position: "bottom",
                    showConfirmButton: false,
                    timer: 10000,
                    timerProgressBar: true,
                    width: 1000,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });
                alerta.fire({
                    icon: 'warning',
                    title: 'Caro contribuinte, suas pendências estão filtradas, remova o filtro para visualizar todas as pendências.'
                });
            }

        }, 1);
    }

    ordenacaoGrid(data, sort) {
        if (sort.active == null)
            return data;

        let campo = sort.active.replace("servico", "servicoDescricao");

        if (campo == "adicionado") {
            data.sort( (item_1, item_2) => (new Date(item_1[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) > new Date(item_2[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (campo == "dataSituacao") {
            data.sort( (item_1, item_2) => (new Date(item_1[campo].split("/").reverse().toString()) > new Date(item_2[campo].split("/").reverse().toString()) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (campo == "inscricao") {
            data.sort( (item_1, item_2) => (item_1[campo] > item_2[campo] ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (typeof(data[0][campo]) == typeof("0")) {
            data.sort( (item_1, item_2) => (item_1[campo]).localeCompare(item_2[campo])  * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (typeof(data[0][campo]) == typeof(0)) {
            data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo])) ? 0 : item_1[campo] ) - ( isNaN(parseInt(item_2[campo])) ? 0 : item_2[campo] ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        
        return data;
    }

    
}