import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeModule } from '../../../pipes/pipes.module';
import { LicenciamentoComponent } from './licenciamento.component';
import { FormQuestionarioModule } from '../form-questionario/form-questionario.module';
import { NgxMaskModule } from 'ngx-mask';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AtendimentoObservacaoModule } from '../atendimento/atendimento-observacao/atendimento-observacao.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InformacoesCadastroModule } from '../informacoes-cadastro/informacoes-cadastro.module';
import { InformacaoRedeSimModule } from '../informacoes-cadastro/informacao-rede-sim/informacao-rede-sim.module';
import { InformacaoRedeSimPerguntasModule } from '../informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.module';


@NgModule({
  declarations: [
    LicenciamentoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormQuestionarioModule,
    NgxMaskModule.forRoot(),
    AtendimentoObservacaoModule,
    InformacoesCadastroModule,
    InformacaoRedeSimModule,
    InformacaoRedeSimPerguntasModule
  ],
  exports: [
    LicenciamentoComponent
  ],
})
export class LicenciamentoModule {}
