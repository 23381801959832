
<li
  class="nav-item dropdown user-profile-dropdown"
  [class.show]="showUserDropdown"
  [class.user-atach]="user"
  [class.web-user]="webUser"
>
  <div ngbDropdown class="d-inline-block">
    <a
      href="javascript:void(0);"
      class="nav-link user"
      id="userProfileDropdown"
      ngbDropdownToggle
    >
      <img
        *ngIf="fotoCliente === null || fotoCliente === ''"
        src="assets/img/placeholder/sem-foto.jpg"
        alt="avatar"
        style="border-radius: 50%;"
      />
      <img
        *ngIf="fotoCliente !== null && fotoCliente !== ''"
        [src]="fotoCliente"
        alt="avatar"
        style="border-radius: 50%;"
      />
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <p class="ml-2 mt-2">
        <ng-content></ng-content>
        <br />
        <small>
          <span class="font-weight-bold">
            ÚLTIMO ACESSO:
          </span>
          {{ usuarioInfos.ultimoAcesso | date: 'dd/MM/yyyy':'pt' }} às
          {{ usuarioInfos.ultimoAcesso | date: 'h:mm:ss':'pt' }}
          <br />
          <span class="font-weight-bold">
            IP:
          </span>
          {{ usuarioInfos.ipAddress }}
        </small>
      </p>
      <div class="dropdown-item" >
        <a
        (click)=redirecionarPerfil();
        class="cursor-pointer"
        >
          <svg class="icone-header">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#meu_perfil"
            ></use>
          </svg>
          Meu Perfil</a
        >
      </div>
      <div class="dropdown-item" >
        <a
          (click)="redirecionaSelecaoPerfil()"
          class="cursor-pointer"
          title="Trocar Perfil"
        >
          <svg class="icone-header">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#troca_perfil"
            ></use>
          </svg>
          Troca de Perfil</a
        >
      </div>
      <div class="dropdown-item" >
        <a
          (click)="redirecionaAlterarSenha()" 
          class="cursor-pointer">
          <svg class="icone-header">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#alterar_senha_02"
            ></use>
          </svg>
          Alterar Senha</a
        >
      </div>
      <div class="dropdown-item" >
        <a (click)="logout()" class="cursor-pointer">
          <svg class="icone-header">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#sair_2"
            ></use>
          </svg>
          Sair</a
        >
      </div>
    </div>
  </div>
</li>
