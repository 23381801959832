import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

import { Constantes } from '../../../../model/constantes';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PerguntaService } from '../../../../services/pergunta/pergunta.service';
import { Pergunta } from '../../../../model/pergunta';
import { Resposta } from '../../../../model/resposta';
import { Atividade } from 'src/app/model/atividade';
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';
import { Atendimento } from 'src/app/model/atendimento';

@Component({
  selector: 'app-cadastrar-pergunta',
  templateUrl: './cadastrar-pergunta.component.html',
  styleUrls: ['../gerenciamento-de-perguntas.component.scss'],
})
export class CadastrarPerguntaComponent implements OnInit {

  formulario: FormGroup;

  pergunta: Pergunta;

  emitirErro: boolean;
  mensagemErro: string;

  atendimento: Array<any> = new Array<any>();
  tipoEmpresa = Constantes.TIPO_EMPRESA;

  acaoAtendimento: string;
  acaoAtendimentoAuxiliar = Constantes.ACAO_ATENDIMENTO;
  
  tipoDePergunta = Object.keys(Constantes.TIPO_DE_PERGUNTA_DESCRICAO);
  tipoDePerguntaDescricao = Constantes.TIPO_DE_PERGUNTA_DESCRICAO;
  
  tipoDeCampoPergunta = Object.keys(Constantes.TIPO_DE_CAMPO_PERGUNTA_DESCRICAO);
  tipoDeCampoPerguntaDescricao = Constantes.TIPO_DE_CAMPO_PERGUNTA_DESCRICAO;

  codigoMenu = null;

  atividadesSelecionadasEmpresa: Array<Atividade> = new Array<Atividade>();
  atividadesSelecionadasAutonomo: Array<Atividade> = new Array<Atividade>();

  constructor(
    private formBuilder: FormBuilder,
    private perguntaService: PerguntaService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    
    this.formulario = this.formBuilder.group({
      nome: ['', [Validators.required]],
      respostas: [''],
      tipo: ['', [Validators.required]],
      status: ['1', [Validators.required]],
      ordem: ['', [Validators.required]],
      obrigatorio: ['', [Validators.required]],
      processo: ['', [Validators.required]],
      staPreencheObrig: ['', [Validators.required]],
      codPerguntaInter: ['', [Validators.required]],
      staImprimeAlvara: ['', [Validators.required]],
      dataFim: ['', [Validators.required]],
      dataInicio: ['', [Validators.required]],
      tpProcesso: ['', [Validators.required]],
      codigoAutomacao: ['', [Validators.required]],
    });

    this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params["codigoPergunta"] != null) {
      this.preencherParaAtendimento(this.activatedRoute.snapshot.params["codigoPergunta"]);
    }
  }
  
  preencherParaAtendimento(codigoPergunta) {
    this.acaoAtendimento = Constantes.ACAO_ATENDIMENTO.Alterar;
    this.perguntaService
      .buscarPorCodigo(localStorage.getItem("pId"), codigoPergunta)
      .pipe(take(1))
      .subscribe((retorno: Pergunta) => {
        this.pergunta = retorno;

        var existeAtividadesSelecionadasEmpresa = this.pergunta.atendimentos.filter(atendimento => atendimento.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa)[0].atividadesExercidas;
        var existeAtividadesSelecionadasAutonomo = this.pergunta.atendimentos.filter(atendimento => atendimento.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo)[0].atividadesExercidas;

        if (existeAtividadesSelecionadasEmpresa != null) {
          this.atividadesSelecionadasEmpresa = existeAtividadesSelecionadasEmpresa;
        }
        if (existeAtividadesSelecionadasAutonomo != null) {
          this.atividadesSelecionadasAutonomo = existeAtividadesSelecionadasAutonomo;
        }

        this.nome.setValue(this.pergunta.nome);
        this.tipo.setValue(this.pergunta.tipo);
        this.status.setValue(this.pergunta.status);
        this.ordem.setValue(this.pergunta.ordem);
        this.obrigatorio.setValue(this.pergunta.obrigatorio);
        this.processo.setValue(this.pergunta.processo);
        this.staPreencheObrig.setValue(this.pergunta.staPreencheObrig);
        this.codPerguntaInter.setValue(this.pergunta.codPerguntaInter);
        this.staImprimeAlvara.setValue(this.pergunta.staImprimeAlvara);
        this.dataFim.setValue(this.pergunta.dataFim);
        this.dataInicio.setValue(this.pergunta.dataInicio);
        this.tpProcesso.setValue(this.pergunta.tpProcesso);
        this.codigoAutomacao.setValue(this.pergunta.codigoAutomacao);

        this.pergunta.respostas.forEach( resposta => {
          this.respostas.setValue(this.respostas.value.concat())
        })

        this.pergunta.respostas.forEach(documento => { this.respostas.setValue(this.respostas.value.concat(documento.nome, ";")) });
        this.respostas.setValue(this.respostas.value.substring(0, this.respostas.value.length - 1));
        
      })
  }

  acaoBotaoVoltar(){     
      this.router.navigate([
        'configuracao/questionario/perguntas', 
        this.activatedRoute.snapshot.params["codigoMenuPai"],
        this.activatedRoute.snapshot.params["codigoOrgao"],
        this.activatedRoute.snapshot.params["codigoMenu"],
      ]);
    
  }

  validarFormulario() {
    if (this.formulario.invalid) {
      let mensagemErro = "";

      if (this.nome.invalid) {
        mensagemErro += "Favor corrigir a descrição.<br>"
      }

      if (mensagemErro === "") {
        this.emitirErro = false;
        return true;
      }

      Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

      this.emitirErro = true;
      this.mensagemErro = mensagemErro;

      return false;
    }

    this.emitirErro = false;
    this.mensagemErro = "";

    return true;
  }

  acaoBotaoSalvar() {
    if (this.validarFormulario()) {
      this.preencher();

      this.perguntaService
      .salvar(this.pergunta)
      .pipe(take(1))
      .subscribe(retorno => {
        Swal.fire({
          title: retorno.titulo,
          html: retorno.mensagem,
          icon: retorno.status,
          willClose: () => {
            if (retorno.status != "error") {
              this.router.navigate([
                "configuracao",
                "questionario",
                "perguntas",
                this.activatedRoute.snapshot.params["codigoMenuPai"],
                this.activatedRoute.snapshot.params["codigoOrgao"],
                this.activatedRoute.snapshot.params["codigoMenu"]
              ]);
            }
          }
        })
      });
    }

  }

  preencher() {
    if (this.acaoAtendimento != Constantes.ACAO_ATENDIMENTO.Alterar) {
      this.pergunta = new Pergunta();
    }

    this.pergunta.nome = this.nome.value;
    this.pergunta.status = this.status.value;
    this.pergunta.processo = this.processo.value;
    this.pergunta.tipo = this.tipo.value;
    this.pergunta.obrigatorio = this.obrigatorio.value;

    this.pergunta.respostas = new Array<Resposta>();

    this.respostas.value.split(";").forEach(resposta => {
      var respostaAuxiliar = new Resposta();
      respostaAuxiliar.nome = resposta;

      this.pergunta.respostas.push(respostaAuxiliar);
    });

    var atendimentoAtividadesEmpresa = new Atendimento();
    var atendimentoAtividadesMei = new Atendimento();
    var atendimentoAtividadesAutonomo = new Atendimento();

    atendimentoAtividadesEmpresa.tipoEmpresa = Constantes.TIPO_EMPRESA.Empresa;
    atendimentoAtividadesMei.tipoEmpresa = Constantes.TIPO_EMPRESA.Mei;
    atendimentoAtividadesAutonomo.tipoEmpresa = Constantes.TIPO_EMPRESA.Autonomo;

    if(this.obrigatorio.value == '1') {
      this.pergunta.atendimentos = [];
    }
    else {
      atendimentoAtividadesEmpresa.atividadesExercidas = this.atividadesSelecionadasEmpresa;
      atendimentoAtividadesAutonomo.atividadesExercidas = this.atividadesSelecionadasAutonomo;

      this.pergunta.atendimentos = [atendimentoAtividadesEmpresa, atendimentoAtividadesMei, atendimentoAtividadesAutonomo];
    }
  
  }

  openDialog(tipoEmpresa) {
    let atividadesSelecionadas;

    if (tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa) {
      atividadesSelecionadas = this.atividadesSelecionadasEmpresa;
    }
    else if (tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo) {
      atividadesSelecionadas = this.atividadesSelecionadasAutonomo;
    } 

    const dialogRef = this.dialog.open(IcadModalComponent, {
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        maxHeight: 'none',
        closeOnNavigation: true,
        data: {
            titulo: "Atividades Para Vincular",
            componente: "form-pergunta-list",
            dados: [atividadesSelecionadas, tipoEmpresa]
        }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === "" || result === null)
            return false;

      this.montarAtividadesSelecionadas(result.atividades, tipoEmpresa);
    });

  }

  montarAtividadesSelecionadas(atividadesSelecionadas, tipoEmpresa) {

    if (tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo || tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo) {
      this.atividadesSelecionadasAutonomo = atividadesSelecionadas;
    }
    else if (tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa) {
      this.atividadesSelecionadasEmpresa = atividadesSelecionadas;
    }

  }

  get nome() {
    return this.formulario.get("nome");
  }
  set nome(nome) {
    this.formulario.get("nome").setValue(nome);
  }

  get respostas() {
    return this.formulario.get("respostas");
  }
  set respostas(respostas) {
    this.formulario.get("respostas").setValue(respostas);
  }

  get tipo() {
    return this.formulario.get("tipo");
  }
  set tipo(tipo) {
    this.formulario.get("tipo").setValue(tipo);
  }

  get status() {
    return this.formulario.get("status");
  }
  set status(status) {
    this.formulario.get("status").setValue(status);
  }

  get ordem() {
    return this.formulario.get("ordem");
  }
  set ordem(ordem) {
    this.formulario.get("ordem").setValue(ordem);
  }

  get obrigatorio() {
    return this.formulario.get("obrigatorio");
  }
  set obrigatorio(obrigatorio) {
    this.formulario.get("obrigatorio").setValue(obrigatorio);
  }

  get processo() {
    return this.formulario.get("processo");
  }
  set processo(processo) {
    this.formulario.get("processo").setValue(processo);
  }

  get staPreencheObrig() {
    return this.formulario.get("staPreencheObrig");
  }
  set staPreencheObrig(staPreencheObrig) {
    this.formulario.get("staPreencheObrig").setValue(staPreencheObrig);
  }

  get codPerguntaInter() {
    return this.formulario.get("codPerguntaInter");
  }
  set codPerguntaInter(codPerguntaInter) {
    this.formulario.get("codPerguntaInter").setValue(codPerguntaInter);
  }

  get staImprimeAlvara() {
    return this.formulario.get("staImprimeAlvara");
  }
  set staImprimeAlvara(staImprimeAlvara) {
    this.formulario.get("staImprimeAlvara").setValue(staImprimeAlvara);
  }

  get dataFim() {
    return this.formulario.get("dataFim");
  }
  set dataFim(dataFim) {
    this.formulario.get("dataFim").setValue(dataFim);
  }

  get dataInicio() {
    return this.formulario.get("dataInicio");
  }
  set dataInicio(dataInicio) {
    this.formulario.get("dataInicio").setValue(dataInicio);
  }

  get tpProcesso() {
    return this.formulario.get("tpProcesso");
  }
  set tpProcesso(tpProcesso) {
    this.formulario.get("tpProcesso").setValue(tpProcesso);
  }

  get codigoAutomacao() {
    return this.formulario.get("codigoAutomacao");
  }
  set codigoAutomacao(codigoAutomacao) {
    this.formulario.get("codigoAutomacao").setValue(codigoAutomacao);
  }
  
}