import { Component, OnInit, ViewChild } from '@angular/core';
import { CadastrarPerguntaComponent } from './cadastrar-pergunta/cadastrar-pergunta.component';
import { Constantes } from '../../../model/constantes';
import { Router } from '@angular/router';


@Component({
  selector: 'app-gerenciamento-de-perguntas',
  templateUrl: './gerenciamento-de-perguntas.component.html',
  styleUrls: ['./gerenciamento-de-perguntas.component.scss'],
})
export class GerenciamentoDePerguntasComponent implements OnInit {

  exibirListagem = true;
  exibirFormularioDeCadastro = false;
  rota = null;

  @ViewChild(CadastrarPerguntaComponent)
  cadastrarpergunta: CadastrarPerguntaComponent;
  
  constructor(private router: Router,) {
    this.rota = this.router.url;
  }

  ngOnInit(): void {}
  
  acaoBotaoVoltar(event) {
    this.router.navigate(["/configuracao/questionario"]);
  }
  
  acaoNovoPergunta(){
    this.router.navigate([ this.router.url, "cadastroPergunta" ]);
  }

  acaoAlterarPergunta(codigoPergunta) {
    this.router.navigate([ this.router.url, "editarPergunta", codigoPergunta ]);    
  }
  
  acaoAtendimento(event) {
    if(event.acao == Constantes.ACAO_ATENDIMENTO.Alterar) {
      this.acaoAlterarPergunta(event.dados.COD_PERGUNTA);
    }
    else if(event.acao == Constantes.ACAO_ATENDIMENTO.Excluir) {
      // this.acaoExcluirRequisito(event.dados.COD_REQUISITO, event.dados.DESC_REQUISITO);
    }
  }


}