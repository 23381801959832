import { Component, OnInit, ViewChild, Renderer2, Input, EventEmitter, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";

import { AtendimentoZoneamentoComponent } from "./atendimento-zoneamento.component"; 
import { AtendimentoAvaliadorComponent } from "./atendimento-avaliador.component";
import { AtendimentoAtividadeComponent } from "./atendimento-atividade.component";
import { AtendimentoRequisitoComponent } from "./atendimento-requisito.component";
import { AtendimentoQuestionarioComponent } from "./atendimento-questionario.component";
import { AtendimentoImobiliarioComponent } from "./atendimento-imobiliario.component";
import { AtendimentoAcaoComponent } from "../atendimento-acao/atendimento-acao.component";
import { AtendimentoService } from "src/app/services/operacao/atendimento.service";
import { AtendimentoConfiguracao } from 'src/app/model/atendimentoConfiguracao';
import { Protocolo } from "src/app/model/protocolo";
import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { Pergunta } from "src/app/model/pergunta";
import { Constantes } from "src/app/model/constantes";
import Swal from "sweetalert2";
import { Fase } from "src/app/model/fase";
import { Servico } from "src/app/model/servico";
import { Atendimento } from "src/app/model/atendimento";
import { AtendimentoEmpresaCnpj } from './atendimento-empresa-cnpj.component';
import { Pendencia } from "src/app/model/pendencia";
import { InformacaoIdentificacaoComponent } from '../../informacoes-cadastro/informacao-identificacao/informacao-identificacao.component';
import { InformacaoLocalizacaoComponent } from '../../informacoes-cadastro/informacao-localizacao/informacao-localizacao.component';
import { InformacaoResponsavelComponent } from '../../informacoes-cadastro/informacao-responsavel/informacao-responsavel.component';
import { AtendimentoCriticaComponent } from './atendimento-critica.component';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewportScroller } from '@angular/common';
import { InformacaoRequerenteComponent } from '../../informacoes-cadastro/informacao-requerente/informacao-requerente.component';
import { AtendimentoEmbasamentoComponent } from './atendimento-embasamento.component';
import { AtendimentoDataAberturaComponent } from './atendimento-data-abertura.component';
import { EmpresaService } from '../../../../services/empresa/empresa.service';
import { Empresa } from '../../../../model/empresa';
import { AtendimentoBaixaComponent } from './atendimento-baixa.component';
import { AtendimentoObservacaoComponent } from '../atendimento-observacao/atendimento-observacao.component';
import { InformacaoQuestionarioComponent } from '../../informacoes-cadastro/informacao-questionario/informacao-questionario.component';
import { InformacaoRedeSimComponent } from '../../informacoes-cadastro/informacao-rede-sim/informacao-rede-sim.component';
import { AtendimentoSeletorImComponent } from '../atendimento-seletor-im/atendimento-seletor-im.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { InformacaoDocumentoComponent } from '../../informacoes-cadastro/informacao-documento/informacao-documento.component';
import { AtendimentoRegerarComponent } from './atendimento-regerar.component';
import { AtendimentoAlvara } from '../../../../model/atendimentoAlvara';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { Despacho } from 'src/app/model/despacho';
import { DespachoFluxo } from 'src/app/model/despachoFluxo';
import { Observacao } from 'src/app/model/observacao';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { Cliente } from 'src/app/model/cliente';
import { LoginService } from 'src/app/services/login/login.service';
import { InformacaoRedeSimPerguntasComponent } from '../../informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.component';
import * as moment from 'moment';


@Component({
  selector: 'app-atendimento-protocolo',
  templateUrl: './atendimento-protocolo.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoProtocoloComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};

  tipoEmpresa: string = "E";
  tipoProcesso = Constantes.PROCESSO;
  processo: string;
  seletorIm: boolean = true;
  eDespacho: boolean = false;

  @Input() numeroProtocolo: string;
  @Input() numeroInscricao: string;
  @Input() permitirAtendimento: boolean;
  orgao: string;
  meusProcessos: boolean;

  empresa: Empresa;
  protocolo: Protocolo;
  protocoloParaAtendimento: Protocolo;
  atendimento: Atendimento;
  atendimentoConfiguracao: AtendimentoConfiguracao;
  pendenciasParaAtendimento: Array<Pendencia>;
  modulo: string;
  menuAtendimento = Constantes.MENU_ATENDIMENTO;
  @Output() configurar: EventEmitter<any> = new EventEmitter();
  eixoConfiguracao: boolean = false;
  atendimentoDespacho: Despacho;

  tipoTelefone = Constantes.TIPO_DE_TELEFONE;

  processoDeAbertura: boolean = true;
  botaoRegerarDocumento: boolean = false;
  
  atendimentoAlvara = new AtendimentoAlvara();

  @ViewChild(AtendimentoAvaliadorComponent)
  atendimentoAvaliador: AtendimentoAvaliadorComponent;
  
  @ViewChild(AtendimentoEmpresaCnpj)
  atendimentoEmpresaCnpj: AtendimentoEmpresaCnpj;
  
  @ViewChild(InformacaoRequerenteComponent)
  informacaoRequerente: InformacaoRequerenteComponent;
  
  @ViewChild(InformacaoRedeSimComponent)
  informacaoRedeSim: InformacaoRedeSimComponent;

  @ViewChild(InformacaoRedeSimPerguntasComponent)
  informacaoRedeSimPerguntas: InformacaoRedeSimPerguntasComponent;

  @ViewChild(InformacaoIdentificacaoComponent)
  informacaoIdentificacao: InformacaoIdentificacaoComponent;

  @ViewChild(InformacaoResponsavelComponent)
  informacaoResponsavel: InformacaoResponsavelComponent;
    
  @ViewChild(InformacaoLocalizacaoComponent)
  informacaoLocalizacao: InformacaoLocalizacaoComponent;

  @ViewChild(AtendimentoImobiliarioComponent)
  atendimentoImobiliario: AtendimentoImobiliarioComponent;

  @ViewChild(AtendimentoZoneamentoComponent)
  atendimentoZoneamento: AtendimentoZoneamentoComponent;

  @ViewChild(AtendimentoAtividadeComponent)
  atendimentoAtividade: AtendimentoAtividadeComponent;

  @ViewChild(AtendimentoRequisitoComponent)
  atendimentoRequisito: AtendimentoRequisitoComponent;

  @ViewChild(InformacaoDocumentoComponent)
  informacaoDocumento: InformacaoDocumentoComponent;

  @ViewChild("informacaoQuestionarioConsultaPrevia")
  informacaoQuestionarioConsultaPrevia: InformacaoQuestionarioComponent;

  @ViewChild("informacaoQuestionarioDadosCadastrais")
  informacaoQuestionarioDadosCadastrais: InformacaoQuestionarioComponent;

  @ViewChild("informacaoQuestionarioRecadastro")
  informacaoQuestionarioRecadastro: InformacaoQuestionarioComponent;

  @ViewChild("informacaoQuestionarioEncerramento")
  informacaoQuestionarioEncerramento: InformacaoQuestionarioComponent;

  @ViewChild("atendimentoQuestionario")
  atendimentoQuestionario: AtendimentoQuestionarioComponent;

  @ViewChild(AtendimentoCriticaComponent)
  atendimentoCritica: AtendimentoCriticaComponent;
  
  @ViewChild(AtendimentoEmbasamentoComponent)
  atendimentoEmbasamento: AtendimentoEmbasamentoComponent;

  @ViewChild(AtendimentoDataAberturaComponent)
  atendimentoDataAbertura: AtendimentoDataAberturaComponent;

  @ViewChild("observacoesGerais")
  atendimentoObservacaoGerais: AtendimentoObservacaoComponent;

  @ViewChild("observacoesDespacho")
  atendimentoObservacaoDespacho: AtendimentoObservacaoComponent;

  @ViewChild(AtendimentoSeletorImComponent)
  atendimentoSeletorIm: AtendimentoSeletorImComponent;

  @ViewChild(AtendimentoBaixaComponent)
  atendimentoBaixa: AtendimentoBaixaComponent;

  @ViewChild(AtendimentoAcaoComponent)
  atendimentoAcao: AtendimentoAcaoComponent;

  @ViewChild(AtendimentoRegerarComponent)
  atendimentoRegerar: AtendimentoRegerarComponent;
  
  moduloTaxaLiberado: boolean = false;

  constructor(
    private document: Document,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private empresaService: EmpresaService,
    private protocoloService: ProtocoloService,
    private atendimentoService: AtendimentoService,
    private arquivoService: ArquivoService,
    public dialog: MatDialog,
    private router: Router,
    private authenticationService: AuthenticationService,
    private viewportScroller: ViewportScroller) {
    
    this.protocolo = new Protocolo();
    this.atendimento = new Atendimento();
    
    this.atendimentoConfiguracao = new AtendimentoConfiguracao();
    this.atendimentoConfiguracao.processo.servicos.push(new Servico());
    this.atendimentoConfiguracao.processo.servicos[0].fases = new Array<Fase>();
    this.atendimentoConfiguracao.processo.servicos[0].fases.push(new Fase());
    this.modulo = this.activatedRoute.snapshot.data["menu"];

    this.viewportScroller.setOffset([0,330]);

    this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];

    authenticationService.currentAxisValue.item.forEach(item => {
      if (item.nome == "moduloTaxa") {
        this.moduloTaxaLiberado = true;
      }
    });

  }

  ngOnInit(): void {
    this.numeroProtocolo = this.numeroProtocolo == null ? (this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "") : this.numeroProtocolo;
    this.numeroInscricao = this.numeroInscricao == null ? (this.activatedRoute.snapshot.params["inscricao"] != null ? this.activatedRoute.snapshot.params["inscricao"] : "") : this.numeroInscricao;
    this.meusProcessos = this.modulo == Constantes.MENU_ATENDIMENTO.Processos;
    this.orgao = this.activatedRoute.snapshot.params["codigoOrgao"];
    
    this.eDespacho = this.activatedRoute.snapshot.data["subMenu"] == "despacho";

    if (this.eDespacho) {
      this.atendimentoDespacho = new Despacho();
      this.atendimentoDespacho.codigo = this.activatedRoute.snapshot.params["codigoDespacho"];
      this.atendimentoDespacho.protocolo = this.numeroProtocolo;
      this.atendimentoDespacho.inscricao = this.numeroInscricao;
    }

    this.permitirAtendimento = this.permitirAtendimento == null ? (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !this.meusProcessos) : this.permitirAtendimento;

    this.permitirAtendimento = this.eDespacho ? false : this.permitirAtendimento;

    this.protocoloService
      .detalharParaAtendimento(localStorage.getItem("pId"), this.numeroProtocolo)
      .pipe(take(1))
      .subscribe( retorno => {
        Object.assign(this.protocolo, retorno[0]);
        Object.assign(this.atendimentoConfiguracao, retorno[1]);
        this.atendimentoAvaliador.protocolo = retorno[0];
        this.buscarParaAtendimento();                
    });
  } 

  goToAnchor(ancora) {
    this.viewportScroller.scrollToAnchor(ancora);
    this.document.querySelectorAll(".nav-link").forEach( item => this.renderer.removeClass(item, "active"));
    this.renderer.addClass(this.document.querySelector("." + ancora), "active");    
  }
      
  buscarParaAtendimento() {
    this.protocolo.criticas.forEach(critica => {
      this.atendimentoConfiguracao.criticas.filter( criticaAtendimento => { 
        return criticaAtendimento.codigo == critica.codigo && criticaAtendimento.nmColuna == critica.nmColuna
      }).forEach( item => {
        item.justificativa = critica.justificativa
        item.valorAntigo = critica.valorAntigo
      })
    });
    
    this.protocolo.criticas = this.atendimentoConfiguracao.criticas;

    if(this.atendimentoConfiguracao.processo.descricao == 'Evento Pessoa Jurídica'){
      this.tipoEmpresa = 'EEV';
    }
    else if(this.atendimentoConfiguracao.processo.descricao == 'Evento Pessoa Física'){
      this.tipoEmpresa = 'AEV'
    }
    else if(this.atendimentoConfiguracao.processo.descricao == 'Alteração de Autônomo não Estabelecido por Ofício'){
      this.tipoEmpresa = 'A'
    }
    else{
      this.tipoEmpresa = this.atendimentoConfiguracao.processo.tipoPessoa == Constantes.TIPO_PESSOA.Fisica ? "A" : "E";
    }
    
    this.processo = this.atendimentoConfiguracao.processo.servicos[0].fases.find(item => item.situacaoAtual != "") != null ? 
      this.atendimentoConfiguracao.processo.servicos[0].fases.find(item => item.situacaoAtual != "").codigoTipoAnalise :
      this.atendimentoConfiguracao.processo.servicos[0].fases[this.atendimentoConfiguracao.processo.servicos[0].fases.length - 1].codigoTipoAnalise;
    this.buscarPendenciasParaAtendimento();

    this.atendimentoAcao.tipoProcesso = this.protocolo.processo.tipo;
  }

  async buscarPendenciasParaAtendimento() {
    await this.atendimentoService
      .buscarPendenciasParaAtendimentoPorOrgao(this.processo, this.orgao)
      .pipe(take(1))
      .subscribe( (retorno: Array<Pendencia>) => {
        if (this.permitirAtendimento) {
          this.atendimentoAcao.pendencias = retorno;
        }

        this.buscarOrgaosParaDespacho()
      });
  }

  async buscarOrgaosParaDespacho() {
    await this.atendimentoService
      .buscarOrgaosParaDespacho(this.processo)
      .pipe(take(1))
      .subscribe( (retorno: Array<Despacho>) => {
        if (this.permitirAtendimento) {
          this.atendimentoAcao.despachos = retorno;
        }
        
        this.buscarEmpresa();
      });
  }

  async buscarEmpresa() {
    if (this.modulo == Constantes.MENU_ATENDIMENTO.Baixa) {
      await this.empresaService
        .buscarDadosBasicosParaAtendimentoDeBaixa(localStorage.getItem("pId"), this.numeroInscricao)
        .pipe(take(1))
        .subscribe( retornoEmpresa => {
          this.empresa = retornoEmpresa;          
          this.preencher();          
      });
    }
    else {
      this.preencher();      
    }
  }

  // ---------------------------------------------------------
  // Preenchimentos para carregar os dados para atendimento
  // ---------------------------------------------------------
  preencher() {
    if(this.protocolo.redeSim.protocoloRedeSim  != "") {
      this.informacaoRedeSim.protocolo = this.protocolo;   
      //this.informacaoRedeSimPerguntas.protocolo = this.protocolo;
    }
    this.informacaoRequerente.protocolo = this.protocolo;
    this.atendimentoAtividade.protocolo = this.protocolo;
    this.atendimentoAtividade.preencher(this.atendimentoConfiguracao);
    
    if (this.modulo != Constantes.MENU_ATENDIMENTO.Processos && !this.eDespacho) {
      this.atendimentoQuestionario.formQuestionario.permitirAtendimento = this.permitirAtendimento;
    }

    this.processoDeAbertura =  (this.protocolo.processo.codigo.toString() == Constantes.SERVICO_DE_ACOMPANHAMENTO.Abertura_De_Empresa.toString() ||
    this.protocolo.processo.codigo.toString() == Constantes.SERVICO_DE_ACOMPANHAMENTO.Abertura_De_Mei.toString() ||
    this.protocolo.processo.codigo.toString() == Constantes.SERVICO_DE_ACOMPANHAMENTO.Abertura_De_Autonomo.toString() ||
    this.protocolo.processo.codigo.toString() == Constantes.SERVICO_DE_ACOMPANHAMENTO.Abertura_De_Profissional_Liberal.toString() ||
    this.protocolo.processo.codigo.toString() == Constantes.SERVICO_DE_ACOMPANHAMENTO.Abertura_De_Empresa_Por_Oficio.toString() ||
    this.protocolo.processo.codigo.toString() == Constantes.SERVICO_DE_ACOMPANHAMENTO.Abertura_De_Mei_Por_Oficio.toString() ||
    this.protocolo.processo.codigo.toString() == Constantes.SERVICO_DE_ACOMPANHAMENTO.Abertura_De_Autonomo_Por_Oficio.toString() ||
    this.protocolo.processo.codigo.toString() == Constantes.SERVICO_DE_ACOMPANHAMENTO.Abertura_De_Autonomo_Nao_Estabelecido.toString());
    
    this.informacaoDocumento.protocolo = new Protocolo();
    this.informacaoDocumento.protocolo.documentos = this.protocolo.documentos;

    if (!this.eDespacho) {
      this.atendimentoObservacaoDespacho.tituloHistorico = "Histórico de Despachos";
      this.atendimentoObservacaoDespacho.permitirAtendimento = false; 
    }

    this.preencherAtendimento();
    this.preencherAtendimentoDespacho();
  }

  preencherAtendimento() {

    if(!((this.protocolo.processo.codigo == '3' || this.protocolo.processo.codigo == '8') 
        && this.modulo == this.menuAtendimento.Dados_Cadastrais 
        && this.permitirAtendimento 
        && this.protocolo.redeSim.protocoloRedeSim != null 
        && this.protocolo.redeSim.protocoloRedeSim != '' 
        && this.protocolo.inscricao == '')){
      this.seletorIm = false;
    }
    
    if (this.modulo == Constantes.MENU_ATENDIMENTO.Consulta_Previa || this.modulo == Constantes.MENU_ATENDIMENTO.Consulta_Previa_Evento) {
      this.preencherAtendimentoConsultaPrevia();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Dados_Cadastrais || this.modulo == Constantes.MENU_ATENDIMENTO.Dados_Cadastrais_Evento) {
      this.preencherAtendimentoDadosCadastrais();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Baixa) {
      this.preencherAtendimentoBaixa();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Oficio) {
      this.preencherAtendimentoOficio();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Recadastro) {
      this.preencherAtendimentoRecadastro();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Processos) {
      this.preencherParaDetalhamentoDeProcessos();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Cancelamento) {
      this.preencherAtendimentoCancelamento();
    }
    else if (this.eDespacho) {
      this.preencherAtendimentoConsultaPrevia();
      this.preencherAtendimentoDadosCadastrais();
      this.preencherParaDetalhamentoDeProcessos();
    }
  }

  preencherAtendimentoConsultaPrevia() {
    this.informacaoLocalizacao.protocolo = this.protocolo;
    this.informacaoLocalizacao.processo = this.processo;
    this.atendimentoImobiliario.preencher(this.atendimentoConfiguracao);

    this.atendimentoZoneamento.preencher(this.atendimentoConfiguracao, this.protocolo);
    this.atendimentoRequisito.preencher(this.atendimentoConfiguracao.viabilidade.requisitos, this.protocolo);

    this.informacaoQuestionarioConsultaPrevia.perguntasUsuario = this.protocolo.viabilidade.perguntasUsuario;

    let pergunta = new Pergunta();
    pergunta.cliente = localStorage.getItem("pId");
    pergunta.codigo = this.activatedRoute.snapshot.params["codigoOrgao"];
    pergunta.processo = this.activatedRoute.snapshot.params["codigoMenuPai"];
    pergunta.tipo = Constantes.TIPO_DE_PERGUNTA.Auditor;    
    
    this.atendimentoQuestionario.perguntas = this.protocolo.viabilidade.perguntasAuditor.perguntas;
    this.atendimentoQuestionario.preencher(pergunta);

    this.atendimentoObservacaoGerais.preencher(this.protocolo.viabilidade.observacoes);
    this.atendimentoEmbasamento.atendimentoConfiguracao = this.atendimentoConfiguracao;
    this.atendimentoEmbasamento.embasamentosSelecionados = this.protocolo.viabilidade.embasamentos;
    this.atendimentoEmbasamento.buscarConfiguracaoEmbasamento();

    if (this.permitirAtendimento) {
      this.atendimentoAcao.mostrarBotaoPendenteOrgao = true;
    }

  }

  preencherAtendimentoDadosCadastrais() {
    this.informacaoLocalizacao.protocolo = this.protocolo;
    this.informacaoLocalizacao.processo = this.processo;
    this.informacaoIdentificacao.protocolo = this.protocolo;
    this.atendimentoEmpresaCnpj.cliente = this.protocolo.cliente;
    this.atendimentoEmpresaCnpj.protocolo = this.protocolo.protocolo;

    if (this.protocolo.processo.tipoPessoa == Constantes.TIPO_PESSOA.Fisica) {
      this.atendimentoEmpresaCnpj.documento = this.protocolo.registro.documentos.find(item => item.tipo == Constantes.TIPO_DE_DOCUMENTO.Cpf);
    }
    else {
      this.atendimentoEmpresaCnpj.documento = this.protocolo.registro.documentos.find(item => item.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj);
    }
    
    if(this.seletorIm){
      this.atendimentoSeletorIm.cliente = this.protocolo.cliente;
      this.atendimentoSeletorIm.protocolo = this.protocolo.protocolo;
      this.atendimentoSeletorIm.documento = this.protocolo.registro.documentos.find(item => item.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj);
    }
    this.atendimentoEmpresaCnpj.carregarEmpresas();
    if (this.protocolo.processo.tipoPessoa != Constantes.TIPO_PESSOA.Fisica) {
      if(this.seletorIm){
        this.atendimentoSeletorIm.carregarEmpresas();
      }
    }
    
    if (this.permitirAtendimento) {
      this.atendimentoCritica.protocolo = this.protocolo;
    }
    
    if(this.modulo != Constantes.MENU_ATENDIMENTO.Dados_Cadastrais_Evento){
      this.informacaoResponsavel.protocolo = this.protocolo;
    }
    
    
    this.atendimentoAtividade.permitirAtendimento = false;
    this.atendimentoRequisito.preencher(this.atendimentoConfiguracao.viabilidade.requisitos, this.protocolo);
    
    this.informacaoQuestionarioConsultaPrevia.perguntasUsuario = this.protocolo.viabilidade.perguntasUsuario;
    this.informacaoQuestionarioDadosCadastrais.perguntasUsuario = this.protocolo.fic.perguntasUsuario;

    let pergunta = new Pergunta();
    pergunta.cliente = localStorage.getItem("pId");
    pergunta.codigo = this.activatedRoute.snapshot.params["codigoOrgao"];
    pergunta.processo = this.activatedRoute.snapshot.params["codigoMenuPai"];
    pergunta.tipo = Constantes.TIPO_DE_PERGUNTA.Auditor;    
    
    this.atendimentoQuestionario.perguntas = this.protocolo.fic.perguntasAuditor.perguntas;
    this.atendimentoQuestionario.preencher(pergunta);

    this.atendimentoObservacaoGerais.preencher(this.protocolo.fic.observacoes);
    
    if (this.permitirAtendimento) {
      this.atendimentoAcao.mostrarBotaoPendenteOrgao = true;
    }
    
    this.protocolo.fic.atendimentos.forEach(atendimento =>{
      if(atendimento.parecer == 'S'){
        this.botaoRegerarDocumento = true;

        var vPossuiDispensaDeAlvara = this.protocolo.documentos.filter(documento => { return documento.tipo.toString() == Constantes.TIPO_DOCUMENTO.Dispensa_de_Alvara.toString() });
        
        setTimeout(() => {
          if (this.atendimentoRegerar != null) {
            this.atendimentoRegerar.permitirRegerarDispensaDeAlvara = vPossuiDispensaDeAlvara.length > 0;
            this.atendimentoRegerar.permitirRegerarAlvaraProvisorio = !this.atendimentoRegerar.permitirRegerarDispensaDeAlvara;
            this.atendimentoRegerar.numeroInscricao = this.protocolo.inscricao;
            this.atendimentoRegerar.protocolo = this.protocolo;
          }
        }, 10);
      }
    });
    

    this.protocolo.criticas.forEach(critica => {
      this.atendimentoConfiguracao.criticas.filter( criticaAtendimento => { 
        return criticaAtendimento.codigo == critica.codigo && criticaAtendimento.nmColuna == critica.nmColuna
      }).forEach( item => {
        item.justificativa = critica.justificativa
        item.valorAntigo = critica.valorAntigo
      })
    });

    if (this.protocolo.inscricao == null || this.protocolo.inscricao == "") {
      this.atendimentoAcao.tipoEmpresa = this.protocolo.registro.tipoPessoa;
    }

  }

  preencherAtendimentoBaixa() {
    if (this.empresa.viabilidade.enderecos.length) {
      this.empresa.viabilidade.enderecos = this.protocolo.viabilidade.enderecos;
    }
    if (this.empresa.viabilidade.atividadesExercidas.length){
      this.empresa.viabilidade.atividadesExercidas = this.protocolo.viabilidade.atividadesExercidas;
    }
    if (this.empresa.viabilidade.atividadesNaoExercidas.length){
      this.empresa.viabilidade.atividadesNaoExercidas = this.protocolo.viabilidade.atividadesNaoExercidas;
    }
    if(this.empresa.fic.socios.length){
      this.empresa.fic.socios = this.protocolo.fic.socios;
    }

    this.protocolo.registro = this.empresa.registro;
    this.protocolo.viabilidade = this.empresa.viabilidade;
    this.protocolo.fic = this.empresa.fic;
    
    this.informacaoIdentificacao.protocolo = this.protocolo;
    this.informacaoLocalizacao.protocolo = this.protocolo;
    
    let perguntaContribuinte = new Pergunta();
    perguntaContribuinte.cliente = localStorage.getItem("pId");
    perguntaContribuinte.codigo = this.activatedRoute.snapshot.params["codigoOrgao"];
    perguntaContribuinte.processo = this.activatedRoute.snapshot.params["codigoMenuPai"];
    perguntaContribuinte.tipo = Constantes.TIPO_DE_PERGUNTA.Encerramento;    
    
    this.informacaoQuestionarioEncerramento.perguntasUsuario = this.protocolo.encerramento.perguntasUsuario;

    let pergunta = new Pergunta();
    pergunta.cliente = localStorage.getItem("pId");
    pergunta.codigo = this.activatedRoute.snapshot.params["codigoOrgao"];
    pergunta.processo = this.activatedRoute.snapshot.params["codigoMenuPai"];
    pergunta.tipo = Constantes.TIPO_DE_PERGUNTA.Auditor;    
    
    this.atendimentoQuestionario.perguntas = this.protocolo.encerramento.perguntasAuditor.perguntas;
    this.atendimentoQuestionario.preencher(pergunta);

    this.atendimentoBaixa.protocolo = this.protocolo;
    this.atendimentoObservacaoGerais.preencher(this.protocolo.encerramento.observacoes);
    
    if(this.protocolo.encerramento.data != ''){
      this.botaoRegerarDocumento = true;
    }
  }

  preencherAtendimentoOficio() {
    console.log("Oficio");
  }

  preencherAtendimentoRecadastro() {
    this.informacaoLocalizacao.protocolo = this.protocolo;
    this.informacaoIdentificacao.protocolo = this.protocolo;
    this.atendimentoRequisito.preencher(this.atendimentoConfiguracao.viabilidade.requisitos, this.protocolo);
    this.informacaoQuestionarioRecadastro.perguntasUsuario = this.protocolo.fic.perguntasUsuario;

    let pergunta = new Pergunta();
    pergunta.cliente = localStorage.getItem("pId");
    pergunta.codigo = this.activatedRoute.snapshot.params["codigoOrgao"];
    pergunta.processo = this.activatedRoute.snapshot.params["codigoMenuPai"];
    pergunta.tipo = Constantes.TIPO_DE_PERGUNTA.Auditor;
    this.informacaoResponsavel.protocolo = this.protocolo;
        
    this.atendimentoQuestionario.perguntas = this.protocolo.fic.perguntasUsuario.perguntas;
    this.atendimentoQuestionario.preencher(pergunta);
    this.atendimentoObservacaoGerais.preencher(this.protocolo.viabilidade.observacoes);

    if(this.permitirAtendimento){
      this.atendimentoAcao.mostrarBotaoViabilidade = true;
    }

    this.protocolo.recadastro.atendimentos.forEach(atendimento =>{
      if(atendimento.parecer == 'S'){
        this.botaoRegerarDocumento = true;

        var vPossuiDispensaDeAlvara = this.protocolo.documentos.filter(documento => { return documento.tipo.toString() == Constantes.TIPO_DOCUMENTO.Dispensa_de_Alvara.toString() });
        
        setTimeout(() => {
          if (this.atendimentoRegerar != null) {
            this.atendimentoRegerar.permitirRegerarDispensaDeAlvara = vPossuiDispensaDeAlvara.length > 0;
            this.atendimentoRegerar.permitirRegerarAlvaraProvisorio = !this.atendimentoRegerar.permitirRegerarDispensaDeAlvara;
            this.atendimentoRegerar.numeroInscricao = this.protocolo.inscricao;
            this.atendimentoRegerar.protocolo = this.protocolo;
          }
        }, 10);
      }
    });    
    
  }

  preencherParaDetalhamentoDeProcessos() {
    this.informacaoIdentificacao.protocolo = this.protocolo;
    this.informacaoLocalizacao.protocolo = this.protocolo;
    this.informacaoResponsavel.protocolo = this.protocolo;
    this.atendimentoRequisito.preencher(this.atendimentoConfiguracao.viabilidade.requisitos, this.protocolo);
    if (this.informacaoQuestionarioConsultaPrevia != null) {
      this.informacaoQuestionarioConsultaPrevia.perguntasUsuario = this.protocolo.viabilidade.perguntasUsuario;
    }
    if (this.informacaoQuestionarioDadosCadastrais != null) {
      this.informacaoQuestionarioDadosCadastrais.perguntasUsuario = this.protocolo.fic.perguntasUsuario;
    }
    if (this.informacaoQuestionarioEncerramento != null) {
      this.informacaoQuestionarioEncerramento.perguntasUsuario = this.protocolo.encerramento.perguntasUsuario;
    }
    if (this.informacaoQuestionarioRecadastro != null) {
      this.informacaoQuestionarioRecadastro.perguntasUsuario = this.protocolo.fic.perguntasUsuario;
    }
    this.atendimentoEmbasamento.atendimentoConfiguracao = this.atendimentoConfiguracao;
    this.atendimentoEmbasamento.embasamentosSelecionados = this.protocolo.viabilidade.embasamentos;

    this.atendimentoObservacaoGerais.preencher(this.protocolo.encerramento.observacoes.filter(item => item.tipo == 'Recadastro' || item.tipo == 'Baixa').concat(this.protocolo.fic.observacoes.filter(item => item.tipo == 'Recadastro' || item.tipo == 'Dados Cadastrais')).concat(this.protocolo.viabilidade.observacoes.filter(item => item.tipo == 'Recadastro' || item.tipo == 'Consulta Prévia')));

    console.log(this.protocolo);

    if(this.protocolo.processo.servicos[0].codigo == 164){
      let dataAtual  = moment(this.protocolo.data,'DD/MM/YYYY').format('DD/MM/YYYY');
      Swal.fire("", "Negócio reativado em " + dataAtual + ". Realize um processo de alteração para atualização e regularização do negócio.", "error");
    }
  }

  preencherAtendimentoCancelamento() {
    if (this.informacaoIdentificacao != null) {
      this.informacaoIdentificacao.protocolo = this.protocolo;
    }
    if (this.informacaoLocalizacao != null) {
      this.informacaoLocalizacao.protocolo = this.protocolo;
    }
    if (this.informacaoResponsavel != null) {
      this.informacaoResponsavel.protocolo = this.protocolo;
    }
    if (this.atendimentoRequisito != null) {
      this.atendimentoRequisito.preencher(this.atendimentoConfiguracao.viabilidade.requisitos, this.protocolo);
    }
    if (this.informacaoQuestionarioConsultaPrevia != null) {
      this.informacaoQuestionarioConsultaPrevia.perguntasUsuario = this.protocolo.viabilidade.perguntasUsuario;
    }
    if (this.informacaoQuestionarioDadosCadastrais != null) {
      this.informacaoQuestionarioDadosCadastrais.perguntasUsuario = this.protocolo.fic.perguntasUsuario;
    }
    if (this.informacaoQuestionarioEncerramento != null) {
      this.informacaoQuestionarioEncerramento.perguntasUsuario = this.protocolo.encerramento.perguntasUsuario;
    }
    if (this.informacaoQuestionarioRecadastro != null) {
      this.informacaoQuestionarioRecadastro.perguntasUsuario = this.protocolo.fic.perguntasUsuario;
    }
    if (this.atendimentoEmbasamento != null) {
      this.atendimentoEmbasamento.atendimentoConfiguracao = this.atendimentoConfiguracao;
      this.atendimentoEmbasamento.embasamentosSelecionados = this.protocolo.viabilidade.embasamentos;
    }
    if (this.atendimentoObservacaoGerais != null) {
      this.atendimentoObservacaoGerais.preencher(this.protocolo.encerramento.observacoes.filter(item => item.tipo == 'Baixa').concat(this.protocolo.fic.observacoes.filter(item => item.tipo == 'Dados Cadastrais')).concat(this.protocolo.viabilidade.observacoes.filter(item => item.tipo == 'Consulta Prévia')));
    }
    this.atendimentoAtividade.permitirAtendimento = false;
    this.atendimentoRequisito.permitirAtendimento = false;
  }

  async preencherAtendimentoDespacho() {
    let observacoesDespacho = new Array<Observacao>();

    await this.protocoloService
      .buscarSolicitacoesDeDespachoPorProtocolo(localStorage.getItem("pId"), this.numeroProtocolo, this.orgao)
      .pipe(take(1))
      .subscribe( (retorno: Despacho) => {             
        retorno.fluxos.forEach( fluxo => {
          let observacao = new Observacao();
          observacao.data = fluxo.data;
          observacao.avaliacao = fluxo.status;
          observacao.processo = fluxo.orgaoOrigem.codigo == this.orgao ? `${ fluxo.orgaoDestino.codigo } - ${ fluxo.orgaoDestino.nome }` : `${ fluxo.orgaoOrigem.codigo } - ${ fluxo.orgaoOrigem.nome }`;
          observacao.login.nome = fluxo.login.nome;
          observacao.observacao = fluxo.observacao;
          observacoesDespacho.push(observacao);
        });
    
        this.atendimentoObservacaoDespacho.preencher(observacoesDespacho);
    });
  }

  // ---------------------------------------------------------
  // Preenchimento com as informações de atendimento realizado
  // ---------------------------------------------------------
  preencherParaAtender() {
    this.protocoloParaAtendimento = new Protocolo();
    this.protocoloParaAtendimento.protocolo = this.protocolo.protocolo;
    this.protocoloParaAtendimento.processo = this.protocolo.processo;
    this.protocoloParaAtendimento.requerente = this.protocolo.requerente;
    this.protocoloParaAtendimento.solicitante = this.protocolo.solicitante;
    this.protocoloParaAtendimento.viabilidade.zonaUsos = this.atendimentoConfiguracao.viabilidade.zonaUsos;
    this.protocoloParaAtendimento.viabilidade.zonas = this.atendimentoConfiguracao.viabilidade.zonas;
    this.protocoloParaAtendimento.viabilidade.usos = this.atendimentoConfiguracao.viabilidade.usos;
    
    this.atendimentoQuestionario.formQuestionario.controleValidade.setValue("true");
    this.atendimentoQuestionario.formQuestionario.preencher();

    this.atendimento = new Atendimento();
    this.atendimento.perguntasAuditor = this.atendimentoQuestionario.formQuestionario.perguntasRespostas;
    this.atendimento.pendencias = this.atendimentoAcao.pendenciasSelecionadas;

    if (this.modulo == Constantes.MENU_ATENDIMENTO.Consulta_Previa || this.modulo == Constantes.MENU_ATENDIMENTO.Consulta_Previa_Evento) {
      this.preencherParaAtenderConsultaPrevia();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Dados_Cadastrais || this.modulo == Constantes.MENU_ATENDIMENTO.Dados_Cadastrais_Evento) {
      this.preencherParaAtenderDadosCadastrais();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Baixa) {
      this.preencherParaAtenderBaixa();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Oficio) {
      this.preencherParaAtenderOficio();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Recadastro) {
      this.preencherParaAtenderRecadastro();
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Cancelamento) {
      this.preencherParaAtenderCancelamento();
    }
    else if (this.eDespacho) {
      this.preencherParaAtenderDespacho();
    }
    
    //não pode ter pra alteração
    if(this.atendimentoAcao.geraInscricao == 'M' && this.atendimentoAcao.tipoProcesso != 'U' && this.atendimentoAcao.tipoProcesso != 'I' && this.atendimentoAcao.tipoProcesso != 'S' && this.atendimentoAcao.tipoProcesso != 'R'){
     this.validarInscricao(this.atendimentoAcao.inscricaoDigitada.value,this.atendimentoAcao.inscricaoAutomatica.value);
    }


    
  }

  preencherParaAtenderConsultaPrevia() {
    this.atendimento.zonas = this.atendimentoZoneamento.zonasAdicionadas;
    this.atendimento.usos = this.atendimentoZoneamento.usosAdicionados;
    this.atendimento.atividadesExercidas = this.atendimentoAtividade.atendimentoConfiguracao.viabilidade.atividadesExercidas;
    this.atendimento.atividadesNaoExercidas = this.atendimentoAtividade.atendimentoConfiguracao.viabilidade.atividadesNaoExercidas;
    this.atendimento.requisitos = this.atendimentoRequisito.requisitosAdicionados;
    this.protocoloParaAtendimento.viabilidade.embasamentos = this.atendimentoEmbasamento.embasamentosSelecionados;
    this.atendimento.observacao = this.atendimentoObservacaoGerais.observacao.value;
    this.protocoloParaAtendimento.viabilidade.atendimentos = [this.atendimento];

  }

  preencherParaAtenderDadosCadastrais() {
    this.atendimento.requisitos = this.atendimentoRequisito.requisitosAdicionados;
    this.atendimento.criticas = this.protocolo.criticas;
    this.atendimento.observacao = this.atendimentoObservacaoGerais.observacao.value;
    this.protocoloParaAtendimento.fic.atendimentos = [this.atendimento];
    this.protocoloParaAtendimento.fic.socios = this.protocolo.fic.socios;

    if (this.atendimentoDataAbertura != null) {
      this.protocoloParaAtendimento.data = this.atendimentoDataAbertura.dataAbertura.value;
    }

    if(this.atendimentoAcao.geraInscricao == 'M' ){
      this.protocoloParaAtendimento.inscricao = this.atendimentoAcao.inscricaoDigitada.value;
    }
    
  }

  preencherParaAtenderBaixa() {
    this.atendimento.atividadesExercidas = this.atendimentoAtividade.atendimentoConfiguracao.viabilidade.atividadesExercidas;
    this.atendimento.atividadesNaoExercidas = this.atendimentoAtividade.atendimentoConfiguracao.viabilidade.atividadesNaoExercidas;
    
    this.atendimento.observacao = this.atendimentoObservacaoGerais.observacao.value;
    this.protocoloParaAtendimento.viabilidade.atendimentos = [this.atendimento];
    this.protocoloParaAtendimento.encerramento.atendimentos = [this.atendimento];
    this.protocoloParaAtendimento.encerramento.data = this.atendimentoDataAbertura.dataAbertura.value;
  }

  preencherParaAtenderOficio() {

  }

  preencherParaAtenderRecadastro() {
    this.atendimento.requisitos = this.atendimentoRequisito.requisitosAdicionados;
    this.atendimento.criticas = this.protocolo.criticas;
    this.atendimento.observacao = this.atendimentoObservacaoGerais.observacao.value;
    this.protocoloParaAtendimento.fic.atendimentos = [this.atendimento];
  }

  preencherParaAtenderCancelamento() {
    this.atendimento.observacao = this.atendimentoObservacaoGerais.observacao.value;
    this.protocoloParaAtendimento.fic.atendimentos = [this.atendimento];
  }

  preencherParaAtenderDespacho() {
    var despachoFluxo = new DespachoFluxo();
    despachoFluxo.orgaoOrigem.codigo = this.orgao;
    despachoFluxo.observacao = this.atendimentoObservacaoGerais.observacao.value;

    this.atendimentoDespacho.fluxos = [despachoFluxo];
  }

  // ---------------------------------------------------------
  // Validações do pedido de atendimento
  // ---------------------------------------------------------
  validar(acaoAtendimento) {
    if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Pendenciar || acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Responder_Despacho) {
      return true;
    }

    if (this.modulo == Constantes.MENU_ATENDIMENTO.Consulta_Previa || this.modulo == Constantes.MENU_ATENDIMENTO.Consulta_Previa_Evento) {
      return this.validarConsultaPrevia(acaoAtendimento);
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Dados_Cadastrais || this.modulo == Constantes.MENU_ATENDIMENTO.Dados_Cadastrais_Evento) {
      return this.validarDadosCadastrais(acaoAtendimento);
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Baixa) {
      return this.validarBaixa(acaoAtendimento);
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Oficio) {
      return this.validarOficio(acaoAtendimento);
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Recadastro) {
      return this.validarRecadastro(acaoAtendimento);
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Cancelamento) {
      return this.validarCancelamento(acaoAtendimento);
    }
  }

  validarConsultaPrevia(acaoAtendimento) {
    
    if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Deferir) {
      if (!this.atendimentoZoneamento.validarFormulario(true, true)) {
        return false;
      }
      if (!this.atendimentoAtividade.validarFormulario()) {
        return false;
      }
      if (!this.atendimentoQuestionario.formQuestionario.validarFormulario()) {
        return false;
      }
      if (!this.atendimentoEmbasamento.validarFormulario()) {
        return false
      }
    }
    else if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Indeferir) {
      if (!this.atendimentoZoneamento.validarFormulario(true, false)) {
        return false;
      }
    }

    return true;
  }

  validarDadosCadastrais(acaoAtendimento) {
    if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Deferir) {
       
      if (!this.atendimentoQuestionario.formQuestionario.validarFormulario()) {
        return false;
      }
      if (this.processoDeAbertura && !this.atendimentoDataAbertura.validarFormulario()) {
        return false;
      }
      if(this.seletorIm){
        if(this.atendimentoSeletorIm.novaIm.value == ""){
          Swal.fire("", "Selecione uma inscrição municipal ou opte por gerar uma nova.", "error");
          return false;
        }
        this.atendimentoSeletorIm.preencher();
        if(this.atendimentoSeletorIm.inputSeletorIm != 'novaIm' && this.atendimentoSeletorIm.inputSeletorIm != ''){
          this.protocoloService
            .vincularInscricao(this.protocoloParaAtendimento.protocolo,this.atendimentoSeletorIm.inputSeletorIm)
            .pipe(take(1))
            .subscribe(retorno => {
          
            })
        }
      }
    }

    return true;
  }
  
  validarBaixa(acaoAtendimento) {
    if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Deferir) {
      if (!this.atendimentoQuestionario.formQuestionario.validarFormulario()) {
        return false;
      }
      if (!this.atendimentoDataAbertura.validarFormulario()) {
        return false;
      }
      if (!this.atendimentoAtividade.validarFormularioBaixa()) {
        return false;
      }
    }

    return true;
  }

  validarOficio(acaoAtendimento) {

  }

  validarRecadastro(acaoAtendimento) {
    /*if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Deferir) {
      if (!this.atendimentoQuestionario.formQuestionario.validarFormulario()) {
        return false;
      }
      if (!this.atendimentoDataAbertura.validarFormulario()) {
        return false;
      }
    }*/

    return true;
  }

  validarCancelamento(acaoAtendimento) {
    /*if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Deferir) {
      if (!this.atendimentoQuestionario.formQuestionario.validarFormulario()) {
        return false;
      }
      if (!this.atendimentoDataAbertura.validarFormulario()) {
        return false;
      }
    }*/

    return true;
  }

  validarInscricao(inscricao,flag){

    if(inscricao == "" && flag == ""){
      Swal.fire("", "Selecione uma opção de inscrição municipal.", "error");
      return false;
    }

    this.buscarPorInscricaoDadosBasicos(inscricao);
  }

   buscarPorInscricaoDadosBasicos(inscricao) {
      this.empresaService
        .buscarPorInscricaoDadosBasicos(localStorage.getItem("pId"), inscricao)
        .pipe(take(1))
        .subscribe( (retorno: Empresa) => {
          if(retorno.registro.nome != ""){
            Swal.fire("", "Inscrição municipal já existente em nossa base de dados.", "error");
            this.atendimentoAcao.inscricaoValida = false;
            return false;
          }else{
            this.atendimentoAcao.inscricaoValida = true;
          }
          
        })
  }

  // ---------------------------------------------------------
  // Ações solicitadas no atendimento
  // ---------------------------------------------------------
  atender(acaoAtendimento) {
    this.preencherParaAtender();

    if(this.modulo == Constantes.MENU_ATENDIMENTO.Dados_Cadastrais && this.atendimentoEmpresaCnpj.documentoEmpresasProtocolos.length != 0){
      if (this.validar(acaoAtendimento)) {
        Swal.fire({
          text: 'Identificamos que existe uma ou mais inscrições municipais ativas vinculadas para o CNPJ/CPF desse negócio. Tem certeza que deseja continuar?',
          icon: 'question',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        })
        .then((result) => {
          if (!result.dismiss) {
            this.executarComPareceres(acaoAtendimento)
          }
        });
      }
    }
    else{
      if (this.validar(acaoAtendimento)) {
        Swal.fire({
          text: 'Confirmar a análise?',
          icon: 'question',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        })
        .then((result) => {
          if (!result.dismiss) {
            if (acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Pendenciar) {
              this.executarSemPareceres(acaoAtendimento)
            }
            else {
              this.executarComPareceres(acaoAtendimento)
            }
          }
        });
      }
    }
    
  }

  executarComPareceres(acaoAtendimento) {
    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '80%',
      height: '95%',
      maxWidth: 'none',
      maxHeight: 'none',
      closeOnNavigation: true,
      data: {
          titulo: "Pareceres",
          componente: "atendimento-pareceres",
          dados: [
            "fase",
            localStorage.getItem("pId"), 
            this.activatedRoute.snapshot.params["codigoOrgao"], 
            this.processo, 
            acaoAtendimento.acao,
            this.atendimentoObservacaoGerais.observacao.value
          ]
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result.parecerFinal != null) {
        this.atendimentoObservacaoGerais.observacao.setValue(result.parecerFinal);
        this.preencherParaAtender();
        this.executarAtendimento(acaoAtendimento)
      }
    });

  }
  
  executarSemPareceres(acaoAtendimento) {
    this.preencherParaAtender();
    this.executarAtendimento(acaoAtendimento)
  }

  executarAtendimento(acaoAtendimento) {
    if (this.modulo == Constantes.MENU_ATENDIMENTO.Consulta_Previa || this.modulo == Constantes.MENU_ATENDIMENTO.Consulta_Previa_Evento) {      
      this.protocoloParaAtendimento.viabilidade.atendimentos[0].parecer = acaoAtendimento.acao;
      this.protocoloService
        .atenderViabilidade(this.protocoloParaAtendimento)
        .pipe(take(1))
        .subscribe(retorno => {
          this.confirmacaoDeAtendimento(retorno);
        })
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Dados_Cadastrais || this.modulo == Constantes.MENU_ATENDIMENTO.Dados_Cadastrais_Evento) {
      this.protocoloParaAtendimento.fic.atendimentos[0].parecer = acaoAtendimento.acao;
      this.protocoloService
        .atenderFic(this.protocoloParaAtendimento)
        .pipe(take(1))
        .subscribe(retorno => {
          this.confirmacaoDeAtendimento(retorno);
        })
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Baixa) {
      this.protocoloParaAtendimento.encerramento.atendimentos[0].parecer = acaoAtendimento.acao;
      this.protocoloService
        .atenderEncerramento(this.protocoloParaAtendimento)
        .pipe(take(1))
        .subscribe(retorno => {
          this.confirmacaoDeAtendimento(retorno);
        })
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Recadastro) {
      if(acaoAtendimento.acao == Constantes.ACAO_ATENDIMENTO.Enviar_Para_Viabilidade){
        this.protocoloParaAtendimento.fic.atendimentos[0].parecer = acaoAtendimento.acao;
        this.protocoloService
          .enviarRecadastroParaViabilidade(this.protocoloParaAtendimento)
          .pipe(take(1))
          .subscribe(retorno => {
            this.confirmacaoDeAtendimento(retorno);
          })
      }
      else{
        this.protocoloParaAtendimento.fic.atendimentos[0].parecer = acaoAtendimento.acao;
        this.protocoloService
          .atenderRecadastramento(this.protocoloParaAtendimento)
          .pipe(take(1))
          .subscribe(retorno => {
            this.confirmacaoDeAtendimento(retorno);
          })
      }
      
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Oficio) {
      
    }
    else if (this.modulo == Constantes.MENU_ATENDIMENTO.Cancelamento) {
      this.protocoloService
        .cancelarPorOficio(this.protocoloParaAtendimento)
        .pipe(take(1))
        .subscribe(retorno => {
          this.confirmacaoDeAtendimento(retorno);
        })
    }
    else if (this.eDespacho) {
      this.atendimentoService
        .responderDespacho(this.atendimentoDespacho)
        .pipe(take(1))
        .subscribe(retorno => {
          this.confirmacaoDeAtendimento(retorno);
        })
    }
  }

  confirmacaoDeAtendimento(retornoAtendimento) {
    let caminhoRetorno = "../";
    if (this.modulo == Constantes.MENU_ATENDIMENTO.Baixa || this.modulo == Constantes.MENU_ATENDIMENTO.Recadastro) {
      caminhoRetorno = "../../";
    }
    else if (this.eDespacho) {
      caminhoRetorno = "../../";
    }

    if (retornoAtendimento.protocolo == null && retornoAtendimento.documento == null) {
      Swal.fire({
        title: retornoAtendimento.titulo,
        html: retornoAtendimento.mensagem,
        icon: retornoAtendimento.status,
        willClose: () => {}
      });
    }
    else if (retornoAtendimento.protocolo.toString().trim() != "" && retornoAtendimento.documento.toString().trim() == "") {
      Swal.fire({
        title: retornoAtendimento.titulo,
        html: retornoAtendimento.mensagem,
        icon: retornoAtendimento.status,
        willClose: () => {
          this.router.navigate([caminhoRetorno], {relativeTo: this.activatedRoute}); 
        }
      });
    }
    else if (retornoAtendimento.protocolo.toString().trim() != "" && retornoAtendimento.documento.toString().trim() != "") {
      Swal.fire({
        title: retornoAtendimento.titulo,
        html: retornoAtendimento.mensagem,
        icon: retornoAtendimento.status,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: () => !Swal.isLoading(),
        willClose: () => {
          this.router.navigate([caminhoRetorno], {relativeTo: this.activatedRoute});
        }
      })
      .then((result) => {
        if (!result.dismiss) {
          this.arquivoService.downloadArquivoAtendimentoPorThread(this.numeroProtocolo)
            .subscribe((nomes: Array<any>) => {
              if (nomes.length) {
                nomes.forEach( nome => {
                  this.arquivoService.downloadArquivoAtendimento(nome)
                    .subscribe((res: any) => {
                      this.arquivoService.handleFile(res, nome + '.pdf');
                    });
                })
              }
              else {
                Swal.fire("", "Não foi possível localizar o arquivo.", "error");
              }
            });
        }
      });
    }
    else {
      Swal.fire({
        title: retornoAtendimento.titulo,
        html: retornoAtendimento.mensagem,
        icon: retornoAtendimento.status,
        willClose: () => {
          this.router.navigate([caminhoRetorno], {relativeTo: this.activatedRoute}); 
        }
      });
    }
  }
  
  acaoModificacaoUso(event) {
    if (event.acao) {
      this.atendimentoAtividade.usosPermitidosAdicionados = this.atendimentoZoneamento.usosPermitidosAdicionados;
      this.atendimentoAtividade.usosPermissiveisAdicionados = this.atendimentoZoneamento.usosPermissiveisAdicionados;
      this.atendimentoAtividade.atualizarStatusUso();
    }
    
  }

  acaoImobiliarioCarregado(event) {
    if (this.modulo == "viabilidade") {
      this.atendimentoZoneamento.preencherZonaPorImobiliario(event.acao);
    }
  }

  

}
