import { formatDate } from "@angular/common";
import { FormControl, FormGroup } from "@angular/forms"
import { isCPF, isCNPJ, isDate } from "brazilian-values";
import * as moment from 'moment';

export class FormValidator {

    static senhaForte() {
        // new RegExp("(?=.*[}{,.^?~=+\-_\/*\-+.\|])(?=.*[a-zA-Z])(?=.*[0-9]).{6,}").test("Cau")
    }

    static dataValida() {
        const validator = (formControl: FormControl) => {
            
            if (!formControl.root || !(<FormGroup>formControl.root).controls || formControl.value == null) {
                return null;
            }
            
            let dataArray = new String(formControl.value.replace(/\D/g,""));

            if (dataArray.length == 8) {
                let data = `${ dataArray[0].toString() + dataArray[1].toString() }/${ dataArray[2].toString() + dataArray[3].toString() }/${ dataArray[4].toString() + dataArray[5].toString() + dataArray[6].toString() + dataArray[7].toString() }`;
                
                if (!isDate(data)) {
                    return { mensagem : "Data inválida!" };
                }
            }
            else if (dataArray.length > 0) {
                return { mensagem : "Data inválida e incompleta!" };
            }
            
            return null;
        };

        return validator;
    }

    static cpfCnpjValido() {
        const validator = (formControl: FormControl) => {
            
            if (!formControl.root || !(<FormGroup>formControl.root).controls) {
                return null;
            }

            if (new String(formControl.value).length == 14) {
                if (!isCPF(formControl.value)) {
                    return { mensagem : "CPF inválido!" };
                }
            }
            
            if (new String(formControl.value).length > 14 && new String(formControl.value).length <= 18) {
                if (!isCNPJ(formControl.value)) {
                    return { mensagem : "CNPJ inválido!" };
                }
            }
            
            return null;
        };

        return validator;
    }

    static cnpjValido() {
        const validator = (formControl: FormControl) => {
            
            if (!formControl.root || !(<FormGroup>formControl.root).controls) {
                return null;
            }
            
            if (new String(formControl.value).length > 14 && new String(formControl.value).length <= 18) {
                if (!isCNPJ(formControl.value)) {
                    return { mensagem : "CNPJ inválido!" };
                }
            }
            
            return null;
        };

        return validator;
    }

    static cpfValido() {
        const validator = (formControl: FormControl) => {
            
            if (!formControl.root || !(<FormGroup>formControl.root).controls) {
                return null;
            }

            if (new String(formControl.value).length == 14) {
                if (!isCPF(formControl.value)) {
                    return { mensagem : "CPF inválido!" };
                }
            }
            
            return null;
        };

        return validator;
    }

    static orOther(otherField: string) {
        const validator = (formControl: FormControl) => {
            
            if (otherField == null) {
                throw new Error('É necessário informar um campo.');
            }

            if (!formControl.root || !(<FormGroup>formControl.root).controls) {
                return null;
            }

            const field = (<FormGroup>formControl.root).get(otherField);
            
            if (!field) {
                throw new Error('É necessário informar um campo válido.');
            }
            
            if (field.value.trim() === "" && formControl.value.trim() === "") {
                return { numeroOuComplemento : otherField };
            }

            return null;
        };

        return validator;
    }

    static equalsTo(otherField: string) {
        const validator = (formControl: FormControl) => {
            
            if (otherField == null) {
                throw new Error('É necessário informar um campo.');
            }

            if (!formControl.root || !(<FormGroup>formControl.root).controls) {
                return null;
            }

            const field = (<FormGroup>formControl.root).get(otherField);
            
            if (!field) {
                throw new Error('É necessário informar um campo válido.');
            }

            if (field.value !== formControl.value) {
                return { equalsTo : otherField };
            }

            return null;
        };

        return validator;
    }

    static dataMaiorHoje(){
        const validator = (formControl: FormControl) => {
        const startDateControl = formControl.value;
        const endDateControl = new Date;
        const intervalType = 'days';

        const minDate = moment(startDateControl, 'DDMMYYYY');
        const maxDate = moment(endDateControl || new Date());
        const diff = maxDate.diff(minDate, intervalType, true);
        const translateIntervalType =
          (intervalType === 'days' && 'dias');
        if (diff < 0) {
          return { mensagem: `Data pretendida não pode ser maior que hoje`};
        } 
 
        return null;
      };
        
      return validator;
    }

    static dataMaior(otherField: string){
        const validator = (formControl: FormControl) => {
            
            if (otherField == null) {
                throw new Error('É necessário informar um campo.');
            }

            if (!formControl.root || !(<FormGroup>formControl.root).controls) {
                return null;
            }

            const field = (<FormGroup>formControl.root).get(otherField);
            
            if (!field) {
                throw new Error('É necessário informar um campo válido.');
            }
            if (moment(field.value,'DDMMYYYY') < moment(formControl.value,'DDMMYYYY')) {
                return { mensagem: `Essa data não pode ser maior que a Data Ult. Alteração.`};                
            }

            return null;
        };

        return validator;
    }

    static dataMenor(otherField: string){
        const validator = (formControl: FormControl) => {
            
            if (otherField == null) {
                throw new Error('É necessário informar um campo.');
            }

            if (!formControl.root || !(<FormGroup>formControl.root).controls) {
                return null;
            }

            const field = (<FormGroup>formControl.root).get(otherField);
            
            if (!field) {
                throw new Error('É necessário informar um campo válido.');
            }
            if (moment(field.value,'DDMMYYYY') > moment(formControl.value,'DDMMYYYY')) {
                return { mensagem: `Essa data tem que ser maior que a data de NIRE e a data de CNPJ.`};                
            }

            return null;
        };

        return validator;
    }

    static dataMaiorNascimento(otherField: string){
        const validator = (formControl: FormControl) => {
            
            if (otherField == null) {
                throw new Error('É necessário informar um campo.');
            }

            if (!formControl.root || !(<FormGroup>formControl.root).controls) {
                return null;
            }

            const field = (<FormGroup>formControl.root).get(otherField);
            
            if (!field) {
                throw new Error('É necessário informar um campo válido.');
            }
            if (moment(field.value,'DDMMYYYY') < moment(formControl.value,'DDMMYYYY')) {
                return { mensagem: `Essa data não pode ser maior que a Data de Emissão.`};                
            }

            return null;
        };

        return validator;
    }

    static dataMenorNascimento(otherField: string){
        const validator = (formControl: FormControl) => {
            
            if (otherField == null) {
                throw new Error('É necessário informar um campo.');
            }

            if (!formControl.root || !(<FormGroup>formControl.root).controls) {
                return null;
            }

            const field = (<FormGroup>formControl.root).get(otherField);
            
            if (!field) {
                throw new Error('É necessário informar um campo válido.');
            }
            if (moment(field.value,'DDMMYYYY') > moment(formControl.value,'DDMMYYYY')) {
                return { mensagem: `Essa data não pode ser menor que a de nascimento.`};                
            }

            return null;
        };

        return validator;
    }
}