import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Login } from "src/app/model/login";

@Injectable({ providedIn: 'root' })
export class LoginService {

    constructor(private http: HttpClient, private router: Router) {}

    salvarUsuario(login: Login) {
        return this.http.post<any>( `${ environment.icadUrl }/login/incluir`, JSON.stringify(login),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    alterarUsuario(login: Login) {
        return this.http.post<any>( `${ environment.icadUrl }/login/alterar`, JSON.stringify(login),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    salvarUsuarioAuditor(login: Login) {
        return this.http.post<any>( `${ environment.icadUrl }/login/incluirLoginOrgao`, JSON.stringify(login),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    alterarUsuarioAuditor(login: Login) {
        return this.http.post<any>( `${ environment.icadUrl }/login/alterarLoginOrgao`, JSON.stringify(login),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    alterarPorOficio(login: Login) {
        return this.http.post<any>( `${ environment.icadUrl }/login/alterarPorOficio`, JSON.stringify(login),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    buscarPorLogin(login: Login) {
        return this.http.get<any>(
            `${ environment.icadUrl }/login/buscarPorLogin/${ login.login }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorLoginLivre(login) {
        return this.http.get<any>(
            `${ environment.icadUrl }/login/buscarPorLoginLivre/${ login }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    buscarPorLoginAuditor(login,codigo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/login/buscarPorLoginAuditor/${ login }/${ codigo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorLoginQuadroSocietario(login) {
        return this.http.get<any>( `${ environment.icadUrl }/login/buscarPorLoginQuadroSocietario/${ login }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    listarMeuPerfil() {
        return this.http.get<any>( `${ environment.icadUrl }/login/listarMeuPerfil`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarTodos() {
        return this.http.get<any>( `${ environment.icadUrl }/cliente/buscarTodos`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    
    buscarPorCodigo(login) {
        return this.http.get<any>( `${ environment.icadUrl }/cliente/buscarPorCodigo/${ login }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    alterarCliente(cliente) {
        return this.http.post<any>( `${ environment.icadUrl }/cliente/alterar`, JSON.stringify(cliente),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    meuPermissionamentoPorCliente() {
        return this.http.get<any>( `${ environment.icadUrl }/login/meuPermissionamentoPorCliente`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    meuPermissionamento() {
        return this.http.get<any>( `${ environment.icadUrl }/login/meuPermissionamento`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    meuPermissionamentoPorLogin(login: Login) {
        return this.http.get<any>( `${ environment.icadUrl }/login/meuPermissionamentoPorLogin/${ login.login }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    vincularClientes(login: Login) {
        return this.http.post<any>( `${ environment.icadUrl }/login/vincularClientes/`, JSON.stringify(login),  
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarRequerente(cliente, protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/login/buscarRequerente/${ cliente }/${ protocolo }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    permissaoParaDelegar() {
        return this.http.get<any>(
            `${ environment.icadUrl }/login/permissaoParaDelegar`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
}