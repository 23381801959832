<div class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Linha do Tempo</strong>
        </div>
    </div>
    <div *ngIf="protocoloLinhaDoTempo == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocoloLinhaDoTempo != null" class="card-body">
        <div class="form-row row-linha justify-content-start align-items-center">
            <div class="col-12">
                <p class="card-text titulo-linha">
                    {{ protocoloLinhaDoTempo.descricaoServico | uppercase }}
                </p>
            </div>
            <div class="col-12">
                <div class="col-12 card-header-linha">
                    <p class="card-text">
                        <b>DATA SOLICITAÇÃO:</b> {{ protocoloLinhaDoTempo.dataAbertura }}
                    </p>
                    <p class="card-text">
                        <b>PROTOCOLO:</b> {{ protocoloLinhaDoTempo.protocolo }}
                    </p>
                    <!---<p class="card-text" *ngIf="protocoloLinhaDoTempo.redeSim.protocoloRedeSim != ''">
                        <b>REDESIM:</b> {{ protocoloLinhaDoTempo.redeSim.protocoloRedeSim | uppercase }}
                    </p>--->
                    <!---<p class="card-text" *ngIf="protocoloLinhaDoTempo.redeSim.protocoloRedeSim != ''">
                        <b>EVENTO:</b> {{ protocoloLinhaDoTempo.redeSim.codigoEvento }} - {{ protocoloLinhaDoTempo.redeSim.descricaoEvento | uppercase }}
                    </p>--->
                    <p class="card-text">
                        <b>FASE ATUAL:</b> {{ protocoloLinhaDoTempo.descricaoFase | uppercase }}
                    </p>
                    <p class="card-text">
                        <b>DATA FASE:</b> {{ protocoloLinhaDoTempo.dataSituacao | uppercase }}
                    </p>
                    
                </div>
            </div>
        </div>
        <div *ngFor="let detalhePrimeiroNivel of protocoloLinhaDoTempo.linhaDoTempoDetalhes"
            class="form-row row-linha justify-content-start align-items-center">
            <div class="col-12">
                <p class="card-text primeiro-titulo">
                    {{ detalhePrimeiroNivel.descricaoFase | uppercase }}
                </p>
            </div>
            <div class="col-12" *ngIf="detalhePrimeiroNivel.linhaDoTempoDetalhes.length">
                <div *ngFor="let detalheSegundoNivel of detalhePrimeiroNivel.linhaDoTempoDetalhes"
                    class="form-row row-linha justify-content-start align-items-center">
                    <div class="col-12" *ngIf="detalheSegundoNivel.descricaoFase != ''">
                        <div class="col-12">
                            <p class="card-text segundo-titulo">
                                {{ detalheSegundoNivel.dataFase != '' ? detalheSegundoNivel.dataFase + ' - ' : '' }}{{ detalheSegundoNivel.descricaoFase | uppercase }}
                            </p>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="detalheSegundoNivel.linhaDoTempoDetalhes.length">
                        <div *ngFor="let detalheTerceiroNivel of detalheSegundoNivel.linhaDoTempoDetalhes"
                            class="form-row row-linha justify-content-start align-items-center">
                            <div class="col-12" *ngIf="detalheTerceiroNivel.descricaoFase != ''">
                                <div class="col-12">
                                    <div class="col-12">
                                        <p class="card-text terceiro-titulo">
                                            {{ detalheTerceiroNivel.descricaoFase | uppercase }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="detalheTerceiroNivel.linhaDoTempoDetalhes.length">
                                <div *ngFor="let detalheQuartoNivel of detalheTerceiroNivel.linhaDoTempoDetalhes"
                                    class="form-row row-linha justify-content-start align-items-center">
                                    <div class="col-12">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="col-12">
                                                    <p class="card-text quarto-titulo">
                                                        {{ detalheQuartoNivel.descricaoFase | uppercase }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="detalheQuartoNivel.linhaDoTempoDetalhes.length">
                                        <div *ngFor="let detalheQuintoNivel of detalheQuartoNivel.linhaDoTempoDetalhes"
                                            class="form-row row-linha justify-content-start align-items-center">
                                            <div class="col-12">
                                                <div class="col-12">
                                                    <p class="card-text">
                                                        {{ detalheQuintoNivel.descricaoFase | uppercase }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-12" *ngIf="detalheQuintoNivel.linhaDoTempoDetalhes.length">
                                                
                                            </div>
                                            <div class="col-12" *ngIf="!detalheQuintoNivel.linhaDoTempoDetalhes.length">
                                                <div class="col-12">
                                                    <div class="col-12">
                                                        <p class="card-text">
                                                            <b>
                                                                {{ detalheQuintoNivel.observacao }}
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="!detalheQuartoNivel.linhaDoTempoDetalhes.length">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <div class="col-12">
                                                    <p class="card-text">
                                                        <b>STATUS: </b>
                                                        <button *ngIf="detalheQuartoNivel.descricaoSituacao != ''" type="button" 
                                                            class="btn btn-sm btn-informacao-atividade pt-0 pb-0 mb-0"
                                                            [class.btn-success]="acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == 'S'"
                                                            [class.btn-secondary]="acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == ''"
                                                            [class.btn-danger]="acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == 'N' || acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == 'C'"
                                                            [class.btn-dark]="acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == 'R'"
                                                            [class.btn-warning]="acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == 'P'">
                                                            {{ 
                                                                acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == 'S' ? 'DEFERIDO' : 
                                                                acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == 'N' ? 'INDEFERIDO' :
                                                                acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == 'P' ? 'PENDENTE' :
                                                                acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == 'C' ? 'CANCELADO' : 
                                                                acaoAtendimentoDescricaoReverso[detalheQuartoNivel.descricaoSituacao] == 'R' ? 'REAVALIAÇÃO' : 'NÃO AVALIADO'
                                                            }}
                                                        </button>
                                                        <br *ngIf="detalheQuartoNivel.descricaoSituacao != ''">
                                                        <b>OBS: </b>{{ detalheQuartoNivel.observacao }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="!detalheTerceiroNivel.linhaDoTempoDetalhes.length">
                                <div class="col-12">
                                    <div class="col-12">
                                        <p class="card-text">
                                            <b>
                                                {{ detalheTerceiroNivel.observacao }}
                                            </b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="!detalheSegundoNivel.linhaDoTempoDetalhes.length">
                        <div class="col-12">
                            <div class="col-12">
                                <p class="card-text">
                                    <b>{{ detalheSegundoNivel.descricaoSituacao != '' ? 'STATUS:' : '' }} </b>
                                    <button *ngIf="detalheSegundoNivel.descricaoSituacao != ''" type="button" 
                                        class="btn btn-sm btn-informacao-atividade pt-0 pb-0 mb-0"
                                        [class.btn-success]="acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == 'S'"
                                        [class.btn-secondary]="acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == ''"
                                        [class.btn-danger]="acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == 'N' || acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == 'C'"
                                        [class.btn-dark]="acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == 'R'"
                                        [class.btn-warning]="acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == 'P'">
                                        {{ 
                                            acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == 'S' ? 'DEFERIDO' : 
                                            acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == 'N' ? 'INDEFERIDO' :
                                            acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == 'P' ? 'PENDENTE' :
                                            acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == 'C' ? 'CANCELADO' : 
                                            acaoAtendimentoDescricaoReverso[detalheSegundoNivel.descricaoSituacao] == 'R' ? 'RETORNO' : 'NÃO AVALIADO'
                                        }}
                                    </button>
                                    <br *ngIf="detalheSegundoNivel.descricaoSituacao != ''">
                                    <b *ngIf="detalhePrimeiroNivel.descricaoFase != 'Inscrição Municipal'">OBS: </b>{{ detalheSegundoNivel.observacao }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>