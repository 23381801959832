import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import { Component, Input, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginatorIntl, MatPaginator } from "@angular/material/paginator";

import { GridProviderPaginacao } from "./grid-provider-paginacao";
import { ComprovanteConsultaPrevia } from "../pdf/comprovanteConsultaPrevia";
import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { ComprovanteDadosCadastrais } from '../pdf/comprovanteDadosCadastrais';
import { IcadModalComponent } from '../modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ComprovanteEncerramento } from '../pdf/comprovanteEncerramento';
import { EmpresaService } from '../../services/empresa/empresa.service';
import { Empresa } from '../../model/empresa';
import { Protocolo } from '../../model/protocolo';
import { ComprovanteRecadastro } from "../pdf/comprovanteRecadastro";
import Swal from "sweetalert2";
import { Constantes } from '../../model/constantes';
import { ArquivoService } from '../../services/arquivo/arquivo.service';
import { ProtocoloAcompanhamento } from '../../model/protocoloAcompanhamento';

@Component({
    selector: "app-grid-acompanhamento",
    templateUrl: "./grid-acompanhamento.component.html",
    styleUrls: ["./grid.component.scss"],
    providers: [ 
        {
            provide: MatPaginatorIntl,
            useClass: GridProviderPaginacao
        }
    ]
})
export class GridAcompanhamentoComponent {
    
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ["protocolo","redeSim","inscricao","requerente","servico","faseDescricao","situacao","dataSituacao","menu"];
    teste: boolean = true;

    codigoMenuPai: number;
    codigoMenu: number;
    codigoOrgao: number;

    @Input() dados: Array<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(
        private router: Router,
        private empresaService: EmpresaService,
        private protocoloService: ProtocoloService,
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private arquivoService: ArquivoService) {
            this.codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];
            this.codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"];
            this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
        }
    
    ngOnInit() {}

    atualizarTabela() {
        this.dataSource = new MatTableDataSource(this.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
        this.dataSource.sort = this.sort;

    }

    aplicarFiltro(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    acaoMenu(acao) {
        eval(acao);
    }

    abrirComprovanteConsultaPrevia(protocolo) {
        this.protocoloService
            .detalhar(localStorage.getItem("pId"), protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                new ComprovanteConsultaPrevia(retorno).abrirPDF();
            });
            
    }

    abrirComprovanteDadosCadastrais(protocolo) {
        this.protocoloService
            .detalhar(localStorage.getItem("pId"), protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                new ComprovanteDadosCadastrais(retorno).abrirPDF();
            });
            
    }

    abrirComprovanteRecadastro(protocolo) {
        this.protocoloService
            .detalhar(localStorage.getItem("pId"), protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                new ComprovanteRecadastro(retorno).abrirPDF();
            });
            
    }

    abrirComprovanteEncerramento(protocolo, inscricao) {
        this.empresaService
            .detalhar(localStorage.getItem("pId"), inscricao)
            .pipe(take(1))
            .subscribe( (empresa: Empresa) => {
                let protocoloAuxiliar = empresa.protocolos.find( item => item.protocolo == protocolo);
                protocoloAuxiliar.registro = empresa.registro;
                protocoloAuxiliar.viabilidade = empresa.viabilidade;
                protocoloAuxiliar.fic = empresa.fic;
                new ComprovanteEncerramento(protocoloAuxiliar).abrirPDF();
        });
            
    }

    seguirProcessoViabilidade(protocolo, servico) {
        // Verifica o tipo de empresa
        if (servico == Constantes.TIPO_EMPRESA.Mei) {
            this.router.navigate(["/operacao/mei/abertura", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, protocolo]);
        }
        else if (servico == Constantes.TIPO_EMPRESA.Autonomo) {
            this.router.navigate(["/operacao/autonomo/abertura", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, protocolo]);
        }
        else if (servico == Constantes.TIPO_EMPRESA.Autonomo_Evento) {
            this.router.navigate(["/operacao/evento/aberturaAutonomo", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, protocolo]);
        }
        else if (servico == Constantes.TIPO_EMPRESA.Empresa_Evento) {
            this.router.navigate(["/operacao/evento/aberturaEmpresa", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, protocolo]);
        }
        else {
            this.router.navigate(["/operacao/empresa/abertura", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, protocolo]);
        }
    }

    seguirProcessoFic(protocolo) {
        this.router.navigate(["/operacao/fic", protocolo]);
    }

    seguirProcessoBaixa(protocolo, servico) {
        // Verifica o tipo de empresa
        if (servico == Constantes.TIPO_EMPRESA.Mei) {
            this.router.navigate(["/operacao/mei/baixa", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, protocolo]);
        }
        else if (servico == Constantes.TIPO_EMPRESA.Autonomo) {
            this.router.navigate(["/operacao/autonomo/baixa", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, protocolo]);
        }
        else if (servico == Constantes.TIPO_EMPRESA.Autonomo_Evento) {
            this.router.navigate(["/operacao/evento/baixaAutonomo", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, protocolo]);
        }
        else if (servico == Constantes.TIPO_EMPRESA.Empresa_Evento) {
            this.router.navigate(["/operacao/evento/baixaEmpresa", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, protocolo]);
        }
        else {
            this.router.navigate(["/operacao/empresa/baixa", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, protocolo]);
        }
    }

    cancelar(protocolo) {
        this.protocoloService
            .cancelar(localStorage.getItem("pId"), protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire("", retorno.mensagem, retorno.status == 200 ? "success" : "error")
                .then(function () { location.reload() });
            });
            
    }

    pendencias(protocolo) {
        this.router.navigate([this.router.url, "acompanhamento", "pendencia", protocolo]);
    }

    anexar(protocolo) {
        let dados = [protocolo, "", this.activatedRoute.snapshot.params["codigoOrgao"] ];
        this.openDialog("anexar", dados);   
    }

    timeline(protocolo) {
        let dados = [protocolo, "", this.activatedRoute.snapshot.params["codigoOrgao"] ];
        this.openDialog("linha-do-tempo", dados);   
    }

    detalhar(protocolo) {
        // let dados = [false, protocolo, ""];
        // this.openDialog("detalhar-protocolo", dados);
        this.router.navigate([this.router.url, "detalhar", protocolo], { queryParams: {}})
    }

    openDialog(componente, dados) {

        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                titulo: "Anexar",
                componente: componente,
                dados: dados
            }
        });
    
        // dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
    }

    ordenacaoGrid(data, sort) {
        if (sort.active == null)
            return data;

        let campo = sort.active.replace("servico", "servicoDescricao");

        if (campo == "adicionado") {
            data.sort( (item_1, item_2) => (new Date(item_1[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) > new Date(item_2[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (campo == "dataSituacao") {
            data.sort( (item_1, item_2) => (new Date(item_1[campo].split("/").reverse().toString()) > new Date(item_2[campo].split("/").reverse().toString()) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (campo == "inscricao") {
            data.sort( (item_1, item_2) => (item_1[campo] > item_2[campo] ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (typeof(data[0][campo]) == typeof("0")) {
            data.sort( (item_1, item_2) => (item_1[campo]).localeCompare(item_2[campo])  * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        else if (typeof(data[0][campo]) == typeof(0)) {
            data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo])) ? 0 : item_1[campo] ) - ( isNaN(parseInt(item_2[campo])) ? 0 : item_2[campo] ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
        }
        
        return data;
    }

    baixarArquivo(codigo, nome) {
        this.arquivoService.downloadArquivoPorCodigo(codigo)
        .subscribe((res: any) => {
            this.arquivoService.handleFile(res, nome + '.pdf');
        });
    }

    buscarMetodosAdicionais(row) {
        this.protocoloService
            .menusAdicionaisAcompanhamento(localStorage.getItem("pId"), row.protocolo)
            .pipe(take(1))
            .subscribe( (retorno: ProtocoloAcompanhamento) => {
                retorno.gridMetodos.forEach( metodo => {
                    var metodoEncontrado = row.gridMetodos.findIndex( metodoExistente => metodoExistente.codigo.toString() == metodo.codigo.toString());
                    if (metodoEncontrado == -1) {
                        row.gridMetodos.push(metodo);
                    }
                });
            });
    }
}