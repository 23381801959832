<div class="card">
    <div *ngIf="empresa == null" class="card-body">
        <div class="row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="empresa != null" class="card-body p-0">
        <div class="row justify-content-center align-items-center header-atividades mb-3">
            <div class="row col-4 justify-content-center align-items-center" >
                <p class="card-text titulo-atividades">
                    <strong>Tipo de Negócio</strong>
                </p>
             </div>
            <div class="row col-4 justify-content-center align-items-center" >
                <p class="card-text titulo-atividades">
                    <strong>Licenciamento</strong>
                </p>
            </div>
            <div class="row col-4 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Situação</strong>
                </p>
            </div>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="row col-4 justify-content-center align-items-center">
                <p class="text-center">
                    <svg 
                        class="iconeGrid {{ empresa.empresaAcompanhamento.tipoEmpresaClasse }}">
                        <use
                            attr.xlink:href="#{{ empresa.empresaAcompanhamento.tipoEmpresaIcone }}"
                        ></use>
                    </svg><br>
                    <strong>{{ empresa.empresaAcompanhamento.tipoEmpresaDescricao }}</strong>
                </p>
            </div>
            <div class="row col-4 justify-content-center align-items-center">
                <p class="text-center">
                    <span 
                        [class.texto-success]="empresa.empresaAcompanhamento.situacaoEmpresa == situacaoAlvaraEmpresa.Regular.toString() || empresa.empresaAcompanhamento.situacaoEmpresa == situacaoAlvaraEmpresa.Regular_Cancelado.toString()"
                        [class.texto-secondary]="empresa.empresaAcompanhamento.situacaoEmpresa == ''"
                        [class.texto-danger]="empresa.empresaAcompanhamento.situacaoEmpresa == situacaoAlvaraEmpresa.Irregular.toString()"
                        [class.texto-info]="empresa.empresaAcompanhamento.situacaoEmpresa == situacaoAlvaraEmpresa.Indefinida.toString()"
                        [class.texto-warning]="empresa.empresaAcompanhamento.situacaoEmpresa == situacaoAlvaraEmpresa.Em_Regularizacao.toString()">
                        <b>
                            {{ empresa.empresaAcompanhamento.situacaoEmpresaDescricao | uppercase }}
                        </b>
                    </span>
                </p>
            </div>
            <div class="row col-4 justify-content-center align-items-center">
                <p class="text-center">
                    <strong>{{ empresa.empresaAcompanhamento.dataEncerramento != '' ? 'Empresa encerrada em ' + empresa.empresaAcompanhamento.dataEncerramento 
                               : (empresa.suspensao.dataInicio != '' && empresa.suspensao.dataFim == '') ? 'Empresa suspensa em ' + empresa.suspensao.dataInicio
                               : 'Ativa' }}</strong>
                </p>
            </div>
        </div>
    </div>
</div>