import { Documento } from "./documento";
import { TaxaCreditoDebito } from "./taxaCreditoDebito";

export class TaxaBaixa {
    cliente: string;
    codigo: string;
    codigoPagamento: string;
    tipoBaixa: string;
    dataPagamento: string;
    dataCredito: string;
    subTotalMora: number;
    subTotalJuros: number;
    subTotalCorrecaoMonetaria: number;
    subTotalDesconto: number;
    subTotal: number;
    total: number;
    totalTarifa: number;
    autenticacao: string;
    codigoRetornoArquivo: string;
    codigoRetornoArquivoReg: string;
    registroRetorno: string;
    loginCadastro: string;
    adicionado: string;
    taxaCreditoDebito: TaxaCreditoDebito;
    documento: Documento;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.codigoPagamento = "";
        this.tipoBaixa = "";
        this.dataPagamento = "";
        this.dataCredito = "";
        this.subTotalMora = 0;
        this.subTotalJuros = 0;
        this.subTotalCorrecaoMonetaria = 0;
        this.subTotalDesconto = 0;
        this.subTotal = 0;
        this.total = 0;
        this.totalTarifa = 0;
        this.autenticacao = "";
        this.codigoRetornoArquivo = "";
        this.codigoRetornoArquivoReg = "";
        this.registroRetorno = "";
        this.loginCadastro = "";
        this.adicionado = "";
        this.taxaCreditoDebito = new TaxaCreditoDebito();
        this.documento = new Documento();
    }
}