import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { stringify } from 'querystring';

// import { Users } from 'src/models/users';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private currentAxisSubject: BehaviorSubject<any>;
  public currentAxis: Observable<any>;
  private currentFotoSubject: BehaviorSubject<any>;
  public currentFoto: Observable<any>;
  private currentClienteSubject: BehaviorSubject<any>;
  public currentCliente: Observable<any>;
  private rotaPermitidaSubject: BehaviorSubject<boolean>;
  public rotaPermitida: Observable<boolean>;
  public estrutura: string;
  public reload: boolean;

  fotoAlterada = new EventEmitter();

  constructor(private http: HttpClient, 
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.currentUserSubject = new BehaviorSubject<any>(
      localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    );
    this.currentAxisSubject = new BehaviorSubject<any>(
      localStorage.getItem('eixos')
        ? JSON.parse(localStorage.getItem('eixos'))[0]
        : null
    );
    this.currentFotoSubject = new BehaviorSubject<any>(
      localStorage.getItem('foto')
        ? JSON.parse(localStorage.getItem('foto'))
        : null
    );
    this.currentClienteSubject = new BehaviorSubject<any>(
      localStorage.getItem('cliente')
        ? JSON.parse(localStorage.getItem('cliente'))
        : null
    );
    this.rotaPermitidaSubject = new BehaviorSubject<any>(
      localStorage.getItem('rotaPermitida')
        ? JSON.parse(localStorage.getItem('rotaPermitida'))
        : false
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentAxis = this.currentAxisSubject.asObservable();
    this.currentFoto = this.currentFotoSubject.asObservable();
    this.currentCliente = this.currentClienteSubject.asObservable();
    this.rotaPermitida = this.rotaPermitidaSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get currentAxisValue(): any {
    return this.currentAxisSubject.value;
  }

  public get currentFotoValue(): any {
    return this.currentFotoSubject.value;
  }

  public get currentClienteValue(): any {
    return this.currentClienteSubject.value;
  }

  public get rotaPermitidaValue(): any {
    return this.rotaPermitidaSubject.value;
  }

  public setCurrentFoto(foto) {
    let fotoBase64 = foto != "" ? JSON.stringify("data:image/png;base64," + foto) : "";
    localStorage.setItem('foto', fotoBase64);
    this.currentFotoSubject.next(
      localStorage.getItem('foto')
        ? JSON.parse(localStorage.getItem('foto'))
        : null
    );
    this.fotoAlterada.emit({ acao: true });
  }

  public setCurrentCliente(cliente) {
    localStorage.setItem('cliente', cliente);
    this.currentClienteSubject.next(
      localStorage.getItem('cliente')
        ? JSON.parse(localStorage.getItem('cliente'))
        : null
    );
    this.fotoAlterada.emit({ acao: true });
  }

  public setCurrentAxis(index) {
    this.currentAxisSubject.next(
      localStorage.getItem('eixos')
        ? JSON.parse(localStorage.getItem('eixos'))[index]
        : null
    );
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(
        `${ environment.icadUrl }/token`,
        {
          username,
          password,
          grant_type: 'password',
          tipoLogin: 0,
          sessaoCore: false,
          cliente: localStorage.getItem('pId'),
        },
        {
          headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((user) => {
          localStorage.setItem('estruturas', JSON.stringify(user.param_user));
          localStorage.setItem('userId', JSON.stringify(parseInt(user.codigo_usuario)));
          localStorage.setItem('tkn', JSON.stringify('Bearer ' + user.access_token));
          this.currentUserSubject.next(user);
          this.rotaPermitidaSubject.next(true);
          this.setCurrentAxis(0);
          return user;
        })
      );
  }

  loginPorNomeDeUsuario(username: string, password: string) {
    return this.http
      .post<any>(
        `${ environment.icadUrl }/token`,
        {
          username,
          password,
          grant_type: 'password_nome_usuario',
          tipoLogin: 0,
          sessaoCore: false,
          cliente: localStorage.getItem('pId'),
        },
        {
          headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((user) => {
          localStorage.setItem('estruturas', JSON.stringify(user.param_user));
          localStorage.setItem('userId', JSON.stringify(parseInt(user.codigo_usuario)));
          localStorage.setItem('tkn', JSON.stringify('Bearer ' + user.access_token));
          this.currentUserSubject.next(user);
          this.rotaPermitidaSubject.next(true);
          this.setCurrentAxis(0);
          return user;
        })
      );
  }

  logarEstrutura(name: string, id: any, navigate: boolean) {
    this.estrutura = name;
    return this.http
      .post<any>(
        `${ environment.icadUrl }/token`,
        {
          grant_type: 'refresh_token',
          refresh_token: (localStorage.getItem('tknEstrutura') ? localStorage.getItem('tknEstrutura') : localStorage.getItem('tkn')).replace('Bearer ', ''),
          cliente: localStorage.getItem('pId'),
        },
        {
          headers: new HttpHeaders({
            app_id: environment.appId,
            'Content-Type': 'application/json',
            param_login: id.toString(),
            codigo_usuario: localStorage.getItem('userId'),
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((estrutura) => {
          localStorage.setItem('eixos', JSON.stringify(estrutura.eixos));
          localStorage.setItem('infos', JSON.stringify(estrutura.infos));
          localStorage.setItem(
            'tknEstrutura',
            JSON.stringify('Bearer ' + estrutura.access_token)
          );
          this.retornoLogin(estrutura, navigate);
          return estrutura;
        })
      );
  }

  retornoLogin(estrutura: any, navigate: boolean) {
    this.obterFoto().subscribe((resFoto) => {
      this.setCurrentFoto(resFoto.html);
    });

    estrutura.empresa = this.estrutura;
    estrutura.empresas = localStorage.getItem('estruturas');

    estrutura.entidade = {};
    estrutura.entidade.cidade = 'São Paulo';
    estrutura.municipio = estrutura.infos[0].municipio;
    estrutura.razaoSocial = estrutura.infos[0].nome;
    estrutura.nomeFantasia = estrutura.infos[0].nome;
    estrutura.departamentoUsuario = estrutura.infos[0].departamento;
    estrutura.cargoUsuario = estrutura.infos[0].cargo;
    estrutura.perfil = estrutura.infos[0].perfil;
    estrutura.infos = estrutura.infos[0];
    

    estrutura.eixos.map((eixo) => {
      eixo.name = eixo.rota ? eixo.rota.split('.')[1] : '';
      eixo.item.map((menu) => {
        menu.name = menu.rota ? menu.rota.split('.')[2] : '';
        menu.item.map((funcionalidade) => {
          funcionalidade.name = funcionalidade.rota
            ? funcionalidade.rota.split('.')[3]
            : '';
        });
      });
    });
    
    localStorage.setItem('user', JSON.stringify(estrutura));
    this.buscaItem(estrutura.eixos);
    this.setCurrentAxis(0);
    this.currentUserSubject.next(estrutura);
    this.rotaPermitidaSubject.next(true);

    if (navigate) {
      let redirect = this.activatedRoute.snapshot.queryParams["returnUrl"];

      if (redirect == "" || redirect == null) {
        let existeOperacao = estrutura.eixos.findIndex(item => item.rota.split('.')[1] == "operacao");
        existeOperacao = existeOperacao == -1 ? 0 : existeOperacao;
        this.setCurrentAxis(existeOperacao);
        this.router.navigate([estrutura.eixos[existeOperacao].rota.split('.')[1]]);
      }
      else {
        this.router.navigate([redirect]);
      }
    }
  }

  obterFoto() {
    return this.http.get<any>(
      `${ environment.icadUrl }/arquivo/downloadArquivoBinarioFotoPerfil/${ localStorage.getItem('pId') }/${ localStorage.getItem('userId') }`,
      {
        headers: new HttpHeaders({
          app_id: environment.appId,
          param_user: 'ORG',
        }),
      }
    );
  }

  obterPerfis() {
    return this.http.get<any>(`${ environment.icadUrl }/ACTO/Api/perfis`, {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('tknEstrutura'),
      }),
    });
  }

  obterUsuario(cpfCnpj) {
    return this.http.get<any>(
      `${ environment.icadUrl }/login/existeLogin/${ cpfCnpj }`,
      {
        headers: new HttpHeaders({
          app_id: environment.appId,
          param_user: 'ORG',
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  recuperarSenha(login: string, email: string) {
    return this.http
    .get<any>(
      `${ environment.icadUrl }/login/recuperarSenha/${ login.replace(/\D/g,"") }/${ email }`,
      {
        headers: new HttpHeaders({
          app_id: environment.appId,
        }),
      }
    )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }

  validarCadastro(login: string, codigoValidacao: string) {
    return this.http
      .get<any>(
        `${ environment.icadUrl }/login/validarCadastro/${ login.replace(/\D/g,"") }/${ codigoValidacao }`,
        {
          headers: new HttpHeaders({
            app_id: environment.appId,
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }

  alterarSenha(login: string, codigoValidacao: string, senha: string) {
    return this.http
      .get<any>(
        `${ environment.icadUrl }/login/alterarSenha/${ login.replace(/\D/g,"") }/${ codigoValidacao }/${ senha }`,
        {
          headers: new HttpHeaders({
            app_id: environment.appId,
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }
  
  alterarSenhaAutenticado(login: string, senhaAtual: string, novaSenha: string) {
    return this.http
      .get<any>(
        `${ environment.icadUrl }/login/alterarSenhaAutenticado/${ login.replace(/\D/g,"") }/${ senhaAtual }/${ novaSenha }`,
        {
          headers: new HttpHeaders({
            app_id: environment.appId,
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }

  reenviarCodigoValidacao(login: string) {
    return this.http
      .get<any>(
        `${ environment.icadUrl }/login/reenviarCodigoValidacao/${ login.replace(/\D/g,"") }`,
        {
          headers: new HttpHeaders({
            app_id: environment.appId,
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }

  buscaItem(eixos) {
    
    eixos.map((eixo) => {
      if (eixo.item != "" && eixo.item != null) {
        this.buscaItem(eixo.item);
      }
      eixo.name = eixo.rota.replace(/\./g, "/");
      const rota = eixo.rota.split(".")[0] + this.router.routerState.snapshot.url;
      
      if (eixo.name === rota) {
        this.reload = eixo.reload;
      }
    });
  }

  tokenPowerBI() {
    return this.http.get<any>(
      `${ environment.icadUrl }/token/tokenPowerBI`,
      {
          headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
          }),
      }
    );
  }

  estaAutenticado() {
    return this.currentUserValue != null ? this.currentUserValue.codigo_usuario : false;
  }

  permissionamentoDeRota(parametros, rota) {
    return this.http.post<any>(
      `${ environment.icadUrl }/token/permissionamentoDeRota`, JSON.stringify([parametros, rota]),
      {
        headers: new HttpHeaders({
          app_id: environment.appId,
          param_user: 'ORG',
          'Content-Type': 'application/json',
        }),
      }
    )
  }

  logout() {
    localStorage.removeItem('pId');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('tkn');
    localStorage.removeItem('tknEstrutura');
    localStorage.removeItem('estruturas');
    localStorage.removeItem('infos');
    localStorage.removeItem('eixos');
    localStorage.removeItem('foto');
    localStorage.removeItem('rotaPermitida');
    this.currentUserSubject.next(null);
    this.currentAxisSubject.next(null);
    this.rotaPermitidaSubject.next(null);
    this.router.navigate(['/portal-home']);
  }

}
