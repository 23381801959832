import { GridMetodo } from "./gridMetodo";
import { GridFiltro } from "./gridFiltro";
import { GridCampos } from "./gridCampos";

export class Grid {
    codigo: string;
    cliente: string;
    orgao: string;
    login: string;
    menu: string;
    metodo: GridMetodo;
    filtro: Array<GridFiltro>;
    campos: Array<GridCampos>;
    dados: Array<any>;
    criacao: string;

    constructor() {
        this.codigo = "";
        this.cliente = "";
        this.orgao = "";
        this.login = "";
        this.menu = "";
        this.metodo = new GridMetodo();
        this.filtro = new Array<GridFiltro>();
        this.campos = new Array<GridCampos>();
        this.dados = new Array<any>();
        this.criacao = "";
    }
}