import { Injectable } from "@angular/core";
import { environment } from "../../../../src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class TaxaService {

    constructor(private http: HttpClient, private router: Router) {}

    listaDeIndices() {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/listaDeIndices`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    salvar(taxa) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/salvar`, JSON.stringify(taxa),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    salvarLancamentoManual(taxaLancamento) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/salvarLancamentoManual`, JSON.stringify(taxaLancamento),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarTaxa(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxa/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLotes(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLotes/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLoteSessoes(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLoteSessoes/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLoteEmpresasPorSessao(posicaoInicial, posicaoFinal, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLoteEmpresasPorSessao/${ posicaoInicial }/${ posicaoFinal }/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxasParaProcessarLote() {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxasParaProcessarLote`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    salvarCalendarioFeriado(calendarioFeriado) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/salvarCalendarioFeriado`, JSON.stringify(calendarioFeriado),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarTaxaCalendarioFeriado(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaCalendarioFeriado/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    salvarCalendarioFiscal(calendarioFiscal) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/salvarCalendarioFiscal`, JSON.stringify(calendarioFiscal),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarTaxaCalendarioFiscal(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaCalendarioFiscal/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    salvarIndice(indice) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/salvarIndice`, JSON.stringify(indice),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarTaxaIndice(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaIndice/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaIndiceAtivos() {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaIndiceAtivos`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    salvarCentroDeCusto(centroCusto) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/salvarCentroDeCusto`, JSON.stringify(centroCusto),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarTaxaCentroDeCusto(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaCentroDeCusto/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaCentroDeCustoAtivos() {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaCentroDeCustoAtivos`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoPorProtocolo(protocolo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoPorProtocolo/${ protocolo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoPorProtocoloEmAberto(protocolo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoPorProtocoloEmAberto/${ protocolo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoPorProtocoloEExercicio(protocolo, exercicio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoPorProtocoloEExercicio/${ protocolo }/${ exercicio }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoParcelasPorProtocoloEAnoVencimento(protocolo, exercicio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoParcelasPorProtocoloEAnoVencimento/${ protocolo }/${ exercicio }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoParcelasPorLancamento(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoParcelasPorLancamento/${ codigo }`,
        {
            reportProgress: true,
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoMemoriasPorLancamento(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoMemoriasPorLancamento/${ codigo }`,
        {
            reportProgress: false,
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoMemoriasDaPreviaPorLancamento(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoMemoriasDaPreviaPorLancamento/${ codigo }`,
        {
            reportProgress: false,
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoMemoriasParcelaPorParcela(codigo, codigoLancamento, numero) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoMemoriasParcelaPorParcela/${ codigo }/${ codigoLancamento }/${ numero }`,
        {
            reportProgress: false,
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoPorInscricao(inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoPorInscricao/${ inscricao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoPorInscricaoEmAberto(inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoPorInscricaoEmAberto/${ inscricao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoPorInscricaoEExercicio(inscricao, exercicio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoPorInscricaoEExercicio/${ inscricao }/${ exercicio }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoParcelasPorInscricaoEAnoVencimento(inscricao, exercicio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoParcelasPorInscricaoEAnoVencimento/${ inscricao }/${ exercicio }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorFiltro(filtro, valor) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarPorFiltro/${ filtro }/${ valor }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    gerarPagamento(taxaLancamento) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/gerarPagamento`, JSON.stringify(taxaLancamento),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    baixar(taxaPagamentos) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/baixar`, JSON.stringify(taxaPagamentos),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    baixarPorPrevia(taxaPagamentos) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/baixarPorPrevia`, JSON.stringify(taxaPagamentos),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    previaBaixa(taxaPagamentos) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/previaBaixa`, JSON.stringify(taxaPagamentos),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    atualizarParcelaRetroativo(taxaLancamentoParcela) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/atualizarParcelaRetroativo`, JSON.stringify(taxaLancamentoParcela),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    salvarIndiceCorrecao(indice) {
        return this.http.post<any>( `${ environment.icadUrl }/taxa/salvarIndiceCorrecao`, JSON.stringify(indice),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarTaxaIndiceCorrecao(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaIndiceCorrecao/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaIndiceCorrecaoPorCodigoDoIndice(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaIndiceCorrecaoPorCodigoDoIndice/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaIndiceCorrecaoAtivos() {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaIndiceCorrecaoAtivos`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarDebitos(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarDebitos/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    novaPreviaLote(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/novaPreviaLote/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    verificarBaixaDeArquivoDeLote(codigo, arquivoPreviaDetalheStatus, arquivoMemoriaStatus) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/verificarBaixaDeArquivoDeLote/${ codigo }/${ arquivoPreviaDetalheStatus }/${ arquivoMemoriaStatus }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    verificarProcessamentoDeLote(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/verificarProcessamentoDeLote/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    gerarLotePorTaxa(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/gerarLotePorTaxa/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarTaxaLancamentoPorPreviaEInscricao(codigo, inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaLancamentoPorPreviaEInscricao/${ codigo }/${ inscricao }`,
        {
            reportProgress: true,
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }
    
    reprocessarEmpresaPorPrevia(codigoLote, codigoAcesso, inscricao, codigoMotivo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/reprocessarEmpresaPorPrevia/${ codigoLote }/${ codigoAcesso }/${ inscricao }/${ codigoMotivo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }
    
    confirmarReprocessamentoDeEmpresas(codigoLote) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/confirmarReprocessamentoDeEmpresas/${ codigoLote }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    calculoCancelarLancamento(codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/calculoCancelarLancamento/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    cancelarLancamento(taxaLancamento) {
        return this.http.post<any>(
        `${ environment.icadUrl }/taxa/cancelarLancamento`, JSON.stringify(taxaLancamento),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    previaRevisao(inscricao, codigoCentroCusto) {
        return this.http.get<any>( 
        `${ environment.icadUrl }/taxa/previaRevisao/${ inscricao }/${ codigoCentroCusto }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    gerarRevisao(taxaLancamento) {
        return this.http.post<any>(
        `${ environment.icadUrl }/taxa/gerarRevisao`, JSON.stringify(taxaLancamento),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    alterarCalendarioFiscalNosLancamentos(dados) {
        return this.http.post<any>(
        `${ environment.icadUrl }/taxa/alterarCalendarioFiscalNosLancamentos`, JSON.stringify(dados),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarTaxaCalendariosFiscaisAtivos() {
        return this.http.get<any>(
        `${ environment.icadUrl }/taxa/buscarTaxaCalendariosFiscaisAtivos`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarCalendarioFiscalPorCentroDeCusto(codigoCentroCusto) {
        return this.http.get<any>( 
        `${ environment.icadUrl }/taxa/buscarCalendarioFiscalPorCentroDeCusto/${ codigoCentroCusto }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

       
}