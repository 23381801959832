import { Component, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";

import { GridProviderPaginacao } from "./grid-provider-paginacao";
import { Constantes } from '../../model/constantes';
import { formatToCapitalized } from 'brazilian-values';

@Component({
    selector: "app-grid-permissoes",
    templateUrl: "./grid-permissoes.component.html",
    styleUrls: ["./grid.component.scss"],
    providers: [ 
        {
            provide: MatPaginatorIntl,
            useClass: GridProviderPaginacao
        }
    ]
})
export class GridPermissoesComponent {
    @Output() selecao = new EventEmitter();
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ["selecao","classificacao","codigo","nome"];
    
    @Input() dados: Array<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    
    tipoEmpresa: string;
    tipoDeNegocio: string;
    limitarSelecao: number = 0;
    tiposDeUsos = Constantes.TIPO_DE_USO;


    constructor() { }
    
    ngOnInit() {
        this.tipoDeNegocio = this.dados[1];
        this.tipoEmpresa = this.dados[2];
        this.dataSource = new MatTableDataSource(this.dados[0]);
        this.limitarSelecao = this.dados.length > 3 ? this.dados[3] : 0;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    aplicarFiltro(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    isAllSelected() {
    }

   
   
    masterToggle() {
    }

    formatarNomeAtividade(nome) {
        return formatToCapitalized(nome);
    }

   
}