<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Responsáveis</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolo != null" class="card-body pr-0">
        <div *ngFor="let socio of protocolo.fic.socios"
            class="form-row row-linha linha-grid-divisao mt-4">
            <div class="row col-12 p-0">
                <div class="form-row col-12 row-linha linha-grid-divisao justify-content-start align-items-center titulo-responsavel">
                    <div class="row col-8">
                        <p class="card-text titulo-socios-quadro" [id]="'lbl-' + socio.login.login + '-nome-responsavel'">
                            <b>{{ socio.login.nome }}</b>
                        </p>
                    </div>
                    <div class="row col-4 justify-content-end align-items-center" [id]="'lbl-' + socio.login.login + '-classificacao-responsavel'">
                        <button *ngIf="socio.responsavelLegal" type="button" class="btn btn-warning btn-sm btn-informacao-atividade border-responsavel">
                            LEGAL
                        </button>
                        <button *ngIf="!socio.responsavelLegal && socio.qualificacao != '0'" type="button" class="btn btn-secondary btn-sm btn-informacao-atividade border-responsavel ml-2">
                            SÓCIO
                        </button>                        
                        <button *ngIf="socio.contador" type="button" class="btn btn-primary btn-sm btn-informacao-atividade border-responsavel ml-2">
                            CONTADOR
                        </button>
                    </div>
                </div>
                <div *ngIf="socio.contador && (socio.login.documentos | filtro: {campo: 'tipo', valor: tipoDocumento.Crc.toString()}).length" 
                    class="form-row col-12 justify-content-start align-items-center">
                    <div class="col-2">
                        <p class="card-text dados-socio">
                            <strong>CRC</strong>
                        </p>
                    </div>
                    <div class="col-10">
                        <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-crc-responsavel'">
                            {{ (socio.login.documentos | filtro: {campo: 'tipo', valor: tipoDocumento.Crc.toString()})[0].numero }}
                             / {{ (socio.login.documentos | filtro: {campo: 'tipo', valor: tipoDocumento.Crc.toString()})[0].estadoEmissor }}
                        </p>
                    </div>
                </div>
                <div class="form-row col-6 justify-content-start align-items-center">
                    <div class="col-4">
                        <p class="card-text dados-socio">
                            <strong>{{ tipoDocumentoDescricao[socio.login.documentos[0].tipo] }}</strong>
                        </p>
                    </div>
                    <div class="col-8">
                        <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-cpfOrCnpj-responsavel'"
                            [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.documentos[0].numero') == ''"
                            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.documentos[0].numero')">
                            <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.documentos[0].numero'}).length && permitirAtendimento && permiteCriticar"
                                type="button" [id]="'btn-criticar-' + socio.login.login + '-cpfOrCnpj-responsavel'"
                                matTooltip="Criticar"
                                matTooltipPosition="above"
                                (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.documentos[0].numero', socio.login.documentos[0].numero, 'CPF / CNPJ')"
                                class="btn btn-sm">
                                <svg class="icone-excluir">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                    ></use>
                                </svg>
                            </button>
                            {{ 
                                socio.login.documentos[0].tipo == tipoDocumento.Cpf.toString() ? (socio.login.documentos[0].numero | mask: '000.000.000-00') :
                                socio.login.documentos[0].tipo == tipoDocumento.Cnpj.toString() ? (socio.login.documentos[0].numero | mask: '00.000.000/0000-00') : socio.login.documentos[0].numero
                            }}
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="card-text dados-socio">
                            <strong>Qualificação</strong>
                        </p>
                    </div>
                    <div class="col-8">
                        <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-qualificacao-responsavel'"
                            [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.qualificacao') == ''"
                            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.qualificacao')">
                            <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.qualificacao'}).length && permitirAtendimento && permiteCriticar"
                                type="button" [id]="'btn-criticar-' + socio.login.login + '-qualificacao-responsavel'"
                                matTooltip="Criticar"
                                matTooltipPosition="above"
                                (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.qualificacao', socio.qualificacao + ' - ' + socio.qualificacaoNome, 'Qualificação')"
                                class="btn btn-sm">
                                <svg class="icone-excluir">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                    ></use>
                                </svg>
                            </button>
                            {{ socio.qualificacao }} - {{ socio.qualificacaoNome }}
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="card-text dados-socio">
                            <strong>Paticipação</strong>
                        </p>
                    </div>
                    <div class="col-8">
                        <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-participacao-responsavel'"
                            [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.participacao') == ''"
                            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.participacao')">
                            <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.participacao'}).length && permitirAtendimento && permiteCriticar"
                                type="button" [id]="'btn-criticar-' + socio.login.login + '-participacao-responsavel'"
                                matTooltip="Criticar"
                                matTooltipPosition="above"
                                (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.participacao', socio.participacao, 'Capital Social')"
                                class="btn btn-sm">
                                <svg class="icone-excluir">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                    ></use>
                                </svg>
                            </button>
                            {{ socio.participacao == '' ? 'R$ 0,00' : 'R$ ' + formatToBRL(socio.participacao) }}
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="card-text dados-socio">
                            <strong>Data de Nascimento</strong>
                        </p>
                    </div>
                    <div class="col-8">
                        <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-nascimento-responsavel'"
                            [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.nascimento') == ''"
                            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.nascimento')">
                            <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.nascimento'}).length && permitirAtendimento && permiteCriticar"
                                type="button" [id]="'btn-criticar-' + socio.login.login + '-nascimento-responsavel'"
                                matTooltip="Criticar"
                                matTooltipPosition="above"
                                (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.nascimento', socio.login.nascimento, 'Data de Nascimento')"
                                class="btn btn-sm">
                                <svg class="icone-excluir">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                    ></use>
                                </svg>
                            </button>
                            {{ socio.login.nascimento }}
                        </p>
                    </div>
                </div>
                <div class="form-row col-6 justify-content-start align-items-center">
                    <div class="col-4">
                        <p class="card-text dados-socio">
                            <strong>Telefone Fixo</strong>
                        </p>
                    </div>
                    <div class="col-8">
                        <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-telefone-fixo-responsavel'"
                            [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.fixo.numero') == ''"
                            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.fixo.numero')">
                            <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.fixo.numero'}).length && permitirAtendimento && permiteCriticar"
                                type="button" [id]="'btn-criticar-' + socio.login.login + '-telefone-fixo-responsavel'"
                                matTooltip="Criticar"
                                matTooltipPosition="above"
                                (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.fixo.numero', retornarTelefonePorTipo(socio.login.telefones, tipoTelefone.Residencial), 'Telefone Fixo')"
                                class="btn btn-sm">
                                <svg class="icone-excluir">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                    ></use>
                                </svg>
                            </button>
                            {{ retornarTelefonePorTipo(socio.login.telefones, tipoTelefone.Residencial) }}
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="card-text dados-socio">
                            <strong>Telefone Comercial</strong>
                        </p>
                    </div>
                    <div class="col-8">
                        <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-telefone-comercial-responsavel'"
                            [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.comercial.numero') == ''"
                            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.comercial.numero')">
                            <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.comercial.numero'}).length && permitirAtendimento && permiteCriticar"
                                type="button" [id]="'btn-criticar-' + socio.login.login + '-telefone-comercial-responsavel'"
                                matTooltip="Criticar"
                                matTooltipPosition="above"
                                (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.comercial.numero', retornarTelefonePorTipo(socio.login.telefones, tipoTelefone.Comercial), 'Telefone Comercial')"
                                class="btn btn-sm">
                                <svg class="icone-excluir">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                    ></use>
                                </svg>
                            </button>
                            {{ retornarTelefonePorTipo(socio.login.telefones, tipoTelefone.Comercial) }}
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="card-text dados-socio">
                            <strong>Telefone Celular</strong>
                        </p>
                    </div>
                    <div class="col-8">
                        <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-telefone-celular-responsavel'"
                            [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.celular.numero') == ''"
                            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.celular.numero')">
                            <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.celular.numero'}).length && permitirAtendimento && permiteCriticar"
                                type="button" [id]="'btn-criticar-' + socio.login.login + '-telefone-celular-responsavel'"
                                matTooltip="Criticar"
                                matTooltipPosition="above"
                                (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.telefone.celular.numero', retornarTelefonePorTipo(socio.login.telefones, tipoTelefone.Celular), 'Telefone Celular')"
                                class="btn btn-sm">
                                <svg class="icone-excluir">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                    ></use>
                                </svg>
                            </button>
                            {{ retornarTelefonePorTipo(socio.login.telefones, tipoTelefone.Celular) }}
                        </p>
                    </div>
                    <div class="col-4">&nbsp;</div>
                    <div class="col-8">&nbsp;</div>
                </div>
                <div class="form-row col-12 mt-3 row-cinza justify-content-start align-items-center">
                    <div class="col-2">
                        <p class="card-text dados-socio">
                            <strong>Endereço</strong> 
                        </p>
                    </div>
                    <div class="col-10">
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text dados-socio">
                                    <strong>Cep</strong> 
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-cep-responsavel'"
                                    [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].cep') == ''"
                                    [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].cep')">
                                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].cep'}).length && permitirAtendimento && permiteCriticar"
                                        type="button" [id]="'btn-criticar-' + socio.login.login + '-cep-responsavel'"
                                        matTooltip="Criticar"
                                        matTooltipPosition="above"
                                        (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].cep', socio.login.enderecos[0].cep, 'Cep')"
                                        class="btn btn-sm">
                                        <svg class="icone-excluir">
                                            <use
                                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                            ></use>
                                        </svg>
                                    </button>
                                    {{ socio.login.enderecos[0].cep == '' ? 'Não Cadastrado' : ( socio.login.enderecos[0].cep  | mask: '00000-000' ) }}
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text dados-socio">
                                    <strong>Tipo</strong> 
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-tipoe-logradouro-responsavel'"
                                    [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].logradouro') == ''"
                                    [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].tipoLogradouro')">
                                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].tipoLogradouro'}).length && permitirAtendimento && permiteCriticar"
                                        type="button" [id]="'btn-criticar-' + socio.login.login + '-tipoe-logradouro-responsavel'"
                                        matTooltip="Criticar"
                                        matTooltipPosition="above"
                                        (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].tipoLogradouro', socio.login.enderecos[0].tipoLogradouro, 'Tipo de Logradouro')"
                                        class="btn btn-sm">
                                        <svg class="icone-excluir">
                                            <use
                                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                            ></use>
                                        </svg>
                                    </button>
                                    {{ socio.login.enderecos[0].tipoLogradouro }}
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text dados-socio">
                                    <strong>Logradouro</strong> 
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-logradouro-responsavel'"
                                    [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].logradouro') == ''"
                                    [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].logradouro')">
                                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].logradouro'}).length && permitirAtendimento && permiteCriticar"
                                        type="button" [id]="'btn-criticar-' + socio.login.login + '-logradouro-responsavel'"
                                        matTooltip="Criticar"
                                        matTooltipPosition="above"
                                        (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].logradouro', socio.login.enderecos[0].logradouro, 'Logradouro')"
                                        class="btn btn-sm">
                                        <svg class="icone-excluir">
                                            <use
                                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                            ></use>
                                        </svg>
                                    </button>
                                    {{ socio.login.enderecos[0].logradouro }},
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text dados-socio">
                                    <strong>Número</strong> 
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-numero-responsavel'"
                                    [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].logradouro') == ''"
                                    [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].numero')">
                                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].numero'}).length && permitirAtendimento && permiteCriticar"
                                        type="button" [id]="'btn-criticar-' + socio.login.login + '-numero-responsavel'"
                                        matTooltip="Criticar"
                                        matTooltipPosition="above"
                                        (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].numero', socio.login.enderecos[0].numero, 'Numero')"
                                        class="btn btn-sm">
                                        <svg class="icone-excluir">
                                            <use
                                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                            ></use>
                                        </svg>
                                    </button>
                                    {{ socio.login.enderecos[0].numero }}
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text dados-socio">
                                    <strong>Compl.</strong>
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-complemento-responsavel'"
                                    [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].complemento') == ''"
                                    [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].complemento')">
                                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].complemento'}).length && permitirAtendimento && permiteCriticar"
                                        type="button" [id]="'btn-criticar-' + socio.login.login + '-complemento-responsavel'"
                                        matTooltip="Criticar"
                                        matTooltipPosition="above"
                                        (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].complemento', socio.login.enderecos[0].complemento, 'Complemento')"
                                        class="btn btn-sm">
                                        <svg class="icone-excluir">
                                            <use
                                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                            ></use>
                                        </svg>
                                    </button>
                                    {{ socio.login.enderecos[0].complemento }}
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text dados-socio">
                                    <strong>Bairro</strong>
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-bairro-responsavel'"
                                    [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].bairro') == ''"
                                    [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].bairro')">
                                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].bairro'}).length && permitirAtendimento && permiteCriticar"
                                        type="button" [id]="'btn-criticar-' + socio.login.login + '-bairro-responsavel'"
                                        matTooltip="Criticar"
                                        matTooltipPosition="above"
                                        (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].bairro', socio.login.enderecos[0].bairro, 'Bairro')"
                                        class="btn btn-sm">
                                        <svg class="icone-excluir">
                                            <use
                                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                            ></use>
                                        </svg>
                                    </button>
                                    {{ socio.login.enderecos[0].bairro }}
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text dados-socio">
                                    <strong>Cidade</strong>
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-cidade-responsavel'"
                                    [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].cidade') == ''"
                                    [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].cidade')">
                                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].cidade'}).length && permitirAtendimento && permiteCriticar"
                                        type="button" [id]="'btn-criticar-' + socio.login.login + '-cidade-responsavel'"
                                        matTooltip="Criticar"
                                        matTooltipPosition="above"
                                        (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].cidade', socio.login.enderecos[0].cidade, 'Cidade')"
                                        class="btn btn-sm">
                                        <svg class="icone-excluir">
                                            <use
                                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                            ></use>
                                        </svg>
                                    </button>
                                    {{ socio.login.enderecos[0].cidade }}
                                </p>
                            </div>
                        </div>
                        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                            <div class="col-2">
                                <p class="card-text dados-socio">
                                    <strong>Estado</strong>
                                </p>
                            </div>
                            <div class="col-10">
                                <p class="card-text dados-socio" [id]="'lbl-' + socio.login.login + '-estado-responsavel'"
                                    [class.valor-nao-cadastrado]="('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].estado') == ''"
                                    [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].estado')">
                                    <button *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].estado'}).length && permitirAtendimento && permiteCriticar"
                                        type="button" [id]="'btn-criticar-' + socio.login.login + '-estado-responsavel'"
                                        matTooltip="Criticar"
                                        matTooltipPosition="above"
                                        (click)="protocolo.criticar('protocolo.fic.' + socio.login.documentos[0].numero + '.login.enderecos[0].estado', socio.login.enderecos[0].estado, 'Estado')"
                                        class="btn btn-sm">
                                        <svg class="icone-excluir">
                                            <use
                                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                                            ></use>
                                        </svg>
                                    </button>
                                    {{ socio.login.enderecos[0].estado }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!protocolo.fic.socios.length"
            class="card-body" [class.p-0]="semCardHeader">
            <div class="row col-12 justify-content-left align-items-center">
                <p class="card-text">
                    <br>
                    Não existem responsáveis cadastrados.
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>