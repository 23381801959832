<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card" *ngIf="tipoEmpresa != 'AO' && tipoEmpresa != 'AE' && tipoEmpresa != 'AM' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Requerente</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>NOME</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text">
                        {{ protocolo.requerente.nome }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>CPF / CNPJ</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p *ngFor="let documento of protocolo.requerente.documentos | filtro: {campo: 'nome', valor: 'CPF'}"
                        class="card-text">
                        {{ formatToCPFOrCNPJ(documento.numero) }}
                    </p>
                    <p *ngFor="let documento of protocolo.requerente.documentos | filtro: {campo: 'nome', valor: 'CNPJ'}"
                        class="card-text">
                        {{ formatToCPFOrCNPJ(documento.numero) }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>DATA DA SOLICITAÇÃO</strong>
                    </p>
                </div>
                <div class="col-10">
                    <p class="card-text">
                        {{ protocolo.data }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong *ngIf="tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA'">Dados do Negócio</strong>
                <strong *ngIf="tipoEmpresa != 'AE' && tipoEmpresa != 'AM' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">{{ tipoEmpresa != 'A' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' ? 'Dados do Contribuinte' : 'Dados do Autônomo' }}</strong>
                <strong *ngIf="tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV'">Identificação do Evento</strong>
            </div>
        </div>
        <div class="card-body" [class.hidden]="tipoEmpresa != 'A' && tipoEmpresa != 'AA'">
            <div class="form-row">
                <div class="form-group col-4" [class.hidden]="this.protocolo.processo.codigo == 'U' || this.protocolo.processo.codigo == 'R' || (tipoEmpresa == 'AA' || tipoEmpresa == 'AEV') || this.emitirCritica">
                    <label class="col-sm-12 col-form-label" for="solicitante"></label>
                    <mat-checkbox #usarDados
                            id="usarDados"
                            name="usarDados"
                            value="1"
                            (change)="usarDadosRequerente(usarDados.checked)">
                            Sou o autônomo, solicitarei minha própria inscrição
                        </mat-checkbox>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="col-sm-12 col-form-label" for="cpfCnpj">CPF</label>
                    <input
                        #inputCpfAutonomo
                        (input)="formatToCPF(inputCpfAutonomo.value)"
                        (blur)="verificaAutonomo()"
                        [class.is-invalid]="cpfAutonomo.invalid && cpfAutonomo.touched"
                        autofocus
                        class="form-control form-control-sm col-4"
                        formControlName="cpfAutonomo"
                        id="cpfAutonomo"
                        name="cpfAutonomo"
                        type="text"
                        maxlength="14"
                    />
                    <div class="mb-1" *ngIf="cpfAutonomo.invalid && cpfAutonomo.touched">
                        <span 
                            class="erro" 
                            *ngIf="cpfAutonomo.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="cpfAutonomo.errors.minlength"
                        >O CPF deve conter ao menos 14 caracteres.</span>
                        <span 
                            class="erro" 
                            *ngIf="cpfAutonomo.hasError('mensagem')"
                        >{{cpfAutonomo.errors.mensagem}}</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="nomeAutonomo">Nome</label>
                    <input
                        [class.is-invalid]="nomeAutonomo.invalid && nomeAutonomo.touched"
                        class="form-control form-control-sm"
                        formControlName="nomeAutonomo"
                        id="nomeAutonomo"
                        name="nomeAutonomo"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="nomeAutonomo.invalid && nomeAutonomo.touched">
                        <span 
                            class="erro" 
                            *ngIf="nomeAutonomo.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="nascimentoAutonomo">Data de Nascimento</label>
                    <input
                        [class.is-invalid]="nascimentoAutonomo.invalid && nascimentoAutonomo.touched"
                        class="form-control form-control-sm"
                        mask="00/00/0000"
                        formControlName="nascimentoAutonomo"
                        id="nascimentoAutonomo"
                        name="nascimentoAutonomo"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="nascimentoAutonomo.invalid && nascimentoAutonomo.touched">
                        <span 
                            class="erro" 
                            *ngIf="nascimentoAutonomo.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="nascimentoAutonomo.hasError('mensagem')"
                        >{{nascimentoAutonomo.errors.mensagem}}</span>
                    </div>
                </div>
                
            </div>    
            <div class="form-row">
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="rgAutonomo">R.G.</label>
                    <input
                        [class.is-invalid]="rgAutonomo.invalid && rgAutonomo.touched"
                        class="form-control form-control-sm"
                        formControlName="rgAutonomo"
                        id="rgAutonomo"
                        name="rgAutonomo"
                        type="text"
                        maxlength="10"
                    />
                    <div class="mb-1" *ngIf="rgAutonomo.invalid && rgAutonomo.touched">
                        <span 
                            class="erro" 
                            *ngIf="rgAutonomo.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="dataEmissao">Data de Emissão</label>
                    <input
                        [class.is-invalid]="dataEmissao.invalid && dataEmissao.touched"
                        mask="00/00/0000"
                        class="form-control form-control-sm"
                        formControlName="dataEmissao"
                        id="dataEmissao"
                        name="dataEmissao"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="dataEmissao.invalid && dataEmissao.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataEmissao.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="dataEmissao.hasError('mensagem')"
                        >{{dataEmissao.errors.mensagem}}</span>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="uf">UF</label>
                    <select 
                        formControlName="uf"
                        class="form-control form-control-sm"
                        [class.is-invalid]="uf.invalid && uf.touched"
                        name="uf" 
                        id="uf"                
                    >
                        <option value=""></option>

                        <option *ngFor="let estado of estados" 
                            value="{{ estado }}"
                        >
                            {{ estado }}
                        </option>
                    </select>
                    
                    <div class="mb-1" *ngIf="uf.invalid && uf.touched">
                        <span 
                            class="erro" 
                            *ngIf="uf.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="emissor">Órgão Emissor</label>
                    <input
                        [class.is-invalid]="emissor.invalid && emissor.touched"
                        class="form-control form-control-sm"
                        formControlName="emissor"
                        id="emissor"
                        name="emissor"
                        type="text"
                        maxlength="3"
                    />
                    <div class="mb-1" *ngIf="emissor.invalid && emissor.touched">
                        <span 
                            class="erro" 
                            *ngIf="emissor.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="sexo">Sexo</label>
                    <select 
                        #inputsexo
                        [class.is-invalid]="sexo.invalid && sexo.touched"
                        formControlName="sexo"
                        class="form-control form-control-sm"
                        name="sexo" 
                        id="sexo"                
                    >
                        <option value=""></option>
                        <option value="1">Masculino</option>
                        <option value="2">Feminino</option>
                    </select>
                    <div class="mb-1" *ngIf="sexo.invalid && sexo.touched">
                        <span   
                            class="erro" 
                            *ngIf="sexo.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="escolaridade">Nível de Escolaridade</label>
                    <select
                        [class.is-invalid]="escolaridade.invalid && escolaridade.touched"
                        class="form-control form-control-sm"
                        formControlName="escolaridade"
                        id="escolaridade"
                        name="escolaridade"
                        type="text"
                        (change)="exibeDadosDeClasse(escolaridade.value)"
                    >
                    <option value="" ></option>
                        <option *ngFor="let escolaridade of listaEscolaridade" 
                            value="{{ escolaridade.codigo }}"
                        >
                            {{ escolaridade.nome }}
                        </option>
                    </select>
                    
                    <div class="mb-1" *ngIf="escolaridade.invalid && escolaridade.touched">
                        <span 
                            class="erro" 
                            *ngIf="escolaridade.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-3" [class.hidden]="!exibirDadosDeClasse">
                    <label class="col-sm-12 col-form-label" for="classe">Órgão de Classe</label>
                    <select
                        [class.is-invalid]="classe.invalid && classe.touched"
                        (change)="exibeDadosDeClasseDetalhe(classe.value)"
                        class="form-control form-control-sm"
                        formControlName="classe"
                        id="classe"
                        name="classe"
                        type="text"
                    >
                    <option value="" ></option>
                        <option *ngFor="let classe of orgaoDeClasse" 
                            value="{{ classe.codigo}}"
                        >
                            {{ classe.abreviacao }} - {{ classe.nome }}
                        </option>
                    </select>
                    <div class="mb-1" *ngIf="classe.invalid && classe.touched">
                        <span 
                            class="erro" 
                            *ngIf="classe.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-3" [class.hidden]="!exibirDadosDeClasseDetalhe">
                    <label class="col-sm-12 col-form-label" for="ufRegistro">Estado do Registro</label>
                    <select 
                        formControlName="ufRegistro"
                        class="form-control form-control-sm"
                        [class.is-invalid]="ufRegistro.invalid && ufRegistro.touched"
                        name="ufRegistro" 
                        id="ufRegistro"                
                    >
                        <option value=""></option>

                        <option *ngFor="let estado of estados" 
                            value="{{ estado }}"
                        >
                            {{ estado }}
                        </option>
                    </select>
                    <div class="mb-1" *ngIf="ufRegistro.invalid && ufRegistro.touched">
                        <span 
                            class="erro" 
                            *ngIf="ufRegistro.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-3" [class.hidden]="!exibirDadosDeClasseDetalhe">
                    <label class="col-sm-12 col-form-label" for="numeroRegistro">Número de Registro</label>
                    <input
                        [class.is-invalid]="numeroRegistro.invalid && numeroRegistro.touched"
                        class="form-control form-control-sm"
                        maxlength="15"
                        formControlName="numeroRegistro"
                        id="numeroRegistro"
                        name="numeroRegistro"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="numeroRegistro.invalid && numeroRegistro.touched">
                        <span 
                            class="erro" 
                            *ngIf="numeroRegistro.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-3" [class.hidden]="!exibirDadosDeClasseDetalhe">
                    <label class="col-sm-12 col-form-label" for="dataRegistro">Data de Registro</label>
                    <input
                        [class.is-invalid]="dataRegistro.invalid && dataRegistro.touched"
                        class="form-control form-control-sm"
                        mask="00/00/0000"
                        formControlName="dataRegistro"
                        id="dataRegistro"
                        name="dataRegistro"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="dataRegistro.invalid && dataRegistro.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataRegistro.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'AA'">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="nomeEmpresarial" *ngIf="tipoEmpresa != 'EEV' && tipoEmpresa != 'AEV'">Nome Empresarial</label>
                    <label class="col-sm-12 col-form-label" for="nomeEmpresarial" *ngIf="tipoEmpresa == 'EEV' || tipoEmpresa == 'AEV'">Nome do Evento</label>
                    <input
                        #inputNomeEmpresarial
                        [class.is-invalid]="nomeEmpresarial.invalid && nomeEmpresarial.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="nomeEmpresarial"
                        id="nomeEmpresarial"
                        name="nomeEmpresarial"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="nomeEmpresarial.invalid && nomeEmpresarial.touched">
                        <span 
                            class="erro" 
                            *ngIf="nomeEmpresarial.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-6" [class.hidden]="tipoEmpresa == 'EEV' || tipoEmpresa == 'AEV'">
                    <label class="col-sm-12 col-form-label" for="nomeFantasia">Nome Fantasia</label>
                    <input
                        #inputNomeFantasia
                        [class.is-invalid]="nomeFantasia.invalid && nomeFantasia.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="nomeFantasia"
                        id="nomeFantasia"
                        name="nomeFantasia"
                        type="text"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="telefoneFixo">Telefone Fixo</label>
                    <input
                        #telefoneFixoInput
                        (input)="formatToPhone(telefoneFixoInput)"
                        class="form-control form-control-sm"
                        formControlName="telefoneFixo"
                        id="telefoneFixo"
                        name="telefoneFixo"
                        type="text"
                        maxlength="14"
                    />
                </div>
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="telefoneComercial">Telefone Comercial</label>
                    <input
                        #telefoneComercialInput
                        (input)="formatToPhone(telefoneComercialInput)"
                        class="form-control form-control-sm"
                        formControlName="telefoneComercial"
                        id="telefoneComercial"
                        name="telefoneComercial"
                        type="text"
                        maxlength="14"
                    />
                </div>
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="celular">Celular</label>
                    <input
                        #telefoneCelularInput
                        (input)="formatToPhone(telefoneCelularInput)"
                        [class.is-invalid]="telefoneCelular.invalid && telefoneCelular.touched"
                        class="form-control form-control-sm"
                        formControlName="telefoneCelular"
                        id="telefoneCelular"
                        name="telefoneCelular"
                        type="text"
                        maxlength="16"
                    />
                    <div class="mb-1" *ngIf="telefoneCelular.invalid && telefoneCelular.touched">
                        <span 
                            class="erro" 
                            *ngIf="telefoneCelular.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="telefoneCelular.errors.maxlength"
                        >Telefone celular digitado é inválido.</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="email">E-mail</label>
                    <input
                        [class.is-invalid]="email.invalid && email.touched"
                        class="form-control form-control-sm"
                        formControlName="email"
                        id="email"
                        name="email"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="email.invalid && email.touched">
                        <span 
                            class="erro" 
                            *ngIf="email.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="email.errors.email"
                        >O valor digitado não é um e-mail válido.</span>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="site">Site</label>
                    <input
                        [class.is-invalid]="site.invalid && site.touched"
                        class="form-control form-control-sm"
                        formControlName="site"
                        id="site"
                        name="site"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="site.invalid && site.touched">
                        <span 
                            class="erro" 
                            *ngIf="site.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="site.errors.site"
                        >O valor digitado não é um e-mail válido.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Dados do Registro</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="orgaoDeRegistro">Órgão de Registro</label>
                    <input
                        disabled
                        autofocus
                        class="form-control form-control-sm"
                        id="orgaoDeRegistro"
                        name="orgaoDeRegistro"
                        type="text"
                        value="{{ protocolo.registro.orgaoNome }}"
                    />
                </div>
                <div class="form-group col-9">
                    <label class="col-sm-12 col-form-label" for="naturezaJuridica">Natureza Jurídica</label>
                    <input
                        disabled
                        autofocus
                        class="form-control form-control-sm"
                        id="naturezaJuridica"
                        name="naturezaJuridica"
                        type="text"
                        value="{{ protocolo.registro.naturezaJuridica.codigo }} - {{ protocolo.registro.naturezaJuridica.nome }}"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="documento">Documento</label>
                    <select 
                        #documentoSelect
                        [class.is-invalid]="documento.invalid && documento.touched"
                        formControlName="documento"
                        class="form-control form-control-sm"
                        name="documento" 
                        id="documento"                
                    >
                        <option value=""></option>
                        <option *ngFor="let documento of documentos" 
                            value="{{ documento.codigo }}"
                        >
                            {{ documento.nome }}
                        </option>
                    </select>
                    <div class="mb-1" *ngIf="documento.invalid && documento.touched">
                        <span 
                            class="erro" 
                            *ngIf="documento.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="porte">Porte</label>
                    <select 
                        #inputporte
                        [class.is-invalid]="porte.invalid && porte.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="porte"
                        id="porte"
                        name="porte"
                    >
                        <option value=""></option>
                        <option *ngFor="let porteEmpresa of tiposPorteEmpresa" 
                            value="{{ porteEmpresa.value }}"
                        >
                            {{ porteEmpresa.label }}
                        </option>
                    </select>
                    <div class="mb-1" *ngIf="porte.invalid && porte.touched">
                        <span 
                            class="erro" 
                            *ngIf="porte.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="capitalSocial">Capital Social</label>
                    <input
                        #inputCapitalSocial
                        [class.is-invalid]="capitalSocial.invalid && capitalSocial.touched"
                        autofocus
                        (input)="formatToBRL(inputCapitalSocial.value)"
                        class="form-control form-control-sm text-right"
                        formControlName="capitalSocial"
                        id="capitalSocial"
                        name="capitalSocial"
                        type="text"
                        maxlength="18"
                    />
                    <div class="mb-1" *ngIf="capitalSocial.invalid && capitalSocial.touched">
                        <span 
                            class="erro" 
                            *ngIf="capitalSocial.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="dataUltimaAlteracao">Data Ult. Alteração</label>
                    <input
                        #inputDataUltimaAlteracao
                        mask="00/00/0000"
                        [class.is-invalid]="dataUltimaAlteracao.invalid && dataUltimaAlteracao.touched"
                        autofocus
                        class="form-control form-control-sm text-right"
                        formControlName="dataUltimaAlteracao"
                        id="dataUltimaAlteracao"
                        name="dataUltimaAlteracao"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="dataUltimaAlteracao.invalid && dataUltimaAlteracao.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataUltimaAlteracao.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="dataUltimaAlteracao.hasError('mensagem')"
                        >{{dataUltimaAlteracao.errors.mensagem}}</span>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="nire">NIRE</label>
                    <input
                        #inputNire
                        matTooltip="Número de Registro na Junta Comercial"
                        matTooltipPosition="below"
                        [class.is-invalid]="nire.invalid && nire.touched"
                        autofocus
                        class="form-control form-control-sm text-right"
                        formControlName="nire"
                        id="nire"
                        name="nire"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="nire.invalid && nire.touched">
                        <span 
                            class="erro" 
                            *ngIf="nire.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="dataNire">Data NIRE</label>
                    <input
                        #inputDataNire
                        mask="00/00/0000"
                        [class.is-invalid]="dataNire.invalid && dataNire.touched"
                        autofocus
                        class="form-control form-control-sm text-right"
                        formControlName="dataNire"
                        id="dataNire"
                        name="dataNire"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="dataNire.invalid && dataNire.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataNire.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="dataNire.hasError('mensagem')"
                        >{{dataNire.errors.mensagem}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Dados de Inscrição</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="cnpj">CNPJ</label>
                    <input
                        #inputCnpj
                        (input)="formatToCNPJ(inputCnpj.value)"
                        [class.is-invalid]="cnpj.invalid && cnpj.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="cnpj"
                        id="cnpj"
                        name="cnpj"
                        type="text"
                        maxlength="18"
                    />
                    <div class="mb-1" *ngIf="cnpj.invalid && cnpj.touched">
                        <span 
                            class="erro" 
                            *ngIf="cnpj.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="cnpj.hasError('mensagem')"
                        >{{cnpj.errors.mensagem}}</span>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="dataCnpj">Data</label>
                    <input
                        #inputDataCnpj
                        mask="00/00/0000"
                        [class.is-invalid]="dataCnpj.invalid && dataCnpj.touched"
                        autofocus
                        class="form-control form-control-sm text-right"
                        formControlName="dataCnpj"
                        id="dataCnpj"
                        name="dataCnpj"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="dataCnpj.invalid && dataCnpj.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataCnpj.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="dataCnpj.hasError('mensagem')"
                        >{{dataCnpj.errors.mensagem}}</span>
                    </div>
                </div>
            </div>
            <div class="form-row col-12 justify-content-center align-items-center">
                <mat-checkbox 
                    #isentoInscricaoCheckBox
                    formControlName="inscricaoEstadualIsento"
                    [checked]="inscricaoEstadual.value == 'ISENTO'"
                    (change)="acaoCheckBoxInscricaoIsento(isentoInscricaoCheckBox)"
                    id="isentoInscricao"
                    name="isentoInscricao">
                    Isento de Inscrição Estadual
                </mat-checkbox>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="inscricaoEstadual">Inscrição Estadual</label>
                    <input
                        #inputInscricaoEstadual
                        [class.is-invalid]="inscricaoEstadual.invalid && inscricaoEstadual.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="inscricaoEstadual"
                        id="inscricaoEstadual"
                        name="inscricaoEstadual"
                        type="text"
                        maxlength="18"
                    />
                    <div class="mb-1" *ngIf="inscricaoEstadual.invalid && inscricaoEstadual.touched">
                        <span 
                            class="erro" 
                            *ngIf="inscricaoEstadual.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="dataInscricaoEstadual">Data</label>
                    <input
                        #inputDataInscricaoEstadual
                        mask="00/00/0000"
                        [class.is-invalid]="dataInscricaoEstadual.invalid && dataInscricaoEstadual.touched"
                        autofocus
                        class="form-control form-control-sm text-right"
                        formControlName="dataInscricaoEstadual"
                        id="dataInscricaoEstadual"
                        name="dataInscricaoEstadual"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="dataInscricaoEstadual.invalid && dataInscricaoEstadual.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataInscricaoEstadual.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="dataInscricaoEstadual.hasError('mensagem')"
                        >{{dataInscricaoEstadual.errors.mensagem}}</span>
                    </div>
                </div>
            </div>
            <div class="form-row pt-4" *ngIf="emitirErroInscricaoMunicipal">
                <div class="form-group col-12">
                    <app-alert type="warning">
                        <div [innerHtml]="mensagemErroInscricaoMunicipal"></div>
                    </app-alert>
                </div>
            </div>
            <div class="form-row col-12 justify-content-center align-items-center" *ngIf="permitirNovaInscricaoMunicipal">
                <mat-checkbox 
                    #novaInscricaoMunicipalCheckBox
                    (change)="acaoCheckBoxNovaInscricaoMunicipal(novaInscricaoMunicipalCheckBox)"
                    id="novaInscricaoMunicipal"
                    name="novaInscricaoMunicipal">
                    Desejo solicitar uma nova inscrição municipal.
                </mat-checkbox>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="inscricaoMunicipal">Inscrição Municipal</label>
                    <input
                        #inputInscricaoMunicipal
                        disabled="true"
                        [class.is-invalid]="inscricaoMunicipal.invalid && inscricaoMunicipal.touched"
                        autofocus
                        class="form-control form-control-sm"
                        (blur)="verificaInscricaoMunicipal()" 
                        [class.is-invalid]="inscricaoMunicipal.invalid && inscricaoMunicipal.touched"
                        [class.is-valid]="inscricaoMunicipalValida"
                        formControlName="inscricaoMunicipal"
                        id="inscricaoMunicipal"
                        name="inscricaoMunicipal"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="inscricaoMunicipal.invalid && inscricaoMunicipal.touched">
                        <span 
                            class="erro" 
                            *ngIf="inscricaoMunicipal.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="dataInscricaoMunicipal">Data</label>
                    <input
                        #inputDataInscricaoMunicipal
                        disabled="true"
                        mask="00/00/0000"
                        [class.is-invalid]="dataInscricaoMunicipal.invalid && dataInscricaoMunicipal.touched"
                        autofocus
                        class="form-control form-control-sm text-right"
                        formControlName="dataInscricaoMunicipal"
                        id="dataInscricaoMunicipal"
                        name="dataInscricaoMunicipal"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="dataInscricaoMunicipal.invalid && dataInscricaoMunicipal.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataInscricaoMunicipal.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="dataInscricaoMunicipal.hasError('mensagem')"
                        >{{dataInscricaoMunicipal.errors.mensagem}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BE' || tipoEmpresa == 'BM' || tipoEmpresa == 'BA' || tipoEmpresa == 'BO' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Tipo de Estabelecimento</strong>
            </div>
        </div>
        <div class="card-body pt-2">
            <mat-radio-group 
                (change)="acaoRadioTipoEstabelecimento()"
                #tipoEstabelecimentoRadio
                formControlName="tipoEstabelecimento" 
                aria-label="Selecione uma opção">
                <div class="row">
                    <div class="form-group col-12">
                        <p class="card-text titulo">
                            <mat-radio-button 
                                value="M">
                                <strong>Matriz</strong>
                            </mat-radio-button>
                        </p>
                        <p class="card-text observacao">É o estabelecimento sede da empresa.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <p class="card-text titulo">
                            <mat-radio-button 
                                value="F">
                                <strong>Filial</strong>
                            </mat-radio-button>
                        </p>
                        <p class="card-text observacao">É o estabelecimento que esta subordinado / vinculado a matriz da empresa</p>
                    </div>
                </div>
                <div *ngIf="tipoEstabelecimento.value === 'F'"
                    class="row">
                    <div class="form-group col-12">
                        <input
                            #cnpjFilialInput
                            placeholder="CNPJ da Matriz"
                            (input)="formatToCNPJFilial(cnpjFilial.value)"
                            [class.is-invalid]="cnpjFilial.invalid && cnpjFilial.touched"
                            autofocus
                            class="form-control form-control-sm col-6"
                            formControlName="cnpjFilial"
                            id="cnpjFilial"
                            name="cnpjFilial"
                            type="text"
                            maxlength="18"
                        />
                        <div class="mb-1" *ngIf="cnpjFilial.invalid && cnpjFilial.touched">
                            <span 
                                class="erro" 
                                *ngIf="cnpjFilial.errors.required"
                            >Campo obrigatório</span>
                            <span 
                                class="erro" 
                                *ngIf="cnpjFilial.hasError('mensagem')"
                            >{{cnpjFilial.errors.mensagem}}</span>
                        </div>
                    </div>
                </div>
            </mat-radio-group>
        </div>
    </div>
    <div class="card" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'BE' && tipoEmpresa != 'BM' && tipoEmpresa != 'BA' && tipoEmpresa != 'BO' && tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Qual o objeto social do seu negócio</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="col-sm-12 col-form-label" for="objetoSocial"></label>
                    <textarea #objetoSocialTextArea
                        class="form-control form-control-sm"
                        [class.is-invalid]="objetoSocial.invalid && objetoSocial.touched"
                        formControlName="objetoSocial"
                        id="objetoSocial"
                        name="objetoSocial"
                        maxlength="20000"
                        cols="30" 
                        rows="5"
                    ></textarea>
                    <div class="mb-1">
                        <span 
                            class="erro" 
                            *ngIf="objetoSocial.invalid && objetoSocial.touched && objetoSocial.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="objetoSocial.invalid && objetoSocial.touched && objetoSocial.errors.maxlength"
                        >A descrição deve conter até 20000 caracteres.</span>
                        <span class="quantidade-caracter">
                            Quantidade de Caracteres: {{ objetoSocial.value.length }} / 20000
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>