<div class="card"> 
    <div class="card-header">
        <div class="col-12">
            <strong>Cadastro Imobiliário</strong>
        </div>
    </div>
    <div *ngIf="imobiliario == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="imobiliario != null" class="card-body">
        <div *ngIf="imobiliario.inscricaoFormatada != ''">
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Inscrição</strong> 
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.inscricao == ''">                
                        {{ imobiliario.inscricao == '' ? 'Não Cadastrado' : imobiliario.inscricao }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Formatada</strong> 
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.inscricaoFormatada == ''">                
                        {{ imobiliario.inscricaoFormatada == '' ? 'Não Cadastrado' : imobiliario.inscricaoFormatada }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Cep</strong> 
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.cep == ''">                
                        {{ imobiliario.cep == '' ? 'Não Cadastrado' : ( imobiliario.cep  | mask: '00000-000' ) }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Endereço</strong> 
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.logradouro == ''">                
                        {{ imobiliario.logradouro }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Compl.</strong>
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.complemento == ''">                
                        {{ imobiliario.complemento == '' ? 'Não Cadastrado' : imobiliario.complemento }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Bairro</strong>
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.bairro == ''">                
                        {{ imobiliario.bairro == '' ? 'Não Cadastrado' : imobiliario.bairro }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Cidade</strong>
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.cidade == ''">                
                        {{ imobiliario.cidade == '' ? 'Não Cadastrado' : imobiliario.cidade }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Estado</strong>
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.estado == ''">                
                        {{ imobiliario.estado == '' ? 'Não Cadastrado' : imobiliario.estado }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Lote</strong>
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.lote == ''">
                        {{ imobiliario.lote == '' ? 'Não Cadastrado' : imobiliario.lote }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Quadra</strong>
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.quadra == ''">
                        {{ imobiliario.quadra == '' ? 'Não Cadastrado' : imobiliario.quadra }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Área do Terreno (m2)</strong>
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.areaTotal == ''">
                        {{ imobiliario.areaTotal == '' ? 'Não Cadastrado' : imobiliario.areaTotal }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Área Construída (m2)</strong>
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.areaConstruida == ''">
                        {{ imobiliario.areaConstruida == '' ? 'Não Cadastrado' : imobiliario.areaConstruida }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Testada (m)</strong>
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.testadaPrincipal == ''">
                        {{ imobiliario.testadaPrincipal == '' ? 'Não Cadastrado' : imobiliario.testadaPrincipal }}
                    </p>
                </div>
            </div>
            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-2">
                    <p class="card-text">
                        <strong>Fração Ideal (m2)</strong>
                    </p>
                </div>
                <div class="col-4">
                    <p class="card-text"
                    [class.valor-nao-cadastrado]="imobiliario.fracao == ''">
                        {{ imobiliario.fracao == '' ? 'Não Cadastrado' : imobiliario.fracao }}
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="imobiliario.inscricaoFormatada == ''">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                    Imobiliário não existe na base.
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>

