import { Component, OnInit, Renderer2 } from '@angular/core';
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { take } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { IcadModalComponent } from "../../../components/modal/icad-modal/icad-modal.component";
import { MatDialog } from "@angular/material/dialog";
// import PerfectScrollbar from 'perfect-scrollbar';
import { ProtocoloService } from '../../../services/operacao/protocolo.service';
import { Configuracao } from '../../../model/configuracao';
import { FaqModulo } from '../../../model/faqModulo';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { Faq } from '../../../model/faq';
import Swal from 'sweetalert2';
import { FaqService } from '../../../services/faq/faq.service';
import { ArquivoService } from '../../../services/arquivo/arquivo.service';
import { FaqArquivo } from '../../../model/faqArquivo';
import { AtendimentoService } from '../../../services/operacao/atendimento.service';

@Component({
    selector: "app-faq",
    templateUrl: "./faq.component.html",
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
    
    public config: PerfectScrollbarConfigInterface = {};

    codigoMenuPai: string;
    codigoOrgao: string;
    codigoMenu: string;
    configuracao: Configuracao;
    faqModulos: Array<FaqModulo>;
    faqMaisAvaliadas: Array<Faq>;
    faqMaisBuscadas: Array<Faq>;
    abaAtual: string;
    abaAtualVertical: string;
    
    
    constructor(
        private faqService: FaqService,
        private document: Document,
        private renderer: Renderer2,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private arquivoService: ArquivoService,
        private atendimentoService: AtendimentoService,) {
        this.codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];
        this.codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"];
        this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
        this.faqModulos = new Array<FaqModulo>();
        this.abaAtual = "";
        this.abaAtualVertical = "";
    }
    
    ngOnInit(): void {
        if (JSON.parse(localStorage.getItem("configuracao")) != null) {
            this.configuracao = JSON.parse(localStorage.getItem("configuracao"));
            this.gerarFaqsAvaliadas()
        }
    }
    
    gerarFaqsAvaliadas() {
        this.configuracao = JSON.parse(localStorage.getItem("configuracao"));
        this.faqModulos = this.configuracao.faqModulos;
    
        setTimeout(() => {
            this.interacaoAbas("justify-pills-1-tab");
            this.interacaoAbasVertical("v-pills-sub-1-tab");
        }, 1);
        
        this.faqMaisAvaliadas = new Array<Faq>();
        this.faqMaisBuscadas = new Array<Faq>();
        this.faqModulos.forEach(faqModulo => {
            faqModulo.faqSubModulos.forEach(faqSubModulo => {
                faqSubModulo.faqs.sort( (faq1, faq2) => parseInt(faq2.totais) - parseInt(faq1.totais) )
                this.faqMaisAvaliadas = this.faqMaisAvaliadas.concat(faqSubModulo.faqs);
                this.faqMaisBuscadas = this.faqMaisBuscadas.concat(faqSubModulo.faqs);
            });
        })

        this.faqMaisAvaliadas.sort( (faq1, faq2) => parseInt(faq2.totais) - parseInt(faq1.totais) )
        this.faqMaisBuscadas.sort( (faq1, faq2) => parseInt(faq2.qtdeBuscadas) - parseInt(faq1.qtdeBuscadas) )
        
        this.faqMaisAvaliadas.splice(5);
        this.faqMaisBuscadas.splice(5);
    }

    atualizarFaqs(faq: Faq, bemAvalidada) {        
        let moduloIndex = this.faqModulos.findIndex(item => item.codigo == faq.codigoModulo);
        let subModuloIndex = this.faqModulos[moduloIndex].faqSubModulos.findIndex(item => item.codigoSubModulo == faq.codigoSubModulo);
        let faqIndex = this.faqModulos[moduloIndex].faqSubModulos[subModuloIndex].faqs.findIndex(item => item.codigo == faq.codigo);

        if (bemAvalidada) {
            this.faqModulos[moduloIndex].faqSubModulos[subModuloIndex].faqs[faqIndex].qtdeAvaliadas = (parseInt(this.faqModulos[moduloIndex].faqSubModulos[subModuloIndex].faqs[faqIndex].qtdeAvaliadas) + 1).toString();
            this.faqModulos[moduloIndex].faqSubModulos[subModuloIndex].faqs[faqIndex].totais = (parseInt(this.faqModulos[moduloIndex].faqSubModulos[subModuloIndex].faqs[faqIndex].totais) + 1).toString();
        }
        else {
            this.faqModulos[moduloIndex].faqSubModulos[subModuloIndex].faqs[faqIndex].qtdeNegadas = (parseInt(this.faqModulos[moduloIndex].faqSubModulos[subModuloIndex].faqs[faqIndex].qtdeNegadas) + 1).toString();
            this.faqModulos[moduloIndex].faqSubModulos[subModuloIndex].faqs[faqIndex].totais = (parseInt(this.faqModulos[moduloIndex].faqSubModulos[subModuloIndex].faqs[faqIndex].totais) - 1).toString();
        }

        this.gerarFaqsAvaliadas();

        localStorage.setItem("configuracao", JSON.stringify(this.configuracao));
    }

    baixarArquivo(faqArquivo: FaqArquivo){
        this.arquivoService.downloadArquivoBinarioFaq(faqArquivo.codigo)
        .subscribe((res: any) => {
            this.arquivoService.handleFile(res, faqArquivo.nome);
        });
    }

    acaoDoFiltro(valor, faqCodigo) {
        
        var rex = new RegExp(valor, 'i');
        var id = '.todo-box-' + faqCodigo + ' ' + '.todo-item-' + faqCodigo;
        $(id).hide();
        let filtrados = $(id).filter(function() {
            return rex.test($(this).text());
        });
        filtrados.show();

        $('.todo-box-nao-encontrado-' + faqCodigo).hide();
        if (!filtrados.length) {
            $('.todo-box-nao-encontrado-' + faqCodigo).show();            
        }
    }

    interacaoAbas(idAba) {
        let botao = '#' + idAba;
        let conteudo = '#' + idAba.replace("-tab", "");
    
        if (this.abaAtual != "") {
            let botaoAtual = '#' + this.abaAtual;
            let conteudoAtual = '#' + this.abaAtual.replace("-tab", "");

            this.renderer.removeClass(this.document.querySelector(botaoAtual), "active");
            this.renderer.removeClass(this.document.querySelector(botaoAtual), "show");
            this.renderer.removeClass(this.document.querySelector(conteudoAtual), "active");
            this.renderer.removeClass(this.document.querySelector(conteudoAtual), "show");
            this.renderer.addClass(this.document.querySelector(conteudoAtual), "d-none");
        }
        this.renderer.addClass(this.document.querySelector(botao), "active");
        this.renderer.addClass(this.document.querySelector(botao), "show");
        this.renderer.addClass(this.document.querySelector(conteudo), "active");
        this.renderer.addClass(this.document.querySelector(conteudo), "show");
        this.renderer.removeClass(this.document.querySelector(conteudo), "d-none");
        this.abaAtual = idAba;
    
    }

    interacaoAbasVertical(idAba) {
        let botao = '#' + idAba;
        let conteudo = '#' + idAba.replace("-tab", "");
    
        if (this.abaAtualVertical != "") {
            let botaoAtual = '#' + this.abaAtualVertical;
            let conteudoAtual = '#' + this.abaAtualVertical.replace("-tab", "");

            this.renderer.removeClass(this.document.querySelector(botaoAtual), "active");
            this.renderer.removeClass(this.document.querySelector(botaoAtual), "show");
            this.renderer.removeClass(this.document.querySelector(conteudoAtual), "active");
            this.renderer.removeClass(this.document.querySelector(conteudoAtual), "show");
            this.renderer.addClass(this.document.querySelector(conteudoAtual), "d-none");
        }
        this.renderer.addClass(this.document.querySelector(botao), "active");
        this.renderer.addClass(this.document.querySelector(botao), "show");
        this.renderer.addClass(this.document.querySelector(conteudo), "active");
        this.renderer.addClass(this.document.querySelector(conteudo), "show");
        this.renderer.removeClass(this.document.querySelector(conteudo), "d-none");
        this.abaAtualVertical = idAba;
    
    }

    abrirChat() {
        const currentUser = this.authenticationService.currentUserValue;
        
        this.atendimentoService
            .urlChat(currentUser.perfil)
            .pipe(take(1))
            .subscribe( retorno => {
              window.open(retorno);
            });
      }

    avaliacaoPositiva( faq: Faq){
        Swal.fire({
            title: 'Avaliação de pergunta',
            html: 'Deseja avaliar positivamente essa pergunta?',
            icon: "question",
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: true,
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
              allowOutsideClick: () => !Swal.isLoading(),
              willClose: () => {
                
              }
        })
        .then((result) => {
            if (!result.dismiss) {
                this.faqService
                .avaliacaoPositivaFaq(faq)
                .pipe(take(1))
                .subscribe( retorno => {
                    if (retorno != "") {
                        Swal.fire({
                            title: "Avaliação realizada",
                            html: `Agradecemos a sua avaliação.`,
                            icon: "success",
                        })
                        this.atualizarFaqs(faq, true);
                    }
                    else {
                        Swal.fire({
                            title: "Avaliação não realizada",
                            html: `Não foi possível avaliar a pergunta, por favor tente novamente.`,
                            icon: "error",
                        })
                    }
                });
            }
        })
    }
    avaliacaoNegativa( faq: Faq){
        Swal.fire({
            title: 'Avaliação de pergunta',
            html: 'Deseja avaliar negativamente essa pergunta?',
            icon: "question",
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: true,
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
              allowOutsideClick: () => !Swal.isLoading(),
              willClose: () => {
                
              }
        })
        .then((result) => {
            if (!result.dismiss) {
                this.faqService
                .avaliacaoNegativaFaq(faq)
                .pipe(take(1))
                .subscribe( retorno => {
                    if (retorno != "") {
                        Swal.fire({
                            title: "Avaliação realizada",
                            html: `Agradecemos a sua avaliação.`,
                            icon: "success",
                        })
                        this.atualizarFaqs(faq, false);
                    }
                    else {
                        Swal.fire({
                            title: "Avaliação não realizada",
                            html: `Não foi possível avaliar a pergunta, por favor tente novamente.`,
                            icon: "error",
                        })
                    }
                });
            }
        })
    }

    
}