<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<form
    name="formulario"
    [formGroup]="formulario"
>
    <div [class.hidden]="!exibeFormularioEndereco">
        <app-form-endereco tipoEnderecoConfiguracao="1"></app-form-endereco>
    </div>
    <div [class.hidden]="exibeFormularioEndereco">
        <app-alert *ngIf="emitirErro"
            type="danger">
            Favor informar todos os endereços.
        </app-alert>
        <div class="row">
            <div class="row col-12 justify-content-center align-items-center">
                <button class="col-3 btn btn-block btn-success"
                    (click)="novaPesquisa()"
                    title="Voltar"
                    id="btnVoltar"
                >
                    Nova pesquisa
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">&nbsp;</div>
        </div>
        <div class="card">
            <div class="card-header">
                <div class="col-12">
                    <strong>Endereço localizado na sua busca inicial</strong>
                </div>
            </div>
            <div *ngIf="enderecosPrincipais == null" class="card-body p-0">
                <div class="row col-12 row-linha">
                    <div class="col-12">
                        <p class="card-text text-center">
                            Carregando...
                        </p>
                    </div>
                </div>      
            </div>
            <div *ngIf="enderecosPrincipais != null" class="card-body p-0">
                <div class="row col-12 p-0" *ngIf="enderecosPrincipais.length">
                    <div class="row col-12 justify-content-center align-items-center header-atividades">
                        <div class="row col-1"></div>
                        <div class="row col-1 justify-content-center align-items-center">
                            <p class="card-text titulo-atividades">
                                <strong>CEP</strong>
                            </p>
                        </div>
                        <div class="row col-4">
                            <p class="card-text titulo-atividades">
                                <strong>Logradouro</strong>
                            </p>
                        </div>
                        <div class="row col-3">
                            <p class="card-text titulo-atividades">
                                <strong>Bairro</strong>
                            </p>
                        </div>
                        <div class="row col-2 justify-content-center align-items-center">
                            <p class="card-text titulo-atividades">
                                <strong>Cidade</strong>
                            </p>
                        </div>
                        <div class="row col-1 justify-content-center align-items-center">
                            <p class="card-text titulo-atividades">
                                <strong>Estado</strong>
                            </p>
                        </div>
                    </div>
                    <div  *ngFor="let enderecoPrincipal of enderecosPrincipais; let i = index"
                        class="row col-12 row-linha linha-grid-divisao justify-content-start align-items-center pt-3 pb-3">
                        <div class="row col-1 justify-content-center align-items-center">
                            <p class="card-text">
                                <mat-radio-button
                                    #enderecoInput
                                    (click)="$event.stopPropagation()"
                                    (change)="selecionarEndereco(enderecoPrincipal, enderecoInput)"
                                    [checked]="enderecoSelecionado.isSelected(enderecoPrincipal)"
                                    [aria-label]="checkboxLabel(enderecoPrincipal)"
                                    id="endereco_{{ enderecoPrincipal.inscricao }}"
                                    name="endereco">
                                </mat-radio-button>
                            </p>
                        </div>
                        <div class="row col-1 justify-content-center align-items-center">
                            <p class="card-text text-center">
                                {{ ("00000000" + enderecoPrincipal.cep).slice(-8) | mask: '00000-000'  }}
                            </p>
                        </div>
                        <div class="row col-4">
                            <p class="card-text">
                                {{ enderecoPrincipal.tipoLogradouro }} 
                                {{ enderecoPrincipal.logradouro }}{{ enderecoPrincipal.numero == '' ? '' : ', ' }}
                                {{ enderecoPrincipal.numero }}{{ enderecoPrincipal.complemento == '' ? '' : ' - ' }}
                                {{ enderecoPrincipal.complemento }}
                                <!--- {{ enderecoPrincipal.pontoReferencia == '' ? '' : ' - ' }}{{ enderecoPrincipal.pontoReferencia }} --->
                            </p>
                        </div>
                        <div class="row col-3">
                            <p class="card-text">
                                {{ enderecoPrincipal.bairro }}
                            </p>
                        </div>
                        <div class="row col-2 justify-content-center align-items-center">
                            <p class="card-text text-center">
                                {{ enderecoPrincipal.cidade }}
                            </p>
                        </div>
                        <div class="row col-1 justify-content-center align-items-center">
                            <p class="card-text text-center">
                                {{ enderecoPrincipal.estado }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row col-12" *ngIf="!enderecosPrincipais.length">
                    <div class="row col-12 row-linha linha-grid-divisao justify-content-center align-items-center pt-3 pb-3">
                        <p class="card-text">
                            Não foram encontrados enderecos na base de imobiliário do município.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <div class="col-12">
                    <strong>Endereços alternativos</strong>
                </div>
            </div>
            <div *ngIf="enderecosAlternativos == null" class="card-body p-0">
                <div class="row col-12 row-linha">
                    <div class="col-12">
                        <p class="card-text text-center">
                            Carregando...
                        </p>
                    </div>
                </div>      
            </div>
            <div *ngIf="enderecosAlternativos != null" class="card-body p-0">
                <div class="row col-12 p-0" *ngIf="enderecosAlternativos.length">
                    <div class="row col-12 justify-content-center align-items-center header-atividades">
                        <div class="row col-1"></div>
                        <div class="row col-1 justify-content-center align-items-center">
                            <p class="card-text titulo-atividades">
                                <strong>CEP</strong>
                            </p>
                        </div>
                        <div class="row col-4">
                            <p class="card-text titulo-atividades">
                                <strong>Logradouro</strong>
                            </p>
                        </div>
                        <div class="row col-3">
                            <p class="card-text titulo-atividades">
                                <strong>Bairro</strong>
                            </p>
                        </div>
                        <div class="row col-2 justify-content-center align-items-center">
                            <p class="card-text titulo-atividades">
                                <strong>Cidade</strong>
                            </p>
                        </div>
                        <div class="row col-1 justify-content-center align-items-center">
                            <p class="card-text titulo-atividades">
                                <strong>Estado</strong>
                            </p>
                        </div>
                    </div>
                    <div  *ngFor="let enderecoAlternativo of enderecosAlternativos; let i = index"
                        class="row col-12 row-linha linha-grid-divisao justify-content-start align-items-center pt-3 pb-3">
                        <div class="row col-1 justify-content-center align-items-center">
                            <p class="card-text">
                                <mat-radio-button
                                    #enderecoInput
                                    (click)="$event.stopPropagation()"
                                    (change)="selecionarEndereco(enderecoAlternativo, enderecoInput)"
                                    [checked]="enderecoSelecionado.isSelected(enderecoAlternativo)"
                                    [aria-label]="checkboxLabel(enderecoAlternativo)"
                                    id="endereco_{{ enderecoAlternativo.inscricao }}"
                                    name="endereco">
                                </mat-radio-button>
                            </p>
                        </div>
                        <div class="row col-1 justify-content-center align-items-center">
                            <p class="card-text text-center">
                                {{ ("00000000" + enderecoAlternativo.cep).slice(-8) | mask: '00000-000'  }}
                            </p>
                        </div>
                        <div class="row col-4">
                            <p class="card-text">
                                {{ enderecoAlternativo.tipoLogradouro }} 
                                {{ enderecoAlternativo.logradouro }}{{ enderecoAlternativo.numero == '' ? '' : ', ' }}
                                {{ enderecoAlternativo.numero }}{{ enderecoAlternativo.complemento == '' ? '' : ' - ' }}
                                {{ enderecoAlternativo.complemento }}{{ enderecoAlternativo.pontoReferencia == '' ? '' : ' - ' }}
                                {{ enderecoAlternativo.pontoReferencia }}
                            </p>
                        </div>
                        <div class="row col-3">
                            <p class="card-text">
                                {{ enderecoAlternativo.bairro }}
                            </p>
                        </div>
                        <div class="row col-2 justify-content-center align-items-center">
                            <p class="card-text text-center">
                                {{ enderecoAlternativo.cidade }}
                            </p>
                        </div>
                        <div class="row col-1 justify-content-center align-items-center">
                            <p class="card-text text-center">
                                {{ enderecoAlternativo.estado }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row col-12" *ngIf="!enderecosAlternativos.length">
                    <div class="row col-12 row-linha linha-grid-divisao justify-content-center align-items-center pt-3 pb-3">
                        <p class="card-text">
                            Não foram encontrados enderecos na base de imobiliário do município.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 justify-content-center" [class.hidden]="!exibeFormularioEndereco">
        <p class="textoVermelho">
        ATENÇÃO - os campos destacados em vermelho são obrigatórios. Caso você tenha feito alguma alteração que apresente inconsistência com as informações disponibilizadas pelo município será apresentada uma nova tela com as opções disponíveis para o endereço pretendido. Você poderá selecionar uma das opções apresentadas ou rever sua pesquisa.
        </p>
    </div>
</form>