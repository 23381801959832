import { ResumoCadastroComponent } from './../resumo-cadastro/resumo-cadastro.component';
import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { FormUsuarioComponent } from "../../../form-usuario/form-usuario.component";
import { FormEnderecoComponent } from "../../../form-endereco/form-endereco.component";
import { Login } from "src/app/model/login";
import { Documento } from "src/app/model/documento";
import { Endereco } from "src/app/model/endereco";
import { Telefone } from "src/app/model/telefone";
import { Constantes } from "src/app/model/constantes";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { LoginService } from "src/app/services/login/login.service";
import Swal from "sweetalert2";
import { NotificacaoService } from "src/app/services/notificacao/notificacao.service";
import { Notificacao } from "src/app/model/notificacao";
import { take } from "rxjs/operators";
import { MatStepper } from '@angular/material/stepper';
import { isCNPJ } from 'brazilian-values';


@Component({
  selector: 'app-cadastrar-usuario',
  templateUrl: './cadastrar-usuario.component.html',
  styleUrls: ['../login.component.scss']
})
export class CadastrarUsuarioComponent implements OnInit {
  
  stepper: MatStepper;
  login: Login;
  documento: Documento;
  endereco: Endereco;
  telefone: Telefone;
  notificacao: Notificacao;
  
  @Input() exibirBotaoFechar = true;
  @Output() cancelarCadastro = new EventEmitter();
  formCadastrarUsuario: FormGroup;
  formularioUsuario: FormGroup;
  formularioEndereco: FormGroup;

  @ViewChild(FormUsuarioComponent)
  formUsuario: FormUsuarioComponent;

  @ViewChild(FormEnderecoComponent)
  formEndereco: FormEnderecoComponent;

  @ViewChild(ResumoCadastroComponent)
  resumoCadastro: ResumoCadastroComponent;

  cadastrarUsuarioMsg: string = "";

  constructor(private notificacaoService: NotificacaoService,
              private authenticationService: AuthenticationService,
              private loginService: LoginService,
              private router: Router, 
              private formBuilder: FormBuilder) 
  {
    

    this.formCadastrarUsuario = this.formBuilder.group({
      controleValidade: ["", [Validators.required]]
    });

    if ( this.estaAutenticado() ) {
      this.exibirBotaoFechar = false;
    }

    setTimeout(() => {
      this.formularioUsuario = this.formUsuario.formulario;
      this.formularioEndereco = this.formEndereco.formulario;
    }, 0);

  }

  ngOnInit(): void {}
  
  salvar() {

    this.preencherLogin();

    if (this.estaAutenticado()) {
      this.alterarUsuario();
    }
    else {      
      this.salvarUsuario();
    }
  }

  salvarUsuario() {
    this.loginService
      .salvarUsuario(this.login)
      .pipe(take(1))
      .subscribe(retorno => {
        if (retorno) {
          Swal.fire("", "Cadastro realizado com sucesso!", "success");
        }
        else {
          Swal.fire("", "Não foi possível efetuar o cadastro, tente novamente mais tarde.", "error");
        }
        this.cancelarCadastrarUsuario();
      });
  }

  alterarUsuario() {
    this.loginService
      .alterarUsuario(this.login)
      .pipe(take(1))
      .subscribe(retorno => {
        if (retorno) {
          Swal.fire("", "Alteração realizada com sucesso!", "success");
          window.history.back();
        }
        else {
          Swal.fire("", "Não foi possível efetuar a alteração, tente novamente mais tarde.", "error");
        }
      });
  }

  verificaValidacaoFormulario(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
        const controle = formGroup.get(campo);
        controle.markAsTouched();
    });
  }

  cancelarCadastrarUsuario() {
    this.cancelarCadastro.emit({ cadastrarUsuario: false });
    this.router.navigate(["/login"]); 
  }

  preencherLogin() {
    this.login = new Login();
    this.login.login = this.formUsuario.cpfCnpj.value.replace(/\D/g,"");
    this.login.senha = this.formUsuario.senha.value;
    this.preencherUsuario();
    this.preencherDocumentos();
    this.preencherEnderecos();
    this.preencherTelefones();    
  }

  preencherUsuario() {
    this.login.nome = this.formUsuario.nome.value;
    this.login.emails.push(this.formUsuario.email.value);
  }

  preencherDocumentos() {
    
    if (isCNPJ(this.formUsuario.cpfCnpj.value)) {
      this.documento = new Documento();
      this.documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cnpj
      this.documento.nome = "CNPJ";
      this.documento.numero = this.formUsuario.cpfCnpj.value.replace(/\D/g,"");
      this.login.documentos.push(this.documento);
    }
    else {
      this.documento = new Documento();
      this.documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf
      this.documento.nome = "CPF";
      this.documento.numero = this.formUsuario.cpfCnpj.value.replace(/\D/g,"");
      this.login.documentos.push(this.documento);
    }
    
    this.documento = new Documento();
    this.documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Crea
    this.documento.nome = "CREA";
    this.documento.numero = this.formUsuario.numeroCrea.value;
    this.documento.estadoEmissor = this.formUsuario.estadoCrea.value;
    this.login.documentos.push(this.documento);

    this.documento = new Documento();
    this.documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Crc
    this.documento.nome = "CRC";
    this.documento.numero = this.formUsuario.numeroCrc.value;
    this.documento.subTipo = this.formUsuario.tipoCrc.value;
    this.documento.estadoEmissor = this.formUsuario.estadoCrc.value;
    this.documento.classificacao = this.formUsuario.classificacaoCrc.value;
    this.login.documentos.push(this.documento);

    this.documento = new Documento();
    this.documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Crc
    this.documento.nome = "CRC_LOGIN";
    this.documento.numero = this.formUsuario.numeroCrc.value;
    this.documento.subTipo = this.formUsuario.tipoCrc.value;
    this.documento.estadoEmissor = this.formUsuario.estadoCrc.value;
    this.documento.classificacao = this.formUsuario.classificacaoCrc.value;
    this.login.documentos.push(this.documento);

    this.documento = new Documento();
    this.documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cau
    this.documento.nome = "CAU";
    this.documento.numero = this.formUsuario.numeroCau.value;
    this.documento.estadoEmissor = this.formUsuario.estadoCau.value;
    this.documento.classificacao = this.formUsuario.classificacaoCau.value;
    this.documento.dataEmissao = this.formUsuario.emissaoCau.value
    this.login.documentos.push(this.documento);

  }

  preencherEnderecos() {
    this.endereco = new Endereco();

    this.endereco.cliente = this.formEndereco.cliente.value;
    this.endereco.codigo = this.formEndereco.codigo.value;
    this.endereco.protocolo = this.formEndereco.protocolo.value;
    this.endereco.inscricao = this.formEndereco.inscricao.value;
    this.endereco.inscricaoFormatada = this.formEndereco.inscricaoFormatada.value;
    this.endereco.cep = this.formEndereco.cep.value.replace(/\D/g,"");
    this.endereco.tipoLogradouro = this.formEndereco.tipoLogradouro.value;
    this.endereco.tituloLogradouro = this.formEndereco.tituloLogradouro.value;
    this.endereco.codigoLogradouro = this.formEndereco.codigoLogradouro.value;
    this.endereco.logradouro = this.formEndereco.logradouro.value;
    this.endereco.numero = this.formEndereco.numero.value;
    this.endereco.complemento = this.formEndereco.complemento.value;
    this.endereco.codigoBairro = this.formEndereco.codigoBairro.value;
    this.endereco.bairro = this.formEndereco.bairro.value;
    this.endereco.cidade = this.formEndereco.cidade.value;
    this.endereco.estado = this.formEndereco.estado.value;
    this.endereco.pais = this.formEndereco.pais.value;
    this.endereco.pontoReferencia = this.formEndereco.pontoReferencia.value;
    this.endereco.latitude = this.formEndereco.latitude.value;
    this.endereco.longitude = this.formEndereco.longitude.value;
    this.endereco.area = this.formEndereco.area.value;
    this.endereco.tipoEndereco = this.formEndereco.tipoEndereco.value;
    this.endereco.tipoBusca = this.formEndereco.tipoBusca.value;
    this.endereco.baseUtilizada = this.formEndereco.baseUtilizada.value;

    this.login.enderecos.push(this.endereco);
  }

  preencherTelefones() {
    let telefoneFixo = new Telefone();
    telefoneFixo.ddd = this.formUsuario.telefoneFixo.value.replace(/\D/g,"").substring(0, 2);
    telefoneFixo.numero = this.formUsuario.telefoneFixo.value.replace(/\D/g,"").substring(2, 11);
    telefoneFixo.tipo = Constantes.TIPO_DE_TELEFONE.Residencial;
    this.login.telefones.push(telefoneFixo);

    let telefoneComercial = new Telefone();
    telefoneComercial.ddd = this.formUsuario.telefoneComercial.value.replace(/\D/g,"").substring(0, 2);
    telefoneComercial.numero = this.formUsuario.telefoneComercial.value.replace(/\D/g,"").substring(2, 11);
    telefoneComercial.tipo = Constantes.TIPO_DE_TELEFONE.Comercial;
    this.login.telefones.push(telefoneComercial);

    let telefoneCelular = new Telefone();
    telefoneCelular.ddd = this.formUsuario.telefoneCelular.value.replace(/\D/g,"").substring(0, 2);
    telefoneCelular.numero = this.formUsuario.telefoneCelular.value.replace(/\D/g,"").substring(2, 11);
    telefoneCelular.tipo = Constantes.TIPO_DE_TELEFONE.Celular;
    this.login.telefones.push(telefoneCelular);

  }

  mudancaPasso(event, passos) {
    
    if (this.stepper == null) {
        this.stepper = passos;
    }
    
    if (event.selectedIndex == 2) {
      if (this.estaAutenticado()) {
        this.preencherLogin();
        this.resumoCadastro.login = this.login;
      }
    }
}

  estaAutenticado() {
    return this.authenticationService.estaAutenticado();
  }
}
