import { OnInit, Component } from "@angular/core";

@Component({
    selector: "app-mapa-do-site",
    templateUrl: "./mapa-do-site.component.html"
})
export class MapaDoSiteComponent implements OnInit {

    constructor() {}

    ngOnInit() {}
}