import { Atendimento } from './atendimento';
import { PerguntaResposta } from './perguntaResposta';
import { Requisito } from './requisito';
import { LicenciamentoFluxo } from './licenciamentoFluxo';
import { Documento } from './documento';
import { LicenciamentoOrientacao } from './licenciamentoOrientacao';
import { LicenciamentoAtividades } from './licenciamentoAtividades';

export class Licenciamento {
    cliente: string;
    codigo: string;
    tipo: string;
    status: string;
    data: string;
    mensagem: string;
    fatorDeRisco: string;
    fatorDeRiscoLista: string;
    fatorDeRiscoEnquadramento: string;
    fatorDeRiscoPergunta: string;
    fatorDeRiscoPerguntaDescricao: string;
    fatorDeRiscoRespostaDescricao: string;
    fatorDeRiscoDocumento: string;
    possuiFob: boolean;
    possuiAnexo: string;
    possuiClassificacaoCopam: string;
    possuiCertidaoDeDispensa: string;
    possuiPrazoEntregaDocumentacao: string;
    possuiRegraBloqueioQuestionario: boolean;
    codigoRegraBloqueioQuestionario: string;
    codigoLiberacaoQuestionario: string;
    prazoEntregaDocumentacao: string;
    statusDoLicenciamento: string;
    numeroProtocolo: string;
    tipoDocumento: string;
    situacaoFob: string;
    situacaoCertidaoDeDispensa: string;
    situacaoLicenca: string;
    documentoGerar: string;
    documentoVisualizar: string;
    documentoLiberar: string;
    documentoBloquear: string;
    documentoTipo: string;
    atendimentoDeferir: string;
    atendimentoIndeferir: string;
    atendimentoLiberar: string;
    atendimentoBloquear: string;
    atendimentoReavaliar: string;
    atendimentoReativar: string;
    atendimentoInformativo: Array<any>;
    qtdProrrogacaoLicenca: string;
    questionarioBloqueado: string;
    dataVenciamentoLicenca: string;
    protocolo: string;
    inscricao: string;
    atendimentos: Array<Atendimento>;
    atividades: string;
    atividadesConstaCopam: string;
    licenciamentoFluxos: Array<LicenciamentoFluxo>;
    licenciamentoOrientacoes: Array<LicenciamentoOrientacao>;
    licenciamentoAtividades: Array<LicenciamentoAtividades>;
    perguntasLicenciamento: Array<PerguntaResposta>;
    perguntasAuditor: PerguntaResposta;
    documentos: Array<Documento>;
    requisito: Requisito;
    geraQuestionario: boolean;
    permiteBloquearLiberarDocumento: boolean;
    permiteRegerarDocumento: boolean;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.tipo = "";
        this.status = "";
        this.data = "";
        this.mensagem = "";
        this.fatorDeRisco = "";
        this.fatorDeRiscoLista = "";
        this.fatorDeRiscoEnquadramento = "";
        this.fatorDeRiscoPergunta = "";
        this.fatorDeRiscoPerguntaDescricao = "";
        this.fatorDeRiscoRespostaDescricao = "";
        this.fatorDeRiscoDocumento = "";
        this.possuiFob = true;
        this.possuiAnexo = "";
        this.possuiClassificacaoCopam = "";
        this.possuiCertidaoDeDispensa = "";
        this.possuiPrazoEntregaDocumentacao = "";
        this.possuiRegraBloqueioQuestionario = false;
        this.codigoRegraBloqueioQuestionario = "";
        this.codigoLiberacaoQuestionario = "";
        this.prazoEntregaDocumentacao = "";
        this.statusDoLicenciamento = "";
        this.numeroProtocolo = "";
        this.tipoDocumento = "";
        this.situacaoFob = "";
        this.situacaoCertidaoDeDispensa = "";
        this.situacaoLicenca = "";
        this.documentoGerar = "";
        this.documentoVisualizar = "";
        this.documentoLiberar = "";
        this.documentoBloquear = "";
        this.documentoTipo = "";
        this.atendimentoDeferir = "";
        this.atendimentoIndeferir = "";
        this.atendimentoLiberar = "";
        this.atendimentoBloquear = "";
        this.atendimentoReavaliar = "";
        this.atendimentoReativar = "";
        this.atendimentoInformativo = new Array<any>();
        this.qtdProrrogacaoLicenca = "";
        this.questionarioBloqueado = "";
        this.dataVenciamentoLicenca = "";
        this.protocolo = "";
        this.inscricao = "";
        this.atendimentos = new Array<Atendimento>();
        this.atividades = "";
        this.atividadesConstaCopam = "";
        this.licenciamentoFluxos = new Array<LicenciamentoFluxo>();
        this.licenciamentoOrientacoes = new Array<LicenciamentoOrientacao>();
        this.licenciamentoAtividades = new Array<LicenciamentoAtividades>();
        this.perguntasLicenciamento = new Array<PerguntaResposta>();
        this.perguntasAuditor = new PerguntaResposta();
        this.documentos = new Array<Documento>();
        this.requisito = new Requisito();
        this.geraQuestionario = true;
        this.permiteBloquearLiberarDocumento = false;
        this.permiteRegerarDocumento = false;
    }
}
