<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Informações para encerramento</strong>
            </div>
        </div>
         <div class="card-body">
            <div class="form-row">
                <div class="form-group col-9">
                    <label class="col-sm-12 col-form-label" for="motivoBaixa">Motivo</label>
                    <select 
                        #motivoBaixaSelect
                        [class.is-invalid]="motivoBaixa.invalid && motivoBaixa.touched"
                        formControlName="motivoBaixa"
                        class="form-control form-control-sm"
                        name="motivoBaixa" 
                        id="motivoBaixa"                
                    >
                        <option value="" *ngIf="tipoEmpresa != 'M'"></option>
                        <option *ngFor="let motivoEncerramento of motivosEncerramento" 
                            [selected]="tipoEmpresa == 'M' && motivoEncerramento.codigo == 1"
                            value="{{ motivoEncerramento.codigo }}"
                        >
                            {{ motivoEncerramento.descricao }}
                        </option>
                    </select>
                    <div class="mb-1" *ngIf="motivoBaixa.invalid && motivoBaixa.touched">
                        <span 
                            class="erro" 
                            *ngIf="motivoBaixa.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="dataPretendida">Data Pretendida</label>
                    <input
                        #dataPretendidaInput
                        mask="00/00/0000"
                        [class.is-invalid]="dataPretendida.invalid && dataPretendida.touched"
                        class="form-control form-control-sm text-center"
                        formControlName="dataPretendida"
                        id="dataPretendida"
                        name="dataPretendida"
                        type="text"
                        maxlength="10"
                    />
                    <div class="mb-1" *ngIf="dataPretendida.invalid && dataPretendida.touched">
                        <span 
                            class="erro" 
                            *ngIf="dataPretendida.errors.minlength"
                        >Data inválida</span>
                        <span 
                            class="erro" 
                            *ngIf="dataPretendida.hasError('mensagem')"
                        >{{dataPretendida.errors.mensagem}}</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="col-sm-12 col-form-label" for="observacao">Observações</label>
                    <textarea #observacaoTextArea
                        class="form-control form-control-sm"
                        [class.is-invalid]="observacao.invalid && observacao.touched"
                        formControlName="observacao"
                        id="observacao"
                        name="observacao"
                        maxlength="500"
                        cols="30" 
                        rows="5"
                    ></textarea>
                    <div class="mb-1">
                        <span 
                            class="erro" 
                            *ngIf="observacao.invalid && observacao.touched && observacao.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="observacao.invalid && observacao.touched && observacao.errors.maxlength"
                        >A descrição deve conter até 500 caracteres.</span>
                        <span class="quantidade-caracter">
                            Quantidade de Caracteres: {{ observacao.value.length }} / 500
                        </span>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    
</form>