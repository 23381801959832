import { Component, OnInit, Input } from '@angular/core';
import { PerguntaResposta } from "src/app/model/perguntaResposta";

@Component({
  selector: 'app-informacao-questionario',
  templateUrl: './informacao-questionario.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoQuestionarioComponent implements OnInit {

  @Input() titulo;
  @Input() semCardHeader: boolean = false;
  perguntasUsuario: PerguntaResposta;
  
  constructor() {}

  ngOnInit(): void {
  }

}
