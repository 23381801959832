import { TaxaLancamentoParcela } from './taxaLancamentoParcela';
import { TaxaLancamentoMemoria } from './taxaLancamentoMemoria';
import { CentroCusto } from './centroCusto';

export class TaxaLancamento {
    cliente: string;
    protocolo: string;
    inscricao: string;
    codigo: string;
    codigoTaxa: string;
    codigoMemoria: string;
    identificacao: string;
    situacao: string;
    exercicio: string;
    orgao: string;
    orgaoDescricao: string;
    requisito: string;
    requisitoDescricao: string;
    fase: string;
    faseDescricao: string;
    avaliacao: string;
    indiceCodigo: string;
    indiceEmbasamento: string;
    indiceValor: string;
    indiceData: string;
    loteReferencia: string;
    loteFatoGerador: string;
    lotePrimeiroVencimento: string;
    loteDemaisVencimentos: string;
    loteNumeroParcelas: string;
    loteCalendarioFiscal: string;
    loteParcelaMinima: string;
    imReferencia: string;
    imFatoGerador: string;
    imPrimeiroVencimento: string;
    imDemaisVencimentos: string;
    imNumeroParcelas: string;
    imCalendarioFiscal: string;
    imParcelaMinima: string;
    tipoMora: string;
    valorMora: string;
    formaCobrancaMora: string;
    tipoJuros: string;
    valorJuros: string;
    formaCobrancaJuros: string;
    tipoCorrecaoMonetaria: string;
    valorCorrecaoMonetaria: string;
    formaCobrancaCorrecaoMonetaria: string;
    tipoDesconto: string;
    valorDesconto: string;
    formaCobrancaDesconto: string;
    qtdDiasDesconto: string;
    qtdDiasRecebimento: string;
    dataReferencia: string;
    qtdParcelas: number;
    total: number;
    loginProcessamento: string;
    processamento: string;
    loginCadastro: string;
    adicionado: string;
    taxaLancamentoParcelas: Array<TaxaLancamentoParcela>;
    taxaLancamentoParcelaUnica: TaxaLancamentoParcela;
    taxaLancamentoMemorias: Array<TaxaLancamentoMemoria>;
    centroCusto: CentroCusto;
    centroCustoAgregador: CentroCusto;
    centrosCustoAgregados: Array<CentroCusto>;
    taxaLancamentoAgregados: Array<TaxaLancamento>;
    apenasPorAtividadePrincipal: boolean;
    observacao: string;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.inscricao = "";
        this.codigo = "";
        this.codigoTaxa = "";
        this.identificacao = "";
        this.situacao = "";
        this.exercicio = "";
        this.orgao = "";
        this.orgaoDescricao = "";
        this.requisito = "";
        this.requisitoDescricao = "";
        this.fase = "";
        this.faseDescricao = "";
        this.avaliacao = "";
        this.indiceCodigo = "";
        this.indiceEmbasamento = "";
        this.indiceValor = "";
        this.indiceData = "";
        this.loteReferencia = "";
        this.loteFatoGerador = "";
        this.lotePrimeiroVencimento = "";
        this.loteDemaisVencimentos = "";
        this.loteNumeroParcelas = "";
        this.loteCalendarioFiscal = "";
        this.loteParcelaMinima = "";
        this.imReferencia = "";
        this.imFatoGerador = "";
        this.imPrimeiroVencimento = "";
        this.imDemaisVencimentos = "";
        this.imNumeroParcelas = "";
        this.imCalendarioFiscal = "";
        this.imParcelaMinima = "";
        this.tipoMora = "";
        this.valorMora = "";
        this.formaCobrancaMora = "";
        this.tipoJuros = "";
        this.valorJuros = "";
        this.formaCobrancaJuros = "";
        this.tipoCorrecaoMonetaria = "";
        this.valorCorrecaoMonetaria = "";
        this.formaCobrancaCorrecaoMonetaria = "";
        this.tipoDesconto = "";
        this.valorDesconto = "";
        this.formaCobrancaDesconto = "";
        this.qtdDiasDesconto = "";
        this.qtdDiasRecebimento = "";
        this.dataReferencia = "";
        this.qtdParcelas = 0;
        this.total = 0;
        this.loginProcessamento = "";
        this.processamento = "";
        this.loginCadastro = "";
        this.adicionado = "";
        this.taxaLancamentoParcelas = new Array<TaxaLancamentoParcela>();
        this.taxaLancamentoParcelaUnica = new TaxaLancamentoParcela();
        this.taxaLancamentoMemorias = new Array<TaxaLancamentoMemoria>();
        this.centroCusto = new CentroCusto();
        this.centroCustoAgregador = new CentroCusto();
        this.centrosCustoAgregados = new Array<CentroCusto>();
        this.taxaLancamentoAgregados = new Array<TaxaLancamento>();
        this.apenasPorAtividadePrincipal = false;
        this.observacao = "";
    }
}