import { Fase } from "./fase";
import { NaturezaJuridica } from "./naturezaJuridica";

export class Servico {
    cliente: string;
    codigo: number;
    codigoAuxiliar: number;
    descricao: string;
    fases: Array<Fase>;
    naturezaJuridicas: Array<NaturezaJuridica>;

    constructor() {
        this.cliente = "";
        this.codigo = 0;
        this.codigoAuxiliar = 0;
        this.descricao = "";
        this.fases = new Array<Fase>();
        this.naturezaJuridicas = new Array<NaturezaJuridica>();
    }
}