import { Component, Input, OnInit } from '@angular/core';
import { formatToCPFOrCNPJ, formatToPhone } from 'brazilian-values';
import { Protocolo } from "src/app/model/protocolo";
import { Constantes } from '../../../../model/constantes';
import { ActivatedRoute } from '@angular/router';
import { Telefone } from '../../../../model/telefone';
import { MascaraPipe } from '../../../../pipes/mascara.pipe';

@Component({
  selector: 'app-informacao-responsavel',
  templateUrl: './informacao-responsavel.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoResponsavelComponent implements OnInit {

  @Input() permiteCriticar = false;
  @Input() permiteInconsistencias = false;  
  @Input() semCardHeader: boolean = false;
  protocolo: Protocolo;

  tipoTelefone = Constantes.TIPO_DE_TELEFONE;
  tipoDocumento = Constantes.TIPO_DE_DOCUMENTO;
  tipoDocumentoDescricao = Constantes.TIPO_DE_DOCUMENTO_DESCRICAO;
  permitirAtendimento: boolean;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private mascara: MascaraPipe,) {
    let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
    let meusProcessos = this.activatedRoute.snapshot.data["menu"] == Constantes.MENU_ATENDIMENTO.Processos;
    let codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"];
    this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !meusNegocios) && codigoOrgao != '100' && !meusProcessos;
  }

  ngOnInit(): void {
  }

  retornarTelefonePorTipo(telefones: Array<Telefone>, tipo) {
    var retorno = "Não Cadastrado";
    telefones.forEach( telefone => {
      if (tipo == telefone.tipo) {
        retorno = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
      }
      if (tipo == telefone.tipo) { 
        retorno = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
      }
      if (tipo == telefone.tipo) { 
        retorno = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
      }
    });
    
    return retorno;
  }

  formatToBRL(valor) {
    return this.mascara.transform(valor, 11);
  }

}
