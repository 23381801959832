<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <!---<div class="card" *ngIf="permitirAtendimento">--->
    <div class="card">
        <div class="card-header">
            <div class="row justify-content-center align-items-center">
                <div class="row col-12" >
                    <strong>Seletor de Inscrição Municipal</strong>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="row col-12 p-0">
                <div class="row col-12 p-0 pt-4 pr-2 justify-content-left align-items-left text-uppercase mb-1">
                    <span class="quantidade-caracter">
                        * Selecione uma das inscrições municipais abaixo para vincular os dados do protocolo a ela ou opte por gerar uma nova inscrição automaticamente pelo sistema.
                    </span>
                </div>
            </div>
            <div class="row col-12 p-0 justify-content-center align-items-center header-atividades">
                <div class="row col-1 p-0 justify-content-start align-items-center"></div>
                <div class="row col-1 p-0 pr-2 justify-content-center align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>Inscrição</strong>
                    </p>
                </div>
                <div class="row col-10 p-0 justify-content-start align-items-center">
                    <p class="card-text titulo-atividades">
                        <strong>Nome</strong>
                    </p>
                </div>
            </div>
            <div class="row col-12 p-0">
                <div class="row col-1 p-0 pt-4 justify-content-center align-items-center" >  
                    <form name="formulario" [formGroup]="formulario" >    
                        <mat-radio-group 
                            #novaIm
                            name="novaIm" 
                            id="novaIm"
                            formControlName="novaIm"
                            aria-label="Companhia">
                            <mat-radio-button class="pr-4" value="novaIm">
                            </mat-radio-button>
                        </mat-radio-group> 
                    </form>
                </div>
                <div class="row col-3 p-0 pt-4 pr-2 justify-content-left align-items-left text-uppercase">
                    <p class="card-text w-300">
                        Gerar uma nova inscrição
                    </p>
                </div>
            </div>
            <div class="row col-12 p-0" *ngFor="let empresa of documentoSelecaoIm; let i = index;">
                <div class="row col-1 p-0 pt-4 justify-content-center align-items-center" >  
                    <form name="formulario" [formGroup]="formulario" >    
                        <mat-radio-group 
                            #novaIm
                            name="novaIm" 
                            id="novaIm"
                            formControlName="novaIm"
                            aria-label="Companhia">
                            <mat-radio-button class="pr-4" value="{{ empresa.inscricao }}">
                            </mat-radio-button>
                        </mat-radio-group> 
                    </form>
                </div>
                <div class="row col-1 p-0 pt-4 pr-2 justify-content-center align-items-center text-uppercase">
                    <p class="card-text w-150">
                        {{ empresa.inscricao }}
                    </p>
                </div>
                <div class="row col-10 p-0 pt-4 justify-content-left align-items-center text-uppercase">
                    <p class="card-text">
                        {{ empresa.nome }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</form>