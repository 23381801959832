import { Component, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ArquivoService } from 'src/app/services/arquivo/arquivo.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Constantes } from 'src/app/model/constantes';
import { GridArquivosVinculosComponent } from '../../../grid-arquivos-vinculos/grid-arquivos-vinculos.component';

@Component({
  selector: 'app-cadastrar-vinculos-por-lote',
  templateUrl: './cadastrar-vinculos-por-lote.component.html',
  styleUrls: ['../../gerenciamento-de-requisitos.component.scss'],
})
export class CadastrarVinculosPorLoteComponent implements OnInit {

  private unsubscribe = new Subject<void>();
  rota = null;
  files: Set<File>;
  formData: FormData;
  currentUser: any;

  @ViewChildren(GridArquivosVinculosComponent)
  gridArquivosVinculos: QueryList<GridArquivosVinculosComponent>;
 
  constructor(private router: Router,
    private arquivoService: ArquivoService,
    private authenticationService: AuthenticationService,) {
      this.rota = this.router.url;
      this.currentUser = this.authenticationService.currentUserValue;
    }

  ngOnInit(): void {}
  
  acaoBotaoVoltar(event) {
    this.router.navigate(["/configuracao/faq"]);
  }
  
  acaoNovoArquivoRetorno150(){
    this.router.navigate([ this.router.url, "cadastroArquivoRetorno150" ]);
  }

  acaoAlterarArquivoRetorno150(codigoModulo, codigoSubModulo, codigoArquivoRetorno150) {
    this.router.navigate([ this.router.url, "editarArquivoRetorno150", codigoModulo, codigoSubModulo, codigoArquivoRetorno150 ]);    
  }
  
  acaoAtendimento(event) {
    if(event.acao == Constantes.ACAO_ATENDIMENTO.Alterar) {
      this.acaoAlterarArquivoRetorno150(event.dados.COD_MODULO, event.dados.COD_SUB_MODULO, event.dados.COD_PERGUNTA);
    }
    else if(event.acao == Constantes.ACAO_ATENDIMENTO.Excluir) {
      // this.acaoExcluirRequisito(event.dados.COD_MODULO, event.dados.COD_SUB_MODULO, event.dados.COD_PERGUNTA);
    }
  }

  upload(event) {
    if (this.arquivos(event)) {
      this.arquivoService
        .uploadArquivoDeVinculos(this.formData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe (retorno => {
          if (retorno.codigo != "") {
            Swal.fire({
              title: "Enviado",
              html: `Caro <b>${ this.currentUser.nome }</b>, você enviou o arquivo: <b>${ this.formData.get("fileName") }</b>, com sucesso.`,
              icon: "success",
            })
            .then((result) => {
              location.reload();
            });
          }
          else {
            Swal.fire({
              title: "Não Enviado",
              html: `Caro <b>${ this.currentUser.nome }</b>, não foi possível enviar o arquivo: <b>${ this.formData.get("fileName") }</b>.`,
              icon: "error",
            })
            .then((result) => {
              location.reload();
            });
          }
        });
    }
    
    (event.target as HTMLInputElement).value = "";
    
  }

  arquivos(event) {
    this.formData = new FormData();
    const arquivosSelecionados = <FileList>event.srcElement.files;
    this.files = new Set();
    for (let i = 0; i < arquivosSelecionados.length; i++) {
      if (arquivosSelecionados[i].name.length > 95) {
        Swal.fire({
          title: "Não Enviado",
          html: `Caro <b>${ this.currentUser.nome }</b>, não foi possível enviar o arquivo: <b>${ arquivosSelecionados[i].name }</b>.<br><br>O nome do arquivo está muito grande, favor diminuir.`,
          icon: "error",
        });

        return false;
      }
      if (arquivosSelecionados[i].size > 5242880) {
        Swal.fire({
          title: "Não Enviado",
          html: `Caro <b>${ this.currentUser.nome }</b>, não foi possível enviar o arquivo: <b>${ arquivosSelecionados[i].name }</b>.<br><br>O tamanho do arquivo está muito grande, favor diminuir.`,
          icon: "error",
        });

        return false;
      }
      this.files.add(arquivosSelecionados[i]);
      this.formData.append('file', arquivosSelecionados[i], arquivosSelecionados[i].name);
      this.formData.append('fileName', arquivosSelecionados[i].name);
    }

    return true;
  }

  openDialog(tipoEmpresa) {
    
  }


}