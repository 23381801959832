import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { GridProviderPaginacao } from "./grid-provider-paginacao";
import { formatToCEP } from "brazilian-values";

@Component({
    selector: "app-grid-endereco",
    templateUrl: "./grid-endereco.component.html",
    styleUrls: ["./grid.component.scss"],
    providers: [ 
        {
            provide: MatPaginatorIntl,
            useClass: GridProviderPaginacao
        }
    ]
})
export class GridEnderecoComponent implements OnInit {
    
    @Output() selecao = new EventEmitter();
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ["cep","tipoLogradouro","logradouro","bairro","cidade"];
    
    @Input() dados: Array<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor() {}
    
    ngOnInit() {
        this.dataSource = new MatTableDataSource(this.dados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    aplicarFiltro(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    formatToCEP(valor) {
        return formatToCEP(valor);
    }

    linhaSelecionada(row) {
        this.selecao.emit({ row });
    }
}