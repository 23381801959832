import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ConsultaViabilidade } from 'src/app/model/consultaViabilidade';


@Injectable({ providedIn: 'root' })
export class ConsultaViabilidadeService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarConcorrentesPorAtividade(cliente, atividades, tipoNegocio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/consultaViabilidade/buscarConcorrentesPorAtividade/${ cliente }/${ atividades }/${ tipoNegocio }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarServicosPublicos(cliente) {
        return this.http.get<any>(
        `${ environment.icadUrl }/consultaViabilidade/buscarServicosPublicos/${ cliente }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPermissaoPorImobiliarioThread(cliente, imobiliario, tipoNegocio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/consultaViabilidade/buscarPermissaoPorImobiliarioThread/${ cliente }/${ imobiliario }/${ tipoNegocio }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPermissaoPorImobiliario(cliente, imobiliario, tipoNegocio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/consultaViabilidade/buscarPermissaoPorImobiliario/${ cliente }/${ imobiliario }/${ tipoNegocio }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPermissaoPorAtividadesThread(cliente, atividades, tipoNegocio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/consultaViabilidade/buscarPermissaoPorAtividadesThread/${ cliente }/${ atividades }/${ tipoNegocio }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPermissaoPorAtividades(cliente, atividades, tipoNegocio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/consultaViabilidade/buscarPermissaoPorAtividades/${ cliente }/${ atividades }/${ tipoNegocio }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPermissaoPorAtividade(cliente, atividades, tipoNegocio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/consultaViabilidade/buscarPermissaoPorAtividade/${ cliente }/${ atividades }/${ tipoNegocio }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPermissaoPorImobiliarioEAtividade(cliente, imobiliario, atividades, tipoNegocio) {
        return this.http.get<any>(
        `${ environment.icadUrl }/consultaViabilidade/buscarPermissaoPorImobiliarioEAtividade/${ cliente }/${ imobiliario }/${ atividades }/${ tipoNegocio }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    verificaRegraParaEmpreendimento(consultaViabilidade: ConsultaViabilidade) {
        return this.http.post<any>(
            `${ environment.icadUrl }/consultaViabilidade/verificaRegraParaEmpreendimento`, JSON.stringify(consultaViabilidade),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
}
