<div class="row col-12 top-atendimento">
    <div class="row col-12 container-principal-atendimento container-tamanho pt-3 pb-3">
        <div class="row col-6 justify-content-start align-items-center">
            <button type="button" class="btn btn-danger" (click)="acaoBotaoVoltar($event)" >Voltar</button>
        </div>
        <div class="row col-6 justify-content-end align-items-center divisao-esquerda">
        <button type="button" 
        class="btn" 
        [class.btn-secondary]="licenciamento.statusDoLicenciamento == 'PO'"
        [class.btn-success]="licenciamento.statusDoLicenciamento != 'PO'"
        (click)="enviarQuestionario()" >Enviar Questionário</button>
        </div>
    </div>
</div>
<div *ngIf="licenciamento != null && empresa != null">
    <div id="navSection" data-spy="affix" class="nav sidenav">
        <div class="sidenav-content">
            <a href="javascript:void(0);" (click)="goToAnchor('tabsDadosLicenciamento')" class="active nav-link tabsDadosLicenciamento">
                <svg class="icone-historico" >
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                    ></use>
                </svg>  
                Dados Licenciamento
            </a>
        </div>
        <a href="javascript:void(0);" (click)="goToAnchor('tabsDocumentos')" class="nav-link tabsDocumentos">
            <svg class="icone-historico" >
                <use
                    attr.xlink:href="#{{ 'segunda_via_documentos' }}"
                ></use>
            </svg>
            Documentos
        </a>
        <div class="sidenav-content">
            <a href="javascript:void(0);" (click)="goToAnchor('tabsObservacoes')" class="nav-link tabsObservacoes">
                <svg class="icone-historico" >
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                    ></use>
                </svg>  
                Observações
            </a>
        </div>
        <div class="sidenav-content" *ngFor="let perguntaLicenciamento of licenciamento.perguntasLicenciamento">
            <a href="javascript:void(0);" 
                matTooltip="{{ perguntaLicenciamento.perfil == '' ? 'Pergunta(s) Fixa(s)' : perguntaLicenciamento.processo }}"
                matTooltipPosition="left"
                (click)="goToAnchor('tabsPerguntas' + perguntaLicenciamento.perfil)" 
                class="nav-link tabsPerguntas{{ perguntaLicenciamento.perfil }}">
                <svg class="icone-historico" >
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                    ></use>
                </svg>  
                {{ perguntaLicenciamento.perfil == '' ? 'Grupo Fixa(s)' : 'Grupo ' + perguntaLicenciamento.perfil }}
            </a>
        </div>
        <div class="sidenav-content">
            <a href="javascript:void(0);" (click)="goToAnchor('tabsFinalizacao')" class="nav-link tabsFinalizacao">
                <svg class="icone-historico" >
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ 'user' }}"
                    ></use>
                </svg>  
                Termo
            </a>
        </div>
    </div>
    <div class="row col-10 layout-top-spacing p-3">
        <div class="row col-12">
            <div id="tabsDadosLicenciamento" class="card w-100" >
                <div class="container-principal-atendimento">
                    <div class="card">
                        <div class="card-header">
                            <div class="row col-12 justify-content-center align-items-center">
                                <strong>QUESTIONÁRIO PARA OBTENÇÃO DE LICENÇA</strong>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="form-row row-linha linha-grid-divisao justify-content-center align-items-center">
                                <div class="col-2">
                                    <p class="card-text">
                                        <strong>Nome:</strong>
                                    </p>
                                </div>
                                <div class="col-10">
                                    <p class="card-text">
                                        <strong>{{ empresa.registro.nome }}</strong>
                                    </p>
                                </div>
                            </div>
                
                            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                                <div class="col-2">
                                    <p class="card-text">
                                        <strong>CPF / CNPJ:</strong>
                                    </p>
                                </div>
                                <div class="col-10">
                                    <p class="card-text">
                                        <strong>{{ empresa.registro.documentos[1].numero }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                                <div class="col-2">
                                    <p class="card-text">
                                        <strong>Insc. Mobiliária:</strong>
                                    </p>
                                </div>
                                <div class="col-4">
                                    <p class="card-text">
                                        <strong>{{ empresa.inscricao }} </strong>
                                    </p>
                                </div>
                                <div class="col-2">
                                    <p class="card-text">
                                        <strong>Data de Abertura: </strong>
                                    </p>
                                </div>
                                <div class="col-4">
                                    <p class="card-text">
                                        <strong> {{ empresa.registro.documentos[1].dataEmissao }} </strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                                <div class="col-2">
                                    <p class="card-text">
                                        <strong>Insc. Estadual: </strong>
                                    </p>
                                </div>
                                <div class="col-4">
                                    <p class="card-text">
                                        <strong>{{ empresa.registro.documentos[2].numero }}</strong>
                                    </p>
                                </div>
                                <div class="col-2">
                                    <p class="card-text">
                                        <strong>Data de Insc. Estadual:</strong>
                                    </p>
                                </div>
                                <div class="col-4">
                                    <p class="card-text">
                                        <strong>{{ empresa.registro.documentos[2].dataEmissao }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                                <div class="col-2">
                                    <p class="card-text">
                                        <strong>Órgão:</strong>
                                    </p>
                                </div>
                                <div class="col-10">
                                    <p class="card-text">
                                        <strong>{{ empresa.registro.orgaoNome}}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                                <div class="col-2">
                                    <p class="card-text">
                                        <strong>Requisitos:</strong>
                                    </p>
                                </div>
                                <div class="col-10">
                                    <p class="card-text">
                                        <strong>{{ licenciamento.requisito.codigo }} - {{ licenciamento.requisito.nome }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                                <div class="col-2">
                                    <p class="card-text">
                                        <strong>Status:</strong>
                                    </p>
                                </div>
                                <div class="col-10">
                                    <p class="card-text">
                                        <strong>{{ tipoDePendenciaLicenciamentoDescricao[licenciamento.statusDoLicenciamento] }}</strong>
                                    </p>
                                </div>
                            </div>
                
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row col-12">
            <div id="tabsDocumentos" class="card w-100">
                <div class="container-principal-atendimento">
                    <app-informacao-documento></app-informacao-documento>
                </div>
            </div>
        </div>

        <div class="row col-12">
            <div id="tabsObservacoes" class="card w-100">
                <div class="container-principal-atendimento">
                    <app-atendimento-observacao></app-atendimento-observacao>
                </div>
            </div>
        </div>

         <!-- Questionários  -->
         <div class="row col-12 layout-spacing" *ngFor="let perguntaLicenciamento of licenciamento.perguntasLicenciamento">
            <div [id]="'tabsPerguntas' + perguntaLicenciamento.perfil" class="row col-12 container-principal-atendimento" >
                <app-form-questionario 
                    class="w-100"
                    [nome]="perguntaLicenciamento.perfil"
                    tipo="L"
                    permitirAtendimento="false"
                    [apenasAcompanhamento]="licenciamento.statusDoLicenciamento == 'PO'"
                    [licenciamentoCriticado]="licenciamento.statusDoLicenciamento == tipoDePendenciaLicenciamento.Pendente_Usuario"
                    [titulo]="perguntaLicenciamento.perfil == '' ? 'Grupo Pergunta(s) Fixa(s)' : 'Grupo ' + perguntaLicenciamento.perfil + ' - ' + perguntaLicenciamento.processo"
                    [perguntasCarregadasDeInicio]="perguntaLicenciamento.perguntas"></app-form-questionario>
            </div>
        </div>
        
        <form
            class="justify-content-center align-items-center"
            name="formulario"
            [formGroup]="formulario"
        >
            <div class="row col-12">
                <div id="tabsFinalizacao" class="card w-100">
                    <div class="container-principal-atendimento">
                        <div class="card">
                            <div class="card-header">
                                <div class="row col-12 justify-content-start align-items-center">
                                    <strong>Termo de Responsabilidade</strong>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row col-12 row-linha justify-content-start align-items-center">
                                    <div class="row col-1 justify-content-center align-items-center">
                                        <mat-checkbox 
                                            formControlName="termoDeAceite"
                                            id="termoDeAceite"
                                            name="termoDeAceite">
                                        </mat-checkbox>
                                    </div>
                                    <div class="row col-11">
                                        <p class="card-text">
                                            Registra-lhe que "omitir; em documento público ou particular, declaração que dele deverá constar, ou nele inserir ou fazer inserir declaração falsa ou diversa da que devia ser escrita, com o fim de prejudicar direito, criar obrigação ou alterar verdade sobre fato juridicamente relevante" é crime previsto no art. 299 do Código Penal Brasileiro.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>