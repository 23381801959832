import { OnInit, Component, Input, ViewChild } from "@angular/core";
import { take } from "rxjs/operators";
import { Processo } from "../../../model/processo";
import { ProcessoService } from '../../../services/processo/processo.service';
import { ActivatedRoute } from '@angular/router';
import { Fase } from '../../../model/fase';
import { Constantes } from '../../../model/constantes';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TimeLineHorizontalComponent } from './time-line-horizontal/time-line-horizontal.component';
import { TimeLineVerticalComponent } from './time-line-vertical/time-line-vertical.component';
import { Observacao } from '../../../model/observacao';

@Component({
    selector: "app-time-line-protocolo",
    templateUrl: "./time-line-protocolo.component.html"
})
export class TimeLineProtocoloComponent implements OnInit {

    public config: PerfectScrollbarConfigInterface = {};

    @Input() dados: Array<any>;
    titulo: string;
    numeroProtocolo: string;
    fases: Array<Fase>;
    observacoes: Array<Observacao>;
    situacaoAcompanhamento = Constantes.SITUACAO_DE_ACOMPANHAMENTO;

    @ViewChild(TimeLineHorizontalComponent)
    timeLineHorizontal: TimeLineHorizontalComponent;

    @ViewChild(TimeLineVerticalComponent)
    timeLineVertical: TimeLineVerticalComponent;

    constructor(
        private activatedRoute: ActivatedRoute,
        private processoService: ProcessoService) {
        this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];
    }

    ngOnInit(): void {
        if (this.dados != null) {
            this.numeroProtocolo = this.dados[0];
        }

        this.executarBusca();
    }

    executarBusca() {
        if (this.numeroProtocolo != null) {
            this.processoService
                .buscarPorProtocoloComObservacoes(this.numeroProtocolo)
                .pipe(take(1))
                .subscribe( retorno => {
                    if (retorno[0].servicos[0].fases != null) {
                        this.fases = retorno[0].servicos[0].fases;
                        this.observacoes = retorno[1];
                        this.carregarHorizontal();
                        this.carregarVertical();
                    }
                })
        }
    }

    carregarHorizontal() {
        this.timeLineHorizontal.numeroProtocolo = this.numeroProtocolo;
        this.timeLineHorizontal.fases = this.fases;
    }

    carregarVertical() {
        this.timeLineVertical.numeroProtocolo = this.numeroProtocolo;
        this.timeLineVertical.fases = this.fases;

        let listaDeProcessos: Set<string> = new Set();
        this.observacoes.forEach( observacao => {
            listaDeProcessos.add(observacao.tipo);
        });

        let observacoesPorProcessos = [];
        listaDeProcessos.forEach( processo => {
            let observacoesAuxiliar = new Array<Observacao>();
            this.observacoes.forEach( observacao => {
                if (observacao.tipo == processo) {
                    observacoesAuxiliar.push(observacao);
                }
            });
            observacoesPorProcessos.push(observacoesAuxiliar);
        });

        this.timeLineVertical.listaDeProcessos = listaDeProcessos;
        this.timeLineVertical.observacoesPorProcessos = observacoesPorProcessos;
    }
}