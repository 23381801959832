import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatRadioModule } from "@angular/material/radio";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";

import { FormUsuarioComponent } from "./form-usuario/form-usuario.component";
import { FormEnderecoComponent } from "./form-endereco/form-endereco.component";
import { FormRequerenteComponent } from "./form-requerente/form-requerente.component";
import { FormLocalizacaoComponent } from "./form-localizacao/form-localizacao.component";
import { FormAtividadeComponent } from "./form-atividade/form-atividade.component";
import { FormResumoComponent } from "./form-resumo/form-resumo.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AlertModule } from "src/app/components/alert/alert.module";
import { NgxMaskModule } from "ngx-mask";
import { PipeModule } from "src/app/pipes/pipes.module";
import { FormIdentificacaoNegocioComponent } from "./form-identificacao-negocio/form-identificacao-negocio.component";
import { FormResponsavelComponent } from "./form-responsavel/form-responsavel.component";
import { FormIdentificacaoComponent } from './form-identificacao/form-identificacao.component';
import { FormAutonomoRequerenteComponent } from "./form-autonomo-requerente/form-autonomo-requerente.component";
import { FormResponsavelDadosComponent } from "./form-responsavel/form-responsavel-dados.component";
import { InformacoesCadastroModule } from "./informacoes-cadastro/informacoes-cadastro.module";
import { FormEncerramentoComponent } from "./form-encerramento/form-encerramento.component";
import { FormQuestionarioModule } from './form-questionario/form-questionario.module';
import { FormLocalizacaoPesquisaComponent } from './form-localizacao-pesquisa/form-localizacao-pesquisa.component';
import { FormAtividadePesquisaModule } from './form-atividade-pesquisa/form-atividade-pesquisa.module';
import { FormTipoNegocioComponent } from './form-tipo-negocio/form-tipo-negocio.component';
import { FormIdentificacaoRequerenteComponent } from './form-identificacao-requerente/form-identificacao-requerente.component';
import { InformacaoRedeSimModule } from './informacoes-cadastro/informacao-rede-sim/informacao-rede-sim.module';
import { FormObservacaoEventoComponent } from "./form-observacao-evento/form-observacao-evento.component";
import { AnexarModule } from "./anexar/anexar.module";
import { InformacaoRedeSimPerguntasModule } from "./informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.module";
import { AtendimentoAcaoModule } from "./atendimento/atendimento-acao/atendimento-acao.module";

const componentes = [
    FormUsuarioComponent,
    FormEnderecoComponent,
    FormRequerenteComponent,
    FormLocalizacaoComponent,
    FormAtividadeComponent,
    FormResumoComponent,
    FormIdentificacaoNegocioComponent,
    FormObservacaoEventoComponent,
    FormResponsavelComponent,
    FormResponsavelDadosComponent,
    FormIdentificacaoComponent,
    FormAutonomoRequerenteComponent,
    FormEncerramentoComponent,
    FormLocalizacaoPesquisaComponent,
    FormTipoNegocioComponent,
    FormIdentificacaoRequerenteComponent,
]

@NgModule({
    declarations: [
        componentes,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatRadioModule,
        MatDialogModule,
        MatDividerModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatSlideToggleModule,
        AlertModule,
        NgxMaskModule.forRoot(),
        PipeModule,
        InformacoesCadastroModule,
        FormQuestionarioModule,
        FormAtividadePesquisaModule,
        InformacaoRedeSimModule,
        AnexarModule,
        InformacaoRedeSimPerguntasModule,
        AtendimentoAcaoModule,
    ],
    exports: [
        componentes,
        FormQuestionarioModule,
        FormAtividadePesquisaModule
    ]
})
export class OutrosModule {

}