import { Component, OnInit } from '@angular/core';
import { Pendencia } from '../../../../model/pendencia';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { Protocolo } from '../../../../model/protocolo';
import { Empresa } from '../../../../model/empresa';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { Licenciamento } from '../../../../model/licenciamento';
import { Despacho } from 'src/app/model/despacho';

@Component({
    selector: "app-atendimento-pendencia-modal",
    templateUrl: "./atendimento-pendencia-modal.component.html",
    styleUrls: ['./atendimento-pendencia-modal.component.scss']
})
export class AtendimentoPendenciaModalComponent implements OnInit {

    protocolo: Protocolo;
    empresa: Empresa;
    licenciamentos: Array<Licenciamento>;
    pendencias: Array<Pendencia>;
    pendenciasSelecionadas: Array<Pendencia>;

    despachos: Array<Despacho>;

    constructor(
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private router: Router) {
        this.pendenciasSelecionadas = new Array<Pendencia>();

    }

    ngOnInit(): void {
    }

    analisarPendencia(pendencia: Pendencia){
      pendencia.inscricao = this.activatedRoute.snapshot.params["inscricao"];
      const dialogRef = this.dialog.open(IcadModalComponent, {
          panelClass: "teste",
          width: '100%',
          height: '100%',
          maxWidth: 'none',
          maxHeight: 'none',
          closeOnNavigation: true,
          data: {
              titulo: "Atendimento de pendência",
              componente: "atendimento-pendencia",
              dados: [pendencia, this.protocolo == null ? this.empresa : this.protocolo, "AUDITOR", this.licenciamentos]
          }
      });
    }

}