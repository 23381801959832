<div *ngIf="pendencias == null" class="row col-12">
    <div class="form-row col-12 row-linha">
        <div class="col-12">
            <p class="card-text text-center">
                Carregando...
            </p>
        </div>
    </div>      
</div>
<div  *ngIf="pendencias != null" class="row col-12 p-0 pl-2 justify-content-center align-items-center">
    <div class="row col-12 p-0 pl-2 justify-content-start align-items-center ng-star-inserted">
        <!-- <a class="nav-link mb-2 text-center pl-2 pr-2 pb-0 pt-0 m-0" 
            id="atendimento-pendencia" 
            href="javascript:void(0);" 
            role="tab" 
            aria-controls="atendimento-requisito" 
            aria-selected="false"
            (click)="analisarPendencia(pendencia)"
            *ngFor="let pendencia of pendencias; let i = index">
            <svg class="iconeGrid {{ pendencia.classe }}" id="atendimento-pendencia" >
                <use attr.xlink:href="assets/sprite.svg#{{ pendencia.icone }}"></use>
            </svg> - {{ pendencia.descricaoTipo }}
        </a> -->
    </div>
</div>