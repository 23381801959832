import { PipeTransform, Pipe } from "@angular/core";
import { MaskPipe } from "ngx-mask";

@Pipe({
	name: "mascara"
})
export class MascaraPipe implements PipeTransform {
	
	transform(value: any, ...args: any[]) {
		let result = value;
		
		switch (args[0]) {
			case 1:
				return this.area(result);
			case 2:
				return this.virgula(result);
			case 4:
				return this.hora(result);
			case 5:
				return this.horaComposta(result);
			case 6:
				return this.dataNoValid(result);
			case 7:
				return this.dataComposta(result);
			case 8:
				return this.telefoneTraco(result);
			case 9:
				return this.integer(result);
			case 10:
				return this.cnae(result);
			case 11:
				return this.valor(result);
			case 12:
				return this.cbo(result);
			default:
				return result;
		}
		
	}

	area(valor) {
		valor = valor.toString().replace(/\D/g,"");
		valor = '00' + valor ;
		valor = valor.toString().replace(/(\d)(\d{2})$/,"$1.$2");
		valor = parseFloat(valor).toFixed(2);
		return valor;
	}

	virgula(valor) {
		valor = valor.toString().replace(/[,]/g,"");
		return valor;
	}

	hora(valor) {
		let retorno ="";
		valor = valor.toString().replace(/\D/g,"");
		valor = valor.toString().replace(/([0-2])|(\d{1})/,"$1");
		valor = valor.toString().replace(/([01][0-9]|[2][0-3])|(\d{2})/,"$1");
		valor = valor.toString().replace(/(\d{2})(\d)/,"$1:$2");
		retorno = valor.toString().substring(0,3);
		valor = valor.toString().replace(/(\d{2}\D[0-5])|(\d{2}\D[6-9])/,"$1");
		valor = valor == "" ? retorno : valor;
		
		return valor;
	}

	horaComposta(valor) {
		let retorno ="";
		valor = valor.toString().replace(/\D/g,"");
		valor = valor.toString().replace(/([0-2])|(\d{1})/,"$1");
		valor = valor.toString().replace(/([01][0-9]|[2][0-3])|(\d{2})/,"$1");
		valor = valor.toString().replace(/(\d{2})(\d)/,"$1:$2");
		retorno = valor.toString().substring(0,3);
		valor = valor.toString().replace(/(\d{2}\D[0-5])|(\d{2}\D[6-9])/,"$1");
		valor = valor == "" ? retorno : valor;
		valor = valor.toString().replace(/(\d{2}\D\d{2})(\d)/,"$1/$2");
		retorno = valor.toString().substring(0,5);
		valor = valor.toString().replace(/(\d{2}\D\d{2}\D[0-2])|(\d{2}\D\d{2}\D[3-9])/,"$1");
		valor = valor == "" ? retorno : valor;
		retorno = valor.toString().substring(0,7);
		valor = valor.toString().replace(/(\d{2}\D\d{2}\D[01][0-9]|\d{2}\D\d{2}\D[2][0-3])|(\d{2}\D\d{2}\D\d{2})/,"$1");
		valor = valor.toString().replace(/(\d{2})(\d)/,"$1:$2");
		valor = valor == "" ? retorno : valor;
		retorno = valor.toString().substring(0,8);
		valor = valor.toString().replace(/(\d{2}\D\d{2}\D\d{2}\D[0-5])|(\d{2}\D\d{2}\D\d{2}\D[6-9])/,"$1");
		valor = valor == "" ? retorno : valor;
		
		return valor;
	}

	dataNoValid(valor) {
		let retorno = "";
		let primeiroDia = valor.toString().substring(0,2);
		let primeiroMes = valor.toString().substring(3,5);
		let primeiroAno = valor.toString().substring(6,10);
		let data = new Date();
		let diaAtual = data.getDate();
		let mesAtual = data.getMonth()+1;
		let anoAtual = data.getFullYear();
		
		valor = valor.toString().replace(/\D/g,"");
		valor = valor.toString().replace(/([0-3])|(\d)/,"$1");
		valor = valor.toString().replace(/([0-2][0-9]|[3][01])|(\d{2})/,"$1");
		valor = valor.toString().replace(/(\d{2})(\d)/,"$1/$2");
		retorno = valor.toString().substring(0,2);
		valor = valor.toString().replace(/(\d{2}\D[01])|(\d{2}\D\d)/,"$1");
		valor = valor == "" ? retorno : valor;
		retorno = valor.toString().substring(0,5);
		valor = valor.toString().replace(/(\d{2}\D[0][0-9]|\d{2}\D[1][0-2])|(\d{2}\D[2-9]\d)/,"$1");
		valor = valor == "" ? retorno : valor;
		valor = valor.toString().replace(/(\d{2})(\d)/,"$1/$2");
		primeiroAno > 2100 ?  "" : 
		primeiroAno == anoAtual && primeiroMes > 12 ? "" : 
		primeiroAno == anoAtual && primeiroMes == mesAtual && primeiroDia > diaAtual ? valor = "" : 0;
		
		return valor;
	}

	data(valor){
		let retorno = '';
		let primeiroDia = valor.substring(0,2);
		let primeiroMes = valor.substring(3,5);
		let primeiroAno = valor.substring(6,10);
		let data = new Date();
		let diaAtual = data.getDate();
		let mesAtual = data.getMonth()+1;
		let anoAtual = data.getFullYear();
		
		valor = valor.replace(/\D/g,"");
		valor = valor.replace(/([0-3])|(\d)/,"$1");
		valor = valor.replace(/([0-2][0-9]|[3][01])|(\d{2})/,"$1");
		valor = valor.replace(/(\d{2})(\d)/,"$1/$2");
		retorno = valor.substring(0,2);
		valor = valor.replace(/(\d{2}\D[01])|(\d{2}\D\d)/,"$1");
		valor == '' ? valor = retorno : 0;
		retorno = valor.substring(0,5);
		valor = valor.replace(/(\d{2}\D[0][0-9]|\d{2}\D[1][0-2])|(\d{2}\D[2-9]\d)/,"$1");
		valor == '' ? valor = retorno : 0;
		valor = valor.replace(/(\d{2})(\d)/,"$1/$2");
		primeiroAno > anoAtual ? valor = '' : 
		primeiroAno == anoAtual && primeiroMes > mesAtual ? valor = '' : 
		primeiroAno == anoAtual && primeiroMes == mesAtual && primeiroDia > diaAtual ? valor = '' : 0;
		return valor
	}

	dataComposta(valor) {
		let retorno = '';
		let primeiroDia = valor.toString().substring(0,2);
		let primeiroMes = valor.toString().substring(3,5);
		let primeiroAno = valor.toString().substring(6,10);
		let segundoDia  = valor.toString().substring(11,13);
		let segundoMes  = valor.toString().substring(14,16);
		let segundoAno  = valor.toString().substring(17,21);
		let data = new Date();
		let diaAtual = data.getDate();
		let mesAtual = data.getMonth()+1;
		let anoAtual = data.getFullYear();
		
		valor = valor.toString().replace(/\D/g,"");
		valor = valor.toString().replace(/([0-3])|(\d)/,"$1");
		valor = valor.toString().replace(/([0-2][0-9]|[3][01])|(\d{2})/,"$1");
		valor = valor.toString().replace(/(\d{2})(\d)/,"$1/$2");
		retorno = valor.toString().substring(0,2);
		valor = valor.toString().replace(/(\d{2}\D[01])|(\d{2}\D\d)/,"$1");
		valor = valor == "" ? retorno : valor;
		retorno = valor.toString().substring(0,4);
		valor = valor.toString().replace(/(\d{2}\D[0][0-9]|\d{2}\D[1][0-2])|(\d{2}\D\d{2})/,"$1");
		valor = valor == "" ? retorno : valor;
		valor = valor.toString().replace(/(\d{2})(\d)/,"$1/$2");
		primeiroAno > anoAtual ? valor = '' : 
		primeiroAno == anoAtual && primeiroMes > mesAtual ? valor = '' : 
		primeiroAno == anoAtual && primeiroMes == mesAtual && primeiroDia > diaAtual ? valor = '' : 0;
		valor = valor.toString().replace(/(\d{4})(\d)/,"$1:$2");
		retorno = valor.toString().substring(0,10);
		valor = valor.toString().replace(/(\d{2}\D\d{2}\D\d{4}\D[0-3])|(\d{2}\D\d{2}\D\d{4}\D\d)/,"$1");
		valor = valor == "" ? retorno : valor;
		retorno = valor.toString().substring(0,12);
		valor = valor.toString().replace(/(\d{2}\D\d{2}\D\d{4}\D[0-2][0-9]|\d{2}\D\d{2}\D\d{4}\D[3][01])|(\d{2}\D\d{2}\D\d{4}\D\d{2})/,"$1");
		valor = valor == "" ? retorno : valor;
		valor = valor.toString().replace(/(\d{4}\D\d{2})(\d)/,"$1/$2");
		retorno = valor.toString().substring(0,15);
		valor = valor.toString().replace(/(\d{2}\D\d{2}\D\d{4}\D\d{2}\D[0][0-9]|\d{2}\D\d{2}\D\d{4}\D\d{2}\D[1][0-2])|(\d{2}\D\d{2}\D\d{4}\D\d{2}\D\d{2})/,"$1");
		valor = valor == "" ? retorno : valor;
		valor = valor.toString().replace(/([:]\d{2}\D\d{2})(\d)/,"$1/$2");
		retorno = valor.toString().substring(0,10);
		//segundoAno > anoAtual ? valor = '' : 
		//segundoAno == anoAtual && segundoMes > mesAtual ? valor = '' : 
		//segundoAno == anoAtual && segundoMes == mesAtual && segundoDia > diaAtual ? valor = '' : 0;
		valor == "" ? valor = retorno : 0;
		if (valor.length == 21){
			retorno = valor.toString().substring(0,10);
			primeiroAno > segundoAno ? valor = '' : 
			primeiroAno == segundoAno && primeiroMes > segundoMes ? valor = '' : 
			primeiroAno == segundoAno && primeiroMes == segundoMes && primeiroDia > segundoDia ? valor = '' : 0;;
			valor == '' ? valor = retorno : 0;
		}
		
		return valor;
	}

	telefoneTraco(valor) {
		valor = valor.toString().replace(/\D/g,"");
		valor = valor.toString().replace(/(\d{2})(\d)/,"$1-$2");
		valor = valor.toString().replace(/(\d{4})(\d)/g,"$1-$2");

		return valor;
	}

	integer(valor) {
		valor = valor.toString().replace(/\D/g,"");

		return valor;
	}

	cnae(valor) {
		valor = valor.toString().replace(/\D/g,"");
		valor = valor.length > 7 ? valor.toString().replace(/(\d{7})/,"$1/") : valor;
		valor = valor.toString().replace(/(\d{4})/,"$1-");

		return valor;
	}

	cbo(valor){
		valor = valor.toString().replace(/\D/g,"");
		valor = valor.toString().replace(/(\d{4})(\d)/,"$1-$2");

		return valor;
	}
	
	valor(valor){
		var numeroNegativo = valor.toString().search('-') > -1;
		valor = valor.toString().replace(/\D/g,"")
		valor = '00' + valor
		valor = valor.toString().replace(/(\d)(\d{2})$/,"$1.$2")
		valor = parseFloat(valor).toFixed(2)
		valor = valor.toString().toString().replace(".",",")
		valor = valor.toString().replace(/(\d)(\d{3})\b/,"$1.$2")
		valor = valor.toString().replace(/(\d)(\d{3})\b/,"$1.$2")
		valor = valor.toString().replace(/(\d)(\d{3})\b/,"$1.$2")

		if (numeroNegativo) {
			valor = '-' + valor.toString()
		}

		return valor
	}


}