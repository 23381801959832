import { Component } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";

import { LoaderService } from "src/app/services/loader/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent {
  isLoading: boolean;

  constructor(private loaderService: LoaderService,
    private ngxService: NgxUiLoaderService) {}
  ngOnInit() {
    this.loaderService.isLoading.subscribe((event) => {
      this.isLoading = event;
      if (event) {
        this.ngxService.start();
      }
      else {
        this.ngxService.stop();
      }
    });
    // this.isLoading = true;
    // this.ngxService.start();
  }
}
