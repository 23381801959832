<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12 justify-content-start align-items-center">
            <strong>Atendimento</strong>
        </div>
    </div>
    <div *ngIf="atendimentoAlvara == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="atendimentoAlvara != null" >
        <div class="row p-0">
            <div class="row col-12 justify-content-center align-items-center header-risco text-uppercase"
                [class.btn-success]="atendimentoAlvara.situacaoEmpresa == situacaoAlvaraEmpresa.Regular.toString() || atendimentoAlvara.situacaoEmpresa == situacaoAlvaraEmpresa.Regular_Cancelado.toString()"
                [class.btn-secondary]="atendimentoAlvara.situacaoEmpresa == ''"
                [class.btn-danger]="atendimentoAlvara.situacaoEmpresa == situacaoAlvaraEmpresa.Irregular.toString()"
                [class.btn-info]="atendimentoAlvara.situacaoEmpresa == situacaoAlvaraEmpresa.Indefinida.toString()"
                [class.btn-warning]="atendimentoAlvara.situacaoEmpresa == situacaoAlvaraEmpresa.Em_Regularizacao.toString()">
                <p class="card-text titulo-atividades text-uppercase">
                    <strong>Atualmente a empresa está com sua situação {{ situacaoAlvaraEmpresaDescricao[atendimentoAlvara.situacaoEmpresa] }}</strong>
                </p>
            </div>
            <div class="row col-12 row-linha" >&nbsp;</div>
            <div class="row col-12 row-linha" >
                <p class="card-text">
                    Caro Auditor, para o atendimento dos documentos favor se atentar a validade apresentada, essa validade tem influência em todo o comportamento dos documentos.<br>
                    Se for necessário o ajuste desta informação, clicar no icone <svg class="icone-editar"><use attr.xlink:href="assets/sprite.svg#{{ 'edit' }}" ></use></svg> e informar a validade.<br>
                    Após as devidas análises utilizar o botão de atendimento com a opção desejada.
                </p>
            </div>
            <div class="row col-12 row-linha" *ngIf="atendimentoAlvara.quantidadeRenovacoes">
                <div class="row col-12 row-linha" >&nbsp;</div>
                <app-alert class="w-100" type="warning">
                    <div>
                        ATENÇÃO - {{ atendimentoAlvara.quantidadeRenovacoes.toString() == '1' ? 'Existe' : 'Existem' }} {{ atendimentoAlvara.quantidadeRenovacoes }} {{ atendimentoAlvara.quantidadeRenovacoes.toString() == '1' ? 'renovação' : 'renovações' }} para essa empresa.
                    </div>
                </app-alert>
            </div>
            <div class="row col-12 row-linha" >&nbsp;</div>
            <div class="row col-12" >
                <div class="row col-5 justify-content-start align-items-center" >
                    <div class="row col-2">
                        <p class="card-text">
                            TIPO
                        </p>
                    </div>
                    <div class="row col-10">
                        <select 
                            #tipoDocumentoSelect
                            (change)="acaoTipoDocumentoSelect(tipoDocumentoSelect.value)"
                            class="form-control form-control-sm"
                            name="tipoDocumento" 
                            id="tipoDocumento"                
                        >
                            <option value=""></option>
                            <option *ngFor="let tipo of tiposDocumento" 
                                [selected]="atendimentoAlvara.tipoDocumento == tipo.codigo"
                                value="{{ tipo.codigo }}"
                            >
                                {{ tipo.nome }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row col-5" >
                    <div class="row col-12 justify-content-center align-items-center">
                        <p class="card-text text-uppercase">
                            <strong *ngIf="atendimentoAlvara.validadeDocumento == ''"> 
                                VALIDADE: INDETERMINADA
                            </strong>
                            <strong *ngIf="atendimentoAlvara.validadeDocumento != ''">
                                VALIDADE: {{ atendimentoAlvara.validadeDocumento }}
                            </strong>
                            <button type="button" 
                                matTooltip="Editar Validade"
                                matTooltipPosition="above"
                                class="btn"
                                (click)="inserirValidade(atendimentoAlvara)">
                                <svg class="icone-editar">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                                    ></use>
                                </svg>
                            </button>
                        </p>
                    </div>
                </div>
                <div class="row col-2" *ngIf="acoesDeAtendimento.length" >
                    <div class="row col-12 justify-content-end align-items-center">
                        <div ngbDropdown class="btn-group">
                            <button type="button" class="btn btn-success dropdown-toggle" ngbDropdownToggle>Atendimento</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                <a *ngFor="let acao of acoesDeAtendimento" class="dropdown-item" href="javascript:void(0)" (click)="acaoDeAtendimento(acao.value, atendimentoAlvara)">{{ acao.descricao }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row col-12 row-linha" *ngIf="atendimentoAlvara.codigoDocumento != ''">
                <div class="row col-12 row-linha" >&nbsp;</div>
                <app-alert class="w-100" type="info">
                    <div>
                        Existe um documento disponível para impressão, para imprimir acesse o botão de atendimento.
                    </div>
                </app-alert>
            </div>
            <div class="row col-12 row-linha" >&nbsp;</div>
            <div class="row col-12 quadro-divisor-titulo-requisito" >
                <div class="row col-12">
                    <p class="card-text text-uppercase">
                        <b>Histórico</b>
                    </p>
                </div>
            </div>
            <div class="row col-12 quadro-divisor-historico-requisito">
                <app-historico-atendimento-alvara class="w-100" [dados]="[atendimentosAlvara,'atendimento']"></app-historico-atendimento-alvara>
            </div>
        </div>
    </div>
</div>