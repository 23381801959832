import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModalComponent } from './ui-modal/ui-modal.component';
import { AnimationModalComponent } from './animation-modal/animation-modal.component';
import { IcadModalComponent } from "./icad-modal/icad-modal.component";
import { MatDialogModule } from '@angular/material/dialog';
import { GridModule } from "../grid/grid.module";
import { AutenticacaoModule } from "src/app/pages/outros/autenticacao/autenticacao.module";
import { AtendimentoModule } from "src/app/pages/outros/atendimento/atendimento.module";
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { AnexarModule } from '../../pages/outros/anexar/anexar.module';
import { LicenciamentoModule } from '../../pages/outros/licenciamento/licenciamento.module';
import { GerenciamentoDeRequisitosModule } from '../../pages/configuracao/gerenciamento-de-requisitos/gerenciamento-de-requisitos.module';
import { TimeLineModule } from '../../pages/outros/time-line/time-line.module';
import { ConsultarProtocoloModule } from '../../pages/operacao/consulta/consultar-protocolo.module';
import { AtendimentoGridConsultaModule } from '../../pages/outros/atendimento/atendimento-grid-consulta/atendimento-grid-consulta.module';
import { LinhaDoTempoModule } from '../../pages/outros/linha-do-tempo/linha-do-tempo.module';
import { AnaliseEntornoModule } from '../../pages/outros/analise-entorno/analise-entorno.module';
import { EnviarEmailSuporteModule } from '../../pages/outros/enviar-email-suporte/enviar-email-suporte.module';
import { AtendimentoPareceresModule } from '../../pages/outros/atendimento/atendimento-pareceres/atendimento-pareceres.module';
import { InformacaoRedeSimModule } from '../../pages/outros/informacoes-cadastro/informacao-rede-sim/informacao-rede-sim.module';
import { InformacaoRedeSimPerguntasModule } from 'src/app/pages/outros/informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.module';
import { MemoriaDeCalculoModule } from '../../pages/outros/taxas/memoria-de-calculo/memoria-de-calculo.module';
import { GerenciamentoDePerguntasModule } from 'src/app/pages/configuracao/gerenciamento-de-perguntas/gerenciamento-de-perguntas.module';
import { GridParcelasTfeModule } from '../../pages/outros/taxas/grid-parcelas-tfe/grid-parcelas-tfe.module';
import { GridArquivosVinculosComponent } from 'src/app/pages/configuracao/grid-arquivos-vinculos/grid-arquivos-vinculos.component';
import { GridArquivosVinculosModule } from '../../pages/configuracao/grid-arquivos-vinculos/grid-arquivos-vinculos.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

@NgModule({
  declarations: [
    UiModalComponent, 
    AnimationModalComponent, 
    IcadModalComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    GridModule,
    AutenticacaoModule,
    AtendimentoModule,
    AtendimentoGridConsultaModule,
    PerfectScrollbarModule,
    AnexarModule,
    LicenciamentoModule,
    GerenciamentoDeRequisitosModule,
    GerenciamentoDePerguntasModule,
    TimeLineModule,
    ConsultarProtocoloModule,
    LinhaDoTempoModule,
    AnaliseEntornoModule,
    EnviarEmailSuporteModule,
    AtendimentoPareceresModule,
    InformacaoRedeSimModule,
    InformacaoRedeSimPerguntasModule,
    MemoriaDeCalculoModule,
    GridParcelasTfeModule,
    GridArquivosVinculosModule,
  ],
  exports: [
    UiModalComponent, 
    AnimationModalComponent, 
    IcadModalComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class ModalModule { }
