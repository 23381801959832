import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      return true;
    }

    if (state.url.indexOf("servicoParaCidadao") != -1) {
      if (state.url.indexOf("pesquisaViabilidade") != -1) {
        this.router.navigate(["/portal-home"], {queryParams: {exibir: "PESQUISA-VIABILIDADE"}});
      }
      else if (state.url.indexOf("verifiqueAutenticidadeDoAlvara") != -1) {
        this.router.navigate(["/portal-home"], {queryParams: {exibir: "VERIFIQUE-AUTENTICIDADE-DO-ALVARA"}});
      }
      else if (state.url.indexOf("faq") != -1) {
        this.router.navigate(["/portal-home"], {queryParams: {exibir: "FAQ"}});
      }
    }
    else if (state.url.replace("/","") != "") {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }
    else {
      this.router.navigate(['/portal-home'], { queryParams: { returnUrl: state.url } });
    }

    return false;
  }
}
