import { OnInit, Component, Input, ViewChild } from "@angular/core";
import { take } from "rxjs/operators";
import { Processo } from '../../../model/processo';
import { ProcessoService } from '../../../services/processo/processo.service';
import { ActivatedRoute } from '@angular/router';
import { Constantes } from '../../../model/constantes';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TimeLineHorizontalComponent } from './time-line-horizontal/time-line-horizontal.component';
import { TimeLineVerticalComponent } from './time-line-vertical/time-line-vertical.component';
import { Observacao } from '../../../model/observacao';
import { EmpresaService } from '../../../services/empresa/empresa.service';

@Component({
    selector: "app-time-line-empresa",
    templateUrl: "./time-line-empresa.component.html"
})
export class TimeLineEmpresaComponent implements OnInit {

    public config: PerfectScrollbarConfigInterface = {};

    @Input() dados: Array<any>;
    titulo: string;
    inscricao: string;
    processos: Array<Processo>;
    observacoes: Array<Observacao>;
    situacaoAcompanhamento = Constantes.SITUACAO_DE_ACOMPANHAMENTO;

    @ViewChild(TimeLineHorizontalComponent)
    timeLineHorizontal: TimeLineHorizontalComponent;

    @ViewChild(TimeLineVerticalComponent)
    timeLineVertical: TimeLineVerticalComponent;

    constructor(
        private activatedRoute: ActivatedRoute,
        private empresaService: EmpresaService,
        private processoService: ProcessoService) {
        this.inscricao = this.activatedRoute.snapshot.params["inscricao"];
    }

    ngOnInit(): void {
        if (this.dados != null) {
            this.inscricao = this.dados[0];
        }

        this.executarBusca();
    }

    executarBusca() {
        // this.empresaService
        //     .cicloDeVida(this.inscricao)
        //     .pipe(take(1))
        //     .subscribe( retorno => {
        //         if (retorno[0] != null) {
        //             this.processos = retorno[0];
        //             this.observacoes = retorno[1];
        //             this.carregarHorizontal();
        //             this.carregarVertical();
        //         }
        //     })
    }

    carregarHorizontal() {
        this.timeLineHorizontal.porFase = false;
        this.timeLineHorizontal.porProcesso = true;
        this.timeLineHorizontal.numeroInscricao = this.inscricao;
        this.timeLineHorizontal.processos = this.processos;
    }

    carregarVertical() {
        this.timeLineVertical.numeroInscricao = this.inscricao;
        this.timeLineVertical.processos = this.processos;

        let listaDeProcessos: Set<string> = new Set();
        this.observacoes.forEach( observacao => {
            listaDeProcessos.add(observacao.tipo);
        });

        let observacoesPorProcessos = [];
        listaDeProcessos.forEach( processo => {
            let observacoesAuxiliar = new Array<Observacao>();
            this.observacoes.forEach( observacao => {
                if (observacao.tipo == processo) {
                    observacoesAuxiliar.push(observacao);
                }
            });
            observacoesPorProcessos.push(observacoesAuxiliar);
        });

        this.timeLineVertical.listaDeProcessos = listaDeProcessos;
        this.timeLineVertical.observacoesPorProcessos = observacoesPorProcessos;
    }
}