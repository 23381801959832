import { Endereco } from "./endereco";
import { Documento } from "./documento";

export class Cliente {
    codigo: string;
    nome: string;
    nomeAbreviado: string;
    nomeEmail: string;
    email: string;
    inicio: string;
    logo: string;
    cepUnico: string;
    diasValidadeAlvara: string;
    modelagem: string;
    inscricaoImobiliariaFormatada: string;
    site: string;
    avaliaFormalizacao: string;
    produto: string;
    geraInscricaoMobiliaria: string;
    enviaEmail: string;
    regraSocio: string;
    statusInterface: string;
    tipoEnvio: string;
    diasIndeferirViabilidade: string;
    integracaoEstadual: string;
    cadastroSimples: string;
    codigoIbge: string;
    endereco: Endereco;
    documento: Documento;

    constructor() {
        this.codigo = "";
        this.nome = "";
        this.nomeAbreviado = "";
        this.nomeEmail = "";
        this.email = "";
        this.inicio = "";
        this.logo = "";
        this.cepUnico = "";
        this.diasValidadeAlvara = "";
        this.modelagem = "";
        this.inscricaoImobiliariaFormatada = "";
        this.site = "";
        this.avaliaFormalizacao = "";
        this.produto = "";
        this.geraInscricaoMobiliaria = "";
        this.enviaEmail = "";
        this.regraSocio = "";
        this.statusInterface = "";
        this.tipoEnvio = "";
        this.diasIndeferirViabilidade = "";
        this.integracaoEstadual = "";
        this.cadastroSimples = "";
        this.codigoIbge = "";
        this.endereco = new Endereco();
        this.documento = new Documento();
    }
}