<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card">
        <div class="card-header card-header-principal" >
            <div class="row col-12 justify-content-center align-items-center" >
                <strong>COMUNICAÇÃO VIA E-MAIL PARA SUPORTE</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="row row-linha mt-2">
                <div class="col-2">
                    De: 
                </div>
                <div class="col-10">
                    {{ deEmail }}
                </div>
            </div>
            <div class="row row-linha mt-2">
                <div class="col-2">
                    Para: 
                </div>
                <div class="col-10">
                    {{ paraEmails }}
                </div>
            </div>
            <div class="row row-linha mt-2">
                <div class="col-2">
                    Assunto: 
                </div>
                <div class="col-10">
                    <select 
                        #assuntoSelect
                        class="form-control form-control-sm"
                        [class.is-invalid]="assunto.invalid && assunto.touched"
                        formControlName="assunto" 
                        name="assunto" 
                        id="assunto"                
                    >
                        <option value="">Selecione aqui o assunto</option>
                        <option *ngFor="let assunto of faqSuporte.emailAssuntos" value="{{ assunto }}">
                            {{ assunto }}
                        </option>
                    </select>
                    <div class="mb-1">
                        <span 
                            class="erro" 
                            *ngIf="assunto.invalid && assunto.touched && assunto.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
            </div>
            <div class="row row-linha mt-4">
                <div class="col-12">
                    <textarea #corpoEmailTextArea
                        class="form-control form-control-sm"
                        [class.is-invalid]="corpoEmail.invalid && corpoEmail.touched"
                        formControlName="corpoEmail"
                        id="corpoEmail"
                        name="corpoEmail"
                        maxlength="2000"
                        cols="30" 
                        rows="5"
                    ></textarea>
                    <div class="mb-1">
                        <span 
                            class="erro" 
                            *ngIf="corpoEmail.invalid && corpoEmail.touched && corpoEmail.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="corpoEmail.invalid && corpoEmail.touched && corpoEmail.errors.maxlength"
                        >A descrição deve conter até 2000 caracteres.</span>
                        <span class="quantidade-caracter">
                            Quantidade de Caracteres: {{ corpoEmail.value.length }} / 2000
                        </span>
                    </div>
                </div>
            </div>
            <div class="row row-linha mt-4">
                <div class="col-12 text-center">
                    <button 
                        (click)="acaoBotaoEnviarEmail()"
                        type="button" 
                        class="btn btn-success"
                        title="Enviar"
                    >
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>