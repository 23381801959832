import { Component, Input, OnInit } from '@angular/core';
import { Protocolo } from "src/app/model/protocolo";
import { Constantes } from "src/app/model/constantes";

@Component({
  selector: 'app-informacao-requerente',
  templateUrl: './informacao-requerente.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoRequerenteComponent implements OnInit {

  @Input() protocolo: Protocolo;
  @Input() tipoEmpresa: string = "";
  @Input() semCardHeader: boolean = false;
  
  tipoTelefone = Constantes.TIPO_DE_TELEFONE;
  tipoDocumento = Constantes.TIPO_DE_DOCUMENTO;
  tipoDocumentoDescricao = Constantes.TIPO_DE_DOCUMENTO_DESCRICAO;

  nomeUsuario: string;
  documentoUsuario: string;
  dataAtual: Date;
  emailUsuario: string;
  dadosAuditor : boolean;
  tipoDeEmpresa: string;

  constructor() {}

  ngOnInit(): void {
  }

}
