export class TaxaCreditoDebito {
    cliente: string;
    codigo: string;
    codigoPagamentoParcela: string;
    codigoBaixa: string;
    creditoDebito: string;
    valor: number;
    loginCadastro: string;
    adicionado: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.codigoPagamentoParcela = "";
        this.codigoBaixa = "";
        this.creditoDebito = "";
        this.valor = 0;
        this.loginCadastro = "";
        this.adicionado = "";
    }
}