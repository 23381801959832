<mat-form-field>
    <mat-label>Pesquisar</mat-label>
    <input matInput (keyup)="aplicarFiltro($event)" placeholder="Digite qualquer valor para filtrar por todas as opções" id="txtFiltro">
</mat-form-field>
  
<div class="mat-elevation-z9">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="protocoloInscricao">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-protocoloInscricao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Protocolo / Inscrição </th>
            <td style="text-align: center; padding-left: 5px;" mat-cell *matCellDef="let row"> {{ row.protocolo }} {{ row.inscricao }} </td>
        </ng-container>

        <ng-container matColumnDef="nomeEmpresaNomeRequerente">
            <th style="width: 25%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-nomeEmpresaNomeRequerente" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Requerente | Razão Social / Nome </th>
            <td style="text-align: left; padding-left: 5px;" mat-cell *matCellDef="let row"> {{ row.nomeEmpresa }} {{ row.nomeRequerente }}</td>
        </ng-container>

        <ng-container matColumnDef="vencimentoParcela">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-vencimentoParcela" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Vencimento </th>
            <td style="text-align: center; padding-left: 5px;" mat-cell *matCellDef="let row"> {{ row.vencimentoParcela }} </td>
        </ng-container>

        <ng-container matColumnDef="lancamentoTotal">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-lancamentoTotal" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Lançamento </th>
            <td style="text-align: center; padding-left: 10px;" mat-cell *matCellDef="let row"> {{ row.lancamentoTotal }} </td>
        </ng-container>

        <ng-container matColumnDef="numeroParcela">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-numeroParcela" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Parcela </th>
            <td style="text-align: center; padding-left: 5px;" mat-cell *matCellDef="let row"> {{ row.numeroParcela == 0 ? 'ÚNICA' : row.numeroParcela }} </td>
        </ng-container>

        <ng-container matColumnDef="situacaoParcela">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-situacaoParcela" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Situação </th>
            <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"
              [class.texto-danger]="row.situacaoParcela == parcelaSituacao.Vencida"
              [class.texto-success]="row.situacaoParcela == parcelaSituacao.Paga"> <b>{{ parcelaSituacaoDescricao[row.situacaoParcela] }}</b> </td>
        </ng-container>

        <ng-container matColumnDef="totalParcela">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-totalParcela" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Valor </th>
            <td style="text-align: right; padding-left: 10px;" mat-cell *matCellDef="let row"> {{ row.totalParcela }} </td>
        </ng-container>
    
        <ng-container matColumnDef="totalBaixa">
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-totalBaixa" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Total Baixa </th>
            <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row"> {{ row.totalBaixaParcela }} </td>
        </ng-container>
 
        <ng-container matColumnDef="menu">
            <th style="width: 1%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-menu" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"></th>
            <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row">
                <div class="row col-12 m-0 p-0">
                    <div class="row col-6 m-0 p-0 justify-content-center align-items-center" *ngIf="row.situacao != parcelaSituacao.Paga && row.situacao != parcelaSituacao.Finalizado">
                        <button
                            type="button" 
                            matTooltip="Boleto"
                            matTooltipPosition="above"
                            class="btn btn-sm m-0 p-0"
                            (click)="acaoImprimirBoleto(row)">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'file' }}"
                            ></use>
                        </svg>
                        </button>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="row" *ngIf="dataSource.filteredData.length == 0">
      <div class="form-row col-12 pt-3 justify-content-center align-items-center">
        <p class="card-text text-center">
          <br>Não existem parcela(s).<br>&nbsp;
        </p>
      </div>
    </div>
    <mat-paginator pageSize="25" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<div>&nbsp;</div>