import { Component, OnInit, ViewChild, Renderer2, Input } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, take } from 'rxjs/operators';
import { environment } from "../../../../environments/environment"
import { GoogleMap, MapGeocoder } from '@angular/google-maps';
import { Endereco } from '../../../model/endereco';
import { Atividade } from '../../../model/atividade';
import { formatToCapitalized } from 'brazilian-values';
import { ConsultaViabilidadeService } from '../../../services/consultaViabilidade/consultaViabilidade.service';
import { ConsultaViabilidade } from '../../../model/consultaViabilidade';
import { Constantes } from '../../../model/constantes';
import * as moment from 'moment';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import "bootstrap";
import { IcadModalComponent } from '../../../components/modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Uso } from '../../../model/uso';
import Swal from 'sweetalert2';
import { MascaraPipe } from '../../../pipes/mascara.pipe';
import { ConsultaViabilidadeEndereco } from '../../../model/consultaViabilidadeEndereco';
import { ConsultaViabilidadeAtividade } from '../../../model/consultaViabilidadeAtividade';
import { Orgao } from '../../../model/orgao';
import { constants } from 'buffer';
import { ActivatedRoute } from '@angular/router';
import { AtividadeService } from '../../../services/atividade/atividade.service';

@Component({
    selector: "app-analise-entorno",
    templateUrl: "./analise-entorno.component.html",
})
export class AnaliseEntornoComponent implements OnInit {
    @Input() tipoDeNegocio: string = "";
    @Input() endereco: Endereco;
    @Input() atividades: Array<Atividade>;

    consultaViabilidade: ConsultaViabilidade;
    consultaViabilidadeEndereco: ConsultaViabilidadeEndereco;
    enderecoLatLng: google.maps.LatLng;
    consultaViabilidadeAtividades: Array<ConsultaViabilidadeAtividade>;
    
    apiLoaded: Observable<boolean>;
    apiLoadedSubject: BehaviorSubject<boolean>;

    tiposDeUsos = Constantes.TIPO_DE_USO;
    tiposDeEmpresa = Constantes.TIPO_EMPRESA;

    situacaoEmpresa: string;

    pontosInteresseGoogle: boolean = false;

    markerLocalizacao: Array<google.maps.Marker>;
    markerConcorrentes: Array<google.maps.Marker>;
    markerClusterConcorrentes: MarkerClusterer;
    markerServicosPublicos: Array<google.maps.Marker>;
    markerClusterServicosPublicos: MarkerClusterer;

    circlePerimetro: google.maps.Circle;

    concorrentes: Array<ConsultaViabilidade>;
    servicosPublicos: Array<ConsultaViabilidade>;

    infoWindows: Array<google.maps.InfoWindow>;

    localizacaoSelecionada: ConsultaViabilidadeEndereco;
    atividadesSelecionadas: Array<ConsultaViabilidadeAtividade>;

    // Temporário
    userId: string; 

    options: google.maps.MapOptions = {
        center: {lat: -12.1346651, lng: -63.4053246},
        zoom: 4,
        streetViewControl: false,
        styles: [
            {
                featureType: "poi",
                stylers: [
                    {
                        visibility: this.pontosInteresseGoogle ? "on" : "off"
                    }
                ]
            }
        ]
    };

    @ViewChild(GoogleMap)
    mapa: GoogleMap;

    constructor(private render: Renderer2,
        private httpClient: HttpClient,
        private geocoder: MapGeocoder,
        private activatedRoute: ActivatedRoute,
        private atividadeService: AtividadeService,
        private consultaViabilidadeService: ConsultaViabilidadeService,
        public dialog: MatDialog,
        private mascara: MascaraPipe) {

        this.userId = localStorage.getItem("userId").replace(/\D/g,"")

        this.apiLoadedSubject = new BehaviorSubject<boolean>(null);
        this.apiLoadedSubject.asObservable();
        this.apiLoaded = this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${ environment.keyGoogleMaps }`, 'callback')
            .pipe(
            map(() => {
                this.apiLoadedSubject.next(true);
                return true;
            }),
                catchError(() => of(false)),
            );
            
        this.consultaViabilidade = new ConsultaViabilidade();
        this.markerLocalizacao = new Array<google.maps.Marker>();
        this.markerConcorrentes = new Array<google.maps.Marker>();
        this.markerServicosPublicos = new Array<google.maps.Marker>();

        this.atividadesSelecionadas = new Array<ConsultaViabilidadeAtividade>();
        
        if (this.activatedRoute.snapshot.queryParams["tipoDeNegocio"] != null) {
            this.tipoDeNegocio = this.activatedRoute.snapshot.queryParams["tipoDeNegocio"];
        }
        if (this.activatedRoute.snapshot.queryParams["endereco"] != null) {
            this.endereco = JSON.parse(this.activatedRoute.snapshot.queryParams["endereco"]);
        }
        if (this.activatedRoute.snapshot.queryParams["atividades"] != null) {
            this.atividades = new Array<Atividade>();
            this.buscarAtividades(this.activatedRoute.snapshot.queryParams["atividades"]);
        }
    }
        
    ngOnInit(): void { 
        this.apiLoadedSubject.subscribe( mapaCarregado => { 
            if (mapaCarregado) {
                setTimeout(() => {
                    this.inicializarMapa()
                }, 10);
            }
        })
    }

    buscarAtividades(listaDeAtividades) {
        if (this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo || 
            this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Baixa_Autonomo ||
            this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo ||
            this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo_Evento){
            this.atividadeService
                .buscarCbo(localStorage.getItem("pId"))
                .pipe(take(1))
                .subscribe( retorno => {
                    listaDeAtividades.split(",").forEach( codigoAtividade => {
                        var index = retorno.findIndex( atividade => { return atividade.codigo == codigoAtividade});
                        if (index != -1) {
                            var atividadeAuxiliar = new Atividade();
                            atividadeAuxiliar.codigo = retorno[index].codigo;
                            atividadeAuxiliar.codigoInterno = retorno[index].codigoInterno;
                            atividadeAuxiliar.nome = retorno[index].nome;
                            
                            this.atividades.push(atividadeAuxiliar);
                        }
                    });
                });
        }
        else{
            this.atividadeService
                .buscarCnae(localStorage.getItem("pId"))
                .pipe(take(1))
                .subscribe( retorno => {
                    listaDeAtividades.split(",").forEach( codigoAtividade => {
                        var index = retorno.findIndex( atividade => { return atividade.codigo == codigoAtividade});
                        if (index != -1) {
                            var atividadeAuxiliar = new Atividade();
                            atividadeAuxiliar.codigo = retorno[index].codigo;
                            atividadeAuxiliar.codigoInterno = retorno[index].codigoInterno;
                            atividadeAuxiliar.nome = retorno[index].nome;
                            
                            this.atividades.push(atividadeAuxiliar);
                        }
                    });
                });
        }
    }

    formatarNomeAtividade(nome) {
        return formatToCapitalized(nome);
    }

    inicializarMapa() {
        var cliente = JSON.parse(localStorage.getItem("cliente")).endereco; 

        this.geocoder.geocode({
            address: `${ cliente.numero } ${ cliente.tipoLogradouro } ${ cliente.logradouro }, ${ cliente.cidade }, ${ cliente.estado }`
        }).subscribe(({results}) => {
            const latLng = results[0].geometry.location;
            this.mapa.googleMap.panTo(latLng);
            this.mapa.googleMap.setZoom(12);
        });

        this.criarBotoesInteracao();
        
        this.consultaViabilidadeEndereco = new ConsultaViabilidadeEndereco();
        this.consultaViabilidadeAtividades = Array<ConsultaViabilidadeAtividade>();
        this.infoWindows = new Array<google.maps.InfoWindow>();

        setTimeout(() => {
            this.adicionarLocalizacaoNoMapa();
        }, 1000);
    }

    acaoExibirLocalizacao() {
        if (this.endereco != null) {
            this.markerLocalizacao.forEach( markerLocalizacao => {
                markerLocalizacao.setVisible( !markerLocalizacao.getVisible() );
                if (this.circlePerimetro != null) {
                    if(!markerLocalizacao.getVisible()) {
                        (<HTMLSelectElement>document.getElementById("selecaoPerimetro")).value = "0";
                    }
                    this.acaoExibirPerimetro();
                }
                this.render.setProperty(document.getElementById("selecaoPerimetro"), "disabled", !markerLocalizacao.getVisible());
            }) 
            return false;
        }
        
        this.adicionarLocalizacaoNoMapa();
    }

    adicionarLocalizacaoNoMapa() {
        if (this.endereco != null) {
            this.geocoder.geocode({
                address: `${ this.endereco.numero } ${ this.endereco.tipoLogradouro } ${ this.endereco.logradouro }, ${ this.endereco.cidade }, ${ this.endereco.estado }`
            }).subscribe(({results}) => {
                this.enderecoLatLng = results[0].geometry.location;
                const marker = new google.maps.Marker({
                    position: this.enderecoLatLng,
                    map: this.mapa.googleMap,
                });
                this.mapa.googleMap.panTo(this.enderecoLatLng);
                this.mapa.googleMap.setZoom(12);
                
                const infos = this.janelaDeInformacoesLocalizacao(this.endereco);

                this.infoWindows.push(infos);

                // Adicionando informações ao marcador
                marker.addListener("click", () => {
                    this.infoWindows.forEach( infoWindow => {
                        infoWindow.close();
                    })
                    infos.open( this.mapa.googleMap, marker );
                    this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                });
                
                this.markerLocalizacao.push(marker);
                this.render.setProperty(document.getElementById("selecaoPerimetro"), "disabled", false);

                // this.preencher();
            });
        }

    }

    acaoExibirPerimetro(){
    
        if (this.endereco != null || this.localizacaoSelecionada != null) {

            let valorKm = (<HTMLSelectElement>document.getElementById("selecaoPerimetro")).value;
            
            if (parseInt(valorKm) != 0 && this.circlePerimetro == null) {
                this.adicionarPerimetroNoMapa(parseInt(valorKm));
            }

            if (this.circlePerimetro != null) {
                if (parseInt(valorKm) == 0) {
                    this.circlePerimetro.setVisible(false);
                }
                else {
                    this.circlePerimetro.setVisible(true);
                    this.circlePerimetro.setRadius(1000 * parseInt(valorKm));
                }
            }

            return false;
        }

    }

    adicionarPerimetroNoMapa(valorKm){
        
        if (this.endereco != null || this.localizacaoSelecionada != null) {
            
            this.circlePerimetro = new google.maps.Circle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                map: this.mapa.googleMap,
                center: this.enderecoLatLng,
                radius: 1000 * parseFloat(valorKm),
            });

        }
    }

    acaoExibirConcorrentes() {
        if (this.concorrentes != null) {
            if (!this.markerConcorrentes.length) {
                Swal.fire("Concorrentes", "Não foram encontrados locais para essa consulta.", "info");
                return false;
            }
            this.markerConcorrentes.forEach( markerConcorrente => {
                markerConcorrente.setVisible( !markerConcorrente.getVisible() );
            }) 

            // if (this.markerClusterConcorrentes == null) {
            //     this.markerClusterConcorrentes = new MarkerClusterer(this.mapa.googleMap, this.markerConcorrentes, {
            //         imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            //         ignoreHidden: true,
            //     });
            // }

            // this.markerClusterConcorrentes.getMarkers().forEach( markerClusterConcorrente => {
            //     markerClusterConcorrente.setVisible( !markerClusterConcorrente.getVisible() )
            // });

            // this.markerClusterConcorrentes.repaint();

            return false;
        }
        
        this.adicionarConcorrentesNoMapa();

    }

    adicionarConcorrentesNoMapa() {
        let listaDeAtividades = "";

        this.atividades.forEach( item => {
            listaDeAtividades = listaDeAtividades.concat(item.codigo, ",");
        });

        this.consultaViabilidadeService
            .buscarConcorrentesPorAtividade(localStorage.getItem("pId"), listaDeAtividades, this.tipoDeNegocio)
            .pipe(take(1))
            .subscribe( (dados: Array<ConsultaViabilidade>) => {
                this.concorrentes = dados;
                this.concorrentes.forEach( (concorrente, indice) => {
                    if (concorrente.latitude != "" && concorrente.longitude != "") {
                        const latLng = new google.maps.LatLng(parseFloat(concorrente.latitude), parseFloat(concorrente.longitude));

                        const image = "assets/img/google-pin/azul.png";
                        
                        // Criando o marcador
                        const marker = new google.maps.Marker({
                            position: latLng,
                            map: this.mapa.googleMap,
                            icon: image,
                            visible: true,
                            zIndex: 1
                        });

                        // this.mapa.googleMap.panTo(latLng);
                        // this.mapa.googleMap.setZoom(12);

                        const infos = this.janelaDeInformacoesParaConcorrentes(concorrente);
                        
                        this.infoWindows.push(infos);

                        // Adicionando informações ao marcador
                        marker.addListener("click", () => {
                            this.infoWindows.forEach( infoWindow => {
                                infoWindow.close();
                            })
                            infos.open( this.mapa.googleMap, marker );
                            this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                        });
                        this.markerConcorrentes.push(marker);
                    }
                    else {
                        // setTimeout(() => {
                        //     this.geocoder.geocode({
                        //         address: `${ concorrente.enderecos[0].numero } ${ concorrente.enderecos[0].tipoLogradouro } ${ concorrente.enderecos[0].logradouro }, ${ concorrente.enderecos[0].cidade }, ${ concorrente.enderecos[0].estado }`
                        //     })
                        //     .pipe(take(1))
                        //     .subscribe(({results, status}) => {
                        //         if (status == google.maps.GeocoderStatus.OK) {
                        //             if (results != null) {
                        //                 const latLng = results[0].geometry.location;
                        //                 const image = "assets/img/google-pin/concorrentes.png";
                                        
                        //                 // Criando o marcador
                        //                 const marker = new google.maps.Marker({
                        //                     position: latLng,
                        //                     map: this.mapa.googleMap,
                        //                     icon: image,
                        //                 });
            
                        //                 this.mapa.googleMap.panTo(latLng);
                        //                 this.mapa.googleMap.setZoom(12);
            
                        //                 const infos = this.janelaDeInformacoesParaEmpresa(concorrente);

                        //                 this.infoWindows.push(infos);
            
                        //                 // Adicionando informações ao marcador
                        //                 marker.addListener("click", () => {
                                                // this.infoWindows.forEach( infoWindow => {
                                                //     infoWindow.close();
                                                // })
                                                // infos.open( this.mapa.googleMap, marker );
                                                // this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                        //                 });
                        //                 this.markerConcorrentes.push(marker);
                        //             }
        
                        //         }
                        //         //console.log(`${ concorrente.enderecos[0].numero } ${ concorrente.enderecos[0].tipoLogradouro } ${ concorrente.enderecos[0].logradouro }, ${ concorrente.enderecos[0].cidade }, ${ concorrente.enderecos[0].estado }`, results, status)
                                
                        //     });
                        // }, 600 * indice);
                    }
                    
                });

                if (!this.concorrentes.length) {
                    this.concorrentes = null
                }
                
                // this.acaoExibirConcorrentes();
            },
            (err) => {
                console.log('err', err);
            }
        );
    }
    
    acaoExibirServicosPublicos() {
        if (this.servicosPublicos != null) {
            if (!this.markerServicosPublicos.length) {
                Swal.fire("Serviços Públicos", "Não foram encontrados locais para essa consulta.", "info");
                return false;
            }
            this.markerServicosPublicos.forEach( markerServicoPublico => {
                markerServicoPublico.setVisible( !markerServicoPublico.getVisible() );
            }) 

            // if (this.markerClusterServicosPublicos == null) {
            //     this.markerClusterServicosPublicos = new MarkerClusterer(this.mapa.googleMap, this.markerServicosPublicos, {
            //         imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            //         ignoreHidden: true,
            //     });
            // }

            // this.markerClusterServicosPublicos.getMarkers().forEach( markerClusterServicoPublico => {
            //     markerClusterServicoPublico.setVisible( !markerClusterServicoPublico.getVisible() )
            // });

            // this.markerClusterServicosPublicos.repaint();

            return false;
        }

        this.adicionarServicosPublicosNoMapa();
    }

    adicionarServicosPublicosNoMapa() {    
        this.consultaViabilidadeService
            .buscarServicosPublicos(localStorage.getItem("pId"))
            .pipe(take(1))
            .subscribe( (dados: Array<ConsultaViabilidade>) => {
                this.servicosPublicos = dados;
                dados.forEach( (servicoPublico, indice) => {
                    if (servicoPublico.latitude != "" && servicoPublico.longitude != "") {
                        const latLng = new google.maps.LatLng(parseFloat(servicoPublico.latitude), parseFloat(servicoPublico.longitude));
                        var image = "";
                        if(servicoPublico.entidade == 'ESCOLAS PARTICULARES'){
                            image = "assets/img/google-pin/escola.png";
                        }
                        else if(servicoPublico.entidade == 'HOSPITAIS PARTICULARES'){
                            image = "assets/img/google-pin/hospital.png";
                        }
                        else if(servicoPublico.entidade == 'POSTOS DE SAÚDE'){
                            image = "assets/img/google-pin/posto-saude.png";
                        }
                        else {
                            image = "assets/img/google-pin/servico-publico.png";
                        }

                        const marker = new google.maps.Marker({
                            position: latLng,
                            icon: image,
                            map: this.mapa.googleMap,
                            title: servicoPublico.razaoSocial,
                            visible: true,
                            zIndex: 1
                        });

                        const infos = this.janelaDeInformacoesParaServicosPublicos(servicoPublico);

                        this.infoWindows.push(infos);

                        // Adicionando informações ao marcador
                        marker.addListener("click", () => {
                            this.infoWindows.forEach( infoWindow => {
                                infoWindow.close();
                            })
                            infos.open( this.mapa.googleMap, marker );
                            this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                        });

                        this.markerServicosPublicos.push(marker);
                    }
                    else {
                        setTimeout(() => {                        
                            this.geocoder.geocode({
                                address: `${ servicoPublico.enderecos[0].numero } ${ servicoPublico.enderecos[0].tipoLogradouro } ${ servicoPublico.enderecos[0].logradouro }, ${ servicoPublico.enderecos[0].cidade }, ${ servicoPublico.enderecos[0].estado }`
                            }).subscribe(({results}) => {
                                if (results != null) {
                                    const latLng = results[0].geometry.location;
                                    
                                    var image = "";
                                    if(servicoPublico.entidade == 'ESCOLAS PARTICULARES'){
                                        image = "assets/img/google-pin/escola.png";
                                    }
                                    else if(servicoPublico.entidade == 'HOSPITAIS PARTICULARES'){
                                        image = "assets/img/google-pin/hospital.png";
                                    }
                                    else if(servicoPublico.entidade == 'POSTOS DE SAÚDE'){
                                        image = "assets/img/google-pin/posto-saude.png";
                                    }
                                    else {
                                        image = "assets/img/google-pin/servico-publico.png";
                                    }
        
                                    const marker = new google.maps.Marker({
                                        position: latLng,
                                        icon: image,
                                        title: servicoPublico.razaoSocial,
                                        visible: false,
                                    });
                                    // this.mapa.googleMap.panTo(latLng);
                                    // this.mapa.googleMap.setZoom(12);
        
                                    const infos = this.janelaDeInformacoesParaServicosPublicos(servicoPublico);
                                    
                                    this.infoWindows.push(infos);
                                    
                                    // Adicionando informações ao marcador
                                    marker.addListener("click", () => {
                                        this.infoWindows.forEach( infoWindow => {
                                            infoWindow.close();
                                        })
                                        infos.open( this.mapa.googleMap, marker );
                                        this.mapa.googleMap.panTo(marker.getPosition() as google.maps.LatLng);
                                    });
        
                                    this.markerServicosPublicos.push(marker);
        
                                    if ((this.servicosPublicos.length - 1) == indice) {
                                        new MarkerClusterer(this.mapa.googleMap, this.markerServicosPublicos, {
                                            imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                                        });
                                    }
                                }
                            });
                        }, 600 * indice);
                    }
                    
                });

                if (!this.servicosPublicos.length) {
                    this.servicosPublicos = null
                }
            },
            (err) => {
                console.log('err', err);
            }
        );
    }

    acaoExibirPontosInteresseGoogle() {

        this.pontosInteresseGoogle = !this.pontosInteresseGoogle

        this.mapa.googleMap.setOptions({
            styles: [
                {
                    featureType: "poi",
                    stylers: [
                        {
                            visibility: this.pontosInteresseGoogle ? "on" : "off"
                        }
                    ]
                }
            ]
        });
    }

    janelaDeInformacoesLocalizacao(endereco){
        // Criando as informações para exibir no marcador
        return new google.maps.InfoWindow({
            content: `
            <html>
                <head></head>
                <body>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <strong>Endereço:&nbsp;</strong> ${ endereco.tipoLogradouro } ${ endereco.logradouro } ${ endereco.numero }, ${ endereco.cidade } - ${ endereco.estado }
                    </div>
                </body>
            </html>`
        });
    }
    
    janelaDeInformacoesParaConcorrentes(consultaViabilidade: ConsultaViabilidade) {
        var listaDeAtividades = '';

        if (this.atividades != null) {
            this.atividades.forEach( atividade => {
                if (consultaViabilidade.atividades.search(atividade.codigo) != -1) {
                    listaDeAtividades = listaDeAtividades + "<li>" + 
                        (this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo || this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo_Evento ? this.mascara.transform(atividade.codigo.toString(), 12) : this.mascara.transform(atividade.codigo.toString(), 10)) + 
                        " - " + (atividade.nome.length > 60 ? this.formatarNomeAtividade(atividade.nome.substring(0, 60) + "...") : this.formatarNomeAtividade(atividade.nome)) + "</li>"
                }
            });
        }

        if (this.atividadesSelecionadas != null) {
            this.atividadesSelecionadas.forEach( atividade => {
                if (consultaViabilidade.atividades.search(atividade.codigo) != -1) {
                    listaDeAtividades = listaDeAtividades + "<li>" + 
                        (this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo || this.tipoDeNegocio == Constantes.TIPO_EMPRESA.Autonomo_Evento ? this.mascara.transform(atividade.codigo.toString(), 12) : this.mascara.transform(atividade.codigo.toString(), 10)) + 
                        " - " + (atividade.nome.length > 60 ? this.formatarNomeAtividade(atividade.nome.substring(0, 60) + "...") : this.formatarNomeAtividade(atividade.nome)) + "</li>"
                }
            });
        }

        var inicio = moment(new Date(consultaViabilidade.ldts));
        var agora = moment(new Date());
        var diferenca = moment.duration({
            years: agora.year() - inicio.year(),
            months: agora.month() - inicio.month(),
            days: agora.date() - inicio.date()
        });

        // Criando as informações para exibir no marcador
        return new google.maps.InfoWindow({
            content: `
            <html>
                <head></head>
                <body>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Razão Social: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.razaoSocial }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Endereço: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.enderecos[0].tipoLogradouro } ${ consultaViabilidade.enderecos[0].logradouro } , ${ consultaViabilidade.enderecos[0].numero } -  ${ consultaViabilidade.enderecos[0].bairro } - ${ consultaViabilidade.enderecos[0].cidade } - ${ consultaViabilidade.enderecos[0].estado } - CEP ${ consultaViabilidade.enderecos[0].cep }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">&nbsp;</div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-12">
                            <strong>Tempo de Vida do Negócio no Local:&nbsp;</strong> ${ diferenca.years() } anos, ${ diferenca.months() } meses e ${ diferenca.days() } dias
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-12">
                            <strong>Atividades: </strong>
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-12">
                            <ul>
                                ${ listaDeAtividades }
                            </ul>
                        </div>
                    </div>
                </body>
            </html>`
        });
    }

    janelaDeInformacoesParaServicosPublicos(consultaViabilidade: ConsultaViabilidade) {
        // Criando as informações para exibir no marcador
        return new google.maps.InfoWindow({
            content: `
            <html>
                <head></head>
                <body>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Grupo: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.entidade }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Tipo: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.tipoServico }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Razão Social: </strong>
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.razaoSocial }
                        </div>
                    </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Endereço: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.enderecos[0].tipoLogradouro } ${ consultaViabilidade.enderecos[0].logradouro } ${ consultaViabilidade.enderecos[0].numero } , ${ consultaViabilidade.enderecos[0].bairro } - ${ consultaViabilidade.enderecos[0].cidade } - ${ consultaViabilidade.enderecos[0].estado } - CEP ${ consultaViabilidade.enderecos[0].cep }
                        </div>
                    </div>
                    <div class="row col-12 row-linha-janela-titulo-maps">
                        <div class="col-3">
                            <strong>Telefone: </strong> 
                        </div>
                        <div class="col-9">
                            ${ consultaViabilidade.telefones[0].numero }
                        </div>
                    </div>
                </body>
            </html>`
        });
    }

    limpar() {
        this.consultaViabilidade = new ConsultaViabilidade();

        this.limparLocalizacao();
        this.limparConcorrentes();
        this.limparServicosPublicos();
        this.limparPerimetro();
        this.inicializarMapa();
    }

    limparLocalizacao() {
        if (this.markerLocalizacao != null) {
            this.markerLocalizacao.forEach( markerConcorrente => {
                markerConcorrente.setMap(null);
            });
        }
        
        this.endereco = null;
        this.consultaViabilidadeEndereco = new ConsultaViabilidadeEndereco();;
        this.localizacaoSelecionada = null;
        this.markerLocalizacao = new Array<google.maps.Marker>();
    }

    limparConcorrentes() {
        if (this.markerClusterConcorrentes != null) {
            this.markerClusterConcorrentes.setMap(null);
            this.markerClusterConcorrentes.repaint();
        }

        if (this.markerConcorrentes != null) {
            this.markerConcorrentes.forEach( markerConcorrente => {
                markerConcorrente.setMap(null);
            });
        }
        this.markerClusterConcorrentes = null;
        this.markerConcorrentes = new Array<google.maps.Marker>();

        this.concorrentes = null;
    }

    limparServicosPublicos() {
        if (this.markerClusterServicosPublicos != null) {
            this.markerClusterServicosPublicos.setMap(null);
            this.markerClusterServicosPublicos.repaint();
        }

        if (this.markerServicosPublicos != null) {
            this.markerServicosPublicos.forEach( markerServicoPublico => {
                markerServicoPublico.setMap(null);
            });
        }
        this.markerClusterServicosPublicos = null;
        this.markerServicosPublicos = new Array<google.maps.Marker>();

        this.servicosPublicos = null;
    }

    limparPerimetro() {
        this.enderecoLatLng = null;
        if (this.circlePerimetro != null) {
            this.circlePerimetro.setMap(null);
        }
        this.circlePerimetro = null;
        (<HTMLSelectElement>document.getElementById("selecaoPerimetro")).value = "0";
        this.render.setProperty(document.getElementById("selecaoPerimetro"), "disabled", true);
    }

    criarBotoesInteracao() {
        const divPrincipal = document.createElement("div");
        divPrincipal.setAttribute("class", "acoes-mapa-principal");
        divPrincipal.style.backgroundColor = "#fff";
        divPrincipal.style.border = "2px solid #fff";
        divPrincipal.style.borderRadius = "3px";
        divPrincipal.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        divPrincipal.style.cursor = "pointer";
        divPrincipal.style.marginTop = "8px";
        divPrincipal.style.marginLeft = "10px";
        divPrincipal.style.marginBottom = "22px";
        divPrincipal.style.textAlign = "center";

        this.criarBotaoLocalizacao(divPrincipal);
        this.criarBotaoConcorrentes(divPrincipal);
        this.criarBotaoServicosPublicos(divPrincipal);
        this.criarBotaoPontosInteresseGoogle(divPrincipal);

        const divPerimetro = document.createElement("div");
        divPerimetro.setAttribute("class", "acoes-mapa-perimetro");
        divPerimetro.style.backgroundColor = "#fff";
        divPerimetro.style.border = "2px solid #fff";
        divPerimetro.style.borderRadius = "3px";
        divPerimetro.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        divPerimetro.style.cursor = "pointer";
        divPerimetro.style.marginBottom = "8px";
        divPerimetro.style.width = "500px";
        this.criarBotaoPerimetro(divPerimetro);
        
        this.mapa.controls[google.maps.ControlPosition.LEFT_TOP].push(divPrincipal);
        this.mapa.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(divPerimetro);

        // this.render.setProperty(document.getElementById("selecaoPerimetro"), "disabled", true);

    }

    criarBotaoLocalizacao(divPrincipal) {
        // Localização.
        const divBotao = this.criarDivBotao("map-pin", "Localização", "localizacao", "");
        divBotao.addEventListener("click", () => {
            this.acaoExibirLocalizacao();
        });

        divPrincipal.appendChild(divBotao);
    }

    criarBotaoConcorrentes(divPrincipal) {
        // Concorrentes.
        const divBotao = this.criarDivBotao("eye", "Concorrentes", "concorrentes", "");
        divBotao.style.borderTop = "1px solid #eeeeee";

        divBotao.addEventListener("click", () => {
            this.acaoExibirConcorrentes();
        });

        divPrincipal.appendChild(divBotao);
    }

    criarBotaoServicosPublicos(divPrincipal) {
        // Serviços Públicos.
        const divBotao = this.criarDivBotao("globe", "Serviços Públicos", "servicos-publicos", "");
        divBotao.style.borderTop = "1px solid #eeeeee";

        divBotao.addEventListener("click", () => {
            this.acaoExibirServicosPublicos();
        });
        divPrincipal.appendChild(divBotao);
    }

    criarBotaoPontosInteresseGoogle(divPrincipal) {
        // Pontos de Interesse do Google.
        const divBotao = this.criarDivBotao("map", "Pontos de Interesse do Google", "pontos-interesse-google", "");
        divBotao.style.borderTop = "1px solid #eeeeee";

        divBotao.addEventListener("click", () => {
            this.acaoExibirPontosInteresseGoogle();
        });
        divPrincipal.appendChild(divBotao);
    }

    criarBotaoPerimetro(divPerimetro) {
        // Perímetro.
        const selectPerimetro = document.createElement("select");
        selectPerimetro.id = "selecaoPerimetro";
        selectPerimetro.setAttribute("class", "form-control form-control-sm");
        selectPerimetro.style.color = "rgb(25,25,25)";
        selectPerimetro.style.fontFamily = "Roboto,Arial,sans-serif";
        selectPerimetro.style.fontSize = "14px";
        selectPerimetro.style.lineHeight = "38px";
        selectPerimetro.style.padding = "8px 15px";

        selectPerimetro.addEventListener("change", () => {
            this.acaoExibirPerimetro();
        });

        const optionPerimetro0 = document.createElement("option");
        optionPerimetro0.value = "0";
        optionPerimetro0.text = "Nenhum";
        selectPerimetro.appendChild(optionPerimetro0);
        
        const optionPerimetro1 = document.createElement("option");
        optionPerimetro1.value = "1";
        optionPerimetro1.text = "1 KM";
        selectPerimetro.appendChild(optionPerimetro1);

        const optionPerimetro2 = document.createElement("option");
        optionPerimetro2.value = "2";
        optionPerimetro2.text = "2 KM";
        selectPerimetro.appendChild(optionPerimetro2);

        const optionPerimetro3 = document.createElement("option");
        optionPerimetro3.value = "3";
        optionPerimetro3.text = "3 KM";
        selectPerimetro.appendChild(optionPerimetro3);

        const optionPerimetro4 = document.createElement("option");
        optionPerimetro4.value = "4";
        optionPerimetro4.text = "4 KM";
        selectPerimetro.appendChild(optionPerimetro4);

        const optionPerimetro5 = document.createElement("option");
        optionPerimetro5.value = "5";
        optionPerimetro5.text = "5 KM";
        selectPerimetro.appendChild(optionPerimetro5);

        const optionPerimetro6 = document.createElement("option");
        optionPerimetro6.value = "10";
        optionPerimetro6.text = "10 KM";
        selectPerimetro.appendChild(optionPerimetro6);

        const optionPerimetro7 = document.createElement("option");
        optionPerimetro7.value = "15";
        optionPerimetro7.text = "15 KM";
        selectPerimetro.appendChild(optionPerimetro7);

        const optionPerimetro8 = document.createElement("option");
        optionPerimetro8.value = "25";
        optionPerimetro8.text = "25 KM";
        selectPerimetro.appendChild(optionPerimetro8);
        
        const divPrincipal = document.createElement("div");
        divPrincipal.setAttribute("class", "row col-12 justify-content-start align-items-center");
        divPrincipal.style.paddingRight = "0px"

        const divTitulo = document.createElement("div");
        divTitulo.setAttribute("class", "row col-8");
        divTitulo.style.paddingRight = "0px"
        divTitulo.innerHTML = `Definir um raio a partir da localização `;
        
        const divSelect = document.createElement("div");
        divSelect.setAttribute("class", "row col-4");
        divSelect.style.paddingRight = "0px"

        divSelect.appendChild(selectPerimetro);

        divPrincipal.appendChild(divTitulo);
        divPrincipal.appendChild(divSelect);

        divPerimetro.appendChild(divPrincipal);
    }

    criarDivBotao(nomeIcone, textoToolTip, id, classe) {
        const divBotao = document.createElement("div");
        divBotao.style.color = "rgb(25,25,25)";
        divBotao.style.fontFamily = "Roboto,Arial,sans-serif";
        divBotao.style.fontSize = "14px";
        divBotao.style.lineHeight = "38px";
        divBotao.style.padding = "5px 10px";
        divBotao.style.minHeight = "55px";
        divBotao.title = textoToolTip;
        
        divBotao.innerHTML = `
            <svg class="icone-pesquisa-viabilidade ${ classe }" id="${ id }" style="float: left; position: relative; margin: 10px;">
                <use
                    xlink:href="#${ nomeIcone }"
                ></use>
            </svg>
        `;
        
        const popper = document.createElement("div");
        popper.id = "tooltip";
        popper.setAttribute("class", "tooltip-google-maps");
        popper.setAttribute("data-popper-placement-google-maps", "right");
        popper.innerHTML = textoToolTip;
        
        divBotao.addEventListener("mouseenter", () => {
            //this.exibirToolTip(popper);
        });
        
        divBotao.addEventListener("mouseleave", () => {
            //this.exibirToolTip(popper);
        });
        
        const arrow = document.createElement("div");
        arrow.id = "arrow";
        arrow.setAttribute("data-popper-arrow", "");
        popper.appendChild(arrow);
        
        divBotao.appendChild(popper);

        return divBotao;
    }

    exibirToolTip(objeto: HTMLDivElement) {
        if (objeto.getAttribute("data-show") == null) {
            objeto.setAttribute("data-show", "");
        }
        else {
            objeto.removeAttribute("data-show");
        }
    }

    htmlParaImagem() {
        let marcarLoalizacao = "";
        
        this.markerLocalizacao.forEach( localizacao => {
            marcarLoalizacao += `&markers=anchor:${ localizacao.getPosition().toUrlValue() }`;
        });

        let marcarConcorrentes = "";
        
        this.markerConcorrentes.forEach( concorrente => {
            marcarConcorrentes += `&markers=icon:https://portal.icadonline.com.br/biblioteca/imagem/icones/azul.png|${ concorrente.getPosition().toUrlValue() }`;
        });
        
        this.httpClient.get<any>(
            `http://maps.googleapis.com/maps/api/staticmap?size=800x1200&center=${ this.mapa.getCenter().toUrlValue() }&zoom=${ this.mapa.getZoom() }${ marcarLoalizacao }${ marcarConcorrentes }&style=feature:poi|visibility:${ this.pontosInteresseGoogle ? "on" : "off" }&key=${ environment.keyGoogleMaps }`,
            {
                headers: new HttpHeaders({
                    // app_id: environment.appId,
                    // param_user: 'ORG',
                    // 'Content-Type': 'application/json',
                }),
            }
        )
        .subscribe( retorno => {
            // console.log(retorno.toDataURL("image/png"));
        });
        // this.httpClient.jsonp(`http://maps.googleapis.com/maps/api/staticmap?&size=600x400&key=${ environment.keyGoogleMaps }`, 'callback')
        //     .pipe(
        //     map(() => true),
        //         catchError(() => of(false)),
        //     )
        //     .subscribe( retorno => {
        //         console.log(retorno);
        //     });
        
        // var Pagelink = "about:blank";
        // var pwa = window.open(Pagelink, "_new");
        // pwa.document.open();
        // pwa.document.write(this.mapa.googleMap.getDiv().innerHTML);
        // pwa.document.close();
        
        // html2canvas(document.querySelector(".div-google-maps")).then(canvas => {
        //     document.body.appendChild(canvas)
            // console.log(canvas);
            // var Pagelink = "about:blank";
            // var pwa = window.open(Pagelink, "_new");
            // pwa.document.open();
            // pwa.document.write(canvas.innerHTML);
            // pwa.document.close();
            // var imgData = canvas.toDataURL("image/png");
            // self.AddImagesResource(imgData);
            // console.log(imgData);
            // document.body.appendChild(canvas);
        // });

        

    }

}