import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { TaxaService } from '../../../../services/taxa/taxa.service';
import { TaxaLancamento } from '../../../../model/taxaLancamento';
import { TaxaLancamentoParcela } from '../../../../model/taxaLancamentoParcela';
import { Constantes } from '../../../../model/constantes';
import { MascaraPipe } from '../../../../pipes/mascara.pipe';
import { GridParcelasComponent } from '../grid-parcelas/grid-parcelas.component';
import Swal from 'sweetalert2';
import { TaxaPagamento } from '../../../../model/taxaPagamento';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GridMemoriaDeCalculoComponent } from '../grid-memoria-de-calculo/grid-memoria-de-calculo.component';
import { MemoriaDeCalculoComponent } from '../memoria-de-calculo/memoria-de-calculo.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';


@Component({
    selector: "app-conta-corrente",
    templateUrl: "./conta-corrente.component.html",
    styleUrls: ["./conta-corrente.component.scss"]
})
export class ContaCorrenteComponent implements OnInit {

    @Output() acaoDeAtendimento = new EventEmitter();
    @Input() semCardHeader: boolean = false;
    @Input() numeroProtocolo: string = "";
    @Input() numeroInscricao: string = "";
    @Input() exercicio: string = new Date().getFullYear().toString();

    @ViewChildren(GridParcelasComponent)
    gridsParcelas: QueryList<GridParcelasComponent>;

    @ViewChildren(MemoriaDeCalculoComponent)
    memoriaDeCalculo: MemoriaDeCalculoComponent;

    taxaLancamentos: Array<TaxaLancamento>;
    taxaLancamentoParcelas: Array<TaxaLancamentoParcela>;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

    acaoAtendimentoDescricao = Constantes.ACAO_ATENDIMENTO_DESCRICAO;
    acaoAtendimentoClass = Constantes.ACAO_ATENDIMENTO_CLASS;

    lancamentoDetalhado = "";

    currentUser = "";

    constructor(
        private activatedRoute: ActivatedRoute,
        private mascara: MascaraPipe,
        private arquivoService: ArquivoService,
        private taxaService: TaxaService,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService) {
            
        this.numeroProtocolo = this.numeroProtocolo == "" ? this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : this.numeroProtocolo : this.numeroProtocolo;
        this.numeroInscricao = this.numeroInscricao == "" ? this.activatedRoute.snapshot.params["inscricao"] != null ? this.activatedRoute.snapshot.params["inscricao"] : this.numeroInscricao : this.numeroInscricao;

        this.currentUser = this.authenticationService.currentUserValue.perfil;

    }

    ngOnInit(): void {
        this.buscarTaxaLancamentoParcelasPorExercicio(this.exercicio);
    }

    acaoAtendimento(event) {
        if (event.boletoUnico != null) {
            this.validarPagamento(event);
        }
        else if (event.informacoesBaixa != null) {
            this.informacaoBaixa(event);
        }
        else if (event.comprovante != null) {
            this.imprimirComprovante(event);
        }
        else if (event.memoriaDeCalculoParcela != null) {
            this.buscarMemoriaDeCalculoParcela(event);
        }

    }
    
    validarPagamento(event) {
        if (event.boletoUnico) {
            var html = `
                <div class="row col-12 justify-content-center align-items-center">
                    <svg class="icone-criticar-alert">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="assets/sprite.svg#alert-octagon"
                        ></use>
                    </svg>
                </div>
                <div class="row col-12 justify-content-center align-items-center mt-5">
                    <h4>
                        <b>ATENÇÃO</b>
                    </h4>
                </div>
                <br>
                <div class="row col-12 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-left">
                        Ao gerar um boleto único com uma ou mais parcelas selecionadas será aplicado a data atual como limite para pagamento, ou seja, o boleto vai agrupar todas as parcelas com o vencimento para o dia de hoje.<br>
                        Caso deseje imprimir um boleto que não esteja vencido com a data de vencimento do mesmo, deverá selecionar a opção de <b>BOLETO INDIVIDUAL</b>.
                    </p>
                    <p class="w-100 text-left">
                        <b>*Para pagamento de PARCELA ÚNICA, selecionar a mesma quando existir e utilizar o BOLETO INDIVIDUAL.</b>
                    </p>
                </div>
                `;
    
            Swal.fire({
                html: html,
                inputAttributes: {
                    autocapitalize: 'off'
                },
                width: 700,
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading(),
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.gerarPagamento(event);
                }
            })
        }
        else {
            this.gerarPagamento(event);
        }
    }

    gerarPagamento(event) {
        var taxaPagamento = new TaxaPagamento();
        taxaPagamento.protocolo = this.numeroProtocolo;
        taxaPagamento.inscricao = this.numeroInscricao;
        taxaPagamento.pagamentoUnico = event.boletoUnico;
        taxaPagamento.meioPagamento.tipo = Constantes.MEIO_PAGAMENTO_TIPO.Boleto_Registrado
        taxaPagamento.taxaLancamentoParcelas = event.parcelas;
 
        this.taxaService
            .gerarPagamento(taxaPagamento)
            .pipe(take(1))
            .subscribe( retornoAtendimento => {
                Swal.fire({
                    title: retornoAtendimento.titulo,
                    html: retornoAtendimento.mensagem,
                    icon: retornoAtendimento.status,
                    customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: true,
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    allowOutsideClick: () => !Swal.isLoading(),
                    willClose: () => {
                    }
                })
                .then((result) => {
                    if (!result.dismiss) {
                        if (retornoAtendimento.arquivos.length) {
                            retornoAtendimento.arquivos.forEach( nome => {
                                this.arquivoService.downloadArquivoAtendimento(nome)
                                    .subscribe((res: any) => {
                                        this.arquivoService.handleFile(res, nome + '.pdf');
                                    });
                                })
                        }
                        else {
                            Swal.fire("", "Não foi possível localizar o arquivo.", "error");
                        }
                    }
                });
            });
    }

    imprimirComprovante(event) {
        var nome = "Comprovante_" + event.comprovante.codigo.toString() + event.comprovante.codigoLancamento.toString();

        if (event.comprovante.taxaBaixa.documento.codigo != null && event.comprovante.taxaBaixa.documento.codigo != "") {
            this.arquivoService
                .downloadArquivoPorCodigo(event.comprovante.taxaBaixa.documento.codigo)
                .subscribe((res: any) => {
                    this.arquivoService.handleFile(res, nome + '.pdf');
                });
        }
        else {
            Swal.fire("", "Não foi possível localizar o comprovante.", "error");
        }

    }

    informacaoBaixa(event) {
        let html = "";

        html += `
            <div class="row col-12 justify-content-center align-items-center">
                <h4>
                    <b>Informações da Baixa</b>
                </h4>
            </div>
            <br>
            <div class="row col-12">
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-left">
                        <b>Subtotal</b>:
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.subTotal }
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.taxaBaixa.subTotal }
                    </p>
                </div>
            </div>
            <div class="row col-12">
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-left">
                        <b>Desconto</b>:
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.subTotalDesconto }
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.taxaBaixa.subTotalDesconto }
                    </p>
                </div>
            </div>
            <div class="row col-12">
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-left">
                        <b>Mora</b>:
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.subTotalMora }
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.taxaBaixa.subTotalMora }
                    </p>
                </div>
            </div>
            <div class="row col-12">
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-left">
                        <b>Juros</b>:
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.subTotalJuros }
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.taxaBaixa.subTotalJuros }
                    </p>
                </div>
            </div>
            <div class="row col-12">
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-left">
                        <b>Correção Monetária</b>:
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.subTotalCorrecaoMonetaria }
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.taxaBaixa.subTotalCorrecaoMonetaria }
                    </p>
                </div>
            </div>
            <div class="row col-12">
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-left">
                        <b>Total</b>:
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.total }
                    </p>
                </div>
                <div class="row col-4 row-linha justify-content-center align-items-center">
                    <p class="w-100 text-right">
                        R$ ${ event.informacoesBaixa.taxaBaixa.total }
                    </p>
                </div>
            </div>
        `;

        if (parseFloat(event.informacoesBaixa.taxaBaixa.taxaCreditoDebito.valor) != 0) {
            html += `
            <div class="row col-12 row-linha justify-content-center align-items-center">
                <p class="w-100 text-left">
                    <b>* Baixa realizada com ${ Constantes.CREDITO_DEBITO_DESCRICAO[event.informacoesBaixa.taxaBaixa.taxaCreditoDebito.creditoDebito].toUpperCase() } de R$ ${ event.informacoesBaixa.taxaBaixa.taxaCreditoDebito.valor }.</b>
                </p>
            </div>
            `
        }

        Swal.fire({
            html: html,
            icon: 'info',
            width: "50%",
            confirmButtonText: "Comprovante",
            showConfirmButton: true,
            showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.imprimirComprovante(event);
          }
        });
    }

    openDialog(componente, dados) {

        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                componente: componente,
                dados: dados
            }
        });
    
        // dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
    }

    buscarMemoriaDeCalculo(codigo){
        let taxaLancamentosAuxiliar = this.taxaLancamentos.filter( taxaLancamento => { return taxaLancamento.codigo == codigo });
        if (taxaLancamentosAuxiliar != null) {
            this.openDialog("memoria-de-calculo", [taxaLancamentosAuxiliar[0], null, "Geral"]);
        }
    }

    buscarMemoriaDeCalculoParcela(event){
        this.openDialog("memoria-de-calculo", [null, event.memoriaDeCalculoParcela, "Parcela"]);
    }

    buscarTaxaLancamento(exercicio) {
        exercicio = exercicio.replace(/\D/g, "");

        if (exercicio.length != 4 || exercicio == "") {
            Swal.fire("", "Favor informar o exercício com 4 dígitos.", "error");
            return false;
        }

        if (exercicio == "") {
            this.buscarTaxaLancamentoParcelas();
        }
        else {
            this.buscarTaxaLancamentoParcelasPorExercicio(exercicio);
        }
    }

    buscarTaxaLancamentoParcelasPorExercicio(exercicio) {
        this.exercicio = exercicio;

        if (this.numeroInscricao != null && this.numeroInscricao != "") {
            this.taxaService
                .buscarTaxaLancamentoParcelasPorInscricaoEAnoVencimento(this.numeroInscricao, this.exercicio)
                .pipe(take(1))
                .subscribe( retorno => {
                    this.taxaLancamentos = retorno[0];
                    this.taxaLancamentoParcelas = retorno[1];
                    this.carregarParcelas();
                })
        }
        else if (this.numeroProtocolo != null && this.numeroProtocolo != "") {
            this.taxaService
                .buscarTaxaLancamentoParcelasPorProtocoloEAnoVencimento(this.numeroProtocolo, this.exercicio)
                .pipe(take(1))
                .subscribe( retorno => {
                    this.taxaLancamentos = retorno[0];
                    this.taxaLancamentoParcelas = retorno[1];
                    this.carregarParcelas();
                })
        }

    }
    
    buscarTaxaLancamentoParcelas() {
        this.exercicio = "";
        
        if (this.numeroInscricao != null && this.numeroInscricao != "") {
            this.taxaService
                .buscarTaxaLancamentoPorInscricao(this.numeroInscricao)
                .pipe(take(1))
                .subscribe( retorno => {
                    this.taxaLancamentos = retorno;
                    this.carregarParcelas();
                })
        }
        else if (this.numeroProtocolo != null && this.numeroProtocolo != "") {
            this.taxaService
                .buscarTaxaLancamentoPorProtocolo(this.numeroProtocolo)
                .pipe(take(1))
                .subscribe( retorno => {
                    this.taxaLancamentos = retorno;
                    this.carregarParcelas();
                })
        }

    }

    async buscarTaxaLancamentoParcelasPorLancamento(codigo) {
        await this.taxaService
            .buscarTaxaLancamentoParcelasPorLancamento(codigo)
            .pipe(take(1))
            .subscribe( retorno => {
                this.taxaLancamentos.filter( taxaLancamento => { return taxaLancamento.codigo == codigo})[0].taxaLancamentoParcelaUnica = retorno[0];
                this.taxaLancamentos.filter( taxaLancamento => { return taxaLancamento.codigo == codigo})[0].taxaLancamentoParcelas = retorno[1];
                this.preencherGridParcelas();
            })
    }

    preencherGridParcelas() {
        setTimeout(() => {
          this.gridsParcelas.forEach(gridParcelas => {
            gridParcelas.grid.dados = this.taxaLancamentos.filter( taxaLancamento => { return taxaLancamento.codigo == gridParcelas.codigoLancamento})[0].taxaLancamentoParcelas;
            var parcelaUnicaEncontrada = this.taxaLancamentos.filter( taxaLancamento => { return taxaLancamento.codigo == gridParcelas.codigoLancamento})[0].taxaLancamentoParcelaUnica;
            if (parcelaUnicaEncontrada.codigo != null && parcelaUnicaEncontrada.codigo != "") {
                gridParcelas.gridParcelaUnica.dados = [parcelaUnicaEncontrada];
            }
            gridParcelas.atualizarTabela();
          });
        }, 10);
    }

    formatToBRL(valor) {
        return this.mascara.transform(valor, 11);
    }
    
    carregarParcelas() {
        this.taxaLancamentos.forEach( taxaLancamento => {
            this.buscarTaxaLancamentoParcelasPorLancamento(taxaLancamento.codigo);
        });
    }
    
    acaoExibirParcelas(codigo) {
        this.lancamentoDetalhado = codigo;                
    }

    acaoOcultarParcelas() {
        this.lancamentoDetalhado = "";
    }

    limpar() {
      this.taxaLancamentos = new Array<TaxaLancamento>();
      this.taxaLancamentoParcelas = new Array<TaxaLancamentoParcela>();
      this.preencherGridParcelas();
    }

}
