import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
import { ProcessamentoLoteTaxaComponent } from '../processamento-lote/processamento-lote-taxa.component';
import { CalendarioFiscalLoteComponent } from '../calendario-fiscal-lote/calendario-fiscal-lote.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import Swal from 'sweetalert2';


@Component({
    selector: "app-operacao-lote",
    templateUrl: "./operacao-lote.component.html",
    styleUrls: ["./../gerenciamento-taxa.component.scss"]
})
export class OperacaoLoteComponent implements OnInit {
    
    formulario: FormGroup;

    emitirErro: boolean;
    mensagemErro: string;

    exibirProcessamentos: boolean = false;
    exibirCalendarioFiscal: boolean = false;

    processamentoLoteLiberado: boolean = false;
    alterarCalendarioFiscalLiberado: boolean = false;

    numeroInscricao: string = "";
    numeroProtocolo: string = "";
    
    @ViewChild(ProcessamentoLoteTaxaComponent)
    processamentoLoteTaxa: ProcessamentoLoteTaxaComponent;
    
    @ViewChild(CalendarioFiscalLoteComponent)
    calendarioFiscalLote: CalendarioFiscalLoteComponent;

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private viewportScroller: ViewportScroller) {
            
        this.viewportScroller.setOffset([0,344]);
            
        this.formulario = this.formBuilder.group({
            tipoBusca: [''],
            exercicio: [''],
            tipoBuscaValorInput: [''],
            tipoBuscaValorSelect: [''],
        });

        authenticationService.currentAxisValue.item.forEach(menu => {
          if (menu.nome == "moduloTaxa") {
            menu.item.forEach(subMenu => {
              if (subMenu.nome == "operacaoLote") {
                subMenu.item.forEach(funcionalidade => {
                  if (funcionalidade.nome == "processarLote") {
                    this.processamentoLoteLiberado = true;
                  }
                  if (funcionalidade.nome == "alterarCalendarioFiscal") {
                    this.alterarCalendarioFiscalLiberado = true;
                  }
                });
              }
            });
          }
        });
    }

    ngOnInit(): void {
      if (this.processamentoLoteLiberado) {
        this.exibirProcessamentos = true;
      }
      else if (this.alterarCalendarioFiscalLiberado) {
        this.exibirCalendarioFiscal = true;
      }
    }

    goToAnchor(ancora) {
      this.viewportScroller.scrollToAnchor(ancora);
    }

    processamentoLote() {
      if (!this.processamentoLoteLiberado) {
        Swal.fire("", "Caro Auditor, está funcionalidade não está liberada para o seu usuário.", "error");
        return false;
      }

      this.goToAnchor("divDetalhes");
      
      this.calendarioFiscalLote.limpar();

      this.exibirProcessamentos = true;
      this.exibirCalendarioFiscal = false;
    }

    alterarCalendarioFiscal() {
      if (!this.alterarCalendarioFiscalLiberado) {
        Swal.fire("", "Caro Auditor, está funcionalidade não está liberada para o seu usuário.", "error");
        return false;
      }

      this.goToAnchor("divDetalhes");
      
      this.processamentoLoteTaxa.limpar();

      this.exibirProcessamentos = false;
      this.exibirCalendarioFiscal = true;
    }

}
