<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="form-row">
        <div class="form-group col-12">

            
            <label class="col-sm-12 col-form-label" for="cpfCnpj">CPF / CNPJ</label>
            <input
                #inputCpfCnpj
                (input)="formatToCPFOrCNPJ(inputCpfCnpj.value)"
                (blur)="verificaUsuario()"
                [class.is-invalid]="cpfCnpj.invalid && cpfCnpj.touched"
                autofocus
                class="form-control form-control-sm col-4"
                formControlName="cpfCnpj"
                id="cpfCnpj"
                name="cpfCnpj"
                type="text"
                maxlength="18"
            />
            <div class="mb-1" *ngIf="cpfCnpj.invalid && cpfCnpj.touched">
                <span 
                    class="erro" 
                    *ngIf="cpfCnpj.errors.required"
                >Campo obrigatório</span>
                <span 
                    class="erro" 
                    *ngIf="cpfCnpj.errors.minlength"
                >O CPF / CNPJ deve conter ao menos 14 caracteres.</span>
                <span 
                    class="erro" 
                    *ngIf="cpfCnpj.errors.maxlength"
                >O CPF / CNPJ deve conter até 18 caracteres.</span>
                <span 
                    class="erro" 
                    *ngIf="cpfCnpj.hasError('mensagem')"
                >{{cpfCnpj.errors.mensagem}}</span>
            </div>
        </div>
    </div>
    <div class="form-row" *ngIf="!estaAutenticado()">
        <div class="col-12 text-right">
            <mat-divider></mat-divider><br>
        </div>
    </div>
    <div class="form-row" *ngIf="!estaAutenticado()">
        <div class="form-group col-6">
            <label class="col-sm-12 col-form-label" for="senha">Senha</label>
            <input
                #senhaInput
                [class.is-invalid]="senha.invalid && senha.touched"
                class="form-control form-control-sm"
                formControlName="senha"
                id="senha"
                name="senha"
                type="password"
            />
            <svg class="icone-visualizar-senha" (click)="senhaInput.type = 'text'" *ngIf="senhaInput.type == 'password'">
              <use
                  attr.xlink:href="assets/sprite.svg#{{ 'eye' }}"
              ></use>
            </svg>
            <svg class="icone-visualizar-senha" (click)="senhaInput.type = 'password'" *ngIf="senhaInput.type == 'text'">
              <use
                  attr.xlink:href="assets/sprite.svg#{{ 'eye-off' }}"
              ></use>
            </svg>
            <div class="mb-1" *ngIf="senha.invalid && senha.touched">
                <span 
                    class="erro" 
                    *ngIf="senha.errors.required"
                >Campo obrigatório</span>
            </div>
        </div>
        <div class="form-group col-6">
            <label class="col-sm-12 col-form-label" for="confirmarSenha">Confirmação</label>
            <input
                #confirmarSenhaInput
                [class.is-invalid]="confirmarSenha.invalid && confirmarSenha.touched"
                class="form-control form-control-sm"
                formControlName="confirmarSenha"
                id="confirmarSenha"
                name="confirmarSenha"
                type="password"
            />
            <svg class="icone-visualizar-senha" (click)="confirmarSenhaInput.type = 'text'" *ngIf="confirmarSenhaInput.type == 'password'">
              <use
                  attr.xlink:href="assets/sprite.svg#{{ 'eye' }}"
              ></use>
            </svg>
            <svg class="icone-visualizar-senha" (click)="confirmarSenhaInput.type = 'password'" *ngIf="confirmarSenhaInput.type == 'text'">
              <use
                  attr.xlink:href="assets/sprite.svg#{{ 'eye-off' }}"
              ></use>
            </svg>
            <div class="mb-1" *ngIf="confirmarSenha.invalid && confirmarSenha.touched">
                <span 
                    class="erro" 
                    *ngIf="confirmarSenha.errors.required"
                >Campo obrigatório</span>
                <span 
                    class="erro" 
                    *ngIf="confirmarSenha.hasError('equalsTo')"
                >Senhas não conferem.</span>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col-12 text-right">
            <mat-divider></mat-divider><br>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-6">
            <label class="col-sm-12 col-form-label" for="nome">Nome</label>
            <input
                [class.is-invalid]="nome.invalid && nome.touched"
                class="form-control form-control-sm"
                formControlName="nome"
                id="nome"
                name="nome"
                type="text"
            />
            <div class="mb-1" *ngIf="nome.invalid && nome.touched">
                <span 
                    class="erro" 
                    *ngIf="nome.errors.required"
                >Campo obrigatório</span>
            </div>
        </div>
        <div class="form-group col-6">
            <label class="col-sm-12 col-form-label" for="email">E-mail</label>
            <input
                [class.is-invalid]="email.invalid && email.touched"
                class="form-control form-control-sm"
                formControlName="email"
                id="email"
                name="email"
                type="text"
            />
            <div class="mb-1" *ngIf="email.invalid && email.touched">
                <span 
                    class="erro" 
                    *ngIf="email.errors.required"
                >Campo obrigatório</span>
                <span 
                    class="erro" 
                    *ngIf="email.errors.email"
                >O valor digitado não é um e-mail válido.</span>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-4">
            <label class="col-sm-12 col-form-label" for="telefoneFixo">Telefone Fixo</label>
            <input
                #telefoneFixoInput
                (input)="formatToPhone(telefoneFixoInput)"
                class="form-control form-control-sm"
                formControlName="telefoneFixo"
                id="telefoneFixo"
                name="telefoneFixo"
                type="text"
                maxlength="14"
            />
        </div>
        <div class="form-group col-4">
            <label class="col-sm-12 col-form-label" for="telefoneComercial">Telefone Comercial</label>
            <input
                #telefoneComercialInput
                (input)="formatToPhone(telefoneComercialInput)"
                class="form-control form-control-sm"
                formControlName="telefoneComercial"
                id="telefoneComercial"
                name="telefoneComercial"
                type="text"
                maxlength="14"
            />
        </div>
        <div class="form-group col-4">
            <label class="col-sm-12 col-form-label" for="celular">Celular</label>
            <input
                #telefoneCelularInput
                (input)="formatToPhone(telefoneCelularInput)"
                [class.is-invalid]="telefoneCelular.invalid && telefoneCelular.touched"
                class="form-control form-control-sm"
                formControlName="telefoneCelular"
                id="telefoneCelular"
                name="telefoneCelular"
                type="text"
                maxlength="16"
            />
            <div class="mb-1" *ngIf="telefoneCelular.invalid && telefoneCelular.touched">
                <span 
                    class="erro" 
                    *ngIf="telefoneCelular.errors.required"
                >Campo obrigatório</span>
                <span 
                    class="erro" 
                    *ngIf="telefoneCelular.errors.maxlength"
                >Telefone celular digitado é inválido.</span>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col-12 text-right">
            <mat-divider></mat-divider><br>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-12">
            <label class="col-sm-12 col-form-label" for="possuiCrc">Possui CRC?</label>
            <mat-radio-group 
                (change)="acaoPossuiCrc()"
                formControlName="possuiCrc" 
                aria-label="Selecione uma opção">
                <mat-radio-button value="S">Sim</mat-radio-button>
                <mat-radio-button value="N">Não</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="form-row" *ngIf="possuiCrc.value === 'S'">
        <div class="form-group col-4">
            <label class="col-sm-12 col-form-label" for="numeroCrc">Nº CRC</label>
            <input
                class="form-control form-control-sm"
                formControlName="numeroCrc"
                id="numeroCrc"
                name="numeroCrc"
                type="text"
                maxlength="10"
            />
        </div>
        <div class="form-group col-2">
            <label class="col-sm-12 col-form-label" for="estadoCrc">Estado</label>
            <select 
                formControlName="estadoCrc"
                class="form-control form-control-sm"
                name="estadoCrc" 
                id="estadoCrc"                
            >
                <option value=""></option>
                <option *ngFor="let estado of estados" 
                    value="{{ estado }}"
                >
                    {{ estado }}
                </option>
            </select>
        </div>
        <div class="form-group col-3">
            <label class="col-sm-12 col-form-label" for="classificacaoCrc">Classificação</label>
            <select 
                formControlName="classificacaoCrc"
                class="form-control form-control-sm"
                name="classificacaoCrc" 
                id="classificacaoCrc"                
            >
                <option value=""></option>
                <option value="profissional">Profissional</option>
            </select>
        </div>
        <div class="form-group col-3">
            <label class="col-sm-12 col-form-label" for="tipoCrc">Tipo</label>
            <select 
                formControlName="tipoCrc"
                class="form-control form-control-sm"
                name="tipoCrc" 
                id="tipoCrc"                
            >
                <option value=""></option>
                <option value="Filiais">Filiais</option>
                <option value="Originários">Originários</option>
                <option value="Provisório">Provisório</option>
                <option value="Secundário">Secundário</option>
                <option value="Transferido">Transferido</option>
            </select>
        </div>
    </div>
    <div class="form-row">
        <div class="col-12 text-right">
            <mat-divider></mat-divider><br>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-12">
            <label class="col-sm-12 col-form-label" for="possuiCrea">Possui CREA?</label>
            <mat-radio-group 
                (change)="acaoPossuiCrea()"
                formControlName="possuiCrea" 
                aria-label="Selecione uma opção">
                <mat-radio-button value="S">Sim</mat-radio-button>
                <mat-radio-button value="N">Não</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="form-row" *ngIf="possuiCrea.value === 'S'">
        <div class="form-group col-4">
            <label class="col-sm-12 col-form-label" for="numeroCrea">Nº CREA</label>
            <input
                class="form-control form-control-sm"
                formControlName="numeroCrea"
                id="numeroCrea"
                name="numeroCrea"
                type="text"
                maxlength="18"
            />
        </div>
        <div class="form-group col-2">
            <label class="col-sm-12 col-form-label" for="estadoCrea">Estado</label>
            <select 
                formControlName="estadoCrea"
                class="form-control form-control-sm"
                name="estadoCrea" 
                id="estadoCrea"                
            >
                <option value=""></option>
                <option *ngFor="let estado of estados" 
                    value="{{ estado }}"
                >
                    {{ estado }}
                </option>
            </select>
        </div>
    </div>
    <div class="form-row">
        <div class="col-12 text-right">
            <mat-divider></mat-divider><br>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-12">
            <label class="col-sm-12 col-form-label" for="possuiCau">Possui CAU?</label>
            <mat-radio-group 
                (change)="acaoPossuiCau()"
                formControlName="possuiCau" 
                aria-label="Selecione uma opção">
                <mat-radio-button value="S">Sim</mat-radio-button>
                <mat-radio-button value="N">Não</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="form-row" *ngIf="possuiCau.value === 'S'">
        <div class="form-group col-4">
            <label class="col-sm-12 col-form-label" for="numeroCau">Nº CAU</label>
            <input
                class="form-control form-control-sm"
                formControlName="numeroCau"
                id="numeroCau"
                name="numeroCau"
                type="text"
                maxlength="18"
            />
        </div>
        <div class="form-group col-2">
            <label class="col-sm-12 col-form-label" for="estadoCau">Estado</label>
            <select 
                formControlName="estadoCau"
                class="form-control form-control-sm"
                name="estadoCau" 
                id="estadoCau"                
            >
                <option value=""></option>
                <option *ngFor="let estado of estados" 
                    value="{{ estado }}"
                >
                    {{ estado }}
                </option>
            </select>
        </div>
        <div class="form-group col-3">
            <label class="col-sm-12 col-form-label" for="classificacaoCau">Classificacao</label>
            <select 
                formControlName="classificacaoCau"
                class="form-control form-control-sm"
                name="classificacaoCau" 
                id="classificacaoCau"                
            >
                <option value=""></option>
                <option value="profissional">Profissional</option>
            </select>
        </div>
        <div class="form-group col-3">
            <label class="col-sm-12 col-form-label" for="emissaoCau">Emissão</label>
            <input
                #emissaoCauInput
                mask="00/00/0000"
                [class.is-invalid]="emissaoCau.invalid && emissaoCau.touched"
                class="form-control form-control-sm"
                formControlName="emissaoCau"
                id="emissaoCau"
                name="emissaoCau"
                type="text"
                maxlength="10"
            />
            <div class="mb-1" *ngIf="emissaoCau.invalid && emissaoCau.touched">
                <span 
                    class="erro" 
                    *ngIf="emissaoCau.errors.minlength"
                >Data inválida</span>
            </div>
            <span 
                class="erro" 
                *ngIf="emissaoCau.hasError('mensagem')"
            >{{emissaoCau.errors.mensagem}}</span>
        </div>
    </div>
    <div class="form-row" *ngIf="estaAutenticado()">
        <div class="col-12 text-right">
            <mat-divider></mat-divider><br>
        </div>
    </div>
    <div class="form-row mb-4" *ngIf="estaAutenticado()">
        <div class="row col-6">
            <label class="col-sm-12 col-form-label" for="fotoPerfil">Enviar foto para o perfil</label>
            <input class="form-control" type="file" id="formFile" (change)="upload($event)">
        </div>
        <div class="row col-6 justify-content-center align-items-center">
            <img class="image-perfil" [src]="fotoPerfilBinario" alt="avatar">
        </div>
    </div>
</form>