<div class="justify-content-md-end">
  <div class="row col-12 top-atendimento mt-2">
    <div class="row col-12 container-principal-atendimento container-tamanho pt-3 pb-3">
      <div class="row col-6 justify-content-start align-items-center">
        <button type="button" class="btn btn-secondary" (click)="acaoBotaoVoltar()">Voltar</button>
      </div>
      <div class="row col-6 justify-content-end align-items-center divisao-esquerda" [class.hidden]="carregamentoDeArquivo">
        <button type="button" class="btn btn-success" (click)="carregarArquivo($event)">Carregar Arquivo</button>
      </div>
    </div>
    <div class="row col-12 container-principal-atendimento container-tamanho pt-3 pb-3 mt-3"
      [class.hidden]="!carregamentoDeArquivo">
      <div class="row col-12" class="w-100 mt-2">
        <h4>Serviço de processamento em lote</h4>
        <p>
          Serviço de processamento em lote
          Aberturas de empresa MEI via oficio por lote.
        </p>
        <strong> Selecionar o documento .xls ou .csv para processamento:</strong>
        <input class="form-control" type="file" id="formFile" (change)="upload($event)">
      </div>
    </div>
    <div class="row col-12 " class="w-100" [class.hidden]="carregamentoDeArquivo">
      <app-grid-atualizacao-oficio></app-grid-atualizacao-oficio>
    </div>
  </div>
</div>