import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { TaxaService } from '../../../services/taxa/taxa.service';
import { TaxaLancamento } from '../../../model/taxaLancamento';
import { TaxaLancamentoParcela } from '../../../model/taxaLancamentoParcela';
import { Constantes } from '../../../model/constantes';
import { MascaraPipe } from '../../../pipes/mascara.pipe';
import { EstatisticasTaxaComponent } from './estatisticas-taxa/estatisticas-taxa.component';
import { ProcessamentoLoteTaxaComponent } from './processamento-lote/processamento-lote-taxa.component';
import { OperacaoTaxaComponent } from './operacao-taxa/operacao-taxa.component';


@Component({
    selector: "app-gerenciamento-taxa",
    templateUrl: "./gerenciamento-taxa.component.html",
    styleUrls: ["./gerenciamento-taxa.component.scss"]
})
export class GerenciamentoTaxaComponent implements OnInit {
    
    exercicio: string = new Date().getFullYear().toString();

    taxaLancamentos: Array<TaxaLancamento>;
    taxaLancamentoParcelas: Array<TaxaLancamentoParcela>;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

    @ViewChild(EstatisticasTaxaComponent)
    estatisticasTaxa: EstatisticasTaxaComponent;

    @ViewChild(ProcessamentoLoteTaxaComponent)
    processamentoLoteTaxa: ProcessamentoLoteTaxaComponent;

    @ViewChild(OperacaoTaxaComponent)
    operacaoTaxa: OperacaoTaxaComponent;

    constructor(
        private activatedRoute: ActivatedRoute,
        private mascara: MascaraPipe,
        private taxaService: TaxaService) {
            
    }

    ngOnInit(): void {
    }

    selecaoAba(indiceMenu) {
        this.operacaoTaxa.tipoBusca.setValue("");
        this.operacaoTaxa.acaoSelectTipoBusca();
    }
}
