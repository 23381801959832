import { OnInit, Component, Input } from "@angular/core";
import { take } from "rxjs/operators";
import { Processo } from "../../../../model/processo";
import { ProcessoService } from '../../../../services/processo/processo.service';
import { ActivatedRoute } from '@angular/router';
import { Fase } from '../../../../model/fase';
import { Constantes } from '../../../../model/constantes';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observacao } from '../../../../model/observacao';

@Component({
    selector: "app-time-line-vertical",
    templateUrl: "./time-line-vertical.component.html",
    styleUrls: ['./time-line-vertical.component.scss']
})
export class TimeLineVerticalComponent implements OnInit {

    public config: PerfectScrollbarConfigInterface = {};

    @Input() dados: Array<any>;
    @Input() porFase: boolean = true;
    @Input() porProcesso: boolean = false;

    titulo: string;
    numeroProtocolo: string;
    fases: Array<Fase>;
    listaDeProcessos: Set<string>;
    observacoesPorProcessos: Array<any>;
    situacaoAcompanhamento = Constantes.SITUACAO_DE_ACOMPANHAMENTO;
    
    numeroInscricao: string;
    processos: Array<Processo>;

    constructor(
        private activatedRoute: ActivatedRoute) {
        this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];
    }

    ngOnInit(): void {
        if (this.dados != null) {
            this.numeroProtocolo = this.dados[0];
            this.fases = this.dados[1];
            this.listaDeProcessos = this.dados[2];
            this.observacoesPorProcessos = this.dados[3];
        }

    }
}