<div class="layout-px-spacing">

    <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-md-12">

            <div class="mail-box-container">
                <div class="mail-overlay"></div>

                <div id="todo-inbox" class="accordion todo-inbox">
                    <div class="search">
                        <input #filtro
                            id="txtFiltro"
                            type="text"
                            (input)="acaoDoFiltro(filtro.value)"
                            class="form-control input-search" placeholder="Localizar por aqui...">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu mail-menu d-lg-none"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                    </div>
            
                    <div class="todo-box">
                        
                        <div id="ct" class="todo-box-scroll" [perfectScrollbar]="config" >
                            <div *ngFor="let pendencia of pendencias; let i = index"
                                class="todo-item all-list">
                                <div class="todo-item-inner">
                                    <div class="row n-chk justify-content-start align-items-center">
                                        <svg 
                                            class="iconeGrid"
                                            [class.iconeGridEmAnalisePelaPrefeitura]="pendencia.codigoTipo == 1"
                                            [class.iconeGridAguardandoContribuinte]="pendencia.codigoTipo == 2 || pendencia.codigoTipo == 3"
                                            [class.iconeGridCritica]="pendencia.codigoTipo == 4"
                                            [class.iconeGridConcluidoDeferido]="pendencia.codigoTipo == 5 || pendencia.codigoTipo == 10"
                                            [class.iconeGridAguardandoAnaliseDaPrefeitura]="pendencia.codigoTipo == 6 || pendencia.codigoTipo == 7"
                                            [class.iconeGridConcluidoIndeferido]="pendencia.codigoTipo == 8">
                                            <use
                                            attr.xlink:href="assets/sprite.svg#{{ 
                                                pendencia.codigoTipo == 1 ? 'paperclip' : 
                                                pendencia.codigoTipo == 2 || pendencia.codigoTipo == 3 ? 'edit-3' : 
                                                pendencia.codigoTipo == 4 ? 'alert-octagon' : 
                                                pendencia.codigoTipo == 5 ? 'message-square' : 
                                                pendencia.codigoTipo == 6 || pendencia.codigoTipo == 7 ? 'shield' : 
                                                pendencia.codigoTipo == 8 ? 'refresh-ccw' :
                                                pendencia.codigoTipo == 10 ? 'dollar-sign' : ''}}"
                                            ></use>
                                        </svg> 
                                    </div>

                                    <div class="todo-content" *ngIf="pendencia.codigoTipo != pendenciaTipo.Dam">
                                        <h3 class="todo-heading" [attr.data-todoHeading]="(pendencia.protocolo == '' || pendencia.codigoTipo == pendenciaTipo.Questionario_De_Licenciamento || pendencia.codigoTipo == pendenciaTipo.Renovacao_Do_Requisito ? pendencia.inscricao : pendencia.protocolo) + ' - ' + pendencia.fluxos[0].login.nome">
                                            <strong>{{ pendenciaDescricao[pendencia.codigoTipo] }}</strong> {{ pendencia.fluxos[0].login.nome != '' ? ' - ' + pendencia.fluxos[0].login.nome : '' }}
                                        </h3>
                                        <p class="meta-date">
                                            <b>Inscrição Municipal:</b> {{ pendencia.inscricao != '' ?  pendencia.inscricao : 'Em fase de solicitação' }} <b>{{ pendencia.protocolo != '' ? ' - Protocolo: ' : '' }}</b> {{ pendencia.protocolo }}  <b>{{ pendencia.redeSim != '' ? ' - Redesim / IDF: ' : '' }}</b> {{ pendencia.redeSim }} 
                                        </p>
                                        <p class="meta-date" *ngIf="pendencia.processo.descricao != ''">
                                            <b>Serviço:</b> {{ pendencia.processo.descricao }}
                                        </p>
                                        <p class="meta-date" *ngIf="pendencia.protocolo == '' || pendencia.codigoTipo == pendenciaTipo.Questionario_De_Licenciamento">
                                            <b>Requisito:</b> {{ pendencia.fluxos[0].requisito.orgao.nome | titlecase }} - {{ pendencia.fluxos[0].requisito.nome | titlecase }}
                                        </p>
                                        <p class="meta-date" *ngIf="pendencia.codigoTipo == pendenciaTipo.Renovacao_Do_Requisito">
                                            <b>Vencimento:</b> {{ pendencia.data | mask: '00/00/0000' }}
                                        </p>
                                        <p class="todo-text" 
                                            [attr.data-todoHtml]="'<p>' + pendenciaDescricao[pendencia.codigoTipo] + ' - ' + pendencia.fluxos[0].observacao + '</p>'" 
                                            [innerHtml]="pendencia.fluxos[0].observacao">
                                        </p>
                                    </div>
                                    <div class="todo-content" *ngIf="pendencia.codigoTipo == pendenciaTipo.Dam">
                                        <h3 class="todo-heading" [attr.data-todoHeading]="(pendencia.protocolo == '' || pendencia.codigoTipo == pendenciaTipo.Questionario_De_Licenciamento || pendencia.codigoTipo == pendenciaTipo.Renovacao_Do_Requisito ? pendencia.inscricao : pendencia.protocolo) + ' - ' + pendencia.fluxos[0].login.nome">
                                            <strong>{{ pendenciaDescricao[pendencia.codigoTipo] }}</strong> {{ pendencia.fluxos[0].login.nome != '' ? ' - ' + pendencia.fluxos[0].login.nome : '' }}
                                        </h3>
                                        <p class="meta-date" *ngIf="pendencia.fase != ''">
                                            <b>Fase:</b> {{ pendencia.fase }}
                                        </p>
                                        <p class="meta-date" *ngIf="pendencia.fase == ''">
                                            <b>Requisito:</b> {{ pendencia.fluxos[0].requisito.orgao.nome | titlecase }} - {{ pendencia.fluxos[0].requisito.nome | titlecase }}
                                        </p>
                                        <p class="meta-date">
                                            <b>Lançamento:</b> {{ pendencia.descricaoTipo }} {{ pendencia.data != '' ? ' - ' + pendencia.data : '' }}
                                        </p>
                                        <p class="todo-text" 
                                            [attr.data-todoHtml]="'<p>' + pendenciaDescricao[pendencia.codigoTipo] + ' - ' + pendencia.fluxos[0].observacao + '</p>'" 
                                            [innerHtml]="pendencia.fluxos[0].observacao">
                                        </p>
                                    </div>
                                    <div class="action-dropdown custom-dropdown-icon">
                                        <div ngbDropdown class="d-inline-block">
                                            <a class="dropdown-toggle" href="javascript:void(0);" role="button" id="dropdownMenuLink-2" ngbDropdownToggle>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                            </a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" *ngIf="pendencia.codigoTipo != pendenciaTipo.Dam">
                                                <a class="dropdown-item" href="javascript:void(0);"
                                                    (click)="seguirProcesso(pendencia)"
                                                >Responder</a>
                                            </div>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" *ngIf="pendencia.codigoTipo == pendenciaTipo.Dam">
                                                <a class="dropdown-item" href="javascript:void(0);"
                                                    (click)="acaoImprimirBoleto(pendencia)"
                                                >Gerar DAM</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>                                    
            </div>
            
        </div>
    </div>

</div>