import { TaxaBaixa } from './taxaBaixa';
import { TaxaLancamentoMemoriaParcela } from './taxaLancamentoMemoriaParcela';

export class TaxaLancamentoParcela {
    codigo: string;
    codigoLancamento: string;
    codigoAcordo: string;
    numero: string;
    situacao: string;
    dataSituacao: string;
    vencimento: string;
    vencimentoParaPagamento: string;
    subTotalMora: number;
    subTotalJuros: number;
    subTotalCorrecaoMonetaria: number;
    subTotalDesconto: number;
    subTotal: number;
    total: number;
    loginProcessamento: string;
    processamento: string;
    loginCadastro: string;
    adicionado: string;
    taxaBaixa: TaxaBaixa;
    taxaLancamentoMemoriasParcela: Array<TaxaLancamentoMemoriaParcela>;

    constructor() {
        this.codigo = "";
        this.codigoLancamento = "";
        this.codigoAcordo = "";
        this.numero = "";
        this.situacao = "";
        this.dataSituacao = "";
        this.vencimento = "";
        this.vencimentoParaPagamento = "";
        this.subTotalMora = 0;
        this.subTotalJuros = 0;
        this.subTotalCorrecaoMonetaria = 0;
        this.subTotalDesconto = 0;
        this.subTotal = 0;
        this.total = 0;
        this.loginProcessamento = "";
        this.processamento = "";
        this.loginCadastro = "";
        this.adicionado = "";
        this.taxaBaixa = new TaxaBaixa();
        this.taxaLancamentoMemoriasParcela = new Array<TaxaLancamentoMemoriaParcela>();
    }
}