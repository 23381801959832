import { OnInit, Component } from "@angular/core";

@Component({
    selector: "app-detalahamento",
    templateUrl: "./detalhamento.component.html"
})
export class DetalhamentoComponent implements OnInit {
    
    constructor() {}

    ngOnInit(): void {}

}