export class LicenciamentoOrientacao {
    cliente: string;
    codigo: string;
    protocolo: string;
    inscricao: string;
    orientacao: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.protocolo = "";
        this.inscricao = "";
        this.orientacao = "";
    }
}