<div class="container-principal">
    <form
        class="containerLogin_formulario row no-gutters justify-content-center align-items-start"
        name="formCadastrarUsuario"
        [formGroup]="formulario"
        (ngSubmit)="salvar()"
    >
        <div class="form-row col-md-12 col-sm-10">
            <div class="col-12 d-flex justify-content-center mt-3">
                <img
                    class="logo"
                    src="../../../../../assets/img/logos/_logo_iCad.svg"
                />
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
                <h1 class="containerLogin_formulario_titulo">
                    ICADONLINE - PESQUISA PRÉVIA DE VIABILIDADE DO SEU EMPREENDIMENTO NO MUNICÍPIO
                </h1>
            </div>
            <!-- <div class="col-12 d-flex justify-content-center mt-4">
                <h1 class="containerLogin_formulario_titulo">
                    {{ tituloPasso }}
                </h1>
            </div> -->
        </div>
        <!-- <div class="form-row col-md-12 col-sm-10">
            <app-alert class="w-100" type="info">
                <div [innerHtml]="tituloPasso"></div>
            </app-alert>
        </div> -->
        <div class="form-row col-md-12 col-sm-10">
            <div class="form-group col-12 mt-4">
                <mat-horizontal-stepper 
                    labelPosition="bottom" 
                    linear 
                    #passos
                    (selectionChange)="mudancaPasso($event, passos)">
                    <mat-step 
                        label="Identificação" >
                        <div class="form-row">
                            <div class="col-12">
                                <app-alert class="w-100" type="info" (click)="baixarArquivo(365)" style="cursor: pointer;">
                                    <div [innerHtml]="tituloPasso"></div>
                                </app-alert>
                            </div>
                        </div>
                        <app-pesquisa-viabilidade-identificacao></app-pesquisa-viabilidade-identificacao>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <button 
                                    (click)="avancarPassoTipoPesquisa(passos)"
                                    type="button" 
                                    class="btn btn-success" 
                                    mat-button >
                                    Avançar
                                </button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step *ngIf="exibirLocalizacao"
                        label="Localização" >
                        <div class="form-row">
                            <div class="col-12">
                                <app-alert class="w-100" type="info" (click)="baixarArquivo(365)" style="cursor: pointer;">
                                    <div [innerHtml]="tituloPasso"></div>
                                </app-alert>
                            </div>
                        </div>
                        <app-form-localizacao-pesquisa (renomearBotao)="renomearBotaoLocalizacao()"></app-form-localizacao-pesquisa>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 text-left">
                                <button 
                                    (click)="voltarPassoLocalizacao()"
                                    type="button" 
                                    class="btn btn-danger" 
                                    mat-button>
                                    {{ btnAnteriorLocalizacao }}
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button 
                                    (click)="avancarPassoLocalizacao()"
                                    type="button"
                                    class="btn btn-success" 
                                    mat-button >
                                    {{ btnAvancarLocalizacao }}
                                </button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step *ngIf="exibirAtividades"
                        label="Atividades" >
                        <div class="form-row">
                            <div class="col-12">
                                <app-alert class="w-100" type="info" (click)="baixarArquivo(365)" style="cursor: pointer;">
                                    <div [innerHtml]="tituloPasso"></div>
                                </app-alert>
                            </div>
                        </div>
                        <app-form-atividade-pesquisa></app-form-atividade-pesquisa>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 text-left">
                                <button 
                                    type="button" 
                                    class="btn btn-danger" 
                                    mat-button
                                    matStepperPrevious>
                                    Anterior
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button 
                                    (click)="avancarPassoAtividade()"
                                    type="button"
                                    class="btn btn-success" 
                                    mat-button>
                                    Avançar
                                </button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step label="Consulta">
                        <div class="form-row">
                            <div class="col-12">
                                <app-alert class="w-100" type="info" (click)="baixarArquivo(365)" style="cursor: pointer;">
                                    <div [innerHtml]="tituloPasso"></div>
                                </app-alert>
                            </div>
                        </div>
                        <div class="form-row">
                            <div id="div-google-maps" class="col-12 div-google-maps">
                                <app-google-maps></app-google-maps>
                            </div>
                        </div>
                        <br>
                        <div class="form-row">
                            <div class="col-12">
                                <app-alert class="w-100" type="info" (click)="baixarArquivo(365)" style="cursor: pointer;">
                                    <div id="legendas">
                                        Através dos ícones à esquerda do mapa, você poderá criar os cenários visuais para análise das possibilidades da abertura ou alteração do empreendimento.<br>
                                        Cada ícone disponibilizará no mapa o tipo de informação relacionada, e é possível utiliza-los conjuntamente.<br>
                                        Ícones disponíveis:
                                        <ul>
                                            <li>
                                                Mapa/Satélite - altera a forma de visualização do mapa.
                                            </li>
                                            <li>
                                                <img
                                                    src="../../../../../assets/img/icons/map-pin.svg"
                                                />
                                                Localização - identifica o local que você definiu para seu empreendimento.
                                            </li>
                                            <li>
                                                <img
                                                    src="../../../../../assets/img/icons/eye.svg"
                                                />
                                                Concorrentes - identifica as empresas que se utilizam da(s) atividade(s) que você pretende utilizar.
                                            </li>
                                            <li>
                                                <img
                                                    src="../../../../../assets/img/icons/globe.svg"
                                                />
                                                Serviços Públicos - informações úteis, disponibilizadas pelo município (UBS´s, hospitais, escolas, secretarias, etc).
                                            </li>
                                            <li>
                                                <img
                                                    src="../../../../../assets/img/icons/thumbs-up.svg"
                                                />
                                                Possível - identifica os locais onde é possível exercer <b>o conjunto</b> de atividades que você pretende.
                                            </li>
                                            <li>
                                                <img
                                                    src="../../../../../assets/img/icons/thumbs-down.svg"
                                                />
                                                Não Possível -  identifica os locais onde não é possível exercer <b>o conjunto</b> de atividades que você pretende.
                                            </li>
                                            <li>
                                                <img
                                                    src="../../../../../assets/img/icons/alert-triangle.svg"
                                                />
                                                Permissível - identifica os locais onde é permissível exercer <b>o conjunto</b> de atividades que você pretende.
                                            </li>
                                            <li>
                                                <img
                                                    src="../../../../../assets/img/icons/map.svg"
                                                />
                                                Pontos de Interesse Google - identifica os pontos de interesse geral, disponibilizado pelo Google.
                                            </li>
                                            <li>
                                                <img
                                                    src="../../../../../assets/img/icons/printer.svg"
                                                />
                                                Compartilhar - possibilita que você imprima a tela com os resultados apresentados.
                                            </li>
                                            <li>
                                                <img
                                                    src="../../../../../assets/img/icons/maximize.svg"
                                                />
                                                Tela Cheia - mostra o mapa em tela cheia.
                                            </li>
                                            <li>
                                                <img
                                                    src="../../../../../assets/img/icons/plus.svg"
                                                />
                                                Zoom - zoom no mapa.
                                            </li>
                                            <li>
                                                Perímetro - define um perímetro de visualização entre 1km e 25km, tendo como ponto central a localização que você definiu.
                                            </li>
                                        </ul>
                                    </div>
                                </app-alert>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 text-left">
                                <button 
                                    type="button" 
                                    class="btn btn-danger" 
                                    mat-button 
                                    matStepperPrevious>
                                    Anterior
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button 
                                    type="submit" 
                                    class="btn btn-success"
                                    title="salvar"
                                >
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
    </form>
</div>