import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constantes } from '../../../model/constantes';
import Swal from 'sweetalert2';

@Component({
    selector: "app-pesquisa-viabilidade-identificacao",
    templateUrl: "./pesquisa-viabilidade-identificacao.component.html",
    styleUrls: ["../pesquisa-viabilidade.component.scss"]
})
export class PesquisaViabilidadeIdentificacaoComponent implements OnInit {
    
    formulario: FormGroup;

    tipoDeBusca: string = "";
    tipoDeNegocio: string = "";
    
    emitirErro: boolean;
    mensagemErro: string;

    constructor(private renderer: Renderer2,
        private document: Document,
        private formBuilder: FormBuilder) {

        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]]
        });
    }

    ngOnInit(): void {}

    selecionar(element: HTMLDivElement) {
        this.document.getElementsByName(element.getAttribute("name")).forEach( item => this.renderer.removeClass(item, "selecionado"));
        this.renderer.addClass(this.document.getElementById(element.id), "selecionado");
        
        if (element.id == "divTipoDeBusca1") {
            this.tipoDeBusca = Constantes.TIPO_DE_PESQUISA.Localizacao_Atividade;
        }
        else if (element.id == "divTipoDeBusca2") {
            this.tipoDeBusca = Constantes.TIPO_DE_PESQUISA.Localizacao;
        }
        else if (element.id == "divTipoDeBusca3") {
            this.tipoDeBusca = Constantes.TIPO_DE_PESQUISA.Atividade;
        }
        else if (element.id == "divTipoDeNegocio1") {
            this.tipoDeNegocio = Constantes.TIPO_EMPRESA.Empresa;
        }
        else if (element.id == "divTipoDeNegocio2") {
            this.tipoDeNegocio = Constantes.TIPO_EMPRESA.Mei;
        }
        else if (element.id == "divTipoDeNegocio3") {
            this.tipoDeNegocio = Constantes.TIPO_EMPRESA.Autonomo;
        }        
    }

    validarFormulario() {
        this.controleValidade.setValue("");
        
        if (this.formulario.invalid) {
            let mensagemErro = "";
            
            if (this.tipoDeBusca == "") {
                mensagemErro += "Favor informar o tipo de pesquisa que deseja realizar.<br>"
            }

            if (this.tipoDeNegocio == "") {
                mensagemErro += "Favor informar o tipo de negócio que deseja realizar a pesquisa.<br>"
            }

            if (mensagemErro === "") {
                this.controleValidade.setValue(true);
                this.emitirErro = false;
                return true;
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErro = true;
            this.mensagemErro = mensagemErro;

            return false;
        }

        this.emitirErro = false;
        this.mensagemErro = "";
        
        return true;
    }

    limpar() {
        this.tipoDeBusca = "";
        this.tipoDeNegocio = "";
        this.controleValidade.setValue("");
        this.document.querySelectorAll(".btn-pesquisa-identificacao").forEach( item => this.renderer.removeClass(item, "selecionado"));
    }

    get controleValidade() {
        return this.formulario.get("controleValidade");
    }
    set controleValidade(controleValidade) {
        this.formulario.get("controleValidade").setValue(controleValidade);
    }
}