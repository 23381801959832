import { OnInit, Component, Input, OnDestroy } from "@angular/core";
import { ArquivoService } from '../../../services/arquivo/arquivo.service';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Empresa } from '../../../model/empresa';
import { AcompanhamentoService } from '../../../services/operacao/acompanhamento.service';
import { ProtocoloAcompanhamento } from '../../../model/protocoloAcompanhamento';
import { Anexo } from '../../../model/anexo';
import Swal from "sweetalert2";
import { EmpresaService } from '../../../services/empresa/empresa.service';
import { Constantes } from '../../../model/constantes';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
    selector: "app-anexar",
    templateUrl: "./anexar.component.html",
    styleUrls: ["./anexo.component.scss"]
})
export class AnexarComponent implements OnInit, OnDestroy {

    private unsubscribe = new Subject<void>();
    @Input() dados: Array<any>;
    @Input() semCardHeader: boolean = false;
    files: Set<File>;
    formData: FormData;
    protocoloAcompanhamento: ProtocoloAcompanhamento;
    empresa: Empresa;
    listaDeProtocolos: Set<string>;
    anexos: Array<Anexo>;
    currentUser: any;
    cliente: string;

    tipoDeDocumento = Constantes.TIPO_DE_DOCUMENTO;

    constructor(private arquivoService: ArquivoService,
        private authenticationService: AuthenticationService,
        private empresaService: EmpresaService,
        private acompanhamentoService: AcompanhamentoService) {
        
        this.currentUser = this.authenticationService.currentUserValue;
        this.protocoloAcompanhamento = new ProtocoloAcompanhamento();
        this.empresa = new Empresa();
        this.listaDeProtocolos = new Set();
        this.anexos = new Array<Anexo>();
        this.cliente = localStorage.getItem("pId");

    }
    
    ngOnInit(): void {
        if (!this.semCardHeader) {
            this.execultarBusca();
        }
    }

    execultarBusca() {
        if (this.dados[1] != "") {
            this.buscarPorInscricao();
        }
        else if (this.dados[0] != "") {
            if (this.dados[3] != null && (this.dados[3] == "ATENDIMENTO" || this.dados[3] == "RESUMO")) {
                this.buscarParaAtendimento();
            }
            else {
                this.buscarPorProtocolo();
            }
        }
    }

    async buscarPorProtocolo() {
        if (this.dados[1] == null) return;
        
        await this.acompanhamentoService
            .acompanharPorProtocolo(this.dados[0])
            .pipe(take(1))
            .subscribe( retorno => {
                retorno.regularizacoes.forEach(dados => {
                    this.protocoloAcompanhamento = dados;
                });
                retorno.cancelados.forEach(dados => {
                    this.protocoloAcompanhamento = dados;

                });
                retorno.concluidos.forEach(dados => {
                    this.protocoloAcompanhamento = dados;

                });
                
                this.arquivoService
                    .buscarPorProtocolo(localStorage.getItem("pId"), this.protocoloAcompanhamento.protocolo)
                    .pipe(take(1))
                    .subscribe( anexos => {
                        this.anexos = anexos;
                        anexos.forEach( (anexo: Anexo) => {
                            this.listaDeProtocolos.add(anexo.protocolo);
                        });
                    });
            });
                
    }

    async buscarParaAtendimento() {   
        if (this.dados[0] == null) return;

        await this.arquivoService
            .buscarPorProtocolo(localStorage.getItem("pId"), this.dados[0])
            .pipe(take(1))
            .subscribe( anexos => {
                this.anexos = anexos;
                anexos.forEach( (anexo: Anexo) => {
                    this.listaDeProtocolos.add(anexo.protocolo);
                });
            });
    }

    async buscarPorInscricao() {
        if (this.dados[1] == null) return;

        await this.empresaService
            .buscarPorInscricaoDadosBasicos(localStorage.getItem("pId"), this.dados[1])
            .pipe(take(1))
            .subscribe( (retorno: Empresa) => {
                this.empresa = retorno;

                this.arquivoService
                    .buscarPorInscricao(localStorage.getItem("pId"), this.dados[1])
                    .pipe(take(1))
                    .subscribe( anexos => {
                        this.anexos = anexos;
                        anexos.forEach( (anexo: Anexo) => {
                            this.listaDeProtocolos.add(anexo.protocolo);
                        });
                    });
            })
    }
    
    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
    
    upload(event) {
        if (this.arquivos(event)) {
            this.arquivoService
                .uploadAnexo(this.formData)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe (retorno => {
                    if (retorno.codigo != "") {
                        Swal.fire({
                            title: "Anexado",
                            html: `Caro <b>${ this.currentUser.nome }</b>, você anexou o arquivo: <b>${ this.formData.get("fileName") }</b>, com sucesso.`,
                            icon: "success",
                        })
                    }
                    else {
                        Swal.fire({
                            title: "Não Anexado",
                            html: `Caro <b>${ this.currentUser.nome }</b>, não foi possível anexar o arquivo: <b>${ this.formData.get("fileName") }</b>.`,
                            icon: "error",
                        })
                    }
                    if (this.dados[0] != "") {
                        this.buscarPorProtocolo();
                    }
                    else if (this.dados[1] != ""){
                        this.buscarPorInscricao();
                    }
                    else {
                        this.montarRetornoAnexo(retorno);
                    }
                });
        }
        
        (event.target as HTMLInputElement).value = "";
        
    }

    montarRetornoAnexo(anexo) {
        this.anexos.push(anexo);
    }

    arquivos(event) {
        this.formData = new FormData();
        const arquivosSelecionados = <FileList>event.srcElement.files;
        this.files = new Set();
        for (let i = 0; i < arquivosSelecionados.length; i++) {
            if (arquivosSelecionados[i].name.length > 95) {
                Swal.fire({
                    title: "Não Anexado",
                    html: `Caro <b>${ this.currentUser.nome }</b>, não foi possível anexar o arquivo: <b>${ arquivosSelecionados[i].name }</b>.<br><br>O nome do arquivo está muito grande, favor diminuir.`,
                    icon: "error",
                });

                return false;
            }
            if (arquivosSelecionados[i].size > 6842880) {
                Swal.fire({
                    title: "Não Anexado",
                    html: `Caro <b>${ this.currentUser.nome }</b>, não foi possível anexar o arquivo: <b>${ arquivosSelecionados[i].name }</b>.<br><br>O tamanho do arquivo está muito grande, favor diminuir.`,
                    icon: "error",
                });

                return false;
            }
            this.files.add(arquivosSelecionados[i]);
            this.formData.append('file', arquivosSelecionados[i], arquivosSelecionados[i].name);
            this.formData.append('fileName', arquivosSelecionados[i].name);
            this.formData.append('protocolo', this.dados[0] == null ? "" : this.dados[0]);
            this.formData.append('inscricao', this.dados[1] == null ? "" : this.dados[1]);
            this.formData.append('codigoOrgao', this.dados[2]);  
        }

        return true;
    }

    baixarArquivo(codigo, nome, extensao) {
        this.arquivoService.downloadArquivoPorCodigo(codigo)
        .subscribe((res: any) => {
            this.arquivoService.handleFile(res, nome + extensao);
        });
    }

    deletarArquivo(codigo) {
        
        this.arquivoService
         .deletarArquivo(codigo)
         .pipe(take(1))
         .subscribe(retorno => {
            if (retorno != null) {
                let index = this.anexos.findIndex( item => item.codigo == codigo)
                this.anexos.splice(index, 1);
                var anexosAuxiliar = new Array<Anexo>();
                Object.assign(anexosAuxiliar,this.anexos);
                this.anexos = new Array<Anexo>();
                Object.assign(this.anexos,anexosAuxiliar);
                Swal.fire("", "Anexo deletado com sucesso!", "success");
              }
              else {
                Swal.fire("", "Não foi possível deletar o anexo, tente novamente mais tarde.", "error");
              }
         });
         (event.target as HTMLInputElement).value = "";
    }

}