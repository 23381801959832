import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Pergunta } from "src/app/model/pergunta";
import { PerguntaResposta } from '../../model/perguntaResposta';

@Injectable({ providedIn: 'root' })
export class PerguntaService {

    constructor(private http: HttpClient, private router: Router) {}

    listarPerguntas(pergunta) {
        return this.http.post<any>(
            `${ environment.icadUrl }/perguntaResposta/listarPerguntas`, JSON.stringify(pergunta),
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    listarTodasPerguntas() {
        return this.http.get<any>(
            `${ environment.icadUrl }/perguntaResposta/listarTodasPerguntas`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorCodigo(cliente, codigo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/perguntaResposta/buscarPorCodigo/${ cliente }/${ codigo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorCodigoAuditor(cliente, codigo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/perguntaResposta/buscarPorCodigoAuditor/${ cliente }/${ codigo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorInscricaoEOrgaoAuditor(cliente, inscricao, orgao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/perguntaResposta/buscarPorInscricaoEOrgaoAuditor/${ cliente }/${ inscricao }/${ orgao }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    salvar(pergunta: Pergunta) {
        return this.http.post<any>( `${ environment.icadUrl }/perguntaResposta/salvar`, JSON.stringify(pergunta),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    salvarRespostasAuditor(perguntaResposta: PerguntaResposta) {
        return this.http.post<any>( `${ environment.icadUrl }/perguntaResposta/salvarRespostasAuditor`, JSON.stringify(perguntaResposta),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }
    
}