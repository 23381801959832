import { Component, OnInit, ViewChild } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";

import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { Constantes } from "src/app/model/constantes";
import { Protocolo } from "src/app/model/protocolo";
import { take } from "rxjs/operators";
import { FormLocalizacaoComponent } from "../../outros/form-localizacao/form-localizacao.component";
import { FormQuestionarioComponent } from "../../outros/form-questionario/form-questionario.component";
import { FormResumoComponent } from "../../outros/form-resumo/form-resumo.component";
import { FormIdentificacaoNegocioComponent } from "../../outros/form-identificacao-negocio/form-identificacao-negocio.component";
import { FormResponsavelComponent } from "../../outros/form-responsavel/form-responsavel.component";
import { LoaderService } from "src/app/services/loader/loader.service";
import { Configuracao } from "src/app/model/configuracao";
import { Documento } from "src/app/model/documento";
import { Pergunta } from "src/app/model/pergunta";
import Swal from "sweetalert2";
import { Processo } from "src/app/model/processo";
import { Servico } from "src/app/model/servico";
import { ComprovanteDadosCadastrais } from "src/app/components/pdf/comprovanteDadosCadastrais";

@Component({
    selector: "app-fic",
    templateUrl: "./fic.component.html"
})
export class FicComponent implements OnInit {
    
    tipoEmpresa: string;
    informativo: string;
    stepper: MatStepper;
    configuracao: Configuracao;
    processo: Processo;
    tipoProcesso = Constantes.PROCESSO;
    servicos: Array<Servico>;
    protocolo: Protocolo;
    documentos: Array<Documento>;

    // Formulários
    formFic: FormGroup;
    formularioIdentificacao: FormGroup;
    formularioLocalizacao: FormGroup;
    formularioResponsavel: FormGroup;
    formularioQuestionario: FormGroup;
    formularioResumo: FormGroup;

    @ViewChild(FormIdentificacaoNegocioComponent)
    formIdentificacaoNegocio: FormIdentificacaoNegocioComponent;

    @ViewChild(FormLocalizacaoComponent)
    formLocalizacao: FormLocalizacaoComponent;

    @ViewChild(FormResponsavelComponent)
    formResponsavel: FormResponsavelComponent;

    @ViewChild(FormQuestionarioComponent)
    formQuestionario: FormQuestionarioComponent;

    @ViewChild(FormResumoComponent)
    formResumo: FormResumoComponent;

    // Botões
    btnAnteriorLocalizacao: string = "Anterior";
    btnAvancarLocalizacao: string = "Avançar";

    // Botões
    btnAnteriorResponsavel: string = "Anterior";
    btnAvancarResponsavel: string = "Avançar";

    constructor(
        private router: Router,
        private protocoloService: ProtocoloService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private loaderService: LoaderService) {

        this.protocolo = new Protocolo();
        this.protocolo.cliente = localStorage.getItem("pId");

        this.documentos = new Array<Documento>();

        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        
        this.informativo = Constantes.INFORMATIVO_FIC_EMPRESA_MEI[0];

        this.formFic = this.formBuilder.group({
            controleValidade: ["", [Validators.required]]
        });

        this.processo = new Processo();
    }
    
    ngOnInit(): void {
        this.protocolo.protocolo = this.activatedRoute.snapshot.params["protocolo"];
        this.protocoloService
            .detalhar(localStorage.getItem("pId"), this.protocolo.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                Object.assign(this.protocolo, retorno);
                Object.assign(this.formIdentificacaoNegocio.protocolo, retorno);
                
                this.tipoEmpresa = this.protocolo.processo.tipo == "O" ? Constantes.TIPO_EMPRESA.Empresa_Evento : 
                                    this.protocolo.processo.tipoPessoa == "F" ? Constantes.TIPO_EMPRESA.Autonomo : Constantes.TIPO_EMPRESA.Empresa;

                this.carregarConfiguracoes();
                this.formIdentificacaoNegocio.preencherInscricaoMunicipal();
                this.carregarEndereco();
                this.carregarIdentificacao();
                this.carregarCriticas();
            });
        
    }

    carregarConfiguracoes() {
        setTimeout(() => {
            this.formIdentificacaoNegocio.tipoEmpresa = this.protocolo.processo.tipoPessoa == "M" ? Constantes.TIPO_EMPRESA.Mei : this.tipoEmpresa;
            this.formResponsavel.tipoEmpresa = this.protocolo.processo.tipo == "O" ? Constantes.TIPO_EMPRESA.Empresa_Evento : 
                                                this.protocolo.processo.tipoPessoa == "M" ? Constantes.TIPO_EMPRESA.Mei : 
                                                this.protocolo.processo.tipoPessoa == "F" ? Constantes.TIPO_EMPRESA.Autonomo : Constantes.TIPO_EMPRESA.Empresa;
            this.formResumo.tipoEmpresa = this.tipoEmpresa;
            this.formularioIdentificacao = this.formIdentificacaoNegocio.formulario;
            this.formIdentificacaoNegocio.inicializacaoFormulario();
        }, 1);

        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        this.configuracao.processos
            .forEach(processo => {
                var tipoProcesso = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ? "O" : this.protocolo.registro.tipoPessoa  == Constantes.TIPO_EMPRESA.Mei ? "M" : "S";

                if (processo.codigo === "A" && processo.tipo === tipoProcesso && processo.tipoPessoa == Constantes.TIPO_PESSOA.Juridica) {
                    this.processo.cliente = processo.cliente;
                    this.processo.codigo = processo.codigo;
                    this.processo.descricao = processo.descricao;
                    this.processo.protocolo = processo.protocolo;
                    this.processo.tipo = processo.tipo;
                    this.processo.tipoPessoa = this.protocolo.processo.tipoPessoa;
                    this.protocolo.processo = this.processo;
                    this.servicos = processo.servicos;

                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
                        this.processo.tipo = Constantes.TIPO_EMPRESA.Mei;
                        processo.servicos.forEach(servico => {
                            if (servico.codigo == 1) {
                                this.servicos = [servico];
                            }
                        });
                    }
                } 
            }
        );

    }

    carregarIdentificacao() {
        this.configuracao.processos.forEach( processo => {
            var tipoProcesso = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ? "O" : this.protocolo.registro.tipoPessoa  == Constantes.TIPO_EMPRESA.Mei ? "M" : "S";

            if (processo.codigo == "A" && processo.tipo == tipoProcesso) {
                processo.servicos.forEach( servico => {
                    if (servico.codigo.toString() == this.protocolo.registro.orgao) {
                        servico.naturezaJuridicas.forEach( natureza => {
                            if (natureza.codigo == this.protocolo.registro.naturezaJuridica.codigo) {
                                this.documentos = natureza.documentos;
                                this.formResponsavel.socioRegras = natureza.socioRegras;
                                this.formResponsavel.obrigatorioContador = natureza.obrigatorioContador;
                                if (!natureza.possuiCapitalSocial) {
                                    this.formIdentificacaoNegocio.capitalSocial.disable();
                                }
                            }
                        })
                    }
                })
            }
        });
    }

    carregarEndereco() {
        this.formLocalizacao.exibeFormularioEndereco = !this.protocolo.viabilidade.enderecos.length;
        this.protocolo.viabilidade.enderecos.forEach( endereco => {
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Empresarial) {
                this.formLocalizacao.enderecoEmpresarialBloqueado = true;
                this.formLocalizacao.enderecoEmpresarialBloqueadoEditar = true;
                this.formLocalizacao.preenchimentoInicial= false;
                this.formLocalizacao.preencherEnderecoEmpresarial(endereco);
            }
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Correspondencia) {
                this.formLocalizacao.preencherEnderecoCorrespondencia(endereco);
            }
        })
    }

    carregarCriticas() {
        if (this.protocolo.criticas.length) {
            setTimeout(() => {
                this.preencherCamposIdentificacaoNegocio();
                this.preencherCamposEndereco();
                this.preencherCamposResponsavel();
                this.preencherCamposQuestionario();
            }, 1);
        }
    }

    preencherCamposIdentificacaoNegocio() {
        this.formIdentificacaoNegocio.preencherCampos(this.protocolo);
    }

    preencherCamposEndereco() {
        this.formLocalizacao.preencherCamposCriticas(this.protocolo);
    }

    preencherCamposResponsavel() {
        this.formResponsavel.preencherCampos(this.protocolo);
    }

    preencherCamposQuestionario() {
        this.protocolo.fic.perguntasUsuario.perguntas.forEach( pergunta => {
            this.formQuestionario.perguntasRespondidasOutrosProcessos.push(pergunta);
        })
    }

    verificaValidacaoFormulario(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });

        setTimeout(() => {
            if (formGroup.invalid) {
                Swal.fire("", "Corrigir os erros destacados na página.", "error");
            }
        }, 1);
    }

    renomearBotaoLocalizacao() {
        this.btnAnteriorLocalizacao = "Anterior";
        this.btnAvancarLocalizacao = "Avançar";

        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (!this.formLocalizacao.preenchimentoInicial) {
                this.btnAnteriorLocalizacao = "Cancelar";
                this.btnAvancarLocalizacao = "Confirmar";
            }
        }
    }

    voltarPassoLocalizacao() {
        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (this.formLocalizacao.preenchimentoInicial) {
                this.stepper.previous();
            }
            else {
                this.formLocalizacao.toggle();
            }
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoLocalizacao() {
        this.formularioLocalizacao = this.formLocalizacao.formulario;
        this.verificaValidacaoFormulario(this.formLocalizacao.formulario);
        if (this.formLocalizacao.validarFormulario())
            this.stepper.next();

        // this.formResponsavel.atualizarCriticas();
        
    }

    renomearBotaoResponsavel() {
        this.btnAnteriorResponsavel = "Anterior";
        this.btnAvancarResponsavel = "Avançar";

        if (this.formResponsavel.editarResponsavel) {
            this.btnAnteriorResponsavel = "Cancelar";
            this.btnAvancarResponsavel = "Confirmar";
        }
    }

    voltarPassoResponsavel() {
        if (this.formResponsavel.editarResponsavel) {
            this.formResponsavel.toggle();
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoResponsavel() {
        this.formularioResponsavel = this.formResponsavel.formulario;
        if (this.formResponsavel.validarFormulario())
            this.stepper.next();
        
    }

    avancarPassoQuestionario() {
        this.verificaValidacaoFormulario(this.formQuestionario.formulario);
        if (this.formQuestionario.validarFormulario()) {
            this.formularioQuestionario.get("controleValidade").setValue(true);
            this.stepper.next();
        }
    }
    
    mudancaPasso(event, passos) {
        
        if (this.stepper == null) {
            this.stepper = passos;
        } 
        
        if ( event.selectedIndex == 2) {
            this.formResponsavel.capitalSocialMatriz = this.formIdentificacaoNegocio.capitalSocial.value;
            this.formResponsavel.controleValidade.setValue("");
        }

        if ( event.selectedIndex == 3) {
            this.formQuestionario.formulario.get("controleValidade").setValue("");
            this.formQuestionario.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;

            let pergunta = new Pergunta();
            pergunta.cliente = localStorage.getItem("pId");

            if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Estabelecido ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Nao_Estabelecido ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Fisica ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo) {

                pergunta.tipo = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ? Constantes.TIPO_DE_PERGUNTA.Fic_Evento : 
                                this.formLocalizacao.apenasCorrespondencia.value ? Constantes.TIPO_DE_PERGUNTA.Fic_Autonomo_Nao_Estabelecido : Constantes.TIPO_DE_PERGUNTA.Fic_Autonomo;
            }
            else {

                pergunta.tipo = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ? Constantes.TIPO_DE_PERGUNTA.Fic_Evento : Constantes.TIPO_DE_PERGUNTA.Fic;
            }

            let listaDeAtividade = [];
            this.protocolo.viabilidade.atividadesExercidas.forEach( atividade => listaDeAtividade.push(atividade) );
            this.protocolo.viabilidade.atividadesNaoExercidas.forEach( atividade => listaDeAtividade.push(atividade) );

            pergunta.atividades = listaDeAtividade;

            this.formQuestionario.carregarPerguntas(pergunta);

            this.formularioQuestionario = this.formQuestionario.formulario;
        }
        
        this.informativo = Constantes.INFORMATIVO_FIC_EMPRESA_MEI[event.selectedIndex];

        this.montarFic(event);
    }

    montarFic(event) {
        if ( event.previouslySelectedIndex == 0) {
            this.formIdentificacaoNegocio.preencher();
            this.protocolo = this.formIdentificacaoNegocio.protocolo;
        }
        else if (event.previouslySelectedIndex == 1) {
            this.protocolo.viabilidade.enderecos = [
                this.formLocalizacao.enderecoEmpresarial,
                this.formLocalizacao.enderecoCorrespondencia,
            ];
            this.formResumo.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
        }
        else if (event.previouslySelectedIndex == 2) {
            this.protocolo.fic.socios = this.formResponsavel.socios;
        }
        else if (event.previouslySelectedIndex == 3) {
            this.formQuestionario.preencher();
            this.protocolo.fic.perguntasUsuario = this.formQuestionario.perguntasRespostas;
        }

        this.formResumo.protocolo = this.protocolo;
        this.formResumo.preencher();
    }

    gerarFic() {
        if (!this.formResumo.termoDeAceite.value) {
            Swal.fire("", "Para que a solicitação seja finalizada ,você devera estar de acordo com o termo de responsabilidade.", "info")
            return false;
        }
        
        this.protocoloService
            .gerarFic(this.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    willClose: () => {
                        if (retorno.redirecionamento != null) {
                            this.router.navigate([retorno.redirecionamento]);
                        }
                    }
                })
            });
    }
    

}