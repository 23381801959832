import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { Component, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";

import Swal, { SweetAlertOptions } from "sweetalert2";
import { Endereco } from "src/app/model/endereco";
import { EnderecoRegra } from "src/app/model/enderecoRegra";
import { FormEnderecoComponent } from "../form-endereco/form-endereco.component";
import { EnderecoService } from "src/app/services/endereco/endereco.service";
import { take } from "rxjs/operators";
import { Constantes } from "src/app/model/constantes";
import { SelectionModel } from '@angular/cdk/collections';

import { PesquisaViabilidadeComponent } from "../../pesquisa-viabilidade/pesquisa-viabilidade.component";


@Component({
    selector: "app-form-localizacao-pesquisa",
    templateUrl: "./form-localizacao-pesquisa.component.html",
    styleUrls: ["./form-localizacao-pesquisa.component.scss"]
})
export class FormLocalizacaoPesquisaComponent implements OnInit {

    @Output() renomearBotao = new EventEmitter();

    formulario: FormGroup;
    
    exibeFormularioEndereco: boolean;
    montarRegra: boolean;

    preenchimentoInicial: boolean;

    emitirErro: boolean;
    emitirCritica: boolean;
    mensagemCritica: string;

    @ViewChild(FormEnderecoComponent)
    formEndereco: FormEnderecoComponent;

    pesquisaViabilidade: PesquisaViabilidadeComponent;

    enderecosPrincipais: Array<Endereco>;
    enderecosAlternativos: Array<Endereco>;

    enderecoSelecionado: SelectionModel<Endereco>;
    limitarSelecao: number = 1;

    constructor(
        private enderecoService: EnderecoService,
        private formBuilder: FormBuilder) {

        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
        });

        this.preenchimentoInicial = true;

        this.exibeFormularioEndereco = true;

        this.emitirCritica = false;
        this.mensagemCritica = "";
        this.emitirErro = false;

    }

    ngOnInit(): void {
        this.enderecoSelecionado = new SelectionModel<Endereco>(false);
    }

    novaPesquisa() {
        this.toggle();
        this.formEndereco.liberaBloqueiaCampos();
    }    

    buscarEnderecosAlternativos() {
        this.enderecoService
            .buscarEnderecosAlternativos(this.formEndereco.endereco)
            .pipe(take(1))
            .subscribe( (dados) => {
                if (!dados.length) {
                    this.enderecosAlternativos = new Array<Endereco>();
                    return false;
                }
                this.enderecosPrincipais = dados[0];
                this.enderecosAlternativos = dados[1];
            },
            (err) => {
                console.log('err', err);
            }
        );
    }

    selecionarEndereco(endereco, atividadeInput) {
        if (this.limitarSelecao > 0) {
            this.enderecoSelecionado.toggle(endereco);
            if (this.enderecoSelecionado.selected.length > this.limitarSelecao) {
                this.enderecoSelecionado.deselect(endereco);
                if (atividadeInput != null) atividadeInput.checked = false;
                Swal.fire("", `Não foi possível adicionar porque o limite máximo é de até ${ this.limitarSelecao } atividades.`, "error");
            }
        }
        else {
            this.enderecoSelecionado.toggle(endereco);
        }
    }

    checkboxLabel(endereco?: Endereco): string {
        return `${this.enderecoSelecionado.isSelected(endereco) ? 'deselect' : 'select'} endereco ${endereco.inscricao + 1}`;
    }

    toggle() {
        this.emitirErro = false;
        this.exibeFormularioEndereco = !this.exibeFormularioEndereco;
        this.renomearBotao.emit({ renomear: true});
    }

    validarFormulario() {
        let mensagemCritica = "";
        this.formEndereco.emitirErro = false;
        this.emitirErro = false;

        this.controleValidade.setValue("");
        // Está no momento de preenchimento do formulario de endereço
        if (this.exibeFormularioEndereco) {
            // Se o formulário de endereço estiver válido, salva o endereço escolhido.
            if(this.formEndereco.formulario.valid) {
                this.formEndereco.preencherEndereco();
                this.buscarEnderecosAlternativos();
                this.toggle();
                this.preenchimentoInicial = false;
            }
            else {
                this.formEndereco.verificaValidacaoFormulario(this.formEndereco.formulario);
                this.formEndereco.emitirErro = true;
                Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");
            }
        }
        else {   
            //Verificar se foi escolhido algum endereço.
            if (this.enderecoSelecionado.isEmpty()) {
                mensagemCritica += "Favor escolher um dos endereços listados para realizar a consulta.<br>"
                this.emitirCritica = true;
                this.formEndereco.emitirErro = true;
                Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");
            }
            else {
                return true;
            }            
        }
        this.mensagemCritica = mensagemCritica;
        return false;
    }

    get controleValidade() {
        return this.formulario.get("controleValidade");
    }
    set controleValidade(controleValidade) {
        this.formulario.get("controleValidade").setValue(controleValidade);
    }

    

}