import { DocumentoEmpresaProtocolo } from './documentoEmpresaProtocolo';
import { RedeSimEvento } from './redeSimEvento';
import { RedeSimPergunta } from './redeSimPergunta';

export class RedeSim {
    cliente: string;
    protocolo: string;
    protocoloRedeSim: string;
    requerenteNome: string;
    requerenteCpf: string;
    email: string;
    cnpjDoNegocio: string;
    codigoEvento: string;
    descricaoEvento: string;
    simplesNacional: boolean;
    dataInclusaoSimplesNacional: string;
    dataExclusaoSimplesNacional: string;
    simei: boolean;
    dataInclusaoSimei: string;
    dataExclusaoSimei: string;
    empresasMesmoCnpj: Array<DocumentoEmpresaProtocolo>;
    redeSimEventos: Array<RedeSimEvento>;
    possuiEstabelecimento: boolean;
    codigoSituacaoCadastral: string;
    descricaoSituacaoCadastral: string;
    redeSimPerguntas: Array<RedeSimPergunta>;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.protocoloRedeSim = "";
        this.requerenteNome = "";
        this.requerenteCpf = "";
        this.email = "";
        this.cnpjDoNegocio = "";
        this.codigoEvento = "";
        this.descricaoEvento = "";
        this.simplesNacional = false;
        this.dataInclusaoSimplesNacional = "";
        this.dataExclusaoSimplesNacional = "";
        this.simei = false;
        this.dataInclusaoSimei = "";
        this.dataExclusaoSimei = "";
        this.empresasMesmoCnpj = new Array<DocumentoEmpresaProtocolo>();
        this.redeSimEventos = new Array<RedeSimEvento>();
        this.possuiEstabelecimento = false;
        this.codigoSituacaoCadastral = "";
        this.descricaoSituacaoCadastral = "";
        this.redeSimPerguntas = new Array<RedeSimPergunta>();
    }
}