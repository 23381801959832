import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { TaxaService } from '../../../../services/taxa/taxa.service';
import { TaxaLancamento } from '../../../../model/taxaLancamento';
import { TaxaLancamentoParcela } from '../../../../model/taxaLancamentoParcela';
import { Constantes } from '../../../../model/constantes';
import { MascaraPipe } from '../../../../pipes/mascara.pipe';
import { AtendimentoService } from '../../../../services/operacao/atendimento.service';
import { Grid } from '../../../../model/grid';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { GridOpcao } from '../../../../model/gridOpcao';
import Swal from 'sweetalert2';
import { ConsultaGeral } from '../../../../model/consultaGeral';
import { AtendimentoGridConsultaComponent } from '../../atendimento/atendimento-grid-consulta/atendimento-grid-consulta.component';
import { GridFiltro } from 'src/app/model/gridFiltro';
import { ContaCorrenteComponent } from '../conta-corrente/conta-corrente.component';
import { LancamentoManualComponent } from '../lancamento-manual/lancamento-manual.component';
import { AcordoComponent } from '../acordo/acordo.component';
import { BaixaManualComponent } from '../baixa-manual/baixa-manual.component';
import { CancelamentoComponent } from '../cancelamento/cancelamento.component';
import { RevisaoComponent } from '../revisao/revisao.component';
import { ViewportScroller } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';


@Component({
    selector: "app-operacao-taxa",
    templateUrl: "./operacao-taxa.component.html",
    styleUrls: ["./../gerenciamento-taxa.component.scss"]
})
export class OperacaoTaxaComponent implements OnInit {
    
    formulario: FormGroup;
    grid: Grid;
    filtroOpcoes: Array<GridOpcao>;

    emitirErro: boolean;
    mensagemErro: string;

    consultaGeral: ConsultaGeral;
    taxaLancamentos: Array<TaxaLancamento>;
    taxaLancamentoParcelas: Array<TaxaLancamentoParcela>;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

    tipoDeDocumento = Constantes.TIPO_DE_DOCUMENTO;

    exibirLista: boolean = false;
    exibirLancamentos: boolean = false;
    exibirLancamentoManual: boolean = false;
    exibirAcordo: boolean = false;
    exibirCancelamento: boolean = false;
    exibirBaixa: boolean = false;
    exibirRevisao: boolean = false;

    listarLancamentosLiberado: boolean = false;
    novoLancamentoLiberado: boolean = false;
    novoAcordoLiberado: boolean = false;
    cancelarLiberado: boolean = false;
    baixarLiberado: boolean = false;
    revisarLiberado: boolean = false;

    numeroInscricao: string = "";
    numeroProtocolo: string = "";
    
    @ViewChild(AtendimentoGridConsultaComponent)
    atendimentoGridConsulta: AtendimentoGridConsultaComponent;
    
    @ViewChild(ContaCorrenteComponent)
    contaCorrente: ContaCorrenteComponent;
    
    @ViewChild(LancamentoManualComponent)
    lancamentoManual: LancamentoManualComponent;
    
    @ViewChild(AcordoComponent)
    acordo: AcordoComponent;
    
    @ViewChild(CancelamentoComponent)
    cancelamentoManual: CancelamentoComponent;
    
    @ViewChild(BaixaManualComponent)
    baixaManual: BaixaManualComponent;
    
    @ViewChild(RevisaoComponent)
    revisaoManual: RevisaoComponent;

    constructor(
        private activatedRoute: ActivatedRoute,
        private atendimentoService: AtendimentoService,
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private mascara: MascaraPipe,
        private taxaService: TaxaService,
        private viewportScroller: ViewportScroller) {

        this.consultaGeral = new ConsultaGeral();
        
        this.grid = new Grid();
        this.grid.filtro.push(new GridFiltro());
            
        this.viewportScroller.setOffset([0,344]);
            
        this.formulario = this.formBuilder.group({
            tipoBusca: [''],
            exercicio: [''],
            tipoBuscaValorInput: [''],
            tipoBuscaValorSelect: [''],
        });

        authenticationService.currentAxisValue.item.forEach(menu => {
          if (menu.nome == "moduloTaxa") {
            menu.item.forEach(subMenu => {
              if (subMenu.nome == "operacaoIndividual") {
                subMenu.item.forEach(funcionalidade => {
                  if (funcionalidade.nome == "lancamento") {
                    this.listarLancamentosLiberado = true;
                  }
                  if (funcionalidade.nome == "lancamentoAvulso") {
                    this.novoLancamentoLiberado = true;
                  }
                  if (funcionalidade.nome == "acordo") {
                    this.novoAcordoLiberado = true;
                  }
                  if (funcionalidade.nome == "cancelamento") {
                    this.cancelarLiberado = true;
                  }
                  if (funcionalidade.nome == "baixa") {
                    this.baixarLiberado = true;
                  }
                  if (funcionalidade.nome == "revisao") {
                    this.revisarLiberado = true;
                  }
                });
              }
            });
          }
        });
    }

    ngOnInit(): void {      
      this.buscarGridConfiguracaoFiltro();
    }

    goToAnchor(ancora) {
      this.viewportScroller.scrollToAnchor(ancora);
    }

    acaoAtendimento(event) {
      this.exibirLista = false;
      this.exibirLancamentos = false;
      this.exibirLancamentoManual = false;
      this.exibirAcordo = false;
      this.exibirCancelamento = false;
      this.exibirBaixa = false;
      this.exibirRevisao = false;

      if (event.acao != null && 
        (
          event.acao == Constantes.ACAO_ATENDIMENTO.Cancelar ||
          event.acao == Constantes.ACAO_ATENDIMENTO.Atualizar_Lancamentos_Taxa
        )) {
        this.lancamentoManual.limpar();
        this.acordo.limpar();
        this.cancelamentoManual.limpar();
        this.baixaManual.limpar();
        this.revisaoManual.limpar();
        
        if (this.listarLancamentosLiberado) {
          this.exibirLancamentos = true;
        }
        else if (this.novoLancamentoLiberado) {
          this.exibirLancamentoManual = true;
        }
        else if (this.novoAcordoLiberado) {
          this.exibirAcordo = true;
        }
        else if (this.cancelarLiberado) {
          this.exibirCancelamento = true;
        }
        else if (this.baixarLiberado) {
          this.exibirBaixa = true;
        }
        else if (this.revisarLiberado) {
          this.exibirRevisao = true;
        }

        if (event.acao == Constantes.ACAO_ATENDIMENTO.Atualizar_Lancamentos_Taxa) {
          this.taxaLancamentos = event.taxaLancamentos;
          this.preencherLancamentos();
        }
      }
      else if (event.acao != null && 
        (
          event.acao == Constantes.ACAO_ATENDIMENTO.Atualizar_Lancamentos_Pos_Baixa ||
          event.acao == Constantes.ACAO_ATENDIMENTO.Atualizar_Lancamentos_Pos_Cancelamento ||
          event.acao == Constantes.ACAO_ATENDIMENTO.Atualizar_Lancamentos_Pos_Revisao
        )) {
        this.lancamentoManual.limpar();
        this.acordo.limpar();
        this.cancelamentoManual.limpar();
        this.baixaManual.limpar();
        this.revisaoManual.limpar();
        this.buscarGridPorFiltro();
      }
      else {        
        if (this.listarLancamentosLiberado) {
          this.exibirLancamentos = true;
        }
        else if (this.novoLancamentoLiberado) {
          this.exibirLancamentoManual = true;
        }
        else if (this.novoAcordoLiberado) {
          this.exibirAcordo = true;
        }
        else if (this.cancelarLiberado) {
          this.exibirCancelamento = true;
        }
        else if (this.baixarLiberado) {
          this.exibirBaixa = true;
        }
        else if (this.revisarLiberado) {
          this.exibirRevisao = true;
        }

        this.numeroInscricao = event.inscricao != null ? event.inscricao : "";
        this.numeroProtocolo = event.protocolo != null ? event.protocolo : "";

        this.preencherLancamentos();

        this.contaCorrente.numeroInscricao = event.inscricao != null ? event.inscricao : "";
        this.contaCorrente.numeroProtocolo = event.protocolo != null ? event.protocolo : "";
  
        this.contaCorrente.buscarTaxaLancamentoParcelas();

        
      }
    }
    
    acaoSelectTipoBusca() {
      this.limpar();

      this.tipoBuscaValorInput.setValue("");
      this.tipoBuscaValorSelect.setValue("");
      
      if (this.grid.filtro[this.tipoBusca.value] != null && this.grid.filtro[this.tipoBusca.value].tipoCampo == "SELECT") {
        of(this.grid.filtro[this.tipoBusca.value].opcoes).subscribe(opcoes => {
            this.filtroOpcoes = opcoes;
        });
      }
    }
    
    buscarGridConfiguracaoFiltro() {
      this.atendimentoService
        .buscarGridConfiguracaoFiltro(this.activatedRoute.snapshot.params["codigoOrgao"], this.activatedRoute.snapshot.params["codigoMenu"])
        .pipe(take(1))
        .subscribe(retorno => {
            this.grid = retorno;
        })
    }

    buscarGridPorFiltro() {
      this.limpar();

      let valor = this.grid.filtro[this.tipoBusca.value].tipoCampo == "INPUT" ? this.tipoBuscaValorInput.value : this.tipoBuscaValorSelect.value
      
      if (this.grid.filtro[this.tipoBusca.value].tipoCampo == "") {
          valor = "TODOS";
      }

      if (valor == "") {
          Swal.fire("", "Favor informar um valor para busca.", "error")
          return false;
      }

      var valorFormatado = valor.replace(/[/]/g,"");

      if (this.tipoBusca.value == 9 || this.tipoBusca.value == 12 || this.tipoBusca.value == 1 || this.tipoBusca.value == 11) {
        valorFormatado = valor.replace(/\D/g,"");
      }
      
      this.taxaService
          .buscarPorFiltro(this.grid.filtro[this.tipoBusca.value].codigo, valorFormatado)
          .pipe(take(1))
          .subscribe(retorno => {
              Object.assign(this.consultaGeral, retorno);
              this.preencherDados();
          });
    }
  
    preencherDados() {
      this.exibirLista = false;
      this.exibirLancamentos = false;
      this.exibirLancamentoManual = false;
      this.exibirAcordo = false;
      this.exibirCancelamento = false;
      this.exibirBaixa = false;
      this.exibirRevisao = false;
      this.emitirErro = false;

      if (this.consultaGeral.protocolos.length || this.consultaGeral.empresas.length == 1) {        
        if (this.listarLancamentosLiberado) {
          this.exibirLancamentos = true;
        }
        else if (this.novoLancamentoLiberado) {
          this.exibirLancamentoManual = true;
        }
        else if (this.novoAcordoLiberado) {
          this.exibirAcordo = true;
        }
        else if (this.cancelarLiberado) {
          this.exibirCancelamento = true;
        }
        else if (this.baixarLiberado) {
          this.exibirBaixa = true;
        }
        else if (this.revisarLiberado) {
          this.exibirRevisao = true;
        }
        this.taxaLancamentos = this.consultaGeral.taxaLancamentos;
        this.preencherLancamentos();
      }
      else if (this.consultaGeral.empresasAcompanhamento.length) {
        if (this.consultaGeral.empresasAcompanhamento.length > 1) {
          this.exibirLista = true;
          this.preencherGridEmpresas();
        }
      }
      else {
        this.emitirErro = true;

        if (this.consultaGeral.qtdTotal == "0") {
          this.mensagemErro = "Não conseguimos localizar dados em nossa base!"
        }
        else {
          this.mensagemErro = "<b>ATENÇÃO</b> - " + this.consultaGeral.mensagem;
        }
      }
    }

    preencherLancamentos() {
      if (this.consultaGeral.protocolos.length) {
        this.numeroInscricao = this.consultaGeral.protocolos[0].inscricao;
        this.numeroProtocolo = this.consultaGeral.protocolos[0].protocolo;

      }
      else if (this.consultaGeral.empresas.length) {
        this.numeroInscricao = this.consultaGeral.empresas[0].inscricao;
        this.numeroProtocolo = this.consultaGeral.empresas[0].protocolo;
      }

      this.contaCorrente.numeroProtocolo = this.numeroProtocolo;
      this.contaCorrente.numeroInscricao = this.numeroInscricao;

      this.lancamentoManual.protocolo.setValue(this.numeroProtocolo);
      this.lancamentoManual.inscricao.setValue(this.numeroInscricao);

      this.acordo.protocolo.setValue(this.numeroProtocolo);
      this.acordo.inscricao.setValue(this.numeroInscricao);

      this.cancelamentoManual.protocolo.setValue(this.numeroProtocolo);
      this.cancelamentoManual.inscricao.setValue(this.numeroInscricao);

      this.baixaManual.protocolo.setValue(this.numeroProtocolo);
      this.baixaManual.inscricao.setValue(this.numeroInscricao);

      this.revisaoManual.protocolo.setValue(this.numeroProtocolo);
      this.revisaoManual.inscricao.setValue(this.numeroInscricao);

      this.contaCorrente.exercicio = "";
      this.contaCorrente.taxaLancamentos = this.taxaLancamentos;

      var taxaLancamentoParcelas = new Array<TaxaLancamentoParcela>();
      this.taxaLancamentos.forEach( taxaLancamento => {
        taxaLancamentoParcelas = taxaLancamentoParcelas.concat(taxaLancamento.taxaLancamentoParcelas);
      });
      
      this.contaCorrente.carregarParcelas();
    }

    preencherGridEmpresas() {
      this.atendimentoGridConsulta.moduloTaxa = true;
      this.atendimentoGridConsulta.grid.dados = this.consultaGeral.empresasAcompanhamento;
      this.atendimentoGridConsulta.atualizarTabela();
    }

    limpar() {
      this.exibirLista = false;
      this.exibirLancamentos = false;
      this.exibirLancamentoManual = false;
      this.exibirAcordo = false;
      this.exibirCancelamento = false;
      this.exibirBaixa = false;
      this.exibirRevisao = false;

      this.consultaGeral = new ConsultaGeral();
      this.taxaLancamentos = new Array<TaxaLancamento>();
      this.taxaLancamentoParcelas = new Array<TaxaLancamentoParcela>();
      this.contaCorrente.limpar();
      this.lancamentoManual.limpar();
      this.acordo.limpar();
      this.cancelamentoManual.limpar();
      this.baixaManual.limpar();
      this.revisaoManual.limpar();
    }

    listarLancamentos() {
      if (!this.listarLancamentosLiberado) {
        Swal.fire("", "Caro Auditor, está funcionalidade não está liberada para o seu usuário.", "error");
        return false;
      }
      
      this.goToAnchor("divDetalhes");
      this.exibirLista = false;
      this.exibirLancamentos = true;
      this.exibirLancamentoManual = false;
      this.exibirAcordo = false;
      this.exibirCancelamento = false;
      this.exibirBaixa = false;
      this.exibirRevisao = false;
    }

    novoLancamento() {
      if (!this.novoLancamentoLiberado) {
        Swal.fire("", "Caro Auditor, está funcionalidade não está liberada para o seu usuário.", "error");
        return false;
      }
      
      this.lancamentoManual.protocolo.setValue(this.numeroProtocolo);
      this.lancamentoManual.inscricao.setValue(this.numeroInscricao);

      this.goToAnchor("divDetalhes");
      this.exibirLista = false;
      this.exibirLancamentos = false;
      this.exibirLancamentoManual = true;
      this.exibirAcordo = false;
      this.exibirCancelamento = false;
      this.exibirBaixa = false;
      this.exibirRevisao = false;
    }

    novoAcordo() {
      if (!this.novoAcordoLiberado) {
        Swal.fire("", "Caro Auditor, está funcionalidade não está liberada para o seu usuário.", "error");
        return false;
      }
      
      this.goToAnchor("divDetalhes");
      var taxaLancamentoParcelasSelecionadas = new Array<TaxaLancamentoParcela>();
      this.contaCorrente.gridsParcelas.forEach( gridParcelas => {
        if (gridParcelas.linhaSelecionada.hasValue()) {
          taxaLancamentoParcelasSelecionadas = taxaLancamentoParcelasSelecionadas.concat(gridParcelas.linhaSelecionada.selected);
        }
      })
      
      if (!taxaLancamentoParcelasSelecionadas.length) {
        Swal.fire("", "Caro auditor, para seguir com o processo você deverá selecionar uma ou mais parcelas.", "error");
        return false;
      }

      this.acordo.protocolo.setValue(this.numeroProtocolo);
      this.acordo.inscricao.setValue(this.numeroInscricao);

      this.exibirLista = false;
      this.exibirLancamentos = false;
      this.exibirLancamentoManual = false;
      this.exibirAcordo = true;
      this.exibirCancelamento = false;
      this.exibirBaixa = false;
      this.exibirRevisao = false;
    }

    cancelar() {
      if (!this.cancelarLiberado) {
        Swal.fire("", "Caro Auditor, está funcionalidade não está liberada para o seu usuário.", "error");
        return false;
      }
      
      this.goToAnchor("divDetalhes");
      this.exibirLista = false;
      this.exibirLancamentos = false;
      this.exibirLancamentoManual = false;
      this.exibirAcordo = false;
      this.exibirCancelamento = true;
      this.exibirBaixa = false;
      this.exibirRevisao = false;

      this.cancelamentoManual.protocolo.setValue(this.numeroProtocolo);
      this.cancelamentoManual.inscricao.setValue(this.numeroInscricao);

      this.cancelamentoManual.buscarTaxaLancamentos();
        
    }

    baixar() {
      if (!this.baixarLiberado) {
        Swal.fire("", "Caro Auditor, está funcionalidade não está liberada para o seu usuário.", "error");
        return false;
      }
      
      this.goToAnchor("divDetalhes");
      var parcelaUnica = false;
      var taxaLancamentoParcelasSelecionadas = new Array<TaxaLancamentoParcela>();

      this.contaCorrente.gridsParcelas.forEach( gridParcela => {
        if (gridParcela.linhaSelecionadaParcelaUnica.hasValue()) {
          parcelaUnica = true;
          taxaLancamentoParcelasSelecionadas = gridParcela.linhaSelecionadaParcelaUnica.selected;
        }
        if (gridParcela.linhaSelecionada.hasValue()) {
          taxaLancamentoParcelasSelecionadas = taxaLancamentoParcelasSelecionadas.concat(gridParcela.linhaSelecionada.selected);
        }
      });
      
      if (!taxaLancamentoParcelasSelecionadas.length) {
        Swal.fire("", "Caro auditor, para seguir com o processo você deverá selecionar uma ou mais parcelas.", "error");
        return false;
      }

      this.baixaManual.protocolo.setValue(this.numeroProtocolo);
      this.baixaManual.inscricao.setValue(this.numeroInscricao);

      this.baixaManual.preencherParaAtender(this.taxaLancamentos, taxaLancamentoParcelasSelecionadas, parcelaUnica);

      this.exibirLista = false;
      this.exibirLancamentos = false;
      this.exibirLancamentoManual = false;
      this.exibirAcordo = false;
      this.exibirCancelamento = false;
      this.exibirBaixa = true;
      this.exibirRevisao = false;
        
    }

    revisar() {
      if (!this.revisarLiberado) {
        Swal.fire("", "Caro Auditor, está funcionalidade não está liberada para o seu usuário.", "error");
        return false;
      }

      this.revisaoManual.protocolo.setValue(this.numeroProtocolo);
      this.revisaoManual.inscricao.setValue(this.numeroInscricao);

      this.goToAnchor("divDetalhes");
      this.exibirLista = false;
      this.exibirLancamentos = false;
      this.exibirLancamentoManual = false;
      this.exibirAcordo = false;
      this.exibirCancelamento = false;
      this.exibirBaixa = false;
      this.exibirRevisao = true;
        
    }

    get tipoBusca() {
      return this.formulario.get("tipoBusca");
    }  
    set tipoBusca(tipoBusca) {
      this.formulario.get("tipoBusca").setValue(tipoBusca);
    }
  
    get exercicio() {
      return this.formulario.get("exercicio");
    }  
    set exercicio(exercicio) {
      this.formulario.get("exercicio").setValue(exercicio);
    }
  
    get tipoBuscaValorInput() {
      return this.formulario.get("tipoBuscaValorInput");
    }  
    set tipoBuscaValorInput(tipoBuscaValorInput) {
      this.formulario.get("tipoBuscaValorInput").setValue(tipoBuscaValorInput);
    }
  
    get tipoBuscaValorSelect() {
      return this.formulario.get("tipoBuscaValorSelect");
    }  
    set tipoBuscaValorSelect(tipoBuscaValorSelect) {
      this.formulario.get("tipoBuscaValorSelect").setValue(tipoBuscaValorSelect);
    }


}
