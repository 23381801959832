import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class OrgaoService {

    constructor(private http: HttpClient, private router: Router) {}

    salvar(orgao) {
        return this.http.post<any>( `${ environment.icadUrl }/orgao/salvar`, JSON.stringify(orgao),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarPorCodigo(codigo) {
        return this.http.get<any>( `${ environment.icadUrl }/orgao/buscarPorCodigo/${ codigo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarPorNome(nome) {
        return this.http.get<any>( `${ environment.icadUrl }/orgao/buscarPorNome/${ nome }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarOrgaosParaConfiguracao() {
        return this.http.get<any>( `${ environment.icadUrl }/orgao/buscarOrgaosParaConfiguracao/${ localStorage.getItem("pId") }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarTodosOrgaosParaConfiguracao() {
        return this.http.get<any>( `${ environment.icadUrl }/orgao/buscarTodosOrgaosParaConfiguracao`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }
          
}