import { GridOpcao } from "./gridOpcao";

export class GridFiltro {
    codigo: string;
    display: string;
    campo: string;
    tipoCampo: string;
    tipoDados: string;
    mascara: string;
    inicial: string;
    valorPadrao: string;
    opcoes: Array<GridOpcao>;
    criacao: string;
    mensagem: string;

    constructor() {
        this.codigo = "";
        this.display = "";
        this.campo = "";
        this.tipoCampo = "";
        this.tipoDados = "";
        this.mascara = "";
        this.inicial = "";
        this.valorPadrao = "";
        this.opcoes = new Array<GridOpcao>();
        this.criacao = "";
        this.mensagem = "";
    }
}