import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ParecerService } from '../../../../services/parecer/parecer.service';
import { Parecer } from '../../../../model/parecer';

@Component({
  selector: 'app-atendimento-pareceres',
  templateUrl: './atendimento-pareceres.component.html',
  styleUrls: ['../atendimento-protocolo/atendimento-protocolo.component.scss']
})
export class AtendimentoPareceresComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};
  @Output() selecao: EventEmitter<any> = new EventEmitter<any>()

  @Input() dados: Array<any>;
  pareceres: Array<Parecer>;
  formulario: FormGroup;

  textoInformativo: string;

  constructor(
    private parecerService: ParecerService,
    private formBuilder: FormBuilder) {

    this.pareceres = new Array<Parecer>();

    this.formulario = this.formBuilder.group({
      parecerFinal: ["", [Validators.maxLength(2000)]],
    });

    this.textoInformativo = `
      <strong>
          Caro Auditor, logo abaixo será exibida uma lista de pareceres pré-configurados para adicionar ao seu parecer final caso seja necessário.<br>
          Lembrando que, ao clicar no botão de adicionar a mensagem será adicionada ao que já existir no quadro "Parecer Final".<br>
          Por gentileza confirmar seu parecer no quadro "Parecer Final" e clicar no botão "Concluir Parecer" para finalizar o atendimento.<br>
      </strong>
    `
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.montarParecer();
    }, 10);
  }

  montarParecer() {
    if (this.dados == null) {
      return false;
    }

    if (this.dados[5] != null) {
      this.parecerFinal.setValue(this.dados[5]);
    }

    if (this.dados[6] != null) {
      this.textoInformativo = this.dados[6];
    }

    this.pareceres = new Array<Parecer>();

    if (this.dados[0] == "fase") {
      this.parecerService
        .buscarPareceresPorOrgaoEFaseEAvaliacao(
          this.dados[1], 
          this.dados[2], 
          this.dados[3], 
          this.dados[4])
        .pipe(take(1))
        .subscribe(retorno => {
          this.preencher(retorno);
        });
    }
    else {
      this.parecerService
        .buscarPareceresPorOrgaoERequisitoEAvaliacao(
          this.dados[1], 
          this.dados[2], 
          this.dados[3], 
          this.dados[4])
        .pipe(take(1))
        .subscribe(retorno => {
          this.preencher(retorno);
        });
    }
  }

  preencher(pareceres: Array<Parecer>) {
    pareceres.forEach(parecer => {
      var parecerAuxiliar = new Parecer();

      parecerAuxiliar.codigo = parecer.codigo;
      parecerAuxiliar.codigoOrgao = parecer.codigoOrgao;
      parecerAuxiliar.codigoRequisito = parecer.codigoRequisito;
      parecerAuxiliar.fase = parecer.fase;
      parecerAuxiliar.avaliacao = parecer.avaliacao;
      parecerAuxiliar.status = parecer.status;
      parecerAuxiliar.parecer = parecer.parecer;

      this.pareceres.push(parecerAuxiliar);
    });
    
  }

  adicionarParecer(parecer) {
    this.parecerFinal.setValue(`${ this.parecerFinal.value }${ this.parecerFinal.value != "" ? "\n" : "" }${ parecer }`)
  }

  acaoBotaoConcluir() {
      this.selecao.emit({ parecerFinal: this.parecerFinal.value });
  }

  get parecerFinal() {
    return this.formulario.get("parecerFinal");
  }
  set parecerFinal(parecerFinal) {
    this.formulario.get("parecerFinal").setValue(parecerFinal);
  }

}
