import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class EnumService {

    constructor(private http: HttpClient, private router: Router) {}

    tipoDeLogradouro() {
        return this.http.get<any>(
        `${ environment.icadUrl }/enum/tipoDeLogradouro`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }
}
