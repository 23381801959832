import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from '../../../components/alert/alert.module';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LinhaDoTempoComponent } from './linha-do-tempo.component';
import { CicloDeVidaComponent } from './ciclo-de-vida.component';
import { PipeModule } from '../../../pipes/pipes.module';


@NgModule({
  declarations: [
    LinhaDoTempoComponent,
    CicloDeVidaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    NgxMaskModule.forRoot(),
    PerfectScrollbarModule,
    PipeModule
  ],
  exports: [
    LinhaDoTempoComponent,
    CicloDeVidaComponent
  ],
})
export class LinhaDoTempoModule {}
