<div class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Questionário {{ titulo }}</strong>
        </div>
    </div>
    <div *ngIf="perguntasUsuario == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="perguntasUsuario != null"  class="card-body">
        <div *ngFor="let pergunta of perguntasUsuario.perguntas"
            class="form-row row-linha linha-grid-divisao">
            <div class="col-12">
                <p class="card-text titulo" [id]="'lbl-' + pergunta.codigo + '-pergunta-questionario'">
                    <strong>{{ pergunta.nome }}</strong>
                </p>
                <p class="card-text observacao" [id]="'lbl-' + pergunta.codigo + '-resposta-questionario'">
                    R.: {{ pergunta.respostas[0].nome }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha " *ngIf="perguntasUsuario.perguntas.length == 0">
            <div class="col-12">
                <p class="card-text text-center">
                    Não existem perguntas para esse processo.
                </p>
            </div>
        </div>
    </div>
</div>