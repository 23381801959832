<li
  class="nav-item dropdown notification-dropdown"
  [class.show]="showNotificationDropdown"
>
  <div ngbDropdown class="d-inline-block">
    <a
      href="javascript:void(0);"
      class="nav-link dropdown-toggle"
      id="notificationDropdown"
      title="Alertas"
      ngbDropdownToggle
    >
      <svg class="icone-header">
        <use
          class=""
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#sino_alerta"
        ></use>
      </svg><span class="badge badge-success" *ngIf="mensagerias != null && mensagerias.length"></span>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <div class="notification-scroll" *ngFor="let mensageria of mensagerias; let i = index">
        <div class="dropdown-item cursor-pointer"
          (click)="exibirMensagem(mensageria)"
        >
          <div class="media">
            <svg class="logo">
              <use
                class="color-envelope"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#mail_envelope"
              ></use>
            </svg>
            <div class="media-body">
              <div class="row justify-content-start align-items-center notification-para">
                <p class="card-text">
                  <b>De:</b> {{ mensageria.tipoDescricao }}<br>
                  <b>Assunto:</b> {{ mensageria.assunto }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dropdown-item cursor-pointer" *ngIf="mensagerias != null && !mensagerias.length">
          <div class="media">
            <div class="media-body">
              <div class="row justify-content-center align-items-center notification-para">
                <p class="card-text text-center">
                  Nenhuma nova mensagem.
                </p>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</li>
