import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { TaxaService } from 'src/app/services/taxa/taxa.service';
import { Constantes } from 'src/app/model/constantes';
import { MascaraPipe } from 'src/app/pipes/mascara.pipe';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CentroCusto } from 'src/app/model/centroCusto';
import { CalendarioFiscal } from 'src/app/model/calendarioFiscal';
import { CalendarioFiscalData } from 'src/app/model/calendarioFiscalData';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';


@Component({
    selector: "app-calendario-fiscal-lote",
    templateUrl: "./calendario-fiscal-lote.component.html",
    styleUrls: ["./../gerenciamento-taxa.component.scss"]
})
export class CalendarioFiscalLoteComponent implements OnInit {
    
    @Output() acaoMenuAtendimento = new EventEmitter();

    formulario: FormGroup;

    emitirErro: boolean;
    mensagemErro: string;

    centrosDeCusto: CentroCusto;
    calendariosFiscaisAtivos: Array<CalendarioFiscal>;
    calendarioFiscalAtual: CalendarioFiscal;
    calendarioFiscalAtualParcelaUnica: CalendarioFiscal;
    calendarioFiscalRevisado: CalendarioFiscal;
    centrosDeCustoAuxiliar: CentroCusto;
    calendarioFiscalAuxiliar: CalendarioFiscal;
    
    alterarCalendarioFiscalLiberado: boolean = false;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

    acaoAtendimentoDescricao = Constantes.ACAO_ATENDIMENTO_DESCRICAO;
    acaoAtendimentoClass = Constantes.ACAO_ATENDIMENTO_CLASS;

    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private mascara: MascaraPipe,
        private taxaService: TaxaService) {

        this.calendarioFiscalAtual = new CalendarioFiscal();
        this.calendarioFiscalRevisado = new CalendarioFiscal();

        this.formulario = this.formBuilder.group({
            cliente: [""],
            codigo: [""],            
            protocolo: ["", [Validators.required]],
            inscricao: ["", [Validators.required]],
            codigoCentroDeCusto: [''],
            codigoCalendarioFiscal: [''],
        });

        authenticationService.currentAxisValue.item.forEach(menu => {
          if (menu.nome == "moduloTaxa") {
            menu.item.forEach(subMenu => {
              if (subMenu.nome == "operacaoLote") {
                subMenu.item.forEach(funcionalidade => {
                  if (funcionalidade.nome == "alterarCalendarioFiscal") {
                    this.alterarCalendarioFiscalLiberado = true;
                  }
                });
              }
            });
          }
        });
            
    }

    ngOnInit(): void {
        this.carregarCentrosDeCusto()
    }
     
    carregarCentrosDeCusto() {
        this.taxaService
        .buscarTaxaCentroDeCustoAtivos()
        .pipe(take(1))
        .subscribe( retorno => {
            this.centrosDeCusto = retorno;
            this.carregarCalendariosFiscais();
        })
    }
     
    carregarCalendariosFiscais() {
        this.taxaService
        .buscarTaxaCalendariosFiscaisAtivos()
        .pipe(take(1))
        .subscribe( retorno => {
            this.calendariosFiscaisAtivos = retorno;
        })
    }

    acaoSelectCalendarioFiscal() {
        this.calendariosFiscaisAtivos
            .filter( calendarioFiscal => calendarioFiscal.codigo == this.codigoCalendarioFiscal.value )
            .forEach( calendarioFiscalEncontrado => {
                this.calendarioFiscalRevisado = new CalendarioFiscal();
                calendarioFiscalEncontrado.calendarioFiscalDatas.forEach( calendarioFiscalData => {
                    var calendarioFiscalDataAuxiliar = new CalendarioFiscalData();
                    calendarioFiscalDataAuxiliar.cliente                = calendarioFiscalData.cliente;
                    calendarioFiscalDataAuxiliar.codigo                 = calendarioFiscalData.codigo;
                    calendarioFiscalDataAuxiliar.codigoCalendarioFiscal = calendarioFiscalData.codigoCalendarioFiscal;
                    calendarioFiscalDataAuxiliar.numero                 = calendarioFiscalData.numero;
                    calendarioFiscalDataAuxiliar.data                   = calendarioFiscalData.data;
                    calendarioFiscalDataAuxiliar.loginCadastro          = calendarioFiscalData.loginCadastro;
                    calendarioFiscalDataAuxiliar.adicionado             = calendarioFiscalData.adicionado;
                    this.calendarioFiscalRevisado.calendarioFiscalDatas.push(calendarioFiscalDataAuxiliar);
                });
            })
    }

    buscarCalendarioFiscalPorCentroDeCusto() {
        if (!this.alterarCalendarioFiscalLiberado) {
          Swal.fire("", "Caro Auditor, está funcionalidade não está liberada para o seu usuário.", "error");
          return false;
        }

        this.limpar();

        if (this.codigoCentroDeCusto.value == "") {
            Swal.fire("", "Para localizar o calendário fiscal da taxas processadas, selecionar um centro de custo disponível.", "error");
            return false;
        }

        this.taxaService
            .buscarCalendarioFiscalPorCentroDeCusto(this.codigoCentroDeCusto.value)
            .pipe(take(1))
            .subscribe(retorno => {
                this.acaoSelectCalendarioFiscal();
                this.calendarioFiscalAtual = retorno.calendarioFiscalAtual;
                this.calendarioFiscalAtualParcelaUnica = retorno.calendarioFiscalAtualParcelaUnica;
                Swal.fire(retorno.titulo, retorno.mensagem, retorno.status);
            });

    }

    formatToBRL(valor) {
        return this.mascara.transform(valor, 11);
    }

    acaoBotaoVoltar(){
        this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Cancelar });
    }

    acaoConfirmarRevisao() {
        this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Atualizar_Lancamentos_Pos_Cancelamento });
    }

    // -------------------------------------------------------------------------
    // Ações para salvar os dados - Início
    // -------------------------------------------------------------------------
    acaoBotaoSalvar() {
        this.centrosDeCustoAuxiliar = new CentroCusto();
        this.centrosDeCustoAuxiliar.codigo = this.codigoCentroDeCusto.value

        this.calendarioFiscalAuxiliar = new CalendarioFiscal();
        this.calendarioFiscalAuxiliar.codigo = this.codigoCalendarioFiscal.value

        Swal.fire({
            text: `Qual o motivo para efetivar essa revisão?`,
            icon: 'question',
            input: 'textarea',
            width: "60%",
            customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            inputValidator: (value) => {
            if (!value) {
                return 'Favor informar o motivo da revisão!'
            }
            }
        })
        .then((result) => {
            this.calendarioFiscalAuxiliar.observacao = result.value;

            if (result.isConfirmed) {
                var dados = [this.centrosDeCustoAuxiliar, this.calendarioFiscalAuxiliar];

                this.taxaService
                    .alterarCalendarioFiscalNosLancamentos(dados)
                    .pipe(take(1))
                    .subscribe(retorno => {
                        Swal.fire({
                            title: retorno.titulo,
                            html: retorno.mensagem,
                            icon: retorno.status,
                            willClose: () => {
                                this.limpar();
                            }
                        })
                    });
            }
        });

    }

    limpar() {        
        this.calendarioFiscalAtual = new CalendarioFiscal();
        this.calendarioFiscalRevisado = new CalendarioFiscal();
    }

    get cliente() {
        return this.formulario.get("cliente");
    }
    set cliente(cliente) {
        this.formulario.get("cliente").setValue(cliente);
    }
    
    get codigo() {
        return this.formulario.get("codigo");
    }
    set codigo(codigo) {
        this.formulario.get("codigo").setValue(codigo);
    }
    
    get protocolo() {
        return this.formulario.get("protocolo");
    }
    set protocolo(protocolo) {
        this.formulario.get("protocolo").setValue(protocolo);
    }
    
    get inscricao() {
        return this.formulario.get("inscricao");
    }
    set inscricao(inscricao) {
        this.formulario.get("inscricao").setValue(inscricao);
    }
  
    get codigoCentroDeCusto() {
      return this.formulario.get("codigoCentroDeCusto");
    }  
    set codigoCentroDeCusto(codigoCentroDeCusto) {
      this.formulario.get("codigoCentroDeCusto").setValue(codigoCentroDeCusto);
    }
  
    get codigoCalendarioFiscal() {
      return this.formulario.get("codigoCalendarioFiscal");
    }  
    set codigoCalendarioFiscal(codigoCalendarioFiscal) {
      this.formulario.get("codigoCalendarioFiscal").setValue(codigoCalendarioFiscal);
    }


}
