import { OnInit, Component } from "@angular/core";

@Component({
    selector: "app-termo",
    templateUrl: "./termo.component.html"
})
export class TermoComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}

}