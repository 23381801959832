<form
    class="justify-content-center align-items-center"
    name="formulario"
    formGroup="formulario"
>

    <div class="card">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Selecione o tipo de solicitação</strong>
            </div>
        </div>

        <div class="card-body">
            <mat-radio-group
                change="acaoRadioAutonomoRequerente()"
                #autonomoRequerenteRadio 
                formControlName="autonomoRequerente" 
                aria-label="Selecione uma opção">
                <div class="row">
                    <div class="form-group col-12">
                        <p class="card-text titulo">
                            <mat-radio-button value="1">
                                <strong>SOU AUTÔNOMO, SOLICITAREI MINHA PRÓPRIA INSCRIÇÃO.</strong>
                            </mat-radio-button>
                        </p>
                        <p class="card-text observacao">É o estabelecimento sede da empresa.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <p class="card-text titulo">
                            <mat-radio-button value="2">
                                <strong>SOU TERCEIRO, DESEJO EFETUAR A SOLICITAÇÃO PARA UM AUTÔNOMO.</strong>
                            </mat-radio-button>
                        </p>
                        <p class="card-text observacao">É o estabelecimento que esta subordinado / vinculado a matriz da empresa</p>
                    </div>
                </div>
            </mat-radio-group>
        </div>
    </div>
</form>