import { Component, OnInit } from '@angular/core';
import { Protocolo } from '../../../../model/protocolo';
import { MotivoEncerramento } from '../../../../model/motivoEncerramento';
import { formatToDateTime } from "brazilian-values";

@Component({
  selector: 'app-informacao-encerramento',
  templateUrl: './informacao-encerramento.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoEncerramentoComponent implements OnInit {

  protocolo: Protocolo;
  
  constructor() {
    this.protocolo = new Protocolo();
    this.protocolo.encerramento.motivosEncerramento = [new MotivoEncerramento()];
  }

  ngOnInit(): void {
  }

}
