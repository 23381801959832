<div class="container-principal">
  <mat-tab-group [selectedIndex]="abaInicial" (selectedIndexChange)="selecaoAba($event)" mat-align-tabs="start" *ngIf="!eixoConfiguracao && !eixoIntegracao && codigoMenuPai != 38" >
    <mat-tab *ngFor="let aba of abas" label="{{ aba.descricao }}">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
            <img src="../../../../assets/img/icons/{{ aba.icone }}.svg" style="width: 15px;"/>
        </mat-icon> {{ aba.descricao }}
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  
  <div class="row">
    <div class="col-12">
      <app-alert type="danger"
        *ngIf="emitirErro" >
        <div [innerHtml]="mensagemErro"></div>
      </app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <div class="card">
        <div class="card-header card-header-localizacao">
            <div class="col-12">
                <strong>Aqui você localiza os dados a sua escolha</strong>
            </div>      
        </div>
        <div class="card-body card-body-localizacao">
          <form
              class="justify-content-center align-items-center"
              name="formulario"
              [formGroup]="formulario"
          >
            <div class="row">
              <div class="col-5">
                <select 
                    #tipoBuscaSelect
                    class="form-control form-control-sm"
                    (change)="acaoSelectTipoBusca()"
                    formControlName="tipoBusca"
                    name="tipoBusca" 
                    id="tipoBusca"                
                >
                    <option disabled value="">--- Selecione ---</option>
                    <option *ngFor="let filtro of grid.filtro; let i = index"
                      [value]="i" 
                      >{{ filtro.display }}
                    </option>
                </select>
              </div>
              <div class="col-5">
                <input *ngIf="tipoBusca.value != '' ? grid.filtro[tipoBusca.value].tipoCampo == 'INPUT' : false"
                    #inputTipoBuscaValor
                    class="form-control form-control-sm"
                    formControlName="tipoBuscaValorInput"
                    id="tipoBuscaValorInput"
                    name="tipoBuscaValorInput"                
                    type="text"
                />
                <input *ngIf="tipoBusca.value != '' ? grid.filtro[tipoBusca.value].tipoCampo == 'DATE' : false"
                    #inputTipoBuscaValor
                    class="form-control form-control-sm text-center"
                    formControlName="tipoBuscaValorInput"
                    id="tipoBuscaValorInput"
                    name="tipoBuscaValorInput"                
                    type="text"
                    mask="00/00/0000"
                    [showMaskTyped]="true"
                />
                <input *ngIf="tipoBusca.value != '' ? grid.filtro[tipoBusca.value].tipoCampo == 'MES_ANO' : false"
                    #inputTipoBuscaValor
                    class="form-control form-control-sm text-center"
                    formControlName="tipoBuscaValorInput"
                    id="tipoBuscaValorInput"
                    name="tipoBuscaValorInput"                
                    type="text"
                    mask="00/0000"
                    [showMaskTyped]="true"
                />
                <select [class.hidden]="tipoBusca.value != '' ? grid.filtro[tipoBusca.value].tipoCampo != 'SELECT' : true"
                  #tipoBuscaValorSelect
                  formControlName="tipoBuscaValorSelect"
                  class="form-control form-control-sm"
                  name="tipoBuscaValorSelect" 
                  id="tipoBuscaValorSelect"                
                >
                  <option value=""></option>
                  <option *ngFor="let opcao of filtroOpcoes" 
                      value="{{ opcao.valor }}"
                  >
                      {{ opcao.display }}
                  </option>
                </select>
              </div>
              <div class="col-2">
                <button 
                    type="button" 
                    class="btn btn-secondary"
                    title="Buscar"
                    (click)="buscarGridPorFiltro()"
                >
                Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-header card-header-localizacao">
            <div class="col-12">
                <strong>Aqui você filtra os dados já localizados</strong>
            </div>      
        </div>
        <div class="card-body card-body-localizacao">
          <div class="row">
            <mat-form-field>
              <mat-label>Filtrar</mat-label>
              <input matInput (keyup)="aplicarFiltro($event)" placeholder="Digite qualquer valor para filtrar por todas as opções">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">
    <ng-container *ngFor="let campo of grid.campos | filtro: {campo: 'campoVisualizado', valor: 'S'}" matColumnDef="{{ campo.nmCampoTabela }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" arrowPosition="{{ campo.dsAlinharColuna == 'right' ? 'before' : 'after' }}"
          [style.padding-right]="campo.dsAlinharColuna == 'right' ? '5px' : '0px'"
          [style.padding-left]="campo.dsAlinharColuna == 'center' ? '20px' : '5px'"
          [style.width]="campo.vlLarguraColuna" 
          [style.text-align]="campo.dsAlinharColuna"
          id="row-{{ campo.nmCampoTabela }}" > {{ campo.dsTitulo }} </th>
          <td mat-cell *matCellDef="let row"
            [style.text-align]="campo.dsAlinharColuna"
            style="padding-left: 5px;"
            [style.padding-right]="campo.dsAlinharColuna == 'right' ? '5px' : '0px'">
            <div *ngIf="campo.nmCampoTabela.indexOf('ICONE') < 0"
              [class]="campo.dsClassColuna == 'S' ? row['CLASS_'.concat(campo.nmCampoTabela)] : ''">
              {{row[campo.nmCampoTabela]}}
            </div>
            <div *ngIf="campo.nmCampoTabela.indexOf('ICONE') >= 0"
              [class]="campo.dsClassColuna == 'S' ? row[campo.nmCampoTabela.replace('ICONE', 'CLASS')] : ''"
              matTooltip="{{ campo.dsTituloCampo == 'S' ? row[campo.nmCampoTabela.replace('ICONE', 'DESCRICAO')] : '' }}"
              matTooltipPosition="below">
              <svg class="iconeGrid">
                <use
                  attr.xlink:href="#{{ row[campo.nmCampoTabela] }}"
                ></use>
              </svg>
              <span *ngIf="campo.nmCampoTabela.replace('ICONE', 'DESCRICAO') != null && campo.dsTituloCampo != 'S'" style="float: left;width: 100%;">{{row[campo.nmCampoTabela.replace('ICONE', 'DESCRICAO')]}} </span>
            </div>
          </td>
    </ng-container>
    <ng-container matColumnDef="menu">
        <th style="width: 1%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-menu" mat-header-cell *matHeaderCellDef class="hide-arrow"></th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row">        
            <button class="btn btn-menu" mat-button [matMenuTriggerFor]="beforeMenu">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
            </button>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button *ngIf="eixoConfiguracao || eixoIntegracao"
                    class="btn" mat-menu-item
                    (click)="eventoAtendimento(acaoAtendimento.Alterar, row)"
                  >
                  <svg class="icone-grid-menu m-2">
                    <use
                      attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                    ></use>
                  </svg>
                  Editar
                </button>
                <button *ngFor="let menu of menusAdicionais"
                    class="btn" mat-menu-item
                    (click)="eventoAtendimento(menu[0], row)"
                  >
                  <svg class="icone-grid-menu m-2">
                    <use
                      attr.xlink:href="assets/sprite.svg#{{ menu[1] }}"
                    ></use>
                  </svg>
                  {{ menu[2] }}
                </button>
                <button *ngIf="codigoMenu == 115"
                    class="btn" mat-menu-item
                    (click)="eventoAtendimento(acaoAtendimento.Vincular_Clientes, row)"
                  > 
                  <svg class="icone-grid-menu m-2">
                    <use
                      attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                    ></use>
                  </svg>
                  Replicar usuário em outros clientes
                </button>
                <!-- <button *ngIf="eixoConfiguracao || eixoIntegracao"
                  class="btn" mat-menu-item
                  (click)="eventoAtendimento(acaoAtendimento.Excluir, row)"
                > Excluir</button> -->
                <button *ngIf="!eixoConfiguracao && !eixoIntegracao"
                  class="btn" mat-menu-item
                  [id]="retornarNomeMenuAtendimento()[0][1] + '_' + row.COD_PREVIA_ID"
                  (click)="abrirAtendimento(row, false)"
                >
                <svg class="icone-grid-menu m-2">
                  <use
                    attr.xlink:href="assets/sprite.svg#{{ retornarNomeMenuAtendimento()[0][0] }}"
                  ></use>
                </svg>  
                {{ retornarNomeMenuAtendimento()[0][2] }}</button> 
                <button *ngIf="!eixoConfiguracao && !eixoIntegracao"
                  class="btn" mat-menu-item
                  [id]="retornarNomeMenuAtendimento()[1][1] + '_' + row.COD_PREVIA_ID"
                  (click)="abrirAtendimento(row, true)"
                >
                <svg class="icone-grid-menu m-2">
                  <use
                    attr.xlink:href="assets/sprite.svg#{{ retornarNomeMenuAtendimento()[1][0] }}"
                  ></use>
                </svg>  
                {{ retornarNomeMenuAtendimento()[1][2] }}</button> 
                <button *ngIf="!eixoConfiguracao && !eixoIntegracao && permitirDelegar && permitirAtendimento && codigoMenu != 179 && codigoMenu != 180 && codigoMenu != 205"
                  class="btn" mat-menu-item
                  [id]="retornarNomeMenuAtendimento()[2][1] + '_' + row.COD_PREVIA_ID"
                  (click)="delegarAtendimento(row)"
                >
                <svg class="icone-grid-menu m-2">
                  <use
                    attr.xlink:href="assets/sprite.svg#{{ retornarNomeMenuAtendimento()[2][0] }}"
                  ></use>
                </svg>  
                {{ retornarNomeMenuAtendimento()[2][2] }}</button> 
            </mat-menu>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="row" *ngIf="dataSource.filteredData.length == 0">
    <div class="form-row col-12 pt-3 justify-content-center align-items-center">
      {{ mensagemDeBusca }}
    </div>
  </div>
  <mat-paginator [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> 
  <button class="btn btn-outline-success" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Excel', sheet: 'sheet_name', Props: {Author: 'Talha'}})">EXCEL</button>
  <button class="btn btn-outline-success" mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
  <button class="btn btn-outline-success" mat-raised-button (click)="exporter.exportTable('json')">JSON</button>
  <button class="btn btn-outline-success" mat-raised-button (click)="exporter.exportTable('txt')">TXT</button>
</div>