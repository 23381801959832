import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { GridArquivosVinculosComponent } from './grid-arquivos-vinculos.component';
import { PipeModule } from '../../../pipes/pipes.module';


@NgModule({
  declarations: [
    GridArquivosVinculosComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTableExporterModule,
    ],
  exports: [
    GridArquivosVinculosComponent
  ],
})
export class GridArquivosVinculosModule {}
