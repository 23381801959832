import { Component, Input, OnInit } from '@angular/core';
import { Empresa } from '../../../../model/empresa';
import { Constantes } from '../../../../model/constantes';

@Component({
  selector: 'app-informacao-situacao-empresa',
  templateUrl: './informacao-situacao-empresa.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoSituacaoEmpresaComponent implements OnInit {

  @Input() empresa: Empresa;
  @Input() tipoEmpresa: string = "";

  situacaoAlvaraEmpresa = Constantes.SITUACAO_ALVARA_EMPRESA;

  constructor() {}

  ngOnInit(): void {
  }

}
