import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GerenciamentoDeRequisitosComponent } from './gerenciamento-de-requisitos.component';
import { AtendimentoGridModule } from '../../outros/atendimento/atendimento-grid/atendimento-grid.module';
import { CadastrarRequisitoComponent } from './cadastrar-requisito/cadastrar-requisito.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormAtividadePesquisaModule } from '../../outros/form-atividade-pesquisa/form-atividade-pesquisa.module';
import { AlertModule } from '../../../components/alert/alert.module';
import { FormRequisitoListComponent } from './cadastrar-requisito/form-atividade-list/form-requisito-list.component';
import { MatListModule } from '@angular/material/list';
import { PipeModule } from '../../../pipes/pipes.module';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { GridModule } from '../../../components/grid/grid.module';
import { MatDialogModule } from '@angular/material/dialog';
import { CadastrarVinculosPorLoteComponent } from './cadastrar-requisito/cadastrar-vinculos-por-lote/cadastrar-vinculos-por-lote.component';
import { GridArquivosVinculosComponent } from '../grid-arquivos-vinculos/grid-arquivos-vinculos.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

@NgModule({
  declarations: [
    GerenciamentoDeRequisitosComponent,
    CadastrarRequisitoComponent,
    FormRequisitoListComponent,
    CadastrarVinculosPorLoteComponent,
    //GridArquivosVinculosComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AtendimentoGridModule,
    MatRadioModule,
    FormAtividadePesquisaModule,
    AlertModule,
    MatCheckboxModule,
    MatListModule,
    PipeModule,
    PerfectScrollbarModule,
    MatInputModule,
    GridModule,
    MatDialogModule,
  ],
  exports: [
    FormRequisitoListComponent,
    CadastrarVinculosPorLoteComponent,
    //GridArquivosVinculosComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
  
})
export class GerenciamentoDeRequisitosModule { }
