<div *ngIf="!informacoesQuestionarios">
    <app-form-questionario [semCardHeader]="semCardHeader" (perguntasCarregadas)="perguntasCarregadas($event)" tipo="AD" [titulo]="titulo"></app-form-questionario>
</div>
<div *ngIf="informacoesQuestionarios">
    <app-informacao-questionario [semCardHeader]="semCardHeader" #informacaoQuestionarioConsultaPrevia titulo="de Consulta Prévia" *ngIf="protocolo.processo.codigo != '27' && protocolo.processo.codigo != '28' && protocolo.processo.tipo != 'E' && protocolo.processo.tipo != 'R' && protocolo.processo.tipo != 'T'"></app-informacao-questionario>
    <app-informacao-questionario [semCardHeader]="semCardHeader" #informacaoQuestionarioConsultaPreviaEvento titulo="de Consulta Prévia Evento" *ngIf="protocolo.processo.codigo == '27' || protocolo.processo.codigo == '28'"></app-informacao-questionario>
    <app-informacao-questionario [semCardHeader]="semCardHeader" #informacaoQuestionarioDadosCadastrais titulo="de Dados Cadastrais" *ngIf="protocolo.processo.codigo != '27' && protocolo.processo.codigo != '28' && protocolo.processo.tipo != 'E' && protocolo.processo.tipo != 'R' && protocolo.processo.tipo != 'T'"></app-informacao-questionario>
    <app-informacao-questionario [semCardHeader]="semCardHeader" #informacaoQuestionarioDadosCadastraisEvento titulo="de Dados Cadastrais Evento" *ngIf="protocolo.processo.codigo == '27' || protocolo.processo.codigo == '28'"></app-informacao-questionario>
    <app-informacao-questionario [semCardHeader]="semCardHeader" #informacaoQuestionarioRecadastro titulo="de Recadastramento" *ngIf="protocolo.processo.tipo == 'R'"></app-informacao-questionario>
    <app-informacao-questionario [semCardHeader]="semCardHeader" #informacaoQuestionarioEncerramento titulo="de Baixa" *ngIf="protocolo.processo.tipo == 'E' || protocolo.processo.tipo == 'T'"></app-informacao-questionario>
</div>