import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NotificacaoService } from '../../../../services/notificacao/notificacao.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { take } from 'rxjs/operators';
import { Mensageria } from '../../../../model/mensageria';
import Swal from "sweetalert2";

@Component({
  selector: "app-notificacoes",
  templateUrl: "./notificacoes.component.html",
  styleUrls: ["./notificacoes.component.scss"],
})
export class NotificacoesComponent {
  @Input() showNotificationDropdown: boolean;
  @Output() clickNotificationDropDown = new EventEmitter();

  mensagerias: Array<Mensageria>;

  constructor(
    private authenticationService: AuthenticationService,
    private notificacaoService: NotificacaoService
  ) {
    this.buscarMensagens();
    setInterval(() => {
      this.buscarMensagens();
    }, 60000);
  }

  async buscarMensagens() {
    const currentUser = this.authenticationService.currentUserValue;
    
    if (currentUser != null && localStorage.getItem("pId") != null) {
      await this.notificacaoService
        .buscarMensagensNaoLidas(
          localStorage.getItem("pId"),
          currentUser.codigo_usuario,
          currentUser.perfil
        )
        .pipe(take(1))
        .subscribe( retorno => {
          this.mensagerias = retorno;
        })
    }

  }

  exibirMensagem(mensageria: Mensageria) {
    Swal.fire({
      html: mensageria.mensagem,
      icon: "info",
      width: 700,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Marcar como lido",
      cancelButtonText: "Fechar",
      willClose: () => {
        this.buscarMensagens();
      }
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        await this.notificacaoService
          .marcarComoLida(localStorage.getItem("pId"), mensageria.codigo)
          .pipe(take(1))
          .subscribe( () => this.buscarMensagens())
      }
    })
    
  }
}
