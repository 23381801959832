import { Component, OnInit } from '@angular/core';
import { Pendencia } from '../../../../model/pendencia';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { Protocolo } from '../../../../model/protocolo';
import { Empresa } from '../../../../model/empresa';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { Licenciamento } from '../../../../model/licenciamento';
import { Despacho } from 'src/app/model/despacho';
import { Constantes } from '../../../../model/constantes';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AtendimentoService } from '../../../../services/operacao/atendimento.service';
import { Login } from '../../../../model/login';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidator } from 'src/app/components/validators/form-validator';
import { Suspensao } from '../../../../model/suspensao';
import { ProtocoloService } from '../../../../services/operacao/protocolo.service';
import { Processo } from '../../../../model/processo';
import { Configuracao } from '../../../../model/configuracao';
import { Atendimento } from 'src/app/model/atendimento';
import { EmpresaService } from '../../../../services/empresa/empresa.service';

@Component({
    selector: "app-atendimento-suspensao",
    templateUrl: "./atendimento-suspensao.component.html",
    styleUrls: ['./atendimento-suspensao.component.scss']
})
export class AtendimentoSuspensaoComponent implements OnInit {

    numeroInscricao: string;
    numeroProtocolo: string;
    configuracao: Configuracao;
    processo: Processo;
    cnpj: string;
    permitirAtendimento: boolean;
    permitirDelegar: boolean = false;
    usuarios: Array<Login>;

    despachos: Array<Despacho>;

    emitirErro: boolean;
    mensagemErro: string;

    formulario: FormGroup;

    codigoMenu: number;
    protocolo: Protocolo;

    dataSuspensao: string;

    empresa: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private atendimentoService: AtendimentoService,
        private protocoloService: ProtocoloService,
        private empresaService: EmpresaService,
        public dialog: MatDialog,
        private router: Router,
        private formBuilder: FormBuilder) {
    
        this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "";
        this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];
        this.cnpj = this.activatedRoute.snapshot.params["cnpj"];

        this.permitirAtendimento = this.activatedRoute.snapshot.data["subMenu"] == "emSuspensao";

        this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];

        atendimentoService
            .permissoesParaAtendimento()
            .pipe(take(1))
            .subscribe( retorno => {
                this.permitirDelegar = retorno.delegar;
        })

        this.emitirErro = false;

        this.formulario = this.formBuilder.group({
            processoAdministrativo: ['', [Validators.required]],
            dataInicio: ['', [Validators.required, Validators.minLength(8), FormValidator.dataValida()]],
            dataFim: ['', [Validators.minLength(8), FormValidator.dataValida()]],
            observacao: ["", Validators.maxLength(20000)],
        });
    }

    ngOnInit(): void {
    }

    preencherParaAtendimento(){

        this.empresaService
            .buscarParaAlteracao(
                localStorage.getItem("pId"), 
                this.cnpj, 
                this.numeroInscricao)
            .pipe(take(1))
            .subscribe( (retorno: Empresa) => {
                this.empresa = retorno.status;

                if (this.empresa == Constantes.STATUS_EMPRESA.Alteracao_Em_Andamento) {
                    this.bloquearAtendimento(this.empresa);
                }
                else{
                    this.protocoloService
                    .buscarSuspensaoAtiva(this.numeroInscricao)
                    .pipe(take(1))
                    .subscribe( retorno => {
                        this.processoAdministrativo = retorno.processoAdministrativo;
                        this.dataInicio = retorno.dataInicio;
                        this.observacao = retorno.observacao;
                        this.bloquearAtendimento("1");

                        this.dataSuspensao = retorno.dataInicio;
                    });
                }
            })
        
    }

    bloquearAtendimento(statusEmpresa){

        this.dataInicio.disable();
        this.processoAdministrativo.disable();
        this.observacao.disable();
        this.dataFim.disable();
        
        let mensagemErro = "";

        if (this.protocolo.encerramento.data != '' ) {
            mensagemErro += "Empresa encerrada em " + this.protocolo.encerramento.data;
        }
        if (this.dataInicio.value != '' && !this.permitirAtendimento) {
            mensagemErro += "Empresa suspensa em " + this.dataInicio.value;
        }
        if(statusEmpresa == Constantes.STATUS_EMPRESA.Alteracao_Em_Andamento){
            mensagemErro += "Empresa já possui um processo de alteração em andamento.";
        }
        
        if (mensagemErro === "") {
            this.emitirErro = false;
            if(this.permitirAtendimento){
                this.dataFim.enable();
            }
            else{
                this.processoAdministrativo.enable();
                this.dataInicio.enable();
                this.observacao.enable();
            }
        }
        else{
            if (this.dataInicio.value != ""){
                Swal.fire("", "Negócio suspenso em " + this.dataInicio.value + " - Para mais informações, acesse a opção Suspensas.", "error");
                this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
            }
            else if (this.protocolo.encerramento.data != '' ) {
                Swal.fire("", "Suspensão não permitida - Negócio baixado em " + this.protocolo.encerramento.data, "error");
                this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
            }
            else if(statusEmpresa == Constantes.STATUS_EMPRESA.Alteracao_Em_Andamento){
                Swal.fire("", "Suspensão não permitida - esse negócio tem um processo de alteração em andamento.", "error");
                this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
            }
            else{
                Swal.fire("", "Negócio suspenso em " + this.dataInicio.value + " - Para mais informações, acesse a opção Suspensas.", "error");
                this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
            }
            
            this.emitirErro = true;
            this.mensagemErro = mensagemErro;
        }
        
        if(this.permitirAtendimento){
            this.dataFim.setValidators([Validators.required, Validators.minLength(8), FormValidator.dataValida()]);
            this.dataFim.updateValueAndValidity();
        }
        else{
            this.dataFim.clearValidators();
        }

        return false;
    }

    acaoBotaoSuspender(){
        if(this.validarFormulario()){
            if(!this.permitirAtendimento){

                Swal.fire({
                    text: 'Tem certeza que deseja suspender esse negócio?',
                    icon: 'question',
                    customClass: {
                      confirmButton: 'btn btn-success',
                      cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: true,
                    showCancelButton: true,
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !Swal.isLoading()
                  })
                  .then((result) => {
                    if (!result.dismiss) {
                        this.executarComPareceres();
                    }
                  });
                
            }
            else{

                Swal.fire({
                    text: 'Tem certeza que deseja cancelar a suspensão desse negócio?',
                    icon: 'question',
                    customClass: {
                      confirmButton: 'btn btn-success',
                      cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: true,
                    showCancelButton: true,
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !Swal.isLoading()
                  })
                  .then((result) => {
                    if (!result.dismiss) {
                        this.executarComPareceres();
                    }
                  });
                
            }
            

        }

    }
    
    executarComPareceres() {
        const dialogRef = this.dialog.open(IcadModalComponent, {
          width: '80%',
          height: '95%',
          maxWidth: 'none',
          maxHeight: 'none',
          closeOnNavigation: true,
          data: {
              titulo: "Pareceres",
              componente: "atendimento-pareceres",
              dados: [
                "fase",
                localStorage.getItem("pId"), 
                this.activatedRoute.snapshot.params["codigoOrgao"], 
                this.processo, 
                //acaoAtendimento.acao,
                this.observacao.value
              ]
          }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
          if (result.parecerFinal != null) {
            this.observacao.setValue(result.parecerFinal);
            this.preencher();
           if(!this.permitirAtendimento){
                this.protocoloService
                    .gerarSuspensao(this.protocolo)
                    .pipe(take(1))
                    .subscribe( retorno => {
                        this.mensagemAcaoBotaoSuspender(retorno);
                    });
            }
            else{
                this.protocoloService
                    .atenderSuspensao(this.protocolo)
                    .pipe(take(1))
                    .subscribe( retorno => {
                        if(retorno){
                            Swal.fire("", "Negócio ativo com sucesso! <br> Protocolo nº " + this.protocolo.protocolo + ". Caro auditor, você efetuou o cancelamento da suspensão desse negócio.", "success");
                        }
                        else{
                            Swal.fire("", "Erro ao tentar remover a suspensão.", "error");
                        }
                        this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
                    });
            }
          }
        });
      }

    mensagemAcaoBotaoSuspender(retorno) {
        Swal.fire({
            title: retorno.titulo,
            html: retorno.mensagem,
            icon: retorno.status,
            willClose: () => {
                this.router.navigate([retorno.redirecionamento], {relativeTo: this.activatedRoute});
            }
        })
    }

    validarFormulario() {
  
        if (this.protocolo.encerramento.data != '' || (this.dataSuspensao != '' && !this.permitirAtendimento)) {
            return false;
        }
  
        if (this.formulario.invalid) {
            let mensagemErro = "";

            if (this.processoAdministrativo.invalid) {
                mensagemErro += "Favor informar o número do processo administrativo.<br>"
            }
            if (this.dataInicio.invalid) {
                mensagemErro += "Favor informar a data inicial da suspensão.<br>"
            }
           if(this.dataFim.invalid){
                mensagemErro += "Favor informar a data final da suspensão.<br>"
            }

            if (mensagemErro === "") {
                this.emitirErro = false;
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErro = true;
            this.mensagemErro = mensagemErro;

            return false;
        }

        this.emitirErro = false;
        this.mensagemErro = "";
        
        return true;
    }

    preencher() {
        this.processo = new Processo();
        
        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        this.configuracao.processos
            .forEach(processo => {
                var tipo = this.protocolo.registro.tipoPessoa == "" ? "J" : this.protocolo.registro.tipoPessoa;

                if (processo.codigo === "S" && processo.tipo === tipo) {
                    this.processo.cliente = processo.cliente;
                    this.processo.codigo = processo.codigo;
                    this.processo.descricao = processo.descricao;
                    this.processo.protocolo = processo.protocolo;
                    this.processo.tipo = processo.tipo;
                    this.processo.servicos = processo.servicos;
                    
                } 
            }
        );

        this.protocolo.processo = this.processo;
        this.protocolo.suspensao.processoAdministrativo = this.processoAdministrativo.value;
        this.protocolo.suspensao.dataInicio = this.dataInicio.value;
        this.protocolo.suspensao.dataFim = this.dataFim.value;
        this.protocolo.suspensao.inscricao = this.numeroInscricao;
        this.protocolo.suspensao.observacao = this.observacao.value;
        this.protocolo.suspensao.cnpj = this.cnpj;

        //if(this.permitirAtendimento){
            var atendimentoAuxiliar = new Atendimento();
            atendimentoAuxiliar.parecer = Constantes.ACAO_ATENDIMENTO.Deferir;

            this.protocolo.viabilidade.atendimentos.push(atendimentoAuxiliar);
        //}

    }

    get processoAdministrativo() {
        return this.formulario.get("processoAdministrativo");
    }  
    set processoAdministrativo(processoAdministrativo) {
        this.formulario.get("processoAdministrativo").setValue(processoAdministrativo);
    }

    get dataInicio() {
        return this.formulario.get("dataInicio");
    }  
    set dataInicio(dataInicio) {
        this.formulario.get("dataInicio").setValue(dataInicio);
    }

    get dataFim() {
        return this.formulario.get("dataFim");
    }  
    set dataFim(dataFim) {
        this.formulario.get("dataFim").setValue(dataFim);
    }

    get observacao() {
        return this.formulario.get("observacao");
    }  
    set observacao(observacao) {
        this.formulario.get("observacao").setValue(observacao);
    }

}