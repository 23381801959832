import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TodoPendenciaComponent } from "./todo-pendencia.component";
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { NgxMaskModule } from "ngx-mask";
import { PipeModule } from "src/app/pipes/pipes.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { TodoEmpresasComponent } from './todo-empresas.component';
import { MatTooltipModule } from '@angular/material/tooltip';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

const components = [
    TodoPendenciaComponent,
    TodoEmpresasComponent,
];

@NgModule({
    declarations: components,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        PerfectScrollbarModule,
        NgxMaskModule.forRoot(),
        PipeModule,
        NgbDropdownModule
    ],
    exports: components,
    providers: [
      {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      }
    ]
})
export class TodoModule {}