import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknEstrutura'),
    });
  }

  obterMenuPorTexto(descricao: string) {
    return this.http.get<any>(
      `${environment.ciphersUrl}/funcao/obter-menus-usuario`,
      {
        headers: this.httpHeaders,
        params: { descricao },
      }
    );
  }

  buscarCodigoCliente(Nome) {
    return this.http.get<any>(
        `${ environment.icadUrl }/configuracao/buscarCodigoCliente/${ Nome }`,
        {
            headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
            }),
        }
    );
  }

  buscarMenuPorCliente(tipo) {
    return this.http.post<any>(
        `${ environment.icadUrl }/token`,
        {
          grant_type: tipo,
          tipoLogin: 0,
          sessaoCore: false,
          cliente: localStorage.getItem('pId'),
        },
        {
          headers: new HttpHeaders({
            app_id: environment.appId,
            param_user: 'ORG',
            'Content-Type': 'application/json',
          }),
        }
    );
  }

  buscarMenuGeral(tipo) {
    return this.http.post<any>(
      `${ environment.icadUrl }/token`,
      {
        grant_type: tipo,
        tipoLogin: 0,
        sessaoCore: false,
        cliente: localStorage.getItem('pId'),
      },
      {
        headers: new HttpHeaders({
          app_id: environment.appId,
          param_user: 'ORG',
          'Content-Type': 'application/json',
        }),
      }
  );
  }

  buscarDados(cliente) {
     return this.http.get<any>(
      `${ environment.icadUrl }/contadorDados/buscarDados/${ cliente }`,
      {
        headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
        }),
      }

    );
  }

  buscarDadosCliente() {
     return this.http.get<any>(
      `${ environment.icadUrl }/cliente/buscarPorCodigo/${ localStorage.getItem("pId") }`,
      {
        headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
        }),
      }

    );
  }

  buscarDadosClienteUrl(url: string) {
     return this.http.get<any>(
      `${ environment.icadUrl }/cliente/buscarPorCodigoUrl/${ url }`,
      {
        headers: new HttpHeaders({
              app_id: environment.appId,
              param_user: 'ORG',
              'Content-Type': 'application/json',
        }),
      }

    );
  }

}
