<div class="mat-elevation-z8 mt-3 mb-3">
    <div class="table-container-atividades">
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="listagem">
        <ng-container matColumnDef="codigo" >
            <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-codigo"
                mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow">Código</th>
            <td mat-cell *matCellDef="let row"
                style="text-align: center; padding-left: 5px; height: 10px;">
                {{ row.codigo }}
            </td>
        </ng-container>
        <ng-container matColumnDef="arquivo">
            <th style="width: 50%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-arquivo"
                mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Arquivo </th>
            <td mat-cell *matCellDef="let row"
                style="text-align: left; padding-left: 5px; padding-right: 5px;">
                {{ row.nome }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th style="width: 40%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-status"
                mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Status </th>
            <td mat-cell *matCellDef="let row"
                style="text-align: left; padding-right: 5px;"> 
                {{ row.observacao }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row 
            class="ajusteDeLinha"
            *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="row mt-4" *ngIf="dataSource != null && dataSource.filteredData.length == 0">
        <div class="form-row col-12 justify-content-center align-items-center">
            Não foram encontradas atividades para esse permissionamento.
        </div>
      </div>
    </div>
    <mat-paginator pageSize="15" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<div>&nbsp;</div>
