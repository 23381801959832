
<div class="mat-elevation-z9">
  <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">
    <ng-container matColumnDef="codigoLancamento">
        <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-codigoLancamento" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Lançamento </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha" [class.texto-success]="row.regraPositiva == 1" [class.texto-danger]="row.regraPositiva == 0" [class.negrito]="row.regraPositiva == 1"> {{ row.codigoLancamento }} </td>
    </ng-container>

    <ng-container matColumnDef="descricaoRegra">
      <th style="width: 40%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-descricaoRegra" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Descrição </th>
      <td style="text-align: left; padding-left: 10px; padding-right: 5px;" mat-cell *matCellDef="let row" [class.texto-success]="row.regraPositiva == 1" [class.texto-danger]="row.regraPositiva == 0" [class.negrito]="row.regraPositiva == 1"> {{ row.descricaoRegra }} </td>
    </ng-container>

    <ng-container matColumnDef="regraPositiva">
      <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-regraPositiva" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Aplicada </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha" [class.texto-success]="row.regraPositiva == 1" [class.texto-danger]="row.regraPositiva == 0" [class.negrito]="row.regraPositiva == 1"> {{ row.regraPositiva == 1 ? 'Sim' : 'Não' }} </td>
    </ng-container>

    <ng-container matColumnDef="acao">
      <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-acao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Ação </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" [class.texto-success]="row.regraPositiva == 1" [class.texto-danger]="row.regraPositiva == 0" [class.negrito]="row.regraPositiva == 1"> {{ acaoTaxaRegraDescricao[row.acao] }} </td>
    </ng-container>

    <ng-container matColumnDef="tipo">
      <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-tipo" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Tipo </th>
      <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha" [class.texto-success]="row.regraPositiva == 1" [class.texto-danger]="row.regraPositiva == 0" [class.negrito]="row.regraPositiva == 1"> {{ tipoTaxaRegraDescricao[row.tipo] }} </td>
    </ng-container>

    <ng-container matColumnDef="valor">
      <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-valor" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Valor </th>
      <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" [class.texto-success]="row.regraPositiva == 1" [class.texto-danger]="row.regraPositiva == 0" [class.negrito]="row.regraPositiva == 1"> {{ formatToBRL(row.valor) }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotal">
      <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotal" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Sub-Total </th>
      <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha" [class.texto-success]="row.regraPositiva == 1" [class.texto-danger]="row.regraPositiva == 0" [class.negrito]="row.regraPositiva == 1"> R$ {{ formatToBRL(row.subTotal) }} </td>
    </ng-container>

    <ng-container matColumnDef="total">
    <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-total" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Total </th>
      <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" [class.texto-success]="row.regraPositiva == 1" [class.texto-danger]="row.regraPositiva == 0" [class.negrito]="row.regraPositiva == 1"> R$ {{ formatToBRL(row.total) }} </td>
    </ng-container> 

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="row" *ngIf="dataSource.filteredData.length == 0">
    <div class="form-row col-12 pt-3 justify-content-center align-items-center">
      <p class="card-text text-center">
        <br>{{ mensagemDeBusca }}<br>&nbsp;
      </p>
    </div>
  </div>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator> 
  <div class="row col-12 justify-content-center align-items-center">
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Excel', sheet: 'sheet_name', Props: {Author: 'Talha'}})">EXCEL</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('json')">JSON</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('txt')">TXT</button>
  </div>
</div>
<div>&nbsp;</div>
