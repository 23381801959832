import { Component, OnInit, Output, Input, ViewChild, EventEmitter } from '@angular/core';
import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { take } from "rxjs/operators";
import { ActivatedRoute, Router } from '@angular/router';
import { AtendimentoConfiguracao } from "src/app/model/atendimentoConfiguracao";
import Swal from 'sweetalert2';
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';
import { MatDialog } from "@angular/material/dialog";
import { Requisito } from "src/app/model/requisito";
import { MatCheckbox } from "@angular/material/checkbox";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { Protocolo } from "src/app/model/protocolo";
import { Constantes } from '../../../../model/constantes';
import { RequisitoService } from '../../../../services/requisito/requisito.service';
import { Documento } from '../../../../model/documento';
import { ObservacaoService } from '../../../../services/observacao/observacao.service';
import { ComprovanteDeRequisitos } from '../../../../components/pdf/comprovanteDeRequisitos';

@Component({
  selector: 'app-atendimento-requisito',
  templateUrl: './atendimento-requisito.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoRequisitoComponent implements OnInit {

  @Input() semCardHeader: boolean = false;
  @Output() acaoDeAtendimento = new EventEmitter();

  formulario: FormGroup;
  requisitos: Array<Requisito>;
  requisitosAdicionados: Array<Requisito>;
  requisitosRemovidos: Array<Requisito>;
  requisitosExistentes: Array<Requisito>;
  permitirAtendimento: boolean;
  tipoDeBloqueioRequisito = Constantes.TIPO_DE_BLOQUEIO_REQUISITO;
  tipoDeBloqueioRequisitoDescricao = Constantes.TIPO_DE_BLOQUEIO_REQUISITO_DESCRICAO;
  
  numeroProtocolo: string;
  numeroInscricao: string;
  codigoOrgao: string;
  codigoMenuPai: string;
  codigoMenu: string;
  
  consultaProcesso: boolean;
  moduloOficio: boolean;

  listaRequisitosObrigatorios: Set<string>;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private requisitoService: RequisitoService,
    private observacaoService: ObservacaoService,
    public dialog: MatDialog) {
    this.requisitosAdicionados = new Array<Requisito>();
    this.requisitosRemovidos = new Array<Requisito>();
    
    let meusProcessos = this.activatedRoute.snapshot.data["menu"] == "processos";
    this.consultaProcesso = this.activatedRoute.snapshot.data["menu"] == "consulta-processo";

    this.moduloOficio = this.activatedRoute.snapshot.data["menu"] == "oficio";
    
    let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
    this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (!meusNegocios || !meusProcessos));
    
    this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];
    this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];
    this.codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"];
    this.codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];
    this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
    
    
    this.formulario = this.formBuilder.group({
      controleValidade: ["", [Validators.required]],
    });
   }

  ngOnInit(): void {
    
  }

  inicializar() {
    this.requisitos = new Array<Requisito>();
    this.requisitosAdicionados = new Array<Requisito>();
    this.requisitosRemovidos = new Array<Requisito>();
    this.requisitosExistentes = new Array<Requisito>();
  }

  abrirComprovanteDeRequisitos() {
    this.requisitoService
        .buscarPorInscricao(localStorage.getItem("pId"), this.numeroInscricao, false)
        .pipe(take(1))
        .subscribe( retorno => {
          var protocoloAuxiliar = new Protocolo();
          protocoloAuxiliar.inscricao = this.numeroInscricao;
          protocoloAuxiliar.viabilidade.requisitos = retorno;
          
          new ComprovanteDeRequisitos(protocoloAuxiliar).abrirPDF();
        });
  }

  exigeJustificativa(checkbox: MatCheckbox, index){
    if (checkbox.checked) {
      if ( !this.requisitosExistentes.find(requisitoExistente => requisitoExistente.codigo == this.requisitos[index].codigo) ) {
        Swal.fire({
            text: 'Qual o motivo para a inclusão desse requisito?',
            icon: 'question',
            input: 'textarea',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            inputValidator: (value) => {
              if (!value) {
                return 'Favor informar o motivo da inclusão do requisito!'
              }
            }
          })
          .then((result) => {
            if (result.dismiss) {
              checkbox.checked = false;
            }
            else {
              this.requisitosRemovidos.splice(
                this.requisitosRemovidos.findIndex(
                  requisito => requisito.codigo == this.requisitos[index].codigo
                ), 1);
                
              let tamanho = this.requisitosAdicionados.push(this.requisitos[index]);
              this.requisitosAdicionados[tamanho - 1].justificativa = result.value;
            }
          })
      }
      else {
        this.requisitosRemovidos.splice(
          this.requisitosRemovidos.findIndex(
            requisito => requisito.codigo == this.requisitos[index].codigo
          ), 1);
      }
    }
    else {
      if ( this.requisitos[index].obrigatorio || this.requisitosExistentes.find(requisitoExistente => requisitoExistente.codigo == this.requisitos[index].codigo) ) {
        Swal.fire({
          text: 'Qual o motivo para a remoção desse requisito?',
          icon: 'question',
          input: 'textarea',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading(),
          inputValidator: (value) => {
            if (!value) {
              return 'Favor informar o motivo da remoção do requisito!'
            }
          }
        })
        .then((result) => {
          if (result.dismiss) {
            checkbox.checked = true;
          }
          else {
            this.requisitosAdicionados.splice(
              this.requisitosAdicionados.findIndex(
                requisito => requisito.codigo == this.requisitos[index].codigo
              ), 1);
              
            let tamanho = this.requisitosRemovidos.push(this.requisitos[index]);
            this.requisitosRemovidos[tamanho - 1].justificativa = result.value;
          }
        })
      }
      else {
        this.requisitosAdicionados.splice(
          this.requisitosAdicionados.findIndex(
            requisito => requisito.codigo == this.requisitos[index].codigo
          ), 1);
      }
    }
  }

  adicionarRemoverRequisitoProtocolo(checkbox: MatCheckbox, codigo){

    if(this.moduloOficio){
      this.exigeJustificativa(checkbox,this.requisitos.findIndex(requisitoExistente => requisitoExistente.codigo == codigo));
      return false;
    }

    Swal.fire({
        text: `Qual o motivo para a ${ checkbox.checked ? 'inclusão' : 'remoção' } desse requisito?`,
        icon: 'question',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        inputValidator: (value) => {
          if (!value) {
            return 'Favor informar o motivo da inclusão do requisito!'
          }
        }
      })
      .then((result) => {
        if (result.dismiss) {
          checkbox.checked ? checkbox.checked = false : checkbox.checked = true;
        }
        else {
          if (checkbox.checked) {
            this.adicionarRequisitoProtocolo(codigo, result.value);
          }
          else {
            this.removerRequisitoProtocolo(codigo, result.value);
          }
        }
      })
  }

  adicionarRequisitoProtocolo(codigo, justificativa) {
    var requisitoAuxiliar = new Requisito();
    requisitoAuxiliar.cliente = localStorage.getItem("pId");
    requisitoAuxiliar.protocolo = this.activatedRoute.snapshot.params["protocolo"];
    requisitoAuxiliar.codigo = codigo;
    requisitoAuxiliar.justificativa = justificativa;

    this.requisitoService
      .adicionarRequisitoProtocolo(requisitoAuxiliar)
      .pipe(take(1))
      .subscribe( retorno => {
        Swal.fire({
          title: retorno.titulo,
          html: retorno.mensagem,
          icon: retorno.status,
          willClose: () => {
            if (retorno.status != "error") {
              var protocoloAuxiliar = new Protocolo();
              protocoloAuxiliar.viabilidade.requisitos = retorno.requisitoDtosPorProtocolo;
              this.preencher(this.requisitos, protocoloAuxiliar);
            }
          }
        });
      });
  }

  removerRequisitoProtocolo(codigo, justificativa) {
    var requisitoAuxiliar = new Requisito();
    requisitoAuxiliar.cliente = localStorage.getItem("pId");
    requisitoAuxiliar.protocolo = this.activatedRoute.snapshot.params["protocolo"];
    requisitoAuxiliar.codigo = codigo;
    requisitoAuxiliar.justificativa = justificativa;

    this.requisitoService
      .removerRequisitoProtocolo(requisitoAuxiliar)
      .pipe(take(1))
      .subscribe( retorno => {
        Swal.fire({
          title: retorno.titulo,
          html: retorno.mensagem,
          icon: retorno.status,
          willClose: () => {
            if (retorno.status != "error") {
              this.listaRequisitosObrigatorios.delete(codigo);
              
              var protocoloAuxiliar = new Protocolo();
              protocoloAuxiliar.viabilidade.requisitos = retorno.requisitoDtosPorProtocolo;

              var requisitoJaAdicionado = this.requisitosAdicionados.findIndex( requisitoAdicionado => { return codigo == requisitoAdicionado.codigo });
              if (requisitoJaAdicionado != -1) {
                this.requisitosAdicionados.splice(requisitoJaAdicionado, 1);
              }

              this.preencher(this.requisitos, protocoloAuxiliar);
            }
          }
        });
      });
  }

  adicionarRemoverRequisitoEmpresa(checkbox: MatCheckbox, codigo){
    Swal.fire({
        text: `Qual o motivo para a ${ checkbox.checked ? 'inclusão' : 'remoção' } desse requisito?`,
        icon: 'question',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        inputValidator: (value) => {
          if (!value) {
            return 'Favor informar o motivo da inclusão do requisito!'
          }
        }
      })
      .then((result) => {
        if (result.dismiss) {
          checkbox.checked ? checkbox.checked = false : checkbox.checked = true;
        }
        else {
          if (checkbox.checked) {
            this.adicionarRequisitoEmpresa(codigo, result.value);
          }
          else {
            this.removerRequisitoEmpresa(codigo, result.value);
          }
        }
      })
  }

  adicionarRequisitoEmpresa(codigo, justificativa) {
    var requisitoAuxiliar = new Requisito();
    requisitoAuxiliar.cliente = localStorage.getItem("pId");
    requisitoAuxiliar.inscricao = this.activatedRoute.snapshot.params["inscricao"];
    requisitoAuxiliar.codigo = codigo;
    requisitoAuxiliar.justificativa = justificativa;

    this.requisitoService
      .adicionarRequisitoEmpresa(requisitoAuxiliar)
      .pipe(take(1))
      .subscribe( retorno => {
        Swal.fire({
          title: retorno.titulo,
          html: retorno.mensagem,
          icon: retorno.status,
          willClose: () => {
            if (retorno.status != "error") {
              //document.location.reload();
            }
            this.acaoDeAtendimento.emit({ acao: "acaoAtendimento", empresa: retorno.dados});
          }
        });
      });
  }

  removerRequisitoEmpresa(codigo, justificativa) {
    var requisitoAuxiliar = new Requisito();
    requisitoAuxiliar.cliente = localStorage.getItem("pId");
    requisitoAuxiliar.inscricao = this.activatedRoute.snapshot.params["inscricao"];
    requisitoAuxiliar.codigo = codigo;
    requisitoAuxiliar.justificativa = justificativa;

    this.requisitoService
      .removerRequisitoEmpresa(requisitoAuxiliar)
      .pipe(take(1))
      .subscribe( retorno => {
        Swal.fire({
          title: retorno.titulo,
          html: retorno.mensagem,
          icon: retorno.status,
          willClose: () => {
            if (retorno.status != "error") {
              //document.location.reload();
            }
            this.acaoDeAtendimento.emit({ acao: "acaoAtendimento", empresa: retorno.dados});
          }
        });
      });
  }

  exibeHistoricoRequisito(codigo){
    let requisitoEncontrado = this.requisitos.filter( item => item.codigo == codigo);
    
    if (requisitoEncontrado.length) {
      if (!requisitoEncontrado[0].observacoes.length) {
        let requisitoEncontradoExistentes = this.requisitosExistentes.filter( item => item.codigo == codigo);
        if (requisitoEncontradoExistentes.length) {
          requisitoEncontrado = requisitoEncontradoExistentes;
        }
      }
    }
    
    if (this.activatedRoute.snapshot.params["inscricao"] != null || this.numeroInscricao != null) {
      this.observacaoService
      .buscarPorInscricaoParaRequisito(this.numeroInscricao, requisitoEncontrado[0].codigo)
      .pipe(take(1))
      .subscribe( retorno => {
        let requisitoAuxiliar = new Requisito();
        
        if (this.codigoOrgao == "200") {
          requisitoAuxiliar = requisitoEncontrado[0];
        }
        
        requisitoAuxiliar.observacoes = retorno;
        
        const dialogRef = this.dialog.open(IcadModalComponent, {
          width: "70%",
          height: "80%",
          closeOnNavigation: true,
          data: {
              titulo: "Histórico do Requisito",
              componente: "historico-requisito",
              dados : [requisitoAuxiliar]
          }
        });
    
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result === "" || result === null)
                return false;
        });
      });
    }
    else {
      this.observacaoService
        .buscarPorProtocoloParaRequisito(this.numeroProtocolo, requisitoEncontrado[0].codigo)
        .pipe(take(1))
        .subscribe( retorno => {
          let requisitoAuxiliar = new Requisito();
          
          if (this.codigoOrgao == "200") {
            requisitoAuxiliar = requisitoEncontrado[0];
          }
          
          requisitoAuxiliar.observacoes = retorno;
          
          const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '70%',
            closeOnNavigation: true,
            data: {
                titulo: "Histórico do Requisito",
                componente: "historico-requisito",
                dados : [requisitoAuxiliar]
            }
          });
      
          dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
              if (result === "" || result === null)
                  return false;
          });
        });
    }

  }

  preencher(requisitos, protocolo) {
    this.requisitos = requisitos;

    this.requisitosExistentes = protocolo.viabilidade.requisitos;

    if (this.listaRequisitosObrigatorios == null) {
      this.listaRequisitosObrigatorios = new Set();
      this.requisitos.forEach(requisito => {
        if (requisito.obrigatorio) {
          this.listaRequisitosObrigatorios.add(requisito.codigo);
        }
      });
    }

    this.requisitos.forEach(requisito => {
      this.formulario.addControl(`requisito_${requisito.codigo}`, new FormControl(false));
      
      if ( (requisito.obrigatorio && !requisito.observacoes.length && this.listaRequisitosObrigatorios.has(requisito.codigo)) || this.requisitosExistentes.find(requisitoExistente => requisitoExistente.codigo == requisito.codigo && requisitoExistente.statusAvaliacao != Constantes.ACAO_ATENDIMENTO.Cancelar)) {
        this.formulario.controls[`requisito_${requisito.codigo}`].setValue(true);
        var requisitosExistentesAuxiliar = this.requisitosExistentes.find(requisitoExistente => requisitoExistente.codigo == requisito.codigo);
        requisito.possuiLicenciamento = requisitosExistentesAuxiliar != null ? requisitosExistentesAuxiliar.possuiLicenciamento : false;
        requisito.justificativa = requisito.observacoes.length ? requisito.observacoes[0].observacao : "";

        this.gerenciarObjetoRequisitosAdicionados(requisito);
      }

    })
  }

  gerenciarObjetoRequisitosAdicionados(requisito){
    var requisitoJaAdicionado = this.requisitosAdicionados.findIndex( requisitoAdicionado => { return requisito.codigo == requisitoAdicionado.codigo });
      if (requisitoJaAdicionado != -1) {
        this.requisitosAdicionados.splice(requisitoJaAdicionado, 1);
      }
      this.requisitosAdicionados.push(requisito);
  }

  retornarNomeIcone(codigo) {
    return this.requisitosAdicionados.find(requisito => requisito.codigo == codigo) ? 'check-square' : 'square';
  }

  retornarVencimento(codigo) {
    var existe = this.requisitosExistentes.filter(requisito => requisito.codigo == codigo);
    return existe.length ? existe[0].vencimento : "";
  }

  retornarStatus(codigo) {
    var existe = this.requisitosExistentes.filter(requisito => requisito.codigo == codigo);
    return existe.length ? existe[0].status : "";
  }

  retornarStatusAvaliacao(codigo) {
    var existe = this.requisitosExistentes.filter(requisito => requisito.codigo == codigo);
    return existe.length ? existe[0].statusAvaliacao : "";
  }

  retornarListaDeDocumentos(lista: Array<Documento>) {
    var listaRetorno = "";

    lista.forEach(documento => { listaRetorno = listaRetorno.concat(documento.nome, " - ") });
    listaRetorno = listaRetorno.substring(0, listaRetorno.length - 3);
    
    return listaRetorno;
  }

  detalharLicenciamentoContribuinte(requisito) {
    this.router.navigate(["/operacao/licenciamento", this.codigoMenuPai, this.codigoOrgao, this.codigoMenu, this.numeroInscricao, requisito.orgao.codigo, requisito.codigo]);
  }

}
