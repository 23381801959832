import { Atendimento } from "./atendimento";
import { Endereco } from "./endereco";
import { PerguntaResposta } from "./perguntaResposta";
import { FormaAtuacao } from "./formaAtuacao";
import { Zona } from "./zona";
import { Uso } from "./uso";
import { Atividade } from "./atividade";
import { Observacao } from "./observacao";
import { Requisito } from "./requisito";
import { Embasamento } from "./embasamento";

export class Viabilidade {
    cliente: string;
    inscricao: string;
    protocolo: string;
    automatizado: boolean;
    analisar: boolean;
    atendimentos: Array<Atendimento>;
    enderecos: Array<Endereco>;
    perguntasUsuario: PerguntaResposta;
    perguntasAuditor: PerguntaResposta;
    formasAtuacao: Array<FormaAtuacao>;
    tipoUnidade: string;
    tipoUnidadeAuxiliar: string;
    nomeUnidadeAuxiliar: string;
    observacaoUnidadeAuxiliar: string;
    descricaoAtividadeEconomica: string;
    zonas: Array<Zona>;
    usos: Array<Uso>;
    zonaUsos: Array<Zona>;
    atividadesExercidas: Array<Atividade>;
    atividadesNaoExercidas: Array<Atividade>;
    observacoes: Array<Observacao>;
    preRequisitos: Array<Requisito>;
    requisitos: Array<Requisito>;
    embasamentos: Array<Embasamento>;

    constructor() {
        this.cliente = "";
        this.inscricao = "";
        this.protocolo = "";
        this.automatizado = false;
        this.analisar = false;
        this.atendimentos = new Array<Atendimento>();
        this.enderecos = new Array<Endereco>();
        this.perguntasUsuario = new PerguntaResposta();
        this.perguntasAuditor = new PerguntaResposta();
        this.formasAtuacao = new Array<FormaAtuacao>();
        this.tipoUnidade = "";
        this.tipoUnidadeAuxiliar = "";
        this.nomeUnidadeAuxiliar = "";
        this.observacaoUnidadeAuxiliar = "";
        this.descricaoAtividadeEconomica = "";
        this.zonas = new Array<Zona>();
        this.usos = new Array<Uso>();
        this.zonaUsos = new Array<Zona>();
        this.atividadesExercidas = new Array<Atividade>();
        this.atividadesNaoExercidas = new Array<Atividade>();
        this.observacoes = new Array<Observacao>();
        this.preRequisitos = new Array<Requisito>();
        this.requisitos = new Array<Requisito>();
        this.embasamentos = new Array<Embasamento>();
    }
}