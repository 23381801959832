import { Component, Input, OnInit } from '@angular/core';
import { Protocolo } from "src/app/model/protocolo";
import { Constantes } from "src/app/model/constantes";
import { RedeSimService } from '../../../../services/redeSim/redeSim.service';
import { RedeSim } from '../../../../model/redeSim';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-informacao-rede-sim',
  templateUrl: './informacao-rede-sim.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoRedeSimComponent implements OnInit {

  @Input() protocolo: Protocolo;
  @Input() tipoEmpresa: string = "";
  @Input() semCardHeader: boolean = false;
  @Input() executarBuscaDadosRedeSim: boolean = false;
  
  tipoTelefone = Constantes.TIPO_DE_TELEFONE;
  tipoDocumento = Constantes.TIPO_DE_DOCUMENTO;
  tipoDocumentoDescricao = Constantes.TIPO_DE_DOCUMENTO_DESCRICAO;

  nomeUsuario: string;
  documentoUsuario: string;
  dataAtual: Date;
  emailUsuario: string;
  dadosAuditor : boolean;
  tipoDeEmpresa: string;

  constructor(private redeSimService: RedeSimService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog) {}

  ngOnInit(): void {
    
    if(this.executarBuscaDadosRedeSim){
      this.buscarDadosRedeSim();
    }

  }

  buscarDadosRedeSim(){
    this.redeSimService
      .buscarPorProtocolo(localStorage.getItem("pId"),this.protocolo.protocolo)
      .pipe(take(1))
      .subscribe(retorno => {
        this.protocolo.redeSim = retorno;
        console.log(this.protocolo.redeSim);
      })
      
  }

  questionarioLicenciamento(){
    let dados = [];
    dados.push(this.protocolo);
    dados.push(this.tipoDeEmpresa);
    dados.push(this.semCardHeader);
    dados.push(this.executarBuscaDadosRedeSim);
    this.openDialog("informacao-rede-sim-perguntas", dados);

    /*const url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["informacao-rede-sim-perguntas"], 
        {
          relativeTo: this.activatedRoute, 
          queryParams: {
            protocolo: JSON.stringify(this.protocolo),
            tipoEmpresa: this.tipoDeEmpresa,
            semCardHeader: false,
            executarBuscaDadosRedeSim: true
          }
        }
      )
    );
    
    this.router.navigate([]).then(result => { window.open("#" + url, '_blank') });*/
    
  }

  openDialog(componente, dados) {

    const dialogRef = this.dialog.open(IcadModalComponent, {
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        maxHeight: 'none',
        closeOnNavigation: true,
        data: {
            componente: componente,
            dados: dados
        }
    });

    // dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
  }


}
