import { Login } from "./login";
import { NaturezaJuridica } from "./naturezaJuridica";

export class Socio {
    cliente: string;
    protocolo: string;
    inscricao: string;
    login: Login;
    tipo: string;
    participacao: string;
    qualificacao: string;
    qualificacaoNome: string;
    responsavelLegal: boolean;
    contador: boolean;
    naturezaJuridica: NaturezaJuridica;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.inscricao = "";
        this.login = new Login();
        this.tipo = "";
        this.participacao = "0";
        this.qualificacao = "";
        this.qualificacaoNome = "";
        this.responsavelLegal = false;
        this.contador = false;
        this.naturezaJuridica = new NaturezaJuridica();
    }
}