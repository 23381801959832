import { ConsultaViabilidadeAtividade } from './consultaViabilidadeAtividade';
import { ConsultaViabilidadeUso } from './consultaViabilidadeUso';
import { ConsultaViabilidadeRequisito } from './consultaViabilidadeRequisito';
import { ConsultaViabilidadeZona } from './ConsultaViabilidadeAtividadeZona';

export class ConsultaViabilidadeEndereco {
    cliente: string;
    codigo: string;
    protocolo: string;
    inscricao: string;
    inscricaoFormatada: string;
    cep: string;
    tipoLogradouro: string;
    tituloLogradouro: string;
    codigoLogradouro: string;
    logradouro: string;
    numero: string;
    complemento: string;
    codigoBairro: string;
    bairro: string;
    cidade: string;
    estado: string;
    pais: string;
    pontoReferencia: string;
    latitude: string;
    longitude: string;
    area: string;
    tipoEndereco: number;
    tipoBusca: string;
    baseUtilizada: string;
    atividades: Array<ConsultaViabilidadeAtividade>;
    uso: ConsultaViabilidadeUso;
    requisito: ConsultaViabilidadeRequisito;
    zonas: Array<ConsultaViabilidadeZona>;
    tipoNegocio: string;
    vidaNegocio: string;
    areaUtilizada: string;
    qtdeFuncionarios: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.protocolo = "";
        this.inscricao = "";
        this.inscricaoFormatada = "";
        this.cep = "";
        this.tipoLogradouro = "";
        this.tituloLogradouro = "";
        this.codigoLogradouro = "";
        this.logradouro = "";
        this.numero = "";
        this.complemento = "";
        this.codigoBairro = "";
        this.bairro = "";
        this.cidade = "";
        this.estado = "";
        this.pais = "";
        this.pontoReferencia = "";
        this.latitude = "";
        this.longitude = "";
        this.area = "";
        this.tipoEndereco = 0;
        this.tipoBusca = "";
        this.baseUtilizada = "";
        this.atividades = new Array<ConsultaViabilidadeAtividade>();
        this.uso = new ConsultaViabilidadeUso();
        this.requisito = new ConsultaViabilidadeRequisito();
        this.zonas = new Array<ConsultaViabilidadeZona>();
        this.tipoNegocio = "";
        this.vidaNegocio = "";
        this.areaUtilizada = "";
        this.qtdeFuncionarios = "";
    }
}