<div class="container-principal">
    <form
        name="formAlteracao"
        [formGroup]="formAlteracao"
        (ngSubmit)="gerarAlteracao()"
    >
    
        <div class="form-row">
            <div class="col-12 d-flex justify-content-center mt-4">
                <h4>
                    {{ informativo }}
                </h4>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-12 mt-4">
                <mat-horizontal-stepper 
                    labelPosition="bottom" 
                    linear
                    #passos
                    (selectionChange)="mudancaPasso($event, passos)">
                    <!-- Requerente -->
                    <mat-step [stepControl]="formularioIdentificacao">
                        <ng-template matStepLabel>Identificação do Requerente</ng-template>
                        <app-alert type="danger"
                            *ngIf="emitirErroIdentificacao" >
                            <div [innerHtml]="mensagemErro"></div>
                        </app-alert>
                        <app-form-requerente 
                            (acaoDeAtendimento)="atualizarTipoEmpresa($event)"
                            [servicos]="servicos"
                        ></app-form-requerente>
                        <app-form-identificacao></app-form-identificacao>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <button 
                                    (click)="avancarPassoIdentificacao(passos)"
                                    type="button" 
                                    class="btn btn-success" 
                                    mat-button>
                                    Avançar
                                </button>
                            </div>
                        </div>
                    </mat-step>

                    <!-- Identificação -->
                    <mat-step [stepControl]="formularioIdentificacaoNegocio">
                        <ng-template matStepLabel>Identificação do Negócio</ng-template>
                        <app-form-identificacao-negocio
                            [documentos]="documentos"></app-form-identificacao-negocio>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 text-left">
                                <button 
                                    type="button" 
                                    class="btn btn-danger" 
                                    mat-button
                                    matStepperPrevious>
                                    Anterior
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button 
                                    (click)="avancarPassoIdentificacaoNegocio()"
                                    type="button" 
                                    class="btn btn-success" 
                                    mat-button>
                                    Avançar
                                </button>
                            </div>
                        </div>
                    </mat-step>
                    
                    
                    <!-- Localização -->
                    <mat-step [stepControl]="formularioLocalizacao">
                        <ng-template matStepLabel>Localização do Negócio</ng-template>
                        <app-form-localizacao (renomearBotao)="renomearBotaoLocalizacao()"></app-form-localizacao>
                        <br>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 text-left">
                                <button 
                                    (click)="voltarPassoLocalizacao()"
                                    type="button" 
                                    class="btn btn-danger" 
                                    mat-button>
                                    {{ btnAnteriorLocalizacao }}
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button 
                                    (click)="avancarPassoLocalizacao()"
                                    type="button"
                                    class="btn btn-success" 
                                    mat-button >
                                    {{ btnAvancarLocalizacao }}
                                </button>
                            </div>
                        </div>
                    </mat-step>
                
                    <!-- Atividade e Operação -->
                    <mat-step [stepControl]="formularioAtividade">
                        <ng-template matStepLabel>Atividade e Operação do Negócio</ng-template>
                        <app-form-atividade></app-form-atividade>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 text-left">
                                <button 
                                    type="button" 
                                    class="btn btn-danger" 
                                    mat-button
                                    matStepperPrevious>
                                    Anterior
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button 
                                    (click)="avancarPassoAtividade()"
                                    type="button"
                                    class="btn btn-success" 
                                    mat-button>
                                    Avançar
                                </button>
                            </div>
                        </div>
                    </mat-step>

                    <!-- Atividade e Operação -->
                    <mat-step>
                        <ng-template matStepLabel>Requisitos</ng-template>
                        <app-atendimento-requisito></app-atendimento-requisito>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 text-left">
                                <button 
                                    type="button" 
                                    class="btn btn-danger" 
                                    mat-button
                                    matStepperPrevious>
                                    Anterior
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button 
                                    type="button"
                                    class="btn btn-success" 
                                    mat-button
                                    matStepperNext>
                                    Avançar
                                </button>
                            </div>
                        </div>
                    </mat-step>

                    <!-- Responsável Contábil, Sócios e Administradores -->
                    <mat-step [stepControl]="formularioResponsavel">
                        <ng-template matStepLabel>Responsável Contábil, Sócios e Administradores</ng-template>
                        <app-form-responsavel (renomearBotao)="renomearBotaoResponsavel()"></app-form-responsavel>
                        <br>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 text-left">
                                <button 
                                    (click)="voltarPassoResponsavel()"
                                    type="button" 
                                    class="btn btn-danger" 
                                    mat-button>
                                    {{ btnAnteriorResponsavel }}
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button 
                                    (click)="avancarPassoResponsavel()"
                                    type="button"
                                    class="btn btn-success" 
                                    mat-button>
                                    {{ btnAvancarResponsavel }}
                                </button>
                            </div>
                        </div>
                    </mat-step>

                    <!-- Questionário -->
                    <mat-step [stepControl]="formularioQuestionarioViabilidade">
                        <ng-template matStepLabel>Questionário Tributário e Mobiliário</ng-template>
                        
                        <app-form-questionario #formQuestionarioViabilidade [tipo]="tipoPergunta.Viabilidade" titulo="Consulta Prévia"></app-form-questionario>
                        <app-form-questionario #formQuestionarioFic [tipo]="tipoPergunta.Fic" titulo="Dados Cadastrais"></app-form-questionario>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 text-left">
                                <button 
                                    type="button" 
                                    class="btn btn-danger" 
                                    mat-button
                                    matStepperPrevious>
                                    Anterior
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button 
                                    (click)="avancarPassoQuestionario()"
                                    type="button"
                                    class="btn btn-success" 
                                    mat-button 
                                    matStepperNext>
                                    Avançar
                                </button>
                            </div>
                        </div>
                    </mat-step>
                    <!-- Conclusão -->
                    <mat-step>
                        <ng-template matStepLabel>Conclusão da Solicitação</ng-template>
                        <app-form-resumo [tipoEmpresa]="tipoEmpresa" [processo]="tipoProcesso.Consulta_Previa_E_Dados_Cadastrais"></app-form-resumo>
                        <br>
                        <div class="form-row">
                            <div class="col-12 text-right">
                                <mat-divider></mat-divider>
                                <br>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6 text-left">
                                <button 
                                    type="button" 
                                    class="btn btn-danger" 
                                    mat-button 
                                    matStepperPrevious>
                                    Anterior
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button 
                                    type="submit" 
                                    class="btn btn-success"
                                    title="salvar"
                                >
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
    </form>
</div>