import { take } from "rxjs/operators";
import { MatSort, SortDirection } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginatorIntl, MatPaginator } from "@angular/material/paginator";
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';

import { Grid } from "src/app/model/grid";
import { GridProviderPaginacao } from "src/app/components/grid/grid-provider-paginacao";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { AtendimentoService } from "src/app/services/operacao/atendimento.service";

import { MatDialog } from "@angular/material/dialog";
import { IcadModalComponent } from "src/app/components/modal/icad-modal/icad-modal.component";
import { FormGroup, FormBuilder } from "@angular/forms";
import { GridFiltro } from "src/app/model/gridFiltro";
import { GridOpcao } from "src/app/model/gridOpcao";
import { of } from "rxjs";
import Swal from "sweetalert2";
import { Constantes } from '../../../../model/constantes';
import { formatToCPFOrCNPJ } from 'brazilian-values';

@Component({
  selector: 'app-atendimento-grid-consulta',
  templateUrl: './atendimento-grid-consulta.component.html',
  styleUrls: ['./atendimento-grid-consulta.component.scss'],
  providers: [ 
      {
          provide: MatPaginatorIntl,
          useClass: GridProviderPaginacao
      }
  ]
})
export class AtendimentoGridConsultaComponent implements OnInit {
  
  grid: Grid;
  filtroOpcoes: Array<GridOpcao>;
  dados: Array<any>;
  abas: Array<any>;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = ["inscricao","ultimoProtocolo","razaoSocial","cpfCnpj","situacaoEmpresaDescricao","dataAbertura","dataEncerramento","menu"];
  formulario: FormGroup;
  codigoMenuPai: number;
  permitirAtendimento: boolean;
  codigoMenu: number;
  eixoConfiguracao: boolean

  moduloTaxa: boolean = false;

  abaInicial: number = 0;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Output() acaoMenuAtendimento = new EventEmitter();

  acaoAtendimento = Constantes.ACAO_ATENDIMENTO;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private atendimentoService: AtendimentoService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService) { 
      this.grid = new Grid();
      this.grid.filtro.push(new GridFiltro());

      this.codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];
      this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
      this.formulario = this.formBuilder.group({
        tipoBusca: [''],
        tipoBuscaValorInput: [''],
        tipoBuscaValorSelect: [''],
      });    
      let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
      this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !meusNegocios);
      this.eixoConfiguracao = this.router.url.split("/")[1] == "configuracao";
    }

  ngOnInit(): void {
    
  }

  atualizarTabela() {
    this.dataSource = new MatTableDataSource(this.grid.dados);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortData = (data, sort) => this.ordenacaoGrid(data, sort);
    this.grid.campos.forEach(item => {
      if (item.ativoOrdenacao == 'S') {
        this.sort.active = item.nmCampoTabela;
        this.sort.direction = <SortDirection>item.ordemAscDesc.toLowerCase();
        item.ativoOrdenacao = 'N';
      }
    });

    this.dataSource.sort = this.sort;
  }

  formatToCPFOrCNPJ(valor) {
    return formatToCPFOrCNPJ(valor.toString());
  }

  aplicarFiltro(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  visualizarProtocolo(row) {
    this.router.navigate([this.router.url, "visualizar-protocolo", row.ultimoProtocolo], { queryParams: {}})
  }

  visualizarLancamentos(row) {
    this.acaoMenuAtendimento.emit({ inscricao: row.inscricao, protocolo: row.ultimoProtocolo });
  }

  visualizarEmpresa(row) {
    if (row.ultimoProtocolo == "") {
      this.router.navigate([this.router.url, "visualizar-empresa", row.inscricao], { queryParams: {}})
    }
    else {
      this.router.navigate([this.router.url, "visualizar-empresa", row.inscricao, row.ultimoProtocolo], { queryParams: {}})
    }
  }
  
  get tipoBusca() {
    return this.formulario.get("tipoBusca");
  }  
  set tipoBusca(tipoBusca) {
    this.formulario.get("tipoBusca").setValue(tipoBusca);
  }

  get tipoBuscaValorInput() {
    return this.formulario.get("tipoBuscaValorInput");
  }  
  set tipoBuscaValorInput(tipoBuscaValorInput) {
    this.formulario.get("tipoBuscaValorInput").setValue(tipoBuscaValorInput);
  }

  get tipoBuscaValorSelect() {
    return this.formulario.get("tipoBuscaValorSelect");
  }  
  set tipoBuscaValorSelect(tipoBuscaValorSelect) {
    this.formulario.get("tipoBuscaValorSelect").setValue(tipoBuscaValorSelect);
  }

  ordenacaoGrid(data, sort) {
    if (sort.active == null)
        return data;

    let campo = sort.active;
    
    if (campo == "adicionado") {
      data.sort( (item_1, item_2) => (new Date(item_1[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) > new Date(item_2[campo].replace(" ","/").split("/").reverse().toString().replace(",", " ")) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (campo == "dataAbertura" || campo == "dataEncerramento") {
        data.sort( (item_1, item_2) => (new Date(item_1[campo].split("/").reverse().toString()) > new Date(item_2[campo].split("/").reverse().toString()) ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (campo == "inscricao") {
        data.sort( (item_1, item_2) => (item_1[campo] > item_2[campo] ? 1 : -1) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (typeof(data[0][campo]) == typeof("0")) {
        data.sort( (item_1, item_2) => (item_1[campo]).localeCompare(item_2[campo])  * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    else if (typeof(data[0][campo]) == typeof(0)) {
        data.sort( (item_1, item_2) => ( ( isNaN(parseInt(item_1[campo])) ? 0 : item_1[campo] ) - ( isNaN(parseInt(item_2[campo])) ? 0 : item_2[campo] ) ) * (sort.direction == "asc" ? 1 : sort.direction == "desc" ? -1 : 0));
    }
    
    return data;
  }
}
