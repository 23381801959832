<div class="row justify-content-center align-items-center header-sub-titulo">
    <div class="row col-12" >
        <p class="card-text titulo-sub-titulo">
            <strong>Empresas Ativas no Mesmo Imível</strong>
        </p>
    </div>
</div>
<div class="layout-px-spacing">
    <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="mail-box-container-historico-requisito">
                <div class="mail-overlay"></div>
                <div id="todo-inbox" class="accordion todo-inbox">
                    <div class="search">
                        <input #filtro
                            type="text"
                            (input)="acaoDoFiltro(filtro.value)"
                            class="form-control input-search" placeholder="Localizar por aqui...">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu mail-menu d-lg-none"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                    </div>            
                    <div class="todo-box">                        
                        <div id="ct" class="todo-box-scroll" [perfectScrollbar]="config" >
                            <div *ngFor="let imobiliarioEmpresaProtocolo of imobiliarioEmpresasProtocolos; let i = index"
                                class="todo-item all-list">
                                <div class="todo-item-inner">
                                    <div class="todo-content">
                                        <h3 class="todo-heading" [attr.data-todoHeading]="imobiliarioEmpresaProtocolo.nome">
                                            <strong>{{ imobiliarioEmpresaProtocolo.nome }}</strong>
                                        </h3>
                                        <p class="todo-text" 
                                            [attr.data-todoHtml]="'<p>' + imobiliarioEmpresaProtocolo.Inscricao + '</p>'" >
                                            Inscrição: {{ imobiliarioEmpresaProtocolo.inscricao }}
                                        </p>
                                        <p class="todo-text" 
                                            [attr.data-todoHtml]="'<p>' + imobiliarioEmpresaProtocolo.complemento + '</p>'" >
                                            Complemento: {{ imobiliarioEmpresaProtocolo.complemento }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>                                    
            </div>
            
        </div>
    </div>

</div>