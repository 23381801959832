<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Redesim - Questionário de Licenciamento</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolo != null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center" *ngFor="let redeSimPergunta of protocolo.redeSim.redeSimPerguntas">
            <div class="col-10">
                <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center" >
                    <div class="col-12">
                        <p class="card-text"
                        [class.valor-nao-cadastrado]="redeSimPergunta.pergunta == ''">
                        <strong>Orgâo Licenciador</strong> {{ redeSimPergunta.orgaoNome == '' ? 'Não Cadastrado' : redeSimPergunta.orgaoNome }}
                        </p>
                    </div>
                </div>
                <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
                    <div class="col-12">
                        <p class="card-text"
                        [class.valor-nao-cadastrado]="redeSimPergunta.pergunta == ''">
                        <strong>Pergunta</strong> {{ redeSimPergunta.pergunta == '' ? 'Não Cadastrado' : redeSimPergunta.pergunta }}
                        </p>
                    </div>
                </div>
                <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center" >
                    <div class="col-12">
                        <p class="card-text"
                        [class.valor-nao-cadastrado]="redeSimPergunta.pergunta == ''">
                        <strong>Resposta</strong> {{ redeSimPergunta.resposta == '' ? 'Não Cadastrado' : redeSimPergunta.resposta }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>