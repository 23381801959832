<!-- NOVA AÇÃO -->
<div class="row col-12 layout-top-spacing p-3">
    <div class="row col-12 top-atendimento">
        <div class="col-lg-12 col-12 layout-spacing">
            <div class="row container-principal-atendimento">
                <div class="card w-100 mb-0">
                  <div class="card-header card-header-localizacao">
                      <div class="col-12">
                          <strong>Aqui você localiza os dados a sua escolha</strong>
                      </div>      
                  </div>
                  <div class="card-body card-body-localizacao">
                    <form
                        class="justify-content-center align-items-center"
                        name="formulario"
                        [formGroup]="formulario"
                    >
                      <div class="row">
                        <div class="col-4">
                          <select 
                              #tipoBuscaSelect
                              class="form-control form-control-sm"
                              (change)="acaoSelectTipoBusca()"
                              formControlName="tipoBusca"
                              name="tipoBusca" 
                              id="tipoBusca"                
                          >
                              <option disabled value="">--- Selecione ---</option>
                              <option *ngFor="let filtro of grid.filtro; let i = index"
                                [value]="i" 
                                >{{ filtro.display }}
                              </option>
                          </select>
                        </div>
                        <div class="col-7">
                          <input [class.hidden]="tipoBusca.value != '' ? grid.filtro[tipoBusca.value].tipoCampo != 'INPUT' : true "
                              #tipoBuscaValorInput
                              class="form-control form-control-sm"
                              formControlName="tipoBuscaValorInput"
                              id="tipoBuscaValorInput"
                              name="tipoBuscaValorInput"                
                              type="text"
                          />
                          <select [class.hidden]="tipoBusca.value != '' ? grid.filtro[tipoBusca.value].tipoCampo != 'SELECT' : true"
                            #tipoBuscaValorSelect
                            formControlName="tipoBuscaValorSelect"
                            class="form-control form-control-sm"
                            name="tipoBuscaValorSelect" 
                            id="tipoBuscaValorSelect"                
                          >
                            <option value=""></option>
                            <option *ngFor="let opcao of filtroOpcoes" 
                                value="{{ opcao.valor }}"
                            >
                                {{ opcao.display }}
                            </option>
                          </select>
                        </div>
                        <div class="col-1 text-right">
                          <button 
                              type="button" 
                              class="btn btn-secondary"
                              title="Buscar"
                              (click)="buscarGridPorFiltro()"
                              [disabled] = "tipoBuscaValorSelect.value == '' && tipoBuscaValorInput == ''"
                          >
                          Buscar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row col-12" *ngIf="emitirErro">
        <div class="col-lg-12 col-12 layout-spacing pt-3 pb-0">
            <app-alert type="danger">
                <div [innerHtml]="mensagemErro"></div>
            </app-alert>
        </div>
    </div>
    
    <div class="row col-12" *ngIf="!exibirLista && !exibirProtocolo && !exibirEmpresa">
        <div class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal container-tamanho row col-12 justify-content-center align-items-center">
                <div class="row">
                    <div class="row col-12 justify-content-center align-items-center">
                        <svg class="logo">
                            <use
                                class="logo_icon"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="#_logo_iCad_Completo"
                            ></use>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row col-12" [class.hidden]="!exibirProtocolo">
        <div class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-informacao-situacao></app-informacao-situacao>
                <app-informacao-requerente [tipoEmpresa]="tipoEmpresa"></app-informacao-requerente>
                <app-informacao-rede-sim *ngIf="consultaGeral.protocolos.length && consultaGeral.protocolos[0].redeSim.protocoloRedeSim != ''"></app-informacao-rede-sim>
                <app-informacao-identificacao #informacaoIdentificacaoComponentProtocolo [tipoEmpresa]="tipoEmpresa"></app-informacao-identificacao>
                <app-linha-do-tempo></app-linha-do-tempo>
                <app-atendimento-requisito #atendimentoRequisitoComponentProtocolo></app-atendimento-requisito>
                <app-anexar #anexarProtocolo [dados]="[numeroProtocolo, numeroInscricao, orgao, 'ATENDIMENTO']" ></app-anexar>
                <app-conta-corrente #contaCorrenteProtocolo [numeroProtocolo]="numeroProtocolo" [numeroInscricao]="numeroInscricao" *ngIf="moduloTaxaLiberado"></app-conta-corrente>
                <div class="row col-12 justify-content-center align-items-center mt-5 mb-5">
                    <button class="btn btn-primary p-2" (click)="visualizarProtocolo()">
                        <svg class="icone-grid-menu m-2">
                            <use attr.xlink:href="assets/sprite.svg#{{ 'search' }}"></use>
                        </svg>  
                        DETALHAR DADOS DO PROTOCOLO
                    </button>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row col-12" [class.hidden]="!exibirEmpresa">
        <div class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-informacao-situacao-empresa></app-informacao-situacao-empresa>
                <app-informacao-identificacao #informacaoIdentificacaoComponentEmpresa [tipoEmpresa]="tipoEmpresa"></app-informacao-identificacao>
                <app-informacao-rede-sim *ngIf="consultaGeral.protocolos.length && consultaGeral.protocolos[0].redeSim.protocoloRedeSim != ''"></app-informacao-rede-sim>
                <app-informacao-localizacao></app-informacao-localizacao>
                <app-informacao-atividade></app-informacao-atividade>
                <app-informacao-responsavel permiteCriticar="false"></app-informacao-responsavel>
                <app-atendimento-requisito #atendimentoRequisitoComponentEmpresa></app-atendimento-requisito>
                <app-informacao-documento></app-informacao-documento>
                <app-informacao-protocolos></app-informacao-protocolos>
                <app-anexar #anexarEmpresa [dados]="['', numeroInscricao, orgao, 'ATENDIMENTO']" ></app-anexar>
                <app-conta-corrente #contaCorrenteEmpresa [numeroProtocolo]="numeroProtocolo" [numeroInscricao]="numeroInscricao" *ngIf="moduloTaxaLiberado"></app-conta-corrente>
                <div class="row col-12 justify-content-center align-items-center mt-5 mb-5">
                    <button class="btn btn-primary p-2" (click)="visualizarEmpresa()">
                        <svg class="icone-grid-menu m-2">
                            <use attr.xlink:href="assets/sprite.svg#{{ 'search' }}"></use>
                        </svg>
                        DETALHAR DADOS DO NEGÓCIO
                    </button>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row col-12" [class.hidden]="!exibirLista">
        <div class="col-lg-12 col-12 layout-spacing">
            <div class="container-principal-atendimento">
                <app-atendimento-grid-consulta class="w-100"></app-atendimento-grid-consulta>
            </div>
        </div>
    </div>
</div>
