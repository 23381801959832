<li
  class="nav-item dropdown user-profile-dropdown"
  [class.show]="showEixosDropdown"
>
  <div ngbDropdown class="d-inline-block">
    <a
      href="javascript:void(0);"
      class="nav-link dropdown-toggle user"
      id="userProfileDropdown"
      title="Eixos"
      ngbDropdownToggle
    >
      <svg class="icone-header">
        <use
          class=""
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#squares2"
        ></use>
      </svg>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <div *ngFor="let eixo of eixos; let i = index" class="dropdown-item">
        <a
          class="cursor-pointer"
          (click)="setAxis(i)"
          [routerLink]="['/' + eixo.name]"
        >
          <svg>
            <use
              class="iconeMenu"
              attr.xlink:href="#{{ eixo.icone }}"
            ></use>
          </svg>
          {{ eixo.descricao }}</a
        >
      </div>
    </div>
  </div>
</li>
