import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Constantes } from "src/app/model/constantes";
import { Pendencia } from "src/app/model/pendencia";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { AcompanhamentoService } from "src/app/services/operacao/acompanhamento.service";
import { take, filter } from 'rxjs/operators';
import { AtendimentoService } from '../../../../services/operacao/atendimento.service';
import { AnexarComponent } from '../../anexar/anexar.component';
import { Protocolo } from '../../../../model/protocolo';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { Anexo } from '../../../../model/anexo';
import { Empresa } from '../../../../model/empresa';
import Swal from "sweetalert2";
import { Atendimento } from 'src/app/model/atendimento';
import { Licenciamento } from '../../../../model/licenciamento';
import { MatCheckbox } from '@angular/material/checkbox';
import * as moment from 'moment';

@Component({
  selector: 'app-atendimento-pendencia',
  templateUrl: './atendimento-pendencia.component.html',
  styleUrls: ['./atendimento-pendencia.component.scss']
})
export class AtendimentoPendenciaComponent implements OnInit {

    @Output() pendenciaRespondida = new EventEmitter();
    @Input() dados;
    formulario: FormGroup;
    pendencia: Pendencia;
    pendenciaResposta: Pendencia;
    protocolo: Protocolo;
    empresa: Empresa;
    licenciamento: Licenciamento;
    anexos: Array<Anexo>;

    anexou: boolean = false;
    exibeObservacao: boolean = true;
    parecerAuditor: string = "";
    
    @ViewChild(AnexarComponent)
    anexarComponent: AnexarComponent;

    tipoDePendencia = Constantes.TIPO_DE_PENDENCIA;

    constructor(
        private arquivoService: ArquivoService,
        private formBuilder: FormBuilder,
        private acompanhamentoService: AcompanhamentoService,
        private atendimentoService: AtendimentoService) {

        this.pendencia = new Pendencia();
        this.pendenciaResposta = new Pendencia();
        this.protocolo = new Protocolo();

        this.formulario = this.formBuilder.group({
            resposta: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(50)]]
        });

    }

    ngOnInit() {
        this.pendencia = Object.assign(this.pendencia, this.dados[0]);
        this.pendenciaResposta = Object.assign(this.pendenciaResposta, this.dados[0]);
        this.protocolo = this.dados[1];
        this.licenciamento = this.dados[3];

        if (this.pendencia.codigoTipo != this.tipoDePendencia.Pendente_Usuario_Requisito.toString()) {
            this.formulario.clearValidators();
        }

        if (this.pendencia.codigoTipo == Constantes.TIPO_DE_PENDENCIA.Providenciar_Anexo.toString()) {
            this.buscarAnexos();
        }

        if (this.pendencia.codigoTipo == Constantes.TIPO_DE_PENDENCIA.Questionario_De_Licenciamento.toString()) {
            this.empresa = this.dados[1];
        }
    }

    buscarAnexos() {
        if (this.protocolo.protocolo != "") {            
            this.arquivoService
                .buscarPorProtocolo(localStorage.getItem("pId"), this.protocolo.protocolo)
                .pipe(take(1))
                .subscribe( (anexos: Array<Anexo>) => {
                    this.anexos = anexos.filter( item => item.codigoPendencia != "");
                });
        }
        else {
            this.arquivoService
                .buscarPorInscricao(localStorage.getItem("pId"), this.empresa.inscricao)
                .pipe(take(1))
                .subscribe( (anexos: Array<Anexo>) => {
                    this.anexos = anexos.filter( item => item.codigoPendencia != "");
                });
        }
    }

    verificaAnexo(semAnexo: MatCheckbox){
        if(semAnexo.checked){
            this.anexou = true;
        }
        else if(!semAnexo.checked){
            this.anexou = false;
        }
    }

    
    acaoBotaoEnviarParaAnalise(pendencia: Pendencia) {

        if(pendencia.codigoTipo == Constantes.TIPO_DE_PENDENCIA.Providenciar_Anexo.toString()){
            this.anexarComponent.anexos.forEach( anexo => {
                if(moment(anexo.dataInicio,'DDMMYYYY') >= moment(pendencia.data,'DDMMYYYY')){
                    this.anexou = true;
                    this.exibeObservacao = false; 
                }
            });
        }

        if(pendencia.codigoTipo == Constantes.TIPO_DE_PENDENCIA.Providenciar_Anexo.toString()){
            if(!this.anexou){
                Swal.fire({
                    title: "Atenção",
                    html: `Caro contribuinte, para responder a pendência é necessário preencher o campo Resposta e fazer os anexos solicitados ou selecione a opção para enviar nenhum anexo.</b>`,
                    icon: "error",
                })
                return false;
                
            }
            if(this.formulario.invalid){
                Swal.fire({
                    title: "Atenção",
                    html: `Caro contribuinte, jusfique no campo resposta o seu envio.</b>`,
                    icon: "error",
                })
                return false;
            }      
         
        }
        
        if (pendencia.codigoTipo == Constantes.TIPO_DE_PENDENCIA.Pendente_Usuario_Requisito.toString()) {
            pendencia.descricaoTipo = this.resposta.value;
        }
        
        this.montarPendencia();
        
        this.acompanhamentoService
            .responderPendencia(this.pendenciaResposta)
            .pipe(take(1))
            .subscribe( retorno => {
                if (retorno) {
                    this.pendenciaRespondida.emit({ pendencia: retorno });
                }
        })
        
    }

    licenciamentoAtendido(){
        this.pendenciaRespondida.emit({ pendencia: true });
    }

    montarPendencia() {
        let orgaoDestino = this.pendenciaResposta.fluxos[0].orgaoDestino;
        let orgaoOrigem = this.pendenciaResposta.fluxos[0].orgaoOrigem;
        this.pendenciaResposta.fluxos[0].orgaoDestino = orgaoOrigem;
        this.pendenciaResposta.fluxos[0].orgaoOrigem = orgaoDestino;
        this.pendenciaResposta.fluxos[0].observacao = this.resposta.value;
    }

    acaoBotaoAprovar(pendencia: Pendencia) {

    }

    acaoBotaoRejeitar(pendencia: Pendencia) {

    }

    acaoBotaoDevolver(pendencia: Pendencia) {

    }

    acaoBotaoAceitarRejeitarAnexo(anexo: Anexo, aceitarRejeitar) {
        let atendimento = new Atendimento();
        atendimento.parecer = aceitarRejeitar ? "S" : "N";
        anexo.atendimento = atendimento;

        this.arquivoService
            .atenderAnexo(anexo)
            .pipe(take(1))
            .subscribe( retorno => {
                if (retorno != "") {
                    Swal.fire({
                        title: "Atendimento",
                        html: `Caro Auditor, você analisou o arquivo: <b>${ anexo.nome }</b>, com sucesso.`,
                        icon: "success",
                    })
                }
                else {
                    anexo.atendimento = new Atendimento();
                    Swal.fire({
                        title: "Não Atendimento",
                        html: `Caro Auditor, não foi possível salvar a analise do arquivo: <b>${ anexo.nome }</b>.`,
                        icon: "error",
                    })
                }
            });
    }

    baixarArquivo(codigo, nome) {
        this.arquivoService.downloadArquivoPorCodigo(codigo)
        .subscribe((res: any) => {
            this.arquivoService.handleFile(res, nome + '.pdf');
        });
    }

    get resposta() {
        return this.formulario.get("resposta");
    }
    set resposta(resposta) {
        this.formulario.get("resposta").setValue(resposta);
    }

}
