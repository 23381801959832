<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card" id="div-dados-responsavel">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Dados do Responsável</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="col-sm-12 col-form-label" for="cpfCnpj">CPF / CNPJ</label>
                    <input
                        #inputCpfCnpj
                        (input)="formatToCPFOrCNPJ(inputCpfCnpj.value)"
                        (blur)="verificaUsuario()"
                        [class.is-invalid]="cpfCnpj.invalid && cpfCnpj.touched"
                        autofocus
                        class="form-control form-control-sm col-4"
                        formControlName="cpfCnpj"
                        id="cpfCnpj"
                        name="cpfCnpj"
                        type="text"
                        maxlength="18"
                    />
                    <div class="mb-1" *ngIf="cpfCnpj.invalid && cpfCnpj.touched">
                        <span 
                            class="erro" 
                            *ngIf="cpfCnpj.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="cpfCnpj.errors.minlength"
                        >O CPF / CNPJ deve conter ao menos 14 caracteres.</span>
                        <span 
                            class="erro" 
                            *ngIf="cpfCnpj.errors.maxlength"
                        >O CPF / CNPJ deve conter até 18 caracteres.</span>
                        <span 
                            class="erro" 
                            *ngIf="cpfCnpj.hasError('mensagem')"
                        >{{cpfCnpj.errors.mensagem}}</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="nome">Nome</label>
                    <input
                        [class.is-invalid]="nome.invalid && nome.touched"
                        class="form-control form-control-sm"
                        formControlName="nome"
                        id="nome"
                        name="nome"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="nome.invalid && nome.touched">
                        <span 
                            class="erro" 
                            *ngIf="nome.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-6">
                    <label class="col-sm-12 col-form-label" for="email">E-mail</label>
                    <input
                        [class.is-invalid]="email.invalid && email.touched"
                        class="form-control form-control-sm"
                        formControlName="email"
                        id="email"
                        name="email"
                        type="text"
                    />
                    <div class="mb-1" *ngIf="email.invalid && email.touched">
                        <span 
                            class="erro" 
                            *ngIf="email.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="email.errors.email"
                        >O valor digitado não é um e-mail válido.</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="telefoneFixo">Telefone Fixo</label>
                    <input
                        #telefoneFixoInput
                        (input)="formatToPhone(telefoneFixoInput)"
                        class="form-control form-control-sm"
                        formControlName="telefoneFixo"
                        id="telefoneFixo"
                        name="telefoneFixo"
                        type="text"
                        maxlength="14"
                    />
                </div>
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="telefoneComercial">Telefone Comercial</label>
                    <input
                        #telefoneComercialInput
                        (input)="formatToPhone(telefoneComercialInput)"
                        class="form-control form-control-sm"
                        formControlName="telefoneComercial"
                        id="telefoneComercial"
                        name="telefoneComercial"
                        type="text"
                        maxlength="14"
                    />
                </div>
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="celular">Celular</label>
                    <input
                        #telefoneCelularInput
                        (input)="formatToPhone(telefoneCelularInput)"
                        [class.is-invalid]="telefoneCelular.invalid && telefoneCelular.touched"
                        class="form-control form-control-sm"
                        formControlName="telefoneCelular"
                        id="telefoneCelular"
                        name="telefoneCelular"
                        type="text"
                        maxlength="16"
                    />
                    <div class="mb-1" *ngIf="telefoneCelular.invalid && telefoneCelular.touched">
                        <span 
                            class="erro" 
                            *ngIf="telefoneCelular.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="telefoneCelular.errors.maxlength"
                        >Telefone celular digitado é inválido.</span>
                    </div>
                </div>
            </div>
            <div class="form-row" *ngIf="isCpf(inputCpfCnpj.value)">
                <div class="form-group col-2">
                    <label class="col-sm-12 col-form-label" for="sexo">Sexo</label>
                    <select 
                        formControlName="sexo"
                        [class.is-invalid]="sexo.invalid && sexo.touched"
                        class="form-control form-control-sm"
                        name="sexo" 
                        id="sexo"                
                    >
                        <option value=""></option>
                        <option value="2">Feminino</option>
                        <option value="1">Masculino</option>
                    </select>
                    <div class="mb-1" *ngIf="sexo.invalid && sexo.touched">
                        <span 
                            class="erro" 
                            *ngIf="sexo.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-2">
                    <label class="col-sm-12 col-form-label" for="nascimento">Data Nascimento</label>
                    <input
                        #nascimentoInput
                        mask="00/00/0000"
                        [class.is-invalid]="nascimento.invalid && nascimento.touched"
                        class="form-control form-control-sm text-right"
                        formControlName="nascimento"
                        id="nascimento"
                        name="nascimento"
                        type="text"
                        maxlength="10"
                    />
                    <div class="mb-1" *ngIf="nascimento.invalid && nascimento.touched">
                        <span 
                            class="erro" 
                            *ngIf="nascimento.errors.minlength"
                        >Data inválida</span>
                    </div>
                    <span 
                        class="erro" 
                        *ngIf="nascimento.hasError('mensagem')"
                    >{{nascimento.errors.mensagem}}</span>
                </div>
                <div class="form-group col-2">
                    <label class="col-sm-12 col-form-label" for="tipoDocumento">Tipo Doc.</label>
                    <select 
                        [class.is-invalid]="tipoDocumento.invalid && tipoDocumento.touched"
                        formControlName="tipoDocumento"
                        class="form-control form-control-sm"
                        name="tipoDocumento" 
                        id="tipoDocumento"                
                    >
                        <option value=""></option>
                        <option value="5">RG</option>
                        <option value="6">RM</option>
                        <option value="11">Outros</option>
                    </select>
                    <div class="mb-1" *ngIf="tipoDocumento.invalid && tipoDocumento.touched">
                        <span 
                            class="erro" 
                            *ngIf="tipoDocumento.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-2">
                    <label class="col-sm-12 col-form-label" for="numeroDocumento">Nº Documento</label>
                    <input
                        [class.is-invalid]="numeroDocumento.invalid && numeroDocumento.touched"
                        class="form-control form-control-sm"
                        formControlName="numeroDocumento"
                        id="numeroDocumento"
                        name="numeroDocumento"
                        type="text"
                        maxlength="18"
                    />
                    <div class="mb-1" *ngIf="numeroDocumento.invalid && numeroDocumento.touched">
                        <span 
                            class="erro" 
                            *ngIf="numeroDocumento.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-2">
                    <label class="col-sm-12 col-form-label" for="estadoDocumento">Estado</label>
                    <select 
                        formControlName="estadoDocumento"
                        [class.is-invalid]="estadoDocumento.invalid && estadoDocumento.touched"
                        class="form-control form-control-sm"
                        name="estadoDocumento" 
                        id="estadoDocumento"                
                    >
                        <option value=""></option>
                        <option *ngFor="let estado of estados" 
                            value="{{ estado }}"
                        >
                            {{ estado }}
                        </option>
                    </select>
                    <div class="mb-1" *ngIf="estadoDocumento.invalid && estadoDocumento.touched">
                        <span 
                            class="erro" 
                            *ngIf="estadoDocumento.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-2">
                    <label class="col-sm-12 col-form-label" for="emissaoDocumento">Emissão</label>
                    <input
                        #emissaoDocumentoInput
                        mask="00/00/0000"
                        [class.is-invalid]="emissaoDocumento.invalid && emissaoDocumento.touched"
                        class="form-control form-control-sm text-right"
                        formControlName="emissaoDocumento"
                        id="emissaoDocumento"
                        name="emissaoDocumento"
                        type="text"
                        maxlength="10"
                    />
                    <div class="mb-1" *ngIf="emissaoDocumento.invalid && emissaoDocumento.touched">
                        <span 
                            class="erro" 
                            *ngIf="emissaoDocumento.errors.minlength"
                        >Data inválida</span>
                    </div>
                    <span 
                        class="erro" 
                        *ngIf="emissaoDocumento.hasError('mensagem')"
                    >{{emissaoDocumento.errors.mensagem}}</span>
                </div>
            </div>
        </div>
    </div>
</form>