<mat-form-field>
  <mat-label>Pesquisar</mat-label>
  <input matInput (keyup)="aplicarFiltro($event)" placeholder="Digite qualquer valor para filtrar por todas as opções">
</mat-form-field>

<div class="mat-elevation-z9">
  <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="inscricao">
          <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-inscricao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Inscrição </th>
          <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{row.inscricao ? row.inscricao : '----'}} </td>
      </ng-container>

      <ng-container matColumnDef="ultimoProtocolo">
          <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-ultimoProtocolo" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Protocolo </th>
          <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{row.ultimoProtocolo ? row.ultimoProtocolo : '----'}} </td>
      </ng-container>

      <ng-container matColumnDef="razaoSocial">
          <th style="width: 30%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-razaoSocial" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Razão Social / Nome </th>
          <td style="text-align: left; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{row.razaoSocial}} </td>
      </ng-container>

      <ng-container matColumnDef="cpfCnpj">
          <th style="width: 15%; text-align: center; padding-left: 40px; padding-right: 5px;" id="row-cpfCnpj" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> CPF / CNPJ </th>
          <td style="text-align: right; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{formatToCPFOrCNPJ(row.cpfCnpj)}} </td>
      </ng-container>

      <ng-container matColumnDef="situacaoEmpresaDescricao">
          <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-situacaoEmpresaDescricao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Situação </th>
          <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{row.situacaoEmpresaDescricao}} </td>
      </ng-container>

      <ng-container matColumnDef="dataAbertura">
          <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-dataAbertura" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Abertura </th>
          <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{row.dataAbertura}} </td>
      </ng-container>

      <ng-container matColumnDef="dataEncerramento">
          <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-dataEncerramento" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Encerramento </th>
          <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{row.dataEncerramento}} </td>
      </ng-container>

      <ng-container matColumnDef="menu">
          <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-menu" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"></th>
          <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row">

              <button class="btn btn-menu" mat-button [matMenuTriggerFor]="beforeMenu">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
              </button>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <button class="btn" mat-menu-item
                    (click)="visualizarEmpresa(row)"
                    *ngIf="row.inscricao != '' && !moduloTaxa"
                  >
                  <svg class="icone-grid-menu m-2">
                    <use
                      attr.xlink:href="assets/sprite.svg#{{ 'search' }}"
                    ></use>
                  </svg>  
                  Dados do Negócio</button>
                  <button class="btn" mat-menu-item
                    (click)="visualizarLancamentos(row)"
                    *ngIf="row.inscricao != '' && moduloTaxa"
                  >
                  <svg class="icone-grid-menu m-2">
                    <use
                      attr.xlink:href="assets/sprite.svg#{{ 'search' }}"
                    ></use>
                  </svg>  
                  Lançamentos</button>
              </mat-menu>
          </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> 
  <!-- <button class="btn btn-outline-success" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Excel', sheet: 'sheet_name', Props: {Author: 'Talha'}})">EXCEL</button>
  <button class="btn btn-outline-success" mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
  <button class="btn btn-outline-success" mat-raised-button (click)="exporter.exportTable('json')">JSON</button>
  <button class="btn btn-outline-success" mat-raised-button (click)="exporter.exportTable('txt')">TXT</button> -->
</div>
<div>&nbsp;</div>