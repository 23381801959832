import { Component, Input, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { EmpresaService } from '../../../services/empresa/empresa.service';
import { ProtocoloLinhaDoTempo } from '../../../model/protocoloLinhaDoTempo';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: "app-ciclo-de-vida",
    templateUrl: "./ciclo-de-vida.component.html",
    styleUrls: ["./linha-do-tempo.component.scss"]
})
export class CicloDeVidaComponent implements OnInit {

    @Output() acaoDeAtendimento = new EventEmitter();
    @Input() numeroInscricao: string = "";
    @Input() semCardHeader: boolean = false;
    protocolosLinhaDoTempo: Array<ProtocoloLinhaDoTempo>;
    controleDeVisualizacaoDosProtocolos: Array<any>;
    semProtocoloParaExibir: boolean = true;

    constructor(
        private document: Document,
        private renderer: Renderer2,
        private viewportScroller: ViewportScroller,
        private activatedRoute: ActivatedRoute,
        private empresaService: EmpresaService) {
        this.numeroInscricao = this.numeroInscricao == "" ? this.activatedRoute.snapshot.params["inscricao"] != null ? this.activatedRoute.snapshot.params["inscricao"] : this.numeroInscricao : this.numeroInscricao;
        this.controleDeVisualizacaoDosProtocolos = new Array<any>();
    }

    ngOnInit(): void {
        if (!this.semCardHeader) {
            this.buscarCicloDeVida();
        }
    }
    
    async buscarCicloDeVida() {
        if (this.numeroInscricao == "") {
            return false;
        }

        await this.empresaService
            .cicloDeVida(localStorage.getItem("pId"), this.numeroInscricao)
            .pipe(take(1))
            .subscribe( retorno => {
                this.protocolosLinhaDoTempo = retorno;
                this.inicializarVisualizacaoDosProtocolos()
            })

    }

    inicializarVisualizacaoDosProtocolos() {
        this.protocolosLinhaDoTempo.forEach( item => { this.controleDeVisualizacaoDosProtocolos.push({protocolo: item.protocolo, ocultar: true}) })
    }

    exibirOcultar(protocolo) {
        var index = this.controleDeVisualizacaoDosProtocolos.findIndex( item => { return item.protocolo == protocolo });
        var status = !this.controleDeVisualizacaoDosProtocolos[index].ocultar;

        this.controleDeVisualizacaoDosProtocolos.forEach( item => { item.ocultar = true });

        this.controleDeVisualizacaoDosProtocolos[index].ocultar = status;
        this.semProtocoloParaExibir = status;

        this.goToAnchor('tabCicloDeVida_' + protocolo);
    }

    goToAnchor(ancora) {
        this.viewportScroller.scrollToAnchor(ancora);
        this.document.querySelectorAll(".nav-link-2").forEach( item => { this.renderer.removeClass(item, "active") });
        this.renderer.addClass(this.document.querySelector("." + ancora), "active");
    }

}
