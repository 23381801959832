<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card">
         <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12"> 
                    <label class="col-sm-12 col-form-label" for="observacao">Observações Gerais</label>
                    <textarea #observacaoTextArea
                        class="form-control form-control-sm"
                        id="observacao"
                        name="observacao"
                        formControlName="observacao"
                        maxlength="500"
                        cols="30" 
                        rows="5"
                    ></textarea>
                    <div class="mb-1">
                        <span 
                            class="erro" 
                            *ngIf="observacao.invalid && observacao.touched && observacao.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="observacao.invalid && observacao.touched && observacao.errors.maxlength"
                        >A descrição deve conter até 500 caracteres.</span>
                        <span class="quantidade-caracter">
                            Quantidade de Caracteres: {{ observacao.value.length }} / 500
                        </span>
                    </div>
                </div>
            </div>
                <div class="row col-12 top-atendimento mt-2">
                  <div class="row col-12  pt-3 pb-3">
                    <div class="row col-12 justify-content-end align-items-center divisao-esquerda">
                      <button type="button" class="btn btn-success" (click)="acaoBotaoReativar()" >Reativar</button>
                    </div>
                  </div>
                </div>
        </div> 
    </div>
    
</form>