<div class="sub-header-container">
  <header class="header navbar navbar-expand-sm">
    <a
      href="javascript:void(0);"
      (click)="this.onNavCollapse.emit()"
      class="sidebarCollapse"
      data-placement="bottom"
    >
      <svg class="logo">
        <use
          class="feather feather-menu"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#menu"
        ></use>
      </svg>
    </a>
    <p class="nomeMenuOp">{{ currentAxis }}</p>

    <app-breadcrumb></app-breadcrumb>

    <a
      href="javascript:void(0);"
      *ngIf="exibirConsultaProcesso"
      (click)="acaoClickHeader('CONSULTA-DE-PROCESSO')"
      class="sidebarCollapse consulta-processo user-name"
      data-placement="bottom"
    >
      <svg class="logo">
        <use
          class="feather feather-menu user-name"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#search"
        ></use>
      </svg>
      Consulta Geral
    </a>
  </header>
</div>
