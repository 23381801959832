import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GerenciamentoDePerguntasComponent } from './gerenciamento-de-perguntas.component';
import { AtendimentoGridModule } from '../../outros/atendimento/atendimento-grid/atendimento-grid.module';
import { CadastrarPerguntaComponent } from './cadastrar-pergunta/cadastrar-pergunta.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormAtividadePesquisaModule } from '../../outros/form-atividade-pesquisa/form-atividade-pesquisa.module';
import { AlertModule } from '../../../components/alert/alert.module';
import { FormPerguntaListComponent } from './cadastrar-pergunta/form-atividade-list/form-pergunta-list.component';
import { MatListModule } from '@angular/material/list';
import { PipeModule } from '../../../pipes/pipes.module';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { GridModule } from '../../../components/grid/grid.module';
import { MatDialogModule } from '@angular/material/dialog';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

@NgModule({
  declarations: [
    GerenciamentoDePerguntasComponent,
    CadastrarPerguntaComponent,
    FormPerguntaListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AtendimentoGridModule,
    MatRadioModule,
    FormAtividadePesquisaModule,
    AlertModule,
    MatCheckboxModule,
    MatListModule,
    PipeModule,
    PerfectScrollbarModule,
    MatInputModule,
    GridModule,
    MatDialogModule,
  ],
  exports: [
    FormPerguntaListComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
  
})
export class GerenciamentoDePerguntasModule { }
