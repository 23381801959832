import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { ProtocoloService } from '../../../services/operacao/protocolo.service';
import { ProtocoloLinhaDoTempo } from '../../../model/protocoloLinhaDoTempo';
import { Constantes } from '../../../model/constantes';

@Component({
    selector: "app-linha-do-tempo",
    templateUrl: "./linha-do-tempo.component.html",
    styleUrls: ["./linha-do-tempo.component.scss"]
})
export class LinhaDoTempoComponent implements OnInit {

    @Output() acaoDeAtendimento = new EventEmitter();
    @Input() numeroProtocolo: string = "";
    @Input() protocoloLinhaDoTempo: ProtocoloLinhaDoTempo;

    acaoAtendimentoDescricaoReverso = Constantes.ACAO_ATENDIMENTO_DESCRICAO_REVERSO;

    constructor(
        private activatedRoute: ActivatedRoute,
        private protocoloService: ProtocoloService) {
        this.numeroProtocolo = this.numeroProtocolo == "" ? this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : this.numeroProtocolo : this.numeroProtocolo;
    }

    ngOnInit(): void {
        if (this.protocoloLinhaDoTempo == null) {
            this.buscarLinhaDoTempo();
        }
    }
    
    buscarLinhaDoTempo() {
        if (this.numeroProtocolo == "") {
            return false;
        }

        this.protocoloService
            .linhaDoTempo(localStorage.getItem("pId"), this.numeroProtocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                this.protocoloLinhaDoTempo = retorno;
            })

    }

}
