<div class="container-principal">
    <div class="row col-lg-12 col-12 pl-0 pr-0">
        <div class="col-12 pl-0 pr-0">
            <div class="card">
                <div class="card-header card-header-localizacao">
                    <div class="col-12">
                        <strong>Auditor, utilize uma das opções para localizar o Negócio, e posteriormente selecione a operação que será realizada, lançamento, revisão, baixa ou outras.</strong>
                    </div>      
                </div>
                <div class="card-body card-body-localizacao">
                <form
                    name="formulario"
                    [formGroup]="formulario"
                >
                    <div class="row justify-content-center align-items-center"> 
                        <div class="col-3">
                            <select 
                                #tipoBuscaSelect
                                class="form-control form-control-sm"
                                (change)="acaoSelectTipoBusca()"
                                formControlName="tipoBusca"
                                name="tipoBusca" 
                                id="tipoBusca"                
                            >
                                <option disabled value="">--- Selecione ---</option>
                                <option *ngFor="let filtro of grid.filtro; let i = index"
                                    [value]="i" 
                                    >{{ filtro.display }}
                                </option>
                            </select>
                        </div>
                        <div class="col-8">
                            <input [class.hidden]="tipoBusca.value != '' ? grid.filtro[tipoBusca.value].tipoCampo != 'INPUT' : true "
                                #tipoBuscaValorInput
                                class="form-control form-control-sm"
                                formControlName="tipoBuscaValorInput"
                                id="tipoBuscaValorInput"
                                name="tipoBuscaValorInput"                
                                type="text"
                            />
                            <select [class.hidden]="tipoBusca.value != '' ? grid.filtro[tipoBusca.value].tipoCampo != 'SELECT' : true"
                            #tipoBuscaValorSelect
                            formControlName="tipoBuscaValorSelect"
                            class="form-control form-control-sm"
                            name="tipoBuscaValorSelect" 
                            id="tipoBuscaValorSelect"                
                            >
                            <option value=""></option>
                            <option *ngFor="let opcao of filtroOpcoes" 
                                value="{{ opcao.valor }}"
                            >
                                {{ opcao.display }}
                            </option>
                            </select>
                        </div>
                        <div class="col-1 text-right">
                            <button 
                                type="button" 
                                class="btn btn-secondary"
                                title="Buscar"
                                (click)="buscarGridPorFiltro()"
                                [disabled] = "tipoBuscaValorSelect.value == '' && tipoBuscaValorInput == ''"
                            >
                            Buscar
                            </button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row col-12 pl-0 pr-0" *ngIf="emitirErro">
        <div class="col-12 pl-0 pr-0">
            <app-alert type="danger">
                <div [innerHtml]="mensagemErro"></div>
            </app-alert>
        </div>
    </div>
    
    <div class="row col-lg-12 col-12 pl-0 pr-0 mb-5" *ngIf="!exibirLista && !exibirLancamentos && !exibirLancamentoManual && !exibirAcordo && !exibirCancelamento && !exibirBaixa && !exibirRevisao">
        <div class="col-12 pl-0 pr-0">
            <div class="container-tamanho row col-12 justify-content-center align-items-center">
                <div class="row">
                    <div class="row col-12 justify-content-center align-items-center">
                        <svg class="logo">
                            <use
                                class="logo_icon"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="#_logo_iCad_Completo"
                            ></use>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0" [class.hidden]="!exibirLista">
        <div class="col-12 pl-0 pr-0">
            <app-atendimento-grid-consulta class="w-100" (acaoMenuAtendimento)="acaoAtendimento($event)"></app-atendimento-grid-consulta>
        </div>
    </div>
    
    <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0" [class.hidden]="!exibirLancamentos && !exibirLancamentoManual && !exibirAcordo && !exibirCancelamento && !exibirBaixa && !exibirRevisao">
        <div class="row card-menus">
            <div class="col-md-2 boxInicial2"
                [class.box-laranja]="exibirLancamentos"
                [class.box-cinza]="!exibirLancamentos">
                <div class="row m-4">
                    <div class="form-row col-md-12 justify-content-center animaHover">
                        <div (click)="listarLancamentos()"
                            class="animated fadeInUp">
                            <span>
                                <svg class="icone">
                                    <use
                                      attr.xlink:href="#{{ 'pie-chart' }}"
                                    ></use>
                                </svg>
                            </span>
                            <p class="texto-icone">Lançamentos</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 boxInicial2"
                [class.box-azul]="exibirLancamentoManual"
                [class.box-cinza]="!exibirLancamentoManual">
                <div class="row m-4">
                    <div class="form-row col-md-12 justify-content-center animaHover">
                        <div (click)="novoLancamento()"
                            class="animated fadeInUp">
                            <span>
                                <svg class="icone">
                                    <use
                                      attr.xlink:href="#{{ 'pie-chart' }}"
                                    ></use>
                                </svg>
                            </span>
                            <p class="texto-icone">Lançamento Avulso</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 boxInicial2"
                [class.box-lilas]="exibirAcordo"
                [class.box-cinza]="!exibirAcordo">
                <div class="row m-4">
                    <div class="form-row col-md-12 justify-content-center animaHover">
                        <div (click)="novoAcordo()"
                            class="animated fadeInUp">
                            <span>
                                <svg class="icone">
                                    <use
                                      attr.xlink:href="#{{ 'pie-chart' }}"
                                    ></use>
                                </svg>
                            </span>
                            <p class="texto-icone">Acordo</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 boxInicial2"
                [class.box-vermelho]="exibirCancelamento"
                [class.box-cinza]="!exibirCancelamento">
                <div class="row m-4">
                    <div class="form-row col-md-12 justify-content-center animaHover">
                        <div (click)="cancelar()"
                            class="animated fadeInUp">
                            <span>
                                <svg class="icone">
                                    <use
                                      attr.xlink:href="#{{ 'pie-chart' }}"
                                    ></use>
                                </svg>
                            </span>
                            <p class="texto-icone">Cancelamento</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 boxInicial2"
                [class.box-verde]="exibirBaixa"
                [class.box-cinza]="!exibirBaixa">
                <div class="row m-4">
                    <div class="form-row col-md-12 justify-content-center animaHover">
                        <div (click)="baixar()"
                            class="animated fadeInUp">
                            <span>
                                <svg class="icone">
                                    <use
                                      attr.xlink:href="#{{ 'pie-chart' }}"
                                    ></use>
                                </svg>
                            </span>
                            <p class="texto-icone">Baixa</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 boxInicial2"
                [class.box-amarelo]="exibirRevisao"
                [class.box-cinza]="!exibirRevisao">
                <div class="row m-4">
                    <div class="form-row col-md-12 justify-content-center animaHover">
                        <div (click)="revisar()"
                            class="animated fadeInUp">
                            <span>
                                <svg class="icone">
                                    <use
                                      attr.xlink:href="#{{ 'pie-chart' }}"
                                    ></use>
                                </svg>
                            </span>
                            <p class="texto-icone">Revisão</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0">
        <div class="card card-body card-body-localizacao" *ngIf="consultaGeral.protocolos.length">
            <div class="row">
                <div class="row col-12">
                    <p class="card-text pb-2 pt-2 col-12">
                        <b>RAZÃO SOCIAL / NOME:</b> {{ consultaGeral.protocolos[0].fic.nomeEmpresa }}
                    </p>
                </div>
                <div class="row col-12">
                    <p class="card-text pb-2 pt-2 col-6">
                        <b>INSCRIÇÃO:</b> {{ consultaGeral.protocolos[0].inscricao }}
                    </p>
                    <p class="card-text pb-2 pt-2 col-6">
                        <b>PROTOCOLO:</b> {{ consultaGeral.protocolos[0].protocolo }}
                    </p>
                </div>
                <div class="row col-12">
                    <p class="card-text pb-2 pt-2 col-6">
                        <b>{{ consultaGeral.protocolos[0].registro.tipoPessoa != 'F' ? 'CNPJ' : 'CPF'}}:</b> 
                        {{ 
                            (consultaGeral.protocolos[0].registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Cnpj }).length ? 
                            ((consultaGeral.protocolos[0].registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Cnpj })[0].numero | mask: '00.000.000/0000-00') : ''
            
                        }} 
                        {{ 
                            (consultaGeral.protocolos[0].registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Cpf }).length ? 
                            ((consultaGeral.protocolos[0].registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Cpf })[0].numero | mask: '000.000.000-00') : ''
            
                        }}
                    </p>
                    <p class="card-text pb-2 pt-2 col-6" *ngIf="consultaGeral.protocolos[0].encerramento.data == ''">
                        <b>ABERTURA:</b> {{ consultaGeral.protocolos[0].registro.data }}
                    </p>
                    <p class="card-text pb-2 pt-2 col-6" *ngIf="consultaGeral.protocolos[0].encerramento.data != ''">
                        <b>ENCERRAMENTO:</b> {{ consultaGeral.protocolos[0].encerramento.data }}
                    </p>
                </div>
                <div class="row col-12">
                    <p class="card-text pb-2 pt-2 col-6">
                        <b>FASE ATUAL:</b> {{ consultaGeral.protocolos[0].linhaDoTempo.descricaoFase | uppercase }}
                    </p>
                    <p class="card-text pb-2 pt-2 col-6">
                        <b>DATA FASE:</b> {{ consultaGeral.protocolos[0].linhaDoTempo.dataSituacao | uppercase }}
                    </p>
                </div>
            </div>
        </div>
        <div class="card card-body card-body-localizacao" *ngIf="consultaGeral.empresas.length">
            <div class="row">
                <div class="row">
                    <div class="form-group col-2">
                        <p class="card-text">
                            <b>RAZÃO SOCIAL / NOME:</b>
                        </p>
                    </div>
                    <div class="form-group col-10">
                        <p class="card-text">
                            {{ consultaGeral.empresas[0].fic.nomeEmpresa }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-2">
                        <p class="card-text">
                            <b>INSCRIÇÃO:</b>
                        </p>
                    </div>
                    <div class="form-group col-4">
                        <p class="card-text">
                            {{ consultaGeral.empresas[0].inscricao }}
                        </p>
                    </div>
                    <div class="form-group col-2">
                        <p class="card-text">
                            <b>PROTOCOLO:</b>
                        </p>
                    </div>
                    <div class="form-group col-4">
                        <p class="card-text">
                            {{ consultaGeral.empresas[0].protocolo }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-2">
                        <p class="card-text">
                            <b>
                                {{ consultaGeral.empresas[0].registro.tipoPessoa != 'F' ? 'CNPJ' : 'CPF'}}:
                            </b>
                        </p>
                    </div>
                    <div class="form-group col-4">
                        <p class="card-text">
                            {{ 
                                (consultaGeral.empresas[0].registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Cnpj }).length ? 
                                ((consultaGeral.empresas[0].registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Cnpj })[0].numero | mask: '00.000.000/0000-00') : ''
                
                            }} 
                            {{ 
                                (consultaGeral.empresas[0].registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Cpf }).length ? 
                                ((consultaGeral.empresas[0].registro.documentos | filtro: {campo: 'tipo', valor: tipoDeDocumento.Cpf })[0].numero | mask: '000.000.000-00') : ''
                
                            }}
                        </p>
                    </div>
                    <div class="form-group col-2" *ngIf="consultaGeral.empresas[0].encerramento.data == ''">
                        <p class="card-text">
                            <b>ABERTURA:</b>
                        </p>
                    </div>
                    <div class="form-group col-4" *ngIf="consultaGeral.empresas[0].encerramento.data == ''">
                        <p class="card-text">
                            {{ consultaGeral.empresas[0].registro.data }}
                        </p>
                    </div>
                    <div class="form-group col-2" *ngIf="consultaGeral.empresas[0].encerramento.data != ''">
                        <p class="card-text">
                            <b>ENCERRAMENTO:</b>
                        </p>
                    </div>
                    <div class="form-group col-4" *ngIf="consultaGeral.empresas[0].encerramento.data != ''">
                        <p class="card-text">
                            {{ consultaGeral.empresas[0].encerramento.data }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-2">
                        <p class="card-text">
                            <b>FASE ATUAL:</b>
                        </p>
                    </div>
                    <div class="form-group col-4">
                        <p class="card-text">
                            {{ consultaGeral.empresas[0].protocolosAcompanhamento.length ? consultaGeral.empresas[0].protocolosAcompanhamento[0].faseDescricao : '' | uppercase }}
                        </p>
                    </div>
                    <div class="form-group col-2">
                        <p class="card-text">
                            <b>DATA FASE:</b>
                        </p>
                    </div>
                    <div class="form-group col-4">
                        <p class="card-text">
                            {{ consultaGeral.empresas[0].protocolosAcompanhamento.length ? consultaGeral.empresas[0].protocolosAcompanhamento[0].dataSituacao : '' | uppercase }}
                        </p>
                    </div>
                </div>
                <hr class="w-100">
                <div class="row">
                    <div class="form-group col-2">
                        <p class="card-text">
                            <b>ATIVIDADE(S) EXERCIDA(S)</b>
                        </p>
                    </div>
                    <div class="form-group col-10">
                        <p class="card-text" *ngFor="let atividadeExercida of consultaGeral.empresas[0].viabilidade.atividadesExercidas">
                            <b>{{ atividadeExercida.codigo | mascara: 10 }}</b> - {{ atividadeExercida.nome }} <b>{{ atividadeExercida.principal ? '(PRINCIPAL)' : '' }}</b>
                        </p>
                    </div>
                </div>
                <hr class="w-100">
                <div class="row">
                    <div class="form-group col-2">
                        <p class="card-text">
                            <b>ATIVIDADE(S) NÃO EXERCIDA(S)</b>
                        </p>
                    </div>
                    <div class="form-group col-10">
                        <p class="card-text" *ngFor="let atividadeNaoExercida of consultaGeral.empresas[0].viabilidade.atividadesNaoExercidas">
                            <b>{{ atividadeNaoExercida.codigo | mascara: 10 }}</b> - {{ atividadeNaoExercida.nome }} <b>{{ atividadeNaoExercida.principal ? '(PRINCIPAL)' : '' }}</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    
    <div id="divDetalhes" class="container-tamanho">
        <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0" [class.hidden]="!exibirLancamentos">
            <div class="col-12 pl-0 pr-0">
                <app-conta-corrente></app-conta-corrente>
            </div>
        </div>
        
        <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0" [class.hidden]="!exibirLancamentoManual">
            <div class="col-12 pl-0 pr-0">
                <app-lancamento-manual class="w-100" (acaoMenuAtendimento)="acaoAtendimento($event)"></app-lancamento-manual>
            </div>
        </div>
        
        <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0" [class.hidden]="!exibirAcordo">
            <div class="col-12 pl-0 pr-0">
                <app-acordo class="w-100" (acaoMenuAtendimento)="acaoAtendimento($event)"></app-acordo>
            </div>
        </div>
        
        <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0" [class.hidden]="!exibirCancelamento">
            <div class="col-12 pl-0 pr-0">
                <app-cancelamento class="w-100" (acaoMenuAtendimento)="acaoAtendimento($event)"></app-cancelamento>
            </div>
        </div>
        
        <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0" [class.hidden]="!exibirBaixa">
            <div class="col-12 pl-0 pr-0">
                <app-baixa-manual class="w-100" (acaoMenuAtendimento)="acaoAtendimento($event)"></app-baixa-manual>
            </div>
        </div>
        
        <div class="row col-lg-12 col-12 layout-spacing pl-0 pr-0" [class.hidden]="!exibirRevisao">
            <div class="col-12 pl-0 pr-0">
                <app-revisao class="w-100" (acaoMenuAtendimento)="acaoAtendimento($event)"></app-revisao>
            </div>
        </div>
    </div>
</div>
