<div *ngIf="dados[2] != 'AUDITOR'" class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Atendimento</strong>
        </div>
    </div>
    <div class="card-body">
        <form
            name="formulario"
            [formGroup]="formulario"
        >
            <div [class.hidden]="pendencia.codigoTipo != tipoDePendencia.Pendente_Usuario_Requisito.toString() && 
                                pendencia.codigoTipo != tipoDePendencia.Esclarecimento_Ao_Auditor.toString()">
                <div class="form-row row-linha justify-content-start align-items-center">
                    <div class="col-2">
                        <p class="card-text">
                            <strong>Orgão</strong>
                        </p>
                    </div>
                    <div class="col-10">
                        <p class="card-text">
                            {{ pendencia.fluxos[0].orgaoOrigem.nome | titlecase }}
                        </p>
                    </div>
                </div>
                <div [class.hidden]="pendencia.fluxos[0].requisito.nome == ''" class="form-row row-linha justify-content-start align-items-center">
                    <div class="col-2">
                        <p class="card-text">
                            <strong>Requisito</strong>
                        </p>
                    </div>
                    <div class="col-10">
                        <p class="card-text">
                            {{ pendencia.fluxos[0].requisito.nome | titlecase }}
                        </p>
                    </div>
                </div>
                <div class="form-row row-linha justify-content-start align-items-center">
                    <div class="col-2">
                        <p class="card-text">
                            <strong>Parecer Auditor</strong>
                        </p>
                    </div>
                    <div class="col-10">
                        <p class="card-text" [innerHtml]="pendencia.fluxos[0].observacao">
                        </p>
                    </div>
                </div>
                <div class="form-row row-linha justify-content-start align-items-center">
                    <div class="col-12">
                        <label class="col-sm-12 col-form-label" for="resposta">Resposta</label>
                        <input
                            #respostaInput
                            [class.is-invalid]="resposta.invalid && resposta.touched"
                            autofocus
                            class="form-control form-control-sm"
                            formControlName="resposta"
                            id="resposta"
                            name="resposta"                
                            type="text"
                            maxlength="150"
                        />
                        <div class="mb-1" *ngIf="resposta.invalid && resposta.touched">
                            <span 
                                class="erro" 
                                *ngIf="resposta.errors.required"
                            >Campo obrigatório</span>
                            <span 
                                class="erro" 
                                *ngIf="resposta.errors.minlength"
                            >A resposta deve conter ao menos 10 caracteres.</span>
                            <span 
                                class="erro" 
                                *ngIf="resposta.errors.maxlength"
                            >A resposta deve conter até 50 caracteres.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div [class.hidden]="pendencia.codigoTipo != tipoDePendencia.Renovacao_Do_Requisito.toString()">
                <div class="form-row row-linha justify-content-start align-items-center">
                    <div class="col-12">
                        <p class="card-text">
                            <strong>Sr. Contribuinte</strong><br>
                            Ao clicar em <strong>Enviar para Análise</strong>, o sistema irá enviar para análise o requisito <font style="color: #d33; font-style: italic; font-weight: bold;">{{ pendencia.fluxos[0].requisito.nome }}</font>, referente ao órgão <font style="color: #d33; font-style: italic; font-weight: bold;">{{ pendencia.fluxos[0].requisito.orgao.nome }}</font>.<br>
                            Acompanhe o andamento da análise através do módulo de <strong>Acompanhamento</strong>, aba <strong>Empresas</strong>, sub aba <strong>Posturas</strong>, até sua finalização.<br>
                            Se necessário forneça a documentação solicitada pelo auditor durante o processo de análise.<br>
                        </p>
                    </div>
                </div>
            </div>
            <div [class.hidden]="pendencia.codigoTipo != tipoDePendencia.Providenciar_Anexo.toString()">
                <div class="form-row row-linha justify-content-start align-items-center">
                    <div class="col-12">
                        <p class="card-text">
                            <strong>Sr. Contribuinte</strong><br>
                            É necessário forneçer a documentação solicitada pelo auditor para a continuidade do processo de análise.<br>
                            Ao clicar em <strong>Enviar para Análise</strong>, o sistema irá enviar para análise protocolo <font style="color: #d33; font-style: italic; font-weight: bold;">{{ pendencia.protocolo }}</font>.<br>
                            Acompanhe o andamento da análise através do módulo de <strong>Acompanhamento</strong> até sua finalização.<br>
                        </p>
                    </div>
                </div>
                <div class="form-row row-linha justify-content-start align-items-center mt-3">
                    <div class="col-12">
                        <p class="card-text">
                            <strong>Parecer do Auditor </strong>
                        </p>
                    </div>
                </div>
                <div class="form-row row-linha justify-content-start align-items-center mt-3">
                    <div class="col-12">
                        <p class="card-text" [innerHtml]="pendencia.fluxos[0].observacao"></p>
                    </div>
                </div>
                <div class="form-row row-linha justify-content-start align-items-center">
                    <div class="col-12">
                        <label class="col-sm-12 col-form-label" for="resposta">Resposta</label>
                        <input
                            #respostaInput
                            [class.is-invalid]="resposta.invalid && resposta.touched"
                            autofocus
                            class="form-control form-control-sm"
                            formControlName="resposta"
                            id="resposta"
                            name="resposta"                
                            type="text"
                            maxlength="150"
                        />
                        <div class="mb-1" *ngIf="resposta.invalid && resposta.touched">
                            <span 
                                class="erro" 
                                *ngIf="resposta.errors.required"
                            >Campo obrigatório</span>
                            <span 
                                class="erro" 
                                *ngIf="resposta.errors.minlength"
                            >A resposta deve conter ao menos 10 caracteres.</span>
                            <span 
                                class="erro" 
                                *ngIf="resposta.errors.maxlength"
                            >A resposta deve conter até 50 caracteres.</span>
                        </div>
                    </div>
                </div>
                <div class="form-row row-linha justify-content-start align-items-center mt-3">
                    <div class="col-1 justify-content-center align-items-center">
                        <mat-checkbox 
                            #semAnexo
                            (change)="verificaAnexo(semAnexo)"
                            id="semAnexo"
                            name="semAnexo">
                        </mat-checkbox>
                    </div>
                    <div class="col-11">
                        <p class="card-text">
                            Desejo enviar a pendência para análise sem anexar nenhum arquivo.
                        </p>
                    </div>
                </div>
            </div>
            <div [class.hidden]="pendencia.codigoTipo != tipoDePendencia.Pendente_Usuario_Cadastro.toString()">
                <div class="form-row row-linha justify-content-start align-items-center">
                    <div class="col-12">
                        <p class="card-text">
                            <strong>Sr. Contribuinte</strong><br>
                            É necessário forneçer as informações solicitada pelo auditor para a continuidade do processo de análise.<br>
                            Ao clicar em <strong>Enviar para Análise</strong>, o sistema irá enviar para análise protocolo <font style="color: #d33; font-style: italic; font-weight: bold;">{{ pendencia.protocolo }}</font>.<br>
                            Acompanhe o andamento da análise através do módulo de <strong>Acompanhamento</strong> até sua finalização.<br>
                        </p>
                    </div>
                </div>
                <div class="form-row row-linha justify-content-start align-items-center mt-3">
                    <div class="col-12">
                        <p class="card-text">
                            <strong>Parecer do Auditor </strong>
                        </p>
                    </div>
                </div>
                <div class="form-row row-linha justify-content-start align-items-center mt-3">
                    <div class="col-12">
                        <p class="card-text" [innerHtml]="pendencia.fluxos[0].observacao"></p>
                    </div>
                </div>
                <div class="form-row row-linha justify-content-start align-items-center">
                    <div class="col-12">
                        <label class="col-sm-12 col-form-label" for="resposta">Resposta</label>
                        <input
                            #respostaInput
                            [class.is-invalid]="resposta.invalid && resposta.touched"
                            autofocus
                            class="form-control form-control-sm"
                            formControlName="resposta"
                            id="resposta"
                            name="resposta"                
                            type="text"
                            maxlength="150"
                        />
                        <div class="mb-1" *ngIf="resposta.invalid && resposta.touched">
                            <span 
                                class="erro" 
                                *ngIf="resposta.errors.required"
                            >Campo obrigatório</span>
                            <span 
                                class="erro" 
                                *ngIf="resposta.errors.minlength"
                            >A resposta deve conter ao menos 10 caracteres.</span>
                            <span 
                                class="erro" 
                                *ngIf="resposta.errors.maxlength"
                            >A resposta deve conter até 50 caracteres.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row row-linha justify-content-center align-items-center linha-botao mb-4">
                <button 
                    (click)="acaoBotaoEnviarParaAnalise(pendencia)"
                    type="button" 
                    class="btn btn-outline-success"
                    title="salvar"
                >
                    Enviar para análise
                </button>
            </div>
        </form>
    </div>
    <br>
    <app-anexar *ngIf="pendencia.codigoTipo == tipoDePendencia.Pendente_Usuario_Requisito.toString() || pendencia.codigoTipo == tipoDePendencia.Pendente_Usuario_Cadastro.toString()" class="m-3" [dados]="['', pendencia.inscricao, '100', 'CONTRIBUINTE']" ></app-anexar>   
</div>
<div *ngIf="pendencia.codigoTipo == tipoDePendencia.Providenciar_Anexo.toString()">
    <app-anexar *ngIf="dados[2] != 'AUDITOR'" [dados]="[pendencia.protocolo, pendencia.inscricao, dados[2]]" ></app-anexar>                
</div>

<div [class.hidden]="pendencia.codigoTipo != tipoDePendencia.Providenciar_Anexo.toString()">
    <div *ngIf="dados[2] == 'AUDITOR'" class="card">
        <div class="card-header card-header-principal" >
            <div class="row col-12 justify-content-end align-items-center" >
                <strong>PROTOCOLO: {{ protocolo.protocolo }}</strong>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="row header-atividades">
                <div class="row justify-content-center align-items-center col-4" >
                    <p class="card-text titulo-atividades">
                        <strong>Nome do Arquivo</strong>
                    </p>
                </div>
                <div class="row justify-content-center align-items-center col-2" >
                    <p class="card-text titulo-atividades">
                        <strong>Orgão</strong>
                    </p>
                </div>
                <div class="row justify-content-center align-items-center col-2" >
                    <p class="card-text titulo-atividades">
                        <strong>Responsável</strong>
                    </p>
                </div>
                <div class="row justify-content-center align-items-center col-2" >
                    <p class="card-text titulo-atividades">
                        <strong>Data</strong>
                    </p>
                </div>
                <div class="row col-2"></div>
            </div>
            <div *ngFor="let anexo of anexos | filtro: {campo: 'protocolo', valor: protocolo.protocolo}; let i = index" 
                class="row row-linha justify-content-center align-items-center"
                [class.row-cinza]="i % 2 != 0" >
                <div class="row col-1 justify-content-end align-items-center"
                    (click)="baixarArquivo(anexo.codigo, anexo.nome)">
                    <svg class="iconeGridDownload" id="informacoes-documentacoes-tab" >
                        <use attr.xlink:href="assets/sprite.svg#{{ 'download' }}"></use>
                    </svg> 
                </div>
                <div class="row col-3">
                    <p class="card-text">
                        {{ anexo.nome }}
                    </p>
                </div>
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text">
                        {{ anexo.orgao.nome }}
                    </p>
                </div>
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text">
                        {{ anexo.observacao }}
                    </p>
                </div>
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text">
                        {{ anexo.dataInicio }}
                    </p>
                </div>
                <div *ngIf="anexo.atendimento.parecer == ''" class="row col-1 justify-content-center align-items-center">
                    <button type="button" 
                            (click)="acaoBotaoAceitarRejeitarAnexo(anexo, true)"
                            class="btn btn-success pt-1 pb-1 pl-2 pr-2">
                        <svg class="iconeGrid">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'check' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
                <div *ngIf="anexo.atendimento.parecer == ''" class="row col-1 justify-content-center align-items-center">
                    <button type="button" 
                            (click)="acaoBotaoAceitarRejeitarAnexo(anexo, false)"
                            class="btn btn-danger pt-1 pb-1 pl-2 pr-2">
                        <svg class="iconeGrid">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'x' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
                <div *ngIf="anexo.atendimento.parecer != ''" class="row col-2 justify-content-center align-items-center">

                    <p *ngIf="anexo.atendimento.parecer == 'S'"
                        class="card-text">
                        <button type="button" class="btn btn-success btn-sm btn-informacao-atividade">
                            ACEITO
                        </button>
                    </p>
                    <p *ngIf="anexo.atendimento.parecer == 'N'"
                        class="card-text">
                        <button type="button" class="btn btn-danger btn-sm btn-informacao-atividade">
                            NEGADO
                        </button>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>