import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AtendimentoConfiguracao } from "src/app/model/atendimentoConfiguracao";
import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Zona } from "src/app/model/zona";
import { Uso } from "src/app/model/uso";
import { Constantes } from "src/app/model/constantes";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import Swal from "sweetalert2";

@Component({
  selector: 'app-atendimento-zoneamento',
  templateUrl: './atendimento-zoneamento.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoZoneamentoComponent implements OnInit {

  @Input() semCardHeader: boolean = false;
  @Output() usoModificado = new EventEmitter();

  numeroProtocolo: string;
  atendimentoConfiguracao: AtendimentoConfiguracao;
  
  dataSourceZona: MatTableDataSource<any>;
  displayedColumnsZona: string[] = ["codigo","nome","tipoDescricao","acao"];
  
  dataSourceUsoPermitido: MatTableDataSource<any>;
  displayedColumnsUsoPermitido: string[] = ["nome","acao"];
  
  dataSourceUsoPermissivel: MatTableDataSource<any>;
  displayedColumnsUsoPermissivel: string[] = ["nome","acao"];

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  zonasAdicionadas: Array<Zona>;
  usosPermitidosAdicionados: Array<Uso>;
  usosPermissiveisAdicionados: Array<Uso>;
  usosAdicionados: Array<Uso>;
  
  emitirErro: boolean;
  mensagemErro: string;
  permitirAtendimento: boolean;

  constructor(
    private activatedRoute: ActivatedRoute) {
    this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];
    this.zonasAdicionadas = new Array<Zona>();
    this.usosPermitidosAdicionados = new Array<Uso>();
    this.usosPermissiveisAdicionados = new Array<Uso>();    
    let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
    let moduloConsultaPrevia = this.activatedRoute.snapshot.data["menu"] == "viabilidade" || this.activatedRoute.snapshot.data["menu"] == "viabilidadeEvento";
    this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && moduloConsultaPrevia && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !meusNegocios);
   }

  ngOnInit(): void {
  }

  acaoAdicionarZonaEscolhida(select) {
    this.atendimentoConfiguracao.viabilidade.zonaUsos.forEach(zona => { 
      if (zona.codigo == select.value) {
        if (!this.zonasAdicionadas.find( item => item.codigo == select.value)) {
          this.zonasAdicionadas.push(zona);
          this.adicionarUsoPorZona();
        };
      }
      
    });

    this.reiniciarDataTables();

    select.value = "";
  }

  acaoExcluirZonaEscolhida(codigo) {
    let index = this.zonasAdicionadas.findIndex( item => item.codigo == codigo)
    this.zonasAdicionadas.splice(index, 1);
    this.reiniciarDataTables();
  }

  acaoBotaoAdicionarUsoPermitido(select) {
    if (select.value == "") {
      return false;
    }

    let index = this.atendimentoConfiguracao.viabilidade.usos.findIndex(uso => uso.codigo == select.value);

    if (!this.usosPermitidosAdicionados.find( item => item.codigo == select.value)) {
      this.usosPermitidosAdicionados.push(this.atendimentoConfiguracao.viabilidade.usos[index]);
      this.acaoModificacaoUso();
    };
    
    this.reiniciarDataTables();

    select.value = "";
  }

  acaoBotaoAdicionarUsoPermissivel(select) {
    if (select.value == "") {
      return false;
    }
    
    let index = this.atendimentoConfiguracao.viabilidade.usos.findIndex(uso => uso.codigo == select.value);
    
    if (!this.usosPermissiveisAdicionados.find( item => item.codigo == select.value)) {
      this.usosPermissiveisAdicionados.push(this.atendimentoConfiguracao.viabilidade.usos[index]);
      this.acaoModificacaoUso();
    };

    this.reiniciarDataTables();

    select.value = "";
  }

  acaoBotaoExcluirUsoPermitido(codigo) {
    let index = this.usosPermitidosAdicionados.findIndex(uso => uso.codigo == codigo);

    this.usosPermitidosAdicionados.splice(index, 1);
    this.reiniciarDataTables();
    this.acaoModificacaoUso();
  }

  acaoBotaoExcluirUsoPermissiveis(codigo) {
    let index = this.usosPermissiveisAdicionados.findIndex(uso => uso.codigo == codigo);

    this.usosPermissiveisAdicionados.splice(index, 1);
    this.reiniciarDataTables();
    this.acaoModificacaoUso();
  }

  adicionarUsoPorZona() {
    this.zonasAdicionadas.forEach(zona => {
      zona.usos.forEach(uso => {
        if (uso.tipo == Constantes.TIPO_DE_USO.Permitido) {
          if (!this.usosPermitidosAdicionados.find( item => item.codigo == uso.codigo)) {
            this.usosPermitidosAdicionados.push(uso);
            this.acaoModificacaoUso();
          };
        }
        else if (uso.tipo == Constantes.TIPO_DE_USO.Permissivel) {
          if (!this.usosPermissiveisAdicionados.find( item => item.codigo == uso.codigo)) {
            this.usosPermissiveisAdicionados.push(uso);
            this.acaoModificacaoUso();
          };
        }
      });
    });
    
    this.reiniciarDataTables();
  }
  
  preencher(atendimentoConfiguracao, protocolo) {
    this.atendimentoConfiguracao = atendimentoConfiguracao;

    protocolo.viabilidade.zonas.forEach(zona => {
      this.zonasAdicionadas.push(zona);      
    });

    protocolo.viabilidade.usos.forEach(uso => {
      if (uso.tipo == Constantes.TIPO_DE_USO.Permitido) {
        if (!this.usosPermitidosAdicionados.find( item => item.codigo == uso.codigo)) {
          this.usosPermitidosAdicionados.push(uso);
        }
      }
      else if (uso.tipo == Constantes.TIPO_DE_USO.Permissivel) {
        if (!this.usosPermissiveisAdicionados.find( item => item.codigo == uso.codigo)) {
          this.usosPermissiveisAdicionados.push(uso);
        }
      };
    });
    this.acaoModificacaoUso();
    this.reiniciarDataTables();
  }
  
  preencherZonaPorImobiliario(zonas) {

    if (!this.zonasAdicionadas.length) {

      zonas.forEach(zona => {
        this.zonasAdicionadas.push(zona);

        if (!this.usosPermitidosAdicionados.length) {
          zona.usos.forEach(uso => {
            if (uso.tipo == Constantes.TIPO_DE_USO.Permitido) {
              if (!this.usosPermitidosAdicionados.find( item => item.codigo == uso.codigo)) {
                this.usosPermitidosAdicionados.push(uso);
              }
            }
          });
        }
        if (!this.usosPermissiveisAdicionados.length) {
          zona.usos.forEach(uso => {
            if (uso.tipo == Constantes.TIPO_DE_USO.Permissivel) {
              if (!this.usosPermissiveisAdicionados.find( item => item.codigo == uso.codigo)) {
                this.usosPermissiveisAdicionados.push(uso);
              }
            };
          });
        }
      });
    }
    
    this.acaoModificacaoUso();
    this.reiniciarDataTables();
  }

  reiniciarDataTables() {
    this.dataSourceZona = new MatTableDataSource(this.zonasAdicionadas);
    this.dataSourceUsoPermitido = new MatTableDataSource(this.usosPermitidosAdicionados);
    this.dataSourceUsoPermissivel = new MatTableDataSource(this.usosPermissiveisAdicionados);
    
    this.dataSourceZona.sort = this.sort;
    this.dataSourceUsoPermitido.sort = this.sort;
    this.dataSourceUsoPermissivel.sort = this.sort;
  }

  acaoModificacaoUso() {
    this.usosAdicionados = new Array<Uso>();

    this.usosPermitidosAdicionados.forEach(usoPermitido => {
      let usoAux = new Uso();
      Object.assign(usoAux, usoPermitido);
      usoAux.tipo = Constantes.TIPO_DE_USO.Permitido;
      this.usosAdicionados.push(usoAux);
    });
    this.usosPermissiveisAdicionados.forEach(usoPermissivel => {
      let usoAux = new Uso();
      Object.assign(usoAux, usoPermissivel);
      usoAux.tipo = Constantes.TIPO_DE_USO.Permissivel;
      this.usosAdicionados.push(usoAux);
    });

    this.usoModificado.emit({ acao: true});
  }

  validarFormulario(validarZona, validarUso) {
    this.emitirErro = false;
    let mensagemErro = "";

    if (validarZona) {
      mensagemErro += this.validarZona();
    }

    if (validarUso) {
      mensagemErro += this.ValidarUso();
    }

    if (mensagemErro == "") {
      return true;
    }
    
    this.mensagemErro = mensagemErro;
    this.emitirErro = true;
    Swal.fire("Zoneamento incorreto", "Corrigir os erros descritos no informativo da página.", "error");
    
    return false;
    
  }

  validarZona() {
    if (!this.zonasAdicionadas.length) {
      return "Favor adicionar um zoneamento.<br>";
    }

    return "";
  }

  ValidarUso() {
    if (!this.usosPermitidosAdicionados.length && !this.usosPermissiveisAdicionados.length) {
      return "Favor adicionar usos permitidos ou permissíveis.<br>";
    }

    return "";
  }
}
