import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { TaxaService } from 'src/app/services/taxa/taxa.service';
import { TaxaLancamento } from 'src/app/model/taxaLancamento';
import { TaxaLancamentoParcela } from 'src/app/model/taxaLancamentoParcela';
import { Constantes } from 'src/app/model/constantes';
import { MascaraPipe } from 'src/app/pipes/mascara.pipe';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormValidator } from '../../../../components/validators/form-validator';
import Swal from 'sweetalert2';


@Component({
    selector: "app-cancelamento",
    templateUrl: "./cancelamento.component.html",
    styleUrls: ["./../gerenciamento-taxa.component.scss"]
})
export class CancelamentoComponent implements OnInit {
    
    @Output() acaoMenuAtendimento = new EventEmitter();

    formulario: FormGroup;

    emitirErro: boolean;
    mensagemErro: string;

    taxaLancamentos: Array<TaxaLancamento>;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

    acaoAtendimentoDescricao = Constantes.ACAO_ATENDIMENTO_DESCRICAO;
    acaoAtendimentoClass = Constantes.ACAO_ATENDIMENTO_CLASS;

    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private mascara: MascaraPipe,
        private taxaService: TaxaService) {

        this.formulario = this.formBuilder.group({
            cliente: [""],
            codigo: [""],            
            protocolo: ["", [Validators.required]],
            inscricao: ["", [Validators.required]],
        });
            
    }

    ngOnInit(): void {}

    formatToBRL(valor) {
        return this.mascara.transform(valor, 11);
    }

    buscarTaxaLancamentos() {
        if (this.inscricao.value != "") {
            this.buscarTaxaLancamentoPorInscricaoEmAberto();
        }
        else if (this.protocolo.value != "") {
            this.buscarTaxaLancamentoPorProtocoloEmAberto();
        }
    }

    buscarTaxaLancamentoPorInscricaoEmAberto() {
        this.taxaService
            .buscarTaxaLancamentoPorInscricaoEmAberto(this.inscricao.value)
            .pipe(take(1))
            .subscribe( retorno => {
                this.taxaLancamentos = retorno;
            })
    }

    buscarTaxaLancamentoPorProtocoloEmAberto() {
        this.taxaService
            .buscarTaxaLancamentoPorProtocoloEmAberto(this.protocolo.value)
            .pipe(take(1))
            .subscribe( retorno => {
                this.taxaLancamentos = retorno;
            })
    }

    acaoBotaoVoltar(){
        this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Cancelar });
    }

    acaoConfirmarCancelamento() {
        this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Atualizar_Lancamentos_Pos_Cancelamento });
    }

    // -------------------------------------------------------------------------
    // Ações para salvar os dados - Início
    // -------------------------------------------------------------------------
    acaoBotaoCancelar(codigo) {
        var taxaLancamentoAuxiliar = new TaxaLancamento();
        taxaLancamentoAuxiliar.codigo = codigo;
        taxaLancamentoAuxiliar.protocolo = this.protocolo.value;
        taxaLancamentoAuxiliar.inscricao = this.inscricao.value;

        this.taxaService
            .calculoCancelarLancamento(codigo)
            .pipe(take(1))
            .subscribe(retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    input: 'textarea',
                    width: "60%",
                    customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: true,
                    showCancelButton: retorno.status != "error",
                    confirmButtonText: retorno.status != "error" ? 'Confirmar' : "OK",
                    cancelButtonText: 'Cancelar',
                    showLoaderOnConfirm: true,
                    inputValidator: (value) => {
                    if (!value) {
                        return 'Favor informar o motivo do cancelamento!'
                    }
                    }
                })
                .then((result) => {
                    taxaLancamentoAuxiliar.observacao = result.value;

                    if (result.isConfirmed && retorno.status != "error") {
                        this.taxaService
                            .cancelarLancamento(taxaLancamentoAuxiliar)
                            .pipe(take(1))
                            .subscribe(retorno => {
                                Swal.fire({
                                    title: retorno.titulo,
                                    html: retorno.mensagem,
                                    icon: retorno.status,
                                    willClose: () => {
                                        this.acaoConfirmarCancelamento();
                                    }
                                })
                            });
                    }
                });
            });

    }

    limpar() {
        this.cliente.reset();
        this.codigo.reset();
        this.protocolo.reset();
        this.inscricao.reset();

        this.cliente.setValue("");
        this.codigo.setValue("");
        this.protocolo.setValue("");
        this.inscricao.setValue("");
        
        this.taxaLancamentos = null;
    }

    get cliente() {
        return this.formulario.get("cliente");
    }
    set cliente(cliente) {
        this.formulario.get("cliente").setValue(cliente);
    }
    
    get codigo() {
        return this.formulario.get("codigo");
    }
    set codigo(codigo) {
        this.formulario.get("codigo").setValue(codigo);
    }
    
    get protocolo() {
        return this.formulario.get("protocolo");
    }
    set protocolo(protocolo) {
        this.formulario.get("protocolo").setValue(protocolo);
    }
    
    get inscricao() {
        return this.formulario.get("inscricao");
    }
    set inscricao(inscricao) {
        this.formulario.get("inscricao").setValue(inscricao);
    }


}
