<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>

    <div id="toggleAccordion" *ngIf="tipoEmpresa == 'BE' || tipoEmpresa == 'BM' || tipoEmpresa == 'BA' || tipoEmpresa == 'BO'">
        <div class="card">
            <div class="card-header" id="div-atividades">
                <section class="mb-0 mt-0">
                    <div class="row">
                        <div class="row col-6 justify-content-start align-items-center">
                            <strong>Atividades Originais</strong>
                        </div>
                    </div>
                </section>
            </div>
            <div *ngIf="atividadesOriginaisExercidas.length"
            class="card-body card-body-titulo">
            <div class="div-lista-atividades">
                <div class="row justify-content-center align-items-center header-atividades">
                    <div class="row col-2" >
                        <p class="card-text titulo-atividades">
                        </p>
                    </div>
                    <div class="row col-10">
                        <p class="card-text titulo-atividades">
                            <strong>Atividade</strong>
                        </p>
                    </div>
                </div>
                <div class="row linha-grid-atividades-selecionadas" 
                    *ngFor="let atividade of atividadesOriginaisExercidas; let i = index">
                    <div class="row col-2 justify-content-center align-items-center linha-atividade-originaria">
                        <div *ngIf="atividade.principal"
                            class="row col-12">
                            <p class="card-text">
                                <button type="button" class="btn btn-success btn-sm btn-informacao-atividade">
                                    PRINCIPAL
                                </button>
                            </p>
                        </div>
                         <div *ngIf="!atividade.principal"
                            class="row col-12">
                            <p class="card-text">
                                <button type="button" class="btn btn-secondary btn-sm btn-informacao-atividade">
                                    SECUNDARIA
                                </button>
                            </p>
                        </div>
                    </div>
                    <div class="row col-10 justify-content-start align-items-center">
                        <p class="card-text">
                            {{ atividade.codigo | mascara: 10 }} - {{ formatarNomeAtividade(atividade.nome) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!atividadesOriginaisExercidas.length"
            class="card-body">
            <p class="card-text">
                Suas atividades apareceram aqui.
            </p>
        </div>
        </div>
    </div>
    <div id="toggleAccordion">
        <div class="card">
            <div class="card-header" id="div-atividades">
                <section class="mb-0 mt-0">
                    <div class="row">
                        <div class="row col-6 justify-content-start align-items-center">
                            <strong>Atividades</strong>
                        </div>
                        <div class="row col-6 justify-content-end align-items-center">
                            <button  *ngIf="!bloqueadoPorCriticas"
                                type="button" 
                                (click)="acaoBotaoAdicionarAtividade()"
                                class="btn btn-success btn-atividade btn-adiconar-atividade">
                                <svg class="iconeAtividade">
                                    <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'plus' }}"
                                    ></use>
                                </svg>
                                Adicionar
                            </button>
                            <button role="menu" class="collapsed" data-toggle="collapse" data-target="#expansao-atividades" aria-expanded="true" aria-controls="expansao-atividades" type="button" 
                                (click)="abrirPainel = !abrirPainel"
                                class="btn btn-success btn-atividade">
                                <svg [class.hidden]="abrirPainel" 
                                    class="iconeAtividade">
                                    <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'chevron-down' }}"
                                    ></use>
                                </svg>
                                <svg [class.hidden]="!abrirPainel" 
                                    class="iconeAtividade">
                                    <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'chevron-up' }}"
                                    ></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
            <div id="expansao-atividades" class="collapse show" aria-labelledby="div-atividades" data-parent="#toggleAccordion" >
                <div *ngIf="atividadesSelecionadas.length"
                    class="card-body card-body-titulo">
                    <div *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'BA'"
                        class="row col-12 justify-content-center align-items-center ng-star-inserted mt-2 mb-2">
                        <div class="quadroQuantidade p-1 font-weight-bold rounded">
                            <button class="btn btn-sm btn btn-success btn-informacao-atividade">
                                {{ quantidadePrincipal }}
                            </button> Principal 
                        </div>
                        <div class="quadroQuantidade p-1 font-weight-bold rounded">
                            <button class="btn btn-sm btn btn-success btn-informacao-atividade">
                                {{ quantidadeExercida }}
                            </button> Exercida 
                        </div>
                        <div class="quadroQuantidade p-1 font-weight-bold rounded">
                            <button class="btn btn-sm btn btn-success btn-informacao-atividade">
                                {{ quantidadeNaoxercida }} 
                            </button>Não Exercida 
                        </div>
                    </div>
                    <div class="div-lista-atividades">
                        <div class="row justify-content-center align-items-center header-atividades">
                            <div class="row col-1" >
                                <p class="card-text titulo-atividades" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'BA'">
                                    <strong>Exercida</strong>
                                </p>
                             </div>
                            <div class="row col-1" >
                                <p class="card-text titulo-atividades" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'BA'">
                                    <strong>Principal</strong>
                                </p>
                            </div>
                            <div class="row col-9">
                                <p class="card-text titulo-atividades">
                                    <strong>Atividade</strong>
                                </p>
                            </div>
                            <div class="row col-1"></div>
                        </div>
                        <div class="row linha-grid-atividades-selecionadas pt-2 pb-2" 
                            *ngFor="let atividade of atividadesSelecionadas; let i = index">
                            <div class="row col-12 justify-content-center align-items-center">
                            </div>
                            <div class="row col-1 justify-content-center align-items-center">
                                <mat-checkbox 
                                    [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'BA'"
                                    #atividadeExercidaCheckBox
                                    (change)="acaoCheckBoxAtividadeExercida(i, atividadeExercidaCheckBox, atividadePrincipalRadio)"
                                    [disabled]="bloqueadoPorCriticas"
                                    [checked]="atividade.exercida"
                                    id="atividadeExercida_{{ atividade.codigo }}"
                                    name="atividadeExercida">
                                </mat-checkbox>
                            </div>
                            <div class="row col-1 justify-content-center align-items-center">
                                <mat-radio-button
                                    [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'BA'"
                                    #atividadePrincipalRadio
                                    (change)="acaoRadioAtividadePrincipal(i)"
                                    [disabled]="bloqueadoPorCriticas"
                                    [checked]="atividade.principal"
                                    id="atividadePrincipal_{{ atividade.codigo }}"
                                    name="atividadePrincipal"
                                    value="{{ atividade.codigo }}">
                                </mat-radio-button>
                            </div>
                            <div [class.col-9]="tipoEmpresa != 'A' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'BA'" 
                                 [class.col-11]="tipoEmpresa == 'A' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'BA'" 
                                class="row justify-content-start align-items-center">
                                <p class="card-text" [innerHtml]="(atividade.codigo | mascara: 10) + ' - ' + formatarNomeAtividade(atividade.nome)"></p>
                            </div>
                            <div class="row col-1 justify-content-end align-items-center">
                                <button *ngIf="!bloqueadoPorCriticas"
                                        type="button" 
                                        matTooltip="Excluir"
                                        matTooltipPosition="right"
                                        class="btn"
                                        (click)="acaoExcluirAtividadeEscolhida(i)">
                                    <svg class="iconeListaAtividade">
                                        <use
                                            attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                                        ></use>
                                    </svg>
                                </button>
                                <button *ngIf="bloqueadoPorCriticas"
                                        type="button" 
                                        matTooltip="Validar"
                                        matTooltipPosition="right"
                                        class="btn"
                                        (click)="acaoValidarAtividadeEscolhida(i)">
                                    <svg class="iconeListaAtividade">
                                        <use
                                            attr.xlink:href="assets/sprite.svg#{{ 'refresh-cw' }}"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!atividadesSelecionadas.length"
                    class="card-body">
                    <p class="card-text">
                        Suas atividades apareceram aqui.
                    </p>
                </div>
            </div>
            <div class="form-row" *ngIf="cliente == '9999' || cliente == '1451'">
                <div class="form-group col-12 text-center">
                    <p class="card-text">
                        <strong>SR. CONTRIBUINTE!! FAVOR SELECIONAR TODAS AS ATIVIDADE EXERCIDAS;</strong> 
                    </p>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12 text-center" (click)="visualizarListaServicos()">
                    <p class="card-text btn texto-info">
                        <strong>Caro contribuinte, consulte aqui o item de serviço correlacionado a atividade CNAE;</strong> 
                    </p>
                </div>
            </div>
        </div>
    </div>    
    <div class="card" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BE' || tipoEmpresa == 'BM' || tipoEmpresa == 'BA' || tipoEmpresa == 'BO' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Tipo de Unidade</strong>
            </div>
        </div>
        <div class="card-body">
            <mat-radio-group 
                (change)="acaoRadioTipoUnidade()"
                #tipoUnidadeRadio 
                formControlName="tipoUnidade" 
                aria-label="Selecione uma opção">
                <div class="row">
                    <div class="form-group col-12">
                        <p class="card-text titulo">
                            <mat-radio-button value="1"><strong>Unidade Produtiva</strong></mat-radio-button>
                        </p>
                        <p class="card-text observacao">Unidade operacional, quando exercer atividades de produção ou venda de bens e/ou serviços destinados a terceiros.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <p class="card-text titulo">
                            <mat-radio-button value="2"><strong>Unidade Auxiliar</strong></mat-radio-button>
                        </p>
                        <p class="card-text observacao">Quando servir apenas à própria empresa, exercendo exclusivamente funções de apoio administrativo ou técnico, voltadas à criação das condições necessárias para o exercício das atividades operacionais dos demais estabelecimentos, não desenvolvendo atividade econômica de produção ou de venda de bens e/ou serviços.</p>
                    </div>
                </div>
                
                <div *ngIf="this.tipoUnidade.value === '2'"
                    class="row">
                    <div class="row justify-content-start align-items-center form-group col-3">
                        <p class="card-text titulo">
                            <strong>Tipo de Unidade Auxiliar</strong>
                        </p>
                    </div>
                    <div class="row justify-content-start align-items-center form-group col-9">
                        <select
                            #tipoUnidadeAuxiliarSelect
                            [class.is-invalid]="tipoUnidadeAuxiliar.invalid && tipoUnidadeAuxiliar.touched"
                            (change)="acaoTipoUnidadeAuxiliarSelect(tipoUnidadeAuxiliarSelect)"
                            formControlName="tipoUnidadeAuxiliar"
                            class="form-control form-control-sm"
                            name="tipoUnidadeAuxiliar" 
                            id="tipoUnidadeAuxiliar"                
                        >
                            <option disabled value="">--- Selecione ---</option>
                            <option value="{{ tipoUnidadeAuxiliar.codigo }}" *ngFor="let tipoUnidadeAuxiliar of tipoUnidadeAuxiliares;"
                                >{{ tipoUnidadeAuxiliar.nome }}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="this.tipoUnidade.value === '2'" class="row">&nbsp;</div>
                <div *ngIf="this.tipoUnidade.value === '2'" 
                    class="row">
                    <p class="card-text observacao"
                        [innerHtml]="observacaoTipoUnidadeAuxiliar">
                    </p>
                </div>
            </mat-radio-group>
        </div>
    </div>
    <div class="card" [class.hidden]="tipoEmpresa == 'BE' || tipoEmpresa == 'BM' || tipoEmpresa == 'BA' || tipoEmpresa == 'BO' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Formas de Atuação</strong>
            </div>
        </div>
        <div *ngIf="this.tipoUnidade.value !== '2'"
            class="card-body">
            <div class="form-group col-12 justify-content-center align-items-center">
                <p class="card-text titulo">
                    <strong>Refere-se às maneiras pelas quais as atividades mercantis e/ou serviços serão exercidos, mediante contraprestação financeira, podendo ser caracterizar por tipos.</strong>
                    <br>&nbsp;
                </p>
            </div>
            <div *ngFor="let formaAtuacao of formasAtuacaoConfiguracao; let i = index" 
                class="form-group col-12 justify-content-center align-items-center">
                <p class="card-text titulo">
                    <mat-checkbox 
                        #formaAtuacaoCheckBox
                        formControlName="formaAtuacaoCheckBox"
                        (change)="acaoCheckBoxFormaAtuacao(formaAtuacao.codigo, formaAtuacaoCheckBox)"
                        [checked]="acaoCheckedFormaAtuacao(formaAtuacao)"
                        id="FormaAtuacao_{{ formaAtuacao.codigo }}"
                        name="FormaAtuacao_{{ formaAtuacao.codigo }}">
                        <strong>{{ formaAtuacao.nome }}</strong>
                    </mat-checkbox>
                </p>
                <p class="card-text observacao">{{ formaAtuacao.observacao }}</p>
            </div>
        </div>
        <div *ngIf="this.tipoUnidade.value === '2'"
            class="card-body">
            <div class="form-group col-12 justify-content-center align-items-center">
                <p class="card-text observacao">Para formas de atuação selecione Unidade Produtiva.</p>
            </div>
        </div>
    </div>
    <div class="form-row" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'BE' && tipoEmpresa != 'BM' && tipoEmpresa != 'BA' && tipoEmpresa != 'BO' && tipoEmpresa != 'AE' && tipoEmpresa != 'AM' && tipoEmpresa != 'AA' && tipoEmpresa != 'AEV' && tipoEmpresa != 'AO' && tipoEmpresa != 'EEV'">
        <div class="form-group col-12">
            <label class="col-sm-12 col-form-label" for="descricaoAtividadeEconomica">Descreva suas atividades e como será o funcionamento do seu negócio</label>
            <textarea #descricaoAtividadeEconomicaTextArea
                class="form-control form-control-sm"
                [class.is-invalid]="descricaoAtividadeEconomica.invalid && descricaoAtividadeEconomica.touched"
                formControlName="descricaoAtividadeEconomica"
                id="descricaoAtividadeEconomica"
                name="descricaoAtividadeEconomica"
                maxlength="20000"
                cols="30" 
                rows="5"
            ></textarea>
            <div class="mb-1">
                <span 
                    class="erro" 
                    *ngIf="descricaoAtividadeEconomica.invalid && descricaoAtividadeEconomica.touched && descricaoAtividadeEconomica.errors.required"
                >Campo obrigatório</span>
                <span 
                    class="erro" 
                    *ngIf="descricaoAtividadeEconomica.invalid && descricaoAtividadeEconomica.touched && descricaoAtividadeEconomica.errors.maxlength"
                >A descrição deve conter até 20000 caracteres.</span>
                <span class="quantidade-caracter">
                    Quantidade de Caracteres: {{ descricaoAtividadeEconomica.value.length }} / 20000
                </span>
            </div>
        </div>
    </div>
</form>