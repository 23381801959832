import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Login } from 'src/app/model/login';
import { formatToPhone } from 'brazilian-values';
import { Constantes } from 'src/app/model/constantes';

@Component({
  selector: 'app-resumo-cadastro',
  templateUrl: './resumo-cadastro.component.html',
  styleUrls: ['./resumo-cadastro.component.scss']
})
export class ResumoCadastroComponent implements OnInit {
  
  tipoTelefone = Constantes.TIPO_DE_TELEFONE;
  login: Login;

  constructor() {}

  ngOnInit(): void {
  }
}

