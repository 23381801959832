import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";

import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import PerfectScrollbar from 'perfect-scrollbar';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};

  @Input() navCollapsed;
  public menuItems: any;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.authenticationService.currentAxis.subscribe((axis) => {
      if (axis != "" && axis != null)
        this.menuItems = axis.item;
      
      this.menuItems.map((item) => {
        const pathArr = item.rota.split('.');
        pathArr.splice(0, 1);
        var path = pathArr.join('/');
        item.rotaSistema = '';
        item.rotaSistema = '/' + path;
        if (!item.item.length) {
          item.rotaSistema += '/' + item.orgao.toString() + '/' + item.codigo.toString();
        }
      });

      this.atualizarScrollBar();
    });
  }

  atualizarScrollBar() {
    setTimeout(() => {
      const container = document.querySelector('.ps');
      if (container != null) {
        const ps = new PerfectScrollbar(container, {
            wheelSpeed: 0.1,
            wheelPropagation: true,
            minScrollbarLength: 40
        });
        container.scrollTop = 0;
        ps.update();
      }
    }, 1);
}
}
