import { Atendimento } from "./atendimento";
import { Telefone } from "./telefone";
import { Socio } from "./socio";
import { Login } from "./login";
import { PerguntaResposta } from "./perguntaResposta";
import { Observacao } from "./observacao";
import { Requisito } from './requisito';

export class Fic {
    cliente: string;
    inscricao: string;
    protocolo: string;
    automatizado: boolean;
    atendimentos: Array<Atendimento>;
    nomeEmpresa: string;
    nomeFantasia: string;
    tipoUnidade: string;
    cnpjFilial: string;
    objetoSocial: string;
    telefones: Array<Telefone>;
    emails: Array<string>;
    sites: Array<string>;
    socios: Array<Socio>;
    contador: Login;
    perguntasUsuario: PerguntaResposta;
    perguntasAuditor: PerguntaResposta;
    requisitos: Array<Requisito>;
    observacoes: Array<Observacao>;

    constructor() {
        this.cliente = "";
        this.inscricao = "";
        this.protocolo = "";
        this.automatizado = false;
        this.atendimentos = new Array<Atendimento>();
        this.nomeEmpresa = "";
        this.nomeFantasia = "";
        this.tipoUnidade = "";
        this.cnpjFilial = "";
        this.objetoSocial = "";
        this.telefones = new Array<Telefone>();
        this.emails = new Array<string>();
        this.sites = new Array<string>();
        this.socios = new Array<Socio>();
        this.contador = new Login();
        this.perguntasUsuario = new PerguntaResposta();
        this.perguntasAuditor = new PerguntaResposta();
        this.requisitos = new Array<Requisito>();
        this.observacoes = new Array<Observacao>();
    }
}