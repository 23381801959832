<div class="justify-content-md-end" [class.hidden]="!exibirListagem">
  <div class="row col-12 top-atendimento">
    <div class="row col-12 container-principal-atendimento container-tamanho pt-3 pb-3">
        <div class="row col-6 justify-content-start align-items-center">
            <button type="button" class="btn btn-secondary" (click)="acaoBotaoVoltar($event)" >Voltar</button>
        </div>
        <div class="row col-6 justify-content-end align-items-center divisao-esquerda">
          <button type="button" class="btn btn-success" (click)="acaoNovoPergunta()" >Cadastrar Pergunta</button>
        </div>
    </div>
  </div>
  <div class="row col-12" class="w-100" >
    <app-atendimento-grid (acaoMenuAtendimento)="acaoAtendimento($event)" ></app-atendimento-grid>
  </div>
</div>