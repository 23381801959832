<div
  *ngIf="
    showUserDropdown ||
    showEixosDropdown ||
    showNotificationDropdown ||
    showCompaniesDropdown
  "
  (click)="
    showUserDropdown = false;
    showEixosDropdown = false;
    showNotificationDropdown = false;
    showCompaniesDropdown = false
  "
  class="dropdown-overlay"
></div>
<div class="header-container fixed-top">
  <div class="topbar-acessibilidade">
    <div class="container-fluid">
        <ul class="float-left hide-phone">
            <li><a accesskey="1" href="javascript:void(0);" (click)="goToAnchor('content')">1 - Ir para o conteúdo</a></li>
            <li><a accesskey="2" href="javascript:void(0);" (click)="goToAnchor('navigation')">2 - Ir para o menu</a></li>
            <li><a id="btnIrBusca" accesskey="3" href="javascript:void(0);" (click)="goToAnchor('portal-busca')">3 - Ir para a busca</a></li>
            <li><a accesskey="4" href="javascript:void(0);" (click)="goToAnchor('footer')">4 - Ir para o rodapé</a></li>
        </ul>
        <div class="float-right">
            <a [routerLink]="['/acessibilidade']" accesskey="5" class="hide-phone">ACESSIBILIDADE</a>
            <span class="ml-2 mr-2 hide-phone">|</span>
            <a [routerLink]="['/termo']" accesskey="5" class="hide-phone">TERMO</a>
            <span class="ml-2 mr-2 hide-phone">|</span>
            <a href="javascript:;" accesskey="6" class="btn-contraste"
              (click)="mudarContraste()">ALTO CONTRASTE</a>
            <span class="ml-2 mr-2">|</span>
            <a id="btn-fontincrease" href="javascript:;" accesskey="7"
              (click)="mudarTamanhoFonte(1)">A+</a>&nbsp;
            <a id="btn-fontdecrease" href="javascript:;" accesskey="8"
              (click)="mudarTamanhoFonte(0)">A-</a>
            <span class="ml-2 mr-2">|</span>
            <a [routerLink]="['/mapa-do-site']" accesskey="9">MAPA DO SITE</a>
        </div>
    </div>
  </div>
  <header class="header navbar navbar-expand-sm">
    <ul class="navbar-item theme-brand flex-row text-center">
      <li class="nav-item theme-logo">
        <a href="index.html">
          <svg class="logo">
            <use
              class="logo_icon"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#_logo_iCad_Completo"
            ></use>
          </svg>
        </a>
      </li>
      <li class="nav-item theme-text">
        <div class="logoPref">
          <div>
            <img src="{{ 'assets/img/placeholder/brasao_' + codigoCliente + '.gif'}}" alt="logo" />
          </div>
          <div>
            <h4>{{ nomeCliente }}</h4>
            <p>{{ departamentoUsuario }}</p>
          </div>
        </div>
      </li>
    </ul>

    <ul class="navbar-item flex-row ml-md-auto" style="height: 45px;">
      <app-pesquisa-menu></app-pesquisa-menu>

      <app-usuario
        [showUserDropdown]="showUserDropdown"
        [user]="true"
        [webUser]="false"
        (clickUserDropDown)="
          showUserDropdown = true;
          showEixosDropdown = false;
          showNotificationDropdown = false;
          showCompaniesDropdown = false
        "
      ></app-usuario>

      <li class="user-atach">
        <div class="nomeCargo">
          <p>{{ nomeUsuario }}</p>
          <span>{{ cargoUsuario }}</span>
        </div>
      </li>

      <app-eixos
        [showEixosDropdown]="showEixosDropdown"
        (clickEixosDropDown)="
          showUserDropdown = false;
          showEixosDropdown = true;
          showNotificationDropdown = false;
          showCompaniesDropdown = false
        "
      ></app-eixos>

      <app-notificacoes
        [showNotificationDropdown]="showNotificationDropdown"
        (clickNotificationDropDown)="
          showUserDropdown = false;
          showEixosDropdown = false;
          showNotificationDropdown = true;
          showCompaniesDropdown = false
        "
      ></app-notificacoes>

      <app-empresas-eicon
        [showCompaniesDropdown]="showCompaniesDropdown"
        [user]="false"
        (clickCompaniesDropDown)="
          showUserDropdown = false;
          showEixosDropdown = false;
          showNotificationDropdown = false;
          showCompaniesDropdown = true
        "
      ></app-empresas-eicon>

      <app-usuario
        class="user-mobile-responsive"
        [showUserDropdown]="showUserDropdown"
        [user]="false"
        [webUser]="true"
        (clickUserDropDown)="
          showUserDropdown = true;
          showEixosDropdown = false;
          showNotificationDropdown = false;
          showCompaniesDropdown = false
        "
      >
        <div class="nomeCargo">
          <p>{{ nomeUsuario }}</p>
          <span>{{ cargoUsuario }}</span>
        </div>
      </app-usuario>
    </ul>
  </header>
</div>
