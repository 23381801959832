import { Protocolo } from "src/app/model/protocolo";
import { Endereco } from "src/app/model/endereco";
import { MascaraPipe } from "src/app/pipes/mascara.pipe";
import { formatToCPFOrCNPJ, formatToPhone, formatToCEP } from "brazilian-values";
import { Configuracao } from "src/app/model/configuracao";
import { Constantes } from "src/app/model/constantes";
import { CreatePdf } from '../createPdf';


export class Comprovantes extends CreatePdf {
    
    mascara: MascaraPipe;
    configuracao: Configuracao;
    
    constructor(public documento , private protocolo: Protocolo) {
        super();
        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        this.mascara = new MascaraPipe();
        
        super.documento = documento;

        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
    }

    abrirPDF(){
        super.abrirPDF();
    }

    montarRequerente(i) {
        var cpfCnpj = formatToCPFOrCNPJ(this.protocolo.requerente.documentos[0].numero.toString());
        let nome = this.protocolo.requerente.nome;
        let email = this.protocolo.requerente.emails[0];
        let orgaoDeRegistro = this.protocolo.registro.orgaoNome;
        let naturezaJuridica = this.protocolo.registro.naturezaJuridica.nome;
        
        let telefoneFixo = "";
        let telefoneComercial = "";
        let celular = "";

        this.protocolo.requerente.telefones.forEach( telefone => {
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Residencial) {
                telefoneFixo = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Comercial) {
                telefoneComercial = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Celular) { 
                celular = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
            }
        });


        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'REQUERENTE', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D' },{} ] ], widths: [130,'*'], headerRows: 1, } },
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "CPF/CNPJ", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: cpfCnpj, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Nome", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: nome, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "E-mail", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: email, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Telefone Fixo", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: telefoneFixo, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Telefone Comercial", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: telefoneComercial, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Celular", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: celular, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        if(orgaoDeRegistro == ''){ 
            this.documento.content[i][0].table.body.push([
                {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        }else{
             this.documento.content[i][0].table.body.push([
                {text: "Órgão de Registro", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: orgaoDeRegistro, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "Natureza Jurídica", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: naturezaJuridica, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        }
    }

    montarLocalizacao(i) {        
        if (this.protocolo.viabilidade.enderecos[0] == null) {
            this.protocolo.viabilidade.enderecos.push(new Endereco());
        }

        if (this.protocolo.viabilidade.enderecos[1] == null) {
            this.protocolo.viabilidade.enderecos.push(new Endereco());
        }
        
        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'LOCALIZAÇÃO', colSpan: 3, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D'  },{},{} ] ], widths: ['*',10,'*'], headerRows: 1, }, layout: 'noBorders' },
        ]);
        
        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""}, {},
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Endereço Empresarial", colSpan: 1, style: "subTitulo2", border: [false, false, false, false], fillColor: "#e6e6e6"}, {},
            {text: "Endereço Correspondência", colSpan: 1, style: "subTitulo2", border: [false, false, false, false], fillColor: "#e6e6e6"}
        ]);
        this.documento.content[i][0].table.body.push([
            {
                table: {
                    body: [
                        [  
                            {text:'Cep', colSpan: 1, style: "subTitulo"},
                            {text: formatToCEP(this.protocolo.viabilidade.enderecos[0].cep.toString()), colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Inscrição', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[0].inscricaoFormatada, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Endereço', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[0].tituloLogradouro + ' ' + this.protocolo.viabilidade.enderecos[0].logradouro + ', ' + this.protocolo.viabilidade.enderecos[0].numero, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Compl', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[0].complemento, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Ref', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[0].pontoReferencia, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Bairro', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[0].bairro, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Cidade', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[0].cidade, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Estado', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[0].estado, colSpan: 1, style: ""},
                        ],
                    ]
                },
                layout: 'noBorders'
            }, {},
            {
                table: {
                    body: [
                        [  
                            {text:'Cep', colSpan: 1, style: "subTitulo"},
                            {text: formatToCEP(this.protocolo.viabilidade.enderecos[1].cep.toString()), colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Inscrição', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[1].inscricaoFormatada, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Endereço', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[1].tituloLogradouro + ' ' + this.protocolo.viabilidade.enderecos[1].logradouro + ', ' + this.protocolo.viabilidade.enderecos[1].numero, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Compl', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[1].complemento, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Ref', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[1].pontoReferencia, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Bairro', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[1].bairro, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Cidade', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[1].cidade, colSpan: 1, style: ""},
                        ],
                        [
                            {text:'Estado', colSpan: 1, style: "subTitulo"}, 
                            {text: this.protocolo.viabilidade.enderecos[1].estado, colSpan: 1, style: ""},
                        ],
                    ]
                },
                layout: 'noBorders'
            }
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""}, {},
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
    }

    montarAtividades(i) {
        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'ATIVIDADES', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D'  },{} ] ], widths: [50,'*'], headerRows: 1, } }
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);

        this.protocolo.viabilidade.atividadesExercidas.forEach( atividade => {
            if (atividade.principal) {
                this.documento.content[i][0].table.body.push([
                    {text: "PRINCIPAL", colSpan: 1, style: "titulo2", border: [false, false, false, false], fillColor: "#00A75D"} ,
                    {text: this.mascara.transform(atividade.codigo, 10) + ' - ' + atividade.nome, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
                ]);
                this.documento.content[i][0].table.body.push([
                    {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                    {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
                ]);
            }
        });

        this.protocolo.viabilidade.atividadesExercidas.forEach( atividade => {
            if (!atividade.principal) {
                this.documento.content[i][0].table.body.push([
                    {text: "EXERCIDA", colSpan: 1, style: "titulo2", border: [false, false, false, false], fillColor: "#007387"} ,
                    {text: this.mascara.transform(atividade.codigo, 10) + ' - ' + atividade.nome, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
                ]);
                this.documento.content[i][0].table.body.push([
                    {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                    {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
                ]);
            }
        });

        this.protocolo.viabilidade.atividadesNaoExercidas.forEach( atividade => {
            this.documento.content[i][0].table.body.push([
                {text: "NÃO EXERCIDA", colSpan: 1, style: "titulo2", border: [false, false, false, false], fillColor: "#748892"} ,
                {text: this.mascara.transform(atividade.codigo, 10) + ' - ' + atividade.nome, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        });
        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        
    }

    montarTipoEstabelecimento(i) {
        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'TIPO DE ESTABELECIMENTO', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D'  },{} ] ], widths: ['*','*'], headerRows: 1, } }
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
        ]);
        
        if (this.protocolo.fic.tipoUnidade == 'M') {
            this.documento.content[i][0].table.body.push([
                {text: "Matriz", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "É o estabelecimento sede da empresa.", colSpan: 2, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        }
        else if (this.protocolo.fic.tipoUnidade == 'F') {
            this.documento.content[i][0].table.body.push([
                {text: "Filial", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "É o estabelecimento que esta subordinado / vinculado a matriz da empresa", colSpan: 2, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "CNPJ Matriz", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: this.protocolo.fic.cnpjFilial, colSpan: 2, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        }

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
        ]);
    }

    montarTipoUnidade(i) {
        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'TIPO DE UNIDADE', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D'  },{} ] ], widths: ['*','*'], headerRows: 1, } }
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
        ]);
        
        if (this.protocolo.viabilidade.tipoUnidade == '1') {
            this.documento.content[i][0].table.body.push([
                {text: "Unidade Produtiva", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "Unidade operacional, quando exercer atividades de produção ou venda de bens e/ou serviços destinados a terceiros.", colSpan: 2, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        }
        else if (this.protocolo.viabilidade.tipoUnidade == '2') {
            this.documento.content[i][0].table.body.push([
                {text: "Unidade Auxiliar", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "Quando servir apenas à própria empresa, exercendo exclusivamente funções de apoio administrativo ou técnico, voltadas à criação das condições necessárias para o exercício das atividades operacionais dos demais estabelecimentos, não desenvolvendo atividade econômica de produção ou de venda de bens e/ou serviços.", colSpan: 2, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: this.protocolo.viabilidade.nomeUnidadeAuxiliar + ' - ' + this.protocolo.viabilidade.observacaoUnidadeAuxiliar, colSpan: 2, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        }
    }

    montarDescricaoDoNegocio(i) {
        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'DESCRIÇÃO DE SUAS ATIVIDADES E COMO SERÁ O FUNCIONAMENTO DO SEU NEGÓCIO', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D'  },{} ] ], widths: ['*','*'], headerRows: 1, } }
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
        ]);

        this.documento.content[i][0].table.body.push([
            {text: this.protocolo.viabilidade.descricaoAtividadeEconomica, colSpan: 2, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        
        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
        ]);
    }

    montarFormaAtuacao(i) {
        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'FORMAS DE ATUAÇÃO', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D'  },{} ] ], widths: ['*','*'], headerRows: 1, } }
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
        ]);

        this.protocolo.viabilidade.formasAtuacao.forEach( formaAtuacao => {
            this.documento.content[i][0].table.body.push([
                {text: formaAtuacao.nome, colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: formaAtuacao.observacao, colSpan: 2, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        });

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
        ]);
    }

    montarQuestionario(i) {
        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'QUESTIONÁRIO', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D'  },{} ] ], widths: [200,'*'], headerRows: 1, } }
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        
        this.protocolo.viabilidade.perguntasUsuario.perguntas.forEach( pergunta => {

            this.documento.content[i][0].table.body.push([
                {text: pergunta.nome, colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: 'R.: ' + (pergunta.respostas[0] != null ? pergunta.respostas[0].nome : ''), colSpan: 2, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        });
    }

    montarQuestionarioFic(i) {
        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'QUESTIONÁRIO', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D'  },{} ] ], widths: [200,'*'], headerRows: 1, } }
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        
        this.protocolo.fic.perguntasUsuario.perguntas.forEach( pergunta => {

            this.documento.content[i][0].table.body.push([
                {text: pergunta.nome, colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: 'R.: ' + (pergunta.respostas[0] != null ? pergunta.respostas[0].nome : ''), colSpan: 2, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        });
    }

    montarDadosEncerramento(i){
        let motivoBaixa = this.protocolo.encerramento.motivosEncerramento[0].descricao;
        let dataPredendida = this.protocolo.encerramento.data;
        let obsBaixa = this.protocolo.encerramento.descricao;
        
        this.documento.content.push([
            {style: 'table',  table: { body: [ [ { text: 'DADOS DA BAIXA', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D' },{} ] ], widths: [130,'*'], headerRows: 1, } },
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Motivo", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: motivoBaixa, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "Data Pretendida", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: dataPredendida, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "Obsrvações", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: obsBaixa, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);

    }

    montarContribuinte(i) {
        if (this.protocolo.processo.tipoPessoa == Constantes.TIPO_PESSOA.Juridica){
            this.montarContribuinteJuridica(i);
        }
        else if (this.protocolo.processo.tipoPessoa == Constantes.TIPO_PESSOA.Fisica){
            this.montarContribuinteFisica(i);
        }
    }
    
    montarContribuinteJuridica(i) {
        let nomeEmpresarial = this.protocolo.fic.nomeEmpresa;
        let nomeFantasia = this.protocolo.fic.nomeFantasia;
        let email = this.protocolo.fic.emails[0];
        let site = this.protocolo.fic.sites;
        
        let telefoneFixo = "";
        let telefoneComercial = "";
        let celular = "";

        this.protocolo.requerente.telefones.forEach( telefone => {
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Residencial) {
                telefoneFixo = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Comercial) {
                telefoneComercial = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Celular) { 
                celular = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
            }
        });

        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'DADOS DO CONTRIBUINTE', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D' },{} ] ], widths: [130,'*'], headerRows: 1, } },
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Nome Empresarial", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: nomeEmpresarial, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Nome Fantasia", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: nomeFantasia, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Telefone Fixo", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: telefoneFixo, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Telefone Comercial", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: telefoneComercial, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Celular", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: celular, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "E-mail", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: email, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Site", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: site, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
    }
    
    montarContribuinteFisica(i) {
        let nome = this.protocolo.registro.nome;
        let email = this.protocolo.fic.emails[0];
        let site = this.protocolo.fic.sites;
        
        let telefoneFixo = "";
        let telefoneComercial = "";
        let celular = "";

        this.protocolo.requerente.telefones.forEach( telefone => {
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Residencial) {
                telefoneFixo = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Comercial) {
                telefoneComercial = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
            }
            if (telefone.tipo == Constantes.TIPO_DE_TELEFONE.Celular) { 
                celular = formatToPhone(telefone.ddd.toString().concat(telefone.numero.toString()));
            }
        });

        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'DADOS DO CONTRIBUINTE', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D' },{} ] ], widths: [130,'*'], headerRows: 1, } },
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Nome", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: nome, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Telefone Fixo", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: telefoneFixo, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Telefone Comercial", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: telefoneComercial, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Celular", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: celular, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "E-mail", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: email, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Site", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: site, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
    }

    montarRegistro(i) {
        if (this.protocolo.processo.tipoPessoa == Constantes.TIPO_PESSOA.Juridica){
            this.montarRegistroJuridica(i);
        }
        else if (this.protocolo.processo.tipoPessoa == Constantes.TIPO_PESSOA.Fisica){
            this.montarRegistroFisica(i);
        }
    }

    montarRegistroJuridica(i) {
        let orgaoDeRegistro = this.protocolo.registro.orgaoNome;
        let naturezaJuridica = this.protocolo.registro.naturezaJuridica.nome;

        let documento = '';
        this.configuracao.processos.forEach( processo => {
            processo.servicos.forEach( servico => {
                if (servico.codigo.toString() == this.protocolo.registro.orgao) {
                    servico.naturezaJuridicas.forEach( natureza => {
                        if (natureza.codigo.replace(/\D/g,"") == this.protocolo.registro.naturezaJuridica.codigo.replace(/\D/g,"")) {
                            natureza.documentos.forEach( documentoAux => {
                                if (documentoAux.codigo == this.protocolo.registro.tipo) {
                                    documento = documentoAux.nome;
                                }
                            })
                        }
                    })
                }
            })
        });
        
        let porte = this.protocolo.registro.porte;
        let capitalSocial = this.protocolo.registro.capitalSocial;
        let dataAlteracao = this.protocolo.registro.ultimaAlteracao;
        let nire = this.protocolo.registro.numero;
        let dataNire = this.protocolo.registro.data;

        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'DADOS DO REGISTRO', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D' },{} ] ], widths: [130,'*'], headerRows: 1, } },
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Órgão de Registro", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: orgaoDeRegistro, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Natureza Jurídica", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: naturezaJuridica, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Documento", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: documento, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Porte", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: porte, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Capital Social", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: capitalSocial, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Data Ult. Alteração", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: dataAlteracao, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "NIRE", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: nire, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Data NIRE", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: dataNire, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
    }

    montarRegistroFisica(i) {
        let nascimento = this.protocolo.registro.nascimento;
        let sexo = Constantes.TIPO_SEXO_NUMBER_DESCRICAO[this.protocolo.registro.sexo];
        let escolaridade = this.protocolo.registro.escolaridade.nome;
        let dataAbertura = this.protocolo.registro.data;

        let documentosAutonomo = [];

        this.protocolo.registro.documentos.forEach( documento => {
            if (documento.tipo != Constantes.TIPO_DE_DOCUMENTO.Municipal) {
                documentosAutonomo.push([documento.tipo, documento.numero, documento.dataEmissao]);
            }
        });

        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'DADOS DO REGISTRO', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D' },{} ] ], widths: [130,'*'], headerRows: 1, } },
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Nascimento", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: nascimento, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Sexo", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: sexo, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Escolaridade", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: escolaridade, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        documentosAutonomo.forEach( documento => {
            this.documento.content[i][0].table.body.push([
                {text: Constantes.TIPO_DE_DOCUMENTO_DESCRICAO[documento[0]], colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cpf ? formatToCPFOrCNPJ(documento[1].toString()) : formatToCPFOrCNPJ(documento[1].toString())) + (documento[2] == '' ? '' : ' - Emissão: ' + documento[2]), colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        });
        this.documento.content[i][0].table.body.push([
            {text: "Data Abertura", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: dataAbertura, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);

    }

    montarInscricao(i) {
        if (this.protocolo.processo.tipoPessoa == Constantes.TIPO_PESSOA.Juridica){
            this.montarInscricaoJuridica(i);
        }
        else if (this.protocolo.processo.tipoPessoa == Constantes.TIPO_PESSOA.Fisica){
            this.montarInscricaoFisica(i);
        }
    }

    montarInscricaoJuridica(i) {
        let cpfCnpj = '';
        let dataCnpj = '';
        let inscricaoEstadual = '';
        let dataInscricaoEstadual = '';
        let inscricaoMunicipal = '';
        let dataInscricaoMunicipal = '';

        this.protocolo.registro.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj) {
                cpfCnpj = formatToCPFOrCNPJ(documento.numero.toString());
                dataCnpj = documento.dataEmissao;
            }
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Estadual) {
                inscricaoEstadual = documento.numero;
                dataInscricaoEstadual = documento.dataEmissao;
            }
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Municipal) {
                inscricaoMunicipal = documento.numero;
                dataInscricaoMunicipal = documento.dataEmissao;
            }
            
        });

        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'DADOS DE INSCRIÇÃO', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D' },{} ] ], widths: [130,'*'], headerRows: 1, } },
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "CNPJ", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: cpfCnpj, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Data CNPJ", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: dataCnpj, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Inscrição Municipal", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: inscricaoMunicipal, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Data Inscrição Municipal", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: dataInscricaoMunicipal, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Inscrição Estadual", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: inscricaoEstadual, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Data Inscrição Estadual", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: dataInscricaoEstadual, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
    }

    montarInscricaoFisica(i) {
        let inscricaoMunicipal = '';
        let dataInscricaoMunicipal = '';

        this.protocolo.registro.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Municipal) {
                inscricaoMunicipal = documento.numero;
                dataInscricaoMunicipal = documento.dataEmissao;
            }
            
        });

        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'DADOS DE INSCRIÇÃO', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D' },{} ] ], widths: [130,'*'], headerRows: 1, } },
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Inscrição Municipal", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: inscricaoMunicipal, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
        this.documento.content[i][0].table.body.push([
            {text: "Data Inscrição Municipal", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
            {text: dataInscricaoMunicipal, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
        ]);
    }

    montarSocios(i) {
        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: 'QUADRO SOCIETÁRIO', colSpan: 2, style: 'titulo', border: [false, false, false, false], fillColor: '#00A75D'  },{} ] ], widths: [130,'*'], headerRows: 1, } }
        ]);

        this.protocolo.fic.socios.forEach( socio =>{
            this.documento.content[i][0].table.body.push([
                {text: "", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: "", colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);

            if (socio.responsavelLegal && socio.contador) {
                this.documento.content[i][0].table.body.push([
                    {text: "LEGAL", colSpan: 2, style: "titulo2", border: [false, false, false, false], fillColor: "#00A75D"}
                ]);
                this.documento.content[i][0].table.body.push([
                    {text: "CONTADOR", colSpan: 2, style: "titulo2", border: [false, false, false, false], fillColor: "#007387"}
                ]);
            }
            else if (socio.responsavelLegal) {
                this.documento.content[i][0].table.body.push([
                    {text: "LEGAL", colSpan: 2, style: "titulo2", border: [false, false, false, false], fillColor: "#00A75D"}
                ]);
            }
            else if (socio.contador) {
                this.documento.content[i][0].table.body.push([
                    {text: "CONTADOR", colSpan: 2, style: "titulo2", border: [false, false, false, false], fillColor: "#007387"}
                ]);
            }
            else {
                this.documento.content[i][0].table.body.push([
                    {text: "SÓCIO", colSpan: 2, style: "titulo2", border: [false, false, false, false], fillColor: "#748892"}
                ]);
            }
            this.documento.content[i][0].table.body.push([
                {text: socio.login.nome, colSpan: 2, style: "subTitulo", border: [false, false, false, false], fillColor: ""}
            ]);
            this.documento.content[i][0].table.body.push([
                {text: "CPF / CNPJ", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: formatToCPFOrCNPJ(("000.000.000-00" + socio.login.login).slice(-11)) , colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
            if (socio.contador && socio.qualificacao != "") {
                this.documento.content[i][0].table.body.push([
                    {text: "Qualificação", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                    {text: socio.qualificacaoNome, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
                ]);
                this.documento.content[i][0].table.body.push([
                    {text: "Participação", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                    {text: socio.participacao, colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
                ]);
            }
            
            this.documento.content[i][0].table.body.push([
                {text: "Endereço", colSpan: 1, style: "subTitulo", border: [false, false, false, false], fillColor: ""} ,
                {text: socio.login.enderecos[0].tipoLogradouro + ' ' +
                    socio.login.enderecos[0].logradouro + 
                    (socio.login.enderecos[0].numero == '' ? '' : ', ') + 
                    (socio.login.enderecos[0].numero) + 
                    (socio.login.enderecos[0].complemento == '' ? '' : ' - ') + 
                    (socio.login.enderecos[0].complemento) + 
                    (socio.login.enderecos[0].pontoReferencia == '' ? '' : ' - ') + 
                    (socio.login.enderecos[0].pontoReferencia) + 
                    (socio.login.enderecos[0].cep == '' ? '' : ' - ') + 
                    (formatToCEP(("00000000" + socio.login.enderecos[0].cep).slice(-8))) + 
                    (socio.login.enderecos[0].bairro == '' ? '' : ' - ') + 
                    (socio.login.enderecos[0].bairro) + 
                    (socio.login.enderecos[0].cidade == '' ? '' : ' - ') + 
                    (socio.login.enderecos[0].cidade) + 
                    (socio.login.enderecos[0].estado == '' ? '' : ' - ') + 
                    (socio.login.enderecos[0].estado) 
                    , colSpan: 1, style: "", border: [false, false, false, false], fillColor: ""}
            ]);
        });
    }

    montarRequisitos(i) {
        this.documento.content.push([
            { style: 'table',  table: { body: [ [ { text: '', colSpan: 5, style: '', border: [false, false, false, false], fillColor: ''  },{},{},{},{} ] ], widths: [70,'*','*',70,70], headerRows: 1, } }
        ]);

        this.documento.content[i][0].table.body.push([
            {text: "Risco"      , colSpan: 1, style: "titulo", border: [false, false, false, false], fillColor: "#00A75D"},
            {text: "Órgão"      , colSpan: 1, style: "titulo", border: [false, false, false, false], fillColor: "#00A75D"},
            {text: "Requisito"  , colSpan: 1, style: "titulo", border: [false, false, false, false], fillColor: "#00A75D"},
            {text: "Vencimento" , colSpan: 1, style: "titulo", border: [false, false, false, false], fillColor: "#00A75D"},
            {text: "Avaliação"  , colSpan: 1, style: "titulo", border: [false, false, false, false], fillColor: "#00A75D"}
        ]);

        this.protocolo.viabilidade.requisitos.forEach( requisito => {
            var corGrauDeRisco = "secondaryMargin";

            if (requisito.requisitoRisco.grauDeRisco <= "3" && requisito.requisitoRisco.grauDeRisco != "") {
                corGrauDeRisco = "primaryMargin";
            }
            else if (requisito.requisitoRisco.grauDeRisco == "3") {
                corGrauDeRisco = "warningMargin";
            }
            else if (requisito.requisitoRisco.grauDeRisco >= "4") {
                corGrauDeRisco = "dangerMargin";
            }
            
            var corAvaliacao = "secondaryMargin";
            var descricaoAvaliacao = "NÃO AVALIADO";

            if (requisito.statusAvaliacao == "S") {
                corAvaliacao = "primaryMargin";
                descricaoAvaliacao = "DEFERIDO";
            }
            else if (requisito.statusAvaliacao == "N") {
                corAvaliacao = "dangerMargin";
                descricaoAvaliacao = "INDEFERIDO";
            }
            else if (requisito.statusAvaliacao == "C") {
                corAvaliacao = "dangerMargin";
                descricaoAvaliacao = "CANCELADO";
            }
            else if (requisito.statusAvaliacao == "P") {
                corAvaliacao = "warningMargin";
                descricaoAvaliacao = "PENDENTE";
            }

            this.documento.content[i][0].table.body.push([
                {text: requisito.requisitoRisco.descricao.toUpperCase()                                     , colSpan: 1, style: corGrauDeRisco     , border: [false, false, false, false], fillColor: "#f2f2f2"},
                {text: requisito.orgao.nome.toUpperCase()                                                   , colSpan: 1, style: "subTitulo"        , border: [false, false, false, false], fillColor: "#f2f2f2"},
                {text: requisito.nome.toUpperCase()                                                         , colSpan: 1, style: "subTitulo"        , border: [false, false, false, false], fillColor: "#f2f2f2"},
                {text: requisito.vencimento == "" ? 'INDETERMINADO' : requisito.vencimento.toUpperCase()    , colSpan: 1, style: "subTitulo"        , border: [false, false, false, false], fillColor: "#f2f2f2"},
                {text: descricaoAvaliacao.toUpperCase()                                                     , colSpan: 1, style: corAvaliacao       , border: [false, false, false, false], fillColor: "#f2f2f2"}
            ]);

            this.documento.content[i][0].table.body.push([
                {text: "", colSpan: 5, style: "subTitulo2", border: [false, false, false, false], fillColor: ""} ,
            ]);

            requisito.observacoes.forEach( observacao => {
                var corAvaliacaoObservacao = "";

                if (observacao.avaliacao == 'S') {
                    corAvaliacaoObservacao = "primary";
                }
                else if (observacao.avaliacao == '' || observacao.avaliacao == 'PA') {
                    corAvaliacaoObservacao = "secondary";
                }
                else if (observacao.avaliacao == 'P' || observacao.avaliacao == 'U') {
                    corAvaliacaoObservacao = "warning";
                }
                else if (observacao.avaliacao == 'N' || observacao.avaliacao == 'C') {
                    corAvaliacaoObservacao = "danger";
                }
                else if (observacao.avaliacao == 'R') {
                    corAvaliacaoObservacao = "dark";
                }
                
                var descricaoAvaliacaoObservacao = "NÃO AVALIADO";
    
                if (observacao.avaliacao == "S") {
                    descricaoAvaliacaoObservacao = "DEFERIDO";
                }
                else if (observacao.avaliacao == "N") {
                    descricaoAvaliacaoObservacao = "INDEFERIDO";
                }
                else if (observacao.avaliacao == 'P' || observacao.avaliacao == 'U') {
                    descricaoAvaliacaoObservacao = "PENDENTE";
                }
                else if (observacao.avaliacao == "C") {
                    descricaoAvaliacaoObservacao = "CANCELADO";
                }
                else if (observacao.avaliacao == "R") {
                    descricaoAvaliacaoObservacao = "REAVALIAÇÃO";
                }
                else if (observacao.avaliacao == "PA") {
                    descricaoAvaliacaoObservacao = "RESPOSTA";
                }
                
                this.documento.content[i][0].table.body.push([
                    {text: descricaoAvaliacaoObservacao, colSpan: 1, style: corAvaliacaoObservacao, border: [false, false, false, false], fillColor: ""},
                    {text: observacao.data + " - " +
                            (observacao.login.nome == '' ? 'NÃO INFORMADO' : observacao.login.nome) + " - " +
                            observacao.observacao , colSpan: 4, style: "", border: [false, false, false, false], fillColor: ""},
                ]);

                this.documento.content[i][0].table.body.push([
                    {text: "", colSpan: 5, style: "subTitulo2", border: [false, false, false, false], fillColor: ""} ,
                ]);
            });
        });
    }

}