import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Critica } from '../../../../model/critica';
import { Protocolo } from '../../../../model/protocolo';
import { AtendimentoAlvara } from '../../../../model/atendimentoAlvara';
import { AtendimentoService } from '../../../../services/operacao/atendimento.service';
import Swal from "sweetalert2";
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { Constantes } from '../../../../model/constantes';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { ProtocoloService } from '../../../../services/operacao/protocolo.service';
import { Documento } from '../../../../model/documento';

@Component({
  selector: 'app-atendimento-regerar',
  templateUrl: './atendimento-regerar.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoRegerarComponent implements OnInit {

  @Output() acaoDeAtendimento = new EventEmitter();
  @Input() criticas: Array<Critica>;
  public mostrarBotaoViabilidade: boolean = false;
  public mostrarBotaoPendenteOrgao: boolean = false;
  atendimentoAlvara = new AtendimentoAlvara();
  modulo: string;
  menuAtendimento = Constantes.MENU_ATENDIMENTO;
  numeroInscricao: string = "";
  temFic: boolean = false;
  documentos: Array<Documento>;

  permitirRegerarAlvaraProvisorio: boolean = true;
  permitirRegerarDispensaDeAlvara: boolean = false;

  protocolo: Protocolo;

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private arquivoService: ArquivoService,
    private atendimentoService: AtendimentoService,
    private protocoloService: ProtocoloService) {

    this.modulo = this.activatedRoute.snapshot.data["menu"];
    this.protocolo = new Protocolo();
  }

  ngOnInit(): void {

  }

  imprimirFic(){
    this.protocolo.documentos.forEach((documento) => {
      if(documento.nome == 'Ficha Cadastral'){
        this.temFic = true;
        this.baixarArquivo(documento.codigo,documento.numero);
      }
      if(!this.temFic){
        Swal.fire("", "Documento não localizado, necessário regerar! ", "success");
      }
    })
  }

  baixarArquivo(codigo, nome) {
    if (this.protocolo.documentos.filter( item => item.codigo == codigo)[0].status != 'N') {
      this.arquivoService.downloadArquivoAtendimento(nome)
        .subscribe((res: any) => {
          this.arquivoService.handleFile(res, nome + '.pdf');
        });
    }
  }

  visualizarArquivo(codigo, nome) {
    this.arquivoService.downloadArquivoAtendimentoParaVisualizar(nome)
      .subscribe((res: any) => {
        const dialogRef = this.dialog.open(IcadModalComponent, {
          width: '60%',
          height: '80%',
          maxWidth: 'none',
          maxHeight: 'none',
          closeOnNavigation: true,
          data: {
              titulo: "Informativo",
              componente: "pop-up",
              dados: [atob(res.html)]
          }
        });
      });
  }

  regerarFic(){
    this.modulo = this.activatedRoute.snapshot.data["menu"];
    this.atendimentoAlvara.protocolo = this.activatedRoute.snapshot.params["protocolo"];
    this.atendimentoAlvara.justificativa = 'Geração manual';
    
    Swal.fire({
      text: 'Confirmar a geração?',
      icon: 'question',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    })
    .then((result) => {
      if (!result.dismiss) {
        this.atendimentoService
          .registrarAtendimentoFichaCadastral(this.atendimentoAlvara)
          .pipe(take(1))
          .subscribe(retorno => {
            Swal.fire({
              title: retorno.titulo,
              html: retorno.mensagem,
              icon: retorno.status,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: true,
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
              willClose: () => {
              }
            })
            .then((result) => {
              if (!result.dismiss) {
                this.arquivoService.downloadArquivoAtendimento(retorno.documento.toString())
                  .subscribe((res: any) => {
                    this.arquivoService.handleFile(res, retorno.documento.toString() + '.pdf');
                  });
              }
            });
          }); 
      }
    });
  }

  regerarAlvaraProvisorio(){
    this.modulo = this.activatedRoute.snapshot.data["menu"];
    this.atendimentoAlvara.protocolo = this.activatedRoute.snapshot.params["protocolo"];
    this.atendimentoAlvara.inscricao = this.numeroInscricao;
    this.atendimentoAlvara.justificativa = 'Geração manual';
    
    Swal.fire({
      text: 'Confirmar a geração?',
      icon: 'question',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    })
    .then((result) => {
      if (!result.dismiss) {
        this.atendimentoService
          .registrarAtendimentoAlvaraProvisorioNosDadosCadastrais(this.atendimentoAlvara)
          .pipe(take(1))
          .subscribe(retorno => {
            Swal.fire({
              title: retorno.titulo,
              html: retorno.mensagem,
              icon: retorno.status,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: true,
              showCancelButton: retorno.status != "error",
              confirmButtonText: retorno.status != "error" ? 'Sim' : "OK",
              cancelButtonText: 'Não',
              willClose: () => {
              }
            })
            .then((result) => {
              if (retorno.status == "error") {
                return false;
              }

              if (!result.dismiss) {
                this.arquivoService.downloadArquivoAtendimento(retorno.documento.toString())
                  .subscribe((res: any) => {
                    this.arquivoService.handleFile(res, retorno.documento.toString() + '.pdf');
                  });
              }
            });
          }); 
      }
    });
  }

  regerarDispensa(){
    this.modulo = this.activatedRoute.snapshot.data["menu"];
    this.atendimentoAlvara.protocolo = this.activatedRoute.snapshot.params["protocolo"];
    this.atendimentoAlvara.inscricao = this.numeroInscricao;
    this.atendimentoAlvara.justificativa = 'Geração manual';
    
    Swal.fire({
      text: 'Confirmar a geração?',
      icon: 'question',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    })
    .then((result) => {
      if (!result.dismiss) {
        this.atendimentoService
          .registrarAtendimentoDispensaDeAlvaraNosDadosCadastrais(this.atendimentoAlvara)
          .pipe(take(1))
          .subscribe(retorno => {
            Swal.fire({
              title: retorno.titulo,
              html: retorno.mensagem,
              icon: retorno.status,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: true,
              showCancelButton: retorno.status != "error",
              confirmButtonText: retorno.status != "error" ? 'Sim' : "OK",
              cancelButtonText: 'Não',
              willClose: () => {
              }
            })
            .then((result) => {
              if (retorno.status == "error") {
                return false;
              }

              if (!result.dismiss) {
                this.arquivoService.downloadArquivoAtendimento(retorno.documento.toString())
                  .subscribe((res: any) => {
                    this.arquivoService.handleFile(res, retorno.documento.toString() + '.pdf');
                  });
              }
            });
          }); 
      }
    });
  }

  regerarBaixa(){
    this.atendimentoAlvara.protocolo = this.activatedRoute.snapshot.params["protocolo"];
    this.atendimentoAlvara.justificativa = 'Geração manual';
    
    Swal.fire({
      text: 'Confirmar a geração?',
      icon: 'question',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    })
    .then((result) => {
      if (!result.dismiss) {
        this.atendimentoService
          .registrarAtendimentoComprovantedeEncerramento(this.atendimentoAlvara)
          .pipe(take(1))
          .subscribe(retorno => {
            Swal.fire({
              title: retorno.titulo,
              html: retorno.mensagem,
              icon: retorno.status,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
              },
              buttonsStyling: true,
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
              willClose: () => {
              }
            })
            .then((result) => {
              if (!result.dismiss) {
                this.arquivoService.downloadArquivoAtendimento(retorno.documento.toString())
                  .subscribe((res: any) => {
                    this.arquivoService.handleFile(res, retorno.documento.toString() + '.pdf');
                  });
              }
            });
          }); 
      }
    });
  }

}
