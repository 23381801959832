<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<div>
    <div class="card">
        <div class="card-header" *ngIf="!semCardHeader">
            <div class="col-12">
                <strong>Atividades</strong>
            </div>
        </div>
        <div *ngIf="atendimentoConfiguracao == null" class="card-body" [class.p-0]="semCardHeader">
            <div class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text text-center">
                        Carregando...
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="atendimentoConfiguracao != null" 
            class="card-body p-0"
            [class.pt-2]="!permitirAtendimento">
            <div *ngFor="let atividadeExercida of atendimentoConfiguracao.viabilidade.atividadesExercidas; let i = index">
                <div class="row col-12 pt-1 pb-1 pl-0 pr-0">
                    <div class="row col-2 justify-content-left align-items-center">
                        <p class="card-text">
                            <button type="button" 
                                class="btn btn-sm btn-informacao-atividade"
                                [class.btn-secondary]="atividadeExercida.grauDeRisco == '0'"
                                [class.btn-danger]="atividadeExercida.grauDeRisco == '1'"
                                [class.btn-warning]="atividadeExercida.grauDeRisco == '2'"
                                [class.btn-success]="atividadeExercida.grauDeRisco == '3'"
                                >
                                {{ atividadeExercida.grauDeRisco == '0' ? 'SEM RISCO' :
                                   atividadeExercida.grauDeRisco == '1' ? 'ALTO RISCO' :
                                   atividadeExercida.grauDeRisco == '2' ? 'MÉDIO RISCO' :
                                   atividadeExercida.grauDeRisco == '3' ? 'BAIXO RISCO' : 'SEM RISCO' }}
                            </button>
                        </p>
                    </div>
                    <div class="row col-2 justify-content-left align-items-center">
                        <p class="card-text">
                            <button type="button" 
                                class="btn btn-sm btn-informacao-atividade"
                                [class.btn-secondary]="atividadeExercida.principal"
                                [class.btn-primary]="!atividadeExercida.principal">
                                {{ atividadeExercida.principal ? 'PRINCIPAL' : 'SECUNDÁRIA' }}
                            </button>
                        </p>
                    </div>
                    <div class="row justify-content-start align-items-center" 
                        [class.col-6]="permitirAtendimento"
                        [class.col-8]="!permitirAtendimento">
                        <p class="card-text" [innerHtml]="'<b>' + (atividadeExercida.codigo | mascara: 10) + '</b> - ' + atividadeExercida.nome"></p>
                    </div>
                    <div *ngIf="permitirAtendimento && !eBaixa && !eRecadastro" class="row col-2 pr-0 justify-content-center align-items-center">
                        <select 
                            #usoSelect
                            class="form-control form-control-sm col-12"
                            name="usos" 
                            id="usos"
                            (change)="acaoVincularAtividadeUso(i, usoSelect)"
                        >
                            <option value=""></option>
                            <option *ngFor="let uso of atividadeExercida.usos" 
                                [selected]="atividadeExercida.uso.codigo == uso.codigo"
                                value="{{ uso.codigo }}"
                            >
                                {{ uso.nome | uppercase }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="permitirAtendimento && eBaixa" class="row col-2 justify-content-center align-items-center">
                        <select 
                            #usoSelect
                            class="form-control form-control-sm col-12"
                            name="usos" 
                            id="usos"
                            (change)="acaoVincularAtividadeUsoSemJustificativa(i, usoSelect)"
                        >
                            <option value=""></option>
                            <option value="A" class="textoVerde"
                                [selected]="atividadeExercida.uso.tipo == 'A'">
                                <strong>Aceito</strong>
                            </option>
                            <option value="N" class="textoVermelho"
                                [selected]="atividadeExercida.uso.tipo == 'N'">
                                <strong>Recusado</strong>
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="!eBaixa && !eRecadastro" class="row col-12 pl-0 pr-0 justificativa-atividade-uso"
                    [class.hidden]="!atividadeExercida.uso.codigo">
                    <div class="row col-2 justify-content-left align-items-center">
                        <p class="card-text">
                            <button type="button" 
                                class="btn btn-sm btn-informacao-atividade"
                                [class.btn-success]="atividadeExercida.uso.tipo == tipoUso.Permitido"
                                [class.btn-warning]="atividadeExercida.uso.tipo == tipoUso.Permissivel"
                                [class.btn-danger]="atividadeExercida.uso.tipo == tipoUso.Nao_Permitido">
                                {{ 
                                    atividadeExercida.uso.tipo == tipoUso.Permitido ? 'PERMITIDO' : 
                                    atividadeExercida.uso.tipo == tipoUso.Permissivel ? 'PERMISSÍVEL' : 'NÃO PERMITIDO'
                                }}
                            </button>
                        </p>
                    </div>
                    <div class="row justify-content-start align-items-center pl-1"
                        [class.col-8]="permitirAtendimento"
                        [class.col-9]="!permitirAtendimento">
                        <div class="form-row col-2 justify-content-start align-items-center">
                            <p class="card-text font-italic">
                                <b>USO</b>
                            </p>
                        </div>
                        <div class="form-row col-10 justify-content-start align-items-center">
                            <p class="card-text font-italic">
                                {{ atividadeExercida.uso.nome }}
                            </p>
                        </div>
                        <div class="form-row col-2 justify-content-start align-items-center">
                            <p class="card-text font-italic">
                                <b>JUSTIFICATIVA</b>
                            </p>
                        </div>
                        <div class="form-row col-10 justify-content-start align-items-center">
                            <p class="card-text font-italic">
                                {{ atividadeExercida.uso.justificativa }}
                            </p>
                        </div>
                    </div>

                    <div *ngIf="permitirAtendimento" class="row col-2 pr-0 justify-content-end align-items-center">
                        <button type="button" 
                                matTooltip="Editar"
                                matTooltipPosition="above"
                                class="btn btn-sm"
                                (click)="acaoAlterarJustificativaAtividadeUso(i)">
                            <svg class="icone-editar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                                ></use>
                            </svg>
                        </button>
                        <button type="button" 
                                matTooltip="Replicar"
                                matTooltipPosition="above"
                                class="btn btn-sm"
                                (click)="acaoReplicarUsoEJustificativaParaTodasAtividades(i)">
                            <svg class="icone-replicar">
                                <use
                                    attr.xlink:href="assets/sprite.svg#{{ 'repeat' }}"
                                ></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>            
            <div *ngIf="!atendimentoConfiguracao.viabilidade.atividadesExercidas.length" class="card-body p-0">
                <div class="row col-12 justify-content-center align-items-center">
                    <p class="card-text text-center">
                        <br>
                        Não existem atividades exercidas.
                        <br>&nbsp;
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="campo-inconsistencia mb-5" 
    *ngIf="protocolo.existeInconsistenciaPorCampo('empresa.viabilidade.atividadesExercidas')">
    <div>
        <div>
            <div class="col-12 texto-warning pt-2 pb-2">
                <strong>Inconsistências</strong>
            </div>
        </div>
        <div *ngIf="protocolo.dadosDeInconsistencia('empresa.viabilidade.atividadesExercidas').length" class="card-body p-0">
            <div *ngFor="let atividadeExercida of protocolo.dadosDeInconsistencia('empresa.viabilidade.atividadesExercidas'); let i = index" class="pb-2">
                <div class="row col-12 pt-1 pb-1 pl-0 pr-0">
                    <div class="row col-2 justify-content-left align-items-center">
                        <p class="card-text">
                            <button type="button" 
                                class="btn btn-sm btn-informacao-atividade"
                                [class.btn-secondary]="atividadeExercida.grauDeRisco == '0'"
                                [class.btn-danger]="atividadeExercida.grauDeRisco == '1'"
                                [class.btn-warning]="atividadeExercida.grauDeRisco == '2'"
                                [class.btn-success]="atividadeExercida.grauDeRisco == '3'"
                                >
                                {{ atividadeExercida.grauDeRisco == '0' ? 'SEM RISCO' :
                                   atividadeExercida.grauDeRisco == '1' ? 'ALTO RISCO' :
                                   atividadeExercida.grauDeRisco == '2' ? 'MÉDIO RISCO' :
                                   atividadeExercida.grauDeRisco == '3' ? 'BAIXO RISCO' : 'SEM RISCO' }}
                            </button>
                        </p>
                    </div>
                    <div class="row col-2 justify-content-left align-items-center">
                        <div class="row col-2 justify-content-left align-items-center">
                            <p class="card-text">
                                <button type="button" 
                                    class="btn btn-sm btn-informacao-atividade"
                                    [class.btn-secondary]="atividadeExercida.principal"
                                    [class.btn-primary]="!atividadeExercida.principal">
                                    {{ atividadeExercida.principal ? 'PRINCIPAL' : 'SECUNDÁRIA' }}
                                </button>
                            </p>
                        </div>
                    </div>
                    <div class="row col-8 justify-content-start align-items-center" >
                        <p class="card-text" [innerHtml]="'<b>' + (atividadeExercida.codigo | mascara: 10) + '</b> - ' + atividadeExercida.nome"></p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!protocolo.dadosDeInconsistencia('empresa.viabilidade.atividadesExercidas').length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                    Não existem atividades não exercidas.
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="card">
        <div class="card-header">
            <div class="col-12">
                <strong>Atividades não exercidas</strong>
            </div>
        </div>
        <div *ngIf="atendimentoConfiguracao == null" class="card-body">
            <div class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text text-center">
                        Carregando...
                    </p>
                </div>
            </div>      
        </div>
        <div *ngIf="atendimentoConfiguracao != null">
            <div *ngIf="atendimentoConfiguracao.viabilidade.atividadesNaoExercidas.length" class="card-body p-0">
                <div *ngFor="let atividadeExercida of atendimentoConfiguracao.viabilidade.atividadesNaoExercidas; let i = index" class="pb-2">
                    <div class="row col-12 pt-1 pb-1 pl-0 pr-0">
                        <div class="row col-2 justify-content-left align-items-center">
                            <p class="card-text">
                                <button type="button" 
                                    class="btn btn-sm btn-informacao-atividade"
                                    [class.btn-secondary]="atividadeExercida.grauDeRisco == '0'"
                                    [class.btn-danger]="atividadeExercida.grauDeRisco == '1'"
                                    [class.btn-warning]="atividadeExercida.grauDeRisco == '2'"
                                    [class.btn-success]="atividadeExercida.grauDeRisco == '3'"
                                    >
                                    {{ atividadeExercida.grauDeRisco == '0' ? 'SEM RISCO' :
                                       atividadeExercida.grauDeRisco == '1' ? 'ALTO RISCO' :
                                       atividadeExercida.grauDeRisco == '2' ? 'MÉDIO RISCO' :
                                       atividadeExercida.grauDeRisco == '3' ? 'BAIXO RISCO' : 'SEM RISCO' }}
                                </button>
                            </p>
                        </div>
                        <div class="row col-2 justify-content-left align-items-center">
                            <div class="row col-2 justify-content-left align-items-center">
                                <p class="card-text">
                                    <button type="button" 
                                        class="btn btn-sm btn-informacao-atividade"
                                        [class.btn-secondary]="atividadeExercida.principal"
                                        [class.btn-primary]="!atividadeExercida.principal">
                                        {{ atividadeExercida.principal ? 'PRINCIPAL' : 'SECUNDÁRIA' }}
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div class="row col-8 justify-content-start align-items-center" >
                            <p class="card-text" [innerHtml]="'<b>' + (atividadeExercida.codigo | mascara: 10) + '</b> - ' + atividadeExercida.nome"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!atendimentoConfiguracao.viabilidade.atividadesNaoExercidas.length" class="card-body p-0">
                <div class="row col-12 justify-content-center align-items-center">
                    <p class="card-text text-center">
                        <br>
                        Não existem atividades não exercidas.
                        <br>&nbsp;
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="campo-inconsistencia mb-5" 
    *ngIf="protocolo.existeInconsistenciaPorCampo('empresa.viabilidade.atividadesNaoExercidas')">
    <div>
        <div>
            <div class="col-12 texto-warning pt-2 pb-2">
                <strong>Inconsistências</strong>
            </div>
        </div>
        <div *ngIf="protocolo.dadosDeInconsistencia('empresa.viabilidade.atividadesNaoExercidas').length" class="card-body p-0">
            <div *ngFor="let atividadeExercida of protocolo.dadosDeInconsistencia('empresa.viabilidade.atividadesNaoExercidas'); let i = index" class="pb-2">
                <div class="row col-12 pt-1 pb-1 pl-0 pr-0">
                    <div class="row col-2 justify-content-left align-items-center">
                        <p class="card-text">
                            <button type="button" 
                                class="btn btn-sm btn-informacao-atividade"
                                [class.btn-secondary]="atividadeExercida.grauDeRisco == '0'"
                                [class.btn-danger]="atividadeExercida.grauDeRisco == '1'"
                                [class.btn-warning]="atividadeExercida.grauDeRisco == '2'"
                                [class.btn-success]="atividadeExercida.grauDeRisco == '3'"
                                >
                                {{ atividadeExercida.grauDeRisco == '0' ? 'SEM RISCO' :
                                   atividadeExercida.grauDeRisco == '1' ? 'ALTO RISCO' :
                                   atividadeExercida.grauDeRisco == '2' ? 'MÉDIO RISCO' :
                                   atividadeExercida.grauDeRisco == '3' ? 'BAIXO RISCO' : 'SEM RISCO' }}
                            </button>
                        </p>
                    </div>
                    <div class="row col-2 justify-content-left align-items-center">
                        <div class="row col-2 justify-content-left align-items-center">
                            <p class="card-text">
                                <button type="button" 
                                    class="btn btn-sm btn-informacao-atividade"
                                    [class.btn-secondary]="atividadeExercida.principal"
                                    [class.btn-primary]="!atividadeExercida.principal">
                                    {{ atividadeExercida.principal ? 'PRINCIPAL' : 'SECUNDÁRIA' }}
                                </button>
                            </p>
                        </div>
                    </div>
                    <div class="row col-8 justify-content-start align-items-center" >
                        <p class="card-text" [innerHtml]="'<b>' + (atividadeExercida.codigo | mascara: 10) + '</b> - ' + atividadeExercida.nome"></p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!protocolo.dadosDeInconsistencia('empresa.viabilidade.atividadesNaoExercidas').length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                    Não existem atividades não exercidas.
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>

<div [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BA' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'"
    class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Tipo de Unidade</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="protocolo != null"  class="card-body">
        <div *ngIf="protocolo.viabilidade.tipoUnidade == '1'" 
            class="form-row row-linha">
            <div class="col-12">
                <p class="card-text titulo">
                    <strong>Unidade Produtiva</strong>
                </p>
                <p class="card-text observacao">
                    Unidade operacional, quando exercer atividades de produção ou venda de bens e/ou serviços destinados a terceiros.
                </p>
            </div>
        </div>
        <div *ngIf="protocolo.viabilidade.tipoUnidade == '2'">
            <div class="form-row row-linha linha-grid-divisao">
                <div class="col-12">
                    <p class="card-text titulo">
                        <strong>Unidade Auxiliar</strong>
                    </p>
                    <p class="card-text observacao">
                        Quando servir apenas à própria empresa, exercendo exclusivamente funções de apoio administrativo ou técnico, voltadas à criação das condições necessárias para o exercício das atividades operacionais dos demais estabelecimentos, não desenvolvendo atividade econômica de produção ou de venda de bens e/ou serviços.
                    </p>
                </div>
            </div>
            <div *ngFor="let tipoUnidadeAuxiliar of tipoUnidadeAuxiliares | filtro: {campo: 'codigo', valor: protocolo.viabilidade.tipoUnidadeAuxiliar}"
                class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text titulo">
                        <strong>Tipo de Unidade Auxiliar</strong> - {{ tipoUnidadeAuxiliar.nome }}
                    </p>
                    <p class="card-text observacao">
                        {{ tipoUnidadeAuxiliar.observacao }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="protocolo.existeInconsistenciaPorCampo('empresa.viabilidade.tipoUnidade')" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BA' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'"
    class="card campo-inconsistencia mb-5" 
    >
    <div>
        <div class="col-12 texto-warning pt-2 pb-2">
            <strong>Inconsistências</strong>
        </div>
    </div>
    <div class="card-body">
        <div *ngIf="protocolo.justificativaDeInconsistencia('empresa.viabilidade.tipoUnidade') == '1'" 
            class="form-row row-linha">
            <div class="col-12">
                <p class="card-text titulo">
                    <strong>Unidade Produtiva</strong>
                </p>
                <p class="card-text observacao">
                    Unidade operacional, quando exercer atividades de produção ou venda de bens e/ou serviços destinados a terceiros.
                </p>
            </div>
        </div>
        <div *ngIf="protocolo.justificativaDeInconsistencia('empresa.viabilidade.tipoUnidade') == '2'">
            <div class="form-row row-linha linha-grid-divisao">
                <div class="col-12">
                    <p class="card-text titulo">
                        <strong>Unidade Auxiliar</strong>
                    </p>
                    <p class="card-text observacao">
                        Quando servir apenas à própria empresa, exercendo exclusivamente funções de apoio administrativo ou técnico, voltadas à criação das condições necessárias para o exercício das atividades operacionais dos demais estabelecimentos, não desenvolvendo atividade econômica de produção ou de venda de bens e/ou serviços.
                    </p>
                </div>
            </div>
            <div *ngFor="let tipoUnidadeAuxiliar of tipoUnidadeAuxiliares | filtro: {campo: 'codigo', valor: protocolo.justificativaDeInconsistencia('empresa.viabilidade.tipoUnidadeAuxiliar')}"
                class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text titulo">
                        <strong>Tipo de Unidade Auxiliar</strong> - {{ tipoUnidadeAuxiliar.nome }}
                    </p>
                    <p class="card-text observacao">
                        {{ tipoUnidadeAuxiliar.observacao }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BA' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'"
    class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Formas de Atuação</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="protocolo != null"  class="card-body">
        <div *ngIf="protocolo.viabilidade.tipoUnidade == '1'" >
            <div *ngFor="let formaAtuacao of protocolo.viabilidade.formasAtuacao;"
                class="form-row row-linha linha-grid-divisao">
                <div class="col-12">
                    <p class="card-text titulo">
                        <strong>{{ formaAtuacao.nome }}</strong>
                    </p>
                    <p class="card-text observacao">
                        {{ formaAtuacao.observacao }}
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="protocolo.viabilidade.tipoUnidade == '2'"
            class="card-body">
            <div class="form-row row-linha linha-grid-divisao">
                <div class="col-12">
                    <p class="card-text observacao">
                        Unidade Auxiliar não possui formas de atuação.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="protocolo.existeInconsistenciaPorCampo('empresa.viabilidade.formasAtuacao')" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BA' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'"
    class="card campo-inconsistencia mb-5" 
    >
    <div>
        <div class="col-12 texto-warning pt-2 pb-2">
            <strong>Inconsistências</strong>
        </div>
    </div>
    <div class="card-body">
        <div *ngIf="protocolo.viabilidade.tipoUnidade == '1'" >
            <div *ngFor="let formaAtuacao of protocolo.dadosDeInconsistencia('empresa.viabilidade.formasAtuacao');"
                class="form-row row-linha linha-grid-divisao">
                <div class="col-12">
                    <p class="card-text titulo">
                        <strong>{{ formaAtuacao.nome }}</strong>
                    </p>
                    <p class="card-text observacao">
                        {{ formaAtuacao.observacao }}
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="protocolo.justificativaDeInconsistencia('empresa.viabilidade.tipoUnidade') == '2'"
            class="card-body">
            <div class="form-row row-linha linha-grid-divisao">
                <div class="col-12">
                    <p class="card-text observacao">
                        Unidade Auxiliar não possui formas de atuação.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BA' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'"
    class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Descrição de suas atividades e como será o funcionamento do seu negócio</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="protocolo != null"  class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text">
                    {{ protocolo.viabilidade.descricaoAtividadeEconomica }}
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="protocolo.existeInconsistenciaPorCampo('empresa.viabilidade.descricaoAtividadeEconomica')" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BA' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'"
    class="card campo-inconsistencia mb-5" 
    >
    <div>
        <div class="col-12 texto-warning pt-2 pb-2">
            <strong>Inconsistências</strong>
        </div>
    </div>
    <div class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text">
                    {{ protocolo.justificativaDeInconsistencia('empresa.viabilidade.descricaoAtividadeEconomica') }}
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="tipoEmpresa == 'E' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'EEV' || tipoEmpresa == 'AEV'"
    class="card">
    <div class="card-header">
        <div class="col-12">
            <strong *ngIf="tipoEmpresa != 'EEV' && tipoEmpresa != 'AEV'">Qual o objeto social do seu negócio</strong>
            <strong *ngIf="tipoEmpresa == 'EEV' || tipoEmpresa == 'AEV'">Observação do Evento</strong>
        </div>
    </div>
    <div *ngIf="protocolo != null" class="card-body">
        <div class="form-row row-linha"
            [class.valor-nao-cadastrado]="protocolo.fic.objetoSocial == ''"
            [class.campo-criticado]="protocolo.existeCriticaPorCampo('protocolo.fic.objetoSocial')">
            <div class="row col-1 justify-content-center align-items-center" *ngIf="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.objetoSocial'}).length && permitirAtendimentoObjetoSocial">
                <p class="card-text">
                    <button 
                        type="button" 
                        matTooltip="Criticar"
                        matTooltipPosition="above"
                        (click)="protocolo.criticar('protocolo.fic.objetoSocial', protocolo.fic.objetoSocial, 'Objeto Social')"
                        class="btn btn-sm">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'alert-octagon' }}"
                            ></use>
                        </svg>
                    </button>
                </p>
            </div>
            <div [class.col-12]="!(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.objetoSocial'}).length || !permitirAtendimentoObjetoSocial"
                [class.col-11]="(protocolo.criticas | filtro: {campo: 'nmColuna', valor: 'protocolo.fic.objetoSocial'}).length && permitirAtendimentoObjetoSocial">
                <p class="card-text" *ngIf="tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">
                    {{ protocolo.fic.objetoSocial }}
                </p>
                <p class="card-text" *ngIf="tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV'">
                    {{ protocolo.viabilidade.descricaoAtividadeEconomica }}
                </p>
            </div> 
        </div>
    </div>
</div>

<div *ngIf="(tipoEmpresa == 'E' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'EEV' || tipoEmpresa == 'AEV') &&
            (protocolo.existeInconsistenciaPorCampo('empresa.fic.objetoSocial') || protocolo.existeInconsistenciaPorCampo('empresa.viabilidade.descricaoAtividadeEconomica'))"
    class="card campo-inconsistencia mb-5" >
    <div>
        <div class="col-12 texto-warning pt-2 pb-2">
            <strong>Inconsistências</strong>
        </div>
    </div>
    <div class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text" *ngIf="tipoEmpresa != 'AEV' && tipoEmpresa != 'EEV'">
                    {{ protocolo.justificativaDeInconsistencia('empresa.fic.objetoSocial') }}
                </p>
                <p class="card-text" *ngIf="tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV'">
                    {{ protocolo.justificativaDeInconsistencia('empresa.viabilidade.descricaoAtividadeEconomica') }}
                </p>
            </div> 
        </div>
    </div>
</div>