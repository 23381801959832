import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AutenticacaoRoutingModule } from './autenticacao-routing.module';
import { LoginComponent } from './login/login.component';
import { AlterarSenhaComponent } from './login/alterar-senha/alterar-senha.component'
import { CadastrarUsuarioComponent } from "./login/cadastrar-usuario/cadastrar-usuario.component";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDividerModule } from '@angular/material/divider';
import { NgxMaskModule } from 'ngx-mask'
import { OutrosModule } from "../outros.module";
import { PerfilComponent } from './login/perfil/perfil.component';
import { ResumoCadastroComponent } from './login/resumo-cadastro/resumo-cadastro.component';
import { PipeModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [
    LoginComponent,
    CadastrarUsuarioComponent,
    AlterarSenhaComponent,
    PerfilComponent,
    ResumoCadastroComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatDividerModule,
    AutenticacaoRoutingModule,
    NgxMaskModule.forRoot(),
    OutrosModule,
    PipeModule,
  ],
  exports: [
    LoginComponent,
    CadastrarUsuarioComponent,
  ]
})
export class AutenticacaoModule {}
