import { Injectable } from "@angular/core";
import { environment } from "../../../../src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class ObservacaoService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarPorProtocoloParaRequisito(protocolo, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/observacao/buscarPorProtocoloParaRequisito/${ localStorage.getItem("pId") }/${ protocolo }/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPorInscricaoParaRequisito(inscricao, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/observacao/buscarPorInscricaoParaRequisito/${ localStorage.getItem("pId") }/${ inscricao }/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    
       
}