import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeModule } from '../../../pipes/pipes.module';
import { AlertModule } from '../../../components/alert/alert.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormAtividadePesquisaComponent } from './form-atividade-pesquisa.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    FormAtividadePesquisaComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    MatMenuModule,
    MatTableExporterModule,
    MatIconModule,
    MatTooltipModule,
    PipeModule,
    AlertModule,
  ],
  exports: [
    FormAtividadePesquisaComponent
  ],
})
export class FormAtividadePesquisaModule {}
