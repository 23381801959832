<app-alert type="danger" *ngIf="protocolo != null && (protocolo.criticas | filtro: {campo: 'justificativa', valor: '', tipoValor: '!='}).length">
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<div class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Críticas</strong>
        </div>
    </div>
    <div *ngIf="protocolo == null" class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="protocolo != null" class="card-body">
        <div *ngFor="let critica of protocolo.criticas; let i = index" >
            <div *ngIf="critica.justificativa != ''" 
                class="row row-linha linha-grid-divisao justify-content-start align-items-center">
                <div class="col-10">
                    <p>
                        <strong>{{ critica.nomeCampo }}</strong> - {{ critica.justificativa }}
                    </p>
                </div>
                <div class="col-2 text-right">
                    <button type="button" 
                            matTooltip="Editar"
                            matTooltipPosition="above"
                            class="btn btn-sm"
                            (click)="protocolo.acaoEditarCritica(i)">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                            ></use>
                        </svg>
                    </button>
                    <button type="button" 
                        matTooltip="Excluir"
                        matTooltipPosition="above"
                        class="btn btn-sm"
                        (click)="protocolo.acaoExcluirCritica(i)">
                        <svg class="icone-excluir">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                            ></use>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 pt-4" *ngIf="!protocolo.existeCritica()">
            <p class="card-text text-center">
                Não existem críticas adicionadas ao protocolo.
            </p>
        </div>
    </div>
</div>