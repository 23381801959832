import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { Protocolo } from "src/app/model/protocolo";
import { AtendimentoService } from "src/app/services/operacao/atendimento.service";
import Swal from "sweetalert2";
import { Constantes } from '../../../../model/constantes';
import { Session } from "inspector";
import { Cliente } from '../../../../model/cliente';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';

@Component({
  selector: 'app-atendimento-avaliador',
  templateUrl: './atendimento-avaliador.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoAvaliadorComponent implements OnInit {

  @Input() titulo: string = "";
  numeroProtocolo: string;
  numeroInscricao: string;
  protocolo: Protocolo;
  protocoloAtendido: boolean;
  codigoOrgao: string;
  codigoMenuPai: number;
  codigoMenu: number;
  menu: string;
  permitirAtendimento: boolean;
  subModulo: string;

  constructor(
    public location: Location,
    private atendimentoService: AtendimentoService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router) {
    this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];
    this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];
    this.protocoloAtendido = this.activatedRoute.snapshot.params["subMenu"] == "concluido";
    this.codigoOrgao = this.activatedRoute.snapshot.params["codigoOrgao"];
    this.codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];    
    this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];
    this.menu = this.activatedRoute.snapshot.data["menu"];

    this.subModulo = this.activatedRoute.snapshot.params["subMenu"];
    
    let meusProcessos = this.activatedRoute.snapshot.data["menu"] == Constantes.MENU_ATENDIMENTO.Processos;
    let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
    this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (!meusNegocios && !meusProcessos));

   }

  ngOnInit(): void {
  }

  vincularAtendimento() {
    if(this.activatedRoute.snapshot.params["codigoOrgao"] != '200'){
      this.vincularAtendimentoPorInscricao()
    }
    else {
     
      this.vincularAtendimentoPorProtocolo()
    }
    
  }

  vincularAtendimentoPorProtocolo() {
    Swal.fire({
      title: "",
      html: 'Deseja vincular o atendimendo do protocolo ' + this.numeroProtocolo + " ao seu usuário?",
      icon: 'question',
      showCancelButton: true})
      .then((result) => {
        if (result.value) {
          this.atendimentoService
            .vincularAtendimentoPorProtocolo(
              localStorage.getItem('pId'), 
              this.activatedRoute.snapshot.params["codigoOrgao"],
              this.authenticationService.currentUserValue.codLoginOrgao,
              this.numeroProtocolo
            )
            .pipe(take(1))
            .subscribe( retorno => {
              if (retorno.length) {
                this.protocolo.viabilidade.atendimentos = retorno;
                Swal.fire("", "Atendimento do protocolo " + this.numeroProtocolo + " vinculado ao seu usuário.", "info");
              }
            });
          }
    });
  }

  vincularAtendimentoPorInscricao() {
    Swal.fire({
      title: "",
      html: 'Deseja vincular o atendimendo do protocolo ' + this.numeroInscricao + " ao seu usuário?",
      icon: 'question',
      showCancelButton: true})
      .then((result) => {
        if (result.value) {
          this.atendimentoService
            .vincularAtendimentoPorInscricao(
              localStorage.getItem('pId'), 
              this.activatedRoute.snapshot.params["codigoOrgao"],
              this.authenticationService.currentUserValue.codLoginOrgao,
              this.numeroInscricao
            )
            .pipe(take(1))
            .subscribe( retorno => {
              if (retorno.length) {
                this.protocolo.viabilidade.atendimentos = retorno;
                Swal.fire("", "Atendimento do protocolo " + this.numeroInscricao + " vinculado ao seu usuário.", "info");
              }
            });
          }
    });
  }

  acaoBotaoVoltar() {
    if(this.codigoOrgao == '200'){
      if(this.codigoMenuPai == 34) {
        if (this.activatedRoute.snapshot.data["menu"] == "processos"){
          this.router.navigate(["../../../"], {relativeTo: this.activatedRoute});
        }
        else{
          this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
        }
        
      }
      else if(this.codigoMenu == 81){
        if (this.activatedRoute.snapshot.params["inscricao"] != null && this.activatedRoute.snapshot.params["protocolo"] != null) {
          this.router.navigate(["../../../"], {relativeTo: this.activatedRoute});
        }else{
          this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
        }
        
      }
      else if(this.codigoMenu == 179 || this.codigoMenu == 180 || this.codigoMenu == 205){
        this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
      }
      else{
        this.router.navigate(["../"], {relativeTo: this.activatedRoute});
      }
    }
    else{
      this.router.navigate(["../../"], {relativeTo: this.activatedRoute});
    }

  }

}
