import { FormGroup } from '@angular/forms';
import { Login } from "./login";
import { Processo } from "./processo";
import { Registro } from "./registro";
import { Viabilidade } from "./viabilidade";
import { Fic } from "./fic";
import { Recadastro } from "./recadastro";
import { Licenciamento } from "./licenciamento";
import { Critica } from './critica';
import Swal from "sweetalert2";
import { Documento } from './documento';
import { Encerramento } from "./encerramento";
import { RedeSim } from './redeSim';
import { ProtocoloLinhaDoTempo } from './protocoloLinhaDoTempo';
import { Anexo } from './anexo';
import { Suspensao } from './suspensao';

export class Protocolo {
    cliente: string;
    inscricao: string;
    data: string;
    protocolo: string;
    protocoloTemporario: string;
    processo: Processo;
    requerente: Login;
    solicitante: Login;
    registro: Registro;
    viabilidade: Viabilidade;
    fic: Fic;
    recadastro: Recadastro;
    criticas: Array<Critica>;
    inconsistencias: Array<Critica>;
    documentos: Array<Documento>;
    licenciamentos: Array<Licenciamento>;
    encerramento: Encerramento;
    redeSim: RedeSim;
    linhaDoTempo: ProtocoloLinhaDoTempo;
    anexos: Array<Anexo>;
    suspensao: Suspensao;

    constructor() {
        this.cliente = "";
        this.inscricao = "";
        this.data = "";
        this.protocolo = "";
        this.protocoloTemporario = "";
        this.processo = new Processo();
        this.requerente = new Login();
        this.solicitante = new Login();
        this.registro = new Registro();
        this.viabilidade = new Viabilidade();
        this.fic = new Fic();
        this.criticas = new Array<Critica>();
        this.inconsistencias = new Array<Critica>();
        this.documentos = new Array<Documento>();
        this.licenciamentos = new Array<Licenciamento>();
        this.encerramento = new Encerramento();
        this.redeSim = new RedeSim();
        this.linhaDoTempo = new ProtocoloLinhaDoTempo();
        this.anexos = new Array<Anexo>();
        this.suspensao = new Suspensao();
    }

    existeCritica() {
        return (this.criticas.filter(item => item.justificativa == "")).length != this.criticas.length;
    }

    existeCriticaPorCampo(campo) {
        return this.criticas.findIndex(item => { return item.nmColuna == campo && item.justificativa != "" }) != -1;
    }

    existeInconsistenciaPorCampo(campo) {
        return this.inconsistencias.findIndex(item => { return item.nmColuna == campo }) != -1;
    }

    acaoExcluirCritica(index) {
        
        let html = "";

        html += `
            <div class="text-left">
                Remover crítica referente ao campo <strong>${ this.criticas[index].nomeCampo }</strong>.<br><br>
            </div>
        `;

        Swal.fire({
            html: html,
            icon: 'question',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
        })
        .then((result) => {
            if (!result.dismiss) {
                this.criticas[index].justificativa = "";
            }          
        })
    }

    acaoEditarCritica(index) {
        let html = "";

        html += `
            <div class="text-left">
                Alterar descrição da crítica referente ao campo <strong>${ this.criticas[index].nomeCampo }</strong>.<br><br>
                Uma breve descrição da irregularidade.
                <textarea class="swal2-textarea w-100 p-0 m-0" id="justificativa" name="justificativa"></textarea>
            </div>
        `;

        Swal.fire({
            html: html,
            icon: 'question',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: () => {
                if ($("textarea[id=justificativa]").val() == "") {
                    Swal.showValidationMessage("Favor detalhar a sua solicitação para um melhor entendimento!");
                    return false;
                }
                
                return $("textarea[id=justificativa]").val();
            }
        })
        .then((result) => {
            if (!result.dismiss) {
                this.criticas[index].justificativa = result.value.toString();
            }          
        })
    }

    criticar(campo, valor: string, titulo: string) {
        let justificativaAtual = this.criticas.filter(item => { return item.nmColuna == campo })[0].justificativa
        let html = "";

        html += `
            <div class="row col-12 justify-content-center align-items-center">
                <svg class="icone-criticar-alert">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="assets/sprite.svg#alert-octagon"
                    ></use>
                </svg>
            </div>
            <br>
            <div class="row col-12 justify-content-center align-items-center">
                <h4>
                    <b>Informativo de Irregularidade</b>
                </h4>
            </div>
            <br>
            <div class="row col-12 row-linha justify-content-center align-items-center">
                <p class="w-100 text-left">
                    <b>${ titulo.toString().toUpperCase() }</b>: ${ valor.toString().toUpperCase() }
                </p>
                <p class="w-100 text-left">
                    Caro auditor, com essa ação você está emitindo uma crítica para a informação apresentada pelo contribuinte nesse campo.<br>
                    Caso deseje notificar os responsáveis pelo protocolo, no final da sua análise, pendenciar o protocolo ao contribuinte.
                </p>
                <p class="w-100 text-left">
                    <textarea class="swal2-textarea w-100 p-0 m-0" id="justificativa" name="justificativa">${ justificativaAtual }</textarea>
                </p>
                <p class="w-100 text-left">
                    <b>*Para remover essa crítica, descer a página até o quadro de "Finalização".</b>
                </p>
            </div>
        `;

        Swal.fire({
            html: html,
            inputAttributes: {
              autocapitalize: 'off'
            },
            width: 700,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: () => {
                if ($("textarea[id=justificativa]").val() == "") {
                    Swal.showValidationMessage("Favor detalhar a sua solicitação para um melhor entendimento!");
                    return false;
                }
                else {
                    let solicitacao = new Array<any>();

                    solicitacao.push({
                        campo: campo,
                        justificativa: $("textarea[id=justificativa]").val()
                    });
                
                    return solicitacao;
                }
            }
        })
        .then((result) => {
            if (!result.dismiss) {
                let index = this.criticas.findIndex(item => item.nmColuna == campo);
                this.criticas[index].valorAntigo = valor.toString();
                this.criticas[index].justificativa = result.value[0].justificativa
            }          
        })
    }

    exibirInconsistencia(campo, valor: string, titulo: string) {
        let justificativaAtual = this.inconsistencias.filter(item => { return item.nmColuna == campo })[0].justificativa
        let html = "";

        html += `
            <div class="row col-12 justify-content-center align-items-center">
                <svg class="icone-inconsistencia-alert">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="assets/sprite.svg#alert-triangle"
                    ></use>
                </svg>
            </div>
            <br>
            <div class="row col-12 justify-content-center align-items-center">
                <h4>
                    <b>Informativo de Inconsistência</b>
                </h4>
            </div>
            <br>
            <div class="row col-12 justify-content-center align-items-center">
                <h5>
                    <b>${ titulo.toString().toUpperCase() }</b>
                </h5>
            </div>
            <br>
            <div class="row col-12 row-linha justify-content-center align-items-center">
                <p class="w-100 text-left">
                    <b>ATUAL</b>: ${ justificativaAtual.toString().toUpperCase() }
                </p>
            </div>
            <br>
            <div class="row col-12 row-linha justify-content-center align-items-center">
                <p class="w-100 text-left">
                    <b>NOVO</b>: ${ valor.toString().toUpperCase() }
                </p>
            </div>
        `;

        Swal.fire({
            html: html,
            inputAttributes: {
              autocapitalize: 'off'
            },
            width: 700,
            showCancelButton: false,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        })
        .then((result) => {         
        })
    }

    justificativaDeInconsistencia(campo) {
        var inconsistencia = this.inconsistencias.filter(item => { return item.nmColuna == campo });

        return inconsistencia.length ? this.inconsistencias.filter(item => { return item.nmColuna == campo })[0].justificativa : "";        
    }

    dadosDeInconsistencia(campo) {
        var inconsistencia = this.inconsistencias.filter(item => { return item.nmColuna == campo });

        return inconsistencia.length ? this.inconsistencias.filter(item => { return item.nmColuna == campo })[0].dadosGenerico : [];        
    }

    static exibirCriticasFormulario(formulario: FormGroup, criticas, passo: string = "") {
        let mensagemCritica = "";
        Object.keys(formulario.controls).forEach(campo => {
            if (passo != "") {
                if (!criticas.filter(item => (item.campo == campo || item.nmColuna == campo.replace(/[_]/g,".")) && item.passo == passo).length) {
                    formulario.get(campo).disable();
                }
                else {
                    let critica = criticas.filter(item => (item.campo == campo || item.nmColuna == campo.replace(/[_]/g,".")) && item.passo == passo);
                    formulario.get(campo).enable();
                    mensagemCritica += critica[0].nomeCampo + " - " + critica[0].justificativa + "<br>";
                }
            }
            else {
                if (!criticas.filter(item => (item.campo == campo || item.nmColuna == campo.replace(/[_]/g,"."))).length) {
                    formulario.get(campo).disable();
                }
                else {
                    let critica = criticas.filter(item => (item.campo == campo || item.nmColuna == campo.replace(/[_]/g,".")));
                    formulario.get(campo).enable();
                    mensagemCritica += critica[0].nomeCampo + " - " + critica[0].justificativa + "<br>";
                }
            }
            
        });
        return mensagemCritica;
    }

    static exibirCriticasFormularioSemBloqueio(formulario: FormGroup, criticas, passo: string = "") {
        let mensagemCritica = "";
        Object.keys(formulario.controls).forEach(campo => {
            if (passo != "") {
                if (criticas.filter(item => (item.campo == campo || item.nmColuna == campo.replace(/[_]/g,".")) && item.passo == passo).length) {
                    let critica = criticas.filter(item => (item.campo == campo || item.nmColuna == campo.replace(/[_]/g,".")) && item.passo == passo);
                    mensagemCritica += critica[0].nomeCampo + " - " + critica[0].justificativa + "<br>";
                }
            }
            else {
                if (criticas.filter(item => (item.campo == campo || item.nmColuna == campo.replace(/[_]/g,"."))).length) {
                    let critica = criticas.filter(item => (item.campo == campo || item.nmColuna == campo.replace(/[_]/g,".")));
                    mensagemCritica += critica[0].nomeCampo + " - " + critica[0].justificativa + "<br>";
                }
            }
            
        });
        return mensagemCritica;
    }
}