import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, QueryList, ViewChildren, EmbeddedViewRef, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Empresa } from '../../../model/empresa';
import { Pendencia } from '../../../model/pendencia';
import { Licenciamento } from '../../../model/licenciamento';
import { formatToCPFOrCNPJ } from "brazilian-values";
import { FormQuestionarioComponent } from '../form-questionario/form-questionario.component';
import { LicenciamentoService } from '../../../services/licenciamento/licenciamento.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Observacao } from '../../../model/observacao';
import { AtendimentoObservacaoComponent } from '../atendimento/atendimento-observacao/atendimento-observacao.component';
import { Constantes } from '../../../model/constantes';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpresaService } from '../../../services/empresa/empresa.service';
import { ArquivoService } from '../../../services/arquivo/arquivo.service';
import { ViewportScroller } from '@angular/common';
import { InformacaoDocumentoComponent } from '../informacoes-cadastro/informacao-documento/informacao-documento.component';
import { Protocolo } from 'src/app/model/protocolo';


@Component({
  selector: 'app-licenciamento',
  templateUrl: './licenciamento.component.html',
  styleUrls: ['./licenciamento.component.scss']
})
export class LicenciamentoComponent implements OnInit {

  @Output() licenciamentoRespondido = new EventEmitter();

  @ViewChild(InformacaoDocumentoComponent)
  informacaoDocumento: InformacaoDocumentoComponent;

  @ViewChildren(FormQuestionarioComponent)
  formQuestionario: QueryList<FormQuestionarioComponent>;

  @ViewChild(AtendimentoObservacaoComponent)
  atendimentoObservacao: AtendimentoObservacaoComponent;

  tipoEmpresa: string;
  inscricao: string;
  orgao: string;
  requisito: string;

  tipoDePendenciaLicenciamento = Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO;
  tipoDePendenciaLicenciamentoDescricao = Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO_DESCRICAO;
  formulario: FormGroup;
  licenciamento: Licenciamento;
  licenciamentoAuxiliar: Licenciamento;
  empresa: Empresa;
  listaDeAtividades: Set<string>;

  constructor(
    private document: Document,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private router: Router,
    private empresaService: EmpresaService,
    private licenciamentoService: LicenciamentoService,
    private viewportScroller: ViewportScroller,
    private arquivoService: ArquivoService,
    private activatedRoute: ActivatedRoute) {

    this.licenciamento = new Licenciamento();
    this.licenciamentoAuxiliar = new Licenciamento();

    this.formulario = this.formBuilder.group({
      termoDeAceite: ["", Validators.required]
    });

    this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];
    this.inscricao = this.activatedRoute.snapshot.params["inscricao"];
    this.orgao = this.activatedRoute.snapshot.params["orgao"];
    this.requisito = this.activatedRoute.snapshot.params["requisito"];

    this.buscarEmpresa();
  }

  ngOnInit(): void {}

  buscarEmpresa() {
    this.empresaService
      .buscarPorInscricaoParaPendenciaDeLicenciamentoPorOrgaoERequisito(
          localStorage.getItem("pId"),  
          this.inscricao,
          this.orgao,
          this.requisito)
      .pipe(take(1))
      .subscribe( (retorno: Empresa) => {
        this.empresa = retorno;
        this.preencher();
      })
  }
  
  imprimirDocumentoQuestionarioBloqueado(licenciamento: Licenciamento) {
    let documento = licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == licenciamento.documentoTipo);
    if (licenciamento.situacaoLicenca) {
        documento = licenciamento.documentos.filter( item => item.ativo == "1" && item.status == "1" && item.tipo.toString() == Constantes.TIPO_DE_DOCUMENTO.Lic.toString());
    }
    
    if (documento.length) {
        this.baixarArquivo(documento[0].documentoEndereco.codigo, documento[0].tipo, documento[0].documentoEndereco.nome, documento[0].documentoEndereco.extensao)
    }
  }

  baixarArquivo(codigo, tipo, nome, extensao) {
    this.arquivoService.downloadArquivoLicenciamentoPorCodigo(codigo, tipo)
      .subscribe((res: any) => {
        this.arquivoService.handleFile(res, nome + "." + extensao);
      });
  }

  goToAnchor(ancora) {
    this.document.getElementById(ancora).scrollIntoView();
    this.document.querySelectorAll(".nav-link").forEach( item => this.renderer.removeClass(item, "active"));
    this.renderer.addClass(this.document.querySelector("." + ancora), "active");    

    this.viewportScroller.scrollToAnchor(ancora);
    let posicaoAtual = this.viewportScroller.getScrollPosition();
    this.viewportScroller.scrollToPosition([posicaoAtual[0], posicaoAtual[1] - 246])
  }

  preencher() {
    this.listaDeAtividades = new Set<string>();
    this.empresa.viabilidade.atividadesExercidas.forEach( atividade => this.listaDeAtividades.add(atividade.codigo));
    this.empresa.viabilidade.atividadesNaoExercidas.forEach( atividade => this.listaDeAtividades.add(atividade.codigo));
    this.empresa.registro.documentos[1].numero = formatToCPFOrCNPJ(this.empresa.registro.documentos[1].numero .toString());
    
    // Filtrando o licenciamento de acordo como órgão e requisito
    this.licenciamento = this.empresa.licenciamentos.filter( item => item.requisito.codigo == this.requisito)[0];
    
    // Gerando um objeto auxiliar para manipular as respostas
    Object.assign(this.licenciamentoAuxiliar, this.licenciamento);
    this.listaDeAtividades.forEach(atividade => this.licenciamentoAuxiliar.atividades = this.licenciamentoAuxiliar.atividades.concat(atividade + ",") );

    setTimeout(() => {
      let observacoes = new Array<Observacao>();
      
      this.licenciamento.licenciamentoFluxos.forEach( fluxo => {
        let observacao = new Observacao();
        observacao.data = fluxo.data;
        observacao.avaliacao = fluxo.status;
        observacao.processo = "De " + fluxo.orgaoOrigem.nome + " para " + fluxo.orgaoDestino.nome;
        observacao.login.nome = fluxo.login.nome;
        observacao.observacao = fluxo.observacao;
        observacoes.push(observacao);
      })

      this.atendimentoObservacao.preencher(observacoes);

      this.informacaoDocumento.protocolo = new Protocolo();
      this.informacaoDocumento.protocolo.documentos = this.licenciamento.documentos.filter( documento => { return documento.ativo.toString() == "1" });
    }, 1);
  }

  acaoBotaoEnviarParaAnalise(pendencia: Pendencia) {
    this.licenciamentoRespondido.emit({ pendencia: pendencia });
  }

  acaoBotaoVoltar(event) {
    this.router.navigate([
      "/operacao/acompanhamento/pendencia", 
      this.activatedRoute.snapshot.params["codigoMenuPai"], 
      this.activatedRoute.snapshot.params["codigoOrgao"], 
      this.activatedRoute.snapshot.params["codigoMenu"]
    ]);
  }

  enviarQuestionario() {
    if (this.montarEValidarLicenciamento()) {
      this.licenciamentoService
        .gerarLicenciamentoEletronico(this.licenciamentoAuxiliar)
        .pipe(take(1))
        .subscribe( retorno => {
          if (retorno) {
            Swal.fire({
              title: "Licenciamento Eletrônico",
              html: `Questionário de licenciamento eletrônico respondido com sucesso!`,
              icon: "success",
              willClose: () => {
                //this.licenciamentoRespondido.emit({ pendencia: retorno });
                this.router.navigate([
                  "/operacao/acompanhamento/pendencia", 
                  this.activatedRoute.snapshot.params["codigoMenuPai"], 
                  this.activatedRoute.snapshot.params["codigoOrgao"], 
                  this.activatedRoute.snapshot.params["codigoMenu"]
                ]);
              }
          })
          }
          else {
            Swal.fire("", "Não foi possível efetuar a solicitação, tente novamente mais tarde.", "error")
          }
        });
    }
    
  }

  montarEValidarLicenciamento() {
    if (this.licenciamento.statusDoLicenciamento == Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO.Pendente_Orgao) {
      Swal.fire("", "Questionário indisponível para responder, detalhamento apenas para acompanhamento.", "warning")
      return false;
    }

    let licenciamentoValido = true;

    if (!this.atendimentoObservacao.observacao.value) {
      Swal.fire("", "Favor informar a observação da análise.", "info")
      licenciamentoValido = false;
      return licenciamentoValido;
    }
    if (!this.termoDeAceite.value) {
      Swal.fire("", "Para que a solicitação seja finalizada ,você devera estar de acordo com o termo de responsabilidade.", "info")
      licenciamentoValido = false;
      return licenciamentoValido;
    }
    if (!this.formQuestionario.toArray().filter(formQuestionarioSelecionado => formQuestionarioSelecionado.formulario.invalid).length) {
      licenciamentoValido = false;
      return licenciamentoValido;
    }

    this.licenciamentoAuxiliar.mensagem = this.atendimentoObservacao.observacao.value;
    
    this.licenciamentoAuxiliar.perguntasLicenciamento.forEach( (perguntaLicenciamento) => {
      this.formQuestionario.toArray().forEach( formQuestionarioSelecionado => {
        licenciamentoValido = formQuestionarioSelecionado.validarFormulario();
        if (!licenciamentoValido) {
          return false;
        }

        formQuestionarioSelecionado.preencher();
        if (formQuestionarioSelecionado.nome == perguntaLicenciamento.perfil) {
          perguntaLicenciamento.perguntas = formQuestionarioSelecionado.perguntasRespostas.perguntas;
        }
      })
    });
    
    return licenciamentoValido;
  }
  
  get termoDeAceite() {
      return this.formulario.get("termoDeAceite");
  }
  set termoDeAceite(termoDeAceite) {
      this.formulario.get("termoDeAceite").setValue(termoDeAceite);
  }

}

