import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";

import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { Constantes } from "src/app/model/constantes";
import { FormRequerenteComponent } from "src/app/pages/outros/form-requerente/form-requerente.component";
import { FormLocalizacaoComponent } from "src/app/pages/outros/form-localizacao/form-localizacao.component";
import { FormAtividadeComponent } from "src/app/pages/outros/form-atividade/form-atividade.component";
import { FormQuestionarioComponent } from "src/app/pages/outros/form-questionario/form-questionario.component";
import { FormResumoComponent } from "src/app/pages/outros/form-resumo/form-resumo.component";
import { Configuracao } from "src/app/model/configuracao";
import { Servico } from "src/app/model/servico";
import { MatStepper } from "@angular/material/stepper";
import { Pergunta } from "src/app/model/pergunta";
import { Protocolo } from "src/app/model/protocolo";
import { Processo } from "src/app/model/processo";
import { Documento } from "src/app/model/documento";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import { ComprovanteConsultaPrevia } from "src/app/components/pdf/comprovanteConsultaPrevia";
import { PerguntaResposta } from "src/app/model/perguntaResposta";
import { Location } from "@angular/common";
import { FormIdentificacaoNegocioComponent } from "src/app/pages/outros/form-identificacao-negocio/form-identificacao-negocio.component";
import { FormEnderecoComponent } from "src/app/pages/outros/form-endereco/form-endereco.component";
import { FormObservacaoEventoComponent } from '../../../outros/form-observacao-evento/form-observacao-evento.component';
import { Anexo } from "src/app/model/anexo";


@Component({
    selector: "app-abertura-evento",
    templateUrl: "./abertura-evento.component.html"
})
export class AberturaEventoComponent implements OnInit {
    
    tipoEmpresa: string;
    informativo: string;
    stepper: MatStepper;
    passoAnterior: number;
    passoAtual: number;
    configuracao: Configuracao;
    processo: Processo;
    tipoProcesso = Constantes.PROCESSO;
    servicos: Array<Servico>;
    protocolo: Protocolo;
    protocoloAtual: Protocolo;

    emitirErroIdentificacao: boolean;
    mensagemErro: string;

    cnpj: string;

    // Formulários
    formAbertura: FormGroup;
    formularioRequerente: FormGroup;
    formularioEndereco: FormGroup;
    formularioIdentificacao: FormGroup;
    formularioEnderecoEvento: FormGroup;
    formularioAtividade: FormGroup;
    formularioQuestionarioViabilidade: FormGroup;
    formularioQuestionarioFic: FormGroup;
    formularioResumo: FormGroup;

    tipoPergunta = Constantes.TIPO_DE_PERGUNTA;

    anexos: Array<Anexo>;

    @ViewChild(FormRequerenteComponent)
    formRequerente: FormRequerenteComponent;

    @ViewChild("formEnderecoRequerente")
    formEndereco: FormEnderecoComponent;
    
    @ViewChild(FormIdentificacaoNegocioComponent)
    formIdentificacao: FormIdentificacaoNegocioComponent;

    @ViewChild("formEnderecoEvento")
    formEnderecoEvento: FormEnderecoComponent;

    @ViewChild(FormAtividadeComponent)
    formAtividade: FormAtividadeComponent;

    @ViewChild("formQuestionarioViabilidade")
    formQuestionarioViabilidade: FormQuestionarioComponent;

    @ViewChild("formQuestionarioFic")
    formQuestionarioFic: FormQuestionarioComponent;

    @ViewChild(FormObservacaoEventoComponent)
    formObservacaoEvento: FormObservacaoEventoComponent;

    @ViewChild(FormResumoComponent)
    formResumo: FormResumoComponent;

    // Botões
    btnAnteriorLocalizacao: string = "Anterior";
    btnAvancarLocalizacao: string = "Avançar";

    constructor(
        private location: Location,
        private router: Router,
        private protocoloService: ProtocoloService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute) {
              
        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];

        this.protocolo = new Protocolo();
        this.protocolo.cliente = localStorage.getItem("pId");
        this.protocolo.viabilidade.perguntasUsuario = new PerguntaResposta();

        this.formAbertura = this.formBuilder.group({
            controleValidade: ["", [Validators.required]]
        });

        this.informativo = Constantes.INFORMATIVO_ABERTURA_EVENTO[0];

        setTimeout(() => {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioEndereco = this.formEndereco.formulario;
            this.formularioIdentificacao = this.formIdentificacao.formulario;
            this.formularioEnderecoEvento = this.formEnderecoEvento.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
            this.formularioResumo = this.formResumo.formulario;

        }, 0);

        this.processo = new Processo();

        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        this.configuracao.processos
            .forEach(processo => {
                var tipoProcesso = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ? "O" : "P";
                var tipoPessoa = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ? Constantes.TIPO_PESSOA.Juridica : Constantes.TIPO_PESSOA.Fisica;

                if (processo.codigo === "A" && processo.tipo === tipoProcesso && processo.tipoPessoa == tipoPessoa) {
                    this.processo.cliente = processo.cliente;
                    this.processo.codigo = processo.codigo;
                    this.processo.descricao = processo.descricao;
                    this.processo.protocolo = processo.protocolo;
                    this.processo.tipo = processo.tipo;
                    this.protocolo.processo = this.processo;
                    this.servicos = processo.servicos;

                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
                        this.processo.tipo = Constantes.TIPO_EMPRESA.Mei;
                        processo.servicos.forEach(servico => {
                            if (servico.codigo == 1) {
                                this.servicos = [servico];
                            }
                        });
                    }
                } 
            }
        );
        
    }

    ngOnInit(): void {
        this.passoAnterior = 0;
        this.passoAtual = 0;
        this.protocolo.protocolo = this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "";

        if (this.protocolo.protocolo != "") {
            this.carregarDadosConsultaPrevia();
        }
    }

    montarObjetoProcesso() {
        setTimeout(() => {
            this.formRequerente.servicos = this.processo.servicos;
        }, 1);
    }

    carregarDadosConsultaPrevia() {
        this.protocoloService
            .detalharConsultaPrevia(this.protocolo.cliente, this.protocolo.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                this.protocoloAtual = retorno;
                setTimeout(() => {
                    this.carregarCriticas();
                }, 1);
            })
    }

    carregarCriticas() {
        this.preencherCamposRequerente();
        this.preencherCamposEndereco();
        this.preencherCamposAtividade();
        this.preencherCamposQuestionario();
    }

    preencherCamposRequerente() {
        this.formRequerente.preencherCamposCriticas(this.protocoloAtual);
    }

    preencherCamposEndereco() {
        //this.formEndereco.preencherCamposCriticas(this.protocoloAtual);
    }

    preencherCamposAtividade() {
        this.formAtividade.preencherCamposCriticas(this.protocoloAtual);
    }
    
    preencherCamposQuestionario() {
        this.formQuestionarioViabilidade.perguntasRespondidasOutrosProcessos = this.protocoloAtual.viabilidade.perguntasUsuario.perguntas;
        this.formQuestionarioViabilidade.bloqueadoPorCriticas = true;

        this.formQuestionarioFic.perguntasRespondidasOutrosProcessos = this.protocoloAtual.fic.perguntasUsuario.perguntas;
        this.formQuestionarioFic.bloqueadoPorCriticas = true;
    }

    verificaValidacaoFormulario(formGroup: FormGroup) {
        
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });
    }

    renomearBotaoLocalizacao() {
        this.btnAnteriorLocalizacao = "Anterior";
        this.btnAvancarLocalizacao = "Avançar";

       // if (this.formEndereco.exibeFormularioEndereco) {
           // if (!this.formEndereco.preenchimentoInicial) {
                //this.btnAnteriorLocalizacao = "Cancelar";
                this.btnAvancarLocalizacao = "Confirmar";
            //}
        //}
    }

    voltarPassoLocalizacao() {
       // if (this.formEndereco.exibeFormularioEndereco) {
            //if (this.formEndereco.preenchimentoInicial) {
                //this.stepper.previous();
           // }
           // else {
               // this.formEndereco.toggle();
           // }
        //}
        //else {
        //    //this.stepper.previous();
       // }
    }

    avancarPassoIdentificacao(passos) {
        

        if (this.stepper == null) {
            this.stepper = passos;
        } 

        this.formularioRequerente = this.formRequerente.formulario;
        this.formularioEndereco = this.formEndereco.formulario;
        this.verificaValidacaoFormulario(this.formularioRequerente);
        this.verificaValidacaoFormulario(this.formularioEndereco);

        //this.formEndereco.controleValidade.setValue("");


        if (this.formularioRequerente.valid && this.formularioEndereco.valid) {
            this.emitirErroIdentificacao = false;
   
            this.stepper.next();

            //this.carregarIdentificacao();
            //this.buscarEmpresa();
        }
        else {
            let mensagemErro = "";
            
            if (this.formularioRequerente.invalid) {
                mensagemErro += "Favor corrigir os campos nos dados do requerente e/ou local de registro da empresa.<br>"
            }
            
            if (this.formularioEndereco.invalid) {
                mensagemErro += "Favor corrigir os campos de localização da empresa.<br>"
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.emitirErroIdentificacao = true;
            this.mensagemErro = mensagemErro;

        }
        
    }

    avancarPassoIdentificacaoEvento(passos) {
        

        if (this.stepper == null) {
            this.stepper = passos;
        } 

        this.formularioIdentificacao = this.formIdentificacao.formulario;
        this.formularioEnderecoEvento = this.formEnderecoEvento.formulario;
        this.verificaValidacaoFormulario(this.formularioIdentificacao);
        this.verificaValidacaoFormulario(this.formularioEnderecoEvento);

        //this.formEndereco.controleValidade.setValue("");

        var formularioEnderecoValido = true;

        Object.keys(this.formularioEnderecoEvento.controls).forEach(controle => {
            if(this.formularioEnderecoEvento.controls[controle].disabled){
                this.formularioEnderecoEvento.controls[controle].enable();
                this.formularioEnderecoEvento.controls[controle].updateValueAndValidity();
                this.formularioEnderecoEvento.controls[controle].markAsTouched();
                if(this.formularioEnderecoEvento.controls[controle].invalid){
                    formularioEnderecoValido = false;
                }
               
                this.formularioEnderecoEvento.controls[controle].disable();
            }
            
        });

        


        if (this.formularioIdentificacao.valid && formularioEnderecoValido) {
            this.emitirErroIdentificacao = false;
   
            this.stepper.next();

            //this.carregarIdentificacao();
            //this.buscarEmpresa();
        }
        else {
            let mensagemErro = "";
            
            if (this.formularioIdentificacao.invalid) {
                mensagemErro += "Favor corrigir os dados do evento.<br>"
            }
            
            if (!formularioEnderecoValido) {
                mensagemErro += "Favor realizar a busca e preencher os dados da localização do evento."
            }

            Swal.fire("", mensagemErro, "error");

            this.emitirErroIdentificacao = true;
            this.mensagemErro = mensagemErro;

        }
        
    }

    
    avancarPassoAtividade() {
        if (this.formAtividade.validarFormulario())
            this.stepper.next();
    }

    avancarPassoQuestionario() {
        this.verificaValidacaoFormulario(this.formQuestionarioViabilidade.formulario);
        this.verificaValidacaoFormulario(this.formQuestionarioFic.formulario);
        if (this.formQuestionarioViabilidade.validarFormulario() && this.formQuestionarioFic.validarFormulario()) {
            this.stepper.next();
        }
    }

    mudancaPasso(event, passos) {
        
        if (this.stepper == null) {
            this.stepper = passos;
        } 

        if (this.passoAtual == 0) {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioEndereco = this.formEndereco.formulario;
            this.formularioIdentificacao = this.formIdentificacao.formulario;
            this.formularioEnderecoEvento = this.formEnderecoEvento.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
            this.formularioResumo = this.formResumo.formulario;

            this.montarObjetoProcesso();
        }

        if ( this.passoAtual == 2 ) {
            this.formAtividade.validarFormulario();
        }
 
        if (event.selectedIndex == 1) {
            //this.formEndereco.formulario.get("controleValidade").setValue("");
        }
        if (event.selectedIndex == 2) {
            this.formAtividade.formulario.get("controleValidade").setValue("");
        }
        if ( event.selectedIndex == 3) {
            // Questionário de Viabilidade
            this.formQuestionarioViabilidade.controleValidade.setValue("");
            this.formQuestionarioViabilidade.apenasCorrespondencia = false;
    
            let perguntaViabilidade = new Pergunta();
            perguntaViabilidade.cliente = localStorage.getItem("pId");
            perguntaViabilidade.tipo = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ? Constantes.TIPO_DE_PERGUNTA.Autonomo_Evento : Constantes.TIPO_DE_PERGUNTA.Viabilidade_Evento;
            perguntaViabilidade.atividades = this.formAtividade.atividadesSelecionadas;
            
            this.formQuestionarioViabilidade.carregarPerguntas(perguntaViabilidade);
            this.formularioQuestionarioViabilidade = this.formQuestionarioViabilidade.formulario;

            // Questionário de Fic
            this.formQuestionarioFic.controleValidade.setValue("");
            this.formQuestionarioFic.apenasCorrespondencia = false;
    
            let perguntaFic = new Pergunta();
            perguntaFic.cliente = localStorage.getItem("pId");
            perguntaFic.tipo = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ? Constantes.TIPO_DE_PERGUNTA.Fic_Evento : Constantes.TIPO_DE_PERGUNTA.Fic_Evento;
            perguntaFic.atividades = this.formAtividade.atividadesSelecionadas;
            
            this.formQuestionarioFic.carregarPerguntas(perguntaFic);
            this.formularioQuestionarioFic = this.formQuestionarioFic.formulario;
        }
        
        this.informativo = Constantes.INFORMATIVO_ABERTURA_EVENTO[event.selectedIndex];
        this.passoAtual = event.selectedIndex;
        this.passoAnterior = event.previouslySelectedIndex;

        this.montarAbertura(event);
    }

    montarAbertura(event) {
        if ( event.previouslySelectedIndex == 0) {

            let documento = new Documento();
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documento.nome = "CPF";
            documento.numero = localStorage.getItem("userId");

            this.protocolo.solicitante.documentos = [documento];
            
            this.formRequerente.preencher();
            this.formEndereco.preencherEndereco();
            this.protocolo.requerente = this.formRequerente.requerente;
            this.protocolo.registro = this.formRequerente.registro;
            this.processo.servicos = [this.formRequerente.servicoEscolhido];
   
            // Ajuste de fluxo - os fluxos são fixos no antigo Icad - ver forma de deixar dinâmico
            // Código de serviço tem que ser 4 para Mei
            if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
                this.processo.servicos[0].codigo = 4;
            }
        }
        else if (event.previouslySelectedIndex == 1) {
            this.formIdentificacao.preencher();
            this.formEnderecoEvento.preencherEndereco();
            this.protocolo.fic = this.formIdentificacao.protocolo.fic;
            this.protocolo.registro = this.formIdentificacao.protocolo.registro;


            if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento || this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento){

                //this.protocolo.fic.nomeEmpresa = this.protocolo.requerente.nome;
                
                this.protocolo.requerente.documentos.forEach( documento => {
                    if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj || documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cpf) {
                        this.cnpj = documento.numero;
                    }
                });

                this.protocolo.registro.documentos.forEach ( documento => {
                    if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj || documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cpf) {
                        documento.numero = this.cnpj;
                    }
                });

            }

        }
        else if (event.previouslySelectedIndex == 2) {
            this.formAtividade.preencher();

            this.protocolo.viabilidade.atividadesExercidas = this.formAtividade.atividadesExercidas;
            if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento){
                this.protocolo.viabilidade.atividadesNaoExercidas = this.formAtividade.atividadesNaoExercidas;
            }
        }
        else if (event.previouslySelectedIndex == 3) {
            this.formQuestionarioViabilidade.preencher();
            this.protocolo.viabilidade.perguntasUsuario = this.formQuestionarioViabilidade.perguntasRespostas;
            
            this.formQuestionarioFic.preencher();
            this.protocolo.fic.perguntasUsuario = this.formQuestionarioFic.perguntasRespostas;
            
            this.protocolo.fic.objetoSocial = this.formObservacaoEvento.objetoSocial.value;
            this.protocolo.viabilidade.descricaoAtividadeEconomica = this.formObservacaoEvento.objetoSocial.value;
        }
        
        this.protocolo.viabilidade.enderecos = [
            this.formEnderecoEvento.endereco,
            this.formEndereco.endereco,
        ];

        this.formResumo.protocolo = this.protocolo;
        this.formResumo.preencher();
    }

    gerarAbertura() {
        this.formResumo.preencherAnexos();
        
        if (!this.formResumo.termoDeAceite.value) {
            Swal.fire("", "Para que a solicitação seja finalizada ,você devera estar de acordo com o termo de responsabilidade.", "info")
            return false;
        }

        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento) {
            this.protocoloService
                .gerarFic(this.protocolo)
                .pipe(take(1))
                .subscribe( retorno => {
                    Swal.fire({
                        title: retorno.titulo,
                        html: retorno.mensagem,
                        icon: retorno.status,
                        willClose: () => {
                            this.router.navigate(["/operacao/acompanhamento"]);
                        }
                    })
                });
        }
        else {
            this.protocoloService
                .gerarViabilidade(this.protocolo)
                .pipe(take(1))
                .subscribe( retorno => {
                    Swal.fire({
                        title: retorno.titulo,
                        html: retorno.mensagem,
                        icon: retorno.status,
                        willClose: () => {
                            this.router.navigate(["/operacao/acompanhamento"]);
                        }
                    })
                });
        }
    }


}