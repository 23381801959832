import { Component, OnInit } from '@angular/core';
import { Protocolo } from '../../../../model/protocolo';

@Component({
    selector: 'app-atendimento-critica',
    templateUrl: 'atendimento-critica.component.html',
    styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoCriticaComponent implements OnInit {

    mensagemErro: string;
    protocolo: Protocolo;
    
    constructor() {}

    ngOnInit() {
        this.mensagemErro = "No quadro abaixo estão as críticas adicionadas, para efetivar favor pendênciar o protocolo para o contribuinte."
    }
}