<div class="card" *ngIf="codigoOrgao == '200' && !consultaProcesso">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Requisitos</strong>
        </div>
    </div>
    <div *ngIf="requisitos == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="requisitos != null" class="card-body p-0">
        <div class="row col-12 p-0 justify-content-center align-items-center header-atividades">
            <div class="row col-1 p-0 justify-content-center align-items-center">
                <button
                    type="button" id="btn-imprimir-requisitos"
                    matTooltip="Imprimir"
                    matTooltipPosition="above"
                    (click)="abrirComprovanteDeRequisitos()"
                    class="btn btn-sm titulo-atividades">
                    <svg class="icone-adicionar">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'printer' }}"
                        ></use>
                    </svg>
                </button>
            </div>
            <div class="row col-1 p-0 pr-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Risco</strong>
                </p>
            </div>
            <div class="row col-2 p-0 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Órgão</strong>
                </p>
            </div>
            <div class="row p-0 col-8 justify-content-start align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Requisito</strong>
                </p>
            </div>
        </div>
        <div *ngFor="let requisito of requisitos; let i = index;"
            class="row col-12 p-0 pb-3"
            [class.row-cinza]="i % 2 != 0">
            <div class="row col-12 p-0">
                <div class="row col-1 p-0 pt-4 justify-content-center align-items-center" >  
                    <form name="formulario" [formGroup]="formulario" >    
                        <mat-checkbox *ngIf="permitirAtendimento"
                            #requisitoCheckbox
                            id="requisito_{{ requisito.codigo}}"
                            name="requisito_{{ requisito.codigo}}"
                            formControlName="requisito_{{ requisito.codigo}}"
                            (change)="adicionarRemoverRequisitoProtocolo(requisitoCheckbox, requisito.codigo)">
                        </mat-checkbox>
                    </form>
                    <svg *ngIf="!permitirAtendimento"
                        [class.icone-check-square]="retornarNomeIcone(requisito.codigo) == 'check-square'"
                        [class.icone-square]="retornarNomeIcone(requisito.codigo) == 'square'">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ retornarNomeIcone(requisito.codigo) }}"
                        ></use>
                    </svg>
                    <svg class="icone-historico ml-4" 
                        matTooltip="Visualizar histórico"
                        (click)="exibeHistoricoRequisito(requisito.codigo)">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'archive' }}"
                        ></use>
                    </svg>
                </div>
                <div class="row col-1 p-0 pt-4 pr-2 justify-content-center align-items-center text-uppercase">
                    <p class="card-text w-100">
                        <button type="button" 
                            class="btn btn-sm btn-informacao-atividade col-12"
                            [class.btn-secondary]="requisito.requisitoRisco.grauDeRisco == '' "
                            [class.btn-success]="requisito.requisitoRisco.grauDeRisco <= '2' && requisito.requisitoRisco.grauDeRisco != ''"
                            [class.btn-warning]="requisito.requisitoRisco.grauDeRisco == '3'"
                            [class.btn-danger]="requisito.requisitoRisco.grauDeRisco >= '4'">
                            {{ requisito.requisitoRisco.descricao | uppercase }}
                        </button>
                    </p>
                </div>
                <div class="row col-2 p-0 pl-2 pt-4 justify-content-left align-items-center text-uppercase">
                    <p class="card-text"
                        [class.erro]="requisito.obrigatorio == true" >
                        {{ requisito.orgao.nome }}
                    </p>
                </div>
                <div class="row p-0 col-8 pt-4 justify-content-left align-items-center text-uppercase">
                    <p class="card-text"
                        [class.erro]="requisito.obrigatorio == true" >
                        {{ requisito.codigo }} - {{ requisito.nome }}
                    </p>
                </div>
            </div>
            <div *ngIf="requisito.bloqueiaDocumento.toString() != '' && requisito.bloqueiaDocumento.toString() != '0'" 
                class="row row-linha justify-content-start align-items-center">
                <div class="col-12">
                    <p class="card-text font-italic">    
                        * Este requisito bloqueará o(s) seguinte(s) documento(s): <b>{{ retornarListaDeDocumentos(requisito.bloqueiaDocumentoLista) }}</b>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card" *ngIf="codigoOrgao != '200' && codigoOrgao != '888' && codigoOrgao != '100' && codigoOrgao != '450'">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Requisitos </strong>
        </div>
    </div>
    <div *ngIf="requisitos == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="requisitos != null" class="card-body p-0">
        <div class="row col-12 p-0 justify-content-center align-items-center header-atividades">
            <div class="row col-1 p-0 justify-content-start align-items-center"></div>
            <div class="row col-1 p-0 pr-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Risco</strong>
                </p>
            </div>
            <div class="row col-10 p-0 justify-content-start align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Requisito</strong>
                </p>
            </div>
        </div>
        <div class="row col-12 p-0" *ngFor="let requisito of requisitos | filtro: {campo: 'orgao.codigo', valor: codigoOrgao}; let i = index;">
            <div class="row col-1 p-0 pt-4 justify-content-center align-items-center" >  
                <form name="formulario" [formGroup]="formulario" >    
                    <mat-checkbox *ngIf="permitirAtendimento"
                        #requisitoCheckbox
                        id="requisito_{{ requisito.codigo}}"
                        name="requisito_{{ requisito.codigo}}"
                        formControlName="requisito_{{ requisito.codigo}}"
                        (change)="adicionarRemoverRequisitoEmpresa(requisitoCheckbox, requisito.codigo)">
                    </mat-checkbox>
                </form>
                <svg class="icone-historico ml-4" 
                    matTooltip="Visualizar histórico"
                    (click)="exibeHistoricoRequisito(requisito.codigo)">
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ 'archive' }}"
                    ></use>
                </svg>
            </div>
            <div class="row col-1 p-0 pt-4 justify-content-left align-items-center text-uppercase">
                <p class="card-text w-100">
                    <button type="button" 
                        class="btn btn-sm btn-informacao-atividade col-12"
                        [class.btn-secondary]="requisito.requisitoRisco.grauDeRisco == '' "
                        [class.btn-success]="requisito.requisitoRisco.grauDeRisco <= '2' && requisito.requisitoRisco.grauDeRisco != ''"
                        [class.btn-warning]="requisito.requisitoRisco.grauDeRisco == '3'"
                        [class.btn-danger]="requisito.requisitoRisco.grauDeRisco >= '4'">
                        {{ requisito.requisitoRisco.descricao | uppercase }}
                    </button>
                </p>
            </div>
            <div class="row col-10 p-0 pl-2 pt-4 justify-content-left align-items-center text-uppercase">
                <p class="card-text"
                    [class.erro]="requisito.obrigatorio == true" >
                    {{ requisito.codigo }} - {{ requisito.nome }}
                </p>
            </div>
            <div *ngIf="requisito.possuiLicenciamento" 
                class="row row-linha justify-content-start align-items-center">
                <div class="col-12">
                    <p class="card-text font-italic">    
                        * Licenciamento Eletrônico - Este requisito possui um questionário de licenciamento.
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="!(requisitos | filtro: {campo: 'orgao.codigo', valor: codigoOrgao}).length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                        Não existem requisitos vinculados.
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>

<div class="card" *ngIf="codigoOrgao != '200' && codigoOrgao != '888' && codigoOrgao != '100' && codigoOrgao != '450'">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Outros Requisitos</strong>
        </div>
    </div>
    <div *ngIf="requisitos == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="requisitos != null" class="card-body p-0">
        <div class="row col-12 p-0 justify-content-center align-items-center header-atividades">            
            <div class="row col-1 p-0 justify-content-center align-items-center">
                <button
                    type="button" id="btn-imprimir-requisitos"
                    matTooltip="Imprimir"
                    matTooltipPosition="above"
                    (click)="abrirComprovanteDeRequisitos()"
                    class="btn btn-sm titulo-atividades">
                    <svg class="icone-adicionar">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'printer' }}"
                        ></use>
                    </svg>
                </button>
            </div>
            <div class="row col-3 p-0 justify-content-start align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Órgão</strong>
                </p>
            </div>
            <div class="row col-4 p-0 justify-content-start align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Requisito</strong>
                </p>
            </div>
            <div class="row col-2 p-0 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Vencimento</strong>
                </p>
            </div>
            <div class="row col-2 p-0 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Avaliação</strong>
                </p>
            </div>
        </div>
        <div class="row col-12 p-0" *ngFor="let requisito of requisitos | filtro: {campo: 'orgao.codigo', valor: codigoOrgao, tipoValor: '!='}; let i = index;">
            <div class="row col-1 p-0 pt-4 justify-content-center align-items-center" >
                <svg
                    [class.icone-check-square]="retornarNomeIcone(requisito.codigo) == 'check-square'"
                    [class.icone-square]="retornarNomeIcone(requisito.codigo) == 'square'">
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ retornarNomeIcone(requisito.codigo) }}"
                    ></use>
                </svg>
                <svg class="icone-historico ml-4" 
                    matTooltip="Visualizar histórico"
                    (click)="exibeHistoricoRequisito(requisito.codigo)">
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ 'archive' }}"
                    ></use>
                </svg>
            </div>
            <div class="row col-3 p-0 pt-4 justify-content-left align-items-center text-uppercase">
                <p class="card-text">
                    {{ requisito.orgao.nome }}
                </p>
            </div>
            <div class="row col-4 p-0 pt-4 justify-content-left align-items-center text-uppercase">
                <p class="card-text"
                    [class.erro]="requisito.obrigatorio == true" >
                    {{ requisito.codigo }} - {{ requisito.nome }}
                </p>
            </div>
            <div class="row col-2 p-0 pt-4 justify-content-center align-items-center text-uppercase">
                <p [class.texto-danger]="retornarStatus(requisito.codigo) == '0'" class="card-text">
                    {{ 
                        retornarVencimento(requisito.codigo) == '' && retornarStatusAvaliacao(requisito.codigo) == '' ? '---' :
                        retornarVencimento(requisito.codigo) == '' && retornarStatusAvaliacao(requisito.codigo) != '' ? 'Indeterminado' : retornarVencimento(requisito.codigo) 
                    }}
                    <br *ngIf="retornarStatus(requisito.codigo) == '0'">
                    <b *ngIf="retornarStatus(requisito.codigo) == '0'">Irregular</b>
                </p>
            </div>
            <div class="row col-2 p-0 pt-4 justify-content-center align-items-center text-uppercase">
                <p class="card-text"
                    [class.texto-success]="retornarStatusAvaliacao(requisito.codigo) == 'S'"
                    [class.texto-secondary]="retornarStatusAvaliacao(requisito.codigo) == ''"
                    [class.texto-danger]="retornarStatusAvaliacao(requisito.codigo) == 'N' || retornarStatusAvaliacao(requisito.codigo) == 'C'"
                    [class.texto-warning]="retornarStatusAvaliacao(requisito.codigo) == 'P'">
                    {{ 
                        retornarStatusAvaliacao(requisito.codigo) == 'S' ? 'DEFERIDO' : 
                        retornarStatusAvaliacao(requisito.codigo) == 'N' ? 'INDEFERIDO' :
                        retornarStatusAvaliacao(requisito.codigo) == 'P' ? 'PENDENTE' :
                        retornarStatusAvaliacao(requisito.codigo) == 'C' ? 'CANCELADO' : 
                        retornarStatusAvaliacao(requisito.codigo) == '' && retornarVencimento(requisito.codigo) == '' ? '---' : 'NÃO AVALIADO'
                    }}
                </p>
            </div>
        </div>
        <div *ngIf="!(requisitos | filtro: {campo: 'orgao.codigo', valor: codigoOrgao, tipoValor: '!='}).length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                        Não existem requisitos.
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>

<div class="card" *ngIf="codigoOrgao == '888' || codigoOrgao == '450'">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Requisitos </strong>
        </div>
    </div>
    <div *ngIf="requisitos == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="requisitos != null" class="card-body p-0">
        <div class="row col-12 p-0 justify-content-center align-items-center header-atividades">
            <div class="row col-1 p-0 justify-content-start align-items-center"></div>
            <div class="row col-1 p-0 pr-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Risco</strong>
                </p>
            </div>
            <div class="row col-3 p-0 justify-content-start align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Órgão</strong>
                </p>
            </div>
            <div class="row col-7 p-0 justify-content-start align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Requisito</strong>
                </p>
            </div>
        </div>
        <div class="row col-12 p-0" *ngFor="let requisito of requisitos; let i = index;">
            <div class="row col-1 p-0 pt-4 justify-content-center align-items-center" >  
                <form name="formulario" [formGroup]="formulario" >    
                    <mat-checkbox *ngIf="permitirAtendimento"
                        #requisitoCheckbox
                        id="requisito_{{ requisito.codigo}}"
                        name="requisito_{{ requisito.codigo}}"
                        formControlName="requisito_{{ requisito.codigo}}"
                        (change)="adicionarRemoverRequisitoEmpresa(requisitoCheckbox, requisito.codigo)">
                    </mat-checkbox>
                </form>
                <svg class="icone-historico ml-4" 
                    matTooltip="Visualizar histórico"
                    (click)="exibeHistoricoRequisito(requisito.codigo)">
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ 'archive' }}"
                    ></use>
                </svg>
            </div>
            <div class="row col-1 p-0 pt-4 justify-content-left align-items-center text-uppercase">
                <p class="card-text w-100">
                    <button type="button" 
                        class="btn btn-sm btn-informacao-atividade col-12"
                        [class.btn-secondary]="requisito.requisitoRisco.grauDeRisco == '' "
                        [class.btn-success]="requisito.requisitoRisco.grauDeRisco <= '2' && requisito.requisitoRisco.grauDeRisco != ''"
                        [class.btn-warning]="requisito.requisitoRisco.grauDeRisco == '3'"
                        [class.btn-danger]="requisito.requisitoRisco.grauDeRisco >= '4'">
                        {{ requisito.requisitoRisco.descricao | uppercase }}
                    </button>
                </p>
            </div>
            <div class="row col-3 p-0 pl-2 pt-4 justify-content-left align-items-center text-uppercase">
                <p class="card-text"
                    [class.erro]="requisito.obrigatorio == true" >
                    {{ requisito.orgao.nome }}
                </p>
            </div>
            <div class="row col-7 p-0 pt-4 justify-content-left align-items-center text-uppercase">
                <p class="card-text"
                    [class.erro]="requisito.obrigatorio == true" >
                    {{ requisito.orgao.nome }} - {{ requisito.codigo }} - {{ requisito.nome }}
                </p>
            </div>
        </div>
        <div *ngIf="!(requisitos | filtro: {campo: 'orgao.codigo', valor: codigoOrgao}).length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                        Não existem requisitos vinculados.
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>

<div class="card" *ngIf="codigoOrgao == '100' || (codigoOrgao == '200' && consultaProcesso)">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            <strong>Requisitos</strong>
        </div>
    </div>
    <div *ngIf="requisitosExistentes == null" class="card-body" [class.p-0]="semCardHeader">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text text-center">
                    Carregando...
                </p>
            </div>
        </div>      
    </div>
    <div *ngIf="requisitosExistentes != null" class="card-body p-0">
        <div class="row col-12 p-0 justify-content-center align-items-center header-atividades">
            <div class="row col-1 p-0 justify-content-center align-items-center">
                <button
                    type="button" id="btn-imprimir-requisitos"
                    matTooltip="Imprimir"
                    matTooltipPosition="above"
                    (click)="abrirComprovanteDeRequisitos()"
                    class="btn btn-sm titulo-atividades">
                    <svg class="icone-adicionar">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'printer' }}"
                        ></use>
                    </svg>
                </button>
            </div>
            <div class="row col-3 p-0 justify-content-start align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Órgão</strong>
                </p>
            </div>
            <div class="row col-4 p-0 justify-content-start align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Requisito</strong>
                </p>
            </div>
            <div class="row col-2 p-0 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Vencimento</strong>
                </p>
            </div>
            <div class="row col-2 p-0 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    <strong>Avaliação</strong>
                </p>
            </div>
        </div>
        <div *ngFor="let requisito of requisitosExistentes; let i = index;"
            class="row col-12 p-0 pb-4"
            [class.row-cinza]="i % 2 != 0">          
            <div class="row col-1 p-0 pt-4 justify-content-center align-items-center" >
                <svg class="icone-historico" 
                    matTooltip="Visualizar histórico"
                    (click)="exibeHistoricoRequisito(requisito.codigo)">
                    <use
                        attr.xlink:href="assets/sprite.svg#{{ 'archive' }}"
                    ></use>
                </svg>
            </div>
            <div class="row col-3 p-0 pt-4 justify-content-left align-items-center text-uppercase">
                <p class="card-text">
                    {{ requisito.orgao.nome }}
                </p>
            </div>
            <div class="row col-4 p-0 pt-4 justify-content-left align-items-center text-uppercase">
                <p class="card-text"
                    [class.erro]="requisito.obrigatorio == true" >
                    {{ requisito.codigo }} - {{ requisito.nome }}
                </p>
            </div>
            <div class="row col-2 p-0 pt-4 justify-content-center align-items-center text-uppercase">
                <p [class.texto-danger]="requisito.status == '0'" class="card-text">
                    {{ requisito.vencimento == '' ? 'Indeterminado' : requisito.vencimento }}
                    <br *ngIf="requisito.status == '0'">
                    <b *ngIf="requisito.status == '0'">Irregular</b>
                </p>
            </div>
            <div class="row col-2 p-0 pt-4 justify-content-center align-items-center text-uppercase">
                <p class="card-text"
                    [class.texto-success]="requisito.statusAvaliacao == 'S'"
                    [class.texto-danger]="requisito.statusAvaliacao == 'N' || requisito.statusAvaliacao == 'C' || requisito.statusAvaliacao == ''"
                    [class.texto-warning]="requisito.statusAvaliacao == 'P'">
                    <b>
                        {{ 
                            requisito.statusAvaliacao == 'S' ? 'DEFERIDO' : 
                            requisito.statusAvaliacao == 'N' ? 'INDEFERIDO' :
                            requisito.statusAvaliacao == 'P' ? 'PENDENTE' :
                            requisito.statusAvaliacao == 'C' ? 'CANCELADO' : 'NÃO AVALIADO'
                        }}
                    </b>
                </p>
            </div>
            <div *ngIf="requisito.bloqueiaDocumento.toString() != '' && requisito.bloqueiaDocumento.toString() != '0'" 
                class="row row-linha justify-content-start align-items-center">
                <div class="col-12">
                    <p class="card-text font-italic">    
                        * Este requisito bloqueará o(s) seguinte(s) documento(s): <b>{{ retornarListaDeDocumentos(requisito.bloqueiaDocumentoLista) }}</b>
                    </p>
                </div>
            </div>
            <div *ngIf="requisito.possuiLicenciamento" 
                class="row row-linha justify-content-start align-items-center">
                <div (click)="detalharLicenciamentoContribuinte(requisito)"
                    class="col-12">
                    <p class="card-text font-italic">    
                        * Licenciamento Eletrônico - Este requisito possui um questionário de licenciamento.
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="!requisitosExistentes.length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                        Não existem requisitos.
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>