<div [class.containerLogin]="!popUp">
  <!-- LOGIN -->
  <form
    *ngIf="!esqueciSenha && !alterarSenha && !selecionarEstrutura && !cadastrarUsuario && !validarCadastro"
    [class.containerLogin_formulario]="!popUp"
    [class.row]="!popUp"
    [class.justify-content-center]="!popUp"
    [class.align-items-center]="!popUp"
    name="loginForm"
    [formGroup]="loginForm"
    (ngSubmit)="submit(loginForm)"
  >
    <div
      [class.col-md-5]="!popUp"
      [class.col-sm-7]="!popUp"
      [class.col-md-12]="popUp"
      [class.col-sm-12]="popUp"
    >
      <div
        [class.card]="!popUp"
        [class.m-3]="!popUp"
        [class.px-3]="!popUp"
      >
        <div class="card-body">
          <div class="form-row">
            <div class="col-12 d-flex justify-content-center mt-3">
              <a [routerLink]="['/portal-home']">
                <img
                  class="logo"
                  src="../../../../../assets/img/logos/_logo_iCad_Completo.svg"
                />
              </a>
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <h1 class="containerLogin_formulario_titulo">Seja bem-vindo</h1>
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <p class="texto-informativo">
                * Para acessar o sistema, informe aqui o CPF ou CNPJ do cadastro que você sempre utilizou para acessar o iCadOnline. <br>
                Caso não tenha cadastro, utilize a opção abaixo "Não possui cadastro?".
              </p>
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <u>
                <a class="containerLogin_formulario_link"
                  (click)="acaoAcessoPorNomeUsuario()">
                  Para acessar com nome de usúario, clique aqui?
                </a>
              </u>
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <p class="texto-informativo">
                * Lembrando que o acesso com o nome de usuário será descontinuado, favor após o acesso memorizar seu CPF / CNPJ do cadastro para efetuar os acessos seguintes.
              </p>
            </div>
            <div></div>
            <div class="form-group col-12 mt-4">
              <label class="sr-only" for="login">CPF / CNPJ</label>
              <input
                #loginInput
                autofocus
                (input)="formatToCPFOrCNPJ(loginInput.value)"
                class="form-control form-control-sm"
                [class.is-invalid]="login.invalid && login.touched"
                formControlName="login"
                id="login"
                name="login"
                placeholder="CPF / CNPJ"
                type="text"
              />
              <div class="invalid-feedback feedback-icon">
                <i class="fa fa-times"></i>
              </div>
              <div *ngIf="login.invalid && login.touched">
                <span class="erro" *ngIf="login.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="form-group col-12">
              <label class="sr-only" for="senha">Senha</label>
              <input
                #senhaInput
                class="form-control form-control-sm"
                [class.is-invalid]="senha.invalid && senha.touched"
                formControlName="senha"
                id="senha"
                name="senha"
                placeholder="Senha"
                type="password"
              />
              <svg class="icone-visualizar-senha" (click)="senhaInput.type = 'text'" *ngIf="senhaInput.type == 'password'">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'eye' }}"
                ></use>
              </svg>
              <svg class="icone-visualizar-senha" (click)="senhaInput.type = 'password'" *ngIf="senhaInput.type == 'text'">
                <use
                    attr.xlink:href="assets/sprite.svg#{{ 'eye-off' }}"
                ></use>
              </svg>
              <div class="invalid-feedback feedback-icon">
                <i class="fa fa-times"></i>
              </div>
              <div *ngIf="senha.invalid && senha.touched">
                <span class="erro" *ngIf="senha.errors.required"
                  >Campo obrigatório</span
                >
                <span class="erro" *ngIf="senha.errors.minlength"
                  >A senha deve conter ao menos 6 caracteres.</span
                >
              </div>
            </div>
          </div>
          <div
            *ngIf="!popUp"
            class="d-flex justify-content-around mt-2"
          >
            <u
              ><a
                class="containerLogin_formulario_link"
                (click)="cadastrarUsuario = true"
              >
                Não possui cadastro?
              </a></u
            >
            <u
              ><a
                class="containerLogin_formulario_link"
                (click)="esqueciSenha = true"
              >
                Esqueceu a senha?
              </a></u
            >
          </div>
          <div class="form-row mt-4">
            <div class="form-group col-6">
              <button
                class="btn btn-block btn-success"
                title="Acessar"
                type="submit"
                id="btnAcessar"
              >
                Acessar
              </button>
            </div>
            <div class="form-group col-6">
              <button
                (click)="onCancelarAcesso()"
                class="btn btn-block btn-danger"
                title="Cancelar"
                type="button"
                id="btnCancelar"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- CADASTRAR USUÁRIO -->
  <ng-container *ngIf="cadastrarUsuario">
    <app-cadastrar-usuario (cancelarCadastro)="onCancelarCadastro($event)"></app-cadastrar-usuario>
  </ng-container>

  <!-- VALIDAR CADASTRO -->
  <ng-container *ngIf="validarCadastro">
    <form
      class="containerLogin_formulario row no-gutters justify-content-center align-items-center"
      name="validarCadastroForm"
      [formGroup]="validarCadastroForm"
      (ngSubmit)="enviarCodigoValidacao()"
    >
      <div class="col-md-5 col-sm-6">
        <div class="card m-3 px-3">
          <div class="card-body">
            <div class="form-row">
              <div class="col-12 d-flex justify-content-center mt-3">
                <img
                  class="logo"
                  src="../../../../../assets/img/logos/_logo_iCad.svg"
                />
              </div>
              <div class="col-12 d-flex justify-content-center mt-4">
                <h1 class="containerLogin_formulario_titulo">
                  Valide seu acesso informando o código enviado no e-mail cadastrado.
                </h1>
              </div>
              <div class="form-group col-12 mt-4">
                <label class="sr-only" for="loginValidacao">CPF / CNPJ</label>
                <input
                  autofocus
                  #loginValidacaoInput
                  class="form-control form-control-sm"
                  (input)="formatToCPFOrCNPJValidacao(loginValidacaoInput.value)"
                  [class.is-invalid]="loginValidacao.invalid && loginValidacao.touched"
                  formControlName="loginValidacao"
                  id="loginValidacao"
                  name="loginValidacao"
                  placeholder="CPF / CNPJ"
                  type="loginValidacao"
                  maxlength="18"
                />
                <div class="invalid-feedback feedback-icon">
                  <i class="fa fa-times"></i>
                </div>
                <div *ngIf="loginValidacao.invalid && loginValidacao.touched">
                  <span class="erro" *ngIf="loginValidacao.errors.required"
                    >Campo obrigatório</span
                  >
                  <span class="erro" *ngIf="loginValidacao.errors.minlength"
                    >Usuário inválido</span
                  >
                </div>
              </div>
              <div class="form-group col-12 mt-4">
                <label class="sr-only" for="codigoValidacao">Código de validação</label>
                <input
                  autofocus
                  class="form-control form-control-sm"
                  [class.is-invalid]="codigoValidacao.invalid && codigoValidacao.touched"
                  formControlName="codigoValidacao"
                  id="codigoValidacao"
                  name="codigoValidacao"
                  placeholder="Código de validação"
                  type="codigoValidacao"
                  maxlength="6"
                />
                <div class="invalid-feedback feedback-icon">
                  <i class="fa fa-times"></i>
                </div>
                <div *ngIf="codigoValidacao.invalid && codigoValidacao.touched">
                  <span class="erro" *ngIf="codigoValidacao.errors.required"
                    >Campo obrigatório</span
                  >
                  <span class="erro" *ngIf="codigoValidacao.errors.minlength"
                    >Código inválido</span
                  >
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-around mt-2">
              <u>
                <a
                  class="containerLogin_formulario_link"
                  (click)="reenviarCodigoValidacao()"
                >
                  Reenviar código de validação?
                </a>
              </u>
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <button
                class="btn btn-secondary"
                (click)="validarCadastro = false; limpaValidarCadastro()"
                title="voltar"
              >
                Voltar
              </button>
              <button
                type="button"
                class="btn btn-success ml-2"
                (click)="enviarCodigoValidacao()"
                title="Validar"
              >
                Validar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>

  <!-- ALTERAR SENHA -->
  <ng-container *ngIf="alterarSenha">
    <form
      class="containerLogin_formulario row no-gutters justify-content-center align-items-center"
      name="alterarSenhaForm"
      [formGroup]="alterarSenhaForm"
    >
      <div class="col-md-5 col-sm-6">
        <div class="card m-3 px-3">
          <div class="card-body">
            <div class="form-row">
              <div class="col-12 d-flex justify-content-center mt-3">
                <img
                  class="logo"
                  src="../../../../../assets/img/logos/_logo_iCad.svg"
                />
              </div>
              <div class="col-12 d-flex justify-content-center mt-4">
                <h1 class="containerLogin_formulario_titulo" *ngIf="!mudarSenha">
                  Nova Senha
                </h1>
                <h1 class="containerLogin_formulario_titulo" *ngIf="mudarSenha">
                  Alterar Senha
                </h1>
              </div>
              <div class="form-group col-12 mt-4" *ngIf="mudarSenha">
                <label class="sr-only" for="senhaAtual">Senha Atual</label>
                <input
                    #senhaAtualInput
                    [class.is-invalid]="senhaAtual.invalid && senhaAtual.touched"
                    class="form-control form-control-sm"
                    formControlName="senhaAtual"
                    placeholder="Senha Atual"
                    id="senhaAtual"
                    name="senhaAtual"
                    type="password"
                />
                <svg class="icone-visualizar-senha" (click)="senhaAtualInput.type = 'text'" *ngIf="senhaAtualInput.type == 'password'">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'eye' }}"
                  ></use>
                </svg>
                <svg class="icone-visualizar-senha" (click)="senhaAtualInput.type = 'password'" *ngIf="senhaAtualInput.type == 'text'">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'eye-off' }}"
                  ></use>
                </svg>
                <div class="mb-1" *ngIf="senhaAtual.invalid && senhaAtual.touched">
                    <span 
                        class="erro" 
                        *ngIf="senhaAtual.errors.required"
                    >Campo obrigatório</span>
                </div>
              </div>
              <div class="form-group col-12 mt-4">
                <label class="sr-only" for="senhaAlterarSenha">Nova Senha</label>
                <input
                    #novaSenhaInput
                    [class.is-invalid]="senhaAlterarSenha.invalid && senhaAlterarSenha.touched"
                    class="form-control form-control-sm"
                    formControlName="senhaAlterarSenha"
                    placeholder="Nova Senha"
                    id="senhaAlterarSenha"
                    name="senhaAlterarSenha"
                    type="password"
                />
                <svg class="icone-visualizar-senha" (click)="novaSenhaInput.type = 'text'" *ngIf="novaSenhaInput.type == 'password'">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'eye' }}"
                  ></use>
                </svg>
                <svg class="icone-visualizar-senha" (click)="novaSenhaInput.type = 'password'" *ngIf="novaSenhaInput.type == 'text'">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'eye-off' }}"
                  ></use>
                </svg>
                <div class="mb-1" *ngIf="senhaAlterarSenha.invalid && senhaAlterarSenha.touched">
                    <span 
                        class="erro" 
                        *ngIf="senhaAlterarSenha.errors.required"
                    >Campo obrigatório</span>
                </div>
              </div>
              <div class="form-group col-12 mt-4">
                <label class="sr-only" for="confirmarSenha">Confirmação</label>
                <input
                    #confirmarSenhaInput
                    [class.is-invalid]="confirmarSenha.invalid && confirmarSenha.touched"
                    class="form-control form-control-sm"
                    formControlName="confirmarSenha"
                    placeholder="Confirme a nova senha"
                    id="confirmarSenha"
                    name="confirmarSenha"
                    type="password"
                />
                <svg class="icone-visualizar-senha" (click)="confirmarSenhaInput.type = 'text'" *ngIf="confirmarSenhaInput.type == 'password'">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'eye' }}"
                  ></use>
                </svg>
                <svg class="icone-visualizar-senha" (click)="confirmarSenhaInput.type = 'password'" *ngIf="confirmarSenhaInput.type == 'text'">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'eye-off' }}"
                  ></use>
                </svg>
                <div class="mb-1" *ngIf="confirmarSenha.invalid && confirmarSenha.touched">
                    <span 
                        class="erro" 
                        *ngIf="confirmarSenha.errors.required"
                    >Campo obrigatório</span>
                    <span 
                        class="erro" 
                        *ngIf="confirmarSenha.hasError('equalsTo')"
                    >Senhas não conferem.</span>
                </div>
              </div>

            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <button
                class="btn btn-secondary"
                (click)="acaoBotaoVoltar()"
                title="voltar"
              >
                Voltar
              </button>
              <button
                type="button"
                class="btn btn-success ml-2"
                (click)="novaSenha()"
                title="Confirmar"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>

  <!-- ESQUECI MINHA SENHA -->
  <ng-container *ngIf="esqueciSenha">
    <form
      class="containerLogin_formulario row no-gutters justify-content-center align-items-center"
      name="esqueciSenhaForm"
      [formGroup]="esqueciSenhaForm"
      (ngSubmit)="recuperarSenha(esqueciSenhaForm)"
    >
      <div class="col-md-5 col-sm-6">
        <div class="card m-3 px-3">
          <div class="card-body">
            <div class="form-row">
              <div class="col-12 d-flex justify-content-center mt-3">
                <img
                  class="logo"
                  src="../../../../../assets/img/logos/_logo_iCad.svg"
                />
              </div>
              <div class="col-12 d-flex justify-content-center mt-4">
                <h1 class="containerLogin_formulario_titulo">
                  Recuperar senha
                </h1>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <label
                  class="forgot-feedback error mt-2"
                  *ngIf="esqueciSenhaMsg.type == 'error'"
                  >Usuário não encontrado!</label
                >
                <label
                  class="forgot-feedback success mt-2"
                  *ngIf="esqueciSenhaMsg.type == 'success'"
                  >Email enviado com sucesso!</label
                >
              </div>
              <div class="form-group col-12 mt-4">
                <label class="sr-only" for="loginValidacaoRecuperar">CPF / CNPJ</label>
                <input
                  autofocus
                  #loginValidacaoRecuperarInput
                  class="form-control form-control-sm"
                  (input)="formatToCPFOrCNPJValidacaoRecuperar(loginValidacaoRecuperarInput.value)"
                  [class.is-invalid]="loginValidacaoRecuperar.invalid && loginValidacaoRecuperar.touched"
                  formControlName="loginValidacaoRecuperar"
                  id="loginValidacaoRecuperar"
                  name="loginValidacaoRecuperar"
                  placeholder="CPF / CNPJ"
                  type="loginValidacaoRecuperar"
                  maxlength="18"
                />
                <div class="invalid-feedback feedback-icon">
                  <i class="fa fa-times"></i>
                </div>
                <div *ngIf="loginValidacaoRecuperar.invalid && loginValidacaoRecuperar.touched">
                  <span class="erro" *ngIf="loginValidacaoRecuperar.errors.required"
                    >Campo obrigatório</span
                  >
                  <span class="erro" *ngIf="loginValidacaoRecuperar.errors.minlength"
                    >Usuário inválido</span
                  >
                </div>
              </div>
              <div class="form-group col-12 mt-4">
                <label class="sr-only" for="email">E-mail</label>
                <input
                  autofocus
                  class="form-control form-control-sm"
                  [class.is-invalid]="
                    (email.invalid && email.touched) ||
                    esqueciSenhaMsg.type == 'error'
                  "
                  formControlName="email"
                  id="email"
                  name="email"
                  placeholder="usuario@eicon.com.br"
                  type="email"
                />
                <div class="invalid-feedback feedback-icon">
                  <i class="fa fa-times"></i>
                </div>
                <div *ngIf="email.invalid && email.touched">
                  <span class="erro" *ngIf="email.errors.required"
                    >Campo obrigatório</span
                  >
                  <span class="erro" *ngIf="email.errors.email"
                    >E-mail inválido</span
                  >
                </div>
                <div *ngIf="email.invalid == false || email.touched == false">
                  <span class="erro" *ngIf="esqueciSenhaMsg.type == 'error'"
                    >Usuário não encontrado</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <button
                class="btn btn-secondary"
                (click)="esqueciSenha = false; limpaEsqueciSenha()"
                title="voltar"
              >
                Voltar
              </button>
              <button
                type="button"
                class="btn btn-success ml-2"
                (click)="recuperarSenha(esqueciSenhaForm)"
                title="recuperar"
              >
                Recuperar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>

  <!-- SELECIONAR ESTRUTURA -->
  <form
    class="containerLogin_formulario row no-gutters justify-content-center align-items-center"
    *ngIf="selecionarEstrutura"
  >
  <div class="col col-md-6 col-sm-6">
    <div class="card m-3 px-3">
        <div class="card-body">
          <div class="form-row">
            <div class="col-12 d-flex justify-content-center mt-3">
              <img
                class="logo"
                src="../../../../../assets/img/logos/_logo_iCad.svg"
              />
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <h1 class="containerLogin_formulario_titulo">
                Selecione um perfil
              </h1>
            </div>
            <table
              class="table table-bordered table-striped table-sm"
              datatable="ng"
            >
              <thead>
                <tr>
                  <th id="descricao">Descrição</th>
                  <th id="acao" class="text-center">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let estrutura of estruturas">
                  <td>{{ estrutura.valor }}</td>
                  <td class="text-center">
                    <a
                      (click)="logarEstrutura(estrutura.chave, estrutura.id)"
                      class="containerLogin_formulario_link"
                      title="selecionar"
                    >
                      <img
                        alt="Avançar"
                        src="../../../../../assets/img/icons/seta-esquerda-proximo-prosseguir-2.svg"
                        width="17px"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="ml-auto pt-3">
              <button
                class="btn btn-secondary float-right"
                (click)="selecionarEstrutura = false"
                title="voltar"
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- Rodapé -->
  <div
    *ngIf="exibirFooter"
    class="containerLogin_rodape d-flex justify-content-center align-items-center"
  >
    <span>&copy; 2020 | Desenvolvido por Eicon</span>
  </div>
</div>
