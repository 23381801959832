import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Parecer } from '../../model/parecer';

@Injectable({ providedIn: 'root' })
export class ParecerService {

    constructor(private http: HttpClient, private router: Router) {}

    salvar(parecer: Parecer) {
        return this.http.post<any>( `${ environment.icadUrl }/parecer/salvar`, JSON.stringify(parecer),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarParecer(cliente, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/parecer/buscarParecer/${ cliente }/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPareceresTodos(cliente) {
        return this.http.get<any>(
        `${ environment.icadUrl }/parecer/buscarPareceresTodos/${ cliente }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPareceresPorOrgaoEFaseEAvaliacao(cliente, codigoOrgao, fase, avaliacao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/parecer/buscarPareceresPorOrgaoEFaseEAvaliacao/${ cliente }/${ codigoOrgao }/${ fase }/${ avaliacao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    buscarPareceresPorOrgaoERequisitoEAvaliacao(cliente, codigoOrgao, codigoRequisito, avaliacao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/parecer/buscarPareceresPorOrgaoERequisitoEAvaliacao/${ cliente }/${ codigoOrgao }/${ codigoRequisito }/${ avaliacao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }
          
}