export const environment = {
  production: "false",
  prucUrl: '',
  srvBaseUrl: '',
  icadUrl: "https://api.icadonline.com.br",
  icadUrlV2: "https://api.icadonline.com.br",
  ciphersUrl: "https://api.icadonline.com.br",
  keyGoogleMaps: "AIzaSyCin59dXes7SqdcpntuHVZU3H7jr4EK0zE",
  appId: '',

  powerBI: {
    reportBaseURL: 'https://app.powerbi.com/reportEmbed',
    qnaBaseURL: 'https://app.powerbi.com/qnaEmbed',
    tileBaseURL: 'https://app.powerbi.com/embed',
    groupID: '078fca91-51b4-42fe-a7ba-bb92bd7c36ba',
  }
};
 