import { Component, Input, OnInit } from '@angular/core';
import { Endereco } from '../../../../model/endereco';
import { Protocolo } from '../../../../model/protocolo';
import { IcadModalComponent } from '../../../../components/modal/icad-modal/icad-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Atividade } from '../../../../model/atividade';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-informacao-localizacao',
  templateUrl: './informacao-localizacao.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoLocalizacaoComponent implements OnInit {
  
  @Input() permiteCriticar = false;
  @Input() permiteInconsistencias = false;  
  @Input() semCardHeader: boolean = false;
  @Input() tipoEmpresa = "";
  apenasCorrespondencia: boolean;

  protocolo: Protocolo;
  processo: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog) {}

  ngOnInit(): void {
  }

  enviarParaAreaDeTranferenciaEmpresarial() {
    var endereco = this.protocolo.viabilidade.enderecos[0];
    navigator.clipboard.writeText(`${ endereco.tipoLogradouro } ${ endereco.logradouro }${ endereco.numero != "" ? ", " + endereco.numero : ""} ${ endereco.complemento != "" ? "- " + endereco.complemento : ""} ${ endereco.bairro != "" ? "- " + endereco.bairro : ""} ${ endereco.cidade != "" ? "- " + endereco.cidade : ""} ${ endereco.estado != "" ? "- " + endereco.estado : ""} ${ endereco.cep != "" ? "- " + endereco.cep : ""}`);
  }

  enviarParaAreaDeTranferenciaCorrespondencia() {
    var endereco = this.protocolo.viabilidade.enderecos[1];
    navigator.clipboard.writeText(`${ endereco.tipoLogradouro } ${ endereco.logradouro }${ endereco.numero != "" ? ", " + endereco.numero : ""} ${ endereco.complemento != "" ? "- " + endereco.complemento : ""} ${ endereco.bairro != "" ? "- " + endereco.bairro : ""} ${ endereco.cidade != "" ? "- " + endereco.cidade : ""} ${ endereco.estado != "" ? "- " + endereco.estado : ""} ${ endereco.cep != "" ? "- " + endereco.cep : ""}`);
  }
  
  analiseDeEntorno() {
    var listaDeAtividades = "";
    var atividades = new Set<string>();

    this.protocolo.viabilidade.atividadesExercidas.forEach( atividade => {
      atividades.add(atividade.codigo);
    });
    this.protocolo.viabilidade.atividadesNaoExercidas.forEach( atividade => {
      atividades.add(atividade.codigo);
    });

    atividades.forEach(atividade => listaDeAtividades = listaDeAtividades.concat(atividade + ",") );
    
    const url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["analise-entorno"], 
        {
          relativeTo: this.activatedRoute, 
          queryParams: {
            tipoDeNegocio: this.protocolo.registro.tipoPessoa,
            endereco: JSON.stringify(this.protocolo.viabilidade.enderecos[0]),
            atividades: listaDeAtividades
          }
        }
      )
    );
    
    this.router.navigate([]).then(result => { window.open("#" + url, '_blank') });
 
  }

  visualizarMapa(){
    const url = `http://www.google.com/maps?f=q&source=s_q&hl=pt-BR&geocode=&q=${this.protocolo.viabilidade.enderecos[0].tipoLogradouro}+${this.protocolo.viabilidade.enderecos[0].logradouro},+${this.protocolo.viabilidade.enderecos[0].numero}+-+${this.protocolo.viabilidade.enderecos[0].bairro}+-+${this.protocolo.viabilidade.enderecos[0].cidade}+${this.protocolo.viabilidade.enderecos[0].estado},+${this.protocolo.viabilidade.enderecos[0].cep}&sspn=0.011894,0.026157&ie=UTF8&hq=&hnear=${this.protocolo.viabilidade.enderecos[0].tipoLogradouro}+${this.protocolo.viabilidade.enderecos[0].logradouro}+,+${this.protocolo.viabilidade.enderecos[0].numero}+-+${this.protocolo.viabilidade.enderecos[0].bairro}+-+${this.protocolo.viabilidade.enderecos[0].cidade}+${this.protocolo.viabilidade.enderecos[0].estado},+${this.protocolo.viabilidade.enderecos[0].cep}+Brasil&z=16&iwloc=A`
    window.open(url, '_blank');        
    //<a target="_blank" href="http://www.google.com/maps?f=q&source=s_q&hl=pt-BR&geocode=&q=#q_lista_insc.TIPO_ABR_TIP#+#q_lista_insc.LOGR_NOM_LOGRADOURO#,+#q_lista_insc.IMOB_NUM_IMOVEL#+-+#q_lista_insc.BAIR_NOM_BAIRRO#+-+#vCidadeMaps#+#q_lista_insc.CLIE_COD_ESTADO#,+#create_Mask(lsnumberformat(q_lista_insc.IMOB_CEP_USUARIO,'00000000'),'00000-000')#&sspn=0.011894,0.026157&ie=UTF8&hq=&hnear=#q_lista_insc.TIPO_ABR_TIP#+#q_lista_insc.LOGR_NOM_LOGRADOURO#+,+#q_lista_insc.IMOB_NUM_IMOVEL#+-+#q_lista_insc.BAIR_NOM_BAIRRO#+-+#q_lista_insc.CLIE_NOM_CIDADE#+#q_lista_insc.CLIE_COD_ESTADO#,+#create_Mask(lsnumberformat(q_lista_insc.IMOB_CEP_USUARIO,'00000000'),'00000-000')#+Brasil&z=16&iwloc=A">Visualizar Google Maps</a>
  }

}
