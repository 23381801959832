import { take, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, Input, ViewChild, EventEmitter } from '@angular/core';

import Swal from 'sweetalert2';
import { AtendimentoService } from '../../../../services/operacao/atendimento.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { InformacaoCadastroComponent } from '../../informacoes-cadastro/informacao-cadastro.component';
import { AtendimentoAvaliadorComponent } from '../atendimento-protocolo/atendimento-avaliador.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Documento } from '../../../../model/documento';
import { AtendimentoAlvara } from '../../../../model/atendimentoAlvara';
import { Constantes } from '../../../../model/constantes';
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';
import { EmpresaService } from 'src/app/services/empresa/empresa.service';


@Component({
  selector: 'app-atendimento-alvara',
  templateUrl: './atendimento-alvara.component.html',
  styleUrls: ['./atendimento-alvara.component.scss']
})
export class AtendimentoAlvaraComponent implements OnInit {

  @Output() acaoDeAtendimentoAlvara = new EventEmitter();
  @Input() semCardHeader: boolean = false;

  public config: PerfectScrollbarConfigInterface = {};

  acoesDeAtendimento: any;
  numeroInscricao: string;
  numeroProtocolo: string;
  orgao: string;
  nomeOrgao: string;
  htmlValidade: string;
  tiposDocumento = new Array<Documento>();
  atendimentosAlvara = new Array<AtendimentoAlvara>();
  atendimentoAlvara = new AtendimentoAlvara();
  situacaoAlvaraEmpresa = Constantes.SITUACAO_ALVARA_EMPRESA;
  situacaoAlvaraEmpresaDescricao = Constantes.SITUACAO_ALVARA_EMPRESA_DESCRICAO;
  tipoDocumentoDescricao = Constantes.TIPO_DE_DOCUMENTO_DESCRICAO;
  
  permitirAtendimento: boolean;
  emitirErro: boolean;
  mensagemErro: string;

  @ViewChild(InformacaoCadastroComponent)
  informacaoCadastro: InformacaoCadastroComponent;

  @ViewChild(AtendimentoAvaliadorComponent)
  atendimentoAvaliador: AtendimentoAvaliadorComponent;

  constructor(
    private empresaService: EmpresaService,
    private arquivoService: ArquivoService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private atendimentoService: AtendimentoService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.acoesDeAtendimento = this.authenticationService.currentUserValue.eixos.filter( eixo => eixo.rota.split('.')[1] == this.activatedRoute.snapshot.data["eixo"]);
    this.acoesDeAtendimento = this.acoesDeAtendimento[0].item.filter( menu => parseInt(menu.codigo) == parseInt(this.activatedRoute.snapshot.params["codigoMenuPai"]));
    this.acoesDeAtendimento = this.acoesDeAtendimento[0].item.filter( menu => parseInt(menu.codigo) == parseInt(this.activatedRoute.snapshot.params["codigoMenu"]));
    this.acoesDeAtendimento = this.acoesDeAtendimento[0].acoes;
    this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];
    this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];
    this.orgao = this.activatedRoute.snapshot.params["codigoOrgao"];
    this.nomeOrgao = this.activatedRoute.snapshot.params["orgao"];
    this.htmlValidade = "";

   }

  ngOnInit(): void {
    this.empresaService
      .permiteAtendimento(localStorage.getItem("pId"), this.numeroInscricao, this.numeroProtocolo)
      .pipe(take(1))
      .subscribe(retorno => {
        if (retorno.status == "error") {
          Swal.fire({
            title: retorno.titulo,
            html: retorno.mensagem,
            icon: retorno.status,
            willClose: () => {
              this.router.navigate([retorno.redirecionamento], {relativeTo: this.activatedRoute});
            }
          });
        }
        else {
          this.authenticationService.currentAxis.subscribe((axis) => {
            if (axis != "" && axis != null) {
              axis.item.forEach(item => {
                if(item.orgao.toString() == this.orgao){
                  this.nomeOrgao = item.titulo.toUpperCase();
                }
              });
            }            
          });
      
          this.buscarTipoDeDocumentoAlvara();
          this.buscarSituacaoAlvaraPorInscricao();
        }

      })
  }

  buscarTipoDeDocumentoAlvara() {
    this.atendimentoService
      .buscarTipoDeDocumentoAlvara(localStorage.getItem("pId"))
      .pipe(take(1))
      .subscribe( retorno => {
        this.tiposDocumento = retorno;
      });
  }

  buscarSituacaoAlvaraPorInscricao() {
    this.atendimentoService
      .buscarSituacaoAlvaraPorInscricao(localStorage.getItem("pId"), this.numeroInscricao)
      .pipe(take(1))
      .subscribe( retorno => {
        this.atendimentoAlvara = retorno;
        this.buscarAtendimentosAlvaraPorInscricao();
      });
  }

  buscarAtendimentosAlvaraPorInscricao() {
    this.atendimentoService
      .buscarAtendimentosAlvaraPorInscricao(localStorage.getItem("pId"), this.numeroInscricao)
      .pipe(take(1))
      .subscribe( retorno => {
        this.atendimentosAlvara = retorno;
      });
  }

  acaoTipoDocumentoSelect(tipoDocumento) {
    this.atendimentoAlvara.tipoDocumento = tipoDocumento;
  }

  baixarArquivo(codigo, nome) {
    nome = nome == "" ? `Documento_${ this.atendimentoAlvara.inscricao }` : nome;

    this.arquivoService.downloadArquivoPorCodigo(codigo)
      .subscribe((res: any) => {
          this.arquivoService.handleFile(res, nome + '.pdf');
      });
  }

  acaoDeAtendimento(acaoAtendimento, atendimentoAlvara) {
    if (parseInt(acaoAtendimento) == Constantes.ACAO_DOCUMENTO.Imprimir) {
      this.baixarArquivo(atendimentoAlvara.codigoDocumento, atendimentoAlvara.nomeDocumento);
      return false;
    }
    
    var mensagem = atendimentoAlvara.mensagensDeAtendimento[acaoAtendimento - 1].mensagem;

    if (atendimentoAlvara.mensagensDeAtendimento[acaoAtendimento - 1].exibirMensagemAdicionalPara != null &&
      atendimentoAlvara.mensagensDeAtendimento[acaoAtendimento - 1].exibirMensagemAdicionalPara.search(atendimentoAlvara.tipoDocumento) > -1 ) {
        mensagem += atendimentoAlvara.mensagensDeAtendimento[acaoAtendimento - 1].mensagemAdicional;
    }

    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '80%',
      height: '95%',
      maxWidth: 'none',
      maxHeight: 'none',
      closeOnNavigation: true,
      data: {
          titulo: "Pareceres",
          componente: "atendimento-pareceres",
          dados: [
            "requisito",
            localStorage.getItem("pId"), 
            this.activatedRoute.snapshot.params["codigoOrgao"], 
            this.activatedRoute.snapshot.params["codigoMenu"],
            acaoAtendimento,
            "",
            mensagem
          ]
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result.parecerFinal != null) {
        var observacao = result.parecerFinal;
        this.confirmarAtendimento(observacao, acaoAtendimento)
      }
    });
  }

  confirmarAtendimento(justificativa, acaoAtendimento) {
    this.atendimentoAlvara.protocolo = this.numeroProtocolo;
    this.atendimentoAlvara.justificativa = justificativa;
    this.atendimentoAlvara.acaoAtendimentoCodigo = acaoAtendimento;
    
    this.atendimentoService
      .registrarAtendimento(this.atendimentoAlvara)
      .pipe(take(1))
      .subscribe(retorno => {
        
        if (retorno.documento != null && retorno.documento != "") {
          Swal.fire({
            title: retorno.titulo,
            html: retorno.mensagem,
            icon: retorno.status,
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            willClose: () => {
            }
          })
          .then((result) => {
            if (!result.dismiss) {
              this.arquivoService.downloadArquivoAtendimento(retorno.documento.toString())
                .subscribe((res: any) => {
                  this.arquivoService.handleFile(res, retorno.documento.toString() + '.pdf');
                });
            }
            this.acaoDeAtendimentoAlvara.emit({ 
              acao: "atendimentoRealizado", 
              situacao: retorno.situacao, 
              historico: retorno.historico, 
              documentos: retorno.documentos
            });
          });
        }
        else {
          Swal.fire({
            title: retorno.titulo,
            html: retorno.mensagem,
            icon: retorno.status,
            willClose: () => {
              this.acaoDeAtendimentoAlvara.emit({ 
                acao: "atendimentoRealizado", 
                situacao: retorno.situacao, 
                historico: retorno.historico, 
                documentos: retorno.documentos
              });
            }
          });
        }
      })
  }

  inserirValidade(atendimentoAlvara){
    
    this.criarHtmlValidade(atendimentoAlvara);

    Swal.fire({
      icon: 'question',
      html: this.htmlValidade,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      didOpen: () => {
        this.semValidade();
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
    .then((result) => {
      if (!result.dismiss) {
        this.atendimentoAlvara.validadeDocumento = $('input[id=vencimento]').val().toString();
     
      }
    });
  }
 
  criarHtmlValidade(atendimentoAlvara: AtendimentoAlvara){
    this.htmlValidade = `
      <div class="row col-12 justify-content-center align-items-center">
        <p class="w-100">
          <b>DATA DE VALIDADE</b>
        </p>
      </div>
      <br><br>
      <div class="row col-12 justify-content-center align-items-center">
        <p class="w-100">
          Qual o vencimento para o documento que será gerado ?
        </p>
      </div>
      <div class="row col-12">
        <div class="row col-8 justify-content-center align-items-center">
          <p class="w-100">
            <input type="text" id="vencimento" name="vencimento" value="${ atendimentoAlvara.validadeDocumento }" class="swal2-input text-center" mask="00/00/0000">
          </p>
        </div>
        <div class="row col-4 justify-content-center align-items-center">
          <p class="w-100">
            <input type="checkbox" id="semValidade" name="semValidade" value="" > Sem validade
          </p>
        </div>
      </div>
    `;
  }
  
  semValidade() {
    $("input[id=vencimento]").mask("00/00/0000");
    $("input[id=semValidade]").on("click", function(){
      if ($("input[id=semValidade]:checked").length) {
        $("input[id=vencimento]").val("INDETERMINADO");
        $("input[id=vencimento]").prop("disabled", true);
      } else if (!$("input[id=semValidade]:checked").length) {
        $("input[id=vencimento]").val(null);
        $("input[id=vencimento]").prop("disabled", false);
      }
    });
  }


}
