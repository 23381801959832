import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormQuestionarioComponent } from "../../form-questionario/form-questionario.component";
import { Pergunta } from "src/app/model/pergunta";
import { Protocolo } from 'src/app/model/protocolo';
import { InformacaoQuestionarioComponent } from '../../informacoes-cadastro/informacao-questionario/informacao-questionario.component';

@Component({
  selector: 'app-atendimento-questionario',
  templateUrl: './atendimento-questionario.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoQuestionarioComponent implements OnInit {

  @Input() informacoesQuestionarios: boolean = false;
  @Input() titulo: string;
  @Input() perguntas: Array<Pergunta>;
  @Input() protocolo: Protocolo;
  @Input() semCardHeader: boolean = false;
  carregado: boolean = false;

  @ViewChild(FormQuestionarioComponent)
  formQuestionario: FormQuestionarioComponent;

  @ViewChild("informacaoQuestionarioConsultaPrevia")
  informacaoQuestionarioConsultaPrevia: InformacaoQuestionarioComponent;

  @ViewChild("informacaoQuestionarioConsultaPreviaEvento")
  informacaoQuestionarioConsultaPreviaEvento: InformacaoQuestionarioComponent;

  @ViewChild("informacaoQuestionarioDadosCadastrais")
  informacaoQuestionarioDadosCadastrais: InformacaoQuestionarioComponent;

  @ViewChild("informacaoQuestionarioDadosCadastraisEvento")
  informacaoQuestionarioDadosCadastraisEvento: InformacaoQuestionarioComponent;

  @ViewChild("informacaoQuestionarioRecadastro")
  informacaoQuestionarioRecadastro: InformacaoQuestionarioComponent;

  @ViewChild("informacaoQuestionarioEncerramento")
  informacaoQuestionarioEncerramento: InformacaoQuestionarioComponent;  

  constructor() {}

  ngOnInit(): void {
    if (this.perguntas != null) {
      this.formQuestionario.perguntas = this.perguntas;
      this.formQuestionario.montagemDePerguntas();
    }
    setTimeout(() => {
      if (this.informacoesQuestionarios) {
        if (this.informacaoQuestionarioConsultaPrevia != null) {
          this.informacaoQuestionarioConsultaPrevia.perguntasUsuario = this.protocolo.viabilidade.perguntasUsuario;
        }
        if (this.informacaoQuestionarioConsultaPreviaEvento != null) {
          this.informacaoQuestionarioConsultaPreviaEvento.perguntasUsuario = this.protocolo.viabilidade.perguntasUsuario;
        }
        if (this.informacaoQuestionarioDadosCadastrais != null) {
          this.informacaoQuestionarioDadosCadastrais.perguntasUsuario = this.protocolo.fic.perguntasUsuario;
        }
        if (this.informacaoQuestionarioDadosCadastraisEvento != null) {
          this.informacaoQuestionarioDadosCadastraisEvento.perguntasUsuario = this.protocolo.fic.perguntasUsuario;
        }
        if (this.informacaoQuestionarioRecadastro != null) {
          this.informacaoQuestionarioRecadastro.perguntasUsuario = this.protocolo.fic.perguntasUsuario;
        }
        if (this.informacaoQuestionarioEncerramento != null) {
          this.informacaoQuestionarioEncerramento.perguntasUsuario = this.protocolo.encerramento.perguntasUsuario;
        }
      }
    }, 10);
  }

  preencher(pergunta) {
    this.formQuestionario.carregarPerguntas(pergunta);
  }

  montagemDePerguntas(perguntas) {
    this.perguntas = perguntas;
    this.formQuestionario.perguntas = perguntas;
    this.formQuestionario.montagemDePerguntas();
  }

  perguntasCarregadas(event) {
    this.carregado = event.carregado;
    this.formQuestionario.inserirRespostas(this.perguntas);
  }

}
