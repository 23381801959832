import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Embasamento } from '../../model/embasamento';

@Injectable({ providedIn: 'root' })
export class EmbasamentoService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarPorCodigo(cliente, codigo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/embasamento/buscarPorCodigo/${ cliente }/${ codigo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }

    salvar(embasamento: Embasamento) {
        return this.http.post<any>( `${ environment.icadUrl }/embasamento/salvar`, JSON.stringify(embasamento),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
          )
    }

    buscarConfiguracaoEmbasamento(cliente) {
        return this.http.get<any>(
        `${ environment.icadUrl }/embasamento/buscarConfiguracaoEmbasamento/${ cliente }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
            }),
        }
        );
    }
          
}