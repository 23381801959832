
import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import Swal from "sweetalert2";
import { takeUntil, take } from 'rxjs/operators';

import { ArquivoService } from 'src/app/services/arquivo/arquivo.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-atualizacao-oficio',
  templateUrl: './atualizacao-oficio.component.html'
})
export class AtualizacaoOficioComponent implements OnInit {

  private unsubscribe = new Subject<void>();

  files: Set<File>;
  formData: FormData;

  currentUser: any;

  carregamentoDeArquivo: boolean;

  constructor(private arquivoService: ArquivoService,
    private authenticationService: AuthenticationService,
    private router: Router) {
    this.currentUser = this.authenticationService.currentUserValue;
  
    this.formData = new FormData();
    this.formData.append('file', "");
    this.formData.append('fileName', "");
    this.formData.append('faq', "");
  }

  ngOnInit(): void {
  }

  acaoBotaoVoltar() {
    if(this.carregamentoDeArquivo){
      this.carregamentoDeArquivo = false
      location.reload();
    }else{
      this.router.navigate(["/operacao/oficio"]);
    }
    
  }

  carregarArquivo(event){
    this.carregamentoDeArquivo = true;
  }

  upload(event) {
    if (this.arquivos(event)) {
      this.arquivoService
        .uploadProcessamentoLote(this.formData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(arquivo => {
          Swal.fire({
            title: "Anexado",
            html: `Caro <b>${this.currentUser.nome}</b>, você anexou o arquivo: <b>${this.formData.get("fileName")}</b>, com sucesso.`,
            icon: "success",
          })
        });

    }
  }

  arquivos(event) {
    this.formData = new FormData();
    const arquivosSelecionados = <FileList>event.srcElement.files;
    this.files = new Set();


    for (let i = 0; i < arquivosSelecionados.length; i++) {
      if (arquivosSelecionados[i].size < 0) {
        Swal.fire({
          title: "",
          html: `Caro <b>${this.currentUser.nome}</b>, não foi possível anexar o arquivo: <b>${this.formData.get("fileName")}</b>`,
          icon: "error",
        });

        return false;
      }

      this.files.add(arquivosSelecionados[i]);
      this.formData.append('file', arquivosSelecionados[i], arquivosSelecionados[i].name);
      this.formData.append('fileName', arquivosSelecionados[i].name);
    }

    return true;
  }
}
