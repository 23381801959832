<div #alert class="alert alert-{{type}}" role="alert" [ngClass]="{'alert-dismissible': dismiss}">
  <div class="row">
    <div class="row col-1 align-items-center justify-content-center">
      <svg class="icone">
          <use
              attr.xlink:href="assets/sprite.svg#{{ 
                type == 'success' ? 'alert-circle' : 
                type == 'warning' ? 'alert-triangle' : 
                type == 'danger' ? 'alert-octagon' : 
                type == 'info' ? 'info' : '' }}"
          ></use>
      </svg>
    </div>
    <div class="row col-11 align-items-center linhas">
      <ng-content></ng-content>
      <button *ngIf="dismiss" (click)="dismissAlert(alert)" type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    </div>
  </div>
</div>
