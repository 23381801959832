import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";

import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { Constantes } from "src/app/model/constantes";
import { FormRequerenteComponent } from "src/app/pages/outros/form-requerente/form-requerente.component";
import { FormLocalizacaoComponent } from "src/app/pages/outros/form-localizacao/form-localizacao.component";
import { FormAtividadeComponent } from "src/app/pages/outros/form-atividade/form-atividade.component";
import { FormQuestionarioComponent } from "src/app/pages/outros/form-questionario/form-questionario.component";
import { FormResumoComponent } from "src/app/pages/outros/form-resumo/form-resumo.component";
import { Configuracao } from "src/app/model/configuracao";
import { Servico } from "src/app/model/servico";
import { MatStepper } from "@angular/material/stepper";
import { Pergunta } from "src/app/model/pergunta";
import { Protocolo } from "src/app/model/protocolo";
import { Processo } from "src/app/model/processo";
import { Documento } from "src/app/model/documento";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import { ComprovanteConsultaPrevia } from "src/app/components/pdf/comprovanteConsultaPrevia";
import { PerguntaResposta } from "src/app/model/perguntaResposta";
import { Location } from "@angular/common";
import { AtividadeService } from "src/app/services/atividade/atividade.service";


@Component({
    selector: "app-abertura-empresa-mei",
    templateUrl: "./abertura-empresa-mei.component.html"
})
export class AberturaEmpresaMeiComponent implements OnInit {
    
    tipoEmpresa: string;
    informativo: string;
    stepper: MatStepper;
    passoAnterior: number;
    passoAtual: number;
    configuracao: Configuracao;
    processo: Processo;
    tipoProcesso = Constantes.PROCESSO;
    servicos: Array<Servico>;
    protocolo: Protocolo;
    protocoloAtual: Protocolo;

    // Formulários
    formAbertura: FormGroup;
    formularioRequerente: FormGroup;
    formularioLocalizacao: FormGroup;
    formularioAtividade: FormGroup;
    formularioQuestionario: FormGroup;
    formularioResumo: FormGroup;

    @ViewChild(FormRequerenteComponent)
    formRequerente: FormRequerenteComponent;

    @ViewChild(FormLocalizacaoComponent)
    formLocalizacao: FormLocalizacaoComponent;

    @ViewChild(FormAtividadeComponent)
    formAtividade: FormAtividadeComponent;

    @ViewChild(FormQuestionarioComponent)
    formQuestionario: FormQuestionarioComponent;

    @ViewChild(FormResumoComponent)
    formResumo: FormResumoComponent;

    // Botões
    btnAnteriorLocalizacao: string = "Anterior";
    btnAvancarLocalizacao: string = "Avançar";

    constructor(
        private location: Location,
        private router: Router,
        private protocoloService: ProtocoloService,
        private atividadeService: AtividadeService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute) {
              
        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];

        this.protocolo = new Protocolo();
        this.protocolo.cliente = localStorage.getItem("pId");
        this.protocolo.viabilidade.perguntasUsuario = new PerguntaResposta();

        this.formAbertura = this.formBuilder.group({
            controleValidade: ["", [Validators.required]]
        });

        this.informativo = Constantes.INFORMATIVO_ABERTURA_EMPRESA_MEI[0];

        setTimeout(() => {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionario = this.formQuestionario.formulario;
            this.formularioResumo = this.formResumo.formulario;
        }, 0);

        this.processo = new Processo();

        this.configuracao = JSON.parse(localStorage.getItem('user')).configuracao;
        this.configuracao.processos
            .forEach(processo => {
                var tipoProcesso = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ? "O" : "S";

                if (processo.codigo === "A" && processo.tipo === tipoProcesso && processo.tipoPessoa == Constantes.TIPO_PESSOA.Juridica) {
                    this.processo.cliente = processo.cliente;
                    this.processo.codigo = processo.codigo;
                    this.processo.descricao = processo.descricao;
                    this.processo.protocolo = processo.protocolo;
                    this.processo.tipo = processo.tipo;
                    this.protocolo.processo = this.processo;
                    this.servicos = processo.servicos;

                    if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
                        this.processo.tipo = Constantes.TIPO_EMPRESA.Mei;
                        processo.servicos.forEach(servico => {
                            if (servico.codigo == 1) {
                                this.servicos = [servico];
                            }
                        });
                    }
                } 
            }
        );
    }

    ngOnInit(): void {
        this.passoAnterior = 0;
        this.passoAtual = 0;
        this.protocolo.protocolo = this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "";

        if (this.protocolo.protocolo != "") {
            this.carregarDadosConsultaPrevia();
        }
    }

    carregarDadosConsultaPrevia() {
        this.protocoloService
            .detalharConsultaPrevia(this.protocolo.cliente, this.protocolo.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                this.protocoloAtual = retorno;
                setTimeout(() => {
                    this.carregarCriticas();
                }, 10);
            })
    }

    carregarCriticas() {
        this.preencherCamposRequerente();
        this.preencherCamposEndereco();
        this.preencherCamposAtividade();
        this.preencherCamposQuestionario();
    }

    preencherCamposRequerente() {
        this.formRequerente.preencherCamposCriticas(this.protocoloAtual);
    }

    preencherCamposEndereco() {
        this.formLocalizacao.preencherCamposCriticas(this.protocoloAtual);
    }

    preencherCamposAtividade() {
        this.formAtividade.preencherCamposCriticas(this.protocoloAtual);
    }
    
    preencherCamposQuestionario() {
        this.formQuestionario.perguntasRespondidasOutrosProcessos = this.protocoloAtual.viabilidade.perguntasUsuario.perguntas;
        this.formQuestionario.bloqueadoPorCriticas = true;
    }

    verificaValidacaoFormulario(formGroup: FormGroup) {
        
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });
    }

    renomearBotaoLocalizacao() {
        this.btnAnteriorLocalizacao = "Anterior";
        this.btnAvancarLocalizacao = "Avançar";

        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (!this.formLocalizacao.preenchimentoInicial) {
                this.btnAnteriorLocalizacao = "Cancelar";
                this.btnAvancarLocalizacao = "Confirmar";
            }
        }
    }

    voltarPassoLocalizacao() {
        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (this.formLocalizacao.preenchimentoInicial) {
                this.stepper.previous();
            }
            else {
                this.formLocalizacao.toggle();
            }
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoLocalizacao() {
        this.formularioLocalizacao = this.formLocalizacao.formulario;
        this.verificaValidacaoFormulario(this.formLocalizacao.formulario);
        if (this.formLocalizacao.validarFormulario())
            this.stepper.next();
        
    }

    avancarPassoAtividade() {
        this.formAtividade.preencher();

        this.protocolo.viabilidade.atividadesExercidas = this.formAtividade.atividadesExercidas;
        this.protocolo.viabilidade.atividadesNaoExercidas = this.formAtividade.atividadesNaoExercidas;

        this.atividadeService
            .validarAtividadesEDescritores(this.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                if (retorno.status == "error") {
                    Swal.fire({
                        title: retorno.titulo,
                        html: retorno.mensagem,
                        icon: retorno.status,
                        willClose: () => {}
                    })
                }
                else {
                    if (this.formAtividade.validarFormulario()) {
                        this.stepper.next();
                    }
                }
            });
    }

    avancarPassoQuestionario() {
        this.verificaValidacaoFormulario(this.formQuestionario.formulario);
        if (this.formQuestionario.validarFormulario()) {
            this.formularioQuestionario.get("controleValidade").setValue(true);
            this.stepper.next();
        }
    }

    mudancaPasso(event, passos) {
        
        if (this.stepper == null) {
            this.stepper = passos;
        } 

        if (this.passoAtual == 0) {
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioQuestionario = this.formQuestionario.formulario;
            this.formularioResumo = this.formResumo.formulario;
        }

        if ( this.passoAtual == 2 ) {
            this.formAtividade.validarFormulario();
        }
        
        if (event.selectedIndex == 1) {
            this.formLocalizacao.formulario.get("controleValidade").setValue("");
        }
        if (event.selectedIndex == 2) {
            this.formAtividade.formulario.get("controleValidade").setValue("");
        }
        if ( event.selectedIndex == 3) {
            this.formQuestionario.formulario.get("controleValidade").setValue("");
            this.formQuestionario.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;

            let pergunta = new Pergunta();
            pergunta.cliente = localStorage.getItem("pId");
            pergunta.tipo = this.tipoEmpresa == Constantes.TIPO_EMPRESA.Empresa_Evento ? Constantes.TIPO_DE_PERGUNTA.Viabilidade_Evento : Constantes.TIPO_DE_PERGUNTA.Viabilidade;
            pergunta.atividades = this.formAtividade.atividadesSelecionadas;

            if (this.protocoloAtual != null) {
                this.preencherCamposQuestionario();
            }

            this.formQuestionario.carregarPerguntas(pergunta);

            this.formularioQuestionario = this.formQuestionario.formulario;
        }
        
        this.informativo = Constantes.INFORMATIVO_ABERTURA_EMPRESA_MEI[event.selectedIndex];
        this.passoAtual = event.selectedIndex;
        this.passoAnterior = event.previouslySelectedIndex;

        this.montarAbertura(event);
    }

    montarAbertura(event) {
        if ( event.previouslySelectedIndex == 0) {

            let documento = new Documento();
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documento.nome = "CPF";
            documento.numero = localStorage.getItem("userId");

            this.protocolo.solicitante.documentos = [documento];
            
            this.formRequerente.preencher();
            this.protocolo.requerente = this.formRequerente.requerente;
            this.protocolo.registro = this.formRequerente.registro;
            this.processo.servicos = [this.formRequerente.servicoEscolhido];
            
            // Ajuste de fluxo - os fluxos são fixos no antigo Icad - ver forma de deixar dinâmico
            // Código de serviço tem que ser 4 para Mei
            if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Mei) {
                this.processo.servicos[0].codigo = 4;
            }
        }
        else if (event.previouslySelectedIndex == 1) {
            this.protocolo.viabilidade.enderecos = [
                this.formLocalizacao.enderecoEmpresarial,
                this.formLocalizacao.enderecoCorrespondencia,
            ];
            this.formResumo.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
        }
        else if (event.previouslySelectedIndex == 2) {
            this.formAtividade.preencher();

            this.protocolo.viabilidade.atividadesExercidas = this.formAtividade.atividadesExercidas;
            this.protocolo.viabilidade.atividadesNaoExercidas = this.formAtividade.atividadesNaoExercidas;

            this.protocolo.viabilidade.tipoUnidade = this.formAtividade.tipoUnidade.value;
            this.protocolo.viabilidade.tipoUnidadeAuxiliar = this.formAtividade.tipoUnidadeAuxiliar.value;
            
            this.protocolo.viabilidade.formasAtuacao = this.formAtividade.formasAtuacao;
            this.protocolo.viabilidade.descricaoAtividadeEconomica = this.formAtividade.descricaoAtividadeEconomica.value;
        }
        else if (event.previouslySelectedIndex == 3) {
            this.formQuestionario.preencher();
            this.protocolo.viabilidade.perguntasUsuario = this.formQuestionario.perguntasRespostas;
        }
        this.formResumo.protocolo = this.protocolo;
        this.formResumo.preencher();
    }

    gerarAbertura() {
        this.formResumo.preencherAnexos();
        
        if (!this.formResumo.termoDeAceite.value) {
            Swal.fire("", "Para que a solicitação seja finalizada ,você devera estar de acordo com o termo de responsabilidade.", "info")
            return false;
        }

        this.protocoloService
            .gerarViabilidade(this.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    willClose: () => {
                        if (retorno.redirecionamento != null) {
                            this.router.navigate([retorno.redirecionamento]);
                        }
                    }
                })
            });
    }


}