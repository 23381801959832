import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { Component, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";

import Swal, { SweetAlertOptions } from "sweetalert2";
import { Endereco } from "src/app/model/endereco";
import { EnderecoRegra } from "src/app/model/enderecoRegra";
import { FormEnderecoComponent } from "../form-endereco/form-endereco.component";
import { EnderecoService } from "src/app/services/endereco/endereco.service";
import { take } from "rxjs/operators";
import { Constantes } from "src/app/model/constantes";
import { Protocolo } from '../../../model/protocolo';

@Component({
    selector: "app-form-localizacao",
    templateUrl: "./form-localizacao.component.html",
    styleUrls: ["./form-localizacao.component.scss"]
})
export class FormLocalizacaoComponent implements OnInit {

    @Output() renomearBotao = new EventEmitter();

    tipoEmpresa: string;

    formulario: FormGroup;
    
    exibeFormularioEndereco: boolean;
    montarRegra: boolean;

    preenchimentoInicial: boolean;

    enderecoEmpresarial: Endereco;
    enderecoEmpresarialRegra: EnderecoRegra;
    enderecoEmpresarialEscolhido: string;
    enderecoEmpresarialBloqueado: boolean;
    enderecoEmpresarialBloqueadoEditar: boolean;
    enderecoEmpresarialPreenchido: boolean;
    enderecoEmpresarialPreenchimento: boolean;

    enderecoCorrespondencia: Endereco;
    enderecoCorrespondenciaRegra: EnderecoRegra;
    enderecoCorrespondenciaEscolhido: string;
    enderecoCorrespondenciaBloqueado: boolean;
    enderecoCorrespondenciaPreenchido: boolean;
    enderecoCorrespondenciaPreenchimento: boolean;

    emitirErro: boolean;
    emitirCritica: boolean;
    mensagemCritica: string;

    @ViewChild(FormEnderecoComponent)
    formEndereco: FormEnderecoComponent;

    textoEnderecoApenasCorrespondencia: string;

    textoTipoEstabelecimento: string;

    bloqueadoPorCriticas: boolean = false;

    protocolo: Protocolo;

    constructor(
        private formBuilder: FormBuilder,
        private enderecoService: EnderecoService,
        private activatedRoute: ActivatedRoute) {

        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
            apenasCorrespondencia: [''],
        });

        this.preenchimentoInicial = true;

        this.exibeFormularioEndereco = true;
        this.enderecoEmpresarial = new Endereco();
        this.enderecoEmpresarialPreenchido = false;
        this.enderecoEmpresarialBloqueado = false;
        this.enderecoEmpresarialBloqueadoEditar = false;
        this.enderecoEmpresarialPreenchimento = true;

        this.enderecoCorrespondencia = new Endereco();
        this.enderecoCorrespondenciaPreenchido = false;
        this.enderecoCorrespondenciaBloqueado = false;
        this.enderecoCorrespondenciaPreenchimento = false;

        this.emitirCritica = false;
        this.mensagemCritica = "";
        this.emitirErro = false;

        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];
        
        if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Fisica ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo ||
            this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Autonomo
        ) 
        {
            this.textoEnderecoApenasCorrespondencia = "Não possuo estabelecimento fixo, autônomo não estabelecido.";
            this.textoTipoEstabelecimento = "Caro contribuinte, caso seja um Autônomo Não Estabelecido, selecione Editar no Endereço do Meu Negócio e selecione a opção Não possuo estabelecimento fixo, autônomo não estabelecido.";
        }
        else {
            this.textoEnderecoApenasCorrespondencia = "Não possuo estabelecimento fixo, endereço apenas para correspondência.";
            this.textoTipoEstabelecimento = "";
        }

    }

    ngOnInit(): void {

        this.enderecoEmpresarialRegra = new EnderecoRegra();
        this.enderecoCorrespondenciaRegra = new EnderecoRegra();
        
        this.enderecoService
            .buscarRegra(localStorage.getItem('pId'), 2)
            .pipe(take(1))
            .subscribe( (dados) => {
                this.enderecoEmpresarialRegra = dados;
            },
            (err) => {
                console.log('err', err);
            }
        );

        this.enderecoService
            .buscarRegra(localStorage.getItem('pId'), 3)
            .pipe(take(1))
            .subscribe( (dados) => {
                this.enderecoCorrespondenciaRegra = dados;
            },
            (err) => {
                console.log('err', err);
            }
        );
        
    }

    acaoAdicionarEnderecoEmpresarial() {
        this.formEndereco.enderecoRegra = this.enderecoEmpresarialRegra;
        this.toggle();
        this.enderecoEmpresarialPreenchimento = true;
        this.formEndereco.limparCampos();
        this.formEndereco.liberaBloqueiaCampos();
        this.formEndereco.tipoBusca.setValue("");
        this.formEndereco.tipoBusca.markAsUntouched();
    }
    
    acaoAdicionarEnderecoCorrespondencia() {
        this.formEndereco.enderecoRegra = this.enderecoCorrespondenciaRegra;
        this.toggle();
        this.enderecoCorrespondenciaPreenchimento = true;
        this.formEndereco.limparCampos();
        this.formEndereco.liberaBloqueiaCampos();
        this.formEndereco.tipoUrbanoRural.setValue("1");
        this.formEndereco.tipoBusca.setValue("");
        this.formEndereco.tipoBusca.markAsUntouched();
    }

    editarEnderecoEmpresarial() {
        this.formEndereco.enderecoRegra = this.enderecoEmpresarialRegra;
        this.toggle();
        this.enderecoEmpresarialPreenchimento = true;
        this.formEndereco.preencherCampos(this.enderecoEmpresarial);
        this.formEndereco.liberaBloqueiaCampos();
        setTimeout(() => {
            this.formEndereco.tipoBusca.setValue(this.enderecoEmpresarial.tipoBusca);
            this.formEndereco.tipoBusca.markAsUntouched();
        }, 1);
    }

    editarEnderecoCorrespondencia() {
        this.formEndereco.enderecoRegra = this.enderecoCorrespondenciaRegra;
        this.toggle();
        this.enderecoCorrespondenciaPreenchimento = true;
        this.formEndereco.preencherCampos(this.enderecoCorrespondencia);
        this.formEndereco.liberaBloqueiaCamposPorCritica(this.protocolo);
        setTimeout(() => {
            this.formEndereco.tipoBusca.setValue(this.enderecoCorrespondencia.tipoBusca);
            this.formEndereco.tipoBusca.markAsUntouched();
        }, 1);
    }

    limparEnderecoEmpresarial() {
        this.enderecoEmpresarial = new Endereco();
        this.enderecoEmpresarialEscolhido = "";
        this.enderecoEmpresarialPreenchido = false;
        if (this.apenasCorrespondencia.value) {
            this.limparEnderecoCorrespondencia();
            this.apenasCorrespondencia.reset();
        }
    }

    limparEnderecoCorrespondencia() {
        this.enderecoCorrespondencia = new Endereco();
        this.enderecoCorrespondenciaEscolhido = "";
        this.enderecoCorrespondenciaPreenchido = false;
    }

    acaoUtilizarParaCorrespondencia() {
        this.emitirErro = false;
        if (this.enderecoEmpresarialPreenchido) {
            this.preencherEnderecoCorrespondencia(this.enderecoEmpresarial);
        }
        else {
            Swal.fire("", "Informe um endereço para o seu negócio.", "error")
        }
    }

    acaoApenasCorrespondencia() {
        if (this.apenasCorrespondencia.value) {
            var textoHtml = "";

            if (this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Evento ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Autonomo_Fisica ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Baixa_Autonomo ||
                this.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo) 
            {
                textoHtml = 'Você selecionou <strong>"Autônomo Não Estabelecido"</strong>, ou seja, não haverá porta comercial; não haverá atendimento ao público, não haverá uso de publicidade; não haverá estoque de matéria prima e/ou mercadoria.<br>Confirma essa informação?';
            }
            else {
                textoHtml = 'Você selecionou <strong>"Endereço Somente para Correspondência"</strong>, ou seja, não haverá porta comercial; não haverá atendimento ao público, não haverá uso de publicidade; não haverá estoque de matéria prima e/ou mercadoria.<br>Confirma essa informação?';
            }

            Swal.fire({
                title: "", 
                html: textoHtml,
                icon: 'question',
                showCancelButton: true})
                .then((result) => {
                    if (!result.value) {
                        this.apenasCorrespondencia.setValue(false);
                    }
                });
        }
    }

    toggle() {
        this.exibeFormularioEndereco = !this.exibeFormularioEndereco;
        this.emitirErro = false;
        if (this.protocolo != null) {
            if (this.protocolo.criticas.filter(item => { return item.passo == "endereco" }).length) {
                this.emitirCritica = !this.exibeFormularioEndereco;
            }
        }
        this.enderecoEmpresarialPreenchimento = false;
        this.enderecoCorrespondenciaPreenchimento = false;
        this.renomearBotao.emit({ renomear: true});
    }

    preencherEndereco(endereco: Endereco) {
        if (this.enderecoEmpresarialPreenchimento) {
            this.preencherEnderecoEmpresarial(endereco);
        }
        else if (this.enderecoCorrespondenciaPreenchimento) {
            this.preencherEnderecoCorrespondencia(endereco);
        }
    }

    preencherEnderecoEmpresarial(endereco: Endereco) {
        Object.assign(this.enderecoEmpresarial, endereco);

        this.enderecoEmpresarialEscolhido = 
        `
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Cep</strong> 
            </div>
            <div class="col-9" id="lbl-cep-empresarial">
                ${this.enderecoEmpresarial.cep}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Inscrição</strong> 
            </div>
            <div class="col-9" id="lbl-imobiliario-empresarial">
                ${this.enderecoEmpresarial.inscricaoFormatada}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Endereço</strong> 
            </div>
            <div class="col-9" id="lbl-logradouro-empresarial">
                ${this.enderecoEmpresarial.tipoLogradouro} ${this.enderecoEmpresarial.logradouro}, ${this.enderecoEmpresarial.numero}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Compl.</strong>
            </div>
            <div class="col-9" id="lbl-complemento-empresarial">
                ${this.enderecoEmpresarial.complemento}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Ref.</strong>
            </div>
            <div class="col-9" id="lbl-pontoReferencia-empresarial">
                ${this.enderecoEmpresarial.pontoReferencia}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Bairro</strong>
            </div>
            <div class="col-9" id="lbl-bairro-empresarial">
                ${this.enderecoEmpresarial.bairro}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Cidade</strong>
            </div>
            <div class="col-9" id="lbl-cidade-empresarial">
                ${this.enderecoEmpresarial.cidade}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Estado</strong>
            </div>
            <div class="col-9" id="lbl-estado-empresarial">
                ${this.enderecoEmpresarial.estado}
            </div>
        </div>
            
        `;

        this.enderecoEmpresarialPreenchido = true;

        if (this.apenasCorrespondencia.value) {
            this.acaoUtilizarParaCorrespondencia();
        }
    }

    preencherEnderecoCorrespondencia(endereco: Endereco) {
        Object.assign(this.enderecoCorrespondencia, endereco);
        this.enderecoCorrespondencia.tipoEndereco = Constantes.TIPO_DE_ENDERECO.Correspondencia;
        
        this.enderecoCorrespondenciaEscolhido = 
        `
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Cep</strong> 
            </div>
            <div class="col-9" id="lbl-cep-correspondencia">
                ${this.enderecoCorrespondencia.cep}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Endereço</strong> 
            </div>
            <div class="col-9" id="lbl-logradouro-correspondencia">
                ${this.enderecoCorrespondencia.tipoLogradouro} ${this.enderecoCorrespondencia.logradouro}, ${this.enderecoCorrespondencia.numero}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Compl.</strong>
            </div>
            <div class="col-9" id="lbl-complemento-correspondencia">
                ${this.enderecoCorrespondencia.complemento}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Bairro</strong>
            </div>
            <div class="col-9" id="lbl-bairro-correspondencia">
                ${this.enderecoCorrespondencia.bairro}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Cidade</strong>
            </div>
            <div class="col-9" id="lbl-cidade-correspondencia">
                ${this.enderecoCorrespondencia.cidade}
            </div>
        </div>
        <div class="form-row linha-grid-divisao">
            <div class="col-3">
                <strong>Estado</strong>
            </div>
            <div class="col-9" id="lbl-estado-correspondencia">
                ${this.enderecoCorrespondencia.estado}
            </div>
        </div>
        `;

        this.enderecoCorrespondenciaPreenchido = true;

    }

    preencherCamposCriticas(protocolo: Protocolo) {
        this.protocolo = protocolo;
        this.apenasCorrespondencia.disable();
        this.exibeFormularioEndereco = !protocolo.viabilidade.enderecos.length;
        
        protocolo.viabilidade.enderecos.forEach( endereco => {
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Empresarial) {
                this.preenchimentoInicial= false;
                this.preencherEnderecoEmpresarial(endereco);
            }
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Correspondencia) {
                this.preencherEnderecoCorrespondencia(endereco);
            }
        })

        if (protocolo.criticas.length && protocolo.criticas.filter(item => { return item.passo == "endereco" }).length) {
            this.emitirCritica = true;
            this.mensagemCritica += "Existem críticas para endereço, favor editar os endereços disponíveis para visualizar as críticas.<br>";
        }
        else {
            this.enderecoEmpresarialBloqueado = true;
            this.enderecoEmpresarialBloqueadoEditar = true;
        }
        
    }

    validarFormulario() {
        this.formEndereco.emitirErro = false;
        this.emitirErro = false;

        this.controleValidade.setValue("");
        // Está no momento de preenchimento do formulario de endereço
        if (this.exibeFormularioEndereco) {
            // Se o formulário de endereço estiver válido, salva o endereço escolhido.
            if(this.formEndereco.formulario.valid) {
                this.formEndereco.preencherEndereco();
                this.preencherEndereco(this.formEndereco.endereco);
                this.toggle();
                this.preenchimentoInicial = false;
            }
            else {
                this.formEndereco.verificaValidacaoFormulario(this.formEndereco.formulario);
                this.formEndereco.emitirErro = true;
                Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");
            }
        }
        else {
        
            this.formulario.markAsTouched();

            // Verifica se foi informado o endereço empresarial
            if (this.enderecoEmpresarialPreenchido && this.enderecoCorrespondenciaPreenchido) {
                this.formulario.get("controleValidade").setValue(true);
                return true
            }
            else {
                this.emitirErro = true;
            }
            
        }
        return false;
    }

    get controleValidade() {
        return this.formulario.get("controleValidade");
    }
    set controleValidade(controleValidade) {
        this.formulario.get("controleValidade").setValue(controleValidade);
    }

    get apenasCorrespondencia() {
        return this.formulario.get("apenasCorrespondencia");
    }  
    set apenasCorrespondencia(apenasCorrespondencia) {
        this.formulario.get("apenasCorrespondencia").setValue(apenasCorrespondencia);
    }

    

}