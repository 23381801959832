import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Atendimento } from 'src/app/model/atendimento';
import { AtendimentoConfiguracao } from 'src/app/model/atendimentoConfiguracao';
import { Fase } from 'src/app/model/fase';
import { Pendencia } from 'src/app/model/pendencia';
import { Protocolo } from 'src/app/model/protocolo';
import { Servico } from 'src/app/model/servico';
import { ProtocoloService } from 'src/app/services/operacao/protocolo.service';
import { take } from "rxjs/operators";
import { AtendimentoAvaliadorComponent } from '../atendimento-protocolo/atendimento-avaliador.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Constantes } from '../../../../model/constantes';

import { AtendimentoRequisitoComponent } from '../atendimento-protocolo/atendimento-requisito.component';
import { AtendimentoQuestionarioComponent } from '../atendimento-protocolo/atendimento-questionario.component';
import { Pergunta } from '../../../../model/pergunta';
import { AtendimentoService } from '../../../../services/operacao/atendimento.service';
import { EmpresaService } from '../../../../services/empresa/empresa.service';
import { MatDialog } from '@angular/material/dialog';
import { Empresa } from '../../../../model/empresa';
import { AtendimentoPendenciaModalComponent } from '../atendimento-pendencia/atendimento-pendencia-modal.component';
import { InformacaoRequerenteComponent } from '../../informacoes-cadastro/informacao-requerente/informacao-requerente.component';
import { InformacaoIdentificacaoComponent } from '../../informacoes-cadastro/informacao-identificacao/informacao-identificacao.component';
import { InformacaoResponsavelComponent } from '../../informacoes-cadastro/informacao-responsavel/informacao-responsavel.component';
import { InformacaoLocalizacaoComponent } from '../../informacoes-cadastro/informacao-localizacao/informacao-localizacao.component';
import { AtendimentoAtividadeComponent } from '../atendimento-protocolo/atendimento-atividade.component';
import { ViewportScroller } from '@angular/common';
import { AtendimentoEmpresaRequisitosComponent } from './atendimento-empresa-requisitos.component';
import { Login } from 'src/app/model/login';
import { InformacaoDocumentoComponent } from '../../informacoes-cadastro/informacao-documento/informacao-documento.component';
import { InformacaoProtocolosComponent } from '../../informacoes-cadastro/informacao-protocolos/informacao-protocolos.component';
import { InformacaoRedeSimComponent } from '../../informacoes-cadastro/informacao-rede-sim/informacao-rede-sim.component';
import { AtendimentoZoneamentoComponent } from '../atendimento-protocolo/atendimento-zoneamento.component';
import { AtendimentoAlvaraComponent } from '../atendimento-alvara/atendimento-alvara.component';
import { LoginService } from '../../../../services/login/login.service';
import { SocioService } from 'src/app/services/socio/socio.service';
import { ZonaService } from '../../../../services/zona/zona.service';
import { DocumentoService } from '../../../../services/documento/documento.service';
import { ProtocoloLinhaDoTempo } from 'src/app/model/protocoloLinhaDoTempo';
import { CicloDeVidaComponent } from '../../linha-do-tempo/ciclo-de-vida.component';
import { AnexarComponent } from '../../anexar/anexar.component';
import { ArquivoService } from 'src/app/services/arquivo/arquivo.service';
import { Anexo } from 'src/app/model/anexo';
import { Despacho } from 'src/app/model/despacho';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { Viabilidade } from '../../../../model/viabilidade';
import { AtendimentoSuspensaoComponent } from '../atendimento-suspensao/atendimento-suspensao.component';
import { PerguntaService } from '../../../../services/pergunta/pergunta.service';
import { PerguntaResposta } from '../../../../model/perguntaResposta';
import { InformacaoRedeSimPerguntasComponent } from '../../informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.component';
import { AtendimentoReativacaoComponent} from '../atendimento-reativacao/atendimento-reativacao.component';


@Component({
  selector: 'app-atendimento-empresa',
  templateUrl: './atendimento-empresa.component.html',
  styleUrls: ['./atendimento-empresa.component.scss']
})
export class AtendimentoEmpresaComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};

  tipoEmpresa: string = "E";
  tipoProcesso = Constantes.PROCESSO;
  processo: string;

  numeroInscricao: string;
  numeroProtocolo: string;
  empresa: Empresa;
  protocolosLinhaDoTempo: Array<ProtocoloLinhaDoTempo>;
  pergutasRespondidasAuditor: PerguntaResposta;
  anexos: Array<Anexo>;
  listaDeProtocolos: Set<string>;

  atendimento: Atendimento;
  atendimentoConfiguracao: AtendimentoConfiguracao;
  permitirAtendimento: boolean;
  consultaProcesso: boolean;
  meusNegocios: boolean;
  modulo: string;

  orgao: string;
  
  @ViewChild(AtendimentoAvaliadorComponent)
  atendimentoAvaliador: AtendimentoAvaliadorComponent;
    
  @ViewChild(InformacaoRequerenteComponent)
  informacaoRequerente: InformacaoRequerenteComponent;
  
  @ViewChild(InformacaoRedeSimComponent)
  informacaoRedeSim: InformacaoRedeSimComponent;

  @ViewChild(InformacaoRedeSimPerguntasComponent)
  informacaoRedeSimPerguntas: InformacaoRedeSimPerguntasComponent;

  @ViewChild(InformacaoIdentificacaoComponent)
  informacaoIdentificacao: InformacaoIdentificacaoComponent;

  @ViewChild(InformacaoResponsavelComponent)
  informacaoResponsavel: InformacaoResponsavelComponent;
    
  @ViewChild(InformacaoLocalizacaoComponent)
  informacaoLocalizacao: InformacaoLocalizacaoComponent;

  @ViewChild(AtendimentoZoneamentoComponent)
  atendimentoZoneamento: AtendimentoZoneamentoComponent;

  @ViewChild(AtendimentoAtividadeComponent)
  atendimentoAtividade: AtendimentoAtividadeComponent;

  @ViewChild(AtendimentoRequisitoComponent)
  atendimentoRequisito: AtendimentoRequisitoComponent;  

  @ViewChild(InformacaoDocumentoComponent)
  informacaoDocumento: InformacaoDocumentoComponent;

  @ViewChild(InformacaoProtocolosComponent)
  informacaoProtocolos: InformacaoProtocolosComponent;

  @ViewChild("atendimentoQuestionario")
  atendimentoQuestionario: AtendimentoQuestionarioComponent;

  @ViewChild(AtendimentoEmpresaRequisitosComponent)
  atendimentoEmpresaRequisitos: AtendimentoEmpresaRequisitosComponent;

  @ViewChild(AnexarComponent)
  anexarComponent: AnexarComponent;

  @ViewChild(CicloDeVidaComponent)
  cicloDeVidaComponent: CicloDeVidaComponent;

  @ViewChild(AtendimentoAlvaraComponent)
  atendimentoAlvara: AtendimentoAlvaraComponent;

  @ViewChild(AtendimentoSuspensaoComponent)
  atendimentoSuspensao: AtendimentoSuspensaoComponent;

  @ViewChild(AtendimentoReativacaoComponent)
  atendimentoReativacao: AtendimentoReativacaoComponent;
  

  informacaoRequerenteCarregado: boolean = false;
  informacaoRedeSimCarregado: boolean = false;
  informacaoRedeSimPerguntasCarregado: boolean = false;
  informacaoIdentificacaoCarregado: boolean = false;
  informacaoResponsavelCarregado: boolean = false;
  informacaoLocalizacaoCarregado: boolean = false;
  atendimentoZoneamentoCarregado: boolean = false;
  atendimentoAtividadeCarregado: boolean = false;
  atendimentoRequisitoCarregado: boolean = false;
  informacaoDocumentoCarregado: boolean = false;
  informacaoProtocolosCarregado: boolean = false;
  atendimentoQuestionarioCarregado: boolean = false;
  atendimentoAnexoCarregado: boolean = false;
  atendimentoCicloDeVidaCarregado: boolean = false;
  moduloTaxaLiberado: boolean = false;
  empresaSuspensa: boolean = false;

  codigoMenu: string;

  constructor(
    private document: Document,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private empresaService: EmpresaService,
    private protocoloService: ProtocoloService,
    private socioService: SocioService,
    private loginService: LoginService,
    private zonaService: ZonaService,
    private documentoService: DocumentoService,
    private arquivoService: ArquivoService,
    private atendimentoService: AtendimentoService,
    private authenticationService: AuthenticationService,
    private perguntaService: PerguntaService,
    public dialog: MatDialog,
    private viewportScroller: ViewportScroller) {
    
    this.empresa = new  Empresa();
    this.listaDeProtocolos = new Set();
    
    this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"] != null ? this.activatedRoute.snapshot.params["protocolo"] : "";
    this.numeroInscricao = this.activatedRoute.snapshot.params["inscricao"];

    this.atendimento = new Atendimento();
    
    this.atendimentoConfiguracao = new AtendimentoConfiguracao();
    this.atendimentoConfiguracao.processo.servicos.push(new Servico());
    this.atendimentoConfiguracao.processo.servicos[0].fases = new Array<Fase>();
    this.atendimentoConfiguracao.processo.servicos[0].fases.push(new Fase());

    this.meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
    this.consultaProcesso = this.activatedRoute.snapshot.data["menu"] == "consulta-processo";
    this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !this.meusNegocios);

    this.modulo = this.activatedRoute.snapshot.data["menu"];

    this.orgao = this.activatedRoute.snapshot.params["codigoOrgao"];

    this.viewportScroller.setOffset([0,144]);

    authenticationService.currentAxisValue.item.forEach(item => {
      if (item.nome == "moduloTaxa") {
        this.moduloTaxaLiberado = true;
      }
    });

    this.codigoMenu = this.activatedRoute.snapshot.params["codigoMenu"];

  }

  ngOnInit(): void {
    if (this.numeroInscricao != "") {
      this.atendimentoService.movimentarEmpresa(this.numeroInscricao).pipe(take(1)).subscribe();
    }

    this.buscarPorInscricaoSomenteAtendimento();
    this.buscarPorInscricaoDadosBasicos();
    this.buscarResponsavel();
    this.buscarDocumentos();
    this.buscarProtocolosEmpresa();
    this.buscarCicloDeVida();
    this.buscarAnexos();
  }

  buscaSuspensaoPorInscricao(cliente,inscricao){
    this.empresaService
          .buscaSuspensaoPorInscricao(
              cliente,  
              inscricao)
          .pipe(take(1))
          .subscribe( (retorno: Empresa) => {
            if(retorno){
              this.empresaSuspensa = true;
              this.informacaoDocumento.empresaSuspensa = true;
            }
          })
  }

  goToAnchor(ancora) {
    this.viewportScroller.scrollToAnchor(ancora);
    this.document.querySelectorAll(".nav-link").forEach( item => this.renderer.removeClass(item, "active"));
    this.renderer.addClass(this.document.querySelector("." + ancora), "active");    
  }

  buscarPorInscricaoSomenteAtendimento() {
    this.empresaService
      .buscarPorInscricaoSemLicenciamentosSomenteAtendimento(
        localStorage.getItem("pId"), 
        this.numeroInscricao,
        this.activatedRoute.snapshot.params["codigoOrgao"], 
        this.activatedRoute.snapshot.params["codigoMenu"]
      )
      .pipe(take(1))
      .subscribe( retorno => {
        this.empresa.protocolos = retorno[0].protocolos;
        this.empresa.viabilidade.requisitos = retorno[0].viabilidade.requisitos;

        this.empresa.encerramento = retorno[0].encerramento;
        
        this.atendimentoAvaliador.protocolo = new Protocolo();
        this.atendimentoAvaliador.protocolo.inscricao = this.empresa.inscricao;
        this.atendimentoAvaliador.protocolo.protocolo = this.empresa.protocolo.toString();

        var protocoloAuxiliar = this.empresa.protocolos.filter( item => { return item.protocolo == this.empresa.protocolo.toString() });
        if (protocoloAuxiliar.length) {

          this.atendimentoAvaliador.protocolo.data = protocoloAuxiliar[0].data;
        }

        this.atendimentoAvaliador.protocolo.processo = this.empresa.protocolos[0].processo;
        this.atendimentoAvaliador.protocolo.viabilidade = this.empresa.viabilidade;
        this.atendimentoAvaliador.protocolo.viabilidade.atendimentos = retorno[0].viabilidade.atendimentos;
        
        Object.assign(this.atendimentoConfiguracao, retorno[1]);
        this.buscarRequerente();
        this.buscarZonas();
        this.buscarUsos();
        this.buscarParaAtendimento();

        if (this.modulo == 'suspensao') {
          this.preencherAtendimentoSuspensao();
        }

        if (this.modulo == 'reativacao'){
          this.preencherAtendimentoReativacao();
        }

      });

  }

  async buscarPorInscricaoDadosBasicos() {
    await 
      this.empresaService
        .buscarPorInscricaoDadosBasicos(localStorage.getItem("pId"), this.numeroInscricao)
        .pipe(take(1))
        .subscribe( (retorno: Empresa) => {
          this.preencherDadosBasicos(retorno);
        })
  }

  async buscarRequerente() {
    await 
      this.loginService
        .buscarRequerente(localStorage.getItem("pId"), this.empresa.protocolos[0].protocolo)
        .pipe(take(1))
        .subscribe( retorno => {
          this.empresa.protocolos[0].requerente = retorno;
        })
  }

  async buscarResponsavel() {
    await 
      this.socioService
        .buscarPorInscricao(localStorage.getItem("pId"), this.numeroInscricao)
        .pipe(take(1))
        .subscribe( retorno => {
          this.empresa.fic.socios = retorno;
        })
  }

  async buscarZonas() {
    await 
      this.zonaService
        .buscarZonasPorProtocolo(localStorage.getItem("pId"), this.empresa.protocolos[0].protocolo)
        .pipe(take(1))
        .subscribe( retorno => {
          this.empresa.viabilidade.zonas = retorno;
        })
  }

  async buscarUsos() {
    await 
      this.zonaService
        .buscarUsosPorProtocolo(localStorage.getItem("pId"), this.empresa.protocolos[0].protocolo)
        .pipe(take(1))
        .subscribe( retorno => {
          this.empresa.viabilidade.usos = retorno;
        })
  }

  async buscarDocumentos() {
    await 
      this.documentoService
        .buscarPorInscricao(localStorage.getItem("pId"), this.numeroInscricao)
        .pipe(take(1))
        .subscribe( retorno => {
          this.empresa.documentos = retorno;
        })
  }

  async buscarProtocolosEmpresa() {
    await 
      this.protocoloService
        .acompanharPorInscricaoParaAtendimento(localStorage.getItem("pId"), this.numeroInscricao)
        .pipe(take(1))
        .subscribe( retorno => {
          this.empresa.protocolosAcompanhamento = retorno.concluidos;
        })
  }

  async buscarCicloDeVida() {
    await this.empresaService
        .cicloDeVida(localStorage.getItem("pId"), this.numeroInscricao)
        .pipe(take(1))
        .subscribe( retorno => {
            this.protocolosLinhaDoTempo = retorno;
        })

  }

  async buscarAnexos() {
    await this.arquivoService
      .buscarPorInscricao(localStorage.getItem("pId"), this.numeroInscricao)
      .pipe(take(1))
      .subscribe( anexos => {
        this.anexos = anexos;
        anexos.forEach( (anexo: Anexo) => {
            this.listaDeProtocolos.add(anexo.protocolo);
        });
      });
  }

  buscarParaAtendimento() {
    this.processo = "D";
    this.buscarPendenciasParaAtendimento();
  }

  async buscarPendenciasParaAtendimento() {
    await this.atendimentoService
      .buscarPendenciasParaAtendimentoPorOrgao(this.processo, this.orgao)
      .pipe(take(1))
      .subscribe( (retorno: Array<Pendencia>) => {
        if (this.atendimentoEmpresaRequisitos != null) {  
          this.atendimentoEmpresaRequisitos.pendencias = retorno;
        }
        
        this.buscarOrgaosParaDespacho();
      });
  }

  async buscarOrgaosParaDespacho() {
    await this.atendimentoService
      .buscarOrgaosParaDespacho(this.processo)
      .pipe(take(1))
      .subscribe( (retorno: Array<Despacho>) => {
        if (this.atendimentoEmpresaRequisitos != null) {
          this.atendimentoEmpresaRequisitos.despachos = retorno;
        }

        this.preencher();
      });
  }

  clickAccordion(accordionAcionado, carregar) {
    if (!carregar) {
      return false;
    }

    setTimeout(() => {      
      if (accordionAcionado == "accordionRequerente") {
        this.preencherInformacaoRequerente();
      }
      if (accordionAcionado == "accordionRedeSim") {
        this.preencherInformacaoRedeSim();
        this.preencherInformacaoRedeSimPerguntas();
      }
      if (accordionAcionado == "accordionIdentificacao") {
        this.preencherInformacaoIdentificacao();
      }
      if (accordionAcionado == "accordionResponsavel") {
        this.preencherInformacaoResponsavel();
      }
      if (accordionAcionado == "accordionLocalizacao") {
        this.preencherInformacaoLocalizacao();
      }
      if (accordionAcionado == "accordionZoneamento") {
        this.preencherAtendimentoZoneamento();
      }
      if (accordionAcionado == "accordionAtividades") {
        this.preencherAtendimentoAtividade();
      }
      if (accordionAcionado == "accordionRequisitos") {
        this.preencherAtendimentoRequisito();
      }
      if (accordionAcionado == "accordionDocumentos") {
        this.preencherInformacaoDocumento();
      }
      if (accordionAcionado == "accordionProtocolos") {
        this.preencherInformacaoProtocolos();
      }
      if (accordionAcionado == "accordionQuestionario") {
        this.preencherAtendimentoQuestionario();
      }
      if (accordionAcionado == "accordionAnexo") {
        this.preencherAtendimentoAnexo();
      }
      if (accordionAcionado == "accordionCicloDeVida") {
        this.preencherAtendimentoCicloDeVida();
      }
      if (accordionAcionado == "accordionAtendimento") {
        this.preencher();
      }
    }, 10);
  }

  // ---------------------------------------------------------
  // Preenchimentos para carregar os dados para atendimento
  // ---------------------------------------------------------
  preencher() {
    this.tipoEmpresa = this.atendimentoConfiguracao.processo.tipoPessoa == Constantes.TIPO_PESSOA.Fisica ? "A" : "E";
    
    if (this.atendimentoEmpresaRequisitos != null) {
      this.atendimentoEmpresaRequisitos.empresa = this.empresa;
      this.atendimentoEmpresaRequisitos.requisitos = this.empresa.viabilidade.requisitos;
      this.atendimentoEmpresaRequisitos.licenciamentos = this.atendimentoConfiguracao.licenciamentos;
    }

    this.preencherAtendimentoAvaliador();

  }

  preencherDadosBasicos(dadosBasicos) {
    this.empresa.redeSim = dadosBasicos.redeSim;

    this.empresa.registro = dadosBasicos.registro;

    this.empresa.viabilidade.tipoUnidade = dadosBasicos.viabilidade.tipoUnidade;
    this.empresa.viabilidade.tipoUnidadeAuxiliar = dadosBasicos.viabilidade.tipoUnidadeAuxiliar;
    this.empresa.viabilidade.nomeUnidadeAuxiliar = dadosBasicos.viabilidade.nomeUnidadeAuxiliar;
    this.empresa.viabilidade.observacaoUnidadeAuxiliar = dadosBasicos.viabilidade.observacaoUnidadeAuxiliar;
    this.empresa.viabilidade.descricaoAtividadeEconomica = dadosBasicos.viabilidade.descricaoAtividadeEconomica;
    this.empresa.viabilidade.formasAtuacao = dadosBasicos.viabilidade.formasAtuacao;
    this.empresa.viabilidade.enderecos = dadosBasicos.viabilidade.enderecos;
    this.empresa.viabilidade.atividadesExercidas = dadosBasicos.viabilidade.atividadesExercidas;
    this.empresa.viabilidade.atividadesNaoExercidas = dadosBasicos.viabilidade.atividadesNaoExercidas;

    this.empresa.fic.nomeEmpresa = dadosBasicos.fic.nomeEmpresa;
    this.empresa.fic.nomeFantasia = dadosBasicos.fic.nomeFantasia;
    this.empresa.fic.tipoUnidade = dadosBasicos.fic.tipoUnidade;
    this.empresa.fic.cnpjFilial = dadosBasicos.fic.cnpjFilial;
    this.empresa.fic.objetoSocial = dadosBasicos.fic.objetoSocial;
    this.empresa.fic.telefones = dadosBasicos.fic.telefones;
    this.empresa.fic.emails = dadosBasicos.fic.emails;
    this.empresa.fic.sites = dadosBasicos.fic.sites;
  }

  preencherAtendimentoSuspensao() {
    this.atendimentoSuspensao.protocolo = new Protocolo();
    this.atendimentoSuspensao.protocolo.inscricao = this.numeroInscricao;
    this.atendimentoSuspensao.protocolo.protocolo = this.numeroProtocolo;
    
    var protocoloAuxiliar = this.empresa.protocolos.filter( item => { return item.protocolo == this.numeroProtocolo });
    if (protocoloAuxiliar.length) {
      this.atendimentoAvaliador.protocolo.data = protocoloAuxiliar[0].data;
      this.atendimentoSuspensao.protocolo.requerente = protocoloAuxiliar[0].requerente;
    }
    
    this.atendimentoSuspensao.protocolo.processo = this.atendimentoConfiguracao.processo;
    this.atendimentoSuspensao.protocolo.viabilidade = this.empresa.viabilidade;
    this.atendimentoSuspensao.protocolo.registro = this.empresa.registro;
    this.atendimentoSuspensao.protocolo.redeSim = this.empresa.redeSim;
    this.atendimentoSuspensao.protocolo.fic = this.empresa.fic;
    this.atendimentoSuspensao.protocolo.documentos = this.empresa.documentos;
    this.atendimentoSuspensao.protocolo.encerramento = this.empresa.encerramento;

    this.atendimentoSuspensao.preencherParaAtendimento();
  }

  preencherAtendimentoReativacao() {
    this.atendimentoReativacao.protocolo = new Protocolo();
    this.atendimentoReativacao.protocolo.inscricao = this.numeroInscricao;
    this.atendimentoReativacao.protocolo.protocolo = this.numeroProtocolo;
    
    var protocoloAuxiliar = this.empresa.protocolos.filter( item => { return item.protocolo == this.numeroProtocolo });
    if (protocoloAuxiliar.length) {
      this.atendimentoAvaliador.protocolo.data = protocoloAuxiliar[0].data;
      this.atendimentoReativacao.protocolo.requerente = protocoloAuxiliar[0].requerente;
    }
    
    this.atendimentoReativacao.protocolo.processo = this.atendimentoConfiguracao.processo;
    this.atendimentoReativacao.protocolo.viabilidade = this.empresa.viabilidade;
    this.atendimentoReativacao.protocolo.registro = this.empresa.registro;
    this.atendimentoReativacao.protocolo.redeSim = this.empresa.redeSim;
    this.atendimentoReativacao.protocolo.fic = this.empresa.fic;
    this.atendimentoReativacao.protocolo.documentos = this.empresa.documentos;
    this.atendimentoReativacao.protocolo.encerramento = this.empresa.encerramento;

    this.atendimentoReativacao.preencherParaAtendimento();
  }

  preencherAtendimentoAvaliador() {
    this.atendimentoAvaliador.protocolo = new Protocolo();
    this.atendimentoAvaliador.protocolo.inscricao = this.numeroInscricao;
    this.atendimentoAvaliador.protocolo.protocolo = this.numeroProtocolo;

    var protocoloAuxiliar = this.empresa.protocolos.filter( item => { return item.protocolo == this.numeroProtocolo });
    if (protocoloAuxiliar.length) {
      this.atendimentoAvaliador.protocolo.data = protocoloAuxiliar[0].data;
    }

    this.atendimentoAvaliador.protocolo.processo = this.atendimentoConfiguracao.processo;
    this.atendimentoAvaliador.protocolo.viabilidade = this.empresa.viabilidade;

  }

  preencherInformacaoRequerente() {
    this.informacaoRequerente.protocolo = new Protocolo();
    if (this.empresa.protocolos.length) {
      this.informacaoRequerente.protocolo.requerente = this.empresa.protocolos[0].requerente;
    }
    this.informacaoRequerente.protocolo.registro = this.empresa.registro;
    this.informacaoRequerenteCarregado = true;
  }

  preencherInformacaoRedeSim() {    
    if (this.empresa.redeSim.protocoloRedeSim != "") {
      this.informacaoRedeSim.protocolo = new Protocolo();
      this.informacaoRedeSim.protocolo.requerente = this.empresa.protocolos[0].requerente;
      this.informacaoRedeSim.protocolo.redeSim = this.empresa.redeSim;
    }
    this.informacaoRedeSimCarregado = true;
  }

  preencherInformacaoRedeSimPerguntas() {    
    
  }

  preencherInformacaoIdentificacao() {
    this.informacaoIdentificacao.protocolo = new Protocolo();
    this.informacaoIdentificacao.protocolo.registro = this.empresa.registro;
    this.informacaoIdentificacao.protocolo.fic = this.empresa.fic;
    this.informacaoIdentificacaoCarregado = true;
  }

  preencherInformacaoResponsavel() {    
    this.informacaoResponsavel.protocolo = new Protocolo();
    this.informacaoResponsavel.protocolo.fic = this.empresa.fic;
    this.informacaoResponsavelCarregado = true;
  }

  preencherInformacaoLocalizacao() {
    this.informacaoLocalizacao.protocolo = new Protocolo();
    this.informacaoLocalizacao.protocolo.viabilidade = this.empresa.viabilidade;
    this.informacaoLocalizacaoCarregado = true;
  }

  preencherAtendimentoZoneamento() {
    this.atendimentoZoneamento.preencher(this.atendimentoConfiguracao, this.empresa);
    this.atendimentoZoneamentoCarregado = true;
  }

  preencherAtendimentoAtividade() {
    this.atendimentoAtividade.protocolo = new Protocolo();
    this.atendimentoAtividade.protocolo.fic = this.empresa.fic;
    this.atendimentoAtividade.protocolo.viabilidade = this.empresa.viabilidade;
    this.atendimentoAtividade.permitirAtendimento = false;
    
    this.atendimentoConfiguracao.viabilidade.atividadesExercidas = this.empresa.viabilidade.atividadesExercidas;
    this.atendimentoConfiguracao.viabilidade.atividadesNaoExercidas = this.empresa.viabilidade.atividadesNaoExercidas;
    this.atendimentoAtividade.preencher(this.atendimentoConfiguracao);

    this.atendimentoAtividadeCarregado = true;
  }

  preencherAtendimentoRequisito() {    
    this.atendimentoRequisito.preencher(this.atendimentoConfiguracao.viabilidade.requisitos, this.empresa);
    this.atendimentoRequisitoCarregado = true;
  }

  preencherInformacaoDocumento() {
    this.informacaoDocumento.protocolo = new Protocolo();
    this.informacaoDocumento.protocolo.documentos = this.empresa.documentos;
    this.informacaoDocumentoCarregado = true;
    this.buscaSuspensaoPorInscricao(localStorage.getItem("pId"),this.numeroInscricao);
  }

  preencherInformacaoProtocolos() {
    this.informacaoProtocolos.protocolos = this.empresa.protocolosAcompanhamento;
    this.informacaoProtocolosCarregado = true;
  }

  preencherAtendimentoQuestionario() {
    let pergunta = new Pergunta();
    pergunta.cliente = localStorage.getItem("pId");
    pergunta.codigo = this.activatedRoute.snapshot.params["codigoOrgao"];
    pergunta.processo = this.activatedRoute.snapshot.params["codigoMenuPai"];
    pergunta.tipo = Constantes.TIPO_DE_PERGUNTA.Auditor;

    if (this.atendimentoQuestionario != null) {
      this.perguntaService
        .buscarPorInscricaoEOrgaoAuditor(localStorage.getItem("pId"), this.numeroInscricao, this.orgao)
        .pipe(take(1))
        .subscribe( retorno => {
          this.atendimentoQuestionario.formQuestionario.permitirAtendimento = this.permitirAtendimento;
          this.atendimentoQuestionario.formQuestionario.permitirSalvarRespostasAuditor = this.permitirAtendimento;
          this.atendimentoQuestionario.formQuestionario.perguntasRespondidas = retorno.perguntas;
          this.atendimentoQuestionario.preencher(pergunta);
        });        
    }
    this.atendimentoQuestionarioCarregado = true;
  }

  preencherAtendimentoAnexo() {
    this.anexarComponent.anexos = this.anexos;
    this.anexarComponent.listaDeProtocolos = this.listaDeProtocolos;
    this.atendimentoAnexoCarregado = true;
  }

  preencherAtendimentoCicloDeVida() {
    this.cicloDeVidaComponent.protocolosLinhaDoTempo = this.protocolosLinhaDoTempo;
    this.cicloDeVidaComponent.inicializarVisualizacaoDosProtocolos()
    this.atendimentoCicloDeVidaCarregado = true;
  }

  atualizacaoRequisitoAdicionadoRemovido(acaoAtendimento) {
    if (this.atendimentoEmpresaRequisitos != null && acaoAtendimento.empresa != null) {
      this.atendimentoEmpresaRequisitos.requisitos = acaoAtendimento.empresa.viabilidade.requisitos;
      this.atendimentoEmpresaRequisitos.licenciamentos = acaoAtendimento.empresa.licenciamentos;
    }
  }

  atualizacaoRequisitoAtendido(acaoAtendimento) {
    if (this.atendimentoEmpresaRequisitos != null && acaoAtendimento.empresa != null) {
      this.atendimentoEmpresaRequisitos.requisitos = acaoAtendimento.empresa.viabilidade.requisitos;
    }
  }

  atualizacaoAtendimentoAlvara(acaoAtendimento) {
    if (this.atendimentoAlvara != null && acaoAtendimento.situacao != null) {
      this.atendimentoAlvara.atendimentoAlvara = acaoAtendimento.situacao;
      this.atendimentoAlvara.atendimentosAlvara = acaoAtendimento.historico;
      this.informacaoDocumento.protocolo.documentos = acaoAtendimento.documentos;
    }
  }

}
