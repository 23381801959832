import { Atividade } from './../../model/atividade';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

// import { Users } from 'src/models/users';

@Injectable({ providedIn: 'root' })
export class AtividadeService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarPorCodigo(Codigo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/atividade/buscarPorCodigo`, JSON.stringify(Codigo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarCnae(Cliente) {
        return this.http.get<any>(
            `${ environment.icadUrl }/atividade/buscarCnae/${ Cliente }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarCnaeDescritores(cnaeClasse) {
        return this.http.get<any>(
            `${ environment.icadUrl }/atividade/buscarCnaeDescritores/${ localStorage.getItem("pId") }/${ cnaeClasse }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarCbo(Cliente) {
        return this.http.get<any>(
            `${ environment.icadUrl }/atividade/buscarCbo/${ Cliente }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorProtocolo(Cliente, Protocolo, Tipo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/atividade/buscarPorProtocolo/${ Cliente }/${ Protocolo }/${ Tipo }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorValorDigitado(Cliente, TipoEmpresa, ValorDigitado) {
        return this.http.get<any>(
            `${ environment.icadUrl }/atividade/buscarPorValorDigitado/${ Cliente }/${ TipoEmpresa }/${ ValorDigitado }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }
     
    verificaTipoAtividade(atividades) {
        return this.http.get<any>(
            `${ environment.icadUrl }/atividade/verificaTipoAtividade/${ atividades }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarAtividadeEmpresaVinculoRequisito(Cliente, CodigoRequisito) {
        return this.http.get<any>(
            `${ environment.icadUrl }/atividade/buscarAtividadeEmpresaVinculoRequisito/${ Cliente }/${ CodigoRequisito }`,
            {
                headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
                'Content-Type': 'application/json',
                }),
            }
        );
    }

    validarAtividadesEDescritores(protocolo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/atividade/validarAtividadesEDescritores`, JSON.stringify(protocolo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
}
