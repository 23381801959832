export class Telefone {
    cliente: string;
    codigo: string;
    ddd: number;
    numero: number;
    tipo: number;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.ddd = 0;
        this.numero = 0;
        this.tipo = 0;
    }
}