import { Licenciamento } from './../../../../model/licenciamento';

import { PendenciaFluxo } from './../../../../model/pendenciaFluxo';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observacao } from '../../../../model/observacao';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Pendencia } from 'src/app/model/pendencia';
import { Constantes } from '../../../../model/constantes';

@Component({
  selector: 'app-atendimento-observacao',
  templateUrl: './atendimento-observacao.component.html',
  styleUrls: ['../atendimento-protocolo/atendimento-protocolo.component.scss']
})
export class AtendimentoObservacaoComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};

  formulario: FormGroup;
  permitirAtendimento: boolean;
  observacoes: Array<Observacao>;
  tituloHistorico: string = "Histórico";
  tituloObservacao: string = "Observações Gerais";

  tipoDePendenciaLicenciamento = Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO;
  tipoDePendenciaLicenciamentoDescricao = Constantes.TIPO_DE_PENDENCIA_LICENCIAMENTO_DESCRICAO;

  tipoDePendenciaStatus = Constantes.TIPO_DE_PENDENCIA_STATUS;
  tipoDePendenciaStatusDescricao = Constantes.TIPO_DE_PENDENCIA_STATUS_DESCRICAO;

  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder) {
    
      let meusProcessos = this.activatedRoute.snapshot.data["menu"] == Constantes.MENU_ATENDIMENTO.Processos;
      let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
      this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (!meusNegocios && !meusProcessos));

    this.formulario = this.formBuilder.group({
      observacao: ["", [Validators.required, Validators.maxLength(2000)]],
    });

  }

  ngOnInit(): void {
    if (!this.permitirAtendimento)
      this.observacao.disable();

  }

  preencher(observacoes: Array<Observacao>) { 
    this.observacoes = observacoes;
  }

  get observacao() {
    return this.formulario.get("observacao");
  }
  set observacao(observacao) {
    this.formulario.get("observacao").setValue(observacao);
  }

}
