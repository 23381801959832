import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { FaqSuporte } from '../../../model/faqSuporte';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Constantes } from '../../../model/constantes';
import { Notificacao } from '../../../model/notificacao';
import Swal from 'sweetalert2';
import { NotificacaoService } from '../../../services/notificacao/notificacao.service';
import { take } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: "app-enviar-email-suporte",
    templateUrl: "./enviar-email-suporte.component.html",
    styleUrls: ["./enviar-email-suporte.component.scss"]
})
export class EnviarEmailSuporteComponent implements OnInit {

    @Output() selecao = new EventEmitter();
    @Input() dados: Array<any>;
    notificacao: Notificacao;
    formulario: FormGroup;
    deEmail: string = "";
    paraEmails: string = "";
    faqSuporte: FaqSuporte;
    emitirErro: boolean;
    mensagemErro: string;

    constructor(private notificacaoService: NotificacaoService,
        private authenticationService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder) {
        
        this.faqSuporte = this.authenticationService.currentUserValue.configuracao.faqSuporte;

        this.faqSuporte.emails.forEach(email => this.paraEmails = this.paraEmails.concat(email).concat(";")) 
        this.deEmail = this.authenticationService.currentUserValue.email;

        this.formulario = this.formBuilder.group({
            corpoEmail: ["", [Validators.required, Validators.maxLength(2000)]],
            assunto: ["", [Validators.required]],
        });

    }
    
    ngOnInit(): void {
    }

    acaoBotaoEnviarEmail() {
        if (this.validarFormulario()) {
     
            this.preencher();
    
            this.notificacaoService
            .enviarComAutenticacao(this.notificacao)
            .pipe(take(1))
            .subscribe(retorno => {
                Swal.fire({
                title: retorno.titulo,
                html: retorno.mensagem,
                icon: retorno.status,
                willClose: () => {
                    if (retorno.status != "error") {
                        this.selecao.emit({ concluido: true });
                    }
                }
                })
            });
        }
    }
    
    validarFormulario() {
        this.formulario.markAllAsTouched();
    
        if (this.formulario.invalid) {
        let mensagemErro = "";
    
        if (this.assunto.invalid) {
            mensagemErro += "Favor corrigir o assunto informado.<br>"
        }
    
        if (this.corpoEmail.invalid) {
            mensagemErro += "Favor corrigir o corpo do e-mail informado.<br>"
        }
    
        if (mensagemErro === "") {
            this.emitirErro = false;
            return true;
        }
    
        Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");
    
        this.emitirErro = true;
        this.mensagemErro = mensagemErro;
    
        return false;
        }
    
        this.emitirErro = false;
        this.mensagemErro = "";
    
        return true;
    }
    
    preencher() {
        this.notificacao = new Notificacao();

        this.notificacao.origem = this.deEmail;
        this.notificacao.assunto = this.assunto.value;
        this.notificacao.corpo = this.corpoEmail.value;
        this.notificacao.tipo = Constantes.TIPO_DE_NOTIFICACAO.Email_Suporte;
    }

    get corpoEmail() {
      return this.formulario.get("corpoEmail");
    }
    set corpoEmail(corpoEmail) {
      this.formulario.get("corpoEmail").setValue(corpoEmail);
    }

    get assunto() {
      return this.formulario.get("assunto");
    }
    set assunto(assunto) {
      this.formulario.get("assunto").setValue(assunto);
    }

}