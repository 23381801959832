import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FormValidator } from "src/app/components/validators/form-validator";
import { formatToCPFOrCNPJ } from "brazilian-values";
import { Empresa } from 'src/app/model/empresa';
import { MascaraPipe } from "src/app/pipes/mascara.pipe";
import { Constantes } from '../../../model/constantes';
import { Documento } from '../../../model/documento';
import { Registro } from '../../../model/registro';
    

@Component({
    selector: "app-form-tipo-negocio",
    templateUrl: "./form-tipo-negocio.component.html",
    styleUrls: ["./form-tipo-negocio.component.scss"],
})

export class FormTipoNegocioComponent implements OnInit {

    tipoEmpresa: string;
    tipoEmpresaConstante = Constantes.TIPO_EMPRESA;
    tipoEmpresaFormulario: string;
    formulario: FormGroup;
    identificacao: Empresa;
    registro: Registro;
    @Output() acaoDeAtendimento = new EventEmitter();
    
    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private mask: MascaraPipe) {
        
        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];
        
        this.registro = new Registro();

        // remover valores
        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
            cpfCnpjEmpresa: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(18), FormValidator.cpfCnpjValido()]],
            im: ['', [Validators.required, Validators.maxLength(20)]],
            tipoNegocio: ['', [Validators.required]],
        });


    }

    ngOnInit(): void {
        
        if(this.tipoEmpresa == 'A' || this.tipoEmpresa == 'BA') {
            this.cpfCnpjEmpresa.clearValidators();
            this.cpfCnpjEmpresa.setValidators([Validators.required, Validators.maxLength(14), FormValidator.cpfCnpjValido()]);
        }
        
    }

    formatToCPFOrCNPJ(valor) {
        this.cpfCnpjEmpresa.setValue(formatToCPFOrCNPJ(valor));
    }
    formatToNumber(valor) {
        this.im.setValue(this.mask.transform(valor, 9));
    }

    acaoTipoEmpresa(){

        if(this.tipoEmpresa == Constantes.TIPO_EMPRESA.Alteracao_Oficio_Empresa){
            if(this.tipoNegocio.value == Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa){
                this.tipoEmpresaFormulario = Constantes.TIPO_EMPRESA.Empresa;
           
            }
            else if(this.tipoNegocio.value == Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei){
                this.tipoEmpresaFormulario = Constantes.TIPO_EMPRESA.Mei;
            }
            else if(this.tipoNegocio.value == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo){
                this.tipoEmpresaFormulario = Constantes.TIPO_EMPRESA.Autonomo;
            }

            this.acaoDeAtendimento.emit({ tipoEmpresaFormulario: this.tipoEmpresaFormulario });
        }

    }
 
    preencher() {
        let documento = new Documento();
        if(this.tipoEmpresa == 'A' || this.tipoEmpresa == 'BA') {
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documento.nome = "CPF";
        }
        else {
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cnpj;
            documento.nome = "CNPJ";
        }
        documento.numero = this.cpfCnpjEmpresa.value;

        let documentoMunicipal = new Documento();
        documentoMunicipal.tipo = Constantes.TIPO_DE_DOCUMENTO.Municipal;
        documentoMunicipal.nome = "Inscrição Municipal";
        documentoMunicipal.numero = this.im.value.toString().replace(/\D/g,"");

        this.registro.documentos = [documento, documentoMunicipal];
    }
    
    get controleValidade() {
        return this.formulario.get("controleValidade");
    }
    set controleValidade(controleValidade) {
        this.formulario.get("controleValidade").setValue(controleValidade);
    }

    get cpfCnpjEmpresa() {
        return this.formulario.get("cpfCnpjEmpresa");
    }  
    set cpfCnpjEmpresa(cpfCnpjEmpresa) {
        this.formulario.get("cpfCnpjEmpresa").setValue(cpfCnpjEmpresa);
    }
    
    get im() {
        return this.formulario.get("im");
    }  
    set im(im) {
        this.formulario.get("im").setValue(im);
    }

    get tipoNegocio(){
        return this.formulario.get("tipoNegocio");
    }
    set tipoNegocio(tipoNegocio){
        this.formulario.get("tipoNegocio").setValue(tipoNegocio);
    }

}