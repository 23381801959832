<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card">
        <div class="card-header">
            <div class="row justify-content-center align-items-center">
                <div class="row col-12" >
                    <strong>{{ tituloHistorico }}</strong>
                </div>
            </div>
        </div>
        <div *ngIf="observacoes != null" class="card-body p-0" >
            <div class="row col-12 ps div-observacoes p-0" [perfectScrollbar]="config">
                <div *ngFor="let observacao of observacoes; let i = index" class="row col-12 p-0 pt-2 pb-2 linha-grid-divisao">
                    <div class="row col-12 p-0 justify-content-start align-items-start">
                        <div class="row col-12 row-linha-observacao">
                            <div class="col-2 p-0">
                                <p class="meta-text mb-0 w-100" >
                                    <b>Situação</b>
                                </p>
                            </div>
                            <div class="col-10 p-0">
                                <p class="meta-text mb-0 w-100">
                                    <button type="button" 
                                        class="btn btn-sm btn-informacao-atividade pt-0 pb-0 mb-0"
                                        [class.btn-success]="observacao.avaliacao == 'S' || observacao.avaliacao == tipoDePendenciaLicenciamento.Deferido || observacao.avaliacao == tipoDePendenciaLicenciamento.Documento_Liberado || observacao.avaliacao == tipoDePendenciaLicenciamento.Documento_Gerado || observacao.avaliacao == tipoDePendenciaStatus.Respondida || observacao.avaliacao == tipoDePendenciaStatus.Aprovada"
                                        [class.btn-secondary]="observacao.avaliacao == '' || observacao.avaliacao == tipoDePendenciaLicenciamento.Bloqueado || observacao.avaliacao == tipoDePendenciaStatus.Nao_Cadastrado"
                                        [class.btn-danger]="observacao.avaliacao == 'N' || observacao.avaliacao == 'C' || observacao.avaliacao == tipoDePendenciaLicenciamento.Indeferido || observacao.avaliacao == tipoDePendenciaLicenciamento.Documento_Bloqueado || observacao.avaliacao == tipoDePendenciaStatus.Cancelada || observacao.avaliacao == tipoDePendenciaStatus.Rejeitada"
                                        [class.btn-dark]="observacao.avaliacao == 'R' || observacao.avaliacao == tipoDePendenciaLicenciamento.Pendente_Orgao || observacao.avaliacao == tipoDePendenciaStatus.Devolvida"
                                        [class.btn-warning]="observacao.avaliacao == 'P' || observacao.avaliacao == tipoDePendenciaLicenciamento.Pendente_Usuario || observacao.avaliacao == tipoDePendenciaLicenciamento.Licenca_Prorrogada || observacao.avaliacao == tipoDePendenciaStatus.Aguardando_Resposta">
                                        {{ 
                                            observacao.avaliacao == 'S' ? 'DEFERIDO' : 
                                            observacao.avaliacao == 'N' ? 'INDEFERIDO' :
                                            observacao.avaliacao == 'P' ? 'PENDENTE' :
                                            observacao.avaliacao == 'C' ? 'CANCELADO' : 
                                            observacao.avaliacao == 'R' ? 'RESPOSTA' : 
                                            tipoDePendenciaLicenciamentoDescricao[observacao.avaliacao] != null ? (tipoDePendenciaLicenciamentoDescricao[observacao.avaliacao] | uppercase) : 
                                            tipoDePendenciaStatusDescricao[observacao.avaliacao] != null ? (tipoDePendenciaStatusDescricao[observacao.avaliacao] | uppercase) : 'NÃO AVALIADO'
                                        }}
                                    </button>{{ observacao.data }} - {{ observacao.login.nome == '' ? 'NÃO INFORMADO' : observacao.login.nome }}
                                </p>
                            </div>
                            <div class="col-2 p-0">
                                <p class="meta-text mb-0 w-100" >
                                    <b>Fase</b>
                                </p>
                            </div>
                            <div class="col-10 p-0">
                                <p class="meta-text mb-0 w-100" [innerHTML]="observacao.processo">
                                </p>
                            </div>
                        </div>
                        <div class="row col-12 row-linha-observacao">
                            <div class="col-2 p-0">
                                <p class="meta-text mb-0 w-100" >
                                    <b>Observação</b>
                                </p>
                            </div>
                            <div class="col-10 p-0">
                                <p class="meta-text mb-0 w-100">
                                    {{ observacao.observacao }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pt-4 pb-4" *ngIf="!observacoes.length">
                <p class="card-text text-center">
                    Não existe histórico do processo.
                </p>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="permitirAtendimento">
        <div class="card-header">
            <div class="row justify-content-center align-items-center">
                <div class="row col-12" >
                    <strong>{{ tituloObservacao }}</strong>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="form-group col-12 p-0 mt-1" >
                <textarea #observacaoTextArea
                    class="form-control form-control-sm"
                    [class.is-invalid]="observacao.invalid && observacao.touched"
                    formControlName="observacao"
                    id="observacao"
                    name="observacao"
                    maxlength="2000"
                    cols="30" 
                    rows="5"
                ></textarea>
                <div class="mb-1">
                    <span 
                        class="erro" 
                        *ngIf="observacao.invalid && observacao.touched && observacao.errors.required"
                    >Campo obrigatório</span>
                    <span 
                        class="erro" 
                        *ngIf="observacao.invalid && observacao.touched && observacao.errors.maxlength"
                    >A descrição deve conter até 2000 caracteres.</span>
                    <span class="quantidade-caracter">
                        Quantidade de Caracteres: {{ observacao.value.length }} / 2000
                    </span>
                </div>
            </div>
        </div>
    </div>
</form>