<div class="row">
    <div class="col-3 justify-content-start">
        <svg class="logo">
            <use
                class="logo_icon"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#_logo_iCad_Completo"
            ></use>
        </svg>
    </div>
    <div 
        *ngIf="propriedade.componente !== 'login'"     
        class="col-9 justify-content-end"
    >
        
        <mat-dialog-actions align="end">
            <button
                mat-button
                mat-dialog-close
                class="btn btn-danger btn-fechar"
                type="button"
                title="Fechar"
            >
            <svg class="iconeFecharPopUp">
                <use
                  attr.xlink:href="assets/sprite.svg#{{ 'x' }}"
                ></use>
            </svg> 
            </button>
        </mat-dialog-actions>
    </div>
</div>
<mat-dialog-content
    [class.espacoConteudoGrid]="propriedade.componente !== 'login'"
>
    <div 
        *ngIf="propriedade.componente === 'pop-up'" 
        [innerHtml]="propriedade.dados[0]">
    </div>
    <app-grid-endereco 
        *ngIf="propriedade.componente === 'grid-endereco'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-grid-endereco>
    <app-grid-atividade 
        *ngIf="propriedade.componente === 'grid-atividade'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-grid-atividade>
    <app-login 
        *ngIf="propriedade.componente === 'login'" 
        popUp="true"
        (autenticado)="onSelecao($event)"
    ></app-login>
    <app-atendimento-pendencia 
        *ngIf="propriedade.componente === 'atendimento-pendencia'" 
        [dados]="propriedade.dados"
        (pendenciaRespondida)="onSelecao($event)"
    ></app-atendimento-pendencia>
    <app-historico-requisito 
        *ngIf="propriedade.componente === 'historico-requisito'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-historico-requisito>
    <app-lista-empresa
        *ngIf="propriedade.componente === 'lista-empresa'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-lista-empresa>
    <app-lista-protocolo
        *ngIf="propriedade.componente === 'lista-protocolo'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-lista-protocolo>
    <app-atendimento-pendenciar
        *ngIf="propriedade.componente === 'atendimento-pendenciar'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-atendimento-pendenciar>
    <app-atendimento-despachar
        *ngIf="propriedade.componente === 'atendimento-despachar'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-atendimento-despachar>
    <app-lista-empresa-cnpj
        *ngIf="propriedade.componente === 'lista-empresa-cnpj'" 
        [dados]="propriedade.dados"
    ></app-lista-empresa-cnpj>
    <app-anexar
        *ngIf="propriedade.componente === 'anexar'" 
        [dados]="propriedade.dados"
    ></app-anexar>
    <app-atendimento-protocolo 
        *ngIf="propriedade.componente === 'detalhar-protocolo'"
        [permitirAtendimento]="propriedade.dados[0]"
        [numeroProtocolo]="propriedade.dados[1]"
        [numeroInscricao]="propriedade.dados[2]"
        (licenciamentoRespondido)="onSelecao($event)"
    ></app-atendimento-protocolo>
    <app-grid-permissoes 
        *ngIf="propriedade.componente === 'grid-permissoes'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-grid-permissoes>
    <app-form-requisito-list 
        *ngIf="propriedade.componente === 'form-requisito-list'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-form-requisito-list>
    <app-form-pergunta-list 
        *ngIf="propriedade.componente === 'form-pergunta-list'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-form-pergunta-list>
    <app-time-line-protocolo
        *ngIf="propriedade.componente === 'timeline-protocolo'" 
        [dados]="propriedade.dados"
    ></app-time-line-protocolo>
    <app-consultar-protocolo
        *ngIf="propriedade.componente === 'consultar-protocolo'" 
    ></app-consultar-protocolo>
    <app-time-line-empresa
        *ngIf="propriedade.componente === 'time-line-empresa'" 
        [dados]="propriedade.dados"
    ></app-time-line-empresa>
    <app-grid-logradouro-bairro 
        *ngIf="propriedade.componente === 'grid-logradouro-bairro'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-grid-logradouro-bairro>
    <app-linha-do-tempo
        *ngIf="propriedade.componente === 'linha-do-tempo'" 
        [numeroProtocolo]="propriedade.dados[0]"
    ></app-linha-do-tempo>
    <app-ciclo-de-vida
        *ngIf="propriedade.componente === 'ciclo-de-vida'" 
        [numeroInscricao]="propriedade.dados[0]"
    ></app-ciclo-de-vida>
    <app-atendimento-questionario 
        *ngIf="propriedade.componente === 'questionario'" 
        [titulo]="propriedade.dados[0]"
        [informacoesQuestionarios]="propriedade.dados[1]"
        [protocolo]="propriedade.dados[2]">
    </app-atendimento-questionario>
    <app-informacao-rede-sim 
        *ngIf="propriedade.componente === 'redeSim'" 
        [protocolo]="propriedade.dados[0]"
        [tipoEmpresa]="propriedade.dados[1]"
        [semCardHeader]="propriedade.dados[2]"
        [executarBuscaDadosRedeSim]="propriedade.dados[3]">
    </app-informacao-rede-sim>    
    <app-analise-entorno
        *ngIf="propriedade.componente === 'analise-entorno'" 
        [tipoDeNegocio]="propriedade.dados[0]"
        [endereco]="propriedade.dados[1]"
        [atividades]="propriedade.dados[2]"></app-analise-entorno>
    <app-enviar-email-suporte
        *ngIf="propriedade.componente === 'enviar-email-suporte'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-enviar-email-suporte>
    <app-atendimento-pareceres
        *ngIf="propriedade.componente === 'atendimento-pareceres'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-atendimento-pareceres>
    <app-grid-usuarios 
        *ngIf="propriedade.componente === 'grid-usuarios'" 
        [dados]="propriedade.dados"
        (selecao)="onSelecao($event)"
    ></app-grid-usuarios>
    <app-informacao-rede-sim-perguntas
        *ngIf="propriedade.componente === 'informacao-rede-sim-perguntas'" 
        [protocolo]="propriedade.dados[0]"
        [tipoEmpresa]="propriedade.dados[1]"
        [semCardHeader]="propriedade.dados[2]"
        [executarBuscaDadosRedeSim]="propriedade.dados[3]"></app-informacao-rede-sim-perguntas>
    <app-memoria-de-calculo
        *ngIf="propriedade.componente === 'memoria-de-calculo'" 
        [taxaLancamento]="propriedade.dados[0]"
        [taxaLancamentoParcela]="propriedade.dados[1]"
        [tipoDeMemoria]="propriedade.dados[2]"
    ></app-memoria-de-calculo>  
    <app-grid-parcelas-tfe
        *ngIf="propriedade.componente === 'grid-parcelas-tfe'" 
        [requisito]="propriedade.dados[0]"
    ></app-grid-parcelas-tfe>    
    <app-grid-arquivos-vinculos 
        *ngIf="propriedade.componente === 'grid-arquivos-vinculos'" 
        [titulo]="propriedade.dados[0]"
        [tipo]="propriedade.dados[1]"
        [codigo]="propriedade.dados[2]"
        (selecao)="onSelecao($event)"
    ></app-grid-arquivos-vinculos>
</mat-dialog-content>