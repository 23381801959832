import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from '../../../components/alert/alert.module';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TimeLineHorizontalComponent } from './time-line-horizontal/time-line-horizontal.component';
import { TimeLineVerticalComponent } from './time-line-vertical/time-line-vertical.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeLineProtocoloComponent } from './time-line-protocolo.component';
import { TimeLineEmpresaComponent } from './time-line-empresa.component';


@NgModule({
  declarations: [
    TimeLineHorizontalComponent,
    TimeLineVerticalComponent,
    TimeLineProtocoloComponent,
    TimeLineEmpresaComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    NgxMaskModule.forRoot(),
    PerfectScrollbarModule,
  ],
  exports: [
    TimeLineHorizontalComponent,
    TimeLineVerticalComponent,
    TimeLineProtocoloComponent,
    TimeLineEmpresaComponent,
  ],
})
export class TimeLineModule {}
