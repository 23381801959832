import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FormValidator } from "src/app/components/validators/form-validator";
import { formatToCPFOrCNPJ } from "brazilian-values";
import { Empresa } from 'src/app/model/empresa';
import { MascaraPipe } from "src/app/pipes/mascara.pipe";
import { Constantes } from '../../../model/constantes';
import { Documento } from '../../../model/documento';
import { Registro } from '../../../model/registro';
import { Protocolo } from '../../../model/protocolo';
    

@Component({
    selector: "app-form-identificacao",
    templateUrl: "./form-identificacao.component.html",
    styleUrls: ["./form-identificacao.component.scss"],
})

export class FormIdentificacaoComponent implements OnInit {

    tipoEmpresa: string;
    formulario: FormGroup;
    identificacao: Empresa;
    registro: Registro;
    emitirCritica: boolean = false;
    mensagemCritica: string = "";
    
    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private mask: MascaraPipe) {
        
        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];
        
        this.registro = new Registro();

        // remover valores
        this.formulario = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
            cpfCnpjEmpresa: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(18), FormValidator.cpfCnpjValido()]],
            im: ['', [Validators.required, Validators.maxLength(20)]],
        });


    }

    ngOnInit(): void {
        
        if(this.tipoEmpresa == 'A' || this.tipoEmpresa == 'BA') {
            this.cpfCnpjEmpresa.clearValidators();
            this.cpfCnpjEmpresa.setValidators([Validators.required, Validators.maxLength(14), FormValidator.cpfValido()]);
        }
        else if(this.tipoEmpresa == 'BO'){
            this.cpfCnpjEmpresa.clearValidators();
            this.cpfCnpjEmpresa.setValidators([Validators.required, Validators.maxLength(18), Validators.minLength(14)]);
        }
        else { 
            this.cpfCnpjEmpresa.setValidators([Validators.required, Validators.minLength(14), Validators.maxLength(18), FormValidator.cpfCnpjValido()]);
        }   
    }

    formatToCPFOrCNPJ(valor) {
        this.cpfCnpjEmpresa.setValue(formatToCPFOrCNPJ(valor));
    }
    formatToNumber(valor) {
        this.im.setValue(this.mask.transform(valor, 9));
    }
 
    preencher() {
        let documento = new Documento();
        if(this.tipoEmpresa == 'A' || this.tipoEmpresa == 'BA') {
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documento.nome = "CPF";
        }
        else {
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cnpj;
            documento.nome = "CNPJ";
        }
        documento.numero = this.cpfCnpjEmpresa.value;

        let documentoMunicipal = new Documento();
        documentoMunicipal.tipo = Constantes.TIPO_DE_DOCUMENTO.Municipal;
        documentoMunicipal.nome = "Inscrição Municipal";
        documentoMunicipal.numero = this.im.value.toString().replace(/\D/g,"");

        this.registro.documentos = [documento, documentoMunicipal];
    }

    preencherCamposCriticas(protocolo: Protocolo) {
        
        this.emitirCritica = false;

        protocolo.registro.documentos.forEach( documento => {
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Cnpj) {
                this.cpfCnpjEmpresa.setValue(formatToCPFOrCNPJ(documento.numero.toString()));
            }
            if (documento.tipo == Constantes.TIPO_DE_DOCUMENTO.Municipal) {
                this.im.setValue(documento.numero);
            }
            
        });

        this.mensagemCritica += Protocolo.exibirCriticasFormulario(this.formulario, protocolo.criticas);

        if (this.mensagemCritica != "") {
            this.emitirCritica = true;
        }
        
    }
    
    get controleValidade() {
        return this.formulario.get("controleValidade");
    }
    set controleValidade(controleValidade) {
        this.formulario.get("controleValidade").setValue(controleValidade);
    }

    get cpfCnpjEmpresa() {
        return this.formulario.get("cpfCnpjEmpresa");
    }  
    set cpfCnpjEmpresa(cpfCnpjEmpresa) {
        this.formulario.get("cpfCnpjEmpresa").setValue(cpfCnpjEmpresa);
    }
    
    get im() {
        return this.formulario.get("im");
    }  
    set im(im) {
        this.formulario.get("im").setValue(im);
    }

}