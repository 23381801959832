import { Component, OnInit, Input } from '@angular/core';
import { Protocolo } from "src/app/model/protocolo";
import { Constantes } from "src/app/model/constantes";
import { ActivatedRoute } from '@angular/router';
import { MascaraPipe } from '../../../../pipes/mascara.pipe';

@Component({
  selector: 'app-informacao-identificacao',
  templateUrl: './informacao-identificacao.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoIdentificacaoComponent implements OnInit {

  @Input() tipoEmpresa = "";
  @Input() permiteCriticar = false;
  @Input() permiteInconsistencias = false;  
  @Input() semCardHeader: boolean = false;
  protocolo: Protocolo = new Protocolo();

  tipoTelefone = Constantes.TIPO_DE_TELEFONE;
  tipoDocumento = Constantes.TIPO_DE_DOCUMENTO;
  tipoDocumentoDescricao = Constantes.TIPO_DE_DOCUMENTO_DESCRICAO;
  permitirAtendimento: boolean;
  tipoAutenticidade: boolean;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private mascara: MascaraPipe,) {    
    let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
    let consultaDeProcessos = this.activatedRoute.snapshot.data["menu"] == "consulta-processo";
    this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !meusNegocios) && !consultaDeProcessos;
    this.permitirAtendimento = this.activatedRoute.snapshot.data["subMenu"] == "despacho" ? false : this.permitirAtendimento;
  }

  ngOnInit(): void {
  }

  typeof(valor) {
    return typeof(valor);
  }

  formatToBRL(valor) {
    return this.mascara.transform(valor, 11);
  }

}
