<mat-form-field>
    <mat-label>
      <svg class="iconeGridFiltro">
        <use
            attr.xlink:href="assets/sprite.svg#{{ 'search' }}"
        ></use>
      </svg>
      Pesquisar
    </mat-label>
    <input matInput (keyup)="aplicarFiltro($event)" placeholder="Digite qualquer valor para filtrar por todas as opções">
</mat-form-field>
  
<div class="mat-elevation-z8">
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="cep">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-cep"> Cep </th>
          <td mat-cell *matCellDef="let row"> {{formatToCEP(row.cep)}} </td>
      </ng-container>

      <ng-container matColumnDef="tipoLogradouro">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-tipo-logradouro"> Rua / Av. </th>
          <td mat-cell *matCellDef="let row"> {{row.tipoLogradouro}} </td>
      </ng-container>

      <ng-container matColumnDef="logradouro">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-logradouro"> Logradouro </th>
          <td mat-cell *matCellDef="let row"> {{row.logradouro}} </td>
      </ng-container>

      <ng-container matColumnDef="bairro">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-bairro"> Bairro </th>
          <td mat-cell *matCellDef="let row"> {{row.bairro}} </td>
      </ng-container>

      <ng-container matColumnDef="cidade">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow" id="row-cidade"> Cidade </th>
          <td mat-cell *matCellDef="let row"> {{row.cidade}} </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row 
          (click)="linhaSelecionada(row)"
          *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator pageSize="5" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<div>&nbsp;</div>