<div [class.hidden]="tipoEmpresa != 'BA'"
    [class.card]="!permiteCriticar"
    [class.card-critica]="permiteCriticar">        
    <div [class.card-header]="!permiteCriticar"
        [class.card-header-critica]="permiteCriticar">
        <div class="col-12">
            <strong>Atividades Originais</strong>
        </div>
    </div>
    <div class="card-body">
        <div *ngFor="let atividadeExercida of empresa.viabilidade.atividadesExercidas | filtro: {campo: 'principal', valor: true}"
            class="form-row row-linha linha-grid-divisao">
            <div
                class="row col-12">
                <p class="card-text" [id]="'lbl-' + atividadeExercida.codigo + '-atividade'">
                    <button type="button" class="btn btn-success btn-sm btn-informacao-atividade">
                        PRINCIPAL
                    </button>&nbsp;&nbsp;<strong>{{ atividadeExercida.codigo | mascara: 10 }}</strong> - {{ atividadeExercida.nome }}
                </p>
            </div>
        </div>
        <div *ngFor="let atividadeExercida of empresa.viabilidade.atividadesExercidas | filtro: {campo: 'principal', valor: false}"
            class="form-row row-linha linha-grid-divisao">
            <div
                class="row col-12">
                <p class="card-text" [id]="'lbl-' + atividadeExercida.codigo + '-atividade'">
                    <button type="button" class="btn btn-primary btn-sm btn-informacao-atividade">
                        EXERCIDA
                    </button>&nbsp;&nbsp;<strong>{{ atividadeExercida.codigo | mascara: 10 }}</strong> - {{ atividadeExercida.nome }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao" *ngFor="let atividadeNaoExercida of empresa.viabilidade.atividadesNaoExercidas; let i = index">
            <div class="row col-12">
                <p class="card-text" [id]="'lbl-' + atividadeNaoExercida.codigo + '-atividade'">
                    <button type="button" class="btn btn-secondary btn-sm btn-informacao-atividade">
                        NÃO EXERCIDA
                    </button>&nbsp;&nbsp;<strong>{{ atividadeNaoExercida.codigo | mascara: 10 }}</strong> - {{ atividadeNaoExercida.nome }}
                </p>
            </div>
        </div>
    </div>
</div>

<div [class.card]="!permiteCriticar"
    [class.card-critica]="permiteCriticar">        
    <div [class.card-header]="!permiteCriticar"
        [class.card-header-critica]="permiteCriticar">
        <div class="col-12">
            <strong>Atividades</strong>
        </div>
    </div>
    <div class="card-body">
        <div *ngFor="let atividadeExercida of protocolo.viabilidade.atividadesExercidas | filtro: {campo: 'principal', valor: true}"
            class="form-row row-linha linha-grid-divisao">
            <div
                class="row col-12">
                <p class="card-text" [id]="'lbl-' + atividadeExercida.codigo + '-atividade'">
                    <button type="button" class="btn btn-primary btn-sm btn-informacao-atividade">
                        EXERCIDA
                    </button>
                    &nbsp;&nbsp;<strong>{{ atividadeExercida.codigo | mascara: 10 }}</strong> - {{ atividadeExercida.nome }}
                    <strong>(PRINCIPAL)</strong>
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao" *ngFor="let atividadeNaoExercida of protocolo.viabilidade.atividadesNaoExercidas | filtro: {campo: 'principal', valor: true}; let i = index">
            <div
                class="row col-12">
                <p class="card-text" [id]="'lbl-' + atividadeNaoExercida.codigo + '-atividade'">
                    <button type="button" class="btn btn-secondary btn-sm btn-informacao-atividade">
                        NÃO EXERCIDA
                    </button>
                    &nbsp;&nbsp;<strong>{{ atividadeNaoExercida.codigo | mascara: 10 }}</strong> - {{ atividadeNaoExercida.nome }}
                    <strong>(PRINCIPAL)</strong>
                </p>
            </div>
        </div>
        <div *ngFor="let atividadeExercida of protocolo.viabilidade.atividadesExercidas | filtro: {campo: 'principal', valor: false}"
            class="form-row row-linha linha-grid-divisao">
            <div
                class="row col-12">
                <p class="card-text" [id]="'lbl-' + atividadeExercida.codigo + '-atividade'">
                    <button type="button" class="btn btn-primary btn-sm btn-informacao-atividade">
                        EXERCIDA
                    </button>&nbsp;&nbsp;<strong>{{ atividadeExercida.codigo | mascara: 10 }}</strong> - {{ atividadeExercida.nome }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao" *ngFor="let atividadeNaoExercida of protocolo.viabilidade.atividadesNaoExercidas; let i = index">
            <div class="row col-12">
                <p class="card-text" [id]="'lbl-' + atividadeNaoExercida.codigo + '-atividade'">
                    <button type="button" class="btn btn-secondary btn-sm btn-informacao-atividade">
                        NÃO EXERCIDA
                    </button>&nbsp;&nbsp;<strong>{{ atividadeNaoExercida.codigo | mascara: 10 }}</strong> - {{ atividadeNaoExercida.nome }}
                </p>
            </div>
        </div>
    </div>
</div>

<div [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BA' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'"
    [class.card]="!permiteCriticar"
    [class.card-critica]="permiteCriticar">
    <div [class.card-header]="!permiteCriticar"
        [class.card-header-critica]="permiteCriticar">
        <div class="col-12">
            <strong>Tipo de Unidade</strong>
        </div>
    </div>
    <div class="card-body">
        <div *ngIf="protocolo.viabilidade.tipoUnidade == '1'" 
            class="form-row row-linha linha-grid-divisao">
            <div class="col-12">
                <p class="card-text titulo" id="lbl-tipo-unidade-atividade">
                    <strong>Unidade Produtiva</strong>
                </p>
                <p class="card-text observacao">
                    Unidade operacional, quando exercer atividades de produção ou venda de bens e/ou serviços destinados a terceiros.
                </p>
            </div>
        </div>
        <div *ngIf="protocolo.viabilidade.tipoUnidade == '2'">
            <div class="form-row row-linha linha-grid-divisao">
                <div class="col-12">
                    <p class="card-text titulo" id="lbl-tipo-unidade-atividade">
                        <strong>Unidade Auxiliar</strong>
                    </p>
                    <p class="card-text observacao">
                        Quando servir apenas à própria empresa, exercendo exclusivamente funções de apoio administrativo ou técnico, voltadas à criação das condições necessárias para o exercício das atividades operacionais dos demais estabelecimentos, não desenvolvendo atividade econômica de produção ou de venda de bens e/ou serviços.
                    </p>
                </div>
            </div>
            <div *ngFor="let tipoUnidadeAuxiliar of tipoUnidadeAuxiliares | filtro: {campo: 'codigo', valor: protocolo.viabilidade.tipoUnidadeAuxiliar}"
                class="form-row row-linha">
                <div class="col-12">
                    <p class="card-text titulo" id="lbl-tipo-unidade-auxiliar-atividade">
                        <strong>Tipo de Unidade Auxiliar</strong> - {{ tipoUnidadeAuxiliar.nome }}
                    </p>
                    <p class="card-text observacao">
                        {{ tipoUnidadeAuxiliar.observacao }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BA' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'"
    [class.card]="!permiteCriticar"
    [class.card-critica]="permiteCriticar">
    <div [class.card-header]="!permiteCriticar"
        [class.card-header-critica]="permiteCriticar">
        <div class="col-12">
            <strong>Formas de Atuação</strong>
        </div>
    </div>
    <div *ngIf="protocolo.viabilidade.tipoUnidade == '1'" 
        class="card-body">
        <div *ngFor="let formaAtuacao of protocolo.viabilidade.formasAtuacao;"
            class="form-row row-linha linha-grid-divisao">
            <div class="col-12">
                <p class="card-text titulo">
                    <strong>{{ formaAtuacao.nome }}</strong>
                </p>
                <p class="card-text observacao">
                    {{ formaAtuacao.observacao }}
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="protocolo.viabilidade.tipoUnidade == '2'"
        class="card-body">
        <div class="form-row row-linha linha-grid-divisao">
            <div class="col-12">
                <p class="card-text observacao">
                    Unidade Auxiliar não possui formas de atuação.
                </p>
            </div>
        </div>
    </div>
</div>

<div [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'BA' || tipoEmpresa == 'AE' || tipoEmpresa == 'AM' || tipoEmpresa == 'AA' || tipoEmpresa == 'AEV' || tipoEmpresa == 'AO' || tipoEmpresa == 'EEV'"
    [class.card]="!permiteCriticar"
    [class.card-critica]="permiteCriticar">
    <div [class.card-header]="!permiteCriticar"
        [class.card-header-critica]="permiteCriticar">
        <div class="col-12">
            <strong>Descrição de suas atividades e como será o funcionamento do seu negócio</strong>
        </div>
    </div>
    <div class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text">
                    {{ protocolo.viabilidade.descricaoAtividadeEconomica }}
                </p>
            </div>
        </div>
    </div>
</div>
<div *ngIf= "tipoEmpresa == 'AEV' || tipoEmpresa == 'EEV'"
    [class.card]="!permiteCriticar"
    [class.card-critica]="permiteCriticar">
    <div [class.card-header]="!permiteCriticar"
        [class.card-header-critica]="permiteCriticar">
        <div class="col-12">
            <strong>Observações do Evento</strong>
        </div>
    </div>
    <div class="card-body">
        <div class="form-row row-linha">
            <div class="col-12">
                <p class="card-text">
                    {{ protocolo.fic.objetoSocial }}
                </p>
            </div>
        </div>
    </div>
</div>