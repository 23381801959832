import { Login } from "./login";
import { Requisito } from "./requisito";
import { Orgao } from "./orgao";

export class PendenciaFluxo {

    codigo: string;
    login: Login;
    requisito: Requisito;
    observacao: string;
    data: string;
    orgaoOrigem: Orgao;
    orgaoDestino: Orgao;

    constructor() {
        this.codigo = "";
        this.login = new Login();
        this.requisito = new Requisito();
        this.observacao = "";
        this.data = "";
        this.orgaoOrigem = new Orgao();
        this.orgaoDestino = new Orgao();
    }
}