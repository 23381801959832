import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Processo } from "src/app/model/processo";

@Injectable({ providedIn: 'root' })
export class ProcessoService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarPorProtocoloComObservacoes(protocolo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/processo/buscarPorProtocoloComObservacoes/${ localStorage.getItem("pId") }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarPorCodigo(codigo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/processo/buscarPorCodigo/${ codigo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    salvarProcesso(processo: Processo) {
        return this.http.post<any>(
            `${ environment.icadUrl }/processo/salvarProcesso`, JSON.stringify(processo),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
}