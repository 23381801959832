import { Requisito } from "./requisito";
import { Login } from "./login";

export class Observacao {
    cliente: string;
    protocolo: string;
    inscricao: string;
    processo: string;
    requisito: Requisito;
    codigo: string;
    login: Login;
    avaliacao: string;
    avaliacaoDescricao: string;
    avaliacaoClasse: string;
    observacao: string;
    data: string;
    tipo: string;

    constructor() {
        this.cliente = "";
        this.protocolo = "";
        this.inscricao = "";
        this.processo = "";
        this.requisito = new Requisito();
        this.codigo = "";
        this.login = new Login();
        this.avaliacao = "";
        this.avaliacaoDescricao = "";
        this.avaliacaoClasse = "";
        this.observacao = "";
        this.data = "";
        this.tipo = "";
    }
}