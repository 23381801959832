import { Protocolo } from "./protocolo";
import { Registro } from "./registro";
import { Viabilidade } from "./viabilidade";
import { Fic } from "./fic";
import { Licenciamento } from "./licenciamento";
import { Encerramento } from "./encerramento";
import { Constantes } from "./constantes";
import { Critica } from './critica';
import { Documento } from './documento';
import { ProtocoloAcompanhamento } from "./protocoloAcompanhamento";
import { RedeSim } from './redeSim';
import { EmpresaAcompanhamento } from 'src/app/model/empresaAcompanhamento';
import { Suspensao } from "./suspensao";

export class Empresa {

    cliente: string;
    inscricao: string;
    protocolo: string;
    registro: Registro;
    viabilidade: Viabilidade;
    fic: Fic;
    licenciamentos: Array<Licenciamento>;
    protocolos: Array<Protocolo>;
    protocolosAcompanhamento: Array<ProtocoloAcompanhamento>;
    empresaAcompanhamento: EmpresaAcompanhamento;
    encerramento: Encerramento;
    status: string;
    criticas: Array<Critica>;
    documentos: Array<Documento>;
    redeSim: RedeSim;
    suspensao: Suspensao;

    constructor() {
        this.cliente = "";
        this.inscricao = "";
        this.protocolo = "";
        this.registro = new Registro();
        this.viabilidade = new Viabilidade();
        this.fic = new Fic();
        this.licenciamentos = new Array<Licenciamento>();
        this.protocolos = new Array<Protocolo>();
        this.protocolosAcompanhamento = new Array<ProtocoloAcompanhamento>();
        this.empresaAcompanhamento = new EmpresaAcompanhamento();
        this.encerramento = new Encerramento();
        this.status = Constantes.STATUS_EMPRESA.Ativa;
        this.criticas = new Array<Critica>();
        this.documentos = new Array<Documento>();
        this.redeSim = new RedeSim();
        this.suspensao = new Suspensao();
    }
}