import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import * as pbi from "powerbi-client";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { take } from "rxjs/operators";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html"
})
export class DashboardComponent implements OnInit {
    
    report: pbi.Embed;
    @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;

    constructor(private authenticationService: AuthenticationService) {
        
    }

    ngOnInit(): void {
        this.authenticationService
            .tokenPowerBI()
            .pipe(take(1))
            .subscribe(retorno => {
                this.showReport(retorno);
            })
    }

    showReport(Token) {
        // Embed URL    
        let embedUrl = environment.powerBI.reportBaseURL;
        let groupID = environment.powerBI.groupID;
        let embedReportId = '04ef1e0e-d88c-437f-b952-67b989e80c92';
        let settings: pbi.IEmbedSettings = {
            // background: pbi.models.BackgroundType.Transparent,
            filterPaneEnabled: false,
            navContentPaneEnabled: true,
            persistentFiltersEnabled: false
        };
        let basicFilter: pbi.models.IBasicFilter = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "prefeitura",
              column: "id_prefeitura"
            },
            operator: "In",
            values: [parseInt(localStorage.getItem("pId"))],
            filterType: pbi.models.FilterType.Basic, // pbi.models.FilterType.BasicFilter
            requireSingleSelection: true // Limits selection of values to one.
        };
        // let basicFilter2: pbi.models.IBasicFilter = {
        //     $schema: "http://powerbi.com/product/schema#basic",
        //     target: {
        //       table: "atendimento",
        //       column: "auditor"
        //     },
        //     operator: "In",
        //     values: ["SIMONE MARQUES DOS SANTOS"],
        //     filterType: pbi.models.FilterType.Basic, // pbi.models.FilterType.BasicFilter
        //     requireSingleSelection: true // Limits selection of values to one.
        // };
        
        // let advancedFilter: pbi.models.IAdvancedFilter  = {
        //     $schema: "http://powerbi.com/product/schema#advanced",
        //     target: {
        //       table: "prefeitura",
        //       column: "id_prefeitura"
        //     },
        //     logicalOperator: "And",
        //     conditions: [
        //         {
        //             operator: "Is",
        //             value: "545"
        //         }
        //     ],
        //     filterType: pbi.models.FilterType.Advanced, 
        // }

        let config: pbi.IEmbedConfiguration = {
            type: 'report',
            tokenType: pbi.models.TokenType.Aad,
            accessToken: Token.access_token,
            embedUrl: embedUrl,
            groupId: groupID,
            id: embedReportId,
            datasetId: Token.dataSetId,
            filters: [
                basicFilter,
                // basicFilter2
            ],
            settings: settings
        };
        let reportContainer = this.reportContainer.nativeElement;
        let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        this.report = powerbi.embed(reportContainer, config);
        this.report.off("loaded");
        this.report.on("loaded", () => {
            console.log("Loaded");
            // this.setTokenExpirationListener(Token.expiration, 2);
        });
        this.report.on("error", () => {
            console.log("Error");
        });
    }

}