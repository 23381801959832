import { Component, NgZone, OnInit } from "@angular/core";
import { DattaConfig } from "../../app-config";
import { Location } from "@angular/common";
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
  selector: "app-core",
  templateUrl: "./core.component.html",
})
export class CoreComponent implements OnInit {
  public dattaConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;

  constructor(private zone: NgZone, 
    private location: Location,
    private authenticationService: AuthenticationService) {
      
    const eixoMenu = this.location.path().split('/')[1];
    const eixos = this.authenticationService.currentUserValue.eixos;
    eixos.forEach((eixo, index) => {
      if (eixo.rota.split('.')[1] === eixoMenu) {
        this.authenticationService.setCurrentAxis(index);
      }
    });
    
    this.dattaConfig = DattaConfig.config;

    let current_url = this.location.path();
    if (this.location["_baseHref"]) {
      current_url = this.location["_baseHref"] + this.location.path();
    }

    if (
      current_url === this.location["_baseHref"] + "/layout/collapse-menu" ||
      current_url === this.location["_baseHref"] + "/layout/box"
    ) {
      this.dattaConfig["collapse-menu"] = true;
    }
    
    this.windowWidth = window.innerWidth;
    this.navCollapsed =
      this.windowWidth >= 992 ? this.dattaConfig["collapse-menu"] : false;
    this.navCollapsedMob = false;
  }

  ngOnInit() {}

  navMobClick() {
    if (
      this.navCollapsedMob &&
      !document
        .querySelector("app-navigation.pcoded-navbar")
        .classList.contains("mob-open")
    ) {
      this.navCollapsedMob = !this.navCollapsedMob;
      setTimeout(() => {
        this.navCollapsedMob = !this.navCollapsedMob;
      }, 100);
    } else {
      this.navCollapsedMob = !this.navCollapsedMob;
    }
  }
}
