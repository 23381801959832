import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

import { Protocolo } from "src/app/model/protocolo";
import { ProtocoloService } from "src/app/services/operacao/protocolo.service";
import { ImobiliarioEmpresaProtocolo } from "src/app/model/imobiliarioEmpresaProtocolo";

@Component({
  selector: 'app-lista-empresa',
  templateUrl: './lista-empresa.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class ListaEmpresa implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};
  
  numeroProtocolo: string;
  protocolo: Protocolo;
  tipoEmpresa: string;
  imobiliarioEmpresasProtocolos: Array<ImobiliarioEmpresaProtocolo>;

  @Output() selecao = new EventEmitter();
  @Input() dados: Array<any>;

  constructor(
    private protocoloService: ProtocoloService,
    private activatedRoute: ActivatedRoute) {
    this.protocolo = new Protocolo();
    this.numeroProtocolo = this.activatedRoute.snapshot.params["protocolo"];
    this.tipoEmpresa = this.protocolo.processo.tipo;
    this.imobiliarioEmpresasProtocolos =  new Array<ImobiliarioEmpresaProtocolo>();
   }

  ngOnInit(): void {
    this.imobiliarioEmpresasProtocolos = this.dados[0];
  }

  acaoDoFiltro(valor) {
    var rex = new RegExp(valor, 'i');
    $('.todo-box .todo-item').hide();
    $('.todo-box .todo-item').filter(function() {
        return rex.test($(this).text());
    }).show();
  }

}
