<!-- PARCELA ÚNICA -->
<div>&nbsp;</div>
<div class="form-row row-linha justify-content-start align-items-center">
  <div class="col-12">
      <div class="col-12 card-header-linha">
          <p class="card-text">
              <b>PARCELA ÚNICA {{ exercicio != '' ? 'DO EXERCÍCIO ' + exercicio : '' }}</b>
          </p>                    
      </div>
  </div>
</div>
<div class="mat-elevation-z9">
  <table mat-table [dataSource]="dataSourceParcelaUnica" matSort>
    <ng-container matColumnDef="numero">
        <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-numero" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Parcela </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.numero }} </td>
    </ng-container>

    <ng-container matColumnDef="vencimento">
        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-vencimento" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Vencimento </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha"> {{ row.vencimento }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotal">
        <th style="width: 8%; text-align: center; padding-left: 40px; padding-right: 5px;" id="row-subTotal" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Subtotal </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row"> R$ {{ formatToBRL(row.subTotal) }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotalDesconto">
        <th style="width: 8%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotalDesconto" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Desconto </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha"> R$ {{ formatToBRL(row.subTotalDesconto) }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotalMora">
        <th style="width: 8%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotalMora" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Mora </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row"> R$ {{ formatToBRL(row.subTotalMora) }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotalJuros">
        <th style="width: 8%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotalJuros" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Juros </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha"> R$ {{ formatToBRL(row.subTotalJuros) }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotalCorrecaoMonetaria">
        <th style="width: 8%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotalCorrecaoMonetaria" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Correção </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row"> R$ {{ formatToBRL(row.subTotalCorrecaoMonetaria) }} </td>
    </ng-container>

    <ng-container matColumnDef="total">
        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-total" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Total </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha"> R$ {{ formatToBRL(row.total) }} </td>
    </ng-container>

    <ng-container matColumnDef="situacao">
        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-situacao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Situação </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"
          [class.texto-danger]="row.situacao == parcelaSituacao.Vencida"
          [class.texto-success]="row.situacao == parcelaSituacao.Paga"> {{ parcelaSituacaoDescricao[row.situacao] }} </td>
    </ng-container>

    <ng-container matColumnDef="dataPagamento">
        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-dataPagamento" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Data Baixa </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha"> {{ row.taxaBaixa.dataPagamento }} </td>
    </ng-container>

    <ng-container matColumnDef="totalBaixa">
        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-totalBaixa" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Total Baixa </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row"
          [class.texto-danger]="row.taxaBaixa.taxaCreditoDebito.creditoDebito != ''"> R$ {{ formatToBRL(row.taxaBaixa.total) }} </td>
    </ng-container>

    <ng-container matColumnDef="menu">
        <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-menu" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"></th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row">
          <mat-checkbox
            *ngIf="row.situacao == parcelaSituacao.Em_Aberto || row.situacao == parcelaSituacao.Vencida"
            (click)="$event.stopPropagation()"
            (change)="selecionarParcelaUnica(row)"
            [checked]="linhaSelecionada.isSelected(row)"
            [disabled]="!this.linhaSelecionada.isEmpty()"
            id="parcela_{{ row.codigo }}"
            name="parcela_{{ row.codigo }}">
          </mat-checkbox>          
          <div class="row col-12 m-0 p-0" *ngIf="row.situacao == parcelaSituacao.Paga">
            <div class="row col-6 m-0 p-0 justify-content-center align-items-center">
              <button
                type="button" 
                matTooltip="Informações da Baixa"
                matTooltipPosition="above"
                class="btn btn-sm m-0 p-0"
                (click)="acaoInformacaoBaixa(row)">
                <svg class="icone-replicar">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'info' }}"
                  ></use>
                </svg>
              </button>
            </div>
            <div class="row col-6 m-0 p-0 justify-content-center align-items-center">
              <button
                type="button" 
                matTooltip="Comprovante"
                matTooltipPosition="above"
                class="btn btn-sm m-0 p-0"
                (click)="acaoImprimirComprovante(row)">
                <svg class="icone-editar">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'file' }}"
                  ></use>
                </svg>
              </button>
            </div>
          </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsParcelaUnica"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsParcelaUnica;"></tr>
  </table>
  <div class="row" *ngIf="dataSourceParcelaUnica.filteredData.length == 0">
    <div class="form-row col-12 pt-1 justify-content-center align-items-center">
      <p class="card-text text-center">
        <br>{{ mensagemDeBuscaParcelaUnica }}<br>&nbsp;
      </p>
    </div>
  </div>
</div>
<div>&nbsp;</div>

<!-- LISTA DE PARCELAS -->
<div class="form-row row-linha justify-content-start align-items-center">
  <div class="col-12">
      <div class="col-12 card-header-linha">
          <p class="card-text">
              <b>TODA(S) AS PARCELA(S) {{ exercicio != '' ? 'COM VENCIMENTO NO EXERCÍCIO ' + exercicio : '' }}</b>
          </p>                    
      </div>
  </div>
</div>
<div class="mat-elevation-z9">
  <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">
    <ng-container matColumnDef="numero">
        <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-numero" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Parcela </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"> {{ row.numero }} </td>
    </ng-container>

    <ng-container matColumnDef="vencimento">
        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-vencimento" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Vencimento </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha"> {{ row.vencimento }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotal">
        <th style="width: 8%; text-align: center; padding-left: 40px; padding-right: 5px;" id="row-subTotal" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Subtotal </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row"> R$ {{ formatToBRL(row.subTotal) }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotalDesconto">
        <th style="width: 8%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotalDesconto" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Desconto </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha"> R$ {{ formatToBRL(row.subTotalDesconto) }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotalMora">
        <th style="width: 8%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotalMora" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Mora </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row"> R$ {{ formatToBRL(row.subTotalMora) }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotalJuros">
        <th style="width: 8%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotalJuros" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Juros </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha"> R$ {{ formatToBRL(row.subTotalJuros) }} </td>
    </ng-container>

    <ng-container matColumnDef="subTotalCorrecaoMonetaria">
        <th style="width: 8%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-subTotalCorrecaoMonetaria" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Correção </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row"> R$ {{ formatToBRL(row.subTotalCorrecaoMonetaria) }} </td>
    </ng-container>

    <ng-container matColumnDef="total">
        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-total" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Total </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row" class="cell-linha"> R$ {{ formatToBRL(row.total) }} </td>
    </ng-container>

    <ng-container matColumnDef="situacao">
        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-situacao" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Situação </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row"
          [class.texto-danger]="row.situacao == parcelaSituacao.Vencida"
          [class.texto-success]="row.situacao == parcelaSituacao.Paga"> {{ parcelaSituacaoDescricao[row.situacao] }} </td>
    </ng-container>

    <ng-container matColumnDef="dataPagamento">
        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-dataPagamento" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Data Baixa </th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row" class="cell-linha"> {{ row.taxaBaixa.dataPagamento }} </td>
    </ng-container>

    <ng-container matColumnDef="totalBaixa">
        <th style="width: 10%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-totalBaixa" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"> Total Baixa </th>
        <td style="text-align: right; padding-left: 5px; padding-right: 10px;" mat-cell *matCellDef="let row"
          [class.texto-danger]="row.taxaBaixa.taxaCreditoDebito.creditoDebito != ''"> R$ {{ formatToBRL(row.taxaBaixa.total) }} </td>
    </ng-container>

    <ng-container matColumnDef="menu">
        <th style="width: 5%; text-align: center; padding-left: 20px; padding-right: 5px;" id="row-menu" mat-header-cell *matHeaderCellDef mat-sort-header class="hide-arrow"></th>
        <td style="text-align: center; padding-left: 5px; padding-right: 5px;" mat-cell *matCellDef="let row">
          <div class="row col-12 m-0 p-0">
            <div class="row col-6 m-0 p-0 justify-content-center align-items-center">
              <button
                type="button" 
                matTooltip="Memória de Cálculo"
                matTooltipPosition="above"
                class="btn btn-sm m-0 p-0"
                (click)="acaoMemoriaDeCalculoParcela(row)"
                *ngIf="currentUser == '2'">
                <svg class="icone-editar">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'book' }}"
                  ></use>
                </svg>
              </button>
            </div>
            <div class="row col-6 m-0 p-0 justify-content-center align-items-center" *ngIf="row.situacao == parcelaSituacao.Em_Aberto || row.situacao == parcelaSituacao.Vencida">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="selecionarParcela(row)"
                [checked]="linhaSelecionada.isSelected(row)"
                [disabled]="!this.linhaSelecionadaParcelaUnica.isEmpty()"
                id="parcela_{{ row.codigo }}"
                name="parcela_{{ row.codigo }}">
              </mat-checkbox>
            </div>
            <div class="row col-6 m-0 p-0 justify-content-center align-items-center" *ngIf="row.situacao == parcelaSituacao.Paga">
              <button
                type="button" 
                matTooltip="Informações da Baixa"
                matTooltipPosition="above"
                class="btn btn-sm m-0 p-0"
                (click)="acaoInformacaoBaixa(row)">
                <svg class="icone-replicar">
                  <use
                      attr.xlink:href="assets/sprite.svg#{{ 'info' }}"
                  ></use>
                </svg>
              </button>
            </div>
          </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="row" *ngIf="dataSource.filteredData.length == 0">
    <div class="form-row col-12 pt-3 justify-content-center align-items-center">
      <p class="card-text text-center">
        <br>{{ mensagemDeBusca }}<br>&nbsp;
      </p>
    </div>
  </div>
  <mat-paginator [pageSize]="12" [pageSizeOptions]="[6, 12, 24, 48, 60, 120]"></mat-paginator> 
  <div class="row col-12 justify-content-center align-items-center">
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Excel', sheet: 'sheet_name', Props: {Author: 'Talha'}})">EXCEL</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('json')">JSON</button>
    <button class="btn btn-outline-success pb-1 pt-1 m-2" mat-raised-button (click)="exporter.exportTable('txt')">TXT</button>
  </div>
</div>
<div>&nbsp;</div>
<div class="row justify-content-center align-items-center mt-4">
  <div class="row col-2"></div>
  <div class="row col-3 justify-content-center align-items-center mt-2">
      <button 
          type="button" 
          class="btn btn-secondary col-12"
          title="Buscar"
          (click)="imprimirBoleto(true)"
      >
      <svg class="icone-adicionar">
          <use
              attr.xlink:href="assets/sprite.svg#{{ 'printer' }}"
          ></use>
      </svg>
      Boleto único
      </button>
  </div>
  <div class="row col-2"></div>
  <div class="row col-3 justify-content-center align-items-center mt-2">
      <button 
          type="button" 
          class="btn btn-secondary col-12"
          title="Buscar"
          (click)="imprimirBoleto(false)"
      >
      <svg class="icone-adicionar">
          <use
              attr.xlink:href="assets/sprite.svg#{{ 'printer' }}"
          ></use>
      </svg>
      Boleto individual
      </button>
  </div>
  <div class="row col-2"></div>
</div>