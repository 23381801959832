import { Pergunta } from "./pergunta";

export class Resposta {
    cliente: string;
    codigo: string;
    nome: string;
    ativa: string;
    perguntas: Array<Pergunta>;
    fatorDeRisco: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.nome = "";
        this.ativa = "";
        this.perguntas = new Array<Pergunta>();
        this.fatorDeRisco = "";
    }
}