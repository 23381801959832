export class Banco {
    codigo: string;
    descricao: string;
    status: boolean;
    digito: string;
    loginCadastro: string;
    adicionado: string;

    constructor() {
        this.codigo = "";
        this.descricao = "";
        this.status = false;
        this.digito = "";
        this.loginCadastro = "";
        this.adicionado = "";
    }
}