export class CalendarioFiscalData {
    cliente: string;
    codigo: string;
    codigoCalendarioFiscal: string;
    numero: string;
    data: string;
    loginCadastro: string;
    adicionado: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.codigoCalendarioFiscal = "";
        this.numero = "";
        this.data = "";
        this.loginCadastro = "";
        this.adicionado = "";
    }
}