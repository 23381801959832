<div class="justify-content-md-end">
  <form name="formulario" 
    [formGroup]="formulario" 
    class="justify-content-center align-items-center"
    >
    <app-alert type="danger"
        *ngIf="emitirErro" >
        <div [innerHtml]="mensagemErro"></div>
    </app-alert>
    <div class="card">
      <div class="card-header">
        <div class="col-12">
            <strong>Baixa</strong>
        </div>
      </div>
      <div class="card-body card-body-localizacao">
        <div class="row">
            <div class="row col-12">
                <p class="card-text pb-2 pt-2 col-12">
                    Caro Auditor, favor informar o valores para baixar a(s) parcela(s) abaixo, os valores atuais atualizado encontram-se logo abaixo de cada campo:<br><br>
                    <b>Valores nos campos para edição:</b> Os valores podem ser editados de acordo com o pagamento realizado.<br><br>
                    <b>Valores informados abaixo dos campos de edição:</b> São os valores atualizados na data de pagamento informada.<br><br>
                    <b>Data abaixo do campo de pagamento:</b> É a data de vencimento da parcela.
                </p>
            </div>
            <div class="row col-12 mt-4 header-atividades">
              <div class="row col-2">
                <p class="card-text pb-2 pt-2 col-12 text-center titulo-atividades">
                  <b>Desconto</b>
                </p>
              </div>
              <div class="row col-2">
                <p class="card-text pb-2 pt-2 col-12 text-center titulo-atividades">
                  <b>Mora</b>
                </p>
              </div>
              <div class="row col-2">
                <p class="card-text pb-2 pt-2 col-12 text-center titulo-atividades">
                  <b>Juros</b>
                </p>
              </div>
              <div class="row col-2">
                <p class="card-text pb-2 pt-2 col-12 text-center titulo-atividades">
                  <b>Correção</b>
                </p>
              </div>
              <div class="row col-2">
                <p class="card-text pb-2 pt-2 col-12 text-center titulo-atividades">
                  <b>Total</b>
                </p>
              </div>
              <div class="row col-2">
                <p class="card-text pb-2 pt-2 col-12 text-center titulo-atividades">
                  <b>Pagamento</b>
                </p>
              </div>
            </div>
            <div class="row col-12 pt-2 pb-3" *ngFor="let taxaLancamentoParcela of taxaLancamentoParcelas; let i = index"
              [class.row-cinza]="i % 2 != 0">
              <div class="row col-12">
                <p class="card-text pt-3 pb-2 col-12"
                  [innerHtml]="informacoesECalculos(taxaLancamentoParcela)">
                </p>
              </div>
              <div class="row col-2">
                <input
                  #inputSubTotalDesconto
                  (input)="formatToBRL(inputSubTotalDesconto.value, 'subTotalDesconto_' + taxaLancamentoParcela.codigoLancamento + '_' + taxaLancamentoParcela.codigo, taxaLancamentoParcela.codigoLancamento, taxaLancamentoParcela.codigo)"
                  class="form-control form-control-sm text-center"
                  formControlName="subTotalDesconto_{{ taxaLancamentoParcela.codigoLancamento }}_{{ taxaLancamentoParcela.codigo }}"
                  id="subTotalDesconto"
                  name="subTotalDesconto"
                  type="text"
                  maxlength="10"
                  prefix="R$ "
                  mask="separator"
                  thousandSeparator="."
                  placeHolderCharacter=""
                  [showMaskTyped]="true"
                  id="subTotalDesconto" 
                  name="subTotalDesconto"
                />
                <p class="card-text pt-2 col-12 text-center"
                  [class.texto-danger]="validadorValores(taxaLancamentoParcela.subTotalDesconto, inputSubTotalDesconto.value)"
                >
                  R$ {{ formatToBRLValor(taxaLancamentoParcela.subTotalDesconto) }}
                </p>
              </div>
              <div class="row col-2">
                <input
                  #inputSubTotalMora
                  (input)="formatToBRL(inputSubTotalMora.value, 'subTotalMora_' + taxaLancamentoParcela.codigoLancamento + '_' + taxaLancamentoParcela.codigo, taxaLancamentoParcela.codigoLancamento, taxaLancamentoParcela.codigo)"
                  class="form-control form-control-sm text-center"
                  formControlName="subTotalMora_{{ taxaLancamentoParcela.codigoLancamento }}_{{ taxaLancamentoParcela.codigo }}"
                  id="subTotalMora"
                  name="subTotalMora"
                  type="text"
                  maxlength="10"
                  prefix="R$ "
                  mask="separator"
                  thousandSeparator="."
                  placeHolderCharacter=""
                  [showMaskTyped]="true"
                  id="subTotalMora" 
                  name="subTotalMora"
                />
                <p class="card-text pt-2 col-12 text-center"
                  [class.texto-danger]="validadorValores(taxaLancamentoParcela.subTotalMora, inputSubTotalMora.value)"
                >
                  R$ {{ formatToBRLValor(taxaLancamentoParcela.subTotalMora) }}
                </p>
              </div>
              <div class="row col-2">
                <input
                  #inputSubTotalJuros
                  (input)="formatToBRL(inputSubTotalJuros.value, 'subTotalJuros_' + taxaLancamentoParcela.codigoLancamento + '_' + taxaLancamentoParcela.codigo, taxaLancamentoParcela.codigoLancamento, taxaLancamentoParcela.codigo)"
                  class="form-control form-control-sm text-center"
                  formControlName="subTotalJuros_{{ taxaLancamentoParcela.codigoLancamento }}_{{ taxaLancamentoParcela.codigo }}"
                  id="subTotalJuros"
                  name="subTotalJuros"
                  type="text"
                  maxlength="10"
                  prefix="R$ "
                  mask="separator"
                  thousandSeparator="."
                  placeHolderCharacter=""
                  [showMaskTyped]="true"
                  id="subTotalJuros" 
                  name="subTotalJuros"
                />
                <p class="card-text pt-2 col-12 text-center"
                  [class.texto-danger]="validadorValores(taxaLancamentoParcela.subTotalJuros, inputSubTotalJuros.value)"
                >
                  R$ {{ formatToBRLValor(taxaLancamentoParcela.subTotalJuros) }}
                </p>
              </div>
              <div class="row col-2">
                <input
                  #inputSubTotalCorrecaoMonetaria
                  (input)="formatToBRL(inputSubTotalCorrecaoMonetaria.value, 'subTotalCorrecaoMonetaria_' + taxaLancamentoParcela.codigoLancamento + '_' + taxaLancamentoParcela.codigo, taxaLancamentoParcela.codigoLancamento, taxaLancamentoParcela.codigo)"
                  class="form-control form-control-sm text-center"
                  formControlName="subTotalCorrecaoMonetaria_{{ taxaLancamentoParcela.codigoLancamento }}_{{ taxaLancamentoParcela.codigo }}"
                  id="subTotalCorrecaoMonetaria"
                  name="subTotalCorrecaoMonetaria"
                  type="text"
                  maxlength="10"
                  prefix="R$ "
                  mask="separator"
                  thousandSeparator="."
                  placeHolderCharacter=""
                  [showMaskTyped]="true"
                  id="subTotalCorrecaoMonetaria" 
                  name="subTotalCorrecaoMonetaria"
                />
                <p class="card-text pt-2 col-12 text-center"
                  [class.texto-danger]="validadorValores(taxaLancamentoParcela.subTotalCorrecaoMonetaria, inputSubTotalCorrecaoMonetaria.value)"
                >
                  R$ {{ formatToBRLValor(taxaLancamentoParcela.subTotalCorrecaoMonetaria) }}
                </p>
              </div>
              <div class="row col-2">
                <input
                  #inputTotal
                  (input)="formatToBRLSemAtualizarValores(inputTotal.value, 'total_' + taxaLancamentoParcela.codigoLancamento + '_' + taxaLancamentoParcela.codigo)"
                  class="form-control form-control-sm text-center"
                  formControlName="total_{{ taxaLancamentoParcela.codigoLancamento }}_{{ taxaLancamentoParcela.codigo }}"
                  id="total"
                  name="total"
                  type="text"
                  maxlength="10"
                  prefix="R$ "
                  mask="separator"
                  thousandSeparator="."
                  placeHolderCharacter=""
                  [showMaskTyped]="true"
                  [allowNegativeNumbers]="true"
                  id="total" 
                  name="total"
                />
                <p class="card-text pt-2 col-12 text-center"
                  [class.texto-danger]="validadorValores(taxaLancamentoParcela.total, inputTotal.value)"
                >
                  R$ {{ formatToBRLValor(taxaLancamentoParcela.total) }}
                </p>
              </div>
              <div class="row col-2">
                <input
                  #inputDataPagamento
                  class="form-control form-control-sm text-center"
                  (blur)="atualizarParcela(taxaLancamentoParcela)"
                  formControlName="dataPagamento_{{ taxaLancamentoParcela.codigoLancamento }}_{{ taxaLancamentoParcela.codigo }}"
                  id="dataPagamento"
                  name="dataPagamento"
                  type="text"
                  mask="00/00/0000"
                  [showMaskTyped]="true"
                  id="dataPagamento" 
                  name="dataPagamento"
                  placeholder=""
                />
                <p class="card-text pt-2 col-12 text-center">
                  {{ taxaLancamentoParcela.vencimento }}
                </p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
      <div class="row col-12">
        <div class="row col-6 justify-content-start align-items-center">
            <button type="button" class="btn btn-danger" (click)="acaoBotaoVoltar()">Voltar</button>
        </div>
        <div class="row col-6 justify-content-end align-items-center divisao-esquerda">
          <button type="button" class="btn btn-success" (click)="acaoBotaoSalvar()">Concluir</button>
        </div>
      </div>
  </div>
</div>