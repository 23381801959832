import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { Constantes } from "src/app/model/constantes";
import { IcadModalComponent } from "src/app/components/modal/icad-modal/icad-modal.component";
import { take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { Pendencia } from "src/app/model/pendencia";
import { ActivatedRoute } from "@angular/router";
import { Critica } from '../../../../model/critica';
import { Protocolo } from 'src/app/model/protocolo';
import { Despacho } from 'src/app/model/despacho';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Cliente } from 'src/app/model/cliente';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-atendimento-acao',
  templateUrl: './atendimento-acao.component.html',
  styleUrls: ['../atendimento-protocolo/atendimento-protocolo.component.scss']
})
export class AtendimentoAcaoComponent implements OnInit {

  @Output() acaoDeAtendimento = new EventEmitter();
  @Input() criticas: Array<Critica>;
  protocolo: Protocolo;
  modulo: string;
  tipoDeProtocolo = Constantes.TIPO_DE_PROTOCOLO
  menuAtendimento = Constantes.MENU_ATENDIMENTO
  eDespacho: boolean = false;

  geraInscricao: string = 'A';
  inscricaoValida: boolean = false;
  tipoEmpresa: string;

  tipoProcesso: string;

  public pendencias: Array<Pendencia>;
  public pendenciasSelecionadas: Array<Pendencia>;
  public mostrarBotaoViabilidade: boolean = false;
  public mostrarBotaoPendenteOrgao: boolean = false;

  public despachos: Array<Despacho>;

  cliente: Cliente;

  formulario: FormGroup;

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private loginService: LoginService,) {
    this.pendencias = new Array<Pendencia>();
    this.pendenciasSelecionadas = new Array<Pendencia>();

    this.despachos = new Array<Despacho>();
    
    this.mostrarBotaoViabilidade = false;
    this.mostrarBotaoPendenteOrgao = false;

    this.formulario = this.formBuilder.group({
      inscricaoDigitada: [''],
      inscricaoAutomatica: ['']
    });
  }

  ngOnInit(): void {
    this.modulo = this.activatedRoute.snapshot.data["menu"];
    this.eDespacho = this.activatedRoute.snapshot.data["subMenu"] == "despacho";
    this.buscarCliente();    
  }

  async buscarCliente() {
    await 
      this.loginService
        .buscarPorCodigo(localStorage.getItem("pId"))
        .pipe(take(1))
        .subscribe(retorno => {
          this.cliente = new Cliente();
          this.cliente = retorno;
          this.geraInscricao = this.cliente.geraInscricaoMobiliaria;
          this.inscricaoValida = true;
        });
  }

  atender(acaoAtendimento) {
    this.acaoDeAtendimento.emit({ acao: acaoAtendimento });
  }

  deferir() {
    this.atender(Constantes.ACAO_ATENDIMENTO.Deferir);
  }

  indeferir() {
    this.atender(Constantes.ACAO_ATENDIMENTO.Indeferir);    
  }

  enviarParaViabilidade(){
    this.atender(Constantes.ACAO_ATENDIMENTO.Enviar_Para_Viabilidade);
  }

  pendenciarOrgao(){
    this.atender(Constantes.ACAO_ATENDIMENTO.Pendenciar_Orgao);
  }

  cancelarProtocolo(){
    this.atender(Constantes.ACAO_ATENDIMENTO.Cancelar);
  }

  pendenciar() {
    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '60%',
      closeOnNavigation: true,
      data: {
          titulo: "Pendência",
          componente: "atendimento-pendenciar",
          dados: [this.pendencias, this.activatedRoute.snapshot.params["codigoOrgao"], this.criticas]
      }
      });

      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === "" || result === null)
          return false;

      this.pendenciasSelecionadas = result.pendencias;
      this.atender(Constantes.ACAO_ATENDIMENTO.Pendenciar);
    });
  }

  despachar() {
    const dialogRef = this.dialog.open(IcadModalComponent, {
      width: '80%',
      height: '90%',
      maxWidth: 'none',
      maxHeight: 'none',
      closeOnNavigation: true,
      data: {
        titulo: "Despacho",
        componente: "atendimento-despachar",
        dados: [
          this.despachos, 
          this.activatedRoute.snapshot.params["codigoOrgao"], 
          this.activatedRoute.snapshot.params["protocolo"], 
          this.activatedRoute.snapshot.params["inscricao"]
        ]
      }
      });

      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === "" || result === null)
          return false;

      this.pendenciasSelecionadas = result.pendencias;

      this.atender(Constantes.ACAO_ATENDIMENTO.Despachar);
    });
  }

  responderDespacho() {
    this.atender(Constantes.ACAO_ATENDIMENTO.Responder_Despacho);
  }

  marcarAutomatica(campo){
    if(campo.checked){
      this.inscricaoDigitada.setValue("");
      this.inscricaoDigitada.disable();
      this.inscricaoValida = true;

    }else{
      this.inscricaoDigitada.setValue("");
      this.inscricaoDigitada.enable();
      this.inscricaoValida = false;
    }
   }

   iniciarParametros(){
    this.inscricaoValida = false;
   }

  get inscricaoDigitada() {
    return this.formulario.get("inscricaoDigitada");
  }
  set inscricaoDigitada(inscricaoDigitada) {
    this.formulario.get("inscricaoDigitada").setValue(inscricaoDigitada);
  }

  get inscricaoAutomatica() {
    return this.formulario.get("inscricaoAutomatica");
  }
  set inscricaoAutomatica(inscricaoAutomatica) {
    this.formulario.get("inscricaoAutomatica").setValue(inscricaoAutomatica);
  }

}
