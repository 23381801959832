export class CentroCusto {
    cliente: string;
    codigo: string;
    codigoCentroDeCusto: string;
    identificacao: string;
    loginCadastro: string;
    adicionado: string;
    status: string;

    constructor() {
        this.cliente = "";
        this.codigo = "";
        this.codigoCentroDeCusto = "";
        this.identificacao = "";
        this.loginCadastro = "";
        this.adicionado = "";
        this.status = "";
    }
}