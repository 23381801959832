<app-alert type="warning" >
  Caro, contribuinte realize a consulta e acompahamento dos seus débitos ativos. Selecione a opção CNPJ ou CPF, preencha a informação e selecione Pesquisar.
</app-alert>

<div class="card container-principal-processos">
  <div class="card-header card-header-localizacao">
      <div class="col-12">
          <strong>Aqui você localiza os dados a sua escolha</strong>
      </div>      
  </div>
  <div class="card-body card-body-localizacao">
    <form
        class="justify-content-center align-items-center"
        name="formulario"
        [formGroup]="formulario"
    >
      <div class="row">
        <div class="col-4">
          <select 
              #tipoBuscaSelect
              class="form-control form-control-sm"
              (change)="acaoSelectTipoBusca()"
              formControlName="tipoBusca"
              name="tipoBusca" 
              id="tipoBusca"                
          >
              <option disabled value="">--- Selecione ---</option>
              <option
                [value]="1" 
                >CPF/CNPJ
              </option>
          </select>
        </div>
        <div class="col-7">
          <input [class.hidden]="tipoBusca.value != '1'"
              #tipoBuscaValorInput
              (input)="formatToCPFOrCNPJ(tipoBuscaValorInput.value)"
              class="form-control form-control-sm"
              formControlName="tipoBuscaValorInput"
              id="tipoBuscaValorInput"
              name="tipoBuscaValorInput"                
              type="text"
              maxlength="18"
          />
        </div>
        <div class="col-1 text-right">
          <button 
              type="button" 
              class="btn btn-secondary"
              title="Buscar"
              (click)="buscarGridPorFiltro()"
              [disabled] = "tipoBuscaValorSelect.value == '' && tipoBuscaValorInput == ''"
          >
          Buscar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container-principal">
  <mat-tab-group mat-align-tabs="start">
    
    <mat-tab label="Aberto">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
          <img src="../../../../assets/img/icons/clipboard.svg" style="width: 15px;"/>
        </mat-icon> Em Aberto
      </ng-template>
      <app-grid-dam #emAberto></app-grid-dam>
    </mat-tab>

    <mat-tab label="Pagas">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
          <img src="../../../../assets/img/icons/check.svg" style="width: 15px;"/>
        </mat-icon> Pagas
      </ng-template>
      <app-grid-dam #pagas></app-grid-dam>
    </mat-tab>

    <mat-tab label="Finalizado">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
          <img src="../../../../assets/img/icons/check.svg" style="width: 15px;"/>
        </mat-icon> Finalizadas
      </ng-template>
      <app-grid-dam #finalizado></app-grid-dam>
    </mat-tab>

   </mat-tab-group>
</div>
