import { Component, OnInit, Input, ViewChild, Renderer2, EventEmitter, ElementRef } from '@angular/core';
import { take } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';
import {DocumentoEmpresaProtocolo} from "src/app/model/documentoEmpresaProtocolo";
import { EmpresaService } from 'src/app/services/empresa/empresa.service';
import { Documento } from 'src/app/model/documento';

@Component({
    selector: 'app-atendimento-empresa-cnpj',
    templateUrl: './atendimento-empresa-cnpj.component.html',
    styleUrls: ['./atendimento-protocolo.component.scss']
  })

export class AtendimentoEmpresaCnpj implements OnInit {

    documentoEmpresasProtocolos: Array<DocumentoEmpresaProtocolo>;

    cliente: string;
    protocolo: string;
    documento: Documento;
    modulo: string;

    @Input() dados: Array<any>;

    constructor(public dialog: MatDialog,
                private empresaService: EmpresaService,
                private activatedRoute: ActivatedRoute){
      this.documentoEmpresasProtocolos =  new Array<DocumentoEmpresaProtocolo>();
      this.modulo = this.activatedRoute.snapshot.data["menu"];
    }

    ngOnInit(): void {
      
    }

    exibeEmpresasCnpj(dados){
        const dialogRef = this.dialog.open(IcadModalComponent, {
          width: '70%',
          closeOnNavigation: true,
          data: {
              titulo: "Negócios com o mesmo CNPJ / CPF",
              componente: "lista-empresa-cnpj",
              dados: [dados]
          }
          });
    
          dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result === "" || result === null)
              return false;
    
        });
      }

      carregarEmpresas(){

        if(this.documento.numero == '00000000000'  || this.documento.numero == '00000000000000' || this.documento.numero.length < 11){
          this.documentoEmpresasProtocolos.length = 0;
        }
        else{
          this.empresaService
          .buscarEmpresasPorDocumento(this.cliente,this.protocolo,this.documento.numero)
          .pipe(take(1))
          .subscribe(retorno => this.documentoEmpresasProtocolos = retorno);
        }
        
      }

}
