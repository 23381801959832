import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { TaxaService } from 'src/app/services/taxa/taxa.service';
import { TaxaLancamento } from 'src/app/model/taxaLancamento';
import { TaxaLancamentoParcela } from 'src/app/model/taxaLancamentoParcela';
import { Constantes } from 'src/app/model/constantes';
import { MascaraPipe } from 'src/app/pipes/mascara.pipe';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormValidator } from '../../../../components/validators/form-validator';
import Swal from 'sweetalert2';
import { CentroCusto } from 'src/app/model/centroCusto';
import { MatDialog } from '@angular/material/dialog';
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';


@Component({
    selector: "app-revisao",
    templateUrl: "./revisao.component.html",
    styleUrls: ["./../gerenciamento-taxa.component.scss"]
})
export class RevisaoComponent implements OnInit {
    
    @Output() acaoMenuAtendimento = new EventEmitter();

    formulario: FormGroup;

    emitirErro: boolean;
    mensagemErro: string;

    centrosDeCusto: Array<CentroCusto>;
    taxaLancamentosAtual: Array<TaxaLancamento>;
    taxaLancamentosRevisado: Array<TaxaLancamento>;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

    acaoAtendimentoDescricao = Constantes.ACAO_ATENDIMENTO_DESCRICAO;
    acaoAtendimentoClass = Constantes.ACAO_ATENDIMENTO_CLASS;

    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private mascara: MascaraPipe,
        private taxaService: TaxaService) {

        this.formulario = this.formBuilder.group({
            cliente: [""],
            codigo: [""],            
            protocolo: ["", [Validators.required]],
            inscricao: ["", [Validators.required]],
            codigoCentroDeCusto: [''],
        });
            
    }

    ngOnInit(): void {
        this.carregarCentrosDeCusto()
    }
     
    carregarCentrosDeCusto() {
        this.taxaService
        .buscarTaxaCentroDeCustoAtivos()
        .pipe(take(1))
        .subscribe( retorno => {
            this.centrosDeCusto = retorno;
        })
    }

    acaoSelectTipoBusca() {
      this.limpar();
    }

    gerarPreviaRevisao() {
        Swal.fire({
            text: 'Caro Auditor, será realizado um pré lançamento com os calculos dessa(s) regra(s), o(s) lançamento(s) só será(ão) concretizado a partir da conclusão, deseja continuar?',
            icon: 'question',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        })
        .then((result) => {
            if (!result.dismiss) {
                this.taxaService
                    .previaRevisao(this.inscricao.value, this.codigoCentroDeCusto.value)
                    .pipe(take(1))
                    .subscribe(retorno => {
                        Swal.fire({
                            title: retorno.titulo,
                            html: retorno.mensagem,
                            icon: retorno.status,
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger'
                            },
                            buttonsStyling: true,
                            confirmButtonText: "OK",
                            showLoaderOnConfirm: true,
                        })
                        .then((result) => {
                            if (result.isConfirmed && retorno.status != "error") {
                                this.taxaLancamentosAtual = retorno.taxaLancamentosAtual;
                                this.taxaLancamentosRevisado = retorno.taxaLancamentosRevisado;
                            }       
                        });
                    });
            }       
        });

    }

    formatToBRL(valor) {
        return this.mascara.transform(valor, 11);
    }

    buscarMemoriaDeCalculo(codigo){
        let taxaLancamentosAuxiliar = this.taxaLancamentosAtual.filter( taxaLancamento => { return taxaLancamento.codigo == codigo });
        if (taxaLancamentosAuxiliar != null) {
            this.openDialog("memoria-de-calculo", [taxaLancamentosAuxiliar[0], null, "Geral"]);
        }
    }

    buscarMemoriaDeCalculoRevisado(codigo){
        let taxaLancamentosAuxiliar = this.taxaLancamentosRevisado.filter( taxaLancamento => { return taxaLancamento.codigo == codigo });
        if (taxaLancamentosAuxiliar != null) {
            this.openDialog("memoria-de-calculo", [taxaLancamentosAuxiliar[0], null, "Previa"]);
        }
    }

    openDialog(componente, dados) {
        const dialogRef = this.dialog.open(IcadModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            closeOnNavigation: true,
            data: {
                componente: componente,
                dados: dados
            }
        });
    
        // dialogRef.afterClosed().pipe(take(1)).subscribe(result => document.location.reload() );
    }

    acaoBotaoVoltar(){
        this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Cancelar });
    }

    acaoConfirmarRevisao() {
        this.acaoMenuAtendimento.emit({ acao: Constantes.ACAO_ATENDIMENTO.Atualizar_Lancamentos_Pos_Cancelamento });
    }

    // -------------------------------------------------------------------------
    // Ações para salvar os dados - Início
    // -------------------------------------------------------------------------
    acaoBotaoSalvar() {
        var taxaLancamentoAuxiliar = new TaxaLancamento();
        
        if (this.taxaLancamentosRevisado.length) {
            taxaLancamentoAuxiliar = this.taxaLancamentosRevisado[0];
        }

        if (this.taxaLancamentosAtual.length) {
            taxaLancamentoAuxiliar.taxaLancamentoAgregados = this.taxaLancamentosAtual;
        }

        Swal.fire({
            text: `Qual o motivo para efetivar essa revisão?`,
            icon: 'question',
            input: 'textarea',
            width: "60%",
            customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            inputValidator: (value) => {
            if (!value) {
                return 'Favor informar o motivo da revisão!'
            }
            }
        })
        .then((result) => {
            taxaLancamentoAuxiliar.observacao = result.value;
            taxaLancamentoAuxiliar.taxaLancamentoAgregados.forEach( taxaLancamentoAgregadoAuxiliar => {
                taxaLancamentoAgregadoAuxiliar.observacao = result.value;
            })

            if (result.isConfirmed) {
                this.taxaService
                    .gerarRevisao(taxaLancamentoAuxiliar)
                    .pipe(take(1))
                    .subscribe(retorno => {
                        Swal.fire({
                            title: retorno.titulo,
                            html: retorno.mensagem,
                            icon: retorno.status,
                            willClose: () => {
                                this.acaoConfirmarRevisao();
                            }
                        })
                    });
            }
        });

    }

    limpar() {        
        this.taxaLancamentosAtual = null;
        this.taxaLancamentosRevisado = null;
    }

    get cliente() {
        return this.formulario.get("cliente");
    }
    set cliente(cliente) {
        this.formulario.get("cliente").setValue(cliente);
    }
    
    get codigo() {
        return this.formulario.get("codigo");
    }
    set codigo(codigo) {
        this.formulario.get("codigo").setValue(codigo);
    }
    
    get protocolo() {
        return this.formulario.get("protocolo");
    }
    set protocolo(protocolo) {
        this.formulario.get("protocolo").setValue(protocolo);
    }
    
    get inscricao() {
        return this.formulario.get("inscricao");
    }
    set inscricao(inscricao) {
        this.formulario.get("inscricao").setValue(inscricao);
    }
  
    get codigoCentroDeCusto() {
      return this.formulario.get("codigoCentroDeCusto");
    }  
    set codigoCentroDeCusto(codigoCentroDeCusto) {
      this.formulario.get("codigoCentroDeCusto").setValue(codigoCentroDeCusto);
    }


}
