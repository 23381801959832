import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";


@Injectable({ providedIn: 'root' })
export class ConsultaGeralService {

    constructor(private http: HttpClient, private router: Router) {}

    buscar(filtro, valor) {
        return this.http.get<any>(
        `${ environment.icadUrl }/consultaGeral/buscar/${ filtro }/${ valor }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

}