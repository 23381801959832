import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtendimentoPendenciaComponent } from './atendimento-pendencia/atendimento-pendencia.component';
import { AtendimentoProtocoloComponent } from './atendimento-protocolo/atendimento-protocolo.component';
import { InformacoesCadastroModule } from "../informacoes-cadastro/informacoes-cadastro.module";
import { PipeModule } from "src/app/pipes/pipes.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxMaskModule } from "ngx-mask";
import { OutrosModule } from "../outros.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { AtendimentoZoneamentoComponent } from './atendimento-protocolo/atendimento-zoneamento.component';
import { AtendimentoAvaliadorComponent } from './atendimento-protocolo/atendimento-avaliador.component';
import { AtendimentoAtividadeComponent } from './atendimento-protocolo/atendimento-atividade.component';
import { AtendimentoEmbasamentoComponent } from './atendimento-protocolo/atendimento-embasamento.component';
import { AtendimentoRequisitoComponent } from './atendimento-protocolo/atendimento-requisito.component';
import { AtendimentoQuestionarioComponent } from './atendimento-protocolo/atendimento-questionario.component';
import { AtendimentoImobiliarioComponent } from './atendimento-protocolo/atendimento-imobiliario.component';
import { AtendimentoAcaoComponent } from './atendimento-acao/atendimento-acao.component';
import { HistoricoRequisitoComponent } from './atendimento-protocolo/historico-requisito.component';
import { ListaEmpresa } from './atendimento-protocolo/lista-empresa.component';
import { ListaProtocolo } from './atendimento-protocolo/lista-protocolo.component';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { MatDividerModule } from "@angular/material/divider";
import { AlertModule } from "src/app/components/alert/alert.module";
import { AtendimentoPendenciarComponent } from './atendimento-pendenciar/atendimento-pendenciar.component';
import { AtendimentoEmpresaCnpj } from './atendimento-protocolo/atendimento-empresa-cnpj.component';
import { ListaEmpresaCnpj } from './atendimento-protocolo/lista-empresa-cnpj.component';
import { AtendimentoEmpresaComponent } from './atendimento-empresa/atendimento-empresa.component';
import { AtendimentoCriticaComponent } from './atendimento-protocolo/atendimento-critica.component';
import { AtendimentoAlvaraComponent } from './atendimento-alvara/atendimento-alvara.component';
import { HistoricoAtendimentoAlvaraComponent } from './atendimento-alvara/historico-atendimento-alvara.component';
import { AnexarModule } from '../anexar/anexar.module';
import { AtendimentoPendenciaModalComponent } from './atendimento-pendencia/atendimento-pendencia-modal.component';
import { AtendimentoDataAberturaComponent } from './atendimento-protocolo/atendimento-data-abertura.component';
import { AtendimentoBaixaComponent } from './atendimento-protocolo/atendimento-baixa.component';
import { AtendimentoEmpresaRequisitosComponent } from './atendimento-empresa/atendimento-empresa-requisitos.component';
import { AtendimentoRequisitoAcaoComponent } from './atendimento-empresa/atendimento-requisito-acao.component';
import { AtendimentoObservacaoModule } from './atendimento-observacao/atendimento-observacao.module';
import { AtendimentoLicenciamentoComponent } from './atendimento-licenciamento/atendimento-licenciamento.component';
import { AtendimentoGridModule } from './atendimento-grid/atendimento-grid.module';
import { MatTableModule } from '@angular/material/table';
import { NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LinhaDoTempoModule } from '../linha-do-tempo/linha-do-tempo.module';
import { AtendimentoSeletorImComponent } from './atendimento-seletor-im/atendimento-seletor-im.component';
import { AtendimentoRegerarComponent } from './atendimento-protocolo/atendimento-regerar.component';
import { AtendimentoPareceresModule } from './atendimento-pareceres/atendimento-pareceres.module';
import { InformacaoRedeSimModule } from '../informacoes-cadastro/informacao-rede-sim/informacao-rede-sim.module';
import { AtendimentoDespacharComponent } from './atendimento-despachar/atendimento-despachar.component';
import { TaxasModule } from '../taxas/taxas.module';
import { AtendimentoDelegarComponent } from './atendimento-delegar/atendimento-delegar.component';
import { AtendimentoSuspensaoComponent } from './atendimento-suspensao/atendimento-suspensao.component';
import { InformacaoRedeSimPerguntasModule } from '../informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.module';
import { AtendimentoAcaoModule } from './atendimento-acao/atendimento-acao.module';
import { AtendimentoReativacaoComponent } from './atendimento-reativacao/atendimento-reativacao.component';
  

const components = [
  AtendimentoPendenciaComponent, 
  AtendimentoProtocoloComponent, 
  AtendimentoRequisitoComponent,
  AtendimentoZoneamentoComponent,
  AtendimentoAvaliadorComponent,
  AtendimentoAtividadeComponent,
  AtendimentoEmbasamentoComponent,
  AtendimentoQuestionarioComponent,
  AtendimentoImobiliarioComponent,
  AtendimentoRegerarComponent,
  AtendimentoPendenciarComponent,
  HistoricoRequisitoComponent,
  ListaEmpresa,
  ListaProtocolo,
  AtendimentoEmpresaCnpj,
  ListaEmpresaCnpj,
  AtendimentoCriticaComponent,
  AtendimentoAlvaraComponent,
  HistoricoAtendimentoAlvaraComponent,
  AtendimentoBaixaComponent,
  AtendimentoPendenciaModalComponent,
  AtendimentoDataAberturaComponent,
  AtendimentoEmpresaComponent,
  AtendimentoEmpresaRequisitosComponent,
  AtendimentoRequisitoAcaoComponent,
  AtendimentoLicenciamentoComponent,
  AtendimentoSeletorImComponent,
  AtendimentoDespacharComponent,
  AtendimentoDelegarComponent,
  AtendimentoSuspensaoComponent,
  AtendimentoReativacaoComponent,
]

@NgModule({
  declarations: [
    components,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
    InformacoesCadastroModule,
    PipeModule,
    OutrosModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDividerModule,
    PerfectScrollbarModule,
    AlertModule,
    AnexarModule,
    AtendimentoObservacaoModule,
    AtendimentoGridModule,
    MatTableModule,
    NgbDropdownModule,
    NgbAccordionModule,
    LinhaDoTempoModule,
    AtendimentoPareceresModule,
    InformacaoRedeSimModule,
    TaxasModule,
    InformacaoRedeSimPerguntasModule,
    AtendimentoAcaoModule,
  ],
  exports: [
    components
  ]
})
export class AtendimentoModule { }
