import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { TaxaLancamento } from '../../../../model/taxaLancamento';
import { TaxaLancamentoParcela } from '../../../../model/taxaLancamentoParcela';
import { Constantes } from '../../../../model/constantes';
import { MascaraPipe } from '../../../../pipes/mascara.pipe';
import { MatDialog } from '@angular/material/dialog';
import { GridMemoriaDeCalculoComponent } from '../grid-memoria-de-calculo/grid-memoria-de-calculo.component';
import { GridMemoriaDeCalculoParcelaComponent } from '../grid-memoria-de-calculo-parcela/grid-memoria-de-calculo-parcela.component';
import { TaxaService } from 'src/app/services/taxa/taxa.service';
import { take } from 'rxjs/operators';


@Component({
    selector: "app-memoria-de-calculo",
    templateUrl: "./memoria-de-calculo.component.html",
    styleUrls: ["./memoria-de-calculo.component.scss"]
})
export class MemoriaDeCalculoComponent implements OnInit {

    @Output() acaoDeAtendimento = new EventEmitter();
    @Input() tipoDeMemoria: string = "Geral";
    @Input() taxaLancamento: TaxaLancamento;
    @Input() taxaLancamentoParcela: TaxaLancamentoParcela;
    @Input() semCardHeader: boolean = false;

    parcelaSituacao = Constantes.PARCELA_SITUACAO;
    parcelaSituacaoDescricao = Constantes.PARCELA_SITUACAO_DESCRICAO;

    acaoAtendimentoDescricao = Constantes.ACAO_ATENDIMENTO_DESCRICAO;
    acaoAtendimentoClass = Constantes.ACAO_ATENDIMENTO_CLASS;

    @ViewChild(GridMemoriaDeCalculoComponent)
    gridMemoriaDeCalculo: GridMemoriaDeCalculoComponent;

    @ViewChild(GridMemoriaDeCalculoParcelaComponent)
    gridMemoriaDeCalculoParcela: GridMemoriaDeCalculoParcelaComponent;


    constructor(
        private mascara: MascaraPipe,
        private taxaService: TaxaService,
        public dialog: MatDialog) {

            this.taxaLancamento = new TaxaLancamento();
            this.taxaLancamentoParcela = new TaxaLancamentoParcela();
            
    }

    ngOnInit(): void {
        if (this.tipoDeMemoria == "Geral") {
            this.buscarTaxaLancamentoMemoriasPorLancamento();
        }
        else if (this.tipoDeMemoria == "Parcela") {
            this.buscarTaxaLancamentoMemoriasParcelaPorParcela();
        }
        else if (this.tipoDeMemoria == "Previa") {
            this.buscarTaxaLancamentoMemoriasDaPreviaPorLancamento();
        }
    }

    formatToBRL(valor) {
        return this.mascara.transform(valor, 11);
    }

    async buscarTaxaLancamentoMemoriasPorLancamento() {
        await this.taxaService
            .buscarTaxaLancamentoMemoriasPorLancamento(this.taxaLancamento.codigo)
            .pipe(take(1))
            .subscribe( retorno => {
                this.taxaLancamento.taxaLancamentoMemorias = retorno;
                this.preencherGridMemoriaDeCalculo();
            })
    }

    async buscarTaxaLancamentoMemoriasDaPreviaPorLancamento() {
        await this.taxaService
            .buscarTaxaLancamentoMemoriasDaPreviaPorLancamento(this.taxaLancamento.codigo)
            .pipe(take(1))
            .subscribe( retorno => {
                this.taxaLancamento.taxaLancamentoMemorias = retorno;
                this.preencherGridMemoriaDeCalculo();
            })
    }

    preencherGridMemoriaDeCalculo() {
        setTimeout(() => {
            this.gridMemoriaDeCalculo.grid.dados = this.taxaLancamento.taxaLancamentoMemorias;
            this.gridMemoriaDeCalculo.atualizarTabela();
        }, 1);
    }

    async buscarTaxaLancamentoMemoriasParcelaPorParcela() {
        await this.taxaService
            .buscarTaxaLancamentoMemoriasParcelaPorParcela(this.taxaLancamentoParcela.codigo, this.taxaLancamentoParcela.codigoLancamento, this.taxaLancamentoParcela.numero)
            .pipe(take(1))
            .subscribe( retorno => {
                this.taxaLancamentoParcela.taxaLancamentoMemoriasParcela = retorno;
                this.preencherGridMemoriaDeCalculoParcela();
            })
    }

    preencherGridMemoriaDeCalculoParcela() {
        setTimeout(() => {
            this.gridMemoriaDeCalculoParcela.grid.dados = this.taxaLancamentoParcela.taxaLancamentoMemoriasParcela;
            this.gridMemoriaDeCalculoParcela.atualizarTabela();
        }, 1);
    }


}
