import { Component, EventEmitter, Output } from '@angular/core';
import { DattaConfig } from '../../../app-config';

import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
})
export class SubHeaderComponent {
  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavCollapsedMob = new EventEmitter();
  public dattaConfig: any;
  public navCollapsedMob;
  public currentAxis: string;
  exibirConsultaProcesso: boolean = false;
  exibirOs2: boolean = false;
  public codigoCliente: string = "null";

  constructor(private router: Router, 
              private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService) {
    this.dattaConfig = DattaConfig.config;
    this.navCollapsedMob = false;
    this.authenticationService.currentAxis.subscribe((axis) => {
      if (axis != "" && axis != null)
        this.currentAxis = axis.descricao;
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.verificaExibicao();
      });
    this.verificaExibicao();
  }

  verificaExibicao(){
    this.authenticationService.currentAxis.subscribe((axis) => {
      if (axis != "" && axis != null)
        this.currentAxis = axis.descricao;
    });

    this.exibirConsultaProcesso = false;
    this.exibirOs2 = false;
    this.codigoCliente = this.authenticationService.currentUserValue.cliente;
    const currentUser = this.authenticationService.currentUserValue;
    
    if(this.currentAxis.toUpperCase() == "OPERAÇÃO" && currentUser != null && currentUser.perfil != null && currentUser.perfil == 2){
      this.exibirConsultaProcesso = true;
    }

    if(this.currentAxis.toUpperCase() == "OPERAÇÃO" && currentUser != null && currentUser.perfil != null && currentUser.perfil == 2 && this.authenticationService.currentUserValue.codLoginOrgao != ""){
      if(this.codigoCliente == '9999' || this.codigoCliente == '3632' || this.codigoCliente == '545' || this.codigoCliente == '4867'){
        this.exibirOs2 = true;
      }
    }  
  }

  acaoClickHeader(acao) {
    //this.openDialog(acao);
    this.router.navigate(["/operacao/consulta-processo", 200, 81]); 
  }

}
