import { OnInit, Component, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import PerfectScrollbar from 'perfect-scrollbar';

@Component({
    selector: "app-icad-modal",
    templateUrl: "./icad-modal.component.html",
    styleUrls: ["./icad-modal.component.scss"]
})

export class IcadModalComponent implements OnInit, OnDestroy{
    
    public config: PerfectScrollbarConfigInterface = {};
    ps: PerfectScrollbar;

    constructor(
        public dialogRef: MatDialogRef<IcadModalComponent>,
        @Inject(MAT_DIALOG_DATA) public propriedade: DialogData) {
    }

    ngOnInit() {
        // const container = document.querySelector('.mat-dialog-container');
        // this.ps = new PerfectScrollbar(container);
        // this.ps.update();
    }

    ngOnDestroy() {
        // this.ps.destroy();
    }

    onSelecao(event) {
        this.dialogRef.close(event);
    }
}

export interface DialogData {
    titulo: string;
    componente: string;
    colunas: string;
    dados: string;
}