<div class="card">
    <div class="card-header" *ngIf="!semCardHeader">
        <div class="col-12">
            DAM
        </div>
    </div>
    <div class="row row-linha justify-content-start align-items-center titulo-linha p-0">
        <div class="row col-8">
            <p class="card-text titulo-linha">
                INFORME UM EXERCÍCIO PARA LOCALIZAR OS SEUS LANÇAMENTOS
            </p>
        </div>
        <div class="row col-2">
            <input
                #inputExercicio
                class="form-control form-control-sm text-center"
                id="exercicio"
                name="exercicio"
                type="text"
                mask="0000"
                [showMaskTyped]="true"
                id="exercicio" 
                name="exercicio"
                [value]="exercicio"
            />
        </div>
        <div class="row col-2 justify-content-center align-items-center mt-2">
            <button 
                type="button" 
                class="btn btn-secondary"
                title="Buscar"
                id="buscar-lancamentos-dam"
                (click)="buscarTaxaLancamento(inputExercicio.value)"
            >
            Buscar
            </button>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="form-row row-linha justify-content-start align-items-center">
            <div class="col-12">
                <div class="col-12 card-header-linha">
                    <p class="card-text">
                        <b>TODO(S) OS LANÇAMENTO(S) {{ exercicio != '' ? 'DO EXERCÍCIO ' + exercicio : '' }}</b>
                    </p>                    
                </div>
            </div>
        </div>
        <div class="row justify-content-center align-items-center header-atividades">
            <div class="row col-1 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    Código
                </p>
            </div>
            <div class="row col-5 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    Lançamento
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    Data
                </p>
            </div>
            <div class="row col-2 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    Total
                </p>
            </div>
            <div class="row col-1 justify-content-center align-items-center">
                <p class="card-text titulo-atividades">
                    Situação
                </p>
            </div>
            <div class="row col-1"></div>
        </div>
        <div *ngIf="taxaLancamentos == null" class="form-row row-linha m-5">
            <div class="col-12">
                <p class="card-text text-center">
                    Informe um execício para visualizar seus lançamentos.
                </p>
            </div>
        </div>   
    </div>
    <div *ngIf="taxaLancamentos != null" class="card-body p-0 mb-4">
        <div *ngFor="let taxaLancamento of taxaLancamentos; let iTaxaLancamento = index">
            <div class="row row-linha-lancamento pt-3 pb-3" [class.row-cinza]="iTaxaLancamento % 2 != 0">
                <div class="row col-1 justify-content-center align-items-center">
                    <p class="card-text titulo fs-10">
                        {{ taxaLancamento.codigo }}
                    </p>
                </div>
                <div class="row col-5 justify-content-start align-items-center">
                    <p class="card-text titulo fs-10">
                        {{ taxaLancamento.codigoTaxa != '' ? taxaLancamento.codigoTaxa + ' - ' : '' }}{{ taxaLancamento.identificacao }}
                    </p>
                </div>
                <div class="row col-2 justify-content-center align-items-center">
                    <p class="card-text titulo fs-10">
                        {{ taxaLancamento.processamento }}
                    </p>
                </div>
                <div class="row col-2 justify-content-end align-items-center">
                    <p class="card-text titulo fs-10">
                        R$ {{ formatToBRL(taxaLancamento.total) }}
                    </p>
                </div>
                <div class="row col-1 justify-content-center align-items-center">
                    <p class="card-text titulo fs-10"
                    [class.texto-danger]="taxaLancamento.situacao == parcelaSituacao.Vencida"
                    [class.texto-success]="taxaLancamento.situacao == parcelaSituacao.Paga">
                        {{ parcelaSituacaoDescricao[taxaLancamento.situacao] }}
                    </p>
                </div>
                <div class="row col-1 justify-content-end align-items-center">
                    <button  
                        type="button" 
                        matTooltip="Memória de Cálculo"
                        matTooltipPosition="above"
                        class="btn btn-sm p-0 mr-2"
                        (click)="buscarMemoriaDeCalculo(taxaLancamento.codigo)"
                        title="Memória de Cálculo"
                        *ngIf="currentUser == '2'">
                        <svg class="icone-editar">
                            <use
                                attr.xlink:href="assets/sprite.svg#{{ 'book' }}"
                            ></use>
                        </svg>
                    </button>
                    <button  [class.hidden]="lancamentoDetalhado == taxaLancamento.codigo"
                        type="button" 
                        matTooltip="Exibir Parcelas"
                        matTooltipPosition="above"
                        class="btn btn-sm p-0 mr-1"
                        (click)="acaoExibirParcelas(taxaLancamento.codigo)"
                        title="Visualizar Parcelas">
                    <svg class="icone-replicar">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'arrow-down-circle' }}"
                        ></use>
                    </svg>
                    </button>
                    <button  [class.hidden]="lancamentoDetalhado != taxaLancamento.codigo"
                        type="button" 
                        matTooltip="Ocultar Parcelas"
                        matTooltipPosition="above"
                        class="btn btn-sm p-0 mr-1"
                        (click)="acaoOcultarParcelas()"
                        title="Ocultar Parcelas">
                    <svg class="icone-replicar">
                        <use
                            attr.xlink:href="assets/sprite.svg#{{ 'arrow-up-circle' }}"
                        ></use>
                    </svg>
                    </button>
                </div>
            </div>
            <div *ngIf="taxaLancamentos != null" class="card-body p-0">
                <div [class.hidden]="lancamentoDetalhado != taxaLancamento.codigo" class="row row-linha mb-5">
                    <app-grid-parcelas class="w-100" [codigoLancamento]="taxaLancamento.codigo" [exercicio]="exercicio" (acaoMenuAtendimento)="acaoAtendimento($event)"></app-grid-parcelas>
                </div>
            </div>
        </div>
        <div *ngIf="!taxaLancamentos.length" class="card-body p-0">
            <div class="row col-12 justify-content-center align-items-center">
                <p class="card-text text-center">
                    <br>
                    Não existem lançamento(s) ativo(s).
                    <br>&nbsp;
                </p>
            </div>
        </div>
    </div>
</div>