import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { AtendimentoAlvara } from '../../model/atendimentoAlvara';
import { Despacho } from "src/app/model/despacho";


@Injectable({ providedIn: 'root' })
export class AtendimentoService {

    constructor(private http: HttpClient, private router: Router) {}

    buscarGrid(orgao, menu) {
        return this.http.get<any>(
        `${ environment.icadUrl }/grid/buscarGrid/${ orgao }/${ menu }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    buscarGridPorFiltro(orgao, menu, filtro, valor) {
        return this.http.get<any>(
        `${ environment.icadUrl }/grid/buscarGridPorFiltro/${ orgao }/${ menu }/${ filtro }/${ valor.replace('/',"") }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    buscarGridConfiguracaoFiltro(orgao, menu) {
        return this.http.get<any>(
        `${ environment.icadUrl }/grid/buscarGridConfiguracaoFiltro/${ orgao }/${ menu }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    movimentarProtocolo(protocolo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/grid/movimentarProtocolo/${ localStorage.getItem('pId') }/${ protocolo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    movimentarEmpresa(inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/grid/movimentarEmpresa/${ localStorage.getItem('pId') }/${ inscricao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    buscarPendenciasParaAtendimento(codigoTipo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/pendencia/buscarPendenciasParaAtendimento/${ localStorage.getItem('pId') }/${ codigoTipo }`,
        {
            reportProgress: false,
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    buscarPendenciasParaAtendimentoPorOrgao(codigoTipo, codigoOrgao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/pendencia/buscarPendenciasParaAtendimentoPorOrgao/${ localStorage.getItem('pId') }/${ codigoTipo }/${ codigoOrgao }`,
        {
            reportProgress: true,
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    buscarPendenciaEmpresaPorCodigo(inscricao, codigoTipo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/pendencia/buscarPendenciaEmpresaPorCodigo/${ localStorage.getItem('pId') }/${ inscricao }/${ codigoTipo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    vincularAtendimentoPorProtocolo(cliente, orgao, loginOrgao, protocolo){
        return this.http.get<any>(
            `${ environment.icadUrl }/atendimento/vincularAtendimentoPorProtocolo/${ cliente }/${ orgao }/${ loginOrgao }/${ protocolo }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    vincularAtendimentoPorInscricao(cliente, orgao, loginOrgao, inscricao){
        return this.http.get<any>(
            `${ environment.icadUrl }/atendimento/vincularAtendimentoPorInscricao/${ cliente }/${ orgao }/${ loginOrgao }/${ inscricao }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }
    
    buscarParaAtendimento(orgao, menu, protocolo) {
        return this.http.get<any>(
        `${ environment.icadUrl }/atendimento/buscarParaAtendimento/${ orgao }/${ menu }/${ protocolo }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }
    
    buscarParaAtendimentoPorInscricao(orgao, menu, inscricao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/atendimento/buscarParaAtendimentoPorInscricao/${ orgao }/${ menu }/${ inscricao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    buscarTipoDeDocumentoAlvara(cliente){
        return this.http.get<any>(
            `${ environment.icadUrl }/atendimentoAlvara/buscarTipoDeDocumento/${ cliente }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    buscarAtendimentosAlvaraPorInscricao(cliente, inscricao){
        return this.http.get<any>(
            `${ environment.icadUrl }/atendimentoAlvara/buscarAtendimentosAlvaraPorInscricao/${ cliente }/${ inscricao }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    buscarSituacaoAlvaraPorInscricao(cliente, inscricao){
        return this.http.get<any>(
            `${ environment.icadUrl }/atendimentoAlvara/buscarSituacaoAlvaraPorInscricao/${ cliente }/${ inscricao }`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    registrarAtendimento(atendimentoAlvara: AtendimentoAlvara) {
        return this.http.post<any>(
            `${ environment.icadUrl }/atendimentoAlvara/registrarAtendimento`, JSON.stringify(atendimentoAlvara),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    registrarAtendimentoFichaCadastral(atendimentoAlvara: AtendimentoAlvara) {
        return this.http.post<any>(
            `${ environment.icadUrl }/atendimentoAlvara/registrarAtendimentoFichaCadastral`, JSON.stringify(atendimentoAlvara),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    registrarAtendimentoAlvaraProvisorioNosDadosCadastrais(atendimentoAlvara: AtendimentoAlvara) {
        return this.http.post<any>(
            `${ environment.icadUrl }/atendimentoAlvara/registrarAtendimentoAlvaraProvisorioNosDadosCadastrais`, JSON.stringify(atendimentoAlvara),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    registrarAtendimentoDispensaDeAlvaraNosDadosCadastrais(atendimentoAlvara: AtendimentoAlvara) {
        return this.http.post<any>(
            `${ environment.icadUrl }/atendimentoAlvara/registrarAtendimentoDispensaDeAlvaraNosDadosCadastrais`, JSON.stringify(atendimentoAlvara),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    registrarAtendimentoComprovantedeEncerramento(atendimentoAlvara: AtendimentoAlvara) {
        return this.http.post<any>(
            `${ environment.icadUrl }/atendimentoAlvara/registrarAtendimentoComprovantedeEncerramento`, JSON.stringify(atendimentoAlvara),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
 
    urlChat(perfil) {
        return this.http.get<any>(
            `${ environment.icadUrl }/chat/urlChat`,
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    param_login: perfil.toString(),
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    buscarOrgaosParaDespacho(codigoTipo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/despacho/buscarOrgaosParaDespacho/${ localStorage.getItem('pId') }/${ codigoTipo }`,
            {
                reportProgress: true,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    buscarDespachosParaAtendimento(codigo) {
        return this.http.get<any>(
            `${ environment.icadUrl }/despacho/buscarDespachosParaAtendimento/${ localStorage.getItem('pId') }/${ codigo }`,
            {
                reportProgress: false,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    despachar(despacho: Despacho) {
        return this.http.post<any>(
            `${ environment.icadUrl }/despacho/despachar`, JSON.stringify(despacho),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    responderDespacho(despacho: Despacho) {
        return this.http.post<any>(
            `${ environment.icadUrl }/despacho/responder`, JSON.stringify(despacho),
            {
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    
    buscarAtendentes(orgao) {
        return this.http.get<any>(
        `${ environment.icadUrl }/atendimento/buscarAtendentes/${ orgao }`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }
    
    permissoesParaAtendimento() {
        return this.http.get<any>(
        `${ environment.icadUrl }/atendimento/permissoesParaAtendimento`,
        {
            headers: new HttpHeaders({
                app_id: environment.appId,
                param_user: 'ORG',
            }),
        }
        );
    }

    buscarBaixaTfe(inscricao) {
        return this.http.get<any>(
            `${ environment.icadUrl }/atendimentoAlvara/buscarBaixaTfe/${ localStorage.getItem('pId') }/${ inscricao }`,
            {
                reportProgress: false,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    buscarDadosIntegradosTfe(inscricao, codigoRequisito) {
        return this.http.get<any>(
            `${ environment.icadUrl }/atendimentoAlvara/buscarDadosIntegradosTfe/${ localStorage.getItem('pId') }/${ inscricao }/${ codigoRequisito }`,
            {
                reportProgress: false,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }

    buscarParcelasTfe(inscricao, codigoRequisito) {
        return this.http.get<any>(
            `${ environment.icadUrl }/atendimentoAlvara/buscarParcelasTfe/${ localStorage.getItem('pId') }/${ inscricao }/${ codigoRequisito }`,
            {
                reportProgress: false,
                headers: new HttpHeaders({
                    app_id: environment.appId,
                    param_user: 'ORG',
                }),
            }
        );
    }
    
}