<div class="container-principal">
  <mat-tab-group mat-align-tabs="start">
    
    <mat-tab label="Aberto">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
          <img src="../../../../assets/img/icons/clipboard.svg" style="width: 15px;"/>
        </mat-icon> Em Aberto
      </ng-template>
      <app-grid-dam #emAberto></app-grid-dam>
    </mat-tab>

    <mat-tab label="Pagas">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
          <img src="../../../../assets/img/icons/check.svg" style="width: 15px;"/>
        </mat-icon> Pagas
      </ng-template>
      <app-grid-dam #pagas></app-grid-dam>
    </mat-tab>

    <mat-tab label="Finalizado">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
          <img src="../../../../assets/img/icons/check.svg" style="width: 15px;"/>
        </mat-icon> Finalizadas
      </ng-template>
      <app-grid-dam #finalizado></app-grid-dam>
    </mat-tab>

   </mat-tab-group>
</div>
