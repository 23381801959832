import { GridFiltro } from './gridFiltro';
import { Protocolo } from './protocolo';
import { Empresa } from './empresa';
import { EmpresaAcompanhamento } from './empresaAcompanhamento';
import { TaxaLancamento } from './taxaLancamento'; 

export class ConsultaGeral {
    codigo: string;
    cliente: string;
    valor: string;
    qtdTotal: string;
    mensagem: string;
    filtro: Array<GridFiltro>;
    protocolos: Array<Protocolo>;
    empresas: Array<Empresa>;
    empresasAcompanhamento: Array<EmpresaAcompanhamento>;
    taxaLancamentos: Array<TaxaLancamento>;

    constructor() {
        this.codigo = "";
        this.cliente = "";
        this.valor = "";
        this.qtdTotal = "";
        this.mensagem = "";
        this.filtro = new Array<GridFiltro>();
        this.protocolos = new Array<Protocolo>();
        this.empresas = new Array<Empresa>();
        this.empresasAcompanhamento = new Array<EmpresaAcompanhamento>();
        this.taxaLancamentos = new Array<TaxaLancamento>();
    }

 
}