import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-empresas",
    templateUrl: "./empresas.component.html"
})
export class EmpresasComponent implements OnInit {
    
    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        
    }

}