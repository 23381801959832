<div class="card">
    <div class="card-header">
        <div class="col-12">
            <strong>Informações para encerramento</strong>
        </div>
    </div>
    <div class="card-body">
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Motivo</strong>
                </p>
            </div>
            <div class="col-10">
                <p *ngFor="let motivoEncerramento of protocolo.encerramento.motivosEncerramento"
                    class="card-text" id="lbl-motivo-encerramento">
                    {{ motivoEncerramento.descricao }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Data Pretendida</strong>
                </p>
            </div>
            <div class="col-4">
                <p class="card-text" id="lbl-data-pretendida-encerramento">
                    {{ protocolo.encerramento.data }}
                </p>
            </div>
        </div>
        <div class="form-row row-linha linha-grid-divisao justify-content-start align-items-center">
            <div class="col-2">
                <p class="card-text">
                    <strong>Observações</strong>
                </p>
            </div>
            <div class="col-4">
                <p class="card-text" id="lbl-descricao-encerramento">
                    {{ protocolo.encerramento.descricao  }}
                </p>
            </div>
        </div>
    </div>
</div>