<app-alert type="warning"
    *ngIf="!emitirErro && !emitirCritica && enderecoRegra.tipoEndereco != '4' && enderecoRegra.tipoEndereco != '1' && liberaBusca" >
    Realize uma busca para preencher os campos bloqueados.
</app-alert>
<app-alert type="danger"
    *ngIf="formulario.invalid && formulario.touched && emitirErro" >
    Favor preencher os campos destacados na página, caso os campos estejam bloqueados, realizar uma busca para preenchê-los.
</app-alert>
<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div class="card" *ngIf="enderecoRegra.tipoEndereco == '2'">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Tipo de endereço</strong>
            </div>
        </div>
        <div class="card-body">
            <mat-radio-group 
            #tipoUrbanoRuralInput 
            (change)="acaoTipoUrbanoRuralInput()"
            formControlName="tipoUrbanoRural" 
            aria-label="Selecione uma opção">
                <div class="row">
                    <div class="form-group col-4">
                        <p class="card-text titulo">
                            <mat-radio-button value="1" *ngIf="enderecoRegra.liberarEnderecoUrbano == 'S'"
                                ><strong>Urbano</strong>
                            </mat-radio-button>
                        </p>
                        <p class="card-text observacao">Imóvel localizado dentro do perímetro urbano definido para o município.</p>
                    </div>
                    <div class="form-group col-4">
                        <p class="card-text titulo">
                            <mat-radio-button value="2" *ngIf="enderecoRegra.liberarEnderecoRural == 'S'"
                                ><strong>Rural</strong>
                            </mat-radio-button>
                        </p>
                        <p class="card-text observacao">Imóvel localizado fora do perímetro urbano definido para o município.</p>
                    </div>
                </div>
            </mat-radio-group>
        </div>
    </div>

    <div class="card" *ngIf="this.tipoUrbanoRural.value == '1' && enderecoRegra.tipoEndereco != '4' && liberaBusca">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Aqui você localiza seu endereço</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row" >
                <div class="form-group col-3">
                    <label class="col-sm-12 col-form-label" for="tipoBusca">Tipo de Busca</label>
                    <select 
                        #tipoBuscaSelect
                        [class.is-invalid]="tipoBusca.invalid && tipoBusca.touched"
                        (change)="acaoTipoBuscaSelect(tipoBuscaSelect.value)"
                        formControlName="tipoBusca"
                        class="form-control form-control-sm"
                        name="tipoBusca" 
                        id="tipoBusca"                
                    >
                        <option disabled value="">--- Selecione ---</option>
                        <option value="C" *ngIf="enderecoRegra.buscarPorCep == 'S'"
                            >Cep
                        </option>
                        <option value="I" *ngIf="enderecoRegra.buscarPorInscricaoImobiliaria == 'S'"
                            >Inscrição Imobiliária
                        </option>
                        <option value="L" *ngIf="enderecoRegra.buscarPorLogradouro == 'S'"
                            >Logradouro
                        </option>
                    </select>
                    <div class="mb-1" *ngIf="tipoBusca.invalid && tipoBusca.touched">
                        <span 
                            class="erro" 
                            *ngIf="tipoBusca.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-2" *ngIf="tipoBuscaSelect.value === 'L'">
                    <label class="col-sm-12 col-form-label" for="tipoBuscaValor">Estado</label>
                    <select 
                        #estadoBuscaSelect
                        (change)="acaoTipoBuscaSelect(tipoBuscaSelect.value)"
                        formControlName="estadoBusca"
                        class="form-control form-control-sm"
                        name="estadoBusca" 
                        maxlength="2"
                        id="estadoBusca"                
                    >
                        <option value=""></option>

                        <option *ngFor="let estado of estados" 
                            value="{{ estado }}"
                        >
                            {{ estado }}
                        </option>
                    </select>
                </div>
                <div 
                    [class.col-7]="tipoBuscaSelect.value !== 'L'"
                    [class.col-5]="tipoBuscaSelect.value === 'L'"
                    class="form-group"
                >
                    <label class="col-sm-12 col-form-label" for="tipoBuscaValor">&nbsp;</label>
                    <input
                        #tipoBuscaValorInput
                        (input)="tipoBuscaSelect.value == 'C' ? formatToCep(tipoBuscaValorInput) : ''"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="tipoBuscaValor"
                        id="tipoBuscaValor"
                        name="tipoBuscaValor"                
                        type="text"
                        [maxlength]="maxLengthBuscaValor"
                    />
                </div>
                <div class="form-group col-2">
                    <label class="col-sm-12 col-form-label" for="tipoBuscaValor">&nbsp;</label>
                    <button 
                        type="button" 
                        class="btn btn-secondary"
                        title="Buscar"
                        (click)="buscarEndereco()"
                        [disabled] = "tipoBuscaSelect.value == '' && tipoBuscaValorInput == ''"
                    >
                    Buscar
                    </button>
                </div>
            </div>
        </div>
    </div>
   
    <div class="card"
        [class.card-2]="layoutConfiguracao">
        <div class="card-header">
            <div class="row col-12 justify-content-start align-items-center">
                <strong>Dados do endereço</strong>
            </div>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="cep">Cep</label>
                    <input
                        #cepInput
                        mask="99999-999"
                        [class.is-invalid]="cep.invalid && cep.touched"
                        (blur)="buscarCep(cepInput.value)"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="cep"
                        id="cep"
                        name="cep"                
                        type="text"
                        maxlength="9"
                    />
                    <div class="mb-1" *ngIf="cep.invalid && cep.touched">
                        <span 
                            class="erro" 
                            *ngIf="cep.errors.required"
                        >Campo obrigatório</span>
                        <span 
                            class="erro" 
                            *ngIf="cep.errors.minlength"
                        >O Cep deve conter ao menos 9 caracteres.</span>
                        <span 
                            class="erro" 
                            *ngIf="cep.errors.maxlength"
                        >O Cep deve conter até 18 caracteres.</span>
                    </div>
                </div>
            </div>
            <div class="form-row" *ngIf="enderecoRegra.tipoEndereco == '2' && tipoUrbanoRural.value != '3'">
                <div class="form-group col-4">
                    <label 
                        #inscricaoFormatadaLabel
                        class="col-sm-12 col-form-label"
                        for="inscricaoFormatada">
                        {{ tipoUrbanoRural.value != '2' ? enderecoRegra.urbanoNomeCampo : enderecoRegra.ruralNomeCampo }}    
                    </label>
                    <input
                        mat-raised-button
                        [matTooltip]="tipoUrbanoRural.value != '2' ? enderecoRegra.urbanoFraseAjuda : enderecoRegra.ruralFraseAjuda"
                        matTooltipPosition="right"
                        (blur)="validarInscricao()"
                        [class.is-invalid]="inscricaoFormatada.invalid && inscricaoFormatada.touched"
                        [class.is-valid]="inscricaoFormatadaValida"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="inscricaoFormatada"
                        id="inscricaoFormatada"
                        name="inscricaoFormatada"                
                        type="text"
                        maxlength="30"
                    />
                    <div class="mb-1" *ngIf="inscricaoFormatada.invalid && inscricaoFormatada.touched">
                        <span 
                            class="erro" 
                            *ngIf="inscricaoFormatada.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-row col-8 justify-content-start align-items-center pt-3">
                    <mat-checkbox #chkSemRegularizacao
                        (change)="acaoSemRegularizacao(chkSemRegularizacao)"
                        [checked]="enderecoSemRegularizacao"
                        formControlName="semRegularizacao"
                        id="semRegularizacao"
                        name="semRegularizacao">
                        Sem Regularização - Imóvel irregular no município.
                    </mat-checkbox>
                </div>
                
            </div>
            <div class="form-row">
                <div class="form-group col-2">
                    <label class="col-sm-12 col-form-label" for="tipoLogradouro">Tipo</label>
                    <select #tipoLogradouroCodigoSelect
                        [class.is-invalid]="tipoLogradouroCodigo.invalid && tipoLogradouroCodigo.touched"
                        formControlName="tipoLogradouroCodigo"
                        class="form-control form-control-sm"
                        name="tipoLogradouroCodigo" 
                        id="tipoLogradouroCodigo"                
                    >
                        <option value=""></option>

                        <option *ngFor="let tipo of tiposLogradouros" 
                            value="{{ tipo.id }}"
                        >
                            {{ tipo.valor }}
                        </option>
                    </select>
                    <div class="mb-1" *ngIf="tipoLogradouroCodigo.invalid && tipoLogradouroCodigo.touched">
                        <span 
                            class="erro" 
                            *ngIf="logradouro.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-8">
                    <label class="col-sm-12 col-form-label" for="logradouro">Logradouro</label>
                    <input
                        [class.is-invalid]="logradouro.invalid && logradouro.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="logradouro"
                        id="logradouro"
                        name="logradouro"                
                        type="text"
                    />
                    <div class="mb-1" *ngIf="logradouro.invalid && logradouro.touched">
                        <span 
                            class="erro" 
                            *ngIf="logradouro.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-2">
                    <label class="col-sm-12 col-form-label" for="numero">Número</label>
                    <input
                        [class.is-invalid]="numero.invalid && numero.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="numero"
                        id="numero"
                        name="numero"                
                        type="text"
                    />
                    <div class="mb-1" *ngIf="numero.invalid && numero.touched">
                        <span 
                            class="erro" 
                            *ngIf="numero.hasError('numeroOuComplemento')"
                        >Campo obrigatório</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12">
                    <label class="col-sm-12 col-form-label" for="complemento">Complemento</label>
                    <input
                        (input)="numero.updateValueAndValidity()"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="complemento"
                        id="complemento"
                        name="complemento"                
                        type="text"
                    />
                </div>
                <div class="form-group col-6" *ngIf="enderecoRegra.tipoEndereco == '2'">
                    <label class="col-sm-12 col-form-label" for="pontoReferencia">Ponto de Referência</label>
                    <input
                        [class.is-invalid]="pontoReferencia.invalid && pontoReferencia.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="pontoReferencia"
                        id="pontoReferencia"
                        name="pontoReferencia"                
                        type="text"
                    />
                    <div class="mb-1" *ngIf="pontoReferencia.invalid && pontoReferencia.touched">
                        <span 
                            class="erro" 
                            *ngIf="pontoReferencia.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="bairro">Bairro</label>
                    <input
                        [class.is-invalid]="bairro.invalid && bairro.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="bairro"
                        id="bairro"
                        name="bairro"                
                        type="text"
                    />
                    <div class="mb-1" *ngIf="bairro.invalid && bairro.touched">
                        <span 
                            class="erro" 
                            *ngIf="bairro.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="cidade">Cidade</label>
                    <input
                        [class.is-invalid]="cidade.invalid && cidade.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="cidade"
                        id="cidade"
                        name="cidade"                
                        type="text"
                    />
                    <div class="mb-1" *ngIf="cidade.invalid && cidade.touched">
                        <span 
                            class="erro" 
                            *ngIf="cidade.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
                <div class="form-group col-4">
                    <label class="col-sm-12 col-form-label" for="estado">Estado</label>
                    <input
                        [class.is-invalid]="estado.invalid && estado.touched"
                        autofocus
                        class="form-control form-control-sm"
                        formControlName="estado"
                        id="estado"
                        name="estado" 
                        maxlength="2"               
                        type="text"
                    />
                    <div class="mb-1" *ngIf="estado.invalid && estado.touched">
                        <span 
                            class="erro" 
                            *ngIf="estado.errors.required"
                        >Campo obrigatório</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12 text-center" (click)="visualizarGoogleMaps()">
                    <p class="card-text btn texto-info">
                        <strong>Visualizar o endereço preenchido no Google Maps</strong> 
                    </p>
                </div>
            </div>
        </div>
    </div>

</form>