import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";

import { ProtocoloService } from "../../../../services/operacao/protocolo.service";
import { Constantes } from '../../../../model/constantes';
import { FormRequerenteComponent } from "../../../../pages/outros/form-requerente/form-requerente.component";
import { FormLocalizacaoComponent } from "../../../../pages/outros/form-localizacao/form-localizacao.component";
import { FormAtividadeComponent } from "../../../../pages/outros/form-atividade/form-atividade.component";
import { FormResumoComponent } from "../../../../pages/outros/form-resumo/form-resumo.component";
import { Configuracao } from "../../../../model/configuracao";
import { Servico } from "../../../../model/servico";
import { MatStepper } from "@angular/material/stepper";
import { Protocolo } from "../../../../model/protocolo";
import { Processo } from "../../../../model/processo";
import { Documento } from "../../../../model/documento";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import { EmpresaService } from "../../../../services/empresa/empresa.service";
import { Empresa } from "../../../../model/empresa";
import { Atividade } from "../../../../model/atividade";
import { FormIdentificacaoNegocioComponent } from "../../../../pages/outros/form-identificacao-negocio/form-identificacao-negocio.component";
import { FormIdentificacaoRequerenteComponent } from '../../../outros/form-identificacao-requerente/form-identificacao-requerente.component';
import { FormTipoNegocioComponent } from '../../../outros/form-tipo-negocio/form-tipo-negocio.component';
import { ConfiguracaoService } from '../../../../services/configuracao/configuracao.service';
import { AtendimentoRequisitoComponent } from '../../../outros/atendimento/atendimento-protocolo/atendimento-requisito.component';
import { InformacaoRequerenteComponent } from '../../../outros/informacoes-cadastro/informacao-requerente/informacao-requerente.component';
import { RequisitoService } from '../../../../services/requisito/requisito.service';
import { Console } from "console";
import { threadId } from "worker_threads";
import { ThrowStmt } from "@angular/compiler";
import { AtendimentoAcaoComponent } from "../../atendimento/atendimento-acao/atendimento-acao.component";

@Component({
    selector: "app-abertura-oficio",
    templateUrl: "./abertura-oficio.component.html"
})
export class AberturaOficioComponent implements OnInit {
    
    tipoEmpresa: string;
    informativo: string;
    stepper: MatStepper;
    passoAnterior: number;
    passoAtual: number;
    configuracao: Configuracao;
    processo: Processo;
    tipoProcesso = Constantes.PROCESSO;
    servicos: Array<Servico>;
    protocolo: Protocolo;
    documentos: Array<Documento>;

    // Formulários
    formAlteracao: FormGroup;
    formularioIdentificacaoRequerente: FormGroup;
    formularioRequerente: FormGroup;
    formularioTipoNegocio: FormGroup;
    formularioIdentificacao: FormGroup;
    formularioIdentificacaoNegocio: FormGroup;
    formularioLocalizacao: FormGroup;
    formularioAtividade: FormGroup;
    formularioResumoAlteracao: FormGroup;

    @ViewChild(FormIdentificacaoRequerenteComponent)
    formIdentificacaoRequerente: FormIdentificacaoRequerenteComponent;

    @ViewChild(FormTipoNegocioComponent)
    formTipoNegocio: FormTipoNegocioComponent;

    @ViewChild(FormIdentificacaoNegocioComponent)
    formIdentificacaoNegocio: FormIdentificacaoNegocioComponent;

    @ViewChild(FormRequerenteComponent)
    formRequerente: FormRequerenteComponent;

    @ViewChild(InformacaoRequerenteComponent)
    informacaoRequerente: InformacaoRequerenteComponent;

    @ViewChild(FormLocalizacaoComponent)
    formLocalizacao: FormLocalizacaoComponent;

    @ViewChild(FormAtividadeComponent)
    formAtividade: FormAtividadeComponent;    

    @ViewChild(AtendimentoRequisitoComponent)
    atendimentoRequisito: AtendimentoRequisitoComponent;

    @ViewChild(FormResumoComponent)
    formResumo: FormResumoComponent;

    // Botões
    btnAnteriorLocalizacao: string = "Anterior";
    btnAvancarLocalizacao: string = "Avançar";

    // Botões
    btnAnteriorResponsavel: string = "Anterior";
    btnAvancarResponsavel: string = "Avançar";

    emitirErroIdentificacao: boolean;
    mensagemErro: string;

    constructor(
        private router: Router,
        private protocoloService: ProtocoloService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private requisitoService: RequisitoService,
        private configuracaoService: ConfiguracaoService) {

        this.tipoEmpresa = this.activatedRoute.snapshot.data["tipoEmpresa"];    

        this.protocolo = new Protocolo();
        this.protocolo.cliente = localStorage.getItem("pId");

        this.formAlteracao = this.formBuilder.group({
            controleValidade: ["", [Validators.required]],
        });

        this.informativo = Constantes.INFORMATIVO_ABERTURA_OFICIO[0];

        this.documentos = new Array<Documento>();

        setTimeout(() => {
            this.formularioIdentificacaoRequerente = this.formIdentificacaoRequerente.formulario;
            this.formularioTipoNegocio = this.formTipoNegocio.formulario;
            this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioResumoAlteracao = this.formResumo.formulario;
        }, 0);

        this.processo = new Processo();
        this.protocolo.processo = this.processo;
        this.emitirErroIdentificacao = false;
    }

    ngOnInit(): void {
        this.passoAnterior = 0;
        this.passoAtual = 0;
    }

    verificaValidacaoFormulario(formGroup: FormGroup) {
        
        Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
            controle.markAsTouched();
        });
    }

    preencherCamposIdentificacaoNegocio() {
        this.formIdentificacaoNegocio.preencherCampos(this.protocolo);

        if(this.protocolo.processo.codigo == 'U' && Constantes.TIPO_EMPRESA.Autonomo){
            this.formIdentificacaoNegocio.cpfAutonomo.disable();
        }

    }

    preencherCamposEndereco() {
        this.formLocalizacao.exibeFormularioEndereco = !this.protocolo.viabilidade.enderecos.length;
        this.protocolo.viabilidade.enderecos.forEach( endereco => {
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Empresarial) {
                this.formLocalizacao.preenchimentoInicial= false;
                this.formLocalizacao.preencherEnderecoEmpresarial(endereco);
            }
            if (endereco.tipoEndereco == Constantes.TIPO_DE_ENDERECO.Correspondencia) {
                this.formLocalizacao.preencherEnderecoCorrespondencia(endereco);
            }
        })
    }

    preencherCamposAtividade() {

        this.formAtividade.atividadesSelecionadas = new Array<Atividade>();
        this.formAtividade.atividadesSelecionadas = this.protocolo.viabilidade.atividadesExercidas.concat(this.protocolo.viabilidade.atividadesNaoExercidas);

        this.formAtividade.tipoUnidade.setValue(this.protocolo.viabilidade.tipoUnidade);
        this.formAtividade.tipoUnidadeAuxiliar.setValue(this.protocolo.viabilidade.tipoUnidadeAuxiliar);
        
        this.protocolo.viabilidade.formasAtuacao.forEach( formaAtuacao => {
            this.formAtividade.formasAtuacaoSelecionadas.push(formaAtuacao.codigo);
        })
        this.formAtividade.descricaoAtividadeEconomica.setValue(this.protocolo.viabilidade.descricaoAtividadeEconomica);
    }

    avancarPassoIdentificacao(passos) {

        if (this.stepper == null) {
            this.stepper = passos;
        }

        if(this.formTipoNegocio.tipoNegocio.value == ''){
            Swal.fire("", "Selecione o tipo do negócio.", "error");
            return false;    
        }

        this.formAtividade.tipoEmpresa = this.formTipoNegocio.tipoNegocio.value;
        
        this.formResumo.nomeUsuario = this.formIdentificacaoRequerente.nomeUsuario;
        this.formResumo.documentoUsuario = this.formIdentificacaoRequerente.documentoUsuario;
        this.formResumo.emailUsuario = this.formIdentificacaoRequerente.emailUsuario;
        this.formResumo.dataAtual = this.formIdentificacaoRequerente.dataAtual;
        this.formResumo.dadosAuditor = true;
        this.formResumo.tipoEmpresa = this.formTipoNegocio.tipoNegocio.value;

        this.stepper.next();
        
    }

    avancarPassoIdentificacaoContribuinte() {
       
        this.formRequerente.tipoEmpresa = this.formTipoNegocio.tipoNegocio.value;
        this.formularioRequerente = this.formRequerente.formulario;

        if(this.formRequerente.cpfCnpj.value != "" && this.formRequerente.nome.value == ""){
            Swal.fire("", "Caso tenha informado o CPF/CNPJ do contribuinte, é necessário informar também o nome.", "error");    
            return false;
        }
        if(this.formRequerente.nome.value != "" && this.formRequerente.cpfCnpj.value == ""){
            Swal.fire("", "Caso tenha informado o nome do contribuinte, é necessário informar também o CPF/CNPJ.", "error");    
            return false;
        }
        
        this.tipoEmpresa = this.formTipoNegocio.tipoNegocio.value;
        this.formResumo.atendimentoAcao.tipoEmpresa = this.formTipoNegocio.tipoNegocio.value;
        
        this.verificaValidacaoFormulario(this.formRequerente.formulario);
        if (this.formularioRequerente.valid) {
            this.formIdentificacaoNegocio.emitirErro = false;
            this.formIdentificacaoNegocio.mensagemErro = "";
            this.stepper.next();
        }
        else {
            let mensagemErro = "";
       
            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.formIdentificacaoNegocio.emitirErro = true;
            this.formIdentificacaoNegocio.mensagemErro = mensagemErro;
        }
    }
    
    avancarPassoIdentificacaoNegocio() {
        this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
        this.verificaValidacaoFormulario(this.formIdentificacaoNegocio.formulario);

        this.formularioRequerente = this.formRequerente.formulario;
        this.formRequerente.tipoEmpresa = this.formTipoNegocio.tipoNegocio.value;
        //this.formRequerente.limparCampos();

        this.verificaValidacaoFormulario(this.formRequerente.formulario);
        if (this.formularioIdentificacaoNegocio.valid && this.formularioRequerente.valid) {
            this.formIdentificacaoNegocio.emitirErro = false;
            this.formIdentificacaoNegocio.mensagemErro = "";
            this.stepper.next();
        }
        else {
            let mensagemErro = "";
            
            if (this.formularioIdentificacaoNegocio.invalid) {
                mensagemErro += "Favor corrigir os campos obrigatórios.<br>"
            }

            Swal.fire("", "Corrigir os erros descritos no começo da página.", "error");

            this.formIdentificacaoNegocio.emitirErro = true;
            this.formIdentificacaoNegocio.mensagemErro = mensagemErro;
        }
    }

    renomearBotaoLocalizacao() {
        this.btnAnteriorLocalizacao = "Anterior";
        this.btnAvancarLocalizacao = "Avançar";

        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (!this.formLocalizacao.preenchimentoInicial) {
                this.btnAnteriorLocalizacao = "Cancelar";
                this.btnAvancarLocalizacao = "Confirmar";
            }
        }
    }

    voltarPassoLocalizacao() {
        if (this.formLocalizacao.exibeFormularioEndereco) {
            if (this.formLocalizacao.preenchimentoInicial) {
                this.stepper.previous();
            }
            else {
                this.formLocalizacao.toggle();
            }
        }
        else {
            this.stepper.previous();
        }
    }

    avancarPassoLocalizacao() {
        this.formularioLocalizacao = this.formLocalizacao.formulario;
        this.verificaValidacaoFormulario(this.formLocalizacao.formulario);
        if (this.formLocalizacao.validarFormulario())
            this.stepper.next();
        
    }

    avancarPassoAtividade() {
        this.verificaValidacaoFormulario(this.formAtividade.formulario);
        if (this.formAtividade.validarFormulario())
            this.stepper.next();
    }

    mudancaPasso(event, passos) {
        if (this.stepper == null) {
            this.stepper = passos;
        } 

        if (event.selectedIndex == 0) {
            this.formularioIdentificacaoRequerente = this.formIdentificacaoRequerente.formulario;
            this.formularioIdentificacaoNegocio = this.formIdentificacaoNegocio.formulario;
            this.formularioRequerente = this.formRequerente.formulario;
            this.formularioLocalizacao = this.formLocalizacao.formulario;
            this.formularioAtividade = this.formAtividade.formulario;
            this.formularioResumoAlteracao = this.formResumo.formulario;
            this.inicializacao();
        }
        
        if (event.selectedIndex == 3) {
            this.formLocalizacao.controleValidade.setValue("");
        }

        if ( event.selectedIndex == 4) {
            this.formAtividade.controleValidade.setValue("");
        }
        
        this.informativo = Constantes.INFORMATIVO_ABERTURA_OFICIO[event.selectedIndex];
        this.passoAtual = event.selectedIndex;
        this.passoAnterior = event.previouslySelectedIndex;

        this.montarOficio(event);
    }

    montarOficio(event) {
        if ( event.previouslySelectedIndex == 0) {

            this.montarConfiguracao();
          
            let documento = new Documento();
            documento.tipo = Constantes.TIPO_DE_DOCUMENTO.Cpf;
            documento.nome = "CPF";
            documento.numero = localStorage.getItem("userId");

            this.protocolo.solicitante.documentos = [documento];
            this.protocolo.data = new Date().toLocaleDateString();         
            this.protocolo.data = new Date().toLocaleDateString("pt-br");
            
        }
        else if ( event.previouslySelectedIndex == 2) {
            //this.formIdentificacaoNegocio.preencher();
            this.protocolo = this.formIdentificacaoNegocio.protocolo;
            
            this.formIdentificacaoRequerente.preencher();

            this.formRequerente.preencher();
            this.protocolo.requerente = this.formRequerente.requerente;
            this.protocolo.registro = this.formRequerente.registro;
            this.processo.servicos = [this.formRequerente.servicoEscolhido];
        }
        else if (event.previouslySelectedIndex == 3) { 
            this.formIdentificacaoNegocio.preencher();
        }    
        else if (event.previouslySelectedIndex == 4) {
            this.protocolo.viabilidade.enderecos = [
                this.formLocalizacao.enderecoEmpresarial,
                this.formLocalizacao.enderecoCorrespondencia,
            ];
            this.formResumo.apenasCorrespondencia = this.formLocalizacao.apenasCorrespondencia.value;
            this.protocolo.registro.estabelecido = "S";

            //SETANDO FLUXO DE AUTONOMO NÃO ESTABELECIDO NA MÃOs
            if(this.formLocalizacao.apenasCorrespondencia.value){
                this.protocolo.registro.estabelecido = "N";
            }          
            this.formAtividade.preencher();

            this.protocolo.viabilidade.atividadesExercidas = this.formAtividade.atividadesExercidas;
            this.protocolo.viabilidade.atividadesNaoExercidas = this.formAtividade.atividadesNaoExercidas;

            this.protocolo.viabilidade.tipoUnidade = this.formAtividade.tipoUnidade.value;
            this.protocolo.viabilidade.tipoUnidadeAuxiliar = this.formAtividade.tipoUnidadeAuxiliar.value;
            
            this.protocolo.viabilidade.formasAtuacao = this.formAtividade.formasAtuacao;
            this.protocolo.viabilidade.descricaoAtividadeEconomica = this.formAtividade.descricaoAtividadeEconomica.value;
                 
            this.inicializarRequisitos(); 
             
            
        }
        else if (event.previouslySelectedIndex == 5) {
            this.protocolo.viabilidade.requisitos = this.atendimentoRequisito.requisitosAdicionados;
        }
        
        this.formResumo.protocolo = this.protocolo;
        this.formResumo.preencher();
        
    }

    montarConfiguracao() {
        if (this.formTipoNegocio.tipoNegocio != null) {
            if (this.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo) {
                this.configuracaoService
                    .buscarConfiguracaoPorServicoEFase(
                        Constantes.SERVICO_DE_ACOMPANHAMENTO.Abertura_De_Autonomo_Por_Oficio,
                        Constantes.FASE_DE_ACOMPANHAMENTO.Dados_Cadastrais
                    )
                    .pipe(take(1))
                    .subscribe( retorno => {
                        this.configuracao = retorno;
                        this.montarObjetoProcesso(this.configuracao.processos[0]);
                        this.inicializacao();
                    })
            }
            else if (this.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Abertura_Oficio_Empresa || this.formTipoNegocio.tipoNegocio.value == Constantes.TIPO_EMPRESA.Abertura_Oficio_Mei) {
                this.configuracaoService
                    .buscarConfiguracaoPorServicoEFase(
                        Constantes.SERVICO_DE_ACOMPANHAMENTO.Abertura_De_Empresa_Por_Oficio,
                        Constantes.FASE_DE_ACOMPANHAMENTO.Dados_Cadastrais
                    )
                    .pipe(take(1))
                    .subscribe( retorno => {
                        this.configuracao = retorno;
                        this.montarObjetoProcesso(this.configuracao.processos[0]);
                        this.inicializacao();
                    })
            }
        }
    }

    montarObjetoProcesso(processo) {
        Object.assign(this.processo, processo);
        this.formRequerente.servicos = processo.servicos;
    }

    inicializacao() {
        this.formIdentificacaoNegocio.tipoEmpresa = this.formTipoNegocio.tipoNegocio.value;
        this.formIdentificacaoNegocio.inicializacaoFormulario();

        this.formRequerente.tipoEmpresa = this.formTipoNegocio.tipoNegocio.value;
        this.formResumo.tipoEmpresa = this.formTipoNegocio.tipoNegocio.value;
        this.formIdentificacaoNegocio.limpar();
        this.formRequerente.limparCampos();
        this.formLocalizacao.limparEnderecoEmpresarial();
        this.formLocalizacao.limparEnderecoCorrespondencia();
        this.formAtividade.limpar();
        this.formRequerente.montarConfiguracao();
        this.atendimentoRequisito.inicializar();

    }

    inicializarRequisitos() {
        let atividades = "";
        this.protocolo.viabilidade.atividadesExercidas.forEach( (atividade: Atividade) => {
            if (this.formIdentificacaoRequerente.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo) {
                atividades += atividade.codigoInterno
            }
            else {
                atividades += atividade.codigo
            }
        })
        
        this.protocolo.viabilidade.atividadesNaoExercidas.forEach( (atividade: Atividade) => {
            if (this.formIdentificacaoRequerente.tipoEmpresa == Constantes.TIPO_EMPRESA.Abertura_Oficio_Autonomo) {
                atividades += atividade.codigoInterno
            }
            else {
                atividades += atividade.codigo
            }
        })

        this.requisitoService
            .buscarParaAtendimentoOficio(this.processo.servicos[0].codigoAuxiliar, this.processo.tipoPessoa, atividades)
            .pipe(take(1))
            .subscribe( requisitos => {
                this.atendimentoRequisito.preencher(requisitos, this.protocolo);
            })
    }

    gerarAbertura() {
        this.formResumo.preencherAnexos();
        
        if (this.formResumo.atendimentoAcao != null && this.formResumo.atendimentoAcao.geraInscricao == "M") {
            this.protocolo.inscricao = this.formResumo.atendimentoAcao.inscricaoDigitada.value;
        }
        
        this.protocolo.processo = this.processo;
        if (!this.formResumo.termoDeAceite.value) {
            Swal.fire("", "Para que a solicitação seja finalizada ,você devera estar de acordo com o termo de responsabilidade.", "info")
            return false;
        }

        this.protocoloService
            .gerarAberturaPorOficio(this.protocolo)
            .pipe(take(1))
            .subscribe( retorno => {
                Swal.fire({
                    title: retorno.titulo,
                    html: retorno.mensagem,
                    icon: retorno.status,
                    willClose: () => {
                        this.router.navigate(["/operacao"], {relativeTo: this.activatedRoute});
                    }
                })
            });
    }

}