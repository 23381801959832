import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NaoEncontradoComponent } from "./pages/outros/erros/nao-encontrado/nao-encontrado.component";
import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { InlineSVGModule } from "ng-inline-svg";
import { HomeComponent } from "./pages/home/home.component";
import { CoreComponent } from "./components/core/core.component";
import { ComponentsModule } from "./components/components.module";
import { AutenticacaoModule } from "./pages/outros/autenticacao/autenticacao.module";
import { OperacaoModule } from "./pages/operacao/operacao.module";
import { NgxMaskModule, MaskPipe } from 'ngx-mask';
import { MascaraPipe } from "./pipes/mascara.pipe";
import { FiltroPipe } from "./pipes/filtro.pipe";
import { UpperCasePipe } from "@angular/common";
import { PortalHomeComponent } from "./pages/portal/home/portal-home.component";
import { AcessibilidadeComponent } from "./pages/outros/acessibilidade/acessibilidade.component";
import { MapaDoSiteComponent } from "./pages/outros/mapa-do-site/mapa-do-site.component";
import { PesquisaViabilidadeModule } from './pages/pesquisa-viabilidade/pesquisa-viabilidade.module';
import { OficioModule } from './pages/outros/oficio/oficio.module';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TermoComponent } from './pages/outros/termo/termo.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CoreComponent,
    PortalHomeComponent,
    NaoEncontradoComponent,
    AcessibilidadeComponent,
    MapaDoSiteComponent,
    TermoComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbNavModule,
    NgbAccordionModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    NgxMaskModule.forRoot(),
    ComponentsModule,
    AutenticacaoModule,
    OperacaoModule,
    PesquisaViabilidadeModule,
    OficioModule,
  ],
  providers: [
    MascaraPipe,
    FiltroPipe,
    UpperCasePipe,
    MaskPipe,
    {
      provide: Document,
      useValue: document
    },
    {
      provide: Window,
      useValue: window
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
