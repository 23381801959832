<!-- <div class="card container-principal-processos">
  <div class="card-header card-header-localizacao">
      <div class="col-12">
          <strong>Aqui você localiza os dados a sua escolha</strong>
      </div>      
  </div>
  <div class="card-body card-body-localizacao">
    <form
        class="justify-content-center align-items-center"
        name="formulario"
        [formGroup]="formulario"
    >
      <div class="row">
        <div class="col-4">
          <select 
              #tipoBuscaSelect
              class="form-control form-control-sm"
              (change)="acaoSelectTipoBusca()"
              formControlName="tipoBusca"
              name="tipoBusca" 
              id="tipoBusca"                
          >
              <option disabled value="">--- Selecione ---</option>
              <option *ngFor="let filtro of grid.filtro; let i = index"
                [value]="i" 
                >{{ filtro.display }}
              </option>
          </select>
        </div>
        <div class="col-7">
          <input [class.hidden]="tipoBusca.value != '' ? grid.filtro[tipoBusca.value].tipoCampo != 'INPUT' : true "
              #tipoBuscaValorInput
              class="form-control form-control-sm"
              formControlName="tipoBuscaValorInput"
              id="tipoBuscaValorInput"
              name="tipoBuscaValorInput"                
              type="text"
          />
          <select [class.hidden]="tipoBusca.value != '' ? grid.filtro[tipoBusca.value].tipoCampo != 'SELECT' : true"
            #tipoBuscaValorSelect
            formControlName="tipoBuscaValorSelect"
            class="form-control form-control-sm"
            name="tipoBuscaValorSelect" 
            id="tipoBuscaValorSelect"                
          >
            <option value=""></option>
            <option *ngFor="let opcao of filtroOpcoes" 
                value="{{ opcao.valor }}"
            >
                {{ opcao.display }}
            </option>
          </select>
        </div>
        <div class="col-1 text-right">
          <button 
              type="button" 
              class="btn btn-secondary"
              title="Buscar"
              (click)="buscarGridPorFiltro()"
              [disabled] = "tipoBuscaValorSelect.value == '' && tipoBuscaValorInput == ''"
          >
          Buscar
          </button>
        </div>
      </div>
    </form>
  </div>
</div> -->

<div class="container-principal">
  <mat-tab-group mat-align-tabs="start">
    
    <!-- Regularizações -->
    <mat-tab label="Regularizações">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
          <img src="../../../../assets/img/icons/clipboard.svg" style="width: 15px;"/>
        </mat-icon> Regularizações
      </ng-template>
      <app-grid-acompanhamento #regularizacoes></app-grid-acompanhamento>
    </mat-tab>

    <!-- Concluidos -->
    <mat-tab label="Concluidos">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
          <img src="../../../../assets/img/icons/check.svg" style="width: 15px;"/>
        </mat-icon> Concluidos
      </ng-template>
      <app-grid-acompanhamento #concluidos></app-grid-acompanhamento>
    </mat-tab>

    <!-- Cancelados -->
    <mat-tab label="Cancelados">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon" style="font-size: 17px; margin-right: 8px;width: 15px;">
          <img src="../../../../assets/img/icons/x.svg" style="width: 15px;"/>
        </mat-icon> Cancelados
      </ng-template>
      <app-grid-acompanhamento #cancelados></app-grid-acompanhamento>
    </mat-tab>

  </mat-tab-group>
</div>
