import { PerfilComponent } from './../outros/autenticacao/login/perfil/perfil.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../services/authentication/auth.guard';

import { HomeComponent } from '../home/home.component';
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProcessoComponent } from "./processo/processo.component";
import { PendenciaComponent } from "./pendencia/pendencia.component";
import { AberturaEmpresaMeiComponent } from "./abertura/empresa-mei/abertura-empresa-mei.component";
import { AlteracaoEmpresaMeiComponent } from "./alteracao/empresa-mei/alteracao-empresa-mei.component";
import { BaixaEmpresaMeiAutonomoComponent } from "./baixa/empresa-mei-autonomo/baixa-empresa-mei-autonomo.component";
import { RecadastroEmpresaMeiComponent } from "./recadastro/empresa-mei/recadastro-empresa-mei.component";
import { AberturaAutonomoComponent } from "./abertura/autonomo/abertura-autonomo.component";
import { AlteracaoAutonomoComponent } from "./alteracao/autonomo/alteracao-autonomo.component";
import { RecadastroAutonomoComponent } from "./recadastro/autonomo/recadastro-autonomo.component";
import { ChatComponent } from "../suporte/chat.component";
import { MensagemComponent } from "../suporte/mensagem.component";
import { FicComponent } from "./fic/fic.component";
import { AtendimentoGridComponent } from "../outros/atendimento/atendimento-grid/atendimento-grid.component";
import { AtendimentoProtocoloComponent } from "../outros/atendimento/atendimento-protocolo/atendimento-protocolo.component";
import { AtendimentoEmpresaComponent } from '../outros/atendimento/atendimento-empresa/atendimento-empresa.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { AtendimentoLicenciamentoComponent } from '../outros/atendimento/atendimento-licenciamento/atendimento-licenciamento.component';
import { AberturaOficioComponent } from '../outros/oficio/abertura/abertura-oficio.component';
import { BaixaOficioComponent } from '../outros/oficio/baixa/baixa-oficio.component';
import { TimeLineProtocoloComponent } from '../outros/time-line/time-line-protocolo.component';
import { ConsultarProtocoloComponent } from './consulta/consultar-protocolo.component';
import { AutenticidadeAlvaraComponent } from './autenticidade-alvara/autenticidade-alvara.component';
import { FaqComponent } from './faq/faq.component';
import { PesquisaViabilidadeComponent } from '../pesquisa-viabilidade/pesquisa-viabilidade.component';
import { LicenciamentoComponent } from '../outros/licenciamento/licenciamento.component';
import { AnaliseEntornoComponent } from '../outros/analise-entorno/analise-entorno.component';
import { AberturaEventoComponent } from './abertura/evento/abertura-evento.component';
import { GerenciamentoTaxaComponent } from '../outros/taxas/gerenciamento-taxa.component';
import { InformacaoRedeSimPerguntasComponent } from '../outros/informacoes-cadastro/informacao-rede-sim-perguntas/informacao-rede-sim-perguntas.component';
import { AtualizacaoOficioComponent } from '../outros/oficio/atualizacao/atualizacao-oficio/atualizacao-oficio.component';
import { DebitoComponent } from './debito/debito.component';
import { MeusDamComponent } from './meus-dam/meus-dam.component';
import { EstatisticasTaxaComponent } from '../outros/taxas/estatisticas-taxa/estatisticas-taxa.component';
import { OperacaoLoteComponent } from '../outros/taxas/operacao-lote/operacao-lote.component';
import { OperacaoTaxaComponent } from '../outros/taxas/operacao-taxa/operacao-taxa.component';
import { AlteracaoOficioComponent } from '../outros/oficio/alteracao/alteracao-oficio.component';

const routes: Routes = [
  {
    path: '',
    data: {
      eixo: 'operacao'
    },
    children: [
      { path: '', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          tipoEmpresa: '',
          menu: '',
        }
      },
      { path: 'dashboard/:codigoOrgao/:codigoMenu',
        component: DashboardComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'DashBoard',
          icone: 'pie-chart',
          isAxis: true,
        }
      },
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu',
        component: ConsultarProtocoloComponent,
        data: {
          eixo: 'operacao',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },    
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          isAxis: true,
        }
      },      
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/informacao-rede-sim-perguntas',
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          isAxis: true,
        }
      },      
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/visualizar-protocolo/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/visualizar-protocolo/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/visualizar-protocolo/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/visualizar-empresa/:inscricao', 
        component: AtendimentoEmpresaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/visualizar-empresa/:inscricao/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/visualizar-empresa/:inscricao/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/visualizar-empresa/:inscricao/:protocolo', 
        component: AtendimentoEmpresaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/visualizar-empresa/:inscricao/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'consulta-processo/:codigoOrgao/:codigoMenu/visualizar-empresa/:inscricao/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'perfil',
        component: PerfilComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Perfil',
          icone: 'pie-chart',
          isAxis: true,
        }
      },
      { path: 'acompanhamento', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Acompanhamento',
          icone: 'layers',
          tipoEmpresa: 'D',
          menu: 'acompanhamento',
        } 
      },
      { path: 'acompanhamento/dashboard/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: DashboardComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'DashBoard',
          icone: 'dashboard',
          isAxis: true,
        }
      },
      { path: 'acompanhamento/processos/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: ProcessoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Processos',
          icone: 'processos_02',
          isAxis: true,
          reload: true,
        }
      },
      { path: 'acompanhamento/processos/:codigoMenuPai/:codigoOrgao/:codigoMenu/acompanhamento/pendencia/:protocolo',
        component: PendenciaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Pêndencia',
          icone: 'pendencia',
          isAxis: true,
        }
      },
      { path: 'acompanhamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/detalhar/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'acompanhamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/detalhar/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'acompanhamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/detalhar/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'consulta-processo',
        }
      },
      { path: 'acompanhamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/time-line/:protocolo', 
        component: TimeLineProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'processos',
        }
      },
      { path: 'acompanhamento/pendencia/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: PendenciaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Pêndencia',
          icone: 'pendencia',
          isAxis: true,
        }
      },
      { path: 'acompanhamento/meusNegocios/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: EmpresasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
        }
      },
      { path: 'acompanhamento/meusNegocios/:codigoMenuPai/:codigoOrgao/:codigoMenu/acompanhamento/pendencia/:inscricao/:protocolo',
        component: PendenciaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Pêndencia',
          icone: 'pendencia',
          isAxis: true,
        }
      },
      { path: 'acompanhamento/processos/:codigoMenuPai/:codigoOrgao/:codigoMenu/acompanhamento/pendencia/:protocolo',
        component: PendenciaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Pêndencia',
          icone: 'pendencia',
          isAxis: true,
        }
      },
      { path: 'acompanhamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo', 
        component: AtendimentoEmpresaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'negocios',
        }
      },
      { path: 'acompanhamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'negocios',
        }
      },
      { path: 'acompanhamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Negócios',
          icone: 'empresa_abertura_02',
          isAxis: true,
          menu: 'negocios',
        }
      },
      { path: 'empresa', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Abertura',
          icone: 'briefcase',
          tipoEmpresa: 'E',
          menu: 'empresa',
        } 
      },
      { path: 'empresa/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AberturaEmpresaMeiComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Abertura',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'E',
          isAxis: true,
        }
      },
      { path: 'empresa/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AberturaEventoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'E',
          isAxis: true,
        }
      },
      { path: 'empresa/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Abertura',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'E',
          isAxis: true,
        }
      },
      { path: 'empresa/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: AberturaEmpresaMeiComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Abertura',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'E',
          isAxis: true,
        }
      },
      { path: 'empresa/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: AberturaEventoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'E',
          isAxis: true,
        }
      },
      { path: 'empresa/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Abertura',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'E',
          isAxis: true,
        }
      },
      { path: 'empresa/alteracao/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AlteracaoEmpresaMeiComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Alteração',
          icone: 'empresa_alteracao',
          tipoEmpresa: 'E',
          isAxis: true,
        }
      },
      { path: 'empresa/alteracao/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Alteração',
          icone: 'empresa_alteracao',
          tipoEmpresa: 'E',
          isAxis: true,
        }
      },
      { path: 'empresa/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: BaixaEmpresaMeiAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BE',
          isAxis: true,
        }
      },
      { path: 'empresa/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BE',
          isAxis: true,
        }
      },
      { path: 'empresa/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: BaixaEmpresaMeiAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BE',
          isAxis: true,
        }
      },
      { path: 'empresa/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BE',
          isAxis: true,
        }
      },
      { path: 'empresa/recadastro/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: RecadastroEmpresaMeiComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Recadastro',
          icone: 'empresa_recadastro_02',
          tipoEmpresa: 'E',
          isAxis: true,
        }
      },
      { path: 'empresa/recadastro/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Empresa / Recadastro',
          icone: 'empresa_recadastro_02',
          tipoEmpresa: 'E',
          isAxis: true,
        }
      },
      { path: 'mei', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Abertura',
          icone: 'users',
          tipoEmpresa: 'M',
          menu: 'mei',
        } 
      },
      { path: 'mei/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AberturaEmpresaMeiComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Abertura',
          icone: 'mei_abertura_03',
          tipoEmpresa: 'M',
          isAxis: true,
        }
      },
      { path: 'mei/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AberturaEventoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura',
          icone: 'mei_abertura_03',
          tipoEmpresa: 'M',
          isAxis: true,
        }
      },
      { path: 'mei/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Abertura',
          icone: 'mei_abertura_03',
          tipoEmpresa: 'M',
          isAxis: true,
        }
      },
      { path: 'mei/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: AberturaEmpresaMeiComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Abertura',
          icone: 'mei_abertura_03',
          tipoEmpresa: 'M',
          isAxis: true,
        }
      },
      { path: 'mei/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: AberturaEventoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura',
          icone: 'mei_abertura_03',
          tipoEmpresa: 'M',
          isAxis: true,
        }
      },
      { path: 'mei/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Abertura',
          icone: 'mei_abertura_03',
          tipoEmpresa: 'M',
          isAxis: true,
        }
      },
      { path: 'mei/alteracao/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AlteracaoEmpresaMeiComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Alteração',
          icone: 'empresa_alteracao',
          tipoEmpresa: 'M',
          isAxis: true,
        }
      },
      { path: 'mei/alteracao/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Alteração',
          icone: 'empresa_alteracao',
          tipoEmpresa: 'M',
          isAxis: true,
        }
      },
      { path: 'mei/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: BaixaEmpresaMeiAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BM',
          isAxis: true,
        }
      },
      { path: 'mei/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BM',
          isAxis: true,
        }
      },
      { path: 'mei/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: BaixaEmpresaMeiAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BM',
          isAxis: true,
        }
      },
      { path: 'mei/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BM',
          isAxis: true,
        }
      },
      { path: 'mei/recadastro/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: RecadastroEmpresaMeiComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Recadastro',
          icone: 'empresa_recadastro_02',
          tipoEmpresa: 'M',
          isAxis: true,
        }
      },
      { path: 'mei/recadastro/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para MEI / Recadastro',
          icone: 'empresa_recadastro_02',
          tipoEmpresa: 'M',
          isAxis: true,
        }
      },
      { path: 'autonomo', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Abertura',
          icone: 'user',
          tipoEmpresa: 'A',
          menu: 'autonomo',
        } 
      },
      { path: 'autonomo/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AberturaAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Abertura',
          icone: 'autonomo_abertura_02',
          tipoEmpresa: 'A',
          isAxis: true,
        }
      },
      { path: 'autonomo/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Abertura',
          icone: 'autonomo_abertura_02',
          tipoEmpresa: 'A',
          isAxis: true,
        }
      },
      { path: 'autonomo/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: AberturaAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Abertura',
          icone: 'autonomo_abertura_02',
          tipoEmpresa: 'A',
          isAxis: true,
        }
      },
      { path: 'autonomo/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Abertura',
          icone: 'autonomo_abertura_02',
          tipoEmpresa: 'A',
          isAxis: true,
        }
      },
      { path: 'autonomo/alteracao/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AlteracaoAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Alteração',
          icone: 'empresa_alteracao',
          tipoEmpresa: 'A',
          isAxis: true,
        }
      },
      { path: 'autonomo/alteracao/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Alteração',
          icone: 'empresa_alteracao',
          tipoEmpresa: 'A',
          isAxis: true,
        }
      },
      { path: 'autonomo/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: BaixaEmpresaMeiAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BA',
          isAxis: true,
        }
      },
      { path: 'autonomo/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BA',
          isAxis: true,
        }
      },
      { path: 'autonomo/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: BaixaEmpresaMeiAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BA',
          isAxis: true,
        }
      },
      { path: 'autonomo/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BA',
          isAxis: true,
        }
      },
      { path: 'autonomo/recadastro/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: RecadastroAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Recadastro',
          icone: 'empresa_recadastro_02',
          tipoEmpresa: 'A',
          isAxis: true,
        }
      },
      { path: 'autonomo/recadastro/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Autônomo / Recadastro',
          icone: 'empresa_recadastro_02',
          tipoEmpresa: 'A',
          isAxis: true,
        }
      },
      { path: 'evento', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura',
          icone: 'briefcase',
          tipoEmpresa: 'EV',
          menu: 'evento',
        } 
      },
      { path: 'evento/aberturaEvento/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AberturaEventoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura Empresa',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'EEV',
          isAxis: true,
        }
      },
      { path: 'evento/aberturaEmpresa/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura Empresa',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'EEV',
          isAxis: true,
        }
      },
      { path: 'evento/aberturaEvento/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: AberturaEventoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura Empresa',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'EEV',
          isAxis: true,
        }
      },
      { path: 'evento/aberturaEmpresa/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura Empresa',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'EEV',
          isAxis: true,
        }
      },
      { path: 'evento/aberturaAutonomoEvento/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AberturaEventoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura Autônomo',
          icone: 'autonomo_abertura_02',
          tipoEmpresa: 'AEV',
          isAxis: true,
        }
      },
      { path: 'evento/aberturaAutonomo/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura Autônomo',
          icone: 'autonomo_abertura_02',
          tipoEmpresa: 'AEV',
          isAxis: true,
        }
      },
      { path: 'evento/aberturaEvento/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: AberturaEventoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura Autônomo',
          icone: 'autonomo_abertura_02',
          tipoEmpresa: 'AEV',
          isAxis: true,
        }
      },
      { path: 'evento/aberturaAutonomo/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Abertura Autônomo',
          icone: 'autonomo_abertura_02',
          tipoEmpresa: 'AEV',
          isAxis: true,
        }
      },
      { path: 'evento/alteracao/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AlteracaoEmpresaMeiComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Alteração',
          icone: 'empresa_alteracao',
          tipoEmpresa: 'AEV',
          isAxis: true,
        }
      },
      { path: 'evento/alteracao/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Alteração',
          icone: 'empresa_alteracao',
          tipoEmpresa: 'AEV',
          isAxis: true,
        }
      },
      { path: 'evento/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: BaixaEmpresaMeiAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BEV',
          isAxis: true,
        }
      },
      { path: 'evento/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BEV',
          isAxis: true,
        }
      },
      { path: 'evento/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo',
        component: BaixaEmpresaMeiAutonomoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BEV',
          isAxis: true,
        }
      },
      { path: 'evento/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Baixa',
          icone: 'empresa_baixa_01',
          tipoEmpresa: 'BEV',
          isAxis: true,
        }
      },
      { path: 'evento/recadastro/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: RecadastroEmpresaMeiComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Recadastro',
          icone: 'empresa_recadastro_02',
          tipoEmpresa: 'REV',
          isAxis: true,
        }
      },
      { path: 'evento/recadastro/:codigoMenuPai/:codigoOrgao/:codigoMenu/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Evento / Recadastro',
          icone: 'empresa_recadastro_02',
          tipoEmpresa: 'REV',
          isAxis: true,
        }
      },
      { path: 'suporte', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Suporte / Chat',
          icone: 'phone-call',
          tipoEmpresa: 'S',
          menu: 'suporte',
        } 
      },
      { path: 'suporte/chat/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: ChatComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Suporte / Chat',
          icone: 'phone-call',
          isAxis: true,
        }
      },
      { path: 'suporte/mensagem/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: MensagemComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Suporte / Mensagem',
          icone: 'phone-call',
          isAxis: true,
        }
      },
      { path: 'fic/:protocolo',
        component: FicComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Ficha Cadastral',
          icone: 'layers',
          menu: 'FIC',
          isAxis: true,
        }
      },
      { path: 'fic/:protocolo/analise-entorno',
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Ficha Cadastral',
          icone: 'layers',
          menu: 'FIC',
          isAxis: true,
        }
      },
      { path: 'licenciamento/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:orgao/:requisito',
        component: LicenciamentoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Para Licenciamento',
          icone: 'empresa_abertura_01',
          tipoEmpresa: 'L',
          isAxis: true,
        }
      },
      { path: 'viabilidade', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Consulta Prévia',
          icone: 'map-pin',
          tipoEmpresa: '',
          menu: 'viabilidade',
        }
      },
      { path: 'viabilidade/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Consulta Prévia',
          icone: 'map-pin',
          tipoEmpresa: '',
          menu: 'viabilidade',
        }
      },
      { path: 'viabilidade/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Consulta Prévia',
          icone: 'map-pin',
          tipoEmpresa: '',
          menu: 'viabilidade',
        }
      },
      { path: 'viabilidade/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Consulta Prévia',
          icone: 'map-pin',
          tipoEmpresa: '',
          menu: 'viabilidade',
        }
      },
      { path: 'viabilidade/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Consulta Prévia',
          icone: 'map-pin',
          tipoEmpresa: '',
          menu: 'viabilidade',
        }
      },
      { path: 'viabilidadeEvento', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Consulta Prévia Evento',
          icone: 'map-pin',
          tipoEmpresa: '',
          menu: 'viabilidadeEvento',
        }
      },
      { path: 'viabilidadeEvento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Consulta Prévia Evento',
          icone: 'map-pin',
          tipoEmpresa: '',
          menu: 'viabilidadeEvento',
        }
      },
      { path: 'viabilidadeEvento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Consulta Prévia Evento',
          icone: 'map-pin',
          tipoEmpresa: '',
          menu: 'viabilidadeEvento',
        }
      },
      { path: 'viabilidadeEvento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Consulta Prévia Evento',
          icone: 'map-pin',
          tipoEmpresa: '',
          menu: 'viabilidadeEvento',
        }
      },
      { path: 'fic', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Dados Cadastrais / Inscrição Municipal',
          icone: 'file-text',
          tipoEmpresa: '',
          menu: 'fic',
        }
      },
      { path: 'fic/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Dados Cadastrais / Inscrição Municipal',
          icone: 'file-text',
          tipoEmpresa: '',
          menu: 'fic',
        }
      },
      { path: 'fic/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Dados Cadastrais / Inscrição Municipal',
          icone: 'file-text',
          tipoEmpresa: '',
          menu: 'fic',
        }
      },
      { path: 'fic/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Dados Cadastrais / Inscrição Municipal',
          icone: 'file-text',
          tipoEmpresa: '',
          menu: 'fic',
        }
      },
      { path: 'fic/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Dados Cadastrais / Inscrição Municipal',
          icone: 'file-text',
          tipoEmpresa: '',
          menu: 'fic',
        }
      },
      { path: 'ficEvento', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Dados Cadastrais / Inscrição Municipal Evento',
          icone: 'file-text',
          tipoEmpresa: '',
          menu: 'ficEvento',
        }
      },
      { path: 'ficEvento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Dados Cadastrais / Inscrição Municipal Evento',
          icone: 'file-text',
          tipoEmpresa: '',
          menu: 'ficEvento',
        }
      },
      { path: 'ficEvento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Dados Cadastrais / Inscrição Municipal Evento',
          icone: 'file-text',
          tipoEmpresa: '',
          menu: 'ficEvento',
        }
      },
      { path: 'ficEvento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Dados Cadastrais / Inscrição Municipal Evento',
          icone: 'file-text',
          tipoEmpresa: '',
          menu: 'ficEvento',
        }
      },
      { path: 'encerramento', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Baixa',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'encerramento',
        }
      },
      { path: 'encerramento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Baixa',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'encerramento',
        }
      },
      { path: 'encerramento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Baixa',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'encerramento',
        }
      },
      { path: 'encerramento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Baixa',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'encerramento',
        }
      },
      { path: 'encerramento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Baixa',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'encerramento',
        }
      },
      { path: 'recadastro', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Recadastro',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'recadastro',
        }
      },
      { path: 'recadastro/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Recadastro',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'recadastro',
        }
      },
      { path: 'recadastro/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Recadastro',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'recadastro',
        }
      },
      { path: 'recadastro/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Recadastro',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'recadastro',
        }
      },
      { path: 'recadastro/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Recadastro',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'recadastro',
        }
      },
      { path: 'oficio', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Ofício',
          icone: 'award',
          tipoEmpresa: '',
          menu: 'oficio',
        }
      },
      { path: 'oficio/abertura/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AberturaOficioComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Ofício',
          icone: 'award',
          tipoEmpresa: 'AO',
          menu: 'oficio',
        }
      },
      { path: 'oficio/alteracao/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AlteracaoOficioComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Ofício',
          icone: 'award',
          tipoEmpresa: 'UJ',
          menu: 'oficio',
        }
      },
      { path: 'oficio/baixa/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: BaixaOficioComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Ofício',
          icone: 'award',
          tipoEmpresa: 'BO',
          menu: 'oficio',
        }
      },
      { path: 'orgao/:orgao', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Orgão',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'orgao',
        }
      },
      { path: 'orgao/:orgao/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Orgão',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'orgao',
        }
      },
      { path: 'orgao/:orgao/despacho/:codigoMenuPai/:codigoOrgao/:codigoMenu/:codigoDespacho/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Orgão',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'orgao',
          subMenu: 'despacho',
        }
      },
      { path: 'orgao/:orgao/despacho/:codigoMenuPai/:codigoOrgao/:codigoMenu/:codigoDespacho/:protocolo/:inscricao', 
        component: AtendimentoEmpresaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Orgão',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'orgao',
          subMenu: 'despacho',
        }
      },
      { path: 'orgao/:orgao/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo', 
        component: AtendimentoEmpresaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Orgão',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'orgao',
        }
      },
      { path: 'orgao/:orgao/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Orgão',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'orgao',
        }
      },
      { path: 'orgao/:orgao/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Orgão',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'orgao',
        }
      },
      { path: 'orgao/:orgao/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/elicenciamento/:codigoRequisito', 
        component: AtendimentoLicenciamentoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Orgão',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'orgao',
        }
      },
      { path: 'moduloAlvara', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Módulo Alvará',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'moduloAlvara',
        }
      },
      { path: 'moduloAlvara/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Orgão',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'orgao',
        }
      },
      { path: 'moduloAlvara/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo', 
        component: AtendimentoEmpresaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Módulo Alvará',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'moduloAlvara',
        }
      },
      { path: 'moduloAlvara/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Módulo Alvará',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'moduloAlvara',
        }
      },
      { path: 'moduloAlvara/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Módulo Alvará',
          icone: 'shield',
          tipoEmpresa: '',
          menu: 'moduloAlvara',
        }
      },
      { path: 'alvara', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Gestão de Licenças e Alvará',
          icone: 'globe',
          tipoEmpresa: '',
          menu: 'alvara',
        }
      },
      { path: 'alvara/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Gestão de Licenças e Alvará',
          icone: 'globe',
          tipoEmpresa: '',
          menu: 'alvara',
        }
      },
      { path: 'alvara/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Gestão de Licenças e Alvará',
          icone: 'globe',
          tipoEmpresa: '',
          menu: 'alvara',
        }
      },
      { path: 'alvara/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Gestão de Licenças e Alvará',
          icone: 'globe',
          tipoEmpresa: '',
          menu: 'alvara',
        }
      },
      { path: 'alvara/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Gestão de Licenças e Alvará',
          icone: 'globe',
          tipoEmpresa: '',
          menu: 'alvara',
        }
      },
      { path: 'servicoParaCidadao/pesquisaViabilidade/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: PesquisaViabilidadeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Serviços para o Cidadão',
          icone: 'empresa_abertura_02',
          isAxis: true,
        }
      },
      { path: 'servicoParaCidadao/verifiqueAutenticidadeDoAlvara/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AutenticidadeAlvaraComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Serviços para o Cidadão',
          icone: 'empresa_abertura_02',
          isAxis: true,
        }
      },
      { path: 'servicoParaCidadao/faq/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: FaqComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Serviços para o Cidadão',
          icone: 'empresa_abertura_02',
          isAxis: true,
        }
      },
      { path: 'ServicoParaAuditor/verifiqueAutenticidadeDoAlvara/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: AutenticidadeAlvaraComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Serviço para o Auditor',
          icone: 'empresa_abertura_02',
          isAxis: true,
        }
      },
      { path: 'cancelamento', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Cancelamentos',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'cancelamento',
        }
      },
      { path: 'cancelamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Cancelamentos',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'cancelamento',
        }
      },
      { path: 'cancelamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo', 
        component: AtendimentoProtocoloComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Cancelamentos',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'cancelamento',
        }
      },
      { path: 'cancelamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/analise-entorno', 
        component: AnaliseEntornoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Cancelamentos',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'cancelamento',
        }
      },      
      { path: 'cancelamento/:subMenu/:codigoMenuPai/:codigoOrgao/:codigoMenu/:protocolo/informacao-rede-sim-perguntas', 
        component: InformacaoRedeSimPerguntasComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Cancelamentos',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'cancelamento',
        }
      },  
      { path: 'moduloTaxa', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'DAM',
          icone: 'credit-card',
          tipoEmpresa: '',
          menu: 'moduloTaxa',
        }
      },
      { path: 'moduloTaxa/financeiro/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: EstatisticasTaxaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Financeiro',
          icone: 'credit-card',
          tipoEmpresa: '',
          menu: 'moduloTaxa',
        }
      },
      { path: 'moduloTaxa/operacaoLote/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: OperacaoLoteComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Operação por Lote',
          icone: 'credit-card',
          tipoEmpresa: '',
          menu: 'moduloTaxa',
        }
      },
      { path: 'moduloTaxa/operacaoIndividual/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: OperacaoTaxaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Operação Individual',
          icone: 'credit-card',
          tipoEmpresa: '',
          menu: 'moduloTaxa',
        }
      },
      { path: 'suspensaoAuditor', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Suspensão',
          icone: 'x-circle',
          tipoEmpresa: '',
          menu: 'suspensaoAuditor',
        }
      },
      { path: 'oficio/novaSuspensao/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Solicitar Suspensão',
          icone: 'award',
          tipoEmpresa: '',
          subMenu: 'novaSuspensao',
          menu: 'suspensao',
        }
      },
      { path: 'oficio/novaSuspensao/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:cnpj', 
        component: AtendimentoEmpresaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Solicitar Suspensão',
          icone: 'award',
          tipoEmpresa: '',
          subMenu: 'novaSuspensao',
          menu: 'suspensao',
        }
      },
      { path: 'oficio/emSuspensao/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Suspensão',
          icone: 'x-circle',
          tipoEmpresa: '',
          subMenu: 'emSuspensao',
          menu: 'suspensao',
        }
      },
      { path: 'oficio/emSuspensao/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:protocolo', 
        component: AtendimentoEmpresaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Suspensão',
          icone: 'x-circle',
          tipoEmpresa: '',
          subMenu: 'emSuspensao',
          menu: 'suspensao',
        }
      },
      { path: 'oficio/atualizacaoMei/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
      component: AtualizacaoOficioComponent,
      data: {
        eixo: 'operacao',
        breadcrumb: 'Atualização',
        icone: 'x-circle',
        tipoEmpresa: '',
        subMenu: 'atualizacao',
        menu: 'atuaizacao',
      }
      },
      { path: 'damContribuinte', 
        component: HomeComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'DAM',
          icone: 'layers',
          tipoEmpresa: 'D',
          menu: 'damContribuinte',
        } 
      },
      { path: 'damContribuinte/consultaDebitos/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: DebitoComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Consulta de Débitos',
          icone: 'pendencia',
          isAxis: true,
          reload: true,
        }
      },
      { path: 'acompanhamento/meusDam/:codigoMenuPai/:codigoOrgao/:codigoMenu',
        component: MeusDamComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus DAM',
          icone: 'pendencia',
          isAxis: true,
          reload: true,
        }
      },
      { path: 'acompanhamento/meusNegocios/:codigoMenuPai/:codigoOrgao/:codigoMenu/acompanhamento/meusDam/:inscricao/:protocolo',
        component: MeusDamComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Meus Dam',
          icone: 'pendencia',
          isAxis: true,
        }
      },
      { path: 'oficio/reativacao/:codigoMenuPai/:codigoOrgao/:codigoMenu', 
        component: AtendimentoGridComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Solicitar Reativação',
          icone: 'award',
          tipoEmpresa: '',
          subMenu: 'reativacao',
          menu: 'reativacao',
        }
      },
      { path: 'oficio/reativacao/:codigoMenuPai/:codigoOrgao/:codigoMenu/:inscricao/:cnpj', 
        component: AtendimentoEmpresaComponent,
        data: {
          eixo: 'operacao',
          breadcrumb: 'Solicitar Reativação',
          icone: 'award',
          tipoEmpresa: '',
          subMenu: 'reativacao',
          menu: 'reativacao',
        }
      },
    ],
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperacaoRoutingModule { }
