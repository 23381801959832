<app-alert type="danger"
    *ngIf="emitirCritica" >
    <div [innerHtml]="mensagemCritica"></div>
</app-alert>
<app-alert type="warning" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
    <div class="col-sm-12" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
        Sr. Contribuinte, segue orientações para preenchimento dos sócios.
    </div>
    <div class="col-sm-12" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
        É necessário escolher um responsável legal do seu negócio.
    </div>
    <div class="col-sm-12" *ngIf="!obrigatorioContador">
        Para o seu processo não é necessário escolher um contador.
    </div>
</app-alert>
<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<form
    class="justify-content-center align-items-center"
    name="formulario"
    [formGroup]="formulario"
>
    <div [class.hidden]="editarResponsavel">
        <div class="card">
            <div class="card-header">
                <div class="row col-12 justify-content-start align-items-center" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
                    <strong>Responsáveis, capital social e administradores</strong>
                </div>
                <div class="row col-12 justify-content-start align-items-center" *ngIf="tipoEmpresa == 'A' || tipoEmpresa == 'AEV'">
                    <strong>Responsável contábil</strong>
                </div>
            </div>
            <div class="card-body">
                <div class="form-row" >
                    <div class="form-group col-3" *ngIf="protocolo.processo.descricao != 'Abertura de M.E.I.'">
                        <label class="col-sm-12 col-form-label" for="cpfCnpjResponsavelBusca">CPF / CNPJ</label>
                        <input
                            #inputcpfCnpjResponsavelBusca
                            (input)="formatToCPFOrCNPJ(inputcpfCnpjResponsavelBusca.value)"
                            [class.is-invalid]="cpfCnpjResponsavelBusca.invalid && cpfCnpjResponsavelBusca.touched"
                            autofocus
                            class="form-control form-control-sm col-12"
                            formControlName="cpfCnpjResponsavelBusca"
                            id="cpfCnpjResponsavelBusca"
                            name="cpfCnpjResponsavelBusca"
                            type="text"
                            maxlength="18"
                        />
                        <div class="mb-1" *ngIf="cpfCnpjResponsavelBusca.invalid && cpfCnpjResponsavelBusca.touched">
                            <span 
                                class="erro" 
                                *ngIf="cpfCnpjResponsavelBusca.errors.minlength"
                            >O CPF / CNPJ deve conter ao menos 14 caracteres.</span>
                            <span 
                                class="erro" 
                                *ngIf="cpfCnpjResponsavelBusca.errors.maxlength"
                            >O CPF / CNPJ deve conter até 18 caracteres.</span>
                            <span 
                                class="erro" 
                                *ngIf="cpfCnpjResponsavelBusca.hasError('mensagem')"
                            >{{cpfCnpjResponsavelBusca.errors.mensagem}}</span>
                        </div>
                    </div>
                    <div class="form-group col-3" *ngIf="protocolo.processo.descricao == 'Abertura de M.E.I.'">
                        <label class="col-sm-12 col-form-label" for="cpfCnpjResponsavelBusca">CPF</label>
                        <input
                            #inputcpfCnpjResponsavelBusca
                            (input)="formatToCPF(inputcpfCnpjResponsavelBusca.value)"
                            [class.is-invalid]="cpfCnpjResponsavelBusca.invalid && cpfCnpjResponsavelBusca.touched"
                            autofocus
                            class="form-control form-control-sm col-12"
                            formControlName="cpfCnpjResponsavelBusca"
                            id="cpfCnpjResponsavelBusca"
                            name="cpfCnpjResponsavelBusca"
                            type="text"
                            maxlength="14"
                        />
                        <div class="mb-1" *ngIf="cpfCnpjResponsavelBusca.invalid && cpfCnpjResponsavelBusca.touched">
                            <span 
                                class="erro" 
                                *ngIf="cpfCnpjResponsavelBusca.errors.maxlength"
                            >O CPF / CNPJ deve conter até 14 caracteres.</span>
                            <span 
                                class="erro" 
                                *ngIf="cpfCnpjResponsavelBusca.hasError('mensagem')"
                            >{{cpfCnpjResponsavelBusca.errors.mensagem}}</span>
                        </div>
                    </div>
                    <div class="form-group col-2">
                        <label class="col-sm-12 col-form-label" for="tipoBuscaValor">&nbsp;</label>
                        <button 
                            type="button" 
                            id="btn-buscar-responsavel"
                            class="btn btn-secondary"
                            title="Buscar"
                            (click)="buscar()"
                        >
                        Buscar
                        </button>
                    </div>
                    <div class="form-group col-2" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
                        <div class="simple--counter-container">
                            <div class="counter-container">
                                <p class="s-counter-text">ADICIONADOS</p>
                                <div class="counter-content">
                                    <h1 class="s-counter2 s-counter">{{ socios.length }}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-3" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
                        <div class="simple--counter-container">
                            <div class="counter-container">
                                <p class="s-counter-text">CAPITAL SOCIAL (MATRIZ)</p>
                                <div class="counter-content">
                                    <h1 class="s-counter2 s-counter">{{ capitalSocialMatriz }}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-2" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
                        <div class="simple--counter-container">
                            <div class="counter-container">
                                <p class="s-counter-text">PERCENTUAL</p>
                                <div class="counter-content">
                                    <h1 class="s-counter2 s-counter">{{ ajusteDePercentual(calcularPercentualTotal()) }}%</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row header-socios">
                <div class="row col-1 justify-content-center align-items-center" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
                    <p class="card-text titulo-socios">
                        <strong>Legal</strong>
                    </p>
                </div>
                <div class="row col-1 justify-content-center align-items-center" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
                    <p class="card-text titulo-socios">
                        <strong>Contador</strong>
                    </p>
                </div>
                <div class="row col-3 justify-content-center align-items-center" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
                    <p class="card-text titulo-socios">
                        <strong>Qualificação</strong>
                    </p>
                </div>
                <div class="row col-3 justify-content-center align-items-center" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
                    <p class="card-text titulo-socios">
                        <strong>Participação</strong>
                    </p>
                </div>
                <div class="row col-3 justify-content-center align-items-center">
                    <p class="card-text titulo-socios">
                        <strong>Responsável</strong>
                    </p>
                </div>
                <div class="row col-1 justify-content-center align-items-center"></div>
            </div>
            <div *ngIf="!socios.length"
                class="card-body">
                <div class="row col-12 justify-content-center align-items-center">
                    <p class="card-text">
                        <br>
                        Seus responsáveis aparecerão aqui.
                        <br>&nbsp;
                    </p>
                </div>
            </div>
            <div *ngIf="socios.length"
                class="card-body card-body-titulo">
                <div *ngFor="let socio of socios; let i = index">
                    <div class="row row-linha linha-grid-divisao" >
                        <div class="row col-1 justify-content-center align-items-center" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'AEV'">
                            <mat-radio-button
                                #responsavelLegalRadio
                                (change)="acaoRadioResponsavelLegal(i)"
                                [checked]="socio.responsavelLegal"
                                id="responsavelLegal_{{ i }}"
                                name="responsavelLegal">
                            </mat-radio-button>
                        </div>
                        <div class="row col-1 justify-content-center align-items-center" *ngIf="tipoEmpresa != 'A' && tipoEmpresa != 'AEV'">
                            <mat-radio-button
                                #contadorRadio
                                [disabled]="!socio.login.contador"
                                [class.hidden]="socio.contador"
                                [matTooltip]="socio.login.contador ? '' : 'Não é possivel adicionar como contador.\n Acesse o cadastro de usuário do CPF / CNPJ informado e preencha os dados de CRC. Após o preenchimento retorne a esse passo e informe o CPF / CNPJ novamente.'"
                                matTooltipPosition="above"
                                (click)="acaoRadioContador(i)"
                                [checked]="socio.contador"
                                id="contador_{{ i }}"
                                name="contador"
                                value="{{ i }}">
                            </mat-radio-button>
                            
                            <button type="button" 
                                    matTooltip="Desmarcar o contribuinte como contador."
                                    matTooltipPosition="above"
                                    [class.hidden]="!socio.contador"
                                    class="btn btn-sm"
                                    (click)="acaoContadorDesmarcar(contadorRadio, i)">
                                <svg class="icone-excluir-socio">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'x-circle' }}"
                                    ></use>
                                </svg>
                            </button>
                        </div>
                        <div class="row col-3 justify-content-center align-items-center" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'AEV'">
                            <select 
                                #qualificacaoSelect
                                [class.is-invalid]="campoInvalido('qualificacao_' + i) && campoTocado('qualificacao_' + i)"
                                (change)="acaoQualificacaoSelect(qualificacaoSelect.value, i, responsavelLegalRadio, inputCapitalSocial)"
                                class="form-control form-control-sm"
                                name="qualificacao_{{ i }}" 
                                id="qualificacao_{{ i }}"
                            >
                                <option value=""></option>
                                <option *ngFor="let regra of socioRegras" 
                                    [selected]="regra.naturezaPessoaCodigo == socio.qualificacao"
                                    value="{{ regra.naturezaPessoaCodigo }}"
                                >
                                    {{ regra.naturezaPessoaNome | uppercase}}
                                </option>
                            </select>
                        </div>
                        <div class="row col-3 justify-content-center align-items-center" [class.hidden]="tipoEmpresa == 'A' || tipoEmpresa == 'AEV'">
                            <input
                                #inputCapitalSocial
                                autofocus
                                (input)="acaoCapitalSocialInput(inputCapitalSocial, i)"
                                [disabled]="capitalSocialMatriz == '0,00' || socio.qualificacao == ''"
                                class="form-control form-control-sm col-8 text-right"
                                type="text"
                                [value]="socio.participacao == 0 || socio.participacao == '' || capitalSocialMatriz == '0,00' ? '0,00' : socio.participacao | mascara: 11"
                                maxlength="18"
                            />
                            <span class="form-row col-2 text-center">
                                {{ ajusteDePercentual(calcularPercentual(i)) }}%
                            </span>
                        </div>
                        <div class="row col-3 justify-content-start align-items-center">
                            <p class="card-text titulo">
                                <strong>{{ socio.login.nome }}</strong><br>
                                <span class="endereco-escolhido">
                                    CPF / CNPJ: {{ htmlFormatToCPFOrCNPJ(socio.login.documentos[0].numero) }}<br>
                                    {{ socio.login.enderecos[0].tipoLogradouro }} 
                                    {{ socio.login.enderecos[0].logradouro }}{{ socio.login.enderecos[0].numero == '' ? '' : ', ' }}
                                    {{ socio.login.enderecos[0].numero }}{{ socio.login.enderecos[0].complemento == '' ? '' : ' - ' }}
                                    {{ socio.login.enderecos[0].complemento }}
                                    <!-- {{ socio.login.enderecos[0].pontoReferencia == '' ? '' : ' - ' }}{{ socio.login.enderecos[0].pontoReferencia }} -->
                                    {{ socio.login.enderecos[0].cep == '' ? '' : ' - ' }}
                                    {{ ("00000000" + socio.login.enderecos[0].cep).slice(-8) | mask: '00000-000'  }}{{ socio.login.enderecos[0].bairro == '' ? '' : ' - ' }}
                                    {{ socio.login.enderecos[0].bairro }}{{ socio.login.enderecos[0].cidade == '' ? '' : ' - ' }}
                                    {{ socio.login.enderecos[0].cidade }}{{ socio.login.enderecos[0].estado == '' ? '' : ' - ' }}
                                    {{ socio.login.enderecos[0].estado }}
                                </span>
                            </p>
                        </div>
                        <div class="row col-1 justify-content-center align-items-center">
                            <button type="button" 
                                    matTooltip="Editar"
                                    matTooltipPosition="above"
                                    class="btn btn-sm"
                                    (click)="acaoEditarSocioEscolhido(i)">
                                <svg class="icone-editar-socio">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'edit' }}"
                                    ></use>
                                </svg>
                            </button>
                            <button type="button" 
                                    matTooltip="Excluir"
                                    matTooltipPosition="above"
                                    class="btn btn-sm"
                                    (click)="acaoExcluirSocioEscolhido(i)">
                                <svg class="icone-excluir-socio">
                                    <use
                                        attr.xlink:href="assets/sprite.svg#{{ 'trash-2' }}"
                                    ></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!possuiContador && !obrigatorioContador" 
            class="card">
            <div class="card-header">
                <div class="col-12">
                    <strong>Deseja continuar sem responsável contábil?</strong>
                </div>
            </div>
            <div class="card-body">
                <div class="form-row row-linha linha-grid-divisao">
                    <div class="row col-1 justify-content-center align-items-center">
                        <mat-checkbox 
                            #termoResponsavelContabil
                            (change)="acaoTermoResponsavelContabil(termoResponsavelContabil)"
                            id="termoResponsavelContabil"
                            name="termoResponsavelContabil">
                        </mat-checkbox>
                    </div>
                    <div class="col-11">
                        <p class="card-text">
                            "Declaro que não possuo responsável contábil."
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div [class.hidden]="!editarResponsavel">
        <app-form-responsavel-dados></app-form-responsavel-dados>
        <app-form-endereco id="app-form-endereco-responsavel" tipoEnderecoConfiguracao="4"></app-form-endereco>
    </div>

</form>