import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AtendimentoConfiguracao } from "src/app/model/atendimentoConfiguracao";
import { Uso } from "src/app/model/uso";
import { Constantes } from "src/app/model/constantes";
import Swal from "sweetalert2";
import { ActivatedRoute } from "@angular/router";
import { FormAtividadeComponent } from '../../form-atividade/form-atividade.component';
import { Protocolo } from '../../../../model/protocolo';
import { TipoUnidade } from '../../../../model/tipoUnidade';

@Component({
  selector: 'app-atendimento-atividade',
  templateUrl: './atendimento-atividade.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoAtividadeComponent implements OnInit {
  
  @Input() tipoEmpresa = "";
  @Input() semCardHeader: boolean = false;
  protocolo: Protocolo = new Protocolo();
  atendimentoConfiguracao: AtendimentoConfiguracao;
  tipoUso = Constantes.TIPO_DE_USO;

  usosPermitidosAdicionados: Array<Uso>;
  usosPermissiveisAdicionados: Array<Uso>;
  
  eBaixa: boolean;
  eRecadastro: boolean;
  emitirErro: boolean;
  mensagemErro: string;
  permitirAtendimento: boolean;
  permitirAtendimentoObjetoSocial: boolean;
  codigoMenuPai: number;

  tipoUnidadeAuxiliares: Array<TipoUnidade>;

  @ViewChild(FormAtividadeComponent)
  formAtivividade: FormAtividadeComponent;
  
  constructor(private activatedRoute: ActivatedRoute) {
    this.usosPermitidosAdicionados = new Array<Uso>();
    this.usosPermissiveisAdicionados = new Array<Uso>();    
    
    let meusProcessos = this.activatedRoute.snapshot.data["menu"] == Constantes.MENU_ATENDIMENTO.Processos;
    let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
    this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (!meusNegocios && !meusProcessos));
    
    let consultaDeProcessos = this.activatedRoute.snapshot.data["menu"] == "consulta-processo";
    this.permitirAtendimentoObjetoSocial = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && this.activatedRoute.snapshot.data["menu"] == "fic" && !consultaDeProcessos;
    
    this.codigoMenuPai = this.activatedRoute.snapshot.params["codigoMenuPai"];
    this.eBaixa = this.activatedRoute.snapshot.data["menu"] == Constantes.MENU_ATENDIMENTO.Baixa;
    this.eRecadastro = this.activatedRoute.snapshot.data["menu"] == Constantes.MENU_ATENDIMENTO.Recadastro;

    this.tipoUnidadeAuxiliares = JSON.parse(localStorage.getItem('user')).configuracao.tipoUnidades;
  }

  ngOnInit(): void {
    
  }

  acaoVincularAtividadeUso(index, select) {
    if (select.value == "") {
      this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso = new Uso();
    }
    else {
      Swal.fire({
        text: 'Qual o motivo para a inclusão desse uso?',
        icon: 'question',
        input: 'textarea',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then((result) => {
        if (result.dismiss) {
          select.value = "";
        }
        else {
          Object.assign(this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso, this.atendimentoConfiguracao.viabilidade.usos.filter( item => item.codigo == select.value)[0])
          // this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso = this.atendimentoConfiguracao.viabilidade.usos.filter( item => item.codigo == select.value)[0];
          if (this.usosPermitidosAdicionados.find(item => item.codigo == select.value)) {
            this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso.tipo = Constantes.TIPO_DE_USO.Permitido
          }
          else if (this.usosPermissiveisAdicionados.find(item => item.codigo == select.value)) {
            this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso.tipo = Constantes.TIPO_DE_USO.Permissivel
          }
          else {
            this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso.tipo = Constantes.TIPO_DE_USO.Nao_Permitido
          }
          this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso.justificativa = result.value;
        }
      })
    }

  }

  acaoVincularAtividadeUsoSemJustificativa(index, select) {
    this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso = new Uso();
    this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso.tipo = select.value;
  }

  acaoAlterarJustificativaAtividadeUso(index) {
    Swal.fire({
      text: 'Qual o novo motivo para esse uso?',
      icon: 'question',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    })
    .then((result) => {
      if (!result.dismiss) {
        this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso.justificativa = result.value;
      }
    })
  }

  acaoReplicarUsoEJustificativaParaTodasAtividades(index) {
    Swal.fire({
      text: 'Deseja realmente replicar o uso e justificativa para todas as atividades?',
      icon: 'question',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    })
    .then((result) => {
      if (!result.dismiss) {
        var uso = this.atendimentoConfiguracao.viabilidade.atividadesExercidas[index].uso;
        this.atendimentoConfiguracao.viabilidade.atividadesExercidas.forEach( atividade => Object.assign(atividade.uso, uso) );
      }
    });
  }

  atualizarStatusUso() {
    if (this.atendimentoConfiguracao != null) {
      this.atendimentoConfiguracao.viabilidade.atividadesExercidas.forEach(atividade => {
        if (this.usosPermitidosAdicionados.find(item => item.codigo == atividade.uso.codigo)) {
          atividade.uso.tipo = Constantes.TIPO_DE_USO.Permitido
        }
        else if (this.usosPermissiveisAdicionados.find(item => item.codigo == atividade.uso.codigo)) {
          atividade.uso.tipo = Constantes.TIPO_DE_USO.Permissivel
        }
        else {
          atividade.uso.tipo = Constantes.TIPO_DE_USO.Nao_Permitido
        }
      })
    }
  }
  
  preencher(atendimentoConfiguracao){
    this.atendimentoConfiguracao = atendimentoConfiguracao;
  }

  validarFormulario() {
    this.emitirErro = false;
    let mensagemErro = "";

    this.atendimentoConfiguracao.viabilidade.atividadesExercidas.forEach(atividade => {
      if (atividade.uso.codigo == "") {
        mensagemErro = "Favor informar os usos para todas as atividades.<br>";
      }
    });

    if (mensagemErro == "") {
      return true;
    }
    
    this.mensagemErro = mensagemErro;
    this.emitirErro = true;
    Swal.fire("Atividades Incorretas", "Corrigir os erros descritos no informativo da página.", "error");
    
    return false;
    
  }

  validarFormularioBaixa() {
    this.emitirErro = false;
    let mensagemErro = "";

    this.atendimentoConfiguracao.viabilidade.atividadesExercidas.forEach(atividade => {
      if (atividade.uso.tipo == "") {
        mensagemErro = "Favor avaliar todas as atividades.<br>";
      }
    });

    if (mensagemErro == "") {
      return true;
    }
    
    this.mensagemErro = mensagemErro;
    this.emitirErro = true;
    Swal.fire("Atividades Incorretas", "Corrigir os erros descritos no informativo da página.", "error");
    
    return false;
    
  }

}
