import { Component, OnInit, Input } from '@angular/core';
import { Protocolo } from "src/app/model/protocolo";
import { ArquivoService } from '../../../../services/arquivo/arquivo.service';
import { MatDialog } from '@angular/material/dialog';
import { IcadModalComponent } from 'src/app/components/modal/icad-modal/icad-modal.component';

@Component({
  selector: 'app-informacao-documento',
  templateUrl: './informacao-documento.component.html',
  styleUrls: ["../informacao-cadastro.component.scss"]
})
export class InformacaoDocumentoComponent implements OnInit {

  @Input() semCardHeader: boolean = false;
  protocolo: Protocolo;

  empresaSuspensa: boolean = false;
  
  constructor(private arquivoService: ArquivoService,
    public dialog: MatDialog,) {
    this.protocolo = new Protocolo();
  }

  ngOnInit(): void {
  }

  baixarArquivo(codigo, nome) {
    if (this.protocolo.documentos.filter( item => item.codigo == codigo)[0].status != 'N') {
      this.arquivoService.downloadArquivoAtendimento(nome)
        .subscribe((res: any) => {
          this.arquivoService.handleFile(res, nome + '.pdf');
        });
    }
  }

  visualizarArquivo(codigo, nome) {
    this.arquivoService.downloadArquivoAtendimentoParaVisualizar(nome)
      .subscribe((res: any) => {
        const dialogRef = this.dialog.open(IcadModalComponent, {
          width: '60%',
          height: '80%',
          maxWidth: 'none',
          maxHeight: 'none',
          closeOnNavigation: true,
          data: {
              titulo: "Informativo",
              componente: "pop-up",
              dados: [atob(res.html)]
          }
        });
      });
  }

}
