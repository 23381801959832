<div class="justify-content-md-end">
  <form name="formulario" 
    [formGroup]="formulario" 
    class="justify-content-center align-items-center"
    >
    <app-alert type="danger"
        *ngIf="emitirErro" >
        <div [innerHtml]="mensagemErro"></div>
    </app-alert>
    <div class="card">
      <div class="card-header">
        <div class="col-12">
            <strong>Baixa</strong>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
      <div class="row col-12">
        <div class="row col-6 justify-content-start align-items-center">
            <button type="button" class="btn btn-danger" (click)="acaoBotaoVoltar()">Voltar</button>
        </div>
        <div class="row col-6 justify-content-end align-items-center divisao-esquerda">
          <button type="button" class="btn btn-success" (click)="acaoBotaoSalvar()">Concluir</button>
        </div>
      </div>
  </div>
</div>