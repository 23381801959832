import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Constantes } from '../../../../model/constantes';
import { DocumentoEmpresaProtocolo } from '../../../../model/documentoEmpresaProtocolo';
import { EmpresaService } from '../../../../services/empresa/empresa.service';
import { Documento } from '../../../../model/documento';
import { take } from "rxjs/operators";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-atendimento-seletor-im',
  templateUrl: './atendimento-seletor-im.component.html',
  styleUrls: ['../atendimento-protocolo/atendimento-protocolo.component.scss']
})
export class AtendimentoSeletorImComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};

  formulario: FormGroup;
  permitirAtendimento: boolean;
  cliente: string;
  protocolo: string;
  documento: Documento;
  modulo: string;
  inputSeletorIm: string;

  @Input() dados: Array<any>;

  documentoSelecaoIm: Array<DocumentoEmpresaProtocolo>;

  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private empresaService: EmpresaService,) {
    
      this.documentoSelecaoIm =  new Array<DocumentoEmpresaProtocolo>();
      let meusProcessos = this.activatedRoute.snapshot.data["menu"] == Constantes.MENU_ATENDIMENTO.Processos;
      let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
      this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (!meusNegocios && !meusProcessos));

    this.formulario = this.formBuilder.group({
      novaIm: [''],
    });

  }

  ngOnInit(): void {
    
  }

  preencher() {
    this.inputSeletorIm = this.novaIm.value;
  }

  carregarEmpresas(){
    this.empresaService
      .buscarEmpresasPorDocumento(this.cliente,this.protocolo,this.documento.numero)
      .pipe(take(1))
      .subscribe(retorno => this.documentoSelecaoIm = retorno);
  }

  get novaIm() {
    return this.formulario.get("novaIm");
  }
  set novaIm(novaIm) {
    this.formulario.get("novaIm").setValue(novaIm);
  }

}
