import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { FormValidator } from '../../../../components/validators/form-validator';
import { Constantes } from '../../../../model/constantes';

@Component({
  selector: 'app-atendimento-data-abertura',
  templateUrl: './atendimento-data-abertura.component.html',
  styleUrls: ['./atendimento-protocolo.component.scss']
})
export class AtendimentoDataAberturaComponent implements OnInit {
  
  formulario: FormGroup;
  permitirAtendimento: boolean;
  emitirErro: boolean;
  mensagemErro: string;
  modulo: string;
  menuAtendimento = Constantes.MENU_ATENDIMENTO;
  mensagemDeAcordoComModulo = "Qual a data desejada para registro da abertura?";

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder) {    
      let meusNegocios = this.activatedRoute.snapshot.data["menu"] == "negocios";
      this.modulo = this.activatedRoute.snapshot.data["menu"];
      this.permitirAtendimento = this.activatedRoute.snapshot.params["subMenu"] != "concluido" && (this.activatedRoute.snapshot.params["subMenu"] != "concluido" && !meusNegocios);

    this.formulario = this.formBuilder.group({
      dataAbertura: ["", [Validators.required, Validators.minLength(8), FormValidator.dataValida(), FormValidator.dataMaiorHoje()]],
    });

    if (this.modulo == Constantes.MENU_ATENDIMENTO.Baixa) {
      this.mensagemDeAcordoComModulo = "Qual a data para registro da baixa?"
    }
   }

  ngOnInit(): void {
    if (!this.permitirAtendimento)
      this.dataAbertura.disable();
  }

  validarFormulario() {
    if (this.modulo == Constantes.MENU_ATENDIMENTO.Baixa) {
      return this.validarFormularioBaixa();
    }
    else {
      return this.validarFormularioAbertura();
    }
  } 

  validarFormularioAbertura() {
    this.formulario.markAllAsTouched();
    this.emitirErro = false;

    if (this.formulario.invalid) {
        let mensagemErro = "";

        if (this.dataAbertura.invalid) {
            mensagemErro += "Favor informar qual a data de abertura.<br>"
        }

        if (mensagemErro == "") {
            this.emitirErro = false;
            return true;
        }

        Swal.fire("Data de Abertura Incorreta", "Corrigir os erros descritos no informativo da página.", "error");

        this.emitirErro = true;
        this.mensagemErro = mensagemErro;

        return false;
    }

    this.emitirErro = false;
    this.mensagemErro = "";

    return true;
  } 

  validarFormularioBaixa() {
    this.formulario.markAllAsTouched();
    this.emitirErro = false;

    if (this.formulario.invalid) {
        let mensagemErro = "";

        if (this.dataAbertura.invalid) {
            mensagemErro += "Favor informar qual a data para registro da baixa.<br>"
        }

        if (mensagemErro == "") {
            this.emitirErro = false;
            return true;
        }

        Swal.fire("Data de Baixa Incorreta", "Corrigir os erros descritos no informativo da página.", "error");

        this.emitirErro = true;
        this.mensagemErro = mensagemErro;

        return false;
    }

    this.emitirErro = false;
    this.mensagemErro = "";

    return true;
  } 

  get dataAbertura() {
    return this.formulario.get("dataAbertura");
  }
  set dataAbertura(dataAbertura) {
    this.formulario.get("dataAbertura").setValue(dataAbertura);
  }

}
