<div class="overlay content" *ngIf="isLoading">
  <!-- <div class="loader">
    <div class="loading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div> -->
  <ngx-ui-loader></ngx-ui-loader>
</div>