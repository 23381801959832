import { Component, OnInit } from "@angular/core";
import { AtendimentoService } from "src/app/services/operacao/atendimento.service";
import { take } from 'rxjs/operators';
import { Configuracao } from '../../model/configuracao';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
    selector: "app-chat",
    templateUrl: "./chat.component.html",
})
export class ChatComponent implements OnInit {
    dados: String;
    configuracao: Configuracao;

    constructor(
        private atendimentoService: AtendimentoService,
        private authenticationService: AuthenticationService) {
          
    }
    
    ngOnInit(): void {        

    }

    salvarUsuarioCervello() {
      const currentUser = this.authenticationService.currentUserValue;

      this.atendimentoService
        .urlChat(currentUser.perfil)
        .pipe(take(1))
        .subscribe( retorno => {
          window.open(retorno);
        });
    }
}