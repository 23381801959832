<app-alert type="danger"
    *ngIf="emitirErro" >
    <div [innerHtml]="mensagemErro"></div>
</app-alert>
<div class="row col-12 justify-content-start mt-5">
    <p class="text-center texto-explicativo ml-3">
        Para iniciar a pesquisa:
    </p>
</div>
<div class="row col-12 justify-content-start mt-5">
    <p class="text-center texto-explicativo ml-3">
        Informe o tipo de negócio pretendido.
    </p>
</div>
<div class="row col-12 mb-4">
    <div class="col-12"><mat-divider></mat-divider></div>
</div>
<div class="row col-12">
    <div class="row col-4">
        <div #divTipoDeNegocio1
            id="divTipoDeNegocio1"
            name="divTipoDeNegocio"
            (click)="selecionar(divTipoDeNegocio1)"
            class="row col-12 justify-content-center align-items-center p-4 btn-pesquisa-identificacao">
            <p class="text-center btn-pesquisa-identificacao-conteudo">
                <span>
                    <svg class="iconeMenu">
                        <use
                          attr.xlink:href="#{{ 'briefcase' }}"
                        ></use>
                    </svg>
                </span>
                Empresa
            </p>
        </div>
    </div>
    <div class="row col-4">
        <div #divTipoDeNegocio2
            id="divTipoDeNegocio2"
            name="divTipoDeNegocio"
            (click)="selecionar(divTipoDeNegocio2)"
            class="row col-12 justify-content-center align-items-center p-4 btn-pesquisa-identificacao">
            <p class="text-center btn-pesquisa-identificacao-conteudo">
                <span>
                    <svg class="iconeMenu">
                        <use
                          attr.xlink:href="#{{ 'users' }}"
                        ></use>
                    </svg>
                </span>
                MEI
            </p>
        </div>
    </div>
    <div class="row col-4">
        <div #divTipoDeNegocio3
            id="divTipoDeNegocio3"
            name="divTipoDeNegocio"
            (click)="selecionar(divTipoDeNegocio3)"
            class="row col-12 justify-content-center align-items-center p-4 btn-pesquisa-identificacao">
            <p class="text-center btn-pesquisa-identificacao-conteudo">
                <span>
                    <svg class="iconeMenu">
                        <use
                          attr.xlink:href="#{{ 'user' }}"
                        ></use>
                    </svg>
                </span>
                Autônomo Estabelecido
            </p>
        </div>
    </div>
</div>
<div class="row col-12 mb-4">
    <div class="col-12">&nbsp;</div>
</div>
<div class="row col-12 justify-content-start mt-5">
    <p class="text-center texto-explicativo ml-3">
        Selecione uma das opções abaixo.
    </p>
</div>
<div class="row col-12 mb-4">
    <div class="col-12"><mat-divider></mat-divider></div>
</div>
<div class="row col-12">
    <div class="row col-4">
        <div #divTipoDeBusca1
            id="divTipoDeBusca1"
            name="divTipoDeBusca"
            (click)="selecionar(divTipoDeBusca1)"
            class="row col-12 justify-content-center align-items-center p-4 btn-pesquisa-identificacao">
            <p class="text-center btn-pesquisa-identificacao-conteudo">
                <img
                    class="logo"
                    src="../../../../../assets/img/icons/anchor.svg"
                />
            </p>
            <p class="text-center btn-pesquisa-identificacao-conteudo">
                <b>LOCAL E ATIVIDADE(S)</b> (já tenho o local e as atividade(s) que pretendo exercer no empreendimento)
            </p>
        </div>
    </div>
    <div class="row col-4">
        <div #divTipoDeBusca2
            id="divTipoDeBusca2"
            name="divTipoDeBusca"
            (click)="selecionar(divTipoDeBusca2)"
            class="row col-12 justify-content-center align-items-center p-4 btn-pesquisa-identificacao">
            <p class="text-center btn-pesquisa-identificacao-conteudo">
                <img
                    class="logo"
                    src="../../../../../assets/img/icons/home.svg"
                />
            </p>
            <p class="text-center btn-pesquisa-identificacao-conteudo">
                <b>LOCAL</b> (tenho somente o local do empreendimento, quero pesquisar quais atividades posso exercer ali)
            </p>
        </div>
    </div>
    <div class="row col-4">
        <div #divTipoDeBusca3
            id="divTipoDeBusca3"
            name="divTipoDeBusca"
            (click)="selecionar(divTipoDeBusca3)"
            class="row col-12 justify-content-center align-items-center p-4 btn-pesquisa-identificacao">
            <p class="text-center btn-pesquisa-identificacao-conteudo">
                <img
                    class="logo"
                    src="../../../../../assets/img/icons/map.svg"
                />
            </p>
            <p class="text-center btn-pesquisa-identificacao-conteudo">
                <b>ATIVIDADE(S)</b> (tenho somente a(s) atividade(s) que pretendo, quero pesquisar em quais locais poderia exerce-las)
            </p>
        </div>
    </div>
</div>
<div class="row col-12">
    <div class="col-12">&nbsp;</div>
</div>
<div class="col-12 text-right">
    <p class="textoVermelho">
        Em dúvida sobre como navegar nos resultados? Utilize o ícone de informação (<img src="../../../../../assets/img/icons/info_azul.svg"/>) nas páginas acessadas
    </p>
</div>
