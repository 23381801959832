import { Component, OnInit, Input, ViewChild, Renderer2 } from '@angular/core';
import { Empresa } from "src/app/model/empresa";
import { Constantes } from "src/app/model/constantes";
import { InformacaoRequerenteComponent } from "./informacao-requerente/informacao-requerente.component";
import { InformacaoIdentificacaoComponent } from "./informacao-identificacao/informacao-identificacao.component";
import { InformacaoLocalizacaoComponent } from "./informacao-localizacao/informacao-localizacao.component";
import { InformacaoAtividadeComponent } from "./informacao-atividade/informacao-atividade.component";
import { InformacaoResponsavelComponent } from "./informacao-responsavel/informacao-responsavel.component";
import { InformacaoQuestionarioComponent } from "./informacao-questionario/informacao-questionario.component";
import { formatToCPFOrCNPJ, formatToDateTime } from "brazilian-values";
import { Endereco } from "src/app/model/endereco";
import { Protocolo } from "src/app/model/protocolo";
import { InformacaoDocumentoComponent } from './informacao-documento/informacao-documento.component';

@Component({
    selector: 'app-informacao-cadastro',
    templateUrl: './informacao-cadastro.component.html',
    styleUrls: ['./informacao-cadastro.component.scss']
})
export class InformacaoCadastroComponent implements OnInit {

    @Input() tipoAtendimento: string;
    @Input() tipoEmpresa: string;
    @Input() dados: any;
    protocolo: Protocolo;
    empresa: Empresa;
    abaAtual: string;

    tipoTelefone = Constantes.TIPO_DE_TELEFONE;

    @ViewChild(InformacaoRequerenteComponent)
    informacaoRequerente: InformacaoRequerenteComponent;

    @ViewChild(InformacaoIdentificacaoComponent)
    informacaoIdentificacao: InformacaoIdentificacaoComponent;

    @ViewChild(InformacaoLocalizacaoComponent)
    informacaoLocalizacao: InformacaoLocalizacaoComponent;

    @ViewChild(InformacaoAtividadeComponent)
    informacaoAtividade: InformacaoAtividadeComponent;

    @ViewChild(InformacaoResponsavelComponent)
    informacaoResponsavel: InformacaoResponsavelComponent;

    @ViewChild("informacaoQuestionarioViabilidade")
    informacaoQuestionarioViabilidade: InformacaoQuestionarioComponent;

    @ViewChild("informacaoQuestionarioFic")
    informacaoQuestionarioFic: InformacaoQuestionarioComponent;

    @ViewChild(InformacaoDocumentoComponent)
    informacaoDocumento: InformacaoDocumentoComponent;

    formasAtuacaoConfiguracao = JSON.parse(localStorage.getItem('user')).configuracao.formasAtuacao;
    tipoUnidadeAuxiliares = JSON.parse(localStorage.getItem('user')).configuracao.tipoUnidades;

    constructor(
        private document: Document,
        private renderer: Renderer2) {

        this.protocolo = new Protocolo();
        this.empresa = new Empresa();
        this.abaAtual = "";
    }

    ngOnInit() {
        if (this.dados != null) {
            if (this.tipoAtendimento == "P") {
                this.preenchimentoProtocolo();
            }
            else {
                this.preenchimentoEmpresa();
            }
        }
    }

    preenchimentoProtocolo() {
        Object.assign(this.protocolo, this.dados);

        setTimeout(() => {
            this.informacaoIdentificacao.tipoEmpresa = this.protocolo.processo.tipo != "A" ? "E" : "A";
            this.informacaoIdentificacao.protocolo = this.protocolo;
            this.informacaoLocalizacao.protocolo = this.protocolo;
            this.informacaoAtividade.protocolo = this.protocolo;
            this.informacaoResponsavel.protocolo = this.protocolo;
            this.informacaoQuestionarioViabilidade.perguntasUsuario = this.protocolo.viabilidade.perguntasUsuario;
            this.informacaoQuestionarioFic.perguntasUsuario = this.protocolo.fic.perguntasUsuario;
            this.informacaoDocumento.protocolo = this.protocolo;
        }, 0);
        
    }

    preenchimentoEmpresa() {
        Object.assign(this.empresa, this.dados);

        setTimeout(() => {
            this.informacaoIdentificacao.tipoEmpresa = this.empresa.registro.tipoPessoa != "F" ? "E" : "A";
            this.informacaoIdentificacao.protocolo.registro = this.empresa.registro;
            this.informacaoIdentificacao.protocolo.fic = this.empresa.fic;
            this.informacaoLocalizacao.protocolo = this.protocolo;
            this.informacaoAtividade.protocolo.viabilidade = this.empresa.viabilidade;
            this.informacaoAtividade.protocolo.fic = this.empresa.fic;
            this.informacaoResponsavel.protocolo.fic = this.empresa.fic;
            this.informacaoQuestionarioViabilidade.perguntasUsuario = this.empresa.viabilidade.perguntasUsuario;
            this.informacaoQuestionarioFic.perguntasUsuario = this.empresa.fic.perguntasUsuario;
        }, 0);
        
    }
        
    formatToCPFOrCNPJ(valor) {
        return formatToCPFOrCNPJ(valor.toString());
    }
        
    formatToDateTime(valor) {
        if (valor == null || valor == "")
            return "";
        
        return formatToDateTime(new Date(valor));
    }

    interacaoAbas(event) {
        let botao = '#' + event.target.id;
        let conteudo = '#' + event.target.id.replace("-tab", "");

        if (event.target.id == this.abaAtual) {
            this.renderer.removeClass(this.document.querySelector(botao), "active");
            this.renderer.removeClass(this.document.querySelector(botao), "show");
            this.renderer.removeClass(this.document.querySelector(conteudo), "active");
            this.renderer.removeClass(this.document.querySelector(conteudo), "show");
            this.abaAtual = "";
        }
        else {
            if (this.abaAtual != "") {
                let botaoAtual = '#' + this.abaAtual;
                let conteudoAtual = '#' + this.abaAtual.replace("-tab", "");

                this.renderer.removeClass(this.document.querySelector(botaoAtual), "active");
                this.renderer.removeClass(this.document.querySelector(botaoAtual), "show");
                this.renderer.removeClass(this.document.querySelector(conteudoAtual), "active");
                this.renderer.removeClass(this.document.querySelector(conteudoAtual), "show");
            }
            this.renderer.addClass(this.document.querySelector(botao), "active");
            this.renderer.addClass(this.document.querySelector(botao), "show");
            this.renderer.addClass(this.document.querySelector(conteudo), "active");
            this.renderer.addClass(this.document.querySelector(conteudo), "show");
            this.abaAtual = event.target.id;
        }
    }

}
