import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Navigation } from '@angular/router';

import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Constantes } from "src/app/model/constantes";
import { Login } from "src/app/model/login";
import { formatToCPFOrCNPJ } from "brazilian-values";
import { FormValidator } from "src/app/components/validators/form-validator";
import { LoginService } from '../../../../services/login/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  
  @Input() popUp: boolean;
  @Input() exibirFooter: boolean;
  @Output() autenticado = new EventEmitter();

  private unsubscribe = new Subject<void>();
  loginForm: FormGroup;
  esqueciSenhaForm: FormGroup;
  validarCadastroForm: FormGroup;
  alterarSenhaForm: FormGroup;
  esqueciSenha: boolean;
  alterarSenha: boolean;
  cadastrarUsuario: boolean;
  validarCadastro: boolean;
  mudarSenha: boolean;
  selecionarEstrutura: boolean;
  estruturas: any;
  estrutura: any;
  esqueciSenhaMsg = {
    type: '',
  };
  acessarPorNomeUsuario: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    public loginService: LoginService
  ) {
    this.esqueciSenha = false;
    this.alterarSenha = false;
    this.selecionarEstrutura = false;
    this.cadastrarUsuario = false;
    this.validarCadastro = false;
    this.mudarSenha = false;

    this.loginForm = this.formBuilder.group({
      login: ['', [Validators.required]],
      senha: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.esqueciSenhaForm = this.formBuilder.group({
      loginValidacaoRecuperar: ['', [Validators.required, Validators.minLength(11)]],
      email: ['', [Validators.required, Validators.email]],
    });

    this.validarCadastroForm = this.formBuilder.group({
      loginValidacao: ['', [Validators.required, Validators.minLength(11)]],
      codigoValidacao: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.alterarSenhaForm = this.formBuilder.group({
      senhaAtual: ['', [Validators.required, Validators.minLength(6)]],
      senhaAlterarSenha: ['', [Validators.required, Validators.minLength(6)]],
      confirmarSenha: ['', [Validators.required, FormValidator.equalsTo('senhaAlterarSenha')]],
    });

    if (!this.estaAutenticado()){
      this.senhaAtual.clearValidators();
    }

  }
  // Reconhece os campos
  get login() {
    return this.loginForm.get('login');
  }
  get senha() {
    return this.loginForm.get('senha');
  }
  get email() {
    return this.esqueciSenhaForm.get('email');
  }
  get loginValidacaoRecuperar() {
    return this.esqueciSenhaForm.get('loginValidacaoRecuperar');
  }
  get loginValidacao() {
    return this.validarCadastroForm.get('loginValidacao');
  }
  get codigoValidacao() {
    return this.validarCadastroForm.get('codigoValidacao');
  }
  get senhaAtual() {
    return this.alterarSenhaForm.get('senhaAtual');
  }
  get senhaAlterarSenha() {
    return this.alterarSenhaForm.get('senhaAlterarSenha');
  }
  get confirmarSenha() {
    return this.alterarSenhaForm.get('confirmarSenha');
  }

  ngOnInit(): void {
    if (this.router.url === '/selecionar-estrutura') {
      this.estruturas = JSON.parse(localStorage.getItem('estruturas'));
      this.selecionarEstrutura = true;
    }
    if (this.router.url === '/cadastrar-usuario') {
      this.cadastrarUsuario = true;
    }
    if (this.router.url === '/alterar-senha') {
      this.alterarSenha = true;
      this.mudarSenha = true;
    }
    if (localStorage.getItem("pId") == "" || localStorage.getItem("pId") == null) {
      this.router.navigate(['/portal-home']);
    }
    this.exibirFooter = !this.popUp;
    
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  formatToCPFOrCNPJ(valor) {
    if (!this.acessarPorNomeUsuario) {
      this.login.setValue(formatToCPFOrCNPJ(valor));
    }
  }

  formatToCPFOrCNPJValidacao(valor) {
    this.loginValidacao.setValue(formatToCPFOrCNPJ(valor));
  }

  formatToCPFOrCNPJValidacaoRecuperar(valor) {
    this.loginValidacaoRecuperar.setValue(formatToCPFOrCNPJ(valor));
  }

  recuperarSenha(esqueciSenhaForm) {
    if (this.esqueciSenhaForm.valid) {
      this.authenticationService
        .recuperarSenha(this.esqueciSenhaForm.value.loginValidacaoRecuperar, this.esqueciSenhaForm.value.email)
        .pipe(take(1))
        .subscribe(
          (res) => {
            if (res) {
              Swal.fire({
                html: `Seu cadastro foi localizado.<br><br>
                        Dentro de instantes você estará recebendo um e-mail com uma senha temporária para acesso ao sistema.<br>
                        Por tratar-se de um e-mail automático, verifique a caixa de spam (ou lixo eletrônico) de e-mails.<br>
                        (Caso encontre este e-mail em situação de spam, não esqueça de autorizar os recebimentos)`,
                icon: "success",
                willClose: () => {
                  this.router.navigate(["/portal-home"]);
                }
              });
            } else {
              Swal.fire('', 'O sistema não localizou o e-mail e/ou CPF/CNPJ informados. Verifique a informação e tente novamente.', 'error');
            }
          },
          (err) => {
            Swal.fire('', 'Não foi possível enviar o código de validação, tente mais tarde.', 'error');
          }
        );
    } else {
      let form = esqueciSenhaForm.get('email');
      form.markAsTouched({ onlySelf: true });
    }
  }

  enviarCodigoValidacao() {
    if (this.validarCadastroForm.valid) {
      this.authenticationService
        .validarCadastro(this.loginValidacao.value.replace(/\D/g, ''), this.codigoValidacao.value)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (usuario: Login) => {
            if (!usuario.status) {
              Swal.fire('', 'Login e/ou código inválidos.', 'error');
              return false;
            }
            this.login.setValue(usuario.login);
            this.senha.setValue(usuario.senha);
            this.submit(this.loginForm);
            Swal.fire('', 'Bem-vindo ao IcadOnline!', 'success');
          },
          (err) => {
            Swal.fire('', 'Não foi possível validar o cadastro, tente mais tarde.', 'error');
          }
        );
    } else {
      this.codigoValidacao.markAsTouched({ onlySelf: true });
    }
  }

  novaSenha(){
    if (!this.estaAutenticado()){
      this.enviarSenhaAlterada();
    }
    else
      this.enviarSenhaAlteradaAutenticado();
  }

  enviarSenhaAlterada() {
    if (this.alterarSenhaForm.valid) {
      this.authenticationService
        .alterarSenha(this.login.value.replace(/\D/g, ''), this.senha.value, this.senhaAlterarSenha.value)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (usuario) => {
            if (!usuario.status) {
              Swal.fire('', 'Não foi possível alterar sua senha, tente mais tarde.', 'error');
              return false;
            }
            this.login.setValue(usuario.login);
            this.senha.setValue(usuario.senha);
            this.submit(this.loginForm);
            
            this.selecionarEstrutura = true;
            this.esqueciSenha = false;
            this.alterarSenha = false;
            
            Swal.fire('', 'Bem-vindo ao IcadOnline!', 'success');
          },
          (err) => {
            Swal.fire('', 'Não foi possível validar o cadastro, tente mais tarde.', 'error');
          }
        );
    } else {
      this.alterarSenhaForm.markAllAsTouched();
    }
  }

  enviarSenhaAlteradaAutenticado() {
    if (this.alterarSenhaForm.valid) {
      this.authenticationService
        .alterarSenhaAutenticado(localStorage.getItem("userId"), this.senhaAtual.value, this.senhaAlterarSenha.value)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (usuario) => {
            if (!usuario.status) {
              Swal.fire('', 'Não foi possível validar suas informações!', 'error');
              return false;
            }
            Swal.fire('', 'Senha alterada com sucesso!', 'success');
            this.acaoBotaoVoltar();
          },
          (err) => {
            Swal.fire('', 'Não foi possível alterar sua senha, tente mais tarde.', 'error');
          }
        );
    } else {
      this.alterarSenhaForm.markAllAsTouched();
    }
  }

  reenviarCodigoValidacao() {
    this.authenticationService
      .reenviarCodigoValidacao(this.login.value.replace(/\D/g, ''))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        retorno => {
          if (retorno) {
            Swal.fire('', 'Código de validação reenviado com sucesso!', 'success');
          }
        },
        (err) => {
          Swal.fire('', 'Não foi possível reenviar o código de validação, tente mais tarde.', 'error');
        }
      )
  }

  submit(form) {
    if (this.loginForm.valid) {
      if (!this.acessarPorNomeUsuario) {
        this.localizacaoPorCpfCnpj();
      }
      else {
        this.localizacaoPorNomeDeUsuario();
      }
      
    } else {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  localizacaoPorCpfCnpj() {
    this.authenticationService
      .login(
        this.login.value,
        this.loginForm.get("senha").value
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (user: any) => {
          if (user.codigoErro != null) {
            Swal.fire({
              title: user.titulo,
              html: user.mensagem,
              icon: user.status,
              willClose: () => {}
            });
            
            return false;
          }

          this.estruturas = user.param_user;
          if (user.tipoLogin == Constantes.TIPO_DE_LOGIN.Nao_Encontrado) {
            Swal.fire('', 'Login e/ou senha inválidos.', 'error');
            throw new Error("Acesso não permitido.");
          }
          else if (user.tipoLogin == Constantes.TIPO_DE_LOGIN.Validar) {
            this.validarCadastro = true;
            return true;
          }
          else if (user.tipoLogin == Constantes.TIPO_DE_LOGIN.Alterar_Senha) {
            this.alterarSenha = true;
            return true;
          }
          else if (this.estruturas.length === 0) {
            Swal.fire('', 'Usuário não possui estrutura definida.', 'error');
            throw new Error("Estrutura não definida.");
          }
          else if (this.estruturas.length === 1) {
            this.logarEstrutura(
              this.estruturas[0].valor,
              this.estruturas[0].id
            );
          } else {
            this.selecionarEstrutura = true;
          }
        },
        (err) => {
          console.log('err', err);
          Swal.fire('', 'Login e/ou senha inválidos.', 'error');
        }
      );
  }

  localizacaoPorNomeDeUsuario() {
    this.authenticationService
      .loginPorNomeDeUsuario(
        this.login.value,
        this.loginForm.get("senha").value
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (user: any) => {
          if (user.codigoErro != null) {
            Swal.fire({
              title: user.titulo,
              html: user.mensagem,
              icon: user.status,
              willClose: () => {}
            });
            
            return false;
          }

          this.login.setValue(parseInt(user.codigo_usuario));
          
          this.estruturas = user.param_user;
          if (user.tipoLogin == Constantes.TIPO_DE_LOGIN.Nao_Encontrado) {
            Swal.fire('', 'Login e/ou senha inválidos.', 'error');
            throw new Error("Acesso não permitido.");
          }
          else if (user.tipoLogin == Constantes.TIPO_DE_LOGIN.Validar) {
            this.validarCadastro = true;
            return true;
          }
          else if (user.tipoLogin == Constantes.TIPO_DE_LOGIN.Alterar_Senha) {
            this.alterarSenha = true;
            return true;
          }
          else if (this.estruturas.length === 0) {
            Swal.fire('', 'Usuário não possui estrutura definida.', 'error');
            throw new Error("Estrutura não definida.");
          }
          else if (this.estruturas.length === 1) {
            this.logarEstrutura(
              this.estruturas[0].valor,
              this.estruturas[0].id
            );
          } else {
            this.selecionarEstrutura = true;
          }
        },
        (err) => {
          console.log('err', err);
          Swal.fire('', 'Login e/ou senha inválidos.', 'error');
        }
      );
  }

  verificaDadosUsuario(){
    if (this.login.value == null || this.login.value == "") {
      return false;
    }
    
    let cpf = this.login.value.toString().replace(/\D/g,"");
  
    this.loginService
        .buscarPorLoginLivre(cpf)
        .pipe(take(1))
        .subscribe( (usuario: Login) => { 
          if(usuario.enderecos[0].cidade == ""){
            Swal.fire({
              html: `Favor completar seus dados de cadastro.`,
              icon: "error",
              willClose: () => {
                this.router.navigate(["/operacao/perfil"]);
              }
            });
          }
        },
        (err) => {
            console.log('err', err);
            Swal.fire('', 'Não foi possível verificar o CPF / CNPJ.', 'error');
        }
    );
  }

  logarEstrutura(name: string, id: string) {

    this.verificaDadosUsuario();
  
    this.authenticationService
      .logarEstrutura(name, id, !this.popUp)
      .subscribe(function (res) {
        this.estrutura = res;
      });
    
    if (this.popUp) {
      this.autenticado.emit({ autenticado: true });
      return true;
    }
  }

  limpaEsqueciSenha() {
    this.esqueciSenhaMsg = {
      type: '',
    };
    this.email.reset();
  }

  limpaValidarCadastro() {
    this.codigoValidacao.reset();
  }

  limpaAlterarSenha() {
    this.alterarSenhaForm.reset();
  }
  
  onCancelarAcesso() {
    this.autenticado.emit({ autenticado: true });
    this.router.navigate(["/portal-home"]);  
    return true;
  }

  onCancelarCadastro(event) {
    this.cadastrarUsuario = event.cadastrarUsuario;
  }

  estaAutenticado() {
    return this.authenticationService.estaAutenticado();
  }

  acaoBotaoVoltar() {
    if ( this.estaAutenticado() ) {
      window.history.back()
    }
    else {
      this.alterarSenha = false; 
      this.limpaAlterarSenha()
    }
  }

  acaoAcessoPorNomeUsuario() {
    this.acessarPorNomeUsuario = !this.acessarPorNomeUsuario;

    if (this.acessarPorNomeUsuario) {
      Swal.fire({
        html: `Solicitação de acesso por usúario.<br><br>
                Atenção: O campo abaixo que antes só permitia CPF / CNPJ agora está permitindo nome de usuário.`,
        icon: "info"
      });
    }
    else {
      Swal.fire({
        html: `Solicitação de acesso por CPF / CNPJ.<br><br>
                Atenção: O campo abaixo está permitindo acesso apenas por CPF / CNPJ.`,
        icon: "info"
      });
    }
  }
}
